import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Slide,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { Row, RowModel, RowSelectionState } from '@tanstack/react-table';
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { useTanstackTableRef } from '@endorlabs/ui-common';

import { IconChevronLeft } from '../../themes';
import { ButtonCancel } from '../Button';
import { ButtonPrimary } from '../Button/ButtonPrimary';
import { DataTable } from '../DataTable';
import {
  DataTableColumnDef,
  DataTableRowSelectionRow,
} from '../DataTable/types';
import { SearchBar } from '../SearchBar';
import {
  ControlledLargeMultiselect,
  ControlledLargeMultistepOption,
} from './ControlledLargeMultiselect';
import { useControlledTableMultiselect } from './hooks/useControlledTableMultiselect';

const FilterSelectionValues = {
  ALL: 'ALL',
  SELECTED: 'SELECTED',
  UNSELECTED: 'UNSELECTED',
} as const;

type FilterSelectionValue =
  typeof FilterSelectionValues[keyof typeof FilterSelectionValues];

interface ControlledTableMultiselectProps<T extends DataTableRowSelectionRow> {
  actions?: ReactNode;
  allRows: DataTableRowSelectionRow[];
  columns?: DataTableColumnDef<T>[];
  isLoading?: boolean;
  label?: ReactNode;
  name: string;
  rows?: T[];
  // FIXME: Pass `messages` instead & enforce message shape
  tableButtonLabel?: ReactNode;
}

/**
 * A multiselect component geared towards very large numbers of options.
 * Allows for selection of options via a DataTable. Column definitions & primitive rows must be provided.
 * This table is displayed by transitioning against an ancestor component.
 * The ancestor component can be specified further up the component tree by supplying a `targetElement`
 * to the `useControlledTableMultiselect` hook.
 *
 * NOTE: Like all `ControlledFooField` components, must be used within a FormProvider.
 */
export const ControlledTableMultiselect = <T extends DataTableRowSelectionRow>({
  actions,
  allRows,
  columns = [],
  isLoading = false,
  label,
  name,
  rows = [],
  tableButtonLabel,
}: ControlledTableMultiselectProps<T>) => {
  const { space } = useTheme();

  const tableRef = useTanstackTableRef<unknown>();
  const tableContainerEl = useRef<HTMLElement>();

  const [filterBySelectionStatus, setFilterBySelectionStatus] =
    useState<FilterSelectionValue>(FilterSelectionValues.ALL);
  const [selectedCount, setSelectedCount] = useState<number>(rows.length);

  // Changes to row selection do not alone trigger a re-render of the table, so track selected count in state
  const handleRowSelectionChange = useCallback(
    (rowSelection: RowSelectionState) => {
      setSelectedCount(Object.keys(rowSelection).length);
    },
    []
  );

  const handleSelectionStatusFilterChange = useCallback(
    (filterType: FilterSelectionValue) => {
      setFilterBySelectionStatus(filterType);
    },
    []
  );

  const rowToOption = useMemo(() => {
    return (row: T) => {
      return {
        label: row.optionLabel ?? 'NO LABEL PROVIDED',
        value: row.optionValue ?? 'NO VALUE PROVIDED',
      } as ControlledLargeMultistepOption;
    };
  }, []);

  /**
   * Establish initial options from rows provided
   */
  const optionsFromProps = useMemo(() => {
    return rows.map(rowToOption);
  }, [rows, rowToOption]);

  const hiddenRows = useMemo(() => {
    const selectedValues = tableRef.current
      ? tableRef.current
          .getSelectedRowModel()
          .flatRows.map(
            (row: Row<unknown>) =>
              (row.original as DataTableRowSelectionRow)?.optionValue
          )
      : [];

    switch (filterBySelectionStatus) {
      case FilterSelectionValues.ALL:
        return [];
      case FilterSelectionValues.SELECTED:
        return allRows
          .filter((row) => !selectedValues.includes(row.optionValue))
          .map((row) => row.optionValue);
      case FilterSelectionValues.UNSELECTED:
        return allRows
          .filter((row) => selectedValues.includes(row.optionValue))
          .map((row) => row.optionValue);
      default:
        return [];
    }
  }, [allRows, filterBySelectionStatus, tableRef]);

  // This state essentially overrides the internal state of ControlledLargeMultiselect by passing in as the options prop.
  // Note that since the ControlledLargeMultiselect can remove (but not add) options internally,
  // we must also provide it a callback to update state here.
  const [optionsIncluded, setOptionsIncluded] =
    useState<ControlledLargeMultistepOption[]>(optionsFromProps);

  // Track if user has manually updated the values.
  // `isTouched` does not work since hidden field can be updated without focus,
  // and `isDirty` doesn't work since hidden field can be updated automatically in a few ways.
  const [userHasUpdated, setUserHasUpdated] = useState<boolean>(false);

  useEffect(() => {
    // Update included options if data comes in after initial render, but only if user has not manually updated.
    if (!userHasUpdated) {
      setOptionsIncluded(optionsFromProps);
    }
  }, [optionsFromProps, userHasUpdated]);

  const { isMultiselectTableDisplayed, targetElement, toggleMultiselectTable } =
    useControlledTableMultiselect({ fieldName: name });

  /**
   * Place our table container element within the container element designated from hook.
   */
  useLayoutEffect(() => {
    if (tableContainerEl.current && targetElement) {
      targetElement.appendChild(tableContainerEl.current);
    }
  }, [tableContainerEl, targetElement]);

  /**
   * Toggle display of the options table
   */
  const toggleOptionsTable = useCallback(() => {
    if (tableRef.current) {
      // Set currently available options as checked in the table
      // This relies on the fact that tables with row selection require an `optionLabel` field that is used as the row ID.
      // See `useDataTableRowSelection
      tableRef.current.setRowSelection(() => {
        return optionsIncluded.reduce((acc, option) => {
          acc[option.value] = true;
          return acc;
        }, {} as Record<string, boolean>);
      });
    }

    toggleMultiselectTable(name);
  }, [name, optionsIncluded, tableRef, toggleMultiselectTable]);

  /**
   * Update multiselect options from rows selected in table, & close table
   */
  const updateOptionsFromTable = useCallback(() => {
    if (tableRef.current) {
      const rowModel = tableRef.current.getSelectedRowModel() as RowModel<T>;
      const newOptions = rowModel.flatRows.map((row) => {
        return rowToOption(row.original);
      });

      setOptionsIncluded(newOptions);
      setUserHasUpdated(true);
    }
    toggleMultiselectTable(name);
  }, [name, rowToOption, tableRef, toggleMultiselectTable]);

  const updateOptionsFromMultiselect = useCallback(
    (newOptions: ControlledLargeMultistepOption[]) => {
      tableRef.current?.setRowSelection(
        newOptions.reduce((acc, opt) => {
          acc[opt.value] = true;
          return acc;
        }, {} as Record<string, boolean>)
      );

      setOptionsIncluded(newOptions);
      setUserHasUpdated(true);
    },
    [tableRef]
  );

  return (
    <>
      <Box sx={{ display: isMultiselectTableDisplayed ? 'none' : 'block' }}>
        <ControlledLargeMultiselect
          actions={
            <>
              <ButtonPrimary disabled={isLoading} onClick={toggleOptionsTable}>
                {tableButtonLabel ?? <FM defaultMessage="Add More" />}
              </ButtonPrimary>

              {actions}
            </>
          }
          isLoading={isLoading}
          label={label}
          name={name}
          options={optionsIncluded}
          updateOptions={updateOptionsFromMultiselect}
        />
      </Box>

      <Slide
        container={targetElement}
        direction="left"
        in={isMultiselectTableDisplayed}
        ref={tableContainerEl}
      >
        <Stack
          spacing={space.lg}
          sx={{
            display: isMultiselectTableDisplayed ? 'block' : 'none',
            position: 'absolute',
            width: '100%',
          }}
        >
          <SearchBar
            onSearch={(val: string) => {
              tableRef.current?.setGlobalFilter(val);
            }}
            searchEvent="onKeyUp"
          />

          <Card>
            <CardHeader
              action={
                <ToggleButtonGroup
                  exclusive
                  onChange={(
                    _: MouseEvent<HTMLElement>,
                    val: FilterSelectionValue
                  ) => {
                    handleSelectionStatusFilterChange(val);
                  }}
                  value={filterBySelectionStatus}
                >
                  <ToggleButton value={FilterSelectionValues.ALL}>
                    <FM
                      values={{ count: allRows.length }}
                      {...messages[FilterSelectionValues.ALL]}
                    />
                  </ToggleButton>
                  <ToggleButton value={FilterSelectionValues.SELECTED}>
                    <FM
                      values={{ count: selectedCount }}
                      {...messages[FilterSelectionValues.SELECTED]}
                    />
                  </ToggleButton>
                  <ToggleButton value={FilterSelectionValues.UNSELECTED}>
                    <FM
                      values={{ count: allRows.length - selectedCount }}
                      {...messages[FilterSelectionValues.UNSELECTED]}
                    />
                  </ToggleButton>
                </ToggleButtonGroup>
              }
              title={
                <Stack direction="row" spacing={space.xs}>
                  <ButtonPrimary
                    onClick={updateOptionsFromTable}
                    startIcon={<IconChevronLeft />}
                  >
                    <FM defaultMessage="Update" />
                  </ButtonPrimary>
                  <ButtonCancel onClick={toggleOptionsTable} />
                </Stack>
              }
            ></CardHeader>

            <CardContent>
              <DataTable
                // @ts-expect-error - accessorFn complaint. See https://github.com/TanStack/table/issues/4241
                columns={columns}
                data={allRows}
                enableColumnSort={true}
                enableLocalSearch={true}
                enableRowSelection={true}
                hiddenRowIds={hiddenRows}
                onRowSelectionChange={handleRowSelectionChange}
                ref={tableRef}
              />
            </CardContent>
          </Card>
        </Stack>
      </Slide>
    </>
  );
};

const messages = defineMessages({
  [FilterSelectionValues.ALL]: { defaultMessage: 'All {count}' },
  [FilterSelectionValues.SELECTED]: { defaultMessage: 'Selected {count}' },
  [FilterSelectionValues.UNSELECTED]: {
    defaultMessage: 'Unselected {count}',
  },
});
