import { Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory } from '@endorlabs/api_client';

import { FlexList, FlexListItem } from '../../../../components';
import { IconCircleFilled } from '../../../../themes';
import { FindingCategoryMessages } from '../../locales';

export const FindingCategoriesAndCWEAsText = ({
  tagList,
  cweList,
}: {
  tagList?: V1FindingCategory[];
  cweList: string[];
}) => {
  const displayList = [tagList, cweList].flat() ?? [];
  return (
    <FlexList
      gap={0}
      divider={
        <IconCircleFilled
          sx={{
            color: 'text.secondary',
            width: 4,
            height: 4,
            margin: '0.5rem',
          }}
        />
      }
    >
      {displayList.map((value, index) => (
        <FlexListItem
          key={value}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption" color="text.secondary">
            {Object.values(V1FindingCategory).includes(
              value as V1FindingCategory
            ) ? (
              <FM {...FindingCategoryMessages[value as V1FindingCategory]} />
            ) : (
              value
            )}
          </Typography>
        </FlexListItem>
      ))}
    </FlexList>
  );
};
