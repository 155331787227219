import { getParser } from 'bowser';

import {
  SpecDeviceScreenInfo,
  SpecDeviceUserAgent,
} from '@endorlabs/api_client';

import { NamedRoutes } from '../../routes/constants';

export const getDeviceScreenInfo = (): SpecDeviceScreenInfo => {
  const availableHeight = window.screen.availHeight;
  const availableWidth = window.screen.availWidth;

  return {
    available_height: availableHeight,
    available_width: availableWidth,
    height: window.innerHeight,
    width: window.innerWidth,
  };
};

export const getDeviceUserAgent = (): SpecDeviceUserAgent => {
  const parser = getParser(window.navigator.userAgent);
  const result = parser.getResult();

  return {
    browser_name: result.browser.name,
    browser_version: result.browser.version,
    os_name: result.os.name,
    os_version: result.os.version,
  };
};

/**
 * Given a location pathname for a route in the App, normalize the route and
 * return the namespace for the tenant.
 */
export const parsePathname = (pathname: string) => {
  let route = pathname
    // remove mongo ObjectIds in route
    .replaceAll(/[0-9a-f]{24}/g, '{uuid}')
    // remove uuids in route
    .replaceAll(/[0-9a-f-]{36}/g, '{uuid}')
    // remove the namespace for tenant routes
    .replace(/^\/t\/([^/]+)/, '/t/{namespace}');

  // strip the trailing slash
  if (route !== '/') {
    route = route.replace(/\/$/, '');
  }

  let namespace: string | undefined;
  // extract namespace from tenant routes
  // ex: `/t/{namespace}/projects/6346f65fe56ebdd657a766c3`
  if (pathname.startsWith(NamedRoutes.TENANTS_INDEX)) {
    const routeParts = pathname.split('/');
    namespace = routeParts[2];
  }

  // default namespace for OSS Explorer
  if (pathname === NamedRoutes.OSS_EXPLORER) {
    namespace = 'oss';
  }

  return { route, namespace };
};
