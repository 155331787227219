import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  SecretRuleServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1SecretRule,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceMutateOptions,
  ResourceQueryOptions,
  SecretRuleResource,
  SecretRuleResourceList,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

interface SecretRuleReadParams {
  namespace: string;
  uuid: string;
}

export interface SecretRuleWriteParams {
  namespace: string;
  resource: V1SecretRule;
}

export interface SecretRuleUpdateParams extends SecretRuleWriteParams {
  mask?: string;
}

type CountSecretRuleOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListSecretRuleOptions = ResourceQueryOptions<SecretRuleResourceList>;
type GetSecretRuleOptions = ResourceQueryOptions<SecretRuleResource>;
type UpsertSecretRuleOptions = ResourceMutateOptions<
  V1SecretRule,
  SecretRuleWriteParams
>;
type DeleteSecretRuleOptions = ResourceMutateOptions<
  object,
  SecretRuleReadParams
>;

const BASE_KEY = 'v1/secretRules';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const SecretRuleQueryKeys = QK;

export const SECRET_RULE_UPDATE_MASK = 'meta,spec';

const getApiService = () => new SecretRuleServiceApi(getClientConfiguration());

const countSecretRules = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.secretRuleServiceListSecretRules(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountSecretRules = (
  namespace: string,
  opts: CountSecretRuleOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SecretRule',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countSecretRules(namespace, requestParameters),
    opts
  );
};

const listSecretRules = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.secretRuleServiceListSecretRules(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as SecretRuleResourceList;
};

export const useListSecretRules = (
  namespace: string,
  opts: ListSecretRuleOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SecretRule',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listSecretRules(namespace, requestParameters),
    opts
  );
};

const getSecretRule = async (namespace: string, secretRuleUuid: string) => {
  const api = getApiService();
  const resp = await api.secretRuleServiceGetSecretRule(
    namespace,
    secretRuleUuid
  );
  return resp.data as SecretRuleResource;
};

export const useGetSecretRule = (
  params: SecretRuleReadParams,
  opts: GetSecretRuleOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getSecretRule(params.namespace, params.uuid),
    opts
  );
};

const createSecretRule = async (
  namespace: string,
  secretRuleBody: V1SecretRule
) => {
  const api = getApiService();
  const resp = await api.secretRuleServiceCreateSecretRule(
    namespace,
    secretRuleBody
  );
  return resp.data as SecretRuleResource;
};

export const useCreateSecretRule = (opts: UpsertSecretRuleOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'SecretRule'),
    mutationFn: (params: SecretRuleWriteParams) =>
      createSecretRule(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateSecretRule = async (params: SecretRuleUpdateParams) => {
  const { resource, namespace, mask = SECRET_RULE_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.secretRuleServiceUpdateSecretRule(namespace, req);
  return resp.data as SecretRuleResource;
};

export const useUpdateSecretRule = (opts: UpsertSecretRuleOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'SecretRule'),
    mutationFn: (params: SecretRuleUpdateParams) => updateSecretRule(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteSecretRule = async (params: SecretRuleReadParams) => {
  const api = getApiService();
  const resp = await api.secretRuleServiceDeleteSecretRule(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteSecretRule = (opts: DeleteSecretRuleOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'SecretRule'),
    mutationFn: (params: SecretRuleReadParams) => deleteSecretRule(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
