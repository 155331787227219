import { Skeleton, Stack, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useIntl } from 'react-intl';

import { Colors, PieChart, PieChartProps } from '@endorlabs/ui-common';

export type BreakdownChartData = {
  key: string;
  label: string;
  value: number;
};

interface DashboardInventoryBreakdownChartProps
  extends Pick<
    PieChartProps<BreakdownChartData>,
    'colorList' | 'onClickHandler'
  > {
  data: BreakdownChartData[];
  isLoading: boolean;
}

export const DashboardInventoryBreakdownChart = ({
  colorList = [Colors.BLUE, Colors.GREEN],
  data,
  isLoading,
  onClickHandler,
}: DashboardInventoryBreakdownChartProps) => {
  const { formatNumber } = useIntl();
  const { space } = useTheme();

  const getValueFormat = (value: string | number) => {
    if ('string' === typeof value) return value;
    return formatNumber(value, {
      notation: 'compact',
    });
  };

  if (isLoading) {
    return (
      <Stack direction="column" spacing={space.xs} alignItems="center">
        <Skeleton variant="circular" height={120} width={120} />
        <Skeleton variant="rounded" height={16} width={64} />
        <Skeleton variant="rounded" height={16} width={64} />
      </Stack>
    );
  }

  return (
    <ParentSize debounceTime={100}>
      {({ width: visWidth }) => (
        <PieChart
          donutThickness={20}
          padAngle={0.05}
          height={180}
          width={visWidth}
          data={data}
          nameKey="label"
          valueKey="value"
          valueFormat={getValueFormat}
          colorList={colorList}
          margin={{ top: 0, left: 0, bottom: 0, right: 0 }}
          tooltips
          legendContainerSize={0.3}
          legendGap={1}
          legendIconShape="pill"
          legendPosition="bottom"
          onClickHandler={onClickHandler}
          showTotalValue
        />
      )}
    </ParentSize>
  );
};
