import { get as _get, set as _set } from 'lodash-es';
import {
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  UserTelemetryServiceApi,
  V1ListParameters,
  V1UserTelemetry,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ListRequestParameters } from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceListResponse,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildListParamArgs,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

interface CreateUserTelemetryParams {
  namespace: string;
  resource: V1UserTelemetry;
}

interface UpdateUserTelemetryParams {
  mask?: string;
  namespace: string;
  resource: V1UserTelemetry;
}

type ListUserTelemetriesOptions = ResourceQueryOptions<
  ResourceListResponse<V1UserTelemetry>
>;

const BASE_KEY = 'v1/user-telemetries';
const QK = {
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
};

export const UserTelementryQueryKeys = QK;

export const USER_TELEMETRY_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new UserTelemetryServiceApi(getClientConfiguration());

const createUserTelemetry = async (params: CreateUserTelemetryParams) => {
  const api = getApiService();
  const resp = await api.userTelemetryServiceCreateUserTelemetry(
    params.namespace,
    params.resource
  );

  return resp.data as V1UserTelemetry;
};

export const useCreateUserTelemetry = (
  options: ResourceMutateOptions<
    V1UserTelemetry,
    CreateUserTelemetryParams
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (params) => createUserTelemetry(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (options.onSettled) {
        options.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateUserTelemetry = async (params: UpdateUserTelemetryParams) => {
  const { mask = USER_TELEMETRY_UPDATE_MASK, namespace, resource } = params;

  const api = getApiService();
  const req = buildUpdateReq(resource, mask);
  const resp = await api.userTelemetryServiceUpdateUserTelemetry(
    namespace,
    req
  );
  return resp.data as V1UserTelemetry;
};

export const useUpdateUserTelemetry = (
  options: ResourceMutateOptions<
    V1UserTelemetry,
    UpdateUserTelemetryParams
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...options,
    mutationFn: (params) => updateUserTelemetry(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (options.onSettled) {
        options.onSettled(data, error, vars, context);
      }
    },
  });
};

const listUserTelemetries = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.userTelemetryServiceListUserTelemetries(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data.list as ResourceListResponse<V1UserTelemetry>;
};

export const useListUserTelemetries = (
  namespace: string,
  listParams?: ListRequestParameters,
  options: ListUserTelemetriesOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'UserTelemetry',
    'LIST',
    listParams,
    options
  );

  return useQuery({
    queryKey: QK.list(namespace, requestParameters),
    queryFn: () => listUserTelemetries(namespace, requestParameters),
    ...options,
  });
};

export const useFindManyUserTelemetries = (
  namespaces: string[],
  listParams?: ListRequestParameters,
  options: ListUserTelemetriesOptions = {}
) => {
  const baseRequestParameters = useBuildReadRequestParameters(
    'UserTelemetry',
    'LIST',
    listParams,
    options
  );

  const queries = namespaces.map((namespace) => {
    const requestParameters: V1ListParameters = {
      // default to not traverse into the tenant namespaces
      traverse: false,
      ...baseRequestParameters,
    };

    return {
      queryKey: QK.list(namespace, requestParameters),
      queryFn: () => listUserTelemetries(namespace, requestParameters),
      ...options,
    } as ListUserTelemetriesOptions;
  });

  const results = useQueries(queries);

  const isFetching = results.some((r) => r.isFetching);
  const isLoading = !!results.length && results.every((r) => r.isLoading);
  const isSuccess = results.some((r) => r.isSuccess);

  // TODO: better memoize data directly from from useQueries calls
  // see: https://github.com/TanStack/query/discussions/5123
  const data = results.flatMap((r) => r.data?.objects ?? []);

  return { data, isFetching, isLoading, isSuccess };
};
