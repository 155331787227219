import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate, useRouter } from '@tanstack/react-location';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';
import { TenantType } from '@endorlabs/endor-core/auth';
import { useAuthentication, useSession } from '@endorlabs/queries';
import { useListEndorLicenses } from '@endorlabs/queries/licenses';
import {
  AppNotificationContainer,
  ButtonLinkPrimary,
  ButtonPrimary,
  ButtonSecondary,
  EmptyState,
  IconExternalLink,
} from '@endorlabs/ui-common';
import endorLogo from '@endorlabs/ui-common/assets/logos/logo-endor-primary-grey.svg';

import { getEnv } from '../../constants';
import {
  FormUpsertInstallation,
  FormUpsertInstallationProps,
} from '../../domains/Installations/components/FormUpsertInstallation';
import { installStep } from '../../domains/Installations/constants';
import { NamespaceSwitcher } from '../../domains/Namespace';
import {
  OnboardingApproaches,
  useOnboardingSteps,
} from '../../domains/Onboarding';
import { AuthInfoProvider } from '../../providers';
import { getDashboardPath, getOnboardRootPath } from '../../routes';
import { NamedRoutes } from '../../routes/constants';

/**
 * This page is outside of any namespace.
 * This page has a very unique, GitHub-specific purpose. Typically, users arriving here are new.
 *
 * The backend redirects to this page upon successful authorization of the Endor GitHub application.
 * Backend does not provide any additional information, so we're starting from scratch.
 * The goal is to create an Installation associating the GH app authorization with a specific namespace.
 * Namespace/config are the key information here. GH application ID is set in a cookie. Backend does the rest.

 * The steps are:
 *
 * 1. Verify that the user is authenticated
 * 2. Make the user select a namespace for the Installation
 * 3. Make the user choose GH-specific Installation config options.
 * 4. Create the Installation
 *   - On success: Send user to their new installation page
 *   - On failure: Display context-specific error messages
 */
export const InstallSuccessPage = () => {
  const { isAuthenticated, isLoading: isLoadingAuthentication } =
    useAuthentication();
  const navigate = useNavigate();
  const { getUserTenants, getUserNamespaces } = useSession();
  const [selectedNamespace, setSelectedNamespace] = useState<
    string | undefined
  >();
  const { space, palette } = useTheme();
  const userNamespaces = getUserNamespaces();
  const userTenants = getUserTenants();

  const router = useRouter();

  const ENV = getEnv();
  const DOCS_ROOT = ENV.URL_ENDOR_DOCS;

  /** Licensing and Bundling code */
  const [stepId, setStepId] = useState<number>(0);

  const qTenantLicenses = useListEndorLicenses(
    { namespace: selectedNamespace! },
    {
      enabled: !!selectedNamespace,
    }
  );

  const handleSelectNamespace = () => {
    setStepId(1);
    setSelectedNamespace(selectedNamespace);
  };

  // Remove shared tenants or expired trial tenants from the listed options
  const filteredTenants = useMemo(() => {
    return userTenants.filter(
      (t) =>
        t.tenantType === TenantType.Premium || t.tenantType === TenantType.Trial
    );
  }, [userTenants]);

  const isEmptyState =
    !isLoadingAuthentication &&
    filteredTenants.length === 0 &&
    getUserNamespaces().length === 0;

  const { getCurrentApproach } = useOnboardingSteps();

  const defaultRedirectPath = useMemo(() => {
    if (selectedNamespace) {
      if (getCurrentApproach() === OnboardingApproaches.GITHUB_APP) {
        return getOnboardRootPath({ tenantName: selectedNamespace });
      }
      return getDashboardPath({ tenantName: selectedNamespace });
    }
    return NamedRoutes.TENANTS_INDEX;
  }, [getCurrentApproach, selectedNamespace]);

  const handleSuccess: FormUpsertInstallationProps['onSuccess'] = () => {
    setTimeout(() => navigate({ to: defaultRedirectPath }), 1500);
  };

  let content = (
    <EmptyState
      size="large"
      title={<FM defaultMessage="Retrieving GitHub application" />}
    >
      <CircularProgress />
    </EmptyState>
  );

  if (!isAuthenticated) {
    content = (
      <EmptyState
        size="large"
        title={
          <FM defaultMessage="Not Authenticated. Please login and try again" />
        }
      >
        <ButtonLinkPrimary linkProps={{ to: NamedRoutes.LOGIN }}>
          <FM defaultMessage="Go to Login" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  if (isEmptyState) {
    content = (
      <EmptyState
        size="large"
        title={<FM defaultMessage="Unable to install GitHub application" />}
        description={
          <FM defaultMessage="No authorized namespaces found. Please contact Endor Labs for support." />
        }
      />
    );
  }

  if (!isLoadingAuthentication && isAuthenticated && !isEmptyState) {
    content = (
      <Stack rowGap={space.md}>
        <Typography variant="h1" sx={{ textAlign: 'center' }}>
          <FM defaultMessage="Installing GitHub application" />
        </Typography>

        <Stepper activeStep={stepId} alternativeLabel>
          {installStep.map((label, index) => (
            <Step
              key={label}
              sx={{
                padding:
                  index % 2 === 0 ? '0px 1.5rem 0px 0px' : '0px 0px 0px 1.5rem',
                '& svg': {
                  width: '1.5rem',
                  height: '1.5rem',
                },
              }}
            >
              <StepLabel>
                <Typography variant="h4">{label}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {stepId === 0 && (
          <>
            <Stack direction="column" spacing={space.xs}>
              <Typography>
                <FM
                  defaultMessage={
                    'Please select a namespace for the GitHub Application'
                  }
                />
              </Typography>

              <Button
                href={`${DOCS_ROOT}/administration/namespaces/`}
                startIcon={<IconExternalLink />}
                target="_blank"
              >
                <FM defaultMessage="Learn more about namespaces" />
              </Button>
            </Stack>

            <Paper
              sx={{
                '.NamespaceSwitcherContent-root': {
                  border: `1px solid ${palette.divider}`,
                  borderRadius: '4px',
                },
              }}
            >
              <NamespaceSwitcher
                activeNamespace={selectedNamespace ?? filteredTenants[0].name}
                disableEmptyState
                hideHeaderContent
                namespaces={userNamespaces}
                onSelect={(_, namespace) => setSelectedNamespace(namespace)}
                tenants={filteredTenants}
                selectedNamespace={selectedNamespace}
              />
            </Paper>

            <Box>
              <ButtonPrimary
                disabled={!selectedNamespace}
                onClick={handleSelectNamespace}
              >
                <FM defaultMessage="Next" />
              </ButtonPrimary>
            </Box>
          </>
        )}

        {stepId === 1 && (
          <Card sx={{ width: '100%' }}>
            <CardContent sx={{ minWidth: '30%' }}>
              <FormUpsertInstallation
                installationPlatformType={V1PlatformSource.Github}
                licenses={qTenantLicenses.data?.list?.objects}
                namespace={selectedNamespace}
                onSuccess={handleSuccess}
                secondaryButtons={
                  <ButtonSecondary onClick={() => setStepId(0)}>
                    <FM defaultMessage="Previous" />
                  </ButtonSecondary>
                }
              />
            </CardContent>
          </Card>
        )}
      </Stack>
    );
  }

  return (
    <AuthInfoProvider>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', paddingTop: space.lg }}
      >
        <Stack alignItems="center" spacing={space.md} width="25%">
          <img alt="Endor Labs" src={endorLogo} height="60" />

          {content}
        </Stack>
      </Box>

      <AppNotificationContainer
        resetKeys={[
          // reset app notifications on route change
          router.state.location.pathname,
        ]}
      />
    </AuthInfoProvider>
  );
};
