import { Box, Stack, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useMemo } from 'react';

import { DependencyMetadataReachabilityType } from '@endorlabs/api_client';
import {
  BarChart,
  Color,
  PackageVersionDependency,
  PieChart,
} from '@endorlabs/ui-common';

interface ProjectVersionDependenciesProps {
  dependencies?: PackageVersionDependency[];
  height: number;
}
export const ProjectVersionDependencies = ({
  dependencies,
  height,
}: ProjectVersionDependenciesProps) => {
  const { palette, space } = useTheme();

  const [typeCount, reachabilityCount] = useMemo(() => {
    const typeCounts: { name: string; count: number }[] = [
      { name: 'Direct', count: 0 },
      { name: 'Transitive', count: 0 },
    ];
    const reachabilityCounts: { name: string; count: number }[] = [
      { name: 'Reachable', count: 0 },
      { name: 'Potentially Reachable', count: 0 },
      { name: 'Unreachable', count: 0 },
    ];
    if (dependencies) {
      dependencies.forEach((item) => {
        const typeValue = item.isDirectDependency;
        const reachabilityValue = item.reachability;

        if (typeValue === true) {
          typeCounts[0].count++;
        } else {
          typeCounts[1].count++;
        }

        switch (reachabilityValue) {
          case DependencyMetadataReachabilityType.Reachable:
            reachabilityCounts[0].count++;
            break;
          case DependencyMetadataReachabilityType.Unreachable:
            reachabilityCounts[2].count++;
            break;
          default:
            reachabilityCounts[2].count++;
            break;
        }
      });
    }
    return [typeCounts, reachabilityCounts];
  }, [dependencies]);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      position="relative"
      gap={space.lg}
    >
      <Box position="relative" width="45%">
        <ParentSize debounceTime={100}>
          {({ width: visWidth }) => (
            <PieChart
              width={visWidth}
              height={height}
              data={typeCount ?? []}
              nameKey="name"
              valueKey="count"
              colorList={[palette.brand.main, palette.brand.light] as Color[]}
              margin={{ top: 20, left: 0, bottom: 4, right: 0 }}
            />
          )}
        </ParentSize>
      </Box>

      <Box position="relative" width="55%">
        <ParentSize debounceTime={100}>
          {({ width: visWidth }) => (
            <BarChart
              width={visWidth}
              height={height}
              data={reachabilityCount ?? []}
              xKey="name"
              yKey="count"
              axisLeft={false}
              axisBottom={false}
              barPadding={0.4}
              showGrid={false}
              barColors={
                [
                  palette.text.primary,
                  palette.text.secondary,
                  palette.text.disabled,
                ] as Color[]
              }
              labelColor={palette.text.primary as Color}
              margin={{ top: 20, left: 0, bottom: 4, right: 0 }}
              showLegends={true}
              legendPosition="right"
              legendContainerSize={0.5}
              legendJustify="end"
            />
          )}
        </ParentSize>
      </Box>
    </Stack>
  );
};
