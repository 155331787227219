import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  APIKeyServiceApi,
  V1APIKey,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { invalidateListQueries } from './client';
import { useBuildReadRequestParameters } from './hooks';
import {
  ApiKeyResource,
  ApiKeyResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  getClientConfiguration,
} from './utils';

export interface ApiKeyReadParams {
  namespace: string;
  uuid: string;
}

export interface ApiKeyWriteParams {
  namespace: string;
  resource: V1APIKey;
}

export type CountApiKeyOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
export type ListApiKeyOptions = ResourceQueryOptions<ApiKeyResourceList>;
export type GetApiKeyOptions = ResourceQueryOptions<ApiKeyResource>;
export type UpsertApiKeyOptions = ResourceMutateOptions<
  V1APIKey,
  ApiKeyWriteParams
>;
export type DeleteApiKeyOptions = ResourceMutateOptions<
  object,
  ApiKeyReadParams
>;

const BASE_KEY = 'v1/api-keys';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const ApiKeyQueryKeys = QK;

const getApiService = () => new APIKeyServiceApi(getClientConfiguration());

const countApiKeys = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.aPIKeyServiceListAPIKeys(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountApiKeys = (
  namespace: string,
  countParams: CountRequestParameters = {},
  opts: CountApiKeyOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'APIKey',
    'COUNT',
    countParams,
    opts
  );

  return useQuery({
    ...opts,
    queryKey: QK.count(namespace, requestParameters),
    queryFn: () => countApiKeys(namespace, requestParameters),
  });
};

const listApiKeys = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.aPIKeyServiceListAPIKeys(
    namespace,
    ...buildListParamArgs(listParams)
  );

  return resp.data as ApiKeyResourceList;
};

export const useListApiKeys = (
  namespace: string,
  listParams: ListRequestParameters = {},
  opts: ListApiKeyOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'APIKey',
    'LIST',
    listParams,
    opts
  );

  return useQuery({
    ...opts,
    queryKey: QK.list(namespace, requestParameters),
    queryFn: () => listApiKeys(namespace, requestParameters),
  });
};

const getApiKey = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.aPIKeyServiceGetAPIKey(namespace, uuid);
  return resp.data as ApiKeyResource;
};

export const useGetApiKey = (
  params: ApiKeyReadParams,
  opts: GetApiKeyOptions = {}
) => {
  return useQuery({
    ...opts,
    queryKey: QK.record(params.namespace, params.uuid),
    queryFn: () => getApiKey(params.namespace, params.uuid),
  });
};

const createApiKey = async (params: ApiKeyWriteParams) => {
  const api = getApiService();
  const resp = await api.aPIKeyServiceCreateAPIKey(
    params.namespace,
    params.resource
  );
  return resp.data as ApiKeyResource;
};

export const useCreateApiKey = (opts: UpsertApiKeyOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'APIKey'),
    mutationFn: (params: ApiKeyWriteParams) => createApiKey(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteApiKey = async (params: ApiKeyReadParams) => {
  const api = getApiService();
  const resp = await api.aPIKeyServiceDeleteAPIKey(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteApiKey = (opts: DeleteApiKeyOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'APIKey'),
    mutationFn: (params: ApiKeyReadParams) => deleteApiKey(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          includeChildren: true,
          includePropagated: true,
        });
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
