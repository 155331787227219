import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';
import { AttributeDisplay, RelativeTimeDisplay } from '@endorlabs/ui-common';

import {
  DetailDrawerDivider,
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import {
  AssuredPackageVersionOverviewAttributes,
  AssuredPackageVersionPatchAttestations,
} from '../../../AssuredPackageVersion';
import { RemediationDetails } from '../RemediationDetails';
import { RemediationOverviewStats } from '../RemediationOverviewStats';
import { RemediationRiskDrivers } from '../RemediationRiskDrivers';
import { RemediationsDetailDrawerFixedFindings } from './RemediationsDetailDrawerFixedFindings';

export const RemediationDetailDrawerOverview = ({
  assuredPackageVersion,
  isLoading,
  versionUpgrade,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
  isLoading?: boolean;
  versionUpgrade?: VersionUpgradeResource;
}) => {
  const { space } = useTheme();

  const upgradeInfo = versionUpgrade?.spec?.upgrade_info;
  if (!upgradeInfo) return null;

  return (
    <DetailDrawerSectionStack divider={<DetailDrawerDivider fullWidth />}>
      <DetailDrawerSection id="remediation-overview">
        <Stack gap={space.sm} paddingTop={space.sm}>
          <RemediationOverviewStats upgradeInfo={upgradeInfo} />

          {assuredPackageVersion && (
            <AssuredPackageVersionOverviewAttributes
              assuredPackageVersion={assuredPackageVersion}
              isLoading={isLoading}
            />
          )}

          {!assuredPackageVersion && (
            // When not from an Endor Patch, show last scanned for the Version Upgrade
            <AttributeDisplay
              heading={<FM defaultMessage="Last Scanned" />}
              value={
                versionUpgrade?.meta.update_time && (
                  <RelativeTimeDisplay
                    value={versionUpgrade.meta.update_time}
                    formatStyle="long"
                  />
                )
              }
            />
          )}
        </Stack>
      </DetailDrawerSection>

      <DetailDrawerSection
        id="remediation-risk"
        title={<FM defaultMessage="Remediation Risk Drivers" />}
      >
        <RemediationRiskDrivers upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>

      <DetailDrawerSection
        id="remediation-details"
        title={<FM defaultMessage="Details" />}
      >
        <RemediationDetails upgradeInfo={upgradeInfo} />
      </DetailDrawerSection>

      {assuredPackageVersion && (
        <DetailDrawerSection
          id="patch-attestations"
          title={<FM defaultMessage="Patch Attestations" />}
        >
          <AssuredPackageVersionPatchAttestations
            assuredPackageVersion={assuredPackageVersion}
          />
        </DetailDrawerSection>
      )}

      <RemediationsDetailDrawerFixedFindings upgradeInfo={upgradeInfo} />
    </DetailDrawerSectionStack>
  );
};
