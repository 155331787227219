import { Alert, Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import { ButtonLinkPrimary, ButtonLinkSecondary } from '@endorlabs/ui-common';

import {
  useOnboardingSteps,
  useOnboardingUserEventTracking,
} from '../../domains/Onboarding';
import { useAuthInfo } from '../../providers';
import { getFindingsPath } from '../../routes';
import { OnboardingStepContentProps } from './types';

export const ViewScanResultsChildren = ({
  approach,
  stepRecord,
}: OnboardingStepContentProps) => {
  const {
    getUncompletedPrequisiteStepNumbers,
    completeStep,
    getIsStepComplete,
  } = useOnboardingSteps((state) => state);
  const { space } = useTheme();
  const isStepComplete = getIsStepComplete(stepRecord.id);

  const missingPrereqNumbers = getUncompletedPrequisiteStepNumbers(
    stepRecord.id,
    approach
  ).map((i) => `Step ${i + 1}`);

  const missingList = <FormattedList value={missingPrereqNumbers} />;

  const { activeNamespace: tenantName } = useAuthInfo();
  const { trackOnboardingUserEvent } = useOnboardingUserEventTracking();

  const onClick = () => {
    if (!isStepComplete) {
      completeStep(stepRecord.id);
      trackOnboardingUserEvent('SET_REACHABILITY_FILTER', 'INITIATED');
    }
  };

  return (
    <Stack spacing={space.md}>
      <Typography color="textSecondary">
        <FM
          defaultMessage="Click on <b>Findings</b> on the left sidebar to review the findings, or you can click <b>Go to Findings</b> below."
          values={{
            b: (v) => <b>{v}</b>,
          }}
        />
      </Typography>

      {missingPrereqNumbers.length > 0 && (
        <Alert
          severity="info"
          sx={{ '& .MuiAlert-icon': { color: 'text.link' } }}
        >
          <Typography color="text.link">
            <FM
              defaultMessage="You must complete {list} before you can view or filter the project findings."
              values={{ list: missingList }}
            />
          </Typography>
        </Alert>
      )}

      <Box>
        {isStepComplete && (
          <ButtonLinkSecondary
            variant="outlined"
            onClick={onClick}
            linkProps={{
              to: getFindingsPath({
                tenantName,
              }),
            }}
          >
            <FM defaultMessage="Revisit Findings" />
          </ButtonLinkSecondary>
        )}

        {!isStepComplete && (
          <ButtonLinkPrimary
            linkProps={{
              to: getFindingsPath({
                tenantName,
              }),
            }}
            onClick={onClick}
          >
            <FM defaultMessage="Go to Findings" />
          </ButtonLinkPrimary>
        )}
      </Box>
    </Stack>
  );
};
