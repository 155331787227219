/**
 * Icon based on {@link ./Verified.tsx}
 *
 * Currently, stroke and fill color are hard coded based on design
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type RevokedProps = {
  color?: string;
  size?: string | number;
};

const Revoked = forwardRef<SVGSVGElement, RevokedProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M 8.5 3.5 Q 12 0 15.5 3.5 Q 20.5 3.5 20.5 8.5 Q 24 12 20.5 15.5 Q 20.5 20.5 15.5 20.5 Q 12 24 8.5 20.5 Q 3.5 20.5 3.5 15.5 Q 0 12 3.5 8.5 Q 3.5 3.5 8.5 3.5 Z"
          fill="hsla(25, 100%, 90%, 1)"
          stroke="hsla(25, 100%, 62%, 1)"
        />

        <path d="M15.5 8.5L8.5 15.5" stroke="hsla(25, 100%, 62%, 1)" />
        <path d="M8.5 8.5L15.5 15.5" stroke="hsla(25, 100%, 62%, 1)" />
      </svg>
    );
  }
);

Revoked.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Revoked.displayName = 'Revoked';

export default Revoked;
