import { Box, Stack, Typography } from '@mui/material';

import {
  PackageIconDisplay,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

export const DependencyDetailDrawerHeader = ({
  dependencyPackageVersionName,
}: {
  dependencyPackageVersionName: string;
}) => {
  const {
    ecosystem,
    label: packageName,
    version,
  } = UIPackageVersionUtils.parsePackageName(dependencyPackageVersionName);

  return (
    <RowStack>
      <PackageIconDisplay
        ecosystem={ecosystem}
        displayDefault="package"
        size="medium"
        withBorder
      />
      <Stack direction="column">
        <Typography component="span" variant="h6">
          {packageName}
        </Typography>

        <Typography component="span" variant="caption">
          {version}
        </Typography>
      </Stack>
    </RowStack>
  );
};
