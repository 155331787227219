import { Skeleton, Stack } from '@mui/material';
import { range as _range } from 'lodash-es';

export const GraphPathListSkeleton = () => {
  const items = _range(0, 4);

  return (
    <Stack gap={3}>
      {items.map((_, i) => {
        return (
          <Stack direction="row" gap={3} key={i}>
            <Skeleton height={24} width={16} />
            <Skeleton height={24} width="80%" />
          </Stack>
        );
      })}
    </Stack>
  );
};
