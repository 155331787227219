import produce from 'immer';
import { useEffect } from 'react';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

interface MultiselectStore {
  isTableDisplayed: boolean;
}

export interface ControlledTableMultiselectStore {
  multiselectRegistry: Record<string, MultiselectStore>;
  registerMultiselect: (key: string) => void;
  reset: () => void;
  setTargetElement: (el: HTMLElement) => void;
  targetElement?: HTMLElement;
  toggleTable: (key: string) => void;
}

const useControlledTableMultiselectStore = create(
  subscribeWithSelector<ControlledTableMultiselectStore>((setState) => {
    return {
      multiselectRegistry: {},

      registerMultiselect: (key) =>
        setState((state) =>
          produce(state, (draft) => {
            draft.multiselectRegistry[key] = { isTableDisplayed: false };
          })
        ),

      reset: () =>
        setState({
          targetElement: undefined,
          multiselectRegistry: {},
        }),

      setTargetElement: (el) =>
        setState((state) =>
          produce(state, (draft) => {
            // @ts-expect-error - immer does not seem to like draft HTML elements
            draft.targetElement = el;
          })
        ),

      toggleTable: (key: string) =>
        setState((state) =>
          produce(state, (draft) => {
            const reg = draft.multiselectRegistry[key];
            if (reg) {
              reg.isTableDisplayed = !reg.isTableDisplayed;
            }
          })
        ),
    };
  })
);

interface UseControlledTableMultiselectProps {
  fieldName?: string;
  targetElement?: HTMLElement;
}

/**
 * Allows multiple ControlledTableMultiselect components to register with a global store,
 * and for an ancestor element to register an element reference that will be used by all
 * registered multiselects for positioning (allows table to transition in sync with the ancestor).
 *
 * EXAMPLE:
 *
 * const FormComponent = () => {
 *  const formEl = useRef(null);
 *  const { isAnyMultiselectTableDisplayed } = useControlledTableMultiselect({
 *    targetElement: formEl.current
 *  });
 *
 *  return (
 *    <div ref={formEl}>)
 *       <FieldComponent fieldName="myFieldName" />
 *    </div>
 *  );
 * }
 *
 * const FieldComponent = ({ fieldName }) => {
 *   const { toggleMultiselectTable } = useControlledTableMultiselect({ fieldName });
 *
 *  return (
 *    <ControlledTableMultiselect fieldName={fieldName} />
 *  )
 * }
 */
export function useControlledTableMultiselect({
  fieldName,
  targetElement,
}: UseControlledTableMultiselectProps) {
  const {
    multiselectRegistry,
    registerMultiselect,
    reset,
    setTargetElement,
    targetElement: targetElementFromState,
    toggleTable,
  } = useControlledTableMultiselectStore();

  useEffect(() => {
    if (targetElement && !targetElementFromState) {
      setTargetElement(targetElement);
    }

    if (fieldName && !multiselectRegistry[fieldName]) {
      registerMultiselect(fieldName);
    }
  }, [
    fieldName,
    multiselectRegistry,
    registerMultiselect,
    setTargetElement,
    targetElement,
    targetElementFromState,
  ]);

  return {
    isAnyMultiselectTableDisplayed: Object.values(multiselectRegistry).some(
      (reg) => reg.isTableDisplayed
    ),

    isMultiselectTableDisplayed: fieldName
      ? multiselectRegistry[fieldName]?.isTableDisplayed ?? false
      : false,

    reset: () => reset(),

    targetElement: targetElementFromState,

    toggleMultiselectTable: (fieldName: string) => {
      toggleTable(fieldName);
    },
  };
}
