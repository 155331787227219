import { IconButton, Typography } from '@mui/material';
import { clamp } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';

import {
  IconChevronLeft,
  IconChevronRight,
  RowStack,
} from '@endorlabs/ui-common';

import {
  DetailDrawerSection,
  DetailDrawerSectionProps,
} from './DetailDrawerSection';

const messages = defineMessages({
  next: { defaultMessage: 'Next' },
  previous: { defaultMessage: 'Previous' },
  summary: {
    defaultMessage:
      '{current, number, ::compact-short}{showPageCount, select, true {-{pageCount}} other {}} of {totalCount, number, ::compact-short}',
  },
});

type DetailDrawerPaginatedSectionProps = Pick<
  DetailDrawerSectionProps,
  'children' | 'disableGutters' | 'title'
> & {
  headerProps?: Omit<
    DetailDrawerSectionProps['headerProps'],
    'action' | 'title'
  >;
  isLoading?: boolean;
  onPaginationChange: (index: number) => void;
  paginatorProps: {
    index: number;
    pageSize?: number;
    totalCount: number;
  };
};

/**
 * Extends {@see DetailDrawerSection} to support paginated sections
 */
export const DetailDrawerPaginatedSection = ({
  children,
  disableGutters,
  headerProps,
  isLoading,
  onPaginationChange,
  paginatorProps,
  title,
}: DetailDrawerPaginatedSectionProps) => {
  const { formatMessage: fm } = useIntl();

  const { index, pageSize = 1, totalCount } = paginatorProps;
  const pageCount = clamp(index + pageSize, 0, totalCount);
  const showControls = !isLoading && totalCount > 1;

  return (
    <DetailDrawerSection
      disableGutters={disableGutters}
      headerProps={{
        variant: 'h3',
        ...headerProps,
        title,
        action: showControls && (
          <RowStack gap={1}>
            <IconButton
              aria-label={fm(messages.previous)}
              disabled={index === 0}
              onClick={() => onPaginationChange(index - pageSize)}
            >
              <IconChevronLeft />
            </IconButton>
            <Typography component="span" variant="body2">
              {fm(messages.summary, {
                current: index + 1,
                totalCount,
                pageCount,
                showPageCount: pageSize > 1 && index < pageCount - 1,
              })}
            </Typography>
            <IconButton
              aria-label={fm(messages.next)}
              disabled={index === totalCount - 1 || pageCount === totalCount}
              onClick={() => onPaginationChange(index + pageSize)}
            >
              <IconChevronRight />
            </IconButton>
          </RowStack>
        ),
      }}
    >
      {children}
    </DetailDrawerSection>
  );
};
