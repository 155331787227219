import { ResourceFilterDefinition } from './types';

export interface FilterComparatorDefinition {
  comparator: FilterComparator;
  name: string;
}

/**
 * All Filter Comparator Values
 *
 * based on {@link github.com/endorlabs/monorepo/src/golang/internal.endor.ai/pkg/filter},
 * with additional utility filters added for array and boolean types
 */
export type FilterComparator =
  | FilterComparatorSingleValue
  | FilterComparatorMultipleValue
  | FilterComparatorWithoutValue;

/**
 * Comparators that use a single value
 */
export type FilterComparatorSingleValue =
  | 'EQUAL'
  | 'NOT_EQUAL'
  | 'MATCHES' // NOTE: match _can_ take multiple values as well
  | 'GREATER'
  | 'GREATER_OR_EQUAL'
  | 'LESSER'
  | 'LESSER_OR_EQUAL';

export const FILTER_COMPARATORS_SINGLE_VALUE: Readonly<{
  [K in FilterComparatorSingleValue]: K;
}> = {
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  MATCHES: 'MATCHES',
  GREATER: 'GREATER',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  LESSER: 'LESSER',
  LESSER_OR_EQUAL: 'LESSER_OR_EQUAL',
};

/**
 * Comparators that use multiple values
 */
export type FilterComparatorMultipleValue =
  | 'IN'
  | 'NOT_IN'
  | 'CONTAINS'
  | 'NOT_CONTAINS'
  /** @deprecated this comparator is not supported in the Endor Labs API and will be removed in the future */
  | 'CONTAINS_ALL'
  /** @deprecated this comparator is not supported in the Endor Labs API and will be removed in the future */
  | 'WITHIN_RANGE';

export const FILTER_COMPARATORS_MULTIPLE_VALUE: Readonly<{
  [K in FilterComparatorMultipleValue]: K;
}> = {
  IN: 'IN',
  NOT_IN: 'NOT_IN',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  /** @deprecated this comparator is not supported in the Endor Labs API and will be removed in the future */
  CONTAINS_ALL: 'CONTAINS_ALL',
  /** @deprecated this comparator is not supported in the Endor Labs API and will be removed in the future */
  WITHIN_RANGE: 'WITHIN_RANGE',
};

/**
 * Comparators that do not use a value
 */
export type FilterComparatorWithoutValue = 'EXISTS' | 'NOT_EXISTS';

export const FILTER_COMPARATORS_WITHOUT_VALUE: Readonly<{
  [K in FilterComparatorWithoutValue]: K;
}> = {
  EXISTS: 'EXISTS',
  NOT_EXISTS: 'NOT_EXISTS',
};

export const FILTER_COMPARATORS = {
  ...FILTER_COMPARATORS_SINGLE_VALUE,
  ...FILTER_COMPARATORS_MULTIPLE_VALUE,
  ...FILTER_COMPARATORS_WITHOUT_VALUE,
} as const;

/**
 * Filter Comparator names, by Filter Comparator type
 */
const FILTER_COMPARATOR_NAMES: Record<FilterComparator, string> = {
  EQUAL: 'equals',
  NOT_EQUAL: 'does not equal',
  MATCHES: 'matches',
  GREATER: 'greater than',
  GREATER_OR_EQUAL: 'greater than or equal to',
  LESSER: 'less than',
  LESSER_OR_EQUAL: 'less than or equal to',
  IN: 'in',
  NOT_IN: 'not in',
  CONTAINS: 'contains',
  CONTAINS_ALL: 'contains all',
  NOT_CONTAINS: 'does not contain',
  EXISTS: 'exists',
  NOT_EXISTS: 'does not exist',
  WITHIN_RANGE: 'within range',
};

export const getFilterComparatorDefinition = (
  comparator: FilterComparator
): FilterComparatorDefinition => {
  return { comparator, name: FILTER_COMPARATOR_NAMES[comparator] };
};

export const getFilterComparatorDefinitions = (
  comparators: FilterComparator[]
): FilterComparatorDefinition[] => {
  return comparators.map(getFilterComparatorDefinition);
};

const VALUE_COMPARATOR_DEFINITIONS: FilterComparatorDefinition[] =
  getFilterComparatorDefinitions([
    FILTER_COMPARATORS.EQUAL,
    FILTER_COMPARATORS.NOT_EQUAL,
    FILTER_COMPARATORS.GREATER,
    FILTER_COMPARATORS.GREATER_OR_EQUAL,
    FILTER_COMPARATORS.LESSER,
    FILTER_COMPARATORS.LESSER_OR_EQUAL,
    FILTER_COMPARATORS.EXISTS,
    FILTER_COMPARATORS.NOT_EXISTS,
    FILTER_COMPARATORS.IN,
    FILTER_COMPARATORS.NOT_IN,
  ]);

const STRING_COMPARATOR_DEFINITIONS: FilterComparatorDefinition[] = [
  ...getFilterComparatorDefinitions([FILTER_COMPARATORS.MATCHES]),
  ...VALUE_COMPARATOR_DEFINITIONS,
];

const BOOLEAN_COMPARATOR_DEFINITIONS: FilterComparatorDefinition[] =
  getFilterComparatorDefinitions([
    FILTER_COMPARATORS.EQUAL,
    FILTER_COMPARATORS.NOT_EQUAL,
  ]);

const ARRAY_COMPARATOR_DEFINITIONS: FilterComparatorDefinition[] =
  getFilterComparatorDefinitions([
    FILTER_COMPARATORS.MATCHES,
    FILTER_COMPARATORS.EQUAL,
    FILTER_COMPARATORS.NOT_EQUAL,
    FILTER_COMPARATORS.CONTAINS,
    FILTER_COMPARATORS.NOT_CONTAINS,
  ]);

/**
 * Get the list of filter comparator definitions for given filter definition,
 * based on the json-schema `type` of the attribute
 */
export const getFilterComparatorsForFilterDefinition = (
  definition: ResourceFilterDefinition
): FilterComparatorDefinition[] => {
  switch (definition.type) {
    case 'number':
    case 'integer':
      return VALUE_COMPARATOR_DEFINITIONS;
    case 'string':
      return STRING_COMPARATOR_DEFINITIONS;
    case 'array':
      return ARRAY_COMPARATOR_DEFINITIONS;
    case 'boolean':
      return BOOLEAN_COMPARATOR_DEFINITIONS;
  }

  // TODO: handle default case, 'object', and 'null'
  return [];
};
