import {
  LoaderData,
  Route,
  RouteMatch,
  RouteMeta,
} from '@tanstack/react-location';
import { head as _head } from 'lodash-es';
import { JSXElementConstructor } from 'react';

import { WithRequired } from '@endorlabs/utils';

import { RootRouteContext, RouteContext } from './types';

const FILE_ROUTE_SHIM_SYMBOL = Symbol('FILE_ROUTE_SHIM');

export interface FileRouteShim extends WithRequired<Route, 'path'> {
  [FILE_ROUTE_SHIM_SYMBOL]: undefined;
}

export type FileRouteOptions = {
  /**
   * @deprecated for child routes, use instead `withRouteChildren`
   */
  children?: Route[];
  component?: JSXElementConstructor<unknown>;
  /**
   * @deprecated for route titles, use instead `beforeLoad`
   */
  meta?: RouteMeta<unknown>;
  pendingComponent?: JSXElementConstructor<any>;
  beforeLoad?: () => Partial<RouteContext> | Promise<Partial<RouteContext>>;
  loader?: (
    options: RouteMatch & {
      context: RootRouteContext;
    }
  ) => Promise<Partial<LoaderData<unknown>>>;
};

/**
 * Temporary utility to assist in migration to `@tanstack/react-router`
 */
export const createFileRoute =
  (path: string) => (options: FileRouteOptions) => {
    const {
      beforeLoad,
      component: RouteComponent,
      pendingComponent: RoutePendingComponent,
      loader,
      // meta,
      ...rest
    } = options;

    const route: FileRouteShim = {
      [FILE_ROUTE_SHIM_SYMBOL]: undefined,
      element: RouteComponent ? <RouteComponent /> : undefined,
      path,
      ...rest,
    };

    if (RoutePendingComponent) {
      route.pendingElement = <RoutePendingComponent />;
      route.pendingMs = 300;
    }

    // HACK: wrap the loader to allow populating route context via beforeLoad
    // https://tanstack.com/router/latest/docs/framework/react/guide/router-context#processing-accumulated-route-context
    if (beforeLoad) {
      route.import = async () => {
        // const _meta = { ...meta } as any;
        // Object.assign(_meta, );
        const routeContext = await beforeLoad();
        return { meta: { context: routeContext } };
      };
    }

    // HACK: Expose route context to the loader
    if (loader) {
      route.loader = (match) => {
        const firstMatch = _head(match.matchLoader?.matches);
        const context = firstMatch?.route.meta?.context as RootRouteContext;

        return loader({ ...match, context });
      };
    }

    return route;
  };
