import { Alert, Grid, Typography, useTheme } from '@mui/material';
import { Control } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PackageManagerResource } from '@endorlabs/queries';
import {
  ControlledTextField,
  ExternalLink,
  IconExternalLink,
  Link,
  RowStack,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../routes';
import { PackageManagerBaseAuthFields } from './types';

export const PackageManagerAWSCodeArtifactFields = ({
  control,
  packageManager,
}: {
  control: Control<PackageManagerBaseAuthFields>;
  packageManager?: PackageManagerResource;
}) => {
  const { palette } = useTheme();
  const { formatMessage: fm } = useIntl();

  return (
    <>
      <Grid item>
        <Alert
          severity="info"
          sx={{
            '& .MuiAlert-action': {
              marginRight: 0,
            },
          }}
          action={
            <ExternalLink
              color={palette.design.greens.dark_teal.main}
              to={NamedRoutes.DOCS_AWS_IDENTITY_PROVIDER}
              variant="button"
            >
              <FM defaultMessage="Setup Instructions" />
            </ExternalLink>
          }
        >
          <Typography variant="body1" color={palette.design.data.blue.hover}>
            <FM
              defaultMessage="You will need to set up your AWS Identity Provider and Trust
                  Policy before proceeding."
            />
          </Typography>
        </Alert>
      </Grid>
      <Grid item container direction="row" justifyContent="space-between">
        <Grid item container direction="column" width="50%" spacing={5}>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Domain *" />}
              helperText={
                <FM defaultMessage="The domain name of the AWS Code Artifact repository" />
              }
              name="auth_provider.aws.domain"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.domain ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Domain is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Repository *" />}
              helperText={
                <FM defaultMessage="The repository name for the AWS Code Artifact repository" />
              }
              name="auth_provider.aws.repository"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.repository ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Repository is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Allowed audience *" />}
              helperText={
                <FM defaultMessage="The allowed audience of the role" />
              }
              name="auth_provider.aws.allowed_audience"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.allowed_audience ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Allowed audience is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
        </Grid>
        <Grid item container direction="column" width="50%" spacing={5}>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Domain owner *" />}
              helperText={
                <FM defaultMessage="The domain owner (or account ID) of the repository" />
              }
              name="auth_provider.aws.domain_owner"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.domain_owner ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Domain owner is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Target role ARN *" />}
              helperText={
                <FM defaultMessage="The ARN of the role assumed to authenticate to the repository" />
              }
              name="auth_provider.aws.target_role_arn"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.target_role_arn ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Target role ARN is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
          <Grid item>
            <ControlledTextField
              autoComplete="off"
              control={control}
              label={<FM defaultMessage="Region *" />}
              helperText={
                <FM defaultMessage="The AWS region of the AWS Code Artifact Repository" />
              }
              name="auth_provider.aws.region"
              defaultValue={
                packageManager?.spec.auth_provider?.aws?.region ?? ''
              }
              rules={{
                required: fm({
                  defaultMessage: 'Region is required',
                }),
              }}
              variant="standard"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
