import { useMemo } from 'react';

import {
  PackageVersionResolutionErrors,
  V1Context,
  V1ResolutionStatus,
} from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListPackageVersions } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

const PACKAGE_VERSION_RESOLUTION_KEYS: `spec.resolution_errors.${keyof PackageVersionResolutionErrors}`[] =
  [
    'spec.resolution_errors.call_graph',
    'spec.resolution_errors.resolved',
    'spec.resolution_errors.unresolved',
  ];

export interface PackageVersionResolutionError extends V1ResolutionStatus {
  key: keyof PackageVersionResolutionErrors;
}
export type PackageVersionResolution = {
  data: string;
  errors: PackageVersionResolutionError[];
  namespace: string;
  packageName: string;
  uuid: string;
  versionRef: string;
};

export const usePackageVersionResolutionErrors = (
  namespace: string,
  projectUuid: string,
  scanContext?: V1Context
): {
  packageVersionsWithResolutionsErrors: PackageVersionResolution[];
  packageVersionResolutionErrorCount: number;
  isLoading: boolean;
} => {
  const qPackageVersionsWithResoutionErrors = useListPackageVersions(
    namespace,
    { enabled: !!scanContext },
    {
      filter: [
        scanContext && filterExpressionBuilders.relatedContext(scanContext),
        `spec.project_uuid==${projectUuid}`,
        PACKAGE_VERSION_RESOLUTION_KEYS.map((key) => `${key} exists`).join(
          ' or '
        ),
      ]
        .map((expression) => `(${expression})`)
        .join(' and '),
    }
  );

  const [
    packageVersionsWithResolutionsErrors,
    packageVersionResolutionErrorCount,
  ] = useMemo(() => {
    let errorCount = 0;

    const packageVersions =
      qPackageVersionsWithResoutionErrors.data?.list?.objects ?? [];

    const packageResolutions = packageVersions.map((pv) => {
      const { label: packageName, version: versionRef } =
        UIPackageVersionUtils.parsePackageName(pv.meta.name);

      const errors = Object.entries(pv.spec.resolution_errors ?? {})
        .filter(([, resolutionError]) => !!resolutionError)
        .map(([key, resolutionError]) => ({
          key,
          ...resolutionError,
        }));

      errorCount += errors.length;

      return {
        data: JSON.stringify(pv, null, 2),
        uuid: pv.uuid,
        namespace: pv.tenant_meta.namespace,
        packageName,
        versionRef,
        errors,
      };
    });

    return [packageResolutions, errorCount];
  }, [qPackageVersionsWithResoutionErrors.data]);

  return {
    isLoading: qPackageVersionsWithResoutionErrors.isLoading,
    packageVersionsWithResolutionsErrors,
    packageVersionResolutionErrorCount,
  };
};
