import { NamedRoutes } from '../constants';

// Named Routes not allowed for redirects
const disallowedNamedRoutePrefixes = [
  NamedRoutes.ERROR_PAGE,
  NamedRoutes.INSTALL_SUCCESS,
  NamedRoutes.INVITATION_ACCEPT,
  NamedRoutes.LOGIN,
  NamedRoutes.LOGIN_SUCCESS,
].map((r) => r.split('/')[1]);

/**
 * Given a url path, determine if a redirect to that path should
 * be allowed
 */
export const shouldAllowRedirect = ({ pathname }: { pathname: string }) => {
  const target = pathname.replace(/^\//, '');

  if (target === '') return false;

  if (
    disallowedNamedRoutePrefixes.some((prefix) => target.startsWith(prefix))
  ) {
    return false;
  }

  return true;
};
