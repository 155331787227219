import { SelectProps, Typography } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';
import {
  SimpleMenu,
  SimpleMenuOption,
  useLatestCallback,
} from '@endorlabs/ui-common';

import {
  getScoreCategoryLabel,
  ScoreCategoryMessages,
  UIMetricsUtils,
} from '../../../../domains/Metrics';

interface ScoreCategoryOption extends SimpleMenuOption {
  value?: V1ScoreCategory;
}
const INCLUDED_SCORE_CATEGORIES: V1ScoreCategory[] = [
  V1ScoreCategory.Security,
  V1ScoreCategory.Activity,
  V1ScoreCategory.Popularity,
  V1ScoreCategory.CodeQuality,
];
export interface ScoreCategorySelectProps
  extends Omit<SelectProps, 'onChange'> {
  activeCategory?: V1ScoreCategory;
  includeAll?: boolean;
  onChange?: (
    event: SyntheticEvent | null,
    scoreCategory?: V1ScoreCategory
  ) => void;
  scoreCategories?: V1ScoreCategory[];
}

export const ScoreCategorySelect = ({
  activeCategory,
  includeAll = true,
  onChange,
  scoreCategories = INCLUDED_SCORE_CATEGORIES,
}: ScoreCategorySelectProps) => {
  const { formatMessage } = useIntl();

  const handleChange = useLatestCallback(
    (event: SyntheticEvent | null, option: ScoreCategoryOption) => {
      if (onChange) {
        onChange(event, option.value);
      }
    }
  );
  const scoreCategoryOptions = useMemo(() => {
    const options = scoreCategories.map((category) => ({
      value: category,
      label: UIMetricsUtils.getScoreCategoryLabel(category),
      key: formatMessage(ScoreCategoryMessages[category]),
    }));
    const allOptions = [
      includeAll
        ? {
            label: <FM defaultMessage="All Factors" />,
            key: 'all',
          }
        : [],
      ...options,
    ];
    return allOptions;
  }, [includeAll, scoreCategories]);

  return (
    <SimpleMenu
      id="score_category"
      options={scoreCategoryOptions as ScoreCategoryOption[]}
      triggerVariant="chip"
      onClick={handleChange}
      triggerTitle={
        <Typography variant="body1" fontWeight={500}>
          {activeCategory ? (
            getScoreCategoryLabel(activeCategory)
          ) : (
            <FM defaultMessage={'All Factors'} />
          )}
        </Typography>
      }
    />
  );
};
