import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'AI/LLM',
});

export const Route = createFileRoute('/t/:namespace/llms/')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
