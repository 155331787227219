import { Card, CardContent, CardHeader, Grid, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { applyFilters, useFilterSearchParams } from '@endorlabs/filters';
import { useListToolPatterns } from '@endorlabs/queries';
import {
  ButtonCancel,
  CiCdToolsRulesTable,
  CiCdToolsRulesTableRow,
  EmptyState,
  SearchBar,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';

export const CiCdToolsRulesView = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();

  const {
    filterDefaultSearchParams: searchValue,
    updateFilterDefaultSearchParams: handleSearchChange,
  } = useFilterSearchParams();

  const qCiCdTools = useListToolPatterns(
    tenantName,
    {},
    { page_size: 500, sort: { path: 'meta.name' } }
  );

  const ciCdToolsRulesTableRows = useMemo(() => {
    const tools = qCiCdTools.data?.list?.objects;

    const filterKeys = ['meta.name', 'spec.categories'];

    const filteredTools = !searchValue
      ? tools
      : matchSorter(tools ?? [], searchValue, {
          keys: filterKeys,
          threshold: matchSorter.rankings.CONTAINS,
        });
    return filteredTools?.map((tool) => ({
      isSystem: tool.tenant_meta.namespace === NAMESPACES.SYSTEM,
      categories: tool.spec.categories,
      namespace: tenantName,
      tool,
    }));
  }, [qCiCdTools.data?.list?.objects, searchValue, tenantName]);

  const onCiCdRuleView = useCallback(
    ({ namespace, tool }: CiCdToolsRulesTableRow) =>
      navigate({
        to: getPoliciesPath({
          tenantName: namespace,
          section: `cicd/view/${tool.uuid}`,
        }),
      }),
    [navigate]
  );

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  const isLoading = qCiCdTools.isLoading;
  const isEmptyState =
    !isLoading && qCiCdTools.data?.list?.objects.length === 0 && !searchValue;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {!isEmptyState && (
        <Grid item>
          <SearchBar
            onSearch={handleSearchChange}
            placeholder={fm({
              defaultMessage: 'Search for Tools or Categories',
            })}
            searchValue={searchValue}
          />
        </Grid>
      )}
      <Grid item>
        <Card>
          <CardHeader
            title={
              <FM defaultMessage="Manage and configure your CI/CD tool rules" />
            }
          ></CardHeader>
          <CardContent>
            <Grid container direction="column" spacing={space.md}>
              {isEmptyState && (
                <Grid item>
                  <EmptyState
                    size="medium"
                    title={
                      <FM defaultMessage="You have not created any CI/CD tool rules" />
                    }
                  ></EmptyState>
                </Grid>
              )}

              {!isEmptyState && (
                <Grid item>
                  <CiCdToolsRulesTable
                    data={ciCdToolsRulesTableRows ?? []}
                    onView={onCiCdRuleView}
                    emptyStateProps={{
                      title: (
                        <FM defaultMessage="No Tools match the search criteria" />
                      ),
                      children: (
                        <ButtonCancel onClick={handleClearSearch}>
                          <FM defaultMessage="Clear Search" />
                        </ButtonCancel>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
