import { Grid, useTheme } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import {
  ButtonPrimary,
  ButtonStack,
  EmptyState,
  IconDownload,
  NavigationTabs,
  useDialog,
} from '@endorlabs/ui-common';

import { ExportedSBOMCreateDialog } from '../../domains/ExportedSBOM';
import { ProjectPageHeader } from '../../domains/Projects';
import { useFullMatch } from '../../routes';

const ProjectVersionDetailPageTabNames = {
  FINDINGS: 'findings',
  INVENTORY: 'inventory',
  SETTINGS: 'settings',
};

const PROJECT_INVENTORY_PATHS = ['dependencies', 'layers'];

const useProjectVersionDetailPageTabs = () => {
  const tabRecords = [
    {
      label: <FM defaultMessage="Findings" />,
      to: ProjectVersionDetailPageTabNames.FINDINGS,
      value: ProjectVersionDetailPageTabNames.FINDINGS,
    },
    {
      label: <FM defaultMessage="Inventory" />,
      to: PROJECT_INVENTORY_PATHS[0],
      value: ProjectVersionDetailPageTabNames.INVENTORY,
    },
    {
      label: <FM defaultMessage="Settings" />,
      to: ProjectVersionDetailPageTabNames.SETTINGS,
      value: ProjectVersionDetailPageTabNames.SETTINGS,
    },
  ];

  // Detect child path from inventory routes
  const childMatch = useFullMatch();
  const childPath = childMatch?.pathname.split('/').pop();
  const activeTab =
    childPath &&
    PROJECT_INVENTORY_PATHS.find((p) => p === childPath) &&
    ProjectVersionDetailPageTabNames.INVENTORY;

  return { activeTab, tabRecords };
};

type ProjectVersionDetailPageProps = {
  project: ProjectResource;
  projectVersion?: ProjectVersionResource;
  versionRef: string;
};

/**
 * Replaces the {@see ProjectVersionPage}
 */
export const ProjectVersionDetailPage = ({
  project,
  projectVersion,
  versionRef,
}: ProjectVersionDetailPageProps) => {
  const { space } = useTheme();

  const projectVersionExists = !!projectVersion;

  const packageExportDialog = useDialog({
    component: ExportedSBOMCreateDialog,
  });
  const handleOpenPackageExportDialog = () => {
    if (!projectVersion) return;
    packageExportDialog.openDialog({
      namespace: project.tenant_meta.namespace,
      project,
      projectVersion,
    });
  };

  const { activeTab, tabRecords } = useProjectVersionDetailPageTabs();

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <ProjectPageHeader
          action={
            <ButtonStack>
              <ButtonPrimary
                data-testid="project-details-primary-button"
                disabled={!projectVersionExists}
                onClick={handleOpenPackageExportDialog}
                startIcon={<IconDownload />}
              >
                <FM defaultMessage="Export SBOM" />
              </ButtonPrimary>
            </ButtonStack>
          }
          project={project}
          projectVersion={projectVersion}
        />
      </Grid>

      {!projectVersionExists && (
        <Grid item>
          <EmptyState
            title={
              <FM defaultMessage="Unable to load project version details" />
            }
            description={
              <FM
                defaultMessage={
                  'The project version "{versionRef}" was not found in this namespace'
                }
                values={{ versionRef }}
              />
            }
          />
        </Grid>
      )}

      {projectVersionExists && (
        <>
          <Grid item>
            <NavigationTabs activeTab={activeTab} tabs={tabRecords} />
          </Grid>

          <Grid item>
            <Outlet />
          </Grid>

          <packageExportDialog.Dialog {...packageExportDialog.dialogProps} />
        </>
      )}
    </Grid>
  );
};
