import { V1Ecosystem } from '@endorlabs/api_client';

import { ECOSYSTEM_PREFIXES } from '../constants';

const EcosystemByPrefix: Record<string, V1Ecosystem> = Object.fromEntries(
  (Object.entries(ECOSYSTEM_PREFIXES) as [V1Ecosystem, string][]).map(
    ([ecosystem, prefix]) => [prefix, ecosystem]
  )
);

export const prefixFromEcosystem = (ecosystem?: V1Ecosystem) => {
  if (ecosystem && ecosystem in ECOSYSTEM_PREFIXES) {
    return ECOSYSTEM_PREFIXES[ecosystem as keyof typeof ECOSYSTEM_PREFIXES];
  }
};

export const ecosystemFromPrefix = (prefix?: string): V1Ecosystem => {
  if (prefix && prefix in EcosystemByPrefix) {
    return EcosystemByPrefix[prefix];
  }

  return V1Ecosystem.Unspecified;
};
