import { useMemo } from 'react';

import { V1PlatformSource } from '@endorlabs/api_client';
import { useListAllProjects } from '@endorlabs/queries';

import { UIProjectUtils } from '../utils';

export interface ProjectInfoCacheEntry {
  uuid: string;
  name: string;
  displayName?: string;
  platformSource: V1PlatformSource;
  repositoryUrl?: string;
}

/**
 * Utility exposing Project Info for a namespace, mapped by project uuid
 */
export const useProjectInfoCache = (
  namespace: string,
  options: { enabled?: boolean } = {}
) => {
  const qProjects = useListAllProjects(
    namespace,
    { staleTime: Infinity, enabled: options.enabled },
    {
      mask: [
        'uuid',
        'meta.name',
        'spec.platform_source',
        'spec.git.http_clone_url',
      ].join(','),
      page_size: 500,
    }
  );

  const cache = useMemo(() => {
    const cache = new Map<string, ProjectInfoCacheEntry>();

    for (const project of qProjects.data ?? []) {
      cache.set(project.uuid, {
        uuid: project.uuid,
        name: project.meta.name,
        displayName:
          UIProjectUtils.parseProjectName(
            project.meta.name,
            project.spec.platform_source
          ) ?? undefined,
        platformSource: project.spec.platform_source,
        repositoryUrl: project.spec.git?.http_clone_url,
      });
    }

    return cache;
  }, [qProjects.data]);

  return { cache, isLoading: qProjects.isLoading };
};
