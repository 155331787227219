import { defineMessages } from 'react-intl';

export const trialHighlights = defineMessages({
  thirtyDays: { defaultMessage: 'Full capabilities for 30 days' },
  creditCard: { defaultMessage: 'No credit cards' },
  invite: { defaultMessage: 'Invite your team ' },
});

export const productHighlights = {
  openSource: defineMessages({
    heading: { defaultMessage: 'Securely adopt open source' },
    body: {
      defaultMessage:
        '150+ pre-commit checks, 80%+ SCA noise reduction, identify fastest paths to fixes.',
    },
  }),
  trust: defineMessages({
    heading: { defaultMessage: 'Ship code you can trust' },
    body: {
      defaultMessage:
        'CI/CD pipeline visibility, repository security posture management, build integrity verification',
    },
  }),
  compliance: defineMessages({
    heading: { defaultMessage: 'Comply with requirements' },
    body: {
      defaultMessage:
        'Single hub for SBOMs, VEX generation, comply with PCI-DSS 4.0 and more',
    },
  }),
};
