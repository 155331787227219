import { useMemo } from 'react';

import { PackageEcosystemIcons } from '../../domains';
import {
  GITHUB_ACTION_PACKAGE_SUFFIX,
  UIPackageVersionUtils,
} from '../../domains/PackageVersion';
import { IconPlanet } from '../../themes';
import { IconTitleDisplay, IconTitleDisplayProps } from '../IconTitleDisplay';

interface CiWorkflowNameDisplayProps extends IconTitleDisplayProps {
  name: string;
  link?: string;
  relativePath?: string;
  suffix?: string;
}

/**
 * Display for a CI workflow name.
 */
export const CiWorkflowNameDisplay = ({
  name: packageName,
  relativePath = '',
  suffix = GITHUB_ACTION_PACKAGE_SUFFIX,
  ...props
}: CiWorkflowNameDisplayProps) => {
  const { ecosystem, label } = useMemo(
    () => UIPackageVersionUtils.parsePackageName(packageName),
    [packageName]
  );

  // Add the relative path and suffix to the label
  const name = useMemo(() => {
    let packageName = label;
    if (relativePath) {
      // handle windows paths, replace all '\' with '/'
      const formattedPath = relativePath.replace(/\\/g, '/');

      // remove the leading '/' if present
      const prefixPath = formattedPath.replace(/^\//, '');

      packageName = `${prefixPath}/${packageName}`;
    }
    if (suffix) {
      packageName = `${packageName}${suffix}`;
    }
    return packageName;
  }, [label, relativePath, suffix]);

  return (
    <IconTitleDisplay
      {...props}
      Icon={PackageEcosystemIcons[ecosystem] ?? IconPlanet}
      title={name}
    />
  );
};
