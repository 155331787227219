import { QueryServiceApi, V1ListParameters } from '@endorlabs/api_client';
import { SupportedToolChainProfileResource } from '@endorlabs/endor-core/SupportedToolChainProfile';

import { createResourceReadHooks, ReadServiceRequestOptions } from './hooks';
import { ResourceListResponse, TResourceList } from './types';
import { getClientConfiguration } from './utils';

const queries = createResourceReadHooks('SupportedToolChainProfile', () => {
  const getApiService = () => new QueryServiceApi(getClientConfiguration());

  // Simple handler built leveraging the Query Service
  const handler = async (
    namespace: string,
    params: V1ListParameters,
    options: ReadServiceRequestOptions
  ) => {
    const resp = await getApiService().queryServiceCreateQuery(
      namespace,
      {
        meta: { name: `ListSupportedToolChainProfiles` },
        spec: {
          query_spec: {
            kind: 'SupportedToolChainProfile',
            list_parameters: params,
          },
        },
      },
      { ...options }
    );

    return resp.data.spec
      ?.query_response as TResourceList<SupportedToolChainProfileResource>;
  };

  return {
    count: () => Promise.reject(new Error('Not Implemented')),
    get: () => Promise.reject(new Error('Not Implemented')),
    group: () => Promise.reject(new Error('Not Implemented')),
    async list(namespace, params, options) {
      const resp = await handler(namespace, params, options);
      return resp.list as ResourceListResponse<SupportedToolChainProfileResource>;
    },
  };
});

// Expose query hooks for SupportedToolChainProfile
export const useListSupportedToolChainProfiles = queries.useList;
