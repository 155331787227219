import { Stack, Typography, useTheme } from '@mui/material';
import { sortBy as _sortBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1Ecosystem,
  V1PlatformSource,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListMetrics } from '@endorlabs/queries';
import {
  DependencyPathDisplay,
  RowStack,
  ScoreCategorySelect,
  UIFindingUtils,
  UIPackageVersionUtils,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { FindingExceptionStateDisplay } from '../../domains/Findings';
import { ScoreFactorsList } from '../../domains/Metrics/components/ScoreFactors';
import { ExceptionPolicyDetails } from '../../domains/Policies/components/ExceptionPolicyDetails';
import { FindingDetailProps } from './types';

/**
 * Get the related metrics for the package version or dependency of the finding
 *
 * - If this is a "self" finding, will get the scores for the package itself
 * - If this is not a "self" finding, will get the scores for the dependency
 */
const useRelatedMetrics = ({
  sourcePackageVersion,
  targetDependencyMetadata,
  isSelfFinding,
}: Pick<
  FindingDetailProps,
  'sourcePackageVersion' | 'targetDependencyMetadata'
> & {
  isSelfFinding: boolean;
}) => {
  const qPackageVersionMetrics = useListMetrics(
    sourcePackageVersion?.tenant_meta?.namespace as string,
    {
      enabled:
        isSelfFinding &&
        !!sourcePackageVersion?.tenant_meta?.namespace &&
        !!sourcePackageVersion?.uuid,
    },
    {
      filter: [
        `meta.parent_uuid==${sourcePackageVersion?.uuid}`,
        `meta.name==package_version_scorecard`,
        filterExpressionBuilders.relatedResourceContext(sourcePackageVersion),
      ].join(' and '),
      page_size: 1,
    }
  );

  const packageVersionMetric = useMemo(() => {
    const metric = qPackageVersionMetrics.data?.list?.objects[0];
    if (metric) {
      const scoreCard = metric.spec.metric_values.scorecard?.score_card;
      const scoreFactors =
        metric.spec.metric_values.scorefactor.score_factor_list?.score_factors;

      return { scoreCard, scoreFactors };
    }
  }, [qPackageVersionMetrics.data]);

  const qTargetDependencyMetrics = useListMetrics(
    targetDependencyMetadata?.spec.dependency_data?.namespace as string,
    {
      enabled:
        !isSelfFinding &&
        !!targetDependencyMetadata?.spec.dependency_data?.namespace &&
        !!targetDependencyMetadata.spec.dependency_data?.package_version_uuid,
    },
    {
      filter: [
        `meta.parent_uuid==${targetDependencyMetadata?.spec.dependency_data?.package_version_uuid}`,
        `meta.name==package_version_scorecard`,
        filterExpressionBuilders.relatedResourceContext(
          targetDependencyMetadata
        ),
      ].join(' and '),
      page_size: 1,
    }
  );

  const targetDependencyMetric = useMemo(() => {
    const metric = qTargetDependencyMetrics.data?.list?.objects[0];
    if (metric) {
      const scoreCard = metric.spec.metric_values.scorecard?.score_card;
      const scoreFactors =
        metric.spec.metric_values.scorefactor.score_factor_list?.score_factors;

      return { scoreCard, scoreFactors };
    }
  }, [qTargetDependencyMetrics.data]);

  // extract finding scores from metadata
  const scoreFactors = isSelfFinding
    ? packageVersionMetric?.scoreFactors
    : targetDependencyMetric?.scoreFactors;
  const scoreCard = isSelfFinding
    ? packageVersionMetric?.scoreCard
    : targetDependencyMetric?.scoreCard;

  const isLoading =
    qPackageVersionMetrics.isLoading || qTargetDependencyMetrics.isLoading;

  return {
    isLoading,
    scoreCard,
    scoreFactors,
  };
};

/**
 * @deprecated
 * @see FindingDetailDrawerV2
 */
export const FindingDetailOperational = ({
  exceptionPolicies = [],
  finding,
  project,
  sourcePackageVersion,
  targetDependencyMetadata,
}: FindingDetailProps) => {
  const { space } = useTheme();

  const isSelfFinding = UIFindingUtils.isSelfFinding(finding);
  const PlatformIcon =
    project && project?.spec
      ? UIProjectUtils.getPlatformIcon(
          project.spec.platform_source as V1PlatformSource
        )
      : undefined;

  // get metrics for the related target dependency or package version
  const {
    scoreCard,
    isLoading: isLoadingMetrics,
    scoreFactors,
  } = useRelatedMetrics({
    sourcePackageVersion,
    targetDependencyMetadata,
    isSelfFinding,
  });

  const [activeScoreCategory, setActiveScoreCategory] = useState<
    V1ScoreCategory | undefined
  >();

  // When score card is present, set the category with the lowest score as active
  useEffect(() => {
    const lowestScoreCategory = _sortBy(scoreCard?.category_scores, [
      'score',
    ])[0];
    if (lowestScoreCategory) {
      setActiveScoreCategory(lowestScoreCategory.category);
    }
  }, [scoreCard]);

  // FIXME? - In cases where a finding ecosystem is unspecified, attempt to match based on the source package ecosystem.
  const targetDependencyEcosystem =
    finding.spec.ecosystem === V1Ecosystem.Unspecified
      ? sourcePackageVersion?.spec?.ecosystem ?? V1Ecosystem.Unspecified
      : finding.spec.ecosystem ?? V1Ecosystem.Unspecified;

  const hasExceptions = exceptionPolicies.length > 0;

  return (
    <Stack spacing={space.md}>
      {exceptionPolicies.length > 0 && (
        <Stack spacing={space.sm}>
          <RowStack>
            <Typography variant="h6">
              <FM defaultMessage="Exception Policies" />
            </Typography>
            <FindingExceptionStateDisplay hasExceptions={true} />
          </RowStack>

          <ExceptionPolicyDetails exceptionPolicies={exceptionPolicies} />
        </Stack>
      )}

      {/* DEPENDENCY PATH */}

      {sourcePackageVersion && !isSelfFinding && (
        <DependencyPathDisplay
          dependencyGraph={
            (sourcePackageVersion?.spec?.resolved_dependencies
              ?.dependency_graph as unknown as Record<string, string[]>) ??
            ({} as Record<string, string[]>)
          }
          displayAsAccordion={false}
          project={project}
          sourcePackageNames={[sourcePackageVersion?.meta?.name ?? '']}
          targetPackageName={UIPackageVersionUtils.deriveFullPackageName({
            name: finding.spec.target_dependency_name ?? '',
            ecosystem: targetDependencyEcosystem,
            version: finding.spec.target_dependency_version ?? '',
          })}
        />
      )}

      {/* SCORE FACTORS */}
      <Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography component="h3" variant="h6">
            <FM defaultMessage="Score Factors" />
          </Typography>

          <ScoreCategorySelect
            activeCategory={activeScoreCategory}
            onChange={(_, category) => setActiveScoreCategory(category)}
          />
        </Stack>

        <ScoreFactorsList
          isLoading={isLoadingMetrics}
          scoreCard={scoreCard}
          scoreFactors={scoreFactors}
          scoreCategory={activeScoreCategory}
        />
      </Stack>
    </Stack>
  );
};
