import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { GraphEdgeDirection, GraphNode } from '@endorlabs/utils/graph';

import { EmptyState } from '../EmptyState';
import { GraphPathListNode } from './GraphPathListNode';
import { GraphPathListSkeleton } from './GraphPathListSkeleton';
import { GraphPathListNodeAttributes } from './types';

export type GraphPathListProps = {
  /**
   * If graph is directed
   *
   * http://www.graphviz.org/docs/attr-types/dirType/
   */
  direction?: GraphEdgeDirection;
  emptyStateMessage?: ReactNode;
  isLoading?: boolean;
  /**
   * Path of nodes from a graph
   */
  path: GraphNode<GraphPathListNodeAttributes>[];
};

export const GraphPathList = ({
  direction = 'none',
  emptyStateMessage,
  isLoading,
  path = [],
}: GraphPathListProps) => {
  const isEmptyState = !isLoading && !path.length;

  if (isLoading) {
    return <GraphPathListSkeleton />;
  }

  if (isEmptyState) {
    return (
      <EmptyState
        size="small"
        title={
          emptyStateMessage ?? <FM defaultMessage="Unable to display Path" />
        }
      />
    );
  }

  return (
    <Box
      className="GraphPathList-root"
      component="ol"
      sx={{ margin: 0, padding: 0 }}
    >
      {path.map((node, index) => (
        <GraphPathListNode
          key={`${node.id}_${index}`}
          node={node}
          direction={direction}
        />
      ))}
    </Box>
  );
};
