import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';
import {
  SpecEnabledFeatureType,
  V1FindingCategory,
} from '@endorlabs/api_client';
import { FindingCategoryMessages } from '@endorlabs/ui-common';

/**
 * NOTE: Boolean installation config values that are null should default to false
 */
export const InstallationDefaultValues = {
  [V1PlatformSource.Azure]: {
    'spec.enabled_features': [],
    'spec.azure_config.host_url': '',
    'spec.azure_config.personal_access_token': '',
    'spec.platform_type': V1PlatformSource.Azure,
  } as Record<string, unknown>,

  [V1PlatformSource.Github]: {
    'spec.enabled_features': [],
    // NOTE: String b/c controlled by radio
    'spec.github_config.enable_full_scan': false,
    'spec.github_config.enable_pr_scans': false,
    'spec.github_config.enable_pr_comments': false,
    'spec.github_config.include_archived_repos': false,
    'spec.platform_type': V1PlatformSource.Github,
  } as Record<string, unknown>,
};

export const installStep: string[] = ['Select a namespace', 'Select scanners'];

export const ENABLED_FEATURES_MAP: {
  helperText: ReactNode;
  label: ReactNode;
  key: string;
  value: SpecEnabledFeatureType;
}[] = [
  {
    helperText: (
      <FM defaultMessage="Prioritize open-source dependency risk with reachability analysis" />
    ),
    key: SpecEnabledFeatureType.GitScan,
    label: <FM defaultMessage="SCA" />,
    value: SpecEnabledFeatureType.GitScan,
  },
  {
    helperText: (
      <FM defaultMessage="Strengthen the security posture of source code repositories" />
    ),
    key: SpecEnabledFeatureType.GithubScan,
    label: <FM {...FindingCategoryMessages[V1FindingCategory.Scpm]} />,
    value: SpecEnabledFeatureType.GithubScan,
  },
  {
    helperText: <FM defaultMessage="Detect and prevent secret leaks" />,
    key: SpecEnabledFeatureType.SecretsScan,
    label: <FM defaultMessage="Secret" />,
    value: SpecEnabledFeatureType.SecretsScan,
  },
  {
    helperText: (
      <FM defaultMessage="Discover shadow pipelines quickly, and validate the integrity of your builds" />
    ),
    key: SpecEnabledFeatureType.ToolsScan,
    label: <FM defaultMessage="CI/CD" />,
    value: SpecEnabledFeatureType.ToolsScan,
  },
];
