import { Box, Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1CiCdTool, V1PlatformSource } from '@endorlabs/api_client';

import { NilDisplay } from '../NilDisplay';
import { ProjectNameDisplay } from '../ProjectNameDisplay';
import { ToolLogoDisplay } from './ToolLogoDisplay';
import { ToolNameDisplay } from './ToolNameDisplay';

const DEFAULT_MAX_COUNT = 3;

export const ToolLogoArrayDisplay = ({
  category,
  justifyContent = 'center',
  maxCount = DEFAULT_MAX_COUNT,
  platformSource,
  projectName,
  tools,
}: {
  category?: string;
  justifyContent?: 'center' | 'start';
  maxCount?: number;
  platformSource?: V1PlatformSource;
  projectName?: string;
  tools: V1CiCdTool[];
}) => {
  const theme = useTheme();

  const visibleTools = tools.slice(0, maxCount);
  const remainingTools = tools.slice(maxCount);

  return (
    <Stack direction="row" justifyContent={justifyContent}>
      {tools.length === 0 ? (
        <NilDisplay variant="dash" />
      ) : (
        <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: '40vw',
              },
            },
          }}
          title={
            <Stack
              direction="column"
              gap={theme.space.xs}
              padding={theme.space.xs}
            >
              <Typography textTransform="uppercase" variant="subtitle2">
                <FM
                  defaultMessage="{category} Tools"
                  values={{ category: category }}
                />
              </Typography>
              <Stack
                direction="row"
                flexWrap="wrap"
                columnGap={theme.space.md}
                rowGap={theme.space.xs}
                marginBottom={theme.space.xs}
              >
                {tools.map((tool) => (
                  <ToolNameDisplay
                    key={tool.name}
                    toolName={tool.name}
                    iconProps={{ displayMode: 'dark' }}
                  />
                ))}
              </Stack>

              {projectName && (
                <Stack alignItems="center" direction="row" gap={theme.space.xs}>
                  <Typography fontWeight={400}>
                    <FM defaultMessage="Project" />
                  </Typography>
                  <ProjectNameDisplay
                    iconProps={{ displayMode: 'dark' }}
                    name={projectName}
                    platformSource={platformSource}
                  />
                </Stack>
              )}
            </Stack>
          }
        >
          <Box>
            <Stack alignItems="center" direction="row" gap={1}>
              {visibleTools.map((tool) => (
                <ToolLogoDisplay
                  fontSize="small"
                  key={tool.name}
                  toolName={tool.name}
                />
              ))}

              {remainingTools.length === 1 && (
                <ToolLogoDisplay
                  fontSize="small"
                  key={remainingTools[0]?.name}
                  toolName={remainingTools[0]?.name}
                />
              )}

              {remainingTools.length > 1 && (
                <Typography component="span" color="text.secondary">
                  <FM
                    defaultMessage="+{count}"
                    values={{ count: remainingTools.length }}
                  />
                </Typography>
              )}
            </Stack>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};
