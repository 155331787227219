import { Skeleton } from '@mui/material';

import {
  isContainerProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import {
  IconTitleDisplay,
  ImgIconLogoMarkDocker,
  RowStack,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { useProjectDetailDrawerContentData } from '../../hooks';

interface ProjectDetailDrawerHeaderProps {
  isProjectLoading: boolean;
  namespace: string;
  project: ProjectResource;
  uuid: string;
}

export const ProjectDetailDrawerHeader = ({
  isProjectLoading,
  namespace,
  project,
  uuid,
}: ProjectDetailDrawerHeaderProps) => {
  const { packageEcosystems, isLoading } = useProjectDetailDrawerContentData({
    isProjectLoading,
    uuid,
    project,
    namespace,
  });
  const containerProject = isContainerProject(project, packageEcosystems);
  return isLoading ? (
    <RowStack alignItems="flex-start" flexWrap="nowrap">
      <Skeleton height={40} width={44} />
      <Skeleton height={40} width="60%" />
    </RowStack>
  ) : (
    <IconTitleDisplay
      iconFrame
      size="xlarge"
      Icon={
        containerProject
          ? ImgIconLogoMarkDocker
          : UIProjectUtils.getPlatformIcon(project.spec.platform_source)
      }
      title={UIProjectUtils.parseProjectName(
        project.meta.name,
        project.spec.platform_source
      )}
      namespace={namespace}
    />
  );
};
