import { V1Ecosystem } from '@endorlabs/api_client';

import { ECOSYSTEM_SEPARATOR, VERSION_SEPARATOR } from '../constants';
import { PackageDescriptor } from '../types';
import { ecosystemFromPrefix } from './ecosystem';

const EMPTY_PACKAGE_DESCRIPTOR: PackageDescriptor = {
  name: '',
  ecosystem: V1Ecosystem.Unspecified,
  label: '',
  version: '',
};

/**
 * Utility to parse a Package or Package Version name
 */
export const parsePackageName = (value?: string): PackageDescriptor => {
  if (!value || 'string' !== typeof value) {
    return EMPTY_PACKAGE_DESCRIPTOR;
  }

  const ecoSepIndex = value.indexOf(ECOSYSTEM_SEPARATOR);
  const ecoPrefix = value.slice(0, ecoSepIndex);
  const rest =
    ecoSepIndex === -1
      ? value
      : value.slice(ecoSepIndex + ECOSYSTEM_SEPARATOR.length);

  if (!ecoPrefix || !rest) return EMPTY_PACKAGE_DESCRIPTOR;

  const ecosystem = ecosystemFromPrefix(ecoPrefix);

  // iterate over the rest of the given package version
  const start = 0;
  let end = rest.indexOf(VERSION_SEPARATOR);
  if (end === 0) {
    // handle leading `@` in package name
    end = rest.indexOf(VERSION_SEPARATOR, 1);
  }

  if (end === -1) {
    end = rest.length;
  }

  // package version takes all characters up to final `@`
  const packageName = rest.slice(start, end);

  // version takes remaining charaters after final `@` (or empty string)
  const version = rest.slice(end + 1);

  return {
    ecosystem,
    label: packageName,
    name: [ecoPrefix, packageName].join(ECOSYSTEM_SEPARATOR),
    version,
  };
};
