import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type ArtifactIconProps = {
  color?: string;
  size?: string | number;
};

const Artifacts = forwardRef<SVGSVGElement, ArtifactIconProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_517_929)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9005 12.3351L22.5005 14.1351C22.8063 14.2871 22.9998 14.5991 23 14.9406V19.2291C22.9998 19.5706 22.8063 19.8826 22.5005 20.0346L18.9005 21.8346C18.647 21.9614 18.3485 21.9614 18.095 21.8346L14.495 20.0346C14.1895 19.8806 13.9976 19.5667 14 19.2246V14.9406C14.0002 14.5991 14.1937 14.2871 14.4995 14.1351L18.0995 12.3351C18.3518 12.2097 18.6482 12.2097 18.9005 12.3351Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.90051 12.3351L9.5005 14.1351C9.80635 14.2871 9.99982 14.5991 10 14.9406V19.2291C9.99982 19.5706 9.80635 19.8826 9.5005 20.0346L5.90051 21.8346C5.64699 21.9614 5.34853 21.9614 5.09501 21.8346L1.49502 20.0346C1.18947 19.8806 0.997641 19.5667 1.00002 19.2246V14.9406C1.0002 14.5991 1.19368 14.2871 1.49952 14.1351L5.09951 12.3351C5.3518 12.2097 5.64823 12.2097 5.90051 12.3351Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.4005 1.33508L16.0005 3.13508C16.3063 3.28705 16.4998 3.59905 16.5 3.94057V8.22906C16.4998 8.57059 16.3063 8.88258 16.0005 9.03456L12.4005 10.8346C12.147 10.9614 11.8485 10.9614 11.595 10.8346L7.99502 9.03456C7.68947 8.8806 7.49764 8.5667 7.50002 8.22456V3.94057C7.5002 3.59905 7.69368 3.28705 7.99952 3.13508L11.5995 1.33508C11.8518 1.20972 12.1482 1.20972 12.4005 1.33508Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_517_929">
            <rect width="24" height="23" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Artifacts.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Artifacts.displayName = 'Artifact';

export default Artifacts;
