import { Box } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef } from 'react';

import { FindingCount } from '@endorlabs/queries';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
} from '@endorlabs/ui-common';

export type ContainerImagesTableRow = {
  key: string;
  name: string;
  findingCounts?: FindingCount[];
  isBaseImage: boolean;
};

export interface ContainerImagesTableProps
  extends Omit<
    DataTableProps<ContainerImagesTableRow>,
    'columns' | 'getRowId' | 'onRowClick' | 'tableId'
  > {
  onClickDetail: (row: ContainerImagesTableRow) => void;
}

const buildContainerImagesTableColumns = ({
  onClickDetail,
}: Pick<
  ContainerImagesTableProps,
  'onClickDetail'
>): DataTableColumnDef<ContainerImagesTableRow>[] => [
  {
    accessorKey: 'name',
    header: 'Image',
    colType: ColTypes.TEXT_LONG,
  },
  {
    id: 'actions',
    cell: ({ row }) =>
      row.original && (
        <Box display="flex" justifyContent="end">
          <DataTableDrawerButton
            onClick={(event) => {
              event.stopPropagation();
              onClickDetail(row.original);
              if (!row.getIsSelected()) row.toggleSelected();
            }}
          />
        </Box>
      ),
    colType: ColTypes.ACTIONS,
    header: '',
  },
];

export const ContainerImagesTable = forwardRef(
  function ContainerImagesTableComponent(
    { onClickDetail, ...props }: ContainerImagesTableProps,
    ref: ForwardedRef<Table<ContainerImagesTableRow>>
  ) {
    const columns = buildContainerImagesTableColumns({ onClickDetail });

    function handleRowClick(row: ContainerImagesTableRow) {
      return onClickDetail(row);
    }

    return (
      <DataTable
        {...props}
        columns={columns}
        onRowClick={handleRowClick}
        getRowId={(r) => r.key}
        ref={ref}
        tableId="ContainerImagesTable"
      />
    );
  }
);
