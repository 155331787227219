import { Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingSource } from '@endorlabs/endor-core/Finding';
import {
  SimpleMenu,
  SimpleMenuOption,
  useLatestCallback,
} from '@endorlabs/ui-common';

import { FindingAggregation } from '../types';

interface FindingAggregationOption extends SimpleMenuOption {
  value: FindingAggregation;
  excludeFindingSources?: FindingSource[];
}

const FINDING_AGGREGATION_OPTIONS: FindingAggregationOption[] = [
  {
    value: FindingAggregation.None,
    label: <FM defaultMessage="None" />,
  },
  {
    value: FindingAggregation.Finding,
    label: <FM defaultMessage="Finding" />,
  },
  {
    value: FindingAggregation.Dependency,
    label: <FM defaultMessage="Dependency" />,
    excludeFindingSources: [
      // Findings from these sources will not have a related Dependency to group by
      FindingSource.Package,
      FindingSource.Repository,
      FindingSource.Secrets,
    ],
  },
  {
    value: FindingAggregation.FindingAndDependency,
    label: <FM defaultMessage="Finding + Dependency" />,
    excludeFindingSources: [
      // Findings from these sources will not have a related Dependency to group by
      FindingSource.Package,
      FindingSource.Repository,
      FindingSource.Secrets,
    ],
  },
  {
    value: FindingAggregation.Package,
    label: <FM defaultMessage="Package" />,
    excludeFindingSources: [
      // Findings from these sources will not have a related Package to group by
      FindingSource.Repository,
      FindingSource.Secrets,
    ],
  },
].map((o) => Object.assign(o, { key: o.value }));

export type FindingAggregationMenuProps = {
  findingSource?: FindingSource;
  value: FindingAggregation;
  onSelect: (event: SyntheticEvent | null, value: FindingAggregation) => void;
};

export const FindingAggregationMenu = ({
  findingSource,
  value,
  onSelect,
}: FindingAggregationMenuProps) => {
  const handleSelect = useLatestCallback(
    (event: SyntheticEvent | null, option: FindingAggregationOption) => {
      onSelect(event, option.value);
    }
  );

  const filteredOptions = useMemo(() => {
    if (!findingSource) return FINDING_AGGREGATION_OPTIONS;

    const options = FINDING_AGGREGATION_OPTIONS.filter((o) => {
      const isExcluded = o.excludeFindingSources?.includes(findingSource);
      return !isExcluded;
    });

    return options;
  }, [findingSource]);

  useEffect(() => {
    const hasOption = filteredOptions.some((o) => o.value === value);
    if (!hasOption) {
      handleSelect(null, filteredOptions[0]);
    }
  }, [filteredOptions, handleSelect, value]);

  const selectedLabel = filteredOptions.find((o) => o.value === value)?.label;

  return (
    <SimpleMenu
      options={filteredOptions}
      id={`FindingAggregationMenu`}
      onClick={handleSelect}
      triggerTitle={
        <Typography color="text.secondary" component="span" variant="button">
          <FM
            defaultMessage="Grouped by: <b>{selectedLabel}</b>"
            values={{
              selectedLabel,
              b: (value) => (
                <Typography
                  color="text.primary"
                  component="span"
                  variant="inherit"
                >
                  {value}
                </Typography>
              ),
            }}
          />
        </Typography>
      }
      triggerVariant="chip"
    />
  );
};
