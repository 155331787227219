import {
  ContextContextType as ResourceContextType,
  V1Context,
} from '@endorlabs/api_client';

import { FilterExpression } from './expression';

/**
 * Given a logical Operator, return a builder for the joined Filter Expression
 */
const makeLogicalExpressionBuilder =
  (operator: 'and' | 'or') => (expressions: FilterExpression[]) => {
    const wrapped = expressions.map((e) => {
      if (e.includes(' and ') || e.includes(' or ')) {
        return wrapExpression(e);
      }
      return e;
    });

    return wrapped.join(` ${operator} `);
  };

/**
 * Given a context, return a filter for the same same context
 */
const relatedContext = (context: V1Context) => {
  switch (context?.type) {
    case ResourceContextType.Main:
      return `context.type == "${ResourceContextType.Main}"`;
    case ResourceContextType.CiRun:
    case ResourceContextType.Ref:
    case ResourceContextType.Sbom:
    case ResourceContextType.External:
      return `(context.type == "${context.type}" and context.id == "${context.id}")`;
    default:
      return undefined;
  }
};

/**
 * Given a resource with context, return a filter for related resources with the same context
 *
 * @example
 *   const qListRepositoryVersionFindings = useListFindings(
 *     namespace,
 *     { enabled: !!repositoryVersion },
 *     { filter: getRelatedResourceContextFilter(repositoryVersion) }
 *   );
 */
const relatedResourceContext = (resource?: {
  context?: V1Context;
}): string | undefined => {
  if (resource?.context?.type) return relatedContext(resource.context);
};

/**
 * Enclose a Filter Expression with parenthesis
 */
const wrapExpression = (expression: FilterExpression): FilterExpression => {
  return `(${expression})`;
};

/**
 * Filter expression builders, extending the comparator expressions in {@link ./expression.ts}
 */
export const filterExpressionBuilders = {
  /**
   * Filter out other contexts besides main and ref. Optionally, provide the target namespace
   */
  defaultResourceContexts: (namespace?: string) => {
    switch (namespace) {
      case 'oss':
        return filterExpressionBuilders.mainResourceContext();
      default:
        return `context.type in [${[
          ResourceContextType.Main,
          ResourceContextType.Ref,
        ]}]`;
    }
  },
  /**
   * Include only resources with the "main" context
   */
  mainResourceContext: () => `context.type == "${ResourceContextType.Main}"`,
  /**
   * Include only resources with the "ref" context
   */
  refResourceContext: () => `context.type == "${ResourceContextType.Ref}"`,
  relatedContext,
  relatedResourceContext,
  and: makeLogicalExpressionBuilder('and'),
  or: makeLogicalExpressionBuilder('or'),
};
