import { Box, Paper, Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';

import {
  AssuredPackageVersionResource,
  parseSecurityAttestationPatch,
} from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  DiffStat,
  ExpandCollapseControl,
  RowStack,
  useDefaultRecord,
} from '@endorlabs/ui-common';

import { PatchDisplayAccordion } from './PatchDisplayAccordion';

export const AssuredPackageVersionPatchDetail = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  const { space } = useTheme();

  const expandState = useDefaultRecord(false);

  const { added, removed, patchFileDiffStats } = useMemo(() => {
    const added = assuredPackageVersion?.spec.upgrade_summary?.line_added ?? 0;
    const removed =
      assuredPackageVersion?.spec.upgrade_summary?.line_removed ?? 0;

    const patchFileDiffStats = (
      assuredPackageVersion?.spec.security_attestation?.patches ?? []
    ).flatMap((p) => parseSecurityAttestationPatch(p));

    return { added, removed, patchFileDiffStats };
  }, [assuredPackageVersion]);

  return (
    <Box paddingX={space.sm}>
      <RowStack justifyContent="space-between">
        <ExpandCollapseControl onClick={(value) => expandState.reset(value)} />

        <DiffStat added={added} removed={removed} />
      </RowStack>

      <Stack gap={space.md} marginTop={4}>
        {patchFileDiffStats.map((diffStat, index) => (
          <Paper key={index} variant="outlined">
            <PatchDisplayAccordion
              diffStat={diffStat}
              expanded={expandState.get(`patch-${index}`)}
              id={`patch-${index}`}
              onChange={(_, value) => expandState.set(`patch-${index}`, value)}
            />
          </Paper>
        ))}
      </Stack>
    </Box>
  );
};
