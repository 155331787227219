import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  DataTablePaginator,
  IconChevronLeft,
  IconChevronRight,
} from '@endorlabs/ui-common';

const DEFAULT_PAGE_SIZES = [5, 10, 20, 50];

/**
 * Somewhat generic pagination component
 * - Similar to {@see DataTablePagination}, without dependency on `@tanstack/react-table`
 * - Similar to the pagination controls in {@see DependencyPathDisplayHeader}
 */
export const FindingDetailSectionPagination = ({
  hidePageSizeSelect,
  pageSizes = DEFAULT_PAGE_SIZES,
  paginator,
  ...rest
}: {
  hidePageSizeSelect?: boolean;
  pageSizes?: number[];
  paginator: DataTablePaginator;
  resourceLabel?: string;
  resourceLabelPlural?: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const { pageCount } = paginator;
  const { pageIndex, pageSize, totalCount } = paginator.state;

  // get defaults for resource labels
  const {
    resourceLabel = fm({ defaultMessage: 'Item' }),
    resourceLabelPlural = fm({ defaultMessage: 'Items' }),
  } = rest;

  const hasPreviousPage = pageIndex > 0;
  const hasNextPage = pageIndex + 1 < pageCount;

  // calculate min and max indexes for items in this page
  const pageMin = Math.min(totalCount, pageSize * pageIndex + 1);
  const pageMax = Math.min(totalCount, pageSize * (pageIndex + 1));

  const showPageSizeSelect = !hidePageSizeSelect && pageCount > 1;

  const onChangePageSize = (newPageSize: number) => {
    // guard valid page size selection
    if (pageSizes.includes(newPageSize)) {
      paginator.onPaginationChange((state) => ({
        ...state,
        pageSize: newPageSize,
      }));
    }
  };

  const gotoPreviousPage = () =>
    paginator.onPaginationChange((state) => ({
      ...state,
      pageIndex: state.pageIndex - 1,
    }));

  const gotoNextPage = () =>
    paginator.onPaginationChange((state) => ({
      ...state,
      pageIndex: state.pageIndex + 1,
    }));

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="flex-end"
      sx={{ width: '100%' }}
    >
      {showPageSizeSelect && (
        <Grid item>
          <Stack alignItems="center" direction="row">
            <Typography variant="body2">
              <FM
                defaultMessage="{resourceLabelPlural} per page"
                values={{
                  resourceLabelPlural,
                }}
              />
            </Typography>
            <Select
              size="small"
              value={paginator.state.pageSize}
              onChange={(event) => onChangePageSize(Number(event.target.value))}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {pageSizes.map((size) => (
                <MenuItem key={size} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
      )}

      {/* Hide pagination control when total count is zero */}
      <Grid item sx={{ visibility: totalCount > 0 ? 'visible' : 'hidden' }}>
        <Stack alignItems="center" direction="row">
          <IconButton
            aria-label={fm({ defaultMessage: 'Previous page' })}
            disabled={!hasPreviousPage}
            onClick={gotoPreviousPage}
          >
            <IconChevronLeft />
          </IconButton>
          <Typography variant="body2">
            <FM
              defaultMessage="Showing {pageMin, number}-{pageMax, number} of {count, number, ::compact-short} {count, plural,
                one {{resourceLabel}}
                other {{resourceLabelPlural}}
              }"
              values={{
                count: totalCount,
                pageMin,
                pageMax,
                resourceLabel,
                resourceLabelPlural,
              }}
            />
          </Typography>
          <IconButton
            aria-label={fm({ defaultMessage: 'Next page' })}
            disabled={!hasNextPage}
            onClick={gotoNextPage}
          >
            <IconChevronRight />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
};
