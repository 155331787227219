import { Box } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { MouseEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ContextContextType,
  V1Context,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import { PackageContexture } from '@endorlabs/queries';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  FindingCountDisplayProps,
  REGEX_COMMIT_SHA_VALIDATION,
  ScoreCategoryIcon,
  UIEventUtils,
} from '@endorlabs/ui-common';
import { UIPackageVersionUtils } from '@endorlabs/ui-common/domains/PackageVersion';

export interface PackagesIndexDetailTableRow {
  context: V1Context;
  uuid: string;
  name: string;
  namespace: string;
  link: string;
  version?: string;
  dependenciesCount?: number;
  // props for my package:
  findingCounts: FindingCountDisplayProps[];
  // props for dependency package:
  dependentsCount?: number;
  SCORE_CATEGORY_SECURITY?: number;
  SCORE_CATEGORY_ACTIVITY?: number;
  SCORE_CATEGORY_POPULARITY?: number;
  SCORE_CATEGORY_CODE_QUALITY?: number;
}

export interface PackagesIndexDetailTableProps
  extends Omit<DataTableProps<PackagesIndexDetailTableRow>, 'columns'> {
  packageContexture: PackageContexture;
  onClickDetail?: (row?: PackagesIndexDetailTableRow) => void;
}

const getPackagesIndexDetailTableColumns = ({
  packageContexture,
  onClickDetail,
}: Pick<
  PackagesIndexDetailTableProps,
  'packageContexture' | 'onClickDetail'
>): DataTableColumnDef<PackagesIndexDetailTableRow>[] => {
  const columns: DataTableColumnDef<PackagesIndexDetailTableRow>[] = [
    {
      accessorKey: 'version',
      cell: ({ row }) => {
        const versionRef = row.original.version;
        const contextType = row.original.context.type;
        let contextId = row.original.context.id;

        if (
          contextType === ContextContextType.Main ||
          contextId === versionRef
        ) {
          return versionRef;
        }

        // shorten commit sha display
        if (REGEX_COMMIT_SHA_VALIDATION.test(contextId)) {
          contextId = contextId.slice(0, 7);
        }

        return (
          <FM
            defaultMessage="{versionRef} ({contextId})"
            values={{
              versionRef,
              contextId,
            }}
          />
        );
      },
      colType: ColTypes.VERSION,
      header: () => <FM defaultMessage="Version" />,
      enableSorting: true,
      sortingFn: (a, b) =>
        UIPackageVersionUtils.sortBySemanticVersion(
          a.original?.version ?? '',
          b.original?.version ?? ''
        ),
    },
    {
      accessorKey: 'dependenciesCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Dependencies" />,
      enableSorting: true,
    },
  ];

  if (packageContexture === PackageContexture.Packages) {
    columns.push(
      {
        accessorKey: 'namespace',
        colType: ColTypes.NAMESPACE,
      },
      {
        accessorKey: 'findingCounts',
        colType: ColTypes.FINDING_COUNTS,
        header: () => <FM defaultMessage="Findings" />,
        // TODO: custom sort for finding counts
        // https://tanstack.com/table/v8/docs/api/features/sorting#sorting-functions
      }
    );

    if (onClickDetail) {
      columns.push({
        id: 'actions',
        cell: ({ row }) =>
          row.original && (
            <Box display="flex" justifyContent="end">
              <DataTableDrawerButton
                onClick={(e) => {
                  e.stopPropagation();
                  onClickDetail(row.original);
                }}
              />
            </Box>
          ),
        colType: ColTypes.ACTIONS,
        header: '',
      });
    }
  }

  if (packageContexture === PackageContexture.Dependencies) {
    columns.push(
      {
        accessorKey: 'dependentsCount',
        colType: ColTypes.NUMBER,
        header: () => <FM defaultMessage="Dependents" />,
        enableSorting: true,
      },
      {
        accessorKey: V1ScoreCategory.Security,
        colType: ColTypes.SCORE,
        header: () => <ScoreCategoryIcon category={V1ScoreCategory.Security} />,
        enableSorting: true,
      },
      {
        accessorKey: V1ScoreCategory.Activity,
        colType: ColTypes.SCORE,
        header: () => <ScoreCategoryIcon category={V1ScoreCategory.Activity} />,
        enableSorting: true,
      },
      {
        accessorKey: V1ScoreCategory.Popularity,
        colType: ColTypes.SCORE,
        header: () => (
          <ScoreCategoryIcon category={V1ScoreCategory.Popularity} />
        ),
        enableSorting: true,
      },
      {
        accessorKey: V1ScoreCategory.CodeQuality,
        colType: ColTypes.SCORE,
        header: () => (
          <ScoreCategoryIcon category={V1ScoreCategory.CodeQuality} />
        ),
        enableSorting: true,
      }
    );
  }

  return columns;
};

export const PackagesIndexDetailTable = ({
  packageContexture,
  onClickDetail,
  ...props
}: PackagesIndexDetailTableProps) => {
  const navigate = useNavigate();
  const columns = getPackagesIndexDetailTableColumns({
    packageContexture,
    onClickDetail,
  });

  function handleRowClick(
    row: PackagesIndexDetailTableRow,
    _: Row<PackagesIndexDetailTableRow>,
    e: MouseEvent
  ) {
    if (row.link) {
      return UIEventUtils.simulateLinkClick(row.link, e, navigate);
    }
    if (onClickDetail) {
      return onClickDetail(row);
    }
  }
  return <DataTable {...props} columns={columns} onRowClick={handleRowClick} />;
};
