import { FindingSource } from '@endorlabs/endor-core/Finding';
import {
  isBinaryProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';

import { UIFindingUtils } from '../../Findings';

/**
 * Helper to build the base Filter Expression for a Project
 */
export const getProjectRelatedFilterExpression = (
  project?: ProjectResource,
  projectVersion?: ProjectVersionResource,
  options: {
    /**
     * Allow overriding the default filter key to the project uuid
     */
    key: string;
  } = { key: 'spec.project_uuid' }
): FilterExpression | undefined => {
  const filterExpressions: string[] = [];

  if (!project) return;

  filterExpressions.push(`${options.key}=="${project.uuid}"`);

  if (!projectVersion?.context) {
    if (isBinaryProject(project)) {
      filterExpressions.push(filterExpressionBuilders.mainResourceContext());
      return filterExpressionBuilders.and(filterExpressions);
    }

    return;
  }

  filterExpressions.push(
    filterExpressionBuilders.relatedResourceContext(projectVersion) as string
  );

  return filterExpressionBuilders.and(filterExpressions);
};

/**
 * Helper to build the base Filter Expression for Findings from a
 * Project/Repository, with a given Finding Source.
 */
export const getProjectFindingFilterExpression = (
  project?: ProjectResource,
  projectVersion?: ProjectVersionResource,
  findingSource?: FindingSource
): FilterExpression | undefined => {
  const baseFilterExpression = getProjectRelatedFilterExpression(
    project,
    projectVersion
  );

  if (!baseFilterExpression) return;

  const findingFilterExpression =
    UIFindingUtils.getFindingFilterExpression(findingSource);

  if (!findingFilterExpression) return baseFilterExpression;

  return filterExpressionBuilders.and([
    baseFilterExpression,
    findingFilterExpression,
  ]);
};
