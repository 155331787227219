import { Stack, Typography } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '@endorlabs/ui-common';

import { AuthProviders } from '../../../../domains/Auth';
import { FormNewInvitation } from '../FormNewInvitation';

export interface InvitationDialogProps
  extends Omit<SimpleDialogProps, 'titleText'> {
  namespace: string;
  onSubmit: (fieldValues: FieldValues) => void;
}

export const InvitationDialog = ({
  namespace,
  onSubmit,
  ...props
}: InvitationDialogProps) => {
  const names: string[] = [];
  for (const provider in AuthProviders) {
    names.push(AuthProviders[provider].displayName);
  }

  return (
    <SimpleDialog
      {...props}
      titleText={
        <FM
          defaultMessage="Invite Your Team to {namespace}"
          values={{ namespace }}
        />
      }
    >
      <Stack spacing={2}>
        <Typography>
          <FM
            defaultMessage="Invitations must be sent to the <b>primary</b> email address associated with the login provider. We support {provider}."
            values={{
              b: (v) => <b>{v}</b>,
              provider: <FormattedList type="conjunction" value={names} />,
            }}
          />
        </Typography>

        <FormNewInvitation onSubmit={onSubmit} showSubmitButton />
      </Stack>
    </SimpleDialog>
  );
};
