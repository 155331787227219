import produce from 'immer';
import { has as _has, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Filter,
  FILTER_COMPARATORS_WITHOUT_VALUE,
  FilterExpression,
  isLogicFilter,
  isValueFilter,
  serialize,
} from '@endorlabs/filters';

import { parseFilterStateExpression } from '../utils';
import { useFilterParams } from './useFilterParams';

type FilterUpdateValue =
  | Partial<Pick<FilterState, 'search' | 'values'>>
  | FilterExpression
  | undefined;

const removeEmptyFilterValues = (values?: Map<string, Filter>) => {
  const clean = new Map<string, Filter>();
  values?.forEach((filter, id) => {
    if (isLogicFilter(filter) && filter.value.length === 0) return;
    if (
      isValueFilter(filter) &&
      !_has(FILTER_COMPARATORS_WITHOUT_VALUE, filter.comparator) &&
      'undefined' === typeof filter.value
    ) {
      return;
    }

    clean.set(id, filter);
  });

  return clean;
};

export type FilterStateValues = Map<string, Filter>;

export type FilterState = {
  expression: FilterExpression | undefined;
  search: string | undefined;
  values: FilterStateValues | undefined;
};

export const EMPTY_FILTER_STATE: FilterState = {
  expression: undefined,
  search: undefined,
  values: undefined,
};

export type UseFilterStateProps = {
  baseFilter?: FilterExpression;
  buildSearchFilter: (searchValue: string) => FilterExpression | undefined;
  defaultFilterValues?: FilterStateValues;
  searchKeys: string[];
  store?: ReturnType<typeof useFilterParams>;
};

export const useFilterState = ({
  baseFilter,
  buildSearchFilter,
  defaultFilterValues,
  searchKeys,
  store,
}: UseFilterStateProps) => {
  const [state, setState] = useState<FilterState>(() => {
    return store?.getState() ?? EMPTY_FILTER_STATE;
  });

  useEffect(() => {
    // sync with store, when provided
    if (!store) return;

    // Update from store on changes
    return store.subscribe((value) => {
      setState(value);
    });
  }, [store]);

  const updateFilter = useCallback(
    (updateValue: FilterUpdateValue) => {
      let next: FilterState = EMPTY_FILTER_STATE;

      // handle: set raw filter expression
      if ('string' === typeof updateValue) {
        const parsed = parseFilterStateExpression(
          updateValue,
          searchKeys,
          baseFilter
        );

        next = {
          expression: parsed.expression,
          search: parsed.search,
          values: parsed.values,
        };
      }

      // handle: filter state update
      if (updateValue && 'object' === typeof updateValue) {
        next = produce(state, (draft) => {
          // unset the raw expression if search or values are provided
          draft.expression = undefined;

          if ('search' in updateValue) {
            const clean = updateValue.search?.trim();

            // edge case: don't store the empty string
            draft.search = clean === '' ? undefined : clean;
          }

          if ('values' in updateValue) {
            draft.values = removeEmptyFilterValues(updateValue.values);
          }
        });
      }

      if (store) {
        store.setState(next);
      } else {
        setState(next);
      }
    },
    [store, searchKeys, baseFilter, state]
  );

  const filterExpression = useMemo(() => {
    const { expression: filterExpression, search: searchValue } = state;

    // set defaults
    let filterValues = state.values as FilterStateValues;
    if (defaultFilterValues) {
      filterValues = new Map(state.values);
      defaultFilterValues.forEach((filter, key) => {
        if (filterValues.has(key)) return;
        filterValues.set(key, filter);
      });
    }

    // if the filter state is a pre-formed expression, return that
    if (filterExpression) return filterExpression;

    // otherwise, build filter expression for the target resource from the current state
    const searchFilter = searchValue
      ? buildSearchFilter(searchValue)
      : undefined;

    const filter = filterValues?.size
      ? serialize(Array.from(filterValues.values()))
      : undefined;

    return [baseFilter, searchFilter, filter].filter(Boolean).join(' and ');
  }, [baseFilter, buildSearchFilter, defaultFilterValues, state]);

  const compareFilter = useCallback(
    (comparisonExpression: string) => {
      const comparisonFilter = parseFilterStateExpression(
        comparisonExpression,
        searchKeys,
        baseFilter
      );

      const filterValuesWithDefaults = new Map([
        // @ts-expect-error - aggressive feature check
        ...(comparisonFilter?.values ?? []),
        // @ts-expect-error - aggressive feature check
        ...(defaultFilterValues ?? []),
      ]);

      return isEqual(state.values, filterValuesWithDefaults);
    },
    [baseFilter, defaultFilterValues, searchKeys, state]
  );

  return useMemo(() => {
    // Consider parseable if the raw expression is not present in state
    const isParseable = !state.expression;

    let filterValues = state.values as FilterStateValues;

    // If not using a raw filter expression, apply default filter values
    if (isParseable && defaultFilterValues) {
      filterValues = new Map(state.values);
      defaultFilterValues.forEach((filter, key) => {
        if (filterValues.has(key)) return;
        filterValues.set(key, filter);
      });
    }

    return {
      _state: { ...state, values: filterValues },
      clearFilter: () => updateFilter(undefined),
      compareFilter,
      filter: filterExpression,
      isParseable,
      updateFilter,
    };
  }, [
    compareFilter,
    defaultFilterValues,
    filterExpression,
    state,
    updateFilter,
  ]);
};
