import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ProjectResource } from '@endorlabs/queries';
import {
  ButtonCancel,
  ControlledLabelsField,
  LabelsFieldValidationType,
} from '@endorlabs/ui-common';

export type FormProjectSettingsLabelsFieldValues = { meta: { tags: string[] } };

export const FormProjectSettingsLabels = ({
  isLoading,
  onSubmit,
  project,
}: {
  isLoading: boolean;
  onSubmit: (values: FormProjectSettingsLabelsFieldValues) => void;
  project?: ProjectResource;
}) => {
  const { formatMessage: fm } = useIntl();
  const {
    reset,
    control,
    handleSubmit: hookFormSubmit,
    watch,
  } = useForm<FormProjectSettingsLabelsFieldValues>({
    defaultValues: { meta: { tags: [] } },
  });

  const sortedTags = useMemo(
    () => (project?.meta.tags ?? []).sort((a, b) => a.localeCompare(b)),
    [project]
  );

  const handleReset = useCallback(() => {
    reset({ meta: { tags: sortedTags ?? [] } });
  }, [sortedTags, reset]);

  // on load, reset the form with the labels from the resource
  useEffect(() => handleReset(), [project, handleReset]);

  const hasLabelValues = watch('meta.tags').length > 0;

  return (
    <form onSubmit={hookFormSubmit(onSubmit)}>
      <Stack direction="column" spacing={4}>
        <ControlledLabelsField
          defaultValue={[]}
          control={control}
          name="meta.tags"
          placeholder={
            // hide placeholder when there are label values
            hasLabelValues
              ? undefined
              : fm({
                  defaultMessage:
                    'What tags do you want to assign this project?',
                })
          }
          helperText={fm({
            defaultMessage:
              'Tags must be 63 characters or less, and may contain only letters (A-Z), numbers (0-9), and the following characters (=@_.-)',
          })}
          validation={LabelsFieldValidationType.MetaTag}
        />

        <Stack direction="row" spacing={4}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            color="primary"
            variant="contained"
          >
            <FM defaultMessage="Save Tags" />
          </LoadingButton>

          <ButtonCancel onClick={handleReset}>
            <FM defaultMessage="Reset Tags" />
          </ButtonCancel>
        </Stack>
      </Stack>
    </form>
  );
};
