import { CircularProgress } from '@mui/material';
import { MakeGenerics, Navigate, useMatch } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListHuggingFaceModels } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  EmptyState,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import { OSSExplorerPageSource } from '../../domains/OSS';
import { NamedRoutes } from '../constants';
import { getOSSExplorerPath } from '../utils';

type HuggingFaceModelRedirectByProjectLocationGenerics = MakeGenerics<{
  Params: {
    projectUuid?: string;
  };
}>;

/**
 * Redirects to a HuggingFaceModel uuid from the given Project uuid
 */
export const HuggingFaceModelRedirectByProject = () => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { params } =
    useMatch<HuggingFaceModelRedirectByProjectLocationGenerics>();

  // NOTE: dependency name in url is encoded to prevent issues
  const projectUuid = params.projectUuid
    ? decodeURIComponent(params.projectUuid)
    : undefined;

  const qListHuggingFaceModels = useListHuggingFaceModels(
    NAMESPACES.OSS,
    {
      filter: filterExpressionBuilders.and([
        `spec.project_uuid=="${projectUuid}"`,
      ]),
      mask: ['tenant_meta.namespace', 'uuid'].join(','),
      page_size: 1, // get the first,
    },
    { enabled: !!projectUuid }
  );

  const redirectPath = useMemo(() => {
    const model = qListHuggingFaceModels.data?.objects[0];
    if (!model) return;

    return getOSSExplorerPath({
      section: OSSExplorerPageSource.HuggingFace,
      uuid: model.uuid,
    });
  }, [qListHuggingFaceModels.data]);

  const errorMessage = useMemo(() => {
    return qListHuggingFaceModels.isError
      ? getErrorMessage('GET', 'HuggingFaceModel', qListHuggingFaceModels.error)
      : undefined;
  }, [
    getErrorMessage,
    qListHuggingFaceModels.error,
    qListHuggingFaceModels.isError,
  ]);

  if (qListHuggingFaceModels.isLoading) {
    return (
      <EmptyState title={<FM defaultMessage="Loading&hellip;" />} size="large">
        <CircularProgress />;
      </EmptyState>
    );
  }

  if (qListHuggingFaceModels.isSuccess && redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  if (errorMessage) {
    return (
      <EmptyState
        title={errorMessage?.message}
        description={errorMessage?.details}
      >
        <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
          <FM defaultMessage="Return Home" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  return (
    <EmptyState
      title={<FM defaultMessage="Unable to Find Hugging Face Model" />}
      description={
        <FM defaultMessage="We were unable to find the hugging face model for the given project. Please try again or contact Endor Labs for support." />
      }
    >
      <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
        <FM defaultMessage="Return Home" />
      </ButtonLinkPrimary>
    </EmptyState>
  );
};
