import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecInvitationState } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDeleteButton,
  DataTableProps,
} from '@endorlabs/ui-common';

import { InvitationStatusLabel } from '../InvitationsStatusLabel';

export interface InvitationsTableRow {
  uuid: string;
  namespace: string;
  email: string;
  inviteStatus: SpecInvitationState;
  inviteDate?: string;
}

export interface InvitationsTableProps
  extends Omit<DataTableProps<InvitationsTableRow>, 'columns'> {
  activeNamespace: string;
  onDelete?: (row: InvitationsTableRow) => void;
}

const buildInvitationsTableColumns = ({
  activeNamespace,
  onDelete,
}: Pick<InvitationsTableProps, 'activeNamespace' | 'onDelete'>) => {
  const columns: DataTableColumnDef<InvitationsTableRow>[] = [
    {
      accessorKey: 'email',
      header: () => <FM defaultMessage="User Email" />,
      cell: ({ getValue, row }) => {
        const shouldHighlight = row.original.namespace === activeNamespace;

        return (
          <Typography component="span" fontWeight={shouldHighlight ? 600 : 0}>
            {getValue()}
          </Typography>
        );
      },
    },
    {
      accessorKey: 'inviteStatus',
      header: () => <FM defaultMessage="Invite Status" />,
      cell: ({ getValue }) => <InvitationStatusLabel value={getValue()} />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
      // Override header here to be consistent with other columns
      header: () => <FM defaultMessage="Invite Namespace" />,
    },
    {
      accessorKey: 'inviteDate',
      header: () => <FM defaultMessage="Invite Date" />,
      colType: ColTypes.DATE,
    },
  ];

  if (onDelete) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => (
        <Box display="flex" justifyContent="end">
          <DataTableDeleteButton
            onClick={() => row.original && onDelete(row.original)}
          />
        </Box>
      ),
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

export const InvitationsTable = ({
  activeNamespace,
  onDelete,
  ...props
}: InvitationsTableProps) => {
  const columns = useMemo(() => {
    return buildInvitationsTableColumns({ activeNamespace, onDelete });
  }, [activeNamespace, onDelete]);

  return <DataTable {...props} columns={columns} />;
};
