import { UseQueryResult } from 'react-query';

import {
  QueryJoinFilters,
  SortEntrySortEntryOrder,
} from '@endorlabs/api_client';

import { IQueryError, TResourceList } from '../types';

export const DEFAULT_UPDATE_MASK = 'meta.name,meta.description,meta.tags,spec';

export const buildUpdateReq = <T extends object>(
  data: T,
  mask = DEFAULT_UPDATE_MASK
) => {
  return {
    object: data,
    request: { update_mask: mask },
  };
};

export const isEmptyListQueryResult = (
  query: UseQueryResult<TResourceList<unknown>>
): boolean => {
  return query.isSuccess && query.data.list?.objects.length === 0;
};

/**
 * Helper to duck type Axios Errors
 */
export const isQueryError = (v: any): v is IQueryError => {
  return v && 'object' === typeof v && Reflect.has(v, 'isAxiosError');
};

export const sortParamBuilders = {
  ascendingBy: (path: string) => ({ path, order: SortEntrySortEntryOrder.Asc }),
  descendingBy: (path: string) => ({
    path,
    order: SortEntrySortEntryOrder.Desc,
  }),
} as const;

export type GroupResponseAggregationKey = {
  key: string;
  value: unknown;
};

export const queryJoinFiltersBuilders = {
  single: (kind: string, filter: string): QueryJoinFilters[] => [
    { kind, filter },
  ],
};

/**
 * Helper to parse a group aggregation key
 */
export const tryParseGroupResponseAggregationKey = (
  key: string
): GroupResponseAggregationKey[] => {
  try {
    return JSON.parse(key) as GroupResponseAggregationKey[];
  } catch (e) {
    // ignore parse error
  }

  return [];
};
