import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecFindingLevel,
  V1FindingCategory,
  V1FindingTags,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { Filter, FilterExpression, serialize } from '@endorlabs/filters';

import { FindingPriorityBucketNames } from '../../../domains/FindingPriorityBuckets';
import { DashboardBucketContext, DashboardBucketContexts } from '../types';

const vulnerabilityFilter: Filter = {
  comparator: 'CONTAINS',
  key: 'spec.finding_categories',
  value: [V1FindingCategory.Vulnerability],
};
const reachabilityFilter: Filter = {
  comparator: 'CONTAINS',
  key: 'spec.finding_tags',
  value: [
    V1FindingTags.ReachableFunction,
    V1FindingTags.PotentiallyReachableFunction,
  ],
};

export const DashboardChartFilterKeys = {
  ALL_FINDINGS: 'ALL_FINDINGS',
  ALL_VULNS: 'ALL_VULNS',
  REACHABLE_VULNS: 'REACHABLE_VULNS',
  COMMIT_COUNT: 'COMMIT_COUNT',
  OUTDATED_DEPS: 'OUTDATED_DEPS',
  UNMAINTAINED_DEPS: 'UNMAINTAINED_DEPS',
} as const;

export type DashboardChartFilterKey =
  (typeof DashboardChartFilterKeys)[keyof typeof DashboardChartFilterKeys];

export interface DashboardChartFilter {
  // Types of primary records applicable to this filter type
  allowedResourceKindsPrimary: ResourceKind[];
  // Should filter expect display of subcategories?
  allowSubcategories: boolean;
  // Filter applied to query
  filters?: Filter[];
  expression?: FilterExpression;
  // Canonical name for this filter type
  key: DashboardChartFilterKey;
  // Label for the filter type
  label: ReactNode;
  // The type of categorical record expected for this filter type
  resourceKindCategory: ResourceKind;
}

/**
 * All filters potentially available to individual dashboard charts.
 * Determine if a filter is applicable by comparing "primary" and "category" resource kinds. Is it a match?
 */
export const DashboardChartFilters: DashboardChartFilter[] = (
  [
    {
      allowedResourceKindsPrimary: [
        'DependencyMetadata',
        'PackageVersion',
        'Project',
      ],
      allowSubcategories: true,
      key: DashboardChartFilterKeys.ALL_FINDINGS,
      label: <FM defaultMessage="All Findings" />,
      resourceKindCategory: 'Finding',
    },
    {
      allowedResourceKindsPrimary: [
        'DependencyMetadata',
        'PackageVersion',
        'Project',
      ],
      allowSubcategories: true,
      filters: [vulnerabilityFilter],
      key: DashboardChartFilterKeys.ALL_VULNS,
      label: <FM defaultMessage="All Vulnerabilities" />,
      resourceKindCategory: 'Finding',
    },
    {
      allowedResourceKindsPrimary: [
        'DependencyMetadata',
        'PackageVersion',
        'Project',
      ],
      allowSubcategories: true,
      filters: [vulnerabilityFilter, reachabilityFilter],
      key: DashboardChartFilterKeys.REACHABLE_VULNS,
      label: <FM defaultMessage="Reachable Vulnerabilities" />,
      resourceKindCategory: 'Finding',
    },
    // {
    //   allowedResourceKindsPrimary: ['Project'],
    //   allowSubcategories: false,
    //   filter: undefined,
    //   key: DashboardChartFilterKeys.COMMIT_COUNT,
    //   label: <FM defaultMessage="# of commits (6 months)" />,
    //   resourceKindCategory: 'RepositoryCommit',
    // },
    {
      allowedResourceKindsPrimary: ['PackageVersion', 'Project'],
      allowSubcategories: false,
      filters: [
        { comparator: 'EQUAL', key: 'meta.name', value: 'outdated_release' },
      ],
      key: DashboardChartFilterKeys.OUTDATED_DEPS,
      label: <FM defaultMessage="Outdated Dependencies" />,
      resourceKindCategory: 'Finding',
    },
    {
      allowedResourceKindsPrimary: ['PackageVersion', 'Project'],
      allowSubcategories: false,
      filters: [
        {
          comparator: 'EQUAL',
          key: 'meta.name',
          value: 'archived_source_code_repo',
        },
      ],
      key: DashboardChartFilterKeys.UNMAINTAINED_DEPS,
      label: <FM defaultMessage="Unmaintained Dependencies" />,
      resourceKindCategory: 'Finding',
    },
  ] satisfies DashboardChartFilter[]
).map((item) => ({
  ...item,
  expression: item.filters?.length ? serialize(item.filters) : undefined,
}));

export const DashboardChartFilterPrimaryKeyMap: Record<
  Extract<ResourceKind, 'Finding' | 'RepositoryCommit'>,
  Record<
    Extract<ResourceKind, 'PackageVersion' | 'Project' | 'DependencyMetadata'>,
    string
  >
> = {
  Finding: {
    DependencyMetadata: 'spec.target_dependency_package_name',
    PackageVersion: 'meta.parent_uuid',
    Project: 'spec.project_uuid',
  },
  // @ts-expect-error - PackageVersion is not valid here
  RepositoryCommit: {
    Project: 'meta.parent_uuid',
  },
};

export interface DashboardChartCategoryControl {
  bucketContext: DashboardBucketContext;
  exclusive: boolean;
  filterOptions: string[];
  initialValue: string[];
  key: string;
  resourceKinds: ResourceKind[];
}

/**
 * A list of definitions for individual chart controls—typically category toggles
 */
export const DashboardChartCategoryControls: DashboardChartCategoryControl[] = [
  {
    bucketContext: DashboardBucketContexts.FINDING_SEVERITY,
    exclusive: false,
    filterOptions: [
      SpecFindingLevel.Critical,
      SpecFindingLevel.High,
      SpecFindingLevel.Medium,
      SpecFindingLevel.Low,
    ],
    initialValue: [SpecFindingLevel.Critical, SpecFindingLevel.High],
    key: 'FindingLevelToggles',
    resourceKinds: ['Finding'],
  },
  {
    bucketContext: DashboardBucketContexts.FINDING_PRIORITY_BUCKETS,
    exclusive: false,
    filterOptions: [
      FindingPriorityBucketNames.PRIORITIZE,
      FindingPriorityBucketNames.PLAN,
      FindingPriorityBucketNames.BACKLOG,
      FindingPriorityBucketNames.DEFER,
    ],
    initialValue: [
      FindingPriorityBucketNames.PRIORITIZE,
      FindingPriorityBucketNames.PLAN,
    ],
    key: 'FindingPriorityToggles',
    resourceKinds: ['Finding'],
  },
];
