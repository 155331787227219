import { Skeleton } from '@mui/material';

import { PackageVersionNameDisplay, RowStack } from '@endorlabs/ui-common';

export const PackageVersionDetailDrawerHeader = ({
  packageVersionName,
  isLoading,
  namespace,
}: {
  packageVersionName: string;
  isLoading: boolean;
  namespace: string;
}) => {
  return (
    <RowStack flexWrap="nowrap">
      {isLoading ? (
        <>
          <Skeleton height={40} width={44} />
          <Skeleton height={40} width="60%" />
        </>
      ) : (
        <PackageVersionNameDisplay
          packageVersion={{ meta: { name: packageVersionName } }}
          namespace={namespace}
          size="xlarge"
          lineBreak="auto"
          iconFrame
          showIcon
          showVersion
        />
      )}
    </RowStack>
  );
};
