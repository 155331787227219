import {
  PolicyAdmissionAction,
  PolicyFindingAction,
  PolicyPolicyType,
  PolicyTemplateParameter,
  V1Policy,
} from '@endorlabs/api_client';

export const PolicyUmbrellaTypes = {
  ACTION: 'actions',
  EXCEPTION: 'exceptions',
  FINDING: 'findings',
  REMEDIATION: 'remediations',
} as const;

export type PolicyUmbrellaType =
  (typeof PolicyUmbrellaTypes)[keyof typeof PolicyUmbrellaTypes];

export const AllowedTemplateTypesByPolicyUmbrellaType: Record<
  PolicyUmbrellaType,
  PolicyPolicyType[]
> = {
  [PolicyUmbrellaTypes.ACTION]: [
    PolicyPolicyType.Admission,
    PolicyPolicyType.Notification,
  ],
  [PolicyUmbrellaTypes.EXCEPTION]: [PolicyPolicyType.Exception],
  [PolicyUmbrellaTypes.FINDING]: [PolicyPolicyType.UserFinding],
  [PolicyUmbrellaTypes.REMEDIATION]: [PolicyPolicyType.Remediation],
};

export const PolicyRuleCreationModes = {
  TEMPLATE: 'TEMPLATE',
  FILE: 'FILE',
  EDITOR: 'EDITOR',
} as const;

export type PolicyRuleCreationMode =
  (typeof PolicyRuleCreationModes)[keyof typeof PolicyRuleCreationModes];

export type FormUpsertPolicyFieldValues = V1Policy & {
  spec: {
    admission?: PolicyAdmissionAction;
    finding?: PolicyFindingAction;
    policy_type: PolicyPolicyType;
    template_values: { [key: string]: { values: string[] } };
  };
};

// Convenience type that allows pre-existing policy template values to accompany template parameters
export type PolicyTemplateParameterField = PolicyTemplateParameter & {
  values?: string[];
};

export type SASTListFilterType = {
  definedByFilters: string[];
  confidenceFilters: string[];
  languageFilters: string[];
  endorTagsFilters: string[];
};
