import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useListAssuredPackageVersions } from '@endorlabs/queries';
import {
  ButtonLinkSecondary,
  ButtonStack,
  PackageVersionNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { useAuthInfo } from '../../../../providers';
import { getAssuredPackageVersionPath } from '../../../../routes';
import { VersionUpgradeDisplay } from '../../../Remediations';
import { AssuredPackageVersionPatchesSection } from '../AssuredPackageVersionPatchesSection';
import { PatchConfigurationDialogButton } from '../PatchConfigurationDialogButton';
import { AssuredPackageVersionDetailDrawerOverview } from './AssuredPackageVersionDetailDrawerOverview';

const AssuredPackageVersionDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Overview" />,
    value: 'overview',
  },
  {
    label: <FM defaultMessage="Patches" />,
    value: 'patches',
  },
];

export type AssuredPackageVersionDetailDrawerProps = {
  namespace: string;
  packageVersionName: string;
};
/**
 * Detail Info Drawer for an AssuredPackageVersion
 */
export const AssuredPackageVersionDetailDrawer = ({
  namespace,
  packageVersionName,
}: AssuredPackageVersionDetailDrawerProps) => {
  const { space } = useTheme();
  const { activeNamespace } = useAuthInfo();

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    namespace,
    {
      filter: `meta.name=="${packageVersionName}"`,
      page_size: 1,
    },
    { enabled: !!packageVersionName }
  );

  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects.at(0);

  // Strip the `-endor-latest` suffix when displaying as an upgrade
  const { version: toVersion } =
    UIPackageVersionUtils.parsePackageName(packageVersionName);
  const fromVersion = toVersion.replace(/-endor-latest$/, '');
  const showVersionUpgrade = toVersion && toVersion !== fromVersion;

  return (
    <DetailDrawerContainer
      headerProps={{
        title: (
          <Stack gap={space.sm}>
            <Stack gap={space.xs}>
              <PackageVersionNameDisplay
                packageVersion={{ meta: { name: packageVersionName } }}
                size="xlarge"
              />

              {showVersionUpgrade && (
                <VersionUpgradeDisplay
                  fromVersion={fromVersion}
                  toVersion={toVersion}
                />
              )}
            </Stack>

            <ButtonStack>
              <PatchConfigurationDialogButton namespace={namespace} />

              <ButtonLinkSecondary
                linkProps={{
                  to: getAssuredPackageVersionPath({
                    namespace: activeNamespace,
                    packageVersionName,
                  }),
                }}
                sx={{ width: 'fit-content' }}
              >
                <FM defaultMessage="View Details" />
              </ButtonLinkSecondary>
            </ButtonStack>
          </Stack>
        ),
      }}
      tabProps={
        // TODO: add loading and empty states
        assuredPackageVersion && {
          disablePadding: true,
          id: 'AssuredPackageVersionDetailDrawer',
          tabRecords: AssuredPackageVersionDetailDrawerTabRecords,
          tabPanelMap: {
            overview: (
              <AssuredPackageVersionDetailDrawerOverview
                assuredPackageVersion={assuredPackageVersion}
              />
            ),
            patches: (
              <AssuredPackageVersionPatchesSection
                assuredPackageVersion={assuredPackageVersion}
              />
            ),
          },
        }
      }
    />
  );
};
