import { V1RequestStatus } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';

export const ResourceKindList: ResourceKind[] = [
  'AuthorizationPolicy',
  'EndorLicense',
];

export const ServiceRequestStatusColorMap: {
  [key in V1RequestStatus]?: 'error' | 'success' | 'warning';
} = {
  [V1RequestStatus.Approved]: 'success',
  [V1RequestStatus.Completed]: 'success',
  [V1RequestStatus.Denied]: 'error',
  [V1RequestStatus.Failed]: 'error',
  [V1RequestStatus.Unspecified]: 'warning',
};

export const TemporaryResourceKindList: ResourceKind[] = [
  'AuthorizationPolicy',
  'EndorLicense',
];
