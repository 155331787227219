import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecInvitationState } from '@endorlabs/api_client';
import {
  InvitationResource,
  useCountInvitations,
  useDeleteInvitation,
  useListInvitations,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  EmptyState,
  useAppNotify,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  InvitationDialog,
  InvitationsTable,
  InvitationsTableRow,
  useInvitationDialog,
} from '../../domains/Invitation';

export interface InvitationsViewProps {
  namespace: string;
}

export const InvitationsView = ({ namespace }: InvitationsViewProps) => {
  const qInvitationsCount = useCountInvitations(namespace);

  const paginator = useDataTablePaginator({
    totalCount: qInvitationsCount.data?.count,
  });

  const qInvitationsList = useListInvitations(
    namespace,
    {},
    { ...paginator.getListParameters() }
  );

  const invitations = useMemo(() => {
    return (
      qInvitationsList.data?.list?.objects.map((o) =>
        mapToInvitationsTableRow(o)
      ) ?? []
    );
  }, [qInvitationsList.data]);
  const addAppNotification = useAppNotify();

  const {
    getInvitationDialogProps,
    openInvitationDialog,
    resetInvitationDialog,
  } = useInvitationDialog({ namespace, onNotification: addAppNotification });

  const isEmptyState =
    !qInvitationsList.isLoading && qInvitationsCount.data?.count === 0;

  const qDeleteInvitation = useDeleteInvitation({
    onSettled: () => {
      resetInvitationDialog();
    },
  });

  const handleDeleteInvitation = (row?: InvitationsTableRow) => {
    // TODO: handle possible edge case
    if (!row) return;

    qDeleteInvitation.mutate({ namespace: row.namespace, uuid: row.uuid });
  };

  const InvitationDeleteConfirmation =
    useConfirmationDialog<InvitationsTableRow>({
      confirmText: <FM defaultMessage="Delete" />,
      isDestructive: true,
      onConfirm: handleDeleteInvitation,
      titleText: <FM defaultMessage="Delete this Invitation?" />,
    });

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="medium"
              title={
                <FM defaultMessage="You have not invited any teammates to your tenant" />
              }
              description={
                <FM defaultMessage="Track and manage all points of access to the Endor Labs UI." />
              }
            >
              <ButtonPrimary onClick={openInvitationDialog}>
                <FM defaultMessage="Invite Your Team" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && (
          <Grid item>
            <Card>
              <CardHeader
                action={
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <ButtonPrimary onClick={openInvitationDialog}>
                      <FM defaultMessage="Invite Your Team" />
                    </ButtonPrimary>
                  </Stack>
                }
              />

              <CardContent>
                <InvitationsTable
                  activeNamespace={namespace}
                  data={invitations}
                  enablePagination
                  isLoading={qInvitationsList.isLoading}
                  onDelete={InvitationDeleteConfirmation.openDialog}
                  paginator={paginator}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      <InvitationDialog {...getInvitationDialogProps()} />
      <InvitationDeleteConfirmation.Dialog
        {...InvitationDeleteConfirmation.dialogProps}
      />
    </>
  );
};

const mapToInvitationsTableRow = ({
  meta,
  spec,
  uuid,
  tenant_meta,
}: InvitationResource): InvitationsTableRow => {
  return {
    uuid: uuid,
    namespace: tenant_meta.namespace,
    email: spec.user_email,
    inviteDate: meta.create_time,
    inviteStatus: spec.invitation_state as SpecInvitationState,
  };
};
