import produce from 'immer';
import _get from 'lodash-es/get';
import _last from 'lodash-es/last';
import _merge from 'lodash-es/merge';
import _pick from 'lodash-es/pick';
import _set from 'lodash-es/set';
import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryResult,
} from 'react-query';

import {
  QueryQuerySpec,
  QueryServiceApi,
  V1ListParameters,
  V1Meta,
  V1Query,
  V1QueryReference,
  V1TenantMeta,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions } from './types';
import { getClientConfiguration } from './utils';

const apiService = () => new QueryServiceApi(getClientConfiguration());

interface InternalBuiltQuery {
  kind: ResourceKind;
  list_parameters: V1ListParameters;
  references: V1QueryReference[];
}

interface QueryCallBuilderState {
  builtQuery: InternalBuiltQuery;
}

/**
 * Interface that conflates some QueryReference/QuerySpec parameters
 * into a single options object useful when adding a reference.
 */
interface ReferenceConnectionOptions {
  connect_from?: string;
  connect_to?: string;
  return_as?: string;
}

/**
 * Public interface returned when building a query
 */
export interface QueryCallBuilder {
  addReference: (
    resourceKind: ResourceKind,
    listParams?: V1ListParameters,
    referenceOptions?: ReferenceConnectionOptions
  ) => QueryCallBuilder;

  getBuiltQuery: (namespace: string, listParams?: V1ListParameters) => V1Query;

  getQueryKey: (namespace: string, listParams?: V1ListParameters) => QueryKey;

  useBuiltQuery: (
    namespace: string,
    queryOptions?: ResourceQueryOptions<V1Query>
  ) => UseQueryResult<V1Query>;

  useSuccessiveQuery: (
    namespace: string,
    queryOptions?: UseInfiniteQueryOptions<V1Query>
  ) => UseQueryResult<V1Query>;
}

/**
 * Build a QueryService API call using chained methods & output a ReactQuery hook.
 *
 * EXAMPLE:
 * const qMySpecialQuery = buildQueryCall('PackageVersion')
 * .addReference('Metric')
 * .addReference('DependencyMetadata', { page_size: 1 })
 * .useBuiltQuery(namespace, { enabled: true, onSuccess: () => {} })
 *
 * @param primaryResourceName Base resource on which to build the query
 * @param listParams List params for primary resource
 * @returns
 */
export function buildQueryCall(
  primaryResource: ResourceKind,
  listParams: V1ListParameters = {}
) {
  // Internal state
  const _internal: QueryCallBuilderState = {
    builtQuery: buildInitialQueryCall(primaryResource, listParams),
  };

  /** Axios call for the built query */
  const sendQueryCall = async (
    namespace: string,
    query: V1Query,
    signal?: AbortSignal
  ) => {
    const resp = await apiService().queryServiceCreateQuery(namespace, query, {
      // pass abort signal to Axios, to support request cancellation on param changes
      signal,
    });

    return resp.data;
  };

  /**
   * Public interface
   */
  const self: QueryCallBuilder = {
    /**
     * Adds a reference clause to the existing query call
     * @param resourceName
     * @param listParams
     * @param connectParams - Override connect properties
     */
    addReference: (resourceKind, listParams = {}, referenceOptions = {}) => {
      const refOptions = _merge(
        { ...DEFAULT_REFERENCE_OPTIONS },
        referenceOptions
      );

      // Extract connect from/to params, which are output higher in the ref definition
      const { return_as, ...connectParams } = refOptions;

      // Establish query reference object
      const newReference: V1QueryReference = {
        ...connectParams,
        query_spec: {
          kind: resourceKind,
          list_parameters: listParams,
        },
      };

      // Add a return_as name if specified
      if (return_as && newReference.query_spec) {
        newReference.query_spec.return_as = return_as;
      }

      // Add reference
      _internal.builtQuery.references.push(newReference);

      return self;
    },

    getQueryKey: (namespace, params) => {
      return [
        finalizeQueryCall(_internal.builtQuery, namespace, params),
      ] as QueryKey;
    },

    // Return the pure query call POST object. For debugging mostly.
    getBuiltQuery: (namespace, params) => {
      return finalizeQueryCall(_internal.builtQuery, namespace, params);
    },

    /**
     * Return a standard useQuery hook using the constructed query.
     * NOTE: Uses the entire QuerySpec as the query key.
     * @param namespace
     * @param opts - Standard @tanstack/query hook options
     * @returns A @tanstack/query hook
     */
    useBuiltQuery: (namespace, opts = {}) => {
      const { kind, list_parameters } = _internal.builtQuery;
      const requestParameters = useBuildReadRequestParameters(
        kind,
        'LIST',
        list_parameters,
        opts
      );

      const finalizedQuery = finalizeQueryCall(
        _internal.builtQuery,
        namespace,
        requestParameters
      );

      return useQuery(
        [finalizedQuery] as QueryKey,
        (ctx) => sendQueryCall(namespace, finalizedQuery, ctx.signal),
        opts
      );
    },

    /**
     * Make calls with successive page tokens until all objects are returned.
     */
    useSuccessiveQuery: (namespace, opts = {}) => {
      const { kind, list_parameters } = _internal.builtQuery;
      const requestParameters = useBuildReadRequestParameters(
        kind,
        'LIST',
        list_parameters,
        opts
      );

      const finalizedQuery = finalizeQueryCall(
        _internal.builtQuery,
        namespace,
        requestParameters
      );

      const getPageToken = (pageData?: V1Query) => {
        if (!pageData) return undefined;

        return _get(
          pageData,
          'spec.query_response.list.response.next_page_token'
        );
      };

      const infiniteQueryResult = useInfiniteQuery<V1Query>(
        // Query key
        ['infinite', finalizedQuery] as QueryKey,

        // Network call, but update page token using infiniteQuery pageParam
        ({ pageParam = 0, signal }) => {
          const pageQuery = produce(finalizedQuery, (draft) => {
            _set(
              draft,
              'spec.query_spec.list_parameters.page_token',
              pageParam
            );
          });

          return sendQueryCall(namespace, pageQuery, signal);
        },

        // Query options
        {
          ...opts,
          getNextPageParam: (lastPage) => {
            return getPageToken(lastPage);
          },

          onSuccess: (data) => {
            const token = data
              ? getPageToken(_last(data?.pages ?? []) as V1Query)
              : undefined;

            if (
              token &&
              infiniteQueryResult.hasNextPage !== false //&&
              // !infiniteQueryResult.isFetchingNextPage
            ) {
              infiniteQueryResult.fetchNextPage();
            }
          },
        }
      );

      const pages = (infiniteQueryResult?.data?.pages ?? []) as V1Query[];
      const lastPage = _last(pages);

      const returnedObjects = pages.flatMap(
        (page) => page.spec?.query_response?.list.objects ?? []
      );
      const returnedResponse = lastPage?.spec?.query_response?.list?.response;

      const returnData: V1Query = {
        meta: lastPage?.meta as V1Meta,
        tenant_meta: lastPage?.tenant_meta as V1TenantMeta,
        spec: {
          query_response: {
            list: {
              objects: returnedObjects,
              response: returnedResponse,
            },
          },
          query_spec: lastPage?.spec?.query_spec,
        },
      };

      const hasMorePages = Boolean(getPageToken(lastPage));

      const returnedQueryResult = _set(
        infiniteQueryResult,
        'data',
        returnData
      ) as UseQueryResult<V1Query>;

      // Treat as still in loading state if there are more pages to fetch
      if (hasMorePages) {
        _set(returnedQueryResult, 'isLoading', true);
        _set(returnedQueryResult, 'isSuccess', false);
        _set(returnedQueryResult, 'status', 'loading');
      }

      return returnedQueryResult;
    },
  };

  return self;
}

/** Establish internal query call object */
const buildInitialQueryCall = (
  primaryResource: ResourceKind,
  listParams: V1ListParameters = {}
) => {
  return {
    kind: primaryResource,
    list_parameters: listParams,
    references: [],
  };
};

// Default to the most common join clause
const DEFAULT_REFERENCE_OPTIONS = {
  connect_from: 'uuid',
  connect_to: 'meta.parent_uuid',
};

/** Organizes internally-built queryCall object into a well-formed query spec */
const finalizeQueryCall = (
  builtQuery: QueryQuerySpec,
  namespace: string,
  listParameters?: V1ListParameters
) => {
  const finalListParameters = _merge(
    {},
    listParameters,
    builtQuery.list_parameters
  );
  const finalReferences = finalizeReferences(builtQuery, finalListParameters);

  return {
    meta: { name: nameQuery(builtQuery) },
    spec: {
      query_spec: {
        kind: builtQuery.kind,
        list_parameters: finalListParameters,
        references: finalReferences,
      },
    },
    tenant_meta: { namespace },
  };
};

/** Builds the final references for the query  */
const finalizeReferences = (
  builtQuery: QueryQuerySpec,
  listParameters?: V1ListParameters
) => {
  if (!builtQuery.references?.length) return builtQuery.references;

  const commonListParameters = _pick(listParameters, ['traverse']);
  return builtQuery.references?.map((ref) => {
    if (!ref.query_spec) return ref;

    return {
      ...ref,
      query_spec: {
        ...ref.query_spec,
        list_parameters: {
          ...commonListParameters,
          ...ref.query_spec?.list_parameters,
        },
      },
    } satisfies V1QueryReference;
  });
};

/** Generates a meta.name for the query based on accessed resource names */
const nameQuery = (builtQuery: QueryQuerySpec) => {
  const references = builtQuery?.references ?? [];

  const referenceClause =
    references.length > 0
      ? ', referencing ' +
        references.map((ref) => ref?.query_spec?.kind).join(', ')
      : '';

  return `QueryService call for ${builtQuery.kind}${referenceClause}`;
};
