import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyResource, PolicyTemplateResource } from '@endorlabs/queries';
import { PolicyOnboardingPopover } from '@endorlabs/ui-common';

import { FormUpsertPolicy as formMessages } from '../locales';
import { PolicyUmbrellaTypes } from '../types';
import { PolicyNameFields } from './PolicyNameFields';
import { PolicyProjectsDrawerButton } from './PolicyProjectsDrawerButton';
import { PolicyRuleFields } from './PolicyRuleFields';
import { PolicyScopeFields } from './PolicyScopeFields';
import { RemediationPolicyFields } from './RemediationPolicyFields';
import { StepAccordion } from './StepAccordion';
import { StepHeaderButton } from './StepHeaderButton';

const messages = formMessages[PolicyUmbrellaTypes.REMEDIATION];

interface RemediationPolicyStepsProps {
  activeTemplate?: PolicyTemplateResource;
  policy?: PolicyResource;
  policyTemplates: PolicyTemplateResource[];
}

/**
 * Numbered steps and form fields to create an Remediation policy
 */
export const RemediationPolicySteps = ({
  activeTemplate,
  policy,
  policyTemplates,
}: RemediationPolicyStepsProps) => {
  const { space } = useTheme();

  const [expandedSteps, setExpandedSteps] = useState([0, 1, 2, 3]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleToggle = useCallback(
    (stepNum: number) => {
      expandedSteps.includes(stepNum)
        ? setExpandedSteps(expandedSteps.filter((s) => s !== stepNum))
        : setExpandedSteps(expandedSteps.concat([stepNum]));
    },
    [expandedSteps]
  );

  return (
    <>
      <PolicyOnboardingPopover
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      <StepAccordion
        expanded={expandedSteps.includes(0)}
        handleToggle={handleToggle}
        stepNumber={0}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={0}
            text={<FM defaultMessage="Define a Policy" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <PolicyRuleFields
            activeTemplate={activeTemplate}
            policy={policy}
            policyTemplates={policyTemplates}
            policyUmbrellaType={PolicyUmbrellaTypes.REMEDIATION}
          />
        </AccordionDetails>
      </StepAccordion>

      <StepAccordion
        expanded={expandedSteps.includes(1)}
        handleToggle={handleToggle}
        stepNumber={1}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={1}
            text={<FM defaultMessage="Choose an Action" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="flex-start"
            spacing={space.md}
            sx={{ paddingTop: space.sm }}
          >
            <RemediationPolicyFields />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      <StepAccordion
        expanded={expandedSteps.includes(2)}
        handleToggle={handleToggle}
        stepNumber={2}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={2}
            text={<FM defaultMessage="Assign Scope" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="flex-start"
            spacing={space.md}
            sx={{ paddingTop: space.sm }}
          >
            <PolicyScopeFields />

            <PolicyProjectsDrawerButton />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      <StepAccordion
        expanded={expandedSteps.includes(3)}
        handleToggle={handleToggle}
        stepNumber={3}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={3}
            text={<FM {...messages.namePolicy} />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack alignItems="flex-start" spacing={space.md}>
            <PolicyNameFields />
          </Stack>
        </AccordionDetails>
      </StepAccordion>
    </>
  );
};
