import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Row } from '@tanstack/react-table';
import { formatISO } from 'date-fns';
import produce from 'immer';
import noop from 'lodash-es/noop';
import omitBy from 'lodash-es/omitBy';
import _set from 'lodash-es/set';
import sortBy from 'lodash-es/sortBy';
import without from 'lodash-es/without';
import { useState } from 'react';
import { FieldNamesMarkedBoolean } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  SpecPackageManagerStatusState,
  V1PackageManager,
} from '@endorlabs/api_client';
import {
  useCreatePackageManager,
  useDeletePackageManager,
  useListPackageManagers,
  useUpdatePackageManager,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  EmptyState,
  getObjectKeys,
  useAppNotify,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import { getIntegrationsRootPath, useFullMatch } from '../../routes';
import {
  AuthLoginPackageManagerKey,
  PACKAGE_MANAGERS,
  PackageManagerFieldsInForm,
  PackageManagerKey,
  PackageManagerSpecFieldMasks,
  PackageManagersWithPasswordAuth,
  PackageManagersWithTokenAuth,
  PackageManagerWithEnabledAWSCodeArtifact,
  PackagistPackageManagerKey,
  TokenLoginPackageManagerKey,
} from './constants';
import { FormUpsertPackagistPackageManager } from './FormUpsertPackagistPackageManager';
import { FormUpsertPasswordAuthPackageManager } from './FormUpsertPasswordAuthPackageManager';
import { FormUpsertTokenAuthPackageManager } from './FormUpsertTokenAuthPackageManager';
import { PackageManagerDeleteConfirmationDialog } from './PackageManagerDeleteConfirmation';
import {
  PackageManagersTable,
  PackageManagersTableRow,
} from './PackageManagersTable';
import {
  AllPackageManagerFieldKeys,
  FormUpsertPackageManagerFieldValues,
  PackageManagerAuthMode,
} from './types';

const messages = defineMessages({
  addPackageManager: { defaultMessage: 'Add Package Manager' },
  createError: {
    defaultMessage: 'Could not create this package manager. {err}',
  },
  createSuccess: { defaultMessage: 'Package manager created successfully' },
  deleteError: {
    defaultMessage: 'Package manager could not be deleted. {err}',
  },
  deleteSuccess: { defaultMessage: 'Package manager deleted' },
  updateError: {
    defaultMessage: 'Could not update this package manager. {err}',
  },
  updateSuccess: { defaultMessage: 'Package manager updated successfully' },
});

/**
 * Individual page to handle CRUD of package managers of a given type (Maven, NPM, etc)
 */
export const PackageManagerSettingsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { packageManagerName },
  } = useFullMatch();
  const addAppNotification = useAppNotify();

  const packageManagerKey = packageManagerName as PackageManagerKey;

  /**
   * Dialog is open if there's an object in state.
   * For `update` & `delete` modes, a `uuid` must be present.
   */
  const [editingPackageManager, setEditingPackageManager] = useState<
    { uuid?: string; action?: 'create' | 'update' | 'delete' } | undefined
  >(undefined);

  const qPackageManagersList = useListPackageManagers(
    tenantName,
    {},
    {
      mask: [
        'uuid',
        'meta',
        'tenant_meta',
        PackageManagerSpecFieldMasks,
        'propagate',
      ].join(','),
    }
  );

  // Filter to only package managers with a url matching the current package manager type
  const activePackageManagers =
    qPackageManagersList.data?.list?.objects.filter(
      (pm) => !!pm?.spec[packageManagerKey]
    ) ?? [];

  // Sort by priority
  const displayedPms = sortBy(
    activePackageManagers.map(
      (pm) =>
        ({
          uuid: pm.uuid,
          name: pm.meta.name,
          status: pm.spec.package_manager_status?.state,
          last_tested: pm.spec.package_manager_status?.last_tested_at,
          auth_type: pm.spec.auth_provider
            ? PackageManagerAuthMode.AWS
            : PackageManagerAuthMode.Basic,
          ...pm.spec[packageManagerKey],
        } as PackageManagersTableRow)
    ),
    ['priority']
  );

  const packageManagerUnderEdit = editingPackageManager
    ? activePackageManagers.find(
        (pm) => pm.uuid === editingPackageManager?.uuid
      )
    : undefined;

  const handleEditDialog = (row: PackageManagersTableRow) => {
    setEditingPackageManager({ uuid: row.uuid, action: 'update' });
  };

  const handleDeleteDialog = (row: PackageManagersTableRow) => {
    setEditingPackageManager({ uuid: row.uuid, action: 'delete' });
  };

  const handleConfirmDelete = () => {
    const pm = activePackageManagers.find(
      (pm) => pm.uuid === editingPackageManager?.uuid
    );
    if (!pm) return;

    qPackageManagerDelete.mutate({
      namespace: pm.tenant_meta.namespace,
      uuid: pm.uuid,
    });
  };

  const closeDialog = () => setEditingPackageManager(undefined);

  const packageManagerStatusNotification = (pm: V1PackageManager) => {
    if (
      pm.spec.package_manager_status?.state ===
      SpecPackageManagerStatusState.Fail
    ) {
      const message = pm.spec.package_manager_status?.error_message;
      addAppNotification({
        message: <FM defaultMessage="{error}" values={{ error: message }} />,
        severity: 'warning',
      });
    }
  };

  const qPackageManagerCreate = useCreatePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.createError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: (pm) => {
      addAppNotification({
        message: <FM {...messages.createSuccess} />,
        severity: 'success',
      });

      packageManagerStatusNotification(pm);
      closeDialog();
    },
  });

  const qPackageManagerUpdate = useUpdatePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.updateError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: (pm) => {
      packageManagerStatusNotification(pm);
      closeDialog();
    },
  });
  const qPackageManagerDelete = useDeletePackageManager({
    onError: (err) => {
      addAppNotification({
        message: (
          <FM
            {...messages.deleteError}
            values={{ err: err.response.data?.message }}
          />
        ),
        severity: 'error',
      });
      closeDialog();
    },
    onSuccess: () => {
      addAppNotification({
        message: <FM {...messages.deleteSuccess} />,
        severity: 'success',
      });
      closeDialog();
    },
  });

  /**
   * Form submission
   */
  const onSubmit = (
    formData: FormUpsertPackageManagerFieldValues,
    dirtyFields: FieldNamesMarkedBoolean<FormUpsertPackageManagerFieldValues>
  ) => {
    const updateUuid = editingPackageManager?.uuid;

    // define specific mask string for spec.${packageManagerKey}.<field>
    const updatedFields: string[] = without(
      getObjectKeys(dirtyFields, true),
      'name',
      'propagate',
      'auth_provider'
    );
    const updatedMaskFields: string = updatedFields
      .map((field) => `spec.${packageManagerKey}.${field}`)
      .join(',');
    const packageManagerSpecificMask =
      updatedMaskFields !== '' ? `,${updatedMaskFields}` : '';

    // HACK: split shared fields from package manager-specific spec fields
    const { name, propagate, auth_provider, ...updatedPackageManager } =
      formData;
    const meta = { name: name ?? '' };
    const existingPm = activePackageManagers.find(
      (pm) => pm.uuid === updateUuid
    );

    if (existingPm) {
      //Filter key value pair not updated in form
      const restValues = omitBy(
        existingPm.spec[packageManagerKey],
        (_, key) => {
          const formKey = key as AllPackageManagerFieldKeys;
          return PackageManagerFieldsInForm.includes(formKey);
        }
      );

      const updatedPm = auth_provider
        ? {
            ...existingPm,
            meta,
            spec: {
              auth_provider: {
                package_manager_type:
                  PackageManagerWithEnabledAWSCodeArtifact[packageManagerKey],
                ...auth_provider,
              },
              [packageManagerKey]: {
                priority: updatedPackageManager.priority,
              },
            },
            propagate,
          }
        : {
            ...existingPm,
            meta,
            spec: {
              [packageManagerKey]: {
                ...restValues,
                ...updatedPackageManager,
              },
            },
            propagate,
          };

      _set(
        updatedPm,
        ['spec', 'package_manager_status', 'last_tested_at'],
        formatISO(Date.now())
      );

      qPackageManagerUpdate.mutate({
        namespace: existingPm.tenant_meta.namespace,
        resource: updatedPm,
        mask: `meta.name,spec.auth_provider,spec.package_manager_status.last_tested_at,propagate${packageManagerSpecificMask}`,
      });
    } else {
      const newPm = auth_provider
        ? {
            meta,
            spec: {
              auth_provider: {
                package_manager_type:
                  PackageManagerWithEnabledAWSCodeArtifact[packageManagerKey],
                ...auth_provider,
              },
              [packageManagerKey]: {
                priority: activePackageManagers.length,
              },
            },
            propagate,
          }
        : {
            meta,
            spec: {
              [packageManagerKey]: {
                ...updatedPackageManager,
                priority: activePackageManagers.length,
              },
            },
            propagate,
          };

      qPackageManagerCreate.mutate({
        namespace: tenantName,
        resource: newPm,
      });
    }
  };

  /**
   * Update all rows to match new priority order
   */
  // Separate named query to avoid multiple app notifications for every reordered manager
  const qUpdateOrder = useUpdatePackageManager({ onSuccess: noop });
  const handleReorder = (rows: Row<PackageManagersTableRow>[]) => {
    rows.forEach((row, newIndex) => {
      const pm = activePackageManagers.find(
        (pm) => pm.uuid === row.original?.uuid
      );

      if (!pm) return;

      const updated = produce(pm, (draft) => {
        _set(draft, ['spec', packageManagerKey, 'priority'], newIndex);
      });

      qUpdateOrder.mutate({
        namespace: pm.tenant_meta.namespace,
        resource: updated,
        mask: `spec.${packageManagerKey}.priority`,
      });
    });
  };
  const [lastTestedRowUuid, setLastTestedRowUuid] = useState<string>('');

  const handleTestConnection = (row: PackageManagersTableRow) => {
    const pm = activePackageManagers.find((pm) => pm.uuid === row.uuid);
    setLastTestedRowUuid(row.uuid);

    if (pm) {
      const updated = produce(pm, (draft) => {
        _set(
          draft,
          ['spec', 'package_manager_status', 'last_tested_at'],
          formatISO(Date.now())
        );
      });

      qPackageManagerUpdate.mutate({
        namespace: pm.tenant_meta.namespace,
        resource: updated,
        mask: 'spec.package_manager_status.last_tested_at',
      });
    }
  };

  const isEmptyState =
    !qPackageManagersList.isLoading && activePackageManagers.length === 0;

  const packageManager = PACKAGE_MANAGERS.find(
    (pm) => pm.key === packageManagerName
  );

  const isPasswordAuth =
    PackageManagersWithPasswordAuth.includes(packageManagerKey);
  const isTokenAuth = PackageManagersWithTokenAuth.includes(packageManagerKey);

  const isSubmitting =
    qPackageManagerCreate.isLoading || qPackageManagerUpdate.isLoading;

  const isAWSCodeArtifactEnabled =
    !!PackageManagerWithEnabledAWSCodeArtifact[packageManagerKey];

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <PageHeader
            action={
              <Box display="flex" justifyContent="flex-end">
                <ButtonPrimary
                  onClick={() =>
                    setEditingPackageManager({
                      uuid: undefined,
                      action: 'create',
                    })
                  }
                >
                  <FM {...messages.addPackageManager} />
                </ButtonPrimary>
              </Box>
            }
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            Icon={packageManager?.Icon}
            metadata={{ summary: [] }}
            title={packageManager?.label}
          />
        </Grid>

        {!isEmptyState && (
          <Grid item>
            <Card>
              <CardContent>
                <PackageManagersTable
                  data={displayedPms}
                  isLoading={qPackageManagersList.isLoading}
                  isStatusUpdating={qPackageManagerUpdate.isLoading}
                  onDelete={handleDeleteDialog}
                  onEdit={handleEditDialog}
                  onTest={handleTestConnection}
                  onReorderComplete={handleReorder}
                  packageManagerKey={packageManagerKey}
                  lastTestedRowUuid={lastTestedRowUuid}
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not added any Package Manager definitions yet" />
              }
            >
              <ButtonPrimary
                onClick={() =>
                  setEditingPackageManager({
                    uuid: undefined,
                    action: 'create',
                  })
                }
              >
                <FM {...messages.addPackageManager} />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}
      </Grid>

      {/* ===== UPSERT DIALOG ===== */}
      <Dialog
        onClose={() => setEditingPackageManager(undefined)}
        open={
          editingPackageManager !== undefined &&
          ['create', 'update'].includes(editingPackageManager.action as string)
        }
      >
        <DialogTitle>
          {editingPackageManager?.uuid !== undefined ? (
            <FM defaultMessage="Editing Package Manager" />
          ) : (
            <FM {...messages.addPackageManager} />
          )}
        </DialogTitle>
        <DialogContent>
          {
            isPasswordAuth ? (
              <FormUpsertPasswordAuthPackageManager
                isSubmitting={isSubmitting}
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                awsCodeArtifactEnabled={isAWSCodeArtifactEnabled}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as AuthLoginPackageManagerKey
                }
              />
            ) : isTokenAuth ? (
              <FormUpsertTokenAuthPackageManager
                isSubmitting={isSubmitting}
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                awsCodeArtifactEnabled={isAWSCodeArtifactEnabled}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as TokenLoginPackageManagerKey
                }
              />
            ) : (
              //This has both password and token auth, based on auth kind. Hence added it separately
              <FormUpsertPackagistPackageManager
                isSubmitting={isSubmitting}
                authRequired={Boolean(packageManager?.authRequired)}
                onSubmit={onSubmit}
                awsCodeArtifactEnabled={isAWSCodeArtifactEnabled}
                packageManager={packageManagerUnderEdit}
                packageManagerKey={
                  packageManagerName as PackagistPackageManagerKey
                }
              />
            )
            // :
          }
        </DialogContent>
      </Dialog>

      {/* ===== DELETE DIALOG ===== */}
      <PackageManagerDeleteConfirmationDialog
        open={
          !!editingPackageManager && editingPackageManager.action === 'delete'
        }
        onConfirm={handleConfirmDelete}
        onCancel={closeDialog}
      />
    </>
  );
};
