import { PackageVersionResource } from '../types';

/**
 * Get all unique File Locations for a given dependency from a PackageVersion
 */
export const getDependencyFileLocations = (
  packageVersion: Pick<PackageVersionResource, 'spec'>,
  filter: { name: string; digest?: string }
): string[] => {
  const dependency =
    packageVersion?.spec.resolved_dependencies?.dependencies?.find(
      (d) => d.name === filter.name
    );

  const allFileLocations = new Set<string>();

  for (const fl of dependency?.file_locations ?? []) {
    allFileLocations.add(fl);
  }

  for (const cl of dependency?.container_layers ?? []) {
    for (const l of cl.file_locations ?? []) {
      if (filter.digest && cl.digest !== filter.digest) {
        continue;
      }

      allFileLocations.add(l);
    }
  }

  return Array.from(allFileLocations.values()).sort();
};
