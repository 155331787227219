import { FindingLogServiceApi, V1CountResponse } from '@endorlabs/api_client';
import { FindingLogResource } from '@endorlabs/endor-core/FindingLog';

import { createResourceReadHooks } from './hooks';
import { ResourceGroupResponse, ResourceListResponse } from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

const queries = createResourceReadHooks('FindingLog', () => {
  const getApiService = () =>
    new FindingLogServiceApi(getClientConfiguration());

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp = await getApiService().findingLogServiceListFindingLogs(
        namespace,
        ...args,
        options
      );
      return resp?.data.count_response as Required<V1CountResponse>;
    },

    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp = await getApiService().findingLogServiceGetFindingLog(
        namespace,
        ...args,
        options
      );
      return resp?.data as FindingLogResource;
    },

    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await getApiService().findingLogServiceListFindingLogs(
        namespace,
        ...args,
        options
      );
      return resp?.data.group_response as ResourceGroupResponse;
    },

    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await getApiService().findingLogServiceListFindingLogs(
        namespace,
        ...args,
        options
      );
      return resp?.data.list as ResourceListResponse<FindingLogResource>;
    },
  };
});

//Query hooks
export const useCountFindingLogs = queries.useCount;
export const useListFindingLogs = queries.useList;
export const useGroupFindingLogs = queries.useGroup;
export const useGetFindingLog = queries.useGet;
