import { minutesToMilliseconds } from 'date-fns';
import { QueryKey, useQuery } from 'react-query';

import {
  ContextContextType,
  DependencyMetadataDependencyData,
  DependencyMetadataImporterData,
  Endorv1Metric as V1Metric,
  QueryQuerySpec,
  QueryServiceApi,
  V1Bom,
  V1DependencyMetadata,
  V1DependencyMetadataSpec,
  V1ImportedSBOM,
  V1ImportedSBOMSpec,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Project,
  V1ProjectSpec,
  V1Query,
  V1RepositoryVersion,
  V1RepositoryVersionSpec,
} from '@endorlabs/api_client';
import { SelectFrom } from '@endorlabs/utils';

import {
  PackageVersionResource,
  ResourceCountResponse,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration } from './utils';

export type QueryDependencyDependentsResponse =
  TResourceList<QueryDependencyDependentsResponseObject>;

export type QueryDependencyDependentsResponseObject = SelectFrom<
  V1DependencyMetadata,
  'uuid' | 'context',
  {
    spec: SelectFrom<
      V1DependencyMetadataSpec,
      never,
      {
        dependency_data: SelectFrom<
          DependencyMetadataDependencyData,
          'package_version_uuid' | 'direct' | 'reachable'
        >;
        importer_data: SelectFrom<
          DependencyMetadataImporterData,
          'project_uuid'
        >;
      }
    >;
    meta: SelectFrom<
      V1Meta,
      'name',
      {
        references: {
          DependencyPackageVersion?: TResourceList<QueryDependencyDependentsDependencyPackageVersionResponseObject>;
          Project?: TResourceList<QueryDependencyDependentsProjectResponseObject>;
          RepositoryVersion?: TResourceList<QueryDependencyDependentsRepositoryVersionResponseObject>;
          PackageVersion?: TResourceList<QueryDependencyDependentsPackageVersionResponseObject>;
          Metric?: TResourceList<QueryDependencyDependentsMetricResponseObject>;
          DependentsCount: ResourceCountResponse;
          ImportedSBOM: TResourceList<QueryDependencyDependentsImportedSBOMResponseObject>;
        };
      }
    >;
  }
>;

type QueryDependencyDependentsDependencyPackageVersionResponseObject =
  SelectFrom<
    PackageVersionResource,
    'uuid' | 'tenant_meta',
    {
      spec: SelectFrom<
        PackageVersionResource['spec'],
        'source_code_reference',
        {
          project_uuid: never;
          resolved_dependencies: SelectFrom<V1Bom, 'dependencies'>;
        }
      >;
      meta: SelectFrom<V1Meta, 'name'>;
      context: never;
    }
  >;

type QueryDependencyDependentsProjectResponseObject = SelectFrom<
  V1Project,
  'uuid' | 'tenant_meta',
  {
    meta: SelectFrom<V1Meta, 'name'>;
    spec: SelectFrom<V1ProjectSpec, 'platform_source'>;
  }
>;

type QueryDependencyDependentsRepositoryVersionResponseObject = SelectFrom<
  V1RepositoryVersion,
  'uuid',
  {
    meta: SelectFrom<V1Meta, 'name'>;
    spec: SelectFrom<V1RepositoryVersionSpec, 'version'>;
    context: never;
  }
>;

type QueryDependencyDependentsPackageVersionResponseObject = SelectFrom<
  PackageVersionResource,
  'uuid' | 'tenant_meta',
  {
    spec: SelectFrom<PackageVersionResource['spec'], 'source_code_reference'>;
    meta: SelectFrom<PackageVersionResource['meta'], 'name'>;
    context: never;
  }
>;

type QueryDependencyDependentsMetricResponseObject = SelectFrom<
  V1Metric,
  'uuid',
  {
    meta: SelectFrom<V1Meta, 'name'>;
    spec: SelectFrom<
      V1MetricSpec,
      'analytic',
      {
        metric_values: {
          scorecard: Required<V1MetricValue>;
          scorefactor: Required<V1MetricValue>;
        };
        // properties required, but not included in query
        project_uuid: never;
        raw: never;
      }
    >;
    tenant_meta: never;
    context: never;
  }
>;

type QueryDependencyDependentsImportedSBOMResponseObject = SelectFrom<
  V1ImportedSBOM,
  'uuid' | 'context' | 'tenant_meta',
  {
    spec: SelectFrom<V1ImportedSBOMSpec, 'main_component_purl'>;
  }
>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'dependency-dependents', namespace, listParams] as const,
};
export const QueryDependencyDependentsQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (rootListParams: V1ListParameters): QueryQuerySpec => {
  return {
    kind: 'DependencyMetadata',
    list_parameters: {
      ...rootListParams,
      mask: [
        'context',
        'uuid',
        'meta.name',
        'spec.dependency_data.direct',
        'spec.dependency_data.reachable',
        'spec.dependency_data.package_version_uuid',
        'spec.importer_data.project_uuid',
        'spec.importer_data.package_version_uuid',
        'spec.importer_data.package_version_ref',
      ].join(','),
    },
    references: [
      {
        connect_from: 'spec.dependency_data.package_version_uuid',
        connect_to: 'uuid',
        query_spec: {
          kind: 'PackageVersion',
          return_as: 'DependencyPackageVersion',
          list_parameters: {
            page_size: 1, // there should only be 1, return the first found
            mask: [
              'tenant_meta',
              'uuid',
              'meta.name',
              'spec.source_code_reference',
              'spec.resolved_dependencies.dependencies',
            ].join(),
          },
        },
      },
      {
        connect_from: 'spec.importer_data.project_uuid',
        connect_to: 'uuid',
        query_spec: {
          kind: 'Project',
          list_parameters: {
            page_size: 1, // there should only be 1, return the first found
            mask: [
              'tenant_meta',
              'uuid',
              'meta.name',
              'spec.platform_source',
            ].join(),
          },
        },
      },
      {
        connect_from: 'spec.importer_data.package_version_ref',
        connect_to: 'spec.version.ref',
        query_spec: {
          kind: 'RepositoryVersion',
          list_parameters: {
            page_size: 1,
            mask: ['uuid', 'meta.name', 'spec.version'].join(','),
          },
        },
      },
      {
        connect_from: 'spec.importer_data.package_version_uuid',
        connect_to: 'uuid',
        query_spec: {
          kind: 'PackageVersion',
          list_parameters: {
            page_size: 1, // there should only be 1, return the first found
            mask: [
              'tenant_meta',
              'uuid',
              'meta.name',
              'spec.source_code_reference',
            ].join(),
          },
        },
      },
      {
        connect_from: 'spec.dependency_data.package_version_uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'Metric',
          list_parameters: {
            page_size: 1, // there should only be 1, return the first found
            // pick the specific metric for the package version, if exists
            filter: 'meta.name==package_version_scorecard',
            mask: [
              'uuid',
              'meta.name',
              'spec.analytic',
              'spec.metric_values',
            ].join(),
          },
        },
      },
      {
        // dependents: packages that have the parent PackageVersion as a dependency
        connect_from: 'meta.name',
        connect_to: 'spec.resolved_dependencies.dependencies.name',
        query_spec: {
          kind: 'PackageVersion',
          return_as: 'DependentsCount',
          list_parameters: {
            count: true,
          },
        },
      },
      {
        connect_from: 'context.id',
        connect_to: 'spec.identifier',
        query_spec: {
          kind: 'ImportedSBOM',
          list_parameters: {
            mask: [
              'context',
              'meta.name',
              'spec.main_component_purl',
              'tenant_meta',
              'uuid',
            ].join(','),
            filter: `context.type == ${ContextContextType.Sbom}`,
          },
        },
      },
    ],
  };
};

const buildQuery = (
  namespace: string,
  listParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryDependencyDependents(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildQuerySpec(listParams),
    },
    tenant_meta: { namespace },
  };
};

const queryDependencyDependents = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query);
  return resp.data.spec?.query_response as QueryDependencyDependentsResponse;
};

/**
 * Custom query for Dependecy Dependents, including metrics
 *
 * NOTE: this query sets the mask for the response objects
 *
 * @deprecated use instead {@see useQueryDependencies}
 */
export const useQueryDependencyDependents = (
  namespace: string,
  opts: ResourceQueryOptions<QueryDependencyDependentsResponse> = {},
  listParams: Omit<V1ListParameters, 'mask'> = {}
) => {
  return useQuery(
    QK.query(namespace, listParams),
    () => queryDependencyDependents(namespace, listParams),
    {
      staleTime: QUERY_STALE_TIME,
      ...opts,
    }
  );
};
