import { V1FindingTags } from '@endorlabs/api_client';
import { Filter, FILTER_COMPARATORS } from '@endorlabs/filters';

import { FilterFieldConfig } from '../../../filters';
import { FindingExceptionsFilterField } from '../../components/FindingExceptionsFilterField';

const HIDE_EXCEPTIONS_FILTER: Filter = {
  comparator: FILTER_COMPARATORS.NOT_CONTAINS,
  key: 'spec.finding_tags',
  value: [V1FindingTags.Exception],
};

export const FindingExceptionsFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'findingExceptions',
  initialFilter: HIDE_EXCEPTIONS_FILTER,

  /**
   * Distinguish between an undefined value (do not hide exceptions),
   * an empty array (hide exceptions),
   * & a populated array (show only exceptions related to specified policy uuids)
   */
  format: (value?: string[]): Filter | undefined => {
    if (value === undefined) {
      return undefined;
    }

    if (value.length === 0) {
      return HIDE_EXCEPTIONS_FILTER;
    }

    return {
      comparator: FILTER_COMPARATORS.CONTAINS,
      key: 'spec.exceptions.policy_uuids',
      value,
    };
  },

  parse: (filter: Filter): string[] | undefined => {
    return filter.value as string[];
  },

  renderInput: ({ onChange, value }) => {
    return <FindingExceptionsFilterField onChange={onChange} value={value} />;
  },
};
