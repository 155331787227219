import { Grid, Stack } from '@mui/material';
import produce from 'immer';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  ButtonDestructive,
  ButtonPrimary,
  ButtonSecondary,
  ControlledCodeEditorField,
  ControlledTextField,
} from '@endorlabs/ui-common';

import { FormUpsertSASTRuleFieldValues } from '../types';
interface FormUpsertEditProps {
  resource: SemgrepRulesResource;
  onCancel: () => void;
  onSave: (resource: SemgrepRulesResource) => void;
  onDelete?: ({ uuid, namespace }: { uuid: string; namespace: string }) => void;
  onDisable?: ({ resource }: { resource: SemgrepRulesResource }) => void;
}

export const FormUpsertEditSASTRule = ({
  resource,
  onCancel,
  onSave,
  onDelete,
  onDisable,
}: FormUpsertEditProps) => {
  const formMethods = useForm<FormUpsertSASTRuleFieldValues>({
    defaultValues: {
      meta: {
        name: resource?.meta?.name ?? '',
      },
      spec: {
        yaml: resource?.spec?.yaml ?? '',
      },
    },
  });

  const { formatMessage: fm } = useIntl();

  const { control, handleSubmit } = formMethods;

  const wrappedOnSubmit = useCallback(
    (fieldValues: FormUpsertSASTRuleFieldValues) => {
      const formattedValues = produce(resource, (draft) => {
        draft.meta.name = fieldValues.meta.name;
        draft.spec.yaml = fieldValues.spec.yaml;
      });
      onSave(formattedValues);
    },
    [onSave, resource]
  );

  return (
    <FormProvider {...formMethods}>
      <form id="sastRuleEditForm" onSubmit={handleSubmit(wrappedOnSubmit)}>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <ControlledTextField
              control={control}
              rules={{
                required: fm({
                  defaultMessage: 'SAST rule name is required',
                }),
              }}
              inputProps={{
                'data-testid': 'sast-rule-name',
              }}
              defaultValue=""
              label={<FM defaultMessage="SAST Rule Name" />}
              name="meta.name"
            />
            <ControlledCodeEditorField
              key="sast-rule-field"
              codeEditorProps={{
                downloadProps: {
                  filename: `${resource?.spec?.rule?.id ?? 'sast-rule'}.yaml`,
                  filetype: 'yaml',
                },
                editorOptions: { lineWrapping: true },
                language: 'text/x-yaml',
                height: 500,
                width: 'inherit',
                title: `${resource?.spec?.rule?.id ?? 'SAST Rule'}`,
              }}
              control={control}
              name="spec.yaml"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row">
              <Grid container justifyContent="flex-start" spacing={4}>
                <Grid item>
                  <ButtonSecondary type="button" onClick={onCancel}>
                    <FM defaultMessage="Cancel" />
                  </ButtonSecondary>
                </Grid>
                <Grid item>
                  <ButtonPrimary type="submit" data-testid="save-btn">
                    <FM defaultMessage="Save" />
                  </ButtonPrimary>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end" spacing={4}>
                {onDelete && (
                  <Grid item>
                    <ButtonDestructive
                      type="button"
                      onClick={() =>
                        onDelete({
                          uuid: resource?.uuid,
                          namespace: resource.tenant_meta.namespace,
                        })
                      }
                    >
                      <FM defaultMessage="Delete Rule" />
                    </ButtonDestructive>
                  </Grid>
                )}
                {onDisable && (
                  <Grid item>
                    <ButtonSecondary
                      type="button"
                      onClick={() => onDisable({ resource: resource })}
                    >
                      <FM defaultMessage="Disable Rule" />
                    </ButtonSecondary>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
