import {
  Chip,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AnyResourceType } from '@endorlabs/endor-core';
import { IconChevronDown } from '@endorlabs/ui-common';

import {
  defaultLabelFn,
  ResourceVersionSelectProps,
} from './ResourceVersionSelect';

/**
 * V2 is a part of the breadcrumbs
 */
export const ResourceVersionSelectV2 = <TResource extends AnyResourceType>({
  getLabelFn = defaultLabelFn,
  isLoading = false,
  onChange,
  resourceList,
  selectedResource,
  defaultVersion,
  variant = 'button',
}: ResourceVersionSelectProps<TResource>) => {
  const getResourceLabel = (uuid: string) => {
    const label = getLabelFn((resourceList ?? []).find((o) => o.uuid === uuid));
    if (defaultVersion && defaultVersion === label) {
      return (
        <Stack direction="row" gap={2}>
          {label}
          <Chip label={<FM defaultMessage="Default Version" />} size="small" />
        </Stack>
      );
    }
    return label;
  };

  const handleSelectChangeEvent = useCallback(
    (event: SelectChangeEvent) => {
      const targetUuid = event.target.value;

      const selected = resourceList?.find((o) => o.uuid === targetUuid);
      if (selected) {
        onChange(selected);
      }
    },
    [resourceList, onChange]
  );

  const selectedResourceIdentifier = selectedResource?.uuid ?? '';

  const isLoadingInitial = isLoading || !selectedResource;
  return isLoadingInitial ? null : (
    <Select
      sx={({ typography }) => ({
        ...typography[variant],
        color: 'text.primary',
        padding: 0,

        '& .MuiSelect-select': {
          padding: 0,
        },

        '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
          paddingRight: '28px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },

        '& .MuiSelect-icon': {
          color: 'text.primary',
          width: '16px',
        },
      })}
      disabled={isLoading}
      value={selectedResourceIdentifier}
      onChange={handleSelectChangeEvent}
      renderValue={getResourceLabel}
      IconComponent={IconChevronDown}
    >
      {resourceList?.map((resource) => (
        <MenuItem key={resource.uuid} value={resource.uuid}>
          <ListItemText
            primary={getLabelFn(resource)}
            primaryTypographyProps={{ variant: 'body1' }}
          ></ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};
