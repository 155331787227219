import { IconButton, Theme, Tooltip } from '@mui/material';
import { ReactNode } from 'react';

import { IconHelp, useStyles } from '@endorlabs/ui-common';

interface HelpIconButtonProps {
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  tooltip: ReactNode;
}

export const HelpIconButton = ({
  size = 'small',
  tooltip,
}: HelpIconButtonProps) => {
  const sx = useStyles(styles);

  return (
    <Tooltip title={tooltip}>
      <IconButton className="HelpIconButton-root" sx={sx}>
        <IconHelp className="HelpIconButton-icon" fontSize={size} />
      </IconButton>
    </Tooltip>
  );
};

const styles = ({ palette }: Theme) => ({
  '&.HelpIconButton-root': {
    color: palette.text.secondary,
    padding: 0,

    '&:hover': { cursor: 'pointer' },
    '&:focus': { color: palette.primary.main },
  },
});
