import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  ServiceRequestServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1ServiceRequest,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { invalidateListQueries } from './client';
import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceMutateOptions,
  ResourceQueryOptions,
  ServiceRequestResource,
  ServiceRequestResourceList,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  getClientConfiguration,
} from './utils';

export interface ServiceRequestWriteParams {
  namespace: string;
  resource: V1ServiceRequest;
}

type CountServiceRequestOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type ListServiceRequestOptions =
  ResourceQueryOptions<ServiceRequestResourceList>;
type UpsertServiceRequestOptions = ResourceMutateOptions<
  V1ServiceRequest,
  ServiceRequestWriteParams
>;

const BASE_KEY = 'v1/service-request';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const ServiceRequestQueryKeys = QK;

const getApiService = () =>
  new ServiceRequestServiceApi(getClientConfiguration());

const countServiceRequest = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.serviceRequestServiceListServiceRequests(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountServiceRequest = (
  namespace: string,
  opts: CountServiceRequestOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ServiceRequest',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countServiceRequest(namespace, requestParameters),
    opts
  );
};

const listServiceRequest = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.serviceRequestServiceListServiceRequests(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as ServiceRequestResourceList;
};

export const useListServiceRequest = (
  namespace: string,
  opts: ListServiceRequestOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ServiceRequest',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listServiceRequest(namespace, requestParameters),
    opts
  );
};

const createServiceRequest = async (
  namespace: string,
  serviceRequestBody: V1ServiceRequest
) => {
  const api = getApiService();
  const resp = await api.serviceRequestServiceCreateServiceRequest(
    namespace,
    serviceRequestBody
  );
  return resp.data as ServiceRequestResource;
};

export const useCreateServiceRequest = (
  opts: UpsertServiceRequestOptions = {}
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'ServiceRequest'),
    mutationFn: (params: ServiceRequestWriteParams) =>
      createServiceRequest(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
