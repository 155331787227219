import { useMatch } from '@tanstack/react-location';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';

import { ProjectVersionFindings } from '../../../../../../../views';
import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionDetailFindingsRoute = () => {
  const { data } = useMatch();
  const project = data.project as ProjectResource;
  const projectVersion = data.projectVersion as ProjectVersionResource;

  return (
    <ProjectVersionFindings
      namespace={project.tenant_meta.namespace}
      project={project}
      projectVersion={projectVersion}
    />
  );
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/:version_ref/findings'
)({
  component: TenantProjectVersionDetailFindingsRoute,
});
