import { minutesToMilliseconds, startOfDay, sub } from 'date-fns';
import { QueryKey, useQuery } from 'react-query';

import {
  QueryServiceApi,
  V1ListParameters,
  V1Meta,
  V1Query,
  V1RepositoryCommit,
} from '@endorlabs/api_client';
import { GroupRequestParameters } from '@endorlabs/endor-core/api';
import { SelectFrom } from '@endorlabs/utils/types';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration } from './utils';

const DATE_TODAY = startOfDay(new Date());
const QUERY_STALE_TIME = minutesToMilliseconds(15);

export const QueryRepositoryCommitQueryKeys = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey => [
    'v1/queries',
    namespace,
    'repository-commits',
    listParams,
  ],
};

export type QueryRepositoryCommitResponse =
  TResourceList<QueryRepositoryCommitResponseObject>;

export type QueryRepositoryCommitResponseObject = SelectFrom<
  V1RepositoryCommit,
  'uuid',
  {
    meta: SelectFrom<V1Meta, 'name' | 'parent_uuid'>;
    ingested_object: never;
  }
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryRepositoryCommit(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'RepositoryCommit',
        list_parameters: {
          ...rootListParams,
        },
      },
    },
    tenant_meta: { namespace },
  };
};

const queryProjects = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryRepositoryCommitResponse;
};

/**
 * Custom query for the commit count for every repository
 */
export const useQueryRepositoryCommit = (
  namespace: string,
  opts: ResourceQueryOptions<QueryRepositoryCommitResponse> = {},
  groupParams: Omit<GroupRequestParameters, 'filter' | 'group'> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'RepositoryCommit',
    'GROUP',
    {
      ...groupParams,
      // provide defaults for the request parameters
      filter: `ingested_object.raw.commit.author.date>${sub(DATE_TODAY, {
        months: 6,
      }).toISOString()}`,
      group: {
        aggregation_paths: 'meta.parent_uuid',
        unique_value_paths: 'meta.parent_uuid',
      },
    },
    opts
  );

  return useQuery(
    QueryRepositoryCommitQueryKeys.query(namespace, requestParameters),
    ({ signal }) => queryProjects(namespace, requestParameters, signal),
    { staleTime: QUERY_STALE_TIME, ...opts }
  );
};
