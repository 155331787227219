import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageContexture,
  PackageVersionResource,
  tryParseGroupResponseAggregationKey,
  useGroupDependencyMetadata,
  useQueryPackageVersions,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  getPaginatorPageSlice,
  useDataTablePaginator,
} from '@endorlabs/ui-common';
import { useTanstackTableRef } from '@endorlabs/ui-common';

import {
  FilterBuilder,
  mapToPackageVersionTableRows,
  PackageVersionsTable,
  PackageVersionsTableRow,
  useFilterBuilder,
} from '../../components';
import { usePackageVersionDetailDrawer } from '../../domains/PackageVersion';

const usePackageVersionDependents = (
  namespace: string,
  packageVersion?: PackageVersionResource,
  filterExpression?: FilterExpression
) => {
  const basePackageVersionDependentsFilter = filterExpressionBuilders.and([
    filterExpressionBuilders.defaultResourceContexts(),
    `spec.dependency_data.package_version_uuid==${packageVersion?.uuid}`,
  ]);

  let packageVersionDependentsFilter = basePackageVersionDependentsFilter;
  if (filterExpression) {
    packageVersionDependentsFilter = filterExpressionBuilders.and([
      basePackageVersionDependentsFilter,
      filterExpression,
    ]);
  }

  const qGroupedDependentsTotal = useGroupDependencyMetadata(
    namespace,
    { enabled: !!packageVersion },
    {
      filter: basePackageVersionDependentsFilter,
      group: {
        aggregation_paths: 'spec.importer_data.package_version_name',
      },
    }
  );

  const qGroupedDependents = useGroupDependencyMetadata(
    namespace,
    { enabled: !!packageVersion },
    {
      filter: packageVersionDependentsFilter,
      group: {
        aggregation_paths: 'spec.importer_data.package_version_name',
      },
    }
  );

  const {
    dependentsCount,
    dependentsCountTotal,
    dependentsPackageVersionNames,
  } = useMemo(() => {
    const dependentsCount = Object.keys(
      qGroupedDependents.data?.groups ?? {}
    ).length;
    const dependentsCountTotal = Object.keys(
      qGroupedDependentsTotal.data?.groups ?? {}
    ).length;

    const dependentsPackageVersionNames = Object.keys(
      qGroupedDependents.data?.groups ?? {}
    )
      .map((key) => {
        const values = tryParseGroupResponseAggregationKey(key);
        return values.find(
          (kv) => kv.key === 'spec.importer_data.package_version_name'
        )?.value;
      })
      .filter(Boolean)
      .sort();

    return {
      dependentsCount,
      dependentsCountTotal,
      dependentsPackageVersionNames,
    };
  }, [qGroupedDependents.data, qGroupedDependentsTotal.data]);

  return {
    isLoading:
      qGroupedDependentsTotal.isLoading || qGroupedDependents.isLoading,
    dependentsCount,
    dependentsCountTotal,
    dependentsPackageVersionNames,
  };
};

export interface PackageVersionDetailDependentsProps {
  tenantName: string;
  packageVersion?: PackageVersionResource;
}

export const PackageVersionDetailDependents = ({
  packageVersion,
  tenantName,
}: PackageVersionDetailDependentsProps) => {
  const { formatMessage: fm } = useIntl();

  const packageContexture = PackageContexture.Dependents;

  const { DetailDrawer, permalinkEffect } = usePackageVersionDetailDrawer();

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<PackageVersionsTableRow>();

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<PackageVersionsTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const { getFilterBuilderProps, filterExpressionMap, clearFilters } =
    useFilterBuilder({
      include: ['DependencyMetadata'],
    });

  const {
    dependentsCount,
    dependentsCountTotal,
    dependentsPackageVersionNames,
    isLoading: isLoadingDependents,
  } = usePackageVersionDependents(
    tenantName,
    packageVersion,
    filterExpressionMap.get('DependencyMetadata')
  );

  const paginator = useDataTablePaginator({
    totalCount: dependentsCount,
  });

  const dependentsPackageVersionNamesPage = getPaginatorPageSlice(
    paginator.state,
    dependentsPackageVersionNames
  );

  const qQueryPackageVersionDependents = useQueryPackageVersions(
    tenantName,
    {
      enabled: !!packageVersion && !!dependentsPackageVersionNamesPage.length,
    },
    {
      page_size: dependentsPackageVersionNamesPage.length,
      filter: `meta.name in ["${dependentsPackageVersionNamesPage.join(
        '","'
      )}"]`,
    }
  );

  const dependents = useMemo(
    () => mapToPackageVersionTableRows(qQueryPackageVersionDependents.data),
    [qQueryPackageVersionDependents.data]
  );

  useEffect(
    () =>
      permalinkEffect({
        packages: dependents,
        sourcePackage: packageVersion,
      }),
    [dependents, packageVersion, permalinkEffect]
  );

  const isLoading =
    isLoadingDependents || qQueryPackageVersionDependents.isLoading;
  const isEmptyState =
    !qQueryPackageVersionDependents.isLoading &&
    !filterExpressionMap.size &&
    dependents.length === 0;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="This package version does not have any dependents" />
            }
            description={
              <FM defaultMessage="When other packages add this package version as a dependency, they will appear here." />
            }
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <FilterBuilder
            {...getFilterBuilderProps()}
            primaryResourceKindLabel={fm({ defaultMessage: 'Dependents' })}
          />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              title={
                isLoading ? (
                  <Skeleton width="60%" />
                ) : (
                  <Typography>
                    <FM
                      defaultMessage="Showing <bold>{filteredCount, number}</bold> of {totalCount, plural,
                        one {<bold>#</bold> dependent} other {<bold>#</bold> dependents}}"
                      values={{
                        filteredCount: dependentsCount,
                        totalCount: dependentsCountTotal,
                        bold: (v) => <strong>{v}</strong>,
                      }}
                    />
                  </Typography>
                )
              }
            />
            <CardContent>
              <PackageVersionsTable
                packageContexture={packageContexture}
                isLoading={qQueryPackageVersionDependents.isLoading}
                data={dependents}
                enablePagination
                ref={tableRef}
                enableRowSelection
                onRowSelectionChange={handleRowSelection}
                paginator={paginator}
                includeColumns={['repository', 'versionRef']}
                onClickDetail={(row) => {
                  row &&
                    DetailDrawer.activate(
                      {
                        packageNamespace: row?.namespace,
                        packageUuid: row?.uuid,
                      },
                      {
                        namespace: row.namespace,
                        packageContexture,
                        sourcePackage: packageVersion,
                        uuid: row?.uuid as string,
                      }
                    );
                }}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Dependents match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={clearFilters}>
                      <FM defaultMessage="Clear Filter" />
                    </ButtonCancel>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
