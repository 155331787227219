import {
  Box,
  FormLabel,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonCancel, ControlledTextField } from '@endorlabs/ui-common';

const hourlyCostValidationMessages = defineMessages({
  min: {
    defaultMessage: 'Hourly cost value must be a number greater than 1',
  },
});

export const CostEstimateFields = () => {
  const { formatMessage } = useIntl();
  const { space } = useTheme();

  const { control, reset } = useFormContext();

  return (
    <Stack direction="column" spacing={space.md} component="fieldset">
      <FormLabel component="legend">
        <Typography variant="body1" color="text.primary" marginBottom={2}>
          <FM defaultMessage="Cost savings is an estimate calculated based on total dev hours saved multiplied by FTE hourly cost for triaging vulnerabilities." />
        </Typography>

        <Typography variant="body1" color="text.primary">
          <FM defaultMessage="Please input hourly cost that applies to your AppSec program." />
        </Typography>
      </FormLabel>

      <Stack direction="row" spacing={space.sm} alignItems="flex-start">
        <ControlledTextField
          control={control}
          inputProps={{ min: 1 }}
          label={<FM defaultMessage="Hourly Cost" />}
          name="hourlyCost"
          type="number"
          required
          rules={{
            min: {
              value: 1,
              message: formatMessage(hourlyCostValidationMessages.min),
            },
          }}
        />

        <ControlledTextField
          control={control}
          label={<FM defaultMessage="Currency" />}
          name="currencyCode"
          required
          select
        >
          <MenuItem value="USD">
            <FM defaultMessage="Dollars" />
          </MenuItem>
          <MenuItem value="EUR">
            <FM defaultMessage="Euros" />
          </MenuItem>
        </ControlledTextField>

        <Box paddingTop={space.sm}>
          <ButtonCancel onClick={() => reset()}>
            <FM defaultMessage="Reset" />
          </ButtonCancel>
        </Box>
      </Stack>
    </Stack>
  );
};
