import { SpecFindingLevel } from '@endorlabs/api_client';
import { FILTER_COMPARATORS } from '@endorlabs/filters';

import { FindingLevelToggles } from '../../../../components';
import {
  FilterFieldConfig,
  filterFieldTransformBuilders,
} from '../../../filters';

export const FindingLevelFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.level',

  ...filterFieldTransformBuilders.fromFilter<SpecFindingLevel[]>(
    {
      key: 'spec.level',
      comparator: FILTER_COMPARATORS.IN,
    },
    { defaultValue: [] }
  ),

  initialFilter: {
    key: 'spec.level',
    comparator: FILTER_COMPARATORS.IN,
    value: [SpecFindingLevel.Critical],
  },

  renderInput: ({ onChange, value }) => (
    <FindingLevelToggles onChange={onChange} value={value} allowEmptyValue />
  ),
} satisfies FilterFieldConfig<SpecFindingLevel[]>;
