import { Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ControlledCheckboxV2 } from '@endorlabs/ui-common';

export const GithubPRFields = () => {
  return (
    <>
      <ControlledCheckboxV2
        label={
          <Typography variant="body1" color="text.primary">
            <FM defaultMessage="Enable GitHub PR Notification Integration for Remediations" />
          </Typography>
        }
        helperText="Automatically create pull requests when configuring Remediation Policies"
        name="spec.action.github_pr_config.enabled"
      />
    </>
  );
};
