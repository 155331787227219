import { useRouter } from '@tanstack/react-location';
import { useEffect } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { RepoVersionResource } from '@endorlabs/queries';

import { withFilterProvider } from '../../domains/filters';
import {
  REMEDIATIONS_SEARCH_KEYS,
  RemediationsAggregatedView,
} from '../../domains/Remediations';
import { useAuthInfo, useLicensingInfo } from '../../providers';

export interface ProjectVersionRemediationsProps {
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

const BaseProjectVersionRemediations = ({
  namespace,
  project,
  repositoryVersion,
}: ProjectVersionRemediationsProps) => {
  const { setLicenseInCurrentRoute } = useAuthInfo();
  const { checkLicensePresent } = useLicensingInfo();
  const router = useRouter();

  const isRemediationLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Recommendation
  );

  useEffect(() => {
    if (!isRemediationLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => setLicenseInCurrentRoute({ pathname: '', isLicense: true });
    }
  }, [
    isRemediationLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  return (
    <RemediationsAggregatedView
      namespace={namespace}
      project={project}
      projectVersion={repositoryVersion}
    />
  );
};

export const ProjectVersionRemediationsV2 = withFilterProvider(
  BaseProjectVersionRemediations,
  {
    displayName: 'ProjectVersionRemediations',
    searchKeys: REMEDIATIONS_SEARCH_KEYS,
  }
);
