import {
  Chip,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { noop } from 'lodash-es';
import { ReactNode, useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AnyResourceType } from '@endorlabs/endor-core';

export interface ResourceVersionSelectProps<TResource extends AnyResourceType> {
  getDescriptionFn?: (resource?: TResource) => void | ReactNode | undefined;
  getLabelFn?: (resource?: TResource) => ReactNode;
  isLoading?: boolean;
  onChange: (resource: TResource) => void;
  resourceList?: TResource[];
  selectedResource?: TResource;
  defaultVersion?: string;
  variant?: Variant;
}

/**
 * Determines displayed label from given resource.
 * Default function simply displays meta.name
 */
export function defaultLabelFn(resource?: AnyResourceType) {
  return resource?.meta.name ?? '';
}

/**
 * Select a Resource Version from a list of Resource Versions
 */
export const ResourceVersionSelect = <TResource extends AnyResourceType>({
  getDescriptionFn = noop,
  getLabelFn = defaultLabelFn,
  isLoading = false,
  onChange,
  resourceList,
  selectedResource,
  defaultVersion,
  variant = 'h1',
}: ResourceVersionSelectProps<TResource>) => {
  const getResourceLabel = (uuid: string) => {
    const label = getLabelFn((resourceList ?? []).find((o) => o.uuid === uuid));
    if (defaultVersion && defaultVersion === label) {
      return (
        <Stack direction="row" gap={2}>
          {label}
          <Chip label={<FM defaultMessage="Default Version" />} size="small" />
        </Stack>
      );
    }
    return label;
  };

  const handleSelectChangeEvent = useCallback(
    (event: SelectChangeEvent) => {
      const targetUuid = event.target.value;

      const selected = resourceList?.find((o) => o.uuid === targetUuid);
      if (selected) {
        onChange(selected);
      }
    },
    [resourceList, onChange]
  );

  const selectedResourceIdentifier = selectedResource?.uuid ?? '';

  const isLoadingInitial = isLoading || !selectedResource;
  return isLoadingInitial ? null : (
    <Select
      sx={({ spacing, typography }) => ({
        ...typography[variant],
        color: 'primary.main',
        paddingTop: 0,

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'secondary.light',
          borderWidth: '1px',
        },

        '& .MuiSelect-select': {
          padding: spacing(0, 2, 0, 3),
        },

        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },

        '& .MuiSelect-icon': {
          color: 'primary.main',
        },
      })}
      disabled={isLoading}
      value={selectedResourceIdentifier}
      onChange={handleSelectChangeEvent}
      renderValue={getResourceLabel}
    >
      {resourceList?.map((resource) => (
        <MenuItem key={resource.uuid} value={resource.uuid}>
          <ListItemText
            primary={getLabelFn(resource)}
            secondary={getDescriptionFn(resource) ?? null}
          ></ListItemText>
        </MenuItem>
      ))}
    </Select>
  );
};
