import { Checkbox, FormControlLabel } from '@mui/material';
import { KeyboardEvent, SyntheticEvent, useCallback } from 'react';

import { FacetFilterDefinition } from './types';

export interface ControlsFacetFilterToggleProps {
  facet: FacetFilterDefinition;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const ControlsFacetFilterToggle = ({
  facet,
  value = false,
  onChange,
}: ControlsFacetFilterToggleProps) => {
  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    onChange(checked);
  };

  const handleToggleOnEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Enter') {
        onChange(!value);
      }
    },
    [onChange, value]
  );

  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          fontWeight: value ? 'bold' : undefined,
        },
      }}
      control={
        <Checkbox
          checked={value}
          onChange={handleChange}
          onKeyUp={handleToggleOnEnter}
        />
      }
      label={facet.label}
      sx={{
        color: 'primary.main',
        marginRight: 0,
        paddingX: 3,
        borderRadius: 1,
      }}
    />
  );
};
