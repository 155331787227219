import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/queries';
import {
  ButtonSecondary,
  Link,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { getPackageVersionPath } from '../../routes';

/**
 * Shared component for displaying the current version
 * of a package, with a link to the 'global' page for
 * that package, with all versions
 */
export const PackageVersionGlobalViewLinkButton = ({
  packageVersion,
}: {
  packageVersion?: PackageVersionResource;
}) => {
  const { formatMessage: fm } = useIntl();
  const globalViewLabel = fm({ defaultMessage: 'Global View' });

  if (!packageVersion) return null;

  const { version: versionRef } = UIPackageVersionUtils.parsePackageName(
    packageVersion.meta.name
  );
  const packageLink = getPackageVersionPath({
    tenantName: packageVersion.tenant_meta.namespace,
    uuid: packageVersion.uuid,
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="h1" component="span" color="grey.500">
        {versionRef}
      </Typography>

      <ButtonSecondary
        title={globalViewLabel}
        LinkComponent={Link}
        // @ts-expect-error FIXME: `Button` doesn't expect the prop `to`
        to={packageLink}
      >
        {globalViewLabel}
      </ButtonSecondary>
    </Stack>
  );
};
