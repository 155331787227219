import { GraphAdjacencyList, GraphNode } from '@endorlabs/utils/graph';

import { ChecklistInputGroupFn, ChecklistInputOption } from './types';

const ROOT_ID = '__root__';

export const buildChecklistGraphData = ({
  options,
  groupBy,
}: {
  options: ChecklistInputOption[];
  groupBy?: ChecklistInputGroupFn;
}) => {
  const graph: GraphAdjacencyList = {
    [ROOT_ID]: [],
  };
  const nodes: GraphNode<ChecklistInputOption>[] = [];

  for (const o of options) {
    nodes.push({
      id: o.key,
      attributes: o,
    });

    if (!graph[o.key]) {
      graph[o.key] = [];
    }

    const parentId = groupBy ? groupBy(o) : o.key;
    if (parentId === o.key) {
      graph[ROOT_ID].push(o.key);
    } else {
      graph[parentId] = (graph[parentId] ?? []).concat(o.key);
    }
  }

  const rootNodes = nodes.filter((n) => graph[ROOT_ID].includes(n.id));

  return { graph, nodes, rootNodes };
};
