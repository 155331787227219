import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import { isEmpty as _isEmpty, range as _range } from 'lodash-es';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { PACKAGE_MANAGER_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';
import {
  FILTER_COMPARATORS,
  ResourceFilter,
  useFilterSearchParams,
} from '@endorlabs/filters';
import { PackageContexture } from '@endorlabs/queries';
import {
  ButtonPrimary,
  EmptyState,
  IconAlertTriangle,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';
import ImgEndorATMascot from '@endorlabs/ui-common/assets/logos/at-mascot-300.png';
import { EcosystemLabel } from '@endorlabs/ui-common/domains/Package';

import {
  FacetedSearchBar,
  FacetFilterDefinition,
  PageHeader,
} from '../../components';
import { useOnboardingUserEventTracking } from '../../domains/Onboarding';
import { OSSExplorerPageSource } from '../../domains/OSS';
import { PackagesIndexDetail } from '../Packages/PackagesIndex/PackagesIndexDetail';
import { useOSSExplorerPageData } from './useOSSExplorerPackagesData';

type PromptExample = { ecosystem: V1Ecosystem; prompt: string };

const usePromptExamples = (): PromptExample[] => {
  const { formatMessage: fm } = useIntl();

  const examples: PromptExample[] = useMemo(
    () => [
      {
        ecosystem: V1Ecosystem.Maven,
        prompt: fm({
          defaultMessage:
            'What Java packages have a similar function to log4j?',
        }),
      },
      {
        ecosystem: V1Ecosystem.Pypi,
        prompt: fm({
          defaultMessage: 'What AI packages have the most permissive license?',
        }),
      },
      {
        ecosystem: V1Ecosystem.Go,
        prompt: fm({
          defaultMessage:
            'Which Go packages have the least known vulnerabilities?',
        }),
      },
    ],
    [fm]
  );

  return examples;
};

const packageVersionFacets: FacetFilterDefinition[] = [
  {
    id: 'packageVersion:spec.ecosystem',
    filter: {
      kind: 'PackageVersion',
      key: 'spec.ecosystem',
      type: 'string',
    },
    control: 'FacetFilterSelect',
    comparator: FILTER_COMPARATORS.EQUAL,
    label: <FM defaultMessage="Ecosystem" />,
    values: PACKAGE_MANAGER_ECOSYSTEMS.map((value) => ({
      key: value.toString(),
      value,
      label: <EcosystemLabel value={value} />,
    })),
  },
];

type OSSExplorerPageLocationGenerics = MakeGenerics<{
  Search: {
    source?: OSSExplorerPageSource;
  };
}>;

export const OSSExplorerPage = () => {
  const { checkDroidGPTUsed } = useOnboardingUserEventTracking();
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate<OSSExplorerPageLocationGenerics>();
  const theme = useTheme();

  const { source = OSSExplorerPageSource.OSS } =
    useSearch<OSSExplorerPageLocationGenerics>();
  const {
    filterDefaultSearchParams: searchValue,
    filterSearchParams: filters,
    updateFilterDefaultSearchParams: handleSearchChange,
    updateFilterSearchParams: handleUpdateFilters,
  } = useFilterSearchParams();

  const { getErrorMessage } = useResourceCRUDMessages();

  const promptExamples = usePromptExamples();

  // TODO: this is a hack to get the ecosystem filter to work
  const selectedEcosystem = useMemo(() => {
    const ecosystems = filters.find((f) => f.key === 'spec.ecosystem')?.value;
    if (Array.isArray(ecosystems)) {
      return ecosystems[0] as V1Ecosystem;
    } else if ('string' === typeof ecosystems) {
      return ecosystems as V1Ecosystem;
    }
  }, [filters]);

  const qPackages = useOSSExplorerPageData({
    ecosystem: selectedEcosystem,
    searchValue,
    source,
  });

  const { isEmptyState, isLoading } = qPackages;

  useEffect(() => {
    if (
      source !== OSSExplorerPageSource.DroidGPT ||
      !searchValue ||
      !selectedEcosystem
    ) {
      return;
    }

    checkDroidGPTUsed({
      isLoading,
      isEmptyState,
      prompt: searchValue,
      ecosystem: selectedEcosystem,
    });
  }, [
    checkDroidGPTUsed,
    isEmptyState,
    isLoading,
    searchValue,
    selectedEcosystem,
    source,
  ]);

  const hasNoResults =
    (isEmptyState && !!searchValue) ||
    // if asking Droid GPT, must include ecosystem
    (source === OSSExplorerPageSource.DroidGPT &&
      _isEmpty(selectedEcosystem) &&
      !_isEmpty(searchValue));

  const errorAlert = useMemo(() => {
    if (!qPackages.isError) return;

    // extract error message from response for local alert
    switch (qPackages.error?.response?.status) {
      case 429: {
        return (
          <Alert icon={<IconAlertTriangle />} severity="error">
            <AlertTitle>
              <FM defaultMessage="Rate Limit Exceeded" />
            </AlertTitle>
            <FM defaultMessage="Request limit to DroidGPT has recently been exceeded. Please try your query again in a little while." />
          </Alert>
        );
      }
      case 500: {
        return (
          <Alert icon={<IconAlertTriangle />} severity="error">
            <AlertTitle>
              <FM defaultMessage="This is not the droid you're looking for." />
            </AlertTitle>
            <FM defaultMessage="An unknown error occurred while retrieving results. Please try again later." />
          </Alert>
        );
      }
    }

    // Get default error message
    const errorMessage = getErrorMessage('LIST', 'Package', qPackages.error);
    return (
      <Alert icon={<IconAlertTriangle />} severity="error">
        <AlertTitle>{errorMessage.message}</AlertTitle>
        {errorMessage.details}
      </Alert>
    );
  }, [getErrorMessage, qPackages.error, qPackages.isError]);

  const handleChangeSource = (source: OSSExplorerPageSource) => {
    navigate({ search: (prev) => ({ ...prev, source }) });
  };

  const getChangeSourceHandler = (source: OSSExplorerPageSource) => () => {
    handleChangeSource(source);
  };

  // allow selecting a query example
  const handleSelectExample = (example: PromptExample) => {
    // ensure search source is DroidGPT
    handleChangeSource(OSSExplorerPageSource.DroidGPT);

    // set the prompt & ecosystem
    handleSearchChange(example.prompt);
    handleUpdateFilters([
      {
        comparator: FILTER_COMPARATORS.EQUAL,
        kind: 'PackageVersion',
        key: 'spec.ecosystem',
        value: example.ecosystem,
        type: 'string',
      } as unknown as ResourceFilter,
    ]);
  };

  // guided help tooltip
  const [ecosystemTooltipAnchorEl, setEcosystemTooltipAnchorEl] =
    useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const el = document.getElementById('packageVersion:spec.ecosystem');
    if (el) {
      setEcosystemTooltipAnchorEl(el);
    } else {
      setEcosystemTooltipAnchorEl(null);
    }
  }, []);

  return (
    <>
      <PageHeader title="" />

      <Box p={theme.space.xl}>
        <Grid
          alignItems="center"
          container
          direction="column"
          flexWrap="nowrap"
          spacing={8}
        >
          <Grid item>
            <img
              alt={fm({ defaultMessage: 'Endor Labs' })}
              src={ImgEndorATMascot}
              height={150}
            />
          </Grid>

          {errorAlert && (
            <Grid item width={{ sm: '100%', md: '60%' }}>
              {errorAlert}
            </Grid>
          )}

          <Grid item width={{ sm: '100%', md: '60%' }}>
            <FacetedSearchBar
              facets={packageVersionFacets}
              filters={filters}
              onFiltersChange={handleUpdateFilters}
              onSearchChange={handleSearchChange}
              primaryResourceKind="PackageVersion"
              primaryResourceKindLabel="an Open Source Package"
              searchValue={searchValue}
            />
          </Grid>

          <Grid item width={{ sm: '100%', md: '60%' }}>
            <Stack direction="row" justifyContent="center" spacing={6}>
              <ButtonPrimary
                onClick={getChangeSourceHandler(OSSExplorerPageSource.OSS)}
              >
                <FM defaultMessage="Search Open Source Packages" />
              </ButtonPrimary>

              <ButtonPrimary
                onClick={getChangeSourceHandler(OSSExplorerPageSource.DroidGPT)}
              >
                <FM defaultMessage="Ask DroidGPT" />
              </ButtonPrimary>
            </Stack>
          </Grid>

          {isLoading && (
            <Grid item width="100%">
              <Stack direction="column" spacing={theme.space.md}>
                {_range(0, 4).map((_, index) => (
                  <Card key={index}>
                    <CardContent>
                      <Stack direction="row" spacing={4} marginBottom={2}>
                        <Skeleton height={32} width={20} />
                        <Skeleton height={32} width={240} />
                      </Stack>

                      <Stack direction="row" spacing={4}>
                        <Skeleton height={24} width={128} />
                        <Skeleton height={24} width={96} />
                        <Skeleton height={24} width={256} />
                      </Stack>
                    </CardContent>
                  </Card>
                ))}
              </Stack>
            </Grid>
          )}

          {hasNoResults && (
            <Grid item width="100%">
              <EmptyState
                size="medium"
                title={<FM defaultMessage="No results found" />}
                description={<FM defaultMessage="Explore other OSS Packages" />}
              >
                <Stack
                  direction="row"
                  gap={4}
                  flexWrap="wrap"
                  justifyContent="center"
                >
                  {promptExamples.map((example, index) => (
                    <Chip
                      label={example.prompt}
                      key={index}
                      onClick={() => handleSelectExample(example)}
                    />
                  ))}
                </Stack>
              </EmptyState>
            </Grid>
          )}

          {!isEmptyState && (
            <Grid
              item
              role="region"
              aria-labelledby="search-results-summary"
              id="oss-result-container"
              width="100%"
            >
              <Typography
                id="search-results-summary"
                sx={{ visibility: 'hidden' }}
              >
                <FM defaultMessage="Search Results" />
              </Typography>

              <Stack spacing={4} role="list">
                {qPackages.data.map(
                  ({ packageResource, versionCount, versions }) => (
                    <Box key={packageResource.meta.name} role="listitem">
                      <PackagesIndexDetail
                        packageContexture={PackageContexture.Dependencies}
                        packageResource={packageResource}
                        versionCount={versionCount}
                        versions={versions}
                      />
                    </Box>
                  )
                )}
              </Stack>
            </Grid>
          )}
        </Grid>

        {/* Tooltip explicity anchored to the ecosystem select, to direct the user to select an ecosystem, when required */}
        {ecosystemTooltipAnchorEl &&
          source === OSSExplorerPageSource.DroidGPT &&
          !!searchValue &&
          !selectedEcosystem && (
            <Tooltip
              arrow
              open={true}
              placement="top"
              PopperProps={{
                anchorEl: ecosystemTooltipAnchorEl,
              }}
              title={<FM defaultMessage="Select an ecosystem" />}
            >
              <Box>
                {/* NOTE: intentionally empty to satisfy Tooltip props */}
              </Box>
            </Tooltip>
          )}
      </Box>
    </>
  );
};
