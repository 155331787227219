import {
  Box,
  CardHeaderProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import _pickBy from 'lodash-es/pickBy';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

export interface TitleActionHeaderProps
  extends Omit<CardHeaderProps, 'avatar'> {
  variant?: Variant;
}

/**
 * Standardizes a common layout pattern where we want a title on the left & 0 ~ 3 controls on the right.
 * Based on MUI CardHeader, & implements that interface.
 */
export const TitleActionHeader = ({
  action,
  className,
  disableTypography,
  subheader,
  subheaderTypographyProps,
  title,
  titleTypographyProps,
  variant = 'h1',
}: TitleActionHeaderProps) => {
  const { space, spacing, typography } = useTheme();

  const titleNode = useRef<HTMLDivElement>(null);
  const titleSx = {
    alignItems: 'center',
    display: 'flex',
    marginLeft: space.xs,
    minHeight: spacing(9),
  };

  const [topAlignActions, setTopAlignActions] = useState<boolean>(false);

  const updateActionsAlignment = () => {
    const titleHeight = titleNode?.current?.clientHeight ?? 0;
    setTopAlignActions(titleHeight > 40 ? true : false);
  };

  useLayoutEffect(() => {
    updateActionsAlignment();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateActionsAlignment);

    return () => window.removeEventListener('resize', updateActionsAlignment);
  }, []);

  // If no typography props are present, derive all valid props from the typography variant
  const finalTypeProps = useMemo(() => {
    return (
      titleTypographyProps ??
      _pickBy(typography[variant], (v, k) => !k.match(/^@media/))
    );
  }, [titleTypographyProps, typography, variant]);

  return (
    <Stack
      alignItems={!!subheader || topAlignActions ? 'flex-start' : 'center'}
      columnGap={space.md}
      className={className}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
      rowGap={space.xs}
      width="100%"
    >
      <Stack direction="column" spacing={space.xs} flexGrow={1} role="heading">
        {/* A FormattedMessage is sufficient here, but can take more complex content */}
        {disableTypography ? (
          <Box sx={{ ...titleSx }}>{title}</Box>
        ) : (
          <Typography
            {...finalTypeProps}
            component="div"
            ref={titleNode}
            sx={{ ...titleSx }}
          >
            {title}
          </Typography>
        )}

        {subheader && (
          <Typography variant="body2" {...subheaderTypographyProps}>
            {subheader}
          </Typography>
        )}
      </Stack>

      {/* The action area is meant for controls (Buttons, IconButtons, Selects, etc.).
      Multiple controls should be wrapped in a fragment. */}
      {action && (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-end"
          minHeight={spacing(9)}
          spacing={space.xs}
          zIndex={2}
        >
          {action}
        </Stack>
      )}
    </Stack>
  );
};
