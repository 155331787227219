import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreFactor } from '@endorlabs/api_client';
import {
  ClampLinesDisplay,
  DrawerAccordion,
  RowStack,
  ScoreCategoryMessages,
  ScoreDisplay,
} from '@endorlabs/ui-common';

export interface ScoreFactorDisplayProps {
  scoreFactor: V1ScoreFactor;
}

export const ScoreFactorDisplay = ({
  scoreFactor,
}: ScoreFactorDisplayProps) => {
  const { space } = useTheme();
  return (
    <DrawerAccordion
      id="score-factor"
      expanded={false}
      align="start"
      titleNode={
        <Stack gap={1}>
          <RowStack alignItems="center">
            <ScoreDisplay
              size="small"
              value={scoreFactor.score as number}
              variant="factor"
            />
            {scoreFactor.category && (
              <Chip
                label={
                  <Typography variant="body2" fontWeight={500}>
                    <FM {...ScoreCategoryMessages[scoreFactor.category]} />
                  </Typography>
                }
                size="small"
              />
            )}
          </RowStack>
          <Typography
            variant="body1"
            fontWeight={600}
            sx={{ wordBreak: 'break-word' }}
          >
            {scoreFactor.name}
          </Typography>
          <Typography sx={{ wordBreak: 'break-word' }}>
            {scoreFactor.description}
          </Typography>
        </Stack>
      }
    >
      {scoreFactor.evidence && (
        <Box pl={space.sm} mt={space.xs}>
          <Typography variant="h4" sx={{ marginBottom: 1 }}>
            <FM defaultMessage="Evidence" />
          </Typography>

          <ClampLinesDisplay maxLines={4}>
            <Typography sx={{ wordBreak: 'break-word' }}>
              {scoreFactor.evidence}
            </Typography>
          </ClampLinesDisplay>
        </Box>
      )}
    </DrawerAccordion>
  );
};
