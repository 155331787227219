/* eslint sort-keys: "error" */

import {
  BomDependency as ResolvedDependency,
  CargoVersionReqOp,
  V1Ecosystem,
  V1PackageVersionDependency as UnresolvedDependency,
} from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/queries';

import { ECOSYSTEM_SEPARATOR, VERSION_SEPARATOR } from '../constants';
import { prefixFromEcosystem } from './ecosystem';
import { stripPackageVersionRef } from './stripPackageVersionRef';

interface ResolvedDependencyWithPackageName extends ResolvedDependency {
  packageName: string;
}

const TRANSFORMS: {
  [K in keyof UnresolvedDependency]: (
    spec: Required<UnresolvedDependency>[K]
  ) => ResolvedDependencyWithPackageName;
} = {
  cargo: (spec) => {
    let versionRef = '*';

    const versionConstraint = spec.req?.[0];
    if (versionConstraint) {
      const versionParts = [
        versionConstraint.major,
        versionConstraint.minor,
        versionConstraint.patch,
      ].filter((i) => i);

      versionRef = versionParts.join('.');

      if (versionConstraint.pre) {
        versionRef += `-${versionConstraint.pre}`;
      }
    }

    switch (versionConstraint?.op) {
      case CargoVersionReqOp.Exact:
        // do nothing for exect version
        break;
      case CargoVersionReqOp.Caret:
        versionRef = `^${versionRef}`;
        break;
      case CargoVersionReqOp.Greater:
        versionRef = `>${versionRef}`;
        break;
      case CargoVersionReqOp.Greatereq:
        versionRef = `>=${versionRef}`;
        break;
      case CargoVersionReqOp.Less:
        versionRef = `<${versionRef}`;
        break;
      case CargoVersionReqOp.Lesseq:
        versionRef = `<=${versionRef}`;
        break;
      case CargoVersionReqOp.Tilde:
        versionRef = `~${versionRef}`;
        break;
      case CargoVersionReqOp.Wildcard:
        versionRef = `*`;
        break;
    }

    const prefix = prefixFromEcosystem(V1Ecosystem.Cargo);
    const packageName = `${prefix}${ECOSYSTEM_SEPARATOR}${spec.name}`;
    const packageVersionName = `${packageName}${VERSION_SEPARATOR}${versionRef}`;

    const dependency: ResolvedDependencyWithPackageName = {
      name: packageVersionName,
      packageName,
      public: !spec.path, // consider private if path is set
      rust_dependency_kind: spec.kind,
    };

    return dependency;
  },
  gem: (spec) => {
    const versionRef = spec.version_constraints;

    const prefix = prefixFromEcosystem(V1Ecosystem.Gem);
    const packageName = `${prefix}${ECOSYSTEM_SEPARATOR}${spec.name}`;
    const packageVersionName = `${packageName}${VERSION_SEPARATOR}${versionRef}`;

    const dependency: ResolvedDependencyWithPackageName = {
      gem_dependency_scope: spec.scope_type,
      name: packageVersionName,
      packageName,
    };
    return dependency;
  },
  go: (spec) => {
    const packageName = stripPackageVersionRef(spec.package);
    const packageVersionName = spec.package;

    const dependency: ResolvedDependencyWithPackageName = {
      golang_dependency_scope: spec.scope_type,
      name: packageVersionName,
      packageName,
    };
    return dependency;
  },
  maven: (spec) => {
    const { artifact_id, group_id, version_constraints } = spec;

    // TODO: better parse for version ref from version constraint
    const versionRefRegex = /[\d.a-z-]+/gi;
    const versionRef = version_constraints.match(versionRefRegex)?.pop();

    const prefix = prefixFromEcosystem(V1Ecosystem.Maven);
    const packageName = `${prefix}${ECOSYSTEM_SEPARATOR}${group_id}:${artifact_id}`;
    const packageVersionName = `${packageName}${VERSION_SEPARATOR}${versionRef}`;

    const dependency: ResolvedDependencyWithPackageName = {
      maven_dependency_scope: spec.scope_type,
      name: packageVersionName,
      packageName,
    };
    return dependency;
  },
  npm: (spec) => {
    const packageName = stripPackageVersionRef(spec.name);
    const packageVersionName = spec.name;

    const dependency: ResolvedDependencyWithPackageName = {
      js_dependency_scope: spec.scope,
      name: packageVersionName,
      packageName,
    };
    return dependency;
  },
  pypi: (spec) => {
    let name = spec.name;
    let versionRef = spec.version_constraints;

    // handle external dependencies (e.g. `git+https://github.com/endorlabs/python-deps@4d08b6e`)
    const externalDepRegex = /^(git\+)?https?:\/\//;
    if (externalDepRegex.test(spec.name)) {
      name = spec.name.replace(externalDepRegex, '');
      if (name.indexOf('@') > -1) {
        [name, versionRef] = name.split('@');
      }
    }

    const prefix = prefixFromEcosystem(V1Ecosystem.Pypi);
    const packageName = `${prefix}${ECOSYSTEM_SEPARATOR}${name}`;
    const packageVersionName = `${packageName}${VERSION_SEPARATOR}${versionRef}`;

    const dependency: ResolvedDependencyWithPackageName = {
      name: packageVersionName,
      packageName,
      pypi_dependency_scope: spec.scope_type,
    };
    return dependency;
  },
};

export const mapUnresolvedToResolvedDependencies = (
  packageVersion: PackageVersionResource
): ResolvedDependencyWithPackageName[] => {
  const unresolvedDependencies =
    packageVersion.spec.unresolved_dependencies ?? [];

  const mapped: ResolvedDependencyWithPackageName[] = [];
  for (const dependency of unresolvedDependencies) {
    for (const [target, transform] of Object.entries(TRANSFORMS)) {
      if (Reflect.has(dependency, target)) {
        const spec = dependency[target as keyof UnresolvedDependency];
        const result = transform(spec as any);
        mapped.push(result);
        break;
      }
    }

    // TODO: warn on unknown dependency type
  }

  return mapped;
};
