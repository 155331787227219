import { Stack, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory } from '@endorlabs/api_client';
import { DEFAULT_FINDING_CATEGORIES } from '@endorlabs/endor-core/Finding';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  FindingCategoryLabel,
  IconLock,
  MultiSelectInput,
} from '@endorlabs/ui-common';

import { useLicensingInfo } from '../../../../providers';
import {
  FilterFieldConfig,
  FilterFieldProps,
  filterFieldTransformBuilders,
} from '../../../filters';
import { filterToLicenseMapping } from '../common';

const LicenseWrapperForFindingsCategory = ({
  onChange,
  value,
}: FilterFieldProps<V1FindingCategory[]>) => {
  const { checkLicensePresent } = useLicensingInfo();
  const { ENABLE_SAST_FEATURE: isSASTEnabled } = useFeatureFlags();
  const finding_categories = isSASTEnabled
    ? DEFAULT_FINDING_CATEGORIES
    : DEFAULT_FINDING_CATEGORIES.filter(
        (category) => !(category === V1FindingCategory.Sast)
      );
  return (
    <MultiSelectInput
      label={<FM defaultMessage="Category" />}
      onChange={onChange}
      options={finding_categories.map((value) => {
        const licenseFeatureType = filterToLicenseMapping[value];

        // If there is corresponding license for this category, check if it is
        // licensed for the active tenant.
        const licensePresent = licenseFeatureType
          ? checkLicensePresent(licenseFeatureType)
          : true;

        return {
          key: value.toString(),
          value,
          label: (
            <Stack direction="row" spacing={2}>
              <Typography component="span">
                <FindingCategoryLabel findingCategory={value} />
              </Typography>
              {!licensePresent && <IconLock />}
            </Stack>
          ),
          disabled: !licensePresent,
        };
      })}
      value={value as V1FindingCategory[]}
    />
  );
};

export const FindingCategoryFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.finding_categories',

  ...filterFieldTransformBuilders.fromFilter<V1FindingCategory[]>(
    {
      key: 'spec.finding_categories',
      comparator: FILTER_COMPARATORS.CONTAINS,
    },
    { defaultValue: [] }
  ),

  initialFilter: {
    key: 'spec.finding_categories',
    comparator: FILTER_COMPARATORS.CONTAINS,
    value: [V1FindingCategory.Vulnerability],
  },

  renderInput: ({ onChange, value }) => (
    <LicenseWrapperForFindingsCategory onChange={onChange} value={value} />
  ),
} satisfies FilterFieldConfig<V1FindingCategory[]>;
