import { Stack, Tooltip, Typography } from '@mui/material';
import { isNil } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { BooleanDisplay, NumberDisplay, RelativeTimeDisplay } from '../formats';
import { Link } from '../Link';
import { NilDisplay } from '../NilDisplay';
import { ScoreDisplay } from '../ScoreDisplay';
import { MetadataMetric } from './types';

type MetricDisplayProps = Pick<MetadataMetric, 'variant' | 'value'>;
const MetricDisplay = ({ variant, value }: MetricDisplayProps) => {
  // show placeholder for `null` or `undefined` values
  if (isNil(value)) {
    return (
      <Tooltip
        title={
          <FM defaultMessage="Metric value is unavailable for this item" />
        }
      >
        <NilDisplay variant="text" />
      </Tooltip>
    );
  }

  if (variant === 'date')
    return <RelativeTimeDisplay value={value as string} />;
  if (variant === 'score') return <ScoreDisplay value={value as number} />;
  if (variant === 'number') return <NumberDisplay value={value as number} />;
  if (variant === 'boolean') return <BooleanDisplay value={value as boolean} />;
  if (variant === 'link')
    return (
      <Link to={value as string} onClick={(event) => event.stopPropagation()}>
        {value}
      </Link>
    );
  if (variant === 'raw')
    return (
      <Typography component="span" variant="body2">
        {value}
      </Typography>
    );

  return null;
};

export interface MetadataListMetricItemProps extends MetadataMetric {
  labelMinWidth?: number;
}

export const MetadataListMetricListItem = ({
  label,
  labelMinWidth,
  value,
  variant = 'raw',
}: MetadataListMetricItemProps) => (
  <Stack direction="row" gap={1} alignItems="center">
    <Typography
      component="span"
      sx={{
        color: 'text.secondary',
        minWidth: () => labelMinWidth ?? 'auto',
      }}
      variant="body2"
    >
      {label}
    </Typography>

    <MetricDisplay variant={variant} value={value} />
  </Stack>
);
