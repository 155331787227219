export const getTextFieldOverrides = () => {
  return {
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        fullWidth: true,
        size: 'small',
        variant: 'standard',
      },
    },
  };
};
