import {
  SemgrepRuleServiceApi,
  V1CountResponse,
  V1SemgrepRule,
} from '@endorlabs/api_client';
import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';

import { createResourceMutateHooks, createResourceReadHooks } from './hooks';
import { ResourceGroupResponse, ResourceListResponse } from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  buildUpdateReq,
  DEFAULT_UPDATE_MASK,
  getClientConfiguration,
} from './utils';

const queries = createResourceReadHooks('SemgrepRule', () => {
  const getApiService = () =>
    new SemgrepRuleServiceApi(getClientConfiguration());

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp = await getApiService().semgrepRuleServiceListSemgrepRules(
        namespace,
        ...args,
        options
      );
      return resp?.data.count_response as Required<V1CountResponse>;
    },

    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp = await getApiService().semgrepRuleServiceGetSemgrepRule(
        namespace,
        ...args,
        options
      );
      return resp?.data as SemgrepRulesResource;
    },

    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await getApiService().semgrepRuleServiceListSemgrepRules(
        namespace,
        ...args,
        options
      );
      return resp?.data.group_response as ResourceGroupResponse;
    },

    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await getApiService().semgrepRuleServiceListSemgrepRules(
        namespace,
        ...args,
        options
      );
      return resp?.data.list as ResourceListResponse<SemgrepRulesResource>;
    },
  };
});

const mutations = createResourceMutateHooks<SemgrepRulesResource>(
  'SemgrepRule',
  () => {
    const getApiService = () =>
      new SemgrepRuleServiceApi(getClientConfiguration());
    return {
      async create(namespace, resource) {
        const resp = await getApiService().semgrepRuleServiceCreateSemgrepRule(
          namespace,
          resource as V1SemgrepRule
        );

        return resp.data as SemgrepRulesResource;
      },

      async update(namespace, params) {
        const { resource, mask = DEFAULT_UPDATE_MASK } = params;
        const request = buildUpdateReq(resource, mask);
        const resp = await getApiService().semgrepRuleServiceUpdateSemgrepRule(
          namespace,
          request
        );
        return resp.data as SemgrepRulesResource;
      },

      async delete(namespace, resource) {
        await getApiService().semgrepRuleServiceDeleteSemgrepRule(
          namespace,
          resource.uuid
        );
      },
    };
  }
);

//Query hooks
export const useCountSemgrepRules = queries.useCount;
export const useListSemgrepRules = queries.useList;
export const useGroupSemgrepRules = queries.useGroup;
export const useGetSemgrepRules = queries.useGet;

//Mutation hooks
export const useCreateSemgrepRules = mutations.useCreate;
export const useUpdateSemgrepRules = mutations.useUpdate;
export const useDeleteSemgrepRules = mutations.useDelete;
