import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ControlledTextField,
  ExternalLink,
  RowStack,
} from '@endorlabs/ui-common';

import { getEnv } from '../../../constants';
import { useFormUpsertContext } from './FormUpsertContext';

// Regular expression to match the required format
const urlPattern =
  /^https:\/\/dev\.azure\.com\/([A-Za-z0-9-]+)\/([A-Za-z0-9]+(?:%20[A-Za-z0-9]*)*)$/;

const urlExample = 'https://dev.azure.com/{organization}/{project}';

const ENV = getEnv();
interface AzureConfigFieldsProps {
  isEditing: boolean;
}

export const AzureConfigFields = ({ isEditing }: AzureConfigFieldsProps) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control } = useFormUpsertContext();

  return (
    <Stack rowGap={space.md}>
      <ControlledTextField
        control={control}
        disabled={isEditing}
        helperText={fm(
          { defaultMessage: 'Must be in the form {example}' },
          { example: urlExample }
        )}
        name="spec.azure_config.host_url"
        placeholder={fm({ defaultMessage: '{urlExample}' }, { urlExample })}
        rules={{
          required: 'Host URL is required',
          pattern: {
            value: urlPattern,
            message: fm(
              {
                defaultMessage:
                  'URL must follow the format: {example}. Organization may include letters, numbers, or hyphens; Project may include spaces.',
              },
              { example: urlExample }
            ),
          },
        }}
        label={<FM defaultMessage="Host URL" />}
        required
      />

      {/* PAT will be `<redacted>` all the time in edit mode.
      Only update in the payload if the user chooses to update it. */}
      <ControlledTextField
        placeholder="*************"
        control={control}
        label={fm({
          defaultMessage: 'Personal Access Token (PAT)',
        })}
        name="spec.azure_config.personal_access_token"
        rules={{
          required: isEditing
            ? undefined
            : fm({ defaultMessage: 'PAT is required' }),
          maxLength: {
            value: 53,
            message: fm({ defaultMessage: 'Exceeds maximum length' }),
          },
        }}
        helperText={
          <RowStack gap={1}>
            <FM defaultMessage="Requires at least code::read permissions" />
            <ExternalLink to={ENV.URL_AZURE_PAT}>
              <FM defaultMessage="Create a PAT" />
            </ExternalLink>
          </RowStack>
        }
        type="password"
      />
    </Stack>
  );
};
