import { TextField } from '@mui/material';
import { noop } from 'lodash-es';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FilterableResource } from '@endorlabs/filters';
import { useResourceKindDisplayLabel } from '@endorlabs/ui-common';

type FormDefaultSearchFieldValues = {
  searchValue: string;
};

export interface FormDefaultSearchProps {
  primaryResourceKind: FilterableResource;
  primaryResourceKindLabel?: string;
  onChange?: (searchValue: string) => void;
}

export const FormDefaultSearch = ({
  primaryResourceKind,
  primaryResourceKindLabel,
  onChange = noop,
}: FormDefaultSearchProps) => {
  const { formatMessage: fm } = useIntl();

  const { control, handleSubmit, reset } =
    useForm<FormDefaultSearchFieldValues>();

  const onSubmit = ({ searchValue }: FormDefaultSearchFieldValues) => {
    onChange(searchValue);
    reset({ searchValue: '' });
  };

  const defaultPrimaryResourceKindLabel = useResourceKindDisplayLabel(
    primaryResourceKind,
    true
  );

  // get placeholder for the autocomplete input
  const placeholder = fm(
    { defaultMessage: 'Search for {kind}' },
    {
      kind: primaryResourceKindLabel ?? defaultPrimaryResourceKindLabel,
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="searchValue"
        defaultValue=""
        rules={{ required: true, minLength: 1 }}
        render={({ field: { ref, ...fieldProps }, fieldState }) => (
          <TextField
            {...fieldProps}
            inputRef={ref}
            error={!!fieldState.error}
            type="search"
            autoComplete="off"
            placeholder={placeholder}
            variant="standard"
            fullWidth
            InputProps={{ disableUnderline: true }}
            sx={{
              verticalAlign: 'inherit',
              '& .MuiInput-input': {
                // remove extra padding under the input
                paddingBottom: 0,
              },
            }}
          />
        )}
      />
    </form>
  );
};
