import { remove as _remove } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ContextContextType,
  V1Ecosystem,
  V1ScanState,
} from '@endorlabs/api_client';
import {
  isBinaryProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  useCountCIRuns,
  useCountFindings,
  useCountPackageVersions,
  useCountUniqueDependencyMetadata,
  useCountVersionUpgrade,
  useFeatureFlags,
  useGroupVersionUpgrade,
  useListMetrics,
} from '@endorlabs/queries';
import { LinkTabProps, UIProjectUtils } from '@endorlabs/ui-common';

import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../constants';

interface ProjectVersionTabRecord extends LinkTabProps {
  value: (typeof ProjectVersionTabNames)[keyof typeof ProjectVersionTabNames];
}

export const ProjectVersionTabNames = {
  CI_RUNS: 'pr-runs',
  DEPENDENCIES: 'dependencies',
  FINDINGS: 'findings',
  OVERVIEW: 'overview',
  PACKAGES: 'packages',
  SETTINGS: 'settings',
  TOOLS: 'tools',
  REMEDIATIONS: 'remediations',
} as const;

const buildTabRecords = (props: {
  ciRunsCount?: number;
  ciRunsIsLoading: boolean;
  dependenciesCount?: number;
  dependenciesIsLoading: boolean;
  findingsCount?: number;
  findingsIsLoading: boolean;
  packagesCount?: number;
  packagesIsLoading: boolean;
  toolsCount?: number;
  toolsIsLoading: boolean;
  remediationsCount?: number;
  remediationsIsLoading: boolean;
}) => {
  const tabRecords: ProjectVersionTabRecord[] = [
    {
      label: <FM defaultMessage="Overview" />,
      to: ProjectVersionTabNames.OVERVIEW,
      value: ProjectVersionTabNames.OVERVIEW,
    },
    {
      label: <FM defaultMessage="Findings" />,
      to: ProjectVersionTabNames.FINDINGS,
      value: ProjectVersionTabNames.FINDINGS,
      count: props.findingsCount,
      isLoading: props.findingsIsLoading,
    },
    {
      label: <FM defaultMessage="Packages" />,
      to: ProjectVersionTabNames.PACKAGES,
      value: ProjectVersionTabNames.PACKAGES,
      count: props.packagesCount,
      isLoading: props.packagesIsLoading,
    },
    {
      label: <FM defaultMessage="Dependencies" />,
      to: ProjectVersionTabNames.DEPENDENCIES,
      value: ProjectVersionTabNames.DEPENDENCIES,
      count: props.dependenciesCount,
      isLoading: props.dependenciesIsLoading,
    },
    {
      label: <FM defaultMessage="Tools" />,
      to: ProjectVersionTabNames.TOOLS,
      value: ProjectVersionTabNames.TOOLS,
      count: props.toolsCount,
      isLoading: props.toolsIsLoading,
    },
    {
      label: <FM defaultMessage="Remediations" />,
      to: ProjectVersionTabNames.REMEDIATIONS,
      value: ProjectVersionTabNames.REMEDIATIONS,
      count: props.remediationsCount,
      isLoading: props.remediationsIsLoading,
      isBeta: false,
    },
    {
      label: <FM defaultMessage="PR Runs" />,
      to: ProjectVersionTabNames.CI_RUNS,
      value: ProjectVersionTabNames.CI_RUNS,
      count: props.ciRunsCount,
      isLoading: props.ciRunsIsLoading,
    },
    {
      label: <FM defaultMessage="Settings" />,
      to: ProjectVersionTabNames.SETTINGS,
      value: ProjectVersionTabNames.SETTINGS,
    },
  ];

  return tabRecords;
};

export interface UseProjectVersionTabsProps {
  activeTab?: string;
  namespace: string;
  project?: ProjectResource;
  projectVersion?: ProjectVersionResource;
}

export const useProjectVersionTabs = ({
  activeTab: targetActiveTab,
  namespace,
  project,
  projectVersion,
}: UseProjectVersionTabsProps) => {
  const isGithubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );

  const isPRRun = projectVersion?.context.type === ContextContextType.CiRun;
  const isScanningPackages =
    !isPRRun &&
    project?.processing_status?.scan_state === V1ScanState.Ingesting;

  const showRemediationsTab = project && !isBinaryProject(project);

  const staleTime = FIFTEEN_MINUTES_IN_MILLISECONDS;

  const relatedFilterExpression = useMemo(() => {
    const projectFilterExpression =
      UIProjectUtils.getProjectRelatedFilterExpressions(
        project,
        projectVersion
      );

    // When GitHub actions feature flag is enable, return related filter for
    // _all_ package versions from a project.
    if (isGithubActionsEnabled) {
      return projectFilterExpression;
    }

    // When GitHub actions feature flag is not enabled, return related filter
    // for _only_ package versions from a project that do not have the
    // GitHub Action ecosystem.
    if (projectFilterExpression) {
      return filterExpressionBuilders.and([
        projectFilterExpression,
        `spec.ecosystem!=${V1Ecosystem.GithubAction}`,
      ]);
    }

    return undefined;
  }, [isGithubActionsEnabled, project, projectVersion]);

  const dependenciesFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(project, projectVersion, {
      key: 'spec.importer_data.project_uuid',
    });

  const projectToolsFilterExpression = useMemo(() => {
    if (
      !projectVersion?.uuid ||
      projectVersion.meta.kind !== 'RepositoryVersion'
    )
      return undefined;

    const filterExpression = [
      'meta.name==version_cicd_tools',
      `meta.parent_uuid=="${projectVersion.uuid}"`,
    ];

    if (projectVersion?.context) {
      filterExpression.push(
        filterExpressionBuilders.relatedResourceContext(
          projectVersion
        ) as string
      );
    }

    return filterExpressionBuilders.and(filterExpression);
  }, [projectVersion]);

  const remediationsFilterExpression = useMemo(() => {
    if (!project?.uuid) {
      return;
    }

    const projectFilter = UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      projectVersion,
      { key: 'spec.project_uuid' }
    );

    if (!projectFilter) return '';

    const expressions = [
      projectFilter,
      `meta.name==AllUpgradesPerDependency`,
      `spec.upgrade_info exists`,
      filterExpressionBuilders.or([
        'spec.upgrade_info.vuln_finding_info.reduction > 0',
        'spec.upgrade_info.other_finding_info.reduction > 0',
      ]),
    ];

    return filterExpressionBuilders.and(expressions);
  }, [project, projectVersion]);

  const qCountProjectPackageVersions = useCountPackageVersions(
    namespace,
    {
      staleTime,
      enabled: !isScanningPackages && !!relatedFilterExpression,
    },
    {
      filter: relatedFilterExpression,
    }
  );

  // count of "unique" dependencies for all package versions in the project
  const qRepositoryVersionDependencies = useCountUniqueDependencyMetadata(
    namespace,
    {
      enabled: !!dependenciesFilterExpression,
    },
    {
      filter: dependenciesFilterExpression,
    }
  );

  const qCountProjectFindings = useCountFindings(
    namespace,
    {
      staleTime,
      enabled: !!relatedFilterExpression,
    },
    {
      filter: relatedFilterExpression,
    }
  );

  const qCountProjectCIRuns = useCountCIRuns(
    namespace,
    {
      staleTime,
      enabled: !!project?.uuid && !isPRRun,
    },
    {
      filter: `meta.parent_kind==Project and meta.parent_uuid==${project?.uuid}`,
    }
  );

  const qProjectToolMetrics = useListMetrics(
    namespace,
    {
      staleTime,
      enabled: !!projectToolsFilterExpression,
    },
    {
      filter: projectToolsFilterExpression,
      page_size: 1,
    }
  );

  const projectToolsCount = useMemo(() => {
    if (qProjectToolMetrics.isSuccess) {
      return (
        qProjectToolMetrics.data?.list?.objects?.[0]?.spec.metric_values
          ?.CiCdTools?.ci_cd_tools?.tools?.length ?? 0
      );
    }
  }, [qProjectToolMetrics.data?.list?.objects, qProjectToolMetrics.isSuccess]);

  const qGroupRemediations = useGroupVersionUpgrade(
    namespace,
    {
      filter: remediationsFilterExpression,
      group: {
        aggregation_paths: [
          'spec.upgrade_info.direct_dependency_package',
          'spec.upgrade_info.root_package_version',
        ].join(','),
      },
    },
    { enabled: showRemediationsTab && !!remediationsFilterExpression }
  );

  const remediationsCount = qGroupRemediations.data?.groups
    ? Object.keys(qGroupRemediations.data.groups).length
    : undefined;

  const tabRecords = buildTabRecords({
    ciRunsCount: qCountProjectCIRuns.data?.count,
    ciRunsIsLoading: qCountProjectCIRuns.isLoading,
    dependenciesCount: qRepositoryVersionDependencies.data?.count,
    dependenciesIsLoading: qRepositoryVersionDependencies.isLoading,
    findingsCount: qCountProjectFindings.data?.count,
    findingsIsLoading: qCountProjectFindings.isLoading,
    packagesCount: qCountProjectPackageVersions.data?.count,
    packagesIsLoading:
      isScanningPackages || qCountProjectPackageVersions.isLoading,
    toolsCount: projectToolsCount,
    toolsIsLoading: qProjectToolMetrics.isLoading,
    remediationsCount,
    remediationsIsLoading: qGroupRemediations.isLoading,
  });

  // Hide non-applicable tabs for projects from package and container scans
  if (project && isBinaryProject(project)) {
    const toHide: string[] = [
      ProjectVersionTabNames.OVERVIEW,
      ProjectVersionTabNames.TOOLS,
      ProjectVersionTabNames.CI_RUNS,
    ];

    _remove(tabRecords, (tab) => toHide.includes(tab.value));
  }

  // Hide non-applicable tabs for PR run scans
  if (isPRRun) {
    const toHide: string[] = [
      ProjectVersionTabNames.CI_RUNS,
      ProjectVersionTabNames.SETTINGS,
    ];

    _remove(tabRecords, (tab) => toHide.includes(tab.value));
  }

  // Remove CI/CD tools section when user does not have feature flag
  const isCiCdToolsEnabled = useFeatureFlags((s) => s.ENABLE_CI_CD_TOOLS);
  if (!isCiCdToolsEnabled) {
    _remove(tabRecords, (tab) => tab.value === ProjectVersionTabNames.TOOLS);
  }

  // Remove Remediations section when user does not have feature flag
  const isRemediationsEnabled = useFeatureFlags((s) => s.ENABLE_REMEDIATIONS);
  if (!isRemediationsEnabled || !showRemediationsTab) {
    _remove(
      tabRecords,
      (tab) => tab.value === ProjectVersionTabNames.REMEDIATIONS
    );
  }

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    ProjectVersionTabNames.FINDINGS;

  return { activeTab, tabRecords };
};
