import { Stack, SvgIconProps, Theme, Tooltip, Typography } from '@mui/material';
import { JSXElementConstructor } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1PlatformSource,
  V1ProcessingStatus,
  V1Project,
  V1RepositoryVersion,
  V1ScanState,
} from '@endorlabs/api_client';
import {
  getRunBySystem,
  ScanResultResource,
} from '@endorlabs/endor-core/ScanResult';
import { useStyles } from '@endorlabs/ui-common';

import { RelativeTimeDisplay } from '../../../../components';
import { IconCloud, IconCloudOff, IconTerminal } from '../../../../themes';
import { UIProjectUtils } from '../../utils';

const getScanStateIcon = (
  status?: V1ProcessingStatus
): JSXElementConstructor<SvgIconProps> => {
  const isManuallyScanned = status?.disable_automated_scan === true;
  if (isManuallyScanned) {
    return IconTerminal;
  }

  if (status?.scan_state === V1ScanState.Unreachable) {
    return IconCloudOff;
  }

  return IconCloud;
};

const getScanStateLabel = (
  status?: V1ProcessingStatus,
  platformSource?: V1PlatformSource,
  scanResult?: ScanResultResource
) => {
  const isGitHubAppScan =
    platformSource === V1PlatformSource.Github &&
    scanResult &&
    getRunBySystem(scanResult);

  if (isGitHubAppScan) {
    return <FM defaultMessage="GitHub App" />;
  }

  const isManuallyScanned = status?.disable_automated_scan === true;
  return isManuallyScanned ? (
    <FM defaultMessage="CLI" />
  ) : (
    <FM defaultMessage="Endor Labs" />
  );
};

const getScanStateTooltip = (
  status?: V1ProcessingStatus,
  platformSource?: V1PlatformSource
) => {
  if (status?.scan_state !== V1ScanState.Unreachable) {
    return null;
  }

  switch (platformSource) {
    case V1PlatformSource.Github:
      return <FM defaultMessage="GitHub is unreachable" />;
    case V1PlatformSource.Gitlab:
      return <FM defaultMessage="GitLab is unreachable" />;
    default:
      return <FM defaultMessage="Project is unreachable" />;
  }
};

export interface ProjectProcessingStatusProps {
  project?: V1Project;
  repositoryVersion?: V1RepositoryVersion;
  /**
   * The latest scan result (if available)
   */
  scanResult?: ScanResultResource;
  showLabel?: boolean;
  showScanTime?: boolean;
}

export const ProjectProcessingStatus = ({
  project,
  repositoryVersion,
  scanResult,
  showLabel,
  showScanTime,
}: ProjectProcessingStatusProps) => {
  const projectStatus = project?.processing_status;
  const platformSource = project?.spec.platform_source;

  // get display values
  const lastScanTime = UIProjectUtils.getLastCodeScanTime(
    project,
    repositoryVersion
  );
  const ScanStateIcon = getScanStateIcon(projectStatus);
  const scanStateLabel = getScanStateLabel(
    projectStatus,
    platformSource,
    scanResult
  );
  const scanStateTooltip = getScanStateTooltip(projectStatus, platformSource);

  const isFailure = projectStatus?.scan_state === V1ScanState.Unreachable;
  const sx = useStyles(styles, { isFailure });

  const display = (
    <Stack direction="row" gap={2} alignItems="center" sx={sx}>
      <ScanStateIcon />

      {showLabel && <Typography component="span">{scanStateLabel}</Typography>}

      {showScanTime && (
        <Typography component="span">
          <RelativeTimeDisplay value={lastScanTime as string} />
        </Typography>
      )}
    </Stack>
  );

  // conditionally wrap the display with tooltip
  if (scanStateTooltip) {
    return <Tooltip title={scanStateTooltip}>{display}</Tooltip>;
  }

  return display;
};

function styles(theme: Theme, options?: { isFailure: boolean }) {
  if (options?.isFailure) {
    return {
      color: theme.palette.status.failure,
    };
  }

  return {
    color: theme.palette.text.primary,
    '& .MuiSvgIcon-root': { color: theme.palette.text.secondary },
  };
}
