import { Grid } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { EmptyState } from '@endorlabs/ui-common';

export const PackageVersionDetailCompare = () => {
  const isEmptyState = true;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="Project comparisons are coming soon" />}
            description={
              <FM defaultMessage="You will be able to explore the differences between two projects or project versions." />
            }
          ></EmptyState>
        </Grid>
      )}
    </Grid>
  );
};
