import { Components, Theme } from '@mui/material';

type FormControlOverrides = Pick<Components<Theme>, 'MuiFormControl'>;

export const getFormControlOverrides = (): FormControlOverrides => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:has(input[type="hidden"]:only-child)': {
            display: 'none',
          },
        },
      },
    },
  };
};
