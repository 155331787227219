import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Language } from '@endorlabs/api_client';
import { GitHubWorkflowJobStep } from '@endorlabs/endor-core/Demo';
import {
  isCompletedDemo,
  isGitHubWorkflowJob,
} from '@endorlabs/endor-core/Demo/utils';
import {
  useCreateDemo,
  useGetDemo,
  useListDemos,
} from '@endorlabs/queries/demo';
import {
  ButtonPrimary,
  ButtonSecondary,
  FlexList,
  FlexListItem,
  IconAlertCircle,
  IconAlertTriangle,
  IconCheckCircle,
  IconChevronDown,
  IconExternalLink,
  IconMinusCircle,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';
import { LanguageLabel } from '@endorlabs/ui-common/domains/Package';

import { useOnboardingSteps } from '../../domains/Onboarding';
import { useAuthInfo } from '../../providers';
import { OnboardingStepContentProps } from './types';

const DEMO_LONG_POLL_INTERVAL = 2_000;

type SupportedDemoRepository = {
  id: string;
  label: JSX.Element;
  language: V1Language;
  link: string;
};

/**
 * References:
 * - {@link https://github.com/orgs/endorlabs-demos/repositories}
 * - {@link src/golang/internal.endor.ai/pkg/demo/demo.go}
 */
export const SUPPORTED_DEMO_REPOSITORIES: SupportedDemoRepository[] = [
  {
    id: 'packer-plugin-amazon',
    language: V1Language.Go,
  },
  {
    id: 'app-java-demo',
    label: <FM defaultMessage="Java with Maven" />,
    language: V1Language.Java,
  },
  {
    id: 'java-gradle8',
    label: <FM defaultMessage="Java with Gradle" />,
    language: V1Language.Java,
  },
  {
    id: 'ShoppingCart',
    label: <FM defaultMessage="Java with Spring" />,
    language: V1Language.Java,
  },
  {
    id: 'homepage',
    language: V1Language.Js,
  },
  {
    id: 'node-express-boilerplate',
    label: <FM defaultMessage="JavaScript with Node.js" />,
    language: V1Language.Js,
  },
  {
    id: 'dotNETewok',
    language: V1Language.Csharp,
  },
  {
    id: 'Twig',
    language: V1Language.Php,
  },
  {
    id: 'python-video-game',
    language: V1Language.Python,
  },
  {
    id: 'railsgoat',
    language: V1Language.Ruby,
  },
  {
    id: 'delta-sharing',
    language: V1Language.Scala,
  },
].map((props) => {
  const label = props.label ?? <LanguageLabel value={props.language} />;

  // Provide a default link for the project url this will be replaced by the
  // full ref url when a demo scan is created.
  const link = `https://github.com/endorlabs-demos/${props.id}`;

  return { ...props, label, link };
});

const DEFAULT_DEMO_REPOSITORY = 'app-java-demo';

export const OnboardingScanProjectGitHubApp = ({
  stepRecord,
}: OnboardingStepContentProps) => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { activeNamespace } = useAuthInfo();

  const {
    completeStep,
    getIsStepComplete,
    uncompleteStep,
    getScanningRepositoryId,
    setScanningRepositoryId,
  } = useOnboardingSteps();
  const isStepCompleted = getIsStepComplete(stepRecord.id);

  const [selectedDemoRepositoryId, setSelectedDemoRepositoryId] = useState<
    string | undefined
  >(getScanningRepositoryId() ?? DEFAULT_DEMO_REPOSITORY);

  const qCreateDemo = useCreateDemo({
    onError: () => {
      // error message handled inline
    },
    onSuccess: () => {
      // succes message handled inline
    },
  });
  const qListDemos = useListDemos(activeNamespace);

  const selectedDemo = useMemo(() => {
    return qListDemos.data?.objects.find(
      (d) => d.spec.requested_project === selectedDemoRepositoryId
    );
  }, [qListDemos.data?.objects, selectedDemoRepositoryId]);

  // If a demo is selected, fetch additional info
  const qGetDemo = useGetDemo(
    { namespace: activeNamespace, uuid: selectedDemo?.uuid as string },
    {
      enabled: !!selectedDemo?.uuid,
      // Long poll until demo is marked "completed"
      refetchInterval: (data) => {
        if (data && isCompletedDemo(data)) return false;
        return DEMO_LONG_POLL_INTERVAL;
      },
    }
  );

  const demoInfo = useMemo(() => {
    const demo = qGetDemo.data;
    if (!demo) return;

    const isCompleted = isCompletedDemo(demo);
    const codeUrl = demo.spec.code_url;
    let workflowUrl = demo.spec.workflow_url;
    let steps: GitHubWorkflowJobStep[] = [];

    if (isGitHubWorkflowJob(demo.spec.job_status)) {
      const jobId = demo.spec.job_status.id;

      // If there is a job id, and the workflow url is the run, change the url
      // to link to the specific job under the run.
      if (/\/runs\/\d+$/.test(workflowUrl) && jobId) {
        workflowUrl = [demo.spec.workflow_url, 'job', jobId].join('/');
      }

      steps = demo.spec.job_status.steps ?? [];
    }

    return {
      isCompleted,
      codeUrl,
      workflowUrl,
      steps,
    };
  }, [qGetDemo.data]);

  const demoErrorMessage = useMemo(() => {
    if (!qCreateDemo.isError) return;
    return getErrorMessage('CREATE', 'Demo', qCreateDemo.error);
  }, [getErrorMessage, qCreateDemo.error, qCreateDemo.isError]);

  const handleSelectDemoRepository = useCallback(
    (e: SelectChangeEvent) => {
      qCreateDemo.reset();
      setSelectedDemoRepositoryId(e.target.value);
    },
    [qCreateDemo]
  );

  const handleStartScanClick = useCallback(() => {
    if (!selectedDemoRepositoryId) return;

    qCreateDemo.mutate({
      namespace: activeNamespace,
      resource: {
        meta: { name: `Demo: ${selectedDemoRepositoryId}` },
        spec: { requested_project: selectedDemoRepositoryId },
        tenant_meta: { namespace: activeNamespace },
      },
    });
    //Required to maintain state during scanning
    setScanningRepositoryId(selectedDemoRepositoryId);
  }, [
    activeNamespace,
    qCreateDemo,
    selectedDemoRepositoryId,
    setScanningRepositoryId,
  ]);

  const demoRepoUrl = useMemo(() => {
    if (demoInfo?.codeUrl) return demoInfo?.codeUrl;
    if (selectedDemoRepositoryId) {
      return SUPPORTED_DEMO_REPOSITORIES.find(
        (r) => r.id === selectedDemoRepositoryId
      )?.link;
    }
  }, [demoInfo?.codeUrl, selectedDemoRepositoryId]);

  return (
    <Stack direction="column" spacing={4} sx={{ alignItems: 'flex-start' }}>
      <Typography color="text.secondary" variant="body1">
        <FM defaultMessage="Select a demo repository to scan" />
      </Typography>

      <Stack
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{ alignItems: 'center', minHeight: 36 }}
      >
        <Select
          sx={{
            marginLeft: -1,
            '& .MuiSelect-select:focus': { backgroundColor: 'transparent' },
          }}
          variant="standard"
          displayEmpty
          IconComponent={IconChevronDown}
          renderValue={(value: string) => {
            const item = SUPPORTED_DEMO_REPOSITORIES.find(
              (r) => r.id === value
            );
            let label = <FM defaultMessage="Select a Demo" />;

            if (item) {
              label = item.label;
            }

            return (
              <Typography
                component="span"
                variant="inherit"
                color="primary"
                fontWeight="bold"
              >
                {label}
              </Typography>
            );
          }}
          onChange={handleSelectDemoRepository}
          value={selectedDemoRepositoryId}
        >
          {SUPPORTED_DEMO_REPOSITORIES.map((dr) => (
            <MenuItem key={dr.id} value={dr.id}>
              {dr.label}
            </MenuItem>
          ))}
        </Select>

        {demoRepoUrl && (
          // Show link to the selected demo repo
          <Typography
            color="text.secondary"
            variant="code"
            sx={{
              backgroundColor: (t) => t.palette.grey[100],
              border: (t) => `1px solid ${t.palette.grey[200]}`,
              padding: 2,
            }}
          >
            {demoRepoUrl}
          </Typography>
        )}
      </Stack>

      {/* Add placeholder step when demo first starts scanning */}
      {(qGetDemo.isLoading || demoInfo?.steps.length === 0) && (
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <CircularProgress size="1.2rem" />
          <Typography component="span">
            <FM defaultMessage="Loading Results" />
          </Typography>
        </Stack>
      )}

      {/* Show steps from demo job status */}
      {demoInfo && (
        <FlexList direction="column">
          {demoInfo.steps.map((s, index) => (
            <FlexListItem key={index}>
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                {
                  // Icon for status and outcome
                  s.status !== 'completed' ? (
                    <CircularProgress size="1.2rem" />
                  ) : s.conclusion === 'failure' ? (
                    <IconAlertCircle color="error" />
                  ) : s.conclusion === 'skipped' ? (
                    <IconMinusCircle color="disabled" />
                  ) : (
                    <IconCheckCircle color="primary" />
                  )
                }

                <Typography component="span">{s.name}</Typography>
              </Stack>
            </FlexListItem>
          ))}
        </FlexList>
      )}

      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {!demoInfo && (
          <LoadingButton
            color="primary"
            // Disable scan button if repo has not been selected, or data for
            // a selected repo is loading.
            disabled={!selectedDemoRepositoryId || qGetDemo.isLoading}
            loading={qCreateDemo.isLoading}
            onClick={handleStartScanClick}
            variant="contained"
          >
            <FM defaultMessage="Start Scan" />
          </LoadingButton>
        )}

        {!!demoInfo && (
          <>
            {isStepCompleted ? (
              <ButtonSecondary onClick={() => uncompleteStep(stepRecord.id)}>
                <FM defaultMessage="Mark Incomplete" />
              </ButtonSecondary>
            ) : (
              <ButtonPrimary
                disabled={!demoInfo.isCompleted}
                onClick={() => completeStep(stepRecord.id)}
              >
                <FM defaultMessage="Mark Complete" />
              </ButtonPrimary>
            )}

            <Button
              href={demoInfo.workflowUrl}
              startIcon={<IconExternalLink />}
              target="_blank"
              variant="outlined"
            >
              {demoInfo.isCompleted ? (
                <FM defaultMessage="View Results in GitHub" />
              ) : (
                <FM defaultMessage="View Progress in GitHub" />
              )}
            </Button>
          </>
        )}
      </Stack>

      {demoErrorMessage && (
        <Alert severity="error" icon={<IconAlertTriangle />}>
          <AlertTitle>{demoErrorMessage.message}</AlertTitle>
          {demoErrorMessage.details}
        </Alert>
      )}
    </Stack>
  );
};
