import { V1ToolChains } from '@endorlabs/api_client';
import { ToolChainProfileSupportedToolKey } from '@endorlabs/endor-core/SupportedToolChainProfile';

import { ToolInfoType } from '../types';

type ToolchainDefinition<KToolchain extends keyof V1ToolChains> = {
  toolchain: KToolchain;
};

type ToolchainToolDefinition<
  KToolchain extends keyof V1ToolChains = keyof V1ToolChains
> = {
  toolchain: KToolchain;
  tool: keyof NonNullable<V1ToolChains[KToolchain]>;
  /**
   * The type of the tool, specifiying the shape of the data in the toolchain
   *
   * @default {ToolInfoType.ToolChainVersion}
   */
  type?: ToolInfoType;
  /**
   * For a tool with type {@see ToolInfoType.ToolChainVersion}, this is the
   * corresponding key in the {@see SupportedToolChainProfile} with the list of
   * supported versions for the tool.
   */
  supportedToolKey?: ToolChainProfileSupportedToolKey;
};

const defineToolchain = <KToolchain extends keyof V1ToolChains>({
  toolchain,
  tools,
}: {
  toolchain: KToolchain;
  tools: Omit<ToolchainToolDefinition<KToolchain>, 'toolchain'>[];
}): {
  toolchain: ToolchainDefinition<KToolchain>;
  tools: ToolchainToolDefinition<KToolchain>[];
} => {
  const toolchainDefinition = {
    toolchain,
  };

  const toolDefinitions = tools.map((tool) => ({
    // Set defaults
    type: ToolInfoType.ToolChainVersion,
    toolchain,
    // merge props from tool
    ...tool,
  }));

  return {
    toolchain: toolchainDefinition,
    tools: toolDefinitions,
  };
};

const ANDROID_TOOL_CHAIN = defineToolchain({
  toolchain: 'android_tool_chain',
  tools: [
    {
      tool: 'command_line_tools_version',
      supportedToolKey: 'android-cmd-line-tools',
    },
    {
      tool: 'packages',
      type: ToolInfoType.ToolList,
    },
  ],
});

const DOTNET_TOOL_CHAIN = defineToolchain({
  toolchain: 'dotnet_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'dotnet',
    },
  ],
});

const GOLANG_TOOL_CHAIN = defineToolchain({
  toolchain: 'golang_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'golang',
    },
  ],
});

const JAVA_TOOL_CHAIN = defineToolchain({
  toolchain: 'java_tool_chain',
  tools: [
    {
      supportedToolKey: 'java',
      tool: 'version',
    },
    {
      tool: 'maven_version',
      supportedToolKey: 'maven',
    },
    {
      tool: 'gradle_version',
      supportedToolKey: 'gradle',
    },
  ],
});

const JAVASCRIPT_TOOL_CHAIN = defineToolchain({
  toolchain: 'javascript_tool_chain',
  tools: [
    {
      tool: 'nodejs_version',
      /**
       * @note the mis-match between the tool keys is intentional to match the
       * current state of the {@see SupportedToolChainProfile}
       */
      supportedToolKey: 'javascript',
    },
    {
      tool: 'pnpm_version',
      supportedToolKey: 'pnpm',
    },
    {
      tool: 'yarn_version',
      supportedToolKey: 'yarn',
    },
    {
      tool: 'npm_packages',
      type: ToolInfoType.ToolChainVersionMap,
    },
  ],
});

const PHP_TOOL_CHAIN = defineToolchain({
  toolchain: 'php_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'php',
    },
    {
      tool: 'composer_version',
      supportedToolKey: 'composer',
    },
  ],
});

/**
 * NOTE: `poetry_version` in the python tool chain is deprecated
 */
const PYTHON_TOOL_CHAIN = defineToolchain({
  toolchain: 'python_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'python',
    },
    {
      tool: 'pip_packages',
      type: ToolInfoType.ToolChainVersionMap,
    },
  ],
});

/**
 * NOTE: `dependabot_version` in the ruby tool chain is deprecated
 */
const RUBY_TOOL_CHAIN = defineToolchain({
  toolchain: 'ruby_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'ruby',
    },
  ],
});

const RUST_TOOL_CHAIN = defineToolchain({
  toolchain: 'rust_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'rust',
    },
    {
      tool: 'mirai_version',
      supportedToolKey: 'mirai',
    },
    {
      tool: 'rustup_version',
      supportedToolKey: 'rustup',
    },
  ],
});

/**
 * NOTE: `java_version` in the scala tool chain is deprecated
 */
const SCALA_TOOL_CHAIN = defineToolchain({
  toolchain: 'scala_tool_chain',
  tools: [
    {
      tool: 'version',
      supportedToolKey: 'scala',
    },
  ],
});

const ALL_TOOL_CHAINS = [
  ANDROID_TOOL_CHAIN,
  DOTNET_TOOL_CHAIN,
  GOLANG_TOOL_CHAIN,
  JAVA_TOOL_CHAIN,
  JAVASCRIPT_TOOL_CHAIN,
  PHP_TOOL_CHAIN,
  PYTHON_TOOL_CHAIN,
  RUBY_TOOL_CHAIN,
  RUST_TOOL_CHAIN,
  SCALA_TOOL_CHAIN,
];

export const TOOL_DEFINITIONS = ALL_TOOL_CHAINS.flatMap(
  (tc) => tc.tools as ToolchainToolDefinition[]
);

export const TOOLCHAIN_DEFINITIONS = ALL_TOOL_CHAINS.map((tc) => tc.toolchain);
