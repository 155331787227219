import { Button, Divider, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonStack } from '../ButtonStack';

export const ExpandCollapseControl = ({
  onClick,
}: {
  // This will be called with true when "Expand All" is clicked,
  // and false when "Collapse All" is clicked
  onClick: (expanded: boolean) => void;
}) => {
  return (
    <ButtonStack divider={<Divider flexItem orientation="vertical" />}>
      <Button onClick={() => onClick(true)}>
        <Typography variant="body2">
          <FM defaultMessage="Expand All" />
        </Typography>
      </Button>
      <Button onClick={() => onClick(false)}>
        <Typography variant="body2">
          <FM defaultMessage="Collapse All" />
        </Typography>
      </Button>
    </ButtonStack>
  );
};
