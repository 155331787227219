import { useMatch } from '@tanstack/react-location';

import { ProjectResource } from '@endorlabs/endor-core/Project';

import { ProjectSettingsView } from '../../../../../../../domains/Projects';
import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionDetailSettingsRoute = () => {
  const { data } = useMatch();
  const project = data.project as ProjectResource;

  return (
    <ProjectSettingsView
      namespace={project.tenant_meta.namespace}
      project={project}
      isLoading={false}
    />
  );
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/:version_ref/settings'
)({
  component: TenantProjectVersionDetailSettingsRoute,
});
