import { defineMessage } from 'react-intl';

import { ProjectsIndexPage } from '../../../../views';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Projects' });

const TenantProjectsIndexRoute = () => {
  return <ProjectsIndexPage />;
};

export const Route = createFileRoute('/t/:namespace/projects/')({
  component: TenantProjectsIndexRoute,
  beforeLoad: () => {
    return { title: ROUTE_TITLE };
  },
});
