import { Navigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { useAuthentication, useSession } from '@endorlabs/queries';

import { AuthenticatedRedirect } from '../components/AuthenticatedRedirect';
import { NamedRoutes } from '../constants';
import { getTenantIndexPath } from '../utils';

const DefaultTenantRoute = () => {
  const { isAuthenticated, isLoading } = useAuthentication();
  const { getLastUsedTenant } = useSession();
  const tenantName = getLastUsedTenant(true);

  // HACK: handle edge case where user is authenticated without a tenant.
  if (!isLoading && isAuthenticated && !tenantName) {
    return <Navigate to={NamedRoutes.TENANTS_INDEX} />;
  }

  const baseUrl = tenantName ? getTenantIndexPath({ tenantName }) : '';

  return (
    <AuthenticatedRedirect
      stripPrefix={NamedRoutes.REDIRECT_DEFAULT_TENANT}
      baseUrl={baseUrl}
      title={<FM defaultMessage="Default Tenant" />}
    />
  );
};

export const Route = {
  path: NamedRoutes.REDIRECT_DEFAULT_TENANT,
  element: <DefaultTenantRoute />,
};
