/* eslint sort-keys: "error" */
import { minutesToMilliseconds } from 'date-fns';
import { groupBy as _groupBy, pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  ContextContextType,
  DependencyMetadataDependencyData,
  DependencyMetadataImporterData,
  Endorv1Metric as V1Metric,
  QueryServiceApi,
  V1DependencyMetadata,
  V1ImportedSBOM,
  V1ImportedSBOMSpec,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Project,
  V1ProjectSpec,
  V1Query,
} from '@endorlabs/api_client';
import {
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom, WithRequired } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration, listAllResource } from './utils';

export type QueryDependenciesResponse =
  TResourceList<QueryDependenciesResponseObject>;

export type QueryDependenciesResponseObject = SelectFrom<
  V1DependencyMetadata,
  'context' | 'tenant_meta' | 'uuid',
  {
    meta: SelectFrom<
      V1Meta,
      'name' | 'tags',
      {
        references: {
          DependencyMetrics?: TResourceList<QueryDependenciesDependencyMetricsResponseObject>;
          ImportingProject?: TResourceList<QueryDependenciesImportingProjectResponseObject>;
          ImportingSBOM?: TResourceList<QueryDependenciesImportingSBOMResponseObject>;
        };
      }
    >;
    spec: {
      dependency_data: SelectFrom<
        DependencyMetadataDependencyData,
        'direct' | 'namespace' | 'package_version_uuid' | 'reachable'
      >;
      importer_data: SelectFrom<
        DependencyMetadataImporterData,
        | 'project_uuid'
        | 'package_version_name'
        | 'package_version_uuid'
        | 'package_version_ref'
      >;
    };
  }
>;

export type QueryDependenciesDependencyMetricsResponseObject = SelectFrom<
  V1Metric,
  'uuid',
  {
    meta: {
      parent_uuid: string;
      name: 'package_version_scorecard' | 'pkg_version_info_for_license';
    };
    spec: SelectFrom<
      V1MetricSpec,
      'analytic',
      {
        metric_values: {
          licenseInfoType?: WithRequired<
            V1MetricValue,
            'description' | 'license_info'
          >;
          scorecard?: WithRequired<V1MetricValue, 'description' | 'score_card'>;
          scorefactor?: WithRequired<
            V1MetricValue,
            'description' | 'score_factor_list'
          >;
        };
        // properties required, but not included in query
        project_uuid: never;
      }
    >;
  }
>;

type QueryDependenciesImportingProjectResponseObject = SelectFrom<
  V1Project,
  'uuid' | 'tenant_meta',
  {
    meta: SelectFrom<V1Meta, 'name'>;
    spec: SelectFrom<V1ProjectSpec, 'platform_source'>;
  }
>;

type QueryDependenciesImportingSBOMResponseObject = SelectFrom<
  V1ImportedSBOM,
  'uuid' | 'context' | 'tenant_meta',
  {
    spec: SelectFrom<V1ImportedSBOMSpec, 'main_component_purl'>;
  }
>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'dependencies', namespace, listParams] as const,
  queryAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'dependencies-all', namespace, listParams] as const,
};
export const QueryDependenciesQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const queryDependencies = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const dependenciesQuery = buildDependenenciesQuery(namespace, listParams);
  const dependenciesResp = await apiService().queryServiceCreateQuery(
    namespace,
    dependenciesQuery,
    {
      // pass abort signal to Axios, to support request cancellation on param changes
      signal,
    }
  );

  const dependenciesData = dependenciesResp.data.spec
    ?.query_response as QueryDependenciesResponse;

  if (!dependenciesData.list?.objects) return dependenciesData;

  // For all dependencies returned, fetch the related metrics from the respective namespace
  const dependenciesWithNamespace = dependenciesData.list.objects.filter(
    (d) => d.spec.dependency_data.namespace
  );
  const dependenciesByNamespace = _groupBy(
    dependenciesWithNamespace,
    (d) => d.spec.dependency_data.namespace
  );

  // Build and execute the calls to get the metrics
  const dependencyMetricQueries = Object.entries(dependenciesByNamespace).map(
    async ([namespace, dependencies]) => {
      const dependenciesByUuid = _groupBy(
        dependencies,
        (d) => d.spec.dependency_data.package_version_uuid
      );

      const uuids = Object.keys(dependenciesByUuid);

      const filter = filterExpressionBuilders.and([
        `meta.parent_uuid in ["${uuids.join('","')}"]`,
        `meta.name in ["pkg_version_info_for_license","package_version_scorecard"]`,
      ]);

      // For the given namespace and dependency package versions, fetch all metrics.
      const allMetrics = await listAllResource<
        QueryDependenciesDependencyMetricsResponseObject,
        TResourceList<QueryDependenciesDependencyMetricsResponseObject>
      >((pageToken) => {
        const query = buildRelatedMetricsQuery(namespace, {
          filter,
          page_size: 500,
          page_token: pageToken,
        });

        return apiService()
          .queryServiceCreateQuery(namespace, query, { signal })
          .then((resp) => {
            const queryResponse = resp?.data.spec
              ?.query_response as TResourceList<QueryDependenciesDependencyMetricsResponseObject>;
            return queryResponse;
          });
      });

      for (const metric of allMetrics) {
        const parents = dependenciesByUuid[metric.meta.parent_uuid] ?? [];
        for (const parent of parents) {
          const existing =
            parent.meta.references.DependencyMetrics?.list?.objects ?? [];
          parent.meta.references.DependencyMetrics = {
            list: { objects: existing.concat(metric), response: null },
          };
        }
      }
    }
  );

  await Promise.all(dependencyMetricQueries);

  return dependenciesData;
};

const buildDependenenciesQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  return {
    meta: {
      name: `QueryDependencies(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'DependencyMetadata',
        list_parameters: {
          ...rootListParams,
          mask: [
            'context',
            'meta',
            'spec.dependency_data.direct',
            'spec.dependency_data.namespace',
            'spec.dependency_data.package_version_uuid',
            'spec.dependency_data.reachable',
            'spec.importer_data.project_uuid',
            'spec.importer_data.package_version_name',
            'spec.importer_data.package_version_uuid',
            'spec.importer_data.package_version_ref',
            'tenant_meta',
            'uuid',
          ].join(','),
        },
        references: [
          {
            connect_from: 'spec.importer_data.project_uuid',
            connect_to: 'uuid',
            query_spec: {
              kind: 'Project',
              list_parameters: {
                ...commonListParameters,
                mask: [
                  'meta.name',
                  'spec.platform_source',
                  'tenant_meta.namespace',
                  'uuid',
                ].join(','),
                page_size: 1,
              },
              return_as: 'ImportingProject',
            },
          },
          {
            connect_from: 'context.id',
            connect_to: 'spec.identifier',
            query_spec: {
              kind: 'ImportedSBOM',
              list_parameters: {
                ...commonListParameters,
                filter: `context.type == ${ContextContextType.Sbom}`,
                mask: [
                  'context',
                  'meta.name',
                  'spec.main_component_purl',
                  'tenant_meta',
                  'uuid',
                ].join(','),
              },
              return_as: 'ImportingSBOM',
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

const buildRelatedMetricsQuery = (
  namespace: string,
  listParameters: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryDependencyMetrics(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Metric',
        list_parameters: {
          ...listParameters,
          mask: [
            'uuid',
            'meta.name',
            'meta.parent_uuid',
            'spec.analytic',
            'spec.metric_values',
          ].join(','),
        },
      },
    },
    tenant_meta: { namespace },
  };
};

/**
 * Custom query for Dependencies
 */
export const useQueryDependencies = (
  namespace: string,
  listParams: Omit<ListRequestParameters, 'mask'>,
  queryOpts: ResourceQueryOptions<QueryDependenciesResponse> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'LIST',
    listParams,
    queryOpts
  );

  return useQuery(
    QK.query(namespace, requestParameters),
    ({ signal }) => queryDependencies(namespace, requestParameters, signal),
    { staleTime: QUERY_STALE_TIME, ...queryOpts }
  );
};

/**
 * Exhaustive Query for Dependencies matching the given filter
 */
export const useQueryAllDependencies = (
  namespace: string,
  listParams: Omit<ListAllRequestParameters, 'mask'>,
  queryOptions: Pick<
    ResourceQueryOptions<QueryDependenciesResponse>,
    'enabled'
  > = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'LIST_ALL',
    listParams,
    queryOptions
  );

  return useQuery(
    QK.queryAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<
        QueryDependenciesResponseObject,
        QueryDependenciesResponse
      >(
        (pageToken) => {
          const pageListParams: V1ListParameters = {
            // take base params, and add the page param
            ...requestParameters,
            page_token: pageToken,
          };

          return queryDependencies(namespace, pageListParams, ctx.signal);
        },
        { signal: ctx.signal }
      ),
    { ...queryOptions }
  );
};
