import { QueryFindingsResponseObject } from '@endorlabs/queries';

import { useInfoDrawer } from '../InfoDrawer';
import {
  FindingCallPathsDrawer,
  FindingCallPathsDrawerProps,
} from './FindingCallPathsDrawer';

export const FINDING_CALL_PATHS_DRAWER_TYPE = 'FindingCallPaths';

export interface FindingCallPathsDrawerParams {
  findingNamespace: string;
  findingUuid: string;
}

export const useFindingCallPathsDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    Pick<FindingCallPathsDrawerParams, 'findingUuid'>,
    FindingCallPathsDrawerProps
  >({
    drawerParams: ['findingUuid', 'type'],
    Component: FindingCallPathsDrawer,
  });

  const permalinkEffect = (findings: QueryFindingsResponseObject[]) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeFinding = activeDrawerParams
      ? findings.find((p) => p.uuid === activeDrawerParams.findingUuid)
      : undefined;

    if (
      activeFinding &&
      activeDrawerParams?.type === FINDING_CALL_PATHS_DRAWER_TYPE &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          type: FINDING_CALL_PATHS_DRAWER_TYPE,
          findingUuid: activeFinding.uuid,
        },
        {
          uuid: activeFinding.uuid,
          namespace: activeFinding.tenant_meta.namespace,
        }
      );
    }
  };

  const activate = (finding: QueryFindingsResponseObject) => {
    DetailDrawer.activate(
      { type: FINDING_CALL_PATHS_DRAWER_TYPE, findingUuid: finding.uuid },
      {
        uuid: finding.uuid,
        namespace: finding.tenant_meta.namespace,
      }
    );
  };

  return { activate, permalinkEffect };
};
