import { Grid, IconButton, Paper } from '@mui/material';
import { useIntl } from 'react-intl';

import { FilterableResource, ResourceFilter } from '@endorlabs/filters';
import {
  IconX,
  SearchBarInput,
  useResourceKindDisplayLabel,
} from '@endorlabs/ui-common';

import { ControlsFacetFilter } from './ControlsFacetFilter';
import { FacetFilterDefinition } from './types';

export interface FacetedSearchBarProps {
  facets?: FacetFilterDefinition[];
  filters?: ResourceFilter[];
  onFiltersChange?: (filters: ResourceFilter[]) => void;
  onSearchChange: (searchValue: string) => void;
  primaryResourceKind: FilterableResource;
  primaryResourceKindLabel?: string;
  searchValue?: string;
}

/**
 * Search bar, with explicitly provided controls for filters, intended to
 * provide faceted search functionality for resources.
 *
 * @note When only a simple Search Bar is needed, use instead {@see SearchBar}
 * @deprecated this will be replaced by {@see FilterBar}
 */
export const FacetedSearchBar = ({
  facets,
  filters,
  onFiltersChange,
  onSearchChange,
  primaryResourceKind,
  primaryResourceKindLabel,
  searchValue,
}: FacetedSearchBarProps) => {
  const { formatMessage: fm } = useIntl();

  const defaultPrimaryResourceKindLabel = useResourceKindDisplayLabel(
    primaryResourceKind,
    true
  );

  // get placeholder for the autocomplete input
  const searchPlaceholder = fm(
    { defaultMessage: 'Search for {kind}' },
    {
      kind: primaryResourceKindLabel ?? defaultPrimaryResourceKindLabel,
    }
  );

  const handleClear = () => {
    onSearchChange('');
    if (onFiltersChange) {
      onFiltersChange([]);
    }
  };

  const showFiltering = !!onFiltersChange && facets && facets?.length > 0;

  return (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Grid container alignItems="center" spacing={4}>
        <Grid item flexGrow={1} order={0}>
          <SearchBarInput
            placeholder={searchPlaceholder}
            onSearch={onSearchChange}
            searchValue={searchValue}
            disableUnderline
            size="medium"
          />
        </Grid>

        {/* Wrap Filter controls below other elements on smaller screens */}
        {showFiltering && (
          <Grid item xs={12} lg="auto" order={{ xs: 2, lg: 1 }}>
            <ControlsFacetFilter
              facets={facets}
              filters={filters}
              onChange={onFiltersChange}
            />
          </Grid>
        )}

        {filters?.length || searchValue ? (
          <Grid item order={{ xs: 1, lg: 2 }}>
            <IconButton
              onClick={handleClear}
              size="small"
              title={fm({ defaultMessage: 'Clear Filters' })}
            >
              <IconX fontSize="inherit" />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};
