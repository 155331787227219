import { Box, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

export const TreeColumnPanel = ({
  title,
  children,
}: PropsWithChildren<{
  title: ReactNode;
}>) => {
  return (
    <Stack className="TreeColumnPanel-root" direction="column">
      <Box className="TreeColumnPanel-heading">
        <Typography variant="h4">{title}</Typography>
      </Box>

      <Box
        className="TreeColumnPanel-content"
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {children}
      </Box>
    </Stack>
  );
};
