import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { ControlledCheckbox, IconChevronDown } from '@endorlabs/ui-common';

import { FormUpsertPolicyFieldValues } from '../types';

export const PolicyAdvancedFields = () => {
  const { palette, typography } = useTheme();
  const { control } = useFormContext<FormUpsertPolicyFieldValues>();

  return (
    <Accordion
      disableGutters // prevent margin on expanded state
      elevation={0}
      sx={{
        marginY: 4,
        '& .MuiAccordionSummary-root': {
          justifyContent: 'flex-start',
          gap: 2,
          '& .MuiAccordionSummary-content': {
            flexGrow: 0,
          },
        },
      }}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>
        <Typography
          sx={{
            ...typography.button,
            color: palette.text.primary,
          }}
        >
          <FM defaultMessage="Advanced" />
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <FormControlLabel
          label={
            <FM defaultMessage="Propagate this policy to all child namespaces." />
          }
          control={
            <ControlledCheckbox
              control={control}
              // @ts-expect-error field value type does not expect boolean?
              defaultValue={false}
              id="propagate"
              name="propagate"
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};
