import { FormattedMessage as FM } from 'react-intl';

import { V1Method as PermissionRuleMethod } from '@endorlabs/api_client';

import { PermissionRuleMethodMessages } from '../../locales';

interface PermissionRuleMethodLabelProps {
  method: PermissionRuleMethod;
}

export const PermissionRuleMethodLabel = ({
  method,
}: PermissionRuleMethodLabelProps) => (
  <FM {...PermissionRuleMethodMessages[method]} />
);
