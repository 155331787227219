import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
} from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { isEmpty as _isEmpty, set as _set } from 'lodash-es';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { GroupByTimeGroupByTimeInterval } from '@endorlabs/api_client';
import { FilterExpression } from '@endorlabs/filters';
import { useGroupFindingLogs } from '@endorlabs/queries';
import { Colors, EmptyState, LineChart } from '@endorlabs/ui-common';

export const DashboardFindingTrend = ({
  namespace,
  filter,
  title,
}: {
  namespace: string;
  filter: FilterExpression;
  title: ReactNode;
}) => {
  const { formatDate } = useIntl();

  const qGroups = useGroupFindingLogs(namespace, {
    filter,
    group_by_time: {
      aggregation_paths: 'meta.create_time',
      interval: GroupByTimeGroupByTimeInterval.Week,
      mode: 'count',
    },
  });

  const isLoading = qGroups.isLoading;

  const isEmpty = !qGroups.data || _isEmpty(qGroups.data.groups);

  const chartData = useMemo(() => {
    const charDataByDate: Record<string, { count: number }> = {};

    const groups = qGroups.data?.groups ?? {};
    Object.entries(groups).forEach(([key, group]) => {
      const count = group.aggregation_count?.count ?? 0;
      _set(charDataByDate, [key, 'count'], count);
    });

    // TODO: limit to max item count?
    return Object.entries(charDataByDate)
      .map(([key, data]) => ({
        key,
        // HACK: date is JSON-encoded ISO Date
        label: formatDate(JSON.parse(key), {
          dateStyle: 'medium',
        }),
        ...data,
      }))
      .sort((a, b) => a.key.localeCompare(b.key));
  }, [formatDate, qGroups.data]);

  return (
    <Card elevation={0}>
      <CardHeader title={title} />
      <CardContent>
        {isLoading && (
          <Stack alignItems="center" height="100%" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
        {isEmpty && !isLoading && (
          <EmptyState
            size="medium"
            title={<FM defaultMessage="No records match the filter criteria" />}
          />
        )}
        {!isLoading && !isEmpty && (
          <ParentSize debounceTime={100}>
            {({ width: visWidth, height: visHeight }) => (
              <LineChart
                width={visWidth}
                height={300}
                data={chartData}
                xKey="label"
                yKeys={['count']}
                axisBottom={true}
                axisColor={Colors.BLACK}
                axisLabel="Counts"
                axisNumTicks={4}
                axisRight={false}
                lineColors={[Colors.GREEN]}
                lineThickness={3}
                margin={{
                  bottom: 24,
                  left: 60,
                  top: 16,
                  right: 32,
                }}
                showGrid={true}
              />
            )}
          </ParentSize>
        )}
      </CardContent>
    </Card>
  );
};
