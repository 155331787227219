import { Stack, Typography, useTheme } from '@mui/material';
import {
  defineMessage,
  defineMessages,
  FormattedMessage as FM,
} from 'react-intl';

import { FindingResource, useFeatureFlags } from '@endorlabs/queries';
import {
  DescriptionList,
  DescriptionListItem,
  FormattedTypography,
  Link,
  NilDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { FindingExceptionStateDisplay } from '../../domains/Findings';
import { ExceptionPolicyDetails } from '../../domains/Policies/components/ExceptionPolicyDetails';
import { getPoliciesPath } from '../../routes';
import { FindingDetailProps } from './types';

const messages = defineMessages({
  findingOverviewTitle: { defaultMessage: 'Overview' },
  findingSummaryTitle: { defaultMessage: 'Summary' },
  findingRemediationTitle: { defaultMessage: 'Remediation' },
  findingExplanationTitle: { defaultMessage: 'Explanation' },
  // source policy
  findingSourcePolicyTitle: { defaultMessage: 'Source Policy' },
  findingSourcePolicyDescription: { defaultMessage: 'Policy Description' },
  findingSourcePolicyLink: { defaultMessage: 'View Policies' },
});

/**
 * Dynamically display information about Finding, based on the properties and
 * metadata present in the Finding.
 * @deprecated
 * @see FindingDetailDrawerV2
 */
export const FindingDetailDynamic = ({
  direction,
  exceptionPolicies = [],
  finding,
}: FindingDetailProps) => {
  const { space } = useTheme();
  const sourcePolicyInfo = (finding as FindingResource).spec.finding_metadata
    ?.source_policy_info;

  const hasExceptions = exceptionPolicies.length > 0;

  return (
    <Stack spacing={3}>
      {/* COMMON FINDING INFO */}
      <DescriptionList title={messages.findingOverviewTitle}>
        <DescriptionListItem
          direction={direction}
          term={messages.findingSummaryTitle}
          value={
            finding.spec.summary ? (
              <FormattedTypography text={finding.spec.summary} />
            ) : (
              <NilDisplay />
            )
          }
        />

        <DescriptionListItem
          direction={direction}
          term={messages.findingExplanationTitle}
          value={
            finding.spec.explanation ? (
              <FormattedTypography text={finding.spec.explanation} />
            ) : (
              <NilDisplay />
            )
          }
        />

        <DescriptionListItem
          direction={direction}
          term={messages.findingRemediationTitle}
          value={
            finding.spec.remediation ? (
              <FormattedTypography text={finding.spec.remediation} />
            ) : (
              <NilDisplay />
            )
          }
        />
      </DescriptionList>

      {/* SOURCE POLICY INFO */}
      {!!sourcePolicyInfo && (
        <DescriptionList title={messages.findingSourcePolicyTitle}>
          <DescriptionListItem
            direction={direction}
            term={messages.findingSourcePolicyDescription}
            value={
              sourcePolicyInfo?.description ? (
                <FormattedTypography text={sourcePolicyInfo?.description} />
              ) : (
                <NilDisplay />
              )
            }
          />

          <Link
            to={getPoliciesPath({
              tenantName: finding.tenant_meta.namespace,
              section: '',
            })}
          >
            <FM {...messages.findingSourcePolicyLink} />
          </Link>
        </DescriptionList>
      )}

      {exceptionPolicies.length > 0 && (
        <Stack spacing={space.sm}>
          <RowStack>
            <Typography variant="h6">
              <FM defaultMessage="Exception Policies" />
            </Typography>
            <FindingExceptionStateDisplay hasExceptions={hasExceptions} />
          </RowStack>

          <ExceptionPolicyDetails exceptionPolicies={exceptionPolicies} />
        </Stack>
      )}
    </Stack>
  );
};
