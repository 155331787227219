import { ChartMargin, LegendPosition } from './types';

export const getChartContainerDirection = (legendPosition: LegendPosition) => {
  switch (legendPosition) {
    case 'top':
      return 'column-reverse';
    case 'bottom':
      return 'column';
    case 'left':
      return 'row-reverse';
    case 'right':
      return 'row';
    case 'center':
      return 'row';
    default:
      return 'row';
  }
};

export const getChartDimensions = (
  width: number,
  height: number,
  margin: ChartMargin,
  showLegends: boolean,
  legendPosition: LegendPosition,
  legendContainerSize: number
) => {
  const chartWidth =
    showLegends && ['left', 'right'].includes(legendPosition)
      ? width -
        margin.left -
        margin.right -
        (width - margin.left - margin.right) * legendContainerSize
      : width - margin.left - margin.right;
  const chartHeight =
    showLegends && ['top', 'bottom'].includes(legendPosition)
      ? height -
        margin.top -
        margin.bottom -
        (height - margin.top - margin.bottom) * legendContainerSize
      : height - margin.top - margin.bottom;

  const legendContainerWidth = showLegends
    ? ['left', 'right'].includes(legendPosition)
      ? width * legendContainerSize
      : chartWidth
    : 0;
  const legendContainerHeight = showLegends
    ? ['top', 'bottom'].includes(legendPosition)
      ? height * legendContainerSize
      : chartHeight
    : 0;

  const svgWidth = ['top', 'bottom', 'center'].includes(legendPosition)
    ? width
    : width - legendContainerWidth;

  const svgHeight = ['left', 'right', 'center'].includes(legendPosition)
    ? height
    : height - legendContainerHeight;

  return [
    svgWidth,
    svgHeight,
    chartWidth,
    chartHeight,
    legendContainerWidth,
    legendContainerHeight,
  ];
};

// Get the points surrounding missing data points
export const getMissingData = <T extends Record<string, string | number>>(
  data: T[],
  dataKeys: Extract<keyof T, string | number>[]
) => {
  const missingData: Partial<
    Record<Extract<keyof T, string | number>, [T, T][]>
  > = {};

  dataKeys.forEach((key) => {
    const result: [T, T][] = [];
    let start = -1;
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] === 0 && start === -1) {
        start = Math.max(i - 1, 0);
        if (i === data.length - 1) {
          result.push([data[start], data[i]]);
        }
      } else if (
        (data[i][key] !== 0 || i === data.length - 1) &&
        start !== -1
      ) {
        result.push([data[start], data[i]]);
        start = -1;
      }
    }
    missingData[key] = result;
  });
  return missingData;
};
