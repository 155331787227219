import { Box, Stack, Theme, Typography, useTheme } from '@mui/material';

import { PatchFileDiffStat } from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  CopyToClipboardButton,
  DiffStat,
  DrawerAccordion,
  DrawerAccordionProps,
  RowStack,
  useStyles,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

interface PatchDisplayAccordionProps
  extends Pick<DrawerAccordionProps, 'expanded' | 'id' | 'onChange'> {
  diffStat: PatchFileDiffStat;
}

export const PatchDisplayAccordion = ({
  diffStat,
  ...drawerAccordionProps
}: PatchDisplayAccordionProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  // Show the first name from the parse diff
  const firstFileName = diffStat.files.at(0);

  const header = (
    <Stack gap={space.xs} width="100%">
      <RowStack gap={space.sm}>
        <RowStack flexGrow={2}>
          <DiffStat
            added={diffStat.linesAdded ?? 0}
            removed={diffStat.linesRemoved ?? 0}
          />
        </RowStack>

        <RowStack flexShrink={0} gap={2} justifySelf="flex-end">
          <CopyToClipboardButton value={diffStat.diff ?? ''} />
        </RowStack>
      </RowStack>

      <Typography
        component="span"
        variant="body2"
        sx={{ wordBreak: 'break-word' }}
      >
        {firstFileName}
      </Typography>
    </Stack>
  );

  return (
    <Box sx={sx}>
      <DrawerAccordion
        {...drawerAccordionProps}
        align="start"
        titleNode={header}
      >
        <CodeEditor
          editorOptions={{
            lineNumbers: false,
            lineWrapping: false,
          }}
          enableClipboard={false}
          height="auto"
          language="text/x-diff"
          value={diffStat.diff}
          width="100%"
        />
      </DrawerAccordion>
    </Box>
  );
};

const styles = ({ palette, space }: Theme) => ({
  '& .MuiAccordionSummary-root': {
    borderBottom: `1px solid ${palette.divider}`,
    padding: `${space.sm}`,
  },

  '& .MuiAccordionDetails-root': {
    padding: 0,
  },

  '& .CodeMirror': { border: 0 },
});
