import { Stack, StackProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';

interface RowStackProps extends Omit<StackProps, 'direction' | 'ref'> {}

export const RowStack = forwardRef<HTMLDivElement, RowStackProps>(
  function RowStack({ ...props }, ref) {
    const { space } = useTheme();

    return (
      <Stack
        alignItems="center"
        flexWrap="wrap"
        gap={space.xs}
        {...props}
        direction="row"
        ref={ref}
      />
    );
  }
);
