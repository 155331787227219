import {
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { uniq } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ScanProfileToolChain } from '@endorlabs/endor-core/ScanProfile';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys,
  IconTrash,
  RowStack,
} from '@endorlabs/ui-common';

import { OSLabelMessages } from '../locales';
import { ToolchainToolInfo, ToolInfoType } from '../types';
import { getToolchainOSLabel, getToolchainToolLabel } from '../utils';

export type ToolChainSummaryProps = {
  isLoading?: boolean;
  onDelete?: (tool: ToolchainToolInfo) => void;
  toolChain?: ScanProfileToolChain;
  tools?: ToolchainToolInfo[];
};

const buildToolChainSummaryTableColumnDefs = ({
  onDelete,
}: Pick<
  ToolChainSummaryProps,
  'onDelete'
>): DataTableColumnDef<ToolchainToolInfo>[] => {
  const columns: DataTableColumnDef<ToolchainToolInfo>[] = [
    {
      cell: (t) => {
        const toolInfo = t.row.original;

        return getToolchainToolLabel(toolInfo);
      },
      header: () => <FM defaultMessage="Tool" />,
      id: 'name',
    },
    {
      cell: (t) => {
        const toolInfo = t.row.original;

        // If the tool type includes a version, display it
        if (
          toolInfo.type === ToolInfoType.ToolChainVersion ||
          toolInfo.type === ToolInfoType.ToolChainVersionMap
        ) {
          return toolInfo.value.version.name;
        }
      },
      header: () => <FM defaultMessage="Version" />,
      id: 'version',
    },
  ];

  if (onDelete) {
    columns.push({
      id: 'actions',
      colType: DataTableColumnTypeKeys.ACTIONS,
      cell: (t) => (
        <RowStack justifyContent="flex-end" width="100%">
          <IconButton
            color="error"
            onClick={(event) => {
              event.stopPropagation();
              onDelete(t.row.original);
            }}
          >
            <IconTrash />
          </IconButton>
        </RowStack>
      ),
    });
  }

  return columns;
};

export const ToolChainSummary = ({
  isLoading,
  onDelete,
  tools = [],
}: ToolChainSummaryProps) => {
  const { space } = useTheme();

  const toolSections = useMemo(() => {
    const sections = [];

    const osValues = uniq(tools.map((t) => t.os));
    const archValues = uniq(tools.map((t) => t.arch));

    for (const osKey of osValues) {
      const toolchains = [];

      for (const archKey of archValues) {
        const filteredTools = tools?.filter(
          (t) => t.os === osKey && t.arch === archKey
        );

        if (filteredTools.length) {
          toolchains.push({
            arch: archKey,
            tools: filteredTools,
          });
        }
      }

      if (toolchains.length) {
        sections.push({
          os: osKey,
          label: getToolchainOSLabel({ os: osKey }),
          toolchains,
        });
      }
    }

    return sections;
  }, [tools]);

  const columns = useMemo(
    () => buildToolChainSummaryTableColumnDefs({ onDelete }),
    [onDelete]
  );

  if (isLoading) {
    return (
      <Stack gap={space.md}>
        <Stack gap={space.xs}>
          <Typography variant="body1" fontWeight={600}>
            <Skeleton width={60} sx={{ fontSize: 'inherit' }} variant="text" />
          </Typography>

          <Stack gap={space.xs}>
            <Typography variant="h4">
              <Skeleton
                width={100}
                sx={{ fontSize: 'inherit' }}
                variant="text"
              />
            </Typography>

            <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
              <DataTable columns={columns} isLoading data={[]} />
            </Paper>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={space.md}>
      {toolSections.map((s) => (
        <Stack key={s.os} gap={space.xs}>
          <Typography variant="body1" fontWeight={600}>
            {s.label}
          </Typography>

          {s.toolchains.map((t) => (
            <Stack key={t.arch} gap={space.xs}>
              <Typography variant="h4">{t.arch}</Typography>

              <Paper variant="outlined" sx={{ overflow: 'hidden' }}>
                <DataTable columns={columns} data={t.tools} />
              </Paper>
            </Stack>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
