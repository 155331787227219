import { useMemo } from 'react';

import { MAX_PATHS_LIMIT } from './constants';

export type NodeId = string;
export type AdjacencyList = Record<NodeId, NodeId[]>;
export type PathList = NodeId[][];

function inverseGraph(graph: AdjacencyList): AdjacencyList {
  const inverted: AdjacencyList = {};

  for (const [node, edges] of Object.entries(graph)) {
    if (!Reflect.has(inverted, node)) {
      inverted[node] = [];
    }

    for (const id of edges) {
      if (!Reflect.has(inverted, id)) {
        inverted[id] = [];
      }

      inverted[id].push(node);
    }
  }

  return inverted;
}

function depthFirstSearch(
  graph: AdjacencyList,
  start: NodeId,
  visit: (node: NodeId, path: NodeId[]) => boolean | void,
  path: NodeId[] = []
): void {
  const timeout = Date.now() + 10_000;

  const next = graph[start] ?? [];
  for (const node of next) {
    // HACK: bail after timeout, to prevent running indefinitely
    if (Date.now() > timeout) break;

    const visited = [start, ...path];

    // should this continue to go deeper into the graph?
    const shouldVisitAdjacent = visit(node, visited);
    if (shouldVisitAdjacent === false) continue;

    // prevent circular paths
    if (!path.some((other) => other === node)) {
      depthFirstSearch(graph, node, visit, visited);
    }
  }
}

// wrapper around dfs
function getAllPaths(graph: AdjacencyList, root: string, leaves: string[]) {
  const allPaths: PathList = [];

  depthFirstSearch(graph, root, (node, path) => {
    if (leaves.includes(node)) {
      allPaths.push([node, ...path]);
    }

    // NOTE: Limiting max paths to prevent OOM
    if (allPaths.length >= MAX_PATHS_LIMIT) return false;
  });

  // sort and return the shortest path first
  allPaths.sort((a, b) => a.length - b.length);
  return allPaths;
}

/**
 * Find all paths in the given dependency graph between a given
 * start (root) and end (edge) node.
 */
export const useDependencyGraphSearch = (
  /**
   * Graph to search over
   */
  graph: AdjacencyList,
  /**
   * Starting node, typically the root of the graph
   */
  starts?: NodeId[],
  /**
   * Ending node, typically an edge of the graph
   */
  end?: NodeId,
  /**
   * Should start search from edge node, instead of root
   */
  reverse?: boolean
): PathList => {
  const invertedGraph = useMemo(() => inverseGraph(graph), [graph]);

  const pathList = useMemo(() => {
    if (!graph || !starts || !end) return [];

    const pathList = getAllPaths(reverse ? graph : invertedGraph, end, starts);

    return pathList;
  }, [end, graph, invertedGraph, reverse, starts]);

  return pathList;
};
