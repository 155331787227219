import { Box, Stack, Typography } from '@mui/material';
import { groupBy } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FilterableResource, ResourceFilter } from '@endorlabs/filters';
import { ResourceKindDisplay } from '@endorlabs/ui-common';

import { FilterValueDisplay } from './FilterValueDisplay';

interface ActiveFilterListProps {
  filters?: ResourceFilter[];
  onDelete: (filter: ResourceFilter) => void;
  onEdit: (filter: ResourceFilter) => void;
  primaryResourceKind: FilterableResource;
  primaryResourceKindLabel?: string;
}

export const ActiveFilterList = ({
  filters,
  onDelete,
  onEdit,
  primaryResourceKind,
  primaryResourceKindLabel,
}: ActiveFilterListProps) => {
  // group the filters by kind, preserving the order in which they were added
  const filtersByKind = useMemo(() => {
    const grouped = groupBy(filters, 'kind');

    const filtersByKind = [];
    for (const [resourceKind, resourceFilters] of Object.entries(grouped)) {
      filtersByKind.push({ kind: resourceKind, filters: resourceFilters });
    }

    return filtersByKind;
  }, [filters]);

  // do not display if there are no filters
  if (!filters?.length) {
    return null;
  }

  return (
    <Stack spacing={2}>
      {/* Filter values, by filter kind */}
      {filtersByKind.map(({ kind, filters }, index) => (
        <Box
          key={kind}
          display="flex"
          gap={2}
          alignItems="center"
          flexWrap="wrap"
        >
          <Typography component="span" color="text.secondary">
            {index === 0 && kind === primaryResourceKind ? (
              <FM
                defaultMessage="List {kind} Where"
                values={{
                  kind: primaryResourceKindLabel ?? (
                    <ResourceKindDisplay value={kind} isPlural />
                  ),
                }}
              />
            ) : index === 0 ? (
              <FM
                defaultMessage="List {primaryKind} That Have {kind} Where"
                values={{
                  primaryKind: primaryResourceKindLabel ?? (
                    <ResourceKindDisplay value={primaryResourceKind} isPlural />
                  ),
                  kind: <ResourceKindDisplay value={kind} isPlural />,
                }}
              />
            ) : kind === primaryResourceKind ? (
              <FM defaultMessage="And Where" />
            ) : (
              <FM
                defaultMessage="And Have {kind} Where"
                values={{
                  kind: <ResourceKindDisplay value={kind} isPlural />,
                }}
              />
            )}
          </Typography>

          {filters.map((filter, index) => (
            <FilterValueDisplay
              key={index}
              filter={filter}
              onDelete={() => onDelete(filter)}
              onEdit={() => onEdit(filter)}
            />
          ))}
        </Box>
      ))}
    </Stack>
  );
};
