import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import { partition as _partition, without as _without } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { DEFAULT_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';
import {
  isBinaryProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import {
  applyFilters,
  FILTER_COMPARATORS,
  filterExpressionBuilders,
  ValueFilter,
} from '@endorlabs/filters';
import {
  PackageSource,
  RepoVersionResource,
  useFeatureFlags,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  ButtonStack,
  EmptyState,
  MultiSelectInput,
  UIPackageVersionUtils,
  UIProjectUtils,
  useDialog,
  usePackageVersionDependencyScoreMetrics,
  useProjectDependencies,
  useTanstackTableRef,
} from '@endorlabs/ui-common';
import { EcosystemLabel } from '@endorlabs/ui-common/domains/Package';

import {
  DependenciesExportDialog,
  DependenciesTable,
  DependenciesTableColumnId,
  DependenciesTableHeader,
  DependenciesTableRow,
  useDependencyDetailDrawer,
} from '../../domains/Dependencies';
import {
  FilterBar,
  FilterFieldConfig,
  filterFieldTransformBuilders,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  buildPackageSourceToggleOptions,
  PackageSourceToggles,
} from '../../domains/PackageVersion';

const PACKAGES_INCLUDE_COLUMNS: DependenciesTableColumnId[] = [
  'hasApproximation',
  'isDirectDependency',
  'dependentsCount',
  'reachability',
  'visibility',
  'sourceCode',
  'unresolvedVersionRefs',
];

const GITHUB_ACTIONS_INCLUDE_COLUMNS: DependenciesTableColumnId[] = [
  'hasApproximation',
  'isDirectDependency',
  'dependentsCount',
  'visibility',
  'sourceCode',
  'unresolvedVersionRefs',
];

export interface ProjectVersionDependenciesProps {
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

const ECOSYSTEM_FILTER_FIELD_ID = 'PackageVersion:spec.ecosystem';

const FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: ECOSYSTEM_FILTER_FIELD_ID,
    ...filterFieldTransformBuilders.fromFilter({
      key: 'ecosystem',
      comparator: FILTER_COMPARATORS.IN,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Ecosystem" />}
        onChange={onChange}
        value={value}
        options={_without(
          DEFAULT_ECOSYSTEMS,
          // GitHub Action dependencies are handled separately in the project
          // dependencies view under "CI Workflows"
          V1Ecosystem.GithubAction
        ).map((value) => ({
          value,
          label: <EcosystemLabel value={value} />,
        }))}
      />
    ),
  } satisfies FilterFieldConfig<V1Ecosystem[]>,
];

const BaseProjectVersionDependencies = ({
  namespace,
  project,
  repositoryVersion,
}: ProjectVersionDependenciesProps) => {
  const isGithubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );
  const { DetailDrawer, permalinkEffect } = useDependencyDetailDrawer();

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<DependenciesTableRow>();

  const [packageSource, setPackageSource] = useState(PackageSource.Packages);

  const {
    clearFilter,
    _state: filterState,
    filter: userFilterExpression,
  } = useFilterContext();

  const qProjectDependencies = useProjectDependencies(
    namespace,
    project,
    repositoryVersion
  );

  const qDependencyPackageVersionScoreMetrics =
    usePackageVersionDependencyScoreMetrics(
      namespace,
      qProjectDependencies.dependencies
    );

  const [workflowDependencies, packageDependencies] = useMemo(() => {
    return _partition(qProjectDependencies.dependencies, (dep) => {
      const { ecosystem: importerEcosystem } =
        UIPackageVersionUtils.parsePackageName(dep.parentVersionName);
      /**
       * spec.ecosystem will be npm for dependencies from github actions.
       * Get the ecosystem from importing package version name to determine
       * dependencies from github actions.
       */
      return importerEcosystem === V1Ecosystem.GithubAction;
    });
  }, [qProjectDependencies.dependencies]);

  const isPackagesEmpty =
    !qProjectDependencies.isLoading && packageDependencies.length === 0;

  const isWorkflowEmpty =
    !qProjectDependencies.isLoading && workflowDependencies.length === 0;

  const [packageSourceValue, setPackageSourceValue] = useState<PackageSource>(
    PackageSource.Packages
  );

  const packageSourceToggleOptions = useMemo(() => {
    const excludeList: PackageSource[] = [];

    // Hide GitHub action package source when:
    // - Feature flag is disabled
    // - Project is from container or package scan
    if (!isGithubActionsEnabled || (project && isBinaryProject(project))) {
      excludeList.push(PackageSource.GithubActions);
    }

    return buildPackageSourceToggleOptions(
      {
        packages: packageDependencies.length,
        ciWorkflows: workflowDependencies.length,
      },
      excludeList
    );
  }, [
    isGithubActionsEnabled,
    packageDependencies.length,
    project,
    workflowDependencies.length,
  ]);

  useEffect(() => {
    if (packageSourceValue) {
      setPackageSource(packageSourceValue);
    }
  }, [packageSourceValue]);

  const selectedDependencies = useMemo(() => {
    return packageSource === PackageSource.GithubActions
      ? workflowDependencies
      : packageDependencies;
  }, [packageDependencies, packageSource, workflowDependencies]);

  // merge dependencies with score metrics
  const [
    projectDependencies,
    projectDependenciesTotalCount,
    hasApproximateDependencies,
  ] = useMemo(() => {
    let hasApproximateDependencies = false;
    const projectDependenciesTotalCount = selectedDependencies.length;
    const projectDependencies = selectedDependencies.map((dep) => {
      // find the related score metrics
      const scoreMetrics = dep.uuid
        ? qDependencyPackageVersionScoreMetrics.scoreMetrics[dep.uuid]
        : undefined;

      if (dep.hasApproximation) {
        hasApproximateDependencies = true;
      }

      return { id: dep.uuid, ...dep, ...scoreMetrics };
    });

    const ecosystemFilter = filterState.values?.get(
      ECOSYSTEM_FILTER_FIELD_ID
    ) as ValueFilter | undefined;

    const filteredDependencies =
      packageSource === PackageSource.Packages && ecosystemFilter
        ? applyFilters([ecosystemFilter], projectDependencies)
        : projectDependencies;

    if (!filterState.search) {
      return [
        filteredDependencies,
        projectDependenciesTotalCount,
        hasApproximateDependencies,
      ];
    }

    const dependenciesResult = matchSorter(
      filteredDependencies,
      filterState.search,
      {
        // use the default sort order as tie breaker
        baseSort: (a, b) => (a.index < b.index ? -1 : 1),
        keys: ['name'],
        threshold: matchSorter.rankings.CONTAINS,
      }
    );

    return [
      dependenciesResult,
      projectDependenciesTotalCount,
      hasApproximateDependencies,
    ];
  }, [
    selectedDependencies,
    filterState.values,
    filterState.search,
    packageSource,
    qDependencyPackageVersionScoreMetrics.scoreMetrics,
  ]);

  const handleClickDetail = useCallback(
    (row?: DependenciesTableRow) => {
      // TODO: handle action for fallback row
      if (!row?.name) return;

      DetailDrawer.activate(
        {
          name: row.name,
          namespace: row.namespace,
        },
        {
          importingNamespace: namespace,
          importingProject: project,
          importingProjectVersion: repositoryVersion,
          name: row.name,
          namespace: row.namespace,
          uuid: row.uuid,
        }
      );
    },
    [DetailDrawer, namespace, project, repositoryVersion]
  );

  useLayoutEffect(
    () =>
      permalinkEffect({
        dependencies: projectDependencies,
        importingNamespace: namespace,
        importingProject: project,
        importingProjectVersion: repositoryVersion,
      }),
    [
      permalinkEffect,
      projectDependencies,
      namespace,
      project,
      repositoryVersion,
    ]
  );

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<DependenciesTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const dependenciesExportDialog = useDialog({
    component: DependenciesExportDialog,
  });
  const handleOpenExportDialog = () => {
    if (!project) return;

    // Get filter for project dependencies
    let projectDependenciesFilterExpression =
      UIProjectUtils.getProjectRelatedFilterExpressions(
        project,
        repositoryVersion,
        { key: 'spec.importer_data.project_uuid' }
      );

    if (projectDependenciesFilterExpression && userFilterExpression) {
      projectDependenciesFilterExpression = filterExpressionBuilders.and([
        projectDependenciesFilterExpression,
        userFilterExpression,
      ]);
    }

    if (!projectDependenciesFilterExpression) return;

    dependenciesExportDialog.openDialog({
      namespace,
      filter: projectDependenciesFilterExpression,
      downloadProps: {
        filename: `project_${project.uuid}_dependencies-export.csv`,
      },
    });
  };

  // calc page state
  const isLoading =
    qProjectDependencies.isLoading ||
    qDependencyPackageVersionScoreMetrics.isLoading;
  const isEmptyState = !isLoading && isPackagesEmpty && isWorkflowEmpty;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="There are no dependencies in this project version" />
            }
            description={
              <FM defaultMessage="As packages are added to this project, dependencies of those packages will appear here." />
            }
          />
        </Grid>
      )}

      {!isEmptyState && (
        <>
          <Grid item>
            {packageSourceToggleOptions.length > 1 && (
              <PackageSourceToggles
                hideCounts={qProjectDependencies.isLoading}
                options={packageSourceToggleOptions}
                onChange={(_, value) => value && setPackageSourceValue(value)}
                value={packageSource}
              />
            )}
            <FilterBar
              fields={
                packageSource === PackageSource.Packages ? FILTER_FIELDS : []
              }
            />
          </Grid>
          <Grid item>
            <Card>
              <CardHeader
                action={
                  <ButtonStack>
                    <ButtonPrimary
                      disabled={isLoading}
                      onClick={handleOpenExportDialog}
                    >
                      <FM defaultMessage="Export Dependencies" />
                    </ButtonPrimary>
                  </ButtonStack>
                }
                title={
                  <DependenciesTableHeader
                    isLoading={isLoading}
                    hasApproximateDependencies={hasApproximateDependencies}
                    totalCount={projectDependenciesTotalCount}
                    filteredCount={projectDependencies.length}
                    resourceKind="Project"
                  />
                }
              />
              <CardContent>
                <DependenciesTable
                  isLoading={isLoading}
                  ref={tableRef}
                  enableRowSelection
                  data={projectDependencies}
                  includeColumns={
                    packageSource === PackageSource.Packages
                      ? PACKAGES_INCLUDE_COLUMNS
                      : GITHUB_ACTIONS_INCLUDE_COLUMNS
                  }
                  enableColumnSort
                  enablePagination
                  onClickDetail={handleClickDetail}
                  showVersion={true}
                  emptyStateProps={{
                    title: (
                      <FM defaultMessage="No Dependencies match the filter criteria" />
                    ),
                    children: (
                      <ButtonCancel onClick={clearFilter}>
                        <FM defaultMessage="Clear Filters" />
                      </ButtonCancel>
                    ),
                  }}
                  onRowSelectionChange={handleRowSelection}
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}

      <dependenciesExportDialog.Dialog
        {...dependenciesExportDialog.dialogProps}
      />
    </Grid>
  );
};

/**
 * Wire Filter Context to page
 */
export const ProjectVersionDependencies = withFilterProvider(
  BaseProjectVersionDependencies,
  {
    displayName: 'ProjectVersionDependencies',
    searchKeys: ['meta.name', 'meta.tags'],
  }
);
