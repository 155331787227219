import { Chip } from '@mui/material';

import { V1FindingCategory } from '@endorlabs/api_client';

import { FlexList, FlexListItem, NilDisplay } from '../../../../components';
import { FindingCategoryLabel } from '../FindingCategoryLabel';

export const FindingCategoriesArrayDisplay = ({
  value: findingCategories,
}: {
  value?: V1FindingCategory[];
}) => {
  if (!findingCategories?.length) return <NilDisplay />;

  return (
    <FlexList>
      {findingCategories.map((findingCategory) => (
        <FlexListItem key={findingCategory}>
          <Chip
            label={<FindingCategoryLabel findingCategory={findingCategory} />}
            size="small"
          />
        </FlexListItem>
      ))}
    </FlexList>
  );
};
