import { Alert, Stack, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  IconAlertTriangle,
} from '@endorlabs/ui-common';

interface ApiKeyDeleteConfirmationDialogProps
  extends Pick<ConfirmationDialogProps, 'open' | 'onCancel' | 'onConfirm'> {
  apiKeyName: string;
}

export const ApiKeyDeleteConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  apiKeyName,
}: ApiKeyDeleteConfirmationDialogProps) => {
  // shared message
  const deleteApiKeyText = <FM defaultMessage="Delete API Key" />;

  return (
    <ConfirmationDialog
      open={open}
      titleText={deleteApiKeyText}
      confirmText={deleteApiKeyText}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isDestructive={true}
    >
      <Stack spacing={4}>
        <Alert severity="error" icon={<IconAlertTriangle />}>
          <FM defaultMessage="Any tooling or automated processes which rely on this API key will cease to function as expected." />
        </Alert>
        <Typography variant="body1">
          <FM
            defaultMessage="This action will revoke and immediately delete the API Key <code>{name}</code>. Are you sure you want to proceed?"
            values={{
              name: apiKeyName,
              // styled `code` element
              // forces content to wrap
              code: (value) => (
                <Typography
                  component="code"
                  variant="inherit"
                  sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
                >
                  {value}
                </Typography>
              ),
            }}
          />
        </Typography>
      </Stack>
    </ConfirmationDialog>
  );
};
