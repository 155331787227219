export const buildDataTableVisibility = (
  columnOrder: string[],
  preferredColumnVisibility?: Record<string, boolean>
) => {
  const entries = columnOrder.map((c) => [c, true]);
  const visibility = Object.fromEntries(entries);

  if (!preferredColumnVisibility) {
    return visibility;
  }

  return { ...visibility, ...preferredColumnVisibility };
};
