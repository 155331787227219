import { Theme } from '@mui/material';

export const getButtonOverrides = ({ spacing }: Theme) => {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiLoadingButton: {
      defaultProps: {
        loadingPosition: 'start',
        variant: 'outlined',
      },

      styleOverrides: {
        root: {
          // If button does not have a start icon, add space to accommodate the loading indicator
          '&.MuiLoadingButton-loading:not(.MuiLoadingButton-startIcon)': {
            paddingLeft: spacing(9.5),
          },
        },
      },
    },
  };
};
