import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import { useCreateSemgrepRules } from '@endorlabs/queries';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers/AuthInfo';
import { getPoliciesPath } from '../../../routes';
import { FormUpsertEditSASTRule } from './FormUpsertEditSASTRule';

export const SASTRulesCreatePage = () => {
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const { activeNamespace } = useAuthInfo();

  const qCreateRule = useCreateSemgrepRules({
    onSuccess: () => {
      navigate({
        to: getPoliciesPath({
          tenantName: activeNamespace,
          section: 'sast',
        }),
      });
    },
  });

  const onSave = (resource: SemgrepRulesResource) => {
    qCreateRule.mutate({
      namespace: activeNamespace,
      resource,
    });
  };

  return (
    <Grid container direction="row" spacing={6}>
      <Grid item xs={12}>
        <PageHeader title={<FM defaultMessage="Create SAST Rule" />} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            sx={{
              paddingTop: spacing(8),
              marginRight: spacing(4),
            }}
            title={
              <Typography variant="body1">
                <FM defaultMessage="Author a custom SAST rule from scratch with YAML language. Only one rule can be created at one time." />
              </Typography>
            }
          />
          <CardContent sx={{ overflow: 'hidden' }}>
            <FormUpsertEditSASTRule
              resource={
                {
                  meta: { name: '' },
                  spec: { yaml: '' },
                } as SemgrepRulesResource
              }
              onSave={onSave}
              onCancel={() =>
                navigate({
                  to: getPoliciesPath({
                    tenantName: activeNamespace,
                    section: 'sast',
                  }),
                })
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
