import { AccordionCard, AccordionCardProps } from '@endorlabs/ui-common';

import { OnboardingStepId } from '../../domains/Onboarding/constants';

interface OnboardingAccordionCardProps extends Omit<AccordionCardProps, 'id'> {
  stepId: OnboardingStepId;
}

export const OnboardingAccordionCard = ({
  children,
  stepId,
  ...props
}: OnboardingAccordionCardProps) => {
  return (
    <AccordionCard {...props} id={stepId}>
      {children}
    </AccordionCard>
  );
};
