import { JSXElementConstructor } from 'react';

import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';

import { FilterStateValues, useFilterParams, useFilterState } from '../hooks';
import { FilterContext } from './FilterContext';

const DEFAULT_SEARCH_KEYS = ['meta.name', 'meta.tags'];

/**
 * Given a component, provide a Filter Context wired to Search/Query Params
 */
export const withFilterProvider = <TProps extends object>(
  Component: JSXElementConstructor<TProps>,
  options?: {
    baseFilter?: FilterExpression;
    defaultFilterValues?: FilterStateValues;
    displayName: string;
    initialFilterValues?: FilterStateValues;
    searchKeys?: string[];
  }
) => {
  const {
    baseFilter,
    defaultFilterValues,
    displayName,
    initialFilterValues,
    searchKeys = DEFAULT_SEARCH_KEYS,
  } = options ?? {};

  const WrappedComponent = (props: TProps) => {
    const filterStore = useFilterParams({ initialFilterValues });
    const filterState = useFilterState({
      baseFilter,
      buildSearchFilter: (searchValue) =>
        filterExpressionBuilders.or(
          searchKeys.map((key) => `${key} matches "${searchValue}"`)
        ),
      defaultFilterValues,
      searchKeys,
      store: filterStore,
    });

    return (
      <FilterContext.Provider value={filterState}>
        <Component {...props} />
      </FilterContext.Provider>
    );
  };

  // set display name
  WrappedComponent.displayName = displayName ?? Component.name;

  return WrappedComponent;
};
