import { Grid, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  EmptyState,
  FullHeightCard,
  ResponsiveCardLayout,
} from '@endorlabs/ui-common';

import {
  CategoricalBarChartVis,
  DashboardVulnerabilityPrioritization,
} from '../../domains/Dashboard';
import {
  FilterBar,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import { FindingLevelFilterFieldConfig } from '../../domains/Findings/constants/filters/FindingLevelFilterFieldConfig';
import { useAuthInfo } from '../../providers';
import { DashboardFeed } from './DashboardFeed';
import { DashboardInventory } from './DashboardInventory';

const visHeight = 330;

const FILTER_FIELDS = [FindingLevelFilterFieldConfig];

/**
 * Dashboard OSS Overview Page
 */
export const DashboardOSSOverview = withFilterProvider(
  () => {
    const { activeNamespace } = useAuthInfo();
    const { space } = useTheme();

    const isDashboardFeedEnabled = useFeatureFlags(
      (f) => f.ENABLE_DASHBOARD_FEED
    );

    const { _state: filterState } = useFilterContext();

    const findingLevels = useMemo(() => {
      const selectedLevels =
        (filterState.values?.get(FindingLevelFilterFieldConfig.id)
          ?.value as SpecFindingLevel[]) ?? FINDING_LEVELS;
      return selectedLevels;
    }, [filterState.values]);

    return (
      <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
        {isDashboardFeedEnabled && (
          <Grid item>
            <DashboardFeed namespace={activeNamespace} />
          </Grid>
        )}

        <Grid item>
          <DashboardInventory namespace={activeNamespace} />
        </Grid>

        <Grid item xs={12}>
          <FilterBar enableSearch={false} fields={FILTER_FIELDS} />
        </Grid>

        <Grid item>
          <DashboardVulnerabilityPrioritization
            findingLevels={findingLevels}
            namespace={activeNamespace}
          />
        </Grid>

        {/* Categorical Charts */}
        <Grid item>
          <ResponsiveCardLayout
            columnCount={3}
            gridProps={{ spacing: space.md }}
          >
            <FullHeightCard>
              <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                <CategoricalBarChartVis
                  chartId="TopProjects-1"
                  findingLevels={findingLevels}
                  height={visHeight}
                  namespace={activeNamespace}
                  title={<FM defaultMessage="Top Projects By" />}
                />
              </ErrorBoundary>
            </FullHeightCard>

            <FullHeightCard>
              <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                <CategoricalBarChartVis
                  chartId="TopPackages-1"
                  findingLevels={findingLevels}
                  height={visHeight}
                  namespace={activeNamespace}
                  title={<FM defaultMessage="Top Packages By" />}
                />
              </ErrorBoundary>
            </FullHeightCard>

            <FullHeightCard>
              <ErrorBoundary FallbackComponent={DashboardCardFallback}>
                <CategoricalBarChartVis
                  chartId="TopDependencies-1"
                  findingLevels={findingLevels}
                  height={visHeight}
                  namespace={activeNamespace}
                  title={<FM defaultMessage="Top Dependencies By" />}
                />
              </ErrorBoundary>
            </FullHeightCard>
          </ResponsiveCardLayout>
        </Grid>

        <Grid item height={120}>
          {/* Just a lil extra space to strech out */}
        </Grid>
      </Grid>
    );
  },
  {
    displayName: 'DashboardOSSOverview',
  }
);

const DashboardCardFallback = () => (
  <EmptyState
    title={<FM defaultMessage="We've Encountered a Problem" />}
    description={
      <FM defaultMessage="Unable to display the content for this section." />
    }
  />
);
