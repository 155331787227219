import { QueryServiceRequestsResponseObject } from '@endorlabs/queries';

import { parseUserName } from '../../domains/ServiceRequest';
import { ServiceRequestTableRow } from './ServiceRequestTable';

export const mapToServiceRequestTableRow = (
  ServiceRequests: QueryServiceRequestsResponseObject[]
): ServiceRequestTableRow[] => {
  const rows = ServiceRequests.map((serviceRequest) => {
    const { meta, spec } = serviceRequest;
    const { ApproveServiceRequest } = meta.references;

    const approver = parseUserName(
      ApproveServiceRequest.list?.objects?.[0]?.meta.created_by ?? '-'
    );

    const creator = parseUserName(meta.created_by ?? '-');

    return {
      approver,
      createTime: meta.create_time,
      creator,
      description: spec.description,
      operation: spec.method,
      resource: spec.resource,
      status: spec.status,
      tenant: spec.target_namespace,
      updateTime: meta.update_time,
      uuid: serviceRequest.uuid,
    };
  });

  return rows;
};
