import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  SBOMImportServiceApi,
  V1CountResponse,
  V1ImportedSBOM,
  V1ImportedSBOMSpec,
  V1ListParameters,
  V1SBOMKind,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { WithRequired } from '@endorlabs/utils/types';

import { useBuildReadRequestParameters } from './hooks';
import { QuerySBOMImportProjectsQueryKeys } from './query_sbom_import_projects';
import {
  ImportedSBOMResource,
  ImportedSBOMResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface SBOMImportReadParams {
  namespace: string;
  uuid: string;
}

export interface SBOMImportWriteParams {
  namespace: string;
  resource: V1ImportedSBOM;
}

export interface SBOMImportUpdateParams extends SBOMImportWriteParams {
  mask?: string;
}

export interface SBOMImportWriteManyParams {
  resources: WithRequired<V1ImportedSBOM, 'tenant_meta'>[];
}

export interface SBOMImportUpdateManyParams extends SBOMImportWriteManyParams {
  mask?: string;
}

type CountSBOMImportOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListSBOMImportOptions = ResourceQueryOptions<ImportedSBOMResourceList>;
type GetSBOMImportOptions = ResourceQueryOptions<ImportedSBOMResource>;
type UpsertSBOMImportOptions = ResourceMutateOptions<
  V1ImportedSBOM,
  SBOMImportWriteParams
>;
type DeleteSBOMOptions = ResourceMutateOptions<object, SBOMImportReadParams>;
type UpsertManySBOMImportOptions = ResourceMutateOptions<
  V1ImportedSBOM[],
  SBOMImportWriteManyParams
>;

const BASE_KEY = 'v1/sbom-imports';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const SBOMImportQueryKeys = QK;

export const SBOM_IMPORT_UPDATE_MASK = 'meta.spec';

const getApiService = () => new SBOMImportServiceApi(getClientConfiguration());

const countSBOMImports = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.sBOMImportServiceListSBOMImports(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountSBOMImports = (
  namespace: string,
  opts: CountSBOMImportOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ImportedSBOM',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countSBOMImports(namespace, requestParameters),
    opts
  );
};

const listSBOMImports = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.sBOMImportServiceListSBOMImports(
    namespace,
    ...buildListParamArgs(listParams)
  );

  return resp.data as ImportedSBOMResourceList;
};

export const useListSBOMImports = (
  namespace: string,
  opts: ListSBOMImportOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ImportedSBOM',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listSBOMImports(namespace, requestParameters),
    opts
  );
};

const getSBOMImport = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.sBOMImportServiceGetSBOMImport(namespace, uuid);
  return resp.data as ImportedSBOMResource;
};

export const useGetSBOMImport = (
  params: SBOMImportReadParams,
  opts: GetSBOMImportOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getSBOMImport(params.namespace, params.uuid),
    opts
  );
};

const createSBOMImport = async (
  namespace: string,
  sBOMContent: V1ImportedSBOM
) => {
  const api = getApiService();
  const resp = await api.sBOMImportServiceCreateSBOMImport(
    namespace,
    sBOMContent
  );

  return resp.data as V1ImportedSBOM;
};

export const useCreateSBOMImport = (opts: UpsertSBOMImportOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'ImportedSBOM'),
    mutationFn: (params: SBOMImportWriteParams) =>
      createSBOMImport(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(
          QuerySBOMImportProjectsQueryKeys.query(vars.namespace)
        );
        queryClient.invalidateQueries(QK.count(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteSBOM = async (params: SBOMImportReadParams) => {
  const api = getApiService();
  const resp = await api.sBOMImportServiceDeleteSBOMImport(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteSBOM = (opts: DeleteSBOMOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'ImportedSBOM'),
    mutationFn: (params: SBOMImportReadParams) => deleteSBOM(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        queryClient.invalidateQueries(
          QuerySBOMImportProjectsQueryKeys.query(vars.namespace)
        );
        queryClient.invalidateQueries(QK.count(vars.namespace));
      }
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
const updateSBOMImport = async (params: SBOMImportUpdateParams) => {
  const { resource, namespace, mask = SBOM_IMPORT_UPDATE_MASK } = params;

  const updateResource: V1ImportedSBOM = {
    ...resource,
  };

  // Set spec.cyclone_dx or spec.spdx value to a blank string
  // These are required in the api but not fetched in the UI
  if (resource.spec && resource.spec?.kind === V1SBOMKind.Cyclonedx) {
    updateResource.spec = { ...resource.spec, spdx: '' };
  } else if (resource.spec && resource.spec?.kind !== V1SBOMKind.Cyclonedx) {
    updateResource.spec = { ...resource.spec, cyclone_dx: '' };
  }

  const req = buildUpdateReq(updateResource, mask);
  const api = getApiService();
  const resp = await api.sBOMImportServiceUpdateSBOMImport(namespace, req);
  return resp.data as ImportedSBOMResource;
};

export const useUpdateSBOMImport = (opts: UpsertSBOMImportOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'ImportedSBOM'),
    mutationFn: (params: SBOMImportUpdateParams) => updateSBOMImport(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateManySBOMImports = async (params: SBOMImportUpdateManyParams) => {
  const api = getApiService();
  const allPromiseResponses = await Promise.all(
    params.resources.map((resource) => {
      const { mask = SBOM_IMPORT_UPDATE_MASK } = params;
      const namespace = resource.tenant_meta.namespace;

      const updatedResource: V1ImportedSBOM = {
        ...resource,
      };

      // Set spec.cyclone_dx or spec.spdx value to a blank string
      // These are required in the api but not fetched in the UI
      if (resource.spec && resource.spec?.kind === V1SBOMKind.Cyclonedx) {
        updatedResource.spec = { ...resource.spec, spdx: '' };
      } else if (
        resource.spec &&
        resource.spec?.kind !== V1SBOMKind.Cyclonedx
      ) {
        updatedResource.spec = { ...resource.spec, cyclone_dx: '' };
      }

      const req = buildUpdateReq(updatedResource, mask);
      return api.sBOMImportServiceUpdateSBOMImport(namespace, req);
    })
  );
  const allResponses = allPromiseResponses.map((resp) => resp.data);
  return allResponses as ImportedSBOMResource[];
};

export const useUpdateManySBOMImports = (
  opts: UpsertManySBOMImportOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'ImportedSBOM'),
    mutationFn: (params: SBOMImportUpdateManyParams) =>
      updateManySBOMImports(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        vars.resources.forEach((resource) => {
          const namespace = resource.tenant_meta.namespace;
          queryClient.invalidateQueries(QK.list(namespace));
          if (resource.uuid) {
            queryClient.invalidateQueries(QK.record(namespace, resource.uuid));
          }
        });
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
