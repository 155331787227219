import { useContext } from 'react';

import { EventTrackingContext } from './EventTrackingContext';

export const useEventTracking = () => {
  const context = useContext(EventTrackingContext);

  if (!context) {
    throw new Error(
      'useEventTracking must be used within a EventTrackingProvider'
    );
  }

  return context;
};
