import { Stack, Typography } from '@mui/material';

import { ImgIconLogoMarkCyclone } from '../../themes';

interface SBOMNameDisplayProps {
  name: string;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  showIcon?: boolean;
  version?: string;
}

/**
 * Display for a SBOM Import
 */
export const SBOMNameDisplay = ({
  name,
  size = 'small',
  showIcon = true,
  version,
}: SBOMNameDisplayProps) => {
  const spacing = 2;

  return (
    <Stack direction="row" spacing={spacing} alignItems="center">
      {showIcon && <ImgIconLogoMarkCyclone />}

      <Typography component="span" fontSize={size} variant="inherit" noWrap>
        {name}
        {!!version && (
          <>
            {/* hint for the browser to add line break before version identifier, when needed */}
            <wbr />
            <Typography component="span" fontSize={size} color="text.secondary">
              @{version}
            </Typography>
          </>
        )}
      </Typography>
    </Stack>
  );
};
