import { Box, ToggleButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import { PackageSource } from '@endorlabs/queries';
import { BetaBadge, ExclusiveToggleButtonGroup } from '@endorlabs/ui-common';

export type PackageSourceToggleOption = {
  value: PackageSource;
  label: ReactNode;
  count?: number;
  isBeta?: boolean;
};

export const buildPackageSourceToggleOptions = (
  counts: {
    packages?: number;
    ciWorkflows?: number;
  },
  excludeList: PackageSource[] = []
) => {
  const { packages = 0, ciWorkflows = 0 } = counts;

  const options: PackageSourceToggleOption[] = [
    {
      value: PackageSource.Packages,
      label: <FM defaultMessage="Packages" />,
      count: packages,
    },
    {
      value: PackageSource.GithubActions,
      label: <FM defaultMessage="CI Workflows" />,
      count: ciWorkflows,
    },
  ];

  return options.filter((option) => !excludeList.includes(option.value));
};

const DEFAULT_PACKAGE_SOURCE_TOGGLE_OPTIONS = buildPackageSourceToggleOptions(
  {}
);

/**
 * Complementary component to a Filter or Search Bar in a
 * PackageVersion/Dependencies page. This component is intended to present
 * the options for Package "source", used to determine a base filter
 * for Packages in the view.
 *
 * Wraps the {@see ExclusiveToggleButtonGroup} component, adding a margin and
 * arrows below to associate the toggles with the related search or filter.
 *
 * @example
 * <Box>
 *   <PackageSourceToggles
 *     hideCounts={isLoadingCounts}
 *     options={packageSourceToggleOptions}
 *     onChange={(_, value) => value && setPackageSource(value)}
 *     value={packageSource}
 *   />
 *   <FilterBar fields={filterFields} />
 * </Box>
 */
export function PackageSourceToggles({
  hideCounts = false,
  onChange,
  options = DEFAULT_PACKAGE_SOURCE_TOGGLE_OPTIONS,
  value,
}: {
  /**
   * If true, hides the arrows below the toggle buttons and removes the margin
   */
  hideCounts?: boolean;
  options: PackageSourceToggleOption[];
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    value: PackageSource
  ) => void;
  value: PackageSource;
}) {
  return (
    <Box>
      <ExclusiveToggleButtonGroup value={value} onChange={onChange}>
        {options.map(({ count, isBeta, label, value }) => {
          if (isBeta) {
            return (
              <ToggleButton key={value} value={value} disabled={count === 0}>
                <BetaBadge>
                  {label}
                  {!hideCounts && !!count && (
                    <Typography variant="inherit">
                      <FormattedNumber value={count} notation="compact" />
                    </Typography>
                  )}
                </BetaBadge>
              </ToggleButton>
            );
          }
          return (
            <ToggleButton key={value} value={value} disabled={count === 0}>
              {label}

              {!hideCounts && !!count && (
                <Typography variant="inherit">
                  <FormattedNumber value={count} notation="compact" />
                </Typography>
              )}
            </ToggleButton>
          );
        })}
      </ExclusiveToggleButtonGroup>
    </Box>
  );
}
