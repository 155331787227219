import {
  Skeleton,
  Stack,
  StackProps,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { isNil as _isNil } from 'lodash-es';
import { ReactNode } from 'react';

import { useStyles } from '../../hooks';
import { HelpIconButton } from '../HelpIconButton';
import { NilDisplay } from '../NilDisplay';
import { RowStack } from '../RowStack';

const DEFAULT_HEADING_TYPOGRAPHY_PROPS = {
  component: 'h4',
  variant: 'overline',
} satisfies TypographyProps;

const DEFAULT_VALUE_TYPOGRAPHY_PROPS = {
  component: 'div',
  variant: 'body1',
} satisfies TypographyProps;

export const DEFAULT_HEADER_MAX_WIDTH = '25%';

// FIXME: Extend whatever StackProps uses
export type AttributeDisplayVariant = 'column' | 'flex' | 'row';

export interface AttributeDisplayProps {
  containerProps?: Pick<StackProps, 'alignItems' | 'justifyContent'>;
  /** Prevent value from being wrapped in a Typography component */
  disableTypography?: boolean;
  /** Attribute heading */
  heading?: ReactNode;
  /** Width of heading component if using "flex" variant */
  headingWidth?: string | number;
  /** Override heading typography props */
  headingTypographyProps?: TypographyProps;
  /** Help icon will be displayed inline with heading */
  helpTooltip?: ReactNode;
  /** Do not display at all if value is undefined or null */
  hideIfNil?: boolean;
  /** Is value still loading */
  isLoading?: boolean;
  /** Customize display when value is undefined or null */
  nilDisplay?: ReactNode;
  /** Attribute value */
  value?: ReactNode;
  /** Override value typography props */
  valueTypographyProps?: TypographyProps;
  /** Component variant to use */
  variant?: AttributeDisplayVariant;
}

/**
 * A layout component composed of a heading and a value.
 * Typically used in when listing a series of resource attributes.
 * Has a few variants:
 *  - column - heading displays above the value
 *  - row - heading displays inline with the value
 *  - flex - heading occupies a fixed width, value appears inline. Useful for a two-column layout.
 */
export const AttributeDisplay = ({
  containerProps,
  disableTypography,
  heading,
  headingWidth,
  headingTypographyProps = DEFAULT_HEADING_TYPOGRAPHY_PROPS,
  helpTooltip,
  hideIfNil = false,
  isLoading = false,
  nilDisplay = <NilDisplay variant="text" />,
  value,
  valueTypographyProps = DEFAULT_VALUE_TYPOGRAPHY_PROPS,
  variant = 'column',
}: AttributeDisplayProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  const isCol = variant === 'column';

  // Display nothing
  if (hideIfNil && _isNil(value)) {
    return undefined;
  }

  // Ensure a variant exists in case caller has not defined in TypographyProps
  const composedValueTypographyProps = {
    ...DEFAULT_VALUE_TYPOGRAPHY_PROPS,
    ...valueTypographyProps,
  };

  const composedHeadingTypographyProps = {
    ...DEFAULT_HEADING_TYPOGRAPHY_PROPS,
    marginTop: isCol ? undefined : '1px',
    ...headingTypographyProps,
  };

  let valueNode = isLoading ? <Skeleton /> : value ?? nilDisplay;

  if (!disableTypography) {
    valueNode = (
      <Typography
        {...composedValueTypographyProps}
        className="AttributeDisplay-value"
        sx={{ flexShrink: 2, wordBreak: 'break-all' }}
      >
        {valueNode}
      </Typography>
    );
  }

  const rootClassNames = clsx({
    'AttributeDisplay-root': true,
    [`AttributeDisplay-${variant}`]: true,
  });

  let headingNode = (
    <Typography
      {...composedHeadingTypographyProps}
      className="AttributeDisplay-heading"
    >
      {heading}
    </Typography>
  );

  if (helpTooltip) {
    headingNode = (
      <RowStack flexShrink={0} flexWrap="nowrap" gap={space.xs}>
        <Typography
          {...composedHeadingTypographyProps}
          className="AttributeDisplay-heading"
        >
          {heading}
        </Typography>
        <HelpIconButton tooltip={helpTooltip} />
      </RowStack>
    );
  }

  /** Row or column variant */

  if (isCol || variant === 'row') {
    const alignItems = isCol ? undefined : 'flex-start';
    const gap = isCol ? 1 : space.sm;

    return (
      <Stack
        alignItems={alignItems}
        className={rootClassNames}
        direction={variant}
        gap={gap}
        sx={sx}
      >
        {headingNode}
        {valueNode}
      </Stack>
    );
  }

  /** "Flex" variant */

  return (
    <Stack
      className={rootClassNames}
      direction="row"
      gap={1}
      {...containerProps}
      sx={sx}
    >
      <Stack
        alignItems="flex-start"
        className="AttributeDisplay-heading"
        direction="row"
        flexShrink={0}
        flexWrap="nowrap"
        gap={space.xs}
        marginRight={space.sm}
        marginTop="1px"
        width={`calc(${headingWidth} - ${space.sm})`}
      >
        {headingNode}
      </Stack>

      <Stack
        alignItems="flex-start"
        className="AttributeDisplay-value"
        direction="row"
        gap={space.xs}
      >
        {valueNode}
      </Stack>
    </Stack>
  );
};

function styles() {
  return {
    '&.AttributeDisplay-row, &.AttributeDisplay-flex': {
      '& .AttributeDisplay-heading': {
        marginTop: '1px',
      },
    },
  };
}
