import { Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { NilDisplay } from '../NilDisplay';

interface EPSSDisplayProps {
  percentile?: number;
  probability?: number;
}
export const EPSSDisplay = ({ percentile, probability }: EPSSDisplayProps) => {
  if (percentile && probability) {
    const probabilityPercentage = probability * 100;
    const percentileNumber = Math.round(percentile * 100);
    return (
      <Typography variant="body2">
        <FM
          defaultMessage="{probability}% chance of exploit ({percentile}{suffix} percentile)"
          values={{
            probability: probabilityPercentage.toFixed(2),
            percentile: percentileNumber,
            suffix: suffixDisplay(percentileNumber),
          }}
        />
      </Typography>
    );
  } else {
    return <NilDisplay />;
  }
};

const suffixDisplay = (value: number) => {
  const lastDigit = value % 10;
  if (lastDigit === 1) {
    return 'st';
  } else if (lastDigit === 2) {
    return 'nd';
  } else if (lastDigit === 3) {
    return 'rd';
  }
  return 'th';
};
