import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { Navigate } from '@tanstack/react-location';
import { differenceInMinutes } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import {
  IconCode,
  IconGitHub,
  IconGitPullRequest,
  LinkTabProps,
  NavigationTabs,
  useStyles,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useOnboardingSteps } from '../../domains/Onboarding';
import { OnboardingApproaches } from '../../domains/Onboarding/constants';
import { useAuthInfo, useAuthTenantInfo } from '../../providers';
import { getOnboardRootPath, NamedRoutes, useFullMatch } from '../../routes';
import { OnboardingCompleteCard } from './OnboardingCompleteCard';
import { OnboardingCTAs } from './OnboardingCTAs';
import { OnboardingResourcesCard } from './OnboardingResourcesCard';
import { OnboardingStepsView } from './OnboardingStepsView';

type ApproachParam = 'cli' | 'github-action' | 'github-app';

const paramToApproachMap = {
  cli: OnboardingApproaches.CLI,
  'github-action': OnboardingApproaches.GITHUB_ACTION,
  'github-app': OnboardingApproaches.GITHUB_APP,
};

const defaultApproachParam: ApproachParam = 'github-app';
const defaultApproach: (typeof paramToApproachMap)[ApproachParam] =
  paramToApproachMap[defaultApproachParam];

export const OnboardingIndexPage = () => {
  const { space, palette } = useTheme();
  const tabsSx = useStyles(tabsStyles);
  const { activeNamespace } = useAuthInfo();
  const { isSharedTenant } = useAuthTenantInfo();
  const { getIsOnboardingComplete, lastCompletedTimestamp } =
    useOnboardingSteps();

  const ApproachTabs: LinkTabProps[] = useMemo(() => {
    const tabs: LinkTabProps[] = [
      {
        Icon: IconGitHub,
        label: (
          <Stack>
            <FM defaultMessage="Scan with GitHub App" />
            <Typography variant="h4" sx={{ color: palette.brand.main }}>
              <FM defaultMessage="(Recommended)" />
            </Typography>
          </Stack>
        ),
        to: getOnboardRootPath({
          tenantName: activeNamespace,
          onboardApproach: 'github-app',
        }),
        value: 'github-app',
      },
      {
        Icon: IconGitPullRequest,
        label: <FM defaultMessage="Scan via GitHub Actions" />,
        to: getOnboardRootPath({
          tenantName: activeNamespace,
          onboardApproach: 'github-action',
        }),
        value: 'github-action',
      },
      {
        Icon: IconCode,
        label: <FM defaultMessage="Scan via CLI" />,
        to: getOnboardRootPath({
          tenantName: activeNamespace,
          onboardApproach: 'cli',
        }),
        value: 'cli',
      },
    ];
    return tabs;
  }, [activeNamespace, palette]);

  const {
    params: { onboardingApproach },
  } = useFullMatch();

  const currentOnboardingApproach =
    paramToApproachMap[onboardingApproach as ApproachParam] ?? defaultApproach;

  // Determine whether to show completed messaging
  const isOnboardingComplete = getIsOnboardingComplete();

  const isRecentCompletion =
    isOnboardingComplete &&
    differenceInMinutes(new Date(), Date.parse(lastCompletedTimestamp)) < 2;

  /* CANNOT ONBOARD USING A SHARED TENANT (e.g. `demo-trial`) */
  if (isSharedTenant) {
    return <Navigate to={NamedRoutes.TENANTS_INDEX} />;
  }

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {/* PAGE HEADER */}
      <Grid item>
        <Stack>
          <PageHeader
            title={<FM defaultMessage="Get Started with Endor Labs" />}
          />

          <Typography color="textSecondary" marginLeft={space.xs}>
            <FM defaultMessage="Follow this guide to get the most out of your trial." />
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        <Grid container direction="row" spacing={space.md}>
          {/* PRIMARY CONTENT */}

          <Grid
            container
            direction="column"
            flexWrap="nowrap"
            item
            spacing={space.md}
            xl={9}
            lg={9}
            md={12}
            sm={12}
            xs={12}
          >
            {isOnboardingComplete && (
              <Grid item>
                <OnboardingCompleteCard
                  isRecentCompletion={isRecentCompletion}
                />
              </Grid>
            )}

            {/* CALLS TO ACTION */}
            <Grid item>
              <OnboardingCTAs />
            </Grid>

            {/* ONBOARDING APPROACH TABS */}
            <Grid item justifyContent="center" width="100%">
              <NavigationTabs
                activeTab={onboardingApproach ?? defaultApproachParam}
                sx={tabsSx}
                tabs={ApproachTabs}
              ></NavigationTabs>
            </Grid>

            <Grid item>
              <OnboardingStepsView approach={currentOnboardingApproach} />
            </Grid>
          </Grid>

          {/* RESOURCES */}
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <OnboardingResourcesCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const tabsStyles = () => ({
  '.MuiTabs-flexContainer': { justifyContent: 'center', width: '100%' },
  '.MuiTab-root .MuiGrid-root:has(.MuiSvgIcon-root)': {
    display: 'block',
    padding: '0.25rem',
  },
});
