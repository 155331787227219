import { without as _without } from 'lodash-es';
import { createElement } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel, V1FindingTags } from '@endorlabs/api_client';
import { DEFAULT_FINDING_CATEGORIES } from '@endorlabs/endor-core/Finding';
import {
  FILTER_COMPARATORS,
  FilterableResource,
  ResourceFilter,
} from '@endorlabs/filters';
import {
  FindingCategoryMessages,
  FindingLevelMessages,
  FindingTagsMessages,
} from '@endorlabs/ui-common';

import { FacetFilterDefinition } from './types';

export const FACET_FILTER_DEFAULT_FILTER_PARTIALS: {
  [k in FilterableResource]?: Omit<ResourceFilter, 'value'>[];
} = {
  Finding: [
    {
      kind: 'Finding',
      key: 'meta.description',
      type: 'string',
      comparator: FILTER_COMPARATORS.MATCHES,
    },
    {
      kind: 'Finding',
      key: 'spec.finding_metadata.vulnerability.meta.description',
      type: 'string',
      comparator: FILTER_COMPARATORS.MATCHES,
    },
    {
      kind: 'Finding',
      key: 'spec.finding_metadata.vulnerability.spec.aliases',
      type: 'array',
      comparator: FILTER_COMPARATORS.MATCHES,
    },
  ],
};

export const FACET_FILTER_DEFINITIONS: {
  [k in FilterableResource]?: FacetFilterDefinition[];
} = {
  Finding: [
    {
      id: 'Finding:spec.dismiss',
      filter: {
        kind: 'Finding',
        key: 'spec.dismiss',
        type: 'boolean',
      },
      control: 'FacetFilterToggle',
      comparator: FILTER_COMPARATORS.NOT_EQUAL,
      label: <FM defaultMessage="Hide Dismissed" />,
    },
    {
      id: 'Finding:spec.finding_categories',
      filter: {
        kind: 'Finding',
        key: 'spec.finding_categories',
        type: 'array',
      },
      comparator: FILTER_COMPARATORS.CONTAINS,
      label: <FM defaultMessage="Category" />,
      control: 'FacetFilterMultiSelect',
      values: DEFAULT_FINDING_CATEGORIES.map((value) => ({
        key: value.toString(),
        value,
        label: createElement(FM, FindingCategoryMessages[value]),
      })),
    },
    {
      id: 'Finding:spec.level',
      filter: {
        kind: 'Finding',
        key: 'spec.level',
        type: 'string',
      },
      label: <FM defaultMessage="Severity" />,
      control: 'FacetFilterMultiSelect',
      values: _without(
        Object.values(SpecFindingLevel),
        SpecFindingLevel.Unspecified
      ).map((value) => ({
        key: value.toString(),
        value,
        label: createElement(FM, FindingLevelMessages[value]),
      })),
    },
    {
      id: 'Finding:spec.finding_tags',
      filter: {
        kind: 'Finding',
        key: 'spec.finding_tags',
        type: 'string',
      },
      // override default comparator
      comparator: FILTER_COMPARATORS.CONTAINS_ALL,
      label: <FM defaultMessage="Attributes" />,
      control: 'FacetFilterFindingAttributes',
      values: _without(
        Object.values(V1FindingTags),
        // omit unspecified as an option
        V1FindingTags.Unspecified
      ).map((value) => ({
        key: value.toString(),
        value,
        label: createElement(FM, FindingTagsMessages[value]),
      })),
    },
  ],
};
