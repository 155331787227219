import {
  getPaginationRowModel,
  RowData,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import { DEFAULT_PAGE_SIZE } from '../constants';
import { DataTablePaginator } from '../useDataTablePaginator';

export const useDataTablePagination = <T extends RowData>(
  enablePagination: boolean,
  paginator?: DataTablePaginator
) => {
  // const tablePaginationProps: Partial<TableOptions<T>> = useMemo(() => {
  const tablePaginationProps: Partial<TableOptions<T>> = useMemo(() => {
    if (enablePagination && paginator) {
      return {
        manualPagination: true,
        onPaginationChange: paginator.onPaginationChange,
        pageCount: paginator.pageCount,
        state: {
          pagination: paginator.state,
        },
      };
    }

    if (enablePagination === true) {
      return {
        initialState: {
          pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
          },
        },
        getPaginationRowModel: getPaginationRowModel(),
      };
    }

    return {};
  }, [paginator, enablePagination]);

  return tablePaginationProps;
};
