import { Box } from '@mui/material';
import clsx from 'clsx';

import { GraphEdgeDirection } from '@endorlabs/utils/graph';

export interface GraphPathListEdgeIndicatorProps {
  direction: GraphEdgeDirection;
}

export const GraphPathListEdgeIndicator = ({
  direction,
}: GraphPathListEdgeIndicatorProps) => {
  const cls = clsx('GraphPathList-edgeIndicator', {
    'GraphPathList-edgeIndicatorForward': direction === 'forward',
    'GraphPathList-edgeIndicatorReverse': direction === 'back',
    'GraphPathList-edgeIndicatorBoth': direction === 'both',
  });

  return (
    <Box
      className={cls}
      sx={{
        backgroundColor: 'divider',
        // flex properties allow this to fill height
        flexBasis: '0px',
        flexGrow: 1,
        marginBottom: '2px',
        marginTop: '2px',
        minHeight: '8px',
        position: 'relative',
        width: '2px',

        // hide this edge indicator for the last item in a list
        '.GraphPathList-node:last-of-type &': {
          display: 'none',
        },

        // account for margin with both arrows
        '&.GraphPathList-edgeIndicatorBoth': {
          minHeight: '2px',
        },

        // top arrow pseudo element for reverse or bidirection path
        '&.GraphPathList-edgeIndicatorReverse,&.GraphPathList-edgeIndicatorBoth':
          {
            marginTop: '8px',
            ':before': {
              content: '""',
              position: 'absolute',
              top: -6,
              left: -3,
              width: 0,
              height: 0,
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              borderBottom: '6px solid #000',
              borderBottomColor: 'divider',
            },
          },

        // bottom arrow pseudo element for forward or bidirection path
        '&.GraphPathList-edgeIndicatorForward,&.GraphPathList-edgeIndicatorBoth':
          {
            marginBottom: '8px',
            ':after': {
              content: '""',
              position: 'absolute',
              bottom: -6,
              left: -3,
              width: 0,
              height: 0,
              borderLeft: '4px solid transparent',
              borderRight: '4px solid transparent',
              borderTop: '6px solid #000',
              borderTopColor: 'divider',
            },
          },
      }}
    />
  );
};
