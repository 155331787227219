import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  ContainerDetailDrawer,
  ContainerDetailDrawerProps,
} from '../components/ContainerDetailDrawer';

export const useContainerDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    ContainerDetailDrawerProps,
    ContainerDetailDrawerProps
  >({
    drawerParams: [
      'containerNamespace',
      'containerUuid',
      'layerIndex',
      'imageName',
    ],
    Component: ContainerDetailDrawer,
  });

  const permalinkEffect = () => {
    // TODO:
  };

  return { DetailDrawer, permalinkEffect };
};
