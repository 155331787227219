import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel as FindingLevel } from '@endorlabs/api_client';

import {
  FindingLevelAbbreviatedMessages,
  FindingLevelMessages,
} from '../../locales';

export const FindingLevelLabel = ({
  findingLevel,
  abbreviate,
}: {
  findingLevel: FindingLevel;
  abbreviate?: boolean;
}) => {
  const message = abbreviate
    ? FindingLevelAbbreviatedMessages[findingLevel]
    : FindingLevelMessages[findingLevel];
  return <FM {...message} />;
};
