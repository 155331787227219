import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { MouseEvent, useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  FindingCountDisplayProps,
  UIEventUtils,
} from '@endorlabs/ui-common';

import { ScanResultScannedByDisplay } from '../../ScanResult';
import { ContainerNameDisplay } from './ContainerNameDisplay';

export type ContainersTableRow = {
  isDefaultVersion?: boolean;
  isSigned?: boolean;
  name: string;
  versionRef: string;
  link: string;
  findingCounts: FindingCountDisplayProps[];
  latestScanResult?: ScanResultResource;
};

const CONTAINERS_TABLE_COLUMNS: DataTableColumnDef<ContainersTableRow>[] = [
  {
    id: 'container',
    cell: (t) => {
      const { isDefaultVersion, isSigned, name, versionRef } = t.row.original;
      return (
        <ContainerNameDisplay
          isDefaultVersion={isDefaultVersion}
          isSigned={isSigned}
          name={name}
          version={versionRef}
        />
      );
    },
    colType: ColTypes.TEXT_LONG,
    header: () => <FM defaultMessage="Container" />,
  },
  {
    accessorKey: 'findingCounts',
    colType: ColTypes.FINDING_COUNTS,
  },
  {
    accessorKey: 'latestScanResult',
    cell: ({ getValue }) => (
      <ScanResultScannedByDisplay scanResult={getValue()} showScanTime />
    ),
    header: () => <FM defaultMessage="Last Scanned" />,
  },
];

export interface ContainersTableProps
  extends Omit<
    DataTableProps<ContainersTableRow>,
    'columns' | 'onRowClick' | 'tableId'
  > {
  //
}

export const ContainersTable = (props: ContainersTableProps) => {
  const navigate = useNavigate();
  const handleRowClick = useCallback(
    (row: ContainersTableRow, _: Row<ContainersTableRow>, evt: MouseEvent) => {
      UIEventUtils.simulateLinkClick(row.link, evt, navigate);
    },
    [navigate]
  );

  return (
    <DataTable
      {...props}
      columns={CONTAINERS_TABLE_COLUMNS}
      onRowClick={handleRowClick}
      tableId="ContainersTable"
    />
  );
};
