import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../../../utils/tanstack-react-router-shim';

const TenantScanProfileDetailIndexRoute = () => {
  return <Navigate to="profile" replace />;
};

export const Route = createFileRoute(
  '/t/:namespace/settings/scan-profiles/:scan_profile_uuid/'
)({
  component: TenantScanProfileDetailIndexRoute,
});
