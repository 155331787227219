import { without as _without } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1Method, V1RequestStatus } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import { ResourceKindDisplay } from '@endorlabs/ui-common';
import { MultiSelectInput } from '@endorlabs/ui-common';

import { FilterFieldConfig, filterFieldTransformBuilders } from '../../filters';
import {
  ServiceRequestMethodTypeMessages,
  ServiceRequestStatusTypeMessages,
} from '../locales';
import { ResourceKindList } from './common';

/**
 * Filter fields for the Artifact Signatures view
 */
export const SERVICE_REQUEST_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: 'ServiceRequest:spec.status_type',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.status',
      comparator: FILTER_COMPARATORS.CONTAINS,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Status" />}
        onChange={onChange}
        options={Object.values(V1RequestStatus)
          .map((value) => ({
            value,
            label: <FM {...ServiceRequestStatusTypeMessages[value]} />,
          }))
          .sort((a, b) => a.value.localeCompare(b.value))}
        value={value as V1RequestStatus[]}
      />
    ),
  } satisfies FilterFieldConfig<V1RequestStatus[]>,
  {
    id: 'ServiceRequest:spec.method_type',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.method',
      comparator: FILTER_COMPARATORS.CONTAINS,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Method" />}
        onChange={onChange}
        options={_without(
          Object.values(V1Method),
          // omit unspecified as an option
          V1Method.Unspecified,
          V1Method.Read,
          V1Method.All
        )
          .map((value) => ({
            value,
            label: <FM {...ServiceRequestMethodTypeMessages[value]} />,
          }))
          .sort((a, b) => a.value.localeCompare(b.value))}
        value={value as V1Method[]}
      />
    ),
  } satisfies FilterFieldConfig<V1Method[]>,
  {
    id: 'ServiceRequest:spec.resource_type',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.resource',
      comparator: FILTER_COMPARATORS.CONTAINS,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Resource" />}
        onChange={onChange}
        options={ResourceKindList.map((value) => ({
          value,
          label: <ResourceKindDisplay value={value} />,
        })).sort((a, b) => a.value.localeCompare(b.value))}
        value={value as ResourceKind[]}
      />
    ),
  } satisfies FilterFieldConfig<ResourceKind[]>,
];
