import { omit as _omit } from 'lodash-es';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { FindingSource } from '@endorlabs/endor-core/Finding';

const VERSION = 2;

// Exclude preferences from persistance as needed
const NON_PERSISTED_USER_PREFERENCES: (keyof UserPreferencesStore)[] = [];

interface AuthPreferences {
  includeChildNamespaces?: boolean;
}

interface FilterPreferences {
  mode?: string;
}

interface PolicyPreferences {}

const DEFAULT_AUTH_PREFERENCES: AuthPreferences = {
  includeChildNamespaces: false,
};

const DEFAULT_POLICY_PREFERENCES: PolicyPreferences = {};

export interface UserPreferencesStore {
  auth?: AuthPreferences;
  darkMode?: boolean;
  defaultTenant?: string;
  filters?: FilterPreferences;
  policy?: PolicyPreferences;
  projectFindingSource?: FindingSource;
  setAuthPreferences: (preferences: Partial<AuthPreferences>) => void;
  setDarkMode: (useDarkMode: boolean) => void;
  setDefaultTenant: (tenantName: string) => void;
  setFilterPreferences: (preferences: FilterPreferences) => void;
  setPolicyPreferences: (preferences: PolicyPreferences) => void;
  setProjectFindingSource: (findingSource: FindingSource) => void;
}

const DEFAULT_STORE_VALUES = {
  auth: DEFAULT_AUTH_PREFERENCES,
  darkMode: false,
  defaultTenant: undefined,
  policy: DEFAULT_POLICY_PREFERENCES,
  projectFindingSource: FindingSource.Dependency,
};

export const useUserPreferences = create(
  persist<UserPreferencesStore>(
    (setState, getState): UserPreferencesStore => {
      return {
        ...DEFAULT_STORE_VALUES,

        setAuthPreferences: (prefs: Partial<AuthPreferences>) => {
          setState((state) => ({
            ...state,
            auth: { ...state.auth, ...prefs },
          }));
        },
        setDarkMode: (useDarkMode: boolean) => {
          setState((state) => ({ ...state, darkMode: useDarkMode }));
        },
        setDefaultTenant: (tenantName: string) => {
          setState((state) => ({ ...state, defaultTenant: tenantName }));
        },
        setFilterPreferences: (prefs: FilterPreferences) => {
          setState((state) => ({
            ...state,
            filters: { ...state.filters, ...prefs },
          }));
        },
        setPolicyPreferences: (prefs: PolicyPreferences) => {
          setState((state) => ({
            ...state,
            policy: { ...state.policy, ...prefs },
          }));
        },
        setProjectFindingSource: (findingSource: FindingSource) => {
          setState((state) => ({
            ...state,
            projectFindingSource: findingSource,
          }));
        },
      };
    },
    {
      migrate: (persistedState, version) => {
        // Remove preference related to policy intro page
        // @ts-expect-error - Allow for unknown here
        if (!persistedState.version && version === 2) {
          return {
            ...DEFAULT_STORE_VALUES,
            ...(persistedState as UserPreferencesStore),
            policy: DEFAULT_POLICY_PREFERENCES,
          };
        }

        return {
          ...DEFAULT_STORE_VALUES,
          ...(persistedState as UserPreferencesStore),
        };
      },
      name: 'user-preferences-store',
      // only persist a subset of the store
      partialize: (state) =>
        _omit(state, NON_PERSISTED_USER_PREFERENCES) as UserPreferencesStore,
      version: VERSION,
    }
  )
);
