import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const TenantDashboardIndexRoute = () => {
  return <Navigate to="oss-overview" replace />;
};

export const Route = createFileRoute('/t/:namespace/dashboard/')({
  component: TenantDashboardIndexRoute,
});
