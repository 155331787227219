import noop from 'lodash-es/noop';
import { useMutation, useQueryClient } from 'react-query';

import { OnboardServiceApi, V1Onboard } from '@endorlabs/api_client';

import { AuthenticationQueryKeys } from './auth';
import { OnboardResource, ResourceMutateOptions } from './types';
import { getClientConfiguration } from './utils';

export interface OnboardWriteParams {
  resource: V1Onboard;
}

type CreateOnboardOptions = ResourceMutateOptions<
  V1Onboard,
  OnboardWriteParams
>;

const getApiService = () => new OnboardServiceApi(getClientConfiguration());

const createOnboard = async (params: OnboardWriteParams) => {
  const api = getApiService();
  const resp = await api.onboardServiceCreateOnboard(params.resource);
  return resp.data as OnboardResource;
};

export const useCreateOnboard = (opts: CreateOnboardOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    mutationFn: (params: OnboardWriteParams) => createOnboard(params),
    onSuccess: async (...args) => {
      // Since user may now be associated with a new tenant,
      // re-request auth verification to obtain the user's updated tenant list
      await queryClient.invalidateQueries(AuthenticationQueryKeys.record());
      (opts.onSuccess ?? noop)(...args);
    },
  });
};
