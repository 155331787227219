import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  PolicyServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1Policy,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { invalidateListQueries } from './client';
import { useBuildReadRequestParameters } from './hooks';
import {
  PolicyResource,
  PolicyResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
  listAllResource,
} from './utils';

interface PolicyReadParams {
  namespace: string;
  uuid: string;
}

export interface PolicyWriteParams {
  namespace: string;
  resource: V1Policy;
}

export interface PolicyUpdateParams extends PolicyWriteParams {
  mask?: string;
}

type CountPolicyOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListPolicyOptions = ResourceQueryOptions<PolicyResourceList>;
type ListAllPolicyOptions = ResourceQueryOptions<PolicyResource[]>;
type GetPolicyOptions = ResourceQueryOptions<PolicyResource>;
type UpsertPolicyOptions = ResourceMutateOptions<V1Policy, PolicyWriteParams>;
type DeletePolicyOptions = ResourceMutateOptions<object, PolicyReadParams>;

const BASE_KEY = 'v1/policies';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'LIST_ALL', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const PolicyQueryKeys = QK;

export const POLICY_UPDATE_MASK = 'meta,spec,propagate';

// not allowed to update these fields for templated policies:
// "spec.rule",
// "spec.query_statements",
// "spec.template_parameters",
// "spec.resource_kinds",
// "spec.group_by_fields",
// "spec.finding.tags",
// "spec.finding.target_kind",
export const TEMPLATED_POLICY_UPDATE_MASK = [
  'meta',
  'spec.disable',
  'spec.project_selector',
  'spec.project_exceptions',
  'spec.template_values',
  'spec.finding.level',
  'spec.finding.categories',
  'spec.finding.summary',
  'spec.finding.explanation',
  'spec.finding.remediation',
  'spec.finding.external_name',
  'spec.finding.meta_tags',
  'spec.admission.bypass_exceptions',
  'spec.admission.disable_enforcement',
  'spec.exception.expiration_time',
  'spec.exception.reason',
  'spec.exception.tags',
  'spec.notification.aggregation_type',
  'spec.notification.bypass_exceptions',
  'spec.notification.notification_target_uuids',
  'propagate'
].join(',');

const getApiService = () => new PolicyServiceApi(getClientConfiguration());

const countPolicies = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.policyServiceListPolicies(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountPolicies = (
  namespace: string,
  opts: CountPolicyOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Policy',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countPolicies(namespace, requestParameters),
    opts
  );
};

const listPolicies = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.policyServiceListPolicies(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as PolicyResourceList;
};

export const useListPolicies = (
  namespace: string,
  opts: ListPolicyOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Policy',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listPolicies(namespace, requestParameters),
    opts
  );
};

export const useListAllPolicies = (
  namespace: string,
  opts: ListAllPolicyOptions = {},
  listParams: ListAllRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Policy',
    'LIST_ALL',
    listParams,
    opts
  );

  return useQuery(
    QK.listAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<PolicyResource, PolicyResourceList>(
        (pageToken) => {
          const pageParams: V1ListParameters = {
            ...requestParameters,
            page_token: pageToken,
          };
          return listPolicies(namespace, pageParams);
        },
        { signal: ctx.signal }
      ),
    opts
  );
};

const getPolicy = async (namespace: string, policyUuid: string) => {
  const api = getApiService();
  const resp = await api.policyServiceGetPolicy(namespace, policyUuid);
  return resp.data as PolicyResource;
};

export const useGetPolicy = (
  params: PolicyReadParams,
  opts: GetPolicyOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getPolicy(params.namespace, params.uuid),
    opts
  );
};

const createPolicy = async (namespace: string, policyBody: V1Policy) => {
  const api = getApiService();
  const resp = await api.policyServiceCreatePolicy(namespace, policyBody);
  return resp.data as PolicyResource;
};

export const useCreatePolicy = (opts: UpsertPolicyOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Policy'),
    mutationFn: (params: PolicyWriteParams) =>
      createPolicy(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          // The created namespace may be propagated to child namespaces
          includeChildren: !!data.propagate,
        });
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updatePolicy = async (params: PolicyUpdateParams) => {
  const { resource, namespace, mask } = params;
  const defaultMaskForResource = resource.spec.template_uuid ? TEMPLATED_POLICY_UPDATE_MASK : POLICY_UPDATE_MASK
  const finalMask = mask || defaultMaskForResource
  const req = buildUpdateReq(resource, finalMask);
  const api = getApiService();
  const resp = await api.policyServiceUpdatePolicy(namespace, req);
  return resp.data as PolicyResource;
};

export const useUpdatePolicy = (opts: UpsertPolicyOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Policy'),
    mutationFn: (params: PolicyUpdateParams) => updatePolicy(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          // The update namespace may propagated from a parent to the active namespace
          includePropagated: true,
        });

        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deletePolicy = async (params: PolicyReadParams) => {
  const api = getApiService();
  const resp = await api.policyServiceDeletePolicy(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeletePolicy = (opts: DeletePolicyOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Policy'),
    mutationFn: (params: PolicyReadParams) => deletePolicy(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          includeChildren: true,
          includePropagated: true,
        });
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
