import { FindingResource } from '@endorlabs/endor-core/Finding';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';

export enum FindingAggregation {
  Dependency = 'FINDING_AGGREGATION_DEPENDENCY',
  FindingAndDependency = 'FINDING_AGGREGATION_FINDING_DEPENDENCY',
  Finding = 'FINDING_AGGREGATION_FINDING',
  None = 'FINDING_AGGREGATION_NONE',
  Package = 'FINDING_AGGREGATION_PACKAGE',
}

export interface FindingDetailDrawerSectionProps {
  finding?: FindingResource;
  isLoading?: boolean;
  namespace: string;
}

export type FindingsDetailSASTSectionProps = {
  sastRuleForFinding?: SemgrepRulesResource;
  finding?: FindingResource;
  isLoading?: boolean;
  namespace: string;
  project?: ProjectResource;
};
