import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'Third Party',
});

export const Route = createFileRoute('/t/:namespace/third-party')({
  // beforeLoad: () => ({ title: ROUTE_TITLE }),
});
