import { Stack, Typography } from '@mui/material';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { PickD3Scale } from '@visx/scale/lib/types/Scale';
import { CSSProperties } from 'react';

import { ChartMargin, LegendProps } from './types';

type LegendPropTypes = Partial<LegendProps> & {
  maxWidth: number;
  maxHeight: number;
  margin: ChartMargin;
  colorScale: PickD3Scale<'ordinal', string, string | number>;
};

export const Legend = ({
  colorScale,
  dataValues = [],
  legendAlign,
  legendDirection = 'row',
  legendFontSize = 12,
  legendGap = 4,
  legendIconShape,
  legendJustify,
  legendPosition = 'bottom',
  maxWidth,
  maxHeight,
  margin,
  showLegendValues,
  valueFormat,
}: LegendPropTypes) => {
  const getLegendContainerStyles = (): CSSProperties => {
    return legendPosition === 'center'
      ? {
          position: 'absolute',
          top: maxHeight / 2 + margin.top,
          left: maxWidth / 2 + margin.left,
          transform: 'translate(-50%,-50%)',
        }
      : {};
  };

  const getLegendValue = (label: { index: number }): number | string => {
    const value = dataValues[label.index];
    return valueFormat ? valueFormat(value) : value;
  };

  return (
    <LegendOrdinal scale={colorScale}>
      {(labels) => (
        <Stack
          direction="row"
          style={getLegendContainerStyles()}
          justifyContent={legendJustify}
          alignItems={legendAlign}
          width={maxWidth}
          height={maxHeight}
          marginTop={
            ['left', 'right'].includes(legendPosition) ? `${margin.top}px` : 0
          }
          marginLeft={
            ['top', 'bottom'].includes(legendPosition) ? `${margin.left}px` : 0
          }
          overflow="hidden"
        >
          <Stack
            gap={legendGap}
            direction={legendDirection}
            maxWidth="min-content"
          >
            {labels.map((label, i) => (
              <LegendItem key={`legend-quantile-${i}`} margin="0 0">
                <svg width={legendFontSize} height={legendFontSize}>
                  {legendIconShape === 'rect' && (
                    <rect
                      fill={label.value}
                      width={legendFontSize}
                      height={legendFontSize}
                    />
                  )}
                  {legendIconShape === 'circle' && (
                    <circle
                      r={legendFontSize / 2}
                      cx={legendFontSize / 2}
                      cy={legendFontSize / 2}
                      fill={label.value}
                    />
                  )}
                  {legendIconShape === 'pill' && (
                    <rect
                      fill={label.value}
                      width={legendFontSize}
                      height={4}
                      y={legendFontSize * 0.5 - 2}
                      rx={2}
                    />
                  )}
                </svg>

                <LegendLabel align="left" margin="0 0 0 4px" flex="0">
                  <Typography component="span" fontSize={legendFontSize} noWrap>
                    {label.text}
                  </Typography>
                </LegendLabel>

                {showLegendValues && (
                  <LegendLabel align="left" margin="0 0 0 4px" flex="0">
                    <Typography
                      component="span"
                      fontSize={legendFontSize}
                      noWrap
                    >
                      {getLegendValue(label)}
                    </Typography>
                  </LegendLabel>
                )}
              </LegendItem>
            ))}
          </Stack>
        </Stack>
      )}
    </LegendOrdinal>
  );
};
