import { Theme } from '@mui/material';

export const getToggleButtonOverrides = (theme: Theme) => {
  const { spacing, palette } = theme;

  return {
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },

    MuiToggleButton: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          padding: spacing(1.5, 4),

          '&.MuiToggleButton-sizeSmall': {
            padding: spacing(1, 2.5),
          },

          '&.Mui-selected': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,

            '&:hover': {
              backgroundColor: palette.primary.dark,
            },
          },
        },
      },
    },
  };
};
