import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import { useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ControlledLabelsField,
  ControlledTextField,
  ControlledURLField,
  ExclusiveToggleButtonGroup,
  IconFileText,
  IconUpload,
  useExclusiveToggleButtonGroup,
  useFileUpload,
} from '@endorlabs/ui-common';

import { SamlMetadataMethods } from '../types';

const CERTIFICATE_FILE_TYPES = [
  'application/x-x509-ca-cert',
  'application/x-x509-user-cert',
  'application/x-pkcs7-crl',
  'application/x-pem-file',
];

const samlMetadataMethodMessages = defineMessages({
  [SamlMetadataMethods.MANUAL]: { defaultMessage: 'Manual' },
  [SamlMetadataMethods.URL]: { defaultMessage: 'Metadata URL' },
});

const CertificateMethods = { PASTE: 'PASTE', UPLOAD: 'UPLOAD' } as const;

export const SamlIdentityProviderFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control, setValue, watch } = useFormContext();

  const onCertificateUpload = useCallback(
    (fileContent: unknown) => {
      setValue('spec.saml_provider.certificates.0', fileContent);
    },
    [setValue]
  );

  const triggerFileDialog = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { inputProps: uploadInputProps, fileData } = useFileUpload({
    acceptedTypes: CERTIFICATE_FILE_TYPES,
    inputName: 'spec.sam_provider.certificates.0',
    onLoad: onCertificateUpload,
  });

  const metadataMethod = watch('saml_metadata_method');

  const { getToggleButtonGroupProps, value: certificateMethod } =
    useExclusiveToggleButtonGroup(CertificateMethods.PASTE, [
      {
        value: CertificateMethods.PASTE,
        children: <FM defaultMessage="Paste it in" />,
      },
      {
        value: CertificateMethods.UPLOAD,
        children: <FM defaultMessage="Upload from File" />,
      },
    ]);

  return (
    <Stack spacing={space.md}>
      <ControlledTextField
        control={control}
        label={fm({
          defaultMessage: 'Identity Provider Name',
        })}
        name="meta.name"
        placeholder={fm({
          defaultMessage:
            'Enter a name for your identity provider configuration',
        })}
        required
      />

      <ControlledTextField
        control={control}
        defaultValue={metadataMethod}
        fullWidth={false}
        label={fm({ defaultMessage: 'Metadata Definition' })}
        name="saml_metadata_method"
        select
        sx={{ width: 240 }}
      >
        {Object.values(SamlMetadataMethods).map((type) => (
          <MenuItem key={type} value={type}>
            <FM {...samlMetadataMethodMessages[type]} />
          </MenuItem>
        ))}
      </ControlledTextField>

      {metadataMethod === SamlMetadataMethods.URL && (
        <Stack spacing={space.sm}>
          <ControlledURLField
            control={control}
            label={fm({
              defaultMessage: 'SAML Identity Provider Metadata URL',
            })}
            name="spec.saml_provider.provider_metadata_url"
            placeholder={fm({
              defaultMessage: 'e.g. https://example-idp.com/metadata',
            })}
            required
          />

          <ControlledLabelsField
            control={control}
            label={fm({
              defaultMessage: 'Attributes',
            })}
            name="spec.saml_provider.attribute_names"
            placeholder={fm({
              defaultMessage: 'e.g. FirstName, LastName, Email, Role',
            })}
          />
        </Stack>
      )}

      {metadataMethod === SamlMetadataMethods.MANUAL && (
        <Stack spacing={space.sm}>
          <ControlledURLField
            control={control}
            label={fm({
              defaultMessage: 'Discovery URL',
            })}
            name="spec.saml_provider.sso_url"
            placeholder={fm({
              defaultMessage: 'e.g. https://example-idp.com/SSO',
            })}
            required
          />

          <ControlledURLField
            control={control}
            label={fm({
              defaultMessage: 'Entity ID/Issuer',
            })}
            name="spec.saml_provider.issuer"
            placeholder={fm({
              defaultMessage: 'e.g. https://example-idp.com/issuer',
            })}
            required
          />

          <ControlledLabelsField
            control={control}
            label={fm({
              defaultMessage: 'Attributes',
            })}
            name="spec.saml_provider.attribute_names"
            placeholder={fm({
              defaultMessage: 'e.g. FirstName, LastName, Email, Role',
            })}
          />

          <Stack spacing={space.xs}>
            <InputLabel htmlFor="certificates">
              <FM defaultMessage="Certificate" />
            </InputLabel>

            <ExclusiveToggleButtonGroup
              {...getToggleButtonGroupProps()}
              size="small"
            />

            {certificateMethod === CertificateMethods.PASTE && (
              <ControlledTextField
                control={control}
                id="certificate"
                multiline
                name="spec.saml_provider.certificates.0"
                placeholder={fm({
                  defaultMessage: '------BEGIN CERTIFICATE-----',
                })}
                rows={8}
                variant="outlined"
              />
            )}

            {certificateMethod === CertificateMethods.UPLOAD && (
              <Stack width="100%">
                {/* Hidden file input outside react-hook-form control */}
                <input
                  {...uploadInputProps}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  type="file"
                />

                {/* Big ol' file upload area */}
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  height={160}
                  sx={{ backgroundColor: ({ palette }) => palette.grey[200] }}
                  width="100%"
                >
                  <Button
                    onClick={triggerFileDialog}
                    startIcon={fileData ? <IconFileText /> : <IconUpload />}
                  >
                    {fileData ? (
                      fileData.name
                    ) : (
                      <FM defaultMessage="Upload Certificate File" />
                    )}
                  </Button>
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
