import { remove as _remove } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';

import { getPoliciesPath } from '../../../routes';

export const PolicyTabNames = {
  ACTIONS: 'actions',
  CICD: 'cicd',
  EXCEPTIONS: 'exceptions',
  FINDING_RULES: 'findings',
  REMEDIATIONS: 'remediations',
  SAST: 'sast',
  SECRETS: 'secrets',
  TEMPLATES: 'templates',
} as const;

export type PolicyTabName =
  (typeof PolicyTabNames)[keyof typeof PolicyTabNames];

const buildTabRecords = (namespace: string) => {
  return [
    {
      label: <FM defaultMessage="Finding Policies" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.FINDING_RULES,
      }),
      value: PolicyTabNames.FINDING_RULES,
    },
    {
      label: <FM defaultMessage="Exception Policies" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.EXCEPTIONS,
      }),
      value: PolicyTabNames.EXCEPTIONS,
    },
    {
      label: <FM defaultMessage="Action Policies" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.ACTIONS,
      }),
      value: PolicyTabNames.ACTIONS,
    },
    {
      label: <FM defaultMessage="Remediation Policies" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.REMEDIATIONS,
      }),
      value: PolicyTabNames.REMEDIATIONS,
    },
    {
      label: <FM defaultMessage="Secret Rules" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.SECRETS,
      }),
      value: PolicyTabNames.SECRETS,
    },
    {
      label: <FM defaultMessage="CI/CD Rules" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.CICD,
      }),
      value: PolicyTabNames.CICD,
    },
    {
      label: <FM defaultMessage="SAST Rules" />,
      to: getPoliciesPath({
        tenantName: namespace,
        section: PolicyTabNames.SAST,
      }),
      value: PolicyTabNames.SAST,
    },
  ];
};

export const usePoliciesPageTabs = ({
  activeTab: targetActiveTab,
  namespace,
}: {
  activeTab: PolicyTabName;
  namespace: string;
}) => {
  const tabRecords = buildTabRecords(namespace);

  // Remove CI/CD tool patterns section when user does not have feature flag
  const isCiCdToolsEnabled = useFeatureFlags((s) => s.ENABLE_CI_CD_TOOLS);
  if (!isCiCdToolsEnabled) {
    _remove(tabRecords, (tab) => tab.value === PolicyTabNames.CICD);
  }

  const isSastFeatureEnabled = useFeatureFlags((s) => s.ENABLE_SAST_FEATURE);
  if (!isSastFeatureEnabled) {
    _remove(tabRecords, (tab) => tab.value === PolicyTabNames.SAST);
  }

  const isRemediationsFeatureEnabled = useFeatureFlags(
    (s) => s.ENABLE_REMEDIATIONS
  );
  if (!isRemediationsFeatureEnabled) {
    _remove(tabRecords, (tab) => tab.value === PolicyTabNames.REMEDIATIONS);
  }

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    PolicyTabNames.FINDING_RULES;

  return { activeTab, tabRecords };
};
