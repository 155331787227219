import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useGetScanProfile } from '@endorlabs/queries';
import { ButtonLinkSecondary, ButtonStack } from '@endorlabs/ui-common';

import {
  DetailDrawerContainer,
  DetailDrawerSection,
} from '../../../components/DetailDrawer';
import { getSettingsScanProfilesPath } from '../../../routes';
import { toToolchainToolInfo } from '../utils';
import { ToolChainSummary } from './ToolChainSummary';

export interface ScanProfileDetailDrawerProps {
  scanProfileNamespace: string;
  scanProfileUuid: string;
}

export const ScanProfileDetailDrawer = ({
  scanProfileNamespace,
  scanProfileUuid,
}: ScanProfileDetailDrawerProps) => {
  const qListScanProfiles = useGetScanProfile(
    scanProfileNamespace,
    { uuid: scanProfileUuid },
    { enabled: !!scanProfileNamespace && !!scanProfileUuid }
  );

  const isLoading = qListScanProfiles.isLoading;
  const scanProfile = qListScanProfiles?.data;
  const tools = useMemo(
    () => toToolchainToolInfo(scanProfile?.spec.toolchain_profile),
    [scanProfile?.spec.toolchain_profile]
  );

  const toolChainContent = (
    <DetailDrawerSection>
      <ToolChainSummary isLoading={isLoading} tools={tools} />
    </DetailDrawerSection>
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title: isLoading ? <Skeleton width={200} /> : scanProfile?.meta.name,
        action: (
          <ButtonStack>
            <ButtonLinkSecondary
              linkProps={{
                to: getSettingsScanProfilesPath({
                  tenantName: scanProfileNamespace,
                  uuid: scanProfileUuid,
                }),
              }}
            >
              <FM defaultMessage="Edit Scan Profile" />
            </ButtonLinkSecondary>
          </ButtonStack>
        ),
      }}
    >
      {toolChainContent}
    </DetailDrawerContainer>
  );
};
