import {
  Alert,
  FormControlLabel,
  MenuItem,
  Stack,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { WebhookConfigWebhookAuthMethod } from '@endorlabs/api_client';
import {
  ControlledCheckbox,
  ControlledTextField,
  ControlledURLField,
} from '@endorlabs/ui-common';

import { FormUpsertNotificationTargetFieldValues } from './FormUpsertNotificationTarget/types';

const WEBHOOK_AUTH_TYPES = [
  {
    label: <FM defaultMessage="Basic" />,
    value: WebhookConfigWebhookAuthMethod.Basic,
  },
  {
    label: <FM defaultMessage="API Key" />,
    value: WebhookConfigWebhookAuthMethod.ApiKey,
  },
  {
    label: <FM defaultMessage="None" />,
    value: WebhookConfigWebhookAuthMethod.None,
  },
];

type WebhookConfigFieldValues = Exclude<
  FormUpsertNotificationTargetFieldValues['spec']['action']['webhook_config'],
  undefined
>;

type WebhookActionTypeFieldValues = {
  spec: {
    action: {
      webhook_config: Pick<
        WebhookConfigFieldValues,
        | 'auth_method'
        | 'api_key'
        | 'basic_auth'
        | 'disable_hmac'
        | 'hmac_shared_secret'
        | 'url'
      >;
    };
  };
};

export const WebhookActionTypeFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control, watch, setValue } =
    useFormContext<WebhookActionTypeFieldValues>();
  const authType = watch('spec.action.webhook_config.auth_method');
  const disableHMAC = watch('spec.action.webhook_config.disable_hmac');
  const isAuthNone = authType === WebhookConfigWebhookAuthMethod.None;

  //Clear hmac shared secret on toggle of disable HMAC
  useEffect(() => {
    if (disableHMAC) {
      setValue('spec.action.webhook_config.hmac_shared_secret', '');
    }
  }, [disableHMAC, setValue]);

  return (
    <Stack spacing={space.sm}>
      <ControlledURLField
        control={control}
        label={fm({
          defaultMessage: 'URL endpoint for webhook',
        })}
        name="spec.action.webhook_config.url"
        placeholder={fm({
          defaultMessage: 'e.g. https://example-idp.com/metadata',
        })}
        required
      />

      <ControlledTextField
        control={control}
        label={fm({ defaultMessage: 'Auth method' })}
        name="spec.action.webhook_config.auth_method"
        helperText={fm({
          defaultMessage: 'Authentication type to connect to webhook',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
        }}
        select
        required
      >
        {WEBHOOK_AUTH_TYPES.map(({ label, value }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
      </ControlledTextField>
      {isAuthNone && (
        <Alert severity="warning">
          It is recommended to authenticate Webhooks.
        </Alert>
      )}
      {authType === WebhookConfigWebhookAuthMethod.Basic && (
        <>
          <ControlledTextField
            control={control}
            defaultValue=""
            label={fm({ defaultMessage: 'Username' })}
            name="spec.action.webhook_config.basic_auth.username"
            helperText={fm({
              defaultMessage: 'Username for basic authentication',
            })}
            placeholder={fm({
              defaultMessage: 'Username for login',
            })}
            rules={{
              required: fm({ defaultMessage: 'This is a required field' }),
              minLength: {
                value: 1,
                message: fm({
                  defaultMessage: 'A non-empty value is required',
                }),
              },
              maxLength: {
                value: 65_536,
                message: fm({
                  defaultMessage: 'The value must be 1024 characters or less',
                }),
              },
            }}
          />

          <ControlledTextField
            control={control}
            defaultValue=""
            label={fm({ defaultMessage: 'Password' })}
            name="spec.action.webhook_config.basic_auth.password"
            helperText={fm({
              defaultMessage: 'Password for login',
            })}
            placeholder={fm({
              defaultMessage: '********',
            })}
            rules={{
              required: fm({ defaultMessage: 'This is a required field' }),
              minLength: {
                value: 1,
                message: fm({
                  defaultMessage: 'A non-empty value is required',
                }),
              },
              maxLength: {
                value: 32,
                message: fm({
                  defaultMessage: 'The value must be 32 characters or less',
                }),
              },
            }}
            type="password"
          />
        </>
      )}

      {authType === WebhookConfigWebhookAuthMethod.ApiKey && (
        <ControlledTextField
          control={control}
          defaultValue=""
          label={fm({ defaultMessage: 'API Key' })}
          name="spec.action.webhook_config.api_key"
          helperText={fm({
            defaultMessage:
              'Make sure this grants the ability to create new issues in the webhook to which notifications will be sent',
          })}
          placeholder={fm({
            defaultMessage: 'Provide a API key for webhook',
          })}
          rules={{
            required: fm({ defaultMessage: 'This is a required field' }),
            minLength: {
              value: 1,
              message: fm({ defaultMessage: 'A non-empty value is required' }),
            },
            maxLength: {
              value: 1_024,
              message: fm({
                defaultMessage: 'The value must be 1024 characters or less',
              }),
            },
          }}
          type="password"
        />
      )}

      {!isAuthNone && (
        <>
          <FormControlLabel
            label={<FM defaultMessage="Disable HMAC Integration Check" />}
            control={
              <ControlledCheckbox
                control={control}
                name="spec.action.webhook_config.disable_hmac"
              />
            }
          />

          {!disableHMAC && (
            <ControlledTextField
              control={control}
              defaultValue=""
              label={fm({ defaultMessage: 'HMAC Shared Key' })}
              name="spec.action.webhook_config.hmac_shared_secret"
              helperText={fm({
                defaultMessage: 'HMAC shared key',
              })}
              placeholder={fm({
                defaultMessage: '*********',
              })}
              rules={{
                required: fm({ defaultMessage: 'This is a required field' }),
                minLength: {
                  value: 1,
                  message: fm({
                    defaultMessage: 'A non-empty value is required',
                  }),
                },
                maxLength: {
                  value: 4_096,
                  message: fm({
                    defaultMessage: 'The value must be 4096 characters or less',
                  }),
                },
              }}
              type="password"
            />
          )}
        </>
      )}
    </Stack>
  );
};
