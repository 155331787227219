import { Chip, useTheme } from '@mui/material';

import { RowStack } from '../RowStack';

interface TagsDisplayProps {
  tags?: string[];
}

/**
 * Global component to display a set of tags (typically resource `meta.tags`) in Chip format.
 */
export const TagsDisplay = ({ tags = [] }: TagsDisplayProps) => {
  const { space } = useTheme();

  return (
    <RowStack flexWrap="wrap" gap={space.xs}>
      {tags.map((tag) => (
        <Chip label={tag} key={tag} size="small" />
      ))}
    </RowStack>
  );
};
