import { Alert, AlertTitle, Stack } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';
import { IQueryError, useListAllVersionUpgrade } from '@endorlabs/queries';
import { useFileDownload } from '@endorlabs/ui-common';
import * as CSV from '@endorlabs/utils/encoding/csv';

import {
  ExportResourceColumn,
  FormExportResource,
  FormExportResourceFieldValues,
} from '../../../../components';
import { getFixedFindingCountForSeverity } from '../../utils/getFixedFindingsBySeverity';
import { RemediationsExportDialogProps } from './types';

type RemediationExportColumn = {
  uuid?: string;
  dependencyName?: string;
  fromVersion?: string;
  packageVersionName?: string;
  projectName?: string;
  remediationRisk?: string;
  toVersion?: string;
  versionAge?: string;
} & {
  [key in `${SpecFindingLevel}`]?: number;
};

const mapRemediationsResponseToExportColumns = (
  versionUpgrades: VersionUpgradeResource[]
): RemediationExportColumn[] => {
  return versionUpgrades
    .map((upgrade) => {
      return {
        uuid: upgrade.uuid,
        dependencyName: upgrade?.spec?.upgrade_info?.direct_dependency_package,
        packageVersionName: upgrade?.spec?.upgrade_info?.root_package_version,
        projectName: upgrade?.spec?.upgrade_info?.project,
        fromVersion: upgrade?.spec?.upgrade_info?.from_version,
        toVersion: upgrade?.spec?.upgrade_info?.to_version,
        remediationRisk: upgrade?.spec?.upgrade_info?.upgrade_risk,
        versionAge: upgrade?.spec?.upgrade_info?.from_version_publish_time,
        FINDING_LEVEL_CRITICAL: getFixedFindingCountForSeverity(
          upgrade?.spec?.upgrade_info,
          SpecFindingLevel.Critical
        ),
        FINDING_LEVEL_HIGH: getFixedFindingCountForSeverity(
          upgrade?.spec?.upgrade_info,
          SpecFindingLevel.High
        ),
        FINDING_LEVEL_MEDIUM: getFixedFindingCountForSeverity(
          upgrade?.spec?.upgrade_info,
          SpecFindingLevel.Medium
        ),
        FINDING_LEVEL_LOW: getFixedFindingCountForSeverity(
          upgrade?.spec?.upgrade_info,
          SpecFindingLevel.Low
        ),
      } satisfies RemediationExportColumn;
    })
    .sort((a, b) =>
      (a.dependencyName ?? '').localeCompare(b.dependencyName ?? '')
    );
};

const REMEDIATION_EXPORT_COLUMNS = (
  [
    // Remediation Columns
    { key: 'uuid', label: 'UUID' },
    { key: 'dependencyName', label: 'Dependency' },
    { key: 'packageVersionName', label: 'Affected Package' },
    { key: 'fromVersion', label: 'Upgrade From Version' },
    { key: 'toVersion', label: 'Upgrade To Version' },
    { key: 'remediationRisk', label: 'Remediation Risk' },
    { key: 'versionAge', label: 'Version Age(Days)' },
    { key: 'projectName', label: 'Project' },
    {
      key: 'FINDING_LEVEL_CRITICAL',
      label: 'Fixed Critical Findings',
    },
    {
      key: 'FINDING_LEVEL_HIGH',
      label: 'Fixed High Findings',
    },
    {
      key: 'FINDING_LEVEL_MEDIUM',
      label: 'Fixed Medium Findings',
    },
    {
      key: 'FINDING_LEVEL_LOW',
      label: 'Fixed Low Findings',
    },
  ] satisfies ExportResourceColumn<keyof RemediationExportColumn>[]
).map((column) => ({ ...column, isDefault: true }));

export const RemediationsExportDialogContent = (
  props: RemediationsExportDialogProps
) => {
  const { filter, namespace, onClose } = props;
  const isCancelled = useRef(false);
  const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
  const [exportError, setExportError] = useState<IQueryError | null>(null);

  const qListAllUpgrades = useListAllVersionUpgrade(
    namespace,
    { filter, page_size: 500 },
    // HACK: query is disabled, but manually invoked
    { enabled: false }
  );

  const [_, downloadData] = useFileDownload({
    filetype: 'csv',
    filename: props.downloadProps?.filename ?? 'remediations-export.csv',
  });

  const handleExportRemediations = useCallback(
    (values: FormExportResourceFieldValues) => {
      setExportError(null);

      const selectedColumns = values.columns;
      if (!selectedColumns.length) {
        // TODO: handle edge case
        return;
      }

      setIsDownloadingExportData(true);

      // wrap promise with loading/error handling
      qListAllUpgrades
        .refetch()
        .then((result) => {
          // Exit without download if cancelled
          if (isCancelled.current) return;

          const remediations = mapRemediationsResponseToExportColumns(
            result.data ?? []
          );

          // convert to CSV
          const output = CSV.stringify(remediations, null, {
            headers: selectedColumns,
          });

          downloadData(output);
        })
        .catch((error) => {
          setExportError(error);
        })
        .finally(() => {
          setIsDownloadingExportData(false);
        });
    },
    [downloadData, qListAllUpgrades]
  );

  const handleCancel = useCallback(() => {
    isCancelled.current = true;

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Stack spacing={4}>
      <FormExportResource
        columns={REMEDIATION_EXPORT_COLUMNS}
        isLoading={isDownloadingExportData}
        onSubmit={handleExportRemediations}
        onCancel={handleCancel}
      />

      {exportError && (
        <Alert severity="error">
          <AlertTitle>
            <FM defaultMessage="Unable to Export Remediations" />
          </AlertTitle>

          {exportError.response.data?.message}
        </Alert>
      )}
    </Stack>
  );
};
