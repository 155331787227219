import { Theme } from '@mui/material';

export const getLinkOverrides = ({ palette }: Theme) => {
  return {
    MuiLink: {
      defaultProps: {
        color: 'text.link',
        underline: 'hover',
      },

      styleOverrides: {
        root: {
          '&.Link-secondary': {
            color: 'inherit',

            '&.Mui-focusVisible, &:hover': {
              color: palette.text.link,
              textDecoration: 'underline',

              '& .MuiTypography-root': {
                textDecoration: 'underline',
              },
            },
          },
        },
      },
    },
  };
};
