import { FormLabel, Stack, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { SECURE_FIELD_REDACTED_VALUE } from '@endorlabs/endor-core/api';
import {
  ControlledTextField,
  REGEX_URL_VALIDATION,
} from '@endorlabs/ui-common';

import { FormUpsertNotificationTargetFieldValues } from './FormUpsertNotificationTarget/types';

const WEBHOOK_URL_MAX_LENGTH = 4_000;

type SlackConfigFieldValues = Exclude<
  FormUpsertNotificationTargetFieldValues['spec']['action']['slack_config'],
  undefined
>;

type WebhookActionTypeFieldValues = {
  spec: {
    action: {
      slack_config: Pick<SlackConfigFieldValues, 'webhook_url'>;
    };
  };
};

export const SlackActionTypeFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control } = useFormContext<WebhookActionTypeFieldValues>();

  /**
   * Custom validation added to handle the webhook url, stored as secure value
   */
  const webhookUrlValidate = useCallback(
    (value: unknown) => {
      if (!value || 'string' !== typeof value || value.length < 1) {
        return fm({
          defaultMessage: 'A non-empty URL is required',
        });
      }

      if (value.length > WEBHOOK_URL_MAX_LENGTH) {
        return fm(
          {
            defaultMessage:
              'The URL must be {maxLength, number} characters or less',
          },
          {
            maxLength: WEBHOOK_URL_MAX_LENGTH,
          }
        );
      }

      const isRedactedValue = value === SECURE_FIELD_REDACTED_VALUE;
      if (isRedactedValue) {
        return true;
      }

      const isValidUrl = REGEX_URL_VALIDATION.test(value);

      if (!isValidUrl) {
        return fm({
          defaultMessage: 'Must be a valid URL',
        });
      }

      return true;
    },
    [fm]
  );

  return (
    <Stack spacing={space.sm}>
      <Stack
        component="fieldset"
        spacing={space.sm}
        sx={{ margin: 0, padding: 0, border: 'none ' }}
      >
        <FormLabel component="legend">
          <Typography variant="body1" color="text.primary">
            <FM defaultMessage="Slack Configuration" />
          </Typography>
          {/* add description if needed <Typography variant="body2">description</Typography> */}
        </FormLabel>

        <ControlledTextField
          control={control}
          label={fm({
            defaultMessage: 'Webhook URL',
          })}
          name="spec.action.slack_config.webhook_url"
          placeholder={fm({
            defaultMessage: 'e.g. https://hooks.slack.com/...',
          })}
          required
          rules={{
            validate: webhookUrlValidate,
          }}
          type="password"
        />
      </Stack>
    </Stack>
  );
};
