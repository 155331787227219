import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '../../../components';
import {
  FormUpsertSecretRule,
  FormUpsertSecretRuleProps,
} from './FormUpsertSecretRule';

export interface SecretRuleUpsertDialogProps
  extends Pick<SimpleDialogProps, 'open' | 'onClose'>,
    FormUpsertSecretRuleProps {}

/**
 * Dialog Wrapper around Auth Policy Upsert Form
 */
export const SecretRuleUpsertDialog = ({
  open,
  onClose,
  ...props
}: SecretRuleUpsertDialogProps) => {
  const isEdit = Boolean(props.secretRule);
  const isSystem = props.secretRule?.tenant_meta.namespace === 'system';
  const editHeader = isSystem ? (
    <FM defaultMessage="View Secret Rule" />
  ) : (
    <FM defaultMessage="Update Secret Rule" />
  );

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      titleText={isEdit ? editHeader : <FM defaultMessage="Add Secrets Rule" />}
    >
      <FormUpsertSecretRule {...props} />
    </SimpleDialog>
  );
};
