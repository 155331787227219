import { get as _get } from 'lodash-es';

import { SpecFindingLevel } from '@endorlabs/api_client';

const FindingSeverityWeights = [
  SpecFindingLevel.Unspecified,
  SpecFindingLevel.Low,
  SpecFindingLevel.Medium,
  SpecFindingLevel.High,
  SpecFindingLevel.Critical,
];

const compareFindingSeverity = (a: SpecFindingLevel, b: SpecFindingLevel) => {
  const weightA = Math.max(0, FindingSeverityWeights.indexOf(a));
  const weightB = Math.max(0, FindingSeverityWeights.indexOf(b));
  return weightB - weightA;
};

/**
 * Given an array of items containing a
 * @param items
 * @param propertyKey
 * @returns
 */
export const sortByFindingSeverity = <T extends object>(
  items: T[],
  propertyKey: keyof T | `${string}.${string}`,
  secondarySort?: (a: T, b: T) => number
): T[] => {
  return (
    items
      // make a shallow copy of the source before sorting
      .slice()
      // sort descending, by severity
      .sort((a, b) => {
        const severityA = _get(
          a,
          propertyKey,
          SpecFindingLevel.Unspecified
        ) as SpecFindingLevel;
        const severityB = _get(
          b,
          propertyKey,
          SpecFindingLevel.Unspecified
        ) as SpecFindingLevel;

        const result = compareFindingSeverity(severityA, severityB);

        if (result === 0 && secondarySort) {
          return secondarySort(a, b);
        }

        return result;
      })
  );
};
