import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType } from '@endorlabs/api_client';
import { PolicyResource, useListPolicyTemplates } from '@endorlabs/queries';
import { PolicyOnboardingPopover } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { PolicyUmbrellaTypes } from '../types';
import { ExceptionPolicyReasonFields } from './ExceptionPolicyReasonFields';
import { PolicyNameFields } from './PolicyNameFields';
import { PolicyProjectsDrawerButton } from './PolicyProjectsDrawerButton';
import { PolicyRuleFields } from './PolicyRuleFields';
import { PolicyScopeFields } from './PolicyScopeFields';
import { StepAccordion } from './StepAccordion';
import { StepHeaderButton } from './StepHeaderButton';

interface ExceptionPolicyStepsProps {
  policy?: PolicyResource;
}

/**
 * Numbered steps and form fields to create an Exception policy
 */
export const ExceptionPolicySteps = ({ policy }: ExceptionPolicyStepsProps) => {
  const { space } = useTheme();

  const [expandedSteps, setExpandedSteps] = useState([0, 1, 2, 3]);
  const { activeNamespace } = useAuthInfo();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleToggle = useCallback(
    (stepNum: number) => {
      expandedSteps.includes(stepNum)
        ? setExpandedSteps(expandedSteps.filter((s) => s !== stepNum))
        : setExpandedSteps(expandedSteps.concat([stepNum]));
    },
    [expandedSteps]
  );

  const qExceptionPolicyTemplates = useListPolicyTemplates(
    activeNamespace,
    undefined,
    {
      filter: `spec.policy_type==${PolicyPolicyType.Exception}`,
    }
  );

  const exceptionPolicyTemplates =
    qExceptionPolicyTemplates.data?.list?.objects ?? [];

  return (
    <>
      <PolicyOnboardingPopover
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />

      {/* ===== EXCEPTION CRITERIA ===== */}
      <StepAccordion
        expanded={expandedSteps.includes(0)}
        handleToggle={handleToggle}
        stepNumber={0}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={0}
            text={<FM defaultMessage="Define Exception Criteria" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack alignItems="flex-start" spacing={space.md}>
            <PolicyRuleFields
              policy={policy}
              policyTemplates={exceptionPolicyTemplates}
              policyUmbrellaType={PolicyUmbrellaTypes.EXCEPTION}
            />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      {/* ===== EXCEPTION REASON ===== */}
      <StepAccordion
        expanded={expandedSteps.includes(1)}
        handleToggle={handleToggle}
        stepNumber={1}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={1}
            text={<FM defaultMessage="Choose a Reason" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="flex-start"
            spacing={space.md}
            sx={{ paddingTop: space.sm }}
          >
            <ExceptionPolicyReasonFields policy={policy} />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      {/* ===== POLICY SCOPE ===== */}
      <StepAccordion
        expanded={expandedSteps.includes(2)}
        handleToggle={handleToggle}
        stepNumber={2}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={2}
            text={<FM defaultMessage="Assign Scope" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            alignItems="flex-start"
            spacing={space.md}
            sx={{ paddingTop: space.sm }}
          >
            <PolicyScopeFields />

            <PolicyProjectsDrawerButton />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      {/* ===== NAME POLICY ===== */}
      <StepAccordion
        expanded={expandedSteps.includes(3)}
        handleToggle={handleToggle}
        stepNumber={3}
      >
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={3}
            text={<FM defaultMessage="Name Your Exception Policy" />}
          />
        </AccordionSummary>
        <AccordionDetails>
          <PolicyNameFields />
        </AccordionDetails>
      </StepAccordion>
    </>
  );
};
