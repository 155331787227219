import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ToolPatternResource, useListToolPatterns } from '@endorlabs/queries';
import {
  CiCdToolPatternDisplay,
  ControlledLabelsField,
  ControlledTextField,
  IconX,
  ToolLogoDisplay,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { getPoliciesPath, useFullMatch } from '../../../routes';
import { PolicyTabNames } from '../hooks';

export const ViewCiCdToolRulesPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { activeUuid: toolUuid },
  } = useFullMatch();
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { space } = useTheme();

  const ciCdPoliciesUrl = getPoliciesPath({
    tenantName,
    section: PolicyTabNames.CICD,
  });

  const onCancel = () => {
    navigate({
      to: ciCdPoliciesUrl,
    });
  };

  const qToolPattern = useListToolPatterns(
    tenantName,
    {},
    { filter: `uuid==${toolUuid}` }
  );

  const toolName = useMemo(() => {
    return qToolPattern.data?.list?.objects[0]?.meta.name ?? '';
  }, [qToolPattern.data?.list?.objects]);

  const tool = qToolPattern.data?.list?.objects[0];

  const formMethods = useForm<ToolPatternResource>({
    mode: 'onTouched',
    defaultValues: tool,
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset(tool);
  }, [tool, reset]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.sm}>
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              label: <FM defaultMessage="All CI/CD Tool Rules" />,
              url: ciCdPoliciesUrl,
            },
          ]}
          title={
            <FM
              defaultMessage="View CI/CD Rule {toolName}"
              values={{ toolName: tool?.meta.name }}
            />
          }
          metadata={{ summary: [] }}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <FormProvider {...formMethods}>
              <form id="FormToolPattern">
                <Stack spacing={space.lg} width="50%">
                  <ControlledTextField
                    control={formMethods.control}
                    inputProps={{ readOnly: true }}
                    label={<FM defaultMessage="Tool Name" />}
                    name="meta.name"
                    required
                  />

                  {/* This will be replaced with a form field for creating/editing tool rules */}
                  <Stack alignItems="start">
                    <Typography
                      marginBottom={space.xs}
                      fontSize="0.6rem"
                      variant="h5"
                    >
                      <FM defaultMessage="Icon" />
                    </Typography>
                    <ToolLogoDisplay
                      toolName={toolName}
                      sx={{ fontSize: '3em' }}
                    />
                  </Stack>

                  <ControlledTextField
                    control={formMethods.control}
                    inputProps={{ readOnly: true }}
                    label={<FM defaultMessage="Description" />}
                    name="meta.description"
                    placeholder="Description not available"
                    multiline
                  />

                  <ControlledLabelsField
                    chipProps={{
                      variant: 'outlined',
                      color: 'primary',
                      onDelete: undefined,
                    }}
                    control={formMethods.control}
                    inputProps={{ readOnly: true }}
                    label={<FM defaultMessage="Categories" />}
                    name="spec.categories"
                  />
                  <CiCdToolPatternDisplay tool={tool} />
                </Stack>
              </form>
            </FormProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
