import { RowData } from '@tanstack/react-table';

import { DataTableColumnDef } from '../types';

const getColumnId = <T extends RowData>(column: DataTableColumnDef<T>) =>
  (column.id ?? column.accessorKey) as string;

const getColumnOrder = <T extends RowData>(
  columns: DataTableColumnDef<T>[],
  staticColumnOrder: string[],
  preferredColumnOrder: string[]
) => {
  const columnIds = columns.map(getColumnId);
  const remainingIds = new Set(columnIds);

  const columnOrder: string[] = [];

  for (const id of staticColumnOrder) {
    if (remainingIds.has(id)) {
      columnOrder.push(id);
      remainingIds.delete(id);
    }
  }

  for (const id of preferredColumnOrder) {
    if (remainingIds.has(id)) {
      columnOrder.push(id);
      remainingIds.delete(id);
    }
  }

  for (const id of columnIds) {
    if (remainingIds.has(id)) {
      columnOrder.push(id);
      remainingIds.delete(id);
    }
  }

  return columnOrder;
};

/**
 * For a given set of columns, and a preferred order, return the column order,
 * and the orders for columns where enableHiding or enableReorder are set.
 */
export const buildDataTableColumnOrder = <T extends RowData>(
  columns: DataTableColumnDef<T>[],
  preferredColumnOrder: string[] = []
) => {
  const hideableColumns: string[] = [];
  const reorderableColumns: string[] = [];
  const staticColumns: string[] = [];

  for (const c of columns) {
    const columnId = getColumnId(c);

    if (c.enableReorder) {
      reorderableColumns.push(columnId);
      continue;
    }

    staticColumns.push(columnId);

    if (c.enableHiding) {
      hideableColumns.push(columnId);
    }
  }

  const columnOrder = getColumnOrder(
    columns,
    staticColumns,
    preferredColumnOrder
  );

  const reorderableColumnIds = new Set(reorderableColumns);

  const reorderableColumnOrder = columnOrder.filter((id) =>
    reorderableColumnIds.has(id)
  );

  return {
    columnOrder,
    hideableColumnOrder: hideableColumns,
    reorderableColumnOrder,
  };
};
