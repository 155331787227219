import { Stack, useTheme } from '@mui/material';
import { get as _get } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import {
  PolicyTemplateParameter,
  PolicyTemplateParameterValues,
} from '@endorlabs/api_client';
import { PolicyResource, PolicyTemplateResource } from '@endorlabs/queries';
import {
  ExclusiveToggleButtonGroup,
  useExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import {
  PolicyRuleCreationModes,
  PolicyTemplateParameterField,
  PolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';
import { FindingPolicyFields } from './FindingPolicyFields';
import { PolicyRulesFromCodeEditor } from './PolicyRulesFromCodeEditor';
import { PolicyRulesFromTemplateFields } from './PolicyRulesFromTemplateFields';

interface PolicyRuleFieldsProps {
  activeTemplate?: PolicyTemplateResource;
  policy?: PolicyResource;
  policyTemplates: PolicyTemplateResource[];
  policyUmbrellaType: PolicyUmbrellaType;
}

/**
 * Determines final parameters for form display based on policy template and existing policy under edit
 */
export const PolicyRuleFields = ({
  activeTemplate: policyActiveTemplate,
  policy,
  policyTemplates = [],
  policyUmbrellaType,
}: PolicyRuleFieldsProps) => {
  const { space } = useTheme();
  const { control, setValue } = useFormContext();

  // Watch for changes to the template_uuid field & update template fields accordingly
  const activeTemplateUuid = useWatch({ control, name: 'spec.template_uuid' });
  const activeTemplate = useMemo(
    () =>
      policyActiveTemplate ??
      policyTemplates.find((p) => p?.uuid === activeTemplateUuid),
    [activeTemplateUuid, policyActiveTemplate, policyTemplates]
  );

  /**
   * If editing a policy, augment template parameters with policy values
   */
  const isEditing = Boolean(policy);
  const parameterValues: PolicyTemplateParameterField[] = useMemo(() => {
    return augmentParametersWithPolicyValues(
      policy?.spec.template_values ?? {},
      activeTemplate?.spec.template_parameters ?? []
    );
  }, [activeTemplate, policy]);

  // Reset template values when parameters change
  useEffect(() => {
    setValue(
      'spec.template_values',

      parameterValues.reduce((acc, param) => {
        acc[param.value] = { values: param.values ?? [] };
        return acc;
      }, {} as Record<string, PolicyTemplateParameterValues>)
    );
  }, [activeTemplate, parameterValues, setValue]);

  /* Derive finding values from policy or template */
  const findingValues = useMemo(
    () => (isEditing ? policy?.spec.finding : activeTemplate?.spec.finding),
    [policy, activeTemplate, isEditing]
  );

  const {
    getToggleButtonGroupProps,
    value: creationModeValue,
    setValue: setCreationModeValue,
  } = useExclusiveToggleButtonGroup(PolicyRuleCreationModes.TEMPLATE, [
    {
      value: PolicyRuleCreationModes.TEMPLATE,
      children: <FM defaultMessage="From Template" />,
    },
    // TODO: enable rego file from upload
    // {
    //   value: PolicyRuleCreationModes.FILE,
    //   children: <FM defaultMessage="From File" />,
    // },
    {
      value: PolicyRuleCreationModes.EDITOR,
      children: <FM defaultMessage="From Scratch" />,
      // NOTE: disable the code editor when creating from template
      disabled: !!policyActiveTemplate,
    },
  ]);

  useEffect(() => {
    // when editing an existing policy, set the creation mode based on the policy
    if (policy?.spec.rule && !policy?.spec.template_uuid) {
      setCreationModeValue(PolicyRuleCreationModes.EDITOR);
    }
  }, [policy, setCreationModeValue]);

  return (
    <Stack alignItems="flex-start" spacing={space.sm} width="100%">
      <ExclusiveToggleButtonGroup
        {...getToggleButtonGroupProps()}
        disabled={isEditing}
        size="small"
      />

      {creationModeValue === PolicyRuleCreationModes.TEMPLATE && (
        <PolicyRulesFromTemplateFields
          parameters={parameterValues}
          policy={policy}
          policyTemplates={policyTemplates}
        />
      )}

      {
        // TODO: enable rego file from upload
        // value === PolicyRuleCreationModes.FILE && <PolicyRulesFromFileUpload />
      }

      {creationModeValue === PolicyRuleCreationModes.EDITOR && (
        <PolicyRulesFromCodeEditor policyUmbrellaType={policyUmbrellaType} />
      )}

      {policyUmbrellaType === PolicyUmbrellaTypes.FINDING && (
        <FindingPolicyFields finding={findingValues} />
      )}
    </Stack>
  );
};

/**
 * Adds policy values to template parameters if applicable
 */
const augmentParametersWithPolicyValues = (
  policyValues: { [key: string]: PolicyTemplateParameterValues },
  policyParameters: PolicyTemplateParameter[]
): PolicyTemplateParameterField[] => {
  return policyParameters.map((param) => {
    const values = _get(policyValues, [param.value, 'values'], []);
    return { ...param, values };
  });
};
