import {
  ColumnSort,
  getSortedRowModel,
  RowData,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

export const useDataTableSorting = <T extends RowData>(
  enableColumnSort: boolean,
  onColumnSort?: (columnSort?: ColumnSort) => void
) => {
  const [sorting, setSortingState] = useState<SortingState>([]);

  const tableSortingProps: Partial<TableOptions<T>> = useMemo(() => {
    if (onColumnSort) {
      return {
        enableSorting: true,
        onSortingChange: (updater) => {
          // resolve updater
          const next =
            'function' === typeof updater ? updater(sorting) : updater;

          setSortingState(next);

          if (onColumnSort) {
            onColumnSort(next[0]);
          }
        },
        state: {
          sorting,
        },
      };
    }

    if (enableColumnSort) {
      return {
        enableSorting: true,
        onSortingChange: setSortingState,
        getSortedRowModel: getSortedRowModel(),
        state: {
          sorting,
        },
      };
    }

    return {
      enableSorting: false,
    };
  }, [enableColumnSort, onColumnSort, sorting]);

  return tableSortingProps;
};
