import { FormattedMessage as FM } from 'react-intl';

import { DetailDrawerSection } from '../../../../components/DetailDrawer/DetailDrawerSection';
import { DetailDrawerSectionStack } from '../../../../components/DetailDrawer/DetailDrawerSectionStack';
import { FindingsDetailSASTSectionProps } from '../../types';
import { FindingSASTDetailSection } from './FindingSASTDetailSection';
import { FindingSASTMetadataSection } from './FindingSASTMetadataSection';
import { FindingSASTRiskSection } from './FindingSASTRiskSection';

export const FindingInfoDrawer = ({
  sastRuleForFinding,
  finding,
  isLoading,
  namespace,
  project,
}: FindingsDetailSASTSectionProps) => {
  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection>
        <FindingSASTRiskSection
          sastRuleForFinding={sastRuleForFinding}
          isLoading={isLoading}
          finding={finding}
          namespace={namespace}
        />
      </DetailDrawerSection>
      <DetailDrawerSection title={<FM defaultMessage={'Details'} />}>
        <FindingSASTDetailSection
          sastRuleForFinding={sastRuleForFinding}
          isLoading={isLoading}
          finding={finding}
          namespace={namespace}
          project={project}
        />
      </DetailDrawerSection>
      <DetailDrawerSection title={<FM defaultMessage="Metadata" />}>
        <FindingSASTMetadataSection
          sastRuleForFinding={sastRuleForFinding}
          isLoading={isLoading}
          finding={finding}
          namespace={namespace}
        />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
