import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Control } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { PackageManagerResource } from '@endorlabs/queries';
import { ControlledCheckbox, IconChevronDown } from '@endorlabs/ui-common';

type PackageManagerAdvancedFieldValues = Pick<
  PackageManagerResource,
  'propagate'
>;

export const PackageManagerAdvancedFields = ({
  control,
}: {
  control: Control<PackageManagerAdvancedFieldValues>;
}) => {
  const { palette, typography } = useTheme();

  return (
    <Accordion
      disableGutters // prevent margin on expanded state
      elevation={0}
      sx={{
        '& .MuiAccordionSummary-root': {
          justifyContent: 'flex-start',
          gap: 2,
          '& .MuiAccordionSummary-content': {
            flexGrow: 0,
          },
        },
      }}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>
        <Typography
          sx={{
            ...typography.button,
            color: palette.text.primary,
          }}
        >
          <FM defaultMessage="Advanced" />
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack direction="column" spacing={6}>
          <FormControlLabel
            label={
              <FM defaultMessage="Propagate this package manager to all child namespaces." />
            }
            control={
              <ControlledCheckbox
                control={control}
                // @ts-expect-error field value type does not expect boolean?
                defaultValue={false}
                name="propagate"
              />
            }
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
