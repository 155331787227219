import { QueryKey, useQuery } from 'react-query';

import {
  CustomTemplateServiceApi,
  V1CustomTemplate,
} from '@endorlabs/api_client';

import { ResourceQueryOptions } from './types';
import { getClientConfiguration } from './utils';

export interface CustomTemplateReadParams {
  customTemplateType: string;
}

type GetCustomTemplateOptions = ResourceQueryOptions<V1CustomTemplate>;

const BASE_KEY = 'v1/custom-templates';
const QK = {
  record: (customTemplateType: string): QueryKey =>
    [BASE_KEY, 'get', customTemplateType] as const,
};
export const CustomTemplateQueryKeys = QK;

const getApiService = () =>
  new CustomTemplateServiceApi(getClientConfiguration());

export const getDefaultCustomTemplate = async ({
  customTemplateType,
}: CustomTemplateReadParams) => {
  const api = getApiService();
  const resp = await api.customTemplateServiceGetDefaultTemplate(
    customTemplateType
  );
  return resp.data as V1CustomTemplate;
};

export const useGetDefaultCustomTemplate = (
  params: CustomTemplateReadParams,
  opts: GetCustomTemplateOptions = {}
) => {
  return useQuery(
    QK.record(params.customTemplateType),
    () => getDefaultCustomTemplate(params),
    opts
  );
};
