import {
  Collapse,
  ListItem,
  ListItemButton,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

import { NumberDisplay } from '@endorlabs/ui-common';

export interface PageNavigationListItemProps {
  isHidden?: boolean;
  isSelected?: boolean;
  label: ReactNode;
  navKey: string;
  onClick?: (s: string) => void;
  suffix?: number | null;
  value?: string;
}

export const PageNavigationListItem = ({
  isHidden,
  isSelected = false,
  label,
  navKey,
  onClick,
  suffix,
}: PageNavigationListItemProps) => {
  const { palette, space, spacing } = useTheme();

  const listItem = (
    <ListItem disablePadding sx={{ minWidth: spacing(50) }}>
      <ListItemButton
        onClick={onClick ? () => onClick(navKey) : undefined}
        selected={isSelected}
        sx={{
          borderRadius: '96px',
          justifyContent: 'space-between',
          ':not(.Mui-selected) .NumberDisplay-root': {
            color: 'text.secondary',
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: palette.brand.main,
            color: palette.getContrastText(palette.brand.main),
          },
        }}
      >
        <Typography variant="button" noWrap>
          {label}
        </Typography>

        {suffix === undefined ? (
          <Skeleton width={16} height={24} sx={{ ml: space.md }} />
        ) : suffix === null ? null : (
          <NumberDisplay
            typographyProps={{
              ml: space.md,
              variant: 'button',
            }}
            value={suffix}
          />
        )}
      </ListItemButton>
    </ListItem>
  );

  return isHidden === undefined ? (
    listItem
  ) : (
    <Collapse appear={false} in={!isHidden} mountOnEnter>
      {listItem}
    </Collapse>
  );
};
