import { keyBy as _keyBy } from 'lodash-es';
import { useMemo } from 'react';

import { GraphNodeId } from '@endorlabs/utils/graph';

import { useGraphData } from '../providers';

export const useGraphDataNode = (nodeId: GraphNodeId) => {
  const { graph, nodes } = useGraphData();

  const { children, node } = useMemo(() => {
    const nodesById = _keyBy(nodes, 'id');
    const node = nodesById[nodeId] ?? { id: nodeId };

    const children = (graph[nodeId] ?? [])
      .map((id) => {
        return nodesById[id] ?? { id };
      })
      .sort((a, b) => a.id.localeCompare(b.id));

    return { children, node };
  }, [graph, nodeId, nodes]);

  return { children, node };
};
