import { useContext } from 'react';

import { GraphDataContext } from './GraphData';

export const useGraphData = () => {
  const context = useContext(GraphDataContext);

  // Ensure filter context is present for consumer
  if (!context) {
    throw new Error('Missing provider for GraphDataContext');
  }

  return context;
};
