import { V1ToolChainVersion } from '@endorlabs/api_client';

export enum ToolInfoType {
  ToolChainVersion = 'TOOL_INFO_TYPE_TOOL_CHAIN_VERSION',
  ToolChainVersionMap = 'TOOL_INFO_TYPE_TOOL_CHAIN_VERSION_MAP',
  ToolList = 'TOOL_INFO_TYPE_TOOL_LIST',
}

export type ToolchainToolInfo = {
  os: string;
  arch: string;
  toolchain: string;
  tool: string;
} & (ToolChainVersionToolInfo | ToolChainVersionMapToolInfo | ToolListToolInfo);

type ToolChainVersionToolInfo = {
  type: ToolInfoType.ToolChainVersion;
  value: {
    version: V1ToolChainVersion;
  };
};

type ToolChainVersionMapToolInfo = {
  type: ToolInfoType.ToolChainVersionMap;
  value: {
    key: string;
    version: V1ToolChainVersion;
  };
};

type ToolListToolInfo = {
  type: ToolInfoType.ToolList;
  value: {
    key: string;
  };
};
