import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  AuthorizationPolicyIdentitySource,
  AuthorizationPolicyIdentitySourceType,
} from '@endorlabs/endor-core/AuthorizationPolicy';
import { IdentityProviderResource } from '@endorlabs/queries';
import {
  IconLock,
  IconMail,
  IconUnlock,
  ImgIconLogoAWS,
  ImgIconLogoGoogle,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
  ImgIconLogoMarkGoogleCloud,
  ImgIconLogoMicrosoft,
  RowStack,
} from '@endorlabs/ui-common';

const AuthPolicyIdentitySourceTypeIcon: Record<
  AuthorizationPolicyIdentitySourceType,
  ReactNode
> = {
  [AuthorizationPolicyIdentitySourceType.APIKey]: <IconLock />,
  [AuthorizationPolicyIdentitySourceType.Azure]: <ImgIconLogoMicrosoft />,
  [AuthorizationPolicyIdentitySourceType.Endor]: <IconMail />,
  [AuthorizationPolicyIdentitySourceType.GCP]: <ImgIconLogoMarkGoogleCloud />,
  [AuthorizationPolicyIdentitySourceType.GitHub]: <ImgIconLogoMarkGitHub />,
  [AuthorizationPolicyIdentitySourceType.GitHubAction]: (
    <ImgIconLogoMarkGitHub />
  ),
  [AuthorizationPolicyIdentitySourceType.AWS]: <ImgIconLogoAWS />,
  [AuthorizationPolicyIdentitySourceType.GitLab]: <ImgIconLogoMarkGitLab />,
  [AuthorizationPolicyIdentitySourceType.Google]: <ImgIconLogoGoogle />,
  [AuthorizationPolicyIdentitySourceType.IdentityProvider]: <IconUnlock />,
  [AuthorizationPolicyIdentitySourceType.Unknown]: <IconLock />,
};

const AuthPolicyIdentitySourceTypeLabel: Record<
  AuthorizationPolicyIdentitySourceType,
  ReactNode
> = {
  [AuthorizationPolicyIdentitySourceType.APIKey]: (
    <FM defaultMessage="API Key" />
  ),
  [AuthorizationPolicyIdentitySourceType.Azure]: <FM defaultMessage="Azure" />,
  [AuthorizationPolicyIdentitySourceType.GCP]: (
    <FM defaultMessage="Google Cloud" />
  ),
  [AuthorizationPolicyIdentitySourceType.Endor]: <FM defaultMessage="Email" />,
  [AuthorizationPolicyIdentitySourceType.GitHub]: (
    <FM defaultMessage="GitHub" />
  ),
  [AuthorizationPolicyIdentitySourceType.GitHubAction]: (
    <FM defaultMessage="GitHub Action" />
  ),
  [AuthorizationPolicyIdentitySourceType.AWS]: <FM defaultMessage="AWS Role" />,
  [AuthorizationPolicyIdentitySourceType.GitLab]: (
    <FM defaultMessage="GitLab" />
  ),
  [AuthorizationPolicyIdentitySourceType.Google]: (
    <FM defaultMessage="Google" />
  ),
  [AuthorizationPolicyIdentitySourceType.IdentityProvider]: (
    <FM defaultMessage="Custom Identity Provider" />
  ),
  [AuthorizationPolicyIdentitySourceType.Unknown]: (
    <FM defaultMessage="Unknown Provider" />
  ),
};

const getIdentitySourceLabel = (
  identitySource: AuthorizationPolicyIdentitySource,
  identityProvider?: IdentityProviderResource
) => {
  if (identitySource.value === identityProvider?.uuid) {
    return identityProvider.meta.name;
  }

  const label = AuthPolicyIdentitySourceTypeLabel[identitySource.type];
  return label ?? identitySource.type;
};

export const AuthPolicyIdentitySourceDisplay = ({
  value: identitySource,
  identityProvider,
}: {
  value: AuthorizationPolicyIdentitySource | undefined;
  identityProvider?: IdentityProviderResource;
}) => {
  if (!identitySource) {
    return (
      <Typography>
        <FM defaultMessage="Unknown Provider" />
      </Typography>
    );
  }

  const icon = AuthPolicyIdentitySourceTypeIcon[identitySource.type] ?? (
    <IconLock />
  );
  const label = getIdentitySourceLabel(identitySource, identityProvider);

  return (
    <RowStack>
      {icon}
      <Typography>{label}</Typography>
    </RowStack>
  );
};
