import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import {
  defineMessages,
  FormattedList,
  FormattedMessage as FM,
  MessageDescriptor,
} from 'react-intl';

import {
  DependencyMetadataReachabilityType,
  V1DependencyMetadataSpec,
  V1DependencyScope,
  V1GroupResponse,
  V1LicenseInfo,
} from '@endorlabs/api_client';
import { QueryPackageVersionsResponseObject } from '@endorlabs/queries';
import { CopyToClipboardButton } from '@endorlabs/ui-common';

const column1 = [
  'dependenciesSelf',
  'dependenciesOss',
  'visibility',
  'repo_name',
  'pinned',
  'reachable',
];
const column2 = ['scope', 'callgraph_available', 'dependents'];

type DependencyMetadataSpecStub = {
  dependency_data?: Pick<
    Exclude<V1DependencyMetadataSpec['dependency_data'], undefined>,
    'direct' | 'namespace' | 'pinned' | 'reachable' | 'repo_name' | 'scope'
  >;
  importer_data?: Pick<
    Exclude<V1DependencyMetadataSpec['importer_data'], undefined>,
    'callgraph_available'
  >;
};

interface PackageVersionDetailOverviewMetadataProps {
  dependencyMetadata?: DependencyMetadataSpecStub[];
  dependentGroups?: V1GroupResponse;
  licenses?: V1LicenseInfo[];
  packageVersion?: QueryPackageVersionsResponseObject;
}

export const PackageVersionDetailOverviewMetadata = ({
  dependencyMetadata,
  dependentGroups,
  licenses,
  packageVersion,
}: PackageVersionDetailOverviewMetadataProps) => {
  const { space } = useTheme();
  const sx = styles();

  const collectedMetadata = useMemo(() => {
    if (packageVersion && dependencyMetadata) {
      return getDepMetadataCounts(
        dependencyMetadata,
        dependentGroups,
        packageVersion
      );
    } else {
      return genBlankMetadata();
    }
  }, [dependencyMetadata, dependentGroups, packageVersion]);

  return (
    <Grid container>
      <Grid item md={6} sm={12}>
        <Stack
          component="ul"
          paddingLeft={0}
          paddingRight={space.lg}
          spacing={space.md}
          sx={sx.list}
        >
          {column1.map((dataPointKey) => {
            const dataPoints = collectedMetadata[dataPointKey];

            return (
              <Stack component="li" key={dataPointKey} spacing={space.xs}>
                <Typography component="h6" variant="h4">
                  <FM {...headings[dataPointKey]} />
                </Typography>
                <Typography variant="body2">
                  <FM {...entryMessages[dataPointKey]} values={dataPoints} />
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Grid>

      <Grid item md={6} sm={12}>
        <Stack
          component="ul"
          borderLeft={({ palette }) => `1px solid ${palette.divider}`}
          paddingLeft={space.lg}
          paddingRight={0}
          spacing={space.md}
          sx={sx.list}
        >
          {/* Display license info */}
          <Stack component="li" spacing={space.xs}>
            <Typography component="h6" variant="h4">
              <FM {...headings.license} />
            </Typography>
            <Typography variant="body2">
              {licenses?.length ? (
                <FormattedList
                  value={licenses?.map((l) => l.name)}
                  type="unit"
                />
              ) : (
                <FM defaultMessage="No license specified" />
              )}
            </Typography>
          </Stack>

          {column2.map((dataPointKey) => {
            const dataPoints = collectedMetadata[dataPointKey];

            return (
              <Stack component="li" key={dataPointKey} spacing={space.xs}>
                <Typography component="h6" variant="h4">
                  <FM {...headings[dataPointKey]} />
                </Typography>
                <Typography variant="body2">
                  <FM {...entryMessages[dataPointKey]} values={dataPoints} />
                </Typography>
              </Stack>
            );
          })}

          {packageVersion?.spec.resolved_dependencies?.resolution_timestamp && (
            <Stack component="li" spacing={space.xs}>
              <Typography component="h6" variant="h4">
                <FM defaultMessage="Resolution Timestamp" />
              </Typography>
              <Typography variant="body2">
                {
                  packageVersion?.spec.resolved_dependencies
                    ?.resolution_timestamp
                }
              </Typography>
            </Stack>
          )}

          <Stack component="li" spacing={space.xs}>
            <Typography component="h6" variant="h4">
              <FM defaultMessage="Package Version UUID" />
            </Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="body2">{packageVersion?.uuid}</Typography>
              {packageVersion && (
                <CopyToClipboardButton
                  size="small"
                  value={packageVersion?.uuid ?? ''}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  list: {
    listStyle: 'none',
    margin: 0,
  },
});

/**
 * NOTE: Order is important here
 */
const genBlankMetadata = (): Record<string, Record<string, number>> => ({
  dependenciesSelf: { direct: 0, indirect: 0 },
  dependenciesOss: { direct: 0, indirect: 0 },
  pinned: { pinned: 0, unpinned: 0 },
  scope: {
    [V1DependencyScope.Build]: 0,
    [V1DependencyScope.Test]: 0,
    [V1DependencyScope.Unspecified]: 0,
    [V1DependencyScope.Normal]: 0,
  },
  reachable: { reachable: 0, unreachable: 0, other: 0 },
  dependents: { direct: 0, indirect: 0 },
  repo_name: { has_source_control: 0, no_source_control: 0 },
  callgraph_available: { available: 0, unavailable: 0 },
  visibility: { private: 0, public: 0 },
});

const getDepMetadataCounts = (
  depMetadata: DependencyMetadataSpecStub[] = [],
  dependentGroups: V1GroupResponse = {},
  packageVersion?: QueryPackageVersionsResponseObject
) => {
  /**
   * Aggregate counts of various metadata found on DependencyMetadata
   */
  const dataFromDepMetadata = depMetadata.reduce((acc, depMetadata) => {
    const { dependency_data: data, importer_data } = depMetadata;

    if (importer_data?.callgraph_available) {
      acc.callgraph_available.available += 1;
    } else {
      acc.callgraph_available.unavailable += 1;
    }

    const dependencyColumn =
      data?.namespace === 'oss' ? acc.dependenciesOss : acc.dependenciesSelf;

    if (data?.direct) {
      dependencyColumn.direct += 1;
    } else {
      dependencyColumn.indirect += 1;
    }

    if (data?.pinned) {
      acc.pinned.pinned += 1;
    } else {
      acc.pinned.unpinned += 1;
    }

    if (data?.reachable === DependencyMetadataReachabilityType.Reachable) {
      acc.reachable.reachable += 1;
    } else if (
      data?.reachable === DependencyMetadataReachabilityType.Unreachable
    ) {
      acc.reachable.unreachable += 1;
    } else {
      acc.reachable.other += 1;
    }

    if (data?.repo_name) {
      acc.repo_name.has_source_control += 1;
    } else {
      acc.repo_name.no_source_control += 1;
    }

    if (data?.scope) {
      acc.scope[data.scope] += 1;
    }

    return acc;
  }, genBlankMetadata());

  /**
   * Aggregate counts of various metadata found on PackageVersion dependencies
   */
  const dataFromPackageVersionDeps = (
    packageVersion?.spec.resolved_dependencies?.dependencies ?? []
  ).reduce((acc, dependency) => {
    if (dependency?.public) {
      acc.visibility.public += 1;
    } else {
      acc.visibility.private += 1;
    }
    return acc;
  }, dataFromDepMetadata);

  /** Add dependent count breakdown from dependents metadata query */
  const directDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('true')
  );
  const indirectDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('false')
  );

  dataFromPackageVersionDeps.dependents.direct = directDependents
    ? directDependents[1].aggregation_count.count
    : 0;
  dataFromPackageVersionDeps.dependents.indirect = indirectDependents
    ? indirectDependents[1].aggregation_count.count
    : 0;

  return dataFromPackageVersionDeps;
};

const headings: Record<string, MessageDescriptor> = defineMessages({
  callgraph_available: { defaultMessage: 'Call Graph Available Dependencies' },
  dependents: { defaultMessage: 'Dependents' },
  dependenciesSelf: { defaultMessage: 'My Dependencies' },
  dependenciesOss: { defaultMessage: 'OSS Dependencies' },
  license: { defaultMessage: 'License' },
  pinned: { defaultMessage: 'Dependency Pinning' },
  reachable: { defaultMessage: 'Reachable Dependencies' },
  repo_name: { defaultMessage: 'Dependency Source Control Visibility' },
  scope: { defaultMessage: 'Dependency Scopes' },
  visibility: { defaultMessage: 'Dependency Visibility' },
});

const entryMessages: Record<string, MessageDescriptor> = defineMessages({
  callgraph_available: {
    defaultMessage:
      '{available, number} Available ・ {unavailable, number} Unavailable',
  },
  dependents: {
    defaultMessage: '{direct, number} Direct ・ {indirect, number} Transitive',
  },
  dependenciesSelf: {
    defaultMessage: '{direct, number} Direct ・ {indirect, number} Transitive',
  },
  dependenciesOss: {
    defaultMessage: '{direct, number} Direct ・ {indirect, number} Transitive',
  },
  pinned: {
    defaultMessage: '{pinned, number} Pinned ・ {unpinned, number} Unpinned',
  },
  reachable: {
    defaultMessage:
      '{reachable, number} Reachable ・ {unreachable, number} Unreachable ・ {other, number} Unknown',
  },
  repo_name: {
    defaultMessage:
      '{has_source_control, number} With Source Control ・ {no_source_control, number} Without Source Control',
  },
  scope: {
    defaultMessage: `{DEPENDENCY_SCOPE_TEST, number} Test ・ {DEPENDENCY_SCOPE_BUILD, number} Build ・ {DEPENDENCY_SCOPE_UNSPECIFIED, number} Unspecified ・ {DEPENDENCY_SCOPE_NORMAL, number} Normal`,
  },
  visibility: {
    defaultMessage: '{public, number} Public ・ {private, number} Private',
  },
});
