import { Box, Palette, Theme, Tooltip, useTheme } from '@mui/material';
import { isNil as _isNil } from 'lodash-es';
import { CSSProperties, ReactNode } from 'react';

import { NumberDisplay } from '../formats';

export type LinkTabCountValue = {
  tooltipLabel: ReactNode;
  severity?: 'info' | 'warning' | 'error';
  value: number;
};
export type LinkTabCountProps = {
  count?: LinkTabCountValue | number;
};
export const LinkTabCount = ({ count }: LinkTabCountProps) => {
  const theme = useTheme();

  if (_isNil(count)) return null;

  if ('number' === typeof count) {
    return <NumberDisplay value={count} />;
  }

  const sx = styles(theme, count.severity);
  return (
    <Tooltip title={count.tooltipLabel}>
      <Box sx={sx.countWrapper}>
        <NumberDisplay value={count.value} />
      </Box>
    </Tooltip>
  );
};

function styles(
  { palette, spacing }: Theme,
  color: keyof Pick<Palette, 'info' | 'warning' | 'error'> = 'info'
): Record<string, CSSProperties> {
  const paletteColor = palette[color];

  return {
    countWrapper: {
      color: paletteColor.dark,
      borderColor: paletteColor.light,
      borderRadius: spacing(1),
      borderStyle: 'solid',
      borderWidth: 1,
      padding: spacing(1, 2),
    },
  };
}
