import {
  Box,
  Drawer,
  GlobalStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useLocation, useRouter } from '@tanstack/react-location';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import {
  ErrorBoundary,
  FallbackProps as ErrorBoundaryFallbackProps,
} from 'react-error-boundary';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useFeatureFlags, useSession } from '@endorlabs/queries';
import { AppNotificationContainer, EmptyState } from '@endorlabs/ui-common';

import { Layout } from '../../constants';
import { getProductTourStyles } from '../../domains/Tour/ProductTourStyles';
import { useAuthInfo, useAuthTenantInfo } from '../../providers';
import { AppDrawerV2 } from '../AppDrawer';
import {
  InfoDrawerFallback,
  useInfoDrawer,
  useInfoDrawerStore,
} from '../InfoDrawer';
import Banner from './Banner';
interface AppLayoutProps {
  children?: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const location = useLocation();
  const router = useRouter();
  const InfoDrawerState = useInfoDrawer();
  const infoDrawerContainerRef = useRef<HTMLDivElement>(null);
  const infoDrawerContentRef = useRef(
    useInfoDrawerStore.getState().activeContent
  );
  const theme = useTheme();
  const appDrawerMinimized = useMediaQuery(
    theme.breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)
  );
  const infoDrawerMaximized = useMediaQuery(
    theme.breakpoints.down(Layout.INFO_DRAWER_MAXIMIZED_WIDTH)
  );
  const { activeNamespace, licenseInCurrentRoute } = useAuthInfo();
  const { getLastUsedTenant } = useSession();

  const tenantName =
    activeNamespace === NAMESPACES.OSS ? getLastUsedTenant() : undefined;

  const {
    isFreeTrialTenant,
    isPremiumTenant,
    tenantInfo,
    getRemainingExpiryPeriod,
  } = useAuthTenantInfo(tenantName);

  const remainingPeriodToExpiry = getRemainingExpiryPeriod();

  const { ENABLE_LICENSE_AND_BUNDLING: isLicenseAndBundlingEnabled } =
    useFeatureFlags();

  const isPageAccessAllowed = useMemo(() => {
    if (!isLicenseAndBundlingEnabled) return true;
    const { pathname, isLicense } = licenseInCurrentRoute || {
      pathname: '',
      isLicense: true,
    };
    if (router.state.location.pathname === pathname) return isLicense;
    return true;
  }, [
    isLicenseAndBundlingEnabled,
    licenseInCurrentRoute,
    router.state.location.pathname,
  ]);
  /**
   * Update InfoDrawer content as needed
   */
  useEffect(
    () =>
      useInfoDrawerStore.subscribe(
        (state) => state.activeContent,
        (activeContent) => {
          infoDrawerContentRef.current = activeContent as ReactNode;
          // scroll the info drawer to the top when content changes
          // ref will be null when drawer is initially opened
          infoDrawerContainerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      ),
    []
  );

  // set dynamically changing window and info drawer width
  const [infoDrawerWidthMax, setInfoDrawerWidthMax] = useState<number>(0);

  /**
   * get dynamic window width
   *
   * windowWidth: width (px) of the current screen
   * Layout.APP_DRAWER_WIDTH_MIN: width (px) of the minimized app drawer / 4
   * INFO_DRAWER_MAXIMIZED_PADDING: width (px) between app drawer and info drawer / 4
   */
  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;

      const infoDrawerWidthMax =
        windowWidth / 4 -
        (Layout.APP_DRAWER_WIDTH_MIN + Layout.INFO_DRAWER_MAXIMIZED_PADDING);
      setInfoDrawerWidthMax(infoDrawerWidthMax);
    };

    // fire once on window load
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  // on route change, scroll to the top of the page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.state.location.pathname]);

  const sx = styles(useTheme(), InfoDrawerState.isOpen, appDrawerMinimized);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <GlobalStyles styles={getProductTourStyles(theme)} />
      <Banner
        isFreeTrialTenant={isFreeTrialTenant}
        isPremiumTenant={isPremiumTenant}
        isLicenseAndBundlingEnabled={isLicenseAndBundlingEnabled}
        isPageAccessAllowed={isPageAccessAllowed}
        remainingPeriodToExpiry={remainingPeriodToExpiry}
        tenantInfo={tenantInfo}
      />
      <Drawer
        open
        sx={{
          '& .MuiDrawer-paper': ({ palette }) => ({
            backgroundColor: palette.darkMode ? '#fff' : undefined,
            borderRight: 0,
          }),
        }}
        variant="permanent"
      >
        <AppDrawerV2 />
      </Drawer>

      <Box component="main" sx={sx.main}>
        <ErrorBoundary
          FallbackComponent={AppContentFallback}
          // NOTE: ensures error boundary is reset on a route change
          resetKeys={[location.current.pathname, location.current.searchStr]}
        >
          {children}
        </ErrorBoundary>
      </Box>

      <Drawer
        onClose={InfoDrawerState.close}
        open={InfoDrawerState.isOpen}
        anchor="right"
        variant={infoDrawerMaximized ? 'temporary' : 'persistent'}
      >
        <Box
          ref={infoDrawerContainerRef}
          sx={({ breakpoints, spacing }) => ({
            height: '100%',
            width: spacing(Layout.INFO_DRAWER_WIDTH),
            [breakpoints.down(Layout.INFO_DRAWER_MAXIMIZED_WIDTH)]: {
              width: spacing(infoDrawerWidthMax),
            },
          })}
        >
          <ErrorBoundary
            FallbackComponent={InfoDrawerFallback}
            // NOTE: ensures error boundary is reset when drawer is closed
            resetKeys={[InfoDrawerState.isOpen]}
          >
            {infoDrawerContentRef.current ?? null}
          </ErrorBoundary>
        </Box>
      </Drawer>

      <AppNotificationContainer
        resetKeys={[
          // reset app notifications on route change
          router.state.location.pathname,
        ]}
      />
    </Box>
  );
};

function styles(
  { spacing, transitions, breakpoints }: Theme,
  infoDrawerOpen: boolean,
  appDrawerMinimized: boolean
) {
  return {
    main: {
      flexGrow: 1,
      marginLeft: appDrawerMinimized
        ? spacing(Layout.APP_DRAWER_WIDTH_MIN)
        : spacing(Layout.APP_DRAWER_WIDTH),
      marginRight: infoDrawerOpen ? spacing(Layout.INFO_DRAWER_WIDTH) : 0,
      padding: spacing(7, 6),
      transition: transitions.create('margin', {
        easing: infoDrawerOpen
          ? transitions.easing.easeOut
          : transitions.easing.sharp,
        duration: infoDrawerOpen
          ? transitions.duration.enteringScreen
          : transitions.duration.leavingScreen,
      }),
    },
    trialContainer: {
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        marginLeft: spacing(Layout.APP_DRAWER_WIDTH_MIN),
      },
    },
  };
}

/**
 * Provide a fallback for a failed page load
 */
const AppContentFallback = ({
  error,
  resetErrorBoundary,
}: ErrorBoundaryFallbackProps) => {
  return (
    <EmptyState
      title={<FM defaultMessage="We've Encountered a Problem" />}
      description={
        <FM defaultMessage="An unknown error has occurred. Please navigate to a previous screen, or return to your dashboard." />
      }
    >
      {/* optionally, allow the user to retry loading the page, or provide default navigation
        <ButtonPrimary onClick={resetErrorBoundary}>
          <FM defaultMessage="Try Again" />
        </ButtonPrimary>

        <ButtonPrimary href="/">
          <FM defaultMessage="Return Home" />
        </ButtonPrimary>
      */}
    </EmptyState>
  );
};
