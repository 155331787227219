import { useInfoDrawer } from '../InfoDrawer';
import {
  ScanIssuesDetailDrawer,
  ScanIssuesDetailDrawerProps,
} from './ScanIssuesDetailDrawer';

export const useScanIssuesDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    {
      focusResourceUuid?: string;
      namespace: string;
      projectUuid: string;
      contextType: string;
      contextId: string;
    },
    ScanIssuesDetailDrawerProps
  >({
    drawerParams: [
      'focusResourceUuid',
      'namespace',
      'projectUuid',
      'contextType',
      'contextId',
    ],
    Component: ScanIssuesDetailDrawer,
  });

  return { DetailDrawer };
};
