import { get as _get } from 'lodash-es';

export type Header<TData extends object> = {
  key: keyof TData | string;
  label: string;
};

type HeaderMap<TData extends object> = Map<
  keyof TData | string,
  Header<TData> & { index: number }
>;

export type StringifyOptions<TData extends object> = {
  headers: Header<TData>[] | undefined;
  delimiter?: string;
};

/**
 * Simple CSV stringify utility
 */
export function stringify<TData extends object>(
  data: TData[],
  replacer?:
    | ((key: keyof TData | string, value: unknown) => undefined | unknown)
    | null,
  options?: StringifyOptions<TData>
): string {
  const { delimiter = ',' } = options ?? {};
  const headerMap = makeHeaderMap(data, options?.headers);

  const lines = new Array(data.length + 1);

  const header = new Array(headerMap.size);
  headerMap.forEach((h) => {
    header[h.index] = escapeCellValue(h.label);
  });
  lines[0] = header.join(delimiter);

  data.forEach((row, index) => {
    lines[index + 1] = stringifyRow<TData>(row, headerMap, delimiter);
  });

  return lines.join('\r\n');
}

function makeHeaderMap<TData extends object>(
  data: TData[],
  headers?: Header<TData>[]
): HeaderMap<TData> {
  if (headers?.length) {
    return new Map(
      headers.map((header, index) => [header.key, { ...header, index }])
    );
  }

  const map: HeaderMap<TData> = new Map();
  for (const row of data) {
    for (const key of Object.keys(row)) {
      if (map.has(key)) continue;
      map.set(key, { key, label: key as string, index: map.size });
    }
  }

  return map;
}

function stringifyRow<TData extends object>(
  data: TData,
  headers: HeaderMap<TData>,
  delimiter: string
) {
  const row = new Array(headers.size).fill('');
  headers.forEach((h) => {
    const value = getCellValue(data, h.key);
    row[h.index] = value;
  });
  return row.join(delimiter);
}

function getCellValue<TData extends object>(
  data: TData,
  key: keyof TData | string
) {
  const value = _get(data, key);
  return escapeCellValue(value);
}

function escapeCellValue(value: unknown) {
  if (typeof value === 'undefined' || value === null) return '';
  if (typeof value === 'number') return value;

  if (Array.isArray(value)) {
    value = value.join(',');
  }

  if ('string' !== typeof value) {
    value = String(value);
  }

  if (/"|,/.test(value as string)) {
    return `"${String(value).replace(/"/g, '""')}"`;
  }

  return value;
}
