import { lazy, Suspense } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { EmptyState } from '@endorlabs/ui-common';

import type { ResourceRawDataBlockProps } from './ResourceRawDataBlock';

/**
 * Lazy load the component by default
 */
export const ResourceRawDataBlockLazy = lazy(() =>
  import(
    /* webpackChunkName: "lazy-ResourceRawDataBlock" */
    './ResourceRawDataBlock'
  ).then((m) => ({
    default: m.ResourceRawDataBlock,
  }))
);

export const ResourceRawDataBlock = (props: ResourceRawDataBlockProps) => (
  <Suspense
    fallback={
      <EmptyState
        size="medium"
        title={<FM defaultMessage="Loading&hellip;" />}
      />
    }
  >
    <ResourceRawDataBlockLazy {...props} />
  </Suspense>
);
