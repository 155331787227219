import { usePRCommentTemplateData } from '../hooks/usePRCommentTemplateData';
import { FormUpsertCustomNotificationTemplate } from './FormUpsertCustomNotificationTemplate';

export const EditPRCommentsCustomTemplate = ({
  tenantName,
  onCancel,
}: {
  tenantName: string;
  onCancel?: () => void;
}) => {
  const { defaultTemplate, template, saveTemplate } = usePRCommentTemplateData({
    tenantName,
  });
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <FormUpsertCustomNotificationTemplate
      defaultValues={defaultTemplate}
      onCancel={handleCancel}
      onSubmit={saveTemplate}
      values={template}
    />
  );
};
