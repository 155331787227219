import { Components, Theme } from '@mui/material';

type RadioOverrides = Pick<Components<Theme>, 'MuiRadio'>;

export const getRadioOverrides = ({ spacing }: Theme): RadioOverrides => {
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: spacing(0.5),
        },
      },
    },
  };
};
