import { Navigate, Outlet } from '@tanstack/react-location';
import { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { useQueryClient } from 'react-query';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  hasResourceMutateErrorHandler,
  hasResourceMutateSuccessHandler,
  isQueryError,
  isResourceMutateMeta,
  useAuthentication,
  useSession,
} from '@endorlabs/queries';
import { useAppNotify, useResourceCRUDMessages } from '@endorlabs/ui-common';

import { AppLayout } from '../../components/AppLayout';
import { useProductTour } from '../../domains/Tour';
import { useAuthInfo } from '../../providers';
import { NamedRoutes } from '../constants';
import { getDashboardPath, getLoginPath } from '../utils';

export const SecureRoute = () => {
  const { getErrorMessage, getSuccessMessage } = useResourceCRUDMessages();
  const addAppNotification = useAppNotify();
  const auth = useAuthentication();
  const {
    canAccessTenant,
    getLastUsedTenant,
    getUserTenants,
    setLastUsedTenant,
    isEndorUser,
    isSessionExpiring,
    isSessionExpired,
  } = useSession();
  const queryClient = useQueryClient();
  const { activeNamespace: tenantName } = useAuthInfo();
  const { productTour } = useProductTour();

  useEffect(() => {
    if (!auth.isAuthenticated) return;

    const lastUsedTenant = getLastUsedTenant();
    if (
      tenantName &&
      // If a "last used tenant" does not exist, set it using the current user tenant.
      (!lastUsedTenant ||
        // If a "last used tenant" does exist, but is different than the current
        // user tenant, update the "last used tenant".
        tenantName !== lastUsedTenant)
    ) {
      setLastUsedTenant(tenantName);
    }

    // if session is expiring soon after the page load, display notification
    if (isSessionExpiring()) {
      addAppNotification({
        id: 'session:expiring',
        action: {
          type: 'link',
          value: getLoginPath(),
          title: <FM defaultMessage="Go to Login" />,
        },
        message: (
          <FM defaultMessage="Your session will expire in less than 5 minutes. Please save any work in progress and renew your login." />
        ),
        severity: 'info',
      });
    }
  }, [
    addAppNotification,
    isSessionExpiring,
    auth.isAuthenticated,
    getLastUsedTenant,
    setLastUsedTenant,
    tenantName,
  ]);

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe((event) => {
      if (
        event?.type === 'queryUpdated' &&
        event.action?.type === 'error' &&
        isQueryError(event.action.error)
      ) {
        const { error } = event.action;

        // handle expired authorization with notification linking back to login page
        if (error.response.status === 401 && isSessionExpired()) {
          addAppNotification({
            id: 'session:expired',
            action: {
              type: 'link',
              value: getLoginPath(),
              title: <FM defaultMessage="Go to Login" />,
            },
            message: (
              <FM defaultMessage="Your session has expired. Please return to the login page to continue." />
            ),
            severity: 'error',
          });
        }
      }
    });

    return unsubscribe;
  }, [addAppNotification, isSessionExpired, queryClient]);

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe((event) => {
      // if there is no mutation meta, ignore
      if (!isResourceMutateMeta(event?.meta)) return;

      // handle mutation error when there is no handler
      if (
        event?.state.status === 'error' &&
        !hasResourceMutateErrorHandler(event)
      ) {
        // show a notification for the error
        const { action, resourceKind } = event.meta.crud;
        const { message, details } = getErrorMessage(
          action,
          resourceKind,
          event.state.error
        );
        addAppNotification({
          id: `${resourceKind}:${action}:error`,
          severity: 'error',
          message,
          details,
        });
        return;
      }

      // handle mutation successes when there is no handler
      if (
        event?.state.status === 'success' &&
        !hasResourceMutateSuccessHandler(event)
      ) {
        // show a notification for the success
        const { action, resourceKind } = event.meta.crud;
        const { message, details } = getSuccessMessage(action, resourceKind);
        addAppNotification({
          id: `${resourceKind}:${action}:success`,
          severity: 'success',
          message,
          details,
        });
      }
    });

    return unsubscribe;
  }, [addAppNotification, getErrorMessage, getSuccessMessage, queryClient]);

  // If not logged in, ensure product tour is stopped
  useEffect(() => {
    if (!auth.isAuthenticated && productTour.isActive()) {
      productTour.cancel();
    }
  }, [auth.isAuthenticated, productTour]);

  if (auth.isLoading) {
    // TODO: Loading state
    return null;
  }

  // If authenticated, render child route
  if (auth.isAuthenticated) {
    const userTenants = getUserTenants();

    // If trying to access a specific tenant page …
    if (tenantName) {
      // … but user has no tenants, redirect them to TenantsIndex, which will determine options
      if (userTenants?.length === 0 && tenantName !== NAMESPACES.OSS) {
        return <Navigate to={NamedRoutes.TENANTS_INDEX} />;
      }

      // … but user doesn't have access to that tenant, redirect to previous or fallback tenant page
      // NOTE: We could also pop a snackbar here, or go to a 404 page
      if (!isEndorUser() && !canAccessTenant(tenantName)) {
        return (
          <Navigate
            to={getDashboardPath({
              tenantName: getLastUsedTenant(true) as string,
            })}
          />
        );
      }
    }
    return (
      <AppLayout>
        <Outlet />
      </AppLayout>
    );
  }

  // If not authenticated, redirect to login page
  return <Navigate to={getLoginPath()} />;
};
