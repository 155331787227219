import { NumberDisplay } from '@endorlabs/ui-common';

/**
 * Context-specific number display used to display total resource count in the PageHeader
 */
export const PageHeaderCount = ({ value }: { value?: number }) => {
  return (
    <NumberDisplay
      // Ideally would use component="span" variant="h1", but there are TS complications to using `component`
      // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop
      typographyProps={{
        sx: ({ typography }) => ({
          ...typography.h1,
          fontFamily: 'unset',
          fontWeight: 400,
          color: 'text.secondary',
        }),
      }}
      value={value}
    />
  );
};
