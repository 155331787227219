import { Box, Typography } from '@mui/material';
import { isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash-es';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  PackagistSpecAuthKind,
  SpecPackageManagerStatusState,
} from '@endorlabs/api_client';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  NumberDisplay,
  OrderableTable,
  OrderableTableProps,
  RelativeTimeDisplay,
  Status,
  StatusIndicator,
  useDataTablePaginator,
} from '@endorlabs/ui-common';
import { PackagistAuthKindLabel } from '@endorlabs/ui-common/domains/PackageManagers/PackageManagersLabel';

import { PackageManagerKey, PackageManagerKeys } from './constants';
import { PackageManagerAuthMode } from './types';
import { transformStateToStatus } from './utils';

export interface PackageManagersTableRow {
  priority: number;
  uuid: string;
  url?: string;
  host?: string;
  auth_kind?: PackagistSpecAuthKind;
  auth_type?: PackageManagerAuthMode;
  name?: string;
  status?: string;
  last_tested?: string;
}

const colHeaders = defineMessages({
  priority: { defaultMessage: 'Priority' },
  url: { defaultMessage: 'URL' },
  host: { defaultMessage: 'Hostname' },
  authKind: { defaultMessage: 'Authentication Type' },
  authType: { defaultMessage: 'Authentication Type' },
  name: { defaultMessage: 'Name' },
  lastTested: {
    defaultMessage: 'Last Tested',
  },
});

const authTypeLabel = defineMessages({
  [PackageManagerAuthMode.AWS]: { defaultMessage: 'AWSCodeArtifact' },
  [PackageManagerAuthMode.Basic]: {
    defaultMessage: 'Basic Auth',
  },
});

const authStateTooltipLabel = defineMessages({
  [SpecPackageManagerStatusState.Success]: {
    defaultMessage: 'Successfully authenticated with host',
  },
  [SpecPackageManagerStatusState.Fail]: {
    defaultMessage: 'Unable to authenticate with host',
  },
  [SpecPackageManagerStatusState.Unspecified]: {
    defaultMessage: 'No connection test was found',
  },
});

const buildPackageManagersTableColDefs = ({
  packageManagerKey,
  onDelete,
  onEdit,
  onTest,
  isStatusUpdating,
  lastTestedRowUuid,
}: {
  onDelete?: (row: PackageManagersTableRow) => void;
  onEdit?: (row: PackageManagersTableRow) => void;
  onTest?: (row: PackageManagersTableRow) => void;
  packageManagerKey: PackageManagerKey;
  isStatusUpdating: boolean;
  lastTestedRowUuid: string;
}) => {
  const isPackagist = packageManagerKey === PackageManagerKeys.packagist;
  const urlKey = isPackagist ? 'host' : 'url';
  const columns: DataTableColumnDef<PackageManagersTableRow>[] = [];

  columns.push({
    accessorKey: 'status',
    header: () => '',
    cell: ({ row, getValue }) => {
      const state: SpecPackageManagerStatusState = getValue();

      // show the loading indicator only for the currently tested row.
      const isRowCurrentlyTested =
        isStatusUpdating && row?.original.uuid === lastTestedRowUuid;

      return (
        <StatusIndicator
          status={
            isRowCurrentlyTested
              ? Status.Running
              : transformStateToStatus(state)
          }
          tooltipNode={
            <Typography variant="body2">
              <FM {...authStateTooltipLabel[state]} />
            </Typography>
          }
        />
      );
    },
    colType: ColTypes.STATUS_INDICATOR,
  });

  //Packagist do not have priority
  if (!isPackagist) {
    columns.push({
      accessorKey: 'priority',
      header: () => <FM {...colHeaders.priority} />,
      cell: ({ getValue }) => <NumberDisplay value={getValue()} />,
    });
  }

  columns.push(
    {
      accessorKey: 'name',
      header: () => <FM {...colHeaders.name} />,
    },
    {
      accessorKey: urlKey,
      header: () => (
        <FM
          {...(isPackagist ? { ...colHeaders.host } : { ...colHeaders.url })}
        />
      ),
    }
  );

  if (!isPackagist) {
    columns.push({
      accessorKey: 'auth_type',
      header: () => <FM {...colHeaders.authType} />,
      cell: ({ row }) => {
        const authType = row?.original['auth_type'];
        return !_isUndefined(authType) ? (
          <FM {...authTypeLabel[authType]} />
        ) : (
          ''
        );
      },
    });
  } else {
    columns.push({
      accessorKey: 'auth_kind',
      cell: ({ row }) => {
        if (row?.original['auth_type'] === PackageManagerAuthMode.AWS) {
          return <FM {...authTypeLabel[PackageManagerAuthMode.AWS]} />;
        }
        const selectedAuth = row?.original['auth_kind'];
        if (!_isEmpty(selectedAuth) && !_isUndefined(selectedAuth)) {
          return PackagistAuthKindLabel({ packagistAuthKind: selectedAuth });
        }
        return '';
      },
      header: () => {
        return <FM {...colHeaders.authKind} />;
      },
    });
  }

  columns.push({
    accessorKey: 'last_tested',
    header: () => <FM {...colHeaders.lastTested} />,
    cell: ({ getValue }) => {
      const lastTestTime = getValue();
      return <RelativeTimeDisplay value={lastTestTime} />;
    },
  });

  if (onDelete || onEdit || onTest) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => {
        const actions: DataTableActionDropdownItem[] = [];

        if (onTest) {
          actions.push({
            label: <FM defaultMessage="Test Connection" />,
            onClick: () => onTest(row.original),
            disabled: isStatusUpdating,
          });
        }

        if (onEdit) {
          actions.push({
            label: <FM defaultMessage="Edit" />,
            onClick: () => onEdit(row.original),
          });
        }

        if (onDelete) {
          actions.push({
            label: <FM defaultMessage="Delete" />,
            onClick: () => onDelete(row.original),
            isDestructive: true,
          });
        }

        return (
          <Box display="flex" justifyContent="end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

export interface PackageManagersTableProps
  extends Omit<OrderableTableProps<PackageManagersTableRow>, 'columns'> {
  onEdit?: (row: PackageManagersTableRow) => void;
  onDelete?: (row: PackageManagersTableRow) => void;
  onTest?: (row: PackageManagersTableRow) => void;
  packageManagerKey: PackageManagerKey;
  isStatusUpdating: boolean;
  lastTestedRowUuid: string;
}

/**
 * Displays a collection of package managers using a given platform
 */
export const PackageManagersTable = ({
  data = [],
  packageManagerKey,
  onDelete,
  onEdit,
  onTest,
  onReorderComplete,
  isStatusUpdating,
  lastTestedRowUuid,
}: PackageManagersTableProps) => {
  const columns = buildPackageManagersTableColDefs({
    onDelete,
    onEdit,
    onTest,
    isStatusUpdating,
    lastTestedRowUuid,
    packageManagerKey,
  });
  const paginator = useDataTablePaginator({
    totalCount: data.length,
  });

  //Packagist does not have priority hence reordering is not required
  const isPackagist = packageManagerKey === PackageManagerKeys.packagist;
  return isPackagist ? (
    <DataTable data={data} columns={columns} paginator={paginator} />
  ) : (
    <OrderableTable
      columns={columns}
      data={data}
      onReorderComplete={onReorderComplete}
    />
  );
};
