import { Card, CardContent, Grid, Typography } from '@mui/material';
import { Navigate, useNavigate } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { V1User } from '@endorlabs/api_client';
import { TenantType } from '@endorlabs/endor-core/auth';
import {
  GLOBAL_TENANTS,
  SHARED_TENANTS,
  TenantResource,
  useCreateOnboard,
  useSession,
} from '@endorlabs/queries';

import { FormNewTenant } from '../../components';
import { MarketingHeading } from '../../domains/Auth';
import { getDashboardPath, getOnboardRootPath } from '../../routes';

// FIXME: This information should not (may not) be required.
// Fill with boilerplate data for now to make tenant creation easier.
const BOILERPLATE_TENANT_DATA = [
  'street',
  'city',
  'state',
  'country',
  'phone_number',
].reduce((obj, field) => {
  return { ...obj, [field]: 'REDACTED' };
}, {});

/**
 * Page to create a new tenant for brand new users
 */
export const TenantsCreatePage = () => {
  const { getLastUsedTenant, getUser, hasValidTenantAccess, getUserTenants } =
    useSession();
  const navigate = useNavigate();

  const user = getUser();
  const lastUsedTenant = getLastUsedTenant(true);
  const tenantsList = getUserTenants();
  const [exploreTenant, setExploreTenant] = useState<string | undefined>(
    undefined
  );
  const [path, setPath] = useState<string>('');

  useEffect(() => {
    if (lastUsedTenant && hasValidTenantAccess()) {
      const trialTenant = tenantsList.filter(
        (t) =>
          t.tenantType === TenantType.Trial ||
          t.tenantType === TenantType.TrialExpired
      )[0];
      if (trialTenant?.name) {
        setPath(
          getOnboardRootPath({
            tenantName: trialTenant.name as string,
          })
        );
        setExploreTenant(trialTenant?.name);
      } else {
        setPath(getDashboardPath({ tenantName: lastUsedTenant as string }));
        setExploreTenant(lastUsedTenant);
      }
    }
  }, [tenantsList, hasValidTenantAccess, lastUsedTenant]);

  const qOnboardCreate = useCreateOnboard();

  const handleOnboardSubmit = async (formData: FieldValues) => {
    const tenant = {
      ...formData,
      spec: BOILERPLATE_TENANT_DATA,
    } as TenantResource;

    qOnboardCreate.mutate(
      {
        resource: {
          spec: { tenant, user: user as V1User },
        },
      },
      {
        onSuccess: () => {
          navigate({
            to: getOnboardRootPath({ tenantName: tenant.meta.name }),
          });
        },
      }
    );
  };

  /**
   * Redirect to most recently used tenant, if available
   */
  if (exploreTenant) {
    return <Navigate to={path} replace />;
  }

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grid
        container
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container direction="column" item xs={12} md={4} spacing={6}>
          <Grid item>
            <MarketingHeading text={<FM defaultMessage="Create a Tenant" />} />
          </Grid>

          <Grid item>
            <Typography sx={{ textAlign: 'center' }}>
              <FM
                defaultMessage="Provide a Tenant name for your organization.<br></br>Note that this name cannot be changed in the future."
                values={{ br: () => <br /> }}
              />
            </Typography>
          </Grid>

          <Grid item>
            <Card>
              <CardContent>
                <FormNewTenant
                  isLoading={qOnboardCreate.isLoading}
                  onSubmit={handleOnboardSubmit}
                  serverErrorResponse={qOnboardCreate.error?.response}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
