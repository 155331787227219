import { List, ListItemButton, ListItemText } from '@mui/material';
import { ReactNode } from 'react';

import { IconChevronRight } from '../../themes';
import { TreeColumnPanel } from './TreeColumnPanel';

export const TreeColumnListPanel = <
  TOption extends { key: string; label: ReactNode; description?: ReactNode }
>({
  onSelectedChange,
  options,
  selected,
  title,
}: {
  onSelectedChange: (key: string) => void;
  options: TOption[];
  selected?: string;
  title: ReactNode;
}) => {
  return (
    <TreeColumnPanel title={title}>
      <List
        sx={{
          '.MuiListItemButton-root': {
            borderRadius: 1,
            paddingX: 2,
          },
          '.MuiListItemText-root': {
            marginY: 0,
          },
        }}
      >
        {options.map((o) => (
          <ListItemButton
            key={o.key}
            onClick={() => onSelectedChange(o.key)}
            selected={o.key === selected}
          >
            <ListItemText primary={o.label} secondary={o.description} />

            <IconChevronRight />
          </ListItemButton>
        ))}
      </List>
    </TreeColumnPanel>
  );
};
