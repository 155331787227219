import { Card, CardContent } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import _sortBy from 'lodash-es/sortBy';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType } from '@endorlabs/api_client';
import {
  PolicyTemplateResource,
  useListPolicyTemplates,
} from '@endorlabs/queries';
import { EmptyState } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { getUmbrellaTypeFromPolicyType } from '../utils';
import {
  PolicyTemplatesTable,
  PolicyTemplatesTableRow,
} from './PolicyTemplatesTable';

interface PolicyTemplatesViewProps {
  policyTypes: PolicyPolicyType[];
}

export const PolicyTemplatesView = ({
  policyTypes,
}: PolicyTemplatesViewProps) => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();

  const createNewPolicyFromTemplate = (
    templateRow: PolicyTemplatesTableRow
  ) => {
    navigate({
      to: getPoliciesPath({
        tenantName,
        section: `${getUmbrellaTypeFromPolicyType(templateRow.policyType)}/new`,
      }),
      search: { templateUuid: templateRow.uuid },
    });
  };

  // Retrieve Endor policy templates
  const qListPolicyTemplates = useListPolicyTemplates(
    tenantName,
    { staleTime: Infinity },
    { filter: `spec.policy_type in [${policyTypes.join(',')}]`, page_size: 500 }
  );

  const policyTemplates = useMemo(
    () =>
      _sortBy(
        (qListPolicyTemplates.data?.list?.objects ?? []).filter(
          (t) => t.spec.policy_type !== PolicyPolicyType.MlFinding
        ),
        ['meta.name']
      ),
    [qListPolicyTemplates.data]
  );

  const buildPolicyTemplateTableRows = (
    templates: PolicyTemplateResource[]
  ): PolicyTemplatesTableRow[] => {
    return templates.map((template) => {
      return {
        description: template.meta.description,
        name: template.meta.name,
        namespace: template.tenant_meta.namespace,
        policyType: template.spec.policy_type as PolicyPolicyType,
        uuid: template.uuid,
      };
    });
  };

  return (
    <Card>
      <CardContent>
        {policyTemplates.length > 0 && (
          <PolicyTemplatesTable
            createPolicy={createNewPolicyFromTemplate}
            data={buildPolicyTemplateTableRows(policyTemplates)}
          />
        )}

        {!qListPolicyTemplates.isLoading && policyTemplates.length === 0 && (
          <EmptyState
            size="medium"
            title={
              <FM defaultMessage="Custom policy templates are coming soon" />
            }
          />
        )}
      </CardContent>
    </Card>
  );
};
