import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantThirdPartySbomsIndexRoute = () => {
  return <Navigate to="import" replace />;
};

export const Route = createFileRoute('/t/:namespace/third-party/sboms/')({
  component: TenantThirdPartySbomsIndexRoute,
});
