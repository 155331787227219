import { minutesToMilliseconds } from 'date-fns';
import { pick as _pick } from 'lodash-es';
import { QueryKey, useInfiniteQuery, useQuery } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryQuerySpec,
  QueryServiceApi,
  ScanResultSpecType,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Query,
  V1RepositoryVersion,
  V1RepositoryVersionSpec,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceCountResponse,
  ResourceInfiniteQueryOptions,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration, sortParamBuilders } from './utils';

export type QueryRepositoryVersionsResponse =
  TResourceList<QueryRepositoryVersionsResponseObject>;

export type QueryRepositoryVersionsResponseObject = SelectFrom<
  V1RepositoryVersion,
  'uuid' | 'tenant_meta' | 'context' | 'scan_object',
  {
    spec: V1RepositoryVersionSpec;
    meta: SelectFrom<
      V1Meta,
      'name' | 'parent_uuid' | 'tags' | 'create_time' | 'update_time',
      {
        references: {
          FindingsGroupByLevel?: Pick<TResourceList<unknown>, 'group_response'>;
          Metric?: QueryRepositoryVersionMetricResponse;
          PackageCount?: ResourceCountResponse;
          LatestScanResult?: TResourceList<ScanResultResource>;
        };
      }
    >;
  }
>;

type QueryRepositoryVersionMetricResponse = TResourceList<
  SelectFrom<
    V1Metric,
    'uuid',
    {
      meta: SelectFrom<V1Meta, 'name'>;
      spec: SelectFrom<
        V1MetricSpec,
        'analytic',
        {
          metric_values: {
            scorecard: Required<V1MetricValue>;
            scorefactor: Required<V1MetricValue>;
          };
          // properties required, but not included in query
          project_uuid: never;
          raw: never;
        }
      >;
      tenant_meta: never;
      context: never;
    }
  >
>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'repository-versions', namespace, listParams] as const,
  infiniteQuery: (
    namespace: string,
    listParams: V1ListParameters = {}
  ): QueryKey =>
    [
      BASE_KEY,
      'infinite',
      'repository-versions',
      namespace,
      listParams,
    ] as const,
};
export const QueryRepositoryVersionsQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (rootListParams: V1ListParameters): QueryQuerySpec => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  return {
    kind: 'RepositoryVersion',
    list_parameters: {
      ...rootListParams,
      mask: [
        'uuid',
        'tenant_meta',
        'context',
        'scan_object',
        'meta.name',
        'meta.parent_uuid',
        'meta.create_time',
        'meta.tags',
        'spec.version',
      ].join(),
    },
    references: [
      {
        connect_from: 'spec.version.sha',
        connect_to: 'spec.source_code_version.sha',
        query_spec: {
          kind: 'Finding',
          return_as: 'FindingsGroupByLevel',
          list_parameters: {
            ...commonListParameters,
            group: {
              aggregation_paths: 'spec.level',
            },
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'Metric',
          list_parameters: {
            ...commonListParameters,
            page_size: 1,
            filter: 'meta.name==version_scorecard',
            mask: [
              'uuid',
              'meta.name',
              'spec.analytic',
              'spec.metric_values',
            ].join(),
          },
        },
      },
      {
        connect_from: 'spec.version.sha',
        connect_to: 'spec.source_code_reference.version.sha',
        query_spec: {
          kind: 'PackageVersion',
          return_as: 'PackageCount',
          list_parameters: {
            ...commonListParameters,
            count: true,
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'ScanResult',
          return_as: 'LatestScanResult',
          list_parameters: {
            ...commonListParameters,
            filter: `spec.type==${ScanResultSpecType.AllScans}`,
            sort: sortParamBuilders.descendingBy('meta.create_time'),
            page_size: 1,
          },
        },
      },
    ],
  };
};

const buildQuery = (
  namespace: string,
  listParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryRepositoryVersions(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildQuerySpec(listParams),
    },
    tenant_meta: { namespace },
  };
};

const queryRepositoryVersions = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryRepositoryVersionsResponse;
};

/**
 * Custom query for Project Versions, including metrics
 *
 * NOTE: this query sets the mask for the response objects
 */
export const useQueryRepositoryVersions = (
  namespace: string,
  opts: ResourceQueryOptions<QueryRepositoryVersionsResponse> = {},
  listParams: Omit<ListRequestParameters, 'mask'> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'RepositoryVersion',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.query(namespace, requestParameters),
    ({ signal }) =>
      queryRepositoryVersions(namespace, requestParameters, signal),
    {
      staleTime: QUERY_STALE_TIME,
      ...opts,
    }
  );
};

/**
 * Infinite Query variant
 */
export const useInfiniteQueryRepositoryVersions = (
  namespace: string,
  opts: ResourceInfiniteQueryOptions<QueryRepositoryVersionsResponse> = {},
  listParams: Omit<ListRequestParameters, 'mask'> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'RepositoryVersion',
    'LIST',
    listParams,
    opts
  );

  return useInfiniteQuery(
    QK.infiniteQuery(namespace, requestParameters),
    ({ pageParam }) => {
      return queryRepositoryVersions(namespace, {
        ...requestParameters,
        page_token: pageParam,
      });
    },
    {
      staleTime: QUERY_STALE_TIME,
      ...opts,
      getNextPageParam: (lastPage, pages) =>
        lastPage?.list?.response?.next_page_token,
    }
  );
};
