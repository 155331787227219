import { ECOSYSTEM_SEPARATOR, VERSION_SEPARATOR } from '../constants';
import { PackageDescriptor } from '../types';
import { prefixFromEcosystem } from './ecosystem';

/**
 * Sort of the inverse of parsePackageName. Given an object with package version attributes,
 * generate a complete string including ecosystem prefix & version suffix.
 * Useful when comparing package names of differing specificity.
 */
export const deriveFullPackageName = (
  value: Omit<PackageDescriptor, 'label'>
) => {
  const stripEcosystem = value.name.split(ECOSYSTEM_SEPARATOR);
  let composedName =
    stripEcosystem.length > 1 ? stripEcosystem[1] : stripEcosystem[0];

  const start = 0;
  let end = composedName.lastIndexOf(VERSION_SEPARATOR);
  if (end < 1) {
    end = composedName.length;
  }

  composedName = composedName.slice(start, end);

  const final = [
    prefixFromEcosystem(value.ecosystem),
    // _findKey(EcosystemByPrefix, (e) => e === value.ecosystem),
    ECOSYSTEM_SEPARATOR,
    composedName,
    VERSION_SEPARATOR,
    value.version,
  ].join('');

  return final;
};
