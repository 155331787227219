import { isObject as _isObject } from 'lodash-es';
import { QueryKey, QueryOptions, useQuery, UseQueryResult } from 'react-query';

import {
  DependencyMetadataServiceApi,
  QueryServiceApi,
  V1CountResponse,
  V1GroupResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  GroupRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  DependencyMetadataResource,
  DependencyMetadataResourceList,
  IQueryError,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
  listAllResource,
} from './utils';

export interface DependencyMetadataReadParams {
  namespace: string;
  uuid: string;
}

export type CountDependencyMetadataOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
export type ListDependencyMetadataOptions =
  ResourceQueryOptions<DependencyMetadataResourceList>;
export type GetDependencyMetadataOptions =
  ResourceQueryOptions<DependencyMetadataResource>;
export type GroupDependencyMetadataOptions = ResourceQueryOptions<
  Required<V1GroupResponse>
>;

const BASE_KEY = 'v1/dependency-metadata';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list-all', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const DependencyMetadataQueryKeys = QK;

const getApiService = () =>
  new DependencyMetadataServiceApi(getClientConfiguration());
const getQueryApiService = () => new QueryServiceApi(getClientConfiguration());

const countDependencyMetadata = async (
  namespace: string,
  countParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.dependencyMetadataServiceListDependencyMetadata(
    namespace,
    ...buildCountParamArgs(countParams),
    { signal }
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const countDependencyMetadataQueryOptions = (
  namespace: string,
  countParams: CountRequestParameters
): QueryOptions<Required<V1CountResponse>, IQueryError> => ({
  queryKey: QK.count(namespace, countParams),
  queryFn: (ctx) => countDependencyMetadata(namespace, countParams, ctx.signal),
});

export const useCountDependencyMetadata = (
  namespace: string,
  opts: CountDependencyMetadataOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'COUNT',
    countParams,
    opts
  );

  return useQuery({
    ...countDependencyMetadataQueryOptions(namespace, requestParameters),
    ...opts,
  });
};

/**
 * pseudo-query wrapping the group API call, and returning a count response
 * of unique dependencies (by dependency name) for a given namespace, with
 * any additional filters
 */
export const useCountUniqueDependencyMetadata = (
  namespace: string,
  opts: CountDependencyMetadataOptions = {},
  params: Pick<GroupRequestParameters, 'filter'> = {}
) => {
  const groupOptions = {
    ...opts,
    select: (data: unknown) => {
      let count = 0;
      if (_isObject(data) && 'groups' in data) {
        count = Object.keys(data?.groups ?? {}).length;
      }

      return { count };
    },
  } as unknown as GroupDependencyMetadataOptions;

  const groupParams = {
    ...params,
    group: {
      aggregation_paths: 'meta.name',
    },
  };

  return useGroupDependencyMetadata(
    namespace,
    groupOptions,
    groupParams
  ) as UseQueryResult<Required<V1CountResponse>, IQueryError>;
};

const listDependencyMetadata = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.dependencyMetadataServiceListDependencyMetadata(
    namespace,
    ...buildListParamArgs(listParams),
    { signal }
  );

  return resp.data as DependencyMetadataResourceList;
};

export const listDependencyMetadataQueryOptions = (
  namespace: string,
  listParams: ListRequestParameters
): QueryOptions<DependencyMetadataResourceList, IQueryError> => ({
  queryKey: QK.list(namespace, listParams),
  queryFn: (ctx) => listDependencyMetadata(namespace, listParams, ctx.signal),
});

export const useListDependencyMetadata = (
  namespace: string,
  opts: ListDependencyMetadataOptions = {},
  listParameters: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'LIST',
    listParameters,
    opts
  );

  return useQuery({
    ...listDependencyMetadataQueryOptions(namespace, requestParameters),
    ...opts,
  });
};

export const listAllDependencyMetadataQueryOptions = (
  namespace: string,
  listParams: ListRequestParameters
): QueryOptions<DependencyMetadataResource[], IQueryError> => ({
  queryKey: QK.listAll(namespace, listParams),
  queryFn: (ctx) =>
    listAllResource<DependencyMetadataResource, DependencyMetadataResourceList>(
      (_, pageId) => {
        const pageParams: V1ListParameters = {
          ...listParams,
          page_id: pageId,
        };

        return listDependencyMetadata(namespace, pageParams);
      },
      { signal: ctx.signal }
    ),
});

export const useListAllDependencyMetadata = (
  namespace: string,
  listParameters: ListAllRequestParameters,
  opts: Pick<ListDependencyMetadataOptions, 'enabled'> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'LIST_ALL',
    listParameters,
    opts
  );

  return useQuery({
    ...listAllDependencyMetadataQueryOptions(namespace, requestParameters),
    ...opts,
  });
};

// NOTE: using Query API due to potentially large filter objects
const groupDependencyMetadata = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  if (!listParams.filter || listParams.filter.length < 256) {
    const resp = await listDependencyMetadata(namespace, listParams, signal);
    return resp.group_response as Required<V1GroupResponse>;
  }

  const api = getQueryApiService();
  const query = {
    meta: {
      name: `GroupDependencyMetadata(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'DependencyMetadata',
        list_parameters: listParams,
      },
    },
  };

  const resp = await api.queryServiceCreateQuery(namespace, query, {
    signal,
  });

  return resp.data.spec?.query_response
    ?.group_response as Required<V1GroupResponse>;
};

export const useGroupDependencyMetadata = (
  namespace: string,
  opts: GroupDependencyMetadataOptions = {},
  groupParams: GroupRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'DependencyMetadata',
    'GROUP',
    groupParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    (ctx) => groupDependencyMetadata(namespace, requestParameters, ctx.signal),
    opts
  );
};

const getDependencyMetadata = async (
  namespace: string,
  uuid: string,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.dependencyMetadataServiceGetDependencyMetadata(
    namespace,
    uuid,
    undefined, // NOTE: not sending GetRequest parameters
    { signal }
  );
  return resp.data as DependencyMetadataResource;
};

export const useGetDependencyMetadata = (
  params: DependencyMetadataReadParams,
  opts: GetDependencyMetadataOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getDependencyMetadata(params.namespace, params.uuid),
    opts
  );
};
