import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  useTheme,
} from '@mui/material';
import {
  ColumnSort,
  ExpandedState,
  getCoreRowModel,
  getFilteredRowModel,
  Row,
  RowSelectionState,
  Table as TanstackTable,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useStyles } from '../../hooks/useStyles';
import { EmptyState, EmptyStateProps } from '../EmptyState';
import { PAGE_SIZES } from './constants';
import { DataTableColumnSettings } from './DataTableColumnSettings';
import { DataTableHeader } from './DataTableHeader';
import { DataTableHeaderCell } from './DataTableHeaderCell';
import { DataTablePagination } from './DataTablePagination';
import { DataTableRow, DataTableRowProps } from './DataTableRow';
import { DataTableSkeletonRows } from './DataTableSkeletonRows';
import { useDataTableRowHighlighting } from './hooks';
import { useDataTableColumnSettings } from './hooks/useDataTableColumnSettings';
import { useDataTablePagination } from './hooks/useDataTablePagination';
import { useDataTableRowSelection } from './hooks/useDataTableRowSelection';
import { useDataTableSorting } from './hooks/useDataTableSorting';
import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableRowData,
} from './types';
import { DataTablePaginator } from './useDataTablePaginator';
import { buildDataTableColumnDefs } from './utils';

/**
 * May extend with additional properties in the future.
 */
export type DataTableColumnSort = ColumnSort;

export interface DataTableProps<T extends DataTableRowData>
  extends Pick<TableOptions<T>, 'getRowId'> {
  /**
   * Content area above table content and right-aligned. Typically used for controls.
   */
  actions?: ReactNode;
  /**
   * ReactTable `columns`
   */
  columns: DataTableColumnDef<T>[];
  /*
   * Add any custom properties to be passed to table column definitions
   */
  customTableColProps?: unknown;
  /**
   * ReactTable `data`
   */
  data: T[];
  /**
   * Props provided to displayed `EmptyState` when no data present
   */
  emptyStateProps?: EmptyStateProps;
  /**
   * Determines whether user can reorder certain columns. Columns where
   * 'enableReorder' property is set will be reorderable and hidable
   */
  enableColumnReorder?: boolean;
  /**
   * Determines whether user can resize columns where allowed
   */
  enableColumnResizing?: boolean;
  /**
   * Determines whether column sorting should be enabled for the table
   * Should be set to `true` when `onColumnSort` is used
   */
  enableColumnSort?: boolean;
  /**
   * Determines whether user can hide certain columns. Columns where
   * 'enableHiding' property is set will be hidable
   */
  enableColumnVisibility?: boolean;
  /**
   * Determines if table supports expanding/collapsing rows
   * Should be set to `true` when renderExpandedRow is provided
   */
  enableExpanding?: boolean;
  /**
   * Displays search bar in actions section that performs fuzzy match search on loaded row.
   */
  enableLocalSearch?: boolean;
  /**
   * Determines whether the table pagination should be displayed.
   * Should be set to true whenever paginator is used.
   */
  enablePagination?: boolean;
  /**
   * Determines whether rows in this table should be selectable.
   */
  enableRowSelection?: boolean;

  /**
   * Rows that will not be displayed. Must correspond to the row ID returned by `getRowId`.
   */
  hiddenRowIds?: string[];
  /**
   * Displays loading state for table
   */
  isLoading?: boolean;
  /**
   * Forces table to a fixed height & enables vertical scrolling of table rows.
   */
  maxHeight?: number;
  /**
   * Manually handle sort events on a column
   *
   * NOTE: disables the default sort handler from `getSortedRowModel` for sorting the visible rows.
   */
  onColumnSort?: (columnSort?: DataTableColumnSort) => void;
  /**
   * Optional handler, allowing click events on a row, not fired when clicking on a column with onClick handler present
   */
  onRowClick?: DataTableRowProps<T>['onRowClick'];
  /**
   * Optional boolean which indicates the details of row is displayed onClick, settings this variable to true allow only one row to be selected at a time.
   */
  isSingleRowSelect?: boolean;
  /**
   * Optional handler, for side effects upon row selection change
   */
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void;
  /**
   * Paginator component to be used
   */
  paginator?: DataTablePaginator;
  /**
   * Optional renderer for an expanded row
   */
  renderExpandedRow?: (props: { row: Row<unknown> }) => ReactNode;
  /**
   * Optional heading for an reorderable column list in the column
   * settings dropdown
   */
  reorderableColumnHeading?: string;
  /**
   * Whether to show the pagination controls
   */
  showPagination?: boolean;
  /**
   * Unique ID for the table. This is used for persisting the table state.
   */
  tableId?: string;
  /**
   * Content area above table content & left-aligned. Typically a header element.
   */
  title?: ReactNode;
}

export const BaseDataTable = <T extends DataTableRowData>(
  {
    actions,
    columns,
    customTableColProps,
    data,
    emptyStateProps = DEFAULT_EMPTY_STATE_PROPS,
    enableColumnReorder = false,
    enableColumnResizing = false,
    enableColumnSort = false,
    enableColumnVisibility = false,
    enableExpanding = false,
    enableLocalSearch = false,
    enablePagination = true,
    enableRowSelection = false,
    getRowId,
    hiddenRowIds = [],
    isLoading,
    isSingleRowSelect = false,
    maxHeight,
    onColumnSort,
    onRowClick,
    onRowSelectionChange,
    paginator,
    renderExpandedRow,
    reorderableColumnHeading,
    showPagination = true,
    tableId,
    title,
  }: DataTableProps<T>,
  ref: Ref<TanstackTable<T>>
) => {
  const { space } = useTheme();
  const tableStyles = useStyles(styles);
  const tableEl = useRef<HTMLTableElement>(null);
  const [tableElWidth, setTableElWidth] = useState<number>(600);

  // Callback ref to track the table container width
  const tableContainerRef = useCallback((node: HTMLTableElement) => {
    node && setTableElWidth(node.clientWidth);
  }, []);

  // Ensure data is memoized before use
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => {
    // Add row selection column to beginning of table if enabled
    if (enableRowSelection && !isSingleRowSelect) {
      columns.unshift({
        colType: ColTypes.ROW_SELECT,
        id: 'row-selection',
      });
    }
    return buildDataTableColumnDefs(columns, customTableColProps);
  }, [enableRowSelection, isSingleRowSelect, columns, customTableColProps]);

  const { tableColumnOptions, columnSettingProps } = useDataTableColumnSettings(
    {
      // @ts-expect-error - accessorFn complaint. See https://github.com/TanStack/table/issues/4241
      columns: memoizedColumns,
      enableColumnReorder: enableColumnReorder,
      enableColumnVisibility: enableColumnVisibility,
      reorderableColumnHeading,
      tableId,
    }
  );

  const defaultTableOptions: Partial<TableOptions<T>> = useMemo(
    () => ({
      columnResizeMode: 'onChange',
      enableColumnResizing,
      enableRowSelection,
      enableMultiSort: false,
      defaultColumn: {
        enableColumnResizing: true,
        enableSorting: false,
        sortDescFirst: true,
        sortUndefined: 1,
      },
      // getRowId: getRowId,
      globalFilterFn: 'includesString',
    }),
    [enableColumnResizing, enableRowSelection]
  );

  const { isRowHighlighted, updateRowHighlighted } =
    useDataTableRowHighlighting();

  // Retrieve table options determined by hooks
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const tablePaginationProps = useDataTablePagination(
    enablePagination,
    paginator
  );
  const tableSortingProps = useDataTableSorting(enableColumnSort, onColumnSort);
  const tableRowSelectionProps = useDataTableRowSelection({
    enableRowSelection,
    onRowSelectionChange,
    isSingleRowSelect,
  });

  // const tableLocalSearchProps = useDataTableLocalSearch(enableLocalSearch);

  // Establish the Tanstack table instance using provided hooks and props
  const table = useReactTable({
    // @ts-expect-error - subtype constraint issue
    columns: memoizedColumns,
    data: memoizedData,
    enableExpanding: enableExpanding && !!renderExpandedRow,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    ...defaultTableOptions,
    ...tableColumnOptions,
    ...tablePaginationProps,
    ...tableRowSelectionProps,
    ...tableSortingProps,

    // @ts-expect-error - optionValue is unexpected
    getRowId: getRowId ?? ((row: Row<T>) => row.id ?? row.optionValue),

    // TODO: Note that initialState & state are already passed as part of some hooks props above.
    // Passing them here explicitly seems to work, but feels redundant & iffy.
    // May want to leverage immer with a more precise merge strategy.
    initialState: {
      ...tablePaginationProps.initialState,
      columnVisibility: {},
    },

    onExpandedChange: setExpanded,

    state: {
      ...tableSortingProps.state,
      ...tablePaginationProps.state,
      ...tableRowSelectionProps.state,
      ...tableColumnOptions.state,
      expanded,
    },
  });

  const handleRowClick = onRowClick
    ? (rowData: T, row: Row<T>, evt: MouseEvent) => {
        onRowClick(rowData, row, evt);
        updateRowHighlighted(row.id);
      }
    : undefined;

  /**
   * Make a reference to the Tanstack table instance available to the parent component if a ref is provided.
   * @see https://stackoverflow.com/questions/68481994/react-table-get-selectedrowids-in-parent-component
   *
   * @example
   * const tableRef = useRef<TableInstance<MyRowDataType> | null>(null);
   * const getSelectedRows = () => tableRef?.current?.getSelectedRows();
   * <DataTable ref={tableRef} ... />
   */
  useImperativeHandle(ref, () => table as TanstackTable<T>);

  // Determine various display states
  const calcMaxHeight = maxHeight ? `${maxHeight}px` : undefined;
  const showEmptyState = !!emptyStateProps && !isLoading && !data.length;
  const showHeader = Boolean(
    title ||
      actions ||
      enableLocalSearch ||
      enableColumnReorder ||
      enableColumnVisibility
  );
  const includePagination =
    showPagination &&
    Boolean(
      enablePagination && (paginator?.isInfinite || table.getPageCount() > 1)
    );

  const cls = clsx({
    'DataTable-row-action': Boolean(onRowClick),
  });

  const tableActions = (
    <Stack alignItems="center" direction="row" gap={space.sm}>
      {actions}
      {(enableColumnReorder || enableColumnVisibility) && !isLoading && (
        <DataTableColumnSettings {...columnSettingProps} />
      )}
    </Stack>
  );

  return (
    <Stack spacing={4} position="relative">
      {showHeader && <DataTableHeader actions={tableActions} title={title} />}

      <TableContainer
        sx={{
          borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
          maxHeight: calcMaxHeight,
        }}
        ref={tableContainerRef}
      >
        <Table
          className={cls}
          id={tableId ? `DataTable-${tableId}` : undefined}
          ref={tableEl}
          stickyHeader
          sx={tableStyles}
        >
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {/* Add additional header cell placeholder when table is expandable */}
                {enableExpanding && !isLoading && <TableCell />}

                {headerGroup.headers.map((header) => {
                  return (
                    <DataTableHeaderCell
                      columnCount={headerGroup.headers.length}
                      header={header}
                      isLoading={isLoading}
                      key={header.id}
                      table={table}
                      tableWidth={tableElWidth}
                    />
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {isLoading ? (
              <DataTableSkeletonRows
                columnCount={table.getHeaderGroups()[0].headers.length}
              />
            ) : (
              table.getRowModel().rows.map((row) => {
                return (
                  <DataTableRow
                    columnOrder={tableColumnOptions.state?.columnOrder}
                    columnVisibility={
                      tableColumnOptions.state?.columnVisibility
                    }
                    enableExpanding={enableExpanding}
                    hidden={hiddenRowIds.includes(row.id)}
                    isColumnResizing={
                      !!table.getState().columnSizingInfo.isResizingColumn
                    }
                    isHighlighted={isRowHighlighted(row.id)}
                    key={row.id}
                    // @ts-expect-error - subtype constraint issue
                    onRowClick={handleRowClick}
                    renderExpandedRow={renderExpandedRow}
                    row={row}
                    rowId={row.id}
                  />
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showEmptyState && <EmptyState {...emptyStateProps} />}
      {includePagination && (
        <DataTablePagination
          pageSizes={PAGE_SIZES}
          paginator={paginator}
          table={table}
        />
      )}
    </Stack>
  );
};
BaseDataTable.displayName = 'DataTable';

export const DataTable = forwardRef(BaseDataTable) as <T>(
  props: DataTableProps<T> & { ref?: ForwardedRef<TanstackTable<T>> }
) => ReturnType<typeof BaseDataTable>;

function styles({ palette }: Theme) {
  return {
    '& .MuiTableHead-root .MuiTableRow-root': {
      verticalAlign: 'top',
    },
    '&.DataTable-row-action': {
      '.MuiTableRow-root:hover': {
        cursor: 'pointer',
      },
    },
    '& .DataTableHeaderCell-resizer': {
      backgroundColor: palette.divider,
      cursor: 'col-resize',
      height: '50%',
      position: 'absolute',
      right: '3px',
      top: '25%',
      width: '3px',
      userSelect: 'none',
      touchAction: 'none',
    },
  };
}

const DEFAULT_EMPTY_STATE_PROPS: EmptyStateProps = {
  size: 'medium',
  title: <FM defaultMessage="No rows to display" />,
};
