import { defineMessages } from 'react-intl';

import { V1DependencyScope } from '@endorlabs/api_client';

export const DependencyScopeMessages = defineMessages<V1DependencyScope>({
  [V1DependencyScope.Build]: { defaultMessage: 'Build' },
  [V1DependencyScope.Normal]: { defaultMessage: 'Normal' },
  [V1DependencyScope.Test]: { defaultMessage: 'Test' },
  [V1DependencyScope.Unspecified]: { defaultMessage: 'Unspecified' },
});
