import { Box } from '@mui/material';
import _random from 'lodash-es/random';
import { ReactNode, useCallback, useEffect, useState } from 'react';

interface ConfettiContainerProps {
  children: ReactNode;
  isConfetti: boolean;
  confettiCallback: () => void;
}

export const ConfettiContainer = ({
  children,
  confettiCallback,
  isConfetti,
}: ConfettiContainerProps) => {
  const [showConfetti, setShowConfetti] = useState(isConfetti);

  //Reference : https://codepen.io/danwilson/pen/jOrxNwx
  const createConfetti = useCallback(() => {
    const confettiPlayers: Animation[] = [];
    const confetti = document.querySelectorAll('.confetti');
    if (!confetti[0].animate) {
      return false;
    }
    for (let i = 0, len = confetti.length; i < len; ++i) {
      const bookmark = confetti[i] as HTMLElement;
      bookmark.innerHTML =
        '<div class="rotate"><div class="askew"></div></div>';
      const scale = Math.random() * 0.7 + 0.3;
      const player = bookmark.animate(
        [
          {
            transform: `translate3d(${
              (i / len) * 100
            }vw,-5vh,0) scale(${scale}) rotate(0turn)`,
            opacity: scale,
          },
          {
            transform: `translate3d(${
              (i / len) * 100
            }vw,30vh,0) scale(${scale}) rotate(${
              Math.random() > 0.5 ? '' : '-'
            }2turn)`,
            opacity: 1,
          },
        ],
        {
          duration: Math.random() * 3000 + 4000,
          iterations: 3,
          delay: -(Math.random() * 7000),
        }
      );
      player.playbackRate = 2;
      confettiPlayers.push(player);
    }

    setTimeout(() => {
      setShowConfetti(false);
      confettiCallback();

      const confetti = document.querySelectorAll('.confetti');
      for (let i = 0, len = confetti.length; i < len; ++i) {
        const bookmark = confetti[i] as HTMLElement;
        bookmark.style.animation = 'none'; // Stop the current animation
        bookmark.style.opacity = '0'; // Set opacity to 0 to make it disappear
      }
    }, 3500);
  }, [confettiCallback, setShowConfetti]);

  useEffect(() => {
    if (showConfetti || isConfetti) {
      createConfetti();
    }

    return () => setShowConfetti(false);
  }, [createConfetti, isConfetti, showConfetti]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      {[...Array(50)].map((_, index) => (
        <Box
          className="confetti"
          key={index}
          sx={{
            width: '1rem',
            height: '1rem',
            display: 'inline-block',
            position: 'absolute',
            top: '-1rem',
            left: '0',
            zIndex: _random(0, 20),
            '&:nth-of-type(4n)': {
              color: 'hsl(18, 100%, 50%)',
            },
            '&:nth-of-type(4n + 1)': {
              color: 'hsl(183, 100%, 50%)',
            },
            '&:nth-of-type(4n + 2)': {
              color: 'hsl(43, 100%, 50%)',
            },
            '&:nth-of-type(4n + 3)': {
              color: 'hsl(133, 100%, 90%)',
            },
            '& .rotate': {
              animation: 'driftyRotate 1s infinite both ease-in-out',
              perspective: '1000',
              '&:nth-of-type(9n)': {
                animationDuration: '2s',
              },
              '&:nth-of-type(9n + 1)': {
                animationDuration: '2.3s',
              },
              '&:nth-of-type(9n + 2)': {
                animationDuration: '1.1s',
              },
              '&:nth-of-type(9n + 3)': {
                animationDuration: '0.75s',
              },
              '&:nth-of-type(9n + 4)': {
                animationDuration: '4.3s',
              },
              '&:nth-of-type(9n + 5)': {
                animationDuration: '3.05s',
              },
              '&:nth-of-type(9n + 6)': {
                animationDuration: '2.76s',
              },
              '&:nth-of-type(9n + 7)': {
                animationDuration: '7.6s',
              },
              '&:nth-of-type(9n + 8)': {
                animationDuration: '1.78s',
              },
            },
            '& .askew': {
              '@keyframes drifty': {
                '0%': {
                  transform: 'skewY(10deg) translate3d(-250%, 0, 0)',
                },
                '100%': {
                  transform: 'skewY(-12deg) translate3d(250%, 0, 0)',
                },
              },
              background: `currentColor`,
              width: '1rem',
              height: '2rem',
              animation: 'drifty 1s infinite alternate both ease-in-out',
              perspective: '1000',
              clipPath:
                'polygon(evenodd, 0% 0%, 100% 0%, 100% 100%, 50% 90%, 0% 100%)',
              '&:nth-of-type(7n)': {
                animationDelay: '-0.6s',
                animationDuration: '2.25s',
              },
              '&:nth-of-type(7n + 1)': {
                animationDelay: '-0.879s',
                animationDuration: '3.5s',
              },
              '&:nth-of-type(7n + 2)': {
                animationDelay: '-0.11s',
                animationDuration: '1.95s',
              },
              '&:nth-of-type(7n + 3)': {
                animationDelay: '-0.246s',
                animationDuration: '0.85s',
              },
              '&:nth-of-type(7n + 4)': {
                animationDelay: '-0.43s',
                animationDuration: '2.5s',
              },
              '&:nth-of-type(7n + 5)': {
                animationDelay: '-0.56s',
                animationDuration: '1.75s',
              },
              '&:nth-of-type(7n + 6)': {
                animationDelay: '-0.76s',
                animationDuration: '1.5s',
              },
            },
          }}
        ></Box>
      ))}
      {children}
    </Box>
  );
};
