import { useMutation } from 'react-query';

import { SBOMExportServiceApi, V1ExportedSBOM } from '@endorlabs/api_client';

import { ResourceMutateOptions } from './types';
import { getClientConfiguration } from './utils';

interface SBOMExportWriteParams {
  namespace: string;
  resource: V1ExportedSBOM;
}

type CreateSBOMExportOptions = ResourceMutateOptions<
  V1ExportedSBOM,
  SBOMExportWriteParams
>;

const getApiService = () => new SBOMExportServiceApi(getClientConfiguration());

const createSBOMExport = async (params: SBOMExportWriteParams) => {
  const api = getApiService();
  const resp = await api.sBOMExportServiceCreateSBOMExport(
    params.namespace,
    params.resource,
    {
      // increase timeout, to avoid timeouts from potentially large SBOM exports
      headers: {
        'Request-Timeout': 300,
      },
    }
  );
  return resp.data as V1ExportedSBOM;
};

export const useCreateSBOMExport = (opts: CreateSBOMExportOptions = {}) => {
  return useMutation(
    (params: SBOMExportWriteParams) => createSBOMExport(params),
    opts
  );
};
