import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  useDeleteSemgrepRules,
  useFeatureFlags,
  useListSemgrepRules,
  useUpdateSemgrepRules,
} from '@endorlabs/queries';
import { EmptyState, useConfirmationDialog } from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { getPoliciesPath, useFullMatch } from '../../../routes';
import { FormUpsertEditSASTRule } from './FormUpsertEditSASTRule';

export const SASTRuleEditPage = () => {
  const { params } = useFullMatch();
  const { policyUuid: sastRuleUuid, namespace } = params;
  const { spacing } = useTheme();

  const { ENABLE_SAST_FEATURE: isSASTEnabled } = useFeatureFlags();
  const navigate = useNavigate();
  const qDeleteRule = useDeleteSemgrepRules({
    onSuccess: () => {
      navigate({
        to: getPoliciesPath({
          tenantName: namespace,
          section: 'sast',
        }),
      });
    },
  });
  const qEditRule = useUpdateSemgrepRules({
    onSuccess: () => {
      navigate({
        to: getPoliciesPath({
          tenantName: namespace,
          section: 'sast',
        }),
      });
    },
  });
  const qSelectedSASTRule = useListSemgrepRules(
    namespace,
    {
      sort: { path: 'meta.name' },
      filter: `spec.rule.metadata.endor_category == "vulnerability" and uuid == "${sastRuleUuid}"`,
    },
    {
      enabled: !!namespace && isSASTEnabled && !!sastRuleUuid,
    }
  );

  const sastRule = useMemo(() => {
    return qSelectedSASTRule.data?.objects[0] ?? ({} as SemgrepRulesResource);
  }, [qSelectedSASTRule.data?.objects]);

  const RuleDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,
    descriptionText: (
      <FM defaultMessage="Rule will no longer be available in the namespace" />
    ),
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qDeleteRule.mutate({
          namespace,
          resource: { uuid: props.uuid },
        });
      }
    },
    titleText: <FM defaultMessage="Delete this Rule?" />,
  });

  const RuleDisableConfirmation = useConfirmationDialog<{
    resource: SemgrepRulesResource;
  }>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Disable" />,
    descriptionText: (
      <FM defaultMessage="Rule will no longer be applied in the namespace" />
    ),
    onConfirm: (props) => {
      if (props) {
        const { resource } = props;
        qEditRule.mutate({
          namespace,
          mask: 'spec.disabled',
          resource: {
            ...resource,
            spec: {
              ...resource.spec,
              disabled: !resource.spec.disabled,
            },
          },
        });
      }
    },
    titleText: <FM defaultMessage="Disable this Rule?" />,
  });

  const onSave = (resource: SemgrepRulesResource) => {
    qEditRule.mutate({
      namespace,
      resource,
    });
  };

  return (
    <Grid container direction="row" spacing={6}>
      <Grid item xs={12}>
        <PageHeader
          breadcrumbsLinks={[
            {
              label: <FM defaultMessage="SAST Rule" />,
              url: getPoliciesPath({
                tenantName: namespace,
                section: `sast/edit-sast-rule/${sastRuleUuid}`,
              }),
            },
          ]}
          title={<FM defaultMessage="Edit SAST Rule" />}
        />
      </Grid>

      {!isSASTEnabled ? (
        <Grid item xs={12}>
          <EmptyState
            size="large"
            title={<FM defaultMessage="SAST Rule is not found" />}
            description={
              <FM defaultMessage="The rule id provided is invalid, the SAST Rule to edit is not found. " />
            }
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card>
            <CardHeader
              sx={{
                paddingTop: spacing(8),
                marginRight: spacing(4),
              }}
              title={
                <Typography variant="body1">
                  <FM defaultMessage="Author a custom SAST rule from scratch with yaml language" />
                </Typography>
              }
            />
            <CardContent sx={{ overflow: 'hidden' }}>
              {qSelectedSASTRule.isLoading ? (
                <Skeleton />
              ) : (
                <FormUpsertEditSASTRule
                  resource={sastRule}
                  onDelete={RuleDeleteConfirmation.openDialog}
                  onDisable={RuleDisableConfirmation.openDialog}
                  onSave={onSave}
                  onCancel={() =>
                    navigate({
                      to: getPoliciesPath({
                        tenantName: namespace,
                        section: 'sast',
                      }),
                    })
                  }
                />
              )}
            </CardContent>
            <RuleDeleteConfirmation.Dialog
              {...RuleDeleteConfirmation.dialogProps}
            />
            <RuleDisableConfirmation.Dialog
              {...RuleDisableConfirmation.dialogProps}
            />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
