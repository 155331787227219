import { Chip, Stack, Tooltip, Typography } from '@mui/material';

import { NamespaceResource } from '@endorlabs/endor-core/Namespace';
import { CopyToClipboardButton } from '@endorlabs/ui-common';

/**
 * Label for a Namespace Resource
 *
 * - Handles text truncation behavior
 * - Exposes copy button
 */
export const NamespaceLabel = ({
  namespaceResource,
  showFullNamespace,
}: {
  namespaceResource: NamespaceResource;
  showFullNamespace?: boolean;
}) => {
  const namespace = namespaceResource.spec.full_name;
  const { description, name, tags } = namespaceResource.meta;

  const tooltipDisplay = (
    <Stack
      direction="column"
      onClick={(e) => e.stopPropagation()} // HACK: prevent a click in the tooltip from triggering a select on the item
      padding={1}
      spacing={2}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography component="span" sx={{ wordBreak: 'break-word' }}>
          {namespace}
        </Typography>

        <CopyToClipboardButton
          value={namespace}
          sx={(t) => ({
            // match the text color used in the tooltips
            color: t.palette.getContrastText(t.palette.text.primary),
            '&:hover': {
              color: t.palette.getContrastText(t.palette.text.primary),
            },
          })}
        />
      </Stack>

      {description && (
        <Typography component="span" variant="body2">
          {description}
        </Typography>
      )}

      {!!tags?.length && (
        <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2}>
          {tags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              sx={(t) => ({
                // match the text color used in the tooltips
                backgroundColor: t.palette.getContrastText(
                  t.palette.text.primary
                ),
                color: t.palette.text.primary,
              })}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );

  return (
    <Tooltip
      title={tooltipDisplay}
      // NOTE: delay prevents over-eager tooltips
      enterNextDelay={300}
    >
      <Typography
        component="div"
        sx={{
          textAlign: 'left',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {showFullNamespace ? namespace : name}
      </Typography>
    </Tooltip>
  );
};
