import GitHub from '@mui/icons-material/GitHub';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonExternalLink,
  ButtonPrimary,
  ButtonSecondary,
  IconLock,
} from '@endorlabs/ui-common';

import { getEnv } from '../../../constants';
import {
  OnboardingApproaches,
  useOnboardingSteps,
} from '../../../domains/Onboarding';
import { useLicensingInfo } from '../../../providers';
import { NamedRoutes } from '../../../routes/constants';
import { NewProjectGithubActionProps } from './types';

const ENV = getEnv();

/**
 * This view provides content/instructions for creating a new project using GitHub Actions
 * https://app.staging.endorlabs.com/t/demo/onboard/github-action
 */
export const NewProjectGithubAction = ({
  stepRecord,
}: NewProjectGithubActionProps) => {
  const sx = styles(useTheme());
  const [enablePRScansFlag, setEnablePRScansFlag] = useState<boolean>(false);
  const { hasAdditionalLicenses } = useLicensingInfo();
  const { ENABLE_AUTOMATED_GITHUB_PR: isAutomatedGitHubPrEnabled } =
    useFeatureFlags();
  const isOnlySBOMLicensePresent = !hasAdditionalLicenses(
    SpecEndorLicenseFeatureType.SbomIngestion
  );
  const {
    completeStep,
    getIsStepComplete,
    uncompleteStep,
    setCurrentApproach,
  } = useOnboardingSteps();
  const isCompleted = !_isEmpty(stepRecord)
    ? getIsStepComplete(stepRecord.id)
    : false;

  const handleGithubAppClick = () => {
    if (!_isEmpty(stepRecord)) {
      setCurrentApproach(OnboardingApproaches.GITHUB_APP);
    } else {
      /* Handle case if user has not selected 'Mark complete' step in onboarding.
       * And user returns to onboarding by default
       */
      setCurrentApproach(undefined);
    }
  };

  return (
    <Stack sx={sx.contentSection} spacing={2}>
      {_isEmpty(stepRecord) && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            marginBottom: 3,
            width: '700px',
          }}
          action={
            <ButtonExternalLink linkProps={{ to: NamedRoutes.DOCS_GITHUB_APP }}>
              <FM defaultMessage="GitHub App Docs" />
            </ButtonExternalLink>
          }
        >
          <AlertTitle
            sx={({ spacing }) => ({
              fontSize: spacing(3.5),
              fontWeight: '400',
            })}
          >
            <FM defaultMessage="Limitations exist, see the docs for more details." />
          </AlertTitle>
        </Alert>
      )}
      <Stack spacing={2}>
        <Typography paragraph variant="body2">
          <FM defaultMessage="Install the GitHub app to automatically scan all of the repositories in your organization." />
        </Typography>
        {isAutomatedGitHubPrEnabled && (
          <FormControlLabel
            sx={sx.automatedPRFlag}
            control={
              <Checkbox
                onChange={(e) => {
                  setEnablePRScansFlag(e.target.checked);
                }}
                value={enablePRScansFlag}
                name="spec.github_config.enable_pr_scans"
              />
            }
            label={
              <>
                <span style={{ fontWeight: 'bold' }}>
                  Enable Automated Pull Requests
                </span>
                <FormHelperText>Requires elevated permissions </FormHelperText>
              </>
            }
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        {!_isEmpty(stepRecord) && (
          <Box>
            {isCompleted ? (
              <ButtonSecondary
                variant="outlined"
                onClick={() => uncompleteStep(stepRecord.id)}
              >
                <FM defaultMessage="Mark Incomplete" />
              </ButtonSecondary>
            ) : (
              <ButtonPrimary
                onClick={() => {
                  completeStep(stepRecord.id);
                  setCurrentApproach(undefined);
                }}
              >
                <FM defaultMessage="Mark Complete" />
              </ButtonPrimary>
            )}
          </Box>
        )}
        <Tooltip
          title={
            isOnlySBOMLicensePresent ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box pr={2}>
                  <IconLock />
                </Box>
                <FM defaultMessage="License required to scan using GitHub app" />
              </Box>
            ) : (
              ''
            )
          }
        >
          <span>
            <Button
              color="primary"
              href={
                enablePRScansFlag ? ENV.URL_GITHUB_APP_PRO : ENV.URL_GITHUB_APP
              }
              onClick={handleGithubAppClick}
              startIcon={<GitHub />}
              variant="contained"
              disabled={isOnlySBOMLicensePresent}
            >
              <FM
                defaultMessage="Install {githubAppName} App"
                values={{
                  githubAppName: enablePRScansFlag ? 'GitHub Pro' : 'GitHub',
                }}
              />
            </Button>
          </span>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const styles = ({ palette }: Theme) => ({
  contentSection: {
    borderLeft: `1px solid ${palette.divider}`,
    marginLeft: 2,
    paddingX: 3,
    paddingBottom: 4,
  },
  automatedPRFlag: {
    alignItems: 'flex-start',
    marginBottom: 2,
  },
});
