import { Grid, Typography } from '@mui/material';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { Link } from '@endorlabs/ui-common';
import logo from '@endorlabs/ui-common/assets/logos/logo-endor-endorctl-init.svg';

import { NamedRoutes } from '../../../routes/constants';

export const EndorctlPage = () => {
  const { formatMessage: fm } = useIntl();

  return (
    <Grid
      container
      spacing={2}
      height="90%"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <img
          alt={fm({ defaultMessage: 'Endor Labs' })}
          src={logo}
          width={300}
        />
      </Grid>

      <Grid item>
        <Typography
          variant="h1"
          textAlign="center"
          sx={{
            fontWeight: '400',
            fontSize: '32px',
            lineHeight: '65px',
          }}
        >
          <FM defaultMessage="Welcome, you have successfully authenticated!" />
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ fontWeight: '400', fontSize: '16px' }}
        >
          <FM defaultMessage="You may now close your browser window." />
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ fontWeight: '400', fontSize: '16px' }}
        >
          <FM defaultMessage="Please go back to the terminal to complete the initialization workflow." />
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ fontWeight: '400', fontSize: '16px' }}
        >
          <FM
            defaultMessage="To learn more about using endorctl, please visit <link>our documentation</link>."
            values={{
              link: (value) => (
                <Link target="_blank" to={NamedRoutes.DOCS_ENDORCTL}>
                  {value}
                </Link>
              ),
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};
