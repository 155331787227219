import { RowData } from '@tanstack/react-table';

import { DataTableColumnDef, getDataTableColumnTypes } from '../types';

export const buildDataTableColumnDefs = <T extends RowData>(
  colDefs: DataTableColumnDef<T>[],
  props?: unknown
) => {
  const resolvedColDefs = colDefs.map((colDef) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { colType, hidden, ...baseColDef } = colDef;

    // TODO: Establish a default header component. Tricky with I18n.
    // If colType is defined, shallow merge definitions with base colType
    if (colType === undefined) {
      return baseColDef;
    } else {
      const colTypeDef = getDataTableColumnTypes(colType, props);
      return { ...colTypeDef, ...baseColDef };
    }
  });

  return resolvedColDefs;
};
