import { useMemo } from 'react';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  RepositoryResource,
  RepoVersionResource,
  useGroupDependencyMetadata,
  useListFindings,
} from '@endorlabs/queries';
import { BooleanDisplay, UIProjectUtils } from '@endorlabs/ui-common';

import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../../constants';
import { useProjectVersionMetadata } from '..';
import { getLanguagesDisplay } from '../utils';

export const useProjectDetailMetadata = ({
  namespace,
  project,
  isLoading: projectIsLoading,
  repository,
  repositoryVersion,
}: {
  namespace: string;
  project?: ProjectResource;
  isLoading: boolean;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}) => {
  const relatedFindingsFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      repositoryVersion
    );

  const relatedDependencyMetadatFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      repositoryVersion,
      { key: 'spec.importer_data.project_uuid' }
    );

  const qListFindings = useListFindings(
    namespace,
    {
      enabled: !!relatedFindingsFilterExpression,
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: relatedFindingsFilterExpression,
      group: {
        aggregation_paths:
          'spec.level,spec.finding_tags,spec.finding_categories',
      },
    }
  );

  const qGroupDependencyMetadata = useGroupDependencyMetadata(
    namespace,
    { enabled: !!relatedDependencyMetadatFilterExpression },
    {
      filter: relatedDependencyMetadatFilterExpression,
      group: {
        aggregation_paths: 'meta.name',
        unique_value_paths: 'spec.dependency_data.direct',
      },
    }
  );

  const { dependenciesCount, dependenciesIsLoading } = useMemo(() => {
    const dependenciesIsLoading = qGroupDependencyMetadata.isLoading;

    const dependenciesCount = {
      total: 0,
      direct: 0,
      indirect: 0,
    };

    for (const group of Object.values(
      qGroupDependencyMetadata.data?.groups ?? {}
    )) {
      const hasDirectDependency = group.unique_values?.[
        'spec.dependency_data.direct'
      ].some((d) => !!d);

      dependenciesCount.total++;

      if (hasDirectDependency) {
        dependenciesCount.direct++;
      } else {
        dependenciesCount.indirect++;
      }
    }

    return { dependenciesCount, dependenciesIsLoading };
  }, [qGroupDependencyMetadata.data, qGroupDependencyMetadata.isLoading]);

  const dependenciesDisplay =
    !!dependenciesCount.direct || !!dependenciesCount.indirect
      ? `${dependenciesCount.direct} Direct \u00B7 ${dependenciesCount.indirect} Transitive`
      : undefined;

  const findingsByLevelAndReachability =
    qListFindings?.data?.group_response?.groups ?? {};

  const {
    fileStats,
    isMetricLoading,
    scorecardMetric,
    packageCount,
    packageEcosystems,
  } = useProjectVersionMetadata(project, repositoryVersion);

  const scoreCard = scorecardMetric?.spec.metric_values?.scorecard.score_card;
  const scoreFactors =
    scorecardMetric?.spec?.metric_values?.scorefactor?.score_factor_list
      ?.score_factors;
  const hasCodeowners = fileStats?.has_codeowners;

  const repositoryVersionUuid = repositoryVersion?.uuid;

  const { languages, repository_license, owner } = repository?.spec || {};

  const topMetadataProps = useMemo(() => {
    const hasProjectMetadata = !!dependenciesCount.total || !!packageCount;

    const hasRepositoryVersionMetadata =
      !!repositoryVersionUuid ||
      !!languages?.ingested_object?.raw ||
      !!repository_license?.name ||
      !!owner;

    const packageMetadata = {
      packages: packageCount,
      packageEcosystems,
      dependencies: dependenciesDisplay,
      dependenciesCount,
      uuid: project?.uuid,
    };

    const repositoryVersionMetadata = {
      hasCodeowners: (
        <BooleanDisplay highlightFalse={false} value={Boolean(hasCodeowners)} />
      ),
      languages: getLanguagesDisplay(languages?.ingested_object?.raw),
      license: repository_license?.name,
      versionUuid: repositoryVersionUuid,
    };

    const isLoadingTopMetadata = dependenciesIsLoading || projectIsLoading;

    return {
      hasProjectMetadata,
      hasRepositoryVersionMetadata,
      isLoadingTopMetadata,
      packageMetadata,
      repositoryVersionMetadata,
    };
  }, [
    dependenciesCount,
    dependenciesDisplay,
    dependenciesIsLoading,
    hasCodeowners,
    languages,
    repository_license,
    owner,
    packageCount,
    packageEcosystems,
    project,
    projectIsLoading,
    repositoryVersionUuid,
  ]);

  return {
    findingsByLevelAndReachability,
    repositoryVersion,
    isMetricLoading,
    scoreCard,
    scoreFactors,
    topMetadataProps,
  };
};
