import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { isString } from 'lodash-es';
import { ReactNode, useEffect } from 'react';
import {
  ControllerProps,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';

import {
  ChecklistInput,
  ChecklistInputOption,
  ChecklistInputProps,
} from './inputs';

export type ControlledChecklistProps<T extends FieldValues> = Pick<
  ControllerProps<T>,
  'name'
> &
  Pick<ChecklistInputProps, 'dense' | 'groupBy' | 'options'> & {
    label?: ReactNode;
    helperText?: ReactNode;
    onChange?: (values: ChecklistInputOption[]) => void;
  };

export const ControlledChecklist = <T extends FieldValues>({
  dense,
  label,
  groupBy,
  helperText,
  name,
  options,
}: ControlledChecklistProps<T>) => {
  const { clearErrors, control } = useFormContext<T>();
  const { field, fieldState } = useController({
    control,
    name,
  });

  const handleChange = (values: ChecklistInputOption[]) => {
    field.onChange(values);
    field.onBlur();
  };

  useEffect(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  // HACK: Handle simple array of strings as value
  const conformingValue = field.value.every((v: unknown) => isString(v))
    ? field.value.map((v: string) => ({ key: v }))
    : field.value;

  const errorMessage = fieldState.error?.message;

  return (
    <FormControl component="fieldset" variant="standard" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <ChecklistInput
        dense={dense}
        groupBy={groupBy}
        onChange={handleChange}
        options={options}
        value={conformingValue}
      />

      {(errorMessage ?? helperText) && (
        <FormHelperText error={!!errorMessage}>
          {errorMessage ?? helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
