import { Box, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { Link } from '@endorlabs/ui-common';

import { InfoDrawerContent } from '../../../components';

export const EnvVariablesInfoDrawer = () => {
  return (
    <InfoDrawerContent
      title={<FM defaultMessage="Getting Environment Variables for Endorctl" />}
    >
      <Box sx={styles(useTheme())}>
        <dt>Getting Your Personal Access Token</dt>
        <dd>
          <FM
            defaultMessage="A developers personal access token for GitHub or GitLab is defined by the environment variable GITHUB_TOKEN. Personal access tokens in GitHub and GitLab are used by Endor Labs to authenticate to private repositories and to gather information from the GitHub API. <br></br><br></br> If you do not have a personal access token, you may create one by following your source control systems online documentation. <br></br><br></br>For GitHub: <link>https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token</link> <br></br><br></br> For GitLab: <link>https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html#create-a-personal-access-token</link> <br></br><br></br> Example GitHub token: <code>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</code>. <br></br>Example GitLab Token: <code>glpat-fb_h2agCffDf9hArewuK</code>" // endorctl:allow
            values={codeHandling}
          />
        </dd>

        <dt>Getting Your Endor Labs Namespace</dt>
        <dd>
          <FM
            defaultMessage="The tenant name or namespace within Endor Labs you use to identify your organization is defined by the environment variable ENDOR_NAMESPACE. Your namespace may be found at the top left hand side of your screen. Your current tenant is displayed in the on screen instructions when available."
            values={codeHandling}
          />
        </dd>

        <dt>Getting Your API Key</dt>
        <dd>
          <FM
            defaultMessage="The API key associated with your Endor Labs namespace is defined by the environment variable ENDOR_API_CREDENTIALS_KEY. This key helps authenticate the endorctl client to the Endor Labs API. To generate a new API key, please go to Manage > API Keys in the left hand navigation. <br></br><br></br>Example API Key: <code>6lv9mQDi</code>."
            values={codeHandling}
          />
        </dd>

        <dt>Getting Your API Key Secret</dt>
        <dd>
          <FM
            defaultMessage="The API key secret associated with your Endor Labs namespace is defined by the environment variable ENDOR_API_CREDENTIALS_SECRET. This secret key helps authenticate your API key to the Endor Labs API. To generate a new API key, please go to Manage > API Keys in the left hand navigation. <br></br><br></br>Example API Key Secret: <code>aomwpb79oWTFehpq</code>."
            values={codeHandling}
          />
        </dd>

        <dt>Getting Your GitHub API URL</dt>
        <dd>
          <FM
            defaultMessage="The GitHub API URL used for API requests to Github and is defined by the environment variable ENDOR_SCAN_GITHUB_API_URL.  ENDOR_SCAN_GITHUB_API_URL should typically be set to the fully qualified domain name used to access GitHub Enterprise Server. This environment variable should only be set if you are using a self hosted GitHub Enterprise Server. <br></br><br></br>Example: https://github.endorlabs.com"
            values={codeHandling}
          />
        </dd>
      </Box>
    </InfoDrawerContent>
  );
};

const codeHandling = {
  code: (v: ReactNode) => <code>{v}</code>,
  br: () => <br></br>,
  link: (value: ReactNode) => (
    <Link to={value as string} sx={{ wordBreak: 'break-all' }}>
      {value}
    </Link>
  ),
};

function styles(theme: Theme) {
  return {
    '& dt': {
      fontWeight: 700,
    },
    '& dd': {
      marginBottom: theme.spacing(6),
      marginLeft: 0,
    },
  };
}
