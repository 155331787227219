import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { IconChevronDown } from '@endorlabs/ui-common';

import { FacetFilterDefinition } from './types';

export interface ControlsFacetFilterSelectProps {
  facet: FacetFilterDefinition;
  value: string;
  onChange: (value: string) => void;
}

export const ControlsFacetFilterSelect = ({
  facet,
  value,
  onChange,
}: ControlsFacetFilterSelectProps) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  return (
    <Select
      id={facet.id}
      sx={{
        // remove the border
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        // set color for dropdown arrow
        '& .MuiSelect-icon': {
          color: 'primary.main',
          fontSize: 16,
        },
      }}
      IconComponent={IconChevronDown}
      displayEmpty
      value={value ?? ''}
      onChange={handleChange}
      renderValue={(selected: string) => {
        return (
          <Typography
            component="span"
            variant="inherit"
            color="primary"
            fontWeight={selected ? 'bold' : ''}
          >
            {facet.values?.find(({ value }) => value === selected)?.label ??
              facet.label}
          </Typography>
        );
      }}
    >
      {facet.values?.map(({ key, value, label }) => (
        <MenuItem key={key} value={value} dense>
          <ListItemText
            id={`FacetFilterDropdown--${key}`}
            primary={label}
            primaryTypographyProps={{ variant: 'body1' }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
