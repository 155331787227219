export enum Colors {
  PURPLE = 'hsla(269, 67%, 48%, 1)',
  BLUE = 'hsla(203, 100%, 36%, 1)',
  RED = '#F44336',
  ORANGE = '#FF9800',
  YELLOW = 'hsla(47, 100%, 50%, 1)',
  BLACK = '#222222',
  WHITE = '#FFFFFF',
  GREEN = `hsla(177, 100%, 17%, 1)`,
}

export const barChartColors = [Colors.PURPLE, Colors.YELLOW, Colors.BLUE];
