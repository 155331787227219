import { AuthenticatedRouteGuard } from '../../../components/AuthenticatedRouteGuard';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const TenantProjectsRoute = () => {
  // Restrict access to sub-routes unless user has read access to `projects`
  return <AuthenticatedRouteGuard resourceKind="Project" />;
};

export const Route = createFileRoute('/t/:namespace/projects')({
  component: TenantProjectsRoute,
});
