import {
  Box,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import { IconChevronDown } from '@endorlabs/ui-common';

type MultiSelectOption<TValue extends string> = {
  value: TValue;
  label: ReactNode;
  disabled?: boolean;
};

export interface MultiSelectInputProps<TValue extends string> {
  label: ReactNode;
  prefix?: ReactNode;
  menuProps?: Partial<MenuProps>;
  onChange: (value: TValue[]) => void;
  options: MultiSelectOption<TValue>[];
  value: TValue[];
}

export const MultiSelectInput = <TValue extends string>({
  label,
  prefix,
  menuProps,
  onChange,
  options,
  value,
}: MultiSelectInputProps<TValue>) => {
  const [internalValue, setInternalValue] = useState<TValue[]>([]);

  // update the facet value on change
  useEffect(() => {
    setInternalValue(value ?? []);
  }, [value]);

  const handleChange = (event: SelectChangeEvent<TValue[]>) => {
    const {
      target: { value },
    } = event;

    setInternalValue(
      // On autofill we get a stringified value.
      typeof value === 'string' ? (value.split(',') as TValue[]) : value
    );
  };

  const handleClose = () => {
    onChange(internalValue);
  };

  return (
    <Select
      sx={{
        '& .MuiOutlinedInput-input': {
          paddingLeft: 1,
        },
        // remove the border
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        // set color for dropdown arrow
        '& .MuiSelect-icon': {
          color: 'primary.main',
          fontSize: 16,
        },
      }}
      IconComponent={IconChevronDown}
      MenuProps={menuProps}
      multiple
      displayEmpty
      value={internalValue}
      onChange={handleChange}
      onClose={handleClose}
      renderValue={(selected: string[]) => {
        return (
          <Box
            color="primary"
            component="span"
            sx={({ palette, typography }) => ({
              color: palette.primary.main,
              fontSize: typography.pxToRem(13),
              fontWeight: selected.length ? 700 : 500,
              lineHeight: typography.button.lineHeight,
            })}
          >
            {label}
          </Box>
        );
      }}
    >
      {prefix}
      {options.map(({ value, label, disabled = false }) => {
        return (
          <MenuItem key={value} value={value} dense disabled={disabled}>
            <ListItemIcon>
              <Checkbox
                checked={internalValue.includes(value)}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': `MultiSelectInput-option--${value}`,
                }}
              />
            </ListItemIcon>

            <ListItemText
              id={`MultiSelectInput--${value}`}
              primary={label}
              primaryTypographyProps={{ component: 'span', variant: 'body1' }}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
};
