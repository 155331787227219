import { FormattedMessage as FM } from 'react-intl';

import { V1DependencyScope } from '@endorlabs/api_client';

import { DependencyScopeMessages } from '../locales';

interface DependencyScopeLabelProps {
  value?: V1DependencyScope;
}

export const DependencyScopeLabel = ({
  value = V1DependencyScope.Unspecified,
}: DependencyScopeLabelProps) => {
  const message = DependencyScopeMessages[value];
  return message ? <FM {...message} /> : value;
};
