import { Route } from '@tanstack/react-location';

export const withRouteChildren = (
  route: Route,
  children: Route[],
  options?: {
    stripPrefix?: string;
  }
) => {
  const { children: existing = [] } = route;
  const { stripPrefix = route.path } = options ?? {};

  if (!stripPrefix) return { ...route, children: [...existing, ...children] };

  const mapped = children.map((r) => {
    if (r.path?.startsWith(stripPrefix)) {
      return { ...r, path: r.path.slice(stripPrefix.length) };
    }

    return r;
  });

  return { ...route, children: [...existing, ...mapped] };
};
