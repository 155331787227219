import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ReactNode } from 'react';

import { FileDownloadFileType } from '../../hooks';
import { CopyToClipboardButton } from '../CopyToClipboardButton';
import {
  FileDownloadButton,
  FileDownloadButtonProps,
} from '../FileDownloadButton';
import { HighlightCodeBlock } from './HighlightCodeBlock';

const BaseCodeBlock = styled('pre')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  margin: 0,
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  overflowX: 'scroll',
}));

interface CodeBlockProps {
  enableClipboard?: boolean;
  enableHighlight?: boolean;
  downloadProps?: FileDownloadButtonProps;
  filetype?: FileDownloadFileType;
  header?: ReactNode;
  value: string;
}

export const CodeBlock = ({
  enableClipboard = true,
  enableHighlight = false,
  downloadProps,
  filetype,
  header,
  value,
}: CodeBlockProps) => {
  return (
    <>
      {enableHighlight && (
        <HighlightCodeBlock
          downloadProps={downloadProps}
          header={header}
          value={value}
          filetype={filetype ? filetype : 'undefined'}
        />
      )}

      {!enableHighlight && (
        <Box position="relative">
          <BaseCodeBlock>
            {header && <Box>{header}</Box>}
            {value}
            {enableClipboard && (
              <Box position="absolute" top={0} right={downloadProps ? 40 : 0}>
                <CopyToClipboardButton size="medium" value={value} />
              </Box>
            )}
            {downloadProps && (
              <Box position="absolute" top={0} right={0}>
                <FileDownloadButton size="medium" {...downloadProps} />
              </Box>
            )}
          </BaseCodeBlock>
        </Box>
      )}
    </>
  );
};
