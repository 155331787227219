import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1Conflict } from '@endorlabs/api_client';
import {
  DrawerAccordion,
  PackageVersionNameDisplay,
} from '@endorlabs/ui-common';

export const PotentialConflictDisplay = ({
  conflict,
  isExpanded,
}: {
  conflict: V1Conflict;
  isExpanded: boolean;
}) => {
  const { space } = useTheme();
  const currentName = conflict.conflicting_current ?? '';
  const upgradeName = conflict.conflicting_upgrade ?? '';
  const importingDependencies = [
    ...(conflict.conflicting_directs_current ?? []),
    ...(conflict.conflicting_directs_upgrade ?? []),
  ];
  return (
    <DrawerAccordion
      align="start"
      expanded={isExpanded}
      id={`${currentName}-${upgradeName}`}
      titleNode={
        <PackageVersionNameDisplay
          packageVersion={{ meta: { name: currentName } }}
          size="medium"
        />
      }
    >
      <Stack gap={space.sm} paddingX={space.sm}>
        <Stack gap={space.xs}>
          <Typography variant="overline">
            <FM defaultMessage="Conflicting Transitive Dependencies" />
          </Typography>
          <Stack paddingLeft={space.sm} paddingTop={0} spacing={space.xs}>
            <PackageVersionNameDisplay
              packageVersion={{ meta: { name: currentName } }}
              showVersion
              size="medium"
            />
            <PackageVersionNameDisplay
              packageVersion={{ meta: { name: upgradeName } }}
              showVersion
              size="medium"
            />
          </Stack>
        </Stack>
        <Stack gap={space.xs}>
          <Typography variant="overline">
            <FM defaultMessage="Used By Direct Dependencies" />
          </Typography>
          <Stack paddingLeft={space.sm} paddingTop={0} spacing={space.xs}>
            {importingDependencies.map((dependencyName) => (
              <PackageVersionNameDisplay
                key={dependencyName}
                packageVersion={{ meta: { name: dependencyName } }}
                size="medium"
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </DrawerAccordion>
  );
};
