import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export interface GaugeChartProps {
  animate?: boolean;
  color?: string;
  Icon?: ReactNode;
  percentage?: number;
  size?: number;
  thickness?: number;
  title?: ReactNode;
  totalCount: number;
  trackColor?: string;
  trackThickness?: number;
  value: number;
}

export const GaugeChart = ({
  animate = true,
  color = '#ff0000',
  Icon,
  percentage = 0,
  size = 120,
  thickness = 10,
  title,
  totalCount = 0,
  trackColor = '#dddddd',
  trackThickness = 5,
  value = 0,
}: GaugeChartProps) => {
  const theme = useTheme();
  const { formatNumber } = useIntl();

  const [progress, setProgress] = useState(0);

  const center = size / 2;
  const radius = (size - thickness) / 2;
  const viewbox = `0 0 ${size} ${size}`;

  const isPercentage = !!percentage;
  const isCount = !!value && !!totalCount;

  // percentage takes priority over absolute count, if present
  const percent = isPercentage
    ? percentage
    : isCount && totalCount > 0
    ? (value / totalCount) * 100
    : 0;
  const percentFraction = Number((percent / 100).toFixed(2));

  const circumference = radius * Math.PI * 2;
  const arcLength = (progress * circumference) / 100;
  const blankLength = circumference - arcLength;

  useEffect(() => {
    setProgress(percent);
  }, [percent]);

  return (
    <Stack
      width={size}
      justifyContent="center"
      alignItems="center"
      gap={theme.space.sm}
    >
      {title && <Typography variant="h3">{title}</Typography>}
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
        }}
      >
        <svg width={size} height={size} viewBox={viewbox}>
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill={color}
            fillOpacity={0.1}
            stroke="none"
            strokeWidth={thickness}
          />
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill="none"
            stroke={trackColor}
            strokeWidth={trackThickness}
          />
          <circle
            cx={center}
            cy={center}
            r={radius}
            fill="none"
            stroke={color}
            strokeWidth={thickness}
            strokeDasharray={`${arcLength} ${blankLength}`}
            strokeLinecap="round"
            style={{ transition: animate ? 'all 0.5s ease-out' : 'none' }}
            transform={`rotate(90 ${size / 2} ${size / 2})`}
          />
        </svg>
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack alignItems="center" spacing={2}>
            {Icon}
            <Typography variant="h3" color={color}>
              {!value
                ? '-'
                : formatNumber(percentFraction, { style: 'percent' })}
            </Typography>
            {!percentage && (
              <Typography variant="body2">{totalCount}</Typography>
            )}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
