import { FormattedMessage as FM } from 'react-intl';

import { SpecInvitationState } from '@endorlabs/api_client';

import { InvitationStatusMessages } from '../locales';

export interface InvitationStatusLabelProps {
  value?: SpecInvitationState;
}

export const InvitationStatusLabel = ({
  value = SpecInvitationState.Unspecified,
}: InvitationStatusLabelProps) => {
  const message = InvitationStatusMessages[value];
  return <FM {...message} />;
};
