import { Theme } from '@mui/material';

export const getSelectOverrides = ({ spacing }: Theme) => {
  return {
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          '&.MuiInput-underline, &.MuiInput-underline.Mui-focused, &.MuiInput-underline:hover':
            {
              '&::before, &::after': {
                borderColor: 'transparent',
              },
            },

          '& .MuiSelect-icon': {
            fontSize: '16px',
          },
        },

        outlined: {
          '&.MuiInputBase-inputSizeSmall': {
            paddingBottom: 0,
            paddingTop: 0,
          },
        },

        standard: {
          borderRadius: '2px',
          padding: spacing(1, 2),
        },
      },
    },
  };
};
