import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ControlledTextField } from '../../../components';
import { REGEX_EMAIL_VALIDATION } from '../../../constants';

// The fields for Email action are a subset of _all_ form fields
type EmailActionTypeFieldValues = {
  'spec.action.email_config.receivers_addresses': string;
};

/**
 * Form fields for Email action type. To be used with {@see FormUpsertNotificationTarget}
 */
export const EmailActionTypeFields = () => {
  const { control } = useFormContext<EmailActionTypeFieldValues>();
  const { formatMessage: fm } = useIntl();

  return (
    <>
      <ControlledTextField
        control={control}
        // @ts-expect-error - need string here
        defaultValue=""
        name="spec.action.email_config.receivers_addresses"
        label={fm({ defaultMessage: 'Email addresses' })}
        placeholder={fm({
          defaultMessage: 'user1@myorg.com, user2@myorg.com',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          validate: (value: string) => {
            return (
              ('string' === typeof value &&
                value
                  .split(',')
                  .map((v: string) => v.trim().match(REGEX_EMAIL_VALIDATION))
                  .includes(null) === false) ||
              fm({
                defaultMessage: 'One or more email addresses are invalid',
              })
            );
          },
        }}
      />
    </>
  );
};
