import { Alert, Stack } from '@mui/material';
import { useLayoutEffect, useRef } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { V1Context, V1ScanResult } from '@endorlabs/api_client';
import { TResourceObject } from '@endorlabs/queries';
import { EmptyState } from '@endorlabs/ui-common';

import { InfoDrawerContent } from '../InfoDrawer';
import { ScanIssuesDetailPackageVersionResolutions } from './ScanIssuesDetailPackageVersionResolutions';
import { ScanIssuesDetailScanResults } from './ScanIssuesDetailScanResults';
import { usePackageVersionResolutionErrors } from './usePackageVersionResolutionErrors';
import { useScanResultsWithIssues } from './useScanResultsWithIssues';

const messages = defineMessages({
  empty_state_title: { defaultMessage: 'No scan issues to display' },
  title: {
    defaultMessage:
      '{count, plural, =0 {No Scan Issues} one {# Scan Issue} other {# Scan Issues}}',
  },
  title_loading: {
    defaultMessage: 'Loading Scan Issues …',
  },
  warning_bypass_host_check: {
    defaultMessage:
      'Data quality issues may exist because this version was scanned with `--bypass-host-check`.',
  },
});

export interface ScanIssuesDetailDrawerProps {
  focusResource?: Pick<TResourceObject<any>, 'uuid'>;
  namespace: string;
  projectUuid: string;
  scanContext?: V1Context;
  scanResults?: V1ScanResult[];
}

/**
 * Display for Scan Results
 */
export const ScanIssuesDetailDrawer = ({
  focusResource,
  namespace,
  projectUuid,
  scanContext,
  scanResults,
}: ScanIssuesDetailDrawerProps) => {
  // get a filtered list of scan results with issues
  const {
    filteredScanResults,
    scanIssueCount,
    hasBypassDoctor,
    isLoading: isLoadingScanResults,
  } = useScanResultsWithIssues(
    namespace,
    projectUuid,
    scanContext,
    scanResults
  );

  // when scan context is provided, get the related package versions with resolution errors
  const {
    packageVersionsWithResolutionsErrors,
    packageVersionResolutionErrorCount,
    isLoading: isLoadingPackageVersions,
  } = usePackageVersionResolutionErrors(namespace, projectUuid, scanContext);

  const totalIssueCount = scanIssueCount + packageVersionResolutionErrorCount;
  const isLoading = isLoadingScanResults || isLoadingPackageVersions;
  const isEmptyState =
    !isLoading &&
    scanIssueCount === 0 &&
    packageVersionResolutionErrorCount === 0;

  // focus the target element if requested
  const focusElementRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (
      focusElementRef.current &&
      'function' === typeof focusElementRef.current.focus
    ) {
      focusElementRef.current.focus();
    }
  }, []);

  return (
    <InfoDrawerContent
      title={
        isLoading ? (
          <FM {...messages.title_loading} />
        ) : (
          <FM {...messages.title} values={{ count: totalIssueCount }} />
        )
      }
    >
      {(isEmptyState || hasBypassDoctor) && (
        <Stack spacing={4} marginTop={4}>
          {hasBypassDoctor && (
            <Alert severity="warning">
              <FM {...messages.warning_bypass_host_check} />
            </Alert>
          )}

          {isEmptyState && (
            <EmptyState
              title={<FM {...messages.empty_state_title} />}
              size="small"
            />
          )}
        </Stack>
      )}

      <ScanIssuesDetailScanResults
        focusElementRef={focusElementRef}
        focusResource={focusResource}
        scanResults={filteredScanResults}
      />

      <ScanIssuesDetailPackageVersionResolutions
        focusElementRef={focusElementRef}
        focusResource={focusResource}
        packageVersionResolutions={packageVersionsWithResolutionsErrors}
      />
    </InfoDrawerContent>
  );
};
