import { differenceInSeconds } from 'date-fns';
import _difference from 'lodash-es/difference';
import _intersection from 'lodash-es/intersection';
import _omit from 'lodash-es/omit';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import {
  OnboardingApproach,
  OnboardingApproaches,
  OnboardingStep,
  OnboardingStepId,
  OnboardingSteps,
} from '../constants';

const VERSION = 3;

export const DEFAULT_APPROACH = OnboardingApproaches.CLI;

type CompletedStepTimestamps = Partial<Record<OnboardingStepId, string>>;

type ScanningRepositoryIdType = string | undefined;

interface OnboardingState {
  completedStepIds: OnboardingStepId[];
  completedStepTimestamps: CompletedStepTimestamps; // UTC
  lastCompletedStep?: OnboardingStepId;
  lastCompletedTimestamp?: string; // UTC
  scanningRepositoryId: ScanningRepositoryIdType;
  tourApproach: OnboardingApproach | undefined;
}

const DEFAULT_STATE: OnboardingState = {
  completedStepIds: [],
  completedStepTimestamps: {},
  lastCompletedStep: undefined,
  lastCompletedTimestamp: undefined,
  scanningRepositoryId: undefined,
  tourApproach: undefined,
};

// UTILS
const getStepsForApproach = (approach: OnboardingApproach) =>
  OnboardingSteps.filter(
    (s) => !s.approaches || s.approaches.includes(approach)
  );

const getStepIdsForApproach = (approach: OnboardingApproach) =>
  getStepsForApproach(approach).map((a) => a.id);

const getAllApproachPaths = () =>
  Object.values(OnboardingApproaches).map((approach) =>
    getStepIdsForApproach(approach)
  );

interface OnboardingStepsStore {
  // Every stepId that has been completed
  completedStepIds: OnboardingStepId[];

  // When those stepIds were completed
  completedStepTimestamps: CompletedStepTimestamps;

  // What onboarding is all about
  completeStep: (stepId: OnboardingStepId) => void;

  // Is any single onboarding approach complete?
  getIsOnboardingComplete: () => boolean;

  // Did I do that? https://shorturl.at/deJMQ
  getIsStepComplete: (stepId: OnboardingStepId) => boolean;

  // For the current approach, what is the earliest step missing?
  // NOTE: This could take you backwards if you have skipped steps.
  getNextStepId: (approach: OnboardingApproach) => OnboardingStepId | undefined;

  // Get total progress, considering all approaches
  getOnboardingProgress: () => number;

  // Get all the steps for a given approach
  getOnboardingSteps: (approach: OnboardingApproach) => OnboardingStep[];

  // Was this step completed within the last N seconds?
  getStepCompletedWithin: (
    stepId: OnboardingStepId,
    seconds: number
  ) => boolean;

  // If there are prequisite steps, what are the missing step numbers for the given approach?
  getUncompletedPrequisiteStepNumbers: (
    stepId: OnboardingStepId,
    approach: OnboardingApproach
  ) => number[];

  // FIXME: This is not helpful anymore since we have timestamps for all steps
  // Instead, `onboardingCompletedAt`
  lastCompletedTimestamp: string;

  uncompleteStep: (stepId: OnboardingStepId) => void;

  scanningRepositoryId: ScanningRepositoryIdType;

  getScanningRepositoryId: () => ScanningRepositoryIdType;

  setScanningRepositoryId: (repoId: ScanningRepositoryIdType) => void;

  tourApproach: OnboardingApproach | undefined;

  getCurrentApproach: () => OnboardingApproach | undefined;

  setCurrentApproach: (approach: OnboardingApproach | undefined) => void;
}

export const useOnboardingSteps = create(
  persist<OnboardingStepsStore>(
    (setState, getState): OnboardingStepsStore => {
      const getCompletedStepTimestamps = () =>
        getState()?.completedStepTimestamps ??
        DEFAULT_STATE.completedStepTimestamps;

      const getCompletedStepIds = () =>
        getState()?.completedStepIds ?? DEFAULT_STATE.completedStepIds;

      const getLastCompletedTimestamp = () =>
        getState()?.lastCompletedTimestamp ??
        DEFAULT_STATE.lastCompletedTimestamp;

      const getCurrentApproachStepIds = (approach: OnboardingApproach) =>
        getStepIdsForApproach(approach);

      return {
        ...DEFAULT_STATE,

        completeStep: (stepId) => {
          const { completedStepTimestamps, completedStepIds } = getState();
          // Add to completed steps
          const newCompletedStepIds = completedStepIds.includes(stepId)
            ? completedStepIds
            : completedStepIds.concat([stepId]);

          const timestamp = new Date().toISOString();
          const newCompletedStepTimestamps = {
            ...completedStepTimestamps,
            [stepId]: timestamp,
          };

          setState((state) => ({
            ...state,
            completedStepIds: newCompletedStepIds,
            completedStepTimestamps: newCompletedStepTimestamps,
            lastCompletedTimestamp: timestamp,
          }));
        },

        completedStepTimestamps: getCompletedStepTimestamps(),

        getIsStepComplete: (stepId) => getCompletedStepIds().includes(stepId),

        getNextStepId: (approach) => {
          const completed = getCompletedStepIds();
          return getCurrentApproachStepIds(approach).find(
            (stepId) => !completed.includes(stepId)
          );
        },

        getOnboardingSteps: (approach) => getStepsForApproach(approach),

        getIsOnboardingComplete: () => {
          const { completedStepIds } = getState();
          const allApproachPaths = getAllApproachPaths();

          return allApproachPaths.some(
            (approachPath) =>
              _difference(approachPath, completedStepIds).length === 0
          );
        },

        getOnboardingProgress: () => {
          const allApproachPaths = getAllApproachPaths();
          const completed = getCompletedStepIds();
          const mostProgressedPct = allApproachPaths.reduce(
            (mostProgressed, approachPath) => {
              const completedCountForPath = _intersection(
                approachPath,
                completed
              ).length;

              const progressForPath =
                completedCountForPath === 0
                  ? 0
                  : completedCountForPath / approachPath.length;

              return progressForPath > mostProgressed
                ? progressForPath
                : mostProgressed;
            },
            0
          );

          return Math.ceil(mostProgressedPct * 100);
        },

        getUncompletedPrequisiteStepNumbers: (stepId, approach) => {
          const prereqs =
            OnboardingSteps.find((s) => s.id === stepId)?.prerequisiteSteps ??
            [];

          const completed = getCompletedStepIds();
          const missingPrereqs = _difference(prereqs, completed);
          const approachStepIds = getStepIdsForApproach(approach);

          return missingPrereqs.map((sid) =>
            approachStepIds.findIndex((s) => s === sid)
          );
        },

        lastCompletedTimestamp: getLastCompletedTimestamp(),

        uncompleteStep: (stepId) => {
          setState((state) => ({
            ...state,
            completedStepIds: getCompletedStepIds().filter(
              (sid) => sid !== stepId
            ),
            completedStepTimestamps: _omit(
              getCompletedStepTimestamps(),
              stepId
            ),
          }));
        },

        getStepCompletedWithin: (stepId, seconds) => {
          const completedRecord = Object.entries(
            getCompletedStepTimestamps()
          ).find(([sid]) => {
            return sid === stepId;
          });

          if (!completedRecord) return false;

          const diff = differenceInSeconds(
            new Date(),
            new Date(completedRecord[1])
          );

          return diff < seconds;
        },

        setScanningRepositoryId: (repoId: ScanningRepositoryIdType) => {
          setState((state) => ({
            ...state,
            scanningRepositoryId: repoId,
          }));
        },

        getScanningRepositoryId: () => {
          const { scanningRepositoryId } = getState();
          return scanningRepositoryId;
        },

        getCurrentApproach: (): OnboardingApproach | undefined =>
          getState()?.tourApproach,

        setCurrentApproach: (approach: OnboardingApproach | undefined) => {
          setState((state) => ({
            ...state,
            tourApproach: approach,
          }));
        },
      };
    },

    // STORE CONFIG
    {
      migrate: (oldState) => ({
        ...(oldState as OnboardingStepsStore),
        ...DEFAULT_STATE,
      }),
      name: 'onboarding-steps-store',
      version: VERSION,
    }
  )
);
