import { Skeleton } from '@mui/material';

import { FlexList, FlexListItem } from '../FlexList';
import { MetadataListMetricListItem } from './MetadataListMetricItem';
import { MetadataMetric } from './types';

export interface MetadataListProps {
  metrics: MetadataMetric[];
  isLoading?: boolean;
  disableDivider?: boolean;
}

export const MetadataList = ({
  metrics,
  isLoading,
  disableDivider = false,
}: MetadataListProps) => {
  return (
    <FlexList
      divider={disableDivider ? null : <FlexListItem>&middot;</FlexListItem>}
    >
      {metrics.map((metric, ix) => (
        <FlexListItem key={ix}>
          {isLoading ? (
            <Skeleton width={160} />
          ) : (
            <MetadataListMetricListItem {...metric} />
          )}
        </FlexListItem>
      ))}
    </FlexList>
  );
};
