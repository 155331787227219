import { Alert, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SecretRuleResource, useDeleteSecretRule } from '@endorlabs/queries';

import { ConfirmationDialogProps } from '../../../components';
import { IconAlertTriangle } from '../../../themes';
import { useAppNotify } from '../../layout';

/**
 * Utility hook to Manage Auth Policy Deletion
 */
export const useDeleteSecretRuleConfirmationDialog = () => {
  const [secretRule, setSecretRule] = useState<SecretRuleResource | undefined>(
    undefined
  );
  const addAppNotification = useAppNotify();
  const isOpen = Boolean(secretRule);

  const handleOpenDialog = useCallback((secretRule: SecretRuleResource) => {
    setSecretRule(secretRule);
  }, []);

  const handleCloseDialog = useCallback(() => setSecretRule(undefined), []);

  const qDeleteSecretRule = useDeleteSecretRule();
  const handleConfirmDismiss = useCallback(() => {
    if (!secretRule) return;

    qDeleteSecretRule.mutate(
      {
        namespace: secretRule.tenant_meta.namespace,
        uuid: secretRule.uuid,
      },
      {
        onSuccess: () => {
          addAppNotification({
            message: (
              <FM
                defaultMessage="Successfully deleted {name}"
                values={{ name: secretRule.meta.name }}
              />
            ),
            severity: 'success',
          });
          setSecretRule(undefined);
        },
        // TODO: show error on failure
      }
    );
  }, [addAppNotification, secretRule, qDeleteSecretRule]);

  const getConfirmationDialogProps =
    useCallback((): ConfirmationDialogProps => {
      return {
        titleText: <FM defaultMessage="Delete Secrets Detector" />,
        open: isOpen,
        onConfirm: handleConfirmDismiss,
        onCancel: handleCloseDialog,
        children: (
          <Stack spacing={4} marginBottom={3}>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <FM defaultMessage="Any tooling or automated processes which rely on this Secrets Detector will cease to function as expected." />
            </Alert>

            <Typography variant="body1">
              <FM
                defaultMessage="This action will revoke and immediately delete the Secrets Detector <code>{name}</code>. Are you sure you want to proceed?"
                values={{
                  name: secretRule?.meta.name,
                  // styled `code` element
                  // forces content to wrap
                  code: (value) => (
                    <Typography
                      component="code"
                      variant="inherit"
                      sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
                    >
                      {value}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Stack>
        ),
      };
    }, [secretRule, handleConfirmDismiss, handleCloseDialog, isOpen]);

  return {
    closeDialog: handleCloseDialog,
    getConfirmationDialogProps,
    openDialog: handleOpenDialog,
  };
};
