import { get as _get, set as _set, uniq as _uniq } from 'lodash-es';
import { Entries } from 'type-fest';

import { V1ToolChains } from '@endorlabs/api_client';
import { ScanProfileToolChain } from '@endorlabs/endor-core/ScanProfile';

import { ToolchainToolInfo, ToolInfoType } from '../types';
import { getToolchainToolInfoDefinition } from './toolchainToolInfoHelpers';

export const fromToolchainToolInfo = (
  tools: ToolchainToolInfo[]
): {
  toolchain: ScanProfileToolChain;
  paths: string[];
} => {
  const toolchain: ScanProfileToolChain = {};
  const paths = new Set<string>();

  for (const t of tools) {
    const path = ['os', t.os, 'arch', t.arch, t.toolchain, t.tool];

    if (t.type === ToolInfoType.ToolList) {
      const existing = _get(toolchain, path, []);
      const merged = _uniq(existing.concat(t.value.key));
      _set(toolchain, path, merged);
      continue;
    }

    if (t.type === ToolInfoType.ToolChainVersionMap) {
      path.push(t.value.key);
    }

    _set(toolchain, path, t.value.version);
  }

  return {
    toolchain,
    paths: Array.from(paths.values()),
  };
};

export const toToolchainToolInfo = (
  toolChain?: ScanProfileToolChain
): ToolchainToolInfo[] => {
  const result: ToolchainToolInfo[] = [];

  const osEntries = Object.entries(toolChain?.os ?? {});

  for (const [osKey, osValue] of osEntries) {
    const archEntries = Object.entries(osValue.arch ?? {});

    for (const [archKey, archValue] of archEntries) {
      const archToolchainEntries = Object.entries(
        archValue
      ) as Entries<V1ToolChains>;

      for (const [toolchainKey, toolchainValue] of archToolchainEntries) {
        if (!toolchainValue) continue;

        const toolchainEntries = Object.entries(toolchainValue) as Entries<
          V1ToolChains[keyof V1ToolChains]
        >;

        for (const [toolchainToolKey, toolchainToolValue] of toolchainEntries) {
          // Skip empty tool info
          if (!toolchainToolValue) continue;

          const toolDefinition = getToolchainToolInfoDefinition({
            toolchain: toolchainKey,
            tool: toolchainToolKey,
          });
          const toolType = toolDefinition?.type;

          // Handle: list of tools
          if (Array.isArray(toolchainToolValue)) {
            for (const name of toolchainToolValue) {
              result.push({
                os: osKey,
                arch: archKey,
                toolchain: toolchainKey,
                tool: toolchainToolKey,
                type: ToolInfoType.ToolList,
                value: { key: name },
              });
            }

            continue;
          }

          // Handle: map of tool versions
          if (toolType === ToolInfoType.ToolChainVersionMap) {
            for (const [key, version] of Object.entries(toolchainToolValue)) {
              result.push({
                os: osKey,
                arch: archKey,
                toolchain: toolchainKey,
                tool: toolchainToolKey,
                type: ToolInfoType.ToolChainVersionMap,
                value: { key, version },
              });
            }

            continue;
          }

          // Handle: single tool version
          if (!('name' in toolchainToolValue)) continue;

          result.push({
            os: osKey,
            arch: archKey,
            toolchain: toolchainKey,
            tool: toolchainToolKey,
            type: ToolInfoType.ToolChainVersion,
            value: { version: toolchainToolValue },
          });
        }
      }
    }
  }

  return result;
};
