import { QueryKey, useQuery } from 'react-query';

import {
  QueryServiceApi,
  V1ApproveServiceRequest,
  V1ListParameters,
  V1Meta,
  V1Query,
  V1ServiceRequest,
  V1ServiceRequestSpec,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration } from './utils';

export const QueryServiceRequestsQueryKeys = {
  query: (namespace: string, listParams: V1ListParameters): QueryKey => [
    'v1/queries',
    namespace,
    'service-requests',
    { listParams },
  ],
};

export type QueryServiceRequestsResponse =
  TResourceList<QueryServiceRequestsResponseObject>;

export type QueryServiceRequestsResponseObject = SelectFrom<
  V1ServiceRequest,
  'uuid',
  {
    meta: SelectFrom<
      V1Meta,
      'name' | 'create_time' | 'created_by' | 'update_time',
      {
        references: {
          ApproveServiceRequest: QueryApproveServiceRequestResponse;
        };
      }
    >;
    spec: SelectFrom<
      V1ServiceRequestSpec,
      'description' | 'method' | 'resource' | 'status' | 'target_namespace'
    >;
    tenant_meta: never;
  }
>;

type QueryApproveServiceRequestResponse = TResourceList<
  SelectFrom<V1ApproveServiceRequest, 'uuid' | 'meta'>
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryServiceRequests(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'ServiceRequest',
        list_parameters: {
          ...rootListParams,
        },
        references: [
          {
            connect_from: 'uuid',
            connect_to: 'spec.service_request_uuid',
            query_spec: {
              kind: 'ApproveServiceRequest',
              list_parameters: {
                page_size: 1,
                mask: [
                  'uuid',
                  'meta.name',
                  'meta.create_time',
                  'meta.created_by',
                ].join(','),
              },
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

const queryServiceRequests = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryServiceRequestsResponse;
};

/**
 * Custom query for the Service Requests page
 */
export const useQueryServiceRequests = (
  namespace: string,
  listParams: ListRequestParameters = {},
  opts: ResourceQueryOptions<QueryServiceRequestsResponse> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ServiceRequest',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QueryServiceRequestsQueryKeys.query(namespace, requestParameters),
    async ({ signal }) =>
      queryServiceRequests(namespace, requestParameters, signal),
    { staleTime: Infinity, ...opts }
  );
};
