import { isAfter } from 'date-fns';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { TenantType } from '@endorlabs/endor-core/auth';

import { useAuthInfo } from './useAuthInfo';
import { useAuthTenantInfo } from './useAuthTenantInfo';

const LICENSE_REQUIRED_FEATURE_TYPES: SpecEndorLicenseFeatureType[] = [
  // UI-1627: require the Endor Patch feature to be explicitly licensed
  SpecEndorLicenseFeatureType.EndorPatching,
];

export const useLicensingInfo = () => {
  const { licenseInfo } = useAuthInfo();
  const { tenantInfo } = useAuthTenantInfo();

  const now = useMemo(() => Date.now(), []);

  // Function returns if license is expired or not
  const isLicenseExpired = useCallback(
    (expiryTime: string) => {
      const licenseExpiry = new Date(expiryTime);
      return isAfter(now, licenseExpiry);
    },
    [now]
  );

  const checkLicensePresent = useCallback(
    (licenseFeatureType: SpecEndorLicenseFeatureType): boolean => {
      // disallow access to ALL features for expired trial tenants
      if (tenantInfo.tenantType === TenantType.TrialExpired) {
        return false;
      }

      // Check if the given feature requires an explicit license
      const isLicenseRequiredFeature =
        LICENSE_REQUIRED_FEATURE_TYPES.includes(licenseFeatureType);

      // Handle: tenant with empty licensed features
      // NOTE: This is possible for
      // - Legacy tenants that have not yet migrated to licensed features
      // - Free trial tenants
      const hasLicensedFeatures = !!licenseInfo?.length;
      if (!hasLicensedFeatures) {
        return !isLicenseRequiredFeature;
      }

      // Find the license feature for the tenant
      const licenseFound = licenseInfo?.find(
        (l) => l.type === licenseFeatureType
      );
      if (!licenseFound) return false;

      // If expiration time is not set or empty, allow as not expired
      if (!licenseFound.expiration_time) {
        return true;
      }

      // Check expiration
      return !isLicenseExpired(licenseFound.expiration_time);
    },
    [licenseInfo, tenantInfo, isLicenseExpired]
  );

  /**
   * Check if there are additional licenses than the license passed.
   * Function used to enable /disable componenets based on additional licenses present or not.
   */
  const hasAdditionalLicenses = useCallback(
    (currentLicense: SpecEndorLicenseFeatureType) => {
      if (_isEmpty(licenseInfo)) return true;

      return licenseInfo?.some((license) => license.type !== currentLicense);
    },
    [licenseInfo]
  );

  return {
    checkLicensePresent,
    hasAdditionalLicenses,
  };
};
