import { MultiSelectInput, MultiSelectInputProps } from '@endorlabs/ui-common';

import { FacetFilterDefinition } from './types';

export interface ControlsFacetFilterMultiSelectProps
  extends Omit<MultiSelectInputProps<string>, 'label' | 'options'> {
  facet: FacetFilterDefinition;
}

/**
 * @deprecated used for {@see FacetedSearchBar}, will be removed in the future
 */
export const ControlsFacetFilterMultiSelect = ({
  facet,
  ...props
}: ControlsFacetFilterMultiSelectProps) => {
  return (
    <MultiSelectInput
      {...props}
      options={facet.values ?? []}
      label={facet.label}
    />
  );
};
