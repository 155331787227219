import {
  Grid,
  Skeleton,
  Stack,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  DescriptionList,
  DescriptionListItem,
  EmptyState,
} from '@endorlabs/ui-common';

const ProjectOverviewTopMetadataLabels = defineMessages({
  dependencies: {
    defaultMessage: 'Dependencies',
  },
  languages: {
    defaultMessage: 'Languages Breakdown',
  },
  license: {
    defaultMessage: 'License',
  },
  hasCodeowners: {
    defaultMessage: 'Has code owners file?',
  },
  packages: {
    defaultMessage: 'Packages',
  },
  packagesMetadata: {
    defaultMessage: 'Package Metadata',
  },
  repositoryVersionMetadata: {
    defaultMessage: 'Repository Version Metadata',
  },
  uuid: {
    defaultMessage: 'Project UUID',
  },
  versionUuid: {
    defaultMessage: 'Repository Version UUID',
  },
});

interface PackageMetadata {
  dependencies?: string;
  packages?: number;
}

interface RepositoryVersionMetadata {
  languages?: string;
  license?: string;
  owner?: ReactNode;
  versionUuid?: string;
}

export interface ProjectOverviewTopMetadataProps {
  hasProjectMetadata: boolean;
  hasRepositoryVersionMetadata: boolean;
  isLoadingTopMetadata: boolean;
  packageMetadata: PackageMetadata;
  repositoryVersionMetadata: RepositoryVersionMetadata;
}

const termTypographyProps: TypographyProps = {
  variant: 'overline',
};

const gridItemProps = {
  item: true,
  md: 6,
  sm: 12,
};

export const ProjectOverviewTopMetadata = ({
  hasProjectMetadata,
  hasRepositoryVersionMetadata,
  isLoadingTopMetadata,
  packageMetadata,
  repositoryVersionMetadata,
}: ProjectOverviewTopMetadataProps) => {
  const { space } = useTheme();

  return (
    <Grid container>
      <Grid {...gridItemProps}>
        <Stack paddingRight={space.lg} spacing={4}>
          <DescriptionList
            title={ProjectOverviewTopMetadataLabels.packagesMetadata}
          >
            {hasProjectMetadata &&
              !isLoadingTopMetadata &&
              Object.entries(packageMetadata).map(([metadata, value], i) => {
                const term =
                  ProjectOverviewTopMetadataLabels[
                    metadata as keyof typeof ProjectOverviewTopMetadataLabels
                  ];

                if (term && value) {
                  return (
                    <DescriptionListItem
                      key={`metadata_${i}`}
                      term={term}
                      termKey={metadata}
                      termTypographyProps={termTypographyProps}
                      value={value}
                    />
                  );
                }
              })}

            {!hasProjectMetadata && !isLoadingTopMetadata && (
              <EmptyState
                size="small"
                title={<FM defaultMessage="No project metadata" />}
              />
            )}

            {isLoadingTopMetadata && <ProjectOverviewTopMetadataSkeleton />}
          </DescriptionList>
        </Stack>
      </Grid>

      <Grid {...gridItemProps}>
        <Stack
          borderLeft={({ palette }) => `1px solid ${palette.divider}`}
          paddingLeft={space.lg}
          spacing={4}
        >
          <DescriptionList
            title={ProjectOverviewTopMetadataLabels.repositoryVersionMetadata}
          >
            {hasRepositoryVersionMetadata &&
              !isLoadingTopMetadata &&
              Object.entries(repositoryVersionMetadata).map(
                ([metadata, value], i) => {
                  const term =
                    ProjectOverviewTopMetadataLabels[
                      metadata as keyof typeof ProjectOverviewTopMetadataLabels
                    ];

                  if (term && value) {
                    return (
                      <DescriptionListItem
                        key={`metadata_${i}`}
                        term={term}
                        termKey={metadata}
                        termTypographyProps={termTypographyProps}
                        value={value}
                      />
                    );
                  }
                }
              )}

            {!hasRepositoryVersionMetadata && !isLoadingTopMetadata && (
              <EmptyState
                size="small"
                title={<FM defaultMessage="No repository version metadata" />}
              />
            )}

            {isLoadingTopMetadata && <ProjectOverviewTopMetadataSkeleton />}
          </DescriptionList>
        </Stack>
      </Grid>
    </Grid>
  );
};

const ProjectOverviewTopMetadataSkeleton = () => (
  <Stack spacing={3}>
    <Skeleton height={42} />
    <Skeleton height={42} />
    <Skeleton height={42} />
    <Skeleton height={42} />
  </Stack>
);
