import { useMutation } from 'react-query';

import {
  ApproveServiceRequestServiceApi,
  V1ApproveServiceRequest,
} from '@endorlabs/api_client';

import { ApproveServiceRequestResource, ResourceMutateOptions } from './types';
import { buildResourceMutateMeta, getClientConfiguration } from './utils';

export interface ApproveServiceRequestWriteParams {
  namespace: string;
  resource: V1ApproveServiceRequest;
}

type UpsertApproveServiceRequestOptions = ResourceMutateOptions<
  V1ApproveServiceRequest,
  ApproveServiceRequestWriteParams
>;

const getApiService = () =>
  new ApproveServiceRequestServiceApi(getClientConfiguration());

const createApproveServiceRequest = async (
  namespace: string,
  approveServiceRequestBody: V1ApproveServiceRequest
) => {
  const api = getApiService();
  const resp =
    await api.approveServiceRequestServiceCreateApproveServiceRequest(
      namespace,
      approveServiceRequestBody
    );
  return resp.data as ApproveServiceRequestResource;
};

export const useCreateApproveServiceRequest = (
  opts: UpsertApproveServiceRequestOptions = {}
) => {
  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'ApproveServiceRequest'),
    mutationFn: (params: ApproveServiceRequestWriteParams) =>
      createApproveServiceRequest(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
