import { pick as _pick } from 'lodash-es';
import { QueryKey, useInfiniteQuery } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryQuerySpec,
  QueryServiceApi,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Query,
  V1QueryReference,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import {
  PackageVersionResource,
  ResourceCountResponse,
  ResourceInfiniteQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration } from './utils';

export type QueryPackagesIndexPackageVersionsResponse =
  TResourceList<QueryPackagesIndexPackageVersionsObject>;
export type QueryPackagesIndexPackageVersionsObject = SelectFrom<
  PackageVersionResource,
  'uuid' | 'tenant_meta' | 'context',
  {
    spec: SelectFrom<
      PackageVersionResource['spec'],
      'ecosystem' | 'project_uuid' | 'source_code_reference'
    >;
    meta: SelectFrom<
      PackageVersionResource['meta'],
      'name',
      {
        references: {
          FindingsGroupByLevel?: Pick<TResourceList<unknown>, 'group_response'>;
          Metric: TResourceList<QueryPackagesIndexPackageVersions_Metric>;
          DependentPackagesCount: ResourceCountResponse;
          DependencyPackagesCount: ResourceCountResponse;
        };
        create_time: never;
        update_time: never;
      }
    >;
  }
>;

type QueryPackagesIndexPackageVersions_Metric = SelectFrom<
  V1Metric,
  'uuid',
  {
    meta: SelectFrom<V1Meta, 'name'>;
    spec: SelectFrom<
      V1MetricSpec,
      'analytic',
      {
        metric_values: {
          scorecard: Required<V1MetricValue>;
        };
        // properties required, but not included in query
        project_uuid: never;
        raw: never;
      }
    >;
    tenant_meta: never;
    context: never;
  }
>;

const getApiService = () => new QueryServiceApi(getClientConfiguration());

const buildPackagesIndexPackageVersionsQuerySpec = (
  listParameters: V1ListParameters,
  includeReferences: ResourceKind[]
): QueryQuerySpec => {
  const commonListParameters = _pick(listParameters, ['traverse']);
  const referenceKinds = new Set(includeReferences);

  const references = [
    // Always include dependencies
    {
      connect_from: 'uuid',
      connect_to: 'spec.importer_data.package_version_uuid',
      query_spec: {
        kind: 'DependencyMetadata',
        return_as: 'DependencyPackagesCount',
        list_parameters: {
          ...commonListParameters,
          count: true,
          filter: filterExpressionBuilders.defaultResourceContexts(),
        },
      },
    },
    // conditionally include other references
    referenceKinds.has('Finding') && {
      // Finding counts for "My Packages"
      connect_from: 'uuid',
      connect_to: 'meta.parent_uuid',
      query_spec: {
        kind: 'Finding',
        return_as: 'FindingsGroupByLevel',
        list_parameters: {
          ...commonListParameters,
          filter: filterExpressionBuilders.defaultResourceContexts(),
          group: {
            aggregation_paths: 'spec.level',
          },
        },
      },
    },
    referenceKinds.has('Metric') && {
      // Metric scores for dependencies
      connect_from: 'uuid',
      connect_to: 'meta.parent_uuid',
      query_spec: {
        kind: 'Metric',
        list_parameters: {
          ...commonListParameters,
          // there should only be 1, return the first found
          page_size: 1,
          // pick the specific metric for the package version, if exists
          filter: [
            'meta.name==package_version_scorecard',
            filterExpressionBuilders.defaultResourceContexts(),
          ].join(' and '),
          mask: [
            'uuid',
            'meta.name',
            'spec.analytic',
            'spec.metric_values',
          ].join(),
        },
      },
    },
    referenceKinds.has('DependencyMetadata') && {
      // NOTE: only getting dependent PackageVersions in the same namespace
      // as the parent PackageVersion (not using `includeoss`)
      connect_from: 'uuid',
      connect_to: 'spec.dependency_data.package_version_uuid',
      query_spec: {
        kind: 'DependencyMetadata',
        return_as: 'DependentPackagesCount',
        list_parameters: {
          ...commonListParameters,
          count: true,
          filter: filterExpressionBuilders.defaultResourceContexts(),
        },
      },
    },
  ].filter(Boolean) as V1QueryReference[];

  return {
    kind: 'PackageVersion',
    list_parameters: {
      ...listParameters,
      mask: [
        'uuid',
        'context',
        'meta.name',
        'spec.ecosystem',
        'spec.project_uuid',
        'spec.source_code_reference.version',
        'tenant_meta',
      ].join(),
    },
    references,
  };
};

const buildPackagesIndexPackageVersionsQuery = (
  namespace: string,
  listParams: V1ListParameters,
  includeReferences: ResourceKind[]
): V1Query => {
  return {
    meta: {
      name: `PackagesIndexPackageVersions(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildPackagesIndexPackageVersionsQuerySpec(
        listParams,
        includeReferences
      ),
    },
    tenant_meta: { namespace },
  };
};

const queryPackagesIndexPackageVersions = async (
  namespace: string,
  listParams: V1ListParameters,
  includeReferences: ResourceKind[]
) => {
  const query = buildPackagesIndexPackageVersionsQuery(
    namespace,
    listParams,
    includeReferences
  );
  const resp = await getApiService().queryServiceCreateQuery(namespace, query);
  return resp.data.spec
    ?.query_response as QueryPackagesIndexPackageVersionsResponse;
};

export const useQueryPackagesIndexPackageVersions = (
  namespace: string,
  opts: ResourceInfiniteQueryOptions<QueryPackagesIndexPackageVersionsResponse> = {},
  listParams: Omit<ListRequestParameters, 'mask'> = {},
  includeReferences: ResourceKind[] = []
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersion',
    'LIST',
    listParams,
    opts
  );

  return useInfiniteQuery(
    [
      'v1/queries',
      namespace,
      'packages-index-package-versions',
      requestParameters,
    ] as QueryKey,
    ({ pageParam }) => {
      return queryPackagesIndexPackageVersions(
        namespace,
        {
          ...requestParameters,
          page_token: pageParam,
        },
        includeReferences
      );
    },
    {
      ...opts,
      staleTime: 5 * 60 * 1000,
      getNextPageParam: (lastPage, pages) =>
        lastPage?.list?.response?.next_page_token,
    }
  );
};
