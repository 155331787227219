import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { SecretRuleResource } from '@endorlabs/queries';
import { ControlledCheckbox, IconChevronDown } from '@endorlabs/ui-common';

type SecretRuleAdvancedFieldValues = Pick<SecretRuleResource, 'propagate'>;

export const SecretRuleAdvancedFields = () => {
  const { palette, typography } = useTheme();
  const { control } = useFormContext<SecretRuleAdvancedFieldValues>();

  return (
    <Accordion
      disableGutters // prevent margin on expanded state
      elevation={0}
      sx={{
        '& .MuiAccordionSummary-root': {
          justifyContent: 'flex-start',
          gap: 2,
          '& .MuiAccordionSummary-content': {
            flexGrow: 0,
          },
        },
      }}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>
        <Typography
          sx={{
            ...typography.button,
            color: palette.text.primary,
          }}
        >
          <FM defaultMessage="Advanced" />
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <FormControlLabel
          label={
            <FM defaultMessage="Propagate this rule to all child namespaces." />
          }
          control={
            <ControlledCheckbox
              control={control}
              // @ts-expect-error field value type does not expect boolean?
              defaultValue={false}
              name="propagate"
            />
          }
        />
      </AccordionDetails>
    </Accordion>
  );
};
