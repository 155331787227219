import { Stack, useTheme } from '@mui/material';

import { ToolCategoryDisplay } from './ToolCategoryDisplay';

export const ToolCategoryListDisplay = ({
  categories,
}: {
  categories: string[];
}) => {
  const theme = useTheme();

  return (
    <Stack direction="row" gap={theme.space.sm}>
      {categories.map((category) => (
        <ToolCategoryDisplay key={category} category={category} />
      ))}
    </Stack>
  );
};
