import { useEffect } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { V1User } from '@endorlabs/api_client';
import { useCreateOnboard, useSession } from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

const LOCAL_STORAGE_INVITATION = 'endor_invitation';

type InvitationStatus =
  | 'success'
  | 'error'
  | 'accepted'
  | 'loading'
  | undefined;

interface StoredInvitation {
  namespace: string;
  uuid: string;
}

interface InvitationStore {
  clearInvitation: () => void;
  getInvitationNamespace: () => string | undefined;
  getInvitationStatus: () => InvitationStatus;
  getInvitationUuid: () => string | undefined;
  invitation?: StoredInvitation;
  invitationStatus: InvitationStatus;
  setInvitationStatus: (s: InvitationStatus) => void;
  storeInvitation: (s: StoredInvitation) => void;
}

const useInvitationStore = create(
  subscribeWithSelector<InvitationStore>((setState, getState) => {
    // Look for invitation object in state, or pull into state from local storage
    const getInvitation = () => {
      const invitationFromStore = getState().invitation;
      if (invitationFromStore) {
        return invitationFromStore;
      } else {
        const invitationFromLocalStorage = window.localStorage.getItem(
          LOCAL_STORAGE_INVITATION
        );

        if (invitationFromLocalStorage) {
          try {
            // safely parse the local storage value
            const invitation = JSON.parse(invitationFromLocalStorage);
            setState({ invitation });
            return invitation;
          } catch (e) {
            // remove the value on a failed parse
            window.localStorage.removeItem(LOCAL_STORAGE_INVITATION);
          }
        }

        return undefined;
      }
    };

    return {
      clearInvitation: async () => {
        window.localStorage.removeItem(LOCAL_STORAGE_INVITATION);
        setState({ invitation: undefined, invitationStatus: undefined });
      },

      getInvitationStatus: () => getState().invitationStatus,
      getInvitationNamespace: () => getInvitation()?.namespace,
      getInvitationUuid: () => getInvitation()?.uuid,
      invitation: undefined,
      invitationStatus: undefined,

      setInvitationStatus: (newStatus: InvitationStatus) => {
        const currentStatus = getState().invitationStatus;
        if (currentStatus !== newStatus)
          setState((state) => ({ ...state, invitationStatus: newStatus }));
      },

      storeInvitation: (invitation: StoredInvitation) => {
        window.localStorage.setItem(
          LOCAL_STORAGE_INVITATION,
          JSON.stringify(invitation)
        );

        setState({ invitation });
      },
    };
  })
);

/**
 * Provides vars/functions to store or clear invitations in state,
 * initiate onboarding flow for an invitation,
 * work with invitation-related search params,
 * & get visibility into onboarding status.
 */
export const useInvitation = () => {
  const { canAccessTenant, getUser, setLastUsedTenant } = useSession();

  const {
    clearInvitation,
    getInvitationNamespace,
    getInvitationStatus,
    getInvitationUuid,
    setInvitationStatus,
    storeInvitation,
  } = useInvitationStore();

  const invitationNamespace = getInvitationNamespace();
  const invitationStatus = getInvitationStatus();

  const qOnboardCreate = useCreateOnboard();

  const processInvitation = () => {
    if (qOnboardCreate.isIdle && getInvitationStatus() === undefined) {
      qOnboardCreate.mutate({
        resource: {
          spec: {
            invitation_uuid: getInvitationUuid(),
            user: getUser(),
          },
        },
      });
    }
  };

  const addAppNotification = useAppNotify();

  // Update status based on query & any other factors
  useEffect(() => {
    if (!invitationNamespace) return;

    if (qOnboardCreate.isLoading) {
      setInvitationStatus('loading');
      return;
    }

    // if user successfully onboards, set this tenant and show success
    if (qOnboardCreate.isSuccess && invitationStatus !== 'success') {
      setLastUsedTenant(invitationNamespace);
      setInvitationStatus('success');
      return;
    }

    if (qOnboardCreate.isError) {
      // if the user can access the tenant, they may have already accepted
      // the invitation.
      // NOTE: currently qualified by checking if the error response
      // is 404 - the invitation is not found.
      if (
        qOnboardCreate.error.response.status === 404 &&
        canAccessTenant(invitationNamespace)
      ) {
        setInvitationStatus('accepted');
      } else {
        setInvitationStatus('error');
      }
      return;
    }
  }, [
    canAccessTenant,
    clearInvitation,
    invitationNamespace,
    invitationStatus,
    qOnboardCreate,
    setInvitationStatus,
    setLastUsedTenant,
  ]);

  useEffect(() => {
    if (
      invitationNamespace &&
      invitationStatus &&
      invitationStatus !== 'loading'
    ) {
      addAppNotification({
        id: `invitation:${invitationStatus}:${invitationNamespace}`,
        severity: invitationStatus === 'accepted' ? 'info' : invitationStatus,
        message: (
          <FM
            {...snackbarMessages[invitationStatus]}
            values={{ namespace: invitationNamespace }}
          />
        ),
      });

      // clear the invitation state after the notification has been displayed.
      clearInvitation();
    }
  }, [
    addAppNotification,
    invitationNamespace,
    invitationStatus,
    clearInvitation,
  ]);

  return {
    invitationNamespace: getInvitationNamespace(),
    invitationStatus: getInvitationStatus(),
    invitationUuid: getInvitationUuid(),
    processInvitation,
    storeInvitation,
  };
};

const snackbarMessages = defineMessages<Exclude<InvitationStatus, undefined>>({
  accepted: {
    defaultMessage:
      'An invitation to the {namespace} namespace had already been accepted.',
  },
  error: {
    defaultMessage:
      'Could not complete the invitation to {namespace}. The invitation may have been withdrawn or sent to an email address that is not the primary email with your login provider.',
  },
  loading: {
    defaultMessage: 'Processing invitation',
  },
  success: {
    defaultMessage: 'You have successfully joined the {namespace} namespace.',
  },
});
