import { MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useSearch } from '@tanstack/react-location';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PolicyResource, PolicyTemplateResource } from '@endorlabs/queries';
import { ControlledTextField } from '@endorlabs/ui-common';

import { PolicyTemplateParameterField } from '../types';
import { PolicyParameterFields } from './PolicyParameterFields';

interface PolicyRulesFromTemplateFieldsProps {
  parameters?: PolicyTemplateParameterField[];
  policy?: PolicyResource;
  policyTemplates: PolicyTemplateResource[];
}

/**
 * Given a set of templates and optional policy, returns form fields driven by policy or template requirements.
 */
export const PolicyRulesFromTemplateFields = ({
  parameters = [],
  policy,
  policyTemplates,
}: PolicyRulesFromTemplateFieldsProps) => {
  const { space } = useTheme();
  const { templateUuid: templateUuidFromUrl } = useSearch();
  const { formatMessage: fm } = useIntl();
  const { control } = useFormContext();

  return (
    <Stack alignItems="flex-start" spacing={space.md} width="100%">
      {policyTemplates.length > 0 && (
        <>
          <ControlledTextField
            control={control}
            defaultValue=""
            // Disable template selection if editing an established policy or templateUuid is driven from URL
            disabled={Boolean(policy?.uuid || templateUuidFromUrl)}
            fullWidth
            id="spec.template_uuid"
            label={<FM defaultMessage="Policy Template" />}
            name="spec.template_uuid"
            placeholder={fm({ defaultMessage: 'Choose a policy template' })}
            rules={{ required: true }}
            select
          >
            {policyTemplates.map((template) => (
              <MenuItem key={template.uuid} value={template.uuid}>
                {template.meta.name}
              </MenuItem>
            ))}
          </ControlledTextField>

          {parameters.length > 0 && (
            <fieldset style={{ width: '100%' }}>
              <Typography
                component="legend"
                variant="overline"
                marginBottom={space.sm}
              >
                <FM defaultMessage="Template Parameters" />
              </Typography>

              <PolicyParameterFields parameters={parameters} />
            </fieldset>
          )}
        </>
      )}
    </Stack>
  );
};
