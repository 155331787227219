import { VALID_NAMESPACE_REGEX } from './constants';

const NAMESPACE_DELIMITER = '.';

/**
 * Parse and return all ancestors for then namespace.
 *
 * @example
 *   getChildNamespace('endor.dev.domain')
 *   // > ['endor', 'endor.dev']
 */
export const getAncestorNamespaces = (namespace: string): string[] => {
  const parts = namespace.split('.');

  const ancestors = [];

  for (let i = 1; i <= parts.length; i++) {
    ancestors.push(parts.slice(0, i).join('.'));
  }

  return ancestors;
};

/**
 * Parse and return the last part of the namespace.
 *
 * @example
 *   getChildNamespace('endor.dev')
 *   // > 'dev'
 */
export const getChildNamespace = (ns: string): string => {
  return ns.split(NAMESPACE_DELIMITER).pop() as string;
};

/**
 * Parse and returns the parent of the namespace.
 *
 * @example
 *   getChildNamespace('endor.dev.frontend')
 *   // > 'endor.dev'
 */
export const getParentNamespace = (ns: string): string => {
  if (isRootNamespace(ns)) return ns;

  const parts = ns.split(NAMESPACE_DELIMITER);
  parts.pop();
  return parts.join(NAMESPACE_DELIMITER);
};

/**
 * Parse and returns the top level root namespace.
 */
export const getRootNamespace = (ns: string): string => {
  return ns.split(NAMESPACE_DELIMITER)[0];
};

/**
 * Verify whether the given namespace is a child of the given parent namespace
 *
 * @example
 *   isChildNamespace('endor.dev.domain', 'endor')
 *   // > true
 *
 * @example
 *   isChildNamespace('endor.dev.domain', 'endor.dev')
 *   // > true
 *
 * @example
 *   isChildNamespace('endor.test.domain', 'endor.dev')
 *   // > false
 */
export const isChildNamespace = (child: string, parent: string): boolean => {
  return child.startsWith(parent + NAMESPACE_DELIMITER);
};

/**
 * Verify whether the given namespace is a direct child of the given parent namespace
 *
 * @example
 *   isDirectChildNamespace('endor.dev.domain', 'endor')
 *   // > false
 *
 * @example
 *   isDirectChildNamespace('endor.dev.domain', 'endor.dev')
 *   // > true
 *
 * @example
 *   isDirectChildNamespace('endor.test.domain', 'endor.dev')
 *   // > false
 */
export const isDirectChildNamespace = (
  child: string,
  parent: string
): boolean => {
  return (
    isChildNamespace(child, parent) && getParentNamespace(child) === parent
  );
};

/**
 * Verify whether the given namespace is a "root" or "tenant" namespace
 */
export const isRootNamespace = (ns: string): boolean => {
  return !ns.includes(NAMESPACE_DELIMITER);
};

/**
 * Verify whether the given namespace is valid
 */
export const isValidNamespace = (ns: string): boolean => {
  const parts = ns.split(NAMESPACE_DELIMITER);
  return parts.every((ns) => VALID_NAMESPACE_REGEX.test(ns));
};

/**
 * Join multiple namespaces or namespace parts
 */
export const joinNamespaces = (...parts: string[]): string => {
  return parts.join('.');
};
