import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PackageContexture, PackageVersionResource } from '@endorlabs/queries';

import {
  DetailDrawerContainer,
  DetailDrawerScoreSection,
} from '../../../../components/DetailDrawer';
import { InfoDrawerContentProps } from '../../../../components/InfoDrawer';
import { usePackageVersionDetailData } from '../../hooks';
import { getPackageVersionDetails } from '../../utils';
import { PackageVersionDetailDrawerHeader } from './PackageVersionDetailDrawerHeader';
import { PackageVersionDetailDrawerOverviewSection } from './PackageVersionDetailDrawerOverviewSection';

const tabRecords = [
  {
    label: <FM defaultMessage="Overview" />,
    value: 'overview',
  },
  {
    label: <FM defaultMessage="Scores" />,
    value: 'scores',
  },
];
export interface PackageVersionDetailDrawerProps
  extends InfoDrawerContentProps {
  namespace: string;
  packageContexture?: PackageContexture.Packages | PackageContexture.Dependents;
  sourcePackage?: PackageVersionResource;
  uuid: string;
}
export const PackageVersionDetailDrawer = ({
  namespace,
  uuid,
}: PackageVersionDetailDrawerProps) => {
  const {
    dependencyMetadata,
    dependentGroups,
    findingsByLevelAndReachability,
    licenses,
    packageVersionQueryObject,
    scoreCard,
    scoreFactors,
    project,
    isLoading,
    isPackageVersionLoading,
  } = usePackageVersionDetailData({ namespace, uuid });

  const { packageName } = useMemo(
    () => getPackageVersionDetails(packageVersionQueryObject),
    [packageVersionQueryObject]
  );
  const overviewContent = (
    <PackageVersionDetailDrawerOverviewSection
      uuid={uuid}
      dependencyMetadata={dependencyMetadata}
      dependentGroups={dependentGroups}
      findingsByLevelAndReachability={findingsByLevelAndReachability}
      licenses={licenses}
      packageVersionQueryObject={packageVersionQueryObject}
      project={project}
      isLoading={isLoading}
    />
  );

  const scoresContent = (
    <DetailDrawerScoreSection
      scoreCard={scoreCard}
      scoreFactors={scoreFactors}
      isLoading={isLoading}
    />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title: (
          <PackageVersionDetailDrawerHeader
            packageVersionName={
              packageVersionQueryObject?.meta.name ?? packageName
            }
            isLoading={isPackageVersionLoading}
            namespace={namespace}
          />
        ),
      }}
      tabProps={{
        id: 'PackageVersionDetailDrawer',
        tabRecords: tabRecords,
        tabPanelMap: {
          overview: overviewContent,
          scores: scoresContent,
        },
      }}
    />
  );
};
