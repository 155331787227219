import { StackProps, useTheme } from '@mui/material';

import { RowStack } from '../RowStack';

interface ButtonStackProps extends StackProps {}

export const ButtonStack = ({ ...props }: ButtonStackProps) => {
  const { space } = useTheme();

  return (
    <RowStack {...props} alignItems="center" flexWrap="wrap" gap={space.xs} />
  );
};
