import {
  Box,
  FormLabel,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonCancel, ControlledTextField } from '@endorlabs/ui-common';

const epssThresholdValidationMessages = defineMessages({
  min: {
    defaultMessage: 'EPSS probability value must be a number greater than 0',
  },
  max: {
    defaultMessage: 'EPSS probability value must be a number less than 100',
  },
});

export const EpssThresholdFields = () => {
  const { formatMessage } = useIntl();
  const { space } = useTheme();

  const { control, reset } = useFormContext();

  return (
    <Stack direction="column" spacing={space.md} component="fieldset">
      <FormLabel component="legend">
        <Typography variant="body1" color="text.primary">
          <FM defaultMessage="Customize Exploitable Likelihood (EPSS) accordingly for your AppSec program." />
        </Typography>
      </FormLabel>

      <Stack direction="row" spacing={space.sm} alignItems="flex-start">
        <ControlledTextField
          control={control}
          inputProps={{ max: 100, min: 0 }}
          label={<FM defaultMessage="EPSS Probability" />}
          name="epssProbabilityThreshold"
          type="number"
          required
          rules={{
            max: {
              value: 100,
              message: formatMessage(epssThresholdValidationMessages.max),
            },
            min: {
              value: 0,
              message: formatMessage(epssThresholdValidationMessages.min),
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <Box paddingTop={space.sm}>
          <ButtonCancel onClick={() => reset()}>
            <FM defaultMessage="Reset" />
          </ButtonCancel>
        </Box>
      </Stack>
    </Stack>
  );
};
