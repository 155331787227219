import { RowData } from '@tanstack/react-table';

export type DataTableRowData = RowData;

export type DataTableRowSelectionRow = DataTableRowData & {
  optionLabel: string;
  optionValue: string;
};

export const DataTableRowActionTypeKeys = {
  ACTIVATE_DRAWER: 'ACTIVATE_DRAWER',
  DELETE_RESOURCE: 'DELETE_RESOURCE',
  EDIT_RESOURCE: 'EDIT_RESOURCE',
  LINK: 'LINK',
  CLONE_RESOURCE: 'CLONE_RESOURCE',
} as const;

export type DataTableRowActionTypes =
  (typeof DataTableRowActionTypeKeys)[keyof typeof DataTableRowActionTypeKeys];

export type DataTableRowActionType =
  (typeof DataTableRowActionTypeKeys)[keyof typeof DataTableRowActionTypeKeys];
