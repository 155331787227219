import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type ConstellationProps = {
  color?: string;
  size?: string | number;
};

const Constellation = forwardRef<SVGSVGElement, ConstellationProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M6.24426 10.4341L10.7777 4.58318M6.24426 10.4341C5.8929 10.1545 5.44953 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14C5.47033 14 5.90275 13.8376 6.24426 13.5659M6.24426 10.4341C6.70478 10.8005 7 11.3658 7 12C7 12.6342 6.70478 13.1995 6.24426 13.5659M10.7777 4.58318C11.1157 4.84451 11.5397 5 12 5C12.4603 5 12.8843 4.84451 13.2223 4.58318M10.7777 4.58318C10.3046 4.21741 10 3.64428 10 3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3C14 3.64428 13.6954 4.21741 13.2223 4.58318M6.24426 13.5659L10.7777 19.4168M10.7777 19.4168C11.1157 19.1555 11.5397 19 12 19C12.4603 19 12.8843 19.1555 13.2223 19.4168M10.7777 19.4168C10.3046 19.7826 10 20.3557 10 21C10 22.1046 10.8954 23 12 23C13.1046 23 14 22.1046 14 21C14 20.3557 13.6954 19.7826 13.2223 19.4168M13.2223 19.4168L17.7557 13.5659M17.7557 13.5659C18.0973 13.8376 18.5297 14 19 14C20.1046 14 21 13.1046 21 12C21 10.8954 20.1046 10 19 10C18.5297 10 18.0973 10.1624 17.7557 10.4341M17.7557 13.5659C17.2952 13.1995 17 12.6342 17 12C17 11.3658 17.2952 10.8005 17.7557 10.4341M17.7557 10.4341L13.2223 4.58318"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M20 3C20 2.44771 20.4477 2 21 2C21.5523 2 22 2.44771 22 3C22 3.32214 21.8477 3.6087 21.6111 3.79159C21.4421 3.92225 21.2301 4 21 4C20.7699 4 20.5579 3.92225 20.3889 3.79159C20.1523 3.6087 20 3.32214 20 3Z"
          fill={color}
        />
        <path
          d="M11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.3221 12.8477 12.6087 12.6111 12.7916C12.4421 12.9223 12.2301 13 12 13C11.7699 13 11.5579 12.9223 11.3889 12.7916C11.1523 12.6087 11 12.3221 11 12Z"
          fill={color}
        />
        <path
          d="M2 21C2 20.4477 2.44771 20 3 20C3.55228 20 4 20.4477 4 21C4 21.3221 3.84768 21.6087 3.61113 21.7916C3.44213 21.9223 3.23015 22 3 22C2.76985 22 2.55787 21.9223 2.38887 21.7916C2.15232 21.6087 2 21.3221 2 21Z"
          fill={color}
        />
        <path
          d="M20 21C20 20.4477 20.4477 20 21 20C21.5523 20 22 20.4477 22 21C22 21.3221 21.8477 21.6087 21.6111 21.7916C21.4421 21.9223 21.2301 22 21 22C20.7699 22 20.5579 21.9223 20.3889 21.7916C20.1523 21.6087 20 21.3221 20 21Z"
          fill={color}
        />
        <path
          d="M2 3C2 2.44771 2.44771 2 3 2C3.55228 2 4 2.44771 4 3C4 3.32214 3.84768 3.6087 3.61113 3.79159C3.44213 3.92225 3.23015 4 3 4C2.76985 4 2.55787 3.92225 2.38887 3.79159C2.15232 3.6087 2 3.32214 2 3Z"
          fill={color}
        />
      </svg>
    );
  }
);

Constellation.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Constellation.displayName = 'Constellation';

export default Constellation;
