import { Box, Card, CardContent, Grid, Skeleton, Stack } from '@mui/material';
import { range as _range } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecEndorLicenseFeatureType,
  V1Ecosystem,
} from '@endorlabs/api_client';
import { DEFAULT_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';
import {
  FILTER_COMPARATORS,
  filterExpressionBuilders,
} from '@endorlabs/filters';
import { PackageContexture } from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonLinkPrimary,
  ButtonPrimary,
  EmptyState,
  IconPlanet,
  MultiSelectInput,
} from '@endorlabs/ui-common';
import {
  EcosystemLabel,
  useAggregatedPackageVersions,
} from '@endorlabs/ui-common/domains/Package';

import { PageHeader, PageHeaderCount } from '../../../components';
import {
  FilterBar,
  FilterFieldConfig,
  filterFieldTransformBuilders,
  useFilterContext,
  withFilterProvider,
} from '../../../domains/filters';
import { useAuthInfo, useLicensingInfo } from '../../../providers';
import { getProjectPath } from '../../../routes';
import { PackagesIndexDetail } from './PackagesIndexDetail';

const PAGE_SIZE = 25;
const FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: 'PackageVersion:spec.ecosystem',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'spec.ecosystem',
      comparator: FILTER_COMPARATORS.IN,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Ecosystem" />}
        onChange={onChange}
        value={value}
        options={DEFAULT_ECOSYSTEMS.map((value) => ({
          value,
          label: <EcosystemLabel value={value} />,
        }))}
      />
    ),
  } satisfies FilterFieldConfig<V1Ecosystem[]>,
];

const BasePackagesIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const { checkLicensePresent } = useLicensingInfo();

  const isContainerLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.ContainerScan
  );

  const basePackagesFilterExpression = isContainerLicensePresent
    ? filterExpressionBuilders.defaultResourceContexts()
    : filterExpressionBuilders.and([
        filterExpressionBuilders.defaultResourceContexts(),
        `spec.ecosystem != ${V1Ecosystem.Container}`,
      ]);

  const { filter: filterExpression, clearFilter } = useFilterContext();

  const { error, isLoading, data, refetch, totalCount } =
    useAggregatedPackageVersions({
      namespace: tenantName,
      baseFilterExpression: basePackagesFilterExpression,
      filterExpression,
    });

  // manual pagination
  const [visibleCount, setVisibleCount] = useState(PAGE_SIZE);
  useEffect(() => {
    setVisibleCount(Math.min(data.length, PAGE_SIZE));
  }, [data.length]);

  const visiblePackages = useMemo(() => {
    // Maps the aggregated package resources to the expected query response shape
    return data.slice(0, visibleCount);
  }, [data, visibleCount]);

  const hasError = !isLoading && error;
  const isEmptyState = !hasError && !isLoading && totalCount == 0;
  const isFilteredEmptyState =
    !hasError && !isLoading && totalCount !== 0 && data.length === 0;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconPlanet}
          isLoading={isLoading}
          title={<FM defaultMessage="All Packages" />}
          titleDetails={
            data.length ? <PageHeaderCount value={data.length} /> : undefined
          }
        />
      </Grid>

      <Grid item>
        <FilterBar fields={FILTER_FIELDS} />
      </Grid>

      {hasError && (
        <Grid item>
          <EmptyState
            title={
              error?.message ?? <FM defaultMessage="Failed to load Packages" />
            }
            description={
              error?.details ?? (
                <FM defaultMessage="The request to load the Packages failed to complete. Please remove filters or try again." />
              )
            }
          >
            <Stack direction="row" spacing={4}>
              {!!filterExpression && (
                <ButtonPrimary onClick={clearFilter}>
                  <FM defaultMessage="Clear Filters" />
                </ButtonPrimary>
              )}

              <ButtonPrimary onClick={() => refetch()}>
                <FM defaultMessage="Try Again" />
              </ButtonPrimary>
            </Stack>
          </EmptyState>
        </Grid>
      )}

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="Add some projects to see their packages here" />
            }
            description={
              <FM defaultMessage="As your inventory of packages grows, this is where you can easily search across them." />
            }
          >
            <ButtonLinkPrimary
              linkProps={{ to: getProjectPath({ tenantName, uuid: 'new' }) }}
            >
              <FM defaultMessage="Add Project" />
            </ButtonLinkPrimary>
          </EmptyState>
        </Grid>
      )}

      {isFilteredEmptyState && (
        <Grid item>
          <EmptyState
            size="medium"
            title={
              <FM defaultMessage="No findings match the filter criteria" />
            }
          >
            <ButtonCancel onClick={clearFilter}>
              <FM defaultMessage="Clear Filter" />
            </ButtonCancel>
          </EmptyState>
        </Grid>
      )}

      {isLoading &&
        _range(0, 4).map((_, index) => (
          <Grid item key={index}>
            <Card sx={{ borderRadius: 1 }}>
              <CardContent>
                <Stack direction="row" spacing={4} marginBottom={2}>
                  <Skeleton height={32} width={20} />
                  <Skeleton height={32} width={240} />
                </Stack>
                <Stack direction="row" spacing={4}>
                  <Skeleton height={24} width={128} />
                  <Skeleton height={24} width={96} />
                  <Skeleton height={24} width={256} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}

      {!hasError && !isEmptyState && (
        <Grid item>
          <Stack spacing={6} role="list">
            {visiblePackages.map(
              ({
                packageName,
                packageResource,
                projectUuid,
                versionCount,
                versions,
              }) => (
                // NOTE: packages are grouped by name, project
                <Box key={`${packageName}:${projectUuid}`} role="listitem">
                  <PackagesIndexDetail
                    packageContexture={PackageContexture.Packages}
                    packageResource={packageResource}
                    projectUuid={projectUuid}
                    versionCount={versionCount}
                    versions={versions}
                  />
                </Box>
              )
            )}
          </Stack>
        </Grid>
      )}

      {!isEmptyState && visibleCount < data.length && (
        <Grid item>
          <Box display="flex" justifyContent="center">
            <ButtonPrimary
              onClick={() => setVisibleCount((count) => count + PAGE_SIZE)}
            >
              <FM defaultMessage="View More Packages" />
            </ButtonPrimary>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

/**
 * Wire Filter Context to page
 */
export const PackagesIndexPage = withFilterProvider(BasePackagesIndexPage, {
  displayName: 'PackagesIndexPage',
  searchKeys: ['meta.name', 'meta.tags'],
});
