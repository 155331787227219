import { V1Ecosystem } from '@endorlabs/api_client';

import {
  ImgIcon,
  ImgIconLogoCocoapods,
  ImgIconLogoCPlusPlus,
  ImgIconLogoGitHubAction,
  ImgIconLogoGo,
  ImgIconLogoMarkDebian,
  ImgIconLogoMarkDocker,
  ImgIconLogoMaven,
  ImgIconLogoNPM,
  ImgIconLogoNuget,
  ImgIconLogoPackagist,
  ImgIconLogoPypi,
  ImgIconLogoRubyGems,
  ImgIconLogoRust,
} from '../../../themes/icons';

/**
 * Icons for known ecosystems
 */
export const PackageEcosystemIcons: Partial<Record<V1Ecosystem, ImgIcon>> = {
  [V1Ecosystem.C]: ImgIconLogoCPlusPlus,
  [V1Ecosystem.Cargo]: ImgIconLogoRust,
  [V1Ecosystem.Cocoapod]: ImgIconLogoCocoapods,
  [V1Ecosystem.Container]: ImgIconLogoMarkDocker,
  [V1Ecosystem.Debian]: ImgIconLogoMarkDebian,
  [V1Ecosystem.Gem]: ImgIconLogoRubyGems,
  [V1Ecosystem.GithubAction]: ImgIconLogoGitHubAction,
  [V1Ecosystem.Go]: ImgIconLogoGo,
  [V1Ecosystem.Maven]: ImgIconLogoMaven,
  [V1Ecosystem.Npm]: ImgIconLogoNPM,
  [V1Ecosystem.Nuget]: ImgIconLogoNuget,
  [V1Ecosystem.Packagist]: ImgIconLogoPackagist,
  [V1Ecosystem.Pypi]: ImgIconLogoPypi,
} as const;
