import { Box, Grid, Slide, Stack, Typography } from '@mui/material';
import { MakeGenerics, useNavigate } from '@tanstack/react-location';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ContextContextType } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageVersionResource,
  useListAllPackageVersions,
  useListPackageVersions,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  ButtonSecondary,
  IconCode,
  IconDownload,
  IconLayout,
  PackageIconDisplay,
  REGEX_COMMIT_SHA_VALIDATION,
  UIPackageVersionUtils,
  useDialog,
} from '@endorlabs/ui-common';

import { PageHeader, ResourceVersionSelectV2 } from '../../components';
import { ExportedSBOMCreateDialog } from '../../domains/ExportedSBOM';
import { useAuthInfo } from '../../providers';
import {
  getPackageVersionPath,
  getPackageVersionRootPath,
  NamedRoutes,
  useFullMatch,
  usePageTitle,
} from '../../routes';
import { PackageVersionDetailView } from '../PackageVersions';
import { usePackageVersionMetadata } from '../PackageVersions/usePackageVersionMetadata';
import { PackageMetadataView } from './PackageMetadataView';

type PackageDetailLocationGenerics = MakeGenerics<{
  Search: {
    versionRef: string;
    mode: string;
  };
  Params: {
    packageVersionUuid: string;
    activeView: string;
  };
}>;

export const PackageDetailPage = () => {
  const {
    params: { packageVersionUuid, activeView },
    search: { mode, versionRef },
  } = useFullMatch<PackageDetailLocationGenerics>();
  const { activeNamespace: tenantName } = useAuthInfo();

  const isOssPackage = tenantName === NAMESPACES.OSS;

  const navigate = useNavigate<PackageDetailLocationGenerics>();

  const packageExportDialog = useDialog({
    component: ExportedSBOMCreateDialog,
  });

  const [viewMode, setViewMode] = useState<string | undefined>(mode);

  const qPackageVersion = useListPackageVersions(
    tenantName,
    {
      // Prevents flash on version change
      keepPreviousData: true,
    },
    {
      filter: `uuid=="${packageVersionUuid}"`,
      mask: ['uuid', 'tenant_meta', 'context', 'meta.name', 'spec'].join(','),
      page_size: 1,
    }
  );

  // NOTE: backwards-compatible handling for older links to the package details page
  // `/t/{namespace}/packages/{package_uuid}?versionRef={version_ref}`
  const qPackageVersionFallback = useListPackageVersions(
    tenantName,
    {
      enabled: !!versionRef,
    },
    {
      filter: filterExpressionBuilders.and([
        `meta.parent_uuid=="${packageVersionUuid}"`,
        `spec.source_code_reference.version.ref=="${versionRef}"`,
      ]),
    }
  );

  // Get the package or fallback
  const packageVersion =
    qPackageVersion.data?.list?.objects[0] ??
    qPackageVersionFallback.data?.list?.objects[0];

  // Load related package versions
  const qPackageVersionRefs = useListAllPackageVersions(
    tenantName,
    {
      enabled:
        !!packageVersion?.spec.project_uuid &&
        !!packageVersion?.spec.package_name,
    },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.defaultResourceContexts(),
        `spec.project_uuid=="${packageVersion?.spec.project_uuid}"`,
        `spec.package_name=="${packageVersion?.spec.package_name}"`,
      ]),
      mask: [
        'uuid',
        'tenant_meta',
        'context',
        'meta.name',
        'spec.project_uuid',
      ].join(','),
    }
  );

  const { ecosystem: packageVersionEcosystem, label: packageName } = useMemo(
    () =>
      UIPackageVersionUtils.parsePackageName(packageVersion?.meta.name ?? ''),
    [packageVersion]
  );

  const packageVersionRefs = useMemo(
    () =>
      (qPackageVersionRefs.data ?? []).sort((a, b) => {
        return UIPackageVersionUtils.sortBySemanticVersion(
          UIPackageVersionUtils.parsePackageName(b.meta.name).version,
          UIPackageVersionUtils.parsePackageName(a.meta.name).version
        );
      }),
    [qPackageVersionRefs.data]
  );

  const handleChangePackageVersion = useCallback(
    (packageVersion: PackageVersionResource) => {
      navigate({
        to: getPackageVersionPath({
          tenantName: packageVersion.tenant_meta.namespace,
          uuid: packageVersion.uuid,
        }),
      });
    },
    [navigate]
  );

  const packageVersionMetadata = usePackageVersionMetadata(
    tenantName,
    isOssPackage,
    packageVersion?.uuid
  );

  const packageVersionMetadataProps = {
    summary: packageVersionMetadata.summary,
  };

  const isMetadataView = !!viewMode && viewMode === 'metadata';

  usePageTitle({
    packageName: packageName,
    packageVersion: getResourceLabel(packageVersion, { raw: true }),
  });

  // For oss packages, return the user to the OSS Explorer, rather than the tenant package page
  const breadcrumbsLinks = isOssPackage
    ? [
        {
          url: NamedRoutes.OSS_EXPLORER,
          label: <FM defaultMessage="OSS Packages" />,
        },
      ]
    : [
        {
          url: getPackageVersionRootPath({ tenantName }),
          label: <FM defaultMessage="All Packages" />,
        },
      ];

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item data-testid="package-details-header">
        <PageHeader
          action={
            <Stack direction="row" spacing={2}>
              <ButtonSecondary
                data-testid="package-details-secondary-button"
                onClick={() =>
                  setViewMode(isMetadataView ? undefined : 'metadata')
                }
                startIcon={isMetadataView ? <IconLayout /> : <IconCode />}
              >
                {isMetadataView ? (
                  <FM defaultMessage="Package Details" />
                ) : (
                  <FM defaultMessage="Raw Data" />
                )}
              </ButtonSecondary>

              <ButtonPrimary
                data-testid="package-details-primary-button"
                disabled={!packageVersion}
                onClick={() => {
                  if (!packageVersion) return;
                  packageExportDialog.openDialog({
                    namespace: packageVersion.tenant_meta.namespace,
                    packageVersion,
                  });
                }}
                startIcon={<IconDownload />}
              >
                <FM defaultMessage="Export SBOM" />
              </ButtonPrimary>
            </Stack>
          }
          breadcrumbsLinks={breadcrumbsLinks}
          breadcrumbsItems={[
            <>
              <PackageIconDisplay
                ecosystem={packageVersionEcosystem}
                displayDefault="package"
                size="inherit"
              />
              <Typography variant="button">{packageName}</Typography>
            </>,
            ...(packageVersion
              ? [
                  <ResourceVersionSelectV2
                    key={packageVersion.uuid}
                    getLabelFn={getResourceLabel}
                    isLoading={
                      qPackageVersion.isLoading || qPackageVersionRefs.isLoading
                    }
                    resourceList={packageVersionRefs}
                    selectedResource={packageVersion}
                    onChange={handleChangePackageVersion}
                  />,
                ]
              : []),
          ]}
          image={
            <PackageIconDisplay
              ecosystem={packageVersionEcosystem}
              displayDefault="package"
              size="large"
            />
          }
          isLoading={qPackageVersion.isLoading}
          metadata={packageVersionMetadataProps}
          title={packageName}
        />
      </Grid>

      <Grid item>
        {packageVersion && (
          <Box sx={{ position: 'relative' }}>
            {/* Regular view */}
            <Box
              style={{
                width: '100%',
                visibility: isMetadataView ? 'hidden' : 'visible',
              }}
            >
              <PackageVersionDetailView
                activeTab={activeView}
                packageVersion={packageVersion}
                tenantName={tenantName}
              />
            </Box>

            {/* Raw code view */}
            <Slide
              direction="up"
              in={isMetadataView}
              mountOnEnter
              unmountOnExit
            >
              <Box
                sx={({ palette, spacing }) => ({
                  backgroundColor: palette.background.default,
                  paddingBottom: spacing(6),
                  position: 'absolute',
                  top: spacing(6),
                  width: '100%',
                })}
              >
                {/* Raw code view */}
                <PackageMetadataView
                  packageVersionObj={packageVersion as PackageVersionResource}
                />
              </Box>
            </Slide>
          </Box>
        )}
      </Grid>

      {/* SBOM Export Dialog */}
      <packageExportDialog.Dialog {...packageExportDialog.dialogProps} />
    </Grid>
  );
};

function getResourceLabel(
  resource?: PackageVersionResource,
  options?: { raw: boolean }
): string {
  let versionRef = UIPackageVersionUtils.parsePackageName(
    resource?.meta.name ?? ''
  ).version;

  if (options?.raw) {
    return versionRef;
  }

  // shorten commit sha display
  if (REGEX_COMMIT_SHA_VALIDATION.test(versionRef)) {
    versionRef = versionRef.slice(0, 7);
  }

  if (resource?.context.type === ContextContextType.Ref) {
    let contextId = resource.context.id;

    // shorten commit sha display
    if (REGEX_COMMIT_SHA_VALIDATION.test(contextId)) {
      contextId = contextId.slice(0, 7);
    }

    if (versionRef !== contextId) {
      return `${versionRef} (${contextId})`;
    }
  }

  return versionRef;
}
