import { ChangeEvent, useEffect } from 'react';
import {
  ControllerProps,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';

import { CheckboxInput, CheckboxInputProps } from './CheckboxInput';

export type ControlledCheckboxV2Props<T extends FieldValues> = Pick<
  ControllerProps<T>,
  'name'
> &
  CheckboxInputProps;

export const ControlledCheckboxV2 = <T extends FieldValues>({
  name,
  ...props
}: ControlledCheckboxV2Props<T>) => {
  const { clearErrors, control } = useFormContext<T>();

  const { field, fieldState } = useController({
    control,
    name,
  });

  const handleChange = (evt: ChangeEvent) => {
    field.onChange(evt);
    field.onBlur();
  };

  useEffect(() => {
    clearErrors(name);
  }, [clearErrors, name]);

  const errorMessage = fieldState.error?.message;

  return (
    <CheckboxInput
      {...props}
      checked={field.value === true}
      error={errorMessage}
      onChange={handleChange}
    />
  );
};
