import { minutesToMilliseconds } from 'date-fns';
import { chunk as _chunk, groupBy as _groupBy } from 'lodash-es';
import { QueryKey, useQueries } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryServiceApi,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1Query,
  V1TenantMeta,
} from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration } from './utils';

const QUERY_STALE_TIME = minutesToMilliseconds(15);
// TODO: size chunks to maximize performance vs pagination
const QUERY_CHUNK_SIZE = 250;

export const QueryRelatedMetricsQueryKeys = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey => [
    'v1/queries',
    namespace,
    'related-metrics',
    listParams,
  ],
};

export type QueryRelatedMetricsResponse =
  TResourceList<QueryRelatedMetricsResponseObject>;

export type QueryRelatedMetricsResponseObject = SelectFrom<
  V1Metric,
  'uuid' | 'context',
  {
    tenant_meta: SelectFrom<V1TenantMeta, 'namespace'>;
    meta: SelectFrom<V1Meta, 'name' | 'parent_uuid'>;
    spec: SelectFrom<V1MetricSpec, 'metric_values'>;
  }
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  kind: string,
  rootListParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryResourceRelatedMetrics(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Metric',
        list_parameters: {
          ...rootListParams,
          mask: [
            'uuid',
            'tenant_meta.namespace',
            'context',
            'meta.name',
            'meta.parent_uuid',
            'spec.metric_values',
          ].join(','),
        },
      },
    },
    tenant_meta: { namespace },
  };
};

/**
 * Get the metric "types" supported by the resource
 *
 * NOTE: currently restricted to 2 types to avoid pagination limits {@see QUERY_CHUNK_SIZE}
 */
const getResourceMetricTypes = (
  kind: string
): [primary: string, secondary?: string] => {
  switch (kind) {
    case 'PackageVersion':
      return ['package_version_scorecard'];
    default:
      throw new Error('Unsupported resource kind: ' + kind);
  }
};

const queryResourceRelatedMetrics = async (
  namespace: string,
  kind: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, kind, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });

  return resp.data.spec?.query_response as QueryRelatedMetricsResponse;
};

/**
 * Custom query for metrics related to the given resources
 */
export const useQueryResourceRelatedMetrics = (
  resources: PackageVersionResource[],
  queryOptions: Pick<
    ResourceQueryOptions<QueryRelatedMetricsResponse>,
    'enabled'
  > = {}
) => {
  const groups = _groupBy(resources, (r) => [
    r.tenant_meta.namespace,
    r.meta.kind,
  ]);

  const queries = Object.entries(groups).flatMap(([key, resources]) => {
    const [namespace, kind] = key.split(',');

    const chunks = _chunk(resources, QUERY_CHUNK_SIZE);

    return chunks.map((resources) => {
      // ensure stable sort for resource uuids used in query
      const uuids = resources.map((r) => r.uuid).sort();

      const metricTypes = getResourceMetricTypes(kind);
      const filters = [
        `meta.parent_uuid in [${uuids}]`,
        `meta.name in [${metricTypes}]`,
        filterExpressionBuilders.defaultResourceContexts(),
      ];

      const listParams: V1ListParameters = {
        filter: filters.join(' and '),
        // allow for all metrics from the given filter
        page_size: uuids.length * metricTypes.length,
      };

      return {
        queryKey: QueryRelatedMetricsQueryKeys.query(namespace, listParams),
        queryFn: ({ signal }) =>
          queryResourceRelatedMetrics(namespace, kind, listParams, signal),
        staleTime: QUERY_STALE_TIME,
        ...queryOptions,
      } as ResourceQueryOptions<QueryRelatedMetricsResponse>;
    });
  });

  const results = useQueries(queries);

  const isLoading = !!results.length && results.every((r) => r.isLoading);
  const isSuccess = results.some((r) => r.isSuccess);

  // TODO: better memoize data directly from from useQueries calls
  // see: https://github.com/TanStack/query/discussions/5123
  const data = results.flatMap((r) => r.data?.list?.objects ?? []);

  return { data, isLoading, isSuccess };
};
