import { VERSION_SEPARATOR } from '@endorlabs/ui-common';

export interface BreakingChangeURIDescriptor {
  language: string;
  projectName: string;
  version: string;
  packageName: string;
  className: string;
  functionName: string;
}

const DEFAULT_BREAKING_CHANGE_URI_DESCRIPTOR: BreakingChangeURIDescriptor = {
  language: '',
  projectName: '',
  version: '',
  packageName: '',
  className: '',
  functionName: '',
};

// TODO: Test these functions further and add units tests

export function parseBreakingChangeURI(
  uri: string
): BreakingChangeURIDescriptor {
  const regex =
    /^(?<language>[^:]+):\/\/(?<projectName>[^$]+)\$(?<version>[^/]+)\/(?<packageName>[^/]*)\/?(?:(?<className>[^.]*)\.)?(?<functionName>[^(]*)/;

  const match = uri.match(regex)?.groups;

  if (!match) {
    return DEFAULT_BREAKING_CHANGE_URI_DESCRIPTOR;
  }

  return {
    language: match.language || '',
    projectName: match.projectName || '',
    version: match.version || '',
    packageName: match.packageName || '',
    className: match.className || '',
    functionName: match.functionName || '',
  };
}

export type BreakingChangeReachablePaths = Record<
  string,
  BreakingChangeURIDescriptor[]
>;

export function parseBreakingChangeReachablePaths(
  reachablePaths: string[]
): BreakingChangeReachablePaths {
  const result: BreakingChangeReachablePaths = {};

  reachablePaths.forEach((path) => {
    const {
      language,
      projectName,
      version,
      packageName,
      className,
      functionName,
    } = parseBreakingChangeURI(path);
    const key = `${projectName}${VERSION_SEPARATOR}${version}`;

    if (!result[key]) {
      result[key] = [];
    }

    result[key].push({
      language,
      projectName,
      version,
      packageName,
      className,
      functionName,
    });
  });
  return result;
}
