import {
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  NotificationActionAggregationType,
  PolicyPolicyType,
} from '@endorlabs/api_client';
import { useListNotificationTargets } from '@endorlabs/queries';
import {
  ControlledTextField,
  ExclusiveToggleButtonGroup,
  Link,
  NotificationTargetDisplay,
  useExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getIntegrationsRootPath } from '../../../routes';

const AGGREGATION_TYPES = [
  {
    label: 'Dependency',
    value: NotificationActionAggregationType.Dependency,
  },
  {
    label: 'Project',
    value: NotificationActionAggregationType.Project,
  },
];

export const AdmissionPolicyFields = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const { control, setValue, watch } = useFormContext();
  const { formatMessage: fm } = useIntl();

  const notificationTargetsSet = watch(
    'spec.notification.notification_target_uuids'
  );

  type ControlViews = 'outcome' | 'notification';
  const {
    getToggleButtonGroupProps,
    value: selectedControl,
    setValue: setSelectedControl,
  } = useExclusiveToggleButtonGroup<ControlViews>('outcome', [
    { value: 'outcome', children: <FM defaultMessage="Enforce Policy" /> },
    {
      value: 'notification',
      children: <FM defaultMessage="Send Notification" />,
    },
  ]);

  useEffect(() => {
    if ((notificationTargetsSet ?? []).length > 0) {
      setValue('spec.admission.disable_enforcement', undefined);
      setSelectedControl('notification');
    }
  }, [notificationTargetsSet, setSelectedControl, setValue]);

  // HACK: hook into toggle button change to set form values
  const handleChangeControlView = (view: ControlViews) => {
    if (view === 'notification') {
      setValue('spec.admission', undefined);
      setValue('spec.policy_type', PolicyPolicyType.Notification);
      setValue(
        'spec.notification.aggregation_type',
        NotificationActionAggregationType.Project
      );
    } else {
      setValue('spec.admission.disable_enforcement', true);
      setValue('spec.notification.notification_target_uuids', []);
      setValue('spec.notification.aggregation_type', '');
      setValue('spec.policy_type', PolicyPolicyType.Admission);
    }
  };

  const notificationTargets =
    useListNotificationTargets(tenantName).data?.list?.objects ?? [];

  return (
    <Stack gap={space.md} width="100%">
      <ExclusiveToggleButtonGroup
        onChange={(event, value) => handleChangeControlView(value)}
        {...getToggleButtonGroupProps()}
        size="small"
      />

      <Stack
        alignItems="flex-start"
        direction="column"
        gap={space.md}
        width="100%"
      >
        {/* Admission outcome */}
        <Controller
          control={control}
          name="spec.admission.disable_enforcement"
          render={({ field: { onChange, ...props } }) => (
            <ToggleButtonGroup
              {...props}
              exclusive
              onChange={(_, val) => {
                if (val != undefined) {
                  onChange(val);
                }
              }}
              sx={{
                display: selectedControl === 'outcome' ? 'auto' : 'none',
                flexShrink: 0,
              }}
            >
              <ToggleButton value={true}>
                <FM defaultMessage="Warn" />
              </ToggleButton>

              <ToggleButton value={false}>
                <FM defaultMessage="Break the Build" />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        />

        {/* Alert Target selector */}
        <ControlledTextField
          control={control}
          defaultValue={[]}
          disabled={notificationTargets.length === 0}
          helperText={
            <Link target="_blank" to={getIntegrationsRootPath({ tenantName })}>
              {fm({
                defaultMessage:
                  'Manage notification targets for this namespace',
              })}
            </Link>
          }
          fullWidth
          label={fm({ defaultMessage: 'Select notification targets' })}
          name="spec.notification.notification_target_uuids"
          select
          SelectProps={{ multiple: true }}
          sx={{
            display: selectedControl === 'notification' ? 'auto' : 'none',
          }}
        >
          {notificationTargets.map((notificationTarget) => (
            <MenuItem
              key={notificationTarget.uuid}
              value={notificationTarget.uuid}
            >
              <NotificationTargetDisplay
                notificationTarget={notificationTarget}
              />
            </MenuItem>
          ))}
        </ControlledTextField>

        {/* Aggregation Type selector */}
        <ControlledTextField
          control={control}
          defaultValue={[]}
          fullWidth
          label={fm({ defaultMessage: 'Select aggregation type' })}
          name="spec.notification.aggregation_type"
          select
          SelectProps={{
            multiple: false,
          }}
          sx={{
            display: selectedControl === 'notification' ? 'auto' : 'none',
          }}
        >
          {AGGREGATION_TYPES.map((aggregationType) => (
            <MenuItem key={aggregationType.value} value={aggregationType.value}>
              <Typography>{aggregationType.label}</Typography>
            </MenuItem>
          ))}
        </ControlledTextField>
      </Stack>
    </Stack>
  );
};
