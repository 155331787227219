import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Tooltip,
  useTheme,
} from '@mui/material';
import _sortBy from 'lodash-es/sortBy';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { applyFilters } from '@endorlabs/filters';
import { useListSecretRules } from '@endorlabs/queries';
import {
  ButtonPrimary,
  ConfirmationDialog,
  EmptyState,
  IconLock,
  SecretRuleUpsertDialog,
  useSecretRuleUpsertDialog,
} from '@endorlabs/ui-common';
import {
  SecretRulesTable,
  SecretRulesTableRow,
} from '@endorlabs/ui-common/domains/SecretRule/components/SecretRulesTable';
import { useDeleteSecretRuleConfirmationDialog } from '@endorlabs/ui-common/domains/SecretRule/hooks/useDeleteSecretRuleConfirmationDialog';

import { FacetedSearchBar, useFacetedSearchBar } from '../../../components';
import { useLicensingInfo } from '../../../providers';
import { SecretRuleFacets } from '../../SecretRules/constants/filters';

export const SecretsView = ({ namespace }: { namespace: string }) => {
  const { space } = useTheme();
  const { checkLicensePresent } = useLicensingInfo();

  const { getSecretRuleUpsertDialogProps, openSecretRuleUpsertDialog } =
    useSecretRuleUpsertDialog({ namespace });
  const DeleteConfirmationDialog = useDeleteSecretRuleConfirmationDialog();

  const qListSecretRules = useListSecretRules(
    namespace,
    {},
    { page_size: 500 }
  );

  const secretRules = useMemo(() => {
    return qListSecretRules.data?.list?.objects ?? [];
  }, [qListSecretRules.data]);

  const handleEditSecretRule = useCallback(
    (_: unknown, row: SecretRulesTableRow) => {
      const secretRule = secretRules.find((rule) => rule.uuid === row.uuid);

      openSecretRuleUpsertDialog({ namespace, secretRule });
    },
    [namespace, secretRules, openSecretRuleUpsertDialog]
  );

  const handleDeleteSecretRule = useCallback(
    (_: unknown, row: SecretRulesTableRow) => {
      const secretRule = secretRules.find((rule) => rule.uuid === row.uuid);

      // validate resource exists before using
      if (secretRule) {
        DeleteConfirmationDialog.openDialog(secretRule);
      }
    },
    [DeleteConfirmationDialog, secretRules]
  );

  const { getFacetedSearchBarProps, filters, searchValue } =
    useFacetedSearchBar({
      // @ts-expect-error - Don't have generated filter definition for SecretRule
      resourceKind: 'SecretRule',
    });

  // HACK: Need to add current namespace to facet options
  const namespaceFacet = SecretRuleFacets.find(
    (facet) => facet.id === 'SecretRule:tenant_meta.namespace'
  );

  if (namespaceFacet && namespaceFacet.values?.length === 1) {
    namespaceFacet.values.push({
      key: namespace,
      value: namespace,
      label: <FM defaultMessage="Custom" />,
    });
  }

  const filteredSecretRules = applyFilters(filters, secretRules);

  const searchedSecretRules = useMemo(() => {
    if (!searchValue) return filteredSecretRules;
    const filterKeys = ['meta.name', 'meta.description', 'meta.tags'];

    return matchSorter(filteredSecretRules, searchValue, {
      // use the default sort order as tie breaker
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: filterKeys,
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [filteredSecretRules, searchValue]);

  const secretRulesTableRows = useMemo(() => {
    return _sortBy(
      searchedSecretRules.map((rule) => ({
        description: rule.spec.description,
        isSystem: rule.tenant_meta.namespace === 'system',
        name: rule.meta.name,
        namespace,
        uuid: rule.uuid,
        validator: Boolean(rule.spec.validation?.http_request?.uri),
      })),
      [
        (sr) => (sr.validator ? 0 : 1),
        (sr) => (sr.namespace === 'system' ? 1 : 0),
      ]
    );
  }, [namespace, searchedSecretRules]);

  const isLoading = qListSecretRules.isLoading;
  const isEmptyState = !isLoading && !secretRules.length;
  const isSecretsLicense = checkLicensePresent(
    SpecEndorLicenseFeatureType.Secrets
  );

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        {secretRules.length > 0 && (
          <Grid item>
            <FacetedSearchBar
              {...getFacetedSearchBarProps()}
              facets={SecretRuleFacets}
            />
          </Grid>
        )}

        <Grid item>
          <Card>
            <CardHeader
              action={
                !isEmptyState && (
                  <Tooltip
                    title={
                      !isSecretsLicense ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Box pr={2}>
                            <IconLock />
                          </Box>
                          <FM defaultMessage="License required" />
                        </Box>
                      ) : (
                        ''
                      )
                    }
                  >
                    <span>
                      <ButtonPrimary
                        onClick={() =>
                          openSecretRuleUpsertDialog({ namespace })
                        }
                        disabled={!isSecretsLicense}
                      >
                        <FM defaultMessage="Create Secrets Rule" />
                      </ButtonPrimary>
                    </span>
                  </Tooltip>
                )
              }
              title={
                <FM defaultMessage="Manage and configure your secrets detection rules" />
              }
            ></CardHeader>

            <CardContent>
              <Grid container direction="column" spacing={space.md}>
                {isEmptyState && (
                  <Grid item>
                    <EmptyState
                      size="medium"
                      title={
                        <FM defaultMessage="You have not created any secrets rules" />
                      }
                    ></EmptyState>
                  </Grid>
                )}

                {/* hide table and wrapper when empty state */}
                {!isEmptyState && (
                  <Grid item>
                    <SecretRulesTable
                      data={secretRulesTableRows}
                      onDelete={handleDeleteSecretRule}
                      onEdit={handleEditSecretRule}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Auth Policy Related Dialogs */}
      <SecretRuleUpsertDialog {...getSecretRuleUpsertDialogProps()} />

      <ConfirmationDialog
        {...DeleteConfirmationDialog.getConfirmationDialogProps()}
      />
    </>
  );
};
