import { QueryState } from 'react-query/types/core/query';

/**
 * Consider as loading when:
 * - `undefined` - query may not yet be registered with dashboard queries
 * - `idle` - query may not yet be enabled
 * - `loading` - query data is loading
 */
export const isQueryStateLoading = (q?: QueryState) =>
  !q || q.status === 'idle' || q.status === 'loading';
