import { Button } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory } from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/queries';
import {
  FindingCategorySelect,
  FullHeightCard,
  IconSidebarRight,
  ResponsiveCardLayout,
} from '@endorlabs/ui-common';

import {
  FindingRiskMatrixTable,
  ScoreCardDisplay,
  ScoreFactorsOverview,
} from '../../components';
import { useScoreFactorsDrawer } from '../../domains/Metrics';
import { usePackageVersionDetailData } from '../../domains/PackageVersion';
import { PackageVersionDetailOverviewMetadata } from './PackageVersionDetailOverviewMetadata';

interface PackageVersionDetailOverviewProps {
  namespace: string;
  packageVersion?: PackageVersionResource;
}

export const PackageVersionDetailOverview = ({
  namespace,
  packageVersion,
}: PackageVersionDetailOverviewProps) => {
  const {
    dependencyMetadata,
    dependentGroups,
    findingsByLevelAndReachability: findingGroups,
    licenses,
    packageVersionQueryObject,
    scoreCard,
    scoreFactors,
    isLoading,
  } = usePackageVersionDetailData({
    namespace,
    uuid: packageVersion?.uuid,
  });

  const { DetailDrawer, permalinkEffect } = useScoreFactorsDrawer();

  useLayoutEffect(() => {
    permalinkEffect();
  }, [packageVersionQueryObject, permalinkEffect]);

  const [activeFindingCategory, setActiveFindingCategory] = useState<
    V1FindingCategory | undefined
  >(undefined);

  return (
    <ResponsiveCardLayout>
      <FullHeightCard title={<FM defaultMessage="Package Version Scores" />}>
        <ScoreCardDisplay scoreCard={scoreCard} isLoading={isLoading} />
      </FullHeightCard>

      <FullHeightCard
        action={
          <Button
            endIcon={<IconSidebarRight />}
            disabled={!packageVersionQueryObject}
            onClick={() => {
              DetailDrawer.activate(
                {
                  resourceNamespace:
                    packageVersionQueryObject?.tenant_meta.namespace,
                  resourceUuid: packageVersionQueryObject?.uuid,
                },
                {
                  resourceNamespace:
                    packageVersionQueryObject?.tenant_meta.namespace,
                  resourceUuid: packageVersionQueryObject?.uuid,
                }
              );
            }}
          >
            <FM defaultMessage="All Score Factors" />
          </Button>
        }
        title={<FM defaultMessage="Score Factors" />}
      >
        <ScoreFactorsOverview
          scoreFactors={scoreFactors}
          isLoading={isLoading}
        />
      </FullHeightCard>

      <FullHeightCard
        action={
          <FindingCategorySelect
            activeFindingCategory={activeFindingCategory}
            onChange={(_, findingCategory) =>
              setActiveFindingCategory(findingCategory)
            }
          />
        }
        title={<FM defaultMessage="Finding Risk Matrix" />}
      >
        <FindingRiskMatrixTable
          activeFindingCategory={activeFindingCategory}
          findingGroups={findingGroups}
        />
      </FullHeightCard>

      <FullHeightCard title={<FM defaultMessage="Top Metadata" />}>
        <PackageVersionDetailOverviewMetadata
          dependencyMetadata={dependencyMetadata}
          dependentGroups={dependentGroups}
          licenses={licenses}
          packageVersion={packageVersionQueryObject}
        />
      </FullHeightCard>
    </ResponsiveCardLayout>
  );
};
