import { useState } from 'react';

export const useDefaultRecord = <T>(initialValue: T) => {
  const [defaultValue, setDefaultValue] = useState(initialValue);
  const [state, setState] = useState<Record<string, T>>({});

  const get = (id: string) => {
    return id in state ? state[id] : defaultValue;
  };

  const set = (id: string, value: T) => {
    setState((state) => ({ ...state, [id]: value }));
  };

  const reset = (value?: T) => {
    if (value !== undefined) {
      setDefaultValue(value);
    }

    setState({});
  };

  return { get, set, reset };
};
