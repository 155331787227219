import { Typography } from '@mui/material';
import { defineMessage, FormattedMessage as FM } from 'react-intl';

import { DataTablePaginator } from '../DataTable';

const countMessage = defineMessage({
  defaultMessage:
    '<b>{count, number, ::compact-short}</b> {count, plural, one {{resourceLabel}} other {{resourceLabelPlural}}}',
});

export interface SimplePaginationCountProps {
  paginator: DataTablePaginator;
  /**
   * The type of items managed by the paginator
   */
  resourceLabel?: string;
  /**
   * The type of items managed by the paginator (plural)
   */
  resourceLabelPlural?: string;
}

/**
 * Generic pagination component
 */
export const SimplePaginationCount = ({
  paginator,
  resourceLabel = 'items',
  resourceLabelPlural = 'item',
}: SimplePaginationCountProps) => {
  const { totalCount } = paginator.state;

  return (
    <Typography component="span" variant="caption">
      <FM
        {...countMessage}
        values={{
          count: totalCount,
          b: (value) => (
            <Typography component="span" variant="inherit" color="text.primary">
              {value}
            </Typography>
          ),
          resourceLabel,
          resourceLabelPlural,
        }}
      />
    </Typography>
  );
};
