import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  V1ChangeType,
  V1Confidence,
  V1ModifierChangeType,
} from '@endorlabs/api_client';

export const BreakingChangeTypeMessage: Record<
  V1ChangeType,
  MessageDescriptor
> = defineMessages({
  [V1ChangeType.Added]: { defaultMessage: 'Added' },
  [V1ChangeType.Changed]: { defaultMessage: 'Changed' },
  [V1ChangeType.Removed]: { defaultMessage: 'Removed' },
  [V1ChangeType.Renamed]: { defaultMessage: 'Renamed' },
  [V1ChangeType.Unspecified]: { defaultMessage: 'Changed' },
});

export const BreakingChangeModifierMessage: Record<
  V1ModifierChangeType,
  MessageDescriptor
> = defineMessages({
  [V1ModifierChangeType.ProtectedToPrivate]: {
    defaultMessage: 'From Private To Public',
  },
  [V1ModifierChangeType.PublicToPrivate]: {
    defaultMessage: 'From Public To Private',
  },
  [V1ModifierChangeType.PublicToProtected]: {
    defaultMessage: 'From Public To Protected',
  },
  [V1ModifierChangeType.Signature]: { defaultMessage: 'Signature' },
  [V1ModifierChangeType.ToAbstract]: {
    defaultMessage: 'From Non Abstract To Abstract',
  },
  [V1ModifierChangeType.ToFinal]: { defaultMessage: 'From Non Final To Final' },
  [V1ModifierChangeType.ToNonstatic]: {
    defaultMessage: 'From Static To Non Static',
  },
  [V1ModifierChangeType.ToStatic]: {
    defaultMessage: 'From Non Static To Static',
  },
  [V1ModifierChangeType.Unspecified]: { defaultMessage: 'Unknown' },
});

export const BreakingChangeConfidenceMessage: Record<
  V1Confidence,
  MessageDescriptor
> = defineMessages({
  [V1Confidence.High]: { defaultMessage: 'High Confidence' },
  [V1Confidence.Low]: { defaultMessage: 'Low Confidence' },
  [V1Confidence.Medium]: { defaultMessage: 'Medium Confidence' },
  [V1Confidence.Unknown]: { defaultMessage: 'Confidence Unknown' },
  [V1Confidence.Unspecified]: { defaultMessage: 'Confidence Unknown' },
});
