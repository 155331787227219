import { Divider } from '@mui/material';

import { DRAWER_SECTION_PADDING_HORIZ } from './constants';

/**
 * Simple divider component for use in the DetailDrawer.
 * 'fullWidth' prop can used to make the divider span end to end,
 * when horizontal padding is added by DetailDrawerSection.
 */
export const DetailDrawerDivider = ({ fullWidth }: { fullWidth?: boolean }) => {
  return (
    <Divider
      sx={
        fullWidth ? { marginX: -1 * DRAWER_SECTION_PADDING_HORIZ } : undefined
      }
      variant="fullWidth"
    />
  );
};
