import { Box, SvgIconProps, useTheme } from '@mui/material';
import { JSXElementConstructor, PropsWithChildren } from 'react';

import { TShirtSize } from '../../types';

export type IconFrameProps = PropsWithChildren<{
  size?: TShirtSize;
  variant?: 'circular' | 'rounded';
}>;

const getIconFramePadding = (size: TShirtSize) => {
  switch (size) {
    case 'xsmall':
    case 'small':
      return 0.5;
    case 'large':
    case 'xlarge':
      return 2;
    case 'medium':
    default:
      return 1;
  }
};

export const IconFrame = ({
  children,
  size = 'medium',
  variant,
}: IconFrameProps) => {
  const { palette, shape } = useTheme();

  const padding = getIconFramePadding(size);
  const borderRadius =
    variant === 'circular'
      ? `999px`
      : variant === 'rounded'
      ? `${shape.borderRadius}px`
      : undefined;

  return (
    <Box
      sx={{
        backgroundColor: palette.background.paper,
        border: `1px solid ${palette.divider}`,
        borderRadius,
        display: 'flex',
        padding,
      }}
    >
      {children}
    </Box>
  );
};

export type WithIconFrameOptions = {
  displayName?: string;
} & Omit<IconFrameProps, 'children'>;

/**
 * Given an Icon component, return a new component composed of the icon wrapped
 * within an outer frame.
 */
export const withIconFrame = (
  IconComponent: JSXElementConstructor<SvgIconProps>,
  options: WithIconFrameOptions
) => {
  const FramedIcon = (props: Omit<SvgIconProps, 'sx'>) => {
    const { fontSize, ...iconProps } = props;

    return (
      <IconFrame
        size={options.size ?? (fontSize as TShirtSize)}
        variant={options.variant}
      >
        <IconComponent {...iconProps} fontSize={fontSize} />
      </IconFrame>
    );
  };

  if (options.displayName) {
    FramedIcon.displayName = options.displayName;
  }

  return FramedIcon;
};
