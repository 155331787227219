import { Alert, Skeleton } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { FindingCallPathsProps } from './FindingCallPaths';

export const SampleMethodsDisclaimerAlert = ({
  finding,
  findingPackageVersion,
  isLoading,
}: FindingCallPathsProps) => {
  if (isLoading || !finding) {
    return <Skeleton width="100%" height={80} variant="rectangular" />;
  }

  const findingPackageVersionName = findingPackageVersion?.meta.name ? (
    findingPackageVersion?.meta.name.replace(/^\w+:\/\//, '')
  ) : (
    <FM defaultMessage="this package version" />
  );

  return (
    <Alert severity="info">
      <FM
        defaultMessage="Sample methods provide examples of statically discovered function calls that reach the dependency or method associated with this finding."
        values={{
          packageVersionName: findingPackageVersionName,
          bold: (v) => <strong className="wrap">{v}</strong>,
        }}
      />
    </Alert>
  );
};
