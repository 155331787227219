import { Divider } from '@mui/material';
import {
  MutableRefObject,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
} from 'react';

import { RowStack } from '../RowStack';

export type TreeColumnsProps = PropsWithChildren<{
  panelMinWidth?: number;
}>;

export const TreeColumns = ({
  children,
  panelMinWidth = 140,
}: TreeColumnsProps) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  // Simple auto-scroll to child element when hidden
  useLayoutEffect(() => {
    if (!ref.current) return;

    const parent = ref.current;
    if (!parent) return;

    const panels = parent.querySelectorAll('.TreeColumnPanel-root');
    const lastPanel = panels[panels.length - 1];
    if (!lastPanel) return;

    const parentBounds = parent.getBoundingClientRect();
    const bounds = lastPanel.getBoundingClientRect();

    if (bounds.right > parentBounds.right) {
      const left = bounds.right;
      parent.scrollTo({ behavior: 'smooth', left });
    }
  });

  return (
    <RowStack
      alignItems="stretch"
      divider={<Divider orientation="vertical" flexItem />}
      flexWrap="nowrap"
      gap={0}
      height="100%"
      ref={ref}
      sx={{
        overflowX: 'auto',
        overscrollBehavior: 'contain',
        '.TreeColumnPanel-root': {
          padding: 1,
        },
        '.TreeColumnPanel-heading': {
          paddingX: 2,
          paddingY: 1,
          marginBottom: 1,
          minWidth: panelMinWidth,
        },
      }}
    >
      {children}
    </RowStack>
  );
};
