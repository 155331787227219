import { Grid } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { remove as _remove } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import { NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';

const DashboardTabNames = {
  ANALYTICS: 'analytics',
  OSS_OVERVIEW: 'oss-overview',
};

const DashboardTabRecords = [
  {
    label: <FM defaultMessage="OSS Overview" />,
    to: DashboardTabNames.OSS_OVERVIEW,
    value: DashboardTabNames.OSS_OVERVIEW,
    key: DashboardTabNames.OSS_OVERVIEW,
  },
  {
    label: <FM defaultMessage="Analytics" />,
    to: DashboardTabNames.ANALYTICS,
    value: DashboardTabNames.ANALYTICS,
    key: DashboardTabNames.ANALYTICS,
  },
];

export const DashboardPage = () => {
  const { ENABLE_DASHBOARD_ANALYTICS_VIEW } = useFeatureFlags();

  const tabRecords = useMemo(() => {
    const tabRecords = DashboardTabRecords.slice();

    if (!ENABLE_DASHBOARD_ANALYTICS_VIEW) {
      _remove(tabRecords, (t) => t.key === DashboardTabNames.ANALYTICS);
    }

    return tabRecords;
  }, [ENABLE_DASHBOARD_ANALYTICS_VIEW]);

  const showNavigationTabs = tabRecords.length > 1;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="Dashboard" />} />
      </Grid>

      {showNavigationTabs && (
        <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
          <NavigationTabs tabs={tabRecords} />
        </Grid>
      )}

      <Grid item>
        <Outlet />
      </Grid>
    </Grid>
  );
};
