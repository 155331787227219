import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grow,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonSecondary, IconZap, Link } from '@endorlabs/ui-common';
import EndorLogo from '@endorlabs/ui-common/assets/logos/symbol-endor-secondary-2.svg';

import { getEnv } from '../../constants';
import { ConfettiContainer } from '../../domains/Onboarding/components/Confetti';
import { useAuthInfo } from '../../providers';
import { getProjectPath } from '../../routes';

const { URL_ENDOR_SALES } = getEnv();

interface OnboardingCompleteCardProps {
  isRecentCompletion?: boolean;
}
export const OnboardingCompleteCard = ({
  isRecentCompletion = false,
}: OnboardingCompleteCardProps) => {
  const [showConfetti, setShowConfetti] = useState(isRecentCompletion);
  const { activeNamespace } = useAuthInfo();
  const { formatMessage: fm } = useIntl();
  const { palette, space } = useTheme();

  return (
    <Grow in>
      <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
        <Grid item>
          <Card>
            <CardContent>
              <Stack rowGap={space.xs} sx={{ overflow: 'hidden' }}>
                <ConfettiContainer
                  isConfetti={showConfetti}
                  confettiCallback={() => {
                    setShowConfetti(false);
                  }}
                >
                  <Stack
                    spacing={4}
                    textAlign="center"
                    sx={{
                      maxWidth: {
                        xs: '100%',
                        sm: '100%',
                        md: '25%',
                        lg: '25%',
                        xl: '25%',
                      },
                    }}
                  >
                    <Typography color="text.primary" variant="h1">
                      <FM defaultMessage="You're all set!" />
                    </Typography>

                    <Box
                      onClick={() => setShowConfetti(true)}
                      sx={{ cursor: 'pointer', zIndex: 8 }}
                    >
                      <img
                        alt={fm({ defaultMessage: 'Endor Labs' })}
                        src={EndorLogo}
                        height={48}
                      />
                    </Box>

                    <Typography>
                      <FM defaultMessage="Congratulations, you are ready to use Endor Labs like a seasoned pro!" />
                    </Typography>

                    <Box>
                      <ButtonSecondary
                        href={getProjectPath({ tenantName: activeNamespace })}
                        sx={{
                          backgroundColor: palette.background.paper,
                          zIndex: 10,
                        }}
                      >
                        <FM defaultMessage="View my Projects" />
                      </ButtonSecondary>
                    </Box>

                    <Typography variant="body2">
                      <FM defaultMessage="Tips: get a refresher from the steps below or click on the logo for more confetti!" />
                    </Typography>
                  </Stack>
                </ConfettiContainer>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Stack
            direction="row"
            padding={space.sm}
            spacing={space.xs}
            sx={{
              alignItems: 'center',
              background: 'hsl(150deg 43.75% 93.73%)',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ marginX: 2 }}>
              <FM defaultMessage="Ready for more?" />
            </Typography>

            <Button
              startIcon={<IconZap />}
              component={Link}
              href={URL_ENDOR_SALES}
              target="_blank"
              color="primary"
              variant="contained"
            >
              <FM defaultMessage="Contact us to upgrade" />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grow>
  );
};
