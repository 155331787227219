import { QueryKey, useQuery } from 'react-query';

import {
  PackageVersionsMetadataServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  PackageVersionMetadataResource,
  PackageVersionMetadataResourceList,
  ResourceListResponse,
  ResourceQueryOptions,
} from './types';
import { buildListParamArgs, getClientConfiguration } from './utils';

export interface PackageVersionMetadataReadParams {
  namespace: string;
  uuid: string;
}

export type CountPackageVersionMetadataOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
export type ListPackageVersionMetadataOptions = ResourceQueryOptions<
  ResourceListResponse<PackageVersionMetadataResource>
>;
export type GetPackageVersionMetadataOptions =
  ResourceQueryOptions<PackageVersionMetadataResource>;

const BASE_KEY = 'v1/package-versions-metadata';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const PackageVersionMetadataQueryKeys = QK;

const getApiService = () =>
  new PackageVersionsMetadataServiceApi(getClientConfiguration());

const listPackageVersionsMetadata = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp =
    await api.packageVersionsMetadataServiceListPackageVersionsMetadata(
      namespace,
      ...buildListParamArgs(listParams),
      { signal }
    );

  return resp.data.list as ResourceListResponse<PackageVersionMetadataResource>;
};

export const useListPackageVersionsMetadata = (
  namespace: string,
  listParams?: ListRequestParameters,
  opts?: ListPackageVersionMetadataOptions
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersionMetadata',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    (ctx) =>
      listPackageVersionsMetadata(namespace, requestParameters, ctx.signal),
    opts
  );
};

const getPackageVersionMetadata = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp =
    await api.packageVersionsMetadataServiceGetPackageVersionsMetadata(
      namespace,
      uuid
    );
  return resp.data as PackageVersionMetadataResource;
};

export const useGetPackageVersionMetadata = (
  params: PackageVersionMetadataReadParams,
  opts: GetPackageVersionMetadataOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getPackageVersionMetadata(params.namespace, params.uuid),
    opts
  );
};
