import { isEmpty as _isEmpty, set as _set } from 'lodash-es';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  GroupByTimeGroupByTimeInterval,
  SpecFindingLevel,
  V1FindingCategory,
  V1FindingLogSpecOperation,
} from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import { ResourceGroupResponse, useGroupFindingLogs } from '@endorlabs/queries';

export const useDashboardVulnerabilitiesTimeTrendData = ({
  namespace,
  baseFilter,
  vulnState,
}: {
  namespace: string;
  baseFilter: FilterExpression;
  vulnState: 'open' | 'resolved';
}) => {
  const { formatDate, formatNumber } = useIntl();

  const vulnBaseFilter = useMemo(() => {
    const expressions = [
      baseFilter,
      `spec.finding_categories contains [${V1FindingCategory.Vulnerability}]`,
    ];

    if (vulnState === 'open') {
      expressions.push(`spec.operation==${V1FindingLogSpecOperation.Create}`);
    } else {
      expressions.push(`spec.operation==${V1FindingLogSpecOperation.Delete}`);
    }

    return filterExpressionBuilders.and(expressions);
  }, [baseFilter, vulnState]);

  const getVulnOverTimeParams = (level: SpecFindingLevel) => ({
    filter: filterExpressionBuilders.and([
      vulnBaseFilter,
      `spec.level==${level}`,
    ]),
    group_by_time: {
      aggregation_paths: 'meta.update_time',
      interval: GroupByTimeGroupByTimeInterval.Week,
      mode: 'count',
    },
  });

  const qVulnOverTimeCritical = useGroupFindingLogs(
    namespace,
    getVulnOverTimeParams(SpecFindingLevel.Critical)
  );

  const qVulnOverTimeHigh = useGroupFindingLogs(
    namespace,
    getVulnOverTimeParams(SpecFindingLevel.High)
  );

  const qVulnOverTimeMedium = useGroupFindingLogs(
    namespace,
    getVulnOverTimeParams(SpecFindingLevel.Medium)
  );

  const qVulnOverTimeLow = useGroupFindingLogs(
    namespace,
    getVulnOverTimeParams(SpecFindingLevel.Low)
  );

  const isLoading = [
    qVulnOverTimeCritical,
    qVulnOverTimeHigh,
    qVulnOverTimeMedium,
    qVulnOverTimeLow,
  ].every((q) => q.isLoading);

  const isEmpty = [
    qVulnOverTimeCritical,
    qVulnOverTimeHigh,
    qVulnOverTimeMedium,
    qVulnOverTimeLow,
  ].every((q) => !q.data || _isEmpty(q.data.groups));

  const vulnOverTimeChartData = useMemo(() => {
    const chartDataByDate: Record<
      string,
      Record<SpecFindingLevel, number>
    > = {};

    const setDataForFindingLevel = (
      level: SpecFindingLevel,
      data?: ResourceGroupResponse
    ) => {
      const groupData = data?.groups ?? {};
      Object.entries(groupData).forEach(([key, group]) => {
        const count = group.aggregation_count?.count ?? 0;

        _set(chartDataByDate, [key, level], count);
      });
    };

    setDataForFindingLevel(
      SpecFindingLevel.Critical,
      qVulnOverTimeCritical.data
    );

    setDataForFindingLevel(SpecFindingLevel.High, qVulnOverTimeHigh.data);

    setDataForFindingLevel(SpecFindingLevel.Medium, qVulnOverTimeMedium.data);

    setDataForFindingLevel(SpecFindingLevel.Low, qVulnOverTimeLow.data);

    const defaultFindingLevels = Object.fromEntries(
      FINDING_LEVELS.map((level) => [level, 0])
    );

    // TODO: limit to max item count?
    return Object.entries(chartDataByDate)
      .map(([key, data]) => ({
        key,
        // HACK: date is JSON-encoded ISO Date
        label: formatDate(JSON.parse(key), {
          dateStyle: 'medium',
        }),
        ...defaultFindingLevels,
        ...data,
      }))
      .sort((a, b) => a.key.localeCompare(b.key));
  }, [
    formatDate,
    qVulnOverTimeCritical.data,
    qVulnOverTimeHigh.data,
    qVulnOverTimeLow.data,
    qVulnOverTimeMedium.data,
  ]);

  return { vulnOverTimeChartData, isEmpty, isLoading };
};
