import { Paper, Skeleton, Stack } from '@mui/material';

/**
 * Loading State/Skeleton for {@see FindingSummaryAccordion}
 */
export const FindingSummaryAccordionSkeletonV2 = () => {
  return (
    <Paper>
      <Stack
        spacing={4}
        sx={{
          marginY: 4,
          paddingBottom: 4,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          '&:last-of-type': {
            borderBottom: 'none',
          },
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="rounded" width={64} height={22} />
          <Skeleton variant="rounded" width={240} height={22} />
        </Stack>

        <Stack alignItems="center" direction="row" spacing={2}>
          <Skeleton variant="rounded" width={70} height={16} />
          <Skeleton variant="rounded" width={64} height={20} />
          <Skeleton variant="rounded" width={60} height={16} />
          <Skeleton variant="rounded" width={60} height={20} />
          <Skeleton variant="rounded" width={40} height={20} />
          <Skeleton variant="rounded" width={80} height={20} />
        </Stack>
      </Stack>
    </Paper>
  );
};
