import { Alert, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AuthorizationPolicyResource } from '@endorlabs/endor-core/AuthorizationPolicy';
import { useDeleteAuthorizationPolicy } from '@endorlabs/queries';

import { ConfirmationDialogProps } from '../../../components';
import { IconAlertTriangle } from '../../../themes';

/**
 * Utility hook to Manage Auth Policy Deletion
 */
export const useDeleteAuthorizationPolicyConfirmationDialog = () => {
  const [authPolicy, setAuthPolicy] = useState<
    AuthorizationPolicyResource | undefined
  >(undefined);
  const isOpen = Boolean(authPolicy);

  const handleOpenDialog = useCallback(
    (authPolicy: AuthorizationPolicyResource) => {
      setAuthPolicy(authPolicy);
    },
    []
  );

  const handleCloseDialog = useCallback(() => setAuthPolicy(undefined), []);

  const qDeleteAuthPolicy = useDeleteAuthorizationPolicy();
  const handleConfirmDismiss = useCallback(() => {
    if (!authPolicy) return;

    qDeleteAuthPolicy.mutate(
      {
        namespace: authPolicy.tenant_meta.namespace,
        uuid: authPolicy.uuid,
      },
      {
        onSuccess: () => setAuthPolicy(undefined),
        // TODO: show error on failure
      }
    );
  }, [authPolicy, qDeleteAuthPolicy]);

  const getConfirmationDialogProps =
    useCallback((): ConfirmationDialogProps => {
      return {
        titleText: <FM defaultMessage="Delete Authorization Policy" />,
        open: isOpen,
        onConfirm: handleConfirmDismiss,
        onCancel: handleCloseDialog,
        children: (
          <Stack spacing={4} marginBottom={3}>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <FM defaultMessage="Any tooling or automated processes which rely on this Authorization Policy will cease to function as expected." />
            </Alert>

            <Typography variant="body1">
              <FM
                defaultMessage="This action will revoke and immediately delete the Authorization Policy <code>{name}</code>. Are you sure you want to proceed?"
                values={{
                  name: authPolicy?.meta.name,
                  // styled `code` element
                  // forces content to wrap
                  code: (value) => (
                    <Typography
                      component="code"
                      variant="inherit"
                      sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
                    >
                      {value}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Stack>
        ),
      };
    }, [authPolicy, handleConfirmDismiss, handleCloseDialog, isOpen]);

  return {
    closeDialog: handleCloseDialog,
    getConfirmationDialogProps,
    openDialog: handleOpenDialog,
  };
};
