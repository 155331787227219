import { without as _without } from 'lodash-es';

import {
  ReferenceReferenceType as VulnReferenceType,
  V1FindingCategory,
} from '@endorlabs/api_client';
import { SpecFindingLevel as FindingLevel } from '@endorlabs/api_client';

export const FINDING_LEVELS = _without(
  Object.values(FindingLevel),
  FindingLevel.Unspecified
);

/**
 * The "default" finding categories to be exposed for use in the UI
 */
export const DEFAULT_FINDING_CATEGORIES: V1FindingCategory[] = _without(
  Object.values(V1FindingCategory),
  V1FindingCategory.Unspecified
).sort((a, b) => a.localeCompare(b));

/**
 * OSV Reference Type, with numeric values.
 *
 * @deprecated this was intiallly needed to deal with mixed enum names and
 * values, and may be removed in the future.
 */
export const VulnReferenceTypeValues: Record<VulnReferenceType, number> = {
  [VulnReferenceType.Unspecified]: 0,
  [VulnReferenceType.Web]: 1,
  [VulnReferenceType.Advisory]: 2,
  [VulnReferenceType.Report]: 3,
  [VulnReferenceType.Fix]: 4,
  [VulnReferenceType.Package]: 5,
  [VulnReferenceType.Article]: 6,
} as const;

/**
 * Inverted Vuln Reference Type enum names, keyed by the enum numeric value.
 *
 * @deprecated this was intiallly needed to deal with mixed enum names and
 * values, and may be removed in the future.
 */
export const VulnReferenceTypeValuesInverted: Record<
  number,
  VulnReferenceType
> = {
  0: VulnReferenceType.Unspecified,
  1: VulnReferenceType.Web,
  2: VulnReferenceType.Advisory,
  3: VulnReferenceType.Report,
  4: VulnReferenceType.Fix,
  5: VulnReferenceType.Package,
  6: VulnReferenceType.Article,
} as const;
