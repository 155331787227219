import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ButtonCancel,
  ButtonPrimary,
  CommonDataTableRowActions,
  DataTableView,
  EmptyState,
  FlexList,
  FlexListItem,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { getProjectVersionPath } from '../../../../routes';
import { FilterBar, useFilterContext } from '../../../filters';
import {
  useCiCdToolsIndexPageData,
  useCiCdToolsIndexPageFilterFields,
} from '../../hooks';
import {
  buildCiCdToolsTableColumns,
  CiCdToolsTableRow,
} from '../CiCdToolsTable';

export const CiCdToolsDataTableView = ({
  namespace,
}: {
  namespace: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();

  const {
    clearFilter,
    filter: filterExpression,
    _state: filterState,
  } = useFilterContext();

  // Whether to hide projects without tools
  const [hideEmptyProjects, setHideEmptyProjects] = useState(true);

  const { baseProjectFilterExpression, filterFields, projectQueryFilters } =
    useCiCdToolsIndexPageFilterFields(
      namespace,
      filterState,
      hideEmptyProjects
    );

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  const { cicdTools, projectCount, toolCount, ...qQueryProjectTools } =
    useCiCdToolsIndexPageData({
      namespace,
      paginator,
      baseFilter: baseProjectFilterExpression,
      queryFilters: projectQueryFilters,
    });

  const columns = useMemo(
    () => buildCiCdToolsTableColumns({ data: cicdTools }),
    [cicdTools]
  );

  const handleRowClick = (row: CiCdToolsTableRow) => {
    const link = getProjectVersionPath({
      tenantName: row.namespace,
      resourceName: 'projects',
      additionalSegments: ['tools'],
      uuid: row.project.uuid,
      versionRef: row.versionRef,
    });
    navigate({ to: link });
  };

  const rowActions = [
    {
      ...CommonDataTableRowActions.LINK,
      isPrimaryAction: true,
      label: fm({ defaultMessage: 'View Project Tools' }),
      onClick: handleRowClick,
    },
  ];

  const nextPageToken =
    qQueryProjectTools.data?.list?.response?.next_page_token;

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginator.resetPagination, projectQueryFilters, namespace]
  );

  // Handle error, loading, and empty states
  const hasError = qQueryProjectTools.isError;
  const isLoading = qQueryProjectTools.isLoading;
  const isEmptyState = !isLoading && !hasError && projectCount === 0;

  // Handle: empty state
  if (isEmptyState) {
    return (
      <EmptyState
        size="large"
        title={
          <FM defaultMessage="CI/CD tools will be available to view here." />
        }
        description={
          <FM defaultMessage="As your inventory of CI/CD tools grow, this is where you can easily search across them." />
        }
      />
    );
  }

  return (
    <DataTableView
      actions={
        <FormControlLabel
          control={
            <Checkbox
              checked={hideEmptyProjects}
              onChange={(e) => setHideEmptyProjects(e.target.checked)}
            />
          }
          label={<FM defaultMessage="Hide Projects without Tools" />}
          sx={{ marginRight: 0 }}
        />
      }
      columns={columns}
      data={cicdTools}
      emptyStateProps={
        hasError
          ? {
              // Handle: error state from data loading
              title: <FM defaultMessage="Failed to load CI/CD tools" />,
              description: (
                <FM defaultMessage="The request to load CI/CD tools failed to complete. Please remove filters or try again." />
              ),
              children: (
                <Stack direction="row" spacing={4}>
                  {filterExpression && (
                    <ButtonPrimary onClick={clearFilter}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonPrimary>
                  )}

                  <ButtonPrimary onClick={() => qQueryProjectTools.refetch()}>
                    <FM defaultMessage="Try Again" />
                  </ButtonPrimary>
                </Stack>
              ),
            }
          : {
              // Handle: possible causes of empty states
              title: (
                <FM defaultMessage="No CI/CD tools match the filter criteria" />
              ),
              children: (
                <FlexList
                  divider={
                    <Typography color="text.secondary">
                      <FM defaultMessage="or" />
                    </Typography>
                  }
                  justifyContent="center"
                >
                  {filterExpression && (
                    <FlexListItem>
                      <ButtonCancel onClick={clearFilter}>
                        <FM defaultMessage="Clear Filters" />
                      </ButtonCancel>
                    </FlexListItem>
                  )}

                  {paginator.state.pageIndex > 0 && (
                    <FlexListItem>
                      <ButtonCancel onClick={() => paginator.resetPagination()}>
                        <FM defaultMessage="Go to First Page of Results" />
                      </ButtonCancel>
                    </FlexListItem>
                  )}
                </FlexList>
              ),
            }
      }
      enablePagination
      filtersContent={
        <FilterBar
          // enableAdvanced
          // isDataTableView
          isCard={false}
          fields={filterFields}
          searchPlaceholder={fm({ defaultMessage: 'Search Project Names' })}
        />
      }
      isLoading={isLoading}
      namespace={namespace}
      paginator={paginator}
      enableColumnReorder
      enableColumnVisibility
      // ref={ref}
      // columns={columns}
      // onRowClick={handleRowClick}
      reorderableColumnHeading="Tool Categories"
      rowActions={rowActions}
      tableId="ci-cd-tools"
    />
  );
};
