import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  FindingResource,
  getFindingDescription,
  getFirstFindingSecurityAdvisoryUrl,
  isVulnerabilityFinding,
} from '@endorlabs/endor-core/Finding';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  ExternalLink,
  FormattedTypography,
} from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { FindingDetailDrawerSectionProps } from '../types';

const genResource = ({ finding }: FindingDetailDrawerSectionProps) => {
  const vuln = finding?.spec.finding_metadata?.vulnerability?.spec;
  const advisoryUrl = finding
    ? getFirstFindingSecurityAdvisoryUrl(finding)
    : undefined;

  const resource = {
    advisory: advisoryUrl ? (
      <ExternalLink to={advisoryUrl}>{advisoryUrl}</ExternalLink>
    ) : undefined,
    description: finding ? getFindingDescription(finding) : undefined,
    vuln_notes: (vuln?.additional_notes ?? []).map(
      (note: string, index: number) => (
        <FormattedTypography key={index} text={note} />
      )
    ),
  };

  return resource;
};

const genRecords = ({ finding }: FindingDetailDrawerSectionProps) => {
  const records: AttributeDisplayRecord[] = [
    {
      attributeKey: 'description',
      heading: undefined,
    },
  ];

  if (finding ? isVulnerabilityFinding(finding) : false) {
    records.push({
      attributeKey: 'advisory',
      heading: <FM defaultMessage="Advisory" />,
      hideIfNil: true,
    });
    records.push({
      attributeKey: 'vuln_notes',
      heading: <FM defaultMessage="Additional Notes" />,
      hideIfNil: true,
    });
  }

  return records;
};

export const FindingRiskDetailsSection = ({
  finding,
  isLoading,
  namespace,
}: FindingDetailDrawerSectionProps) => {
  const records = useMemo(
    () => genRecords({ finding, namespace }),
    [finding, namespace]
  );
  const resource = useMemo(
    () => genResource({ finding, namespace }),
    [finding, namespace]
  );

  return (
    <DetailDrawerSection title={<FM defaultMessage="Risk Details" />}>
      <AttributeDisplayStack
        attributeRecords={records}
        isLoading={isLoading}
        resource={resource}
      ></AttributeDisplayStack>
    </DetailDrawerSection>
  );
};
