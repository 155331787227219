import { Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScanResult } from '@endorlabs/api_client';
import {
  CopyToClipboardChip,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  FindingCountDisplayProps,
  NilDisplay,
  ScanResultStatusIndicator,
} from '@endorlabs/ui-common';

export type CIRunsTableRow = {
  namespace: string;
  ciRunId: string;
  versionRef?: string;
  versionSha?: string;
  endTime?: string;
  findingCounts?: FindingCountDisplayProps[];
  scanResults?: V1ScanResult[];
  tags: string[];
  action?: string;
};

export type CIRunsTableColumnId = keyof CIRunsTableRow;

export const buildCIRunsTableColumnDefs = ({
  includeColumns,
}: {
  includeColumns: CIRunsTableColumnId[];
}) => {
  const visibleColumns = new Set<CIRunsTableColumnId>(includeColumns);

  const columns: DataTableColumnDef<CIRunsTableRow>[] = [
    {
      accessorKey: 'scanResults',
      cell: ({ getValue }) => (
        <ScanResultStatusIndicator scanResults={getValue()} />
      ),
      colType: ColTypes.STATUS_INDICATOR,
    },
    {
      accessorKey: 'ciRunId',
      cell: ({ getValue }) => {
        const value = getValue();

        // fallback for nil/empty
        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return (
          <CopyToClipboardChip
            value={value}
            displayValue={value.slice(0, 8)}
            size="small"
          />
        );
      },
      header: () => <FM defaultMessage="ID" />,
    },
    {
      accessorKey: 'versionRef',
      cell: ({ getValue }) => {
        const value = getValue();

        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return <Typography>{value}</Typography>;
      },
      colType: ColTypes.VERSION,
      header: () => <FM defaultMessage="Ref " />,
    },
    {
      accessorKey: 'versionSha',
      cell: ({ getValue }) => {
        const value = getValue();

        // fallback for nil/empty
        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return (
          <CopyToClipboardChip
            value={value}
            displayValue={value.slice(0, 8)}
            size="small"
          />
        );
      },
      header: () => <FM defaultMessage="Commit" />,
    },
    {
      accessorKey: 'endTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Completed" />,
    },
    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
    },
    {
      accessorKey: 'tags',
      colType: ColTypes.TAGS,
    },
  ];

  return columns.filter((c) => {
    const id = c.id ?? c.accessorKey;
    return id && visibleColumns.has(id as CIRunsTableColumnId);
  });
};

export type PRRunsTableRow = {
  namespace: string;
  prRunId: string;
  versionRef?: string;
  versionSha?: string;
  endTime?: string;
  findingCounts?: FindingCountDisplayProps[];
  scanResults?: V1ScanResult[];
  tags: string[];
  action?: string;
};

export type PRRunsTableColumnId = keyof PRRunsTableRow;

export interface PRRunsTableProps
  extends Omit<
    DataTableProps<PRRunsTableRow>,
    'columns' | 'onRowClick' | 'tableId'
  > {
  excludeColumns?: PRRunsTableColumnId[];
  onClickDetail?: (row: PRRunsTableRow) => void;
}

export const buildPRRunsTableColumnDefs = ({
  excludeColumns,
}: Pick<
  PRRunsTableProps,
  'excludeColumns'
>): DataTableColumnDef<PRRunsTableRow>[] => {
  const columns: DataTableColumnDef<PRRunsTableRow>[] = [
    {
      accessorKey: 'scanResults',
      cell: ({ getValue }) => (
        <ScanResultStatusIndicator scanResults={getValue()} />
      ),
      colType: ColTypes.STATUS_INDICATOR,
    },
    {
      accessorKey: 'prRunId',
      cell: ({ getValue }) => {
        const value = getValue();

        // fallback for nil/empty
        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return (
          <CopyToClipboardChip
            value={value}
            displayValue={value.slice(0, 8)}
            size="small"
          />
        );
      },
      header: () => <FM defaultMessage="ID" />,
    },
    {
      accessorKey: 'endTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Completed" />,
    },
    {
      accessorKey: 'versionSha',
      cell: ({ getValue }) => {
        const value = getValue();

        // fallback for nil/empty
        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return (
          <CopyToClipboardChip
            value={value}
            displayValue={value.slice(0, 8)}
            size="small"
          />
        );
      },
      header: () => <FM defaultMessage="Commit SHA" />,
    },
    {
      accessorKey: 'versionRef',
      cell: ({ getValue }) => {
        const value = getValue();

        if (!value || !value.trim()) {
          return <NilDisplay variant="text" />;
        }

        return <Typography>{value}</Typography>;
      },
      colType: ColTypes.VERSION,
      header: () => <FM defaultMessage="Ref " />,
    },

    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
    },
    {
      accessorKey: 'tags',
      colType: ColTypes.TAGS,
    },
  ];
  return columns.filter((col) => {
    if (!col.accessorKey) return true;
    return (excludeColumns ?? []).includes(col.accessorKey) ? false : true;
  });
};
