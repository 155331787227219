/**
 * "Well Known" namespaces that may be referenced in code
 */
export const NAMESPACES = Object.freeze({
  DEMO_TRIAL: 'demo-trial',
  ENDOR_ADMIN: 'endor-admin',
  ENDOR_OSS: 'endor-oss',
  OSS: 'oss',
  SYSTEM: 'system',
});

export type Namespaces = typeof NAMESPACES[keyof typeof NAMESPACES];

export const VALID_NAMESPACE_REGEX = /^[0-9a-z\-_]{1,32}$/;
