import { useMutation } from 'react-query';

import { V1ExportedVEX, VEXExportServiceApi } from '@endorlabs/api_client';

import { ResourceMutateOptions } from './types';
import { getClientConfiguration } from './utils';

interface VEXExportWriteParams {
  namespace: string;
  resource: V1ExportedVEX;
}

type CreateVEXExportOptions = ResourceMutateOptions<
  V1ExportedVEX,
  VEXExportWriteParams
>;

const getApiService = () => new VEXExportServiceApi(getClientConfiguration());

const createVEXExport = async (params: VEXExportWriteParams) => {
  const api = getApiService();
  const resp = await api.vEXExportServiceCreateVEXExport(
    params.namespace,
    params.resource,
    {
      // increase timeout, to avoid timeouts from potentially large VEX exports
      headers: {
        'Request-Timeout': 300,
      },
    }
  );
  return resp.data as V1ExportedVEX;
};

export const useCreateVEXExport = (opts: CreateVEXExportOptions = {}) => {
  return useMutation(
    (params: VEXExportWriteParams) => createVEXExport(params),
    opts
  );
};
