import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type AIProps = {
  color?: string;
  size?: string | number;
};

const AI = forwardRef<SVGSVGElement, AIProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M7.80887 3.72303L7.96885 3.80193C8.61542 4.13068 9.14201 4.65009 9.47529 5.28786L9.55528 5.44565C9.87524 6.07027 10.7818 6.07027 11.1084 5.44565L11.1884 5.28786C11.5217 4.65009 12.0483 4.13068 12.6948 3.80193L12.8548 3.72303C13.488 3.40744 13.488 2.51325 12.8548 2.19108L12.6948 2.11218C12.0483 1.78344 11.5217 1.26402 11.1884 0.626258L11.1084 0.468461C10.7884 -0.156154 9.8819 -0.156154 9.55528 0.468461L9.47529 0.626258C9.14201 1.26402 8.61542 1.78344 7.96885 2.11218L7.80887 2.19108C7.17563 2.50668 7.17563 3.40086 7.80887 3.72303Z"
          fill={color}
        />
        <path
          d="M9.06869 9.88371C9.72192 9.55496 9.72192 8.64105 9.06869 8.31231L8.09549 7.81919C7.22895 7.37867 6.52239 6.68173 6.07579 5.827L5.57586 4.86706C5.24257 4.22272 4.31604 4.22272 3.98276 4.86706L3.48283 5.827C3.03623 6.68173 2.32966 7.37867 1.46312 7.81919L0.489929 8.31231C-0.16331 8.64105 -0.16331 9.55496 0.489929 9.88371L1.46312 10.3768C2.32966 10.8173 3.03623 11.5143 3.48283 12.369L3.98276 13.3289C4.31604 13.9733 5.24257 13.9733 5.57586 13.3289L6.07579 12.369C6.52239 11.5143 7.22895 10.8173 8.09549 10.3768L9.06869 9.88371Z"
          fill={color}
        />
        <path
          d="M21.5735 13.8155L20.587 13.3158C19.3939 12.7175 18.4407 11.7773 17.8341 10.6004L17.3275 9.62729C16.8809 8.7594 15.9877 8.22026 15.0012 8.22026C14.0146 8.22026 13.1214 8.7594 12.6748 9.62729L12.1682 10.6004C11.5617 11.7773 10.6085 12.7175 9.4153 13.3158L8.42878 13.8155C7.54891 14.256 7.00232 15.137 7.00232 16.1101C7.00232 17.0832 7.54891 17.9642 8.42878 18.4048L9.4153 18.9045C10.6085 19.5028 11.5617 20.443 12.1682 21.6199L12.6748 22.593C13.1214 23.4609 14.0146 24 15.0012 24C15.9877 24 16.8809 23.4609 17.3275 22.593L17.8341 21.6199C18.4407 20.443 19.3939 19.5028 20.587 18.9045L21.5735 18.4048C22.4534 17.9642 23 17.0832 23 16.1101C23 15.137 22.4534 14.256 21.5735 13.8155ZM20.667 16.6493L19.6805 17.149C18.1074 17.938 16.8542 19.174 16.0543 20.7257L15.5477 21.6988C15.3944 21.9947 15.1145 22.0275 15.0012 22.0275C14.8878 22.0275 14.6079 21.9947 14.4546 21.6988L13.948 20.7257C13.1481 19.174 11.8949 17.938 10.3218 17.149L9.33531 16.6493C9.03536 16.498 9.00203 16.2219 9.00203 16.1101C9.00203 15.9984 9.03536 15.7222 9.33531 15.571L10.3218 15.0713C11.8949 14.2823 13.1481 13.0462 13.948 11.4946L14.4546 10.5215C14.6079 10.2256 14.8878 10.1927 15.0012 10.1927C15.1145 10.1927 15.3944 10.2256 15.5477 10.5215L16.0543 11.4946C16.8542 13.0462 18.1074 14.2823 19.6805 15.0713L20.667 15.571C20.967 15.7222 21.0003 15.9984 21.0003 16.1101C21.0003 16.2219 20.967 16.498 20.667 16.6493Z"
          fill={color}
        />
      </svg>
    );
  }
);

AI.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AI.displayName = 'AI';

export default AI;
