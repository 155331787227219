import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { useMemo } from 'react';

import { V1PlatformSource } from '@endorlabs/api_client';

import { REGEX_COMMIT_SHA_VALIDATION } from '../../constants';
import { UIProjectUtils } from '../../domains';

export type ProjectIconDisplayProps = {
  /**
   * Whether the icon is displayed on a dark background
   * or a light background
   */
  displayMode?: 'dark' | 'light';
};
export interface ProjectNameDisplayProps {
  iconProps?: ProjectIconDisplayProps;
  name?: string;
  platformSource?: V1PlatformSource;
  size?: 'small' | 'medium' | 'large' | 'inherit';
  showIcon?: boolean;
  breadcrumbItem?: boolean;
}

export const ProjectNameDisplay = ({
  iconProps = { displayMode: 'light' },
  name,
  platformSource: platformSourceOverride,
  size = 'small',
  showIcon = true,
}: ProjectNameDisplayProps) => {
  const { palette, spacing } = useTheme();
  const { displayMode: iconDisplayMode } = iconProps;
  const projectlabel = useMemo(
    () => UIProjectUtils.parseProjectName(name, platformSourceOverride),
    [name, platformSourceOverride]
  );
  const sizeMedium = size === 'medium';
  const PlatformIcon = UIProjectUtils.getPlatformIcon(platformSourceOverride);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {showIcon && (
        <Box
          alignItems="center"
          display="flex"
          sx={
            iconDisplayMode === 'dark'
              ? {
                  backgroundColor: palette.common.white,
                  borderRadius: spacing(1),
                  padding: spacing(0.5),
                }
              : undefined
          }
        >
          <PlatformIcon fontSize={sizeMedium ? 'small' : size} />
        </Box>
      )}

      <Typography
        component="span"
        fontSize={sizeMedium ? 'inherit' : size}
        variant={sizeMedium ? 'button' : 'body1'}
      >
        {projectlabel}
      </Typography>
    </Stack>
  );
};
