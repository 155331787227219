import { Stack, Typography, useTheme } from '@mui/material';
import { useNavigate, useRouter } from '@tanstack/react-location';
import { Fragment, ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useSession } from '@endorlabs/queries';
import { IconChevronRight, IconLayers } from '@endorlabs/ui-common';

import { NamespaceSwitcherPopover } from '../../domains/Namespace';
import { useAuthInfo, useAuthTenantInfo } from '../../providers';
import { NamedRoutes } from '../../routes/constants';
import { getDashboardPath, getTenantIndexPath } from '../../routes/utils';
import { useAppDrawerNavSections } from '../AppDrawer/useAppDrawerNavSections';

interface PageBreadcrumbsV2Props {
  breadcrumbsItems?: ReactNode[];
}

export const PageBreadcrumbsV2 = ({
  breadcrumbsItems = [],
}: PageBreadcrumbsV2Props) => {
  const {
    getLastUsedTenant,
    getUserNamespaces,
    getUserTenants,
    setLastUsedTenant,
  } = useSession();

  const navigate = useNavigate();
  const router = useRouter();
  const theme = useTheme();

  const lastUsedTenant = getLastUsedTenant(true);
  const { activeNamespace: tenantName } = useAuthInfo();
  const { getIsSharedTenant } = useAuthTenantInfo(lastUsedTenant);

  const newTenantPathRegex = new RegExp(`/t/${tenantName}/([^/]+)`);

  const { sharedTenantNavKeys } = useAppDrawerNavSections();

  const switchTenant = (newTenantName: string) => {
    setLastUsedTenant(newTenantName);
    const isSharedTenant = getIsSharedTenant(newTenantName);
    // Attempt to redirect to current top-level page (e.g. /projects/, /packages/), with new tenant.
    const match = router.state.location.pathname.match(newTenantPathRegex);

    if (newTenantName) {
      const isPathValidForSharedTenant = match
        ? sharedTenantNavKeys.includes(match[1])
        : false;
      if (isSharedTenant && !isPathValidForSharedTenant) {
        navigate({ to: getTenantIndexPath({ tenantName: newTenantName }) });
        return;
      } else {
        if (match) {
          navigate({ to: match[0].replace(tenantName, newTenantName) });
          return;
        }

        navigate({ to: getDashboardPath({ tenantName: newTenantName }) });
        return;
      }
    }

    // Fallback redirect to Tenant index
    navigate({ to: NamedRoutes.TENANTS_INDEX });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        backgroundColor: theme.palette.grey[200],
        width: 'max-content',
        padding: theme.spacing(0.5, 4),
        borderRadius: '16px',
      }}
    >
      <IconLayers fontSize="small" />
      <Typography variant="button" sx={{ color: theme.palette.text.secondary }}>
        <FM defaultMessage="namespace:" />
      </Typography>
      <NamespaceSwitcherPopover
        activeNamespace={lastUsedTenant as string}
        namespaces={getUserNamespaces()}
        tenants={getUserTenants()}
        onSelect={switchTenant}
      />
      {breadcrumbsItems?.map((breadcrumb, i) => {
        return (
          <Fragment key={i}>
            <IconChevronRight color="action" fontSize="small" />
            {breadcrumb}
          </Fragment>
        );
      })}
    </Stack>
  );
};
