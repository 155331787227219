import { FilterComparator } from './comparator';
import { FilterExpression } from './expression';
import { Filter, LogicalFilterOperator } from './types';
import { escapeValue, isLogicFilter } from './utils';

type ValueFilterComparator = Exclude<
  FilterComparator,
  'CONTAINS_ALL' | 'WITHIN_RANGE'
>;

const COMPARATORS: Record<ValueFilterComparator, string> = {
  MATCHES: 'matches',
  EQUAL: '==',
  NOT_EQUAL: '!=',
  GREATER: '>',
  GREATER_OR_EQUAL: '>=',
  LESSER: '<',
  LESSER_OR_EQUAL: '<=',
  IN: 'in',
  NOT_IN: 'not in',
  CONTAINS: 'contains',
  NOT_CONTAINS: 'not contains',
  EXISTS: 'exists',
  NOT_EXISTS: 'not exists',
};

const COMPARATORS_WITHOUT_VALUES = new Set<`${FilterComparator}`>([
  'EXISTS',
  'NOT_EXISTS',
]);

const translateComparator = (comparator: ValueFilterComparator): string =>
  COMPARATORS[comparator];

const translateOperator = (operator: LogicalFilterOperator) =>
  operator.toLowerCase();

const translateValue = (value: unknown): string => {
  if (Array.isArray(value)) {
    return `[${value.map((v) => translateValue(v)).join(',')}]`;
  }

  return escapeValue(value);
};

export const serialize = (
  filters: Filter[],
  joinOperator: LogicalFilterOperator = 'AND'
): FilterExpression => {
  const expressions = filters.map((f) => {
    if (isLogicFilter(f)) {
      const expression = serialize(f.value, f.operator);
      const hasMultipleValues = f.value.length > 1;

      // ensure a child expression is properly escaped
      return hasMultipleValues ? `(${expression})` : expression;
    }

    const expression = `${f.key} ${translateComparator(f.comparator)}`;
    if (COMPARATORS_WITHOUT_VALUES.has(f.comparator)) {
      return expression;
    }

    return `${expression} ${translateValue(f.value)}`;
  });

  return expressions.join(` ${translateOperator(joinOperator)} `);
};
