import { produce } from 'immer';
import _unset from 'lodash-es/unset';

import { V1IdentityProvider, V1SAMLProvider } from '@endorlabs/api_client';
import { IdentityProviderResource } from '@endorlabs/queries';

import {
  IdentityProviderFieldValues,
  IdentityProviderType,
  IdentityProviderTypeFields,
  IdentityProviderTypes,
  SamlMetadataMethods,
} from '../types';

export const determineIdentityProviderType = (
  identityProvider?: IdentityProviderResource
): IdentityProviderType => {
  if (identityProvider?.spec.oidc_provider) {
    return IdentityProviderTypes.OIDC;
  }

  if (identityProvider?.spec.saml_provider) {
    return IdentityProviderTypes.SAML;
  }

  return IdentityProviderTypes.OIDC;
};

export const fromIdentityProviderModel = (
  identityProvider: IdentityProviderResource | IdentityProviderFieldValues
): IdentityProviderFieldValues => {
  const fieldValues = produce(
    identityProvider,
    (draft: IdentityProviderFieldValues) => {
      if (draft.spec.saml_provider?.certificates) {
        draft.spec.saml_provider.certificates =
          draft.spec.saml_provider.certificates.map((cert) => atob(cert));
      }

      draft.saml_metadata_method = determineSamlMetadataMethodByFields(
        draft as IdentityProviderResource
      );
    }
  ) as IdentityProviderFieldValues;

  return fieldValues;
};

export const toIdentityProviderModel = (
  fieldValues: IdentityProviderFieldValues,
  identityProviderType: IdentityProviderType
) => {
  return produce(fieldValues, (draft) => {
    // identityProviderType is source of truth. Set all other provider types to undefined.
    Object.values(IdentityProviderTypes)
      .filter((providerType) => providerType !== identityProviderType)
      .forEach((providerType) => {
        _unset(draft, `spec[${IdentityProviderTypeFields[providerType]}]`);
      });

    if (
      draft.saml_metadata_method === SamlMetadataMethods.URL ??
      (!draft.saml_metadata_method &&
        !!draft.spec.saml_provider?.provider_metadata_url)
    ) {
      ['issuer', 'sso_url', 'certificates'].forEach((f) => {
        if (draft.spec.saml_provider) {
          _unset(draft, `spec.saml_provider[${f as keyof V1SAMLProvider}]`);
        }
      });
    }

    if (draft.saml_metadata_method === SamlMetadataMethods.MANUAL) {
      ['provider_metadata_url'].forEach((f) => {
        if (draft.spec.saml_provider) {
          _unset(draft, `spec.saml_provider[${f as keyof V1SAMLProvider}]`);
        }
      });

      const certificates = new Array(
        draft.spec.saml_provider?.certificates as unknown as string
      );

      // Base64 encode SAML certificate
      if (certificates[0] && draft.spec.saml_provider) {
        draft.spec.saml_provider.certificates = [btoa(certificates[0])];
      }
    }

    // Strip extra field
    _unset(draft, 'saml_metadata_method');
  }) as V1IdentityProvider;
};

export const determineSamlMetadataMethodByFields = (
  identityProvider?: IdentityProviderFieldValues
) => {
  if (identityProvider?.spec.saml_provider?.provider_metadata_url)
    return SamlMetadataMethods.URL;

  if (identityProvider?.spec.saml_provider?.issuer)
    return SamlMetadataMethods.MANUAL;

  return SamlMetadataMethods.URL;
};
