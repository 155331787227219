import { Configuration } from '@endorlabs/api_client';

export interface ClientConfiguration {
  basePath?: string;
  baseOptions?: {
    withCredentials?: boolean;
    headers?: Record<string, string>;
  };
}

// Set default read for UI calls to be from the secondary
const ENDOR_READ_FROM_SECONDARY_KEY = 'x-endor-read-from-secondary';
const ENDOR_CALLER_KEY = 'x-endor-caller';
const REQUEST_TIMEOUT_KEY = 'Request-Timeout';

let __CONFIG__: ClientConfiguration = {
  basePath: 'https://localhost:8443',
  baseOptions: {
    withCredentials: true,
    headers: {
      // [ENDOR_CALLER_KEY]: '@endorlabs/webapp', // TODO: send x-endor-caller value
      [ENDOR_READ_FROM_SECONDARY_KEY]: 'true',
      // Default all timeouts from the UI to 60 seconds
      [REQUEST_TIMEOUT_KEY]: '60',
    },
  },
};

/**
 * Expose method to set base client configuration
 */

export const setClientConfiguration = (
  config: ClientConfiguration = __CONFIG__
) => {
  __CONFIG__ = {
    ...__CONFIG__,
    ...config,
    baseOptions: {
      ...__CONFIG__.baseOptions,
      ...config.baseOptions,
      headers: {
        ...__CONFIG__.baseOptions?.headers,
        ...config.baseOptions?.headers,
      },
    },
  };
};

export const getClientConfiguration = () => new Configuration(__CONFIG__);
