import { defineMessages, MessageDescriptor } from 'react-intl';

import { PolicyExceptionReason, PolicyPolicyType } from '@endorlabs/api_client';

import { PolicyUmbrellaTypes } from '../types';

export const messages = defineMessages({
  labelSwitch: { defaultMessage: 'Is policy enabled?' },
  [PolicyPolicyType.MlFinding]: { defaultMessage: 'Endor Labs' },
  [PolicyPolicyType.SystemFinding]: { defaultMessage: 'Endor Labs' },
  [PolicyPolicyType.UserFinding]: { defaultMessage: 'Custom' },
});

export const EditPolicyPage = {
  [PolicyUmbrellaTypes.ACTION]: defineMessages({
    pageTitleCreate: { defaultMessage: 'Create Action Policy' },
    pageTitleUpdate: { defaultMessage: 'Update Action Policy' },
    formHeading: {
      defaultMessage: 'Act on Findings based on criteria you select',
    },
  }),
  [PolicyUmbrellaTypes.EXCEPTION]: defineMessages({
    pageTitleCreate: { defaultMessage: 'Create Exception Policy' },
    pageTitleUpdate: { defaultMessage: 'Update Exception Policy' },
    formHeading: {
      defaultMessage: 'Disregard certain Findings based on criteria you select',
    },
  }),
  [PolicyUmbrellaTypes.FINDING]: defineMessages({
    pageTitleCreate: { defaultMessage: 'Create Finding Policy' },
    pageTitleUpdate: { defaultMessage: 'Update Finding Policy' },
    formHeading: {
      defaultMessage: 'Create Findings based on criteria you select',
    },
  }),
  [PolicyUmbrellaTypes.REMEDIATION]: defineMessages({
    pageTitleCreate: { defaultMessage: 'Create Remediation Policy' },
    pageTitleUpdate: { defaultMessage: 'Update Remediation Policy' },
    formHeading: {
      defaultMessage: 'Create Remediation Policy based on criteria you select',
    },
  }),
};

export const FormUpsertPolicy = {
  [PolicyUmbrellaTypes.ACTION]: defineMessages({
    stepHeader: { defaultMessage: 'Choose Actions' },
    namePolicy: { defaultMessage: 'Name Your Action Policy' },
    submitCreate: { defaultMessage: 'Create Action Policy' },
    submitUpdate: { defaultMessage: 'Update Action Policy' },
  }),
  [PolicyUmbrellaTypes.EXCEPTION]: defineMessages({
    stepHeader: { defaultMessage: 'Configure Exceptions' },
    namePolicy: { defaultMessage: 'Name Your Exception Policy' },
    submitCreate: { defaultMessage: 'Create Exception Policy' },
    submitUpdate: { defaultMessage: 'Update Exception Policy' },
  }),
  [PolicyUmbrellaTypes.FINDING]: defineMessages({
    stepHeader: { defaultMessage: 'Configure Findings' },
    namePolicy: { defaultMessage: 'Name Your Finding Policy' },
    submitCreate: { defaultMessage: 'Create Finding Policy' },
    submitUpdate: { defaultMessage: 'Update Finding Policy' },
  }),
  [PolicyUmbrellaTypes.REMEDIATION]: defineMessages({
    stepHeader: { defaultMessage: 'Configure Remediations' },
    namePolicy: { defaultMessage: 'Name Your Remediation Policy' },
    submitCreate: { defaultMessage: 'Create Remediation Policy' },
    submitUpdate: { defaultMessage: 'Update Remediation Policy' },
  }),
};

export const FindingPoliciesView = defineMessages({
  headerWithSearch: {
    defaultMessage: `Showing {policyCount} of {totalPolicyCount, plural,
      =0 {# finding policies}
      one {# finding policy}
      other {# finding policies}
    }`,
  },
  headerWithoutSearch: {
    defaultMessage: `There {policyCount, plural,
      =0 {are no finding policies}
      one {is # finding policy}
      other {are # finding policies}
    } in this namespace`,
  },
});

export const AdmissionPoliciesView = defineMessages({
  headerWithSearch: {
    defaultMessage: `Showing {policyCount} of {totalPolicyCount, plural,
      =0 {# action policies}
      one {# action policy}
      other {# action policies}
    }`,
  },
  headerWithoutSearch: {
    defaultMessage: `{totalPolicyCount, plural,
      =0 {No action policies defined}
      one {# action policy defined}
      other {# action policies defined}
    }`,
  },
});

export const ExceptionPoliciesView = defineMessages({
  headerWithSearch: {
    defaultMessage: `Showing {policyCount} of {totalPolicyCount, plural,
      =0 {# exception policies}
      one {# exception policy}
      other {# exception policies}
    }`,
  },
  headerWithoutSearch: {
    defaultMessage: `{totalPolicyCount, plural,
      =0 {No exception policies defined}
      one {# exception policy defined}
      other {# exception policies defined}
    }`,
  },
});

export const RemediationPoliciesView = defineMessages({
  headerWithSearch: {
    defaultMessage: `Showing {policyCount} of {totalPolicyCount, plural,
      =0 {# remediation policies}
      one {# remediation policy}
      other {# remediation policies}
    }`,
  },
  headerWithoutSearch: {
    defaultMessage: `{totalPolicyCount, plural,
      =0 {No remediation policies defined}
      one {# remediation policy defined}
      other {# remediation policies defined}
    }`,
  },
});

export const ExceptionPolicyReasonLabels: Record<
  PolicyExceptionReason,
  MessageDescriptor
> = defineMessages({
  [PolicyExceptionReason.FalsePositive]: { defaultMessage: 'False Positive' },
  [PolicyExceptionReason.InTriage]: { defaultMessage: 'In Triage' },
  [PolicyExceptionReason.RiskAccepted]: { defaultMessage: 'Risk Accepted' },
  [PolicyExceptionReason.Other]: { defaultMessage: 'Other' },
  [PolicyExceptionReason.Unspecified]: { defaultMessage: 'Unspecified' },
});

export const PolicyTemplateOwnerLabels = defineMessages({
  system: { defaultMessage: 'Endor Labs' },
  other: { defaultMessage: 'User Defined' },
});

export const PolicyTypeLabels = defineMessages({
  [PolicyPolicyType.Admission]: { defaultMessage: 'Action Policy' },
  [PolicyPolicyType.Exception]: { defaultMessage: 'Exception Policy' },
  [PolicyPolicyType.MlFinding]: { defaultMessage: 'Finding Policy' },
  [PolicyPolicyType.Notification]: { defaultMessage: 'Notification' },
  [PolicyPolicyType.Remediation]: { defaultMessage: 'Remediation Policy' },
  [PolicyPolicyType.SystemFinding]: { defaultMessage: 'Finding Policy' },
  [PolicyPolicyType.Unspecified]: { defaultMessage: 'Unspecified' },
  [PolicyPolicyType.UserFinding]: { defaultMessage: 'Finding Policy' },
});
