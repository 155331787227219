import { MetricToolsByCategory } from '@endorlabs/queries';

import { ProjectToolsTableRow } from './types';

export const mapToProjectToolsTableRows = (
  namespace: string,
  toolsByCategory: MetricToolsByCategory,
  override?: (row: ProjectToolsTableRow) => ProjectToolsTableRow
): ProjectToolsTableRow[] => {
  const sortedToolCategories = Object.keys(toolsByCategory).sort((a, b) =>
    a.localeCompare(b)
  );

  return sortedToolCategories.map((category) => {
    const toolCount = toolsByCategory[category].length;

    const tools = toolsByCategory[category];

    const row: ProjectToolsTableRow = {
      namespace,
      category,
      toolCount,
      tools,
    };

    // allow override
    if (override) {
      return override(row);
    }

    return row;
  });
};
