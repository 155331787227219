import { QueryKey, useQuery } from 'react-query';

import { EndorLicenseServiceApi } from '@endorlabs/api_client';

import { EndorLicenseResourceList, ResourceQueryOptions } from './types';
import { getClientConfiguration } from './utils';

const BASE_KEY = 'v1/endor-licenses';

const QK = {
  record: (namespace: string): QueryKey =>
    [BASE_KEY, 'get', namespace] as const,
};

export const EndorLicenseQueryKeys = QK;

export const ENDOR_LICENSE_UPDATE_MASK = 'meta,spec';

type GetLicenseOptions = ResourceQueryOptions<EndorLicenseResourceList>;

export interface LicenseReadParams {
  namespace: string;
}
const getApiService = () =>
  new EndorLicenseServiceApi(getClientConfiguration());

const listTenantLicenses = async (namespace: string) => {
  const api = getApiService();
  const resp = await api.endorLicenseServiceListEndorLicenses(namespace);
  return resp.data as EndorLicenseResourceList;
};

export const useListEndorLicenses = (
  params: LicenseReadParams,
  opts: GetLicenseOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace),
    () => listTenantLicenses(params.namespace),
    opts
  );
};
