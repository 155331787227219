import { Divider, Skeleton, Stack } from '@mui/material';
import _sortBy from 'lodash-es/sortBy';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1ScoreCard,
  V1ScoreCategory,
  V1ScoreFactor,
} from '@endorlabs/api_client';
import { EmptyState, FlexList, FlexListItem } from '@endorlabs/ui-common';

import { ScoreFactorDisplay } from './ScoreFactorDisplay';

export interface ScoreFactorsListProps {
  defaultScoreCategory?: V1ScoreCategory;
  isLoading?: boolean;
  scoreCard?: V1ScoreCard;
  scoreCategory?: V1ScoreCategory;
  scoreFactors?: V1ScoreFactor[];
  showControls?: boolean;
}

export const ScoreFactorsList = ({
  isLoading = false,
  scoreCategory,
  scoreFactors = [],
}: ScoreFactorsListProps) => {
  const displayedScoreFactors = useMemo(() => {
    if (!scoreCategory) return _sortBy(scoreFactors, ['score']);

    return _sortBy(
      (scoreFactors ?? []).filter((sf) => sf.category === scoreCategory),
      ['score']
    );
  }, [scoreFactors, scoreCategory]);

  return (
    <Stack direction="column" gap={4}>
      {isLoading && (
        <Stack gap={4}>
          {Array(3)
            .fill(undefined)
            .map((n, i) => (
              <Skeleton height={120} key={i} sx={{ transform: 'none' }} />
            ))}
        </Stack>
      )}

      {!isLoading && displayedScoreFactors.length === 0 && (
        <EmptyState
          size="small"
          title={<FM defaultMessage="No factors found" />}
        />
      )}

      {!isLoading && scoreFactors.length > 0 && (
        <FlexList direction="column" gap={4} divider={<Divider />}>
          {displayedScoreFactors.map((sf, i) => (
            <FlexListItem key={i}>
              <ScoreFactorDisplay scoreFactor={sf} />
            </FlexListItem>
          ))}
        </FlexList>
      )}
    </Stack>
  );
};
