import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import {
  TitleActionHeader,
  TitleActionHeaderProps,
} from '@endorlabs/ui-common';

import { DRAWER_SECTION_PADDING_HORIZ } from './constants';

export interface DetailDrawerSectionProps extends Omit<StackProps, 'title'> {
  disableGutters?: boolean;
  disablePadding?: boolean;
  headerProps?: TitleActionHeaderProps;
  title?: ReactNode;
}

/**
 * Layout component to keep detail drawer layout simple
 */
export const DetailDrawerSection = ({
  children,
  disableGutters = false,
  disablePadding,
  headerProps,
  id,
  title,
}: DetailDrawerSectionProps) => {
  const { space } = useTheme();

  return (
    <Box>
      <Stack
        id={id}
        className="DetailDrawerSection-root"
        paddingX={disableGutters ? 0 : DRAWER_SECTION_PADDING_HORIZ}
        marginTop={disablePadding ? '-16px' : 0}
        marginBottom={disablePadding ? '-24px' : 0}
        spacing={space.xs}
      >
        {headerProps && (
          <TitleActionHeader
            className="DetailDrawerSection-header"
            {...headerProps}
          />
        )}

        {!headerProps && title ? (
          <Typography className="DetailDrawerSection-header" variant="h3">
            {title}
          </Typography>
        ) : undefined}

        <Box className="DetailDrawerSection-content">{children}</Box>
      </Stack>
    </Box>
  );
};
