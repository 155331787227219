import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  GroupByTimeGroupByTimeInterval,
  V1FindingLogSpecOperation,
} from '@endorlabs/api_client';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import { useCountFindingLogs } from '@endorlabs/queries';
import { RowStack } from '@endorlabs/ui-common';

import { useDashboardFindingResolutionTimeTrendData } from '../hooks';
import { DashboardStat } from './DashboardStat';

export const DashboardVulnerabilitiesSnapshot = ({
  namespace,
  baseFilter,
}: {
  namespace: string;
  baseFilter: FilterExpression;
}) => {
  const qCountFindingsOpen = useCountFindingLogs(namespace, {
    filter: filterExpressionBuilders.and(
      [
        baseFilter,
        `spec.operation==${V1FindingLogSpecOperation.Create}`,
      ].filter(Boolean)
    ),
  });

  const qCountFindingsResolved = useCountFindingLogs(namespace, {
    filter: filterExpressionBuilders.and(
      [
        baseFilter,
        `spec.operation==${V1FindingLogSpecOperation.Delete}`,
      ].filter(Boolean)
    ),
  });

  const { isLoading, snapshot } = useDashboardFindingResolutionTimeTrendData({
    namespace,
    baseFilter,
    groupByTimeOptions: {
      interval: GroupByTimeGroupByTimeInterval.Week,
      group_size: 1,
    },
  });

  const { palette } = useTheme();

  return (
    <Card
      elevation={0}
      sx={{ backgroundColor: palette.design.greens.dark_teal[200] }}
    >
      <CardHeader title={<FM defaultMessage="Vulnerabilities Snapshot" />} />
      <CardContent>
        <RowStack gap={4}>
          <DashboardStat
            isLoading={qCountFindingsOpen.isLoading}
            title={<FM defaultMessage="Newly Discovered" />}
            value={qCountFindingsOpen.data?.count}
          />

          <DashboardStat
            isLoading={qCountFindingsResolved.isLoading}
            title={<FM defaultMessage="Resolved" />}
            value={qCountFindingsResolved.data?.count}
          />

          <DashboardStat
            accentColor={palette.design.greens.dark_teal[600]}
            isLoading={isLoading}
            title={<FM defaultMessage="Average Time to Resolve" />}
            value={snapshot.avg}
            unit={<FM defaultMessage="days" />}
          />

          <DashboardStat
            isLoading={isLoading}
            title={<FM defaultMessage="Minumum Time to Resolve" />}
            value={snapshot.min}
            unit={<FM defaultMessage="days" />}
          />

          <DashboardStat
            isLoading={isLoading}
            title={<FM defaultMessage="Maximum Time to Resolve" />}
            value={snapshot.max}
            unit={<FM defaultMessage="days" />}
          />
        </RowStack>
      </CardContent>
    </Card>
  );
};
