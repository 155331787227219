/**
 * The tenant type, determined from the Endor License associated with the
 * tenant.
 */
export enum TenantType {
  Global = 'TENANT_TYPE_GLOBAL',
  Shared = 'TENANT_TYPE_SHARED',
  Premium = 'TENANT_TYPE_PREMIUM',
  Trial = 'TENANT_TYPE_TRIAL',
  TrialExpired = 'TENANT_TYPE_TRIAL_EXPIRED',
  Unspecified = 'TENANT_TYPE_UNSPECIFIED',
}
