import { RouteMatch, useRouter } from '@tanstack/react-location';
import _findLast from 'lodash-es/findLast';
import _isObject from 'lodash-es/isObject';
import _isString from 'lodash-es/isString';
import { ReactNode, useEffect, useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

/**
 * Intended as a utility to assist in extracting route segment titles for use
 * in breadcrumbs or page titles.
 *
 * TODO: update for use with @tanstack/react-router after replacing.
 *
 * Refeference: {@link https://tanstack.com/router/v1/docs/framework/react/guide/router-context#processing-accumulated-route-contexts}
 */
const getRouteContext = <K extends string>(match: RouteMatch, props: K[]) => {
  const routeContext = match.route.meta?.context;
  if (_isObject(routeContext) && props.every((key) => key in routeContext)) {
    return routeContext as Record<K, unknown>;
  }
};

const isSimpleMessageDescriptor = (v: unknown): v is MessageDescriptor => {
  if (!_isObject(v)) {
    return false;
  }

  if (!('id' in v && _isString(v.id))) {
    return false;
  }

  if (!('defaultMessage' in v)) {
    return false;
  }

  if (
    !_isString(v.defaultMessage) &&
    !(Array.isArray(v.defaultMessage) && v.defaultMessage.length === 1)
  ) {
    return false;
  }

  return true;
};

export const usePageTitle = (
  pageTitleValues: Record<string, ReactNode> = {}
) => {
  const { formatMessage: fm } = useIntl();

  // Find the deepest route match with a page title in meta
  const router = useRouter();
  const deepestMatch = _findLast(router.state.matches, (match) =>
    Boolean(match.route.meta?.pageTitle)
  );

  // Determine page title with fallbacks
  const pageTitle = useMemo(() => {
    const routePageTitle = deepestMatch?.route.meta
      ?.pageTitle as MessageDescriptor;

    if (!routePageTitle) return;

    if (_isString(routePageTitle.defaultMessage))
      return routePageTitle as string;

    // If page title expects values, but none are provided, fall back to default
    if (
      (routePageTitle.defaultMessage ?? []).length > 1 &&
      Object.keys(pageTitleValues).length === 0
    ) {
      return;
    }

    return fm(routePageTitle, pageTitleValues);
  }, [deepestMatch, fm, pageTitleValues]);

  const pageTitleSegments = useMemo(() => {
    const segments = [];
    for (const m of router.state.matches) {
      const routeContext = getRouteContext(m, ['title']);

      if (!routeContext) continue;
      const { title } = routeContext;

      if (_isString(title)) {
        segments.push(title);
      } else if (isSimpleMessageDescriptor(title)) {
        segments.push(fm(title));
      }
    }
    return segments.reverse();
  }, [fm, router.state.matches]);

  // Update document title
  useEffect(() => {
    // If there is a page title
    if ('string' === typeof pageTitle) {
      document.title = pageTitle;
      return;
    }

    if (pageTitleSegments.length) {
      document.title = pageTitleSegments.join(' | ');
      return;
    }
  }, [pageTitle, pageTitleSegments]);
};
