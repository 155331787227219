import { Typography } from '@mui/material';
import { addYears } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { ReactNode, useMemo } from 'react';
import { FormattedDate, FormattedMessage as FM } from 'react-intl';

import { V1SystemRole } from '@endorlabs/api_client';
import { PermissionRoleLabel, RowStack } from '@endorlabs/ui-common';

export interface ApiKeyFieldOption {
  id: string;
  value: string;
  label: ReactNode;
}

const PERMISSIONS = [
  {
    id: V1SystemRole.Admin,
    label: <PermissionRoleLabel role={V1SystemRole.Admin} />,
    value: V1SystemRole.Admin,
  },
  {
    id: V1SystemRole.ReadOnly,
    label: <PermissionRoleLabel role={V1SystemRole.ReadOnly} />,
    value: V1SystemRole.ReadOnly,
  },
  {
    id: V1SystemRole.CodeScanner,
    label: <PermissionRoleLabel role={V1SystemRole.CodeScanner} />,
    value: V1SystemRole.CodeScanner,
  },
  {
    id: V1SystemRole.PolicyEditor,
    label: <PermissionRoleLabel role={V1SystemRole.PolicyEditor} />,
    value: V1SystemRole.PolicyEditor,
  },
];

const EXPIRATIONS = [
  ...[30, 60, 90, 180].map((offset) => ({
    id: `EXPIRATION_${offset}_DAYS`,
    label: <FM defaultMessage="{offset, number} Days" values={{ offset }} />,
    getValue: (now: number) => addDays(now, offset).toISOString(),
  })),
  ...[1].map((offset) => ({
    id: `EXPIRATION_${offset}_YEARS`,
    label: <FM defaultMessage="1 Year" />,
    getValue: (now: number) => addYears(now, offset).toISOString(),
  })),
];

/**
 * Utility to generate the field options for the API Key form
 */
export const useApiKeyFieldOptions = (): Record<
  string,
  ApiKeyFieldOption[]
> => {
  const now = useMemo(() => Date.now(), []);

  const expirations = useMemo(() => {
    return EXPIRATIONS.map((option) => {
      const value = option.getValue(now);

      return {
        id: option.id,
        value,
        label: (
          <RowStack
            divider={
              <Typography component="span" color="text.secondary">
                &middot;
              </Typography>
            }
          >
            <Typography component="span">{option.label}</Typography>

            <Typography component="span" color="text.secondary">
              <FormattedDate value={value} dateStyle="medium" />
            </Typography>
          </RowStack>
        ),
      };
    });
  }, [now]);

  return { permissions: PERMISSIONS, expirations };
};
