import { useMemo } from 'react';

import {
  PackageResource,
  tryParseGroupResponseAggregationKey,
  useListPackageVersions,
} from '@endorlabs/queries';
import {
  UIPackageVersionUtils,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

type UseAggregatedPackageVersionsProps = {
  namespace: string;
  baseFilterExpression: string;
  filterExpression?: string;
};

/**
 * Aggregate Package Versions by the parent Package
 *
 * Returns a Package stub for the parent, and a list of versions
 */
export const useAggregatedPackageVersions = ({
  namespace,
  baseFilterExpression,
  filterExpression,
}: UseAggregatedPackageVersionsProps) => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const qGroupedPackageVersionsTotal = useListPackageVersions(
    namespace,
    {},
    {
      filter: baseFilterExpression,
      group: {
        aggregation_paths: ['spec.package_name', 'spec.project_uuid'].join(','),
        unique_value_paths: ['meta.name'].join(','),
      },
    }
  );

  const qGroupedPackageVersions = useListPackageVersions(
    namespace,
    {},
    {
      filter: [baseFilterExpression, filterExpression]
        .filter(Boolean)
        .join(' and '),
      group: {
        aggregation_paths: ['spec.package_name', 'spec.project_uuid'].join(','),
        unique_value_paths: ['meta.name'].join(','),
      },
    }
  );

  const totalCount = useMemo(() => {
    return Object.keys(
      qGroupedPackageVersionsTotal.data?.group_response?.groups ?? {}
    ).length;
  }, [qGroupedPackageVersionsTotal.data?.group_response?.groups]);

  const data = useMemo(() => {
    const packageVersionEntries = Object.entries(
      qGroupedPackageVersions.data?.group_response?.groups ?? {}
    );

    return packageVersionEntries
      .map(([key, group]) => {
        const values = tryParseGroupResponseAggregationKey(key);
        const packageName = values.find((kv) => kv.key === 'spec.package_name')
          ?.value as string;
        const projectUuid = values.find((kv) => kv.key === 'spec.project_uuid')
          ?.value as string;

        const { ecosystem } =
          UIPackageVersionUtils.parsePackageName(packageName);

        // build package stub from aggregation
        const packageResource = {
          meta: { name: packageName },
          spec: { ecosystem },
          tenant_meta: { namespace },
        } satisfies PackageResource;

        const versionCount = group.aggregation_count?.count ?? 0;

        // map info for package versions
        const versions =
          group.unique_values?.['meta.name']
            .map((name) => {
              const { version: versionRef } =
                UIPackageVersionUtils.parsePackageName(name);
              return { name, versionRef };
            })
            .sort((a, b) =>
              UIPackageVersionUtils.sortBySemanticVersion(
                b.versionRef,
                a.versionRef
              )
            ) ?? [];

        return {
          packageName,
          packageResource,
          projectUuid,
          versionCount,
          versions,
        };
      })
      .sort((a, b) => a.packageName.localeCompare(b.packageName));
  }, [namespace, qGroupedPackageVersions.data]);

  return {
    data,
    error: qGroupedPackageVersions.error
      ? getErrorMessage('LIST', 'PackageVersion', qGroupedPackageVersions.error)
      : undefined,
    isError: qGroupedPackageVersions.isError,
    isLoading: qGroupedPackageVersions.isLoading,
    refetch: qGroupedPackageVersions.refetch,
    totalCount,
  };
};
