import { V1Ecosystem } from '@endorlabs/api_client';

import { PackageURL } from '../../PackageURL';
import { PackageDescriptor } from '../types';
import { deriveFullPackageName } from './deriveFullPackageName';

export const packageDescriptorFromPackageURL = (
  packageURL: PackageURL
): PackageDescriptor => {
  const { name, namespace, version = '', type } = packageURL;

  let packageName = namespace ? `${namespace}/${name}` : name;
  let ecosystem = V1Ecosystem.Unspecified;
  switch (type.toLowerCase()) {
    case 'cargo':
      ecosystem = V1Ecosystem.Cargo;
      break;
    case 'composer':
      ecosystem = V1Ecosystem.Packagist;
      break;
    case 'gem':
      ecosystem = V1Ecosystem.Gem;
      break;
    case 'golang':
      ecosystem = V1Ecosystem.Go;
      break;
    case 'maven':
      ecosystem = V1Ecosystem.Maven;
      // custom handling for maven package namespace
      if (namespace) {
        packageName = `${namespace}:${name}`;
      }
      break;
    case 'npm':
      ecosystem = V1Ecosystem.Npm;
      break;
    case 'nuget':
      ecosystem = V1Ecosystem.Nuget;
      break;
    case 'pypi':
      ecosystem = V1Ecosystem.Pypi;
      break;
  }

  const packageVersionName = deriveFullPackageName({
    ecosystem,
    name: packageName,
    version,
  });

  return {
    ecosystem,
    label: packageName,
    name: packageVersionName,
    version,
  };
};
