import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Control } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import {
  ControlledCheckbox,
  ControlledLabelsField,
} from '../../../../components';
import { IconChevronDown } from '../../../../themes';
import { FormUpsertAuthorizationPolicyFieldValues } from './types';

export const AuthorizationPolicyAdvancedFields = ({
  control,
}: {
  control: Control<FormUpsertAuthorizationPolicyFieldValues>;
}) => {
  const { palette, typography } = useTheme();

  return (
    <Accordion
      disableGutters // prevent margin on expanded state
      elevation={0}
      sx={{
        '& .MuiAccordionSummary-root': {
          justifyContent: 'flex-start',
          gap: 2,
          '& .MuiAccordionSummary-content': {
            flexGrow: 0,
          },
        },
      }}
    >
      <AccordionSummary expandIcon={<IconChevronDown />}>
        <Typography
          sx={{
            ...typography.button,
            color: palette.text.primary,
          }}
        >
          <FM defaultMessage="Advanced" />
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack direction="column" spacing={6}>
          <ControlledLabelsField
            control={control}
            helperText={
              <FM defaultMessage="By default, this policy applies to the current namespace and all its child namespaces. Instead, you can apply it to one or more child namespaces, allowing you to group your policies for child namespaces in their parent namespace for easier management." />
            }
            label={<FM defaultMessage="Namespaces this policy applies to" />}
            name="targetNamespaces"
          />

          <FormControlLabel
            label={
              <FM defaultMessage="Propagate this policy to all child namespaces." />
            }
            control={
              <ControlledCheckbox
                control={control}
                // @ts-expect-error field value type does not expect boolean?
                defaultValue={false}
                name="propagate"
              />
            }
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
