import { defineMessages } from 'react-intl';

import { ResolutionStatusStatusError as ResolutionStatusError } from '@endorlabs/api_client';

export const ResolutionStatusErrorMessages =
  defineMessages<ResolutionStatusError>({
    [ResolutionStatusError.Unspecified]: {
      defaultMessage: 'Unspecified Error',
    },
    [ResolutionStatusError.ManifestLoad]: {
      defaultMessage: 'Manifest Load Error',
    },
    [ResolutionStatusError.ManifestParse]: {
      defaultMessage: 'Manifest Parse Error',
    },
    [ResolutionStatusError.ManifestEmpty]: {
      defaultMessage: 'Manifest Empty Error',
    },
    [ResolutionStatusError.Dependency]: { defaultMessage: 'Dependency Error' },
    [ResolutionStatusError.CallGraph]: { defaultMessage: 'Call Graph Error' },
    [ResolutionStatusError.MissingArtifact]: {
      defaultMessage: 'Missing Artifact',
    },
    [ResolutionStatusError.Build]: { defaultMessage: 'Build Error' },
    [ResolutionStatusError.PackageVersionUnavailable]: {
      defaultMessage: 'Package Version Unavailable',
    },
    [ResolutionStatusError.NoCodeArtifact]: {
      defaultMessage: 'No Code Artifact',
    },
    [ResolutionStatusError.Venv]: {
      defaultMessage: 'Cannot create venv',
    },
    [ResolutionStatusError.Internal]: {
      defaultMessage: 'Internal data error',
    },
    [ResolutionStatusError.Unsupported]: {
      defaultMessage: 'Unsupported Language',
    },
  });
