import { Shadows } from '@mui/material/styles/shadows';

// Slightly more transparent versions of colors used in default MUI theme.
// https://mui.com/material-ui/customization/default-theme/
const shadowColors = [
  'rgba(0, 0, 0, 0.15)',
  'rgba(0, 0, 0, 0.11)',
  'rgba(0, 0, 0, 0.05)',
  'rgba(0, 0, 0, 0.01)',
];

// Mira's createShadow function
function createShadow() {
  return `0px 1px 2px 0px rgba(0, 0, 0, 0.05)`;
}

// TODO: Repeated shadow definitions are a remnant from our original "flat" theme.
// Define programatically instead so elevation is reliable?
export const shadows: Shadows = [
  'none',
  createShadow(),
  createShadow(),

  // 3 - Default Card elevation
  `0 0 0 1px ${shadowColors[3]}, 0 2px 5px 0 ${shadowColors[2]}`,

  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),

  // 8 - Default popover elevation (Menu, etc)
  `0px 5px 5px -3px ${shadowColors[0]}, 0px 8px 10px 1px ${shadowColors[1]}, 0px 3px 14px 2px ${shadowColors[2]}`,

  createShadow(),
  createShadow(),
  createShadow(),

  // 12 - Snackbars
  `0px 6px 6px -4px ${shadowColors[0]}, 0px 10px 12px 2px ${shadowColors[1]}, 0px 5px 16px 3px ${shadowColors[2]}`,

  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),

  // 24 - Dialog elevation
  `0px 11px 15px -7px ${shadowColors[0]}, 0px 24px 38px 3px ${shadowColors[1]}, 0px 9px 46px 8px ${shadowColors[2]}`,
];
