import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { FieldNamesMarkedBoolean, useForm } from 'react-hook-form';
import {
  defineMessages,
  FormattedMessage as FM,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import { PackagistSpecAuthKind } from '@endorlabs/api_client';
import {
  IQueryErrorResponse,
  PackageManagerResource,
} from '@endorlabs/queries';
import {
  ControlledTextField,
  REGEX_HOSTNAME_VALIDATION,
} from '@endorlabs/ui-common';
import { PackagistAuthKindLabel } from '@endorlabs/ui-common/domains/PackageManagers/PackageManagersLabel';

import { PACKAGIST_AUTH_TYPES, PackagistPackageManagerKey } from './constants';
import { PackageManagerAdvancedFields } from './PackageManagerAdvancedFields';
import { PackageManagerAWSCodeArtifactFields } from './PackageManagerAWSCodeArtifactFields';
import { PackageManagerMainFormFields } from './PackageManagerMainFormFields';
import { PackageManagerAuthMode, PackagistFieldValues } from './types';
import { updatePackagistFieldsToModel } from './utils';

interface FormPackagistManagerProps {
  authRequired: boolean;
  isSubmitting: boolean;
  awsCodeArtifactEnabled: boolean;
  onSubmit: (
    data: PackagistFieldValues,
    dirtyFields: FieldNamesMarkedBoolean<PackagistFieldValues>
  ) => void;
  packageManager?: PackageManagerResource;
  packageManagerKey: PackagistPackageManagerKey;
  serverErrorResponse?: IQueryErrorResponse;
}

const UserNameMessage: Record<string, MessageDescriptor> = defineMessages({
  key: {
    defaultMessage: 'Consumer Key',
  },
  default: {
    defaultMessage: 'Username',
  },
});

const getUserMessages = (
  authKind: PackagistSpecAuthKind | undefined
): MessageDescriptor => {
  if (authKind === PackagistSpecAuthKind.BitbucketOauth) {
    return UserNameMessage.key;
  }
  return UserNameMessage.default;
};

const PasswordValidateMessage: Record<string, MessageDescriptor> =
  defineMessages({
    secret: {
      defaultMessage: 'Consumer secret is required',
    },
    token: {
      defaultMessage: 'Token is required',
    },
    default: {
      defaultMessage: 'Password is required',
    },
  });

const getPasswordValidateMessages = (
  authKind: string | undefined
): MessageDescriptor => {
  if (authKind === PackagistSpecAuthKind.BitbucketOauth) {
    return PasswordValidateMessage.secret;
  } else if (authKind && authKind != PackagistSpecAuthKind.HttpBasic) {
    return PasswordValidateMessage.token;
  } else {
    return PasswordValidateMessage.default;
  }
};

const PasswordMessage: Record<string, MessageDescriptor> = defineMessages({
  secret: {
    defaultMessage: 'Consumer Secret',
  },
  token: {
    defaultMessage: 'Token',
  },
  default: {
    defaultMessage: 'Password',
  },
});

const UserValidateMessage: Record<string, MessageDescriptor> = defineMessages({
  secret: {
    defaultMessage: 'Consumer key is required',
  },
  default: {
    defaultMessage: 'Username is required',
  },
});

const getUserValidateMessages = (
  authKind: PackagistSpecAuthKind | undefined
): MessageDescriptor => {
  if (authKind === PackagistSpecAuthKind.BitbucketOauth) {
    return UserValidateMessage.secret;
  }
  return UserValidateMessage.default;
};

const getPasswordMessages = (
  authKind: string | undefined
): MessageDescriptor => {
  if (authKind === PackagistSpecAuthKind.BitbucketOauth) {
    return PasswordMessage.secret;
  } else if (authKind && authKind !== PackagistSpecAuthKind.HttpBasic) {
    return PasswordMessage.token;
  } else {
    return PasswordMessage.default;
  }
};

export const FormUpsertPackagistPackageManager = ({
  authRequired,
  isSubmitting,
  awsCodeArtifactEnabled,
  onSubmit,
  packageManager,
  packageManagerKey,
}: FormPackagistManagerProps) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { dirtyFields },
  } = useForm<PackagistFieldValues>();
  const { formatMessage: fm } = useIntl();
  const authKind = watch('auth_kind');

  const [showUsername, setShowusername] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const hasAuthProvider = !!packageManager?.spec.auth_provider;
  const hasHost = !!packageManager?.spec[packageManagerKey]?.host?.length;

  const [selectedAuthMode, setSelectedAuthMode] =
    useState<PackageManagerAuthMode>(() => {
      return hasAuthProvider
        ? PackageManagerAuthMode.AWS
        : PackageManagerAuthMode.Basic;
    });

  useEffect(() => {
    // reset fields from the given package manager
    reset({ propagate: packageManager?.propagate ?? false });
  }, [packageManager, reset]);

  const wrappedOnSubmit = useCallback(
    (fieldValues: PackagistFieldValues) => {
      if (selectedAuthMode === PackageManagerAuthMode.Basic) {
        const formattedValues = updatePackagistFieldsToModel(fieldValues);
        onSubmit(formattedValues, dirtyFields);
      } else {
        onSubmit(fieldValues, {});
      }
    },
    [onSubmit, selectedAuthMode, dirtyFields]
  );

  useEffect(() => {
    if (authRequired) {
      if (
        !authKind ||
        authKind === PackagistSpecAuthKind.HttpBasic ||
        authKind === PackagistSpecAuthKind.BitbucketOauth ||
        authKind === PackagistSpecAuthKind.GitlabToken
      ) {
        setShowusername(true);
        setShowPassword(true);
      } else {
        setShowusername(false);
        setShowPassword(true);
      }
    } else {
      setShowusername(false);
      setShowPassword(false);
    }
  }, [authKind, authRequired]);

  return (
    <form id="packagistPackageManager" onSubmit={handleSubmit(wrappedOnSubmit)}>
      <Grid container direction="column" item spacing={5}>
        <PackageManagerMainFormFields
          selectedAuthMode={selectedAuthMode}
          setSelectedAuthMode={setSelectedAuthMode}
          packageManager={packageManager}
          control={control}
          hasAuthProvider={hasAuthProvider}
          hasBasicAuthField={hasHost}
          awsCodeArtifactEnabled={awsCodeArtifactEnabled}
        />
        {selectedAuthMode === PackageManagerAuthMode.Basic && (
          <>
            <Grid item>
              <ControlledTextField
                autoComplete="off"
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.host ?? ''
                }
                fullWidth
                label={fm({
                  defaultMessage: 'Package manager host',
                })}
                name="host"
                rules={{
                  required: fm({
                    defaultMessage: 'Package manager host is required',
                  }),
                  validate: (value: unknown) => {
                    // validate string as hostname
                    // adapted from https://github.com/miguelmota/is-valid-hostname
                    const isValidCharacters =
                      'string' === typeof value &&
                      REGEX_HOSTNAME_VALIDATION.test(value);

                    const isValidHostname =
                      isValidCharacters &&
                      value
                        .split('.')
                        .every(
                          (label) =>
                            label.length < 64 &&
                            !label.startsWith('-') &&
                            !label.endsWith('-')
                        );

                    return (
                      isValidHostname ||
                      fm({
                        defaultMessage: 'Must be a valid host',
                      })
                    );
                  },
                }}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <ControlledTextField
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.auth_kind ?? ''
                }
                fullWidth
                label={
                  <FM defaultMessage="Authentication for private repositories" />
                }
                name="auth_kind"
                id="auth_kind"
                rules={{
                  required: fm({
                    defaultMessage: 'Authentication type is required',
                  }),
                }}
                variant="standard"
                select
              >
                {PACKAGIST_AUTH_TYPES.map((auth: PackagistSpecAuthKind) => (
                  <MenuItem key={auth} value={auth}>
                    {PackagistAuthKindLabel({
                      packagistAuthKind: auth,
                    })}
                  </MenuItem>
                ))}
              </ControlledTextField>
            </Grid>
            {showUsername && (
              <Grid item width="36%">
                <ControlledTextField
                  autoComplete="off"
                  control={control}
                  defaultValue={
                    packageManager?.spec[packageManagerKey]?.user ?? ''
                  }
                  fullWidth
                  label={<FM {...getUserMessages(authKind)} />}
                  name="user"
                  rules={{
                    required: authRequired
                      ? fm(getUserValidateMessages(authKind))
                      : false,
                  }}
                  variant="standard"
                />
              </Grid>
            )}
            {showPassword && (
              <Grid item width="36%">
                <ControlledTextField
                  autoComplete="off"
                  control={control}
                  defaultValue={
                    packageManager?.spec[packageManagerKey]?.password ?? ''
                  }
                  fullWidth
                  label={<FM {...getPasswordMessages(authKind)} />}
                  name="password"
                  rules={{
                    required: authRequired
                      ? fm(getPasswordValidateMessages(authKind))
                      : false,
                  }}
                  type="password"
                  variant="standard"
                />
              </Grid>
            )}
          </>
        )}
        {selectedAuthMode === PackageManagerAuthMode.AWS && (
          <PackageManagerAWSCodeArtifactFields
            control={control}
            packageManager={packageManager}
          />
        )}
        <Grid item>
          <PackageManagerAdvancedFields control={control} />
        </Grid>

        <Grid item marginTop={4}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            variant="contained"
          >
            {packageManager && <FM defaultMessage="Save Changes" />}
            {!packageManager && <FM defaultMessage="Add Package Manager" />}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
