import { FormattedMessage as FM } from 'react-intl';

import { NilDisplay, Pip } from '@endorlabs/ui-common';

interface FindingFixAvailableDisplayProps {
  fixVersion?: string;
}

export const FindingFixAvailableDisplay = ({
  fixVersion,
}: FindingFixAvailableDisplayProps) => {
  const msg = (
    <FM
      defaultMessage="Fixed version: <b>{version}</b>"
      values={{ b: (s) => <b>{s}</b>, version: fixVersion }}
    />
  );

  return fixVersion ? (
    <Pip color="success" label={msg} />
  ) : (
    <NilDisplay variant="text" />
  );
};
