/**
 * Cloned from https://github.com/feathericons/react-feather/blob/3a85e8a2b67ba2142a7576928799944aa910dde2/src/icons/sidebar.js
 *
 * The `<line>` element in the SVG icon is shifted to the right side of the icon
 */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type SidebarRightProps = {
  color?: string;
  size?: string | number;
};

const SidebarRight = forwardRef<SVGSVGElement, SidebarRightProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <line x1="15" y1="3" x2="15" y2="21" />
      </svg>
    );
  }
);

SidebarRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SidebarRight.displayName = 'SidebarRight';

export default SidebarRight;
