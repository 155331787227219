import { useMemo } from 'react';

import { V1GroupResponse } from '@endorlabs/api_client';
import { tryParseGroupResponseAggregationKey } from '@endorlabs/queries';
import { PackageVersionDependency } from '@endorlabs/ui-common';

export const useProjectVersionLicenseChartData = (
  packageMetadataGroupResponse?: V1GroupResponse,
  dependencies?: PackageVersionDependency[]
) => {
  const chartData = useMemo(() => {
    const depData = Object.keys(packageMetadataGroupResponse?.groups ?? {}).map(
      (key) => tryParseGroupResponseAggregationKey(key)
    );

    const chartData: { name: string; count: number }[] = [];

    if (depData.length && dependencies?.length) {
      depData.forEach((d) => {
        const packageName = d.find((d) => d.key === 'meta.name')?.value as
          | string
          | undefined;
        const versionList = d.find((d) => d.key === 'spec.versions')?.value as
          | Record<string, unknown>[]
          | undefined;
        const version = dependencies.find(
          (dep) => dep.packageName === packageName
        )?.versionRef;

        if (packageName && versionList && version) {
          const versionObjects = versionList.filter(
            (v: Record<string, unknown>) => v.version === version
          );
          const licenseList = versionObjects.flatMap(
            (v) => v.license ?? []
          ) as string[];

          licenseList.forEach((l) => {
            const entry = chartData.find((data) => data.name === l);
            if (entry) {
              entry.count += 1;
            } else {
              chartData.push({ name: l, count: 1 });
            }
          });
        }
      });
    }

    return chartData.sort((a, b) => b.count - a.count).slice(0, 5);
  }, [dependencies, packageMetadataGroupResponse]);

  return chartData;
};
