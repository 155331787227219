import { Checkbox, FormControlLabel } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FILTER_COMPARATORS, FilterableResource } from '@endorlabs/filters';
import { MultiSelectInput, SeverityDisplay } from '@endorlabs/ui-common';

import { FacetFilterDefinition } from '../../../components';
import { FilterFieldConfig, filterFieldTransformBuilders } from '../../filters';
import { SASTListFilterType } from '../types';

export const FindingPolicyFacets = [
  {
    id: 'FindingPolicy:spec.disable',
    filter: {
      kind: 'Policy' as FilterableResource,
      key: 'spec.disable',
      type: 'boolean',
    },
    control: 'FacetFilterToggle',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
    label: <FM defaultMessage="Hide Disabled" />,
  },
  {
    id: 'FindingPolicy:spec.finding.level',
    filter: {
      kind: 'Policy' as FilterableResource,
      key: 'spec.finding.level',
      type: 'array',
    },
    comparator: FILTER_COMPARATORS.CONTAINS,
    label: <FM defaultMessage="Finding Level" />,
    control: 'FacetFilterMultiSelect',
    values: Object.values(SpecFindingLevel)
      .filter((lvl) => lvl !== SpecFindingLevel.Unspecified)
      .map((value) => ({
        key: value.toString(),
        value,
        label: <SeverityDisplay value={value} />,
      })),
  },
] as FacetFilterDefinition[];

export const AdmissionPolicyFacets = [
  {
    id: 'AdmissionPolicy:spec.admission.disable_enforcement',
    filter: {
      kind: 'Policy',
      key: 'spec.admission.disable_enforcement',
      type: 'boolean',
    },
    control: 'FacetFilterToggle',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
    label: <FM defaultMessage="Hide Warnings" />,
  },
] as FacetFilterDefinition[];

export const getSASTFilterFields = ({
  definedByFilters,
  confidenceFilters,
  languageFilters,
  endorTagsFilters,
}: SASTListFilterType): FilterFieldConfig[] => {
  return [
    {
      id: 'spec.defined_by',
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.defined_by',
        comparator: FILTER_COMPARATORS.CONTAINS,
      }),

      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          label={<FM defaultMessage="Defined By" />}
          onChange={onChange}
          options={definedByFilters.map((value) => ({
            value,
            label: value,
          }))}
          value={value as string[]}
        />
      ),
    },
    {
      id: 'spec.rule.metadata.confidence',
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.rule.metadata.confidence',
        comparator: FILTER_COMPARATORS.CONTAINS,
      }),

      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          label={<FM defaultMessage="Confidence" />}
          onChange={onChange}
          options={confidenceFilters.map((value) => ({
            value,
            label: value,
          }))}
          value={value as string[]}
        />
      ),
    },
    {
      id: 'spec.rule.languages',
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.rule.languages',
        comparator: FILTER_COMPARATORS.CONTAINS,
      }),

      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          label={<FM defaultMessage="Language" />}
          onChange={onChange}
          options={languageFilters.map((value) => ({
            value,
            label: value,
          }))}
          value={value as string[]}
        />
      ),
    },
    {
      id: 'spec.rule.metadata.endor_tags',
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.rule.metadata.endor_tags',
        comparator: FILTER_COMPARATORS.CONTAINS,
      }),

      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          label={<FM defaultMessage="Rule Tags" />}
          onChange={onChange}
          options={endorTagsFilters.map((value) => ({
            value,
            label: value,
          }))}
          value={value as string[]}
        />
      ),
    },
  ] satisfies FilterFieldConfig[];
};

const DisabledRemediationPolicyFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.disable',
  ...filterFieldTransformBuilders.fromFilter({
    key: 'spec.disable',
    comparator: FILTER_COMPARATORS.NOT_EQUAL,
  }),

  renderInput: ({ onChange, value }) => (
    <FormControlLabel
      componentsProps={{
        typography: {
          fontWeight: value ? 'bold' : undefined,
        },
      }}
      control={
        <Checkbox checked={value} onChange={(_, value) => onChange(value)} />
      }
      label={<FM defaultMessage="Hide Disabled" />}
      sx={{
        color: 'primary.main',
        marginRight: 0,
        paddingX: 3,
        borderRadius: 1,
      }}
    />
  ),
} satisfies FilterFieldConfig<boolean>;

// Remediation Policy filter fields
export const REMEDIATION_POLICIES_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  DisabledRemediationPolicyFieldConfig,
];

/**
 * Default search keys for use with Remediation Policies filters
 */
export const REMEDIATION_POLICIES_SEARCH_KEYS = ['meta.name'];
