import {
  Box,
  Button,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  PopoverOrigin,
  Theme,
} from '@mui/material';
import {
  Children,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useStyles } from '../../hooks';
import { IconChevronDown } from '../../themes';

export type SimpleMenuOption = {
  disabled?: boolean;
  key: string;
  label: ReactNode;
};

type Position = 'bottom' | 'bottom-right' | 'bottom-left';

const getMenuPositionProps = (position: Position) => {
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  };
  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'center',
  };

  switch (position) {
    case 'bottom-left':
      anchorOrigin.horizontal = 'right';
      transformOrigin.horizontal = 'right';
      break;
    case 'bottom-right': {
      anchorOrigin.horizontal = 'left';
      transformOrigin.horizontal = 'left';
    }
  }

  return {
    anchorOrigin,
    transformOrigin,
  };
};

export type SimpleMenuProps<T extends SimpleMenuOption> = {
  id: string;
  onClick: (event: SyntheticEvent, item: T) => void;
  position?: Position;
  triggerTitle: ReactNode;
  triggerVariant?: 'button' | 'chip' | 'default';
} & (
  | { groupedOptions: T[][]; options?: never }
  | { groupedOptions?: never; options: T[] }
);

/**
 * Simplified wrapper around MUI Menu
 *
 * Reference {@link https://mui.com/material-ui/react-menu}
 */
export const SimpleMenu = <T extends SimpleMenuOption>({
  id,
  onClick,
  triggerTitle,
  triggerVariant,
  groupedOptions,
  options,
  position = 'bottom-right',
}: SimpleMenuProps<T>) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleSelect = useCallback(
    (event: React.SyntheticEvent, item: T) => {
      onClick(event, item);
      if (!event.isDefaultPrevented()) {
        handleClose(event);
      }
    },
    [onClick]
  );

  const flattenedOptions = useMemo(() => {
    const flattenedOptions: ReactNode[] = [];

    if (groupedOptions) {
      groupedOptions.forEach((group, index) => {
        if (index > 0) {
          flattenedOptions.push(<Divider />);
        }

        group.forEach((o) => {
          flattenedOptions.push(
            <MenuItem
              value={o.key}
              onClick={(e) => handleSelect(e, o)}
              data-value={o.key}
            >
              <ListItemText primary={o.label} />
            </MenuItem>
          );
        });
      });
    }

    if (options) {
      options.forEach((o) => {
        flattenedOptions.push(
          <MenuItem
            value={o.key}
            onClick={(e) => handleSelect(e, o)}
            data-value={o.key}
            disabled={o.disabled}
          >
            <ListItemText primary={o.label} />
          </MenuItem>
        );
      });
    }

    return Children.toArray(flattenedOptions);
  }, [handleSelect, groupedOptions, options]);

  const triggerStyles = useStyles(styles, { triggerVariant });

  const menuId = `${id}-menu`;
  const triggerId = `${id}-trigger`;

  return (
    <Box className="SimpleMenu-root">
      <Button
        ref={anchorRef}
        id={triggerId}
        aria-controls={open ? menuId : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<IconChevronDown />}
        sx={triggerStyles}
        variant={triggerVariant === 'button' ? 'contained' : 'text'}
      >
        {triggerTitle}
      </Button>

      <Menu
        id={menuId}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': triggerId,
        }}
        {...getMenuPositionProps(position)}
      >
        {flattenedOptions}
      </Menu>
    </Box>
  );
};

function styles(theme: Theme, options?: { triggerVariant?: string }) {
  const { palette, spacing } = theme;

  if (options?.triggerVariant === 'chip') {
    return {
      backgroundColor: palette.grey[200],
      borderRadius: 999,
      color: palette.text.primary,
      padding: spacing(1, 3),
    };
  }

  return {};
}
