import produce from 'immer';
import { debounce as _debounce } from 'lodash-es';
import { useCallback, useRef, useState } from 'react';

import {
  GoogleprotobufAny,
  SpecUserTelemetryEvent,
  V1UserTelemetry,
} from '@endorlabs/api_client';
import {
  useCreateUserTelemetry,
  useFindManyUserTelemetries,
  useUpdateUserTelemetry,
} from '@endorlabs/queries';
import { useLatestCallback } from '@endorlabs/ui-common';

import { EventIdentifiers } from './types';

const USER_TELEMETRY_EVENT_BATCH_WAIT = 3_000;
const USER_TELEMETRY_EVENT_BATCH_MAX_WAIT = 30_000;

// Events are mapped to the API type, including handling for the
// google.protobuf.Any field
type UserTelemetryEvent = Omit<SpecUserTelemetryEvent, 'properties'> & {
  properties?: Record<string, string>;
};

/**
 * Manages queuing User Telemetry events, and the creation and updating of the
 * persisted User Event store.
 */
export const useUserTelemetryEventQueue = ({
  eventIdentifiers,
}: {
  eventIdentifiers?: EventIdentifiers;
}) => {
  const [trackedNamespaces, setTrackedNamespaces] = useState<string[]>([]);

  const qUserTelemetries = useFindManyUserTelemetries(
    trackedNamespaces,
    {
      filter: `spec.user_id==${eventIdentifiers?.userId}`,
      page_size: 1,
      traverse: false,
    },
    {
      enabled: !!eventIdentifiers?.userId,
    }
  );
  const qCreateUserTelementry = useCreateUserTelemetry();
  const qUpdateUserTelementry = useUpdateUserTelemetry();

  const userEventQueue = useRef<Map<string, UserTelemetryEvent[]>>(new Map());

  const sendBatch = useLatestCallback(
    _debounce(
      () => {
        const isReady = !!eventIdentifiers && qUserTelemetries.isSuccess;
        if (!isReady) {
          // trigger to attempt send again later
          sendBatch();
          return;
        }

        const { userId, sessionId } = eventIdentifiers;

        // Get next events to send
        const next = userEventQueue.current.entries().next();

        // If no events exist to send, exit
        if (next.done) return;
        const [namespace, events] = next.value;

        // Find existing user telemetry object
        const existing = qUserTelemetries.data.find(
          (ut) => ut.tenant_meta?.namespace === namespace
        );
        const eventStore = existing?.spec.event_store ?? {};

        // Reduce the tracked events, and merge with any existing user events
        const nextEventStore = produce(eventStore, (draft) => {
          for (const event of events) {
            const key = event.key;

            // NOTE: not overwriting a previous event marked as COMPLETED
            if (draft[key] && draft[key].value === 'COMPLETED') continue;

            // HACK: wrap event properties for `google.protobuf.Any`
            // Without the `@type` wrapper here, the object is not deserialized
            // by the grpcgateway, and is not persisted to the Mongo DB or the
            // Audit Logs.
            const properties = event.properties
              ? ({
                  '@type': 'type.googleapis.com/google.protobuf.Struct',
                  value: event.properties,
                } satisfies GoogleprotobufAny)
              : undefined;

            draft[key] = { ...event, properties };
          }
        });

        // Create or Update User Telemetry, only if the user events have changed
        if (eventStore !== nextEventStore) {
          const resource: V1UserTelemetry = {
            uuid: existing?.uuid,
            meta: { name: userId },
            spec: {
              user_id: userId,
              session_id: sessionId,
              event_store: nextEventStore,
            },
          };

          // Create or update the user telemetry
          // NOTE: failed mutations are not currently re-tried
          if (existing) {
            qUpdateUserTelementry.mutate({
              namespace,
              mask: 'spec.event_store,spec.session_id',
              resource,
            });
          } else {
            qCreateUserTelementry.mutate({ namespace, resource });
          }
        }

        // Remove the pending user events
        userEventQueue.current.delete(namespace);
        if (userEventQueue.current.size) {
          sendBatch();
        }
      },
      USER_TELEMETRY_EVENT_BATCH_WAIT,
      { maxWait: USER_TELEMETRY_EVENT_BATCH_MAX_WAIT }
    )
  );

  const enqueue = useCallback(
    (namespace: string, events: UserTelemetryEvent[]) => {
      const current = userEventQueue.current.get(namespace) ?? [];
      userEventQueue.current.set(namespace, current.concat(events));
      sendBatch();

      // track the active namespaces
      setTrackedNamespaces((state) => {
        if (state.includes(namespace)) return state;
        return [...state, namespace];
      });
    },
    [sendBatch]
  );

  return { enqueue };
};
