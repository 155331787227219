import { Box, Switch } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PolicyPolicyType, SpecFindingLevel } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  RelativeTimeDisplay,
} from '@endorlabs/ui-common';

import { messages } from '../locales';

export type FindingPoliciesTableRowActionHandler = (args: {
  namespace: string;
  uuid: string;
}) => void;

export interface FindingPoliciesTableProps
  extends Omit<DataTableProps<FindingPoliciesTableRow>, 'columns'> {
  onDelete?: FindingPoliciesTableRowActionHandler;
  onDisable?: FindingPoliciesTableRowActionHandler;
  onEdit?: FindingPoliciesTableRowActionHandler;
}

const actionHandler =
  (
    row: Row<FindingPoliciesTableRow>,
    action: FindingPoliciesTableRowActionHandler
  ) =>
  (_: SyntheticEvent) => {
    if (!row.original) return;

    const { namespace, uuid } = row.original;
    return action({ namespace, uuid });
  };

export const FindingPoliciesTable = ({
  onDelete,
  onDisable,
  onEdit,
  ...props
}: FindingPoliciesTableProps) => {
  const { formatMessage: fm } = useIntl();

  const columns = useMemo(
    () =>
      buildPoliciesTableColDefs({
        labelSwitch: fm(messages.labelSwitch),
        onDelete,
        onDisable,
        onEdit,
      }),
    [fm, onDelete, onDisable, onEdit]
  );

  return <DataTable {...props} columns={columns} />;
};

export interface FindingPoliciesTableRow {
  description?: string;
  disable?: boolean;
  findingLevel?: SpecFindingLevel;
  labels?: string[];
  lastTriggered?: string;
  name: string;
  namespace: string;
  policyType?: string;
  severity: string;
  triggerCount: number;
  uuid: string;
}

/**
 * Build the Table columns
 * Include the Delete column when `onDelete` handler is provided
 */
const buildPoliciesTableColDefs = ({
  labelSwitch,
  onDelete,
  onDisable,
  onEdit,
}: {
  labelSwitch?: string;
  onDelete?: FindingPoliciesTableRowActionHandler;
  onDisable?: FindingPoliciesTableRowActionHandler;
  onEdit?: FindingPoliciesTableRowActionHandler;
}) => {
  const columns: DataTableColumnDef<FindingPoliciesTableRow>[] = [
    {
      accessorKey: 'name',
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
      header: () => <FM defaultMessage="Name" />,
    },
    {
      accessorKey: 'findingLevel',
      colType: ColTypes.SEVERITY,
      header: () => <FM defaultMessage="Severity" />,
    },
    {
      accessorKey: 'policyType',
      cell: (t) => <FM {...messages[t.getValue() as keyof typeof messages]} />,
      header: () => <FM defaultMessage="Defined By" />,
      minSize: 60,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'triggerCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Times Triggered" />,
    },
    {
      accessorKey: 'lastTriggered',
      cell: (t) => (
        <RelativeTimeDisplay
          nilDisplayProps={{ variant: 'dash' }}
          value={t.getValue()}
        />
      ),
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Triggered" />,
    },
    {
      accessorKey: 'labels',
      colType: ColTypes.TAGS,
    },
  ];

  if (onDisable) {
    columns.unshift({
      id: 'enabled',
      cell: ({ row }) => {
        return (
          <Switch
            aria-label={labelSwitch}
            checked={!row.original?.disable}
            onChange={actionHandler(row, onDisable)}
            size="small"
          />
        );
      },
      colType: ColTypes.SWITCH,
    });
  }

  if (onDelete || onEdit) {
    columns.push({
      id: 'actions',
      header: '',
      cell: ({ row }) => {
        const actions: DataTableActionDropdownItem[] = [];
        if (onEdit) {
          actions.push({
            disabled: !shouldAllowEdit(row),
            label: <FM defaultMessage="Edit Policy" />,
            onClick: actionHandler(row, onEdit),
          });
        }
        if (onDelete) {
          actions.push({
            disabled: !shouldAllowDelete(row),
            isDestructive: true,
            label: <FM defaultMessage="Delete Policy" />,
            onClick: actionHandler(row, onDelete),
          });
        }

        return (
          actions.length > 0 && (
            <Box display="flex" justifyContent="flex-end">
              <DataTableActionDropdown items={actions} />
            </Box>
          )
        );
      },
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

const shouldAllowEdit = (row: Row<FindingPoliciesTableRow>) => {
  const disallowedTypes = [PolicyPolicyType.MlFinding];
  return (
    row.original &&
    row.original.policyType &&
    !disallowedTypes.includes(row.original.policyType as PolicyPolicyType)
  );
};

const shouldAllowDelete = (row: Row<FindingPoliciesTableRow>) => {
  const disallowedTypes = [
    PolicyPolicyType.MlFinding,
    PolicyPolicyType.SystemFinding,
  ];
  return (
    row.original &&
    row.original.policyType &&
    !disallowedTypes.includes(row.original.policyType as PolicyPolicyType)
  );
};
