import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { PropsWithChildren, ReactNode, SyntheticEvent } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from '../../hooks';
import { IconXCircle } from '../../themes';

export type SimpleDialogProps = PropsWithChildren<{
  actions?: ReactNode;
  descriptionText?: ReactNode;
  disableCloseOnOutsideClick?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  onClose?: () => void;
  open: boolean;
  titleText: ReactNode;
}>;

export const SimpleDialog = ({
  actions,
  children,
  descriptionText,
  disableCloseOnOutsideClick,
  maxWidth = 'md',
  onClose,
  open,
  titleText,
}: SimpleDialogProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);
  const { formatMessage: fm } = useIntl();

  const handleClose = (
    event: SyntheticEvent,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (
      !disableCloseOnOutsideClick ||
      (disableCloseOnOutsideClick && reason !== 'backdropClick')
    ) {
      onClose?.();
    }
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      scroll="body"
      sx={sx}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={space.xs} flexGrow={1}>
            <Typography component="h2" id="dialog-title" variant="inherit">
              {titleText}
            </Typography>

            {descriptionText && (
              <Typography
                color="text.secondary"
                id="dialog-description"
                maxWidth="92%"
                variant="body2"
              >
                {descriptionText}
              </Typography>
            )}
          </Stack>

          {onClose && (
            <IconButton
              aria-label={fm({ defaultMessage: 'Close' })}
              className="SimpleDialog-close"
              onClick={onClose}
            >
              <IconXCircle fontSize="medium" />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>

      {!!actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};

function styles({ space }: Theme) {
  return {
    '& .MuiDialog-paper': {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '85vh',
      overflowY: 'hidden',

      '& .MuiDialogTitle-root': {
        marginBottom: 0,
        paddingBottom: space.sm,
      },

      '& .MuiDialogContent-root': {
        marginRight: space.sm,
        overflowY: 'auto',
      },

      '& .SimpleDialog-close': {
        alignSelf: 'flex-start',
      },
    },
  };
}
