import { useEffect, useRef } from 'react';

export function useLatestCallback<T extends (...args: any[]) => any>(
  callback: T
) {
  const stableFnRef = useRef<(...args: Parameters<T>) => ReturnType<T>>();
  const cbRef = useRef<T>(callback);

  if (!stableFnRef.current) {
    stableFnRef.current = (...args) => cbRef.current(...args);
  }

  useEffect(() => {
    cbRef.current = callback;
  });

  return stableFnRef.current;
}
