import { groupBy } from 'lodash-es';
import { matchSorter } from 'match-sorter';

import {
  FilterableResource,
  ResourceFilterDefinition,
} from '@endorlabs/filters';

const isValidFilterKey = (value: string) => {
  return value.length > 1 && /[^\s]/.test(value);
};

const booleanOptions = ['true', 'false'];

export const getFilteredOptions = (
  resourceKinds: FilterableResource[],
  options: ResourceFilterDefinition[],
  searchValue: string
): ResourceFilterDefinition[] => {
  if (searchValue.indexOf(':') > 0) {
    const [kind, key] = searchValue.split(':');

    const matchingKind = resourceKinds.find(
      (rk) => rk.toLowerCase() === kind.toLowerCase()
    );

    if (matchingKind) {
      resourceKinds = [matchingKind as FilterableResource];
      searchValue = key;
    }
  }

  const sorted = matchSorter(options, searchValue, {
    // use the default sort order as tie breaker
    baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    keys: ['name', 'key'],
  });

  const grouped = groupBy(sorted, 'kind');

  const results: ResourceFilterDefinition[] = [];
  for (const kind of resourceKinds) {
    if (grouped[kind]?.length) {
      const optionsAdded = grouped[kind]?.map((g) => {
        if (g.type === 'boolean') {
          return { ...g, options: booleanOptions };
        }
        return g;
      });
      results.push(...optionsAdded);
    }

    // TODO: allow user-added filters
    // if (isValidFilterKey(searchValue)) {
    //   results.push({
    //     kind: kind as FilterableResource,
    //     // name: '', // custom definition, has no name
    //     key: searchValue,
    //     type: 'string',
    //   } as FilterDefinition);
    // }
  }
  return results;
};
