import { Box, Card, CardContent, Grid, SvgIconProps } from '@mui/material';
import { MakeGenerics, useNavigate } from '@tanstack/react-location';
import { minutesToMilliseconds } from 'date-fns';
import { get as _get, omit as _omit } from 'lodash-es';
import { JSXElementConstructor, useCallback, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import {
  NotificationTargetActionActionType,
  PolicyPolicyType,
  V1CustomTemplateType,
} from '@endorlabs/api_client';
import {
  buildQueryCall,
  isQueryError,
  NotificationTargetResource,
  NotificationTargetResourceList,
  sortParamBuilders,
  useCountNotificationTargets,
  useFeatureFlags,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  ConfirmationDialog,
  CustomNotificationTemplateTypeKeys,
  EmptyState,
  IconMail,
  NotificationTargetActionTemplateKeyMap,
  NotificationTargetActionTypeKey,
  NotificationTargetActionTypeKeys,
  useAppNotify,
  useDataTablePaginator,
  useDeleteNotificationTargetConfirmationDialog,
  useNotificationTargetUpsertDialog,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useNotificationTargetTemplateData } from '../../domains/Integrations';
import { CustomTemplateRestoreConfirmationDialog } from '../../domains/Integrations/components/CustomTemplateRestoreConfirmationDialog';
import { useAuthInfo } from '../../providers';
import {
  getCustomTemplatePath,
  getIntegrationsRootPath,
  useFullMatch,
} from '../../routes';
import {
  NotificationTargetsTable,
  NotificationTargetsTableRow,
  OptionalNotificationTargetCols,
} from './NotificationTargetsTable';

const actionTypeTitleMessages =
  defineMessages<NotificationTargetActionActionType>({
    [NotificationTargetActionActionType.Email]: {
      defaultMessage: 'Email Notification Integrations',
    },
    [NotificationTargetActionActionType.Jira]: {
      defaultMessage: 'Jira Notification Integrations',
    },
    [NotificationTargetActionActionType.Vanta]: {
      defaultMessage: 'Vanta Notification Integrations',
    },
    [NotificationTargetActionActionType.Webhook]: {
      defaultMessage: 'Webhook Notification Integrations',
    },
    [NotificationTargetActionActionType.Slack]: {
      defaultMessage: 'Slack Notification Integrations',
    },
    [NotificationTargetActionActionType.GithubPr]: {
      defaultMessage: 'GitHub PR Remediation',
    },
    [NotificationTargetActionActionType.Unspecified]: {
      defaultMessage: 'Notification Integrations',
    },
  });

const ActionTypeIcons: Partial<
  Record<
    NotificationTargetActionActionType,
    JSXElementConstructor<SvgIconProps>
  >
> = {
  [NotificationTargetActionActionType.Email]: IconMail,
};

type NotificationTargetSettingsLocationGenerics = MakeGenerics<{
  Params: {
    actionType: NotificationTargetActionTypeKey;
  };
}>;

export const NotificationTargetSettingsPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { actionType: actionTypeKey },
  } = useFullMatch<NotificationTargetSettingsLocationGenerics>();
  const addNotification = useAppNotify();
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();

  const {
    ENABLE_CUSTOM_NOTIFICATION_TEMPLATES: isCustomNotificationTemplatesEnabled,
  } = useFeatureFlags();

  const actionType: NotificationTargetActionActionType | undefined =
    NotificationTargetActionTypeKeys[actionTypeKey];
  const ActionTypeIcon = actionType ? ActionTypeIcons[actionType] : undefined;
  const titleMessage =
    actionTypeTitleMessages[
      actionType ?? NotificationTargetActionActionType.Unspecified
    ];

  const filterExpressions = [`spec.action.action_type==${actionType}`];
  const qCountNotificationTargets = useCountNotificationTargets(
    tenantName,
    {
      filter: filterExpressions.join(' and '),
    },
    { enabled: !!actionType }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountNotificationTargets.data?.count,
  });

  const qNotificationTargetsQuery = buildQueryCall('NotificationTarget', {
    filter: filterExpressions.join(' and '),
    mask: [
      'meta.name',
      'meta.description',
      'meta.tags',
      'propagate',
      'spec',
      'tenant_meta.namespace',
      'uuid',
    ].join(','),
    sort: sortParamBuilders.ascendingBy('meta.create_time'),
    ...paginator.getListParameters(),
  })
    .addReference(
      'Policy',
      {
        filter: `spec.policy_type==${PolicyPolicyType.Notification}`,
        count: true,
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.notification.notification_target_uuids',
        return_as: 'PolicyCount',
      }
    )
    .useBuiltQuery(tenantName, {
      enabled: !!actionType,
      staleTime: minutesToMilliseconds(5),
    });

  const { templateType, templateTypeKey, hasCustomTemplateSupport } =
    useMemo(() => {
      const templateTypeKey =
        NotificationTargetActionTemplateKeyMap[actionTypeKey];
      const templateType = templateTypeKey
        ? CustomNotificationTemplateTypeKeys[templateTypeKey]
        : V1CustomTemplateType.Unspecified;
      const hasCustomTemplateSupport =
        templateType !== V1CustomTemplateType.Unspecified &&
        isCustomNotificationTemplatesEnabled;
      return {
        templateType,
        templateTypeKey,
        hasCustomTemplateSupport,
      };
    }, [actionTypeKey, isCustomNotificationTemplatesEnabled]);

  const { restoreDefaultTemplate } = useNotificationTargetTemplateData({
    templateType,
    tenantName,
  });

  const [templateRestoreDialogOpen, setTemplateRestoreDialogOpen] =
    useState(false);

  const [currentRow, setCurrentRow] = useState<NotificationTargetsTableRow>();

  const notificationTargetTableRows = useMemo(() => {
    const queryResponse = qNotificationTargetsQuery.data?.spec
      ?.query_response as NotificationTargetResourceList;
    const notificationTargets = queryResponse?.list?.objects ?? [];

    const notificationTargetTableRows: NotificationTargetsTableRow[] =
      notificationTargets.map((target) => {
        const policyCount =
          target.meta.references?.PolicyCount?.count_response?.count ?? 0;

        // build action specific columns
        const jiraLabels = target.spec.action.jira_config?.labels ?? [];
        const jiraURL = target.spec.action.jira_config?.url;
        const jiraProjectKey = target.spec.action.jira_config?.project_key;
        const jiraProject =
          // NOTE: Trailing slash is required in provided JIRA URL
          jiraURL && jiraProjectKey
            ? `${jiraURL}projects/${jiraProjectKey}`
            : undefined;

        const webhookURL = target.spec.action?.webhook_config?.url;
        const hasCustomTemplate = !!target.spec.custom_template;
        const notificationTarget = _omit(target, 'meta.references');

        return {
          uuid: notificationTarget.uuid,
          name: notificationTarget.meta.name,
          namespace: notificationTarget.tenant_meta.namespace,
          description: notificationTarget.meta.description,
          hasCustomTemplate,
          notificationTarget,
          policyCount,
          jiraLabels,
          jiraProject,
          webhookURL,
        };
      });

    return notificationTargetTableRows;
  }, [qNotificationTargetsQuery.data]);

  const {
    Dialog: NotificationTargetUpsertDialog,
    getNotificationTargetUpsertDialogProps,
    openNotificationTargetUpsertDialog,
  } = useNotificationTargetUpsertDialog({
    onSuccess: (response: unknown, payload: unknown) => {
      const notificationTargetUuid = _get(payload, ['resource', 'uuid']);
      const notificationTargetName = _get(payload, [
        'resource',
        'meta',
        'name',
      ]);

      const message = notificationTargetUuid
        ? fm(
            {
              defaultMessage: 'Successfully updated the "{name}" integration',
            },
            { name: notificationTargetName }
          )
        : fm(
            { defaultMessage: 'Successfully created the "{name}" integration' },
            { name: notificationTargetName }
          );
      addNotification({ message });

      // refetch query to populate table data when notification targets are created or updated
      qNotificationTargetsQuery.refetch();
    },
  });
  const deleteNotificationTargetConfirmationDialog =
    useDeleteNotificationTargetConfirmationDialog({
      onSuccess: (response: unknown, payload: unknown) => {
        // integration name is not passed in the delete request, and not known here
        const message = fm({
          defaultMessage: 'Successfully deleted the integration',
        });
        addNotification({ message });

        // refetch query to populate table data when an notification target is deleted
        qNotificationTargetsQuery.refetch();
      },
      onError(error: unknown, payload: unknown) {
        let message = fm({
          defaultMessage:
            'Failed to delete the integration. An unknown error occurred.',
        });
        if (isQueryError(error)) {
          if (error.response.status === 403) {
            message = fm({
              defaultMessage:
                'Failed to delete the integration. You are not authorized to perform this action.',
            });
          }
          if (error.response.status === 424) {
            message = fm(
              {
                defaultMessage: '{errorMsg}',
              },
              {
                errorMsg:
                  error.response.data?.message ??
                  'Cannot delete notification target because it is associated with policies',
              }
            );
          }
        }

        addNotification({ message, severity: 'error' });
      },
    });

  const handleCreateNotificationTarget = useCallback(() => {
    openNotificationTargetUpsertDialog({ namespace: tenantName, actionType });
  }, [actionType, openNotificationTargetUpsertDialog, tenantName]);

  const handleDeleteNotificationTarget = useCallback(
    (row: NotificationTargetsTableRow) => {
      const notificationTarget =
        qNotificationTargetsQuery.data?.spec?.query_response?.list?.objects?.find(
          (at: NotificationTargetResource) => at.uuid === row.uuid
        );

      // TODO: handle edge case
      if (notificationTarget) {
        deleteNotificationTargetConfirmationDialog.openDialog(
          notificationTarget
        );
      }
    },
    [deleteNotificationTargetConfirmationDialog, qNotificationTargetsQuery.data]
  );

  const handleEditNotificationTarget = useCallback(
    (row: NotificationTargetsTableRow) => {
      const notificationTarget =
        qNotificationTargetsQuery.data?.spec?.query_response?.list?.objects?.find(
          (at: NotificationTargetResource) => at.uuid === row.uuid
        );

      // TODO: handle edge case
      if (notificationTarget) {
        openNotificationTargetUpsertDialog({
          namespace: tenantName,
          actionType,
          notificationTarget,
        });
      }
    },
    [
      actionType,
      openNotificationTargetUpsertDialog,
      qNotificationTargetsQuery.data,
      tenantName,
    ]
  );

  const setIncludeColumns = (): OptionalNotificationTargetCols => {
    let includeColumns: OptionalNotificationTargetCols | undefined = undefined;
    if (actionType === NotificationTargetActionActionType.Jira) {
      includeColumns = ['jiraLabels', 'jiraProject'];
    } else if (actionType === NotificationTargetActionActionType.Webhook) {
      includeColumns = ['webhookURL'];
    }
    if (hasCustomTemplateSupport) {
      includeColumns = [...(includeColumns ?? []), 'hasCustomTemplate'];
    }
    return includeColumns;
  };

  const handleTemplateEdit = (row: NotificationTargetsTableRow) => {
    if (templateTypeKey) {
      navigate({
        to: getCustomTemplatePath({
          tenantName,
          section: 'custom-notification-template',
          key: templateTypeKey,
          uuid: row.uuid,
        }),
      });
    }
  };

  const handleTemplateRestore = (row: NotificationTargetsTableRow) => {
    if (hasCustomTemplateSupport) {
      setCurrentRow(row);
      setTemplateRestoreDialogOpen(true);
    }
  };

  const isLoading = qNotificationTargetsQuery.isLoading;
  const isEmptyState = !isLoading && notificationTargetTableRows?.length === 0;

  return (
    <>
      <Grid container direction="column" spacing={6}>
        <Grid item>
          <PageHeader
            action={
              !isLoading &&
              !isEmptyState && (
                <Box display="flex" justifyContent="flex-end">
                  <ButtonPrimary onClick={handleCreateNotificationTarget}>
                    <FM defaultMessage="Add Notification Integration" />
                  </ButtonPrimary>
                </Box>
              )
            }
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            Icon={ActionTypeIcon}
            metadata={{ summary: [] }}
            title={<FM {...titleMessage} />}
          />
        </Grid>

        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not configured any notification integrations." />
              }
            >
              <ButtonPrimary onClick={handleCreateNotificationTarget}>
                <FM defaultMessage="Add Notification Integration" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && (
          <Grid item>
            <Card>
              <CardContent>
                <NotificationTargetsTable
                  data={notificationTargetTableRows}
                  isLoading={isLoading}
                  includeColumns={setIncludeColumns()}
                  onDelete={handleDeleteNotificationTarget}
                  onEdit={handleEditNotificationTarget}
                  onTemplateEdit={
                    hasCustomTemplateSupport ? handleTemplateEdit : undefined
                  }
                  onTemplateRestore={
                    hasCustomTemplateSupport ? handleTemplateRestore : undefined
                  }
                  paginator={paginator}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>

      {/* DIALOGS */}
      <NotificationTargetUpsertDialog
        {...getNotificationTargetUpsertDialogProps()}
      />
      <ConfirmationDialog
        {...deleteNotificationTargetConfirmationDialog.getConfirmationDialogProps()}
      />
      <CustomTemplateRestoreConfirmationDialog
        open={templateRestoreDialogOpen}
        onCancel={() => setTemplateRestoreDialogOpen(false)}
        onConfirm={() => {
          if (currentRow) {
            restoreDefaultTemplate(currentRow.notificationTarget);
            setTemplateRestoreDialogOpen(false);
          }
        }}
      />
    </>
  );
};
