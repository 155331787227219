import { Collapse, TableCell, TableRow, Theme } from '@mui/material';
import {
  Cell,
  ColumnOrderState,
  flexRender,
  Row,
  VisibilityState,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { memo, MouseEvent, ReactNode, useCallback, useMemo } from 'react';

import { IconChevronDown, useStyles } from '@endorlabs/ui-common';

import { DataTableColumnDef, DataTableRowData } from './types';
import { getColWidthSx } from './utils';

export interface DataTableRowProps<T extends DataTableRowData> {
  columnOrder?: ColumnOrderState;
  columnVisibility?: VisibilityState;
  enableExpanding: boolean;
  hidden?: boolean;
  isColumnResizing: boolean;
  isHighlighted?: boolean;
  onRowClick?: (data: T, row: Row<T>, evt: MouseEvent) => void;
  renderExpandedRow?: (props: { row: Row<unknown> }) => ReactNode;
  row: Row<T>;
  rowId?: string;
}

const DataTableRowCmp = <T extends DataTableRowData>({
  columnOrder,
  columnVisibility,
  enableExpanding,
  hidden = false,
  isHighlighted = false,
  onRowClick,
  renderExpandedRow,
  row,
  rowId,
}: DataTableRowProps<T>) => {
  const sx = useStyles(styles);

  const cells = useMemo(
    () => row.getVisibleCells(),
    /* eslint-disable */
    // recompute cells on columnOrder and columnVisibily change
    [columnOrder, columnVisibility, row]
    /* eslint-enable */
  );

  const columnsSx = useMemo(
    () => cells.map((cell) => getColWidthSx(cell.column)),
    [cells]
  );

  const rowClickHandler = useCallback(
    (row: Row<T>) => {
      return (event: MouseEvent<HTMLTableCellElement>) => {
        // Allow user to select cell text without triggering click handler
        const textSelection = window.getSelection()?.toString();
        const rowData = row.original;

        if (onRowClick && rowData && !textSelection) {
          onRowClick(rowData, row, event);
        }
      };
    },
    [onRowClick]
  );

  const classNames = clsx({
    'DataTableRow-root': true,
    'DataTableRow-isHidden': hidden,
    'DataTableRow-isHighlighted': isHighlighted,
  });

  /**
   * Handles click event for table cells
   * If cell click handler is not defined, row handler will be fired
   */
  const cellClickHandler = useCallback(
    (
      columnDef: DataTableColumnDef<T>,
      cell: Cell<unknown, unknown>,
      row: Row<T>
    ) => {
      return (event: MouseEvent<HTMLTableCellElement>) => {
        const { onClick } = columnDef;
        if (onClick) {
          onClick(columnDef, cell, row);
        } else {
          rowClickHandler(row)(event);
        }
      };
    },
    [rowClickHandler]
  );

  return (
    <>
      <TableRow
        className={classNames}
        hover
        data-testid={rowId ?? row.id}
        selected={row.getIsSelected()}
        sx={sx}
      >
        {/* Add additional row cell placeholder when table is expandable */}
        {enableExpanding && (
          <TableCell>
            <IconChevronDown
              sx={[
                {
                  transition: 'transform 300ms',
                  transform: 'rotate(0)',
                },
                row.getIsExpanded() && {
                  transform: 'rotate(-180deg)',
                },
              ]}
            />
          </TableCell>
        )}

        {cells.map((cell, i) => {
          const { columnDef } = cell.column;

          return (
            <TableCell
              key={cell.id}
              sx={columnsSx[i]}
              data-testid={cell.id}
              // @ts-expect-error - subtype constraint issue
              // Expected:DataTableColumnDef, Available: ColumnDef
              onClick={cellClickHandler(columnDef, cell, row)}
            >
              {flexRender(columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>

      {/* Handle Row Expansion */}
      {!!renderExpandedRow && (
        <TableRow>
          <TableCell
            colSpan={cells.length + 1}
            sx={{
              backgroundColor: ({ palette }) => palette.grey[100],
              paddingBottom: 0,
              paddingTop: 0,
              '&:not(:has(> .MuiCollapse-root))': {
                // remove the border when the row is collapsed
                borderBottom: 'none',
              },
            }}
          >
            <Collapse in={row.getIsExpanded()} timeout="auto" unmountOnExit>
              {/*
                For each expanded row, call the render method
                passed in from the parent component
              */}
              {renderExpandedRow({ row: row as Row<unknown> })}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

// Memoize the component to prevent re-render during column resizing
// Taken from: https://github.com/TanStack/table/issues/1766#issuecomment-1054703281
export const DataTableRow = memo(
  DataTableRowCmp,
  (prevProps, nextProps) => nextProps.isColumnResizing
);

function styles({ palette, spacing }: Theme) {
  return {
    '&.DataTableRow-isHidden': { display: 'none' },

    '&.DataTableRow-isHighlighted': {
      backgroundColor: palette.grey[100],

      '& .MuiTableCell-root:first-of-type': {
        borderLeft: `${spacing(1)} solid ${palette.success.light}`,
      },
    },
  };
}
