import { Box, Link as MUILink, Stack } from '@mui/material';
import { forwardRef } from 'react';

import { IconExternalLink } from '@endorlabs/ui-common';

import { LinkProps } from '../Link';

/**
 * Allows absolute links only.
 */
type ExternalLinkProps = Omit<LinkProps, 'target'>;

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(
  ({ to, ...props }: LinkProps, ref) => (
    <MUILink
      {...props}
      ref={ref}
      href={to as string}
      // ensure the link content does not exceed parent width and overflows
      maxWidth="100%"
      overflow="hidden"
      target="_blank"
      /**
       * ensure `window.opener` is not passed with target="_blank"
       * @link https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
       */
      rel="noopener noreferrer nofollow"
    >
      <Stack
        alignItems="center"
        component="span"
        direction="row"
        display="inline-flex"
        flexWrap="nowrap"
        gap={1}
        maxWidth="100%"
      >
        <Box
          // Add ellipsis if text content overflows
          component="span"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
            width: '100%',
          }}
        >
          {props.children}
        </Box>

        <IconExternalLink fontSize="inherit" />
      </Stack>
    </MUILink>
  )
);

ExternalLink.displayName = 'ExternalLink';
