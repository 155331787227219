import {
  CircularProgress,
  IconButton,
  Palette,
  Stack,
  SvgIconProps,
  SxProps,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material';
import { JSXElementConstructor, MouseEvent, ReactNode } from 'react';

import { Status } from '../../constants';
import {
  IconCircleFilled,
  IconSemiCircleFilled,
  IconSquareFilled,
  IconTriangleFilled,
} from '../../themes';

const ColorForStatus: Record<Status, keyof Palette['status']> = {
  [Status.Failure]: 'failure',
  [Status.PartialSuccess]: 'partialSuccess',
  [Status.Pending]: 'pending',
  [Status.Running]: 'pending',
  [Status.Success]: 'success',
};

const IconForStatus: Record<Status, JSXElementConstructor<SvgIconProps>> = {
  [Status.Failure]: IconTriangleFilled,
  [Status.PartialSuccess]: IconSemiCircleFilled,
  [Status.Pending]: IconSquareFilled,
  [Status.Running]: IconSquareFilled,
  [Status.Success]: IconCircleFilled,
};

export type StatusIndicatorProps = {
  onClick?: (event: MouseEvent) => void;
  status: Status;
  tooltipNode?: ReactNode;
};

export const StatusIndicator = ({
  onClick,
  status,
  tooltipNode,
}: StatusIndicatorProps) => {
  const theme = useTheme();
  const sx = styles(theme, { status });

  const Icon = IconForStatus[status];
  let content =
    status === Status.Running ? (
      <CircularProgress
        color="inherit"
        size={16}
        sx={{ color: theme.palette.text.secondary }}
      />
    ) : (
      <Icon sx={sx.icon} />
    );

  if (onClick) {
    content = (
      <IconButton onClick={onClick} sx={sx.wrapper}>
        {content}
      </IconButton>
    );
  } else {
    content = (
      <Stack alignItems="center" sx={sx.wrapper}>
        {content}
      </Stack>
    );
  }

  if (tooltipNode) {
    return (
      <Tooltip title={tooltipNode} placement="bottom-start">
        {content}
      </Tooltip>
    );
  }

  return content;
};

function styles(
  { palette }: Theme,
  options: { status: Status }
): Record<string, SxProps<Theme>> {
  const statusColor = ColorForStatus[options.status];
  const iconColor = statusColor ? palette.status[statusColor] : undefined;

  return {
    icon: {
      color: iconColor,
      '&.MuiSvgIcon-root': {
        // add drop shadow for svg shape, except for pending status
        filter:
          status === Status.Pending
            ? undefined
            : 'drop-shadow( 0 0 2px hsla(0, 0%, 0%, 0.15) )',
      },
    },
    wrapper: {
      // Consistent display for `IconButton` or `Box`
      display: 'inline-flex',
    },
  };
}
