import { Box, Fade, IconButton, IconButtonProps } from '@mui/material';
import clsx from 'clsx';
import { SyntheticEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useCopyToClipboard } from '../../hooks';
import { IconCheck } from '../../themes';
import { IconCopy } from '../../themes';

interface CopyToClipboardButtonProps extends IconButtonProps {
  getValue?: () => string;
  stopPropagation?: boolean;
  value: string;
}

export const CopyToClipboardButton = ({
  size = 'small',
  getValue,
  stopPropagation = true,
  value,
  ...props
}: CopyToClipboardButtonProps) => {
  const [isCopied, copyText, setIsCopied] = useCopyToClipboard(value);
  const { formatMessage: fm } = useIntl();

  const handleCopy = (evt: SyntheticEvent) => {
    if (stopPropagation) evt.stopPropagation();

    const dynamicValue = getValue ? getValue() : undefined;
    copyText(dynamicValue);
  };

  useEffect(() => {
    if (!isCopied) return;

    // NOTE: clearing timer in effect to prevent state change after unmount
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 1800);

    return () => clearTimeout(timer);
  }, [isCopied, setIsCopied]);

  const cn = clsx(isCopied && 'success');

  return (
    <IconButton
      aria-label={fm({ defaultMessage: 'Copy to Clipboard' })}
      className={cn}
      onClick={handleCopy}
      sx={{ position: 'relative' }}
      {...props}
    >
      <IconCopy fontSize={size} />
      <Fade in={isCopied}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'success.main',
            border: '2px solid white',
            borderRadius: '50%',
            color: 'common.white',
            display: 'flex',
            fontSize: size === 'xsmall' ? '6px' : '12px',
            height: '1.6em',
            justifyContent: 'center',
            padding: '6px',
            position: 'absolute',
            right: '-0.2em',
            top: '0.3em',
            width: '1.6em',
          }}
        >
          <IconCheck
            aria-label={fm({ defaultMessage: 'Copy successful' })}
            fontSize="inherit"
          />
        </Box>
      </Fade>
    </IconButton>
  );
};
