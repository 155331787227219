import { Checkbox, Chip, Stack, Typography, useTheme } from '@mui/material';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage as FM, FormattedMessage } from 'react-intl';

import { V1FindingTags } from '@endorlabs/api_client';
import { FindingAttributeGroup } from '@endorlabs/endor-core/Finding';
import { RowStack } from '@endorlabs/ui-common';

import { FilterDropdown } from '../../filters/components/FilterDropdown';

type FieldToggleOption = {
  header: ReactNode;
  isExclusive: boolean;
  values: { label: ReactNode; value: V1FindingTags }[];
  type: FindingAttributeGroup;
};

const FIELD_TOGGLE_GROUPS: FieldToggleOption[] = [
  {
    header: <FormattedMessage defaultMessage="Fix Available" />,
    isExclusive: true,
    values: [
      {
        label: <FormattedMessage defaultMessage="Yes" />,
        value: V1FindingTags.FixAvailable,
      },
      {
        label: <FormattedMessage defaultMessage="No" />,
        value: V1FindingTags.Unfixable,
      },
    ],

    type: FindingAttributeGroup.Fixability,
  },
  {
    header: <FormattedMessage defaultMessage="Reachable Function" />,
    isExclusive: false,
    values: [
      {
        label: <FormattedMessage defaultMessage="Yes" />,
        value: V1FindingTags.ReachableFunction,
      },
      {
        label: <FormattedMessage defaultMessage="Potentially" />,
        value: V1FindingTags.PotentiallyReachableFunction,
      },
      {
        label: <FormattedMessage defaultMessage="No" />,
        value: V1FindingTags.UnreachableFunction,
      },
    ],
    type: FindingAttributeGroup.FunctionReachability,
  },
  {
    header: <FormattedMessage defaultMessage="Reachable Dependency" />,
    isExclusive: false,
    values: [
      {
        label: <FormattedMessage defaultMessage="Yes" />,
        value: V1FindingTags.ReachableDependency,
      },
      {
        label: <FormattedMessage defaultMessage="Potentially" />,
        value: V1FindingTags.PotentiallyReachableDependency,
      },
      {
        label: <FormattedMessage defaultMessage="No" />,
        value: V1FindingTags.UnreachableDependency,
      },
    ],
    type: FindingAttributeGroup.DependencyReachability,
  },
  {
    header: <FormattedMessage defaultMessage="Phantom Dependency" />,
    isExclusive: true,
    values: [
      {
        label: <FormattedMessage defaultMessage="Yes" />,
        value: V1FindingTags.Phantom,
      },
    ],
    type: FindingAttributeGroup.Phantom,
  },
  {
    header: <FormattedMessage defaultMessage="Dependency Origin" />,
    isExclusive: true,
    values: [
      {
        label: <FormattedMessage defaultMessage="This Repo" />,
        value: V1FindingTags.ProjectInternal,
      },
      {
        label: <FormattedMessage defaultMessage="This Tenant" />,
        value: V1FindingTags.NamespaceInternal,
      },
    ],
    type: FindingAttributeGroup.DependencyOrigin,
  },
  {
    header: <FormattedMessage defaultMessage="Test Dependency" />,
    isExclusive: true,
    values: [
      {
        label: <FormattedMessage defaultMessage="Yes" />,
        value: V1FindingTags.Test,
      },
      {
        label: <FormattedMessage defaultMessage="No" />,
        value: V1FindingTags.Normal,
      },
    ],
    type: FindingAttributeGroup.Test,
  },
  {
    header: <FormattedMessage defaultMessage="Finding Origin" />,
    isExclusive: false,
    values: [
      {
        label: <FormattedMessage defaultMessage="Direct" />,
        value: V1FindingTags.Direct,
      },
      {
        label: <FormattedMessage defaultMessage="Transitive" />,
        value: V1FindingTags.Transitive,
      },
      {
        label: <FormattedMessage defaultMessage="Self" />,
        value: V1FindingTags.Self,
      },
    ],
    type: FindingAttributeGroup.FindingOrigin,
  },
];

interface FindingAttributesFilterFieldProps {
  onChange: (value: V1FindingTags[]) => void;
  value: V1FindingTags[];
}

export const FindingAttributesFilterField = ({
  onChange,
  value,
}: FindingAttributesFilterFieldProps) => {
  const { palette, space } = useTheme();

  const [internalState, setInternalState] = useState<Set<V1FindingTags>>(
    () => new Set(value)
  );

  const isActive = Boolean(value?.length);

  useEffect(() => {
    setInternalState(new Set(value));
  }, [value]);

  const handleFilterApply = () => {
    onChange(Array.from(internalState));
  };

  const handleFilterCancel = () => {
    setInternalState(new Set(value));
  };

  const getToggleClickHandler =
    (option: FieldToggleOption, target: V1FindingTags) => (_: MouseEvent) => {
      const valuesToUnset = option.isExclusive
        ? option.values.map((o) => o.value).filter((value) => value !== target)
        : [];

      setInternalState((old) => {
        const next = new Set(old);

        for (const value of valuesToUnset) {
          next.delete(value as V1FindingTags);
        }

        if (next.has(target)) {
          next.delete(target);
        } else {
          next.add(target);
        }

        return next;
      });
    };

  const content = (
    <Stack spacing={space.sm}>
      {FIELD_TOGGLE_GROUPS.map((option, index) => (
        <Stack spacing={space.xs} direction="column" key={index}>
          <Typography variant="overline">{option.header}</Typography>

          <Stack direction="row" spacing={space.xs}>
            {option.values.map(({ value, label }, index) => {
              const isSelected = internalState.has(value);

              return (
                <Chip
                  color="primary"
                  data-value={value}
                  key={index}
                  label={
                    <RowStack gap={1}>
                      {!option.isExclusive && (
                        <Checkbox
                          checked={isSelected}
                          sx={{
                            margin: 0,
                            backgroundColor: 'transparent',
                            color: palette.text.primary,
                            transform: 'scale(0.6)',

                            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                              backgroundColor: 'transparent',
                              color: palette.getContrastText(
                                palette.primary.main
                              ),
                            },
                          }}
                        />
                      )}

                      <Typography
                        component="span"
                        color={
                          isSelected
                            ? palette.getContrastText(palette.primary.main)
                            : palette.text.primary
                        }
                        variant="body2"
                      >
                        {label}
                      </Typography>
                    </RowStack>
                  }
                  onClick={getToggleClickHandler(option, value)}
                  size="small"
                  variant={isSelected ? 'filled' : 'outlined'}
                  sx={({ spacing }) => ({
                    height: spacing(6),
                  })}
                />
              );
            })}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <FilterDropdown
      id="FindingAttributesFilterField"
      isActive={isActive}
      label={<FM defaultMessage="Attributes" />}
      onApply={handleFilterApply}
      onCancel={handleFilterCancel}
    >
      {content}
    </FilterDropdown>
  );
};
