import { hasIn, isMatch, isObject, pick } from 'lodash-es';

import {
  FILTER_COMPARATORS_MULTIPLE_VALUE,
  FILTER_COMPARATORS_SINGLE_VALUE,
  FILTER_COMPARATORS_WITHOUT_VALUE,
  FilterComparatorSingleValue,
} from './comparator';
import {
  FilterableResource,
  LogicFilter,
  ResourceFilter,
  ResourceFilterDefinition,
  ValueFilter,
} from './types';

const PRIMITIVE_VALUES = new Set<string>([
  '[]',
  true.toString(),
  false.toString(),
]);

// TODO: this could be replaced with lodash.groupby
export const groupBy = <T extends object>(
  collection: T[],
  getKey: (item: T) => string
) => {
  const result: Record<string, T[]> = {};

  for (const item of collection) {
    const groupByKey = getKey(item);
    result[groupByKey] = (result[groupByKey] || []).concat(item);
  }

  return result;
};

export const hasResourceFilters = (
  filters: ResourceFilter[],
  kind: FilterableResource
) => {
  return filters.some((f) => f.kind === kind);
};

export const isLogicFilter = (v: unknown): v is LogicFilter => {
  return isObject(v) && hasIn(v, 'operator');
};

export const isValueFilter = (v: unknown): v is ValueFilter => {
  return isObject(v) && hasIn(v, 'comparator');
};

export const isFilterComparatorSingleValue = (
  v: unknown
): v is FilterComparatorSingleValue => {
  return !!v && 'string' == typeof v && v in FILTER_COMPARATORS_SINGLE_VALUE;
};

export const isFilterComparatorMultipleValue = (
  v: unknown
): v is FilterComparatorSingleValue => {
  return !!v && 'string' == typeof v && v in FILTER_COMPARATORS_MULTIPLE_VALUE;
};

export const isFilterComparatorWithoutValue = (
  v: unknown
): v is FilterComparatorSingleValue => {
  return !!v && 'string' == typeof v && v in FILTER_COMPARATORS_WITHOUT_VALUE;
};

export const selectResourceFilters = (
  filters: ResourceFilter[],
  kind: FilterableResource
) => {
  return filters.filter((f) => f.kind === kind);
};

/**
 * ensures values are wrapped with double quotes, and escaped properly before use
 */
export const escapeValue = (value: unknown): string => {
  // preserve escaped date/time values
  if (
    'string' === typeof value &&
    (value.startsWith('date(') ||
      value.startsWith('now(') ||
      PRIMITIVE_VALUES.has(value))
  ) {
    return value;
  }

  return JSON.stringify(value);
};

/**
 * Extends the provided filters with attributes from definitions, when available
 */
export const extendResourceFiltersWithDefinitions = (
  filters: ResourceFilter[],
  definitions: ResourceFilterDefinition[]
) => {
  if (!filters.length) return [];
  if (!definitions.length) return [];

  return filters.map((filter) => {
    const filterIdentifier = pick(filter, ['kind', 'key']);
    const filterDefinition = definitions.find((d) =>
      isMatch(d, filterIdentifier)
    );

    if (!filterDefinition) return filter;
    return { ...filterDefinition, ...filter };
  });
};
