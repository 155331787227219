import type { ReactNode } from 'react';
import { ListItem, ListItemButton, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export interface ReorderableListItemProps {
  children: ReactNode;
  id: string | number;
  index: number;
  reorderFn: (dragIndex: number, hoverIndex: number) => void;
  type: string;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ReorderableListItem = ({
  children,
  id,
  index,
  reorderFn,
  type,
}: ReorderableListItemProps) => {
  const { space } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: unknown | null }
  >({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position.
      // return early if no item is dragged.
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      reorderFn(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <ListItem disablePadding sx={{ opacity }}>
      <ListItemButton
        ref={ref}
        sx={{
          paddingX: space.sm,
          paddingY: space.xs,
        }}
        alignItems="center"
      >
        {children}
      </ListItemButton>
    </ListItem>
  );
};
