import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { clickOn, waitForElementAvailable } from '../utils';

const ENV = getEnv();
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-projects"]';

export const getProjectFindingsStep = ({
  navigate,
  tourData,
}: BasicTourStepProps) => {
  const selFinding = `.DataTableView-table tbody tr:nth-of-type(2)`;
  const selDrawer = '.DetailDrawerContainer-content';
  const selCallPathsButton = `${selDrawer} button[id$="callPath"]`;
  const selCallPath = `${selDrawer} .call-graph-detail-container`;

  return {
    attachTo: {
      element: selFinding,
      on: 'top-center',
    },

    beforeShowPromise: async function () {
      const baseUrl = getProjectDetailPath({
        tenantName: tourData.tourTenant,
        uuid: tourData.findingsProject?.uuid,
        section: 'findings',
      });

      const stepUrl = `${baseUrl}?filter.values=%7B%22spec.finding_categories%22%3A%7B%22key%22%3A%22spec.finding_categories%22%2C%22comparator%22%3A%22CONTAINS%22%2C%22value%22%3A%5B%22FINDING_CATEGORY_VULNERABILITY%22%5D%7D%7D&resourceDetail=%7B%22findingUuid%22%3A%22${tourData.vulnFindingUuid}%22%2C%22findingNamespace%22%3A%22demo-trial%22%7D`;

      navigate({
        to: stepUrl,
      });

      await waitForElementAvailable(`${selFinding} .MuiChip-root`);
      await waitForElementAvailable(selCallPathsButton);
      await clickOn(selCallPathsButton);
      await waitForElementAvailable(selCallPath);
    },

    highlightSelectors: [selFinding, selNav, selCallPath],
    title: 'Evidence for Engineers',
    description:
      'View full call-graphs highlighting how your first-party code reaches the vulnerable function within a direct or transitive dependency. All analyzed statically without any dreadful runtime agents.',

    urlLearn: `${ENV.URL_ENDOR_DOCS}/managing-projects/view-findings/`,
  };
};
