import {
  mergeWith as _mergeWith,
  partition as _partition,
  uniq as _uniq,
} from 'lodash-es';
import { useMemo } from 'react';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import {
  getDependencyFileLocations,
  PackageVersionResource,
} from '@endorlabs/endor-core/PackageVersion';
import {
  FindingCount,
  tryParseGroupResponseAggregationKey,
  useGetPackageVersion,
  useListFindings,
} from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

export type ContainerDependencyData = {
  containerFileLocations: string[];
  findingCounts: FindingCount[];
  findings: { uuid: string; level: SpecFindingLevel }[];
  name: string;
};

export type ContainerLayerData = {
  command?: string;
  dependencies: ContainerDependencyData[];
  digest: string;
  findingCounts: FindingCount[];
  isBaseImageLayer: boolean;
  layerIndex: number;
};

export type ContainerImageData = {
  dependencies: ContainerDependencyData[];
  findingCounts: FindingCount[];
  isBaseImage: boolean;
  key: string;
  name: string;
};

export const useContainerLayerData = ({
  packageVersion: partialPackageVersion,
}: {
  packageVersion?: PackageVersionResource;
}) => {
  const qGetPackageVersion = useGetPackageVersion(
    {
      namespace: partialPackageVersion?.tenant_meta.namespace ?? '',
      uuid: partialPackageVersion?.uuid ?? '',
    },
    { enabled: !!partialPackageVersion }
  );

  const packageVersion = qGetPackageVersion.data;

  // Fetch the related findings for this container PackageVersion
  const qRelatedFindingCounts = useListFindings(
    partialPackageVersion?.tenant_meta.namespace ?? '',
    { enabled: !!partialPackageVersion },
    {
      filter: `meta.parent_uuid=="${partialPackageVersion?.uuid}"`,
      group: {
        aggregation_paths: 'spec.target_dependency_package_name,spec.level',
        show_aggregation_uuids: true,
      },
    }
  );

  const data = useMemo(() => {
    const findings: {
      dependency: string;
      level: SpecFindingLevel;
      uuid: string;
    }[] = [];
    const findingsMapByDependency: Record<
      string,
      Partial<Record<SpecFindingLevel, number>>
    > = {};

    for (const [key, group] of Object.entries(
      qRelatedFindingCounts.data?.group_response?.groups ?? {}
    )) {
      const values = tryParseGroupResponseAggregationKey(key);

      const dependencyName = values.find(
        (kv) => kv.key === 'spec.target_dependency_package_name'
      )?.value as string | undefined;
      const findingLevel = values.find((kv) => kv.key === 'spec.level')
        ?.value as SpecFindingLevel | undefined;
      const count = group.aggregation_count?.count ?? 1;
      const uuids = group.aggregation_uuids ?? [];

      if (dependencyName && findingLevel) {
        findingsMapByDependency[dependencyName] = _mergeWith(
          findingsMapByDependency[dependencyName],
          { [findingLevel]: count },
          (a = 0, b = 0) => a + b
        );

        for (const uuid of uuids) {
          findings.push({
            uuid,
            level: findingLevel,
            dependency: dependencyName,
          });
        }
      }
    }

    const dependenciesbyLayerDigest: Record<string, string[]> = {};
    for (const d of packageVersion?.spec.resolved_dependencies?.dependencies ??
      []) {
      for (const l of d.container_layers ?? []) {
        dependenciesbyLayerDigest[l.digest] = (
          dependenciesbyLayerDigest[l.digest] ?? []
        ).concat(d.name);
      }
    }

    const buildContainerDependencyData = (
      dependencyNames: string[],
      options?: { digest?: string }
    ) => {
      const dependencies: ContainerDependencyData[] = [];
      const totalFindingsMap: Partial<Record<SpecFindingLevel, number>> = {};

      for (const depName of dependencyNames) {
        const dependencyFindingsMap = findingsMapByDependency[depName] ?? {};

        dependencies.push({
          name: depName,
          findingCounts: FINDING_LEVELS.map((level) => ({
            level,
            value: dependencyFindingsMap[level] ?? 0,
          })),
          findings: findings.filter((f) => f.dependency === depName),
          containerFileLocations: packageVersion
            ? getDependencyFileLocations(packageVersion, {
                name: depName,
                digest: options?.digest,
              })
            : [],
        });

        _mergeWith(
          totalFindingsMap,
          dependencyFindingsMap,
          (a = 0, b = 0) => a + b
        );
      }

      return {
        dependencies,
        findingCounts: FINDING_LEVELS.map((level) => ({
          level,
          value: totalFindingsMap[level] ?? 0,
        })),
      };
    };

    const buildImagesData = (packageVersion?: PackageVersionResource) => {
      const containerLayers =
        packageVersion?.spec.container_metadata?.layers ?? [];
      const baseImageName =
        packageVersion?.spec.container_metadata?.base_image?.name;

      if (!baseImageName) {
        return [];
      }

      // strip a digest suffix from base image name, if present
      const baseImageNameClean = baseImageName.split('@sha256:')[0];

      // parse the package name for use as the image name
      const parsed = UIPackageVersionUtils.parsePackageName(
        packageVersion.meta.name
      );
      const packageImageNameClean = [parsed.label, parsed.version].join(':');

      const [baseLayers, otherLayers] = _partition(
        containerLayers,
        (l) => l.base_layer
      );

      const baseDependencies = _uniq(
        baseLayers.flatMap((l) => dependenciesbyLayerDigest[l.digest] ?? [])
      );

      const otherDependencies = _uniq(
        otherLayers.flatMap((l) => dependenciesbyLayerDigest[l.digest] ?? [])
      );

      return [
        {
          isBaseImage: true,
          key: baseImageName,
          name: baseImageNameClean,
          ...buildContainerDependencyData(baseDependencies),
        },
        {
          isBaseImage: false,
          key: packageVersion.meta.name,
          name: packageImageNameClean,
          ...buildContainerDependencyData(otherDependencies),
        },
      ] satisfies ContainerImageData[];
    };

    const buildLayersData = (packageVersion?: PackageVersionResource) => {
      const containerLayers =
        packageVersion?.spec.container_metadata?.layers ?? [];

      return containerLayers.map((layer, index) => {
        const layerDependencies = dependenciesbyLayerDigest[layer.digest] ?? [];

        const { dependencies, findingCounts } = buildContainerDependencyData(
          layerDependencies,
          { digest: layer.digest }
        );

        return {
          command: layer.command,
          dependencies,
          digest: layer.digest,
          findingCounts,
          isBaseImageLayer: !!layer.base_layer,
          layerIndex: index,
        } satisfies ContainerLayerData;
      });
    };

    const images = buildImagesData(packageVersion);
    const layers = buildLayersData(packageVersion);

    return { images, layers };
  }, [packageVersion, qRelatedFindingCounts.data]);

  return {
    data,
    isLoading: qGetPackageVersion.isLoading,
    isLoadingCounts: qRelatedFindingCounts.isLoading,
  };
};
