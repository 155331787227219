import { set as _set } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  V1CustomTemplate,
  V1CustomTemplateType,
  V1EmailTemplate,
  V1NotificationTarget,
  V1SlackTemplate,
  V1WebhookTemplate,
} from '@endorlabs/api_client';
import {
  useGetDefaultCustomTemplate,
  useGetNotificationTarget,
  useUpdateNotificationTarget,
} from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

import { CustomTemplateFields } from '../types';

/**
 * Custom hook to get Template data for given NotificationTarget and
 * expose methods to save the template.
 */
export const useNotificationTargetTemplateData = ({
  templateType,
  tenantName,
  notificationTargetUuid,
}: {
  templateType: V1CustomTemplateType;
  tenantName: string;
  notificationTargetUuid?: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const addAppNotification = useAppNotify();

  const qNotificationTarget = useGetNotificationTarget(
    { namespace: tenantName, uuid: notificationTargetUuid ?? '' },
    { enabled: !!notificationTargetUuid, staleTime: Infinity }
  );

  const qDefaultTemplate = useGetDefaultCustomTemplate(
    {
      customTemplateType: templateType,
    },
    {
      enabled: templateType !== V1CustomTemplateType.Unspecified,
      staleTime: Infinity,
    }
  );

  const customTemplate = useMemo(() => {
    switch (templateType) {
      case V1CustomTemplateType.Email:
        return qNotificationTarget.data?.spec.custom_template?.email_template;
      case V1CustomTemplateType.Slack:
        return qNotificationTarget.data?.spec.custom_template?.slack_template;
      case V1CustomTemplateType.Webhook:
        return qNotificationTarget.data?.spec.custom_template?.webhook_template;
    }
  }, [qNotificationTarget.data?.spec.custom_template, templateType]);

  const defaultTemplate = useMemo(() => {
    switch (templateType) {
      case V1CustomTemplateType.Email:
        return qDefaultTemplate.data?.email_template;
      case V1CustomTemplateType.Slack:
        return qDefaultTemplate.data?.slack_template;
      case V1CustomTemplateType.Webhook:
        return qDefaultTemplate.data?.webhook_template;
    }
  }, [qDefaultTemplate.data, templateType]);

  const template = customTemplate ?? defaultTemplate;

  const qUpdateNotificationTargetTemplate = useUpdateNotificationTarget({
    onError: (error) => {
      addAppNotification({
        message:
          error.response.data?.message ??
          fm({ defaultMessage: 'Failed to update PR Comment Template' }),
        severity: 'error',
      });
    },
  });

  const buildCustomTemplateByType = useCallback(
    (template: CustomTemplateFields) => {
      const customTemplate = {
        template_type: templateType,
      } as V1CustomTemplate;

      switch (templateType) {
        case V1CustomTemplateType.Email:
          customTemplate.email_template = template as V1EmailTemplate;
          break;
        case V1CustomTemplateType.Slack:
          customTemplate.slack_template = template as V1SlackTemplate;
          break;
        case V1CustomTemplateType.Webhook:
          customTemplate.webhook_template = template as V1WebhookTemplate;
          break;
      }

      return customTemplate;
    },
    [templateType]
  );

  const saveTemplate = (
    template: CustomTemplateFields,
    notificationTarget?: V1NotificationTarget
  ) => {
    const notificationTargetData =
      notificationTarget ?? qNotificationTarget.data;

    if (notificationTargetData) {
      const updatedNotificationTargetData = _set(
        notificationTargetData,
        'spec.custom_template',
        buildCustomTemplateByType(template)
      );
      qUpdateNotificationTargetTemplate.mutate({
        mask: 'spec.custom_template',
        namespace: notificationTargetData.tenant_meta?.namespace ?? tenantName,
        resource: updatedNotificationTargetData,
      });
    }
  };

  const restoreDefaultTemplate = (
    notificationTarget?: V1NotificationTarget
  ) => {
    if (defaultTemplate) {
      saveTemplate(defaultTemplate, notificationTarget);
    }
  };

  return {
    defaultTemplate,
    template,
    restoreDefaultTemplate,
    saveTemplate,
  };
};
