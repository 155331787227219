import { Box } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';

import { LicenseRequired } from '../../../components';
import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../components/DetailDrawer';
import { useLicensingInfo } from '../../../providers';
import { AssuredPackageVersionPatchDetail } from './AssuredPackageVersionPatchDetail';
import { AssuredPackageVersionPatchSummary } from './AssuredPackageVersionPatchSummary';

export const AssuredPackageVersionPatchesSection = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  const { checkLicensePresent } = useLicensingInfo();
  const hasEndorPatchesLicense = checkLicensePresent(
    SpecEndorLicenseFeatureType.EndorPatching
  );

  if (!hasEndorPatchesLicense) {
    return (
      <Box marginTop="10vh">
        <LicenseRequired
          description={
            <FM defaultMessage="See the exact code changes that go into an Endor Patch." />
          }
          title={<FM defaultMessage="Endor Patches License Required" />}
        />
      </Box>
    );
  }

  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection disableGutters>
        <AssuredPackageVersionPatchSummary
          assuredPackageVersion={assuredPackageVersion}
        />
      </DetailDrawerSection>

      <DetailDrawerSection disableGutters>
        <AssuredPackageVersionPatchDetail
          assuredPackageVersion={assuredPackageVersion}
        />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
