import { Stack, Typography, TypographyProps } from '@mui/material';

interface FormattedTypographyProps extends TypographyProps {
  /**
   * (optional) Spacing between the paragraph elements, in theme units
   */
  paragraphSpacing?: number;
  /**
   * Pre-formatted text to display
   */
  text: string | undefined;
}

export const FormattedTypography = ({
  text,
  paragraphSpacing = 2,
  ...props
}: FormattedTypographyProps) => {
  if (!text) {
    return <Typography {...props}>{text}</Typography>;
  }

  const formattedText = text
    .split('\n')
    .filter(Boolean) // strips empty newlines from output
    .map((str, i) => (
      <Typography key={i} {...props}>
        {str}
      </Typography>
    ));

  return (
    <Stack className="FormattedTypography-root" spacing={paragraphSpacing}>
      {formattedText}
    </Stack>
  );
};
