import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor } from 'react';

import {
  ImgIconLogoGoogle,
  ImgIconLogoMarkGitHub,
  ImgIconLogoMarkGitLab,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../../routes/constants';

export const AuthProviderNames = {
  AZURE: 'Azure',
  GITHUB: 'GitHub',
  GITLAB: 'GitLab',
  GOOGLE: 'Google',
} as const;

export type AuthProviderName =
  typeof AuthProviderNames[keyof typeof AuthProviderNames];

const APN = AuthProviderNames;

export interface AuthProviderConfig {
  displayName: AuthProviderName;
  Icon?: JSXElementConstructor<SvgIconProps>;
  imageUrl?: string;
  url: string;
  isGlobal?: boolean;
}

export const AuthProviders: Record<string, AuthProviderConfig> = {
  [APN.GOOGLE]: {
    displayName: APN.GOOGLE,
    Icon: ImgIconLogoGoogle,
    url: NamedRoutes.AUTH_GOOGLE,
  },
  [APN.GITHUB]: {
    displayName: APN.GITHUB,
    Icon: ImgIconLogoMarkGitHub,
    url: NamedRoutes.AUTH_GITHUB,
  },
  // NOTE: hidden from login page for https://endorlabs.atlassian.net/browse/UI-114.
  // May be re-enabled in the future.
  // [APN.AZURE]: {
  //   displayName: APN.AZURE,
  //   Icon: ImgIconLogoMicrosoft,
  //   url: NamedRoutes.AUTH_AZURE,
  // },
  [APN.GITLAB]: {
    displayName: APN.GITLAB,
    Icon: ImgIconLogoMarkGitLab,
    url: NamedRoutes.AUTH_GITLAB,
  },
} as const;
