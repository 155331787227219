import { AlertColor } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1SecretRule } from '@endorlabs/api_client';
import {
  SecretRuleResource,
  useCreateSecretRule,
  useUpdateSecretRule,
} from '@endorlabs/queries';

import { useAppNotify } from '../../layout';
import {
  SecretRuleUpsertDialog,
  SecretRuleUpsertDialogProps,
} from '../components/SecretRuleUpsertDialog';

/**
 * Utility hook to Manage Secret Rule Upsert Dialog
 */
export const useSecretRuleUpsertDialog = ({
  namespace,
}: {
  namespace: string;
}) => {
  const [secretRuleState, setSecretRuleState] = useState<
    | {
        namespace: string;
        secretRule?: SecretRuleResource;
      }
    | undefined
  >(undefined);
  const isOpen = Boolean(secretRuleState);
  const addAppNotification = useAppNotify();

  const handleOpenDialog = useCallback(
    (props: { namespace: string; secretRule?: SecretRuleResource }) => {
      setSecretRuleState(props);
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setSecretRuleState(undefined);
  }, []);

  const qCreateSecretRule = useCreateSecretRule({
    onSuccess: () => {
      // app notification handled locally - preventing system notification
    },
  });
  const qUpdateSecretRule = useUpdateSecretRule({
    onSuccess: () => {
      // app notification handled locally - preventing system notification
    },
  });

  const serverErrorResponse =
    qCreateSecretRule.error?.response ?? qUpdateSecretRule.error?.response;

  const handleSubmit = useCallback(
    (model: V1SecretRule) => {
      // TODO: handle possible edge case?
      if (!secretRuleState) return;

      const { namespace, secretRule } = secretRuleState;
      // field mask for update
      const updateMask = ['meta.name', 'spec', 'propagate'].join();
      const isEditing = Boolean(secretRule);
      const successMessage = {
        message: (
          <FM
            defaultMessage={`Secret Rule {ruleName} Successfully {action}`}
            values={{
              action: isEditing ? 'Updated' : 'Created',
              ruleName: `"${model?.meta?.name}"`,
            }}
          />
        ),
        severity: 'success' as AlertColor,
      };

      // if there is an existing secret rule to be updated
      if (isEditing) {
        qUpdateSecretRule.mutate(
          { namespace, resource: model, mask: updateMask },
          {
            onSuccess: () => {
              addAppNotification(successMessage);
              handleCloseDialog();
            },
          }
        );
      } else {
        qCreateSecretRule.mutate(
          { namespace, resource: model },
          {
            onSuccess: () => {
              addAppNotification(successMessage);
              handleCloseDialog();
            },
          }
        );
      }
    },
    [
      secretRuleState,
      qUpdateSecretRule,
      addAppNotification,
      handleCloseDialog,
      qCreateSecretRule,
    ]
  );

  const getSecretRuleUpsertDialogProps = useCallback<
    () => SecretRuleUpsertDialogProps
  >(
    () => ({
      namespace,
      open: isOpen,
      onClose: handleCloseDialog,
      onSubmit: handleSubmit,
      secretRule: secretRuleState?.secretRule,
      serverErrorResponse,
    }),
    [
      namespace,
      secretRuleState?.secretRule,
      handleCloseDialog,
      handleSubmit,
      isOpen,
      serverErrorResponse,
    ]
  );

  return {
    Dialog: SecretRuleUpsertDialog,
    openSecretRuleUpsertDialog: handleOpenDialog,
    getSecretRuleUpsertDialogProps,
  };
};
