import { useMatch } from '@tanstack/react-location';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import {
  findProjectVersionQueryOptions,
  getProjectQueryOptions,
} from '@endorlabs/queries';

import { ProjectVersionDetailPage } from '../../../../../../../views/ProjectVersion/ProjectVersionDetailPage';
import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionDetailRoute = () => {
  const { data, params } = useMatch();

  const project = data.project as ProjectResource;
  const projectVersion = data.projectVersion as ProjectVersionResource;

  const versionRef = decodeURIComponent(params.version_ref);

  return (
    <ProjectVersionDetailPage
      project={project}
      projectVersion={projectVersion}
      versionRef={versionRef}
    />
  );
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/:version_ref'
)({
  component: TenantProjectVersionDetailRoute,
  loader: async ({ context, params }) => {
    const { namespace, project_uuid, version_ref } = params;
    const versionRef = decodeURIComponent(version_ref);

    // Preload the project data for the route
    const project = await context.queryClient.fetchQuery(
      getProjectQueryOptions({
        namespace,
        uuid: project_uuid,
      })
    );

    // Find the project version from the given version ref
    const projectVersion = await context.queryClient.fetchQuery(
      findProjectVersionQueryOptions({ project, versionRef })
    );

    return { project, projectVersion };
  },
});
