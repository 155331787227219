import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryJoinFilters,
  QueryServiceApi,
  SpecEnvironment,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Project,
  V1ProjectSpec,
  V1Query,
  V1ScanResult,
  V1ScanResultSpec,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { TResourceList } from './types';
import { getClientConfiguration, sortParamBuilders } from './utils';

// Restrict the allowed resource kinds for the join filters
export type QueryProjectToolsJoinFilters = QueryJoinFilters & {
  kind: Extract<ResourceKind, 'Project' | 'Metric'>;
};

export const QueryProjectToolsQueryKeys = {
  query: (
    namespace: string,
    listParams: V1ListParameters,
    filters: QueryJoinFilters[]
  ): QueryKey => ['v1/queries', namespace, 'projects', { listParams, filters }],
};

export type QueryProjectToolsResponse =
  TResourceList<QueryProjectToolsResponseObject>;

export type QueryProjectToolsResponseObject = SelectFrom<
  V1Project,
  'uuid' | 'tenant_meta',
  {
    spec: SelectFrom<V1ProjectSpec, 'platform_source'>;
    meta: SelectFrom<
      V1Meta,
      'name' | 'tags',
      {
        create_time: never;
        update_time: never;
        references: {
          // Latest Metric with tools in main context
          MainContextMetric: QueryProjectMetricsResponse;
          // Latest Metric with tools in ref context
          RefContextMetric: QueryProjectMetricsResponse;
          MainScanResult: QueryProjectScanResultResponse;
          RefScanResult: QueryProjectScanResultResponse;
        };
      }
    >;
  }
>;

type QueryProjectMetricsResponse = TResourceList<
  SelectFrom<
    V1Metric,
    'context' | 'uuid',
    {
      meta: SelectFrom<
        V1Meta,
        'update_time',
        {
          name: 'version_cicd_tools';
        }
      >;
      spec: SelectFrom<
        V1MetricSpec,
        'analytic',
        {
          metric_values: {
            CiCdTools: Required<V1MetricValue>;
          };
          // properties required, but not included in query
          project_uuid: never;
          raw: never;
        }
      >;
      tenant_meta: never;
    }
  >
>;

type QueryProjectScanResultResponse = TResourceList<
  SelectFrom<
    V1ScanResult,
    'context' | 'uuid',
    {
      meta: never;
      spec: SelectFrom<
        V1ScanResultSpec,
        'exit_code' | 'status' | 'type',
        { environment: SelectFrom<SpecEnvironment, 'config'> }
      >;
      tenant_meta: never;
    }
  >
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters,
  filters: QueryJoinFilters[]
): V1Query => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  const projectFilter = filters.find((f) => f.kind === 'Project')?.filter;

  // Default filter for metrics to be used for the join filter and references
  const baseMetricsFilter = `meta.name==version_cicd_tools`;

  // Default filter for scan results with tools option enabled
  const baseScanResultFilter =
    'spec.environment.config.ScanConfig.Enables contains tools';

  // If user has not provided any filters for metrics, base metrics filter
  // will be used.
  const userMetricsFilter = filters.find((f) => f.kind === 'Metric');
  const metricsJoinFilter = {
    kind: 'Metric',
    filter: userMetricsFilter?.filter
      ? filterExpressionBuilders.and([
          userMetricsFilter.filter,
          // Use the defaultResourceContexts filter to get the projects with
          // tools in either main or ref context
          filterExpressionBuilders.defaultResourceContexts(),
        ])
      : filterExpressionBuilders.defaultResourceContexts(),
  };

  return {
    meta: {
      name: `QueryProjectTools(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Project',
        list_parameters: {
          ...rootListParams,
          filter: projectFilter,
          mask: [
            'uuid',
            'meta.name',
            'meta.tags',
            'spec.platform_source',
            'tenant_meta',
          ].join(),
        },
        with: [metricsJoinFilter],
        references: [
          {
            connect_from: 'uuid',
            connect_to: 'spec.project_uuid',
            query_spec: {
              kind: 'Metric',
              return_as: 'MainContextMetric',
              list_parameters: {
                ...commonListParameters,
                filter: filterExpressionBuilders.and([
                  baseMetricsFilter,
                  filterExpressionBuilders.mainResourceContext(),
                ]),
                page_size: 1,
                mask: [
                  'context',
                  'meta.update_time',
                  'spec.metric_values',
                ].join(','),
                sort: sortParamBuilders.descendingBy('meta.update_time'),
              },
            },
          },
          {
            connect_from: 'uuid',
            connect_to: 'spec.project_uuid',
            query_spec: {
              kind: 'Metric',
              return_as: 'RefContextMetric',
              list_parameters: {
                ...commonListParameters,
                filter: filterExpressionBuilders.and([
                  baseMetricsFilter,
                  filterExpressionBuilders.refResourceContext(),
                ]),
                page_size: 1,
                mask: [
                  'context',
                  'meta.update_time',
                  'spec.metric_values',
                ].join(','),
                sort: sortParamBuilders.descendingBy('meta.update_time'),
              },
            },
          },
          {
            connect_from: 'uuid',
            connect_to: 'meta.parent_uuid',
            query_spec: {
              kind: 'ScanResult',
              return_as: 'MainScanResult',
              list_parameters: {
                ...commonListParameters,
                filter: filterExpressionBuilders.and([
                  baseScanResultFilter,
                  filterExpressionBuilders.mainResourceContext(),
                ]),
                sort: sortParamBuilders.descendingBy('meta.create_time'),
                page_size: 1,
                mask: [
                  'context',
                  'uuid',
                  'spec.end_time',
                  'spec.exit_code',
                  'spec.status',
                  'spec.type',
                  'spec.environment.config',
                ].join(','),
              },
            },
          },
          {
            connect_from: 'uuid',
            connect_to: 'meta.parent_uuid',
            query_spec: {
              kind: 'ScanResult',
              return_as: 'RefScanResult',
              list_parameters: {
                ...commonListParameters,
                filter: filterExpressionBuilders.and([
                  baseScanResultFilter,
                  filterExpressionBuilders.refResourceContext(),
                ]),
                sort: sortParamBuilders.descendingBy('meta.create_time'),
                page_size: 1,
                mask: [
                  'context',
                  'uuid',
                  'spec.end_time',
                  'spec.exit_code',
                  'spec.status',
                  'spec.type',
                  'spec.environment.config',
                ].join(','),
              },
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

const queryProjectTools = async (
  namespace: string,
  listParams: V1ListParameters,
  filters: QueryJoinFilters[],
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams, filters);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryProjectToolsResponse;
};

/**
 * Custom query for the Tools page
 */
export const useQueryProjectTools = (
  namespace: string,
  // filters should be passed in formatted `QueryJoinFilters` rather
  // than as part of the list parameters object
  // list mask is set by the query spec
  listParams: Omit<ListRequestParameters, 'filter' | 'mask'> = {},
  queryOptions?: {
    filters: QueryProjectToolsJoinFilters[];
  }
) => {
  const { filters = [] } = queryOptions ?? {};
  const requestParameters = useBuildReadRequestParameters(
    'Project',
    'LIST',
    listParams
  );

  return useQuery(
    QueryProjectToolsQueryKeys.query(namespace, requestParameters, filters),
    async ({ signal }) =>
      queryProjectTools(namespace, requestParameters, filters, signal),
    { staleTime: Infinity }
  );
};
