import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const TenantThirdPartyIndexRoute = () => {
  return <Navigate to="sboms" replace />;
};

export const Route = createFileRoute('/t/:namespace/third-party/')({
  component: TenantThirdPartyIndexRoute,
});
