import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { REGEX_COMMIT_SHA_VALIDATION } from '../../constants';
import { UIPackageVersionUtils } from '../../domains/PackageVersion';
import { PackageIconDisplay } from '../PackageIconDisplay';

interface PackageDisplayProps {
  name: string;
  link?: string;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  showIcon?: boolean;
  showVersion?: boolean;
}

/**
 * @deprecated
 * @see PackageVersionNameDisplay
 *
 * Display for a Package, or Package version
 */
export const PackageNameDisplay = ({
  name: packageName,
  size = 'small',
  showIcon = true,
  showVersion = false,
}: PackageDisplayProps) => {
  const { ecosystem, label, version } = useMemo(
    () => UIPackageVersionUtils.parsePackageName(packageName),
    [packageName]
  );

  const versionDisplay = useMemo(() => {
    if (!version) return null;

    if (REGEX_COMMIT_SHA_VALIDATION.test(version)) {
      return version.slice(0, 7);
    }

    return version;
  }, [version]);

  const spacing = 2;

  return (
    <Stack direction="row" spacing={spacing} alignItems="center">
      {showIcon && (
        <PackageIconDisplay
          ecosystem={ecosystem}
          displayDefault="package"
          size={size}
        />
      )}

      <Typography component="span" fontSize={size} variant="inherit">
        {label}
        {showVersion && (
          <>
            {/* hint for the browser to add line break before version identifier, when needed */}
            <wbr />
            <Typography component="span" fontSize={size} color="text.secondary">
              @{versionDisplay}
            </Typography>
          </>
        )}
      </Typography>
    </Stack>
  );
};

/**
 * @deprecated usage of this exported method should be replaced with {@see UIPackageVersionUtils.parsePackageName}
 */
export const parsePackageName = UIPackageVersionUtils.parsePackageName;
