import { defineMessages } from 'react-intl';

import { FindingPriorityBucketNames } from '../constants';

export const FindingPriorityBucketLabels = defineMessages({
  [FindingPriorityBucketNames.PRIORITIZE]: { defaultMessage: 'Prioritize' },
  [FindingPriorityBucketNames.PLAN]: { defaultMessage: 'Plan' },
  [FindingPriorityBucketNames.BACKLOG]: { defaultMessage: 'Backlog' },
  [FindingPriorityBucketNames.DEFER]: { defaultMessage: 'Defer' },
  [FindingPriorityBucketNames.OFF_RADAR]: { defaultMessage: 'Off Your Radar' },
});
