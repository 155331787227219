import {
  FILTER_COMPARATORS,
  isLogicFilter,
  isValueFilter,
  ValueFilter,
} from '@endorlabs/filters';

import { ControlsFacetFilterFindingEpssProbability } from '../../../../components/FacetedSearchBar/ControlsFacetFilterFindingEpssProbability';
import { FilterFieldConfig } from '../../../filters';

export const FindingEpssProbabilityFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',

  initialFilter: {
    key: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',
    comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
    value: 0.01,
  },

  format: (value) => {
    if (!value || !Array.isArray(value) || value.length !== 2) return;

    const [min, max] = value;

    return {
      operator: 'AND',
      value: [
        {
          key: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',
          comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
          value: min,
        },
        {
          key: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',
          comparator: FILTER_COMPARATORS.LESSER_OR_EQUAL,
          value: max,
        },
      ],
    };
  },

  parse: (filter) => {
    // Handle input as min-max
    if (
      isLogicFilter(filter) &&
      filter.operator === 'AND' &&
      filter.value.length === 2 &&
      filter.value.every((f) => isValueFilter(f))
    ) {
      const [min, max] = filter.value as ValueFilter[];

      if (
        min.comparator === 'GREATER_OR_EQUAL' &&
        max.comparator === 'LESSER_OR_EQUAL'
      ) {
        return [min.value, max.value] as [number, number];
      }
    }

    // Handle single value input as min
    if (isValueFilter(filter) && filter.comparator === 'GREATER_OR_EQUAL') {
      return [filter.value, 1] as [number, number];
    }

    return undefined;
  },

  renderInput: ({ onChange, value }) => (
    <ControlsFacetFilterFindingEpssProbability
      facet={null as any}
      onChange={onChange}
      value={value as any}
    />
  ),
} satisfies FilterFieldConfig<[min: number, max: number]>;
