import { Card, CardContent, Grid, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { V1ServiceRequest } from '@endorlabs/api_client';
import { useCreateServiceRequest } from '@endorlabs/queries';
import { IconArtifactRegistry, useAppNotify } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { ServiceRequestForm } from '../../domains/ServiceRequest';
import { useAuthInfo } from '../../providers';
import { getServiceRequestPath } from '../../routes';

export const ServiceRequestCreatePage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const addAppNotification = useAppNotify();
  const navigate = useNavigate();

  const qCreateServiceRequest = useCreateServiceRequest({
    onSuccess: () => {
      addAppNotification({
        message: <FM defaultMessage="Service Request Created" />,
        severity: 'success',
      });
      navigate({ to: getServiceRequestPath({ tenantName }) });
    },
  });

  const handleSubmit = (values: V1ServiceRequest) => {
    qCreateServiceRequest.mutate({
      namespace: tenantName,
      resource: {
        meta: {
          name: `${values.spec.resource} in ${values.spec.target_namespace}`,
        },
        spec: values.spec,
      },
    });
  };

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              url: getServiceRequestPath({ tenantName }),
              label: <FM defaultMessage="All Service Requests" />,
            },
          ]}
          Icon={IconArtifactRegistry}
          title={<FM defaultMessage="Create Service Request" />}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <ServiceRequestForm onSubmit={handleSubmit} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
