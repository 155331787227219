import { isEqual as _isEqual, merge as _merge } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import { useMemo, useRef } from 'react';

import { PackageVersionResource } from '@endorlabs/queries';

import {
  DependencyInfo,
  useDependenciesFromDependencyMetadata,
} from '../../Dependencies';

export interface PackageVersionDependency extends DependencyInfo {
  isPublic?: boolean;
}

/**
 * Return a stable array of PackageVersion resources, to enable strict equality checks on the return value
 */
const useStablePackageVersionsRef = (
  packageVersionOrVersions?: PackageVersionResource | PackageVersionResource[]
): PackageVersionResource[] => {
  const packageVersionsRef = useRef<PackageVersionResource[]>([]);

  // cast value to array
  let packageVersions: PackageVersionResource[] = [];
  if (Array.isArray(packageVersionOrVersions)) {
    packageVersions = [...packageVersionOrVersions];
  } else if (packageVersionOrVersions) {
    packageVersions = [packageVersionOrVersions];
  }

  // ensure stable sort order for package versions
  packageVersions?.sort((a, b) => a.uuid.localeCompare(b.uuid));

  // update the ref when the package versions change
  const previous = packageVersionsRef.current.map((pv) => pv.uuid);
  const next = packageVersions.map((pv) => pv.uuid);

  if (!_isEqual(previous, next)) {
    packageVersionsRef.current = packageVersions;
  }

  return packageVersionsRef.current;
};

/**
 * From a given Package Version, or list of Package Versions, build a list of
 * dependencies.
 *
 * Take from the Package Version resolved dependencies first, then fill in
 * detail from Dependency Metadata (when available).
 */
export const usePackageVersionDependencies = (
  namespace: string,
  packageVersionOrVersions?: PackageVersionResource | PackageVersionResource[],
  searchValue = ''
) => {
  const packageVersions = useStablePackageVersionsRef(packageVersionOrVersions);

  // NOTE: filtering by uuid, does not need a context filter
  const dependencyFilterExpression = `spec.importer_data.package_version_uuid in [${packageVersions?.map(
    (pv) => pv.uuid
  )}]`;

  // NOTE: take namespace from package version, falling back to the given namespace
  const packageVersionNamespace =
    packageVersions[0]?.tenant_meta.namespace ?? namespace;
  const qDependencyMetadata = useDependenciesFromDependencyMetadata(
    packageVersionNamespace,
    dependencyFilterExpression,
    { enabled: !!packageVersions }
  );

  const [dependencies, dependencyGraph] = useMemo(() => {
    // store in a map to handle de-duping depedencies
    const dependenciesMap: Record<string, PackageVersionDependency> =
      qDependencyMetadata.dependenciesMap;
    const mergedDependencyGraph: Record<string, string[]> = {};

    for (const packageVersion of packageVersions ?? []) {
      const packageDependencies =
        packageVersion?.spec.resolved_dependencies?.dependencies ?? [];

      // override dependency properties with resolved dependency information
      for (const dep of packageDependencies) {
        if (Reflect.has(dependenciesMap, dep.name)) {
          dependenciesMap[dep.name].isPublic = !!dep.public;
        }
      }

      const packageDependencyGraph =
        packageVersion.spec.resolved_dependencies?.dependency_graph ?? {};

      // merge all dependency graphs into a singular adjacency list
      _merge(mergedDependencyGraph, packageDependencyGraph);
    }

    // apply a default sort to the dependencies list, placing direct dependencies first
    const dependencies = Object.values(dependenciesMap).sort((a, b) =>
      a.isDirectDependency === b.isDirectDependency
        ? a.name.localeCompare(b.name)
        : a.isDirectDependency
        ? -1
        : 1
    );

    // If no search value is provided, return list as-is
    if (!searchValue) return [dependencies, mergedDependencyGraph];

    const filteredDependencies = matchSorter(dependencies, searchValue, {
      // use the default sort order as tie breaker
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
      keys: ['name'],
    });

    return [filteredDependencies, mergedDependencyGraph];
  }, [packageVersions, qDependencyMetadata.dependenciesMap, searchValue]);

  return {
    dependencies,
    dependencyGraph,
    isLoading: qDependencyMetadata.isLoading,
    isSuccess: qDependencyMetadata.isSuccess,
  };
};
