import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../utils/tanstack-react-router-shim';

const AdminIndexRoute = () => {
  return <Navigate to="feature-flags" />;
};

export const Route = createFileRoute('/admin/')({
  component: AdminIndexRoute,
});
