import { V1Ecosystem } from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { FindingResource } from '@endorlabs/queries';

export const getTargetDependencyEcosystem = (
  finding: FindingResource,
  sourcePackageVersion: PackageVersionResource
) => {
  const ecosystem =
    finding.spec.ecosystem === V1Ecosystem.Unspecified
      ? sourcePackageVersion?.spec?.ecosystem ?? V1Ecosystem.Unspecified
      : finding.spec.ecosystem ?? V1Ecosystem.Unspecified;
  return ecosystem as V1Ecosystem;
};
