import { ResourceKind } from '@endorlabs/endor-core';

import { DashboardChartFilterKey } from '../constants';

export const DashboardBucketContexts = {
  FINDING_PRIORITY_BUCKETS: 'FINDING_PRIORITY_BUCKETS',
  FINDING_SEVERITY: 'FINDING_SEVERITY',
};

export type DashboardBucketContext =
  typeof DashboardBucketContexts[keyof typeof DashboardBucketContexts];

export interface DashboardState {
  bucketContext: DashboardBucketContext;
  views: DashboardViewRecord[];
}

export interface DashboardViewRecord {
  filterKeyActive?: DashboardChartFilterKey;
  resourceKindPrimary: ResourceKind;
  viewId: string;
}
