import { Accordion, AccordionProps, Theme, useTheme } from '@mui/material';

interface StepAccordionProps extends AccordionProps {
  handleToggle: (stepNumber: number) => void;
  stepNumber: number;
}

export const StepAccordion = ({
  handleToggle,
  stepNumber,
  ...accordionProps
}: StepAccordionProps) => {
  const sx = styles(useTheme());

  return (
    <Accordion
      {...accordionProps}
      sx={sx.root}
      onChange={() => handleToggle(stepNumber)}
      TransitionProps={{ unmountOnExit: false }}
    ></Accordion>
  );
};

function styles({ palette, space, spacing }: Theme) {
  return {
    root: {
      boxShadow: 'none',

      '&:not([style])': {
        marginTop: 0,
      },

      '&::before': {
        height: 0,
      },

      '& .MuiAccordionSummary-root': {
        borderTop: `1px solid transparent`,
        boxShadow: 'none',
        minHeight: spacing(12),
        paddingLeft: 0,

        '&.Mui-expanded': {
          boxShadow: 'none',
          minHeight: spacing(12),
        },

        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
      },

      '& .MuiAccordionDetails-root': {
        borderLeft: `2px solid ${palette.divider}`,
        marginLeft: '8px',
        padding: `0 ${space.lg} ${space.md}`,
      },
    },
  };
}
