import { FormattedMessage as FM } from 'react-intl';

import { V1FindingTags } from '@endorlabs/api_client';

import { FindingTagsMessages } from '../../locales';

const DEFAULT_FINDING_TAG_MESSAGE =
  FindingTagsMessages[V1FindingTags.Unspecified];

export interface FindingTagLabelProps {
  findingTag?: V1FindingTags;
}

export const FindingTagLabel = ({
  findingTag = V1FindingTags.Unspecified,
}: FindingTagLabelProps) => {
  const message =
    FindingTagsMessages[findingTag] ?? DEFAULT_FINDING_TAG_MESSAGE;

  return <FM {...message} />;
};
