import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';

import {
  isPageNavigationGroupItem,
  PageNavigation,
} from '../../components/PageNavigation';
import { PageNavigationItem } from '../../components/PageNavigation/PageNavigationGroup';
import { useFilterContext } from '../../domains/filters';
import { FindingsPageNavigationItems } from '../../domains/Findings';

const CustomItem: PageNavigationItem = {
  isHidden: true,
  label: <FM defaultMessage="Custom" />,
  navKey: 'custom',
  suffix: undefined,
};

export const FindingsPageNavigation = () => {
  const { filter: activeFilterExpression, updateFilter } = useFilterContext();
  const { ENABLE_SAST_FEATURE: isSastEnabled } = useFeatureFlags();

  // Set filter value on the default item based on current filter state
  // Add the "Custom" item to top of list
  const navigationItems = useMemo((): PageNavigationItem[] => {
    const firstPartyCodeObject = FindingsPageNavigationItems.filter(
      (f) => f.navKey === 'firstPartyCode'
    )[0];

    const formattedList = isSastEnabled
      ? FindingsPageNavigationItems
      : [
          ...FindingsPageNavigationItems.filter(
            (f) => f.navKey !== 'firstPartyCode'
          ),
          {
            ...firstPartyCodeObject,
            groupItems: (firstPartyCodeObject?.groupItems || []).filter(
              (g) => g.navKey !== 'sast'
            ),
          },
        ];

    const allItems = [CustomItem].concat(formattedList);
    return allItems;
  }, [isSastEnabled]);

  // When comparing filters, only match against items with values
  const searchableItems = useMemo(() => {
    return navigationItems.reduce((acc: PageNavigationItem[], navItem) => {
      if (navItem.value) {
        acc.push(navItem);
      }

      // NOTE: Only goes a single level down
      if (isPageNavigationGroupItem(navItem)) {
        acc = acc.concat(
          (navItem.groupItems ?? []).filter((item: PageNavigationItem) =>
            Boolean(item.value)
          )
        );
      }
      return acc;
    }, []);
  }, [navigationItems]);

  // Determine if any nav items match current filter
  const selectedItemKey = useMemo(() => {
    const matchingItem = searchableItems.find((item) => {
      return item.value && activeFilterExpression.includes(item.value);
    });

    return matchingItem?.navKey;
  }, [activeFilterExpression, searchableItems]);

  // If there is a match, mark the "Custom" item as hidden
  navigationItems[0].isHidden = Boolean(selectedItemKey);

  const handleSelection = useCallback(
    (navKey: string) => {
      const selectedItem = searchableItems.find(
        (item) => item.navKey === navKey
      );
      if (!selectedItem) return;

      // @ts-expect-error nav items extend PageNavigationGroupProps
      const selectedFilterValues = selectedItem.filterValues;

      // Set filter based on expression, which is a general override
      if (selectedFilterValues) updateFilter({ values: selectedFilterValues });
    },
    [searchableItems, updateFilter]
  );

  return (
    <PageNavigation
      handleSelection={handleSelection}
      items={navigationItems}
      selectedItemKey={selectedItemKey ?? 'custom'}
    />
  );
};
