import { addHours, addMinutes, addSeconds, isValid } from 'date-fns';
import { FormattedMessage as FM } from 'react-intl';

import { FILTER_COMPARATORS, isValueFilter } from '@endorlabs/filters';

import { ControlsFacetFilterDatePicker } from '../../../../components/FacetedSearchBar/ControlsFacetFilterDatePicker';
import { FilterFieldConfig } from '../../../filters';

export const FindingTimeFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'meta.create_time',

  initialFilter: {
    key: 'meta.create_time',
    comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
    // Last 30 days
    value: `now(-720h)`,
  },

  format: (value) => {
    if (value && 'string' === typeof value) {
      const date = new Date(value);
      if (isValid(date)) {
        return {
          key: 'meta.create_time',
          comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
          value: `date(${date.toISOString()})`,
        };
      }
    }
  },

  parse: (filter) => {
    if (isValueFilter(filter) && 'string' === typeof filter.value) {
      // HACK: ensure date value is properly escaped for the filter, and parsed for the date picker component
      let dateValue = filter.value;
      if (dateValue.startsWith('date(')) {
        dateValue = dateValue.replace(/date\((.*)\)/, '$1');
      }

      // handle: parse date offset
      if (dateValue.startsWith('now(')) {
        const parsed = dateValue.match(/now\("?(.*)(\w)"?\)/);
        if (parsed) {
          const offset = Number(parsed[1]);
          const increment = parsed[2];

          if (!isNaN(offset)) {
            switch (increment) {
              case 's':
                dateValue = addSeconds(Date.now(), offset).toISOString();
                break;
              case 'm':
                dateValue = addMinutes(Date.now(), offset).toISOString();
                break;

              case 'h':
                dateValue = addHours(Date.now(), offset).toISOString();
                break;
            }
          }
        }
      }

      if (isValid(new Date(dateValue))) {
        return dateValue;
      }
    }
  },

  renderInput: ({ onChange, value }) => (
    <ControlsFacetFilterDatePicker
      onChange={onChange}
      value={value as any}
      facet={
        {
          label: <FM defaultMessage="When was the Finding first scanned?" />,
        } as any
      }
    />
  ),
} satisfies FilterFieldConfig<string>;
