import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Stack,
  Typography,
} from '@mui/material';
import {
  ComponentProps,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage as FM, MessageDescriptor } from 'react-intl';

import { IconChevronDown } from '../../themes';

export interface DrawerAccordionProps
  extends Partial<ComponentProps<typeof DrawerAccordionTitle>> {
  /**
   * Allow for a ref to be passed to the AccordionSummary control
   */
  accordionSummaryRef?: AccordionSummaryProps['ref'];
  align?: 'start' | 'end' | 'center';
  children?: ReactNode;
  expanded?: boolean;
  id: string;
  isCollapsable?: boolean;
  onChange?: (event: SyntheticEvent, isExpanded: boolean) => void;
  titleNode?: ReactNode;
}

export const DrawerAccordion = ({
  accordionSummaryRef,
  action,
  align = 'center',
  children,
  expanded = true,
  id,
  isCollapsable = true,
  onChange,
  title,
  titleNode,
}: DrawerAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    !isCollapsable ? true : expanded
  );

  useEffect(() => {
    if (isCollapsable) {
      setIsExpanded(expanded);
    }
  }, [expanded, isCollapsable]);

  const handleToggleExpanded = (event: SyntheticEvent, isExpanded: boolean) => {
    setIsExpanded(isExpanded);
    if (onChange) {
      onChange(event, isExpanded);
    }
  };

  const displayTitle = titleNode ? (
    titleNode
  ) : title ? (
    <DrawerAccordionTitle title={title} action={action} />
  ) : null;

  return (
    <Accordion
      className="DrawerAccordion-root"
      disableGutters // prevent margin on expanded state
      expanded={isExpanded}
      onChange={isCollapsable ? handleToggleExpanded : undefined}
      sx={{
        borderRadius: 0,
        boxShadow: 'none',
        padding: 0,
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        ref={accordionSummaryRef}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{
          padding: 0,

          '&.MuiAccordionSummary-root': {
            minHeight: 48,
            '&.Mui-expanded': { minHeight: 48 },
          },
        }}
      >
        <Stack alignItems={align} direction="row" sx={{ width: '100%' }}>
          {isCollapsable && (
            <IconChevronDown
              fontSize="small"
              sx={[
                {
                  marginRight: 2,
                  transition: 'transform 300ms',
                  transform: 'rotate(0)',
                },
                isExpanded && { transform: 'rotate(-180deg)' },
              ]}
            />
          )}
          {displayTitle}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ width: '100%' }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export const DrawerAccordionTitle = ({
  action,
  title,
}: {
  action?: ReactNode;
  title: MessageDescriptor;
}) => (
  <Stack
    alignItems="center"
    direction="row"
    justifyContent="space-between"
    width="100%"
  >
    <Typography variant="h6">
      <FM {...title} />
    </Typography>

    {action}
  </Stack>
);
