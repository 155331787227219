import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  ButtonCancel,
  ChecklistInputGroupFn,
  ControlledChecklist,
  FileDownloadFileType,
  IconDownload,
} from '@endorlabs/ui-common';

const DEFAULT_FILE_TYPES: FileDownloadFileType[] = ['csv'];

const messages = defineMessages({
  exportAction: {
    defaultMessage: `Export to {fileType, select,
        csv { CSV }
        json { JSON }
        yaml { YAML }
        other {File}
    }`,
  },
});

const defaultGroupBy: ChecklistInputGroupFn = (option) => {
  if ('groupKey' in option && 'string' === typeof option.groupKey) {
    return option.groupKey;
  }

  return option.key;
};

export type ExportResourceColumn<Key extends string = string> = {
  groupKey?: string;
  isDefault?: boolean;
  isGroupHeader?: boolean;
  key: Key;
  label: string;
};

export type FormExportResourceFieldValues = {
  columns: ExportResourceColumn[];
  fileType: FileDownloadFileType;
};

export type FormExportResourceProps = {
  columns: ExportResourceColumn[];
  enableGrouping?: boolean;
  fileTypes?: FileDownloadFileType[];
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: (values: FormExportResourceFieldValues) => void;
};

/**
 * Form to manage the export of a resource.
 */
export const FormExportResource = ({
  columns,
  enableGrouping,
  fileTypes = DEFAULT_FILE_TYPES,
  isLoading,
  onSubmit,
  onCancel,
}: FormExportResourceProps) => {
  const [columnOptions, columnsDefaultValues] = useMemo(() => {
    const options = [];
    const defaultValue = [];

    for (const c of columns) {
      // Omit option if grouping is disabled
      if (!enableGrouping && c.isGroupHeader) continue;

      options.push(c);
      if (c.isDefault) {
        defaultValue.push(c);
      }
    }

    return [options, defaultValue];
  }, [columns, enableGrouping]);

  const formMethods = useForm<FormExportResourceFieldValues>({
    defaultValues: {
      columns: columnsDefaultValues,
      fileType: fileTypes[0],
    },
  });
  const { handleSubmit: hookFormSubmit } = formMethods;

  const fileTypeState = formMethods.watch('fileType');

  const handleSubmit = ({
    columns,
    fileType,
  }: FormExportResourceFieldValues) => {
    // Filter out column options provided as group headers
    const filtered = columns.filter((c) => !c.isGroupHeader);
    onSubmit({ fileType, columns: filtered });
  };

  // set the file type and trigger a submit
  const handleSubmitWithFileType =
    (fileType: FileDownloadFileType) => (event: MouseEvent) => {
      formMethods.setValue('fileType', fileType);
      // HACK: manually triggering form submission
      formMethods.handleSubmit(handleSubmit)(event);
    };

  return (
    <FormProvider {...formMethods}>
      <form id="FormExportResource" onSubmit={hookFormSubmit(handleSubmit)}>
        <ControlledChecklist
          groupBy={enableGrouping ? defaultGroupBy : undefined}
          label={<FM defaultMessage="Select Columns" />}
          name="columns"
          options={columnOptions}
        />

        <Stack direction="row" marginTop={4} spacing={4}>
          {fileTypes.map((fileType) => (
            <LoadingButton
              variant="contained"
              loading={fileTypeState === fileType && isLoading}
              disabled={fileTypeState !== fileType && isLoading}
              key={fileType}
              onClick={handleSubmitWithFileType(fileType)}
              startIcon={<IconDownload fontSize="inherit" />}
            >
              <FM {...messages.exportAction} values={{ fileType }} />
            </LoadingButton>
          ))}

          {onCancel && (
            <ButtonCancel onClick={onCancel} variant="outlined">
              <FM defaultMessage="Cancel" />
            </ButtonCancel>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
