import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  DemoServiceApi,
  V1CountResponse,
  V1Demo,
  V1ListParameters,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { DemoResource } from '@endorlabs/endor-core/Demo';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceListResponse,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildListParamArgs,
  buildResourceMutateMeta,
  getClientConfiguration,
} from './utils';

interface DemoReadParams {
  namespace: string;
  uuid: string;
}

interface DemoWriteParams {
  namespace: string;
  resource: V1Demo;
}

type ListDemosOptions = ResourceQueryOptions<
  ResourceListResponse<DemoResource>
>;
type GetDemoOptions = ResourceQueryOptions<DemoResource>;
type CreateDemoOptions = ResourceMutateOptions<V1Demo, DemoWriteParams>;

const BASE_KEY = 'v1/demo';
const QK = {
  count: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, listParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const DemoQueryKeys = QK;

const getApiService = () => new DemoServiceApi(getClientConfiguration());

const createDemo = async (params: DemoWriteParams) => {
  const api = getApiService();
  const resp = await api.demoServiceCreateDemo(
    params.namespace,
    params.resource
  );
  return resp.data;
};

export const useCreateDemo = (opts: CreateDemoOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Demo'),
    mutationFn: (params: DemoWriteParams) => createDemo(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.count(vars.namespace));
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const getDemo = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.demoServiceGetDemo(namespace, uuid);
  return resp.data as DemoResource;
};

export const useGetDemo = (
  params: DemoReadParams,
  opts: GetDemoOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getDemo(params.namespace, params.uuid),
    opts
  );
};

const listDemos = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.demoServiceListDemos(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data.list as ResourceListResponse<DemoResource>;
};

export const useListDemos = (
  namespace: string,
  opts: ListDemosOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Demo',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listDemos(namespace, requestParameters),
    opts
  );
};
