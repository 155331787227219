import { V1Project, V1RepositoryVersion } from '@endorlabs/api_client';

/**
 * TODO: if a repo version specific scan time is added in the future, prefer
 * that value over the project analytic time.
 */
export const getLastAnalyticScanTime = (
  project?: V1Project,
  repositoryVersion?: V1RepositoryVersion
) => {
  return project?.processing_status?.analytic_time;
};

export const getLastCodeScanTime = (
  project?: V1Project,
  repositoryVersion?: V1RepositoryVersion
) => {
  return (
    repositoryVersion?.scan_object?.scan_time ??
    project?.processing_status?.scan_time
  );
};
