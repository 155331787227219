import {
  DashboardConfigServiceApi,
  V1CountResponse,
  V1DashboardConfig,
} from '@endorlabs/api_client';
import { DashboardConfigResource } from '@endorlabs/endor-core/DashboardConfig';

import { createResourceMutateHooks, createResourceReadHooks } from './hooks';
import { ResourceGroupResponse, ResourceListResponse } from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

const DEFAULT_UPDATE_MASK = 'spec';

const queries = createResourceReadHooks('DashboardConfig', () => {
  const getApiService = () =>
    new DashboardConfigServiceApi(getClientConfiguration());

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp =
        await getApiService().dashboardConfigServiceListDashboardConfig(
          namespace,
          ...args,
          options
        );
      return resp.data.count_response as Required<V1CountResponse>;
    },
    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp =
        await getApiService().dashboardConfigServiceGetDashboardConfig(
          namespace,
          ...args,
          options
        );
      return resp.data as DashboardConfigResource;
    },
    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().dashboardConfigServiceListDashboardConfig(
          namespace,
          ...args,
          options
        );
      return resp.data.group_response as ResourceGroupResponse;
    },
    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().dashboardConfigServiceListDashboardConfig(
          namespace,
          ...args,
          options
        );
      return resp.data.list as ResourceListResponse<DashboardConfigResource>;
    },
  };
});

const mutations = createResourceMutateHooks<DashboardConfigResource>(
  'DashboardConfig',
  () => {
    const getApiService = () =>
      new DashboardConfigServiceApi(getClientConfiguration());

    return {
      async create(namespace, resource) {
        const resp =
          await getApiService().dashboardConfigServiceCreateDashboardConfig(
            namespace,
            resource as V1DashboardConfig
          );

        return resp.data as DashboardConfigResource;
      },
      async delete(namespace, resource) {
        await getApiService().dashboardConfigServiceDeleteDashboardConfig(
          namespace,
          resource.uuid
        );
      },
      async update(namespace, params) {
        const { resource, mask = DEFAULT_UPDATE_MASK } = params;
        const request = buildUpdateReq(resource, mask);
        const resp =
          await getApiService().dashboardConfigServiceUpdateDashboardConfig(
            namespace,
            request
          );

        return resp.data as DashboardConfigResource;
      },
    };
  }
);

// Expose query hooks for DashboardConfig
export const useCountDashboardConfig = queries.useCount;
export const useGetDashboardConfig = queries.useGet;
export const useGroupDashboardConfig = queries.useGroup;
export const useListDashboardConfig = queries.useList;

// Expose mutation hooks for DashboardConfig
export const useCreateDashboardConfig = mutations.useCreate;
export const useDeleteDashboardConfig = mutations.useDelete;
export const useUpdateDashboardConfig = mutations.useUpdate;
