import { Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  AttributeDisplayStack,
  RelativeTimeDisplay,
} from '@endorlabs/ui-common';

import { FindingsDetailSASTSectionProps } from '../../types';
import { ConfidenceDisplaySection } from './ConfidenceDisplaySection';

const genResource = ({
  finding,
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const resource = {
    confidence: (
      <ConfidenceDisplaySection
        confidence={
          sastRuleForFinding?.spec?.rule?.metadata?.confidence?.toUpperCase() ??
          'UNKNOWN'
        }
        showIcon={true}
        showLabel={true}
        size="small"
      />
    ),
    impact: (
      <ConfidenceDisplaySection
        confidence={
          sastRuleForFinding?.spec?.rule?.metadata?.impact?.toUpperCase() ??
          'UNKNOWN'
        }
        showIcon={true}
        showLabel={true}
        size="small"
      />
    ),
    firstScanned: (
      <RelativeTimeDisplay value={finding?.meta.create_time ?? ''} />
    ),
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'confidence',
      heading: <FM defaultMessage="Confidence" />,
    },
    {
      attributeKey: 'impact',
      heading: <FM defaultMessage="Impact" />,
    },
    {
      attributeKey: 'firstScanned',
      heading: <FM defaultMessage="First Scanned" />,
    },
  ];
};

export const FindingSASTRiskSection = ({
  sastRuleForFinding,
  finding,
  isLoading,
  namespace,
}: FindingsDetailSASTSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, sastRuleForFinding }),
    [finding, namespace, sastRuleForFinding]
  );

  const { space } = useTheme();
  return (
    <Stack spacing={space.sm}>
      <AttributeDisplayStack
        attributeRecords={records}
        headingWidth="30%"
        isLoading={isLoading}
        resource={resource}
        variant="row"
      />
    </Stack>
  );
};
