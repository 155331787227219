import { IconButton, IconButtonProps, SvgIcon } from '@mui/material';

import { IconEdit, IconSidebarRight, IconTrash } from '../../themes';

interface DataTableActionButtonProps extends IconButtonProps {
  IconCmp: typeof SvgIcon;
}

export const DataTableActionButton = ({
  IconCmp,
  ...rest
}: DataTableActionButtonProps) => {
  const icon = <IconCmp fontSize="inherit" />;

  return (
    <IconButton {...rest} size="small">
      {icon}
    </IconButton>
  );
};

export const DataTableEditButton = (props: IconButtonProps) => (
  <DataTableActionButton IconCmp={IconEdit} {...props} />
);

export const DataTableDeleteButton = (props: IconButtonProps) => (
  <DataTableActionButton IconCmp={IconTrash} {...props} />
);

export const DataTableDrawerButton = (props: IconButtonProps) => (
  <DataTableActionButton IconCmp={IconSidebarRight} {...props} />
);
