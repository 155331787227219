import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { RowData, Table } from '@tanstack/react-table';
import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { DataTablePaginator } from './useDataTablePaginator';

export const KeyboardEventKeys = {
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
};

export interface DataTablePaginationProps<T extends RowData> {
  pageSizes: number[];
  paginator?: DataTablePaginator;
  table?: Table<T>;
}

export function DataTablePagination<T extends RowData>({
  pageSizes,
  paginator,
  table,
}: DataTablePaginationProps<T>) {
  const { formatMessage: fm } = useIntl();

  if (!table) return undefined;

  const onPageInputFocus = (event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  const onPageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numValue = value === '' ? 1 : parseInt(value, 10);
    const valid =
      event.target.checkValidity() &&
      numValue > 0 &&
      (paginator?.isInfinite || numValue <= table.getPageCount()); // enforce page limit when known

    if (!valid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      table.setPageIndex(numValue - 1);
    }
  };

  const onPageInputKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    switch (evt.key) {
      case KeyboardEventKeys.ARROW_UP:
        if (table.getCanNextPage()) {
          table.nextPage();
        }
        break;
      case KeyboardEventKeys.ARROW_DOWN:
        if (table.getCanPreviousPage()) {
          table.previousPage();
        }
        break;
      case KeyboardEventKeys.ENTER:
        (evt.target as HTMLInputElement)?.blur();
        break;
      default:
        break;
    }
  };

  const canPreviousPage = table.getCanPreviousPage();
  const canNextPage = paginator?.isInfinite
    ? paginator.hasNextPage()
    : table.getCanNextPage();

  return (
    <Grid alignItems="center" container justifyContent="flex-end" spacing={4}>
      <Grid item>
        <Grid alignItems="center" container>
          <Grid item>
            <FormLabel
              htmlFor="data-table-page-size"
              sx={{ marginRight: ({ spacing }) => spacing(2) }}
            >
              {fm({ defaultMessage: 'Rows per page' })}
            </FormLabel>
          </Grid>
          <Grid item>
            <Select
              aria-label={fm({
                defaultMessage: 'Rows Per Page',
              })}
              fullWidth
              id="data-table-page-size"
              onChange={(evt: SelectChangeEvent) => {
                table.setPageSize(parseInt(evt.target.value));
              }}
              value={`${table.getState().pagination.pageSize}`}
            >
              {pageSizes.map((ps) => (
                <MenuItem key={ps} value={ps}>
                  {ps}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid alignItems="center" container>
          <Grid item>
            <IconButton
              aria-label={fm({
                defaultMessage: 'Previous Page',
              })}
              disabled={!canPreviousPage}
              onClick={() => table.previousPage()}
            >
              <ChevronLeft />
            </IconButton>
          </Grid>

          <Grid item>
            <FormLabel>Page</FormLabel>
          </Grid>

          <Grid item>
            <OutlinedInput
              inputProps={{ pattern: '[0-9]+' }}
              // onBlur={() => gotoPage(pageIndex)}
              onChange={onPageInputChange}
              onFocus={onPageInputFocus}
              onKeyDown={onPageInputKeyDown}
              size="small"
              sx={({ spacing }) => ({
                margin: spacing(0, 2),
                maxWidth: spacing(12),
                '& .MuiInputBase-inputSizeSmall': {
                  paddingBottom: 0,
                  paddingTop: 0,
                },
              })}
              value={table.getState().pagination.pageIndex + 1}
            />
          </Grid>

          {/* hide total page count when infinite */}
          {!paginator?.isInfinite && (
            <Grid item>
              <Typography>
                <FM
                  defaultMessage="of {count, number}"
                  values={{ count: table.getPageCount() }}
                />
              </Typography>
            </Grid>
          )}

          <Grid item>
            <IconButton
              aria-label={fm({
                defaultMessage: 'Next page',
              })}
              disabled={!canNextPage}
              onClick={() => table.nextPage()}
            >
              <ChevronRight />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
