import { RowData, TableOptions } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import { DataTableColumnSettingsProps } from '../DataTableColumnSettings';
import { DataTableColumnDef } from '../types';
import {
  buildDataTableColumnOrder,
  buildDataTableVisibility,
  buildNextDataTableColumnOrder,
} from '../utils';
import {
  DataTableColumnPreferences,
  useDataTablePreferences,
} from './useDataTablePreferences';

interface UseDataTableColumnSettingsProps<T> {
  columns: DataTableColumnDef<T>[];
  enableColumnReorder?: boolean;
  enableColumnVisibility?: boolean;
  reorderableColumnHeading?: string;
  tableId?: string;
}

interface UseDataTableColumnSettingsReturnProps<T> {
  tableColumnOptions: Partial<TableOptions<T>>;
  columnSettingProps: DataTableColumnSettingsProps<T>;
}

export const useDataTableColumnSettings = <T extends RowData>({
  columns,
  enableColumnReorder = false,
  enableColumnVisibility = false,
  reorderableColumnHeading,
  tableId,
}: UseDataTableColumnSettingsProps<T>): UseDataTableColumnSettingsReturnProps<T> => {
  const { getTableColumnPreferences, setTableColumnPreferences } =
    useDataTablePreferences();

  const savedTableState = tableId
    ? getTableColumnPreferences(tableId)
    : undefined;

  const { columnOrder, hideableColumnOrder, reorderableColumnOrder } =
    useMemo(() => {
      // ignore preferred order if disabled
      const preferredColumnOrder = enableColumnReorder
        ? savedTableState?.columnOrder
        : undefined;
      return buildDataTableColumnOrder(columns, preferredColumnOrder);
    }, [columns, enableColumnReorder, savedTableState?.columnOrder]);

  const columnVisibility = useMemo(() => {
    // ignore preferred visibility if disabled
    const preferredColumnVisibility = enableColumnVisibility
      ? savedTableState?.columnVisibility
      : undefined;
    return buildDataTableVisibility(columnOrder, preferredColumnVisibility);
  }, [columnOrder, enableColumnVisibility, savedTableState?.columnVisibility]);

  const saveColumnState = useCallback(
    (update: DataTableColumnPreferences) => {
      if (!tableId) return;
      if (!enableColumnReorder && !enableColumnVisibility) return;

      let nextColumnOrder = reorderableColumnOrder;
      if (enableColumnReorder && update.columnOrder) {
        const preferredColumnOrder =
          savedTableState?.columnOrder ?? reorderableColumnOrder;
        nextColumnOrder = buildNextDataTableColumnOrder(
          preferredColumnOrder,
          update.columnOrder
        );
      }

      let nextColumnVisibility = columnVisibility;
      if (enableColumnVisibility && update.columnVisibility) {
        // merge the existing visibility with the given
        const preferredColumnVisibility =
          savedTableState?.columnVisibility ?? columnVisibility;
        nextColumnVisibility = {
          ...preferredColumnVisibility,
          ...update.columnVisibility,
        };
      }

      setTableColumnPreferences(tableId, {
        columnOrder: nextColumnOrder,
        columnVisibility: nextColumnVisibility,
      });
    },
    [
      columnVisibility,
      enableColumnReorder,
      enableColumnVisibility,
      reorderableColumnOrder,
      savedTableState?.columnOrder,
      savedTableState?.columnVisibility,
      setTableColumnPreferences,
      tableId,
    ]
  );

  return {
    tableColumnOptions: {
      state: { columnOrder, columnVisibility },
      // NOTE: Table hooks are not currently used
      // onColumnOrderChange: setColumnOrder,
      // onColumnVisibilityChange: setColumnVisibility,
    },
    columnSettingProps: {
      columns,
      columnVisibility,
      hideableColumnOrder,
      reorderableColumnOrder,
      reorderableColumnHeading,
      saveColumnState,
    },
  };
};
