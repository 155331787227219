import { useMemo } from 'react';

import {
  ScanResultSpecStatus,
  ScanResultSpecType,
  V1Context,
  V1EndorctlRC,
  V1ScanResult,
  V1ScanResultSpec,
} from '@endorlabs/api_client';
import { useProjectLatestScanResults } from '@endorlabs/ui-common';

type AllLogs = Exclude<V1ScanResultSpec['logs'], undefined>;
type ErrorLogs = Exclude<V1ScanResultSpec['errors'], undefined>;
type WarningLogs = Exclude<V1ScanResultSpec['warnings'], undefined>;
type InfoLogs = Exclude<V1ScanResultSpec['infos'], undefined>;

// type check to ensure Scan Result logs match expected
type LogMessage = (AllLogs | ErrorLogs | WarningLogs | InfoLogs)[number];

const SEVERITY_LEVELS = ['info', 'warning', 'error'] as const;

type LogSeverity = (typeof SEVERITY_LEVELS)[number];

// mappings from log level to severity level
const SEVERITY_LEVEL_MAPPING: Record<string, LogSeverity> = {
  warn: 'warning',
} as const;

export type ScanResultIssue = {
  severity: LogSeverity;
  title: string;
  description?: string;
};

export type ScanResultWithIssues = {
  data: string;
  issues: ScanResultIssue[];
  namespace: string;
  scanType: ScanResultSpecType;
  uuid: string;
  exitCode?: V1EndorctlRC;
};

const getScanResultsWithIssues = (
  allScanResults?: V1ScanResult[]
): [
  scanResults: ScanResultWithIssues[],
  scanIssueCount: number,
  hasBypassDoctor: boolean
] => {
  const filteredScanResults: Required<V1ScanResult>[] = [];
  let hasBypassDoctor = false;
  let scanIssueCount = 0;

  for (const sr of allScanResults ?? []) {
    if ('undefined' === typeof sr.spec || 'undefined' === typeof sr.uuid)
      continue;

    const status = sr.spec.status;
    const config = sr.spec.environment?.config;

    if (
      status === ScanResultSpecStatus.Failure ||
      status === ScanResultSpecStatus.PartialSuccess
    ) {
      filteredScanResults.push(sr as Required<V1ScanResult>);
    } else if (
      config &&
      'BypassDoctor' in config &&
      Boolean(config.BypassDoctor)
    ) {
      hasBypassDoctor = true;
    }
  }

  filteredScanResults.sort(
    (a, b) =>
      a.spec?.type?.localeCompare(
        b.spec?.type ?? ScanResultSpecType.Unspecified
      ) ?? 0
  );

  const scanResults: ScanResultWithIssues[] = filteredScanResults.map((sr) => {
    const issues: ScanResultIssue[] = mapScanResultIssues(sr);

    scanIssueCount += issues.length;

    return {
      data: JSON.stringify(sr, null, 2),
      issues,
      namespace: sr.tenant_meta.namespace,
      scanType: sr.spec?.type ?? ScanResultSpecType.Unspecified,
      uuid: sr.uuid,
      exitCode: sr.spec.exit_code,
    };
  });

  return [scanResults, scanIssueCount, hasBypassDoctor];
};

const mapScanResultIssues = (scanResult: V1ScanResult) => {
  const seen = new Set<string>();
  const issues: ScanResultIssue[] = [];

  const allLogMessages = [
    ...(scanResult.spec?.logs ?? []),
    // TODO: remove additional deprecated logs once removed
    ...(scanResult.spec?.errors ?? []),
    ...(scanResult.spec?.warnings ?? []),
    ...(scanResult.spec?.infos ?? []),
  ];

  for (const message of allLogMessages) {
    const issue = parseScanResultLogMessage(message);
    if (!issue) continue;

    // NOTE: de-duplicating logs from general `logs` field, and the level-specific
    // fields (`errors`, `warnings`, `infos`). This may be removed when the
    // deprecated fields are removed.
    const id = `${issue.severity}:${issue.title}`;
    if (!seen.has(id)) {
      seen.add(id);
      issues.push(issue);
    }
  }

  // sort descending by severity level
  issues.sort(
    (a, b) =>
      SEVERITY_LEVELS.indexOf(b.severity) - SEVERITY_LEVELS.indexOf(a.severity)
  );

  return issues;
};

const parseScanResultLogMessage = (log: LogMessage): ScanResultIssue | void => {
  // try to safely parse the error log
  let title: string | undefined;
  let description: string | undefined;
  let severity: LogSeverity | undefined;

  try {
    const parsed = JSON.parse(log);

    if (parsed.msg) {
      title = parsed.msg;
    }
    if (parsed.error) {
      description = parsed.error;
    }
    if (parsed.level) {
      if (SEVERITY_LEVELS.includes(parsed.level)) {
        severity = parsed.level;
      } else if (Reflect.has(SEVERITY_LEVEL_MAPPING, parsed.level)) {
        severity = Reflect.get(SEVERITY_LEVEL_MAPPING, parsed.level);
      }
    }
  } catch (e: unknown) {
    title = 'Failed to parse scan result log.';
  }

  // bail if parse failed
  if (!title || !severity) return;

  return { title, description, severity };
};

/**
 * Given a list of Scan Results, returns only the Scan Results with issues.
 *
 * Also checks for the existence of the `--bypass-host-check` flag for the scan.
 */
export const useScanResultsWithIssues = (
  namespace: string,
  projectUuid: string,
  scanContext?: V1Context,
  scanResults?: V1ScanResult[]
) => {
  const qScanResults = useProjectLatestScanResults(
    {
      namespace,
      projectUuid,
      scanContext,
    },
    { enabled: !!scanResults }
  );

  const [filteredScanResults, scanIssueCount, hasBypassDoctor] = useMemo(
    () => getScanResultsWithIssues(scanResults ?? qScanResults.data?.objects),
    [qScanResults.data, scanResults]
  );

  return {
    filteredScanResults,
    hasBypassDoctor,
    isLoading: qScanResults.isLoading,
    scanIssueCount,
  };
};
