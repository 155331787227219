import IconClose from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonCancel, ButtonDestructive } from '../../components';
import { ButtonPrimary } from '../Button';

export type ConfirmationDialogProps = PropsWithChildren<{
  titleText: ReactNode;
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  isDestructive?: boolean;
}>;

export const ConfirmationDialog = ({
  titleText,
  open,
  onCancel,
  onConfirm,
  children,
  confirmText = <FM defaultMessage="Confirm" />,
  cancelText = <FM defaultMessage="Cancel" />,
  isDestructive,
}: ConfirmationDialogProps) => {
  const { formatMessage: fm } = useIntl();
  const ConfirmButton = isDestructive ? ButtonDestructive : ButtonPrimary;

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="sm">
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            component="span"
            id="preview-modal-title"
            variant="inherit"
          >
            {titleText}
          </Typography>

          {onCancel && (
            <IconButton
              aria-label={fm({ defaultMessage: 'Close' })}
              onClick={onCancel}
              sx={{ marginRight: -2 }}
            >
              <IconClose />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>

      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        <ConfirmButton onClick={onConfirm}>
          <Typography>{confirmText}</Typography>
        </ConfirmButton>
        <ButtonCancel onClick={onCancel}>
          <Typography>{cancelText}</Typography>
        </ButtonCancel>
      </DialogActions>
    </Dialog>
  );
};
