import { Palette, TypeText } from '@mui/material';
import { get as _get, has as _has } from 'lodash-es';

import { GraphPathListColors } from './types';

export const getGraphPathListNodeColor = (
  palette: Palette,
  colorName: GraphPathListColors = 'primary'
): string | undefined => {
  if (!colorName) return;

  const paletteTextColors = palette.text;
  if (_has(paletteTextColors, colorName)) {
    return paletteTextColors[colorName as keyof TypeText];
  }

  if (_has(palette, [colorName, 'main'])) {
    return _get(palette, [colorName, 'main']);
  }
};
