import { LoadingButton } from '@mui/lab';
import { Button, Grid, Stack, TextField, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { V1IdentityProvider } from '@endorlabs/api_client';
import {
  getClientConfiguration,
  IdentityProviderResource,
} from '@endorlabs/queries';

import { ControlledTextField, SimpleDialog } from '../../../components';
import { useAppNotify } from '../../layout';
import { DEFAULT_IDENTITY_PROVIDERS } from '../constants';
import {
  IdentityProviderFieldValues,
  IdentityProviderType,
  IdentityProviderTypes,
} from '../types';
import { fromIdentityProviderModel, toIdentityProviderModel } from '../utils';
import { OidcIdentityProviderFields } from './OidcIdentityProviderFields';
import { SamlIdentityProviderFields } from './SamlIdentityProviderFields';

export interface FormUpsertIdentityProviderProps {
  handleDelete: () => void;
  identityProvider?: IdentityProviderResource;
  identityProviderType: IdentityProviderType;
  isDeleting?: boolean;
  isLoading?: boolean;
  isSubmitting?: boolean;
  namespace: string;
  onSubmit: (data: V1IdentityProvider) => void;
}

interface Cert {
  Data: string;
}
interface KeyDescriptor {
  KeyInfo: {
    X509Data: {
      X509Certificates: Cert[];
    };
  };
}

interface AssertionConsumerService {
  Location: string;
}
interface SamlConfig {
  EntityID: string;
  SPSSODescriptor: {
    AssertionConsumerServices: AssertionConsumerService[];
    KeyDescriptors: KeyDescriptor[];
  };
}

/**
 * Form used to create or edit an Custom Identity Provider
 *
 * Currently, only manages 'global' permissions for a user
 */
export const FormUpsertIdentityProvider = ({
  handleDelete,
  identityProvider,
  identityProviderType,
  isDeleting,
  isLoading,
  isSubmitting,
  namespace,
  onSubmit,
}: FormUpsertIdentityProviderProps) => {
  const { space } = useTheme();

  // Set default values for form
  const defaultValues = useMemo(() => {
    return fromIdentityProviderModel(
      identityProvider ?? DEFAULT_IDENTITY_PROVIDERS[identityProviderType]
    );
  }, [identityProvider, identityProviderType]);

  const formMethods = useForm<IdentityProviderFieldValues>({
    mode: 'onTouched',
    defaultValues,
  });

  const { handleSubmit: hookFormSubmit, reset } = formMethods;

  // reset the form with defaultValues
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, identityProvider, reset]);

  const handleSubmit = useCallback(
    (fieldValues: IdentityProviderFieldValues) => {
      const model = toIdentityProviderModel(fieldValues, identityProviderType);
      // console.log('FV', fieldValues);
      // console.log('MODEL', model);
      onSubmit(model);
    },
    [identityProviderType, onSubmit]
  );

  const [samlConfig, setSamlConfig] = useState<SamlConfig | undefined>(
    undefined
  );
  const addAppNotification = useAppNotify();

  const handleShowConfig = useCallback(async () => {
    const axiosConfig = getClientConfiguration();

    const response = await fetch(
      `${axiosConfig.basePath}/v1/auth/saml-metadata?tenant=${namespace}`,
      {
        credentials: 'include',
        headers: {
          ...axiosConfig.baseOptions.headers,
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      setSamlConfig(data);
    } else {
      addAppNotification({
        message: (
          <FM defaultMessage="There was a problem retrieving SAML config details" />
        ),
        severity: 'error',
      });
    }
  }, [addAppNotification, namespace]);

  const isWaiting = isLoading || isDeleting || isSubmitting;

  return (
    <Grid container direction="column" rowSpacing={5} mt={3}>
      <FormProvider {...formMethods}>
        <form
          id="FormUpsertIdentityProvider"
          onSubmit={hookFormSubmit(handleSubmit)}
        />
        <Grid item>
          {/* NOTE: Selectively rendering here ultimately determines what fields will be sent to the form submission  */}
          {identityProviderType === IdentityProviderTypes.OIDC && (
            <OidcIdentityProviderFields />
          )}

          {identityProviderType === IdentityProviderTypes.SAML && (
            <SamlIdentityProviderFields />
          )}

          <ControlledTextField
            control={formMethods.control}
            defaultValue={namespace}
            name="tenant_meta.namespace"
            type="hidden"
          />
        </Grid>

        <Grid item>
          <Stack direction="row" spacing={space.xs}>
            <LoadingButton
              loading={isWaiting}
              onClick={hookFormSubmit(handleSubmit)}
              type="submit"
              variant="contained"
            >
              {!isWaiting && <FM defaultMessage="Save Configuration" />}
            </LoadingButton>

            <LoadingButton
              color="error"
              disabled={isWaiting || !identityProvider}
              loading={isDeleting}
              onClick={handleDelete}
              variant="contained"
            >
              {!isDeleting && (
                <FM defaultMessage="Delete Custom Identity Provider" />
              )}
            </LoadingButton>

            {identityProvider &&
              !samlConfig &&
              identityProviderType === IdentityProviderTypes.SAML && (
                <Button onClick={handleShowConfig}>
                  <FM defaultMessage="View Configuration" />
                </Button>
              )}
          </Stack>
        </Grid>
      </FormProvider>

      {samlConfig && (
        <SimpleDialog
          descriptionText={
            <FM defaultMessage="Add this configuration to your identity provider" />
          }
          open={!!samlConfig}
          onClose={() => setSamlConfig(undefined)}
          titleText={<FM defaultMessage="View SAML Configuration" />}
        >
          <Stack spacing={space.md}>
            <TextField
              InputProps={{ readOnly: true }}
              label={<FM defaultMessage="SSO URL" />}
              value={samlConfig.EntityID}
            />
            <TextField
              InputProps={{ readOnly: true }}
              label={<FM defaultMessage="Audience URI" />}
              value={
                samlConfig.SPSSODescriptor.AssertionConsumerServices[0]
                  ?.Location
              }
            />
            <TextField
              InputProps={{ readOnly: true }}
              label={<FM defaultMessage="Encryption Certificate" />}
              multiline
              rows={7}
              value={
                samlConfig.SPSSODescriptor.KeyDescriptors[0]?.KeyInfo.X509Data
                  ?.X509Certificates[0]?.Data
              }
            />
          </Stack>
        </SimpleDialog>
      )}
    </Grid>
  );
};
