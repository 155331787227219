import { defineMessage } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { FindingLevelMessages } from '@endorlabs/ui-common';

import { FindingPriorityBucketNames } from '../../../domains/FindingPriorityBuckets/constants';
import { FindingPriorityBucketLabels } from '../../../domains/FindingPriorityBuckets/locales';
import { DashboardBucketContexts } from '../types';

export const DashboardBucketContextChartMessageKeys = {
  [DashboardBucketContexts.FINDING_SEVERITY]: {
    Finding: new Map([
      [SpecFindingLevel.Critical, FindingLevelMessages.FINDING_LEVEL_CRITICAL],
      [SpecFindingLevel.High, FindingLevelMessages.FINDING_LEVEL_HIGH],
      [SpecFindingLevel.Medium, FindingLevelMessages.FINDING_LEVEL_MEDIUM],
      [SpecFindingLevel.Low, FindingLevelMessages.FINDING_LEVEL_LOW],
    ]),
    RepositoryCommit: new Map([
      ['commit', defineMessage({ defaultMessage: 'commit' })],
    ]),
  },

  [DashboardBucketContexts.FINDING_PRIORITY_BUCKETS]: {
    Finding: new Map([
      [
        FindingPriorityBucketNames.PRIORITIZE,
        FindingPriorityBucketLabels.PRIORITIZE,
      ],
      [FindingPriorityBucketNames.PLAN, FindingPriorityBucketLabels.PLAN],
      [FindingPriorityBucketNames.BACKLOG, FindingPriorityBucketLabels.BACKLOG],
      [FindingPriorityBucketNames.DEFER, FindingPriorityBucketLabels.DEFER],
      [
        FindingPriorityBucketNames.OFF_RADAR,
        FindingPriorityBucketLabels.OFF_RADAR,
      ],
    ]),
  },
};
