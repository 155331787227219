import { useCallback, useEffect, useReducer, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { V1Invitation } from '@endorlabs/api_client';
import { useCreateInvitation } from '@endorlabs/queries';

import { InvitationDialogProps } from '../components';

interface InvitationDialogState {
  count: number;
  sent: string[];
}

interface InvitationDialogAction {
  type: 'sent' | 'queue' | 'clear';
  emails: string[];
}

const invitationDialogReducer = (
  state: InvitationDialogState,
  action: InvitationDialogAction
) => {
  switch (action.type) {
    case 'queue': {
      return {
        count: action.emails.length,
        sent: state.sent,
      };
    }
    case 'sent': {
      return {
        count: state.count,
        sent: state.sent.concat(action.emails),
      };
    }
    case 'clear': {
      return { count: 0, sent: [] };
    }
    default:
      return { ...state };
  }
};

const getInvitationNotification = ({ sent }: InvitationDialogState) => {
  const details =
    sent.length === 1 ? (
      <FM
        defaultMessage="An invitation was sent to {email}"
        values={{ email: sent[0] }}
      />
    ) : (
      <FM
        defaultMessage="Invitations sent to {count} users"
        values={{ count: sent.length }}
      />
    );

  return {
    message: <FM defaultMessage="Invitation Sent" />,
    details,
  };
};

export type UseInvitationDialogProps = {
  namespace: string;
  onNotification?: (
    notification: ReturnType<typeof getInvitationNotification>
  ) => void;
};

/**
 * Utility hook to Manage Invitation Dialog
 */
export const useInvitationDialog = ({
  namespace,
  onNotification,
}: UseInvitationDialogProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const [state, dispatch] = useReducer(invitationDialogReducer, {
    count: 0,
    sent: [],
  });

  useEffect(() => {
    if (state.sent.length === state.count && state.count > 0) {
      const notification = getInvitationNotification(state);

      // TODO: show snackbar notification on successful send. Reset state on close
      // notify(notification, { onClose: () => dispatch({ type: 'clear', emails: [] }) })

      // TEMP: expose notification to caller, to allow display local notification
      if (onNotification) {
        onNotification(notification);
      }
    }
  }, [state, onNotification]);

  const qInvitationCreate = useCreateInvitation({
    onSuccess: (resp: V1Invitation) => {
      dispatch({
        type: 'sent',
        emails: [resp?.spec?.user_email as string],
      });
    },
  });

  const handleSubmit = useCallback(
    (formData: FieldValues) => {
      const emails = formData.emails.split(',').map((v: string) => v.trim());

      dispatch({ type: 'queue', emails });

      emails.forEach((email: string) => {
        qInvitationCreate.mutate({
          namespace,
          resource: {
            meta: { name: `${email} auth for ${namespace}` },
            spec: {
              user_email: email,
            },
          },
        });
      });

      setShowModal(false);
    },
    [namespace, qInvitationCreate]
  );

  const getInvitationDialogProps = useCallback<() => InvitationDialogProps>(
    () => ({
      namespace,
      open: showModal,
      onClose: () => setShowModal(false),
      onSubmit: handleSubmit,
    }),
    [handleSubmit, namespace, showModal]
  );

  return {
    getInvitationDialogProps,
    openInvitationDialog: handleOpenModal,
    resetInvitationDialog: () => dispatch({ type: 'clear', emails: [] }),
  };
};
