import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  V1Method as ServiceRequestMethodType,
  V1RequestStatus as ServiceRequestStatusType,
} from '@endorlabs/api_client';

/**
 * Map of ServiceRequestStatus Categories to internationalized, human-readable labels.
 */
export const ServiceRequestStatusTypeMessages: Record<
  ServiceRequestStatusType,
  MessageDescriptor
> = defineMessages({
  [ServiceRequestStatusType.Approved]: { defaultMessage: 'Approved' },
  [ServiceRequestStatusType.Denied]: { defaultMessage: 'Denied' },
  [ServiceRequestStatusType.Completed]: { defaultMessage: 'Completed' },
  [ServiceRequestStatusType.Failed]: { defaultMessage: 'Failed' },
  [ServiceRequestStatusType.Unspecified]: { defaultMessage: 'Pending' },
});

export const ServiceRequestMethodTypeMessages: Record<
  ServiceRequestMethodType,
  MessageDescriptor
> = defineMessages({
  [ServiceRequestMethodType.Update]: { defaultMessage: 'Update' },
  [ServiceRequestMethodType.Delete]: { defaultMessage: 'Delete' },
  [ServiceRequestMethodType.Create]: { defaultMessage: 'Create' },
  [ServiceRequestMethodType.Unspecified]: {
    defaultMessage: 'Unspecified',
  },
  [ServiceRequestMethodType.Read]: { defaultMessage: 'Read' },
  [ServiceRequestMethodType.All]: { defaultMessage: 'All' },
});
