import { Card, CardContent, CardHeader } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';

import { NewProjectEndorCtlCli } from './NewProjectEndorCtlCli';

interface NewProjectGitlabProps {
  namespace: string;
}

export const NewProjectGitlab = ({ namespace }: NewProjectGitlabProps) => {
  return (
    <Card>
      <CardHeader
        title={
          <FM defaultMessage="Scan GitLab repositories with the Endor CLI" />
        }
      />
      <CardContent>
        <NewProjectEndorCtlCli
          tenantName={namespace}
          platformSource={V1PlatformSource.Gitlab}
        />
      </CardContent>
    </Card>
  );
};
