import { Stack, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM, MessageDescriptor } from 'react-intl';

export interface DescriptionListProps {
  children: ReactNode | ReactNode[];
  spacing?: number;
  title?: MessageDescriptor;
  titleTypographyProps?: TypographyProps;
}

export const DescriptionList = ({
  children,
  spacing = 6,
  title,
  titleTypographyProps = {
    variant: 'h6',
  },
}: DescriptionListProps) => (
  <Stack direction="column" spacing={4}>
    {title && (
      <Typography {...titleTypographyProps}>
        <FM {...title} />
      </Typography>
    )}
    <Stack component="dl" marginTop={0} spacing={spacing}>
      {children}
    </Stack>
  </Stack>
);
