import { castArray } from 'lodash-es';
import { useMemo } from 'react';

import { V1FindingCategory } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { FILTER_COMPARATORS, walkFilter } from '@endorlabs/filters';

import { FilterFieldConfig, FilterState } from '../../filters';
import { FINDING_FILTER_FIELDS } from '../constants';

export const useFindingsFilterFields = (
  findingSource: FindingSource,
  options?: {
    exclude?: string[];
    filterState?: FilterState;
  }
): FilterFieldConfig[] => {
  const filteredFindingCategories = useMemo(() => {
    const filteredFindingCategories: V1FindingCategory[] = [];

    // HACK: get the actively filtered finding categories from the filter state
    options?.filterState?.values?.forEach((valueFilter) => {
      walkFilter(valueFilter, {
        onVisit: (valueFilter) => {
          if (
            valueFilter.key === 'spec.finding_categories' &&
            valueFilter.comparator === FILTER_COMPARATORS.CONTAINS
          ) {
            const categories = castArray(
              valueFilter.value
            ) as V1FindingCategory[];

            filteredFindingCategories.push(...categories);
          }
        },
      });
    });

    return filteredFindingCategories;
  }, [options?.filterState?.values]);

  const showContainerLayersField =
    findingSource === FindingSource.Container ||
    filteredFindingCategories.includes(V1FindingCategory.Container);

  return useMemo(() => {
    let fields = FINDING_FILTER_FIELDS;

    // Allow excluding custom fields
    if (options?.exclude?.length) {
      fields = fields.filter((f) => !options.exclude?.includes(f.id));
    }

    // Remove non-applicable filters for containers
    if (!showContainerLayersField) {
      fields = fields.filter(
        (f) => f.id !== 'spec.finding_metadata.container_data.has_base_layer'
      );
    }

    // Remove non-applicable filters for repositories
    if (findingSource === FindingSource.Repository) {
      return fields.filter(
        (field) =>
          field.id !== 'spec.ecosystem' &&
          field.id !==
            'spec.finding_metadata.vulnerability.spec.epss_score.probability_score'
      );
    }

    // Remove non-applicable filters for secrets
    // TODO(UI-283): support filtering by secret valid/invalid
    if (findingSource === FindingSource.Secrets) {
      return fields.filter(
        (field) => field.id === 'spec.level' || field.id === 'meta.create_time'
      );
    }

    return fields;
  }, [findingSource, options?.exclude, showContainerLayersField]);
};
