import { Theme } from '@mui/material';

export const getTabsOverrides = (theme: Theme) => {
  const { palette, typography } = theme;

  return {
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.h4,

          '&.Mui-selected': {
            color: palette.text.primary,
          },
        },
      },
    },

    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  };
};
