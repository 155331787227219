import { FilterFieldConfig } from '../../../filters';
import { FindingAttributesFilterFieldConfig } from './FindingAttributesFilterFieldConfig';
import { FindingCategoryFilterFieldConfig } from './FindingCategoryFilterFieldConfig';
import { FindingContainerLayersFilterFieldConfig } from './FindingContainerLayersFilterFieldConfig';
import { FindingEcosystemFilterFieldConfig } from './FindingEcosystemFilterFieldConfig';
import { FindingEpssProbabilityFilterFieldConfig } from './FindingEpssProbabilityFilterFieldConfig';
import { FindingExceptionsFilterFieldConfig } from './FindingExceptionsFilterFieldConfig';
import { FindingLevelFilterFieldConfig } from './FindingLevelFilterFieldConfig';
import { FindingTimeFilterFieldConfig } from './FindingTimeFilterFieldConfig';

// Shared Findings filter field definitions
export const FINDING_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  FindingLevelFilterFieldConfig,
  FindingExceptionsFilterFieldConfig,
  FindingCategoryFilterFieldConfig,
  FindingAttributesFilterFieldConfig,
  FindingEpssProbabilityFilterFieldConfig,
  FindingEcosystemFilterFieldConfig,
  FindingContainerLayersFilterFieldConfig,
  FindingTimeFilterFieldConfig,
];

/**
 * Default search keys for use with Finding filters
 */
export const FINDING_SEARCH_KEYS = [
  'meta.description',
  'meta.tags',
  'spec.finding_metadata.vulnerability.spec.aliases',
];
