import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '@endorlabs/ui-common';

import { PatchConfigurationDialogContent } from './PatchConfigurationDialogContent';
import { PatchConfigurationDialogState } from './types';

export type PatchConfigurationDialogProps = Pick<
  SimpleDialogProps,
  'onClose' | 'open'
> & {
  state?: PatchConfigurationDialogState;
};

export const PatchConfigurationDialog = ({
  onClose,
  open,
  state,
}: PatchConfigurationDialogProps) => {
  return (
    <SimpleDialog
      descriptionText={
        <FM defaultMessage="The Endor Labs Patch Factory contains our repository of secure artifacts." />
      }
      onClose={onClose}
      open={open}
      titleText={<FM defaultMessage="Connect to Endor Labs Patch Factory" />}
    >
      {state && <PatchConfigurationDialogContent {...state} />}
    </SimpleDialog>
  );
};
