/**
 * Known key values for the meta.annotations field for API objects
 */
export enum MetaAnnotations {
  /**
   * This annotation is set on a Project or SystemConfig object to enable
   * auto-detection for tools in an endorctl scan.
   */
  EndorScanEnableBuildToolsVersionDetection = 'ENDOR_SCAN_ENABLE_BUILD_TOOLS_VERSION_DETECTION',
}
