import { Box, Grid, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import _castArray from 'lodash-es/castArray';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ControlledLabelsField, IconHelp } from '@endorlabs/ui-common';

import { PolicyTemplateParameterField } from '../types';
import { PolicyParameterFieldFromData } from './PolicyParameterFieldFromData';
import { PolicyParameterFieldFromQuery } from './PolicyParameterFieldFromQuery';

interface PolicyParameterFieldsProps {
  parameters: PolicyTemplateParameterField[];
}

/**
 * Form fields to configure policy parameters from a template
 */
export const PolicyParameterFields = ({
  parameters,
}: PolicyParameterFieldsProps) => {
  const { space } = useTheme();
  const { control, watch } = useFormContext();
  const { formatMessage: fm } = useIntl();

  return (
    <Grid container direction="column" spacing={space.sm}>
      {parameters.map((param) => {
        const possible_values = param.possible_values ?? [];
        const hasPossibleValues = possible_values.length > 0;
        const possible_values_from_db = param.possible_values_from_db;
        const allowMultiple = param.multiple_ok === true;
        const parameterFieldName = `spec.template_values.${param.value}.values`;
        const parameterFieldValue = _castArray(watch(parameterFieldName) ?? []);

        const displayAsLargeMultiselect = Boolean(possible_values_from_db);
        const displayAsLabelsField =
          allowMultiple && !hasPossibleValues && !displayAsLargeMultiselect;

        // Build rows using parameter values. How
        return (
          <Grid
            container
            item
            key={param.value}
            spacing={space.sm}
            sx={{ paddingLeft: 0 }}
            xs={12}
          >
            {/* Display parameter label & description on left for most fields */}
            {!displayAsLargeMultiselect && (
              <Grid item width="260px">
                <Box
                  sx={({ palette, spacing }) => ({
                    alignItems: 'center',
                    backgroundColor: palette.grey[100],
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '260px',
                    padding: spacing(2, 4),
                  })}
                >
                  <Typography component="label" htmlFor={`field-${param.name}`}>
                    {param.name}
                  </Typography>
                  <Tooltip
                    title={
                      param.description ??
                      fm({
                        defaultMessage: 'No description provided',
                      })
                    }
                  >
                    <IconHelp
                      sx={({ palette }) => ({
                        color: palette.text.secondary,
                      })}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            )}

            {displayAsLabelsField && (
              <Grid flexGrow={3} item>
                <ControlledLabelsField
                  control={control}
                  defaultValue={[]}
                  fullWidth
                  name={`spec.template_values.${param.value}.values`}
                />
              </Grid>
            )}

            {/* If param has possible values (not from db), provide a select, otherwise a plain text field */}
            {!displayAsLabelsField && !displayAsLargeMultiselect && (
              <Grid flexGrow={3} item>
                <PolicyParameterFieldFromData
                  allowMultiple={allowMultiple}
                  inputFieldName={param.name}
                  possibleValues={possible_values}
                  parameterFieldName={parameterFieldName}
                  parameterFieldValue={parameterFieldValue}
                />
              </Grid>
            )}

            {/* If param has possible_values_from_db, provide special handling */}
            {displayAsLargeMultiselect && (
              <Grid item xs={12}>
                <Stack spacing={space.xs}>
                  <Stack direction="row" spacing={space.xs}>
                    <Typography
                      component="label"
                      htmlFor={`field-${param.name}`}
                    >
                      {param.name}
                    </Typography>
                    <Tooltip
                      title={
                        param.description ??
                        fm({
                          defaultMessage: 'No description provided',
                        })
                      }
                    >
                      <IconHelp
                        sx={({ palette }) => ({
                          color: palette.text.secondary,
                        })}
                      />
                    </Tooltip>
                  </Stack>

                  <PolicyParameterFieldFromQuery
                    parameter={param}
                    parameterFieldName={parameterFieldName}
                    parameterFieldValue={parameterFieldValue}
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
