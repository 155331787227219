import { Slide, Theme } from '@mui/material';
import { createBreakpoints } from '@mui/system';

import { breakpoints } from './breakpoints';

const themeBreakpoints = createBreakpoints(breakpoints);

export const getDialogOverrides = ({ spacing }: Theme) => {
  const DIALOG_PADDING = spacing(10);

  return {
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: 'md',
        scroll: 'body',
        TransitionComponent: Slide,
        TransitionProps: {
          direction: 'up',
        },
      },

      styleOverrides: {
        root: {
          // MUI default is rgba(0, 0, 0, 0.5)
          backgroundColor: 'hsla(180, 33%, 12%, 0.5)',
        },
        container: {
          '&, &.MuiDialog-scrollBody': {
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'center',
          },
        },

        paper: {
          borderRadius: '11px',
          marginBottom: '4vh',
          top: '3.25vh',

          // Visually center Dialogs in main content area (accommodate AppDrawer).
          // FIXME: This should be based on AppDrawer width, but those constants are defined in webapp
          [themeBreakpoints.up(1366)]: {
            left: 100,
          },
          [themeBreakpoints.down(1366)]: {
            left: 34,
          },
        },
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: 'h1',
      },

      styleOverrides: {
        root: {
          marginBottom: spacing(6),
          paddingTop: spacing(7),
          paddingLeft: DIALOG_PADDING,
          paddingRight: DIALOG_PADDING,
          paddingBottom: 0,
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: DIALOG_PADDING,
          paddingRight: DIALOG_PADDING,
          paddingTop: 0,
          paddingBottom: DIALOG_PADDING,
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingLeft: DIALOG_PADDING,
          paddingRight: DIALOG_PADDING,
          paddingTop: 0,
          paddingBottom: DIALOG_PADDING,
          justifyContent: 'start',
        },
      },
    },
  };
};
