import {
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IconHelp } from '../../themes';
import { CopyToClipboardButton } from '../CopyToClipboardButton';

export interface DescriptionListItemProps {
  direction?: 'column' | 'row';
  term: { defaultMessage: string };
  termKey?: string;
  termTypographyProps?: TypographyProps;
  value: ReactNode;
  valueTypographyProps?: TypographyProps;
  helpTooltip?: ReactNode;
}

export const DescriptionListItem = ({
  direction = 'column',
  term,
  termKey,
  termTypographyProps = {
    variant: 'h4',
  },
  value,
  valueTypographyProps = { variant: 'body2' },
  helpTooltip,
}: DescriptionListItemProps) => {
  const { palette, space } = useTheme();

  return (
    <Stack direction={direction} spacing={space.xs}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography component="dt" {...termTypographyProps}>
          <FM {...term} />
        </Typography>

        {helpTooltip && (
          <Tooltip title={helpTooltip}>
            <IconHelp
              htmlColor={palette.text.secondary}
              fontSize="small"
              sx={{
                ...(termTypographyProps.variant === 'h4' && { fontSize: 16 }),
                '&:hover': { cursor: 'pointer' },
              }}
            />
          </Tooltip>
        )}
      </Stack>

      <Stack alignItems="center" direction="row" gap={2}>
        <Typography
          component="dd"
          {...valueTypographyProps}
          sx={{ wordBreak: 'break-all' }}
        >
          {value}
        </Typography>

        {termKey && termKey.match(/uuid$/i) && (
          <CopyToClipboardButton size="small" value={value as string} />
        )}
      </Stack>
    </Stack>
  );
};
