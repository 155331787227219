import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory, V1GroupResponse } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnHeader,
  FindingLevelLabel,
  NumberDisplay,
  TruncatedTextDisplay,
  UIFindingUtils,
} from '@endorlabs/ui-common';

import { FindingRiskMatrixSeverity, FindingRiskMatrixTableRow } from './types';
import {
  buildFindingRiskMatrixTableRows,
  getFilteredFindingGroups,
} from './utils';

const FindingRiskMatrixCell = ({
  severity,
  value,
}: {
  severity: FindingRiskMatrixSeverity;
  value: number;
}) => {
  const theme = useTheme();

  const color =
    severity === 'total'
      ? theme.palette.text.primary
      : UIFindingUtils.getSeverityColor(severity, theme);

  return (
    <Typography
      component="span"
      sx={() => ({ color })}
      textAlign="right"
      variant="h1"
    >
      <NumberDisplay value={value} />
    </Typography>
  );
};

const getFindingRiskMatrixTableColumnDefs = (
  columnWidth?: number
): DataTableColumnDef<FindingRiskMatrixTableRow>[] => {
  const FindingRiskMatrixTableColumnDefs: DataTableColumnDef<FindingRiskMatrixTableRow>[] =
    [
      {
        accessorKey: 'severity',
        header: '',
        cell: (t) => (
          <Typography
            sx={(theme) => ({
              color: UIFindingUtils.getSeverityColor(t.getValue(), theme),
            })}
            variant="h6"
          >
            {t.getValue() === 'total' ? (
              <FM defaultMessage="Total" />
            ) : (
              <FindingLevelLabel findingLevel={t.getValue()} />
            )}
          </Typography>
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'reachable',
        header: () => (
          <DataTableColumnHeader
            helpTooltip={
              <FM defaultMessage="Findings with a reachable function." />
            }
            label={
              <TruncatedTextDisplay
                value="Reachable"
                start={11}
                end={11}
                width="100%"
                display="block"
                justifyContent="end"
                variant="body2"
              />
            }
          />
        ),
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'potential',
        header: () => (
          <DataTableColumnHeader
            helpTooltip={
              <FM defaultMessage="Findings with a potentially reachable function." />
            }
            label={
              <TruncatedTextDisplay
                value="Potentially Reachable"
                width="100%"
                display="block"
                justifyContent="end"
                variant="body2"
              />
            }
          />
        ),
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'unreachable',
        header: () => (
          <DataTableColumnHeader
            helpTooltip={
              <FM defaultMessage="Findings with an unreachable function." />
            }
            label={
              <TruncatedTextDisplay
                value="Unreachable"
                width="100%"
                display="block"
                justifyContent="end"
                variant="body2"
              />
            }
          />
        ),
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
      {
        accessorKey: 'total',
        header: (x) => {
          return (
            <TruncatedTextDisplay
              value="Total"
              width="100%"
              display="block"
              justifyContent="end"
              variant="body2"
            />
          );
        },
        cell: (t) => (
          <FindingRiskMatrixCell
            severity={t.row.getValue('severity')}
            value={t.getValue()}
          />
        ),
        minSize: columnWidth,
        maxSize: columnWidth,
      },
    ];
  return FindingRiskMatrixTableColumnDefs;
};

interface FindingRiskMatrixTableProps {
  activeFindingCategory?: V1FindingCategory;
  findingGroups?: V1GroupResponse['groups'];
  columnWidth?: number;
  isLoading?: boolean;
}

/**
 * Displays a table-like structure with Finding counts organized by severity & important attributes
 */
export const FindingRiskMatrixTable = ({
  activeFindingCategory,
  findingGroups,
  columnWidth,
}: FindingRiskMatrixTableProps) => {
  const sx = styles();

  const filteredFindingGroups = useMemo(
    () => getFilteredFindingGroups(findingGroups, activeFindingCategory),
    [activeFindingCategory, findingGroups]
  );

  const rows = useMemo(
    () => buildFindingRiskMatrixTableRows(filteredFindingGroups),
    [filteredFindingGroups]
  );

  return (
    <Box sx={sx.root} textAlign="right">
      <DataTable
        columns={getFindingRiskMatrixTableColumnDefs(columnWidth)}
        data={rows}
      />
    </Box>
  );
};

const styles = () => ({
  root: {
    ' .MuiTableContainer-root': {
      borderBottom: 0,
    },

    '& .MuiTableRow-root:last-of-type': {
      '.MuiTableCell-root': {
        borderBottom: 0,
      },
    },

    '& .MuiTableCell-head': {
      textAlign: 'right',

      '& > div': {
        justifyContent: 'flex-end',
      },
    },
  },
});
