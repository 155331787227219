import { useQuery } from 'react-query';

import { ResourceKind } from '@endorlabs/endor-core';

interface Schema {
  type?: string;
  properties?: { [propertyName: string]: Schema } | ArrayLike<any>;
  $ref?: string;
}

interface OriginalSchema extends Schema {
  definitions: { [definitionName: string]: Schema };
}

interface Template {
  [propertyName: string]: any;
}

function generateApiTemplate(schema: Schema, originalSchema: OriginalSchema) {
  const template: Template = {};

  if (!schema.properties) {
    return template;
  }

  for (const [propertyName, propertySchema] of Object.entries(
    schema.properties
  )) {
    if (!propertySchema) {
      continue;
    }

    // Check if the property schema is a reference
    if (propertySchema.$ref) {
      const refParts = propertySchema.$ref.split('/');
      const definitionName = refParts[refParts.length - 1];

      // Check if the referenced definition exists in original schema
      if (
        originalSchema.definitions &&
        originalSchema.definitions[definitionName]
      ) {
        const referencedDefinition = originalSchema.definitions[definitionName];
        // Recursively generate template using referenced definition and original schema
        template[propertyName] = generateApiTemplate(
          referencedDefinition,
          originalSchema
        );
      }
    } else {
      // Set default value based on property type
      switch (propertySchema.type) {
        case 'string':
          template[propertyName] = '';
          break;
        case 'number':
        case 'integer':
          template[propertyName] = 0;
          break;
        case 'boolean':
          template[propertyName] = false;
          break;
        case 'object':
          template[propertyName] = {};
          break;
        case 'array':
          template[propertyName] = [];
          break;
        default:
          template[propertyName] = undefined;
          break;
      }
    }
  }

  return template;
}

const SCHEMA_LOADERS: Partial<
  Record<ResourceKind, () => Promise<OriginalSchema>>
> = {
  AuthorizationPolicy: () =>
    import(
      /* webpackChunkName: "lazy_endorlabs__json-schema__v1" */
      /* webpackMode: "lazy-once" */
      `@endorlabs/json-schema/v1/AuthorizationPolicy.json`
    ),
  EndorLicense: () =>
    import(
      /* webpackChunkName: "lazy_endorlabs__json-schema__v1" */
      /* webpackMode: "lazy-once" */
      `@endorlabs/json-schema/v1/EndorLicense.json`
    ),
};

export const useCreateTemplate = (resourceKind: ResourceKind) => {
  return useQuery({
    queryKey: ['v1/mocks', resourceKind],
    queryFn: async () => {
      const loader = SCHEMA_LOADERS[resourceKind];
      if (!loader) {
        throw new Error(`Resource not Supported: ${resourceKind}`);
      }

      const schema = await loader();

      return generateApiTemplate(schema, schema);
    },
    enabled: !!resourceKind,
  });
};
