import { ScanResultResource } from './types';

export const getRunBySystem = (
  value: ScanResultResource
): boolean | undefined => {
  const config = value.spec?.environment?.config ?? {};
  return config.RunBySystem;
};

export const getBypassHostCheck = (
  value: ScanResultResource
): boolean | undefined => {
  const config = value.spec?.environment?.config ?? {};
  return config.BypassHostCheck ?? config.BypassDoctor;
};
