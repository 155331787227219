import { defineMessages, MessageDescriptor } from 'react-intl';

import { PackagistSpecAuthKind } from '@endorlabs/api_client';

export const PackagistAuthKindMessages: Record<
  PackagistSpecAuthKind,
  MessageDescriptor
> = defineMessages({
  [PackagistSpecAuthKind.Unspecified]: { defaultMessage: 'Unspecified' },
  [PackagistSpecAuthKind.HttpBasic]: {
    defaultMessage: 'Http Basic',
  },
  [PackagistSpecAuthKind.HttpBearer]: {
    defaultMessage: 'Http Bearer',
  },
  [PackagistSpecAuthKind.GithubOauth]: {
    defaultMessage: 'GitHub OAuth',
  },
  [PackagistSpecAuthKind.GitlabOauth]: { defaultMessage: 'GitLab OAuth' },
  [PackagistSpecAuthKind.GitlabToken]: { defaultMessage: 'GitLab Token' },
  [PackagistSpecAuthKind.BitbucketOauth]: { defaultMessage: 'BitBucket OAuth' },
});
