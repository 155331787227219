import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingSource } from '@endorlabs/endor-core/Finding';
import { FilterExpression } from '@endorlabs/filters';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  IconTelescope,
  NavigationTabs,
  UIFindingUtils,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  getDefaultFilterValues,
  getInitialFilterValues,
  ResourceFilterContextProvider,
} from '../../domains/filters';
import {
  FINDING_FILTER_FIELDS,
  FINDING_SEARCH_KEYS,
} from '../../domains/Findings';
import { useAuthInfo } from '../../providers';
import { getFindingsPath } from '../../routes';
import { FindingsAggregatedView } from './FindingsAggregatedView';
import { FindingsIndexPageV2 } from './FindingsIndexPageV2';

const FindingsTabNames = {
  DEPENDENCY: 'dependency',
  PACKAGE: 'package',
  REPOSITORY: 'repository',
  SECRETS: 'secrets',
} as const;

type FindingsIndexPageView =
  (typeof FindingsTabNames)[keyof typeof FindingsTabNames];

const FindingsTabRecords = [
  {
    label: <FM defaultMessage="Dependency" />,
    to: FindingsTabNames.DEPENDENCY,
    value: FindingsTabNames.DEPENDENCY,
  },
  {
    label: <FM defaultMessage="Package" />,
    to: FindingsTabNames.PACKAGE,
    value: FindingsTabNames.PACKAGE,
  },
  {
    label: <FM defaultMessage="Repository" />,
    to: FindingsTabNames.REPOSITORY,
    value: FindingsTabNames.REPOSITORY,
  },
  {
    label: <FM defaultMessage="Secrets" />,
    to: FindingsTabNames.SECRETS,
    value: FindingsTabNames.SECRETS,
  },
];

const getFilterExpressionForView = (
  activeView?: FindingsIndexPageView
): FilterExpression | undefined => {
  switch (activeView) {
    case FindingsTabNames.DEPENDENCY:
      return UIFindingUtils.getFindingFilterExpression(
        FindingSource.Dependency
      );
    case FindingsTabNames.PACKAGE:
      return UIFindingUtils.getFindingFilterExpression(FindingSource.Package);
    case FindingsTabNames.REPOSITORY:
      return UIFindingUtils.getFindingFilterExpression(
        FindingSource.Repository
      );
    case FindingsTabNames.SECRETS:
      return UIFindingUtils.getFindingFilterExpression(FindingSource.Secrets);
  }
};

const getInitialFiltersForView = (activeView?: FindingsIndexPageView) => {
  const initialFilterValues = getInitialFilterValues(FINDING_FILTER_FIELDS);

  switch (activeView) {
    case FindingsTabNames.PACKAGE:
      // HACK: remove findings tags related to dependency reachability
      initialFilterValues.delete('spec.finding_tags');
      break;
    case FindingsTabNames.REPOSITORY:
    case FindingsTabNames.SECRETS: {
      // HACK: remove initial filters not applicable to repository or secrets findings
      const keys = Array.from(initialFilterValues.keys());
      for (const key of keys) {
        if (key === 'meta.create_time' || key === 'spec.level') continue;

        initialFilterValues.delete(key);
      }
      break;
    }
  }

  return initialFilterValues;
};

const DEFAULT_FILTER_VALUES = getDefaultFilterValues(FINDING_FILTER_FIELDS);

export const FindingsIndexPage = ({
  activeView,
}: {
  activeView: FindingsIndexPageView;
}) => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { ENABLE_IA_1_PAGE_IMPROVEMENTS } = useFeatureFlags();

  const activeTab =
    FindingsTabRecords.find((r) => r.value === activeView)?.value ??
    FindingsTabNames.DEPENDENCY;

  // add base for finding routes
  const findingsPathBase = getFindingsPath({ tenantName });
  const findingsTabs = FindingsTabRecords.map((tab) => ({
    ...tab,
    to: `${findingsPathBase}/${tab.to}`,
  }));

  // get the base filter for each Finding view
  const baseFilter = useMemo(
    () => getFilterExpressionForView(activeTab),
    [activeTab]
  );

  const initialFilterValues = useMemo(
    () => getInitialFiltersForView(activeTab),
    [activeTab]
  );

  if (ENABLE_IA_1_PAGE_IMPROVEMENTS) {
    return <FindingsIndexPageV2 />;
  }

  return (
    <ResourceFilterContextProvider
      baseFilter={baseFilter}
      defaultFilterValues={DEFAULT_FILTER_VALUES}
      initialFilterValues={initialFilterValues}
      namespace={tenantName}
      resourceKind="Finding"
      resourceSearchKeys={FINDING_SEARCH_KEYS}
    >
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        <Grid item>
          <PageHeader
            Icon={IconTelescope}
            title={<FM defaultMessage="All Findings" />}
          />
        </Grid>

        <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
          <NavigationTabs activeTab={activeTab} tabs={findingsTabs} />
        </Grid>

        <Grid item>
          {activeTab === FindingsTabNames.DEPENDENCY && (
            <FindingsAggregatedView
              namespace={tenantName}
              findingSource={FindingSource.Dependency}
            />
          )}
          {activeTab === FindingsTabNames.PACKAGE && (
            <FindingsAggregatedView
              namespace={tenantName}
              findingSource={FindingSource.Package}
            />
          )}
          {activeTab === FindingsTabNames.REPOSITORY && (
            <FindingsAggregatedView
              namespace={tenantName}
              findingSource={FindingSource.Repository}
            />
          )}
          {activeTab === FindingsTabNames.SECRETS && (
            <FindingsAggregatedView
              namespace={tenantName}
              findingSource={FindingSource.Secrets}
            />
          )}

          {/* TODO: add Finding trends page
          {activeTab === FindingsTabNames.TRENDS && (
            <FindingsIndexTrendsView namespace={tenantName} />
          )}
          */}
        </Grid>
      </Grid>
    </ResourceFilterContextProvider>
  );
};
