import { Theme } from '@mui/material';

import { CVSSV3SeverityLevel, SpecFindingLevel } from '@endorlabs/api_client';

/**
 * Return the color associated with a particular Finding severity level
 * @param severityLevel
 * @param theme
 * @returns
 */
export const getSeverityColor = (
  severityLevel: SpecFindingLevel | CVSSV3SeverityLevel,
  theme: Pick<Theme, 'palette'>
) => {
  const { palette } = theme;

  switch (severityLevel) {
    case SpecFindingLevel.Critical:
    case CVSSV3SeverityLevel.Critical:
      return palette.severity.critical;

    case SpecFindingLevel.High:
    case CVSSV3SeverityLevel.High:
      return palette.severity.high;

    case SpecFindingLevel.Medium:
    case CVSSV3SeverityLevel.Medium:
      return palette.severity.medium;

    case SpecFindingLevel.Low:
    case CVSSV3SeverityLevel.Low:
      return palette.severity.low;

    case SpecFindingLevel.Unspecified:
    case CVSSV3SeverityLevel.Unspecified:
    case CVSSV3SeverityLevel.None:
      return palette.text.secondary;

    default:
      return palette.text.secondary;
  }
};
