import { ToggleButton, ToggleButtonProps } from '@mui/material';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';

interface UseExclusiveToggleButtonGroup<T> {
  getToggleButtonGroupProps: () => { value: T; children: ReactNode[] };
  setValue: (value: T) => void;
  value: T;
}

/**
 * Manages state & provides props for an exclusive ToggleButtonGroup.
 * Valid props require caller to provide a map of button values to message elements.
 *
 * TODO: Further genericize to allow non-exclusive, & button overrides.
 */
export function useExclusiveToggleButtonGroup<T extends string>(
  initialValue: T,
  buttonPropsArray: (Omit<ToggleButtonProps, 'value'> & { value: T })[]
): UseExclusiveToggleButtonGroup<T> {
  const [selectedValue, setSelectedValue] = useState<T>(initialValue);

  const changeSelectedValue = useCallback(
    (evt: MouseEvent<HTMLElement>, value: T) => {
      setSelectedValue(value);
    },
    [setSelectedValue]
  );

  const getToggleButtonGroupProps = useMemo(() => {
    return () => ({
      value: selectedValue,
      // NOTE: If provided, JSX children will override the children prop here
      children: buttonPropsArray.map((buttonProps) => (
        <ToggleButton
          {...buttonProps}
          onClick={changeSelectedValue}
          value={buttonProps.value}
          key={buttonProps.value}
        />
      )),
    });
  }, [buttonPropsArray, selectedValue, changeSelectedValue]);

  return {
    getToggleButtonGroupProps,
    setValue: setSelectedValue,
    value: selectedValue,
  };
}
