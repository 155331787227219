import { Grid } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { IconBookmark, NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { useFullMatch } from '../../../routes';
import { ResourceFilterContextProvider } from '../../filters';
import { SAST_RULES_SEARCH_KEYS } from '../constants/common';
import { PolicyTabName, PolicyTabNames, usePoliciesPageTabs } from '../hooks';
import { AdmissionPoliciesView } from './AdmissionPoliciesView';
import { CiCdToolsRulesView } from './CiCdToolsRulesView';
import { ExceptionPoliciesView } from './ExceptionPoliciesView';
import { FindingPoliciesView } from './FindingPoliciesView';
import { RemediationPoliciesView } from './RemediationPoliciesView';
import { SASTRulesView } from './SASTRulesView';
import { SecretsView } from './SecretsView';

export const PoliciesIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const { params } = useFullMatch();
  const { activeView } = params;

  const { activeTab, tabRecords } = usePoliciesPageTabs({
    activeTab: activeView as PolicyTabName,
    namespace: tenantName,
  });

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconBookmark}
          title={<FM defaultMessage="Policies & Rules" />}
          metadata={{ summary: [] }}
        />
      </Grid>

      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs activeTab={activeTab} tabs={tabRecords} />
      </Grid>

      <Grid item>
        {activeTab === PolicyTabNames.FINDING_RULES && <FindingPoliciesView />}
        {activeTab === PolicyTabNames.EXCEPTIONS && <ExceptionPoliciesView />}
        {activeTab === PolicyTabNames.ACTIONS && <AdmissionPoliciesView />}
        {activeTab === PolicyTabNames.REMEDIATIONS && (
          <RemediationPoliciesView />
        )}
        {activeTab === PolicyTabNames.SECRETS && (
          <SecretsView namespace={tenantName} />
        )}
        {activeTab === PolicyTabNames.CICD && <CiCdToolsRulesView />}
        {activeTab === PolicyTabNames.SAST && (
          <>
            <ResourceFilterContextProvider
              namespace={tenantName}
              resourceKind="SastRules"
              resourceSearchKeys={SAST_RULES_SEARCH_KEYS}
            >
              <SASTRulesView />
            </ResourceFilterContextProvider>
          </>
        )}
      </Grid>
    </Grid>
  );
};
