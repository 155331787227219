import {
  get as _get,
  isObject as _isObject,
  toString as _toString,
  uniq as _uniq,
} from 'lodash-es';

import { LICENSE_METRIC_ANALYTIC_NAMES } from '../constants';
import { LicenseMetricResource, MetricResource } from '../types';

export const isLicenseMetric = (
  metric: unknown
): metric is LicenseMetricResource => {
  if (_isObject(metric)) {
    const analyticName =
      _get(metric, ['spec', 'analytic']) ?? _get(metric, ['meta', 'name']);

    return (
      !!analyticName && LICENSE_METRIC_ANALYTIC_NAMES.includes(analyticName)
    );
  }

  return false;
};

export type LicenseInfoValues = Record<
  | 'files'
  | 'matchedTexts'
  | 'names'
  | 'types'
  | 'urls'
  | 'spdxids'
  | 'fileLocations',
  string[]
>;

/**
 * Get all unique values from the License Metric License Info
 */
export const getLicenseMetricLicenseInfoValues = (
  metric?: Pick<MetricResource, 'spec'>
): LicenseInfoValues => {
  const files = [];
  const matchedTexts = [];
  const names = [];
  const types = [];
  const urls = [];
  const spdxids = [];
  const fileLocations = [];

  if (isLicenseMetric(metric)) {
    const allLicenseInfo =
      metric.spec.metric_values.licenseInfoType?.license_info.all_licenses ??
      [];

    for (const li of allLicenseInfo) {
      if (li.file) {
        files.push(li.file);
      }
      if (li.matched_text) {
        matchedTexts.push(li.matched_text);
      }
      const name = (li.spdxid || li.name).trim();
      if (name) {
        names.push(name);
      }
      if (li.type) {
        types.push(li.type);
      }
      if (li.url) {
        urls.push(li.url);
      }
      if (li.spdxid) {
        spdxids.push(li.spdxid);
      }
      if (li.file_location) {
        fileLocations.push(_toString(li.file_location));
      }
    }
  }

  return {
    fileLocations: _uniq(fileLocations).sort(),
    files: _uniq(files).sort(),
    matchedTexts: _uniq(matchedTexts).sort(),
    names: _uniq(names).sort(),
    spdxids: _uniq(spdxids).sort(),
    types: _uniq(types).sort(),
    urls: _uniq(urls).sort(),
  };
};
