import { isMatch as _isMatch } from 'lodash-es';

import { PackageVersionResource } from '@endorlabs/queries';

import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  PackageVersionDetailDrawer,
  PackageVersionDetailDrawerProps,
} from '../components/PackageVersionDetailDrawer';

/**
 * necessary properties for finding target Package Version from list
 */
export interface PackageVersionIdentifier {
  isDirectDependency?: boolean;
  namespace: string;
  uuid: string;
}

export interface PackageVersionDetailDrawerParams {
  packageNamespace: string;
  packageUuid: string;
}

interface PermalinkEffectProps {
  packages: PackageVersionIdentifier[];
  sourcePackage?: PackageVersionResource;
}

export const usePackageVersionDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    PackageVersionDetailDrawerProps,
    PackageVersionDetailDrawerProps
  >({
    drawerParams: ['packageNamespace', 'packageUuid'],
    Component: PackageVersionDetailDrawer,
  });

  const permalinkEffect = ({ packages }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activePackage = activeDrawerParams
      ? packages.find((p) =>
          _isMatch(p, {
            namespace: activeDrawerParams.packageNamespace,
            uuid: activeDrawerParams.packageUuid,
          })
        )
      : undefined;

    if (
      activePackage &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          packageNamespace: activePackage.namespace,
          packageUuid: activePackage.uuid,
        },
        {
          namespace: activePackage.namespace,
          uuid: activePackage.uuid,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
