import { InputBase, styled } from '@mui/material';

/**
 * Common Base Input for use in the Filter Builder
 */
export const ControlInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    position: 'relative',
    fontSize: theme.typography.body1,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    borderRadius: 2,
    '&:focus': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));
