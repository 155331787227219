import { useMemo } from 'react';

import { V1Ecosystem } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import {
  isBinaryProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { useCountFindings, useFeatureFlags } from '@endorlabs/queries';
import { UIProjectUtils } from '@endorlabs/ui-common';

import { buildFindingSourceToggleOptions } from '../../domains/Findings';
import { useProjectPackageEcosystems } from '../../domains/Projects';

/**
 * Return the counts by Finding Source for Project Findings
 */
export const useProjectFindingCountsByFindingSource = ({
  namespace,
  project,
  projectVersion,
}: {
  namespace: string;
  project?: ProjectResource;
  projectVersion?: ProjectVersionResource;
}) => {
  const qProjectPackageEcosystems = useProjectPackageEcosystems(
    project,
    projectVersion
  );

  // Handle projects from package or container scans
  const hasRepository = project && !isBinaryProject(project);
  const hasContainerPackages = qProjectPackageEcosystems.data.ecosystems?.some(
    (e) => e === V1Ecosystem.Container
  );
  const isGithubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );

  const containerFindingsFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.Container
    );
  const qCountContainerFindings = useCountFindings(
    namespace,
    {
      enabled: hasContainerPackages && !!containerFindingsFilterExpression,
    },
    {
      filter: containerFindingsFilterExpression,
    }
  );
  const dependenciesFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.Dependency
    );
  const qCountDependencyFindings = useCountFindings(
    namespace,
    {
      enabled: !!dependenciesFilterExpression,
    },
    {
      filter: dependenciesFilterExpression,
    }
  );

  const packagesFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.Package
    );
  const qCountPackageFindings = useCountFindings(
    namespace,
    {
      enabled: !!packagesFilterExpression,
    },
    {
      filter: packagesFilterExpression,
    }
  );

  const repositoriesFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.Repository
    );
  const qCountRepositoryFindings = useCountFindings(
    namespace,
    {
      enabled: hasRepository && !!repositoriesFilterExpression,
    },
    {
      filter: repositoriesFilterExpression,
    }
  );

  const secretsFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.Secrets
    );
  const qCountSecretsFindings = useCountFindings(
    namespace,
    {
      enabled: hasRepository && !!secretsFilterExpression,
    },
    {
      filter: secretsFilterExpression,
    }
  );

  const githubActionFilterExpression =
    UIProjectUtils.getProjectFindingFilterExpressions(
      project,
      projectVersion,
      FindingSource.GithubAction
    );
  const qCountGithubActionFindings = useCountFindings(
    namespace,
    {
      enabled:
        hasRepository &&
        !!githubActionFilterExpression &&
        isGithubActionsEnabled,
    },
    {
      filter: githubActionFilterExpression,
    }
  );

  const [totalFindingsCount, findingSourceToggleOptions] = useMemo(() => {
    const totalFindingsCount = [
      qCountDependencyFindings.data,
      qCountGithubActionFindings.data,
      qCountPackageFindings.data,
      qCountRepositoryFindings.data,
      qCountSecretsFindings.data,
    ].reduce((sum, data) => sum + (data?.count ?? 0), 0);

    const excludedFindingSources: FindingSource[] = isGithubActionsEnabled
      ? [FindingSource.All]
      : [FindingSource.All, FindingSource.GithubAction];

    // Hide non-applicable finding sources for projects from package or container scan
    if (!hasRepository) {
      excludedFindingSources.push(
        FindingSource.GithubAction,
        FindingSource.Repository,
        FindingSource.Secrets
      );
    }

    if (!hasContainerPackages) {
      excludedFindingSources.push(FindingSource.Container);
    }

    const options = buildFindingSourceToggleOptions(
      {
        containerFindings: qCountContainerFindings.data?.count,
        dependencyFindings: qCountDependencyFindings.data?.count,
        githubActionFindings: qCountGithubActionFindings.data?.count,
        packageFindings: qCountPackageFindings.data?.count,
        repositoryFindings: qCountRepositoryFindings.data?.count,
        secretsFindings: qCountSecretsFindings.data?.count,
      },
      excludedFindingSources
    );

    return [totalFindingsCount, options];
  }, [
    hasContainerPackages,
    hasRepository,
    isGithubActionsEnabled,
    qCountContainerFindings.data?.count,
    qCountDependencyFindings.data,
    qCountGithubActionFindings.data,
    qCountPackageFindings.data,
    qCountRepositoryFindings.data,
    qCountSecretsFindings.data,
  ]);

  const isLoading = [
    qCountDependencyFindings,
    qCountGithubActionFindings,
    qCountPackageFindings,
    qCountRepositoryFindings,
    qCountSecretsFindings,
  ].some((q) => q.isLoading);

  return {
    isLoading,
    findingSourceToggleOptions,
    totalFindingsCount,
  };
};
