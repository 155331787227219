import { Box, Grid, useTheme } from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import { NavigationTabs } from '@endorlabs/ui-common';
import ImgEndorATMascot from '@endorlabs/ui-common/assets/logos/at-mascot-300.png';

import { PageHeader } from '../../components';
import { OSSExplorerPageSource } from '../../domains/OSS';
import { useAuthInfo } from '../../providers';
import { OSSExplorerHuggingFaceModelsView } from './OSSExplorerHuggingFaceModelsView';
import { OSSExplorerPackagesView } from './OSSExplorerPackagesView';
import { OSSExplorerPage } from './OssExplorerPage';
import { useOSSExplorerPageTabs } from './useOSSExplorerPageTabs';

export const OSSExplorerPageV2 = ({
  activeView,
}: {
  activeView?: OSSExplorerPageSource;
}) => {
  const { setLicenseInCurrentRoute } = useAuthInfo();
  const router = useRouter();

  const { activeTab, tabRecords } = useOSSExplorerPageTabs({
    activeTab: activeView ?? OSSExplorerPageSource.DroidGPT,
  });
  const { formatMessage: fm } = useIntl();
  const theme = useTheme();

  const { ENABLE_LLM: isLLMEnabled } = useFeatureFlags();

  useEffect(() => {
    setLicenseInCurrentRoute({
      pathname: router.state.location.pathname,
      isLicense: true,
    });
  }, [router.state.location.pathname, setLicenseInCurrentRoute]);

  if (!isLLMEnabled) {
    return <OSSExplorerPage />;
  }

  return (
    <>
      <PageHeader title="" />

      <Box p={theme.space.xl}>
        <Grid
          alignItems="center"
          container
          direction="column"
          flexWrap="nowrap"
          spacing={8}
        >
          <Grid item>
            <img
              alt={fm({ defaultMessage: 'Endor Labs' })}
              src={ImgEndorATMascot}
              height={150}
            />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <NavigationTabs
              variant="standard"
              centered={true}
              activeTab={activeTab}
              tabs={tabRecords}
            />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            {activeTab === OSSExplorerPageSource.DroidGPT && (
              <OSSExplorerPackagesView
                source={OSSExplorerPageSource.DroidGPT}
              />
            )}
            {activeTab === OSSExplorerPageSource.OSS && (
              <OSSExplorerPackagesView source={OSSExplorerPageSource.OSS} />
            )}
            {activeTab === OSSExplorerPageSource.HuggingFace && (
              <OSSExplorerHuggingFaceModelsView />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
