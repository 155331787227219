import {
  ContextContextType as ResourceContextType,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import {
  PackageContexture,
  QueryPackageVersionsResponse,
  selectFindingCountsFromGroupResponse,
  selectMetricScores,
} from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { getPackageVersionPath, getProjectPath } from '../../routes';
import { PackageVersionsTableRow } from './types';

export const mapToPackageVersionTableRows = (
  response?: QueryPackageVersionsResponse,
  packageContexture?: PackageContexture,
  override?: (row: PackageVersionsTableRow) => PackageVersionsTableRow
): PackageVersionsTableRow[] => {
  const objects = response?.list?.objects ?? [];

  return objects.map((object) => {
    const { namespace: tenantName } = object.tenant_meta;

    const {
      FindingsGroupByLevel,
      Metric,
      Project,
      DependentPackagesCount,
      DependencyPackagesCount,
    } = object.meta.references;

    const metricScores = selectMetricScores(Metric?.list?.objects[0]);

    const findingCounts = selectFindingCountsFromGroupResponse(
      FindingsGroupByLevel?.group_response
    );

    // get the package version from the package version name
    const { version: versionRef } = UIPackageVersionUtils.parsePackageName(
      object.meta.name
    );

    // TODO: support linking to Package Verison with Context
    let packageVersionLink;
    if (
      object.context.type === ResourceContextType.Main ||
      object.context.type === ResourceContextType.Ref
    ) {
      packageVersionLink = getPackageVersionPath({
        tenantName,
        uuid: object.uuid,
      });
    }

    const cloneUrl = object.spec?.source_code_reference?.http_clone_url;

    const project = Project?.list?.objects[0];
    const projectName = project?.meta.name;
    const projectPlatformSource = project?.spec.platform_source;
    const projectLink = project
      ? getProjectPath({
          tenantName: project?.tenant_meta.namespace,
          uuid: project?.uuid,
        })
      : undefined;

    // NOTE: dependency and dependent counts are taken from DependencyMetadata
    // these counts are eventually consistent, and may not be accurate
    const dependenciesCount =
      DependencyPackagesCount?.count_response.count ?? 0;
    const dependentsCount = DependentPackagesCount?.count_response.count ?? 0;

    const row: PackageVersionsTableRow = {
      namespace: tenantName,
      id: object.uuid, // Required for tanstack-table operations
      uuid: object.uuid,
      name: object.meta.name,
      link: packageVersionLink,
      versionRef,
      projectName,
      projectPlatformSource,
      projectLink,
      dependenciesCount,
      dependentsCount,
      findingCounts,
      ...(tenantName === 'oss' && { repositoryUrl: cloneUrl }),
      [V1ScoreCategory.Security]: metricScores.scoreSecurity?.score,
      [V1ScoreCategory.Activity]: metricScores.scoreActivity?.score,
      [V1ScoreCategory.Popularity]: metricScores.scorePopularity?.score,
      [V1ScoreCategory.CodeQuality]: metricScores.scoreCodeQuality?.score,
      resolutionErrors: object.spec.resolution_errors,
      relativePath: object.spec.relative_path,
    };

    // allow override
    if (override) {
      return override(row);
    }

    return row;
  });
};
