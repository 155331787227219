import { Alert, AlertTitle, Skeleton, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useResourceKindDisplayLabel } from '@endorlabs/ui-common';

export type DependenciesTableHeaderProps = {
  isLoading: boolean;
  hasApproximateDependencies: boolean;
  totalCount: number;
  filteredCount: number;
  resourceKind: string;
};
export const DependenciesTableHeader = ({
  isLoading,
  hasApproximateDependencies,
  totalCount,
  filteredCount,
  resourceKind,
}: DependenciesTableHeaderProps) => {
  const resourceKindLabel = useResourceKindDisplayLabel(resourceKind);

  if (isLoading) {
    return <Skeleton width="60%" />;
  }

  if (hasApproximateDependencies) {
    return (
      <Alert severity="warning" color="warning" variant="outlined">
        <AlertTitle>
          <FM
            defaultMessage="Errors found while resolving some dependencies. This may result in an incomplete or inaccurate dependency graph."
            values={{
              resourceKind: resourceKindLabel,
            }}
          />
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <Typography>
      <FM
        defaultMessage="Showing <bold>{filteredCount, number}</bold> of {totalCount, plural,
              one {<bold>#</bold> dependency} other {<bold>#</bold> dependencies}}"
        values={{
          filteredCount,
          totalCount,
          bold: (v) => <strong>{v}</strong>,
        }}
      />
    </Typography>
  );
};
