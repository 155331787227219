import { SvgIconProps, Typography, useTheme } from '@mui/material';
import { pick as _pick } from 'lodash-es';
import { forwardRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IconSlash } from '../../themes';

export interface NilDisplayProps extends SvgIconProps {
  variant?: 'dash' | 'icon' | 'text';
}
export const NilDisplay = forwardRef<SVGSVGElement, NilDisplayProps>(
  ({ variant, ...rest }, ref) => {
    const { palette } = useTheme();
    const isText = variant === 'text';
    const isDash = variant === 'dash';

    if (isText || isDash) {
      const textDisplay = isDash ? '-' : <FM defaultMessage="N/A" />;

      // pass in valid values from SvgIconProps
      const typographyProps = _pick(rest, ['fontSize', 'color']);

      return (
        <Typography
          alignItems="center"
          color={palette.text.secondary}
          component="figure"
          variant={isDash ? 'h3' : 'body2'}
          {...typographyProps}
        >
          {textDisplay}
        </Typography>
      );
    }

    return (
      <IconSlash
        fontSize="inherit"
        htmlColor={palette.text.secondary}
        {...rest}
        ref={ref}
      />
    );
  }
);

NilDisplay.displayName = 'NilDisplay';
