import {
  Endorv1Metric as V1Metric,
  V1CategoryScore,
  V1CiCdTool,
  V1ScoreCategory,
} from '@endorlabs/api_client';

type V1ScoreCategoryInverted = {
  [K in `${V1ScoreCategory}`]: keyof typeof V1ScoreCategory;
};

const scoreCategoryInverted = Object.entries(V1ScoreCategory).reduce(
  (acc, entry) => {
    const [key, value] = entry;
    acc[value] = key;
    return acc;
  },
  {} as Record<string, string>
) as V1ScoreCategoryInverted;

export type MetricScoreCategory = `score${keyof typeof V1ScoreCategory}`;
export type MetricScores = {
  [K in MetricScoreCategory]?: V1CategoryScore;
};

export type MetricToolsByCategory = Record<string, V1CiCdTool[]>;

export const selectMetricScores = (metric?: V1Metric): MetricScores => {
  const metricScores: MetricScores = {};

  // validation
  if (!metric) {
    return metricScores;
  }

  // select the category scores from the metric, if exists
  const categoryScores =
    metric.spec?.metric_values.scorecard.score_card?.category_scores ?? [];

  for (const cs of categoryScores) {
    if (cs.category) {
      const key = scoreCategoryInverted[cs.category];
      metricScores[`score${key}`] = cs;
    }
  }

  return metricScores;
};

export const selectMetricScoresByCategory = (
  metric?: Pick<V1Metric, 'spec'>
): Partial<Record<V1ScoreCategory, number>> => {
  const scores: Partial<Record<V1ScoreCategory, number>> = {};

  // validation
  if (!metric) {
    return scores;
  }

  // select the category scores from the metric, if exists
  const categoryScores =
    metric.spec?.metric_values.scorecard.score_card?.category_scores ?? [];

  for (const cs of categoryScores) {
    if (cs.category) {
      scores[cs.category] = cs.score;
    }
  }

  return scores;
};

export const selectMetricCiCdToolsByCategory = (
  metric?: V1Metric
): MetricToolsByCategory => {
  const categories: MetricToolsByCategory = {};

  if (!metric) {
    return categories;
  }

  const ciCdTools =
    metric.spec?.metric_values.CiCdTools.ci_cd_tools?.tools ?? [];

  ciCdTools.sort((a, b) => a.name.localeCompare(b.name));

  for (const tool of ciCdTools) {
    const toolCategories = tool.categories ?? [];
    for (const category of toolCategories) {
      if (categories[category]) {
        categories[category].push(tool);
      } else {
        categories[category] = [tool];
      }
    }
  }

  return categories;
};

export const selectMetricCiCdToolCategories = (metric?: V1Metric): string[] => {
  const categories = [] as string[];

  if (!metric) {
    return categories;
  }

  const ciCdTools =
    metric.spec?.metric_values.CiCdTools.ci_cd_tools?.tools ?? [];

  for (const tool of ciCdTools) {
    const toolCategories = tool.categories ?? [];
    for (const category of toolCategories) {
      if (!categories.includes(category)) {
        categories.push(category);
      }
    }
  }

  return categories;
};
