import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  PackageVersionResource,
  useGetPackageVersion,
} from '@endorlabs/queries';
import { FileDownloadFileType } from '@endorlabs/ui-common';

import { ResourceRawDataBlock } from '../../components/ResourceRawDataBlock';

interface PackageMetadataViewProps {
  packageVersionObj: PackageVersionResource;
}

export const PackageMetadataView = ({
  packageVersionObj,
}: PackageMetadataViewProps) => {
  // setting toggle button state to JSON or YAML
  const [format, setFormat] = useState<FileDownloadFileType>('json');
  const handleChangeFormat = useCallback(
    (_: React.MouseEvent<HTMLElement>, newFormat?: FileDownloadFileType) => {
      // catch empty event payload
      if (newFormat) {
        setFormat(newFormat);
      }
    },
    []
  );

  // fetch full package version data
  const qPackageVersion = useGetPackageVersion(
    {
      namespace: packageVersionObj.tenant_meta.namespace,
      uuid: packageVersionObj.uuid,
    },
    {
      enabled: !!packageVersionObj,
      placeholderData: packageVersionObj,
      staleTime: Infinity,
    }
  );

  const isPackageVersionLoading =
    qPackageVersion.isLoading || qPackageVersion.isPlaceholderData;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          value={format}
          exclusive
          onChange={handleChangeFormat}
        >
          <ToggleButton value="json">
            <FM defaultMessage="JSON" />
          </ToggleButton>
          <ToggleButton value="yaml">
            <FM defaultMessage="YAML" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item>
        <ResourceRawDataBlock
          filetype={format}
          isLoading={isPackageVersionLoading}
          kind="PackageVersion"
          resource={qPackageVersion.data}
        />
      </Grid>
    </Grid>
  );
};
