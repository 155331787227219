import { Alert, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  NotificationTargetResource,
  ResourceMutateOptions,
  useDeleteNotificationTarget,
} from '@endorlabs/queries';

import { ConfirmationDialogProps } from '../../../components';
import { IconAlertTriangle } from '../../../themes';

/**
 * Utility hook to Manage Notification Target Deletion
 */
export const useDeleteNotificationTargetConfirmationDialog = (
  options: Pick<
    ResourceMutateOptions<unknown, unknown>,
    'onSuccess' | 'onError'
  >
) => {
  const [notificationTarget, setNotificationTarget] = useState<
    NotificationTargetResource | undefined
  >(undefined);
  const isOpen = Boolean(notificationTarget);

  const handleOpenDialog = useCallback(
    (notificationTarget: NotificationTargetResource) => {
      setNotificationTarget(notificationTarget);
    },
    []
  );

  const handleCloseDialog = useCallback(
    () => setNotificationTarget(undefined),
    []
  );

  const qDeleteNotificationTarget = useDeleteNotificationTarget(options);
  const handleConfirmDismiss = useCallback(() => {
    if (!notificationTarget) return;

    qDeleteNotificationTarget.mutate(
      {
        namespace: notificationTarget.tenant_meta.namespace,
        uuid: notificationTarget.uuid,
      },
      {
        // NOTE: notification on success/error state should be handled by parent
        onSuccess: () => setNotificationTarget(undefined),
        onError: () => setNotificationTarget(undefined),
      }
    );
  }, [notificationTarget, qDeleteNotificationTarget]);

  const getConfirmationDialogProps =
    useCallback((): ConfirmationDialogProps => {
      return {
        titleText: <FM defaultMessage="Delete Notification Integration" />,
        open: isOpen,
        onConfirm: handleConfirmDismiss,
        onCancel: handleCloseDialog,
        children: (
          <Stack spacing={4} marginBottom={3}>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <FM defaultMessage="If any action policies rely on this notification integration, they will no longer function as expected." />
            </Alert>

            <Typography variant="body1">
              <FM
                defaultMessage="This action will delete the Notification Integration <code>{name}</code>. Are you sure you want to proceed?"
                values={{
                  name: notificationTarget?.meta.name,
                  // styled `code` element
                  // forces content to wrap
                  code: (value) => (
                    <Typography
                      component="code"
                      variant="inherit"
                      sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
                    >
                      {value}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Stack>
        ),
      };
    }, [notificationTarget, handleConfirmDismiss, handleCloseDialog, isOpen]);

  return {
    closeDialog: handleCloseDialog,
    getConfirmationDialogProps,
    openDialog: handleOpenDialog,
  };
};
