import { Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  getRunBySystem,
  ScanResultResource,
} from '@endorlabs/endor-core/ScanResult';
import {
  IconCloud,
  IconTerminalOutlined,
  NilDisplay,
  RelativeTimeDisplay,
  RowStack,
} from '@endorlabs/ui-common';

export const ScanResultScannedByDisplay = ({
  scanResult,
  showLabel = false,
  showScanTime = false,
  size = 'medium',
}: {
  scanResult?: ScanResultResource;
  showLabel?: boolean;
  showScanTime?: boolean;
  size?: 'small' | 'medium';
}) => {
  const typographyVariant = size === 'small' ? 'body2' : 'body1';
  const { palette } = useTheme();
  if (!scanResult) {
    return <NilDisplay variant="text" />;
  }

  const isAutomatedScan = getRunBySystem(scanResult);
  const lastScanTime =
    scanResult.spec?.end_time ?? scanResult.meta?.create_time ?? '';

  const Icon = isAutomatedScan ? IconCloud : IconTerminalOutlined;

  return (
    <RowStack>
      <Icon htmlColor={palette.text.secondary} />

      {showLabel && (
        <Typography component="span" variant={typographyVariant} noWrap>
          {isAutomatedScan ? (
            <FM defaultMessage="GitHub App" />
          ) : (
            <FM defaultMessage="CLI" />
          )}
        </Typography>
      )}

      {showScanTime && (
        <Typography component="span" variant={typographyVariant} noWrap>
          <RelativeTimeDisplay value={lastScanTime} />
        </Typography>
      )}
    </RowStack>
  );
};
