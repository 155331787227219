import { Alert, Stack } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
  IconAlertTriangle,
} from '@endorlabs/ui-common';

interface PackageManagerDeleteConfirmationDialogProps
  extends Pick<ConfirmationDialogProps, 'open' | 'onCancel' | 'onConfirm'> {
  packageManagerUuid?: string;
}

export const PackageManagerDeleteConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  packageManagerUuid,
}: PackageManagerDeleteConfirmationDialogProps) => {
  // shared message
  const deletePackageManagerText = (
    <FM
      defaultMessage="Delete this Package Manager?"
      values={{
        name: packageManagerUuid,
        code: (v) => <code>{v}</code>,
        bold: (v) => <strong>{v}</strong>,
      }}
    />
  );

  return (
    <ConfirmationDialog
      cancelText={<FM defaultMessage="Go back" />}
      confirmText={deletePackageManagerText}
      isDestructive={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      titleText={deletePackageManagerText}
    >
      <Stack spacing={4}>
        <Alert severity="error" icon={<IconAlertTriangle />}>
          <FM defaultMessage="Package scanning will no longer include custom packages hosted in this registry." />
        </Alert>
      </Stack>
    </ConfirmationDialog>
  );
};
