import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  PolicyProjectsDrawer,
  PolicyProjectsDrawerProps,
} from '../components/PolicyProjectsDrawer';

export const usePolicyProjectsDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    { activePolicies: string },
    PolicyProjectsDrawerProps
  >({
    drawerParams: ['activePolicies'],
    Component: PolicyProjectsDrawer,
  });

  return { DetailDrawer };
};
