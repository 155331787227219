import { FormattedMessage as FM } from 'react-intl';

import { ResolutionStatusStatusError as StatusError } from '@endorlabs/api_client';

import { ResolutionStatusErrorMessages } from '../locales';

export const ResolutionStatusErrorLabel = ({
  statusError = StatusError.Unspecified,
}: {
  statusError?: StatusError;
}) => <FM {...ResolutionStatusErrorMessages[statusError]} />;
