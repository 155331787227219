import { Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

interface BooleanDisplayProps {
  highlightFalse?: boolean;
  value: boolean;
}

/**
 * Presents a boolean value in a internationalized format, with color applied
 */
export const BooleanDisplay = ({
  highlightFalse = true,
  value,
}: BooleanDisplayProps) => {
  if (value) {
    return (
      <Typography>
        <FM defaultMessage="Yes" />
      </Typography>
    );
  }

  return (
    <Typography sx={highlightFalse ? { color: 'error.main' } : undefined}>
      <FM defaultMessage="No" />
    </Typography>
  );
};
