import { Outlet } from '@tanstack/react-location';
import { ReactNode, useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Method } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { LoadingState, useAppNotify } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../providers';

/**
 * Restricts access to child routes when:
 *
 * - User is not authenticated
 * - User does not have permissions for the requested method and resource
 */
export const AuthenticatedRouteGuard = ({
  children = <Outlet />,
  method,
  resourceKind,
}: {
  children?: ReactNode;
  method?: V1Method;
  resourceKind?: ResourceKind;
}) => {
  const addAppNotification = useAppNotify();

  const { activeNamespace, checkActivePermission, isLoading } = useAuthInfo();
  const allow = checkActivePermission(method, resourceKind);

  useEffect(() => {
    if (isLoading || allow) return;

    const timer = setTimeout(() =>
      addAppNotification({
        id: 'auth:error:unauthorized',
        message: (
          <FM
            defaultMessage="Not Authorized to view namespace: `{activeNamespace}`"
            values={{ activeNamespace }}
          />
        ),
        details: (
          <FM defaultMessage="Please try again or contact Endor Labs for support." />
        ),
        severity: 'error',
      })
    );
    return () => clearTimeout(timer);
  }, [activeNamespace, addAppNotification, allow, isLoading]);

  if (isLoading) {
    // TODO: loading state
    return <LoadingState />;
  }

  // TODO: redirect to alternate route, if possible
  // if (!allow) {
  //   return <Navigate to={NamedRoutes.TENANTS_INDEX} />;
  // }

  return children;
};
