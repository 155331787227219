import { LoadingButton } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Meta } from '@endorlabs/api_client';
import {
  ButtonCancel,
  ControlledMultistateLabelsField,
  ControlledMultistateLabelsFieldValue,
  LabelCheckboxState,
  LabelsFieldValidationType,
} from '@endorlabs/ui-common';

export const TAG_FIELD_NAME = 'tags';

export type FormMultiResourceTagsFieldValues = {
  [TAG_FIELD_NAME]: ControlledMultistateLabelsFieldValue[];
};

export type FormMultiResourceTagsProps = {
  isLoading: boolean;
  onSubmit: (values: FormMultiResourceTagsFieldValues) => void;
  placeholderText?: string;
  resources?: Array<{ uuid: string; meta: V1Meta }>;
};

export const FormMultiResourceTags = ({
  isLoading,
  placeholderText,
  onSubmit,
  resources,
}: FormMultiResourceTagsProps) => {
  const { formatMessage: fm } = useIntl();

  const theme = useTheme();

  const tagListForResources = useMemo(() => {
    const tagList: Record<string, string[]> = {};
    resources?.forEach((resource) => {
      resource?.meta.tags?.forEach((tag) => {
        if (tagList[tag]) {
          tagList[tag].push(resource.uuid);
        } else {
          tagList[tag] = [resource.uuid];
        }
      });
    });
    return tagList;
  }, [resources]);

  const tagFormValues = useMemo(() => {
    const allResourcesCount = resources?.length ?? 0;
    const formValues = Object.entries(tagListForResources).reduce(
      (acc: ControlledMultistateLabelsFieldValue[], [label, resourceList]) => {
        const tagResourcesCount = resourceList.length ?? 0;
        if (tagResourcesCount === allResourcesCount) {
          acc.push({
            label,
            state: LabelCheckboxState.Checked,
            resourceList: resourceList,
          });
        } else {
          acc.push({
            label,
            state: LabelCheckboxState.Indeterminate,
            resourceList: resourceList,
          });
        }
        return acc;
      },
      []
    );
    return formValues.sort((a, b) => a.label.localeCompare(b.label));
  }, [resources?.length, tagListForResources]);

  const {
    reset,
    control,
    formState,
    handleSubmit: hookFormSubmit,
    watch,
  } = useForm<FormMultiResourceTagsFieldValues>({
    defaultValues: { [TAG_FIELD_NAME]: tagFormValues },
  });

  const handleReset = useCallback(() => {
    reset({ [TAG_FIELD_NAME]: tagFormValues });
  }, [tagFormValues, reset]);

  // on load, reset the form with the labels from the resource
  useEffect(() => handleReset(), [resources, handleReset]);

  const hasLabelValues = watch(TAG_FIELD_NAME).length > 0;

  return (
    <form onSubmit={hookFormSubmit(onSubmit)}>
      <Stack direction="column" spacing={theme.space.md}>
        <ControlledMultistateLabelsField
          defaultValue={tagFormValues}
          control={control}
          multiStateLabelsResourceList={resources?.map((p) => p.uuid)}
          name={TAG_FIELD_NAME}
          placeholder={
            // hide placeholder when there are label values
            hasLabelValues ? undefined : placeholderText
          }
          helperText={fm({
            defaultMessage:
              'Tags must be 63 characters or less, and may contain only letters (A-Z), numbers (0-9), and the following characters (=@_.-)',
          })}
          validation={LabelsFieldValidationType.MetaTag}
        />

        <Stack direction="row" spacing={4}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formState.isDirty}
          >
            <FM defaultMessage="Save Tags" />
          </LoadingButton>

          <ButtonCancel onClick={handleReset} disabled={!formState.isDirty}>
            <FM defaultMessage="Reset Tags" />
          </ButtonCancel>
        </Stack>
      </Stack>
    </form>
  );
};
