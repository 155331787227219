import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM, MessageDescriptor } from 'react-intl';

import { V1Language } from '@endorlabs/api_client';
import { CodeBlock } from '@endorlabs/ui-common';

interface StepHeadingProps {
  msg: MessageDescriptor;
  count: number;
}

export const StepHeading = ({ msg, count }: StepHeadingProps) => {
  const { palette, spacing } = useTheme();
  return (
    <Stack spacing={2} direction="row" alignItems="baseline">
      <Box
        sx={{
          height: spacing(6),
          width: spacing(6),
          fontWeight: 500,
          borderRadius: '50%',
          background: palette.brand.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          color: palette.getContrastText(palette.brand.main),
        }}
      >
        {count}
      </Box>
      <Typography sx={{ color: 'text.primary' }} variant="subtitle2">
        <FM {...msg} />
      </Typography>
    </Stack>
  );
};

export const getPackageManager = (language: V1Language) => {
  const packageManagers = [];
  switch (language) {
    case V1Language.Go:
      packageManagers.push('Go');
      break;

    case V1Language.Java:
    case V1Language.Kotlin:
      packageManagers.push('Gradle', 'Maven');
      break;

    case V1Language.Rust:
      packageManagers.push('Cargo');
      break;

    case V1Language.Typescript:
    case V1Language.Js:
      packageManagers.push('npm', 'pnpm', 'yarn');
      break;

    case V1Language.Csharp:
      packageManagers.push('DotNet', 'Nuget');
      break;

    case V1Language.Python:
      packageManagers.push('Pip', 'Poetry', 'Setuptools');
      break;

    case V1Language.Scala:
      packageManagers.push('sbt');
      break;

    case V1Language.Ruby:
      packageManagers.push('Bundler');
      break;

    case V1Language.Php:
      packageManagers.push('Composer');
      break;

    // TODO: Swift support?

    default:
      packageManagers.push([]);
      break;
  }
  return packageManagers;
};

type Props = {
  packageManager: string | never;
};

export const PackageManagerCodeBlock = ({ packageManager }: Props) => {
  return (
    <>
      {packageManager === 'Maven' && (
        <>
          <CodeBlock
            value={`## Resolve the dependencies for your project without error
mvn dependency:tree`}
          />
          <CodeBlock
            value={`## Ensure build is successful
mvn install
`}
          />
          <CodeBlock
            value={`## If you have multiple Java modules not referenced in the root pom.xml file,
## make sure to run mvn install separately for those modules.
`}
            enableClipboard={false}
          />
        </>
      )}
      {packageManager === 'Gradle' && (
        <>
          <CodeBlock
            value={`## If you are using gradle wrappers ( recommended )
## Resolve the dependencies for your project without error
./gradlew dependencies

## Resolve dependencies and create an artifact
./gradlew assemble`}
          />
          <CodeBlock
            value={`## OR if you dont use gradlew, please use the gradle command
## Resolve the dependencies for your project without error
gradle dependencies

## Resolve dependencies and create an artifact
gradle assemble`}
          />
        </>
      )}
      {packageManager === 'Go' && (
        <>
          <CodeBlock
            value={`## Ensure that your go.mod file is well formed
go mod tidy`}
          />
        </>
      )}
      {packageManager === 'Pip' && (
        <>
          <CodeBlock
            value={`## Enter the working copy root directory that's created
## Create a virtual environment
python3 -m venv venv`}
          />
          <CodeBlock
            value={`## Activate the virtual environment
source venv/bin/activate`}
          />
          <CodeBlock
            value={`## Install your project's dependencies for requirements.txt
venv/bin/python -m pip install -r requirements.txt`}
          />
        </>
      )}
      {packageManager === 'Setuptools' && (
        <>
          <CodeBlock
            value={`## Enter the working copy root directory that's created
## Create a virtual environment
python3 -m venv venv`}
          />
          <CodeBlock
            value={`## Install your project's dependencies for setuptools projects
venv/bin/python -m pip install .`}
          />
        </>
      )}
      {packageManager === 'Poetry' && (
        <>
          <CodeBlock
            value={`## Install your project's dependencies
poetry install`}
          />
        </>
      )}
      {packageManager === 'npm' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has package.json and build project successfully
npm install`}
          />
        </>
      )}
      {packageManager === 'yarn' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has package.json and build project successfully
yarn install`}
          />
        </>
      )}
      {packageManager === 'pnpm' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has package.json and build project successfully
pnpm install`}
          />
          <CodeBlock
            value={`## Set environment variable
export ENDOR_PNPM_ENABLED=true`}
          />
        </>
      )}
      {packageManager === 'Bundler' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has Gemfile and build project successfully
bundler install`}
          />
        </>
      )}
      {packageManager === 'DotNet' && (
        <>
          <CodeBlock
            value={`## Generate build artifacts project.assets.json file and resolve dependencies.
## Optionally generate a lock file.
dotnet restore --use-lock-file`}
          />
          <CodeBlock value={`dotnet build`} />
        </>
      )}
      {packageManager === 'Nuget' && (
        <>
          <CodeBlock
            value={`## Generate build artifacts project.assets.json file and resolve dependencies
nuget restore`}
          />
        </>
      )}
      {packageManager === 'sbt' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has build.sbt file. Run below commands to build projects successfully
sbt compile
sbt projects`}
          />
          <CodeBlock
            value={`## Ensure below command runs successfully inside the project directory
sbt dependencyTree`}
          />
        </>
      )}
      {packageManager === 'Composer' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has composer.json
composer install`}
          />
        </>
      )}
      {packageManager === 'Cargo' && (
        <>
          <CodeBlock
            value={`## Ensure your repo has Cargo.toml file
cargo build`}
          />
        </>
      )}
    </>
  );
};

export const PhantomDependencyCode = () => {
  return (
    <>
      <CodeBlock
        value={`## To detect dependencies used in source code but not declared in the package’s manifest files,
## set the environment variable ENDOR_SCAN_PHANTOM_DEPS to 'true'
export ENDOR_SCAN_PHANTOM_DEPS=true`}
      />
      <CodeBlock
        value={`## Or use the scan command flag '--phantom-dependencies'
endorctl scan --phantom-dependencies`}
      />
      <CodeBlock
        value={`## Perform the scan from within the root directory of the git project repository,
## save the local results to a results.json file.
## Or view results and related analysis information on the Endor Labs user interface
endorctl scan -o json | tee /path/to/results.json`}
      />
    </>
  );
};
