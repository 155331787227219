import { get as _get } from 'lodash-es';

import {
  V1Method as Method,
  V1Permissions as Permissions,
} from '@endorlabs/api_client';

import { RESOURCE_ENDPOINTS, ResourceKind } from '../../common';

/**
 * Given a set of permissions, check if the requested method is allowed
 */
export const checkPermission = (
  permissions: Permissions,
  method: Method = Method.Read,
  resourceKind?: ResourceKind,
  uuid?: string
): boolean => {
  // Check first for "global" permissions
  const globalAllowed: Method = _get(
    permissions,
    ['rules', '*', 'methods'],
    []
  );
  if (globalAllowed.includes(method) || globalAllowed.includes(Method.All)) {
    return true;
  }

  // If no resource kind is provided, stop
  if (!resourceKind) return false;

  // Permissions for resources are keyed by the api path for the resource kind
  const permissionKey = _get(RESOURCE_ENDPOINTS, resourceKind);
  if (!permissionKey) return false;

  const resourceKindAllowed: Method = _get(
    permissions,
    ['rules', permissionKey, 'methods'],
    []
  );

  if (
    resourceKindAllowed.includes(method) ||
    resourceKindAllowed.includes(Method.All)
  ) {
    return true;
  }

  // If no uuid is provided, stop
  if (!uuid) return false;

  const resourceAllowed: Method = _get(
    permissions,
    ['rules', `${permissionKey}:${uuid}`, 'methods'],
    []
  );

  return (
    resourceAllowed.includes(method) || resourceAllowed.includes(Method.All)
  );
};
