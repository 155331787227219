import { Grid } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCard, V1ScoreCategory } from '@endorlabs/api_client';
import { ScoreCategoryMessages } from '@endorlabs/ui-common';

import {
  ScoreCardDisplayItem,
  ScoreCardDisplayItemProps,
} from './ScoreCardDisplayItem';

const INCLUDED_SCORE_CATEGORIES = [
  V1ScoreCategory.Security,
  V1ScoreCategory.Activity,
  V1ScoreCategory.Popularity,
  V1ScoreCategory.CodeQuality,
];

export interface ScoreCardDisplayProps extends ScoreCardDisplayItemProps {
  scoreCard?: V1ScoreCard;
  includedScoreCategories?: V1ScoreCategory[];
}

export const ScoreCardDisplay = ({
  isLoading,
  scoreCard,
  showTotal,
  size,
  totalSize,
  includedScoreCategories = INCLUDED_SCORE_CATEGORIES,
}: ScoreCardDisplayProps) => {
  return (
    <Grid alignItems="flex-end" container justifyContent="space-around">
      <Grid flexGrow={1} item>
        <ScoreCardDisplayItem
          colorize
          isLoading={isLoading}
          score={scoreCard?.overall_score}
          showChart={false}
          showTotal
          size={size ?? 'xlarge'}
          totalSize={totalSize}
          title={<FM defaultMessage="Overall" />}
        />
      </Grid>

      {includedScoreCategories.map((cat) => {
        return (
          <Grid item key={cat} flexGrow={1} flexShrink={0}>
            <ScoreCardDisplayItem
              colorize
              isLoading={isLoading}
              score={
                scoreCard?.category_scores?.find(
                  (score) => score.category === cat
                )?.score
              }
              showChart={false}
              showTotal={showTotal}
              size={size}
              totalSize={totalSize}
              title={<FM {...ScoreCategoryMessages[cat]} />}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};
