import {
  Chip,
  Stack,
  Tooltip,
  Typography,
  TypographyProps,
} from '@mui/material';
import { FormattedList } from 'react-intl';

/**
 * Wraps {@see FormattedList}, limiting the number of displayed items and
 * adding the overflow items on hover in a tooltip
 */
export const OverflowFormattedListDisplay = ({
  maxItems = 3,
  typographyProps,
  value,
}: {
  maxItems?: number;
  typographyProps?: Pick<TypographyProps, 'fontWeight'>;
  value: string[];
}) => {
  const sorted = value.slice().sort();
  if (sorted.length <= maxItems) {
    return (
      <Typography component="span" {...typographyProps}>
        <FormattedList value={sorted} type="unit" />
      </Typography>
    );
  }

  return (
    <Tooltip title={<FormattedList value={sorted} type="unit" />}>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        <Typography component="span" {...typographyProps}>
          <FormattedList value={sorted.slice(0, maxItems)} type="unit" />
        </Typography>

        <Chip label={`+${sorted.length - maxItems}`} size="small" />
      </Stack>
    </Tooltip>
  );
};
