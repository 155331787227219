import {
  AuthorizationPolicyResource,
  parseAuthorizationPolicyClause,
} from '@endorlabs/endor-core/AuthorizationPolicy';

import { AuthorizationPoliciesTableRow } from './AuthorizationPoliciesTable';

export const mapAuthorizationPoliciesToTableRow = (
  authPolicies: AuthorizationPolicyResource[]
): AuthorizationPoliciesTableRow[] => {
  return authPolicies.map((ap) => {
    const { source, claims } = parseAuthorizationPolicyClause(ap);
    const identityClaims = claims.map((c) => [c.prefix, c.value].join('='));

    return {
      uuid: ap.uuid,
      namespace: ap.tenant_meta.namespace,
      targetNamespaces: ap.spec.target_namespaces,
      identitySource: source,
      identityClaims,
      permissions: ap.spec.permissions,
      expirationTime: ap.spec.expiration_time,
    };
  });
};
