import { QueryKey, QueryOptions, useQuery } from 'react-query';

import {
  QueryQuerySpec,
  QueryServiceApi,
  V1ListParameters,
  V1Query,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import {
  isBinaryProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { WithOnly, WithRequired } from '@endorlabs/utils';

import {
  ResourceListResponse,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import {
  getClientConfiguration,
  listAllResource,
  sortParamBuilders,
} from './utils';

type FindProjectVersionParams = {
  project: ProjectResource;
  versionRef: string;
};
type FindProjectVersionOptions = ResourceQueryOptions<
  ProjectVersionResource | undefined
>;
type ListProjectVersionParams = {
  project: ProjectResource;
};

type ListProjectVersionOptions = ResourceQueryOptions<ProjectVersionResource[]>;

export const QueryProjectVersionsQueryKeys = {
  find: (namespace: string, uuid: string, versionRef: string): QueryKey => [
    'v1/queries',
    namespace,
    'project-versions',
    uuid,
    versionRef,
  ],
  listAll: (namespace: string, uuid: string): QueryKey => [
    'v1/queries',
    namespace,
    'project-versions',
    uuid,
  ],
};

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (
  project: ProjectResource,
  listParameters: WithOnly<ListRequestParameters, 'filter'>
): QueryQuerySpec => {
  const commonListParameters: V1ListParameters = {
    // Return the required fields for Project Versions
    mask: ['context', 'meta.kind', 'meta.name', 'uuid'].join(','),
    // return most recent first
    sort: sortParamBuilders.descendingBy('meta.update_time'),
    // Disable traverse for these calls - project versions should reside in the same namespace
    traverse: false,
  };

  if (isBinaryProject(project)) {
    return {
      kind: 'PackageVersion',
      list_parameters: {
        ...commonListParameters,
        filter: filterExpressionBuilders.and([
          `spec.project_uuid=="${project.uuid}"`,
          listParameters.filter,
        ]),
      },
    };
  }

  if (project.spec.sbom) {
    return {
      kind: 'ImportedSBOM',
      list_parameters: {
        ...commonListParameters,
        filter: filterExpressionBuilders.and([
          `meta.parent_uuid=="${project.uuid}"`,
          listParameters.filter,
        ]),
      },
    };
  }

  return {
    kind: 'RepositoryVersion',
    list_parameters: {
      ...commonListParameters,
      filter: filterExpressionBuilders.and([
        `meta.parent_uuid=="${project.uuid}"`,
        listParameters.filter,
      ]),
    },
  };
};

const listProjectVersions = async (
  project: ProjectResource,
  listParameters: WithOnly<ListRequestParameters, 'filter'>,
  signal?: AbortSignal
) => {
  const namespace = project.tenant_meta.namespace;

  const query: WithRequired<V1Query, 'spec'> = {
    tenant_meta: { namespace },
    meta: {
      name: `ListProjectVersions(namespace=${namespace},project=${project.uuid}):`,
    },
    spec: { query_spec: buildQuerySpec(project, listParameters) },
  };

  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });

  // Extract the Project Version from the response
  const queryResponse = resp.data.spec?.query_response as
    | TResourceList<ProjectVersionResource>
    | undefined;

  const listResponse: ResourceListResponse<ProjectVersionResource> =
    queryResponse?.list ?? { objects: [], response: {} };
  return listResponse;
};

export const findProjectVersionQueryOptions = ({
  project,
  versionRef,
}: FindProjectVersionParams): QueryOptions<
  ProjectVersionResource | undefined
> => {
  const listParameters = {
    filter: `context.id=="${versionRef}"`,
    page_size: 1,
  };

  return {
    queryKey: QueryProjectVersionsQueryKeys.find(
      project.tenant_meta.namespace,
      project.uuid,
      versionRef
    ),
    queryFn: (ctx) =>
      listProjectVersions(project, listParameters, ctx.signal).then(
        (d) => d?.objects[0]
      ),
  };
};

export const listAllProjectVersionQueryOptions = (
  { project }: ListProjectVersionParams,
  listParams?: Pick<ListRequestParameters, 'filter'>
): QueryOptions<ProjectVersionResource[]> => {
  const listParameters = {
    filter: filterExpressionBuilders.defaultResourceContexts(),
    ...listParams,
  };

  return {
    queryKey: QueryProjectVersionsQueryKeys.listAll(
      project.tenant_meta.namespace,
      project.uuid
    ),
    queryFn: (ctx) =>
      listAllResource<
        ProjectVersionResource,
        TResourceList<ProjectVersionResource>
      >(
        (pageToken) => {
          const pageParameters = { ...listParameters, page_token: pageToken };

          return listProjectVersions(project, pageParameters, ctx.signal).then(
            (list) => ({ list })
          );
        },
        { signal: ctx.signal }
      ),
  };
};

export const useFindProjectVersion = (
  params: FindProjectVersionParams,
  options?: FindProjectVersionOptions
) => {
  const { queryFn, queryKey } = findProjectVersionQueryOptions(params);
  return useQuery({ ...options, queryFn, queryKey });
};

export const useListAllProjectVersions = (
  params: ListProjectVersionParams,
  listParams?: ListRequestParameters,
  options?: ListProjectVersionOptions
) => {
  const { queryFn, queryKey } = listAllProjectVersionQueryOptions(
    params,
    listParams
  );
  return useQuery({ ...options, queryFn, queryKey });
};
