import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { CiCdToolEvidence } from '@endorlabs/api_client';
import { AttributeDisplay, ExternalLink } from '@endorlabs/ui-common';

export const ToolEvidenceFilePathsDisplay = ({
  evidences,
  repositoryUrl,
  repositorySha,
}: {
  evidences?: CiCdToolEvidence[];
  repositoryUrl?: string;
  repositorySha?: string;
}) => {
  const { palette, spacing } = useTheme();

  if (!evidences?.length) {
    return;
  }

  return (
    <AttributeDisplay
      disableTypography
      heading={<FM defaultMessage="File Paths" />}
      value={
        <Stack spacing={spacing(2)}>
          {evidences.map((evidence, index) => {
            const filePath = evidence.file_path
              ? evidence.line_number
                ? `${evidence.file_path}#L${evidence.line_number}`
                : `${evidence.file_path}`
              : undefined;

            // TODO: link is valid only for GitHub, support other platform sources
            const fileLink =
              filePath && `${repositoryUrl}/blob/${repositorySha}/${filePath}`;

            if (fileLink) {
              return (
                <ExternalLink key={`${filePath}_${index}`} to={fileLink}>
                  {filePath}
                </ExternalLink>
              );
            }

            return (
              <Typography
                key={evidence.file_path}
                color={palette.info.main}
                noWrap
                paddingRight={4}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {filePath}
              </Typography>
            );
          })}
        </Stack>
      }
    />
  );
};
