import { Skeleton } from '@mui/material';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  MetricToolsByCategory,
  RepositoryResource,
  RepoVersionResource,
  selectMetricCiCdToolsByCategory,
  useListMetrics,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  CommonDataTableRowActions,
  DataTableView,
  EmptyState,
} from '@endorlabs/ui-common';

import { useProjectToolsDetailDrawer } from '../../../../components';
import { FIVE_MINUTES_IN_MILLISECONDS } from '../../../../constants';
import { FilterBar, useFilterContext } from '../../../filters';
import { ProjectCiCdToolsTableRow } from '../ProjectCiCdToolsTable/types';
import {
  buildProjectCiCdToolsTableColumns,
  mapToProjectCiCdToolsTableRows,
} from '../ProjectCiCdToolsTable/utils';

export interface ProjectCiCdToolsDataTableViewProps {
  namespace: string;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}

export const ProjectCiCdToolsDataTableView = ({
  namespace,
  repository,
  repositoryVersion,
}: ProjectCiCdToolsDataTableViewProps) => {
  const { formatMessage: fm } = useIntl();

  const { _state, updateFilter } = useFilterContext();
  const { DetailDrawer, permalinkEffect } = useProjectToolsDetailDrawer();

  const projectToolsFilter = useMemo(() => {
    if (!repositoryVersion) return;

    const expressions = [
      `meta.name==version_cicd_tools`,
      `meta.parent_uuid=="${repositoryVersion.uuid}"`,
    ];

    return filterExpressionBuilders.and(expressions);
  }, [repositoryVersion]);

  const qListProjectToolMetrics = useListMetrics(
    namespace,
    {
      enabled: !!projectToolsFilter,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: projectToolsFilter,
      // Should only have 1 tool metric for a given Repo Version
      page_size: 1,
    }
  );

  const { rows, toolCount } = useMemo(() => {
    let toolsByCategory = selectMetricCiCdToolsByCategory(
      qListProjectToolMetrics.data?.list?.objects[0]
    );

    const searchValue = _state.search;
    if (searchValue) {
      const filteredToolsByCategory: MetricToolsByCategory = {};

      Object.entries(toolsByCategory).forEach(([category, tools]) => {
        const isCategoryMatch = category
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        const isToolNameMatch = tools.some((tool) =>
          tool.name.toLowerCase().includes(searchValue.toLowerCase())
        );

        if (isCategoryMatch || isToolNameMatch) {
          filteredToolsByCategory[category] = tools;
        }
      });

      // Update to only matching tools
      toolsByCategory = filteredToolsByCategory;
    }

    const rows = mapToProjectCiCdToolsTableRows(toolsByCategory);

    const uniqueToolNames = new Set<string>();
    for (const pvt of rows) {
      for (const t of pvt.tools) {
        uniqueToolNames.add(t.name);
      }
    }

    return {
      rows,
      toolCount: uniqueToolNames.size,
    };
  }, [_state.search, qListProjectToolMetrics.data]);

  useLayoutEffect(() => {
    if (repositoryVersion) {
      permalinkEffect({ repository, repositoryVersion });
    }
  }, [permalinkEffect, repository, repositoryVersion]);

  const handleSearchChange = useCallback(
    (searchValue: string) => {
      updateFilter({ search: searchValue });
    },
    [updateFilter]
  );

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  const isLoading = !repositoryVersion || qListProjectToolMetrics.isLoading;
  const isEmptyState =
    !isLoading && !qListProjectToolMetrics.data?.list?.objects.length;

  const columns = useMemo(() => buildProjectCiCdToolsTableColumns(), []);
  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      isPrimaryAction: true,
      onClick: (row?: ProjectCiCdToolsTableRow) => {
        if (!namespace || !row?.category) return;

        DetailDrawer.activate(
          {
            namespace: namespace,
            toolCategory: row.category,
          },
          {
            namespace: namespace,
            repository,
            repositoryVersion: repositoryVersion,
            toolCategory: row.category,
          }
        );
      },
    },
  ];

  if (isEmptyState) {
    return (
      <EmptyState
        title={
          <FM defaultMessage="There are no tools in this project version" />
        }
        description={
          <FM defaultMessage="As tools are added to this project, they will appear here." />
        }
      />
    );
  }

  return (
    <DataTableView
      columns={columns}
      countMessage={
        isLoading ? (
          <Skeleton width={120} />
        ) : (
          fm(
            {
              defaultMessage:
                '{toolCount, plural, =0 {No tools} one {# tool} other {# tools}}',
            },
            { toolCount }
          )
        )
      }
      data={rows}
      filtersContent={
        <FilterBar
          fields={[]}
          isCard={false}
          searchPlaceholder={fm({
            defaultMessage: 'Search for Categories or Tools',
          })}
        />
      }
      isLoading={isLoading}
      namespace={namespace}
      rowActions={rowActions}
      emptyStateProps={{
        title: <FM defaultMessage="No Tools match the filter criteria" />,
        children: (
          <ButtonCancel onClick={handleClearSearch}>
            <FM defaultMessage="Clear Search" />
          </ButtonCancel>
        ),
      }}
    />
  );
};
