import { FormattedMessage as FM } from 'react-intl';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { ContainerDetailDrawerDependenciesSection } from './ContainerDetailDrawerDependenciesSection';
import { ContainerDetailDrawerFindingsSection } from './ContainerDetailDrawerFindingsSection';
import { ContainerDetailDrawerProps } from './types';

const ContainerDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Findings" />,
    value: 'findings',
  },
  {
    label: <FM defaultMessage="Dependencies" />,
    value: 'dependencies',
  },
];

/**
 * Detail Info Drawer for a Container Package Version
 */
export const ContainerDetailDrawer = (props: ContainerDetailDrawerProps) => {
  const { imageName, layerIndex } = props;

  const findingsContent = <ContainerDetailDrawerFindingsSection {...props} />;

  const dependenciesContent = (
    <ContainerDetailDrawerDependenciesSection {...props} />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        title: imageName ?? (
          <FM defaultMessage="Layer {layerIndex}" values={{ layerIndex }} />
        ),
      }}
      tabProps={{
        id: 'ContainerDetailDrawer',
        tabRecords: ContainerDetailDrawerTabRecords,
        tabPanelMap: {
          findings: findingsContent,
          dependencies: dependenciesContent,
        },
      }}
    />
  );
};
