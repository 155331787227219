import { RepoVersionResource } from '@endorlabs/queries';

interface KeyValue {
  key: string;
  value: string;
  /**
   * serialize the key-value pair, with optional separator value
   */
  toString(sep?: string): string;
}

const createKeyValue = (key: string, value: string): KeyValue => {
  return {
    key,
    value,
    toString: (sep = '=') => `${key}${sep}${value}`,
  };
};

const buildRepoVersionMatcher = (
  pathOrMatch: string | Record<'sha' | 'ref', string>
) => {
  let refPath: string, shaPath: string;

  if ('string' == typeof pathOrMatch) {
    refPath = `${pathOrMatch}.ref`;
    shaPath = `${pathOrMatch}.sha`;
  } else {
    refPath = pathOrMatch.ref;
    shaPath = pathOrMatch.sha;
  }

  return (repositoryVersion?: RepoVersionResource): KeyValue | undefined => {
    const repoVersionVersion = repositoryVersion?.spec.version;

    if (repoVersionVersion && repoVersionVersion.sha)
      return createKeyValue(shaPath, repoVersionVersion.sha);
    if (repoVersionVersion && repoVersionVersion.ref)
      return createKeyValue(refPath, repoVersionVersion.ref);
    if (repositoryVersion?.meta.name)
      return createKeyValue(refPath, repositoryVersion.meta.name);

    return undefined;
  };
};

/**
 * Given a RepositoryVersion, returns the preferred property name and value to match against Dependency Metadata.
 */
export const getBestDependencyMetadataMatchForRepoVersion =
  buildRepoVersionMatcher({
    sha: 'spec.importer_data.package_version_sha',
    ref: 'spec.importer_data.package_version_ref',
  });

/**
 * Given a RepositoryVersion, returns the preferred property name and value to match against a PackageVersion.
 */
export const getBestPackageMatchForRepoVersion = buildRepoVersionMatcher(
  'spec.source_code_reference.version'
);

/**
 * Given a RepositoryVersion, returns the preferred property name and value to match against a Finding.
 */
export const getBestFindingMatchForRepoVersion = buildRepoVersionMatcher(
  'spec.source_code_version'
);
