import { isMatch as _isMatch } from 'lodash-es';

import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  DependencyDetailDrawer,
  DependencyDetailDrawerProps,
} from '../components/DependencyDetailDrawer';

/**
 * necessary properties for finding target Dependency from list
 */
interface DependencyIdentifier {
  name: string;
  namespace?: string;
  uuid?: string;
}

export interface DependencyDetailDrawerParams {
  packageNamespace: string;
  packageUuid: string;
}

interface PermalinkEffectProps
  extends Pick<
    DependencyDetailDrawerProps,
    | 'importingNamespace'
    | 'importingPackageVersion'
    | 'importingProject'
    | 'importingProjectVersion'
  > {
  dependencies: DependencyIdentifier[];
}

export const useDependencyDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    DependencyDetailDrawerProps,
    DependencyDetailDrawerProps
  >({
    drawerParams: ['name', 'namespace'],
    Component: DependencyDetailDrawer,
  });

  const permalinkEffect = ({
    dependencies,
    ...drawerProps
  }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeDependency = activeDrawerParams
      ? dependencies.find((p) =>
          _isMatch(p, {
            name: activeDrawerParams.name,
          })
        )
      : undefined;

    const nextDrawerParams = {
      name: activeDependency?.name,
      namespace: activeDependency?.namespace,
    };

    if (
      activeDependency &&
      !DetailDrawer.matchesActiveContent(nextDrawerParams)
    ) {
      DetailDrawer.activate(nextDrawerParams, {
        ...drawerProps,
        name: activeDependency.name,
        namespace: activeDependency.namespace,
        uuid: activeDependency.uuid,
      });
    }
  };

  return { DetailDrawer, permalinkEffect };
};
