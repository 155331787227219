import { FormattedMessage as FM } from 'react-intl';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import { DataTableColumnTypeKeys as ColTypes } from '@endorlabs/ui-common';

import { DataTableRegistryEntry } from '../../Policies/constants/DataTableRegistry';
import { SASTRuleInAddPolicyRow } from '../types';
import { getCWEForSASTRules } from './utils';

export const SASTRulesSelectionTableRegistryEntry: DataTableRegistryEntry<
  SemgrepRulesResource,
  SASTRuleInAddPolicyRow
> = {
  // @ts-expect-error - accessorFn complaint. See https://github.com/TanStack/table/issues/4241
  buildColumns: () => {
    return [
      {
        accessorKey: 'ruleName',
        header: () => <FM defaultMessage="SAST Rule" />,
        colType: ColTypes.TEXT_LONG,
      },
      {
        accessorKey: 'definedBy',
        header: () => <FM defaultMessage="Defined By" />,
        colType: ColTypes.TEXT_WITH_DESCRIPTION,
      },
      {
        accessorKey: 'cwe',
        header: () => <FM defaultMessage="CWE" />,
      },
      {
        accessorKey: 'confidence',
        header: () => <FM defaultMessage="Confidence" />,
        colType: ColTypes.TEXT_WITH_DESCRIPTION,
      },
      {
        accessorKey: 'languages',
        header: () => <FM defaultMessage="Languages" />,
      },
      {
        accessorKey: 'ruleTags',
        header: () => <FM defaultMessage="Rule Tags" />,
        colType: ColTypes.TAGS,
      },
    ];
  },

  buildRows: (
    semgrepRules: SemgrepRulesResource[]
  ): SASTRuleInAddPolicyRow[] => {
    return semgrepRules.map((semgrepRule) => ({
      ruleName: semgrepRule.meta.name,
      definedBy: semgrepRule.spec?.defined_by ?? '',
      cwe: getCWEForSASTRules(semgrepRule.spec.rule?.metadata?.cwe),
      confidence: semgrepRule.spec.rule?.metadata?.confidence ?? '',
      languages: (semgrepRule.spec.rule?.languages || []).map(
        (value: string) => value
      ),
      ruleTags: semgrepRule.spec.rule?.metadata?.endor_tags,
    }));
  },
};
