import {
  Box,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/system';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { useStyles } from '../../hooks';
import { IconX } from '../../themes/icons';
import { ButtonSecondary } from '../Button/ButtonSecondary';
import { EmptyState } from '../EmptyState';
import { ControlledTextField } from './ControlledTextField';

export interface ControlledLargeMultistepOption {
  label: string;
  value: string;
}

interface ControlledLargeMultiselectProps {
  actions?: ReactNode;
  isLoading?: boolean;
  label?: ReactNode;
  name: string;
  options: ControlledLargeMultistepOption[];
  updateOptions?: (options: ControlledLargeMultistepOption[]) => void;
}

/**
 * Must be used within a FormProvider.
 */
export const ControlledLargeMultiselect = ({
  actions,
  isLoading,
  label,
  name,
  options = [],
  updateOptions,
}: ControlledLargeMultiselectProps) => {
  const { palette, space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const containerStyles = useStyles(styles);

  const { control, setValue } = useFormContext();

  // Ensure that hidden field name always ends with []
  const hiddenFieldName = useMemo(
    () => (name.match(/\[\]$/) ? name : `${name}[]`),
    [name]
  );

  // The options currently selected for submission.
  // Note that the hidden field that will be submitted can only contain strings.
  // However, the UI also needs to keep track of option labels.
  // Therefore, we must keep the hidden field values in sync with state here.
  const [availableOptions, setAvailableOptions] =
    useState<ControlledLargeMultistepOption[]>(options);

  useEffect(() => {
    setAvailableOptions(options);
    setCheckedOptions([]);
  }, [options]);

  // Sync the hidden field based on available options
  useEffect(() => {
    setValue(
      hiddenFieldName,
      availableOptions.map((opt) => opt.value)
    );
  }, [availableOptions, hiddenFieldName, setValue]);

  // The options manually checked by the user for removal.
  // Note we only track option value here.
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  // const [focusedOption, setFocusedOption] = useState<number>(0);

  const toggleCheckedOption = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      const { value } = evt.target;

      if (checkedOptions.includes(value)) {
        setCheckedOptions(checkedOptions.filter((v) => v !== value));
      } else {
        setCheckedOptions([...checkedOptions, value]);
      }
    },
    [checkedOptions]
  );

  // "Remove" button option. Remove any available options currently checked.
  const removeAvailableOptions = useCallback(() => {
    const updatedOptions = availableOptions.filter(
      (v) => !checkedOptions.includes(v.value)
    );

    updateOptions && updateOptions(updatedOptions);
    !updateOptions && setAvailableOptions(updatedOptions);

    setCheckedOptions([]);
  }, [availableOptions, checkedOptions, updateOptions]);

  return (
    <Stack className="ControlledLargeMultiselect__root" sx={containerStyles}>
      {/*
        Hidden form field containing values of currently selected options.
        Note this is the only true named form field in the component.
      */}
      <ControlledTextField
        control={control}
        id={`field-${name}`}
        label={label}
        name={hiddenFieldName}
        type="hidden"
      />

      <Stack
        className="ControlledLargeMultiselect__container"
        direction="row"
        spacing={space.xs}
        width="100%"
      >
        <Stack className="ControlledLargeMultiselect__selector" spacing={1}>
          <Box sx={{ border: `1px solid ${palette.text.secondary}` }}>
            <Stack
              className="ControlledLargeMultiselect__options"
              flexWrap="nowrap"
              height={240}
              spacing={space.sm}
            >
              {isLoading &&
                Array(4)
                  .fill(null)
                  .map((_, i) => <Skeleton key={i} sx={{ width: '80%' }} />)}

              {!isLoading && availableOptions.length === 0 && (
                <EmptyState
                  size="small"
                  title={<FM defaultMessage="No items selected" />}
                />
              )}

              {!isLoading &&
                availableOptions.map((option, i) => {
                  const checked = checkedOptions.includes(option.value);
                  return (
                    <FormControlLabel
                      checked={checked}
                      className={[
                        'ControlledLargeMultiselect__checkboxItem',
                        checked ? 'Mui-selected' : '',
                      ].join(' ')}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={toggleCheckedOption}
                          sx={{ marginRight: space.xs }}
                          value={option.value}
                        />
                      }
                      key={option.value}
                      label={option.label}
                      // tabIndex={i === focusedOption ? 0 : -1}
                      // tabIndex={0}
                      // onClick={updateOptions}
                    />
                  );
                })}
            </Stack>
          </Box>
          <Typography
            className="ControlledLargeMultiselect__count"
            component="h4"
            variant="body2"
          >
            <FM
              defaultMessage="{count, plural, =0 {No items} one {# item} other {# items}}"
              values={{ count: availableOptions.length }}
            />
          </Typography>
        </Stack>

        <Stack
          className="ControlledLargeMultiselect__actions"
          spacing={space.xs}
        >
          {/* Parent-provided action buttons */}
          {actions}

          <ButtonSecondary
            disabled={!checkedOptions.length}
            onClick={removeAvailableOptions}
            startIcon={<IconX />}
          >
            <FM defaultMessage="Remove" />
          </ButtonSecondary>
        </Stack>
      </Stack>
    </Stack>
  );
};

const styles = ({ palette, space, spacing, typography }: Theme) => {
  return {
    '.ControlledLargeMultiselect__selector': {
      flexGrow: 3,
      position: 'relative',
    },

    '.ControlledLargeMultiselect__checkboxItem': {
      alignItems: 'flex-start',
      display: 'flex',
      marginLeft: 0,
      marginTop: '1px',
      padding: spacing(1, 0),

      '&:has(.Mui-focusVisible)': {
        border: '1px solid green',
      },

      '&:has(input:checked)': {
        backgroundColor: alpha(
          palette.primary.main,
          palette.action.selectedOpacity
        ),
      },

      '& .MuiCheckbox-root': {
        marginRight: '4px',
        visibility: 'hidden',
        width: 0,
      },

      '& .MuiFormControlLabel-label': {
        fontSize: typography.body2.fontSize,
      },
    },
    '.ControlledLargeMultiselect__options': {
      overflowY: 'auto',
      padding: space.xs,
    },
    '.ControlledLargeMultiselect__actions': {
      flexShrink: 0,
    },
    '.ControlledLargeMultiselect__count': {
      paddingRight: '4px',
      textAlign: 'right',
      width: '100%',
    },
  };
};
