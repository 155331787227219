import { differenceInCalendarDays, isAfter, isValid, parseISO } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { TenantType } from '@endorlabs/endor-core/auth';
import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import { TenantInfo, useSession } from '@endorlabs/queries';

import {
  DEFAULT_EXPIRY_PERIOD_FOR_EXISTING_TENANTS,
  LICENSE_PERIOD_EXPIRY_INVALID,
} from '../../components/AppLayout/constants';
import { useAuthInfo } from './useAuthInfo';

/**
 * Hook wrapping AuthInfo, with additional information about the current tenant
 *
 * @param targetNamespace allow overriding the namespace used to identify the tenant
 */
export const useAuthTenantInfo = (targetNamespace?: string) => {
  const { activeNamespace } = useAuthInfo();
  const { session } = useSession();

  /**
   * Wrapper around user tenants, returning the tenant info for the active
   * namespace, falling back to the last used tenant when not found.
   */
  const tenantInfo = useMemo(() => {
    const tenantName = getRootNamespace(targetNamespace ?? activeNamespace);

    const defaultTenantInfo: TenantInfo = {
      uuid: '000000000000000000000000',
      name: tenantName,
      tenantType: TenantType.Unspecified,
    };

    const tenantInfo = session?.tenants?.find((t) => t.name === tenantName);

    return tenantInfo ?? defaultTenantInfo;
  }, [activeNamespace, session?.tenants, targetNamespace]);

  const isPremiumTenant = useMemo(() => {
    return tenantInfo.tenantType === TenantType.Premium;
  }, [tenantInfo.tenantType]);

  const isFreeTrialTenant = useMemo(() => {
    return (
      tenantInfo.tenantType === TenantType.Trial ||
      tenantInfo.tenantType === TenantType.TrialExpired
    );
  }, [tenantInfo.tenantType]);

  const isSharedTenant = useMemo(() => {
    return tenantInfo.tenantType === TenantType.Shared;
  }, [tenantInfo.tenantType]);

  const getIsSharedTenant = (tenantName: string) => {
    const tenantInfo = session?.tenants?.find((t) => t.name === tenantName);
    return tenantInfo?.tenantType === TenantType.Shared;
  };

  /**
   * The remaining time period (in days) until the tenant's free trial or premium tenants license expires
   */
  const getRemainingExpiryPeriod = useCallback(() => {
    if (!tenantInfo.expiry) return 0;

    const parsedDate = parseISO(tenantInfo.expiry);
    // Handle situation for existing tenants in case of premium type, were expiry time is not set
    if (
      !isValid(parsedDate) ||
      tenantInfo.expiry === DEFAULT_EXPIRY_PERIOD_FOR_EXISTING_TENANTS
    )
      return LICENSE_PERIOD_EXPIRY_INVALID;

    const now = Date.now();
    const expiryDate = new Date(tenantInfo.expiry);
    if (isAfter(now, expiryDate)) return 0;

    return differenceInCalendarDays(expiryDate, Date.now());
  }, [tenantInfo.expiry]);

  return {
    isFreeTrialTenant,
    isSharedTenant,
    getIsSharedTenant,
    getRemainingExpiryPeriod,
    tenantInfo,
    isPremiumTenant,
  };
};
