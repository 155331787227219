import {
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import {
  addDays,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IconClock, RowStack } from '@endorlabs/ui-common';

import { FilterDropdown } from '../../domains/filters/components/FilterDropdown';
import { FacetFilterDefinition } from './types';

// initialize date presets on load
const DATE_NOW = new Date();
const DATE_TODAY = startOfDay(DATE_NOW);
const FORMATTED_DATE_TODAY = DATE_TODAY.toISOString();
const FORMATTED_DATE_PRESETS = [
  {
    id: 'all_time',
    label: <FM defaultMessage="All Time" />,
    value: undefined,
  },
  {
    id: 'last_day',
    label: <FM defaultMessage="Last Day" />,
    value: startOfYesterday().toISOString(),
  },
  {
    id: 'last_week',
    label: <FM defaultMessage="Last Week" />,
    value: startOfWeek(subWeeks(DATE_TODAY, 1), {
      weekStartsOn: 1,
    }).toISOString(),
  },
  {
    id: 'last_month',
    label: <FM defaultMessage="Last Month" />,
    value: startOfMonth(subMonths(DATE_TODAY, 1)).toISOString(),
  },
  {
    id: 'last_7_days',
    label: <FM defaultMessage="Last 7 Days" />,
    value: subDays(DATE_TODAY, 7).toISOString(),
  },
  {
    id: 'last_30_days',
    label: <FM defaultMessage="Last 30 Days" />,
    value: subDays(DATE_TODAY, 30).toISOString(),
  },
  {
    id: 'last_60_days',
    label: <FM defaultMessage="Last 60 Days" />,
    value: subDays(DATE_TODAY, 60).toISOString(),
  },
  {
    id: 'last_90_days',
    label: <FM defaultMessage="Last 90 Days" />,
    value: subDays(DATE_TODAY, 90).toISOString(),
  },
];

const getDatePresetLabel = (value: string) => {
  const start = subDays(new Date(value), 1);
  const end = addDays(new Date(value), 1);

  const preset = FORMATTED_DATE_PRESETS.find(
    (p) => p.value && isWithinInterval(new Date(p.value), { start, end })
  );

  return preset?.label ?? <FM defaultMessage="Custom" />;
};

export interface ControlsFacetFilterDatePickerProps {
  facet: FacetFilterDefinition;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

/**
 * Facet Filter control for Date
 */
export const ControlsFacetFilterDatePicker = ({
  facet,
  value,
  onChange,
}: ControlsFacetFilterDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<string | undefined>(value);

  const handleApply = (event?: React.MouseEvent<HTMLButtonElement>) => {
    onChange(selectedDate);
  };

  const handleCancel = (event?: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedDate(value);
  };

  const content = (
    <>
      {facet.label && (
        <Typography paddingTop={4} paddingX={6}>
          {facet.label}
        </Typography>
      )}

      <Stack direction="row">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="day"
            maxDate={FORMATTED_DATE_TODAY}
            value={selectedDate}
            onChange={(date) => {
              if (date) {
                const updatedDate = new Date(date);
                setSelectedDate(updatedDate.toISOString());
              } else {
                setSelectedDate(undefined);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <List disablePadding sx={{ marginTop: 4, marginRight: 6 }}>
          {FORMATTED_DATE_PRESETS.map((preset) => (
            <ListItemButton
              key={preset.id}
              onClick={() => setSelectedDate(preset.value)}
              selected={selectedDate === preset.value}
            >
              {preset.label}
            </ListItemButton>
          ))}
        </List>
      </Stack>
    </>
  );

  const buttonText = useMemo(() => {
    if (value) {
      return getDatePresetLabel(value);
    }

    return <FM defaultMessage="All Time" />;
  }, [value]);

  return (
    <FilterDropdown
      id="ControlsFacetFilterDatePicker-popover"
      isActive={Boolean(value)}
      label={
        <RowStack gap={0}>
          <IconClock fontSize="small" sx={{ marginRight: '6px' }} />
          {buttonText}
        </RowStack>
      }
      onApply={handleApply}
      onCancel={handleCancel}
      useContentContainer={false}
    >
      {content}
    </FilterDropdown>
  );
};
