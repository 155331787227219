import { useMatch } from '@tanstack/react-location';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';

import { ProjectVersionInventoryView } from '../../../../../../../views/ProjectVersion/ProjectVersionInventoryView';
import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionDetailInventoryRoute = () => {
  const { data, params } = useMatch();
  const project = data.project as ProjectResource;
  const projectVersion = data.projectVersion as ProjectVersionResource;

  // TODO: pass activeView to inventory page
  const activeView = params['inventory_view'];

  return (
    <ProjectVersionInventoryView
      activeView={activeView}
      project={project}
      projectVersion={projectVersion}
    />
  );
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/:version_ref/:inventory_view'
)({
  component: TenantProjectVersionDetailInventoryRoute,
});
