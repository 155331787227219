import { Chip, Theme, Tooltip } from '@mui/material';
import { ChipProps } from '@mui/material/Chip';
import clsx from 'clsx';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel as FindingLevel } from '@endorlabs/api_client';

import { useStyles } from '../../../../hooks';
import { UIFindingUtils } from '../../utils';
import { FindingLevelLabel } from '../FindingLevelLabel';

export interface FindingLevelChipProps
  extends Pick<ChipProps, 'disabled' | 'size'> {
  abbreviate?: boolean;
  level: FindingLevel;
}

export const FindingLevelChip = ({
  abbreviate = true,
  disabled = false,
  level,
  size = 'medium',
}: FindingLevelChipProps) => {
  const sx = useStyles(styles, { level, size });

  const classNames = clsx({
    'FindingLevelChip-root': true,
    [`FindingLevelChip-level-${level}`]: true,
    'FindingLevelChip-sizeXsmall': size === 'xsmall',
    'FindingLevelChip-sizeSmall': size === 'small',
    'FindingLevelChip-sizeMedium': size === 'medium',
  });

  return (
    <Tooltip
      title={
        <FM
          defaultMessage="{level} Finding"
          values={{
            level: <FindingLevelLabel findingLevel={level} />,
          }}
        />
      }
    >
      <Chip
        className={classNames}
        disabled={disabled}
        label={
          <FindingLevelLabel abbreviate={abbreviate} findingLevel={level} />
        }
        size={size}
        sx={sx}
      />
    </Tooltip>
  );
};

const styles = (
  theme: Theme,
  { level }: FindingLevelChipProps = {
    level: FindingLevel.Critical,
  }
) => {
  const { palette, spacing, typography } = theme;
  const bgColor = UIFindingUtils.getSeverityColor(level, theme);

  return {
    '&.FindingLevelChip-root': {
      backgroundColor: bgColor,
      // FIXME: For accessibility, this really should be contrastText.
      color: palette.common.white,
      // color: palette.getContrastText(bgColor),
      flexShrink: 0,
      fontSize: typography.caption.fontSize,

      '&.Mui-disabled': {
        backgroundColor: palette.action.disabled,
      },

      '& .MuiChip-label': {
        padding: 0,
        textAlign: 'center',
        userSelect: 'none',
      },
    },

    '&.FindingLevelChip-sizeXsmall': {
      borderRadius: '4px',
      fontSize: typography.pxToRem(11),
      fontWeight: 500,
      height: spacing(4),
      width: spacing(4),
    },

    '&.FindingLevelChip-sizeSmall': {
      borderRadius: '50%',
      height: spacing(5),
      width: spacing(5),
    },

    '&.FindingLevelChip-sizeMedium': {
      borderRadius: '50%',
      height: spacing(6),
      width: spacing(6),
    },
  };
};
