import { Box } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
} from '@endorlabs/ui-common';

import { ProjectToolsTableRow } from './types';

export interface ProjectToolsTableProps
  extends Omit<DataTableProps<ProjectToolsTableRow>, 'columns'> {
  onClickDetail?: (row?: ProjectToolsTableRow) => void;
}

const buildProjectToolsTableColumns = ({
  onClickDetail,
}: Omit<
  ProjectToolsTableProps,
  'data'
>): DataTableColumnDef<ProjectToolsTableRow>[] => {
  const columns: DataTableColumnDef<ProjectToolsTableRow>[] = [
    {
      accessorKey: 'category',
      colType: ColTypes.TOOL_CATEGORY,
    },
    {
      accessorKey: 'toolCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="# of Tools" />,
    },
    {
      accessorKey: 'tools',
      colType: ColTypes.TOOLS_NAMES,
    },
  ];

  // Show the actions
  if (onClickDetail) {
    columns.push({
      id: 'actions',
      cell: ({ row }) =>
        row.original && (
          <Box display="flex" justifyContent="end">
            <DataTableDrawerButton
              onClick={(event) => {
                event.stopPropagation();
                onClickDetail(row.original);
                if (!row.getIsSelected()) row.toggleSelected();
              }}
            />
          </Box>
        ),
      colType: ColTypes.ACTIONS,
      header: '',
    });
  }

  return columns;
};

/**
 * @deprecated replaced by {@see ProjectCiCdToolsTable} and will be removed in the future
 */
export const ProjectToolsTable = forwardRef(function ProjectToolsTableComponent(
  { onClickDetail, ...props }: ProjectToolsTableProps,
  ref: ForwardedRef<Table<ProjectToolsTableRow>>
) {
  const columns = buildProjectToolsTableColumns({ onClickDetail });
  function handleRowClick(row: ProjectToolsTableRow) {
    if (onClickDetail) {
      return onClickDetail(row);
    }
  }
  /* User should select only one row at a time when displaying row detail. */
  const isSingleRowSelect = onClickDetail ? true : false;
  return (
    <DataTable
      {...props}
      ref={ref}
      columns={columns}
      onRowClick={handleRowClick}
      isSingleRowSelect={isSingleRowSelect}
    />
  );
});
