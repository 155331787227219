import { Avatar, Grid, Theme, Typography } from '@mui/material';
import { useMemo } from 'react';

import { UserResource } from '@endorlabs/queries';

interface UserDisplayProps {
  background?: string;
  showAvatar?: boolean;
  showName?: boolean;
  size?: 'small' | 'medium' | 'large';
  user: UserResource;
}

/**
 * Basic display for a user, outputting user name, avatar, or both.
 * Name is determined from best available combination of user name properties (first/last, user_name, email, etc)
 * Avatar by default displays initials based on name. Avatar color is determined by a hash based on name.
 * TODO: Use avatar image from auth provider, if present.
 */
export const UserDisplay = ({
  background,
  showAvatar = true,
  showName = true,
  size = 'medium',
  user,
}: UserDisplayProps) => {
  const displayName = useMemo(() => getName(user), [user]);
  const nameHash = useMemo(() => getHash(displayName), [displayName]);

  return (
    <Grid
      container
      alignItems="center"
      flexWrap={showName ? 'nowrap' : 'wrap'}
      spacing={2}
      sx={{ cursor: 'pointer' }}
    >
      {showAvatar && (
        <Grid item>
          <Avatar
            alt={displayName}
            className={`hash_${nameHash.slice(0, 1)} ${size}`}
            sx={sx.avatar}
          >
            <Typography sx={{ fontSize: 'inherit' }}>
              {getInitials(user)}
            </Typography>
          </Avatar>
        </Grid>
      )}

      {showName && (
        <Grid item>
          <Typography
            sx={{
              color: ({ palette }) =>
                palette.getContrastText(background ?? palette.background.paper),
            }}
          >
            {displayName}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const getName = (user: UserResource): string | undefined => {
  const { meta, spec } = user;
  if (spec.user_name) return spec.user_name;
  if (spec.first_name) return [spec.first_name, spec.last_name].join(' ');
  if (spec.email) return spec.email.split('@')[0];
  if (meta.name) return meta.name.split('@')[0];

  return undefined;
};

const getInitials = (user: UserResource) => {
  const name = getName(user);

  if (name) {
    return name
      .split(' ')
      .slice(0, 2)
      .map((name) => name.slice(0, 1))
      .join('')
      .toUpperCase();
  }

  return undefined;
};

const getHash = (str: string | undefined): string => {
  if (!str) return '0';

  const h = Array.from(str).reduce(
    (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
    0
  );
  return h.toString();
};

const sx = {
  avatar: {
    '&.small': {
      fontSize: ({ typography }: Theme) => typography.body2.fontSize,
      height: 16,
      width: 16,
    },
    '&.medium': {
      fontSize: ({ typography }: Theme) => typography.caption.fontSize,
      height: 24,
      width: 24,
    },
    '&.large': {
      fontSize: ({ typography }: Theme) => typography.h4.fontSize,
      height: 40,
      width: 40,
    },

    // TODO: Better colors in keeping with theme
    '&.hash_0, &.hash_1': {
      backgroundColor: 'red',
    },
    '&.hash_2, &.hash_3': {
      backgroundColor: 'purple',
    },
    '&.hash_4, &.hash_5': {
      backgroundColor: 'blue',
    },
    '&.hash_6, &.hash_7': {
      backgroundColor: 'green',
    },
    '&.hash_8, &.hash_9': {
      backgroundColor: 'orange',
    },
  },
  label: {},
};
