import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  InvitationServiceApi,
  V1CountResponse,
  V1Invitation,
  V1ListParameters,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import { InvitationResource, InvitationResourceList } from './types';
import { ResourceMutateOptions, ResourceQueryOptions } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface InvitationReadParams {
  namespace: string;
  uuid: string;
}

export interface InvitationWriteParams {
  namespace: string;
  resource: V1Invitation;
}

export interface InvitationUpdateParams extends InvitationWriteParams {
  mask?: string;
}

type CountInvitationOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListInvitationOptions = ResourceQueryOptions<InvitationResourceList>;
type GetInvitationOptions = ResourceQueryOptions<InvitationResource>;
type UpsertInvitationOptions = ResourceMutateOptions<
  V1Invitation,
  InvitationWriteParams
>;
type DeleteInvitationOptions = ResourceMutateOptions<
  object,
  InvitationReadParams
>;

const BASE_KEY = 'v1/invitations';
const QK = {
  count: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, listParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const InvitationQueryKeys = QK;

export const INVITATION_UPDATE_MASK = 'meta,spec';

const getApiService = () => new InvitationServiceApi(getClientConfiguration());

export const countInvitations = async (
  namespace: string,
  countParams: V1ListParameters
) => {
  const api = getApiService();
  const resp = await api.invitationServiceListInvitations(
    namespace,
    ...buildCountParamArgs(countParams)
  );
  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountInvitations = (
  namespace: string,
  opts: CountInvitationOptions = {},
  countParams: V1ListParameters = {}
) => {
  return useQuery(
    QK.count(namespace, countParams),
    () => countInvitations(namespace, countParams),
    opts
  );
};

export const listInvitations = async (
  namespace: string,
  listParams?: V1ListParameters
) => {
  const api = getApiService();
  const resp = await api.invitationServiceListInvitations(
    namespace,
    ...buildListParamArgs(listParams ?? {})
  );
  return resp.data as InvitationResourceList;
};

export const useListInvitations = (
  namespace: string,
  opts: ListInvitationOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Invitation',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listInvitations(namespace, requestParameters),
    opts
  );
};

export const getInvitation = async ({
  namespace,
  uuid,
}: InvitationReadParams) => {
  const api = getApiService();
  const resp = await api.invitationServiceGetInvitation(namespace, uuid);
  return resp.data as InvitationResource;
};

export const useGetInvitation = (
  params: InvitationReadParams,
  opts: GetInvitationOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getInvitation(params),
    opts
  );
};

const createInvitation = async (params: InvitationWriteParams) => {
  const api = getApiService();
  const resp = await api.invitationServiceCreateInvitation(
    params.namespace,
    params.resource
  );
  return resp.data as InvitationResource;
};

export const useCreateInvitation = (opts: UpsertInvitationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Invitation'),
    mutationFn: (params: InvitationWriteParams) => createInvitation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateInvitation = async (params: InvitationUpdateParams) => {
  const { resource, namespace, mask = INVITATION_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.invitationServiceUpdateInvitation(namespace, req);
  return resp.data as InvitationResource;
};

export const useUpdateInvitation = (opts: UpsertInvitationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Invitation'),
    mutationFn: (params: InvitationUpdateParams) => updateInvitation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteInvitation = async (params: InvitationReadParams) => {
  const api = getApiService();
  const resp = await api.invitationServiceDeleteInvitation(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteInvitation = (opts: DeleteInvitationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Invitation'),
    mutationFn: (params: InvitationReadParams) => deleteInvitation(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
