import { Skeleton } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  RepositoryResource,
  RepoVersionResource,
  selectMetricCiCdToolsByCategory,
  useListMetrics,
} from '@endorlabs/queries';

import { DetailDrawerContainer } from '../DetailDrawer';
import { InfoDrawerContentProps } from '../InfoDrawer';
import { ProjectToolDetailDrawerToolEvidence } from './ProjectToolDetailDrawerToolEvidence';

const ProjectToolsDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Evidence" />,
    value: 'evidence',
  },
];

export interface ProjectToolsDetailDrawerProps extends InfoDrawerContentProps {
  namespace: string;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
  toolCategory: string;
}

/**
 * Detail Info Drawer for a Project Tools Table rows
 */
export const ProjectToolsDetailDrawer = ({
  namespace,
  repository,
  repositoryVersion,
  toolCategory,
}: ProjectToolsDetailDrawerProps) => {
  const qListCiCdTools = useListMetrics(
    repositoryVersion?.tenant_meta.namespace ?? '',
    { enabled: !!repositoryVersion },
    {
      filter: filterExpressionBuilders.and([
        `meta.name==version_cicd_tools`,
        `meta.parent_uuid=="${repositoryVersion?.uuid}"`,
      ]),
      mask: 'spec.metric_values',
      page_size: 1,
    }
  );

  const isLoading = qListCiCdTools.isLoading;

  const toolsByCategory = selectMetricCiCdToolsByCategory(
    qListCiCdTools.data?.list?.objects[0]
  );
  const tools = toolsByCategory[toolCategory] ?? [];

  const title = isLoading ? <Skeleton width={240} /> : toolCategory;

  const evidenceContent = (
    <ProjectToolDetailDrawerToolEvidence
      tools={tools}
      repository={repository}
      repositoryVersion={repositoryVersion}
    />
  );

  return (
    <DetailDrawerContainer
      headerProps={{ title }}
      tabProps={{
        id: 'project-tool-detail-drawer',
        tabPanelMap: {
          evidence: evidenceContent,
        },
        tabRecords: ProjectToolsDetailDrawerTabRecords,
      }}
    />
  );
};
