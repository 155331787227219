import { without as _without } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { DEFAULT_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import { MultiSelectInput } from '@endorlabs/ui-common';
import { EcosystemLabel } from '@endorlabs/ui-common/domains/Package';

import {
  FilterFieldConfig,
  filterFieldTransformBuilders,
} from '../../../filters';

export const FindingEcosystemFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.ecosystem',

  ...filterFieldTransformBuilders.fromFilter({
    key: 'spec.ecosystem',
    comparator: FILTER_COMPARATORS.IN,
  }),

  renderInput: ({ onChange, value }) => (
    <MultiSelectInput
      label={<FM defaultMessage="Ecosystem" />}
      onChange={onChange}
      value={value}
      options={_without(
        DEFAULT_ECOSYSTEMS,
        // Exclude ecosystems that are not applicable for Findings.
        // The ecosystem set in the Finding is taken from the dependency
        // ecosystem, rather than the package ecosystem.
        V1Ecosystem.GithubAction,
        V1Ecosystem.Container
      )
        .sort((a, b) => a.localeCompare(b))
        .map((value) => ({
          value,
          // eslint-disable-next-line react/jsx-no-undef
          label: <EcosystemLabel value={value} />,
        }))}
    />
  ),
} satisfies FilterFieldConfig<V1Ecosystem[]>;
