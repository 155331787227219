import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Popover,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { lightBlue } from '@mui/material/colors';
import { isNil as _isNil } from 'lodash-es';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { TenantType } from '@endorlabs/endor-core/auth';
import { useFeatureFlags, useSession } from '@endorlabs/queries';
import {
  IconLogout,
  IconMoon,
  IconSun,
  IconZap,
  Link,
  UserDisplay,
} from '@endorlabs/ui-common';

import { getEnv, Layout } from '../../constants';
import { useOnboardingSteps } from '../../domains/Onboarding';
import { useAuthTenantInfo } from '../../providers';
import { getOnboardRootPath } from '../../routes';
import { NamedRoutes } from '../../routes/constants';
import { OnboardingBtn } from '../OnboardingButton/OnboardingBtn';

export interface SessionDisplayProps {
  appDrawerMinimized?: boolean;
  tenantName: string | undefined;
}

const { URL_ENDOR_SALES } = getEnv();

export const SessionDisplay = ({
  appDrawerMinimized,
  tenantName,
}: SessionDisplayProps) => {
  const { getAuthProvider, getUser, getUserTenants } = useSession();
  const authProvider = getAuthProvider();
  const { formatMessage: fm } = useIntl();
  const user = getUser();
  const theme = useTheme();
  const { palette, space, spacing, typography } = theme;
  const sx = styles(theme);

  const darkMode = palette.darkMode;
  const darkModeIcon = darkMode ? <IconSun /> : <IconMoon />;

  const {
    isFreeTrialTenant,
    isSharedTenant,
    tenantInfo,
    getRemainingExpiryPeriod,
    isPremiumTenant,
  } = useAuthTenantInfo(tenantName);

  const { getOnboardingProgress, getIsOnboardingComplete } =
    useOnboardingSteps();

  const freeTrialTenantName = useMemo(() => {
    if (isFreeTrialTenant) return tenantInfo.name;
    if (!isSharedTenant) return;

    return getUserTenants().find((t) => t.tenantType === TenantType.Trial)
      ?.name;
  }, [getUserTenants, isFreeTrialTenant, isSharedTenant, tenantInfo.name]);

  const showTrialPeriod = !_isNil(freeTrialTenantName);

  /* Show onboarding button for
   *  a) premium tenant if onboarding steps are not completed
   *  b) free trial tenants
   *  c) shared tenants like demo-trial when user just sign-up
   */
  const premiumTenantOnboarding =
    (isPremiumTenant || isSharedTenant) && !getIsOnboardingComplete();

  const showOnboardingBtn = showTrialPeriod || premiumTenantOnboarding;

  const onboardingTenantName = freeTrialTenantName
    ? freeTrialTenantName
    : premiumTenantOnboarding
    ? tenantName
    : undefined;

  const url = onboardingTenantName
    ? getOnboardRootPath({
        tenantName: onboardingTenantName,
      })
    : NamedRoutes.TENANT_CREATE;

  const onboardingProgress = isFreeTrialTenant ? getOnboardingProgress() : 0;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (showTrialPeriod) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const isPopoverOpen = Boolean(anchorEl);
  const id = isPopoverOpen ? 'simple-popper' : undefined;

  if (!user) return null;

  return (
    <Grid container direction="column" item spacing={2}>
      {showOnboardingBtn && (
        <Grid item alignItems="center" sx={sx.btnContainer}>
          <OnboardingBtn
            appDrawerMinimized={appDrawerMinimized}
            btnLabel={<FM defaultMessage="Getting Started" />}
            linkProps={{
              to: url,
            }}
            progress={onboardingProgress}
          />
        </Grid>
      )}

      <Grid
        alignItems="center"
        container
        item
        onClick={handleClick}
        role="button"
        spacing={2}
        sx={sx.user}
      >
        <Grid item>
          <UserDisplay
            background={palette.background.dark}
            user={user}
            showName={!appDrawerMinimized}
          />
        </Grid>

        {showTrialPeriod && (
          <Grid item>
            <Chip
              sx={{
                backgroundColor: lightBlue[600],
                color: palette.background.paper,
              }}
              label="Trial"
              size="small"
            />
          </Grid>
        )}
      </Grid>

      {showTrialPeriod && (
        <Grid item>
          <Popover
            anchorEl={anchorEl}
            id={id}
            onClose={() => setAnchorEl(null)}
            open={isPopoverOpen}
            sx={{ zIndex: (theme) => theme.zIndex.tooltip }}
          >
            <Card elevation={12}>
              <CardContent>
                <Stack spacing={space.sm} width={spacing(50)}>
                  {tenantInfo.tenantType === TenantType.Trial && (
                    <Typography>
                      <FM
                        defaultMessage="You have {remainingTrialPeriod} days remaining in your free trial."
                        values={{
                          remainingTrialPeriod: getRemainingExpiryPeriod(),
                        }}
                      />
                    </Typography>
                  )}
                  {tenantInfo.tenantType === TenantType.TrialExpired && (
                    <Typography>
                      <FM defaultMessage="Your free trial is over, but your time with Endor Labs doesn't have to end!" />
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    component={Link}
                    href={URL_ENDOR_SALES}
                    startIcon={<IconZap />}
                    sx={{
                      fontSize: typography.body2.fontSize,
                      lineHeight: 1.25,
                    }}
                    target="_blank"
                    variant="contained"
                  >
                    <FM
                      defaultMessage="Ready for more?<br></br>Contact Us"
                      values={{ br: () => <br /> }}
                    />
                  </Button>

                  <Button
                    href={NamedRoutes.AUTH_LOGOUT.replace(
                      ':authProvider',
                      authProvider as string
                    )}
                    size="medium"
                    startIcon={<IconLogout />}
                    sx={{ alignSelf: 'baseline' }}
                  >
                    <FM defaultMessage="Logout" />
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Popover>
        </Grid>
      )}

      {/* <Grid item xs={12}>
        <Button startIcon={darkModeIcon}>
          {palette.darkMode ? (
            <FM defaultMessage="Light Mode" />
          ) : (
            <FM defaultMessage="Dark Mode" />
          )}
        </Button>
      </Grid> */}

      {!showTrialPeriod && (
        <Grid item alignItems="center">
          <Tooltip
            title={appDrawerMinimized ? fm({ defaultMessage: 'Logout' }) : ''}
            arrow
            placement="right"
          >
            <Button
              href={NamedRoutes.AUTH_LOGOUT.replace(
                ':authProvider',
                authProvider as string
              )}
              size="small"
              startIcon={<IconLogout />}
              sx={sx.logout}
            >
              {!appDrawerMinimized && <FM defaultMessage="Logout" />}
            </Button>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

function styles({ breakpoints, palette }: Theme) {
  return {
    btnContainer: {
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        margin: '4px',
      },
    },
    user: {
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        justifyContent: 'space-around',
      },
      display: 'flex',
      justifyContent: 'space-between',
    },
    logout: {
      color: palette.grey[200],
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        color: palette.grey[200],
        paddingLeft: 2,
        paddingRight: 0,
      },
    },
  };
}
