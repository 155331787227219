import { Stack, Typography, TypographyProps, useTheme } from '@mui/material';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { MetricResource } from '@endorlabs/endor-core/Metric';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { RepositoryResource } from '@endorlabs/queries';
import { ChecklistDisplay, DescriptionListItem } from '@endorlabs/ui-common';

import { ProjectOverviewLanguageBreakdown } from '../ProjectOverviewLanguageBreakdown';

const ProjectOverviewMetadataLabels = defineMessages({
  branchProtections: {
    defaultMessage: 'Branch Protections',
  },
  description: {
    defaultMessage: 'Description',
  },
  organization: {
    defaultMessage: 'Organization',
  },
  languages: {
    defaultMessage: 'Languages Breakdown',
  },
  permissions: {
    defaultMessage: 'Permissions',
  },
  protectedBranches: {
    defaultMessage: 'Protected Branches',
  },
  repositoryUuid: {
    defaultMessage: 'Repository UUID',
  },
  settings: {
    defaultMessage: 'Settings',
  },
  uuid: {
    defaultMessage: 'Project UUID',
  },
});

const ProjectOverviewSettingsMap = {
  allow_fork: 'Allows Fork',
  allow_squash_merge: 'Allows Squash Merge',
  allow_merge_commit: 'Allows Merge Commits',
  allow_rebase_merge: 'Allows Rebase Merge',
  allow_auto_merge: 'Allows Auto Merge',
  private: 'Private Repository',
};
export interface ProjectOverviewMetadataProps {
  project?: ProjectResource;
  repository?: Partial<RepositoryResource>;
  scpmData?: MetricResource;
  languagesVisHeight: number;
}

const termTypographyProps: TypographyProps = {
  variant: 'overline',
};

export const ProjectOverviewMetadata = ({
  project,
  repository,
  scpmData,
  languagesVisHeight,
}: ProjectOverviewMetadataProps) => {
  const { space } = useTheme();

  const projectUuid = project?.uuid;
  const repositoryUuid = repository?.uuid;
  const organization = project?.spec.git?.organization;
  const description = project?.meta.description;
  const languages = repository?.spec?.languages;
  const repoAccess =
    scpmData?.spec.metric_values.ScpmDataType.scpm_data?.repository_access;
  const repoRawObject = repoAccess?.ingested_object?.raw;
  const repoPermissions = Object.entries(
    repoRawObject?.['permissions' as keyof typeof repoRawObject] ?? {}
  ).map((p) => ({ label: p[0], value: !!p[1] }));
  const repoSettings: { label: string; value: boolean }[] = Object.entries(
    ProjectOverviewSettingsMap
  ).map((item) => ({
    label: item[1],
    value: !!repoAccess?.[item[0] as keyof typeof repoAccess],
  }));
  const protectedBranches = Object.keys(
    repository?.spec?.branch_protections ?? {}
  ).join(', ');
  return (
    <Stack spacing={space.sm}>
      {languages && (
        <ProjectOverviewLanguageBreakdown
          languages={languages}
          height={languagesVisHeight}
        />
      )}
      {description && (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.description}
          termKey="description"
          termTypographyProps={termTypographyProps}
          value={description}
        />
      )}
      {organization && (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.organization}
          termKey="organization"
          termTypographyProps={termTypographyProps}
          value={organization}
        />
      )}
      {projectUuid && (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.uuid}
          termKey="projectUuid"
          termTypographyProps={termTypographyProps}
          value={projectUuid}
        />
      )}
      {repositoryUuid && (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.repositoryUuid}
          termKey="repositoryUuid"
          termTypographyProps={termTypographyProps}
          value={repositoryUuid}
        />
      )}
      {repoSettings.length > 0 && (
        <Stack gap={space.sm}>
          <Typography {...termTypographyProps}>
            <FM {...ProjectOverviewMetadataLabels.settings} />
          </Typography>
          <ChecklistDisplay list={repoSettings} columns={2} />
        </Stack>
      )}
      {repoPermissions.length > 0 && (
        <Stack gap={space.sm}>
          <Typography {...termTypographyProps}>
            <FM {...ProjectOverviewMetadataLabels.permissions} />
          </Typography>
          <ChecklistDisplay list={repoPermissions} columns={6} />
        </Stack>
      )}
      {protectedBranches ? (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.protectedBranches}
          termKey="protectedBranches"
          termTypographyProps={termTypographyProps}
          value={protectedBranches}
        />
      ) : (
        <DescriptionListItem
          term={ProjectOverviewMetadataLabels.branchProtections}
          termKey="branchProtections"
          termTypographyProps={termTypographyProps}
          value={
            <FM defaultMessage="No branch protections were found. To scan for branch protections you must use an Administrative personal access token during your scan." />
          }
        />
      )}
    </Stack>
  );
};
