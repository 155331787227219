import { isNil as _isNil } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { PackageVersionResolutionErrors as ResolutionErrors } from '@endorlabs/api_client';
import { compareFindingCounts, FindingCount } from '@endorlabs/queries';
import {
  CiWorkflowNameDisplay,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  GITHUB_ACTION_PACKAGE_SUFFIX,
  NilDisplay,
  NumberDisplay,
  PackageVersionNameDisplay,
  ResolutionErrorStatusIndicator,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { PackageVersionsTableViewProps } from './PackageVersionsTableView';

export interface PackageVersionsTableRow {
  namespace: string;
  uuid: string;
  id: string;
  name: string;
  resolutionErrors?: ResolutionErrors;
  relativePath?: string;
  dependenciesCount?: number;
  findingCounts?: FindingCount[];
  link?: string;
}

export const buildPackageVersionsTableColumns = ({
  onClickResolutionErrors,
}: Pick<
  PackageVersionsTableViewProps,
  'onClickResolutionErrors'
>): DataTableColumnDef<PackageVersionsTableRow>[] => {
  return [
    {
      colType: ColTypes.STATUS_INDICATOR,
      accessorKey: 'resolutionErrors',
      cell: ({ getValue, row }) => (
        <ResolutionErrorStatusIndicator
          errors={getValue()}
          onClick={
            onClickResolutionErrors
              ? (event) => {
                  event.stopPropagation();
                  onClickResolutionErrors(row.original);
                }
              : undefined
          }
        />
      ),
      header: '',
      hidden: !onClickResolutionErrors,
    },
    {
      accessorKey: 'name',
      cell: ({ getValue, row }) => {
        const packageName = getValue() as string;
        const { ecosystem } =
          UIPackageVersionUtils.parsePackageName(packageName);
        if (ecosystem === V1Ecosystem.GithubAction) {
          return (
            <CiWorkflowNameDisplay
              name={packageName}
              relativePath={row.original.relativePath}
              suffix={GITHUB_ACTION_PACKAGE_SUFFIX}
              namespace={row.original.namespace}
              size="large"
              url={row.original.link}
              iconFrame
              showIcon
            />
          );
        }
        return (
          <PackageVersionNameDisplay
            packageVersion={{ meta: { name: packageName } }}
            namespace={row.original.namespace}
            url={row.original.link}
            size="large"
            lineBreak="auto"
            iconFrame
            showIcon
            showVersion
          />
        );
      },
      colType: ColTypes.PACKAGE,
      header: () => <FM defaultMessage="Package" />,
      enableSorting: true,
    },
    {
      accessorKey: 'dependenciesCount',
      cell: ({ getValue }) => {
        const count = getValue();
        // handle `undefined` value
        return _isNil(count) ? (
          <NilDisplay variant="text" />
        ) : (
          <NumberDisplay value={count} />
        );
      },
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Dependencies" />,
      enableSorting: true,
    },
    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
      header: () => <FM defaultMessage="Findings" />,
      enableSorting: true,
      sortingFn: (a, b) =>
        compareFindingCounts(
          a.original?.findingCounts,
          b.original?.findingCounts
        ),
    },
  ];
};
