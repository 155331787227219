import { omit as _omit } from 'lodash-es';
import { QueryOptions, useQuery } from 'react-query';

import { V1CountResponse } from '@endorlabs/api_client';
import { AnyResourceType, ResourceKind } from '@endorlabs/endor-core';
import {
  CountRequestParameters,
  GetRequestParameters,
  GroupRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import {
  IQueryError,
  ResourceGroupResponse,
  ResourceListResponse,
  ResourceQueryOptions,
} from '../types';
import { createResourceQueryKeyBuilders, listAllResource } from '../utils';
import { useBuildReadRequestParameters } from './useBuildReadRequestParameters';

export type ReadServiceRequestOptions = { signal?: AbortSignal };

export interface ResourceReadService<T extends AnyResourceType> {
  count(
    namespace: string,
    params: CountRequestParameters,
    options: ReadServiceRequestOptions
  ): Promise<Required<V1CountResponse>>;
  get(
    namespace: string,
    params: GetRequestParameters,
    options: ReadServiceRequestOptions
  ): Promise<T>;
  group(
    namespace: string,
    params: GroupRequestParameters,
    options: ReadServiceRequestOptions
  ): Promise<ResourceGroupResponse>;
  list(
    namespace: string,
    params: Omit<ListRequestParameters, 'group'>,
    options: ReadServiceRequestOptions
  ): Promise<ResourceListResponse<T>>;
}

export const createResourceReadHooks = <T extends AnyResourceType>(
  kind: ResourceKind,
  serviceFactory: () => ResourceReadService<T>
) => {
  const qk = createResourceQueryKeyBuilders(kind);
  const service = serviceFactory();

  // Query Option Factories
  const getQueryOptions = (
    namespace: string,
    getParameters: GetRequestParameters
  ): QueryOptions<T, IQueryError> => {
    return {
      queryKey: qk.record(
        namespace,
        getParameters.uuid,
        _omit(getParameters, ['uuid'])
      ),
      queryFn: (ctx) =>
        service.get(namespace, getParameters, { signal: ctx.signal }),
    };
  };

  // Query Hooks
  const useCount = (
    namespace: string,
    countParameters: CountRequestParameters,
    options?: ResourceQueryOptions<Required<V1CountResponse>>
  ) => {
    const requestParameters = useBuildReadRequestParameters(
      kind,
      'COUNT',
      countParameters,
      options
    );

    return useQuery({
      queryKey: qk.count(namespace, requestParameters),
      queryFn: (ctx) =>
        service.count(namespace, requestParameters, { signal: ctx.signal }),
      ...options,
    });
  };

  const useGet = (
    namespace: string,
    getParameters: GetRequestParameters,
    options?: ResourceQueryOptions<T>
  ) => {
    // TODO: validate get request parameters
    const { queryFn, queryKey } = getQueryOptions(namespace, getParameters);

    return useQuery({
      queryFn,
      queryKey,
      ...options,
    });
  };

  const useGroup = (
    namespace: string,
    groupParameters: GroupRequestParameters,
    options?: ResourceQueryOptions<ResourceGroupResponse>
  ) => {
    const requestParameters = useBuildReadRequestParameters(
      kind,
      'GROUP',
      groupParameters,
      options
    );

    return useQuery({
      queryKey: qk.list(namespace, requestParameters),
      queryFn: (ctx) =>
        service.group(namespace, requestParameters as GroupRequestParameters, {
          signal: ctx.signal,
        }),
      ...options,
    });
  };

  const useList = (
    namespace: string,
    listParameters: ListRequestParameters,
    options?: ResourceQueryOptions<ResourceListResponse<T>>
  ) => {
    const requestParameters = useBuildReadRequestParameters(
      kind,
      'LIST',
      listParameters,
      options
    );

    return useQuery({
      queryKey: qk.list(namespace, requestParameters),
      queryFn: (ctx) =>
        service.list(namespace, requestParameters, { signal: ctx.signal }),
      ...options,
    });
  };

  const useListAll = (
    namespace: string,
    listParameters: ListAllRequestParameters,
    options?: ResourceQueryOptions<T[]>
  ) => {
    const requestParameters = useBuildReadRequestParameters(
      kind,
      'LIST_ALL',
      listParameters,
      options
    );

    return useQuery({
      queryKey: qk.list(namespace, requestParameters),
      queryFn: (ctx) =>
        listAllResource(
          (pageToken) => {
            const pageRequestParameters = {
              ...requestParameters,
              page_token: pageToken,
            };
            return service
              .list(namespace, pageRequestParameters, {
                signal: ctx.signal,
              })
              .then((list) => ({ list }));
          },
          { signal: ctx.signal }
        ),
      ...options,
    });
  };

  return {
    getQueryOptions,
    useCount,
    useGet,
    useGroup,
    useList,
    useListAll,
  };
};
