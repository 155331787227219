import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { useFilterSearchParams } from '@endorlabs/filters';
import {
  ProjectResource,
  RepoVersionResource,
  useFeatureFlags,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  Link,
  SearchBar,
} from '@endorlabs/ui-common';

import { CIRunsTable, CIRunsTableRow } from '../../components';
import { ResourceFilterContextProvider } from '../../domains/filters';
import { getProjectDetailPath } from '../../routes';
import { NamedRoutes } from '../../routes/constants';
import { PR_RUNS_SEARCH_KEYS } from './constants';
import { ProjectDetailPRRunsV2 } from './ProjectDetailPRRunsV2';
import { useCIRunsData } from './useCIRunsData';

export interface ProjectDetailCIRunsProps {
  namespace: string;
  project?: ProjectResource;
  repositoryVersion?: RepoVersionResource;
}

export const ProjectDetailCIRuns = ({
  namespace,
  project,
  repositoryVersion,
}: ProjectDetailCIRunsProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();

  const { ENABLE_IA_1_PAGE_IMPROVEMENTS: isIAPageImprovements } =
    useFeatureFlags();
  const [alertDismissed, setAlertDismissed] = useState(false);

  const {
    filterDefaultSearchParams: searchValue,
    updateFilterDefaultSearchParams: handleSearchChange,
  } = useFilterSearchParams();

  const { data, isEmptyState, isLoading, isLoadingRelated, paginator } =
    useCIRunsData({
      namespace,
      project,
      searchValue,
    });

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  const handleRowClick = (row: CIRunsTableRow) => {
    const base = getProjectDetailPath({
      tenantName: namespace,
      uuid: project?.uuid,
      section: 'pr-runs',
    });
    const link = [base, row.ciRunId].join('/');
    navigate({ to: link });
  };

  if (isIAPageImprovements)
    return (
      <ResourceFilterContextProvider
        namespace={namespace}
        resourceKind="CIRun"
        resourceSearchKeys={PR_RUNS_SEARCH_KEYS}
      >
        <ProjectDetailPRRunsV2 namespace={namespace} project={project} />
      </ResourceFilterContextProvider>
    );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      {!isEmptyState && (
        <Grid item>
          <SearchBar
            onSearch={handleSearchChange}
            placeholder={fm({
              defaultMessage:
                'Search for PR runs by branch, tag, or commit SHA',
            })}
            searchValue={searchValue}
          />
        </Grid>
      )}

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="There are no PR runs in this this project" />
            }
            description={
              <FM defaultMessage="As new PR runs are created, they will appear here." />
            }
          />
        </Grid>
      )}
      {!isEmptyState && (
        <Grid item>
          <Card>
            {!alertDismissed && (
              <CardHeader
                title={
                  <Alert
                    severity="warning"
                    color="primary"
                    variant="outlined"
                    onClose={() => setAlertDismissed(true)}
                  >
                    <AlertTitle>
                      <FM
                        // TODO: when Project Overview is re-added, update this text to direct user to the Versions tab.
                        // defaultMessage="These scans are from running <code>`endorctl scan --pr`</code>. To see the results of scans without the <code>--pr</code> flag, go to the Versions tab."
                        defaultMessage="These scans are from running <code>`endorctl scan --pr`</code>."
                        values={{
                          code: (value) => (
                            <Typography
                              variant="code"
                              fontSize="inherit"
                              lineHeight="inherit"
                            >
                              {value}
                            </Typography>
                          ),
                        }}
                      />
                    </AlertTitle>

                    {NamedRoutes.DOCS_ENDORCTL && (
                      <FM
                        defaultMessage="To learn more about using endorctl, please visit <link>our documentation</link>."
                        values={{
                          link: (value) => (
                            <Link
                              target="_blank"
                              to={NamedRoutes.DOCS_ENDORCTL}
                            >
                              {value}
                            </Link>
                          ),
                        }}
                      />
                    )}
                  </Alert>
                }
              />
            )}

            <CardContent>
              <CIRunsTable
                data={data}
                enablePagination
                isLoading={isLoading}
                isLoadingRelated={isLoadingRelated}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No PR Runs match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={handleClearSearch}>
                      <FM defaultMessage="Clear Search" />
                    </ButtonCancel>
                  ),
                }}
                paginator={paginator}
                onRowClick={handleRowClick}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
