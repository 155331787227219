import { Alert, Box, Skeleton, Stack } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { ProjectMinimal } from '@endorlabs/endor-core/Project';

import { IconInfo } from '../../themes';
import { DrawerAccordion } from '../DrawerAccordion';
import { EmptyState } from '../EmptyState';
import { ProjectNameDisplayV2 } from '../ProjectNameDisplay/ProjectNameDisplayV2';
import arrowDown from './arrow-down.svg';
import arrowUp from './arrow-up.svg';
import { MAX_PATHS_LIMIT } from './constants';
import { DependencyPathDisplayHeader } from './DependencyPathDisplayHeader';
import { DependencyPathDisplayItem } from './DependencyPathDisplayItem';
import {
  AdjacencyList,
  useDependencyGraphSearch,
} from './useDependencyGraphSearch';

export interface DependencyPathDisplayProps {
  dependencyGraph: AdjacencyList;
  displayAsAccordion?: boolean;
  expanded?: boolean;
  isLoading?: boolean;
  isPartial?: boolean;
  project?: ProjectMinimal;
  reverse?: boolean;
  sourcePackageNames?: string[];
  targetPackageName?: string;
}

export const DependencyPathDisplayMessages = defineMessages({
  emptyMessage: { defaultMessage: 'Dependency path could not be shown' },
});

export const DependencyPathDisplay = ({
  dependencyGraph,
  displayAsAccordion = true,
  expanded = true,
  isLoading = false,
  isPartial = false,
  project,
  reverse = false,
  sourcePackageNames = [],
  targetPackageName,
}: DependencyPathDisplayProps) => {
  const sx = styles(reverse);

  const [displayedPath, setDisplayedPath] = useState<number>(0);

  const listContainer = useRef(null);

  const dependencyPaths = useDependencyGraphSearch(
    dependencyGraph,
    sourcePackageNames,
    targetPackageName,
    reverse
  );

  // reset the displayed path index on change
  useEffect(() => {
    setDisplayedPath(0);
  }, [dependencyPaths]);

  const updateDisplayedPath = useCallback(
    (pathNum: number) =>
      pathNum >= 0 &&
      pathNum < dependencyPaths.length &&
      setDisplayedPath(pathNum),
    [dependencyPaths]
  );

  const displayedDependencies = dependencyPaths[displayedPath];
  const isEmpty =
    !isLoading &&
    (!displayedDependencies || displayedDependencies.length === 0);
  const isLimitReached = dependencyPaths.length >= MAX_PATHS_LIMIT;

  const showControls = !isLoading && !isEmpty;
  const showSampleWarning =
    !isEmpty && !isLoading && (isPartial || isLimitReached);

  return (
    <DrawerAccordion
      expanded={expanded}
      id="dependency_path"
      isCollapsable={displayAsAccordion}
      titleNode={
        <DependencyPathDisplayHeader
          count={dependencyPaths.length}
          current={displayedPath}
          showControls={showControls}
          updateCurrent={updateDisplayedPath}
        />
      }
    >
      <Stack ref={listContainer.current}>
        {isLoading && (
          <Stack gap={4}>
            {Array(4)
              .fill(undefined)
              .map((_, i) => {
                return (
                  <Stack direction="row" gap={3} key={i}>
                    <Skeleton height={24} width={16} />
                    <Skeleton height={24} width="80%" />
                  </Stack>
                );
              })}
          </Stack>
        )}

        {isEmpty && (
          <EmptyState
            size="small"
            title={<FM defaultMessage="Dependency path could not be shown" />}
          />
        )}

        {showSampleWarning && (
          <Alert
            severity="warning"
            icon={<IconInfo />}
            sx={{
              marginBottom: 4,
              '& .wrap': {
                wordBreak: 'break-all',
              },
            }}
          >
            <FM defaultMessage="Sample of the paths to the dependency are shown below. Additional paths to the dependency may exist." />
          </Alert>
        )}

        {!isEmpty && !isLoading && (
          <Stack component="ol" direction="column" gap={0} sx={sx.root}>
            {project && (
              <Box
                component="li"
                className="DependencyPathDisplay-item"
                key={project.uuid}
              >
                <ProjectNameDisplayV2 project={project} />
              </Box>
            )}

            {displayedDependencies.map((dep, ix) => {
              return (
                <Box
                  className="DependencyPathDisplay-item"
                  component="li"
                  key={`${dep}_${ix}`}
                >
                  <DependencyPathDisplayItem
                    bold={ix === 0 || ix === displayedDependencies.length - 1}
                    name={dep}
                    sx={{ wordBreak: 'break-all' }}
                  />
                </Box>
              );
            })}
          </Stack>
        )}
      </Stack>
    </DrawerAccordion>
  );
};

function styles(reverse: boolean) {
  return {
    root: {
      margin: 0,
      padding: 0,

      '& .DependencyPathDisplay-item': {
        backgroundImage: `url(${reverse ? arrowUp : arrowDown})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: reverse ? '6px 90%' : '6px 75%',
        backgroundSize: '6px 16px',
        listStyle: 'none',
        padding: '0 0 20px',

        '&:last-of-type': {
          backgroundImage: 'none',
          paddingBottom: 0,
        },
      },
    },
  };
}
