import { Box, Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { OnboardingPopover, OnboardingPopoverProps } from './OnboardingPopover';

const list = [
  <FM defaultMessage="Is there a patch available?" key={1} />,
  <FM defaultMessage="Is the vulnerable function reachable?" key={2} />,
  <FM defaultMessage="Is it a test dependency?" key={3} />,
];

export const OnboardingPopoverFindings = ({
  willShow,
}: Omit<OnboardingPopoverProps, 'selector' | 'open'>) => {
  const { space } = useTheme();

  return (
    <OnboardingPopover
      heading={<FM defaultMessage="Prioritize Findings" />}
      open={false}
      placement="top-start"
      popperOptions={{
        modifiers: [{ name: 'offset', options: { offset: [-160, -33] } }],
      }}
      selector="[data-testid='FilterBarSavedFilters']"
      willShow={willShow}
    >
      <Stack>
        <Box marginBottom={space.xs}>
          <FM defaultMessage="Use attributes to focus on just the findings that matter. Try starting with:" />
        </Box>

        <ul style={{ margin: 0, padding: 0, listStyle: 'inside' }}>
          {list.map((liText, i) => {
            return <li key={i}>{liText}</li>;
          })}
        </ul>
      </Stack>
    </OnboardingPopover>
  );
};
