import {
  Badge,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import _noop from 'lodash-es/noop';
import { ReactNode } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { BetaBadge, Link } from '@endorlabs/ui-common';

import { Layout } from '../../constants';
import { AppDrawerNavItemProps } from './types';

export const AppDrawerNavItem = ({
  appDrawerMinimized,
  badgeContent,
  Icon,
  isActive = false,
  isBeta = false,
  label,
  navKey,
  url,
}: AppDrawerNavItemProps) => {
  const { typography } = useTheme();
  const sx = styles(useTheme());

  const icon = <Icon fontSize="medium" />;

  const cls = clsx({
    isActive: isActive,
  });

  const getLabelDisplay = (
    label: ReactNode,
    isBeta: boolean,
    badgeContent: ReactNode
  ) => {
    if (isBeta) {
      return <BetaBadge>{label}</BetaBadge>;
    }

    if (badgeContent) {
      return (
        <Badge
          badgeContent={
            <Typography variant="overline">{badgeContent}</Typography>
          }
          color="error"
          sx={({ spacing }) => ({
            '& .MuiBadge-badge': {
              fontSize: typography.pxToRem(10),
              right: spacing(-4),
              top: spacing(0.5),
              textTransform: 'uppercase',
            },
          })}
        >
          {label}
        </Badge>
      );
    }

    return label;
  };

  return (
    <Tooltip title={appDrawerMinimized ? label : ''} placement="right" arrow>
      <ListItemButton
        component={Link}
        data-testid={`AppDrawerNavItem-${navKey}`}
        onClick={_noop}
        className={cls}
        sx={sx.button}
        to={url}
      >
        <ListItemAvatar sx={sx.avatar}>{icon}</ListItemAvatar>
        {!appDrawerMinimized && (
          <ListItemText
            primary={getLabelDisplay(label, isBeta, badgeContent)}
          />
        )}
      </ListItemButton>
    </Tooltip>
  );
};

const styles = ({ breakpoints, palette }: Theme) => {
  return {
    button: {
      paddingX: 6,
      paddingY: 1.5,

      '&:hover': {
        backgroundColor: palette.background.dark,
      },
      '&.isActive': {
        backgroundColor: palette.background.dark,

        // Add pointer to right side
        '&::after': {
          content: '""',
          borderRadius: '1px',
          borderStyle: 'solid',
          borderWidth: '9px',
          borderColor: `transparent ${palette.background.default} transparent transparent`,
          position: 'absolute',
          right: '-1px',
        },
      },
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        paddingLeft: 6,
        paddingRight: 2,
      },
    },
    avatar: {
      color: palette.grey[200],
      minWidth: '36px',
    },
  };
};
