import {
  IdentityProviderOIDCProvider,
  V1IdentityProvider,
  V1SAMLProvider,
} from '@endorlabs/api_client';
export const IdentityProviderTypes = {
  OIDC: 'OIDC',
  SAML: 'SAML',
} as const;

export type IdentityProviderType =
  typeof IdentityProviderTypes[keyof typeof IdentityProviderTypes];

export const IdentityProviderTypeFields = {
  [IdentityProviderTypes.OIDC]: 'oidc_provider',
  [IdentityProviderTypes.SAML]: 'saml_provider',
} as const;

export type IdentityProviderTypeField =
  typeof IdentityProviderTypeFields[keyof typeof IdentityProviderTypeFields];

export interface IdentityProviderFieldValues
  extends Omit<V1IdentityProvider, 'spec'> {
  saml_metadata_method?: SamlMetadataMethod;
  spec: {
    oidc_provider?: IdentityProviderOIDCProvider;
    saml_provider?: V1SAMLProvider;
  };
}

export const SamlMetadataMethods = {
  MANUAL: 'MANUAL',
  URL: 'URL',
} as const;
export type SamlMetadataMethod =
  typeof SamlMetadataMethods[keyof typeof SamlMetadataMethods];
