import {
  Box,
  Skeleton,
  Stack,
  TypeText,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

import { NumberDisplay, RowStack } from '@endorlabs/ui-common';

export const DashboardStat = ({
  accentColor,
  action,
  isLoading,
  onClick,
  title,
  unit,
  value,
  valueColor,
}: {
  accentColor?: string;
  action?: ReactNode;
  isLoading?: boolean;
  onClick?: () => void;
  title: ReactNode;
  unit?: ReactNode;
  value?: number;
  valueColor?: keyof TypeText;
}) => {
  const { space, palette } = useTheme();

  return (
    <Stack
      direction="column"
      spacing={space.xs}
      className="DashboardStat-root"
      sx={{
        flexGrow: 1,
        ...(accentColor && {
          paddingLeft: 2,
          borderLeft: `2px solid ${accentColor}`,
        }),
      }}
    >
      <RowStack>
        <Typography variant="h6" textAlign="left" color="text.secondary">
          {title}
        </Typography>

        {action}
      </RowStack>

      <RowStack alignItems="baseline" onClick={onClick}>
        {isLoading ? (
          <Skeleton variant="text" width={80} sx={{ fontSize: '1.5rem' }} />
        ) : (
          <Box
            onClick={onClick}
            sx={{ cursor: onClick ? 'pointer' : 'default' }}
          >
            <NumberDisplay
              value={value}
              typographyProps={{
                fontSize: '1.5rem',
                color: palette.text[valueColor || 'primary'],
                variant: 'xl',
              }}
            />
          </Box>
        )}

        {unit && <Typography sx={{ fontSize: '1.25rem' }}>{unit}</Typography>}
      </RowStack>
    </Stack>
  );
};
