import {
  Box,
  Theme,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export interface ExclusiveToggleButtonGroupProps
  extends ToggleButtonGroupProps {
  children: ReactNode;
}

export const ExclusiveToggleButtonGroup = ({
  children,
  ...props
}: ExclusiveToggleButtonGroupProps) => {
  const sx = styles(useTheme());
  return (
    <Box>
      <ToggleButtonGroup {...props} exclusive={true} sx={sx.root}>
        {children}
      </ToggleButtonGroup>
    </Box>
  );
};

function styles({ spacing, palette }: Theme) {
  return {
    root: {
      '& .MuiToggleButton-root': {
        border: 'none',
        backgroundColor: palette.grey[200],
        borderRadius: 999,
        color: palette.text.primary,
        gap: spacing(1),
        padding: spacing(1.5, 4),

        '&.Mui-disabled': {
          border: 'none',
          color: palette.text.disabled,
        },
        '&.Mui-selected': {
          color: palette.getContrastText(palette.primary.main),
          backgroundColor: palette.primary.main,
        },
        '&:not(.Mui-disabled,.Mui-selected) .MuiTypography-root': {
          color: palette.text.secondary,
        },
      },

      '& .MuiToggleButtonGroup-grouped': {
        '&:not(:first-of-type)': {
          // override default mui border from connected buttons
          borderColor: 'transparent',
          borderRadius: 999,
        },
        '&:not(:last-of-type)': {
          // override default mui border from connected buttons
          borderColor: 'transparent',
          borderRadius: 999,
          marginRight: spacing(2),
          marginLeft: 0,
        },
      },
    },
  };
}
