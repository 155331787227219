import { minutesToMilliseconds } from 'date-fns';
import { QueryKey, useQuery } from 'react-query';

import {
  QueryServiceApi,
  V1ListParameters,
  V1Query,
} from '@endorlabs/api_client';

import { ResourceCountResponse, ResourceQueryOptions } from './types';
import { getClientConfiguration } from './utils';

export type QueryFindingsCountResponse = ResourceCountResponse;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'findings-count', namespace, listParams] as const,
};
export const QueryFindingsCountQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const queryFindingsCount = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryFindingsCountResponse;
};

/**
 * Custom query for Findings count
 */
export const useQueryFindingsCount = (
  namespace: string,
  listParams: V1ListParameters = {},
  queryOpts: ResourceQueryOptions<QueryFindingsCountResponse> = {}
) => {
  return useQuery(
    QK.query(namespace, listParams),
    ({ signal }) => queryFindingsCount(namespace, listParams, signal),
    { staleTime: QUERY_STALE_TIME, ...queryOpts }
  );
};

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryFindingsCount(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Finding',
        list_parameters: {
          ...rootListParams,
          count: true,
        },
      },
    },
    tenant_meta: { namespace },
  };
};
