import { useCallback, useState } from 'react';

import {
  RemediationsExportDialog,
  RemediationsExportDialogProps,
} from '../components';

type DialogState = Omit<RemediationsExportDialogProps, 'onClose' | 'open'>;

/**
 * Utility hook to manage Remediations Export Dialog
 */
export function useRemediationsExportDialog() {
  const [dialogState, setDialogState] = useState<DialogState | undefined>(
    undefined
  );
  const isOpen = Boolean(dialogState);

  const handleOpenDialog = useCallback((state: DialogState) => {
    setDialogState(state);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogState(undefined);
  }, []);

  const getDialogProps = useCallback(
    () =>
      ({
        ...dialogState,
        open: isOpen,
        onClose: handleCloseDialog,
      } as RemediationsExportDialogProps),
    [dialogState, handleCloseDialog, isOpen]
  );

  return {
    Dialog: RemediationsExportDialog,
    openDialog: handleOpenDialog,
    getDialogProps,
  };
}
