import { buildCallPathFromFunctionPath } from './buildCallPathFromFunctionPath';
import { buildFunctionPathFromReachablePath } from './buildFunctionPathFromReachablePath';
import { getFindingFilterExpression } from './getFindingFilterExpression';
import { getSeverityColor } from './getSeverityColor';
import { isFindingLevelMatch } from './isFindingLevelMatch';
import { isSelfFinding } from './isSelfFinding';
import { sortByFindingSeverity } from './sortByFindingSeverity';

export const UIFindingUtils = {
  buildCallPathFromFunctionPath,
  buildFunctionPathFromReachablePath,
  isFindingLevelMatch,
  isSelfFinding,
  getFindingFilterExpression,
  getSeverityColor,
  sortByFindingSeverity,
};
