import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  RepositoryVersionServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  RepoVersionResource,
  RepoVersionResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  getClientConfiguration,
} from './utils';

interface RepoVersionReadParams {
  namespace: string;
  uuid: string;
}

export type CountRepositoryVersionsOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type ListRepoVersionOptions = ResourceQueryOptions<RepoVersionResourceList>;
type GetRepoVersionOptions = ResourceQueryOptions<RepoVersionResource>;
type DeleteRepoVersionOptions = ResourceMutateOptions<
  object,
  RepoVersionReadParams
>;

const BASE_KEY = 'v1/repository-versions';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const RepositoryVersionsQueryKeys = QK;

export const REPOSITORY_VERSIONS_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new RepositoryVersionServiceApi(getClientConfiguration());

const countRepositoryVersions = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.repositoryVersionServiceListRepositoryVersions(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountRepositoryVersions = (
  namespace: string,
  opts: CountRepositoryVersionsOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'RepositoryVersion',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countRepositoryVersions(namespace, requestParameters),
    opts
  );
};

const listRepositoryVersions = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.repositoryVersionServiceListRepositoryVersions(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as RepoVersionResourceList;
};

export const useListRepositoryVersions = (
  namespace: string,
  opts: ListRepoVersionOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'RepositoryVersion',
    'COUNT',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listRepositoryVersions(namespace, requestParameters),
    opts
  );
};

export const getRepositoryVersion = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.repositoryVersionServiceGetRepositoryVersion(
    namespace,
    uuid
  );
  return resp.data as RepoVersionResource;
};

export const useGetRepositoryVersion = (
  namespace: string,
  uuid: string,
  opts: GetRepoVersionOptions = {}
) => {
  return useQuery(
    QK.record(namespace, uuid),
    () => getRepositoryVersion(namespace, uuid),
    opts
  );
};

const deleteRepositoryVersion = async (params: RepoVersionReadParams) => {
  const api = getApiService();
  const resp = await api.repositoryVersionServiceDeleteRepositoryVersion(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteRepositoryVersion = (
  opts: DeleteRepoVersionOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'RepositoryVersion'),
    mutationFn: (params: RepoVersionReadParams) =>
      deleteRepositoryVersion(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
