import { useMemo } from 'react';

import { ProjectResource, RepoVersionResource } from '@endorlabs/queries';

import { useDependenciesFromDependencyMetadata } from '../../Dependencies';
import { UIProjectUtils } from '../utils';

/**
 * Wrapper for {@see usePackageVersionDependencies}
 *
 * Allows building a list of all dependencies for all Package Versions from a
 * given Repository Version.
 */
export const useProjectDependencies = (
  namespace: string,
  project?: ProjectResource,
  repositoryVersion?: RepoVersionResource
) => {
  const packagesFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      repositoryVersion,
      { key: 'spec.importer_data.project_uuid' }
    );

  const qDependencies = useDependenciesFromDependencyMetadata(
    namespace,
    packagesFilterExpression as string,
    { enabled: !!packagesFilterExpression }
  );

  const dependencies = useMemo(() => {
    // apply a default sort to the dependencies list, placing direct dependencies first
    const dependencies = Object.values(qDependencies.dependenciesMap).sort(
      (a, b) =>
        a.isDirectDependency === b.isDirectDependency
          ? a.name.localeCompare(b.name)
          : a.isDirectDependency
          ? -1
          : 1
    );

    return dependencies;
  }, [qDependencies.dependenciesMap]);

  return {
    dependencies,
    isLoading: qDependencies.isLoading,
    isSuccess: qDependencies.isSuccess,
  };
};
