import { Card, CardContent, Stack } from '@mui/material';
import { isEqual as _isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';

import { useUserPreferences } from '@endorlabs/queries';

import {
  useFilterContext,
  useFilterSavedFiltersContext,
  useFilterValidationContext,
} from '../providers';
import { FilterBarMode, FilterFieldConfig, SavedFilter } from '../types';
import { DataTableViewFilters } from './DataTableViewFilters';
import { FilterBarAdvanced } from './FilterBarAdvanced';
import { FilterBarBasic, FilterBarBasicProps } from './FilterBarBasic';
import { FilterBarHeader, FilterBarHeaderProps } from './FilterBarHeader';

export interface FilterBarProps {
  enableAdvanced?: boolean;
  enableSavedFilters?: boolean;
  enableSearch?: boolean; // Used to disable search in FilterBarBasic
  fields: FilterFieldConfig[];
  isCard?: boolean;
  isDataTableView?: boolean;
  searchPlaceholder?: string;
}

/**
 * Component intended for managing a Filter for a single Resource
 *
 * @note this component requires a {@see FilterContext} to be present.
 */
export const FilterBar = ({
  enableAdvanced = false,
  enableSavedFilters = false,
  enableSearch = true,
  fields,
  isCard = true,
  isDataTableView = false,
  searchPlaceholder,
}: FilterBarProps) => {
  const userPreferences = useUserPreferences();

  const {
    filter,
    isParseable,
    updateFilter,
    clearFilter,
    _state: filterState,
  } = useFilterContext();
  const { savedFilters, createSavedFilter } = useFilterSavedFiltersContext();
  const { error: filterValidationError } = useFilterValidationContext();

  // get default filter mode from user preferences
  const [mode, setMode] = useState<FilterBarMode>(() => {
    if (!enableAdvanced) {
      return FilterBarMode.Basic;
    }

    const preferredMode = userPreferences.filters?.mode;

    if (
      preferredMode &&
      Object.values(FilterBarMode).some((mode) => mode === preferredMode)
    ) {
      return preferredMode as FilterBarMode;
    }

    return FilterBarMode.Basic;
  });

  useEffect(
    () => {
      // if the filter is non-parseable, force advanced mode
      if (mode === FilterBarMode.Basic && !isParseable) {
        setMode(FilterBarMode.Advanced);
      }

      userPreferences.setFilterPreferences({ mode });
    },
    // ignore the `userPreferences` in the list of dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isParseable, mode]
  );

  const handleCreateSavedFilter = (newSavedFilter: Omit<SavedFilter, 'id'>) => {
    // NOTE: app notification for SavedQuery is shown on success/error
    createSavedFilter(newSavedFilter);
  };

  const onSavedFilterSelect: FilterBarHeaderProps['onSavedFilterSelect'] = (
    value
  ) => updateFilter(value.filter);

  const onFilterChange: FilterBarBasicProps['onFilterChange'] = (values) => {
    if (!_isEqual(filterState.values, values)) {
      updateFilter({ values });
    }
  };

  const onSearchChange: FilterBarBasicProps['onSearchChange'] = (
    searchValue
  ) => {
    if (filterState.search !== searchValue) {
      updateFilter({ search: searchValue });
    }
  };

  // Render new layout format
  if (isDataTableView) {
    return (
      <DataTableViewFilters
        enableAdvanced={enableAdvanced}
        enableSavedFilters={enableSavedFilters}
        fields={fields}
        mode={mode}
        onClear={clearFilter}
        onCreateSavedFilter={handleCreateSavedFilter}
        onFilterChange={onFilterChange}
        onModeChange={setMode}
        onSavedFilterSelect={onSavedFilterSelect}
        onSearchChange={onSearchChange}
        searchPlaceholder={searchPlaceholder}
        savedFilters={savedFilters}
      />
    );
  }

  const content = (
    <Stack className="FilterBar-root">
      {(enableAdvanced || enableSavedFilters) && (
        <FilterBarHeader
          enableAdvanced={enableAdvanced}
          enableSavedFilters={enableSavedFilters}
          filter={filter}
          isParseable={isParseable}
          mode={mode}
          onClear={clearFilter}
          onCreateSavedFilter={handleCreateSavedFilter}
          onModeChange={setMode}
          onSavedFilterSelect={onSavedFilterSelect}
          savedFilters={savedFilters}
        />
      )}

      {/* BASIC MODE */}
      {mode === FilterBarMode.Basic && (
        <FilterBarBasic
          enableSearch={enableSearch}
          fields={fields}
          filterValues={filterState.values}
          onClear={clearFilter}
          onFilterChange={onFilterChange}
          onSearchChange={onSearchChange}
          searchPlaceholder={searchPlaceholder}
          searchValue={filterState.search}
        />
      )}

      {/* ADVANCED MODE */}
      {mode === FilterBarMode.Advanced && (
        <FilterBarAdvanced
          filter={filter}
          filterValidationError={filterValidationError}
          onClear={clearFilter}
          onExpressionChange={(value) => updateFilter(value)}
          searchPlaceholder={searchPlaceholder}
        />
      )}
    </Stack>
  );

  {
    return isCard ? (
      <Card>
        <CardContent>{content}</CardContent>
      </Card>
    ) : (
      content
    );
  }
};
