import { uniqBy as _uniqBy } from 'lodash-es';

import { V1AutomatedScanParameters, V1Language } from '@endorlabs/api_client';
import {
  isLanguageEnum,
  LanguageString,
  SUPPORTED_CALL_GRAPH_LANGUAGES,
  SUPPORTED_SCAN_LANGUAGES,
  toLanguageEnum,
  toLanguageString,
} from '@endorlabs/endor-core/Language';
import { LanguageLabel } from '@endorlabs/ui-common';

const mapToLanguageOption = (value: V1Language | LanguageString) => {
  if (isLanguageEnum(value)) {
    return {
      label: <LanguageLabel value={value} />,
      value: toLanguageString(value),
    };
  }

  const enumValue = toLanguageEnum(value);
  if (enumValue === V1Language.Unspecified) {
    return { label: value, value };
  }

  return { label: <LanguageLabel value={enumValue} />, value };
};

export const getLanguageOptions = (
  automatedScanParameters?: V1AutomatedScanParameters
) => {
  const { call_graph_languages = [], languages = [] } =
    automatedScanParameters ?? {};

  const callGraphLanguages = _uniqBy(
    [
      ...call_graph_languages.map(mapToLanguageOption),
      ...SUPPORTED_CALL_GRAPH_LANGUAGES.map(mapToLanguageOption),
    ],
    (v) => v.value
  ).sort((a, b) => a.value.localeCompare(b.value));

  const scanLanguages = _uniqBy(
    [
      ...languages.map(mapToLanguageOption),
      ...SUPPORTED_SCAN_LANGUAGES.map(mapToLanguageOption),
    ],
    (v) => v.value
  ).sort((a, b) => a.value.localeCompare(b.value));

  return { callGraphLanguages, scanLanguages };
};
