import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  RepositoryResource,
  RepoVersionResource,
  sortParamBuilders,
  useGetRepository,
  useGetRepositoryVersion,
  useListProvisioningResults,
} from '@endorlabs/queries';
import {
  FileDownloadFileType,
  useProjectLatestScanResults,
} from '@endorlabs/ui-common';

import { ResourceRawDataBlock } from '../ResourceRawDataBlock';

interface ProjectMetadataViewProps {
  project?: ProjectResource;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}

export const ProjectMetadataView = ({
  project,
  repository,
  repositoryVersion,
}: ProjectMetadataViewProps) => {
  // setting toggle button state to JSON or YAML
  const [format, setFormat] = useState<FileDownloadFileType>('json');
  const handleChangeFormat = useCallback(
    (_: React.MouseEvent<HTMLElement>, newFormat?: FileDownloadFileType) => {
      // catch empty event payload
      if (newFormat) {
        setFormat(newFormat);
      }
    },
    []
  );

  // fetch related scan result data
  const qScanResults = useProjectLatestScanResults({
    namespace: project?.tenant_meta.namespace ?? '',
    projectUuid: project?.uuid,
    scanContext: repositoryVersion?.context,
  });
  const scanResult = qScanResults.data?.objects[0];

  const qListProvisioningResult = useListProvisioningResults(
    project?.tenant_meta.namespace ?? '',
    {
      filter: `spec.project_url=="${project?.spec.internal_reference_key}"`,
      page_size: 1,
      sort: sortParamBuilders.descendingBy('meta.create_time'),
    },
    {
      enabled:
        !!project?.tenant_meta.namespace &&
        !!project?.spec.internal_reference_key,
    }
  );
  const provisioningResult = qListProvisioningResult.data?.objects.at(0);

  // fetch full repository data
  const hasRepository = !!repository;
  const qRepository = useGetRepository(
    repository?.tenant_meta.namespace as string,
    repository?.uuid as string,
    {
      enabled: hasRepository,
      placeholderData: repository,
      staleTime: Infinity,
    }
  );
  const isRepositoryLoading =
    qRepository.isLoading || qRepository.isPlaceholderData;

  // fetch full repository version data
  const hasRepositoryVersion = !!repositoryVersion;
  const qRepositoryVersion = useGetRepositoryVersion(
    repositoryVersion?.tenant_meta.namespace as string,
    repositoryVersion?.uuid as string,
    {
      enabled: hasRepositoryVersion,
      placeholderData: repositoryVersion,
      staleTime: Infinity,
    }
  );

  const isRepositoryVersionLoading =
    qRepositoryVersion.isLoading || qRepositoryVersion.isPlaceholderData;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <ToggleButtonGroup
          color="primary"
          value={format}
          exclusive
          onChange={handleChangeFormat}
        >
          <ToggleButton value="json">
            <FM defaultMessage="JSON" />
          </ToggleButton>
          <ToggleButton value="yaml">
            <FM defaultMessage="YAML" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item>
        <ResourceRawDataBlock
          filetype={format}
          resource={project}
          kind="Project"
        />
      </Grid>

      <Grid item>
        <ResourceRawDataBlock
          fallback={<FM defaultMessage="No recent scans found" />}
          filetype={format}
          isLoading={qScanResults.isLoading}
          kind="ScanResult"
          resource={scanResult}
        />
      </Grid>

      <Grid item>
        <ResourceRawDataBlock
          fallback={<FM defaultMessage="No recent scans found" />}
          filetype={format}
          isLoading={qListProvisioningResult.isLoading}
          kind="ProvisioningResult"
          resource={provisioningResult}
        />
      </Grid>

      {hasRepository && (
        <Grid item>
          <ResourceRawDataBlock
            filetype={format}
            isLoading={isRepositoryLoading}
            kind="Repository"
            resource={qRepository.data}
          />
        </Grid>
      )}

      {hasRepositoryVersion && (
        <Grid item>
          <ResourceRawDataBlock
            filetype={format}
            isLoading={isRepositoryVersionLoading}
            kind="RepositoryVersion"
            resource={qRepositoryVersion.data}
          />
        </Grid>
      )}
    </Grid>
  );
};
