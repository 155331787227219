import { Components, Theme } from '@mui/material';

type IconButtonComponentOverrides = Pick<Components<Theme>, 'MuiIconButton'>;

export const getIconButtonOverrides = (
  theme: Theme
): IconButtonComponentOverrides => {
  const { palette, spacing, typography } = theme;

  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: spacing(0.5),

          '&:hover': { color: palette.primary.main },
        },
        // @ts-expect-error this override is not know in the typing here
        sizeExtraSmall: {
          fontSize: typography.pxToRem(16),
        },
      },
    },
  };
};
