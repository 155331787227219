import { CircularProgress, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  tryParseGroupResponseAggregationKey,
  useListFindings,
} from '@endorlabs/queries';
import {
  EmptyState,
  TitleActionHeader,
  Treemap,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { FindingLevelToggles } from '../../components';
import { colorListGray } from '../../domains/Dashboard';
import { useAuthInfo } from '../../providers';
import { getDependencyPath } from '../../routes';

export const DependencyFindingsVis = ({ height }: { height: number }) => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const navigate = useNavigate();

  const { space } = useTheme();

  const [filterList, setFilterList] = useState<SpecFindingLevel[]>([
    SpecFindingLevel.Critical,
    SpecFindingLevel.High,
  ]);

  const updateFilterList = (list: SpecFindingLevel[]) => {
    setFilterList(list);
  };

  // Get the count of "unique" findings by each dependency package version
  // - "unique" is determined by the finding `meta.description` value
  // - the `spec.target_uuid` is needed for linking to the dependencies page
  const qResourceFinding = useListFindings(tenantName, undefined, {
    filter: [
      filterExpressionBuilders.mainResourceContext(),
      `meta.parent_kind==PackageVersion`,
      `spec.level in [${filterList}]`,
    ].join(' and '),
    group: {
      aggregation_paths: 'spec.target_dependency_package_name',
      unique_count_paths: 'meta.description',
      unique_value_paths: 'spec.target_uuid',
    },
  });

  const dependencyFindingCounts = useMemo(() => {
    const dependencyFindingCounts = [];

    for (const [key, group] of Object.entries(
      qResourceFinding.data?.group_response?.groups ?? {}
    )) {
      const aggregationValues = tryParseGroupResponseAggregationKey(key);
      const name = aggregationValues.find(
        (av) => av.key === 'spec.target_dependency_package_name'
      )?.value as string;

      const dependencyUuid = group.unique_values?.['spec.target_uuid']?.[0];
      const count = group.unique_counts?.['meta.description']?.count ?? 1;

      if (dependencyUuid) {
        dependencyFindingCounts.push({
          count,
          name,
          uuid: dependencyUuid,
        });
      }
    }

    dependencyFindingCounts.sort((a, b) => b.count - a.count);

    const topDependencyFindingCounts = dependencyFindingCounts.slice(0, 10);

    return topDependencyFindingCounts;
  }, [qResourceFinding.data]);

  const chartData = useMemo(() => {
    const chartData: {
      uuid: string;
      name: string;
      parent: string | null;
      findings: number;
      url: string;
    }[] = [];

    for (const item of dependencyFindingCounts) {
      const { label, version } = UIPackageVersionUtils.parsePackageName(
        item.name
      );
      const name = `${label}@${version}`;
      chartData.push({
        findings: item.count,
        name,
        parent: tenantName,
        uuid: item.uuid,
        url: getDependencyPath({ tenantName, uuid: item.uuid }),
      });
    }

    if (chartData.length) {
      // Add an additional parent node for the chart data
      chartData.push({
        findings: 0,
        name: tenantName,
        parent: null,
        uuid: '',
        url: '',
      });
    }

    return chartData;
  }, [dependencyFindingCounts, tenantName]);

  const handleDependencyClick = (
    data: Record<string, string | number | null>
  ) => {
    navigate({ to: data.url });
  };

  const isLoading = qResourceFinding.isLoading;
  const isSuccess = qResourceFinding.isSuccess;
  const isEmptyState = !isLoading && isSuccess && chartData.length === 0;

  return (
    <div style={{ position: 'relative' }} data-testid="dependency-findings">
      <Stack spacing={space.sm}>
        <TitleActionHeader
          action={
            <FindingLevelToggles
              value={filterList}
              onChange={updateFilterList}
            />
          }
          title={<FM defaultMessage="Riskiest Dependencies" />}
          variant="h2"
        />
        <Stack height={height} justifyContent="center" alignItems="center">
          {isLoading && <CircularProgress />}
          {isEmptyState && (
            <EmptyState
              size="medium"
              imageWidth={300}
              textAlign="center"
              title={
                <FM defaultMessage="No dependencies meet the filter criteria" />
              }
            />
          )}
          {!isLoading && !isEmptyState && (
            <ParentSize debounceTime={100}>
              {({ width: visWidth, height: visHeight }) => (
                <Treemap
                  data={chartData}
                  width={visWidth}
                  height={visHeight}
                  groupKey="name"
                  parentKey="parent"
                  countKey="findings"
                  colorList={colorListGray}
                  strokeWidth={2}
                  labelBaseSize={16}
                  labelSkipSize={7}
                  allowVerticalLabels={false}
                  margin={{ top: 0, left: 0, bottom: 0, right: 0 }}
                  onClickHandler={handleDependencyClick}
                />
              )}
            </ParentSize>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
