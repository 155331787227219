import { Stack, StackProps, useTheme } from '@mui/material';
import { get as _get } from 'lodash-es';
import { ReactNode, useMemo } from 'react';

import {
  AttributeDisplay,
  AttributeDisplayProps,
  AttributeDisplayVariant,
  DEFAULT_HEADER_MAX_WIDTH,
} from './AttributeDisplay';

export interface AttributeDisplayRecord extends AttributeDisplayProps {
  attributeKey: string;
}

interface AttributeDisplayStackProps {
  attributeRecords?: AttributeDisplayRecord[];
  containerProps?: Pick<
    StackProps,
    | 'alignItems'
    | 'alignContent'
    | 'alignSelf'
    | 'divider'
    | 'flex'
    | 'justifyContent'
  >;
  headingWidth?: string | number;
  isLoading?: boolean;
  resource: Record<string, ReactNode>;
  variant?: AttributeDisplayVariant;
}

/**
 * Outputs a series of AttributeDisplays based upon
 * a series of attribute records and a provided resource.
 * Ex: <AttributeDisplayStack
 *    attributeRecords={[{ heading: 'Foo', attributeKey: 'spec.foo' }]}
 *    resource={{ spec: { foo: 'Foo value' }}}
 * />
 */
export const AttributeDisplayStack = ({
  attributeRecords = [],
  containerProps = {},
  headingWidth = DEFAULT_HEADER_MAX_WIDTH,
  isLoading,
  resource,
  variant = 'column',
}: AttributeDisplayStackProps) => {
  const { space } = useTheme();

  const spacing = useMemo(
    () => ({
      column: space.sm,
      flex: undefined,
      row: space.md,
    }),
    [space]
  );

  // Map records to resource values only on change
  const outputRecords = useMemo(() => {
    const mappedRecords = attributeRecords.map((record) => {
      const valueNode =
        !!resource && !!record.attributeKey
          ? _get(resource, record.attributeKey)
          : undefined;

      return { ...record, isLoading, value: valueNode };
    });

    return mappedRecords;
  }, [attributeRecords, isLoading, resource]);

  const isCol = variant === 'column';
  const isRow = variant === 'row';

  // Column and rows have similar output, only differing in direction & spacing
  if (isCol || isRow) {
    return (
      <Stack direction={variant} gap={spacing[variant]} {...containerProps}>
        {outputRecords.map((record) => {
          return <AttributeDisplay {...record} key={record.attributeKey} />;
        })}
      </Stack>
    );
  }

  // Flex layout requires separate consideration
  return (
    <Stack direction="column" spacing={space.xs} {...containerProps}>
      {outputRecords.map((record) => {
        return (
          <AttributeDisplay
            {...record}
            key={record.attributeKey}
            headingWidth={headingWidth}
            variant="flex"
          />
        );
      })}
    </Stack>
  );
};
