import {
  CircularProgress,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import {
  V1Ecosystem,
  V1FindingCategory,
  V1FindingTags,
} from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  tryParseGroupResponseAggregationKey,
  useCountFindings,
  useListFindings,
} from '@endorlabs/queries';
import { IconHelp } from '@endorlabs/ui-common';

import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import { useAuthInfo } from '../../providers';

/**
 * List of ecosystems that support reachability analysis.
 *
 * This list is used to calculate the unreachable percentage of vulnerability findings for the tenant.
 */
const REACHABILITY_SUPPORTED_ECOSYSTEMS: V1Ecosystem[] = [
  V1Ecosystem.Cargo,
  V1Ecosystem.Go,
  V1Ecosystem.Maven,
  V1Ecosystem.Pypi,
];

export const VulnerabilityStatsVis = () => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const { space } = useTheme();

  const baseVulnerableFindingsFilters = [
    `spec.finding_categories contains [${V1FindingCategory.Vulnerability}]`,
    filterExpressionBuilders.mainResourceContext(),
  ];

  const qFindingsByEcosystem = useListFindings(
    tenantName,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: [...baseVulnerableFindingsFilters].join(' and '),
      group: {
        aggregation_paths: 'spec.ecosystem',
      },
    }
  );

  const qFindingsUnreachableCount = useCountFindings(
    tenantName,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: [
        ...baseVulnerableFindingsFilters,
        `spec.finding_tags contains [${V1FindingTags.UnreachableFunction}]`,
        `spec.ecosystem in ["${REACHABILITY_SUPPORTED_ECOSYSTEMS.join(
          '","'
        )}"]`,
      ].join(' and '),
      count: true,
    }
  );

  // calculate total count and unreachable percentage from query responses
  const [totalCount, unreachablePercentage] = useMemo(() => {
    let totalCount = 0;
    let reachabilitySupportedCount = 0;

    for (const [key, group] of Object.entries(
      qFindingsByEcosystem.data?.group_response?.groups ?? {}
    )) {
      const aggregationValues = tryParseGroupResponseAggregationKey(key);
      const ecosystem = aggregationValues.find(
        (av) => av.key === 'spec.ecosystem'
      )?.value as string;
      const count = group.aggregation_count?.count ?? 1;

      totalCount += count;

      if (
        REACHABILITY_SUPPORTED_ECOSYSTEMS.includes(ecosystem as V1Ecosystem)
      ) {
        reachabilitySupportedCount += count;
      }
    }

    const unreachableCount = qFindingsUnreachableCount.data?.count ?? 0;

    // NOTE: protect against NaN from division by 0
    const unreachablePercentage = reachabilitySupportedCount
      ? unreachableCount / reachabilitySupportedCount
      : 0;

    return [totalCount, unreachablePercentage];
  }, [
    qFindingsByEcosystem.data?.group_response?.groups,
    qFindingsUnreachableCount.data?.count,
  ]);

  return (
    <Grid
      container
      justifyContent="space-around"
      data-testid="vulnerability-stats"
    >
      <Grid item md={6}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" gap={space.xs} justifyContent="center">
            <Typography variant="h3" textAlign="center">
              <FM defaultMessage="Total Vulnerabilities" />
            </Typography>
            <Tooltip
              title={<FM defaultMessage="All package ecosystems" />}
              arrow
              placement="top"
            >
              <IconHelp />
            </Tooltip>
          </Stack>

          <Typography variant="xxl" textAlign="center">
            {qFindingsByEcosystem.isLoading ? (
              <CircularProgress />
            ) : (
              <FormattedNumber value={totalCount} notation="compact" />
            )}
          </Typography>
        </Stack>
      </Grid>

      <Grid item md={6}>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" gap={space.xs} justifyContent="center">
            <Typography variant="h3" textAlign="center">
              <FM defaultMessage="Unreachable Vulnerabilities" />
            </Typography>
            <Tooltip
              title={
                <FM defaultMessage="Percentage of vulnerabilities where the function associated with the known vulnerability is unreachable. Only applies to supported ecosystems." />
              }
              arrow
              placement="top"
            >
              <IconHelp />
            </Tooltip>
          </Stack>
          <Typography variant="xxl" textAlign="center">
            {qFindingsByEcosystem.isLoading ||
            qFindingsUnreachableCount.isLoading ? (
              <CircularProgress />
            ) : (
              <FormattedNumber
                value={unreachablePercentage}
                style={unreachablePercentage ? 'percent' : undefined}
                notation="compact"
              />
            )}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
