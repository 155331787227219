import { V1Ecosystem } from '@endorlabs/api_client';

/**
 * Ecosystems related to containers
 */
export const CONTAINER_ECOSYSTEMS: V1Ecosystem[] = [
  V1Ecosystem.Apk,
  V1Ecosystem.Container,
  V1Ecosystem.Debian,
  V1Ecosystem.Rpm,
].sort();

/**
 * Ecosystems related to a package manager, for use in the UI
 *
 * Ecosystem values in this list should have corresponding entries in the
 * PackageVersionMetadata collection.
 *
 * @example
 * https://api.endorlabs.com/v1/namespaces/oss/package-versions-metadata?list_parameters.count=true&list_parameters.filter=spec.ecosystem==ECOSYSTEM_CARGO
 * > { "count_response": { "count": 153803 } }
 */
export const PACKAGE_MANAGER_ECOSYSTEMS: V1Ecosystem[] = [
  V1Ecosystem.C,
  V1Ecosystem.Cargo,
  V1Ecosystem.Cocoapod,
  V1Ecosystem.Gem,
  V1Ecosystem.GithubAction,
  V1Ecosystem.Go,
  V1Ecosystem.Maven,
  V1Ecosystem.Npm,
  V1Ecosystem.Nuget,
  V1Ecosystem.Packagist,
  V1Ecosystem.Pypi,
].sort();

/**
 * Ecosystems supported in DroidGPT queries
 */
export const DROID_GPT_ECOSYSTEMS: V1Ecosystem[] = [
  ...PACKAGE_MANAGER_ECOSYSTEMS,
  V1Ecosystem.HuggingFace,
].sort();

/**
 * The "default" ecosystems to be exposed for use in the UI
 */
export const DEFAULT_ECOSYSTEMS: V1Ecosystem[] = [
  ...CONTAINER_ECOSYSTEMS,
  ...PACKAGE_MANAGER_ECOSYSTEMS,
].sort();
