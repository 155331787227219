import { Badge, BadgeProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useStyles } from '@endorlabs/ui-common';

interface BetaBadgeProps extends BadgeProps {
  text?: ReactNode;
}

export const BetaBadge = (props: BetaBadgeProps) => {
  const sx = useStyles(styles);
  const badgeContent = props.text ? props.text : <FM defaultMessage="Beta" />;

  return (
    <Badge
      {...props}
      badgeContent={badgeContent}
      className="BetaBadge-root"
      color="warning"
      sx={sx}
    />
  );
};

const styles = ({ palette, spacing, typography }: Theme) => ({
  '& .MuiBadge-badge': {
    color: palette.common.white,
    fontSize: typography.pxToRem(10),
    letterSpacing: '0.05em',
    lineHeight: 1,
    right: spacing(-5),
    textTransform: 'uppercase',
    top: spacing(0.5),
  },
});
