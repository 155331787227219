import {
  Box,
  FormLabel,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonCancel, ControlledTextField } from '@endorlabs/ui-common';

const slaValidationMessages = defineMessages({
  min: {
    defaultMessage: 'SLA duration value must be a number greater than 0',
  },
});

export const SLAFields = ({ fieldName }: { fieldName: string }) => {
  const { formatMessage } = useIntl();
  const { space } = useTheme();

  const { control, reset } = useFormContext();

  return (
    <Stack direction="column" spacing={space.md} component="fieldset">
      <FormLabel component="legend">
        <Typography variant="body1" color="text.primary">
          <FM defaultMessage="Set a custom SLA duration" />
        </Typography>
      </FormLabel>

      <Stack direction="row" spacing={space.sm} alignItems="flex-start">
        <ControlledTextField
          control={control}
          inputProps={{ min: 0 }}
          label={<FM defaultMessage="SLA Duration" />}
          name={fieldName}
          type="number"
          required
          rules={{
            min: {
              value: 0,
              message: formatMessage(slaValidationMessages.min),
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">Days</InputAdornment>,
          }}
        />

        <Box paddingTop={space.sm}>
          <ButtonCancel onClick={() => reset()}>
            <FM defaultMessage="Reset" />
          </ButtonCancel>
        </Box>
      </Stack>
    </Stack>
  );
};
