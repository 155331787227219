import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1CiCdTool } from '@endorlabs/api_client';
import { RepositoryResource, RepoVersionResource } from '@endorlabs/queries';
import { ExpandCollapseControl, RowStack } from '@endorlabs/ui-common';

import { ToolEvidenceAccordion } from '../../domains/CiCdTools';
import {
  DetailDrawerDivider,
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../DetailDrawer';

export const ProjectToolDetailDrawerToolEvidence = ({
  tools,
  repository,
  repositoryVersion,
}: {
  tools: V1CiCdTool[];
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}) => {
  const totalEvidenceCount = tools.reduce((count, tool) => {
    return count + (tool.evidence?.length ?? 0);
  }, 0);

  const [allExpanded, setAllExpanded] = useState<boolean>(false);

  const repositoryUrl =
    repository && repository?.spec.http_clone_url.replace(/\.git$/, '');
  const repositorySha = repositoryVersion?.spec.version?.sha;

  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection>
        <RowStack justifyContent="space-between" width="100%">
          <ExpandCollapseControl onClick={setAllExpanded} />

          <Typography variant="body2">
            <FM
              defaultMessage="{count, plural, =0 {No evidence} one {# evidence item} other {# evidence items}}"
              values={{ count: totalEvidenceCount }}
            />
          </Typography>
        </RowStack>
      </DetailDrawerSection>

      <DetailDrawerSection disablePadding>
        <Stack divider={<DetailDrawerDivider />} gap={2} padding={1}>
          {tools.map((tool) => (
            <ToolEvidenceAccordion
              key={tool.name}
              isExpanded={allExpanded}
              tool={tool}
              repositoryUrl={repositoryUrl}
              repositorySha={repositorySha}
            />
          ))}
        </Stack>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
