import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem, V1FileStats } from '@endorlabs/api_client';
import { MetricResource } from '@endorlabs/endor-core/Metric';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  RepoVersionResource,
  selectFindingCountsFromGroupResponse,
  useListFindings,
  useListMetrics,
} from '@endorlabs/queries';
import {
  ExternalLink,
  FindingCountArrayDisplay,
  MetadataMetric,
  NilDisplay,
  ProjectProcessingStatus,
  useProjectLatestScanResults,
} from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';
import { useProjectPackageEcosystems } from './useProjectPackageEcosystems';

interface UseProjectVersionMetadataReturn {
  fileStats?: V1FileStats;
  isMetricLoading?: boolean;
  latestScanResult?: ScanResultResource;
  packageCount?: number;
  packageEcosystems?: V1Ecosystem[];
  scorecardMetric?: MetricResource;
  summary: MetadataMetric[];
}

/**
 * Utility to build the Page Header summary for a given Project and Repository Version when available
 */
export const useProjectVersionMetadata = (
  project?: ProjectResource,
  projectVersion?: ProjectVersionResource
): UseProjectVersionMetadataReturn => {
  const projectNamespace = project?.tenant_meta.namespace ?? '';

  const relatedResourceContextFilter =
    filterExpressionBuilders.relatedResourceContext(projectVersion);

  const qProjectPackageEcosystems = useProjectPackageEcosystems(
    project,
    projectVersion
  );

  const qMetrics = useListMetrics(
    projectNamespace,
    {
      enabled: !!project && !!projectVersion && !!relatedResourceContextFilter,
      staleTime: StaleTimes.MEDIUM,
    },
    {
      mask: 'meta.name,meta.parent_uuid,spec.metric_values',
      filter:
        relatedResourceContextFilter &&
        filterExpressionBuilders.and([
          relatedResourceContextFilter,
          `spec.project_uuid=="${project?.uuid}"`,
          `meta.name in [repo_stats_for_file,version_scorecard]`,
        ]),
      page_size: 2,
    }
  );

  const qFindingCounts = useListFindings(
    projectNamespace,
    {
      enabled: !!project && !!relatedResourceContextFilter,
      staleTime: StaleTimes.MEDIUM,
    },
    {
      filter:
        relatedResourceContextFilter &&
        filterExpressionBuilders.and([
          relatedResourceContextFilter,
          `spec.project_uuid==${project?.uuid}`,
        ]),
      group: {
        aggregation_paths: 'spec.level',
      },
    }
  );

  const qScanResults = useProjectLatestScanResults(
    {
      namespace: projectNamespace,
      projectUuid: project?.uuid,
      scanContext: projectVersion?.context,
    },
    { enabled: !!project && !!projectVersion }
  );

  // rebuild metrics when data changes
  return useMemo(() => {
    const { count: packageCount, ecosystems: packageEcosystems } =
      qProjectPackageEcosystems.data;

    const hasProjectVersion = !!projectVersion?.uuid;

    const scorecardMetric = qMetrics.data?.list?.objects.find(
      (o) => o.meta.name === 'version_scorecard'
    );
    const fileStatsMetric = qMetrics.data?.list?.objects.find(
      (o) => o.meta.name === 'repo_stats_for_file'
    );

    // map the finding counts by finding level
    const findingCounts = selectFindingCountsFromGroupResponse(
      qFindingCounts.data?.group_response
    );

    // build up the summary metadata, based on the tenant type
    const summary: MetadataMetric[] = [];

    if (hasProjectVersion) {
      summary.push({
        label: <FM defaultMessage="Findings" />,
        value: <FindingCountArrayDisplay value={findingCounts} />,
        variant: 'raw',
      });
    }

    // Always add the scan info
    const latestScanResult = qScanResults.data?.objects[0];
    summary.push({
      label: <FM defaultMessage="Scanned By" />,
      value: qScanResults.isLoading ? (
        <Skeleton width={24} variant="rounded" />
      ) : (
        <ProjectProcessingStatus
          project={project}
          repositoryVersion={projectVersion as RepoVersionResource}
          scanResult={latestScanResult}
          showLabel
          showScanTime={false}
        />
      ),
      variant: 'raw',
    });

    if (project?.spec.git) {
      // add the following metrics, for all projects
      const repositoryLink = project?.spec.git.http_clone_url.replace(
        /\.git$/,
        ''
      );

      summary.push({
        label: <FM defaultMessage="Repository" />,
        value: repositoryLink ? (
          <ExternalLink to={repositoryLink}>{repositoryLink}</ExternalLink>
        ) : (
          <NilDisplay variant="text" />
        ),
        variant: 'raw',
      });
    }

    return {
      fileStats: fileStatsMetric?.spec.metric_values.fileStats.file_stats,
      isMetricLoading: qMetrics.isLoading,
      latestScanResult,
      packageCount,
      packageEcosystems,
      scorecardMetric,
      summary,
    };
  }, [
    project,
    projectVersion,
    qFindingCounts.data?.group_response,
    qMetrics.data?.list?.objects,
    qMetrics.isLoading,
    qProjectPackageEcosystems.data,
    qScanResults.isLoading,
    qScanResults.data?.objects,
  ]);
};
