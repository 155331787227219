import { Components, Theme } from '@mui/material';

type InputOverrides = Pick<
  Components<Theme>,
  'MuiInputAdornment' | 'MuiInputBase' | 'MuiInputLabel' | 'MuiOutlinedInput'
>;

export const getInputOverrides = ({
  palette,
  spacing,
  typography,
}: Theme): InputOverrides => {
  return {
    MuiInputBase: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          // Remove annoying border that still shows on hidden inputs
          '&:has(input[type="hidden"])': {
            '&::before, &::after': {
              border: 0,
            },
          },
        },

        sizeSmall: {
          paddingBottom: spacing(1),
          paddingTop: spacing(1),
        },

        inputSizeSmall: {
          paddingBottom: spacing(1),
          paddingTop: spacing(1),
        },
      },
    },

    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: palette.text.disabled,
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: true,
      },

      styleOverrides: {
        root: {
          ...typography.h4,
          color: palette.text.primary,

          // Override label color on focus
          '&.Mui-focused': {
            color: palette.text.primary,
          },

          // Handle label color on focus + error
          '&.Mui-focused.Mui-error': {
            color: palette.error.main,
          },

          // Reposition label for outlined inputs
          '&.MuiInputLabel-outlined': {
            left: spacing(-3.5),
            position: 'relative',
            top: spacing(1),
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2px',

          // Eliminate the gap in top border of outlined inputs
          // where MUI likes to place the label for its shrink animation
          '.MuiFormControl-root & .MuiOutlinedInput-notchedOutline legend': {
            display: 'none',
            visibility: 'hidden',
          },
        },
      },
    },
  };
};
