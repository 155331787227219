import { Theme, Typography, TypographyProps, useTheme } from '@mui/material';
import clsx from 'clsx';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';

import { UIFindingUtils } from '../../domains/Findings';

const FindingSeverityMap = {
  [SpecFindingLevel.Critical]: <FM defaultMessage="Critical" />,
  [SpecFindingLevel.High]: <FM defaultMessage="High" />,
  [SpecFindingLevel.Medium]: <FM defaultMessage="Medium" />,
  [SpecFindingLevel.Low]: <FM defaultMessage="Low" />,
  [SpecFindingLevel.Unspecified]: '',
};

interface SeverityDisplayProps extends TypographyProps {
  value: SpecFindingLevel;
}
export const SeverityDisplay = ({ value, ...props }: SeverityDisplayProps) => {
  const sx = styles(useTheme());
  const cls = clsx(value);

  return (
    <Typography {...props} className={cls} component="span" sx={sx}>
      {FindingSeverityMap[value]}
    </Typography>
  );
};

function styles(theme: Theme) {
  const { Critical, High, Medium, Low } = SpecFindingLevel;
  const colors = [Critical, High, Medium, Low].reduce((acc, lvl) => {
    const colorRule = {
      [`&.${lvl}`]: {
        color: UIFindingUtils.getSeverityColor(lvl, theme),
      },
    };
    return { ...acc, ...colorRule };
  }, {});
  return {
    ...colors,
  };
}
