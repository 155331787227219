import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';
import {
  FILTER_COMPARATORS,
  filterExpressionBuilders,
  serialize,
  ValueFilter,
} from '@endorlabs/filters';
import {
  normalizeCiCdToolCategories,
  QueryProjectToolsJoinFilters,
  useListAllToolPatterns,
} from '@endorlabs/queries';
import {
  FilterComparatorSelect,
  FilterComparatorSelectOption,
  MultiSelectInput,
} from '@endorlabs/ui-common';

import {
  FilterFieldConfig,
  filterFieldTransformBuilders,
  FilterState,
} from '../../filters';

const FILTER_COMPARATOR_OPTIONS: FilterComparatorSelectOption[] = [
  { comparator: FILTER_COMPARATORS.IN, name: 'Contains' },
  { comparator: FILTER_COMPARATORS.NOT_IN, name: 'Does Not Contain' },
];

const CATEGORY_FILTER_ID = 'Metric:tool.category';
const TOOL_FILTER_ID = 'Metric:tool.name';

/**
 * Custom hook to build filter fields and filter expressions
 * for the CI/CD tools index page
 *
 * TODO: Add unit tests
 */
export const useCiCdToolsIndexPageFilterFields = (
  namespace: string,
  filterState?: FilterState,
  hideEmptyProjects?: boolean
) => {
  const [categoryComparator, setCategoryComparator] = useState(
    FILTER_COMPARATOR_OPTIONS[0]
  );
  const [toolComparator, setToolComparator] = useState(
    FILTER_COMPARATOR_OPTIONS[0]
  );

  useEffect(() => {
    // Check for existing comparators in filter state
    const existingCategoryComparator = filterState?.values?.get(
      CATEGORY_FILTER_ID
    ) as ValueFilter;
    const selectedCategoryOption = FILTER_COMPARATOR_OPTIONS.find(
      (option) => option.comparator === existingCategoryComparator?.comparator
    );
    if (selectedCategoryOption) {
      setCategoryComparator(selectedCategoryOption);
    }
    const existingToolComparator = filterState?.values?.get(
      TOOL_FILTER_ID
    ) as ValueFilter;
    const selectedToolOption = FILTER_COMPARATOR_OPTIONS.find(
      (option) => option.comparator === existingToolComparator?.comparator
    );
    if (selectedToolOption) {
      setToolComparator(selectedToolOption);
    }
  }, [filterState?.values]);

  // Check for existing filter values in filter state
  const categoryFilterValue = useMemo(() => {
    return (filterState?.values?.get(CATEGORY_FILTER_ID) as ValueFilter)?.value;
  }, [filterState?.values]);
  const toolFilterValue = useMemo(() => {
    return (filterState?.values?.get(TOOL_FILTER_ID) as ValueFilter)?.value;
  }, [filterState?.values]);

  // Fetch all tool patterns to populate filter options
  const qToolPatterns = useListAllToolPatterns(namespace, {}, {});

  // Extract tool names and category names from tool patterns
  const { toolNames, toolCategories } = useMemo(() => {
    const toolNames = qToolPatterns.data
      ?.map((toolPattern) => toolPattern.meta.name)
      .sort((a, b) => a.localeCompare(b));
    const toolCategories =
      qToolPatterns.data?.reduce((acc, toolPattern) => {
        acc.push(...(toolPattern.spec.categories ?? []));
        return acc;
      }, [] as string[]) ?? [];
    const normalizedCategories = normalizeCiCdToolCategories(
      toolCategories
    ) as string[];
    return { toolNames, toolCategories: normalizedCategories };
  }, [qToolPatterns.data]);

  const filterFields: FilterFieldConfig<any>[] = [
    {
      id: CATEGORY_FILTER_ID,
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.metric_values.CiCdTools.ci_cd_tools.tools.categories',
        comparator: categoryComparator.comparator,
      }),
      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          prefix={
            <FilterComparatorSelect
              onChange={setCategoryComparator}
              options={FILTER_COMPARATOR_OPTIONS}
              selectedOption={categoryComparator}
            />
          }
          label={<FM defaultMessage="Tool Categories" />}
          menuProps={{ sx: { maxHeight: '60vh' } }}
          onChange={onChange}
          value={value ?? categoryFilterValue}
          options={
            toolCategories?.map((value) => ({
              value,
              label: value,
            })) ?? []
          }
        />
      ),
    } satisfies FilterFieldConfig<string[]>,
    {
      id: TOOL_FILTER_ID,
      ...filterFieldTransformBuilders.fromFilter({
        key: 'spec.metric_values.CiCdTools.ci_cd_tools.tools.name',
        comparator: toolComparator.comparator,
      }),
      renderInput: ({ onChange, value }) => (
        <MultiSelectInput
          label={<FM defaultMessage="Tools" />}
          menuProps={{ sx: { maxHeight: '60vh' } }}
          onChange={onChange}
          value={value ?? toolFilterValue}
          prefix={
            <FilterComparatorSelect
              onChange={setToolComparator}
              options={FILTER_COMPARATOR_OPTIONS}
              selectedOption={toolComparator}
            />
          }
          options={
            toolNames?.map((value) => ({
              value,
              label: value,
            })) ?? []
          }
        />
      ),
    } satisfies FilterFieldConfig<string[]>,
  ];

  // NOTE: base filter to exclude projects created from SBOM imports
  const baseProjectFilterExpression = `spec.platform_source != ${V1PlatformSource.Unspecified}`;

  // Base expression for CI/CD tools metrics
  const baseMetricFilterExpression = `meta.name==version_cicd_tools`;

  const projectQueryFilters = useMemo(() => {
    const queryJoinFilters: QueryProjectToolsJoinFilters[] = [];

    // If raw expression is given, pass directly through to join filter
    if (filterState?.expression) {
      queryJoinFilters.push({
        kind: 'Metric',
        filter: filterState.expression,
      });

      return queryJoinFilters;
    }

    // If there is a search value, use it to filter on Projects
    const searchValue = filterState?.search;
    const projectFilterExpression = searchValue
      ? filterExpressionBuilders.and([
          baseProjectFilterExpression,
          `meta.name matches "${searchValue}"`,
        ])
      : baseProjectFilterExpression;

    queryJoinFilters.push({
      kind: 'Project',
      filter: projectFilterExpression,
    });

    // If other filters are given, use them to filter on related Metrics
    if (filterState?.values?.size) {
      const expression = serialize(Array.from(filterState.values.values()));
      queryJoinFilters.push({
        kind: 'Metric',
        filter: expression,
      });
      return queryJoinFilters;
    }

    // Default to showing only Projects with related Metrics, when enabled
    if (hideEmptyProjects) {
      queryJoinFilters.push({
        kind: 'Metric',
        filter: baseMetricFilterExpression,
      });
    }

    return queryJoinFilters;
  }, [
    baseMetricFilterExpression,
    baseProjectFilterExpression,
    filterState?.expression,
    filterState?.search,
    filterState?.values,
    hideEmptyProjects,
  ]);

  return {
    baseProjectFilterExpression,
    filterFields,
    isLoading: qToolPatterns.isLoading,
    projectQueryFilters,
  };
};
