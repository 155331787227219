import { Box, Tooltip, Typography, TypographyProps } from '@mui/material';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

const DEFAULT_LENGTH = 10;

export interface TruncatedTextDisplayProps
  extends Omit<TypographyProps, 'children'> {
  value: string;
  width?: number | `${number}%`;
  start?: number;
  end?: number;
}

export const TruncatedTextDisplay = ({
  value = '',
  width = 250,
  start = DEFAULT_LENGTH,
  end = DEFAULT_LENGTH,
  justifyContent,
  ...typographyProps
}: TruncatedTextDisplayProps) => {
  const ref = useRef() as MutableRefObject<HTMLSpanElement>;
  const outerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [isTruncated, setIsTruncated] = useState(false);

  // reset truncation when value or width changes
  useEffect(() => {
    const { clientWidth, scrollWidth } = ref.current;
    setIsTruncated(scrollWidth > clientWidth);
  }, [value, width]);

  // split the truncated parts from the given value
  const [valueStart, valueTruncated, valueEnd] = useMemo(() => {
    if (value.length < start + end) return ['', value, ''];

    const valueStart = value.slice(0, start);
    const valueTruncated = value.slice(start, value.length - end);
    const valueEnd = value.slice(value.length - end);

    return [valueStart, valueTruncated, valueEnd];
  }, [value, start, end]);

  if (!isTruncated) {
    return (
      <Box
        alignItems="center"
        justifyContent={justifyContent}
        display="flex"
        ref={outerRef}
        width={width}
      >
        <Typography
          ref={ref}
          {...typographyProps}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {value}
        </Typography>
      </Box>
    );
  }

  return (
    <Tooltip title={value}>
      <Box
        alignItems="center"
        justifyContent={justifyContent}
        width={width}
        display="flex"
        flexWrap="nowrap"
      >
        <Typography {...typographyProps}>{valueStart}</Typography>
        <Typography
          {...typographyProps}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          ref={ref}
        >
          {valueTruncated}
        </Typography>
        <Typography {...typographyProps}>{valueEnd}</Typography>
      </Box>
    </Tooltip>
  );
};
