import { Slider, Stack, Typography, useTheme } from '@mui/material';
import { isEqual as _isEqual } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { FilterDropdown } from '../../domains/filters/components/FilterDropdown';
import { FacetFilterDefinition } from './types';

type RangeValue = [min: number, max: number];
const MIN_DISTANCE = 0.1;
const DEFAULT_RANGE: RangeValue = [0, 1];

/**
 * Parse the given value for the control
 */
const parseValue = (v: unknown): RangeValue | undefined => {
  if (!Array.isArray(v)) return undefined;

  const parsed = v.map((v) => {
    if ('string' === typeof v) return parseFloat(v);
    return 'number' === typeof v ? v : NaN;
  });

  // if a value is invalid, or outside the expected range, return undefined
  if (
    parsed.length !== DEFAULT_RANGE.length ||
    parsed.some((v) => isNaN(v) || v < DEFAULT_RANGE[0] || v > DEFAULT_RANGE[1])
  ) {
    return undefined;
  }

  return parsed as RangeValue;
};

export interface ControlsFacetFilterFindingEpssProbability {
  facet: FacetFilterDefinition;
  onChange: (value: RangeValue | undefined) => void;
  value: string[] | number[] | undefined;
}

/**
 * Facet Filter control for EPSS Probability
 */
export const ControlsFacetFilterFindingEpssProbability = ({
  onChange,
  value: filterValue,
}: ControlsFacetFilterFindingEpssProbability) => {
  const { formatMessage: fm, formatNumber } = useIntl();
  const [value, setValue] = useState<RangeValue>(DEFAULT_RANGE);
  const { space } = useTheme();

  useEffect(() => {
    setValue(parseValue(filterValue) ?? DEFAULT_RANGE);
  }, [filterValue]);

  const isDefaultValue = !filterValue || _isEqual(filterValue, DEFAULT_RANGE);

  /**
   * Adapted from https://mui.com/material-ui/react-slider/#minimum-distance,
   * forces a minimum distance between the min and max values
   */
  const handleChange = (
    evt: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const [min, max] = newValue;

    if (max - min < MIN_DISTANCE) {
      if (activeThumb === 0) {
        const clamped = Math.min(min, 1 - MIN_DISTANCE);
        setValue([clamped, clamped + MIN_DISTANCE]);
      } else {
        const clamped = Math.max(max, MIN_DISTANCE);
        setValue([clamped - MIN_DISTANCE, clamped]);
      }
    } else {
      setValue([min, max]);
    }
  };

  const handleApply = () => {
    if (_isEqual(value, DEFAULT_RANGE)) {
      onChange(undefined);
    } else {
      onChange(value);
    }
  };

  const handleCancel = () => {
    setValue(parseValue(filterValue) ?? DEFAULT_RANGE);
  };

  const content = (
    <Stack gap={space.xs}>
      <Typography variant="body2">
        <FM
          defaultMessage="The Finding has an EPSS Probability within the range of {min, number, ::percent} to {max, number, ::percent}"
          values={{
            min: value[0],
            max: value[1],
          }}
        />
      </Typography>

      <Slider
        getAriaLabel={(index) =>
          index === 0
            ? fm({ defaultMessage: 'Minimum EPSS Probability' })
            : fm({ defaultMessage: 'Maximum EPSS Probability' })
        }
        getAriaValueText={(value) => `${value}%`}
        max={1}
        min={0}
        onChange={handleChange}
        size="small"
        step={0.01}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => formatNumber(value, { style: 'percent' })}
      />
    </Stack>
  );

  return (
    <FilterDropdown
      id="ControlsFacetFilterFindingEpssProbability-popover"
      isActive={!isDefaultValue}
      label={<FM defaultMessage="EPSS Probability" />}
      onApply={handleApply}
      onCancel={handleCancel}
    >
      {content}
    </FilterDropdown>
  );
};
