import { head as _head, last as _last, round as _round } from 'lodash-es';
import { MouseEventHandler, ReactNode, useMemo } from 'react';
import {
  defineMessages,
  FormattedMessage as FM,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import {
  SpecFindingLevel,
  V1FindingCategory,
  V1FindingTags,
} from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import {
  FilterExpression,
  filterExpressionBuilders,
  serialize,
  ValueFilter,
} from '@endorlabs/filters';
import {
  FindingCountsByLevel,
  selectFindingCountsByLevelFromGroupResponse,
} from '@endorlabs/queries';
import { FindingTagLabel } from '@endorlabs/ui-common';

import { isQueryStateLoading } from '../utils';
import { DashboardConfigValue } from './useDashboardConfigValue';
import { useDashboardQueries } from './useDashboardQueries';

const LABEL_MESSAGES = defineMessages({
  stage_total: {
    defaultMessage: 'Total Open Vulnerabilities',
  },
  not_in_test: { defaultMessage: 'Not In Test' },
  fix_available: { defaultMessage: 'Fix Available' },
  reachable: { defaultMessage: 'Reachability' },
  exploitable: { defaultMessage: 'Exploitable Likelihood' },
});

export type VulnerabilityPrioritizationStageValue = Partial<
  Record<SpecFindingLevel, number>
>;

export const VulnerabilityPrioritizationStageKeys = {
  TOTAL: 'TOTAL',
  NOT_IN_TEST: 'NOT_IN_TEST',
  FIX_AVAILABLE: 'FIX_AVAILABLE',
  REACHABLE: 'REACHABLE',
  EXPLOITABLE: 'EXPLOITABLE',
} as const;

export type VulnerabilityPrioritizationStageKey =
  (typeof VulnerabilityPrioritizationStageKeys)[keyof typeof VulnerabilityPrioritizationStageKeys];

export type VulnerabilityPrioritizationStageDefinition = {
  key: VulnerabilityPrioritizationStageKey;
  labelMessage: MessageDescriptor;
  captionMessage?: MessageDescriptor;
};

export type VulnerabilityPrioritizationStageAction = {
  label: ReactNode;
  onClick?: MouseEventHandler;
};

export type VulnerabilityPrioritizationStage = Omit<
  VulnerabilityPrioritizationStageDefinition,
  'labelMessage' | 'captionMessage'
> & {
  label: string;
  caption?: string;
  actions?: VulnerabilityPrioritizationStageAction[];
  expression: FilterExpression;
  filters: ValueFilter[];
  isLoading: boolean;
  value: VulnerabilityPrioritizationStageValue;
  total: number;
};

export type UseVulnerabilityPrioritizationStagesProps = {
  config: DashboardConfigValue;
  namespace: string;
  enabled?: boolean;
};

export const VULN_PRIORITIZATION_STAGES: VulnerabilityPrioritizationStageDefinition[] =
  [
    {
      key: VulnerabilityPrioritizationStageKeys.TOTAL,
      labelMessage: LABEL_MESSAGES.stage_total,
    },
    {
      key: VulnerabilityPrioritizationStageKeys.NOT_IN_TEST,
      labelMessage: LABEL_MESSAGES.not_in_test,
    },
    {
      key: VulnerabilityPrioritizationStageKeys.FIX_AVAILABLE,
      labelMessage: LABEL_MESSAGES.fix_available,
    },
    {
      key: VulnerabilityPrioritizationStageKeys.REACHABLE,
      labelMessage: LABEL_MESSAGES.reachable,
    },
    {
      key: VulnerabilityPrioritizationStageKeys.EXPLOITABLE,
      labelMessage: LABEL_MESSAGES.exploitable,
    },
  ];

const getVulnPrioritizationStageActions = (
  key: VulnerabilityPrioritizationStageKey,
  config: DashboardConfigValue,
  handlers: {
    onEditEpssThreshold?: MouseEventHandler;
    onEditFindingReachability?: MouseEventHandler;
  }
): VulnerabilityPrioritizationStageAction[] | undefined => {
  if (key === VulnerabilityPrioritizationStageKeys.EXPLOITABLE) {
    return [
      {
        label: (
          <FM
            defaultMessage="EPSS > {threshold, number}%"
            values={{ threshold: config.epssProbabilityThreshold }}
          />
        ),
        onClick: handlers.onEditEpssThreshold,
      },
    ];
  }

  if (key === VulnerabilityPrioritizationStageKeys.REACHABLE) {
    return config.findingReachabilityTags.map((t) => ({
      label: <FindingTagLabel findingTag={t} />,
      onClick: handlers.onEditFindingReachability,
    }));
  }
};

const getVulnPrioritizationStageFilters = (
  key: VulnerabilityPrioritizationStageKey,
  config: DashboardConfigValue
) => {
  const filters: ValueFilter[] = [];

  /* eslint-disable no-fallthrough */
  switch (key) {
    case VulnerabilityPrioritizationStageKeys.EXPLOITABLE:
      filters.push({
        key: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',
        comparator: 'GREATER_OR_EQUAL',
        value: _round(config.epssProbabilityThreshold / 100, 2),
      });
    case VulnerabilityPrioritizationStageKeys.REACHABLE:
      filters.push({
        key: 'spec.finding_tags',
        comparator: 'CONTAINS',
        value: config.findingReachabilityTags,
      });
    case VulnerabilityPrioritizationStageKeys.FIX_AVAILABLE:
      filters.push({
        key: 'spec.finding_tags',
        comparator: 'CONTAINS',
        value: [V1FindingTags.FixAvailable],
      });
    case VulnerabilityPrioritizationStageKeys.NOT_IN_TEST:
      filters.push({
        key: 'spec.finding_tags',
        comparator: 'CONTAINS',
        value: [V1FindingTags.Normal],
      });
    case VulnerabilityPrioritizationStageKeys.TOTAL:
      filters.push({
        key: 'spec.finding_categories',
        comparator: 'CONTAINS',
        value: [V1FindingCategory.Vulnerability],
      });
  }
  /* eslint-enable no-fallthrough */

  return filters;
};

const getDevEstimateProjections = (
  config: DashboardConfigValue,
  stages: VulnerabilityPrioritizationStage[]
) => {
  let costSaved = 0;
  let devHoursSaved = 0;

  if (stages.length === 0) {
    return { costSaved, devHoursSaved };
  }

  // Calculate the diff between finding counts at the baseline and end of funnel
  const baseline =
    stages.find((s) => s.key === config.baselineFindingsFilter) ??
    _head(stages);
  const final = _last(stages);

  if (!baseline || !final) {
    return { costSaved, devHoursSaved };
  }

  const diff = baseline.total - final.total;

  devHoursSaved = diff * config.devHours;
  costSaved = devHoursSaved * config.hourlyCost;

  return { costSaved, devHoursSaved };
};

const getFilteredValue = (
  counts: FindingCountsByLevel,
  findingLevels: SpecFindingLevel[]
): { total: number; value: VulnerabilityPrioritizationStageValue } => {
  const getValue = (level: SpecFindingLevel) => {
    let value = counts[level] ?? 0;
    if (!findingLevels.includes(level)) {
      value = 0;
    }
    return value;
  };

  const value = FINDING_LEVELS.reduce((acc, level) => {
    acc[level] = getValue(level);
    return acc;
  }, {} as any) as VulnerabilityPrioritizationStageValue;

  // Add the total
  const total = Object.values(value).reduce((s, v) => s + v, 0);

  return { total, value };
};

export const useVulnerabilityPrioritization = ({
  config,
  enabled = true,
  findingLevels,
  namespace,
  onEditEpssThreshold,
  onEditFindingReachability,
}: {
  config: DashboardConfigValue;
  enabled?: boolean;
  findingLevels: SpecFindingLevel[];
  namespace: string;
  onEditEpssThreshold?: MouseEventHandler;
  onEditFindingReachability?: MouseEventHandler;
}) => {
  const { formatMessage: fm } = useIntl();
  const { getQueryStatus } = useDashboardQueries({ namespace });

  // NOTE: excluding dismissed/excepted findings
  const baseFilterExpression = filterExpressionBuilders.and([
    filterExpressionBuilders.mainResourceContext(),
    `spec.dismiss != true`,
    `spec.finding_tags not contains [${V1FindingTags.Exception}]`,
  ]);

  const stages = VULN_PRIORITIZATION_STAGES.map((s) => {
    const { key, labelMessage, captionMessage } = s;

    // Convert the message descriptors to strings here.
    const filters = getVulnPrioritizationStageFilters(key, config);
    const actions = getVulnPrioritizationStageActions(key, config, {
      onEditEpssThreshold,
      onEditFindingReachability,
    });

    return {
      key,
      label: fm(labelMessage),
      caption: captionMessage ? fm(captionMessage) : undefined,
      filters,
      actions,
      expression: filterExpressionBuilders.and([
        baseFilterExpression,
        serialize(filters),
      ]),
    };
  });

  // Inject data for each stage from dashboard queries
  const stagesWithData: VulnerabilityPrioritizationStage[] = stages.map(
    (stage) => {
      let data;
      let isLoading = true;

      if (enabled) {
        const qStage = getQueryStatus({
          aggregation_paths: ['spec.level'],
          filters: [stage.expression],
          resourceKind: 'Finding',
        });

        data = qStage?.data;
        isLoading = isQueryStateLoading(qStage);
      }

      const counts = selectFindingCountsByLevelFromGroupResponse(
        data?.spec?.query_response?.group_response
      );

      const { total, value } = getFilteredValue(counts, findingLevels);

      return { ...stage, isLoading, total, value };
    }
  );

  const { devHoursSaved, costSaved } = useMemo(
    () => getDevEstimateProjections(config, stagesWithData),
    [config, stagesWithData]
  );

  const isLoading = stagesWithData.some((s) => s.isLoading);

  return {
    costSaved,
    devHoursSaved,
    isLoading,
    stages: stagesWithData,
  };
};
