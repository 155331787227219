import { Button, ButtonProps } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

export const ButtonCancel = (props: ButtonProps) => {
  return (
    <Button {...props}>
      {props.children ?? <FM defaultMessage="Cancel" />}
    </Button>
  );
};
