import { useMatch } from '@tanstack/react-location';

import { NewProjectGitHub } from '../../../../../views/Projects/NewProject/NewProjectGitHub';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantCreateProjectGithubRoute = () => {
  const {
    params: { namespace },
  } = useMatch();
  return <NewProjectGitHub namespace={namespace} />;
};

export const Route = createFileRoute('/t/:namespace/projects/new/github')({
  component: TenantCreateProjectGithubRoute,
});
