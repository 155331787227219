import { Box, Stack, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1Confidence } from '@endorlabs/api_client';

import { BreakingChangeConfidenceDisplay } from '../BreakingChangeConfidenceDisplay';

export const BreakingConfidenceTooltip = () => {
  return (
    <Stack padding={2} spacing={4}>
      <Box>
        <BreakingChangeConfidenceDisplay confidence={V1Confidence.High} />
        <Typography paddingTop={1} variant="body2">
          <FM defaultMessage="Endor Labs is confident this change will impact your application." />
        </Typography>
      </Box>
      <Box>
        <BreakingChangeConfidenceDisplay confidence={V1Confidence.Medium} />
        <Typography paddingTop={1} variant="body2">
          <FM defaultMessage="Endor Labs has reason to suspect this update may impact your application." />
        </Typography>
      </Box>
      <Box>
        <BreakingChangeConfidenceDisplay confidence={V1Confidence.Low} />
        <Typography paddingTop={1} variant="body2">
          <FM defaultMessage="Endor Labs did not find any evidence that this update will break your application." />
        </Typography>
      </Box>
    </Stack>
  );
};
