import { isMatch as _isMatch } from 'lodash-es';

import { ProjectResource } from '@endorlabs/endor-core/Project';

import { useInfoDrawer } from '../../../components/InfoDrawer';
import {
  ProjectDetailDrawer,
  ProjectDetailDrawerProps,
} from '../components/ProjectDetailDrawer';

export interface ProjectDetailDrawerParams {
  projectNamespace: string;
  projectUuid: string;
}

export const useProjectDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    Pick<ProjectDetailDrawerProps, 'uuid'>,
    ProjectDetailDrawerProps
  >({
    drawerParams: ['projectNamespace', 'projectUuid'],
    Component: ProjectDetailDrawer,
  });

  const permalinkEffect = (projects: ProjectResource[]) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeProject = activeDrawerParams
      ? projects.find((p) => p.uuid === activeDrawerParams.projectUuid)
      : undefined;

    if (
      activeProject &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          projectNamespace: activeProject.tenant_meta.namespace,
          projectUuid: activeProject.uuid,
        },
        {
          namespace: activeProject.tenant_meta.namespace,
          uuid: activeProject.uuid,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
