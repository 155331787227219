import { Divider, Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import {
  AttributeDisplay,
  NumberDisplay,
  RowStack,
} from '@endorlabs/ui-common';

export const AssuredPackageVersionPatchSummary = ({
  assuredPackageVersion,
}: {
  assuredPackageVersion?: AssuredPackageVersionResource;
}) => {
  const { space, palette } = useTheme();

  const summary = useMemo(() => {
    const upgradeSummary = assuredPackageVersion?.spec.upgrade_summary;
    const endorPatchCount = upgradeSummary?.number_of_endor_patches ?? 0;
    const upstreamPatchCount = upgradeSummary?.number_of_original_patches ?? 0;

    return {
      endorPatchCount,
      totalPatchCount: endorPatchCount + upstreamPatchCount,
      upstreamPatchCount,
    };
  }, [assuredPackageVersion?.spec.upgrade_summary]);

  return (
    <Stack
      divider={<Divider />}
      spacing={space.sm}
      sx={{
        backgroundColor: palette.grey[100],
        borderBottom: `1px solid ${palette.divider}`,
        padding: space.sm,
      }}
    >
      <RowStack justifyContent="center">
        <AttributeDisplay
          heading={<FM defaultMessage="Commits" />}
          value={<NumberDisplay value={summary.totalPatchCount} />}
          // TODO: add tooltip when known
          // helpTooltip={<FM defaultMessage="Patches" />}
          valueTypographyProps={{
            textAlign: 'center',
            variant: 'h3',
          }}
        />
      </RowStack>

      <RowStack
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-evenly"
      >
        <AttributeDisplay
          headingWidth={200}
          heading={<FM defaultMessage="Upstream Commits" />}
          value={<NumberDisplay value={summary.upstreamPatchCount} />}
          helpTooltip={
            <FM defaultMessage="An upstream commit refers to a commit created by the library's original maintainer, with no modifications made to it." />
          }
          valueTypographyProps={{
            textAlign: 'center',
            variant: 'h3',
          }}
        />

        <AttributeDisplay
          headingWidth={200}
          heading={<FM defaultMessage="Endor Commits" />}
          value={<NumberDisplay value={summary.endorPatchCount} />}
          helpTooltip={
            <FM defaultMessage="An Endor commit is a commit created by Endor Labs, which may involve minor or major changes to an upstream commit." />
          }
          valueTypographyProps={{
            textAlign: 'center',
            variant: 'h3',
          }}
        />
      </RowStack>
    </Stack>
  );
};
