import { useEffect, useState } from 'react';

import { UserSession } from '@endorlabs/queries';
import { digestString } from '@endorlabs/utils/crypto/digest';
import * as hex from '@endorlabs/utils/encoding/hex';

import { EventIdentifiers } from './types';

const makeEventIdentifiers = async (
  session: UserSession
): Promise<EventIdentifiers | undefined> => {
  if (!session.user || !session.expiration_time) return;

  const [userId, sessionId] = await Promise.all([
    digestString('SHA-1', session.user.meta.name),
    digestString(
      'SHA-1',
      session.user.meta.name + '|' + session.expiration_time
    ),
  ])
    // convert the results to hex-encoded strings
    .then((values) => values.map((buffer) => hex.fromArrayBuffer(buffer)));

  return { userId, sessionId };
};

export const useEventIdentifiers = ({ session }: { session?: UserSession }) => {
  const [eventIdentifiers, setEventIdentifiers] = useState<
    EventIdentifiers | undefined
  >(undefined);

  useEffect(() => {
    if (!session) {
      setEventIdentifiers(undefined);
      return;
    }

    makeEventIdentifiers(session)
      .then((identifiers) => {
        if (identifiers) {
          setEventIdentifiers(identifiers);
        }
      })
      .catch((error) => {
        // TODO: handle errors
      });
  }, [session]);

  return eventIdentifiers;
};
