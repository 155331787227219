import { V1PlatformSource } from '@endorlabs/api_client';
import { ProjectMinimal } from '@endorlabs/endor-core/Project';

import { UIProjectUtils } from '../../domains';
import { IconTitleDisplay, IconTitleDisplayProps } from '../IconTitleDisplay';

export interface ProjectNameDisplayV2Props extends IconTitleDisplayProps {
  platformSource?: V1PlatformSource;
  project: ProjectMinimal;
  url?: string;
}

export const ProjectNameDisplayV2 = ({
  platformSource: platformSourceOverride,
  project,
  ...props
}: ProjectNameDisplayV2Props) => {
  const platformSource = platformSourceOverride ?? project.spec.platform_source;

  return (
    <IconTitleDisplay
      {...props}
      Icon={UIProjectUtils.getPlatformIcon(platformSource)}
      title={UIProjectUtils.parseProjectName(project.meta.name, platformSource)}
    />
  );
};
