import { Stack } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonLogin } from '@endorlabs/ui-common';
import { setURLSearchParams } from '@endorlabs/utils/url';

import { AuthProviderName, AuthProviders } from '../constants';

interface LoginButtonsProps {
  exclude?: AuthProviderName[];
  include?: AuthProviderName[];
  returnTo?: string;
}

export const LoginButtons = ({
  exclude = [],
  include,
  returnTo,
}: LoginButtonsProps) => {
  let providers = Object.values(AuthProviders);

  if (include !== undefined) {
    providers = providers.filter(
      (provider) => provider.isGlobal || include.includes(provider.displayName)
    );
  } else if (exclude.length > 0) {
    providers = providers.filter((p) => exclude.includes(p.displayName));
  }

  // when present, pass the return route to the login handler
  if (returnTo) {
    providers = providers.map(({ url, ...rest }) => ({
      ...rest,
      url: setURLSearchParams(url, {
        // double url-encode the 'return to' url, to preserve
        // query string parameters after the redirect from
        // the api server
        redirect: `?redirect=${encodeURIComponent(returnTo)}`,
      }),
    }));
  }

  return (
    <Stack spacing={4}>
      {providers.map((provider) => {
        return (
          <ButtonLogin
            href={provider.url}
            Icon={provider.Icon}
            imageUrl={provider.imageUrl}
            key={provider.displayName}
            color="secondary"
          >
            <FM
              defaultMessage="Log in with {provider}"
              values={{ provider: provider.displayName }}
            />
          </ButtonLogin>
        );
      })}
    </Stack>
  );
};
