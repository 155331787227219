import { FormattedMessage as FM } from 'react-intl';

import { PolicyResource } from '@endorlabs/queries';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { ExceptionPolicyDetails } from '../../Policies/components/ExceptionPolicyDetails';

interface FindingExceptionPoliciesSectionProps {
  exceptionPolicies?: PolicyResource[];
}

export const FindingExceptionPoliciesSection = ({
  exceptionPolicies = [],
}: FindingExceptionPoliciesSectionProps) => {
  return (
    <DetailDrawerSection title={<FM defaultMessage="Exception Policies" />}>
      <ExceptionPolicyDetails exceptionPolicies={exceptionPolicies} />
    </DetailDrawerSection>
  );
};
