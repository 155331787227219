import { Mutation } from 'react-query';

import { ResourceKind } from '@endorlabs/endor-core';
import { ResourceMutateAction } from '@endorlabs/endor-core/api';

import { ResourceMutateMeta } from '../types';

/**
 * Helper to build Mutation meta object for use with react-query
 */
export const buildResourceMutateMeta = (
  action: ResourceMutateAction,
  resourceKind: ResourceKind
): ResourceMutateMeta => ({ crud: { action, resourceKind } });

/**
 * Helper to detect a resource mutate error handler
 */
export const hasResourceMutateErrorHandler = (event: Mutation): boolean => {
  const errorHandler = event.options.onError;
  return undefined !== errorHandler;
};

/**
 * Helper to detect a resource mutate success handler
 */
export const hasResourceMutateSuccessHandler = (event: Mutation): boolean => {
  const successHandler = event.options.onSuccess;
  return undefined !== successHandler;
};

/**
 * Type Guard for custom mutation meta
 */
export const isResourceMutateMeta = (v: any): v is ResourceMutateMeta => {
  return (
    v &&
    'object' === typeof v &&
    Reflect.has(v, 'crud') &&
    'object' === typeof v.crud &&
    Reflect.has(v.crud, 'action') &&
    Reflect.has(v.crud, 'resourceKind')
  );
};
