import { useContext } from 'react';

import { FilterContext } from './FilterContext';

export const useFilterContext = () => {
  const context = useContext(FilterContext);

  // Ensure filter context is present for consumer
  if (!context) {
    throw new Error('Missing provider for FilterContext');
  }

  return context;
};
