import { useCallback, useMemo } from 'react';

import { SavedQueryResource } from '@endorlabs/endor-core/SavedQuery';
import {
  sortParamBuilders,
  useCreateSavedQuery,
  useListSavedQueries,
} from '@endorlabs/queries';

import { SavedFilter } from '../types';

export type UseResourceFilterSavedQueriesResult = {
  savedFilters: SavedFilter[];
  isLoading: boolean;
  createSavedFilter: (newSavedFilter: Omit<SavedFilter, 'id'>) => void;
};

/**
 * Provides Saved Filters for a given Resource from associated Saved Queries
 */
export const useResourceFilterSavedQueries = (
  namespace: string,
  resourceKind: string
): UseResourceFilterSavedQueriesResult => {
  const qCreateSavedQuery = useCreateSavedQuery();

  const qSavedQueries = useListSavedQueries(
    namespace,
    {
      filter: [
        'meta.parent_uuid not exists',
        `spec.query.spec.query_spec.kind==${resourceKind}`,
      ].join(' and '),
      mask: [
        'uuid',
        'meta.name',
        'meta.tags',
        'spec.query.spec.query_spec.list_parameters.filter',
      ].join(','),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    },
    { enabled: Boolean(namespace && resourceKind) }
  );

  const savedFilters = useMemo(() => {
    return (
      qSavedQueries.data?.objects.map((o) => ({
        id: o.uuid,
        name: o.meta.name,
        filter: o.spec.query?.spec?.query_spec?.list_parameters?.filter ?? '',
        tags: o.meta.tags,
      })) ?? []
    );
  }, [qSavedQueries.data]);

  const createSavedFilter = useCallback(
    (newSavedFilter: Omit<SavedFilter, 'id'>) => {
      const { filter, name, tags } = newSavedFilter;
      const resource: Omit<SavedQueryResource, 'uuid'> = {
        meta: { name, tags },
        spec: {
          query: {
            meta: { name },
            spec: {
              query_spec: {
                kind: resourceKind,
                list_parameters: {
                  filter,
                },
              },
            },
            tenant_meta: { namespace },
          },
        },
        tenant_meta: { namespace },
        propagate: true,
      };

      // return the empty promise
      return qCreateSavedQuery.mutate({ namespace, resource });
    },
    [namespace, qCreateSavedQuery, resourceKind]
  );

  return {
    createSavedFilter,
    savedFilters,
    isLoading: qSavedQueries.isLoading || qCreateSavedQuery.isLoading,
  };
};
