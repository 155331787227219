import { without as _without } from 'lodash-es';

import { V1Ecosystem } from '@endorlabs/api_client';
import { PACKAGE_MANAGER_ECOSYSTEMS } from '@endorlabs/endor-core/Ecosystem';

/**
 * The ecosystems currently supported for Remediations
 */
export const REMEDIATIONS_SUPPORTED_ECOSYSTEMS = _without(
  PACKAGE_MANAGER_ECOSYSTEMS,
  V1Ecosystem.GithubAction
);
