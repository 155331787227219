import {
  capitalize as _capitalize,
  concat as _concat,
  groupBy as _groupBy,
  isNil as _isNil,
  isNumber as _isNumber,
  isObject as _isObject,
  isString as _isString,
} from 'lodash-es';

import {
  ReferenceReferenceType as VulnReferenceType,
  SpecAffectedRange,
  V1Vuln,
} from '@endorlabs/api_client';
import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';

import { VulnReferenceTypeValues } from '../constants';
import {
  CVSSScoreSummary,
  FINDING_ATTRIBUTE_GROUPING,
  FindingAttributeGroup,
  FindingAttributeValue,
  FindingResource,
} from '../types';
import { isSecurityFinding } from './categoryHelpers';

/**
 * Gets the merged set of Finding "Attributes"
 */
export const getFindingAttributeValues = (
  finding: FindingResource
): FindingAttributeValue[] => {
  const findingAttributes = _concat<FindingAttributeValue>(
    finding.spec.finding_tags,
    // Add 'dismissed' status as a finding tag
    finding.spec.dismiss ? ['FINDING_SPEC_DISMISSED'] : []
  );

  return findingAttributes as FindingAttributeValue[];
};

/**
 * Returns Finding "attributes" grouped by the related attributes
 */
export const getGroupedFindingAttributes = (
  attributes: FindingAttributeValue[]
): Partial<Record<FindingAttributeGroup, FindingAttributeValue[]>> => {
  return _groupBy(
    attributes,
    (a) =>
      Reflect.get(FINDING_ATTRIBUTE_GROUPING, a) ??
      FindingAttributeGroup.Unspecified
  );
};

/**
 * Get the embeded vulnerability from the Finding Metadata
 */
export const getFindingVulnerabilityMetadata = (
  finding: Partial<FindingResource>
): V1Vuln | undefined => {
  return finding.spec?.finding_metadata?.vulnerability;
};

/**
 * Helper function to defensively reach nested "affected ranges" in vuln object
 */
const getSecurityFindingAffectedRanges = (
  finding: FindingResource
): SpecAffectedRange[] | undefined => {
  return getFindingVulnerabilityMetadata(finding)?.spec?.affected?.[0]?.ranges;
};

/**
 * Determines the title of a Finding for use in PageHeaders, drawer headings, etc.
 * Typically derived from a Finding metadata property.
 */
export const getFindingTitle = (
  finding: Partial<FindingResource>
): string | undefined => {
  if (isSecurityFinding(finding)) {
    return (
      getFindingVulnerabilityMetadata(finding)?.meta?.description ||
      finding.meta?.description ||
      finding.meta?.name
    );
  }

  return finding.meta?.description || finding.meta?.name;
};

/**
 * Find the best available description for a given finding
 */
export const getFindingDescription = (finding: FindingResource): string => {
  return finding.spec.summary || finding.meta.description || finding.meta.name;
};

/**
 * Find the published time for a Finding from the Finding Metadata
 *
 * If the finding is encoded as a Protobuf Timestamp, re-encode as a JSON Date
 */
export const getFindingPublishedTime = (finding: FindingResource): string => {
  const publishedTime =
    getFindingVulnerabilityMetadata(finding)?.spec?.published;

  // support `google.protobuf.Timestamp`, ignoring `nanos`
  if (publishedTime && _isObject(publishedTime)) {
    const { seconds } = publishedTime;

    // return the JSON encoded date from the timestamp, when present
    return seconds ? new Date(seconds * 1000).toJSON() : '';
  }

  return publishedTime ?? '';
};

export const getFindingDiscovered = (finding: FindingResource): string => {
  if (isSecurityFinding(finding)) {
    return getFindingPublishedTime(finding);
  }

  return finding.meta.create_time ?? '';
};

/**
 * Returns proposed fix version, if any, from a Security Finding.
 */
export const getSecurityFindingFixVersion = (
  finding: FindingResource
): string | undefined => {
  if (isSecurityFinding(finding)) {
    const proposedVersion = finding.spec.proposed_version;
    // return undefined if proposed version is default empty string
    return proposedVersion === '' ? undefined : proposedVersion;
  }
};

/**
 * Returns introduced version, if any, from a Security Finding.
 */
export const getSecurityFindingIntroducedVersion = (
  finding: FindingResource
): string | undefined => {
  if (isSecurityFinding(finding)) {
    const ranges = getSecurityFindingAffectedRanges(finding);
    const versionIntroduced = ranges?.find((r) => r.introduced)?.introduced;
    return versionIntroduced;
  }
};

export const getSecurityFindingCvssScore = (
  finding: FindingResource
): CVSSScoreSummary => {
  const cvssV3Severity =
    getFindingVulnerabilityMetadata(finding)?.spec?.cvss_v3_severity;

  const baseScore = cvssV3Severity?.score;
  const scoreVector = cvssV3Severity?.vector;
  const scoreType = scoreVector?.match(/CVSS:(\d+\.\d+)\//i)?.[1];

  return { baseScore, scoreType, scoreVector };
};

export const getSecurityFindingCveId = (
  finding: Partial<FindingResource>
): string | undefined => {
  const vulnerabilityMetadata = getFindingVulnerabilityMetadata(finding);
  const cveId = vulnerabilityMetadata?.spec?.raw?.endor_vulnerability?.cve_id;
  if (cveId) return cveId;

  // Fallback to an alias for the vuln, if present
  const aliases = vulnerabilityMetadata?.spec?.aliases;
  if (aliases?.length) {
    return aliases[0];
  }
};

export const getSecurityFindingCweId = (
  finding: FindingResource
): string | undefined => {
  let cweId =
    getFindingVulnerabilityMetadata(finding)?.spec?.raw?.endor_vulnerability
      ?.cwe;
  if (cweId) {
    cweId = 'CWE-' + cweId;
  }
  return cweId;
};

export const getSASTSecurityFindingCwe = (
  semgrepRule: SemgrepRulesResource
): Record<'cweId' | 'cweDescription', string>[] => {
  const cweArray = semgrepRule?.spec?.rule?.metadata?.cwe ?? [];
  if (cweArray.length > 0) {
    return cweArray?.map((cwe: string) => {
      const cweContent = cwe.split(':');
      return {
        cweDescription: cweContent[1] ?? '',
        cweId: cweContent[0] ?? '',
      };
    });
  }
  return [];
};

export const getSASTSecurityFindingCweId = (
  semgrepRule: SemgrepRulesResource
): string[] => {
  return getSASTSecurityFindingCwe(semgrepRule).map((cwe) => cwe.cweId);
};

export const getFindingsLanguageDisplay = (values: Array<string>): string => {
  return values.map((value) => _capitalize(value)).join(', ');
};

/**
 * Given an Reference type and a value, determine whether they are
 * equivalent. Handle case-insensitive string values, and numeric enum values.
 */
export const isEqualReferenceType = (
  type: VulnReferenceType,
  value?: VulnReferenceType | number
): boolean => {
  if (_isNil(value)) {
    return false;
  }

  if (_isString(value)) {
    return (
      type.toLowerCase() === value.toLowerCase() ||
      type.toLowerCase().endsWith(value.toLowerCase())
    );
  }

  if (_isNumber(value)) {
    // is numeric reference type, compare enum values
    const refTypeValue = VulnReferenceTypeValues[type];
    return refTypeValue === value;
  }

  return false;
};

/**
 * Helper to access advisory urls
 */
export const getAllFindingSecurityAdvisoryUrls = (
  finding: FindingResource
): string[] => {
  const advisoryUrls = [];

  if (isSecurityFinding(finding)) {
    const references =
      getFindingVulnerabilityMetadata(finding)?.spec?.references;

    if (references && Array.isArray(references)) {
      for (const ref of references) {
        if (
          ref.url &&
          isEqualReferenceType(VulnReferenceType.Advisory, ref.type)
        ) {
          advisoryUrls.push(ref.url);
        }
      }
    }
  }

  return advisoryUrls;
};

export const getFirstFindingSecurityAdvisoryUrl = (
  finding: FindingResource
): string | undefined => {
  return getAllFindingSecurityAdvisoryUrls(finding)[0];
};

export const getFindingSecurityAdvisoryLabel = (
  finding: FindingResource
): string | undefined => {
  if (isSecurityFinding(finding)) {
    return getFindingVulnerabilityMetadata(finding)?.spec?.aliases?.[0];
  }
};

export const isCallgraphPresent = (finding: FindingResource): boolean => {
  return finding?.spec?.reachable_paths &&
    finding.spec.reachable_paths.length > 0
    ? true
    : false;
};

export const hasExceptions = (finding: FindingResource): boolean =>
  Boolean(
    finding.spec.exceptions?.policy_uuids &&
      (finding.spec.exceptions?.policy_uuids ?? []).length > 0
  );

export const getCWEURL = (cweId: string): string => {
  const number = cweId.split('-')?.[1];
  return number
    ? `https://cwe.mitre.org/data/definitions/${number}.html`
    : 'https://cwe.mitre.org/data/index.html';
};
