import { FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REGEX_EMAIL_VALIDATION } from '@endorlabs/ui-common';

import {
  ControlledTextField,
  ControlledTextFieldProps,
} from './ControlledTextField';

export const ControlledEmailField = <TFieldValues extends FieldValues>(
  props: ControlledTextFieldProps<TFieldValues>
) => {
  const { formatMessage: fm } = useIntl();

  const rules = {
    ...(props.rules ?? {}),
    pattern: {
      value: REGEX_EMAIL_VALIDATION,
      message: fm({
        defaultMessage: 'Must be a valid email address',
      }),
    },
  };

  return <ControlledTextField {...props} rules={rules} />;
};
