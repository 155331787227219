/* eslint sort-keys: "error" */
import { minutesToMilliseconds } from 'date-fns';
import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  Endorv1Vulnerability,
  QueryServiceApi,
  SpecEPSSScore,
  V1Context,
  V1Finding,
  V1FindingSpec,
  V1ListParameters,
  V1Meta,
  V1Project,
  V1Query,
  V1TenantMeta,
  V1VulnSpec,
} from '@endorlabs/api_client';
import {
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import {
  PackageVersionResource,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration, listAllResource } from './utils';

type FindingSpecFields = Extract<
  keyof V1FindingSpec,
  | 'approximation'
  | 'dismiss'
  | 'ecosystem'
  | 'exceptions'
  | 'explanation'
  | 'extra_key'
  | 'finding_categories'
  | 'finding_tags'
  | 'level'
  | 'project_uuid'
  | 'reachable_paths'
  | 'remediation'
  | 'summary'
  | 'target_dependency_name'
  | 'target_dependency_package_name'
  | 'target_dependency_version'
  | 'target_uuid'
  | 'proposed_version'
>;

type FindingSpecFindingMetadataVulnerabilityMetaFields = Extract<
  keyof V1Meta,
  'description'
>;

type FindingSpecFindingMetadataVulnerabilitySpecFields = Extract<
  keyof V1VulnSpec,
  | 'affected'
  | 'aliases'
  | 'cvss_v3_severity'
  | 'published'
  | 'references'
  | 'additional_notes'
>;

type SpecEPSSScoreType = Extract<
  keyof SpecEPSSScore,
  'probability_score' | 'percentile_score'
>;

type Endorv1VulnerabilityType = Extract<
  keyof Endorv1Vulnerability,
  'cve_id' | 'cwe'
>;

// enforce consistent types and values
const QUERY_FINDINGS_FIELD_MASK: Record<
  | Exclude<keyof V1Finding, 'spec'>
  | `spec.${FindingSpecFields}`
  | `spec.finding_metadata.vulnerability.meta.${FindingSpecFindingMetadataVulnerabilityMetaFields}`
  | `spec.finding_metadata.vulnerability.spec.${FindingSpecFindingMetadataVulnerabilitySpecFields}`
  | `spec.finding_metadata.vulnerability.spec.epss_score.${SpecEPSSScoreType}`
  | `spec.finding_metadata.vulnerability.spec.raw.endor_vulnerability.${Endorv1VulnerabilityType}`,
  true
> = {
  context: true,
  meta: true,
  'spec.approximation': true,
  'spec.dismiss': true,
  'spec.ecosystem': true,
  'spec.exceptions': true,
  'spec.explanation': true,
  'spec.extra_key': true,
  'spec.finding_categories': true,
  'spec.finding_metadata.vulnerability.meta.description': true,
  'spec.finding_metadata.vulnerability.spec.additional_notes': true,
  'spec.finding_metadata.vulnerability.spec.affected': true,
  'spec.finding_metadata.vulnerability.spec.aliases': true,
  'spec.finding_metadata.vulnerability.spec.cvss_v3_severity': true,
  'spec.finding_metadata.vulnerability.spec.epss_score.percentile_score': true,
  'spec.finding_metadata.vulnerability.spec.epss_score.probability_score': true,
  'spec.finding_metadata.vulnerability.spec.published': true,
  'spec.finding_metadata.vulnerability.spec.raw.endor_vulnerability.cve_id':
    true,
  'spec.finding_metadata.vulnerability.spec.raw.endor_vulnerability.cwe': true,
  'spec.finding_metadata.vulnerability.spec.references': true,
  'spec.finding_tags': true,
  'spec.level': true,
  'spec.project_uuid': true,
  'spec.proposed_version': true,
  'spec.reachable_paths': true,
  'spec.remediation': true,
  'spec.summary': true,
  'spec.target_dependency_name': true,
  'spec.target_dependency_package_name': true,
  'spec.target_dependency_version': true,
  'spec.target_uuid': true,
  tenant_meta: true,
  uuid: true,
};

// HACK: overriding typings for `key.*` fields, to allow derived typings from the query data
export type QueryFindingsFields =
  | Exclude<
      keyof typeof QUERY_FINDINGS_FIELD_MASK,
      'context' | 'meta' | 'tenant_meta'
    >
  | `context.${keyof V1Context}`
  | `meta.${Exclude<keyof V1Meta, 'references'>}`
  // HACK
  | `meta.references.PackageVersion.list.objects.0.uuid`
  | `meta.references.PackageVersion.list.objects.0.meta.name`
  | `meta.references.Project.list.objects.0.uuid`
  | `meta.references.Project.list.objects.0.meta.name`
  | `tenant_meta.${keyof V1TenantMeta}`;

export type QueryFindingsResponse = TResourceList<QueryFindingsResponseObject>;

export type QueryFindingsResponseObject = SelectFrom<
  V1Finding,
  'context' | 'tenant_meta' | 'uuid',
  {
    meta: SelectFrom<
      V1Meta,
      keyof V1Meta,
      {
        references: {
          PackageVersion?: TResourceList<
            SelectFrom<
              PackageVersionResource,
              'uuid' | 'tenant_meta',
              {
                meta: SelectFrom<PackageVersionResource['meta'], 'name'>;
                spec: SelectFrom<
                  PackageVersionResource['spec'],
                  'relative_path' | 'source_code_reference'
                >;
              }
            >
          >;
          Project?: TResourceList<
            SelectFrom<
              V1Project,
              'uuid' | 'tenant_meta',
              { meta: SelectFrom<V1Meta, 'name'> }
            >
          >;
        };
      }
    >;
    spec: SelectFrom<
      V1FindingSpec,
      FindingSpecFields,
      {
        finding_metadata?: {
          vulnerability?: {
            meta: SelectFrom<
              V1Meta,
              FindingSpecFindingMetadataVulnerabilityMetaFields
            >;
            spec?: SelectFrom<
              V1VulnSpec,
              FindingSpecFindingMetadataVulnerabilitySpecFields
            >;
          };
        };
        // TODO: fix SelectFrom to not require explicilty typing omitted fields
        extra_key: never;
        last_processed: never;
        metadata: never;
        project_uuid: never;
      }
    >;
  }
>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'findings', namespace, listParams] as const,
  queryAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'findings-all', namespace, listParams] as const,
};
export const QueryFindingsQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const queryFindings = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryFindingsResponse;
};

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  return {
    meta: {
      name: `QueryFindings(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Finding',
        list_parameters: {
          ...rootListParams,
          mask: Object.keys(QUERY_FINDINGS_FIELD_MASK).join(','),
        },
        references: [
          {
            connect_from: 'meta.parent_uuid',
            connect_to: 'uuid',
            query_spec: {
              kind: 'PackageVersion',
              list_parameters: {
                ...commonListParameters,
                mask: [
                  'uuid',
                  'meta.name',
                  'spec.relative_path',
                  'spec.source_code_reference.version',
                  'tenant_meta.namespace',
                ].join(','),
                page_size: 1,
              },
            },
          },
          {
            connect_from: 'spec.project_uuid',
            connect_to: 'uuid',
            query_spec: {
              kind: 'Project',
              list_parameters: {
                ...commonListParameters,
                mask: ['uuid', 'meta.name', 'tenant_meta.namespace'].join(','),
                page_size: 1,
              },
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

/**
 * Custom query for Findings
 *
 * When `includeReferences === true` including related PackageVersions & Projects
 */
export const useQueryFindings = (
  namespace: string,
  listParams: Omit<ListRequestParameters, 'mask'>,
  queryOpts: ResourceQueryOptions<QueryFindingsResponse> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Finding',
    'LIST',
    listParams,
    queryOpts
  );

  return useQuery(
    QK.query(namespace, requestParameters),
    ({ signal }) => queryFindings(namespace, requestParameters, signal),
    { staleTime: QUERY_STALE_TIME, ...queryOpts }
  );
};

/**
 * Exhaustive Query for Findings matching the given filter
 */
export const useQueryAllFindings = (
  namespace: string,
  listParams: Omit<ListAllRequestParameters, 'mask'>,
  queryOptions: Pick<
    ResourceQueryOptions<QueryFindingsResponse>,
    'enabled'
  > = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Finding',
    'LIST_ALL',
    listParams,
    queryOptions
  );

  return useQuery(
    QK.queryAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<QueryFindingsResponseObject, QueryFindingsResponse>(
        (pageToken) => {
          const pageListParams: V1ListParameters = {
            // take base params, and add the page param
            ...requestParameters,
            page_token: pageToken,
          };

          return queryFindings(namespace, pageListParams, ctx.signal);
        },
        { signal: ctx.signal }
      ),
    { ...queryOptions }
  );
};
