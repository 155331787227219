import { has as _has, isObject as _isObject } from 'lodash-es';

import { DemoResource, GitHubWorkflowJob } from './types';

/**
 * Given a Demo Resource, check if it should be considered 'Completed'
 */
export const isCompletedDemo = (v: DemoResource): boolean => {
  const jobStatus = v.spec.job_status;

  // if the embedded job status
  if (!isGitHubWorkflowJob(jobStatus)) return false;
  if (jobStatus.status !== 'completed') return false;

  return jobStatus.steps?.every((s) => s.status === 'completed') ?? false;
};

/**
 * Duck type a potential GitHub Workflow Job object
 */
export const isGitHubWorkflowJob = (v: unknown): v is GitHubWorkflowJob => {
  if (!v || !_isObject(v)) return false;

  const hasStatusProperty = _has(v, ['status']);
  const hasStepsProperty =
    _has(v, ['steps']) &&
    Array.isArray((v as { steps: unknown }).steps) &&
    (v as { steps: unknown[] }).steps.every((s) => _has(s, ['status']));

  return hasStatusProperty && hasStepsProperty;
};
