import { defineMessages, MessageDescriptor } from 'react-intl';

import { V1ScanState as ScanState } from '@endorlabs/api_client';

export const ScanStateMessages: Record<ScanState, MessageDescriptor> =
  defineMessages({
    [ScanState.Analytic]: { defaultMessage: 'Analytic' },
    [ScanState.Idle]: { defaultMessage: 'Idle' },
    [ScanState.Ingesting]: { defaultMessage: 'Ingesting' },
    [ScanState.NotProcessed]: { defaultMessage: 'Not Processed' },
    [ScanState.Unreachable]: { defaultMessage: 'Unreachable' },
    [ScanState.RequestFullRescan]: { defaultMessage: 'Request Full Rescan'},
    [ScanState.RequestIncrementalRescan]: { defaultMessage: 'Request Incremental Rescan'},
    [ScanState.Unspecified]: { defaultMessage: 'Unspecified' },
  });
