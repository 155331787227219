import { FormattedMessage as FM } from 'react-intl';

import { V1SystemRole } from '@endorlabs/api_client';
import {
  AuthorizationPolicyIdentityClaim,
  AuthorizationPolicyIdentitySourceType,
} from '@endorlabs/endor-core/AuthorizationPolicy';

import { PermissionRoleLabel } from '../PermissionRoleLabel';

export const DEFAULT_IDENTITY_CLAIMS: AuthorizationPolicyIdentityClaim[] = [
  { prefix: 'user', value: '' },
];

export const DEFAULT_IDENTITY_SOURCE_OPTIONS = [
  {
    label: <FM defaultMessage="GitHub" />,
    value: AuthorizationPolicyIdentitySourceType.GitHub,
  },
  // NOTE: removed as auth policy otion for https://endorlabs.atlassian.net/browse/UI-114.
  // May be re-enabled in the future.
  // {
  //   label: <FM defaultMessage="Azure" />,
  //   value: AuthorizationPolicyIdentitySourceType.Azure,
  // },
  {
    label: <FM defaultMessage="GitLab" />,
    value: AuthorizationPolicyIdentitySourceType.GitLab,
  },
  {
    label: <FM defaultMessage="Google" />,
    value: AuthorizationPolicyIdentitySourceType.Google,
  },
  {
    label: <FM defaultMessage="Google Cloud" />,
    value: AuthorizationPolicyIdentitySourceType.GCP,
  },
  {
    label: <FM defaultMessage="GitHub Action OIDC" />,
    value: AuthorizationPolicyIdentitySourceType.GitHubAction,
  },
  {
    label: <FM defaultMessage="AWS Role" />,
    value: AuthorizationPolicyIdentitySourceType.AWS,
  },
  {
    label: <FM defaultMessage="Email" />,
    value: AuthorizationPolicyIdentitySourceType.Endor,
  },
];

export const DEFAULT_PERMISSION_ROLES_OPTIONS = [
  {
    label: <PermissionRoleLabel role={V1SystemRole.Admin} />,
    value: V1SystemRole.Admin,
  },
  {
    label: <PermissionRoleLabel role={V1SystemRole.ReadOnly} />,
    value: V1SystemRole.ReadOnly,
  },
  {
    label: <PermissionRoleLabel role={V1SystemRole.CodeScanner} />,
    value: V1SystemRole.CodeScanner,
  },
  {
    label: <PermissionRoleLabel role={V1SystemRole.PolicyEditor} />,
    value: V1SystemRole.PolicyEditor,
  },
];
