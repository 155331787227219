import {
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { MouseEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FilterExpression } from '@endorlabs/filters';
import { IconCode } from '@endorlabs/ui-common';

import { FilterBarMode } from '../types';
import {
  FilterBarSavedFilters,
  FilterBarSavedFiltersProps,
} from './FilterBarSavedFilters';

export interface FilterBarHeaderProps
  extends Pick<
    FilterBarSavedFiltersProps,
    'onCreateSavedFilter' | 'onSavedFilterSelect' | 'savedFilters'
  > {
  enableAdvanced: boolean;
  enableSavedFilters: boolean;
  filter?: FilterExpression;
  isParseable: boolean;
  mode: FilterBarMode;
  onClear: () => void;
  onModeChange: (mode: FilterBarMode) => void;
}

export const FilterBarHeader = ({
  enableAdvanced,
  enableSavedFilters,
  isParseable,
  filter,
  mode,
  onClear,
  onCreateSavedFilter,
  onModeChange,
  onSavedFilterSelect,
  savedFilters,
}: FilterBarHeaderProps) => {
  const handleModeChange = (_: MouseEvent, mode?: FilterBarMode) => {
    if (!mode) return;

    // if Filter is not parseable, clear before switching from advanced mode
    if (!isParseable && mode === FilterBarMode.Basic) {
      onClear();
    }

    onModeChange(mode);
  };

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      marginBottom={4}
      rowGap={2}
      width="100%"
    >
      {enableAdvanced && (
        <Tooltip
          title={
            isParseable ? null : (
              <FM defaultMessage="Filter has been edited in Advanced mode. Switching to Basic mode will reset the Filter." />
            )
          }
        >
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleModeChange}
            value={mode}
          >
            <ToggleButton size="small" value={FilterBarMode.Basic}>
              <Typography component="span" variant="body2">
                <FM defaultMessage="Basic Filters" />
              </Typography>
            </ToggleButton>

            <ToggleButton size="small" value={FilterBarMode.Advanced}>
              <IconCode sx={{ marginRight: 1 }} />
              <Typography component="span" variant="body2">
                <FM defaultMessage="Advanced" />
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
      )}

      {enableSavedFilters && (
        <Box flexGrow={1} sx={{ display: 'flex', justifyContent: 'end' }}>
          <FilterBarSavedFilters
            filter={filter}
            onCreateSavedFilter={onCreateSavedFilter}
            onSavedFilterSelect={onSavedFilterSelect}
            savedFilters={savedFilters}
          />
        </Box>
      )}
    </Stack>
  );
};
