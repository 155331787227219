import { defineMessages } from 'react-intl';

import { OnboardingStepIds } from './constants';

// FIXME: Move to domains
export const OnboardingStepMessages = {
  [OnboardingStepIds.ONBOARD_ADD_PROJECT]: defineMessages({
    title: { defaultMessage: 'Scan a Project' },
  }),

  [OnboardingStepIds.ONBOARD_VIEW_FINDINGS]: defineMessages({
    title: { defaultMessage: 'View and Filter Findings' },
  }),

  [OnboardingStepIds.ONBOARD_INVITE_TEAM]: defineMessages({
    title: { defaultMessage: 'Invite Your Team' },
  }),
};
