import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'SBOM Hub',
});

export const Route = createFileRoute('/t/:namespace/third-party/sboms')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
