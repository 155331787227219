import { last as _last, partition as _partition } from 'lodash-es';

import {
  AuthorizationPolicyIdentityClaim,
  AuthorizationPolicyIdentitySource,
  AuthorizationPolicyIdentitySourceType,
  AuthorizationPolicyResource,
} from '../types';
import { isAuthorizationPolicyIdentitySourceType } from './logic';

const parseAuthorizationPolicyClaim = (
  claim: string
): AuthorizationPolicyIdentityClaim & {
  sourceType?: AuthorizationPolicyIdentitySourceType;
} => {
  const delimiterIx = claim.indexOf('=');

  const prefix = claim.slice(0, delimiterIx);
  const value = claim.slice(delimiterIx + 1);

  // Parse claim with source suffix (e.x. user@github, user@example.org@google)
  if (value.includes('@')) {
    const parsedValue = value.split('@');
    let sourceType: AuthorizationPolicyIdentitySourceType | undefined =
      undefined;

    // If the claim includes a source type,
    const lastValue = _last(parsedValue);
    if (isAuthorizationPolicyIdentitySourceType(lastValue)) {
      sourceType = lastValue;
      parsedValue.pop();
    }

    const cleanValue = parsedValue.slice(0, 2).join('@');

    return { prefix, sourceType, value: cleanValue };
  }

  // Default to returning the prefix and value
  return { prefix, value };
};

export const parseAuthorizationPolicyClause = (
  authPolicy: AuthorizationPolicyResource
): {
  claims: AuthorizationPolicyIdentityClaim[];
  source: AuthorizationPolicyIdentitySource | undefined;
} => {
  const claims: AuthorizationPolicyIdentityClaim[] = [];
  let source: AuthorizationPolicyIdentitySource | undefined = undefined;

  const [rawClaims, nonClaims] = _partition(authPolicy.spec.clause, (c) =>
    c.includes('=')
  );

  for (const value of nonClaims) {
    // Handle: Identity Provider
    if (authPolicy.meta.parent_kind && authPolicy.meta.parent_uuid === value) {
      source = {
        type: authPolicy.meta
          .parent_kind as AuthorizationPolicyIdentitySourceType,
        value,
      };
      break;
    }

    // Handle: Auth Provider included as standalone in clause
    if (isAuthorizationPolicyIdentitySourceType(value)) {
      source = { type: value, value };
      break;
    }

    // Handle: default to a standalone value in claim as source
    if (!source) {
      source = { type: AuthorizationPolicyIdentitySourceType.Unknown, value };
    }
  }

  // Parse claims in clause
  for (const claim of rawClaims) {
    const { prefix, value, sourceType } = parseAuthorizationPolicyClaim(claim);

    if (!source && sourceType) {
      source = { type: sourceType, value: sourceType };
    }

    claims.push({ prefix, value });
  }

  return { claims, source };
};
