import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FileUploadProps, FileUploadStatus } from '../../hooks';
import { IconCheckCircle, IconUploadCloud } from '../../themes';

type FileUploadBoxProps = Pick<
  FileUploadProps,
  'dragAndDropProps' | 'fileData' | 'inputProps' | 'readError'
> & {
  titleText?: ReactNode;
  uploadError?: string;
  uploadStatus?: FileUploadStatus;
};

export const FileUploadBox = ({
  dragAndDropProps,
  fileData,
  inputProps,
  readError,
  uploadError,
  uploadStatus = FileUploadStatus.IDLE,
  titleText,
}: FileUploadBoxProps) => {
  const theme = useTheme();

  const isError = readError || uploadError;

  return (
    <Stack>
      <Box
        onDragOver={dragAndDropProps.handleDragOver}
        onDragLeave={dragAndDropProps.handleDragLeave}
        onDrop={dragAndDropProps.handleDrop}
        sx={{
          backgroundColor: theme.palette.background.default,
          border: isError
            ? `2px dashed ${theme.palette.error.dark}`
            : `2px dashed ${theme.palette.background.dark}`,
          padding: theme.space.md,
          textAlign: 'center',
        }}
      >
        {fileData && uploadStatus === FileUploadStatus.LOADED && (
          <>
            <IconCheckCircle color="success" />
            <Typography variant="body2" color="textSecondary" mt={1}>
              <FM
                defaultMessage="{fileName} Uploaded."
                values={{ fileName: fileData ? fileData.name : 'File' }}
              />
            </Typography>
          </>
        )}
        {fileData && uploadStatus === FileUploadStatus.LOADING && (
          <>
            <CircularProgress />
            <Typography variant="body2" color="textSecondary" mt={1}>
              <FM
                defaultMessage="Uploading {fileName}"
                values={{
                  fileName: fileData ? <b>{fileData.name}</b> : 'File',
                }}
              />
            </Typography>
          </>
        )}
        {(!fileData || isError || uploadStatus === FileUploadStatus.IDLE) && (
          <Stack spacing={theme.space.xs}>
            <input
              type="file"
              style={{ display: 'none' }}
              id="file-input"
              {...inputProps}
            />
            <Typography variant="body1" paddingBottom={theme.space.xs}>
              {titleText}
            </Typography>
            <label htmlFor="file-input">
              <Button
                component="span"
                startIcon={<IconUploadCloud />}
                variant="outlined"
              >
                <FM defaultMessage="Browse" />
              </Button>
            </label>
            <Typography variant="body2" color="textSecondary" mt={1}>
              {dragAndDropProps.isDragging ? (
                <FM defaultMessage="Drop the file here" />
              ) : (
                <FM defaultMessage="Drag and drop a file or click to browse" />
              )}
            </Typography>
          </Stack>
        )}
      </Box>
      {readError && (
        <Typography variant="body2" color={theme.palette.error.main} mt={1}>
          <FM
            defaultMessage="{error}"
            values={{
              error: readError,
            }}
          />
        </Typography>
      )}
      {uploadStatus === FileUploadStatus.ERROR && (
        <Typography variant="body2" color={theme.palette.error.main} mt={1}>
          <FM
            defaultMessage="{error}"
            values={{
              error: uploadError ?? 'An error occured while uploading the file',
            }}
          />
        </Typography>
      )}
    </Stack>
  );
};
