import { FILTER_COMPARATORS, isValueFilter } from '@endorlabs/filters';

import { FilterFieldConfig } from '../../../filters';
import {
  FindingContainerLayersFilterField,
  FindingContainerLayersFilterOption,
} from '../../components/FindingContainerLayersFilterField';

export const FindingContainerLayersFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.finding_metadata.container_data.has_base_layer',

  format: (value) => {
    if (value === FindingContainerLayersFilterOption.Base) {
      return {
        key: 'spec.finding_metadata.container_data.has_base_layer',
        comparator: FILTER_COMPARATORS.EQUAL,
        value: true,
      };
    }

    if (value === FindingContainerLayersFilterOption.Application) {
      return {
        key: 'spec.finding_metadata.container_data.has_base_layer',
        comparator: FILTER_COMPARATORS.EQUAL,
        value: false,
      };
    }

    return undefined;
  },

  parse: (filter) => {
    if (
      isValueFilter(filter) &&
      filter.key === 'spec.finding_metadata.container_data.has_base_layer' &&
      filter.comparator === FILTER_COMPARATORS.EQUAL
    ) {
      return filter.value
        ? FindingContainerLayersFilterOption.Base
        : FindingContainerLayersFilterOption.Application;
    }
  },

  renderInput: ({ onChange, value }) => (
    <FindingContainerLayersFilterField onChange={onChange} value={value} />
  ),
} satisfies FilterFieldConfig<FindingContainerLayersFilterOption>;
