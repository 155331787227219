import { CircularProgress, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListFindings, useQueryProjectFindings } from '@endorlabs/queries';
import {
  BarChartStackedHorizontal,
  Color,
  EmptyState,
  TitleActionHeader,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { FindingLevelToggles } from '../../components';
import { useAuthInfo } from '../../providers';
import { getProjectPath } from '../../routes';

const specLevelMap: Record<string, string> = {
  [SpecFindingLevel.Critical]: 'Critical',
  [SpecFindingLevel.High]: 'High',
  [SpecFindingLevel.Medium]: 'Medium',
  [SpecFindingLevel.Low]: 'Low',
};

export const ProjectFindingsVis = ({
  height,
}: {
  height: number;
  fontSize?: number;
}) => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const theme = useTheme();

  const navigate = useNavigate();

  const [filterList, setFilterList] = useState<SpecFindingLevel[]>([
    SpecFindingLevel.Critical,
    SpecFindingLevel.High,
  ]);

  const updateFilterList = (list: SpecFindingLevel[]) => {
    setFilterList(list);
  };

  const findingLevelsFilter = useMemo(
    () => `spec.level in [${filterList}]`,
    [filterList]
  );

  const qResourceFinding = useListFindings(tenantName, undefined, {
    filter: [
      findingLevelsFilter,
      filterExpressionBuilders.mainResourceContext(),
    ].join(' and '),
    group: {
      aggregation_paths: 'spec.project_uuid',
      unique_value_paths: 'spec.project_uuid',
    },
  });

  const projectsFilter = useMemo(() => {
    const topFindingsGroupData = Object.values(
      qResourceFinding.data?.group_response?.groups ?? {}
    )
      .sort(
        (a, b) =>
          (b.aggregation_count?.count ?? 0) - (a.aggregation_count?.count ?? 0)
      )
      .slice(0, 10);

    const projectUuids: string[] = [];
    for (const group of topFindingsGroupData) {
      const projectUuid = group.unique_values?.['spec.project_uuid']?.[0];

      if (projectUuid) {
        projectUuids.push(projectUuid);
      }
    }

    // ensure uuids in stable order for filter
    projectUuids.sort();

    return `uuid in [${projectUuids}]`;
  }, [qResourceFinding.data?.group_response?.groups]);

  const qProjects = useQueryProjectFindings(
    tenantName,
    { enabled: qResourceFinding.isSuccess },
    { filter: projectsFilter }
  );

  const chartData = useMemo(() => {
    const projectObjects = qProjects?.data?.list?.objects;
    const chartData: Record<string, string | number>[] = [];
    if (projectObjects) {
      projectObjects.forEach(({ uuid, meta, spec }) => {
        const findingObj: Record<string, number> = {
          Critical: 0,
          High: 0,
          Medium: 0,
          Low: 0,
        };

        const findingGroups = Object.values(
          meta.references.FindingsCount?.group_response?.groups ?? {}
        );

        findingGroups.forEach((item) => {
          const specLevel: string = item.unique_values
            ? String(item.unique_values['spec.level'][0])
            : '';
          if (filterList.includes(specLevel as SpecFindingLevel)) {
            findingObj[specLevelMap[specLevel] as string] =
              item.aggregation_count?.count ?? 0;
          }
        });

        chartData.push({
          name: String(
            UIProjectUtils.parseProjectName(meta.name, spec.platform_source) ??
              meta.name
          ),
          url: getProjectPath({ tenantName, uuid: uuid }),
          ...findingObj,
        });
      });
    }
    return chartData;
  }, [filterList, qProjects?.data?.list?.objects, tenantName]);

  const handleProjectClick = (data: Record<string, string | number>) => {
    navigate({ to: data.url });
  };

  const isLoading = qResourceFinding.isLoading || qProjects.isLoading;
  const isSuccess = qProjects.isSuccess;
  const isEmptyState = !isLoading && isSuccess && chartData.length === 0;

  return (
    <div style={{ position: 'relative' }} data-testid="project-findings">
      <Stack spacing={theme.space.sm}>
        <TitleActionHeader
          action={
            <FindingLevelToggles
              value={filterList}
              onChange={updateFilterList}
            />
          }
          title={<FM defaultMessage="Top Riskiest Projects" />}
          variant="h2"
        />

        <Stack height={height} justifyContent="center" alignItems="center">
          {isLoading && <CircularProgress />}
          {isEmptyState && (
            <EmptyState
              size="medium"
              imageWidth={300}
              textAlign="center"
              title={
                <FM defaultMessage="No projects meet the filter criteria" />
              }
            />
          )}
          {!isLoading && !isEmptyState && (
            <ParentSize debounceTime={100}>
              {({ width: visWidth, height: visHeight }) => (
                <BarChartStackedHorizontal
                  data={chartData?.sort((a, b) => {
                    const aTotal =
                      Number(a.Critical) +
                      Number(a.High) +
                      Number(a.Medium) +
                      Number(a.Low);
                    const bTotal =
                      Number(b.Critical) +
                      Number(b.High) +
                      Number(b.Medium) +
                      Number(b.Low);
                    return bTotal - aTotal;
                  })}
                  width={visWidth}
                  height={visHeight}
                  xKeys={['Critical', 'High', 'Medium', 'Low']}
                  yKey="name"
                  axisLeft={false}
                  axisBottom={false}
                  barPadding={0.4}
                  showBarTitles={true}
                  barTitleFontSize={10}
                  showGrid={false}
                  barColors={
                    [
                      theme.palette.severity.critical,
                      theme.palette.severity.high,
                      theme.palette.severity.medium,
                      theme.palette.severity.low,
                    ] as Color[]
                  }
                  margin={{ top: 4, left: 0, bottom: 4, right: 40 }}
                  onClickHandler={handleProjectClick}
                />
              )}
            </ParentSize>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
