import { defineMessage } from 'react-intl';

import { TenantsCreatePage } from '../../views/Tenants/TenantCreatePage';
import { NamedRoutes } from '../constants';
import { createFileRoute } from '../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Create Tenant' });

export const Route = createFileRoute(NamedRoutes.TENANT_CREATE + '/')({
  component: TenantsCreatePage,
  beforeLoad: () => {
    return { title: ROUTE_TITLE };
  },
});
