import { Components, Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { createElement } from 'react';

import {
  IconCheckSquare,
  IconMinusSquare,
  IconSquare,
} from '../icons/FeatherIcons';

type CheckboxComponentOverrides = Pick<Components<Theme>, 'MuiCheckbox'>;

export const getCheckboxOverrides = ({
  palette,
  spacing,
}: Theme): CheckboxComponentOverrides => {
  const iconChecked = createElement(IconCheckSquare);
  const iconIndeterminate = createElement(IconMinusSquare);
  const iconEmpty = createElement(IconSquare);

  return {
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: iconChecked,
        icon: iconEmpty,
        indeterminateIcon: iconIndeterminate,
        size: 'medium',
      },

      styleOverrides: {
        root: {
          color: palette.grey[400],
          height: spacing(4),
          marginRight: spacing(2),
          padding: 0,
          width: spacing(4),

          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            backgroundColor: alpha(palette.success.light, 0.3),
            borderRadius: '1px',
            color: palette.primary.main,
          },
        },
      },
    },
  };
};
