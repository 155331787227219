import { Alert, Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import { V1Invitation } from '@endorlabs/api_client';
import { IQueryErrorResponse, useCreateInvitation } from '@endorlabs/queries';
import { ButtonPrimary, ButtonSecondary } from '@endorlabs/ui-common';

import { AuthProviders } from '../../domains/Auth';
import { FormNewInvitation, InvitationRef } from '../../domains/Invitation';
import { useOnboardingSteps } from '../../domains/Onboarding';
import { useAuthInfo } from '../../providers';
import { getAccessRootPath } from '../../routes';
import { OnboardingStepContentProps } from './types';

export const InviteTeamChildren = ({
  stepRecord,
}: OnboardingStepContentProps) => {
  const { space } = useTheme();
  const { completeStep } = useOnboardingSteps();

  const ref = useRef<InvitationRef | null>(null);
  const { activeNamespace: namespace } = useAuthInfo();
  const names: string[] = [];
  const [invitationSent, setInvitationSent] = useState(false);
  const [serverError, setServerError] = useState<
    IQueryErrorResponse | undefined
  >(undefined);

  for (const provider in AuthProviders) {
    names.push(AuthProviders[provider].displayName);
  }

  const qInvitationCreate = useCreateInvitation({
    onSuccess: (resp: V1Invitation) => {
      setInvitationSent(true);
      completeStep(stepRecord.id);
    },
    onError: (error) => {
      setServerError(error.response);
    },
  });

  const onSubmit = (formData: FieldValues) => {
    const emails = formData.emails.split(',').map((v: string) => v.trim());
    emails.forEach((email: string) => {
      qInvitationCreate.mutate({
        namespace,
        resource: {
          meta: { name: `${email} auth for ${namespace}` },
          spec: {
            user_email: email,
          },
        },
      });
    });
  };

  const handleFormSubmit = () => {
    // Trigger form submission from the parent component
    ref?.current?.submitForm();
  };

  const url = `${getAccessRootPath({ tenantName: namespace })}/invitations`;

  return (
    <Stack spacing={space.md}>
      <Typography color="textSecondary">
        <FM defaultMessage="Give colleagues direct access to your trial. " />
      </Typography>

      {invitationSent ? (
        <Alert severity="success">
          <Stack direction="row">
            <Typography color="primary">
              <FM defaultMessage="Invitation sent successfully." />
            </Typography>
            <Typography fontWeight={500}>
              <FM
                defaultMessage="<a>View Invitations</a>"
                values={{
                  a: (v) => <Link href={url}>{v}</Link>,
                }}
              />
            </Typography>
          </Stack>
        </Alert>
      ) : (
        <>
          <FormNewInvitation
            helperText={
              <FM
                defaultMessage="Invitations must be sent to the primary email address associated with the login provider. We support {provider}."
                values={{
                  provider: <FormattedList type="conjunction" value={names} />,
                }}
              />
            }
            onSubmit={onSubmit}
            serverErrorResponse={serverError}
            ref={ref}
            showSubmitButton={false}
          />
        </>
      )}

      <Box>
        {invitationSent ? (
          <ButtonSecondary onClick={() => setInvitationSent(false)}>
            <FM defaultMessage="Invite More Users" />
          </ButtonSecondary>
        ) : (
          <ButtonPrimary onClick={handleFormSubmit}>
            <FM defaultMessage="Invite Users" />
          </ButtonPrimary>
        )}
      </Box>
    </Stack>
  );
};
