import { Button, ButtonProps, darken, useTheme } from '@mui/material';

import { IconExternalLink } from '@endorlabs/ui-common';

import { Link, LinkProps } from '../Link';

export interface ButtonExternalLinkProps
  extends Omit<ButtonProps, 'href' | 'endIcon'> {
  linkProps: Pick<LinkProps, 'to'>;
}

/**
 * Button with an external link props.
 */

export const ButtonExternalLink = ({
  linkProps,
  ...props
}: ButtonExternalLinkProps) => {
  const theme = useTheme();
  const externalLinkProps = {
    ...linkProps,
    target: '_blank',
    /**
     * ensure `window.opener` is not passed with target="_blank"
     * @link https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/
     */
    rel: 'noopener noreferrer nofollow',
  };

  return (
    <Button
      {...props}
      {...externalLinkProps}
      LinkComponent={Link}
      endIcon={<IconExternalLink fontSize="inherit" />}
      variant="contained"
      sx={{
        backgroundColor: theme.palette.text.link,
        '&:hover': {
          backgroundColor: darken(theme.palette.text.link ?? '', 0.6),
        },
      }}
    />
  );
};
