import { QueryKey, useQueries, useQuery } from 'react-query';

import {
  UserInfoServiceApi,
  V1ListParameters,
  V1UserInfoResponse,
} from '@endorlabs/api_client';

import { ResourceQueryOptions } from './types';
import { TenantInfo } from './useSession';
import { getClientConfiguration } from './utils';

type GetActivePermissionOptions = ResourceQueryOptions<V1UserInfoResponse>;
type ListActivePermissionOptions = ResourceQueryOptions<V1UserInfoResponse>;

const BASE_KEY = 'v1/auth';
const QK = {
  record: (namespace?: string): QueryKey => [BASE_KEY, namespace],
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
};
export const AuthenticationQueryKeys = QK;

const getApiService = () => new UserInfoServiceApi(getClientConfiguration());

const getActivePermissions = async (
  namespace: string,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.userInfoServiceGetActivePermissions(namespace, {
    signal,
  });
  return resp.data as V1UserInfoResponse;
};

export const useActivePermissions = (
  namespace: string,
  options: GetActivePermissionOptions = {}
) => {
  return useQuery(
    QK.record(namespace),
    ({ signal }) => getActivePermissions(namespace, signal),
    options
  );
};

export const useListActivePermissions = (
  namespaces: TenantInfo[],
  options: ListActivePermissionOptions = {}
) => {
  const queries = namespaces.map((namespace) => {
    return {
      queryKey: QK.list(namespace.name, {}),
      queryFn: () => getActivePermissions(namespace.name),
      ...options,
    };
  });
  const results = useQueries(queries);

  //Add queryKey to get tenant name in response
  const response = results.map((result, index) => ({
    ...result,
    queryKey: queries[index].queryKey,
  }));

  return response;
};
