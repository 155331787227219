import { Stack, Theme, Typography } from '@mui/material';
import { alpha } from '@mui/system';

import { useStyles } from '../../hooks';
import { IconCode } from '../../themes';

type DisplayType = 'color' | 'grayscale';

export const InlineCodeDisplay = ({
  children,
  display = 'grayscale',
  showIcon = false,
}: {
  children?: string[];
  display?: DisplayType;
  showIcon?: boolean;
}) => {
  const boxSx = useStyles(boxStyles, { display });
  const listSx = useStyles(listStyles);
  return (
    <Stack sx={boxSx}>
      {children?.map((child, index) => (
        <Stack key={`${child}-${index}`} direction="row" sx={listSx}>
          {showIcon && <IconCode />}
          <Typography fontSize="small" variant="code">
            {child}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

const boxStyles = (
  { palette, space }: Theme,
  options?: { display: DisplayType }
) => ({
  backgroundColor:
    options?.display === 'color'
      ? alpha(palette.info.light, 0.2)
      : palette.grey[200],
  borderRadius: '4px',
  gap: space.xs,
  maxWidth: 'max-content',
  overflowWrap: 'anywhere',
  padding: space.xs,
});

const listStyles = ({ space }: Theme) => ({
  gap: space.sm,
});
