import { AUTHORIZATION_POLICY_IDENTITY_SOURCE_TYPES } from '../constants';
import { AuthorizationPolicyIdentitySourceType } from '../types';

export const isAuthorizationPolicyIdentitySourceType = (
  v: unknown
): v is AuthorizationPolicyIdentitySourceType => {
  return (
    !!v && AUTHORIZATION_POLICY_IDENTITY_SOURCE_TYPES.some((type) => type === v)
  );
};
