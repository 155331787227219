import { Stack, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  getSecurityFindingCvssScore,
  getSecurityFindingFixVersion,
} from '@endorlabs/endor-core/Finding';
import { AttributeDisplayStack, EPSSDisplay, Link } from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { FindingDetailDrawerSectionProps } from '../types';
import { FindingFixAvailableDisplay } from './FindingFixAvailableDisplay';

const URL_EPSS_DOCS =
  'https://www.first.org/epss/articles/prob_percentile_bins';

const genResource = ({ finding }: FindingDetailDrawerSectionProps) => {
  const vuln = finding?.spec.finding_metadata?.vulnerability?.spec;

  const osvScores = finding ? getSecurityFindingCvssScore(finding) : undefined;

  const resource = {
    epss: (
      <EPSSDisplay
        percentile={vuln?.epss_score?.percentile_score}
        probability={vuln?.epss_score?.probability_score}
      />
    ),
    osv_base_score: osvScores?.baseScore,
    osv_score_type: osvScores?.scoreType,
    vuln_fix_version: (
      <FindingFixAvailableDisplay
        fixVersion={finding ? getSecurityFindingFixVersion(finding) : undefined}
      />
    ),
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'vuln_fix_version',
      heading: <FM defaultMessage="Fix Available" />,
    },
    {
      attributeKey: 'osv_base_score',
      heading: <FM defaultMessage="Base CVSS" />,
    },
    {
      attributeKey: 'epss',
      heading: <FM defaultMessage="EPSS Score" />,
      helpTooltip: (
        <FM
          defaultMessage="EPSS measures an estimated probability of observing exploitation activity in the next 30 days in the wild. The EPSS percentile rank is a percentile rank ordering of the probability from highest to lowest. (<link>Learn more</link>)"
          values={{
            link: (value) => (
              <Link
                color="inherit"
                target="_blank"
                sx={{ textDecoration: 'underline' }}
                to={URL_EPSS_DOCS}
              >
                {value}
              </Link>
            ),
          }}
        />
      ),
    },
    {
      attributeKey: 'osv_score_type',
      heading: <FM defaultMessage="Score Type" />,
    },
  ];
};

export const FindingRiskStatsSection = ({
  finding,
  isLoading,
  namespace,
}: FindingDetailDrawerSectionProps) => {
  const { space } = useTheme();

  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace }),
    [finding, namespace]
  );

  return (
    <DetailDrawerSection>
      <Stack gap={space.sm}>
        <AttributeDisplayStack
          attributeRecords={records.slice(0, 2)}
          isLoading={isLoading}
          resource={resource}
          variant="row"
        ></AttributeDisplayStack>

        <AttributeDisplayStack
          attributeRecords={records.slice(2)}
          isLoading={isLoading}
          resource={resource}
          variant="row"
        ></AttributeDisplayStack>
      </Stack>
    </DetailDrawerSection>
  );
};
