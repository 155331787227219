import { Box, Skeleton, Stack, TableCell } from '@mui/material';
import { Header, RowData } from '@tanstack/react-table';
import { flexRender, Table } from '@tanstack/react-table';
import _debounce from 'lodash-es/debounce';
import { useCallback, useMemo } from 'react';

import {
  COLUMN_WIDTH_REDISTRIBUTION_THRESHOLD,
  LAST_COLUMN_REDISTRIBUTION_RATIO,
} from './constants';
import { DataTableSortIconButton } from './DataTableSortIconButton';
import { DataTableRowData } from './types';
import { getColWidthSx } from './utils';

interface DataTableHeaderCellProps<T extends DataTableRowData> {
  columnCount: number;
  header: Header<T, unknown>;
  isLoading?: boolean;
  table: Table<T>;
  tableWidth?: number;
}

export const DataTableHeaderCell = <T extends RowData>({
  columnCount,
  header,
  isLoading = false,
  table,
  tableWidth,
}: DataTableHeaderCellProps<T>) => {
  // Determine width of column based on available space
  const getWidthSx = useCallback(() => {
    let extraSpace = 0;
    const totalTableSize = table.getTotalSize();
    const tableElWidth = tableWidth ?? 0;
    const isLastColumn = header.index === columnCount - 1;
    const spaceToDistribute = tableElWidth - totalTableSize;

    if (
      isLastColumn &&
      spaceToDistribute > COLUMN_WIDTH_REDISTRIBUTION_THRESHOLD
    ) {
      extraSpace = Math.round(
        spaceToDistribute * LAST_COLUMN_REDISTRIBUTION_RATIO
      );
    }

    return getColWidthSx(header.column, extraSpace);
  }, [columnCount, header, table, tableWidth]);

  const widthSx = getWidthSx();

  // Create a debounced resize handler
  const resizeHandler = useMemo(
    () => _debounce(header.getResizeHandler(), 100),
    [header]
  );

  // Save debounced resize handler as a callback
  const resizeHandlerFn = useCallback(
    (args: unknown) => {
      resizeHandler(args);
    },
    [resizeHandler]
  );

  return (
    <TableCell
      key={header.id}
      colSpan={header.colSpan}
      sx={{ ...widthSx, verticalAlign: 'middle' }}
    >
      {header.isPlaceholder ? null : isLoading ? (
        <Skeleton height={38} />
      ) : (
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          onClick={header.column.getToggleSortingHandler()}
          sx={[
            header.column.getCanSort() && {
              cursor: 'pointer',
            },
          ]}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}

          {header.column.getCanSort() && (
            <DataTableSortIconButton
              isSorted={!!header.column.getIsSorted()}
              isSortedDesc={header.column.getIsSorted() == 'desc'}
            />
          )}
        </Stack>
      )}

      {!isLoading && header.column.getCanResize() && (
        <Box
          className="DataTableHeaderCell-resizer"
          onTouchStart={resizeHandlerFn}
          onMouseDown={resizeHandlerFn}
        />
      )}
    </TableCell>
  );
};
