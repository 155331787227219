import { useState } from 'react';

export const useDataTableRowHighlighting = () => {
  const [highlightedRowId, setHighlightedRowId] = useState<string | undefined>(
    undefined
  );

  return {
    isRowHighlighted: (rowId: string) => rowId === highlightedRowId,
    updateRowHighlighted: (rowId?: string) => setHighlightedRowId(rowId),
  };
};
