import {
  SpecEnabledFeatureType as EnabledFeatureType,
  SpecEndorLicenseFeatureType as FeatureType,
} from '@endorlabs/api_client';

/**
 * Mapping from Licensed Features to Features that may be enabled for the
 * scans in Endor (i.e. GitHub Application).
 */
export const LICENSE_FEATURE_TO_ENABLED_FEATURE: Partial<
  Record<FeatureType, EnabledFeatureType>
> = {
  [FeatureType.CicdDiscovery]: EnabledFeatureType.ToolsScan,
  [FeatureType.Sca]: EnabledFeatureType.GitScan,
  [FeatureType.ScmPostureManagement]: EnabledFeatureType.GithubScan,
  [FeatureType.Secrets]: EnabledFeatureType.SecretsScan,
};
