import { FindingSource } from '@endorlabs/endor-core/Finding';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  selectFindingCountsFromGroupResponse,
  useListFindings,
} from '@endorlabs/queries';
import { UIFindingUtils } from '@endorlabs/ui-common/domains/Findings';

export const useProjectFindingsBySource = ({
  uuid,
  namespace,
  source,
  project,
}: {
  uuid: string;
  namespace: string;
  source: FindingSource;
  project?: ProjectResource;
}) => {
  const projectFindingsFilter =
    UIFindingUtils.getFindingFilterExpression(source);

  const qFindingCounts = useListFindings(
    namespace,
    {
      enabled: !!project,
    },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `spec.project_uuid=="${uuid}"`,
        projectFindingsFilter ?? '',
      ]),
      group: {
        aggregation_paths: 'spec.level',
      },
    }
  );

  const findingCounts = selectFindingCountsFromGroupResponse(
    qFindingCounts.data?.group_response
  );
  return findingCounts;
};
