import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { EmptyState, EmptyStateProps } from '../EmptyState';

export interface LoadingStateProps extends Omit<EmptyStateProps, 'title'> {
  title?: ReactNode;
}

/**
 * Wrapper around {@see EmptyState} for use as a loading indicator
 */
export const LoadingState = ({
  title = <FM defaultMessage="Loading &hellip;" />,
  children = <CircularProgress />,
  size = 'large',
  ...emptyStateProps
}: LoadingStateProps) => {
  return (
    <EmptyState {...emptyStateProps} size={size} title={title}>
      {children}
    </EmptyState>
  );
};
