import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  FindingResource,
  FindingSpecPathNode,
  getFindingTitle,
} from '@endorlabs/endor-core/Finding';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { PackageVersionResource } from '@endorlabs/queries';
import {
  DependencyPathDisplayHeader,
  FormattedTypography,
  GraphPathList,
  IconAlertTriangle,
  IconGalaxy,
  UIFindingUtils,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { useOnboardingUserEventTracking } from '../../../Onboarding';
import { SampleMethodsDisclaimerAlert } from './SampleMethodsDisclaimerAlert';

const getFindingCallPaths = (
  finding?: FindingResource,
  findingProject?: ProjectResource
) => {
  const reachablePaths =
    (finding?.spec.reachable_paths?.length && finding?.spec.reachable_paths) ||
    [];

  const callPaths = [];

  for (const rp of reachablePaths) {
    if (!rp.nodes) continue;

    const callPath = UIFindingUtils.buildCallPathFromFunctionPath(
      rp.nodes as FindingSpecPathNode[]
    );

    // prepend project as root node, when exists
    if (findingProject) {
      const projectName =
        UIProjectUtils.parseProjectName(
          findingProject.meta.name,
          findingProject.spec.platform_source
        ) ?? findingProject.meta.name;

      callPath.unshift({
        id: 'Project',
        attributes: {
          Icon: IconGalaxy,
          label: projectName,
        },
      });
    }

    // additionally, add the finding title as final node in path
    callPath.push({
      id: 'Finding',
      attributes: {
        Icon: IconAlertTriangle,
        color: 'warning',
        // finding will be defined here, if reachable paths exist
        label: getFindingTitle(finding as FindingResource),
      },
    });

    callPaths.push(callPath);
  }

  return callPaths;
};

export type FindingCallPathsProps = {
  finding?: FindingResource;
  findingPackageVersion?: PackageVersionResource;
  findingProject?: ProjectResource;
  isLoading: boolean;
};

export const FindingCallPaths = ({
  finding,
  findingPackageVersion,
  findingProject,
  isLoading,
}: FindingCallPathsProps) => {
  const { space } = useTheme();
  const { trackOnboardingUserEvent } = useOnboardingUserEventTracking();
  const callPaths = getFindingCallPaths(finding, findingProject);
  const [callPathIndex, setCallPathIndex] = useState(0);

  // reset index when path counts change
  useEffect(() => {
    setCallPathIndex(0);
  }, [callPaths.length]);

  const isEmptyState = !isLoading && callPaths.length === 0;

  useEffect(() => {
    if (isLoading || isEmptyState) return;

    trackOnboardingUserEvent('INSPECTED_CALL_GRAPH', 'COMPLETED', {
      packageVersionName: findingPackageVersion?.meta.name,
    });
  }, [
    findingPackageVersion?.meta.name,
    isEmptyState,
    isLoading,
    trackOnboardingUserEvent,
  ]);

  return (
    <Stack spacing={space.sm}>
      <SampleMethodsDisclaimerAlert
        finding={finding}
        findingPackageVersion={findingPackageVersion}
        isLoading={isLoading}
      />

      {/* TODO: call paths filter */}

      <Stack className="call-graph-detail-container" gap={space.sm}>
        {/* TODO: clean up shared component */}
        <DependencyPathDisplayHeader
          count={callPaths.length}
          current={callPathIndex}
          // NOTE: hiding controls when loading, or empty
          showControls={!isLoading && !isEmptyState}
          title={
            <Typography variant="h3">
              <FM defaultMessage="Sample Call Paths" />
            </Typography>
          }
          updateCurrent={setCallPathIndex}
        />

        <GraphPathList
          direction="forward"
          emptyStateMessage={
            <FM defaultMessage="No call paths were discovered for this Finding" />
          }
          isLoading={isLoading}
          path={callPaths[callPathIndex]}
        />

        {/* Show Finding Summary after call path */}
        <Box>
          {!isLoading && !isEmptyState && (
            <FormattedTypography text={finding?.spec.summary} />
          )}
          {isLoading && (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton width="60%" />
            </>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};
