import {
  FormLabel,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { map as _map } from 'lodash-es';
import { isNull as _isNull } from 'lodash-es';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { JIRAConfigJiraIssueType } from '@endorlabs/api_client';
import { IconTrash } from '@endorlabs/ui-common';

import {
  ButtonCancel,
  ControlledLabelsField,
  ControlledTextField,
  RowStack,
} from '../../../components';
import { REGEX_URL_VALIDATION } from '../../../constants';
import { FormUpsertNotificationTargetFieldValues } from './FormUpsertNotificationTarget/types';

const JIRA_ISSUE_TYPES = [
  {
    label: 'Task',
    value: JIRAConfigJiraIssueType.Task,
  },
  {
    label: 'Bug',
    value: JIRAConfigJiraIssueType.Bug,
  },
];

// The fields for Jira action are a subset of _all_ form fields
type JiraConfigFieldValues = Exclude<
  FormUpsertNotificationTargetFieldValues['spec']['action']['jira_config'],
  undefined
>;

type JiraActionTypeFieldValues = {
  spec: {
    action: {
      jira_config: Pick<
        JiraConfigFieldValues,
        | 'user_name'
        | 'api_key'
        | 'url'
        | 'project_key'
        | 'issue_type'
        | 'labels'
        | 'custom_fields'
        | 'jira_issue_type'
      >;
    };
  };
};

/**
 * Form fields for Jira action type. To be used with {@see FormUpsertNotificationTarget}
 */
export const JiraActionTypeFields = () => {
  const { control, watch, setValue } =
    useFormContext<JiraActionTypeFieldValues>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'spec.action.jira_config.custom_fields',
  });

  const { formatMessage: fm } = useIntl();

  const issueType = watch('spec.action.jira_config.issue_type');
  const jiraIssueType = watch('spec.action.jira_config.jira_issue_type');

  useEffect(() => {
    /* Map legacy issue type to jira issue type for edit jira config when jira_issue_type is undefined
     * In subsequent edit the new value of jira_issue_type is taken
     */
    if (
      issueType &&
      _isNull(jiraIssueType) &&
      (issueType === JIRAConfigJiraIssueType.Task ||
        issueType === JIRAConfigJiraIssueType.Bug)
    ) {
      const label =
        JIRA_ISSUE_TYPES.find((item) => item.value === issueType)?.label ??
        issueType;
      setValue('spec.action.jira_config.jira_issue_type', label);
    }
  }, [issueType, jiraIssueType, setValue]);

  return (
    <>
      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Username' })}
        helperText={fm({
          defaultMessage:
            ' Any Jira issues created by Endor Labs will appear as being created by this user account',
        })}
        name="spec.action.jira_config.user_name"
        placeholder={fm({
          defaultMessage: 'What Jira user do you want to authenticate as?',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
      />
      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'API Key' })}
        name="spec.action.jira_config.api_key"
        helperText={fm({
          defaultMessage:
            'Make sure this grants the ability to create new issues in the Jira Project to which notifications will be sent',
        })}
        placeholder={fm({
          defaultMessage: 'Provide a API key for Jira',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 65_536,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
        type="password"
      />
      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Jira URL' })}
        name="spec.action.jira_config.url"
        helperText={fm({
          defaultMessage: 'The HTTP endpoint of your Jira instance',
        })}
        placeholder={fm({
          defaultMessage: 'https://mycompany.atlassian.net/',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
          pattern: {
            value: REGEX_URL_VALIDATION,
            message: fm({
              defaultMessage: 'Must be a valid URL',
            }),
          },
          validate: (_value: unknown) => {
            const value = String(_value);
            if (value.length > 1 && !value.endsWith('/')) {
              return fm({
                defaultMessage:
                  'The endpoint value must end with a trailing slash (`/`)',
              });
            }
          },
        }}
      />
      <ControlledTextField
        control={control}
        defaultValue=""
        label={fm({ defaultMessage: 'Project Key' })}
        name="spec.action.jira_config.project_key"
        helperText={fm({
          defaultMessage: 'Notifications will create issues in this project',
        })}
        placeholder={fm({
          defaultMessage: 'MYPROJECT',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
          minLength: {
            value: 1,
            message: fm({ defaultMessage: 'A non-empty value is required' }),
          },
          maxLength: {
            value: 1_024,
            message: fm({
              defaultMessage: 'The value must be 1024 characters or less',
            }),
          },
        }}
      />
      <ControlledTextField
        control={control}
        defaultValue={''}
        label={fm({ defaultMessage: 'Issue Type' })}
        name={'spec.action.jira_config.jira_issue_type'}
        helperText={fm({
          defaultMessage:
            'Notifications will open issues with this issue type. Examples: "Task", "Bug", "Story", "Sub-Task", "Epic"',
        })}
        placeholder={fm({
          defaultMessage: 'Ex: Task',
        })}
        rules={{
          required: fm({ defaultMessage: 'This is a required field' }),
        }}
      />

      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        name="spec.action.jira_config.labels"
        label={fm({ defaultMessage: 'Labels' })}
        helperText={fm({
          defaultMessage:
            'These will be added to any Jira issues created as the result of an alert',
        })}
      />

      <Typography variant="subtitle2">
        <FM defaultMessage="Custom Fields" />
      </Typography>
      <Stack spacing={2} direction={'column'}>
        {_map(fields, (field, index) => {
          return (
            <RowStack
              key={field.id}
              alignItems={'flex-end'}
              justifyContent={'space-between'}
            >
              <ControlledTextField
                control={control}
                defaultValue={''}
                key={`key-${field.id}`}
                label={fm({ defaultMessage: 'Key' })}
                name={`spec.action.jira_config.custom_fields.${index}.key`}
                placeholder={fm({
                  defaultMessage: 'Ex: Source',
                })}
                rules={{
                  required: fm({ defaultMessage: 'This is a required field' }),
                  minLength: {
                    value: 1,
                    message: fm({
                      defaultMessage: 'A non-empty value is required',
                    }),
                  },
                  maxLength: {
                    value: 1_024,
                    message: fm({
                      defaultMessage:
                        'The value must be 1024 characters or less',
                    }),
                  },
                }}
                sx={{ width: '40%' }}
              />
              <ControlledTextField
                control={control}
                defaultValue={field}
                key={`value-${field.id}`}
                label={fm({ defaultMessage: 'Value' })}
                name={`spec.action.jira_config.custom_fields.${index}.value`}
                placeholder={fm({
                  defaultMessage: 'Ex: Endor Labs',
                })}
                rules={{
                  required: fm({ defaultMessage: 'This is a required field' }),
                  minLength: {
                    value: 1,
                    message: fm({
                      defaultMessage: 'A non-empty value is required',
                    }),
                  },
                  maxLength: {
                    value: 1_024,
                    message: fm({
                      defaultMessage:
                        'The value must be 1024 characters or less',
                    }),
                  },
                }}
                sx={{ width: '45%' }}
              />
              <IconButton onClick={(value) => remove(index)} size="small">
                <IconTrash
                  fontSize="inherit"
                  sx={({ palette }) => ({
                    color: palette.status.failure,
                  })}
                />
              </IconButton>
            </RowStack>
          );
        })}
        <ButtonCancel
          sx={{ alignSelf: 'flex-start' }}
          onClick={() => {
            append({ key: '', value: '' });
          }}
        >
          <FM defaultMessage={'+ Add Custom Field'} />
        </ButtonCancel>
      </Stack>
    </>
  );
};
