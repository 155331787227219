import { FormattedMessage as FM } from 'react-intl';

import { V1FindingCategory as FindingCategory } from '@endorlabs/api_client';

import { FindingCategoryMessages } from '../../locales';

export const FindingCategoryLabel = ({
  findingCategory,
}: {
  findingCategory: FindingCategory;
}) => <FM {...FindingCategoryMessages[findingCategory]} />;
