import { defineMessage } from 'react-intl';

import { getAuthVerificationQueryOptions } from '@endorlabs/queries';
import { LoadingState } from '@endorlabs/ui-common';

import { createFileRoute } from '../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Admin' });

export const Route = createFileRoute('/admin')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  loader: async ({ context }) => {
    // Preload the queries used in the page
    await context.queryClient.prefetchQuery(getAuthVerificationQueryOptions());
    return {};
  },
  pendingComponent: LoadingState,
});
