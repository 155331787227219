import { Box, Typography, useTheme } from '@mui/material';
import { RowSelectionState } from '@tanstack/react-table';
import { useLayoutEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ResourceFilter } from '@endorlabs/filters';
import { QueryProjectsResponseObject } from '@endorlabs/queries';
import {
  BulkActionRecord,
  ButtonCancel,
  CommonDataTableRowActions,
  DataTablePaginator,
  DataTableView,
  FlexList,
  FlexListItem,
} from '@endorlabs/ui-common';

import { FilterBuilder, FilterBuilderProps } from '../../components';
import { useProjectDetailDrawer } from '../../domains/Projects';
import {
  buildProjectsTableColumns,
  ProjectsTableRow,
} from './utils/buildProjectsTableColumns';

interface ProjectsTableViewProps {
  bulkActions: BulkActionRecord<ProjectsTableRow>[];
  clearFilters: () => void;
  data: ProjectsTableRow[];
  filters: ResourceFilter[];
  getFilterBuilderProps: () => FilterBuilderProps;
  handleRowSelection: (state: RowSelectionState) => void;
  isLoading: boolean;
  namespace: string;
  onDelete: (row: ProjectsTableRow) => void;
  paginator: DataTablePaginator;
  projectsWithoutReferences: QueryProjectsResponseObject[];
  totalCount: number;
}

export const ProjectsTableView = ({
  bulkActions,
  clearFilters,
  data,
  filters,
  getFilterBuilderProps,
  handleRowSelection,
  isLoading,
  namespace,
  onDelete,
  paginator,
  projectsWithoutReferences,
  totalCount,
}: ProjectsTableViewProps) => {
  const { space } = useTheme();
  const { DetailDrawer, permalinkEffect } = useProjectDetailDrawer();
  useLayoutEffect(
    () => permalinkEffect(projectsWithoutReferences),
    [projectsWithoutReferences, permalinkEffect]
  );
  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      isPrimaryAction: true,
      onClick: (row: ProjectsTableRow) => {
        DetailDrawer.activate(
          {
            projectNamespace: row.namespace,
            projectUuid: row.uuid,
          },
          {
            namespace: row.namespace,
            uuid: row.uuid,
          }
        );
      },
    },
    {
      ...CommonDataTableRowActions.DELETE_RESOURCE,
      onClick: (row: ProjectsTableRow) => onDelete(row),
    },
  ];

  return (
    <DataTableView
      namespace={namespace}
      columns={buildProjectsTableColumns()}
      data={data}
      countMessage={
        <FM
          defaultMessage="{count, number} projects"
          values={{ count: totalCount }}
        />
      }
      filtersContent={
        <Box marginBottom={space.xs}>
          <FilterBuilder {...getFilterBuilderProps()} isCard={false} />
        </Box>
      }
      isLoading={isLoading}
      bulkActions={bulkActions}
      onRowSelectionChange={handleRowSelection}
      rowActions={rowActions}
      paginator={paginator}
      enablePagination
      enableRowSelection
      emptyStateProps={{
        title: <FM defaultMessage="No Project results found" />,
        children: (
          // Handle possible causes of empty states
          <FlexList
            divider={
              <Typography color="text.secondary">
                <FM defaultMessage="or" />
              </Typography>
            }
            justifyContent="center"
          >
            {filters.length > 0 && (
              <FlexListItem>
                <ButtonCancel onClick={clearFilters}>
                  <FM defaultMessage="Clear Filters" />
                </ButtonCancel>
              </FlexListItem>
            )}

            {paginator.state.pageIndex > 0 && (
              <FlexListItem>
                <ButtonCancel onClick={() => paginator.resetPagination()}>
                  <FM defaultMessage="Go to First Page of Results" />
                </ButtonCancel>
              </FlexListItem>
            )}
          </FlexList>
        ),
      }}
    />
  );
};
