import { Grid, Theme, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import { MakeGenerics, Navigate, useSearch } from '@tanstack/react-location';

import { useFeatureFlags } from '@endorlabs/queries';
import screenshotSignup from '@endorlabs/ui-common/assets/login/screenshot-dashboard-2024.02.png';

import { NamedRoutes } from '../../../routes/constants/NamedRoutes';
import { SignupFormCard } from './SignupFormCard';
import { SignupHighlightsCard } from './SignupHighlightsCard';

type SignupRouteLocationGenerics = MakeGenerics<{
  Search: {
    code?: string;
  };
}>;

export const SignupPage = () => {
  const theme = useTheme();
  const { space } = theme;
  const sx = styles(theme);

  const { code } = useSearch<SignupRouteLocationGenerics>();

  return (
    <Grid
      className="SignupPage"
      container
      height="100vh"
      justifyContent="center"
      paddingTop={{ lg: '12%', md: '6%', sm: '3%' }}
      sx={sx}
      width="100vw"
    >
      <Grid className="SignupPage__bg" item />
      <Grid className="SignupPage__overlay" item />
      <Grid
        container
        height="60%"
        item
        justifyContent={{ lg: 'flex-start', md: 'center', sm: 'center' }}
        lg={8}
        maxHeight="60%"
        md={10}
        sm={10}
        spacing={space.xl}
      >
        <Grid item lg={6} md={12} sm={12}>
          <SignupFormCard code={code} />
        </Grid>

        <Grid item lg={6} md={12} sm={12}>
          <SignupHighlightsCard />
        </Grid>
      </Grid>
    </Grid>
  );
};

function styles({ breakpoints, palette }: Theme) {
  return {
    '.SignupPage': {},

    '.SignupPage__bg, .SignupPage__overlay': {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },

    '.SignupPage__bg': {
      backgroundImage: `url(${screenshotSignup})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: -2,
    },

    '.SignupPage__overlay': {
      backgroundColor: alpha('#000503', 0.6),
      backdropFilter: 'blur(2px)',
      zIndex: -1,
    },

    textWrapper: {
      height: '100%',
      background: palette.background.paper,
      padding: '36px',
      width: '31vw',
      [breakpoints.down('md')]: {
        marginTop: '1rem',
        width: '100vw',
      },
    },

    success: {
      borderRadius: '4px',
      background: 'rgba(150, 199, 12, 0.25)',
      padding: 3,
      color: palette.brand.main,
    },
  };
}
