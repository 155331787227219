import { useLocation, useNavigate } from '@tanstack/react-location';
import {
  isEqual as _isEqual,
  memoize as _memoize,
  pick as _pick,
} from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  Filter,
  ResourceFilter,
  useFilterSearchParams,
} from '@endorlabs/filters';
import { useLatestCallback } from '@endorlabs/ui-common';

import { FilterState, FilterStateValues } from './useFilterState';

/**
 * Generic definition for a "store" for persisting state.
 *
 * Intendend to be compatible with both:
 * - zustand stores
 * - `useSyncExternalStore` in React 18 {@link https://react.dev/reference/react/useSyncExternalStore}
 *
 * See also {@link https://tanstack.com/store}
 */
export interface Store<TState = unknown> {
  getState: () => TState;
  setState: (next: TState) => void;
  subscribe: (
    listener: (state: TState, prev: TState | undefined) => void
  ) => () => void;
}

type FilterParamStoreKeys = 'filter' | 'filter.search' | 'filter.values';

export const FILTER_PARAM_STORE_KEYS: FilterParamStoreKeys[] = [
  'filter',
  'filter.search',
  'filter.values',
];

/**
 * Allow for backwards-compatible mapping from the list of filters to the map of filter values.
 */
const mapFilterListToValues = (
  filters: ResourceFilter[]
): Map<string, Filter> => {
  const entries: [string, Filter][] = filters.map((f) => {
    const key = [f.kind, f.key].join(':');
    let filter = _pick(f, ['key', 'comparator', 'value']) as Filter;

    // handle deprecated `CONTAINS_ALL` comparator
    if (f.comparator === 'CONTAINS_ALL') {
      filter = {
        operator: 'AND',
        value: f.value.map((value) => ({
          key: f.key,
          comparator: 'CONTAINS',
          value: [value],
        })),
      } as Filter;
    }

    // handle deprecated `WITHIN_RANGE` comparator
    if (f.comparator === 'WITHIN_RANGE') {
      const [min, max] = f.value;

      filter = {
        operator: 'AND',
        value: [
          { key: f.key, comparator: 'GREATER', value: min },
          { key: f.key, comparator: 'LESSER_OR_EQUAL', value: max },
        ],
      } as Filter;
    }

    return [key, filter];
  });
  return new Map(entries);
};

/**
 * Internal method to parse filter state from the current search params
 * @private exported for testing only
 */
export const parseSearchParams = (
  params: Record<string, unknown> = {}
): FilterState => {
  const search = (params['filter.search'] ?? params['filter.default']) as
    | string
    | undefined;

  let values: Map<string, Filter> | undefined;
  let expression = params['filter'] as string | undefined;

  try {
    const rawValues = params['filter.values'];
    const parsedValues =
      rawValues && 'string' === typeof rawValues
        ? JSON.parse(rawValues)
        : undefined;

    if (!parsedValues) {
      // ignore nil value
    } else if (Array.isArray(parsedValues)) {
      values = mapFilterListToValues(parsedValues);
    } else if ('object' === typeof parsedValues) {
      values = new Map(Object.entries(parsedValues));
    }
  } catch (e) {
    // ignore JSON.parse failure
  }

  // Backwards compatible parse for filter values
  // - When `filter` is a JSON-encoded `Filter[]`, parse and return as the filter values
  if (!values && expression) {
    const parsedValues = useFilterSearchParams.parse(expression);
    if (parsedValues) {
      values = mapFilterListToValues(parsedValues);
      expression = undefined;
    }
  }

  return { expression, search, values };
};

/**
 * Internal method to serialize filter state into the next search params
 * @private exported for testing only
 */
export const serializeSearchParams = (
  state: FilterState
): Record<FilterParamStoreKeys, unknown> => {
  const filterValues = state.values?.size
    ? JSON.stringify(Object.fromEntries(state.values.entries()))
    : undefined;

  return {
    'filter.search': state.search,
    'filter.values': filterValues,
    filter: state.expression,
  };
};

/**
 * Initialize the internal state from search parameters or the given
 * initial values.
 *
 * HACK: uses memoize to allow invoking once per given key (ref)
 */
const initialize = _memoize((_, { params, initialFilterValues }) => {
  // Set from search params, when present
  const initial = parseSearchParams(params);
  if (initial.values?.size || initial.search || initial.expression) {
    return { snapshot: initial, isInitialized: true };
  }

  // Set given initial filter state, when present
  if (initialFilterValues) {
    initial.values = initialFilterValues;
  }

  return { snapshot: initial, isInitialized: false };
});

/**
 * URL Search Param backed store for Filter state
 */
export const useFilterParams = ({
  initialFilterValues,
}: {
  initialFilterValues?: FilterStateValues;
}): Store<FilterState> => {
  const location = useLocation();
  const navigate = useNavigate();

  const memoKey = useRef({});
  const state = useRef<{ isInitialized: boolean; snapshot: FilterState }>(
    initialize(memoKey, {
      params: location.current.search,
      initialFilterValues,
    })
  );

  useEffect(() => {
    if (state.current.isInitialized) return;
    state.current.isInitialized = true;

    // Update params from internal value
    navigate({
      replace: true,
      search: (old) => ({
        ...old,
        ...serializeSearchParams(state.current.snapshot),
      }),
    });
  }, [navigate]);

  const getState = useLatestCallback(() => {
    return state.current.snapshot;
  });

  const setState = useCallback(
    (state: FilterState) => {
      navigate({
        search: (old) => ({
          ...old,
          ...serializeSearchParams(state),
        }),
      });
    },
    [navigate]
  );

  const subscribe = useCallback(
    (
      listener: (state: FilterState, prev: FilterState | undefined) => void
    ): (() => void) => {
      const unsubscribe = location.subscribe(() => {
        const prev = state.current.snapshot;
        const next = parseSearchParams(location.current.search);

        // If changed, update local state
        if (!_isEqual(prev, next)) {
          state.current.snapshot = next;
        }

        listener(next, prev);
      });

      return unsubscribe;
    },
    [location]
  );

  return useMemo(
    () => ({
      getState,
      setState,
      subscribe,
    }),
    [getState, setState, subscribe]
  );
};
