import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
} from '@mui/material';
import { SyntheticEvent, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingSource } from '@endorlabs/endor-core/Finding';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { PackageVersionResource, useQueryFindings } from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  EmptyState,
  PackageNameDisplay,
  UIFindingUtils,
  UIPackageVersionUtils,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import {
  FilterBar,
  getDefaultFilterValues,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  FINDING_FILTER_FIELDS,
  FINDING_SEARCH_KEYS,
  FindingAggregation,
  FindingAggregationMenu,
  FindingDetailSectionPagination,
  FindingSourceToggles,
  FindingSummaryAccordionV2,
  useAggregatedFindingsData,
  useFindingsExportDialog,
  useFindingsFilterFields,
} from '../../domains/Findings';
import { FindingsAggregatedGroupSection } from '../Findings/FindingsAggregatedGroupSection';
import { usePackageVersionFindingCountsByFindingSource } from './usePackageVersionFindingCountsByFindingSource';

export interface PackageVersionDetailFindingsProps {
  defaultFindingSource?: FindingSource;
  tenantName: string;
  packageVersion?: PackageVersionResource;
}

const PackageVersionDetailFindingsBase = ({
  defaultFindingSource = FindingSource.Dependency,
  packageVersion,
  tenantName: namespace,
}: PackageVersionDetailFindingsProps) => {
  const { getErrorMessage } = useResourceCRUDMessages();

  // Manage the filter for findings
  const {
    filter: userFilterExpression,
    clearFilter,
    _state: filterState,
  } = useFilterContext();

  const [findingSource, setFindingSource] =
    useState<FindingSource>(defaultFindingSource);
  const handleChangeFindingSource = (
    _: SyntheticEvent,
    value?: FindingSource
  ) => {
    if (value && value !== findingSource) {
      setFindingSource(value);
      clearFilter();
    }
  };
  const filterFields = useFindingsFilterFields(findingSource);

  // get finding total finding counts, and counts for toggles
  const {
    isLoading: isLoadingCounts,
    totalFindingsCount,
    findingSourceToggleOptions,
  } = usePackageVersionFindingCountsByFindingSource({
    namespace,
    packageVersion,
  });

  const [findingAggregation, setFindingAggregation] =
    useState<FindingAggregation>(FindingAggregation.None);
  const isGrouped = findingAggregation !== FindingAggregation.None;

  const findingsFilterExpression = useMemo(() => {
    const baseFilterExpression =
      UIPackageVersionUtils.getPackageVersionFindingFilterExpression(
        packageVersion,
        findingSource
      ) as string;

    if (!baseFilterExpression || !userFilterExpression) {
      return baseFilterExpression;
    }

    return filterExpressionBuilders.and([
      baseFilterExpression,
      userFilterExpression,
    ]);
  }, [findingSource, packageVersion, userFilterExpression]);

  const {
    data: aggregatedFindings,
    error: aggregatedFindingsError,
    isLoading: isLoadingAggregatedFindings,
    isLoadingAny,
    paginator,
    totalCount,
  } = useAggregatedFindingsData({
    enabled: !!findingsFilterExpression,
    findingSource,
    findingAggregation,
    filterExpression: findingsFilterExpression,
    filterState,
    namespace,
  });

  const findingPageUuids = aggregatedFindings.flatMap((d) => d.uuids);
  const qListFindings = useQueryFindings(
    namespace,
    {
      filter: `uuid in [${findingPageUuids}]`,
    },
    { enabled: !isGrouped && !isLoadingAny && !!findingPageUuids.length }
  );

  // ensure result is sorted
  const listFindings = useMemo(() => {
    return UIFindingUtils.sortByFindingSeverity(
      qListFindings.data?.list?.objects ?? [],
      'spec.level',
      (a, b) => a.meta.name.localeCompare(b.meta.name)
    );
  }, [qListFindings.data?.list?.objects]);

  const hasError = !!aggregatedFindingsError;
  const isLoading = isLoadingAggregatedFindings || qListFindings.isLoading;

  const isEmptyState = !isLoading && totalFindingsCount === 0;
  const isFilteredEmptyState =
    !isLoading && !hasError && !isEmptyState && totalCount === 0;
  const errorMessage = hasError
    ? getErrorMessage('LIST', 'Finding', aggregatedFindingsError)
    : undefined;

  const findingsExportDialog = useFindingsExportDialog();

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {!isEmptyState && (
        <Grid item>
          <FindingSourceToggles
            hideFindingCounts={isLoadingCounts}
            options={findingSourceToggleOptions}
            onChange={handleChangeFindingSource}
            value={findingSource}
          />

          <FilterBar fields={filterFields} />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              disableTypography
              title={
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Box flexGrow={1}>
                    <FindingAggregationMenu
                      findingSource={findingSource}
                      value={findingAggregation}
                      onSelect={(_, value) => setFindingAggregation(value)}
                    />
                  </Box>

                  <Box>
                    {isLoading ? (
                      <Skeleton height={24} width={300} />
                    ) : (
                      <FindingDetailSectionPagination
                        hidePageSizeSelect
                        paginator={paginator}
                        resourceLabel={isGrouped ? 'Group' : 'Finding'}
                        resourceLabelPlural={isGrouped ? 'Groups' : 'Findings'}
                      />
                    )}
                  </Box>

                  <ButtonPrimary
                    disabled={isLoading || isFilteredEmptyState}
                    onClick={() =>
                      findingsExportDialog.openDialog({
                        namespace,
                        filter: findingsFilterExpression,
                        downloadProps: {
                          filename: `package-version_${packageVersion?.uuid}_findings-export.csv`,
                        },
                      })
                    }
                  >
                    <FM defaultMessage="Export Findings" />
                  </ButtonPrimary>
                </Stack>
              }
              sx={{ marginBottom: 4 }}
            />

            <CardContent>
              {isFilteredEmptyState && (
                <EmptyState
                  size="large"
                  title={
                    <FM defaultMessage="No Findings match the filter criteria" />
                  }
                >
                  <ButtonCancel onClick={clearFilter}>
                    <FM defaultMessage="Clear Filter" />
                  </ButtonCancel>
                </EmptyState>
              )}

              {isLoading &&
                Array.from({ length: paginator.state.pageSize }, (_, ix) => (
                  <Skeleton height={100} key={ix} />
                ))}

              {!isLoading && isGrouped && (
                <Stack spacing={4}>
                  {aggregatedFindings.map((group, index) => (
                    <FindingsAggregatedGroupSection
                      findingSummaryProps={{
                        // when grouped by dependency, use the default finding title
                        forceFindingDescriptionTitle: [
                          FindingAggregation.Package,
                          FindingAggregation.Dependency,
                        ].includes(findingAggregation),
                        hideProjectMetadata: true,
                      }}
                      key={index}
                      namespace={namespace}
                      severity={group.severity}
                      title={getGroupSectionTitle(group.title)}
                      totalCount={group.totalCount}
                      uuids={group.uuids}
                    />
                  ))}
                </Stack>
              )}

              {!isLoading && !isGrouped && (
                <Stack
                  spacing={4}
                  sx={({ palette, shape, spacing }) => ({
                    '& .MuiPaper-root': {
                      border: `1px solid ${palette.divider}`,
                      borderRadius: `${shape.borderRadius}px`,
                      paddingX: spacing(4),
                      '&:before': {
                        display: 'none', // hide the top border on the collapse
                      },
                    },
                  })}
                >
                  {listFindings.map((f) => (
                    <FindingSummaryAccordionV2
                      finding={f}
                      key={f.uuid}
                      namespace={namespace}
                      forceFindingDescriptionTitle
                      hideProjectMetadata
                    />
                  ))}
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="There are no findings in this package version" />
            }
            description={
              <FM defaultMessage="As findings are raised in future scans of this package, they will appear here." />
            }
          />
        </Grid>
      )}

      {hasError && (
        <Grid item>
          <EmptyState
            title={errorMessage?.message}
            description={errorMessage?.details}
          >
            <Stack direction="row" spacing={4}>
              <ButtonPrimary
                onClick={() =>
                  setFindingAggregation(FindingAggregation.Finding)
                }
              >
                <FM defaultMessage="Reset" />
              </ButtonPrimary>
            </Stack>
          </EmptyState>
        </Grid>
      )}

      <findingsExportDialog.Dialog {...findingsExportDialog.getDialogProps()} />
    </Grid>
  );
};

export const PackageVersionDetailFindings = withFilterProvider(
  PackageVersionDetailFindingsBase,
  {
    displayName: 'PackageVersionDetailFindings',
    searchKeys: FINDING_SEARCH_KEYS,
    defaultFilterValues: getDefaultFilterValues(FINDING_FILTER_FIELDS),
  }
);

/**
 * Utility to handle formatting package version names as group titles
 */
function getGroupSectionTitle(title?: string) {
  if ('string' === typeof title && /^\w+:\/\//.test(title)) {
    return <PackageNameDisplay name={title} size="medium" showVersion />;
  }

  return title;
}
