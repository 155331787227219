import { Box, Theme, Tooltip, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';

import { getScoreCategoryLabel } from '../../domains/Metrics';
import { IconArrowDownRight, IconArrowUpRight, IconMinus } from '../../themes';

interface ScoreDisplayBarProps {
  category?: V1ScoreCategory;
  value?: number;
}

export const ScoreDisplayFactor = ({
  category,
  value,
}: ScoreDisplayBarProps) => {
  const sx = styles(useTheme());

  if (value === undefined) return <Box sx={sx.root} />;

  const Icon =
    value < 0 ? IconArrowDownRight : value > 0 ? IconArrowUpRight : IconMinus;

  return (
    <Tooltip
      title={
        <FM
          defaultMessage="{disposition} {category} score factor"
          values={{
            disposition:
              value < 0 ? 'Negative' : value > 0 ? 'Positive' : 'Neutral',
            category: getScoreCategoryLabel(category),
          }}
        />
      }
    >
      <Box sx={sx.root}>
        <Icon />
      </Box>
    </Tooltip>
  );
};

const styles = ({ palette, space, typography }: Theme) => ({
  root: {
    '.factorGood &, .factorBad &, .factorNeutral &, .nil &': {
      alignItems: 'center',
      borderRadius: '50%',
      color: palette.common.white,
      display: 'flex',
      height: space.md,
      justifyContent: 'center',
      textAlign: 'center',
      width: space.md,

      '& > .MuiSvgIcon-root': {
        fontSize: typography.pxToRem(16),
      },
    },

    '.small &': {
      height: space.sm,
      width: space.sm,

      '& > .MuiSvgIcon-root': {
        fontSize: typography.pxToRem(12),
      },
    },
    '.factorGood &': {
      backgroundColor: palette.scores.good,
    },
    '.factorBad &': {
      backgroundColor: palette.scores.bad,
    },

    '.factorNeutral &, .nil &': {
      backgroundColor: palette.grey[500],
    },
  },
});
