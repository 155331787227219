import produce from 'immer';

import {
  SpecArchType,
  SpecBinaryType,
  V1PlatformSource,
} from '@endorlabs/api_client';
import { sortParamBuilders, useListPluginBinaries } from '@endorlabs/queries';

import { getEnv } from '../../../../constants';

/**
 * Get the most recently published EndorCTL binary for the given version
 */
export const useListEndorctlBinaries = (archType?: SpecArchType) => {
  const ENV = getEnv();

  const RECENT_VERSION_COUNT = 50;
  return useListPluginBinaries(
    {
      enabled: !!archType,

      select: produce((data) => {
        const seen = new Set();

        // de-duplicate binaries by version
        if (data.list?.objects) {
          const binaries =
            data.list?.objects.filter((o) => {
              const version = o.spec.version;
              if (!version) return false;
              if (seen.has(version)) return false;

              seen.add(version);
              return true;
            }) ?? [];

          // HACK: transform download URL to replace googleapis with api download proxy
          binaries.forEach((o) => {
            if (o.spec.url?.startsWith('https://storage.googleapis.com/')) {
              o.spec.url = o.spec.url.replace(
                'https://storage.googleapis.com/',
                `${ENV.API_BASE_URL}/download/`
              );
            }
          });

          data.list.objects = binaries;
        }

        return data;
      }),
    },
    {
      filter: [
        // include only endorctl binaries for the target arch
        `spec.binary_type=="${SpecBinaryType.EndorctlBinary}"`,
        `spec.arch_type=="${archType}"`,
        // exclude binaries marked as 'unsupported'
        `spec.unsupported!=true`,
      ].join(' and '),
      mask: [
        'uuid',
        'spec.checksum',
        'spec.published_date',
        'spec.unsupported',
        'spec.url',
        'spec.version',
      ].join(','),
      page_size: RECENT_VERSION_COUNT,
      sort: sortParamBuilders.descendingBy('spec.published_date'),
    }
  );
};
