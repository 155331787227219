/**
 * Given an set of numeric values, with their respective weights, return
 * the weighted average of the values.
 *
 * @example
 *   const values = [
 *     [1, 10],
 *     [10, 1],
 *   ];
 *
 *   // Expect: average weighted to favor `1`
 *   // > 1.818181...
 *   const avg = weightedAverage(values);
 */
export const weightedAverage = (values: number[][]) => {
  let totalValue = 0;
  let totalWeight = 0;

  for (const [value = 1, weight = 0] of values) {
    const weighted = value * weight;
    totalValue += weighted;
    totalWeight += weight;
  }

  return totalValue / totalWeight;
};
