import { QueryKey, useQuery } from 'react-query';

import {
  PluginBinaryServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';

import {
  PluginBinaryResource,
  PluginBinaryResourceList,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

export type CountPluginBinariesOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type ListPluginBinaryOptions = ResourceQueryOptions<PluginBinaryResourceList>;
type GetPluginBinaryOptions = ResourceQueryOptions<PluginBinaryResource>;

const BASE_KEY = 'v1/plugin_binaries';
const QK = {
  count: (countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', countParams] as const,
  list: (listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', listParams] as const,
  record: (uuid: string): QueryKey => [BASE_KEY, 'get', uuid] as const,
};
export const PluginBinariesQueryKeys = QK;

const getApiService = () =>
  new PluginBinaryServiceApi(getClientConfiguration());

const countPluginBinaries = async (countParams: V1ListParameters = {}) => {
  const api = getApiService();
  const resp = await api.pluginBinaryServiceListPluginBinaries(
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountPluginBinaries = (
  opts: CountPluginBinariesOptions = {},
  countParams: V1ListParameters = {}
) => {
  return useQuery(
    QK.count(countParams),
    () => countPluginBinaries(countParams),
    opts
  );
};

const listPluginBinaries = async (listParams: V1ListParameters = {}) => {
  const api = getApiService();
  const resp = await api.pluginBinaryServiceListPluginBinaries(
    ...buildListParamArgs(listParams)
  );
  return resp.data as PluginBinaryResourceList;
};

export const useListPluginBinaries = (
  opts: ListPluginBinaryOptions = {},
  listParams?: V1ListParameters
) => {
  return useQuery(
    QK.list(listParams),
    () => listPluginBinaries(listParams),
    opts
  );
};

export const getPluginBinary = async (uuid: string) => {
  const api = getApiService();
  const resp = await api.pluginBinaryServiceGetPluginBinary(uuid);
  return resp.data as PluginBinaryResource;
};

export const useGetPluginBinary = (
  uuid: string,
  opts: GetPluginBinaryOptions = {}
) => {
  return useQuery(QK.record(uuid), () => getPluginBinary(uuid), opts);
};
