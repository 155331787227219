import { alpha, IconButton, lighten, TextField } from '@mui/material';
import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  Ref,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { IconSearch, IconX } from '../../themes';

export interface SearchBarInputProps {
  disableUnderline?: boolean;
  enableClear?: boolean;
  onKeyDown?: KeyboardEventHandler;
  onSearch: (searchValue: string) => void;
  placeholder?: string;
  searchEvent?: 'onKeyUp' | 'onChange';
  searchValue?: string;
  size?: 'small' | 'medium' | 'large';
}

const BaseSearchBarInput = (
  {
    disableUnderline = true,
    enableClear = false,
    onKeyDown,
    onSearch,
    placeholder,
    searchEvent = 'onChange',
    searchValue,
    size = 'small',
  }: SearchBarInputProps,
  ref: Ref<HTMLInputElement>
) => {
  const { formatMessage: fm } = useIntl();
  const [internalValue, setInternalValue] = useState(searchValue ?? '');

  useEffect(() => {
    setInternalValue(searchValue ?? '');
  }, [searchValue]);

  const handleBlur = (_: FocusEvent) => {
    if (internalValue !== searchValue) {
      onSearch(internalValue);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setInternalValue(inputValue);
  };

  const handleClear = (_: SyntheticEvent) => {
    setInternalValue('');
    onSearch('');
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (
      (searchEvent === 'onKeyUp' || event.code === 'Enter') &&
      internalValue !== searchValue
    ) {
      onSearch(internalValue);
    } else if (event.code === 'Escape' && searchValue !== '') {
      handleClear(event);
    }
  };

  return (
    <TextField
      autoComplete="off"
      value={internalValue}
      onChange={handleChange}
      onKeyUp={handleKeyUp}
      onKeyDown={onKeyDown}
      onBlur={handleBlur}
      placeholder={placeholder}
      variant="standard"
      fullWidth
      inputProps={{
        'aria-label': fm({ defaultMessage: 'Search' }),
      }}
      InputProps={{
        disableUnderline,
        startAdornment: <IconSearch fontSize={size} />,
        endAdornment:
          enableClear && searchValue ? (
            <IconButton
              onClick={handleClear}
              aria-label={fm({ defaultMessage: 'Clear Search' })}
            >
              <IconX />
            </IconButton>
          ) : undefined,
      }}
      inputRef={ref}
      sx={[
        {
          '& .MuiSvgIcon-root:has(+ input)': {
            marginRight: size === 'small' ? 1 : 2,
            color: (theme) =>
              lighten(alpha(theme.palette.text.secondary, 1), 0.74),
          },
        },
        disableUnderline && {
          '& .MuiInput-input': {
            // remove extra padding under the input when underline is hidden
            paddingBottom: 0,
          },
        },
      ]}
    />
  );
};

export const SearchBarInput = forwardRef<HTMLInputElement, SearchBarInputProps>(
  BaseSearchBarInput
);
