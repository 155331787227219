import { isNil as _isNil } from 'lodash-es';

import { TResourceList } from '../types';

/**
 * Helper to list all records of a resource.
 *
 * TODO: update to use page_id where possible
 */
export async function listAllResource<T, R extends TResourceList<T>>(
  fetchPage: (pageToken?: number, pageId?: string) => Promise<R>,
  options?: { signal?: AbortSignal }
) {
  // allow caller to cancel request on timeout
  let isCancelled = false;
  if (options?.signal) {
    if (options.signal.aborted) {
      isCancelled = true;
    }

    options.signal.addEventListener('abort', () => {
      isCancelled = true;
    });
  }

  const results: T[] = [];

  let pageId;
  let pageToken;
  while (!isCancelled) {
    const r = await fetchPage(pageToken, pageId);

    // minimal validation for response
    if (_isNil(r.list) || 'object' !== typeof r.list) {
      break;
    }

    const { objects, response } = r.list;

    if (objects?.length) {
      results.push(...objects);
    }

    if (!response?.next_page_token && !response?.next_page_id) {
      // no remaining pages, break here
      break;
    }

    pageId = response.next_page_id;
    pageToken = response.next_page_token;
  }

  return results;
}
