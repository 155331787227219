import { FormattedDate } from 'react-intl';

import { DateTimeComponentProps } from './types';

/**
 * DateDisplay presents the combined date and time of a timestamp in a consistent, internationalized format.
 */
export const DateTimeDisplay = ({ value }: DateTimeComponentProps) => {
  return <FormattedDate value={value} dateStyle="medium" timeStyle="short" />;
};
