import { Alert, Stack, useTheme } from '@mui/material';
import { useSearch } from '@tanstack/react-location';
import { ReactNode } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { MarketingHeading } from './AuthHelperComponents';

export const LoginErrorDisplay = () => {
  const { space } = useTheme();

  const { code } = useSearch();

  type ErrorCode = '410' | '406' | '403';

  const errorTitles = defineMessages({
    [403]: { defaultMessage: 'Oops, please try again!' },
    [406]: {
      defaultMessage: 'Oops, please enter your email again!',
    },
    [410]: {
      defaultMessage: 'Oops, please enter your email again!',
    },
  });

  const errorMessages = defineMessages({
    // TODO: We may need a way to distinguish bad email logins from bad OAuth logins.
    [403]: {
      defaultMessage:
        'It looks like the special link we emailed you is not working as expected. Please try opening the link in the <b>same browser window</b> or request a new link.',
      values: { b: (str: ReactNode) => <b>{str}</b> },
    },
    [406]: {
      defaultMessage:
        'It looks like the email address provided is not a business email. Please try again by entering your work email address.',
    },
    [410]: {
      defaultMessage:
        'It looks like the special link we emailed you is no longer valid. Please request another one by entering your work email address.',
    },
  });

  const errorTitle = errorTitles[code as ErrorCode] ?? errorTitles[403];
  const errorMessage = errorMessages[code as ErrorCode] ?? errorMessages[403];

  return (
    <Stack spacing={space.sm}>
      <MarketingHeading text={<FM {...errorTitle} />} />

      <Alert severity="warning">
        <FM {...errorMessage} />
      </Alert>
    </Stack>
  );
};
