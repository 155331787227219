import {
  Box,
  Divider,
  FormControlLabel,
  Skeleton,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { head as _head } from 'lodash-es';
import {
  KeyboardEvent,
  lazy,
  Suspense,
  SyntheticEvent,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { UserInfoResponseTenantInfo as TenantInfo } from '@endorlabs/api_client';
import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import { useCountNamespaces, useUserPreferences } from '@endorlabs/queries';
import { IconCheck, Link, SearchBarInput } from '@endorlabs/ui-common';

import { getNamespacesRootPath } from '../../../../routes';
import { NamespaceSwitcherListView } from './NamespaceSwitcherListView';

const NamespaceSwitcherTreeViewLazy = lazy(() =>
  import('./NamespaceSwitcherTreeView').then((m) => ({
    default: m.NamespaceSwitcherTreeView,
  }))
);

const NamespaceSwitcherTreeViewSkeleton = () => (
  <Stack direction="column" spacing={1} paddingY={1} paddingX={2}>
    <Skeleton variant="rounded" width="100%" />
    <Skeleton variant="rounded" width="100%" />
    <Skeleton variant="rounded" width="100%" />
  </Stack>
);

const useIsEmptyStateCheck = (
  tenants: TenantInfo[],
  options: { enabled: boolean }
) => {
  const isOnlyTenant = tenants.length === 1;
  const qCountNamespaces = useCountNamespaces(_head(tenants)?.name ?? '', {
    enabled: isOnlyTenant && options.enabled,
  });

  return options.enabled && isOnlyTenant && qCountNamespaces.data?.count === 0;
};

export type NamespaceSwitcherProps = {
  activeNamespace: string;
  disableEmptyState?: boolean;
  hideHeaderContent?: boolean;
  namespaces?: string[];
  onSelect: (event: SyntheticEvent, namespace: string) => void;
  selectedNamespace?: string;
  tenants: TenantInfo[];
};

export const NamespaceSwitcher = ({
  activeNamespace,
  disableEmptyState = false,
  hideHeaderContent = false,
  namespaces,
  onSelect,
  selectedNamespace,
  tenants,
}: NamespaceSwitcherProps) => {
  const [searchValue, setSearchValue] = useState('');
  const { palette, space } = useTheme();

  const hasSearchValue = !!searchValue;

  const contentRef = useRef<HTMLUListElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  const [authPreferences, setAuthPreferences] = useUserPreferences((s) => [
    s.auth,
    s.setAuthPreferences,
  ]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!contentRef.current) return;

    // on arrow down, move focus to content
    if (event.code === 'ArrowDown') {
      const listItem = contentRef.current.querySelector(
        'li:first-child'
      ) as HTMLLIElement | null;

      if (listItem) {
        event.preventDefault();
        listItem.focus();
      }
    }
  };

  const isEmptyState = useIsEmptyStateCheck(tenants, {
    enabled: !disableEmptyState,
  });

  return (
    <Stack
      className="NamespaceSwitcherContent-root"
      direction="column"
      spacing={1}
      paddingBottom={1}
      width="inherit"
    >
      <Box padding={2} paddingBottom={1}>
        <SearchBarInput
          enableClear
          onKeyDown={handleKeyDown}
          onSearch={setSearchValue}
          ref={searchRef}
          searchEvent="onKeyUp"
          searchValue={searchValue}
          size="small"
        />
      </Box>

      <Divider />

      {!hideHeaderContent && (
        <Stack
          className="NamespaceSwitcherContent-header"
          paddingX={space.sm}
          paddingY={space.xs}
          spacing={space.md}
          sx={{ borderBottom: `1px solid ${palette.divider}` }}
        >
          <Stack spacing={1}>
            <Typography variant="h4">
              <FM defaultMessage="Tenant" />
            </Typography>

            <Stack
              alignItems="center"
              direction="row"
              spacing={space.xs}
              sx={{ color: palette.brand.main }}
            >
              <IconCheck />
              <Typography>{getRootNamespace(activeNamespace)}</Typography>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h4">
              <FM defaultMessage="Namespaces" />
            </Typography>

            <Tooltip
              title={
                <FM defaultMessage="In all pages, include data or objects for this namespace and all its children" />
              }
            >
              <FormControlLabel
                id="toggle-include-child-namespaces"
                control={
                  <Switch
                    checked={authPreferences?.includeChildNamespaces}
                    onChange={(evt) =>
                      setAuthPreferences({
                        includeChildNamespaces: evt.target.checked,
                      })
                    }
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ letterSpacing: '' }} variant="body2">
                    <FM defaultMessage="Include All Child Namespaces" />
                  </Typography>
                }
              />
            </Tooltip>
          </Stack>
        </Stack>
      )}

      {/* When search value is not present, display as tree */}
      {!isEmptyState && !hasSearchValue && (
        <Suspense fallback={<NamespaceSwitcherTreeViewSkeleton />}>
          <NamespaceSwitcherTreeViewLazy
            activeNamespace={activeNamespace}
            namespaces={namespaces}
            onSelect={onSelect}
            ref={contentRef}
            selectedNamespace={selectedNamespace}
            tenants={tenants}
          />
        </Suspense>
      )}

      {!isEmptyState && hasSearchValue && (
        <NamespaceSwitcherListView
          activeNamespace={activeNamespace}
          namespaces={namespaces}
          ref={contentRef}
          searchValue={searchValue}
          tenants={tenants}
          onSelect={onSelect}
        />
      )}

      {/* When empty state, display help and link to manage Namespace Page */}
      {isEmptyState && (
        <Stack
          direction="column"
          paddingX={1}
          paddingY={2}
          spacing={2}
          textAlign="center"
        >
          <Typography>
            <FM defaultMessage="You do not have any namespaces." />
          </Typography>

          <Typography>
            <FM
              defaultMessage="To create and manage namespaces, go to <link>Namespaces</link>"
              values={{
                link: (value) => (
                  <Link
                    to={getNamespacesRootPath({
                      tenantName: activeNamespace ?? '',
                    })}
                  >
                    {value}
                  </Link>
                ),
              }}
            />
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};
