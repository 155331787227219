import { V1CustomTemplateType } from '@endorlabs/api_client';

import { useNotificationTargetTemplateData } from '../hooks/useNotificationTargetTemplateData';
import { FormUpsertCustomNotificationTemplate } from './FormUpsertCustomNotificationTemplate';

export const EditNotificationTargetCustomTemplate = ({
  templateType,
  tenantName,
  notificationTargetUuid,
  onCancel,
}: {
  templateType: V1CustomTemplateType;
  tenantName: string;
  notificationTargetUuid?: string;
  onCancel?: () => void;
}) => {
  const { defaultTemplate, template, saveTemplate } =
    useNotificationTargetTemplateData({
      templateType,
      tenantName,
      notificationTargetUuid,
    });
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };
  return (
    <FormUpsertCustomNotificationTemplate
      defaultValues={defaultTemplate}
      onCancel={handleCancel}
      onSubmit={saveTemplate}
      values={template}
    />
  );
};
