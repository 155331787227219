import {
  ChecklistInputOption,
  ControlledChecklist,
} from '@endorlabs/ui-common';

export interface EnabledFeaturesFieldsProps {
  featureRecords: ChecklistInputOption[];
}

export const EnabledFeaturesFields = ({
  featureRecords,
}: EnabledFeaturesFieldsProps) => {
  return (
    <ControlledChecklist
      dense={false}
      name="spec.enabled_features"
      options={featureRecords}
    />
  );
};
