import { useMatch } from '@tanstack/react-location';

import { ProjectResource } from '@endorlabs/endor-core/Project';

import { ProjectVersionsIndexPage } from '../../../../../../views/ProjectVersion/ProjectVersionsIndexPage';
import { createFileRoute } from '../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionsIndexRoute = () => {
  const {
    data: { project },
  } = useMatch();

  return <ProjectVersionsIndexPage project={project as ProjectResource} />;
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/'
)({
  component: TenantProjectVersionsIndexRoute,
});
