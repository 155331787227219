import { Box, Fade, IconButton, IconButtonProps } from '@mui/material';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

import { useFileDownload, UseFileDownloadProps } from '../../hooks';
import { IconCheck } from '../../themes';
import { IconDownload } from '../../themes';

export interface FileDownloadButtonProps
  extends Pick<IconButtonProps, 'disabled' | 'size'>,
    UseFileDownloadProps {
  data?: string;
}

export const FileDownloadButton = ({
  data = '',
  disabled,
  filename,
  filetype,
  size,
}: FileDownloadButtonProps) => {
  const [isDownloaded, downloadData, setIsDownloaded] = useFileDownload({
    filetype,
    filename,
  });
  const { formatMessage: fm } = useIntl();

  const handleDownload = () => {
    downloadData(data);

    setTimeout(() => {
      setIsDownloaded(false);
    }, 1800);
  };

  const cn = clsx(isDownloaded && 'success');

  return (
    <IconButton
      aria-label={fm({ defaultMessage: 'Download' })}
      className={cn}
      onClick={handleDownload}
      sx={{ position: 'relative' }}
      size={size}
      disabled={disabled}
    >
      <IconDownload fontSize="inherit" />
      <Fade in={isDownloaded}>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: 'success.main',
            border: '2px solid white',
            borderRadius: '50%',
            color: 'common.white',
            display: 'flex',
            fontSize: '12px',
            height: '1.6em',
            justifyContent: 'center',
            padding: '6px',
            position: 'absolute',
            right: '-0.2em',
            top: '0.3em',
            width: '1.6em',
            // For the small icon button, shrink the copied icon as well
            '.MuiIconButton-sizeSmall &': {
              fontSize: '8px',
              height: '1.2em',
              width: '1.2em',
              top: '0.2em',
            },
          }}
        >
          <IconCheck
            aria-label={fm({ defaultMessage: 'Copy successful' })}
            fontSize="inherit"
          />
        </Box>
      </Fade>
    </IconButton>
  );
};
