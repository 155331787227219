import {
  QueryKey,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  NamespaceServiceApi,
  UserInfoResponseTenantInfo,
  V1CountResponse,
  V1ListParameters,
  V1Namespace,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { NamespaceResource } from '@endorlabs/endor-core/Namespace';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceListResponse,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface NamespaceReadParams {
  /**
   * The "parent" namespace for the target namespace.
   */
  namespace: string;
  uuid: string;
}

export interface NamespaceWriteParams {
  /**
   * The "parent" namespace for the namespace being created.
   */
  namespace: string;
  resource: V1Namespace;
}

export interface NamespaceUpdateParams extends NamespaceWriteParams {
  mask?: string;
}

type CountNamespaceOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListNamespaceOptions = ResourceQueryOptions<
  ResourceListResponse<NamespaceResource>
>;
type GetNamespaceOptions = ResourceQueryOptions<NamespaceResource>;
type UpsertNamespaceOptions = ResourceMutateOptions<
  V1Namespace,
  NamespaceWriteParams
>;
type DeleteNamespaceOptions = ResourceMutateOptions<
  object,
  NamespaceReadParams
>;

const BASE_KEY = 'v1/namespaces';

const QK = {
  count: (
    parentNamespace: string,
    listParams: V1ListParameters = {}
  ): QueryKey => [BASE_KEY, 'count', parentNamespace, listParams] as const,
  list: (
    parentNamespace: string,
    listParams: V1ListParameters = {}
  ): QueryKey => [BASE_KEY, 'list', parentNamespace, listParams] as const,
  record: (parentNamespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', parentNamespace, uuid] as const,
};

export const NamespacesQueryKeys = QK;

export const NAMESPACE_UPDATE_MASK = 'meta,spec';

const getApiService = () => new NamespaceServiceApi(getClientConfiguration());

export const countNamespaces = async (
  parentNamespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.namespaceServiceListNamespaces(
    parentNamespace,
    ...buildCountParamArgs(listParams)
  );
  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountNamespaces = (
  parentNamespace: string,
  opts: CountNamespaceOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Namespace',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(parentNamespace, requestParameters),
    () => countNamespaces(parentNamespace, requestParameters),
    opts
  );
};

export const listNamespaces = async (
  parentNamespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.namespaceServiceListNamespaces(
    parentNamespace,
    ...buildListParamArgs(listParams),
    { signal }
  );
  return resp.data.list as ResourceListResponse<NamespaceResource>;
};

export const useListNamespaces = (
  parentNamespace: string,
  opts: ListNamespaceOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Namespace',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(parentNamespace, requestParameters),
    (ctx) => listNamespaces(parentNamespace, requestParameters, ctx.signal),
    opts
  );
};

export const getNamespace = async ({
  namespace: parentNamespace,
  uuid,
}: NamespaceReadParams) => {
  const api = getApiService();
  const resp = await api.namespaceServiceGetNamespace(parentNamespace, uuid);
  return resp.data as NamespaceResource;
};

export const useGetNamespace = (
  params: NamespaceReadParams,
  opts: GetNamespaceOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getNamespace(params),
    opts
  );
};

const createNamespace = async (params: NamespaceWriteParams) => {
  const api = getApiService();
  const resp = await api.namespaceServiceCreateNamespace(
    params.namespace,
    params.resource
  );
  return resp.data;
};

export const useCreateNamespace = (opts: UpsertNamespaceOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Namespace'),
    mutationFn: (params: NamespaceWriteParams) => createNamespace(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.count(vars.namespace));
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateNamespace = async (params: NamespaceUpdateParams) => {
  const { resource, namespace, mask = NAMESPACE_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.namespaceServiceUpdateNamespace(namespace, req);
  return resp.data;
};

export const useUpdateNamespace = (opts: UpsertNamespaceOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Namespace'),
    mutationFn: (params: NamespaceUpdateParams) => updateNamespace(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.count(vars.namespace));
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteNamespace = async (params: NamespaceReadParams) => {
  const api = getApiService();
  const resp = await api.namespaceServiceDeleteNamespace(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteNamespace = (opts: DeleteNamespaceOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Namespace'),
    mutationFn: (params: NamespaceReadParams) => deleteNamespace(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.count(vars.namespace));
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

/**
 * Custom query for child namespaces from the given tenants
 */
export const useListTenantNamespaces = (
  tenants: UserInfoResponseTenantInfo[],
  listParams: ListRequestParameters,
  options: ListNamespaceOptions = {}
) => {
  const baseRequestParameters = useBuildReadRequestParameters(
    'Namespace',
    'LIST',
    listParams,
    options
  );

  const queries = tenants.map((tenantInfo) => {
    const namespace = tenantInfo.name as string;
    const requestParameters: V1ListParameters = {
      // default to not traverse into the tenant namespaces
      traverse: false,
      ...baseRequestParameters,
    };

    return {
      queryKey: QK.list(namespace, requestParameters),
      queryFn: (ctx) =>
        listNamespaces(namespace, requestParameters, ctx.signal),
      placeholderData: () => {
        return {
          objects: [
            {
              uuid: tenantInfo.uuid,
              meta: { name: namespace },
              spec: { full_name: namespace },
            },
          ],
        };
      },
      ...options,
    } as ListNamespaceOptions;
  });

  const results = useQueries(queries);

  const isLoading = !!results.length && results.every((r) => r.isLoading);
  const isFetching = !!results.length && results.some((r) => r.isFetching);
  const isSuccess = results.some((r) => r.isSuccess);

  // TODO: better memoize data directly from from useQueries calls
  // see: https://github.com/TanStack/query/discussions/5123
  const data = results.flatMap((r) => r.data?.objects ?? []);

  return { data, isFetching, isLoading, isSuccess };
};
