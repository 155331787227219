import { Typography } from '@mui/material';
import { FormattedMessage as FM, MessageDescriptor } from 'react-intl';

interface StepHeadingProps {
  msg: MessageDescriptor;
}

export const StepHeading = ({ msg }: StepHeadingProps) => {
  return (
    <Typography sx={{ '& b': { color: 'text.secondary' } }} variant="h4">
      <FM
        {...msg}
        values={{
          b: (t) => <b>{t}</b>,
        }}
      />
    </Typography>
  );
};
