import { useIntl } from 'react-intl';

import { Pip } from '@endorlabs/ui-common';

import { RemediationRiskColorMap } from '../../constants';
import { RemediationRiskMessages } from '../../locales';
import { RemediationRisk } from '../../types';

export interface RemediationRiskDisplayProps {
  remediationRisk: RemediationRisk;
  size?: 'xsmall' | 'small' | 'medium';
}

export const RemediationRiskDisplay = ({
  remediationRisk,
  size = 'xsmall',
}: RemediationRiskDisplayProps) => {
  const { formatMessage: fm } = useIntl();
  if (!remediationRisk) return null;
  const label = RemediationRiskMessages[remediationRisk]
    ? fm(RemediationRiskMessages[remediationRisk])
    : remediationRisk;
  return (
    <Pip
      color={RemediationRiskColorMap[remediationRisk] ?? 'default'}
      label={label}
      size={size}
    />
  );
};
