import { Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ScoreDisplay } from '@endorlabs/ui-common';

export interface ScoreCardDisplayItemProps {
  chart?: ReactNode;
  colorize?: boolean;
  isLoading?: boolean;
  nilDisplay?: ReactNode;
  score?: number;
  showChart?: boolean;
  showTotal?: boolean;
  title?: ReactNode;
  size?: 'large' | 'xlarge';
  totalSize?: 'small' | 'large' | 'xlarge';
}

export const ScoreCardDisplayItem = ({
  chart,
  colorize = false,
  isLoading = false,
  nilDisplay,
  score,
  size = 'large',
  showChart = false,
  showTotal = false,
  title,
  totalSize = size,
}: ScoreCardDisplayItemProps) => {
  const { space, spacing, typography } = useTheme();

  const chartContent = showChart ? (
    isLoading || !chart ? (
      <ChartSkeleton />
    ) : (
      chart
    )
  ) : undefined;

  const totalVariant =
    totalSize === 'small' ? 'h2' : totalSize === 'large' ? 'xl' : 'xl';

  return (
    <Grid container direction="column" spacing={space.xs}>
      {title && (
        <Grid alignItems="flex-end" flexGrow={1} flexShrink={0} item xs={12}>
          <Typography variant="h4">
            {title ?? <Skeleton width="70%" />}
          </Typography>
        </Grid>
      )}

      <Grid
        alignItems="flex-end"
        container
        flexWrap="nowrap"
        item
        spacing={space.sm}
        xs={12}
      >
        {/* SCORE */}
        <Grid item>
          <Stack
            alignItems="baseline"
            direction="row"
            minHeight={typography.xl.fontSize}
          >
            <ScoreDisplay
              colorize={colorize}
              nilDisplay={
                nilDisplay ?? (
                  <Typography
                    alignItems="center"
                    display="flex"
                    minHeight={38}
                    variant="h3"
                  >
                    {isLoading ? (
                      <Skeleton height={typography.xl.fontSize} width="1.2em" />
                    ) : (
                      <FM defaultMessage="N/A" />
                    )}
                  </Typography>
                )
              }
              size={size === 'large' ? 'large' : 'xlarge'}
              value={score}
              variant="summary"
            />

            {showTotal && score !== undefined && (
              <Typography
                color="text.secondary"
                component="span"
                variant={totalVariant}
              >
                / 10
              </Typography>
            )}
          </Stack>
        </Grid>

        {/* CHART */}
        {showChart && chartContent && <Grid item>{chartContent}</Grid>}
      </Grid>
    </Grid>
  );
};

const ChartSkeleton = () => {
  return (
    <Stack direction="row" spacing="2px" alignItems="flex-end">
      {[4, 6, 7, 5, 7, 6, 7, 9].map((score, i) => {
        return (
          <Skeleton
            height={score * 3}
            sx={{
              borderRadius: 0,
              transform: 'none',
            }}
            key={i}
            width={4}
          />
        );
      })}
    </Stack>
  );
};
