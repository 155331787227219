import {
  Stack,
  StepIcon,
  StepLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export const StepHeaderButton = ({
  stepNumber,
  text,
}: {
  stepNumber: number;
  text: ReactNode;
}) => {
  const { palette, space, typography } = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={space.xs}
      sx={{
        backgroundColor: palette.background.paper,
        height: '100%',
        paddingRight: space.sm,
      }}
    >
      <StepIcon active icon={stepNumber + 1} />
      <StepLabel>
        <Typography
          sx={{
            ...typography.button,
            color: palette.brand.main,
          }}
        >
          {text}
        </Typography>
      </StepLabel>
    </Stack>
  );
};
