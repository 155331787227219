import { FieldError, FieldName, ResolverResult } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { NotificationTargetActionActionType } from '@endorlabs/api_client';

import { FormUpsertNotificationTargetFieldValues } from './types';

export const useNotificationTargetValidator = () => {
  const { formatMessage: fm } = useIntl();

  const validate = (
    values: FormUpsertNotificationTargetFieldValues
  ): ResolverResult<FormUpsertNotificationTargetFieldValues> => {
    if (
      values.spec.action.action_type ===
      NotificationTargetActionActionType.Vanta
    ) {
      const vantaConfig = values.spec.action.vanta_config;

      // Validate that at least one of the resource fields is present
      if (
        !vantaConfig?.package_vulnerability_resource_id &&
        !vantaConfig?.sca_resource_id
      ) {
        const fieldError: FieldError = {
          type: 'validate',
          message: fm({
            defaultMessage:
              'A value is required for either Package Vulnerability or Static Code Analysis Vulnerability',
          }),
        };

        const errors: Record<
          FieldName<FormUpsertNotificationTargetFieldValues>,
          FieldError
        > = {
          'spec.action.vanta_config.package_vulnerability_resource_id':
            fieldError,
          'spec.action.vanta_config.sca_resource_id': fieldError,
        };

        return {
          values: {},
          errors,
        };
      }
    }

    return {
      values,
      errors: {},
    };
  };

  return { validate };
};
