import { get as _get } from 'lodash-es';

import { V1Ecosystem, V1PlatformSource } from '@endorlabs/api_client';

import { BinaryProjectResource, ProjectResource } from '../types';

export const isBinaryProject = (
  p: ProjectResource
): p is BinaryProjectResource => {
  return (
    !!p && _get(p, ['spec', 'platform_source']) === V1PlatformSource.Binary
  );
};

export const isContainerProject = (
  project: ProjectResource,
  ecosystems?: V1Ecosystem[]
): project is BinaryProjectResource => {
  return (
    isBinaryProject(project) &&
    !!ecosystems?.length &&
    ecosystems.every((e) => e === V1Ecosystem.Container)
  );
};
