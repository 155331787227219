import { Grid, Stack, Typography, useTheme } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  FindingResource,
  getFindingDiscovered,
  getFindingSecurityAdvisoryLabel,
  getFirstFindingSecurityAdvisoryUrl,
  getSecurityFindingFixVersion,
  getSecurityFindingIntroducedVersion,
  isCallgraphPresent,
  isMalwareFinding,
  isReachableDependency,
  isSecurityFinding,
  isVulnerabilityFinding,
} from '@endorlabs/endor-core/Finding';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  QueryFindingsResponseObject,
  useFeatureFlags,
  useUpdateFinding,
} from '@endorlabs/queries';
import {
  AttributeDisplay,
  ButtonSecondary,
  DateDisplay,
  ExternalLink,
  FormattedTypography,
  IconSidebarRight,
  NilDisplay,
  SimpleMenu,
  useDialog,
} from '@endorlabs/ui-common';

import { useFindingCallPathsDrawer } from '../../../components';
import { getFindingsPath } from '../../../routes';
import { ExceptionPolicyCreateDialog } from '../../Policies/components/ExceptionPolicyCreateDialog';
import { useFindingDetailDrawer } from '../hooks';

/**
 * Replaces {@see FindingSummaryAccordionContent}
 */
export const FindingSummaryAccordionContent = ({
  finding,
}: {
  finding: QueryFindingsResponseObject;
}) => {
  const { space } = useTheme();

  const { ENABLE_IA_1_PAGE_IMPROVEMENTS } = useFeatureFlags();

  const { DetailDrawer: FindingDetailDrawer, permalinkEffect } =
    useFindingDetailDrawer();
  const FindingCallPathsDrawer = useFindingCallPathsDrawer();

  useLayoutEffect(
    () => FindingCallPathsDrawer.permalinkEffect([finding]),
    [finding, FindingCallPathsDrawer]
  );

  const isOssFinding = finding.tenant_meta.namespace === NAMESPACES.OSS;

  const findingNamespace = finding.tenant_meta.namespace;
  const findingDiscovered = getFindingDiscovered(finding);
  const findingLink =
    !isOssFinding &&
    getFindingsPath({
      tenantName: finding.tenant_meta.namespace,
      uuid: finding.uuid,
    });

  const findingSecurityAdvisory = getFindingSecurityAdvisoryLabel(finding);
  const findingSecurityAdvisoryUrl =
    getFirstFindingSecurityAdvisoryUrl(finding);

  const securityFindingFixVersion = getSecurityFindingFixVersion(finding);
  const securityFindingIntroducedVersion = getSecurityFindingIntroducedVersion(
    finding
  ) ?? <NilDisplay />;

  const [dismissed, setDismissed] = useState<boolean>(finding.spec.dismiss);
  const qUpdateFinding = useUpdateFinding({
    // NOTE: prevent success notification, and hide modal
    onSuccess: (data) => setDismissed(data.spec.dismiss ?? false),
  });

  const handleToggleDismiss = useCallback(() => {
    const partialFindingSpec: Partial<FindingResource['spec']> = {
      dismiss: !dismissed,
    };

    qUpdateFinding.mutate({
      namespace: finding.tenant_meta.namespace,
      resource: {
        uuid: finding.uuid,
        spec: partialFindingSpec as FindingResource['spec'],
        meta: finding.meta,
        context: finding.context,
      },
      mask: 'spec.dismiss',
    });
  }, [dismissed, finding, qUpdateFinding]);

  const exceptionPolicyCreateDialog = useDialog({
    component: ExceptionPolicyCreateDialog,
  });

  // Only show actions for tenant findings
  const showFindingActions = !isOssFinding;

  const findingActions = useMemo(() => {
    return [
      {
        disabled: qUpdateFinding.isLoading,
        key: 'dismiss',
        label: dismissed ? (
          <FM defaultMessage="Undo Dismiss" />
        ) : (
          <FM defaultMessage="Dismiss Finding" />
        ),
      },
      {
        key: 'exception-policy',
        label: <FM defaultMessage="Add Exception" />,
      },
    ];
  }, [dismissed, qUpdateFinding.isLoading]);

  const handleFindingAction = (_: SyntheticEvent, action: { key: string }) => {
    if (action.key === 'dismiss') {
      return handleToggleDismiss();
    }

    if (action.key === 'exception-policy') {
      const packageVersions =
        finding.meta.references.PackageVersion?.list?.objects;
      exceptionPolicyCreateDialog.openDialog({
        namespace: findingNamespace,
        findings: [finding],
        packageVersions,
      });
    }
  };

  // Only show more details button for a finding when:
  // - Not a securty finding
  // - Is a security finding AND is a vulnerability
  const showMoreDetailsButton =
    !isSecurityFinding(finding) ||
    isVulnerabilityFinding(finding) ||
    isMalwareFinding(finding);

  // Only show call path if finding is reachable && has call graph path
  const showCallPathsButton =
    !ENABLE_IA_1_PAGE_IMPROVEMENTS &&
    !isMalwareFinding(finding) &&
    isReachableDependency(finding) &&
    isCallgraphPresent(finding);

  return (
    <Stack
      marginTop={-4} // offset spacing from accordion summary
      spacing={space.sm}
    >
      <Grid container direction="row" spacing={space.lg} width="100%">
        <Grid item md={6} sm={12} sx={{ '&.MuiGrid-item': { paddingLeft: 0 } }}>
          <Stack spacing={space.sm}>
            <AttributeDisplay
              disableTypography={true}
              heading={<FM defaultMessage="Summary" />}
              value={
                <FormattedTypography
                  text={finding.spec.summary}
                  variant="body2"
                />
              }
            />

            <AttributeDisplay
              disableTypography={true}
              heading={<FM defaultMessage="Remediation" />}
              value={
                <FormattedTypography
                  text={finding.spec?.remediation}
                  variant="body2"
                />
              }
            />
          </Stack>
        </Grid>

        {isSecurityFinding(finding) && (
          <Grid item md={3} sm={6}>
            <Stack spacing={space.sm}>
              <AttributeDisplay
                heading={<FM defaultMessage="Introduced Version" />}
                value={securityFindingIntroducedVersion}
              />

              <AttributeDisplay
                heading={<FM defaultMessage="Fixed Version" />}
                value={securityFindingFixVersion}
              />
            </Stack>
          </Grid>
        )}

        <Grid item md={3} sm={6}>
          <Stack spacing={space.sm}>
            {isVulnerabilityFinding(finding) && (
              <AttributeDisplay
                heading={<FM defaultMessage="First Discovered" />}
                value={
                  findingDiscovered ? (
                    <DateDisplay value={findingDiscovered} />
                  ) : (
                    <NilDisplay />
                  )
                }
              />
            )}

            <AttributeDisplay
              heading={<FM defaultMessage="First Scanned" />}
              value={
                finding ? (
                  <DateDisplay value={finding.meta.create_time} />
                ) : (
                  <NilDisplay />
                )
              }
            />

            {findingSecurityAdvisory && (
              <AttributeDisplay
                heading={<FM defaultMessage="Advisory" />}
                value={
                  findingSecurityAdvisoryUrl ? (
                    <ExternalLink to={findingSecurityAdvisoryUrl}>
                      {findingSecurityAdvisory}
                    </ExternalLink>
                  ) : (
                    <Typography sx={{ lineBreak: 'break-all' }}>
                      {findingSecurityAdvisory}
                    </Typography>
                  )
                }
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row" spacing={4}>
        {showFindingActions && (
          <SimpleMenu
            id={`finding-actions-${finding.uuid}`}
            onClick={handleFindingAction}
            options={findingActions}
            triggerTitle={<FM defaultMessage="Actions" />}
            triggerVariant="button"
          />
        )}

        {showMoreDetailsButton && (
          <ButtonSecondary
            endIcon={<IconSidebarRight />}
            onClick={() => {
              FindingDetailDrawer.activate(
                { findingUuid: finding.uuid },
                {
                  finding,
                  findingUuid: finding.uuid,
                  namespace: finding.tenant_meta.namespace,
                }
              );
            }}
          >
            <FM defaultMessage="More Details" />
          </ButtonSecondary>
        )}

        {showCallPathsButton && (
          <ButtonSecondary
            endIcon={<IconSidebarRight />}
            id={`finding-callpath-${finding.uuid}`}
            onClick={() => {
              FindingCallPathsDrawer.activate(finding);
            }}
          >
            <FM defaultMessage="See Call Paths" />
          </ButtonSecondary>
        )}
      </Stack>

      <exceptionPolicyCreateDialog.Dialog
        {...exceptionPolicyCreateDialog.dialogProps}
      />
    </Stack>
  );
};
