import {
  ScanProfileServiceApi,
  V1CountResponse,
  V1ScanProfile,
} from '@endorlabs/api_client';
import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';

import {
  createResourceMutateHooks,
  createResourceReadHooks,
  ReadServiceRequestOptions,
} from './hooks';
import {
  ListParamsArray,
  ResourceGroupResponse,
  ResourceListResponse,
} from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

const DEFAULT_UPDATE_MASK = 'spec';

const queries = createResourceReadHooks('ScanProfile', () => {
  const getApiService = () =>
    new ScanProfileServiceApi(getClientConfiguration());

  // Handler wrapping shared list call transforms
  const listHandler = (
    namespace: string,
    args: ListParamsArray,
    options: ReadServiceRequestOptions
  ) =>
    getApiService().scanProfileServiceListScanProfiles(
      namespace,
      ...args,
      options
    );

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.count_response as Required<V1CountResponse>;
    },
    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp = await getApiService().scanProfileServiceGetScanProfile(
        namespace,
        ...args,
        options
      );
      return resp.data as ScanProfileResource;
    },
    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.group_response as ResourceGroupResponse;
    },
    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.list as ResourceListResponse<ScanProfileResource>;
    },
  };
});

const mutations = createResourceMutateHooks<ScanProfileResource>(
  'ScanProfile',
  () => {
    const getApiService = () =>
      new ScanProfileServiceApi(getClientConfiguration());

    return {
      async create(namespace, resource) {
        const resp = await getApiService().scanProfileServiceCreateScanProfile(
          namespace,
          resource as V1ScanProfile
        );
        return resp.data as ScanProfileResource;
      },
      async delete(namespace, resource) {
        await getApiService().scanProfileServiceDeleteScanProfile(
          namespace,
          resource.uuid
        );
      },
      async update(namespace, params) {
        const { resource, mask = DEFAULT_UPDATE_MASK } = params;
        const request = buildUpdateReq(resource, mask);
        const resp = await getApiService().scanProfileServiceUpdateScanProfile(
          namespace,
          request
        );

        return resp.data as ScanProfileResource;
      },
    };
  }
);

// Expose query option factories
export const getScanProfileQueryOptions = queries.getQueryOptions;

// Expose query hooks for ScanProfile
export const useCountScanProfiles = queries.useCount;
export const useGetScanProfile = queries.useGet;
export const useGroupScanProfiles = queries.useGroup;
export const useListScanProfiles = queries.useList;
export const useListAllScanProfiles = queries.useListAll;

// Expose mutation hooks for ScanProfile
export const useCreateScanProfile = mutations.useCreate;
export const useDeleteScanProfile = mutations.useDelete;
export const useUpdateScanProfile = mutations.useUpdate;
