import _omit from 'lodash-es/omit';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { ResourceKind } from '@endorlabs/endor-core';

import { DashboardChartFilterKeys } from '../constants/DashboardChartFilters';
import {
  DashboardBucketContext,
  DashboardBucketContexts,
  DashboardState,
  DashboardViewRecord,
} from '../types';

// Update on breaking changes. Existing store will be wiped & replaced.
const VERSION = 3;

// Exclude preferences from persistence as needed
const NON_PERSISTED_DASHBOARD_PREFERENCES: (keyof DashboardPreferencesStore)[] =
  [];

export interface DashboardPreferencesStore {
  bucketContext: DashboardBucketContext;
  views: DashboardViewRecord[];
  setDashboardPreferences: (preferences: Partial<DashboardState>) => void;
}

const DEFAULT_VIEWS = [
  {
    filterKeyActive: DashboardChartFilterKeys.ALL_FINDINGS,
    resourceKindPrimary: 'Project' as ResourceKind,
    viewId: 'TopProjects-1',
  },
  {
    filterKeyActive: DashboardChartFilterKeys.REACHABLE_VULNS,
    resourceKindPrimary: 'Project' as ResourceKind,
    viewId: 'TopProjects-2',
  },
  {
    filterKeyActive: DashboardChartFilterKeys.REACHABLE_VULNS,
    resourceKindPrimary: 'PackageVersion' as ResourceKind,
    viewId: 'TopPackages-1',
  },
  {
    filterKeyActive: DashboardChartFilterKeys.OUTDATED_DEPS,
    resourceKindPrimary: 'PackageVersion' as ResourceKind,
    viewId: 'TopPackages-2',
  },
  {
    filterKeyActive: DashboardChartFilterKeys.REACHABLE_VULNS,
    resourceKindPrimary: 'DependencyMetadata' as ResourceKind,
    viewId: 'TopDependencies-1',
  },
];

export const useDashboardPreferences = create(
  persist<DashboardPreferencesStore>(
    (setState): DashboardPreferencesStore => {
      return {
        bucketContext: DashboardBucketContexts.FINDING_SEVERITY,
        views: DEFAULT_VIEWS,
        setDashboardPreferences: (newState: Partial<DashboardState>) => {
          setState((state) => ({
            ...state,
            ...newState,
          }));
        },
      };
    },
    {
      migrate: (oldState) => ({
        ...(oldState as DashboardPreferencesStore),
        views: DEFAULT_VIEWS,
      }),
      name: 'user-dashboard-preferences-store',
      // only persist a subset of the store
      partialize: (state) =>
        _omit(
          state,
          NON_PERSISTED_DASHBOARD_PREFERENCES
        ) as DashboardPreferencesStore,
      version: VERSION,
    }
  )
);
