import { Components, Theme } from '@mui/material';

type AutocompleteComponentOverrides = Pick<
  Components<Theme>,
  'MuiAutocomplete'
>;

export const getAutocompleteOverrides = (): AutocompleteComponentOverrides => {
  return {
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: { paper: { elevation: 8 } },
      },
      styleOverrides: {
        paper: {
          // hides the popover content when empty, to prevent the box shadow
          // from appearing.
          '&:empty': {
            visibility: 'hidden',
          },
        },
      },
    },
  };
};
