import { createContext } from 'react';

import { GraphAdjacencyList, GraphNode } from '@endorlabs/utils/graph';

export type GraphData = {
  graph: GraphAdjacencyList;
  nodes: GraphNode[];
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const GraphDataContext = createContext<GraphData>(null!);
