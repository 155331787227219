import { Theme } from '@mui/material';

export const getTooltipOverrides = (theme: Theme) => {
  const { palette, spacing, typography } = theme;

  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.text.primary,
          color: palette.getContrastText(palette.text.primary),
          fontSize: typography.body2.fontSize,
          lineHeight: 1.4,
          padding: spacing(1.5, 2.5),
        },

        arrow: {
          color: palette.text.primary,
        },
      },
    },
  };
};
