/* eslint sort-keys: "error" */
/* eslint prettier/prettier: ["error", { quoteProps: "consistent" }] */

import { Box, SvgIconProps, useTheme } from '@mui/material';
import { JSXElementConstructor } from 'react';

import { IconRocketLaunchOutlined } from '../../themes';
import * as ToolLogos from '../../themes/icons/CiCdToolLogos';

export type ToolLogoDisplayProps = Pick<SvgIconProps, 'fontSize' | 'sx'> & {
  /**
   * Whether the icon is displayed on a dark background
   * or a light background
   */
  displayMode?: 'dark' | 'light';
  toolName: string;
};

/**
 * @private exported only for testing, and should not be used outside of this module
 */
export const TOOL_LOGO_MAP: Record<
  string,
  JSXElementConstructor<SvgIconProps>
> = {
  'AWS CloudFormation': ToolLogos.ToolLogoAwsCloudFormation,
  'AWS CodeBuild': ToolLogos.ToolLogoAwsCodeBuild,
  'Amazon ECR': ToolLogos.ToolLogoAwsEcr,
  'Ansible': ToolLogos.ToolLogoAnsible,
  'AppVeyor': ToolLogos.ToolLogoAppveyor,
  'Azure Container Registry': ToolLogos.ToolLogoAzureContainerRegistry,
  'Azure Pipelines': ToolLogos.ToolLogoAzurePipelines,
  'Azure Resource Manager': ToolLogos.ToolLogoAzureResourceGroup,
  'Bandit': ToolLogos.ToolLogoBandit,
  'Bitbucket Pipelines': ToolLogos.ToolLogoBitbucket,
  'Buddy': ToolLogos.ToolLogoBuddy,
  'Buildkite': ToolLogos.ToolLogoBuildkite,
  'Cargo Audit': ToolLogos.ToolLogoCargoAudit,
  'Cargo Deny': ToolLogos.ToolLogoCargoDeny,
  'Checkmarx': ToolLogos.ToolLogoCheckmarx,
  'Checkmarx KICS': ToolLogos.ToolLogoCheckmarx,
  'Checkmarx One': ToolLogos.ToolLogoCheckmarx,
  'Checkov': ToolLogos.ToolLogoCheckov,
  'CircleCI': ToolLogos.ToolLogoCircleci,
  'CodeQL': ToolLogos.ToolLogoCodeql,
  'CodeShip': ToolLogos.ToolLogoCodeship,
  'CodeTotal': ToolLogos.ToolLogoCodetotal,
  'Contrast Agents': ToolLogos.ToolLogoContrast,
  'Contrast Assess': ToolLogos.ToolLogoContrast,
  'Contrast Scan': ToolLogos.ToolLogoContrast,
  'Cosign': ToolLogos.ToolLogoCosign,
  'Debricked': ToolLogos.ToolLogoDebricked,
  'Dependabot': ToolLogos.ToolLogoDependabot,
  'Docker': ToolLogos.ToolLogoDocker,
  'DockerHub': ToolLogos.ToolLogoDocker,
  'Drone CI': ToolLogos.ToolLogoDrone,
  'Eclipse Steady': ToolLogos.ToolLogoEclipseSteady,
  'Endor Labs': ToolLogos.ToolLogoEndorLabs,
  'Fortify SAST': ToolLogos.ToolLogoFortify,
  'Git Secrets': ToolLogos.ToolLogoGitSecrets,
  'GitGuardian IaC Scanning': ToolLogos.ToolLogoGitGuardian,
  'GitGuardian Secret Detection': ToolLogos.ToolLogoGitGuardian,
  'GitHub Actions': ToolLogos.ToolLogoGithubActions,
  'GitHub Packages': ToolLogos.ToolLogoGithubPackages,
  'GitHub Secret Scanning': ToolLogos.ToolLogoGithubSecrets,
  'GitLab Container Scanning': ToolLogos.ToolLogoGitlab,
  'GitLab IaC Scanning': ToolLogos.ToolLogoGitlab,
  'GitLab Pipelines': ToolLogos.ToolLogoGitlab,
  'GitLab SAST': ToolLogos.ToolLogoGitlab,
  'GitLab SCA': ToolLogos.ToolLogoGitlab,
  'GitLab Secrets': ToolLogos.ToolLogoGitlab,
  'GitLeaks': ToolLogos.ToolLogoGitLeaks,
  'Google Artifact Registry': ToolLogos.ToolLogoGoogleArtifactRegistry,
  'Google Cloud Build': ToolLogos.ToolLogoGoogleCloudbuild,
  'HCL Software': ToolLogos.ToolLogoHCLSoftware,
  'JFrog Artifactory': ToolLogos.ToolLogoJfrog,
  'JFrog Frogbot': ToolLogos.ToolLogoJfrog,
  'JFrog Pipelines': ToolLogos.ToolLogoJfrog,
  'Jenkins': ToolLogos.ToolLogoJenkins,
  'Kubernetes': ToolLogos.ToolLogoKubernetes,
  'MegaLinter': ToolLogos.ToolLogoMegaLinter,
  'Mend': ToolLogos.ToolLogoWhitesource,
  'OWASP Dependency Check': ToolLogos.ToolLogoOwasp,
  'OX Security': ToolLogos.ToolLogoOxSecurity,
  'OpenSSF Scorecard': ToolLogos.ToolLogoOpenssf,
  'Orca Security Container Scanning': ToolLogos.ToolLogoOrca,
  'Orca Security FS Scaning': ToolLogos.ToolLogoOrca,
  'Orca Security IaC Security': ToolLogos.ToolLogoOrca,
  'Prisma Cloud': ToolLogos.ToolLogoPrismaCloud,
  'Pulumi': ToolLogos.ToolLogoPulumi,
  'Puppet': ToolLogos.ToolLogoPuppet,
  'Pyup': ToolLogos.ToolLogoPyup,
  'Quay': ToolLogos.ToolLogoQuay,
  'Rancher Compose': ToolLogos.ToolLogoRancher,
  'Renovate': ToolLogos.ToolLogoRenovate,
  'Retire.js': ToolLogos.ToolLogoRetireJS,
  'SaltStack': ToolLogos.ToolLogoSaltstack,
  'Screwdriver CI': ToolLogos.ToolLogoScrewdriver,
  'Semgrep': ToolLogos.ToolLogoSemgrep,
  'Serverless Framework': ToolLogos.ToolLogoServerless,
  'Snyk': ToolLogos.ToolLogoSnyk,
  'SonarQube': ToolLogos.ToolLogoSonarqube,
  'Sonatype': ToolLogos.ToolLogoSonatype,
  'SpotBugs': ToolLogos.ToolLogoSpotBugs,
  'StepSecurity': ToolLogos.ToolLogoStepSecurity,
  'Synopsys Black Duck': ToolLogos.ToolLogoBlackduck,
  'Synopsys Coverity': ToolLogos.ToolLogoCoverity,
  'TeamCity': ToolLogos.ToolLogoTeamcity,
  'Tekton': ToolLogos.ToolLogoTekton,
  'Tekton Chains': ToolLogos.ToolLogoTekton,
  'Tekton Dashboard': ToolLogos.ToolLogoTekton,
  'Tekton Operator': ToolLogos.ToolLogoTekton,
  'Tekton Pipelines': ToolLogos.ToolLogoTekton,
  'Terraform': ToolLogos.ToolLogoTerraform,
  'Travis CI': ToolLogos.ToolLogoTravisci,
  'Trivy': ToolLogos.ToolLogoTrivy,
  'Trufflehog': ToolLogos.ToolLogoTrufflehog,
  'Vagrant': ToolLogos.ToolLogoVargrant,
  'Veracode': ToolLogos.ToolLogoVeracode,
};

const TOOL_LOGO_KEYS = Object.keys(TOOL_LOGO_MAP);

/**
 * @private exported only for testing, and should not be used outside of this module
 */
export const _getMatchingToolName = (toolName: string): string | undefined => {
  if (toolName in TOOL_LOGO_MAP) return toolName;

  // Allow partial matching for sub-tools
  return TOOL_LOGO_KEYS.find((key) => toolName.startsWith(key + ' '));
};

export const ToolLogoDisplay = ({
  displayMode = 'light',
  toolName,
  ...svgIconProps
}: ToolLogoDisplayProps) => {
  const { palette, shape, spacing } = useTheme();
  const matchedName = _getMatchingToolName(toolName);
  const Logo = TOOL_LOGO_MAP[matchedName as string];

  return (
    <Box
      alignItems="center"
      display="flex"
      sx={
        displayMode === 'dark'
          ? {
              backgroundColor: palette.common.white,
              borderRadius: `${shape.borderRadius}px`,
              padding: spacing(1),
            }
          : {
              border: `1px solid ${palette.divider}`,
              borderRadius: `${shape.borderRadius}px`,
              padding: spacing(1),
            }
      }
    >
      {!Logo ? (
        <IconRocketLaunchOutlined
          htmlColor={displayMode === 'dark' ? palette.text.primary : undefined}
          {...svgIconProps}
        />
      ) : (
        <Logo {...svgIconProps} />
      )}
    </Box>
  );
};
