import { Box, Tooltip } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import { isNil as _isNil } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedRelativeTime } from 'react-intl';
import { Props as FormattedRelativeTimeProps } from 'react-intl/src/components/relative';

import { NilDisplay, NilDisplayProps } from '../NilDisplay';
import { DateTimeDisplay } from './DateTimeDisplay';
import { DateTimeComponentProps } from './types';

// Array representing one minute, hour, day, week, month, etc in seconds
const UNIT_CUTOFFS = [
  60,
  3600,
  86400,
  86400 * 7,
  86400 * 30,
  86400 * 365,
  Infinity,
];

// Array equivalent to the above but in the string representation of the units
const UNITS: FormattedRelativeTimeProps['unit'][] = [
  'second',
  'minute',
  'hour',
  'day',
  'week',
  'month',
  'year',
];

interface RelativeTimeDisplayProps extends DateTimeComponentProps {
  formatStyle?: Intl.RelativeTimeFormatStyle;
  nilDisplayProps?: NilDisplayProps;
}

/**
 * RelativeTimeDisplay presents, in words, the relative distance of now from a provided timestamp, and does so in a consistent, internationalized format.
 *
 * E.g. "2 days ago", "in 3 minutes".
 *
 * See https://formatjs.io/docs/react-intl/components/#formattedrelativetime
 */
export const RelativeTimeDisplay = ({
  formatStyle = 'narrow',
  nilDisplayProps,
  value,
}: RelativeTimeDisplayProps) => {
  const now = useMemo(() => new Date(), []);

  const output = useMemo(() => {
    const diffInSeconds = differenceInSeconds(new Date(value), now);

    // Grab the ideal cutoff unit
    const unitIndex = UNIT_CUTOFFS.findIndex(
      (cutoff) => cutoff > Math.abs(diffInSeconds)
    );

    // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
    // is one day in seconds, so we can divide our seconds by this to get the # of days
    const divisor = unitIndex ? UNIT_CUTOFFS[unitIndex - 1] : 1;

    return {
      unit: UNITS[unitIndex],
      value: Math.floor(diffInSeconds / divisor),
    };
  }, [now, value]);

  // handle an nil/empty date
  if (_isNil(value) || value === '') {
    return <NilDisplay variant="text" {...nilDisplayProps} />;
  }

  return (
    <Tooltip title={<DateTimeDisplay value={value} />}>
      <Box component="span">
        <FormattedRelativeTime
          style={formatStyle}
          unit={output.unit}
          value={output.value}
        />
      </Box>
    </Tooltip>
  );
};
