import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  useGetFinding,
  useListPackageVersions,
  useListProjects,
} from '@endorlabs/queries';

import { InfoDrawerContent } from '../InfoDrawer';
import { FindingCallPathsDrawerContent } from './FindingCallPathsDrawerContent';

export interface FindingCallPathsDrawerProps {
  namespace: string;
  uuid: string;
}

export const FindingCallPathsDrawer = ({
  uuid,
  namespace,
}: FindingCallPathsDrawerProps) => {
  // load data for drawer content
  const qFinding = useGetFinding({ namespace, uuid });

  const qFindingPackageVersion = useListPackageVersions(
    namespace,
    { enabled: qFinding.isSuccess },
    {
      filter: [
        `uuid=="${qFinding.data?.meta.parent_uuid}"`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      mask: 'uuid,meta.name',
      // should only be one
      page_size: 1,
    }
  );

  const qFindingProject = useListProjects(
    namespace,
    { enabled: qFinding.isSuccess },
    {
      filter: `uuid=="${qFinding.data?.spec.project_uuid}"`,
      mask: 'uuid,meta.name,spec.platform_source',
      // should only be one
      page_size: 1,
    }
  );

  // get the package version and project from responses
  const findingPackageVersion = qFindingPackageVersion.data?.list?.objects[0];
  const findingProject = qFindingProject.data?.list?.objects[0];

  const isLoading = qFinding.isLoading || qFindingPackageVersion.isLoading;

  return (
    <InfoDrawerContent
      title={<FM defaultMessage="Calling Methods to Finding" />}
    >
      <FindingCallPathsDrawerContent
        finding={qFinding.data}
        findingPackageVersion={findingPackageVersion}
        findingProject={findingProject}
        isLoading={isLoading}
      />
    </InfoDrawerContent>
  );
};
