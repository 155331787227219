import { Stack, useTheme } from '@mui/material';
import produce from 'immer';
import { ReactNode, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';
import { MutationOptions } from 'react-query';
import { PickDeep } from 'type-fest';

import {
  V1EndorLicense,
  V1Installation,
  V1PlatformSource,
} from '@endorlabs/api_client';
import { getEnabledFeaturesFromLicenses } from '@endorlabs/endor-core/EndorLicense';
import {
  InstallationResource,
  InstallationWriteParams,
  IQueryError,
  useCreateInstallation,
  useUpdateInstallation,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  ButtonStack,
  ControlledTextField,
  useAppNotify,
} from '@endorlabs/ui-common';

import {
  SOURCE_CONTROL_MANAGERS,
  SourceControlManagerKeys,
} from '../../../views/Integrations/constants';
import { updateAzureFormFieldsToModel } from '../../../views/Integrations/utils';
import { ENABLED_FEATURES_MAP } from '../constants';
import {
  fieldValuesToInstallationRecord,
  getInstallationDefaultValues,
  getUpdateMask,
} from '../utils';
import { getInstallationErrorMessage } from '../utils/errorMessaging';
import { FormUpsertContext } from './FormUpsertContext';
import { FormUpsertInstallationAzure } from './FormUpsertInstallationAzure';
import { FormUpsertInstallationGitHub } from './FormUpsertInstallationGitHub';

export type UpsertInstallationFields = PickDeep<
  InstallationResource,
  | 'meta.name'
  | 'spec.azure_config'
  | 'spec.github_config'
  | 'spec.enabled_features'
  | 'spec.platform_type'
  | 'tenant_meta.namespace'
>;

type ErrorHandler = MutationOptions<
  V1Installation,
  IQueryError,
  InstallationWriteParams
>['onError'];

type SuccessHandler = MutationOptions<
  V1Installation,
  IQueryError,
  InstallationWriteParams
>['onSuccess'];

type ValidInstallationType = V1PlatformSource.Azure | V1PlatformSource.Github;

export interface FormUpsertInstallationProps {
  installation?: InstallationResource;
  installationPlatformType?: ValidInstallationType;
  licenses?: V1EndorLicense[];
  namespace?: string;
  onCancel?: () => void;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
  secondaryButtons?: ReactNode;
}

/**
 * Form to create/update installation config options specific to GitHub app installations
 */
export const FormUpsertInstallation = ({
  installation,
  installationPlatformType = V1PlatformSource.Github,
  licenses = [],
  namespace,
  onCancel,
  onError,
  onSuccess,
  secondaryButtons,
}: FormUpsertInstallationProps) => {
  const { space } = useTheme();

  const addAppNotification = useAppNotify();

  const platformType = (installation?.spec.platform_type ??
    installationPlatformType) as ValidInstallationType;

  const currentPlatform =
    SOURCE_CONTROL_MANAGERS.find((mgr) => mgr.platformSource === platformType)
      ?.key ?? SourceControlManagerKeys.GitHub;

  // Determine upsert method
  const isUpdateForm = !!installation;

  const workingInstallation = getInstallationDefaultValues({
    installation,
    namespace,
    platformType,
  });

  const formMethods = useForm<UpsertInstallationFields>({
    defaultValues: workingInstallation,
  });

  const { control, handleSubmit: hookFormSubmit } = formMethods;

  // Disable unlicensed feature checkboxes as needed
  // TODO: Need to filter out GithubScan for Azure platform
  const licensedFeaturesList = getEnabledFeaturesFromLicenses(licenses);

  const featureCheckboxRecords = ENABLED_FEATURES_MAP.map((feat) => ({
    ...feat,
    disabled: !licensedFeaturesList.includes(feat.value),
  }));

  const handleSuccess: typeof onSuccess = (...args) => {
    addAppNotification({
      id: `installation:${currentPlatform?.toLowerCase()}:upsert:success`,
      message: (
        <FM
          defaultMessage="Successfully {action} this {currentPlatform} installation"
          values={{
            action: isUpdateForm ? 'updated' : 'created',
            currentPlatform,
          }}
        />
      ),
      severity: 'success',
    });

    // Call handler prop if provided
    if (onSuccess) {
      onSuccess(...args);
    }

    if (onCancel) onCancel();
  };

  const handleError: typeof onError = (...args) => {
    const error = args[0];

    const errorMessage = getInstallationErrorMessage(
      error,
      isUpdateForm,
      currentPlatform
    );

    addAppNotification({
      details: errorMessage.details,
      id: `installation:${platformType}:upsert:error`,
      message: errorMessage.message,
      severity: 'error',
    });

    // Call handler prop if provided
    if (onError) {
      onError(...args);
    }
  };

  const qCreateInstallation = useCreateInstallation({
    onError: handleError,
    onSuccess: handleSuccess,
  });

  const qUpdateInstallation = useUpdateInstallation({
    onError: handleError,
    onSuccess: handleSuccess,
  });

  const onSubmit = useCallback(
    (fieldValues: UpsertInstallationFields) => {
      const updatedInstallation = produce(workingInstallation, (draft) => {
        const updatedRecord = fieldValuesToInstallationRecord(fieldValues);
        draft.spec = {
          ...draft.spec,
          ...updatedRecord.spec,
        };
      });

      if (isUpdateForm) {
        qUpdateInstallation.mutate({
          mask: getUpdateMask(platformType),
          namespace: updatedInstallation.tenant_meta.namespace,
          resource: updatedInstallation,
        });
      } else {
        qCreateInstallation.mutate({
          namespace: updatedInstallation.tenant_meta.namespace,
          resource: updatedInstallation,
        });
      }
    },
    [
      isUpdateForm,
      platformType,
      qCreateInstallation,
      qUpdateInstallation,
      workingInstallation,
    ]
  );

  const wrappedOnSubmit = useCallback(
    (fieldValues: UpsertInstallationFields) => {
      if (installationPlatformType === V1PlatformSource.Azure) {
        const formattedValues = updateAzureFormFieldsToModel(fieldValues);
        onSubmit(formattedValues);
      }
      onSubmit(fieldValues);
    },
    [onSubmit, installationPlatformType]
  );

  return (
    <FormUpsertContext.Provider value={formMethods}>
      <FormProvider {...formMethods}>
        <form
          id="FormUpdateInstallation"
          onSubmit={hookFormSubmit(wrappedOnSubmit)}
        >
          <ControlledTextField
            control={control}
            name="spec.platform_type"
            type="hidden"
          />

          <ControlledTextField
            control={control}
            name="tenant_meta.namespace"
            type="hidden"
          />

          <Stack rowGap={space.md}>
            {platformType === V1PlatformSource.Github && (
              <FormUpsertInstallationGitHub
                featureRecords={featureCheckboxRecords}
                installation={installation}
              />
            )}

            {platformType === V1PlatformSource.Azure && (
              <FormUpsertInstallationAzure
                featureRecords={featureCheckboxRecords}
                installation={installation}
              />
            )}

            <ButtonStack>
              {secondaryButtons}

              {onCancel && <ButtonCancel onClick={onCancel} />}

              <ButtonPrimary type="submit">
                {isUpdateForm ? (
                  <FM defaultMessage="Save" />
                ) : (
                  <FM defaultMessage="Create" />
                )}
              </ButtonPrimary>
            </ButtonStack>
          </Stack>
        </form>
      </FormProvider>
    </FormUpsertContext.Provider>
  );
};
