import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  SBOMOrganizationalContactServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1SBOMOrganizationalContact,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceMutateOptions,
  ResourceQueryOptions,
  SBOMOrganizationalContactResource,
  SBOMOrganizationalContactResourceList,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

interface SBOMOrganizationalContactReadParams {
  namespace: string;
  uuid: string;
}

export interface SBOMOrganizationalContactWriteParams {
  namespace: string;
  resource: V1SBOMOrganizationalContact;
}

export interface SBOMOrganizationalContactUpdateParams
  extends SBOMOrganizationalContactWriteParams {
  mask?: string;
}

type CountSBOMOrganizationalContactOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type ListSBOMOrganizationalContactOptions =
  ResourceQueryOptions<SBOMOrganizationalContactResourceList>;
type GetSBOMOrganizationalContactOptions =
  ResourceQueryOptions<SBOMOrganizationalContactResource>;
type UpsertSBOMOrganizationalContactOptions = ResourceMutateOptions<
  V1SBOMOrganizationalContact,
  SBOMOrganizationalContactWriteParams
>;
type DeleteSBOMOrganizationalContactOptions = ResourceMutateOptions<
  object,
  SBOMOrganizationalContactReadParams
>;

const BASE_KEY = 'v1/sbom-organizational-contacts';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const SBOMOrganizationalContactQueryKeys = QK;
export const SBOMOrganizationalContact_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new SBOMOrganizationalContactServiceApi(getClientConfiguration());

const countSBOMOrganizationalContacts = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceListSBOMOrganizationalContacts(
      namespace,
      ...buildCountParamArgs(countParams)
    );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountSBOMOrganizationalContacts = (
  namespace: string,
  opts: CountSBOMOrganizationalContactOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SBOMOrganizationalContact',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countSBOMOrganizationalContacts(namespace, requestParameters),
    opts
  );
};

const listSBOMOrganizationalContacts = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceListSBOMOrganizationalContacts(
      namespace,
      ...buildListParamArgs(listParams)
    );
  return resp.data as SBOMOrganizationalContactResourceList;
};

export const useListSBOMOrganizationalContacts = (
  namespace: string,
  opts: ListSBOMOrganizationalContactOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SBOMOrganizationalContact',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listSBOMOrganizationalContacts(namespace, requestParameters),
    opts
  );
};

const getSBOMOrganizationalContact = async (
  namespace: string,
  SBOMOrganizationalContactUuid: string
) => {
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceGetSBOMOrganizationalContact(
      namespace,
      SBOMOrganizationalContactUuid
    );
  return resp.data as SBOMOrganizationalContactResource;
};

export const useGetSBOMOrganizationalContact = (
  params: SBOMOrganizationalContactReadParams,
  opts: GetSBOMOrganizationalContactOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getSBOMOrganizationalContact(params.namespace, params.uuid),
    opts
  );
};

const createSBOMOrganizationalContact = async (
  namespace: string,
  SBOMOrganizationalContactBody: V1SBOMOrganizationalContact
) => {
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceCreateSBOMOrganizationalContact(
      namespace,
      SBOMOrganizationalContactBody
    );
  return resp.data as SBOMOrganizationalContactResource;
};

export const useCreateSBOMOrganizationalContact = (
  opts: UpsertSBOMOrganizationalContactOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'SBOMOrganizationalContact'),
    mutationFn: (params: SBOMOrganizationalContactWriteParams) =>
      createSBOMOrganizationalContact(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateSBOMOrganizationalContact = async (
  params: SBOMOrganizationalContactUpdateParams
) => {
  const {
    resource,
    namespace,
    mask = SBOMOrganizationalContact_UPDATE_MASK,
  } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceUpdateSBOMOrganizationalContact(
      namespace,
      req
    );
  return resp.data as SBOMOrganizationalContactResource;
};

export const useUpdateSBOMOrganizationalContact = (
  opts: UpsertSBOMOrganizationalContactOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'SBOMOrganizationalContact'),
    mutationFn: (params: SBOMOrganizationalContactUpdateParams) =>
      updateSBOMOrganizationalContact(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteSBOMOrganizationalContact = async (
  params: SBOMOrganizationalContactReadParams
) => {
  const api = getApiService();
  const resp =
    await api.sBOMOrganizationalContactServiceDeleteSBOMOrganizationalContact(
      params.namespace,
      params.uuid
    );
  return resp.data;
};

export const useDeleteSBOMOrganizationalContact = (
  opts: DeleteSBOMOrganizationalContactOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'SBOMOrganizationalContact'),
    mutationFn: (params: SBOMOrganizationalContactReadParams) =>
      deleteSBOMOrganizationalContact(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
