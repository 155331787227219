import { LoadingButton } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { NamespaceResource } from '@endorlabs/endor-core/Namespace';
import {
  ButtonCancel,
  ControlledLabelsField,
  ControlledTextField,
  LabelsFieldValidationType,
  REGEX_NAMESPACE_VALIDATION,
} from '@endorlabs/ui-common';

export interface FormUpsertNamespaceProps {
  isLoading?: boolean;
  namespace: string;
  onCancel?: () => void;
  onSubmit: (data: NamespaceResource) => void;
  resource?: NamespaceResource;
}

/**
 * Form used to create or edit a Namespace
 */
export const FormUpsertNamespace = ({
  isLoading,
  namespace,
  onCancel,
  onSubmit,
  resource,
}: FormUpsertNamespaceProps) => {
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();

  const {
    control,
    handleSubmit: hookFormSubmit,
    reset,
  } = useForm<NamespaceResource>({
    defaultValues: {},
  });

  // reset the form with a resource, if provided
  useEffect(() => {
    reset(resource);
  }, [resource, reset]);

  const isEdit = !!resource;

  return (
    <form id="FormUpsertNamespace" onSubmit={hookFormSubmit(onSubmit)}>
      <Stack direction="column" spacing={space.md}>
        <ControlledTextField
          control={control}
          InputProps={
            // prevent update to name on edit
            isEdit ? { readOnly: true, disableUnderline: true } : undefined
          }
          label={fm({
            defaultMessage: 'Name',
          })}
          name="meta.name"
          placeholder={fm({
            defaultMessage: 'What is the namespace name?',
          })}
          required
          rules={{
            pattern: {
              value: REGEX_NAMESPACE_VALIDATION,
              message: fm({
                defaultMessage:
                  'The name must be 32 characters or less, and may contain only lowercase letters (a-z), numbers (0-9) and the following characters (_-).',
              }),
            },
          }}
        />

        <ControlledTextField
          control={control}
          label={fm({
            defaultMessage: 'Description',
          })}
          name="meta.description"
          placeholder={fm({
            defaultMessage: 'What do you want to say about this namespace?',
          })}
        />

        <ControlledLabelsField
          control={control}
          defaultValue={[]}
          fullWidth
          label={fm({ defaultMessage: 'Tags' })}
          name="meta.tags"
          helperText={fm({
            defaultMessage: 'The new namespace will have these tags',
          })}
          validation={LabelsFieldValidationType.MetaTag}
        />

        <Stack direction="row" spacing={space.sm}>
          <LoadingButton loading={isLoading} type="submit" variant="contained">
            {isEdit ? (
              <FM defaultMessage="Update Namespace" />
            ) : (
              <FM defaultMessage="Create Namespace" />
            )}
          </LoadingButton>

          {onCancel && (
            <ButtonCancel onClick={onCancel}>
              <FM defaultMessage="Cancel" />
            </ButtonCancel>
          )}
        </Stack>
      </Stack>
    </form>
  );
};
