import { getEnv } from '../../../constants';
import { getDashboardPath } from '../../../routes';
import { BasicTourStepProps } from '../types';

const ENV = getEnv();

const selVulnFunnel = '.DashboardVulnerabilityPrioritization-funnel';
const selNav = '#app-primary-nav [data-testid="AppDrawerNavItem-dashboard"]';

export const getDashboardStep = ({ tourData }: BasicTourStepProps) => {
  return {
    attachTo: {
      element: selVulnFunnel,
      on: 'top-end',
    },

    description:
      'Cut down the open source vulnerability noise by over 80% using reachability analysis.',

    highlightSelectors: [selVulnFunnel, selNav],

    stepUrl: `${getDashboardPath({
      tenantName: tourData.tourTenant,
    })}`,

    title: 'Prioritize Reachable Vulnerabilities',
    // urlLearn: `${ENV.URL_ENDOR_MARKETING}/blog/securing-code-with-beautiful-call-graph-visualizations`,
  };
};
