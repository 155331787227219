import { IconButton, MenuItem, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { ControlledTextField, IconX } from '@endorlabs/ui-common';

interface PolicyParameterFieldFromDataProps {
  allowMultiple?: boolean;
  inputFieldName?: string;
  parameterFieldName: string;
  parameterFieldValue?: string[];
  possibleValues?: string[];
}

export const PolicyParameterFieldFromData = ({
  allowMultiple,
  inputFieldName,
  possibleValues,
  parameterFieldName,
  parameterFieldValue,
}: PolicyParameterFieldFromDataProps) => {
  const { space } = useTheme();
  const { control, setValue } = useFormContext();

  const hasValues = Boolean(
    parameterFieldValue && parameterFieldValue.length > 0
  );

  const hasPossibleValues = Boolean(
    possibleValues && possibleValues.length > 0
  );

  return (
    <ControlledTextField
      control={control}
      defaultValue={[]}
      inputProps={{
        id: `field-${inputFieldName}`,
      }}
      name={parameterFieldName}
      select={hasPossibleValues}
      SelectProps={{
        displayEmpty: true,
        endAdornment: hasValues && (
          <IconButton
            onClick={() => {
              setValue(parameterFieldName, []);
            }}
          >
            <IconX />
          </IconButton>
        ),
        multiple: allowMultiple,
        renderValue: () => {
          if (!hasValues) {
            return <FM defaultMessage="Any" />;
          }

          return parameterFieldValue?.join(', ');
        },
      }}
      sx={{
        overflow: 'hidden',
        '.MuiSelect-icon': {
          marginRight: hasValues ? space.lg : 0,
        },
      }}
    >
      {/* Empty value as first option */}
      <MenuItem value={''} sx={{ display: 'none' }}>
        Any
      </MenuItem>
      {possibleValues &&
        possibleValues.map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
    </ControlledTextField>
  );
};
