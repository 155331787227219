import { useContext } from 'react';

import { AuthInfoContext } from './AuthInfoContext';

export function useAuthInfo() {
  const context = useContext(AuthInfoContext);

  // Ensure context is present for consumer
  if (!context) {
    throw new Error('Missing provider for AuthInfoContext');
  }

  return context;
}
