import { LoadingButton } from '@mui/lab';
import { Stack, useTheme } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { ButtonCancel } from '@endorlabs/ui-common';

import { DashboardConfigValue } from '../../hooks';
import { CostEstimateFields } from './CostEstimateFields';
import { DevEstimateFields } from './DevEstimateFields';
import { EpssThresholdFields } from './EpssThresholdFields';
import { FindingReachabilityFields } from './FindingReachabilityFields';
import { SLAFields } from './SLAFields';
import { cleanDashboardConfigValueFields } from './utils';

export type FormDashboardConfigValueFieldset =
  | 'COST_ESTIMATE'
  | 'DEV_ESTIMATE'
  | 'EPSS_THRESHOLD'
  | 'FINDING_REACHABILITY'
  | 'SLA_CRITICAL'
  | 'SLA_HIGH'
  | 'SLA_MEDIUM'
  | 'SLA_LOW';

export type FormDashboardConfigValueProps = {
  config: DashboardConfigValue;
  fieldsets: FormDashboardConfigValueFieldset[];
  isLoading?: boolean;
  onCancel?: () => void;
  onSubmit: (values: DashboardConfigValue) => void;
};

export const FormDashboardConfigValue = ({
  config,
  onSubmit,
  fieldsets,
  isLoading,
  onCancel,
}: FormDashboardConfigValueProps) => {
  const { space } = useTheme();
  const formMethods = useForm<DashboardConfigValue>({});
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    if (config) {
      reset(config);
    }
  }, [config, reset]);

  const showDevEstimate = fieldsets?.includes('DEV_ESTIMATE');
  const showCostEstimate = fieldsets.includes('COST_ESTIMATE');
  const showEpssThreshold = fieldsets.includes('EPSS_THRESHOLD');
  const showFindingReachability = fieldsets.includes('FINDING_REACHABILITY');
  const showCriticalSLA = fieldsets.includes('SLA_CRITICAL');
  const showHighSLA = fieldsets.includes('SLA_HIGH');
  const showMediumSLA = fieldsets.includes('SLA_MEDIUM');
  const showLowSLA = fieldsets.includes('SLA_LOW');

  const wrappedOnSubmit = useCallback(
    (fieldValues: DashboardConfigValue) => {
      onSubmit(cleanDashboardConfigValueFields(fieldValues));
    },
    [onSubmit]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(wrappedOnSubmit)} noValidate>
        <Stack direction="column" spacing={space.md}>
          {showDevEstimate && <DevEstimateFields />}
          {showCostEstimate && <CostEstimateFields />}
          {showEpssThreshold && <EpssThresholdFields />}
          {showFindingReachability && <FindingReachabilityFields />}
          {showCriticalSLA && <SLAFields fieldName="slaCritical" />}
          {showHighSLA && <SLAFields fieldName="slaHigh" />}
          {showMediumSLA && <SLAFields fieldName="slaMedium" />}
          {showLowSLA && <SLAFields fieldName="slaLow" />}

          <Stack direction="row" spacing={space.sm}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              <FM defaultMessage="Save" />
            </LoadingButton>

            {onCancel && (
              <ButtonCancel onClick={onCancel}>
                <FM defaultMessage="Cancel" />
              </ButtonCancel>
            )}
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
