import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const TenantSettingsIndexRoute = () => {
  return <Navigate to="system" replace />;
};

export const Route = createFileRoute('/t/:namespace/settings/')({
  component: TenantSettingsIndexRoute,
});
