import { UseNavigateType } from '@tanstack/react-location';
import { MouseEvent } from 'react';

/**
 * Simulate a link click, opening in a new tab if ctrl or meta key is pressed
 */
const simulateLinkClick = (
  url: string,
  evt: MouseEvent,
  navigate: UseNavigateType
) => {
  if (evt.ctrlKey || evt.metaKey) {
    window.open(url, '_blank', 'noreferrer');
  } else {
    navigate({ to: url });
  }
};

export const UIEventUtils = {
  simulateLinkClick,
};
