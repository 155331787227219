import {
  AccordionDetails,
  AccordionSummary,
  Stack,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyResource, PolicyTemplateResource } from '@endorlabs/queries';

import { FormUpsertPolicy as formMessages } from '../locales';
import { PolicyUmbrellaTypes } from '../types';
import { PolicyNameFields } from './PolicyNameFields';
import { PolicyProjectsDrawerButton } from './PolicyProjectsDrawerButton';
import { PolicyRuleFields } from './PolicyRuleFields';
import { PolicyScopeFields } from './PolicyScopeFields';
import { StepAccordion } from './StepAccordion';
import { StepHeaderButton } from './StepHeaderButton';

const messages = formMessages[PolicyUmbrellaTypes.FINDING];

interface FindingPolicyStepsProps {
  activeTemplate?: PolicyTemplateResource;
  policy?: PolicyResource;
  policyTemplates: PolicyTemplateResource[];
}

/**
 * Numbered steps and form fields to create a Finding policy
 */
export const FindingPolicySteps = ({
  activeTemplate,
  policy,
  policyTemplates,
}: FindingPolicyStepsProps) => {
  const theme = useTheme();
  const { space } = theme;

  const [expandedSteps, setExpandedSteps] = useState([0, 1, 2]);

  const handleToggle = useCallback(
    (stepNum: number) => {
      expandedSteps.includes(stepNum)
        ? setExpandedSteps(expandedSteps.filter((s) => s !== stepNum))
        : setExpandedSteps(expandedSteps.concat([stepNum]));
    },
    [expandedSteps]
  );

  return (
    <Stack spacing={space.lg}>
      <StepAccordion
        expanded={expandedSteps.includes(0)}
        handleToggle={handleToggle}
        stepNumber={0}
      >
        {/* POLICY CREATION METHOD AND SUB-CONTROLS */}
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={0}
            text={<FM defaultMessage="Define a Policy" />}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Stack alignItems="flex-start" spacing={space.md}>
            <PolicyRuleFields
              activeTemplate={activeTemplate}
              policy={policy}
              policyTemplates={policyTemplates}
              policyUmbrellaType={PolicyUmbrellaTypes.FINDING}
            />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      <StepAccordion
        expanded={expandedSteps.includes(1)}
        handleToggle={handleToggle}
        stepNumber={1}
      >
        {/* POLICY -> PROJECT SCOPE AND LABEL SELECTION */}
        <AccordionSummary>
          <StepHeaderButton
            stepNumber={1}
            text={<FM defaultMessage="Assign Scope" />}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Stack
            alignItems="flex-start"
            spacing={space.md}
            sx={{ paddingTop: space.sm }}
          >
            <PolicyScopeFields />

            <PolicyProjectsDrawerButton />
          </Stack>
        </AccordionDetails>
      </StepAccordion>

      <StepAccordion
        expanded={expandedSteps.includes(2)}
        handleToggle={handleToggle}
        stepNumber={2}
      >
        <AccordionSummary>
          {/* POLICY NAME AND DESCRIPTION */}
          <StepHeaderButton
            stepNumber={2}
            text={<FM {...messages.namePolicy} />}
          />
        </AccordionSummary>

        <AccordionDetails>
          <Stack alignItems="flex-start" spacing={space.md}>
            <PolicyNameFields />
          </Stack>
        </AccordionDetails>
      </StepAccordion>
    </Stack>
  );
};
