import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';

import { ScoreCategoryMessages } from '../locales';

export const getScoreCategoryLabel = (category?: V1ScoreCategory) =>
  category ? <FM {...ScoreCategoryMessages[category]} /> : '';

export const UIMetricsUtils = {
  getScoreCategoryLabel,
};
