import {
  PathNode,
  V1Finding,
  V1FindingSpec,
  V1FindingTags,
} from '@endorlabs/api_client';

import { ResourceType } from '../common';

/**
 * Finding Resource
 */
export type FindingResource = ResourceType<V1Finding, V1FindingSpec>;

/**
 * Merged set of finding "Attributes". This is a superset of the Finding Tags,
 * to support the consistent display for Findings in the UI.
 */
export type FindingAttributeValue = V1FindingTags | 'FINDING_SPEC_DISMISSED';

/**
 * Finding "attributes" have implicit grouping
 */
export enum FindingAttributeGroup {
  DependencyReachability = 'FINDING_ATTRIBUTE_GROUP_DEPENDENCY_REACHABILITY',
  FindingOrigin = 'FINDING_ATTRIBUTE_GROUP_FINDING_ORIGIN',
  Fixability = 'FINDING_ATTRIBUTE_GROUP_FIXABILTIY',
  FunctionReachability = 'FINDING_ATTRIBUTE_GROUP_FUNCTION_REACHABILITY',
  Phantom = 'FINDING_ATTRIBUTE_GROUP_PHANTOM',
  DependencyOrigin = 'FINDING_ATTRIBUTE_GROUP_DEPENDENCY_ORIGIN',
  Test = 'FINDING_ATTRIBUTE_GROUP_TEST',
  Unspecified = 'FINDING_ATTRIBUTE_GROUP_UNSPECIFIED',
}

/**
 * Mapping from Finding "attributes" to the expected group.
 */
export const FINDING_ATTRIBUTE_GROUPING: Partial<
  Record<
    Exclude<
      FindingAttributeValue,
      // Exclude deprecated finding tags
      V1FindingTags.CiBlocker | V1FindingTags.Fixable | V1FindingTags.Production
    >,
    FindingAttributeGroup
  >
> = {
  [V1FindingTags.Direct]: FindingAttributeGroup.FindingOrigin,
  [V1FindingTags.FixAvailable]: FindingAttributeGroup.Fixability,
  [V1FindingTags.NamespaceInternal]: FindingAttributeGroup.DependencyOrigin,
  [V1FindingTags.Normal]: FindingAttributeGroup.Test,
  [V1FindingTags.Phantom]: FindingAttributeGroup.Phantom,
  [V1FindingTags.PotentiallyReachableDependency]:
    FindingAttributeGroup.DependencyReachability,
  [V1FindingTags.PotentiallyReachableFunction]:
    FindingAttributeGroup.FunctionReachability,
  [V1FindingTags.ProjectInternal]: FindingAttributeGroup.DependencyOrigin,
  [V1FindingTags.ReachableDependency]:
    FindingAttributeGroup.DependencyReachability,
  [V1FindingTags.ReachableFunction]: FindingAttributeGroup.FunctionReachability,
  [V1FindingTags.Self]: FindingAttributeGroup.FindingOrigin,
  [V1FindingTags.Test]: FindingAttributeGroup.Test,
  [V1FindingTags.Transitive]: FindingAttributeGroup.FindingOrigin,
  [V1FindingTags.Unfixable]: FindingAttributeGroup.Fixability,
  [V1FindingTags.UnreachableDependency]:
    FindingAttributeGroup.DependencyReachability,
  [V1FindingTags.UnreachableFunction]:
    FindingAttributeGroup.FunctionReachability,
};

/**
 * The "source" of a Finding
 */
export enum FindingSource {
  All = 'FINDING_SOURCE_ALL',
  Container = 'FINDING_SOURCE_CONTAINER',
  Dependency = 'FINDING_SOURCE_DEPENDENCY',
  GithubAction = 'FINDING_SOURCE_GITHUB_ACTION',
  Package = 'FINDING_SOURCE_PACKAGE',
  Repository = 'FINDING_SOURCE_REPOSITORY',
  Secrets = 'FINDING_SOURCE_SECRETS',
  SAST = 'FINDING_SOURCE_SAST',
}

/**
 * Related to {@see V1FindingSpec} `spec.reachable_paths.nodes`
 */
export type FindingSpecPathNode = PathNode &
  Required<Pick<PathNode, 'function_ref' | 'package_version'>>;

export type CVSSScoreSummary = {
  baseScore: number | undefined;
  scoreType: string | undefined;
  scoreVector: string | undefined;
};
