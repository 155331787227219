import { Box } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  NumberDisplay,
  RelativeTimeDisplay,
} from '@endorlabs/ui-common';

import { RemediationRisk } from '../../types';
import { RemediationRiskDisplay } from '../RemediationRiskDisplay';

const RemediationOverviewRecords: AttributeDisplayRecord[] = [
  {
    attributeKey: 'remediationRisk',
    heading: <FM defaultMessage="Remediation Risk" />,
  },
  {
    attributeKey: 'findingsFixed',
    heading: <FM defaultMessage="Findings Fixed" />,
  },
  { attributeKey: 'versionAge', heading: <FM defaultMessage="Version Age" /> },
];

export const RemediationOverviewStats = ({
  variant = 'row',
  justifyContent = 'space-between',
  upgradeInfo,
}: {
  variant?: 'row' | 'column';
  justifyContent?: 'space-around' | 'space-between';
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const findingsFixed = upgradeInfo?.total_findings_fixed ?? 0;

  return (
    <AttributeDisplayStack
      attributeRecords={RemediationOverviewRecords}
      containerProps={{
        flex: '1 1 0',
        justifyContent,
      }}
      resource={{
        remediationRisk: (
          <Box>
            <RemediationRiskDisplay
              remediationRisk={
                (upgradeInfo?.upgrade_risk ?? '') as RemediationRisk
              }
            />
          </Box>
        ),
        findingsFixed: <NumberDisplay value={findingsFixed} />,
        versionAge: (
          <RelativeTimeDisplay
            value={upgradeInfo?.from_version_publish_time ?? ''}
          />
        ),
      }}
      variant={variant}
    />
  );
};
