import { Card, CardContent, Grid } from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  MetricToolsByCategory,
  RepositoryResource,
  RepoVersionResource,
  selectMetricCiCdToolsByCategory,
  useFeatureFlags,
  useListMetrics,
} from '@endorlabs/queries';
import { ButtonCancel, EmptyState, SearchBar } from '@endorlabs/ui-common';
import { useTanstackTableRef } from '@endorlabs/ui-common';

import {
  mapToProjectToolsTableRows,
  ProjectToolsTable,
  ProjectToolsTableRow,
  useProjectToolsDetailDrawer,
} from '../../components';
import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import { useFilterContext, withFilterProvider } from '../../domains/filters';
import { ProjectCiCdToolsDataTableView } from '../../domains/Projects/components/ProjectCiCdToolsDataTableView';

export interface ProjectVersionToolsProps {
  namespace: string;
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}

const ProjectVersionToolsBase = (props: ProjectVersionToolsProps) => {
  const { ENABLE_IA_1_PAGE_IMPROVEMENTS } = useFeatureFlags();
  if (ENABLE_IA_1_PAGE_IMPROVEMENTS) {
    return <ProjectCiCdToolsDataTableView {...props} />;
  }

  return <ProjectVersionToolsV1 {...props} />;
};

/**
 * @deprecated will be removed with IA redesign work
 */
const ProjectVersionToolsV1 = ({
  namespace,
  repository,
  repositoryVersion,
}: ProjectVersionToolsProps) => {
  const { formatMessage: fm } = useIntl();
  const { _state, updateFilter } = useFilterContext();
  const searchValue = _state.search;

  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<ProjectToolsTableRow>();

  const { DetailDrawer, permalinkEffect } = useProjectToolsDetailDrawer();

  const projectToolsFilters = [
    `meta.name==version_cicd_tools`,
    `meta.parent_uuid==${repositoryVersion?.uuid}`,
  ];

  // use query for package versions, including metrics
  const qQueryProjectTools = useListMetrics(
    namespace,
    {
      enabled: !!repositoryVersion?.context,
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: projectToolsFilters.join(' and '),
      page_size: 1,
    }
  );

  const metricCiCdCTools =
    qQueryProjectTools.data?.list?.objects[0]?.spec.metric_values.CiCdTools
      ?.ci_cd_tools?.tools;

  const projectVersionTools = useMemo(() => {
    const toolsByCategory = selectMetricCiCdToolsByCategory(
      qQueryProjectTools.data?.list?.objects[0]
    );

    if (searchValue) {
      const filteredToolsByCategory: MetricToolsByCategory = {};

      Object.entries(toolsByCategory).forEach(([category, tools]) => {
        const isCategoryMatch = category
          .toLowerCase()
          .includes(searchValue.toLowerCase());

        const isToolNameMatch = tools.some((tool) =>
          tool.name.toLowerCase().includes(searchValue.toLowerCase())
        );
        if (isCategoryMatch || isToolNameMatch) {
          filteredToolsByCategory[category] = tools;
        }
      });
      return mapToProjectToolsTableRows(namespace, filteredToolsByCategory);
    } else {
      return mapToProjectToolsTableRows(namespace, toolsByCategory);
    }
  }, [namespace, qQueryProjectTools.data?.list?.objects, searchValue]);

  const handleClickDetail = useCallback(
    (row?: ProjectToolsTableRow) => {
      if (!namespace || !row?.category) return;

      DetailDrawer.activate(
        {
          namespace: namespace,
          toolCategory: row.category,
        },
        {
          namespace: namespace,
          repository,
          repositoryVersion: repositoryVersion,
          toolCategory: row.category,
        }
      );
    },
    [DetailDrawer, namespace, repository, repositoryVersion]
  );

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable = tableRef.current as Table<ProjectToolsTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const handleSearchChange = useCallback(
    (searchValue: string) => {
      updateFilter({ search: searchValue });
    },
    [updateFilter]
  );

  const handleClearSearch = useCallback(() => {
    handleSearchChange('');
  }, [handleSearchChange]);

  useLayoutEffect(() => {
    if (repositoryVersion) {
      permalinkEffect({
        repository,
        repositoryVersion,
      });
    }
  }, [permalinkEffect, repository, repositoryVersion]);

  const isLoading = qQueryProjectTools.isLoading;
  const isEmptyState =
    !qQueryProjectTools.isLoading &&
    (!metricCiCdCTools || metricCiCdCTools.length === 0);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="There are no tools in this project version" />
            }
            description={
              <FM defaultMessage="As tools are added to this project, they will appear here." />
            }
          ></EmptyState>
        </Grid>
      )}
      {!isEmptyState && (
        <Grid item>
          <SearchBar
            onSearch={handleSearchChange}
            placeholder={fm({
              defaultMessage: 'Search for Categories or Tools',
            })}
            searchValue={searchValue}
          />
        </Grid>
      )}
      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardContent>
              <ProjectToolsTable
                ref={tableRef}
                isLoading={isLoading}
                data={projectVersionTools}
                enablePagination
                enableColumnSort
                enableRowSelection
                onClickDetail={handleClickDetail}
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No Tools match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={handleClearSearch}>
                      <FM defaultMessage="Clear Search" />
                    </ButtonCancel>
                  ),
                }}
                onRowSelectionChange={handleRowSelection}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const ProjectVersionTools = withFilterProvider(ProjectVersionToolsBase, {
  displayName: 'ProjectVersionTools',
  searchKeys: [
    'spec.metric_values.CiCdTools.ci_cd_tools.tools.categories',
    'spec.metric_values.CiCdTools.ci_cd_tools.tools.name',
  ],
});
