import { Skeleton, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { IconCircleFilled, RowStack } from '@endorlabs/ui-common';

export const RemediationsAggregatedCountsNode = ({
  isLoading,
  lastUpdateTime,
  totalGroupCount,
}: {
  isLoading: boolean;
  lastUpdateTime?: string;
  totalGroupCount: number;
}) => {
  if (isLoading) {
    return <Skeleton width={175} />;
  }

  if (!lastUpdateTime) {
    return (
      <Typography variant="caption">
        <FM defaultMessage="Not Yet Analyzed" />
      </Typography>
    );
  }

  return (
    <RowStack
      divider={
        <IconCircleFilled
          sx={{ width: 4, height: 4, color: 'text.secondary' }}
        />
      }
    >
      <Typography variant="caption">
        <FM
          defaultMessage="{totalGroupCount, plural, =0 {No Groups} one {<b>#</b> Group} other {<b>#</b> Groups}}"
          values={{
            totalGroupCount,
            b: (value) => (
              <Typography
                color="text.primary"
                component="span"
                variant="inherit"
              >
                {value}
              </Typography>
            ),
          }}
        />
      </Typography>

      <Typography variant="caption">
        <FM
          defaultMessage="Last Analyzed: {updateTime, date, long}"
          values={{
            updateTime: new Date(lastUpdateTime).getTime(),
          }}
        />
      </Typography>
    </RowStack>
  );
};
