import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonPrimary, ButtonSecondary } from '@endorlabs/ui-common';

import { useOnboardingSteps } from '../../domains/Onboarding';
import { AddNewProjectPage } from '../Projects/OnboardingAddProject';
import { OnboardingStepContentProps } from './types';

export const AddProjectChildren = ({
  stepRecord,
}: OnboardingStepContentProps) => {
  const { space } = useTheme();
  const { completeStep, getIsStepComplete, uncompleteStep } =
    useOnboardingSteps();
  const isCompleted = getIsStepComplete(stepRecord.id);

  return (
    <Stack spacing={space.md}>
      <Typography color="textSecondary">
        <FM defaultMessage="Configuring an Endor Labs tenant and start scan your repositories with CLI" />
      </Typography>

      <AddNewProjectPage />

      <Box>
        {isCompleted ? (
          <ButtonSecondary
            variant="outlined"
            onClick={() => uncompleteStep(stepRecord.id)}
          >
            <FM defaultMessage="Mark Incomplete" />
          </ButtonSecondary>
        ) : (
          <ButtonPrimary onClick={() => completeStep(stepRecord.id)}>
            <FM defaultMessage="Mark Complete" />
          </ButtonPrimary>
        )}
      </Box>
    </Stack>
  );
};
