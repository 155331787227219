import { Theme } from '@mui/material';

export const getSwitchOverrides = (theme: Theme) => {
  const { palette } = theme;

  return {
    MuiSwitch: {
      defaultProps: {
        size: 'small',
      },

      styleOverrides: {
        root: {
          '.MuiFormControlLabel-root &': {
            marginRight: 4,
          },
        },

        switchBase: {
          '.MuiSwitch-track': {
            border: `1px solid ${palette.text.secondary}`,
          },

          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: palette.primary.main,
            opacity: 0.8,
          },
        },
        thumb: {
          backgroundColor: palette.background.paper,
          border: `1px solid ${palette.text.secondary}`,
        },
      },
    },
  };
};
