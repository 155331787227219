import { Tooltip } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { DependencyMetadataReachabilityType } from '@endorlabs/api_client';

import { IconCheck, IconMinus, IconX } from '../../themes';

export const ReachabilityTypeDisplay: React.FC<{
  value: DependencyMetadataReachabilityType;
}> = ({ value: reachabilityType }) => {
  switch (reachabilityType) {
    case DependencyMetadataReachabilityType.Reachable:
      return (
        <Tooltip title={<FM defaultMessage="Reachable" />}>
          <IconCheck />
        </Tooltip>
      );

    case DependencyMetadataReachabilityType.Unreachable:
      return (
        <Tooltip title={<FM defaultMessage="Unreachable" />}>
          <IconX color="error" />
        </Tooltip>
      );

    case DependencyMetadataReachabilityType.Unknown:
    case DependencyMetadataReachabilityType.Unspecified:
    default:
      return (
        <Tooltip title={<FM defaultMessage="Potentially Reachable" />}>
          <IconMinus />
        </Tooltip>
      );
  }
};
