import { Skeleton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/queries';
import { parsePackageName } from '@endorlabs/ui-common';

import {
  DependencyDependentAggregate,
  DependencyDependentsType,
} from './types';

/**
 * i18n formatted messages for package and project summary
 */
const messages = defineMessages({
  packages: {
    defaultMessage:
      'Showing {totalCount, plural, one {<bold>1 package</bold> that is} other {<bold># packages</bold> that are}} dependent on {name} {versionRef}, of which <bold>{directCount, plural, one {1 is} other {# are}} direct</bold> and <bold>{transitiveCount, plural, =0 {0 are} one {1 is} other {# are}} transitive</bold>.',
  },
  packagesFiltered: {
    defaultMessage:
      'Showing <bold>{filteredCount, number} of {totalCount, plural, one {1 package} other {# packages}}</bold> that {totalCount, plural, one {is} other {are}} dependent on {name} {versionRef}, of which <bold>{directCount, plural, one {1 is} other {# are}} direct</bold> and <bold>{transitiveCount, plural, =0 {0 are} one {1 is} other {# are}} transitive</bold>.',
  },
  projects: {
    defaultMessage:
      'Showing {totalCount, plural, one {<bold>1 project</bold> that is} other {<bold># project</bold> that are}} dependent on {name} {versionRef}',
  },
  projectsFiltered: {
    defaultMessage:
      'Showing <bold>{filteredCount, number} of {totalCount, plural, one {# project} other {# projects}}</bold> dependent on {name} {versionRef}',
  },
});

export interface DependencyDetailDependentsSummaryProps {
  dependencyPackageVersion?: PackageVersionResource;
  dependencyDependentType: DependencyDependentsType;
  aggregatedDependents: DependencyDependentAggregate[];
  totalCount: number;
  isLoading: boolean;
}

export const DependencyDetailDependentsSummary = ({
  dependencyPackageVersion,
  dependencyDependentType,
  aggregatedDependents,
  totalCount,
  isLoading,
}: DependencyDetailDependentsSummaryProps) => {
  const [packageName, packageVersionRef] = useMemo(() => {
    if (!dependencyPackageVersion) return [undefined, undefined];

    const { label, version } = parsePackageName(
      dependencyPackageVersion.meta.name
    );
    return [label, version];
  }, [dependencyPackageVersion]);

  const filteredCount = aggregatedDependents.length;
  let directCount = 0;
  let transitiveCount = 0;
  for (const dependent of aggregatedDependents) {
    if (dependent.hasDirect) {
      directCount++;
    } else {
      transitiveCount++;
    }
  }

  if (isLoading) {
    return <Skeleton height={48} width={512} />;
  }

  if (filteredCount === 0) return null;

  let message;
  if (filteredCount === totalCount) {
    message =
      dependencyDependentType === DependencyDependentsType.PACKAGES
        ? messages.packages
        : messages.projects;
  } else {
    message =
      dependencyDependentType === DependencyDependentsType.PACKAGES
        ? messages.packagesFiltered
        : messages.projectsFiltered;
  }

  return (
    <Typography variant="body1" data-testid="dependents-summary">
      <FM
        {...message}
        values={{
          totalCount,
          filteredCount,
          name: packageName,
          versionRef: packageVersionRef,
          directCount: directCount,
          transitiveCount: transitiveCount,
          bold: (v) => <strong>{v}</strong>,
        }}
      />
    </Typography>
  );
};
