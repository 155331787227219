import {
  V1Ecosystem,
  V1FindingCategory,
  V1FindingTags,
} from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';

/**
 * Helper to build the base Filter Expression for Findings from a
 * Package Version, with a given Finding Source.
 */
export const getPackageVersionFindingFilterExpression = (
  packageVersion?: PackageVersionResource,
  findingSource?: FindingSource
): FilterExpression | undefined => {
  if (!packageVersion?.uuid || !packageVersion?.context) return;

  const filterExpressions = [
    `meta.parent_kind==PackageVersion`,
    `meta.parent_uuid==${packageVersion.uuid}`,
    filterExpressionBuilders.relatedResourceContext(packageVersion) as string,
  ];

  switch (findingSource) {
    case FindingSource.Container:
      filterExpressions.push(
        `spec.finding_categories contains ["${V1FindingCategory.Container}"]`
      );
      break;
    case FindingSource.Dependency:
      filterExpressions.push(
        `spec.finding_tags not contains [${V1FindingTags.Self}]`,
        `spec.ecosystem!=${V1Ecosystem.GithubAction}`
      );
      break;
    case FindingSource.GithubAction:
      filterExpressions.push(
        `meta.parent_kind==PackageVersion`,
        `spec.ecosystem==${V1Ecosystem.GithubAction}`
      );
      break;
    case FindingSource.Package:
      filterExpressions.push(
        `meta.parent_kind==PackageVersion`,
        `spec.finding_tags contains [${V1FindingTags.Self}]`,
        `spec.ecosystem!=${V1Ecosystem.GithubAction}`
      );
      break;
  }

  return filterExpressionBuilders.and(filterExpressions);
};
