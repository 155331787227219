import { createContext } from 'react';

import { FilterExpression } from '@endorlabs/filters';

import { FilterState } from '../hooks/useFilterState';

export interface FilterContextValue {
  clearFilter: () => void;
  compareFilter: (f: string) => boolean;
  filter: FilterExpression;
  isParseable: boolean;
  _state: FilterState;
  /**
   * Update the current Filter state from a partial state, or explicitly set the Filter from an expression
   */
  updateFilter: (
    value: Partial<Pick<FilterState, 'search' | 'values'>> | FilterExpression
  ) => void;
}

/**
 * Context for a Filter
 */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const FilterContext = createContext<FilterContextValue>(null!);
