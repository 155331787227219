import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  IconCalendar,
  IconEnergyLeaf,
  IconPrioritize,
  IconTarget,
  IconWind,
} from '@endorlabs/ui-common';

export const FindingPriorityBucketNames = {
  PRIORITIZE: 'PRIORITIZE',
  PLAN: 'PLAN',
  DEFER: 'DEFER',
  BACKLOG: 'BACKLOG',
  OFF_RADAR: 'OFF_RADAR',
};

export type FindingPriorityBucketName =
  typeof FindingPriorityBucketNames[keyof typeof FindingPriorityBucketNames];

export const FindingPriorityBuckets = [
  FindingPriorityBucketNames.PRIORITIZE,
  FindingPriorityBucketNames.PLAN,
  FindingPriorityBucketNames.BACKLOG,
  FindingPriorityBucketNames.DEFER,
] as const;

export const FindingPriorityBucketIcons = {
  [FindingPriorityBucketNames.OFF_RADAR]: IconEnergyLeaf,
  [FindingPriorityBucketNames.PRIORITIZE]: IconPrioritize,
  [FindingPriorityBucketNames.PLAN]: IconTarget,
  [FindingPriorityBucketNames.BACKLOG]: IconCalendar,
  [FindingPriorityBucketNames.DEFER]: IconWind,
} as const;

const LOW_EPSS_THRESHOLD = 0.2;
const HIGH_EPSS_THRESHOLD = 0.8;

/**
 * These are oh so arbitrary, & could absolutely result in overlap & gaps in returned records.
 * Anything not captured by these filters is "off your radar", & so seemingly not of consequence,
 * even though it might very well be of consequence. Particularly if you created this bucket filter
 * yourself without adequate thought.
 *
 * Taken from suggested "risk" and "ease" filters in PRD here: https://endorlabs.atlassian.net/wiki/spaces/ENG/pages/145260563/Findings+Prioritization#What-is-risk%3F
 *
 * The default values for these filters need to be determined by analytics, backend, & customer teams.
 */
export const FindingPriorityBucketFilters = {
  [FindingPriorityBucketNames.PRIORITIZE]: [
    `spec.finding_metadata.vulnerability.spec.epss_score.probability_score > ${LOW_EPSS_THRESHOLD}`,
    'spec.level in ["FINDING_LEVEL_CRITICAL", "FINDING_LEVEL_HIGH"]',
    // 'spec.level != "FINDING_LEVEL_LOW"',
    // 'spec.finding_tags == FINDINGS_TAGS_DIRECT',
    'spec.finding_tags == FINDING_TAGS_FIX_AVAILABLE',
  ],

  [FindingPriorityBucketNames.PLAN]: [
    // `spec.finding_metadata.vulnerability.spec.epss_score.probability_score <= ${HIGH_EPSS_THRESHOLD}`,
    '((spec.level in ["FINDING_LEVEL_MEDIUM", "FINDING_LEVEL_LOW"] AND spec.finding_tags == FINDING_TAGS_DIRECT) OR (spec.level in ["FINDING_LEVEL_CRITICAL", "FINDING_LEVEL_HIGH"] AND spec.finding_tags == FINDING_TAGS_TRANSITIVE))',
    // 'spec.finding_tags == FINDINGS_TAGS_TRANSITIVE',
    'spec.finding_tags == FINDING_TAGS_FIX_AVAILABLE',
  ],

  [FindingPriorityBucketNames.BACKLOG]: [
    'spec.level in ["FINDING_LEVEL_CRITICAL", "FINDING_LEVEL_HIGH"]',
    // `spec.finding_metadata.vulnerability.spec.epss_score.probability_score <= ${LOW_EPSS_THRESHOLD}`,
    'spec.finding_tags != FINDING_TAGS_FIX_AVAILABLE',
  ],

  [FindingPriorityBucketNames.DEFER]: [
    'spec.level in ["FINDING_LEVEL_MEDIUM", "FINDING_LEVEL_LOW"]',
    'spec.finding_tags == FINDING_TAGS_UNFIXABLE',
  ],
};

export const SHARED_BUCKET_FILTER = [
  // 'spec.finding_categories == FINDING_CATEGORY_VULNERABILITY',
  filterExpressionBuilders.mainResourceContext(),
  'spec.finding_tags in ["FINDING_TAGS_POTENTIALLY_REACHABLE_FUNCTION", "FINDING_TAGS_REACHABLE_FUNCTION", "FINDING_TAGS_REACHABLE_DEPENDENCY", "FINDING_TAGS_POTENTIALLY_REACHABLE_DEPENDENCY"]',
  'spec.finding_tags != FINDING_TAGS_TEST',
];
