import { Components, Theme } from '@mui/material';

type FormControlLabelOverrides = Pick<Components<Theme>, 'MuiFormControlLabel'>;

export const getFormControlLabelOverrides = ({
  typography,
}: Theme): FormControlLabelOverrides => {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },

        label: {
          ...typography.body2,
          lineHeight: 1.1,
        },
      },
    },
  };
};
