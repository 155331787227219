import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import { has, isObject, pick } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { FILTER_COMPARATORS } from './comparator';
import { ResourceFilter } from './types';

export const FILTER_SEARCH_PARAM = 'filter.values';
export const FILTER_DEFAULT_SEARCH_PARAM = 'filter.default';

// Get the required properties from a filter, to be included in the serialized
// search parameter
const FILTER_SEARCH_PARAM_PROPERTIES: Readonly<(keyof ResourceFilter)[]> = [
  'kind',
  'key',
  'comparator',
  'value',
];

type FilterLocationGenerics = MakeGenerics<{
  Search: {
    [FILTER_SEARCH_PARAM]?: string;
    [FILTER_DEFAULT_SEARCH_PARAM]?: string;
  };
}>;

export const buildFilterSearchParams = ({
  filters,
  filterDefault,
}: {
  filters?: ResourceFilter[];
  /**
   * Default value for Search Filter
   */
  filterDefault?: string;
}) => {
  return {
    [FILTER_SEARCH_PARAM]: filters?.length
      ? stringifyFilters(filters)
      : undefined,
    [FILTER_DEFAULT_SEARCH_PARAM]: filterDefault ? filterDefault : undefined,
  };
};

export const parseFilters = (value?: string) => {
  if (!value) return;

  try {
    const parsed = JSON.parse(value);
    if (!Array.isArray(parsed)) {
      throw new Error('Failed to parse Filters: expected array');
    }

    // validate that each value has the expected properties for filter
    const isValid = parsed.every(
      (value) =>
        isObject(value) &&
        FILTER_SEARCH_PARAM_PROPERTIES.every((key) => has(value, key))
    );

    if (!isValid) {
      throw new Error('Failed to parse Filters: unexpected value');
    }

    const filters = (parsed as ResourceFilter[]).map((filter) => {
      // backwards compatability: handle previously defined filters present in url
      switch (filter.comparator.toString()) {
        case 'MATCH':
          return {
            ...filter,
            comparator: FILTER_COMPARATORS.MATCHES,
          } as ResourceFilter;
        case 'ARRAY_EMPTY':
          return {
            ...filter,
            comparator: FILTER_COMPARATORS.EQUAL,
            value: '[]',
          } as ResourceFilter;
        case 'ARRAY_NOT_EMPTY':
          return {
            ...filter,
            comparator: FILTER_COMPARATORS.NOT_EQUAL,
            value: '[]',
          } as ResourceFilter;
        case 'BOOLEAN_TRUE':
          return {
            ...filter,
            comparator: FILTER_COMPARATORS.NOT_EQUAL,
            value: 'false',
          } as ResourceFilter;
        case 'BOOLEAN_NOT_TRUE':
          return {
            ...filter,
            comparator: FILTER_COMPARATORS.NOT_EQUAL,
            value: 'true',
          } as ResourceFilter;
      }

      return filter;
    });

    return filters;
  } catch (e) {
    // failed to parse params
  }
};

export const stringifyFilters = (filters: ResourceFilter[]): string => {
  const mapped: unknown[] = filters.map((f) =>
    pick(f, FILTER_SEARCH_PARAM_PROPERTIES)
  );
  return JSON.stringify(mapped);
};

/**
 * @deprecated use instead Filter Context {@see useFilterParams}
 */
export const useFilterSearchParams = () => {
  const searchParams = useSearch<FilterLocationGenerics>();
  const navigate = useNavigate<FilterLocationGenerics>();

  const updateFilterSearchParams = useCallback(
    (filters: ResourceFilter[]) => {
      const params = pick(buildFilterSearchParams({ filters }), [
        FILTER_SEARCH_PARAM,
      ]);

      navigate({
        search: (old) => ({ ...old, ...params }),
        replace: true,
      });
    },
    [navigate]
  );

  const updateFilterDefaultSearchParams = useCallback(
    (filterDefault: string) => {
      const params = pick(buildFilterSearchParams({ filterDefault }), [
        FILTER_DEFAULT_SEARCH_PARAM,
      ]);

      navigate({
        search: (old) => ({ ...old, ...params }),
        replace: true,
      });
    },
    [navigate]
  );

  const filterSearchParams = useMemo(
    () => parseFilters(searchParams?.[FILTER_SEARCH_PARAM]) ?? [],
    // parse the filters from search params, only when the serialized value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams?.[FILTER_SEARCH_PARAM]]
  );

  const filterDefaultSearchParams = useMemo(
    () => searchParams?.[FILTER_DEFAULT_SEARCH_PARAM],
    // parse the filters from search params, only when the serialized value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams?.[FILTER_DEFAULT_SEARCH_PARAM]]
  );

  return {
    filterSearchParams,
    filterDefaultSearchParams,
    updateFilterSearchParams,
    updateFilterDefaultSearchParams,
  };
};

// NOTE: exposing the parse & stringify functionality for the filter search params
useFilterSearchParams.parse = parseFilters;
useFilterSearchParams.stringify = stringifyFilters;
