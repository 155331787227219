import { Button, Popover, Stack, Theme, Typography } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';

import { UserInfoResponseTenantInfo as TenantInfo } from '@endorlabs/api_client';
import { getChildNamespace } from '@endorlabs/endor-core/Namespace';
import { IconChevronDown, useStyles } from '@endorlabs/ui-common';

import { NamespaceSwitcher } from '../NamespaceSwitcher';

export type NamespaceSwitcherPopoverProps = {
  activeNamespace: string;
  hideLabel?: boolean;
  namespaces?: string[];
  onSelect: (namespace: string) => void;
  /**
   * List of the authorized user's tenants
   */
  tenants: TenantInfo[];
};

/**
 * Popover wrapping the Namespace Switcher
 */
export const NamespaceSwitcherPopover = ({
  activeNamespace,
  hideLabel,
  namespaces,
  onSelect,
  tenants,
}: NamespaceSwitcherPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (_: SyntheticEvent) => {
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);

  const handleNamespaceSelect = useCallback(
    (event: SyntheticEvent, namespace: string) => {
      onSelect(namespace);
      handlePopoverClose(event);
    },
    [onSelect]
  );

  const namespaceDisplay = useMemo(() => {
    return activeNamespace ? getChildNamespace(activeNamespace) : '';
  }, [activeNamespace]);

  const sx = useStyles(styles, {
    isOpen,
    hideLabel,
  });

  return (
    <>
      <Stack direction="row" alignItems="center" sx={sx}>
        <Button
          className="NamespaceSwitcher-popoverTrigger"
          variant="text"
          onClick={handlePopoverOpen}
          endIcon={
            /* mimic the dropdown arrow on the Select input */
            <IconChevronDown />
          }
        >
          <Typography variant="button">{namespaceDisplay}</Typography>
        </Button>
      </Stack>

      <Popover
        id={isOpen ? 'NamespaceSwitcher-popover' : undefined}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted={false}
        sx={{
          '& .NamespaceSwitcherContent-root': {
            maxWidth: 240,
            minWidth: 180,
          },
        }}
      >
        <NamespaceSwitcher
          activeNamespace={activeNamespace}
          namespaces={namespaces}
          onSelect={handleNamespaceSelect}
          selectedNamespace={activeNamespace} // for the popover, the selected namespace is the active namespace
          tenants={tenants}
        />
      </Popover>
    </>
  );
};

function styles(
  { palette }: Theme,
  options?: {
    isOpen: boolean;
    hideLabel?: boolean;
  }
) {
  const color = palette.text.primary;

  return {
    '& .NamespaceSwitcher-popoverTrigger': {
      color,
      minWidth: 'max-content',
      padding: 0,

      '&:hover': { backgroundColor: 'transparent' },

      '& .MuiButton-endIcon': {
        marginLeft: 1,
        transition: 'transform 300ms',
        ...(options?.isOpen && { transform: 'rotate(180deg)' }),
        ...(options?.hideLabel && { marginX: 0 }),
      },

      '& .MuiTypography-root': {
        ...(options?.hideLabel && {
          height: 0,
          width: 0,
          visibility: 'hidden',
        }),
      },
    },
  };
}
