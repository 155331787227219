import { Route } from '@tanstack/react-location';

import { WithRequired } from '@endorlabs/utils';

import { FileRouteOptions } from './createFileRoute';

export type LazyFileRouteOptions = Required<
  Pick<FileRouteOptions, 'component'>
>;

const LAZY_FILE_ROUTE_SHIM_SYMBOL = Symbol('LAZY_FILE_ROUTE_SHIM');
export interface LazyFileRouteShim extends WithRequired<Route, 'path'> {
  [LAZY_FILE_ROUTE_SHIM_SYMBOL]: undefined;
}

/**
 * Temporary utility to assist in migration to `@tanstack/react-router`
 */
export const createLazyFileRoute =
  (path: string) => (options: LazyFileRouteOptions) => {
    const { component: RouteComponent, ...rest } = options;

    const route: LazyFileRouteShim = {
      [LAZY_FILE_ROUTE_SHIM_SYMBOL]: undefined,
      element: <RouteComponent />,
      path,
      ...rest,
    };

    return route;
  };
