import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FilterDropdown } from '../../filters';

export enum FindingContainerLayersFilterOption {
  All = 'FINDING_CONTAINER_LAYERS_ALL',
  Application = 'FINDING_CONTAINER_LAYERS_APPLICATION',
  Base = 'FINDING_CONTAINER_LAYERS_BASE',
}

const FINDING_CONTAINER_LAYERS_OPTIONS = [
  {
    label: <FM defaultMessage="All Layers" />,
    value: FindingContainerLayersFilterOption.All,
  },
  {
    label: <FM defaultMessage="Base Image Layers Only" />,
    value: FindingContainerLayersFilterOption.Base,
  },
  {
    label: <FM defaultMessage="Application Layers Only" />,
    value: FindingContainerLayersFilterOption.Application,
  },
] as const;

export const FindingContainerLayersFilterField = ({
  onChange,
  value: initialValue = FindingContainerLayersFilterOption.All,
}: {
  onChange: (value: FindingContainerLayersFilterOption) => void;
  value?: FindingContainerLayersFilterOption;
}) => {
  const [internalValue, setInternalValue] = useState(initialValue);

  const handleApply = () => {
    onChange(internalValue);
  };

  const handleCancel = () => {
    setInternalValue(initialValue);
  };

  return (
    <FilterDropdown
      id="FindingContainerLayersFilterField"
      label={<FM defaultMessage="Container Layers" />}
      isActive={internalValue !== FindingContainerLayersFilterOption.All}
      onApply={handleApply}
      onCancel={handleCancel}
      useContentContainer={false}
    >
      <MenuList>
        {FINDING_CONTAINER_LAYERS_OPTIONS.map(({ label, value }) => (
          <MenuItem
            dense
            key={value}
            onClick={() => setInternalValue(value)}
            value={value}
          >
            <ListItemIcon>
              <Radio checked={value === internalValue} />
            </ListItemIcon>

            <ListItemText
              id={`MultiSelectInput--${value}`}
              primary={label}
              primaryTypographyProps={{ component: 'span', variant: 'body1' }}
            />
          </MenuItem>
        ))}
      </MenuList>
    </FilterDropdown>
  );
};
