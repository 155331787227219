import { FormattedMessage as FM } from 'react-intl';

import { V1EndorctlRC as ExitCode } from '@endorlabs/api_client';

import { ScanResultExitCodeMessages } from '../../locales';

interface ScanResultExitCodeLabelProps {
  value?: ExitCode;
}

export const ScanResultExitCodeLabel = ({
  value = ExitCode.Unspecified,
}: ScanResultExitCodeLabelProps) => (
  <FM {...ScanResultExitCodeMessages[value]} />
);
