import { orderBy as _orderBy, uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { GroupRequestParameters } from '@endorlabs/endor-core/api';
import { FINDING_LEVELS, FindingSource } from '@endorlabs/endor-core/Finding';
import {
  FilterExpression,
  filterExpressionBuilders,
  isValueFilter,
  serialize,
} from '@endorlabs/filters';
import {
  FindingResourceList,
  IQueryError,
  listFindingsQueryOptions,
  ResourceGroupResponseGroupData,
  ResourceQueryOptions,
  tryParseGroupResponseAggregationKey,
  useListPackageVersions,
  useUserPreferences,
} from '@endorlabs/queries';
import {
  getPaginatorPageSlice,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { FilterState } from '../../filters';
import { FindingAggregation } from '../types';

export interface AggregatedFinding {
  findingCounts: Partial<Record<SpecFindingLevel, number>>;
  key: string;
  title: string;
  severity: SpecFindingLevel;
  totalCount: number;
  referenceCounts?: Record<ResourceKind, number>;
  uuids: string[];
  filter: FilterExpression;
}

export interface UseAggregatedFindingsDataProps {
  enabled?: boolean;
  filterState?: FilterState;
  filterExpression: FilterExpression;
  findingAggregation: FindingAggregation;
  findingSource?: FindingSource;
  namespace: string;
}

const FINDING_AGGREGATION_PATHS: Partial<Record<FindingAggregation, string[]>> =
  {
    FINDING_AGGREGATION_DEPENDENCY: ['spec.target_dependency_package_name'],
    FINDING_AGGREGATION_FINDING_DEPENDENCY: [
      'meta.description',
      'spec.target_dependency_package_name',
    ],
    FINDING_AGGREGATION_PACKAGE: ['meta.parent_uuid'],
  };

const FINDING_AGGREGATION_FILTERS: Partial<
  Record<FindingAggregation, FilterExpression[]>
> = {
  FINDING_AGGREGATION_DEPENDENCY: [
    'meta.parent_kind==PackageVersion',
    'spec.finding_tags not contains [FINDING_TAGS_SELF]',
  ],
  FINDING_AGGREGATION_FINDING_DEPENDENCY: [
    'meta.parent_kind==PackageVersion',
    'spec.finding_tags not contains [FINDING_TAGS_SELF]',
  ],
  FINDING_AGGREGATION_PACKAGE: ['meta.parent_kind==PackageVersion'],
};

const FINDING_SOURCE_REFERENCE_COUNT_MAPPING: Partial<
  Record<FindingSource, Record<string, ResourceKind>>
> = {
  FINDING_SOURCE_DEPENDENCY: {
    'meta.parent_uuid': 'PackageVersion',
  },
};

const hasSeverityFilter = (
  severity: SpecFindingLevel,
  filterState?: FilterState
) => {
  if (!filterState?.values || !filterState.values.size) return true;

  const filter = filterState.values.get('spec.level');

  // if no severity filter, return true
  if (!filter) return true;

  // check the severity filter value
  return isValueFilter(filter) && Array.isArray(filter.value)
    ? filter.value.includes(severity)
    : filter.value === severity;
};

export const useAggregatedFindingsData = ({
  enabled = true,
  findingSource,
  findingAggregation,
  filterExpression,
  filterState,
  namespace,
}: UseAggregatedFindingsDataProps) => {
  // Get finding aggregation paths
  const aggregationPaths = FINDING_AGGREGATION_PATHS[findingAggregation] ?? [
    'meta.description',
  ];
  const aggregationFilters =
    FINDING_AGGREGATION_FILTERS[findingAggregation] ?? [];

  const referenceCountPaths = useMemo(() => {
    const base = {
      'spec.project_uuid': 'Project',
    };

    if (findingSource) {
      return {
        ...base,
        ...FINDING_SOURCE_REFERENCE_COUNT_MAPPING[findingSource],
      };
    }

    return base;
  }, [findingSource]);

  const includeChildNamespaces = useUserPreferences(
    (s) => s.auth?.includeChildNamespaces
  );
  const baseParams: GroupRequestParameters = {
    group: {
      show_aggregation_uuids: true,
      aggregation_paths: aggregationPaths.join(','),
      unique_count_paths: Object.keys(referenceCountPaths).join(','),
    },
    traverse: includeChildNamespaces,
  };

  const qGroupedFindings = useQueries(
    FINDING_LEVELS.map((level) => {
      const aggregationFilterExpression = [
        filterExpression,
        `spec.level==${level}`,
      ].filter((v): v is string => !!v);

      if (!findingSource || findingSource === FindingSource.All) {
        aggregationFilterExpression.push(...aggregationFilters);
      }

      const requestParameters = {
        ...baseParams,
        filter: filterExpressionBuilders.and(aggregationFilterExpression),
      };

      const { queryFn, queryKey } = listFindingsQueryOptions(
        namespace,
        requestParameters
      );

      return {
        enabled: enabled && hasSeverityFilter(level, filterState),
        queryFn,
        queryKey,
      } satisfies ResourceQueryOptions<FindingResourceList>;
    })
  );

  const aggregation = useMemo(() => {
    const aggregation: Record<string, AggregatedFinding> = {};

    const mergeGroupResponse = (
      severity: SpecFindingLevel,
      groups: Record<string, ResourceGroupResponseGroupData>
    ) => {
      Object.entries(groups).forEach(([key, group]) => {
        const aggregationValues = tryParseGroupResponseAggregationKey(key);

        // get aggregation values from response
        const title = aggregationValues[0].value as string;
        const count = group.aggregation_count?.count ?? 1;
        const uuids = [...(group.aggregation_uuids ?? [])].sort();

        // Handle "None" aggregation by projecting an aggregation with groups of 1
        if (findingAggregation === FindingAggregation.None) {
          for (const uuid of uuids) {
            aggregation[uuid] = {
              key: uuid,
              findingCounts: {
                [severity]: 1,
              },
              severity,
              title,
              totalCount: 1,
              uuids: [uuid],
              filter: `uuid=="${uuid}"`,
            };
          }

          return;
        }

        // Handle other aggregation types
        const existing = aggregation[key] ?? {
          severity,
          totalCount: 0,
          uuids: [],
        };

        // Build up reference counts
        const referenceCounts: Record<string, number> =
          existing.referenceCounts ?? {};
        for (const [key, count] of Object.entries(group.unique_counts ?? {})) {
          if (Reflect.has(referenceCountPaths, key)) {
            const kind = Reflect.get(referenceCountPaths, key);
            referenceCounts[kind] =
              (referenceCounts[kind] || 0) + (count.count ?? 1);
          }
        }

        // Build filter for the group from the aggregation key
        const aggregationFilterExpression = serialize(
          aggregationValues.map(({ key, value }) => ({
            key,
            value: value as string,
            comparator: 'EQUAL' as const,
          }))
        );

        aggregation[key] = {
          ...existing,
          key,
          title,
          findingCounts: {
            ...existing?.findingCounts,
            [severity]: count,
          },
          totalCount: existing.totalCount + count,
          uuids: [...existing.uuids, ...uuids],
          referenceCounts,
          filter: filterExpressionBuilders.and([
            filterExpression,
            ...aggregationFilters,
            aggregationFilterExpression,
          ]),
        };
      });
    };

    // Loop through the query data
    FINDING_LEVELS.forEach((level, index) => {
      const query = qGroupedFindings[index];
      mergeGroupResponse(level, query.data?.group_response?.groups ?? {});
    });

    const result = Object.values(aggregation);

    // Sort aggregated findings by severity counts
    return _orderBy(
      result,
      [
        (v) => v.findingCounts.FINDING_LEVEL_CRITICAL ?? 0,
        (v) => v.findingCounts.FINDING_LEVEL_HIGH ?? 0,
        // use total count as the tie breaker after Critical and High
        (v) => v.totalCount,
      ],
      ['desc', 'desc', 'desc']
    );
  }, [findingAggregation, qGroupedFindings, referenceCountPaths]);

  const paginator = useDataTablePaginator({
    totalCount: aggregation.length,
  });

  const data = useMemo(
    () => getPaginatorPageSlice(paginator.state, aggregation),
    [aggregation, paginator.state]
  );

  // When aggregating by Package, fetch the related package names
  const packageVersionUuids = _uniq(data.map((d) => d.title)).filter((i) => i);

  const qListPackageVersions = useListPackageVersions(
    namespace,
    {
      enabled:
        findingAggregation === FindingAggregation.Package &&
        !!packageVersionUuids.length,
    },
    {
      filter: `uuid in ["${packageVersionUuids.join('","')}"]`,
      mask: 'uuid,meta.name',
    }
  );

  // Enrich the data with the additional data, if needed
  const enrichedData = useMemo(() => {
    if (findingAggregation !== FindingAggregation.Package) {
      return data;
    }
    const packageVersionsByUuid = new Map(
      qListPackageVersions.data?.list?.objects.map((o) => [o.uuid, o])
    );

    return data.map((d) => {
      const packageVersion = packageVersionsByUuid.get(d.title);

      return { ...d, title: packageVersion?.meta.name ?? d.title };
    });
  }, [data, findingAggregation, qListPackageVersions.data]);

  // If one of the calls has error, return the error, along with the finding
  // level of the call.
  const error = useMemo(() => {
    const errorIndex = qGroupedFindings.findIndex((q) => q.error);
    if (errorIndex === -1) return;

    return {
      ...(qGroupedFindings[errorIndex].error as IQueryError),
      level: FINDING_LEVELS[errorIndex],
    };
  }, [qGroupedFindings]);

  const isLoadingAny =
    qGroupedFindings.some((q) => q.isLoading) || qListPackageVersions.isLoading;

  // When critical findings are included, consider loading complete after the
  // critical findings are loaded. Else, consider loading complete after all
  // findings are loaded.
  const isLoading = hasSeverityFilter(SpecFindingLevel.Critical, filterState)
    ? qGroupedFindings[0].isLoading || qListPackageVersions.isLoading
    : isLoadingAny;

  const totalCount = aggregation.reduce((t, v) => t + v.totalCount, 0);

  return {
    data: enrichedData,
    error,
    isLoading,
    isLoadingAny,
    paginator,
    totalCount,
  };
};
