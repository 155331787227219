import { Stack, Theme, Typography, useTheme } from '@mui/material';

import {
  ExternalLink,
  IconCircleFilled,
  useStyles,
} from '@endorlabs/ui-common';

export const ReferencesStringDisplaySection = ({
  references = [],
}: {
  references: string[];
}) => {
  const sx = useStyles(styles);
  const { space } = useTheme();

  return (
    <Stack className="FindingDetailSecurityReferences-root" gap={space.xs}>
      {references.map((ref) => {
        return (
          <Stack
            className="FindingDetailSecurityReferences-item"
            key={ref}
            sx={sx}
          >
            <Typography component="span">・</Typography>
            <ExternalLink sx={{ display: 'inline-block' }} to={ref}>
              {ref}
            </ExternalLink>
          </Stack>
        );
      })}
    </Stack>
  );
};

function styles({ typography }: Theme) {
  return {
    '&.FindingDetailSecurityReferences-item': {
      display: 'flex',
      flexWrap: 'nowrap',
      fontSize: typography.body2.fontSize ?? 'inherit',
      maxWidth: '100%',
      flexDirection: 'row',
      alignItems: 'center',
    },
  };
}
