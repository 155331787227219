import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  PackageManagerServiceApi,
  V1ListParameters,
  V1PackageManager,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import { PackageManagerResource, PackageManagerResourceList } from './types';
import { ResourceMutateOptions, ResourceQueryOptions } from './types';
import {
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface PackageManagerReadParams {
  namespace: string;
  uuid: string;
}

export interface PackageManagerWriteParams {
  namespace: string;
  resource: V1PackageManager;
}

export interface PackageManagerUpdateParams extends PackageManagerWriteParams {
  mask?: string;
}

type ListPackageManagerOptions =
  ResourceQueryOptions<PackageManagerResourceList>;
type GetPackageManagerOptions = ResourceQueryOptions<PackageManagerResource>;
type UpsertPackageManagerOptions = ResourceMutateOptions<
  V1PackageManager,
  PackageManagerWriteParams
>;
type DeletePackageManagerOptions = ResourceMutateOptions<
  object,
  PackageManagerReadParams
>;

const BASE_KEY = 'v1/packageManagers';
const QK = {
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const PackageManagerQueryKeys = QK;

export const PACKAGE_MANAGER_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new PackageManagerServiceApi(getClientConfiguration());

export const listPackageManagers = async (
  namespace: string,
  listParams?: V1ListParameters
) => {
  const api = getApiService();
  const resp = await api.packageManagerServiceListPackageManagers(
    namespace,
    ...buildListParamArgs(listParams ?? {})
  );
  return resp.data as PackageManagerResourceList;
};

export const useListPackageManagers = (
  namespace: string,
  opts: ListPackageManagerOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageManager',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listPackageManagers(namespace, requestParameters),
    opts
  );
};

export const getPackageManager = async ({
  namespace,
  uuid,
}: PackageManagerReadParams) => {
  const api = getApiService();
  const resp = await api.packageManagerServiceGetPackageManager(
    namespace,
    uuid
  );
  return resp.data as PackageManagerResource;
};

export const useGetPackageManager = (
  params: PackageManagerReadParams,
  opts: GetPackageManagerOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getPackageManager(params),
    opts
  );
};

const createPackageManager = async (params: PackageManagerWriteParams) => {
  const api = getApiService();
  const resp = await api.packageManagerServiceCreatePackageManager(
    params.namespace,
    params.resource
  );
  return resp.data as PackageManagerResource;
};

export const useCreatePackageManager = (
  opts: UpsertPackageManagerOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'PackageManager'),
    mutationFn: (params: PackageManagerWriteParams) =>
      createPackageManager(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updatePackageManager = async (params: PackageManagerUpdateParams) => {
  const { resource, namespace, mask = PACKAGE_MANAGER_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.packageManagerServiceUpdatePackageManager(
    namespace,
    req
  );
  return resp.data as PackageManagerResource;
};

export const useUpdatePackageManager = (
  opts: UpsertPackageManagerOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'PackageManager'),
    mutationFn: (params: PackageManagerUpdateParams) =>
      updatePackageManager(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deletePackageManager = async (params: PackageManagerReadParams) => {
  const api = getApiService();
  const resp = await api.packageManagerServiceDeletePackageManager(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeletePackageManager = (
  opts: DeletePackageManagerOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'PackageManager'),
    mutationFn: (params: PackageManagerReadParams) =>
      deletePackageManager(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
