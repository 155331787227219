import {
  Autocomplete,
  AutocompleteChangeReason,
  Checkbox,
  Chip,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import {
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { LabelFieldHelperText } from '../LabelFieldHelperText';
import {
  getLabelsFieldValidationRules,
  LabelsFieldValidationType,
} from './utils';

export enum LabelCheckboxState {
  Checked = 'checked',
  Unchecked = 'unchecked',
  Indeterminate = 'indeterminate',
}

export type ControlledMultistateLabelsFieldProps<
  TFieldValues extends FieldValues
> = Omit<ControllerProps<TFieldValues>, 'render' | 'rules'> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
  TextFieldProps & {
    validation?: LabelsFieldValidationType;
    multiStateLabelsResourceList?: string[];
  };

export type ControlledMultistateLabelsFieldValue = {
  label: string;
  resourceList: string[];
  state: LabelCheckboxState;
};

/**
 * A controlled field for use in react-hook-form. Wraps MUI's Autocomplete to
 * provide a normalized interface for adding labels ("tags") in the UI.
 */
export const ControlledMultistateLabelsField = <T extends FieldValues>({
  control,
  defaultValue = [] as PathValue<T, Path<T>>,
  helperText,
  inputProps,
  name,
  label,
  placeholder,
  validation,
  multiStateLabelsResourceList = [],
  ...textFieldProps
}: ControlledMultistateLabelsFieldProps<T>) => {
  const theme = useTheme();
  const { rules, validateSingleValue } =
    getLabelsFieldValidationRules(validation);

  const { field, fieldState, formState } = useController({
    control,
    // NOTE: `name` prop is used for react-hook-form. Input `name` can be set with `htmlName`.
    name,
    defaultValue,
    rules,
  });

  const [indeterminateValuesMap, setIndeterminateValuesMap] = useState<
    Record<string, string[]>
  >({});

  const labels: ControlledMultistateLabelsFieldValue[] = useMemo(() => {
    return field.value ?? [];
  }, [field.value]);

  // track the input value, and add as a label on blur, if it does not already exist,
  // allowing the user to enter value, then tab to the save button.
  const [labelInput, setLabelInput] = useState('');

  const handleChange = useCallback(
    (
      evt?: SyntheticEvent,
      value?: (ControlledMultistateLabelsFieldValue | string)[],
      changeReason?: AutocompleteChangeReason
    ) => {
      const cleanLabelInput = labelInput.trim();
      if (changeReason === 'createOption' || changeReason === 'blur') {
        // if the current text input is a new label, add it to the fields
        const existingLabel = labels.find(
          (label) => label.label === cleanLabelInput
        );
        if (cleanLabelInput && !existingLabel) {
          const nextValues = labels.concat({
            label: cleanLabelInput,
            state: LabelCheckboxState.Checked,
            resourceList: multiStateLabelsResourceList,
          });
          field.onChange(nextValues);
        }

        setLabelInput('');
      }
      if (changeReason === 'blur') {
        field.onBlur();
      }
    },
    [field, labelInput, labels, multiStateLabelsResourceList]
  );

  const handleBlur = useCallback(() => {
    handleChange(undefined, undefined, 'blur');
    field.onBlur();
  }, [field, handleChange]);

  const handleMultistateLabelChange = (changedPosition: number) => {
    const labels: ControlledMultistateLabelsFieldValue[] = field.value ?? [];

    const changedLabel = labels[changedPosition];

    //If changed value is invalid, remove from the list.
    if (validateSingleValue(changedLabel.label) !== true) {
      field.onChange([
        ...labels.slice(0, changedPosition),
        ...labels.slice(changedPosition + 1),
      ]);
      return;
    }

    switch (changedLabel.state) {
      case LabelCheckboxState.Checked: {
        if (indeterminateValuesMap[changedLabel.label]) {
          changedLabel.state = LabelCheckboxState.Indeterminate;
          changedLabel.resourceList =
            indeterminateValuesMap[changedLabel.label];
        } else {
          changedLabel.state = LabelCheckboxState.Unchecked;
          changedLabel.resourceList = [];
        }
        break;
      }
      case LabelCheckboxState.Indeterminate: {
        changedLabel.state = LabelCheckboxState.Unchecked;
        setIndeterminateValuesMap({
          ...indeterminateValuesMap,
          [changedLabel.label]: changedLabel.resourceList,
        });
        changedLabel.resourceList = [];
        break;
      }
      case LabelCheckboxState.Unchecked: {
        changedLabel.state = LabelCheckboxState.Checked;
        changedLabel.resourceList = multiStateLabelsResourceList;
        break;
      }
    }
    field.onChange(labels);
  };

  const isDirtyValue = (value: ControlledMultistateLabelsFieldValue) => {
    const existingIndex = formState?.defaultValues?.['tags'].findIndex(
      (defaultValue: ControlledMultistateLabelsFieldValue) =>
        defaultValue.label === value.label && defaultValue.state === value.state
    );
    return existingIndex === -1;
  };

  return (
    <Autocomplete
      disabled={textFieldProps.disabled}
      id={`${name}-autocomplete`}
      onBlur={handleBlur}
      onChange={handleChange}
      inputValue={labelInput}
      onInputChange={(_, value) => setLabelInput(value)}
      // NOTE: `multiple`, combined with `freeSolo`, allows for adding arbitrary values ("tags")
      // https://mui.com/material-ui/react-autocomplete/#free-solo
      multiple
      freeSolo
      fullWidth
      options={[]}
      disableClearable // NOTE: prevents clearing _all_ tags
      renderTags={(
        value: ControlledMultistateLabelsFieldValue[],
        getTagProps
      ) =>
        value.map((option, index) => {
          const label = option.label;
          const state = option.state;
          const isValidState = validateSingleValue(label) === true;
          return (
            // NOTE: `key` provided from `getTagProps()`
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              color={isValidState ? 'default' : 'error'}
              label={
                <Tooltip
                  title={isValidState ? <FM {...messages[state]} /> : ''}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    columnGap={theme.space.xs}
                  >
                    <Typography
                      sx={{
                        textDecoration:
                          state === LabelCheckboxState.Unchecked
                            ? 'line-through'
                            : 'normal',
                      }}
                      color={
                        state === LabelCheckboxState.Unchecked
                          ? theme.palette.text.disabled
                          : theme.palette.text.primary
                      }
                      fontWeight={isDirtyValue(option) ? 'bold' : 'normal'}
                    >
                      {label}
                    </Typography>
                    <Checkbox
                      checked={state === LabelCheckboxState.Checked}
                      indeterminate={state === LabelCheckboxState.Indeterminate}
                      onClick={() => {
                        handleMultistateLabelChange(index);
                      }}
                      sx={{
                        margin: 0,
                        backgroundColor: 'transparent',
                        transform: 'scale(0.8)',
                        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                          backgroundColor: 'transparent',
                          color: theme.palette.grey[600],
                        },
                      }}
                    />
                  </Stack>
                </Tooltip>
              }
              onDelete={undefined}
              size="small"
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          error={Boolean(fieldState.error)}
          id={`${name}-input`}
          inputProps={{ ...params.inputProps, ...inputProps }}
          inputRef={field.ref}
          label={label}
          placeholder={placeholder}
          helperText={
            <LabelFieldHelperText
              fieldState={fieldState}
              helperText={helperText}
            />
          }
          variant="standard"
        />
      )}
      sx={({ spacing }) => ({
        display: textFieldProps.type === 'hidden' ? 'none' : 'auto',

        // apply styles to the nested input
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          // prevent the height from jumping when a chip is added
          minHeight: spacing(8),
          // force the input to wrap when close to the end
          minWidth: spacing(32),
        },
      })}
      value={field.value}
    />
  );
};

const messages = defineMessages({
  [LabelCheckboxState.Checked]: {
    defaultMessage:
      'This tag will be applied to all selected projects. Click to restore its original state.',
  },
  [LabelCheckboxState.Indeterminate]: {
    defaultMessage:
      'This tag only applies to some selected projects. Click to remove it, or click twice to apply it to all selected projects.',
  },
  [LabelCheckboxState.Unchecked]: {
    defaultMessage:
      'This tag will be removed from the selected projects. Click to apply it to all selected projects.',
  },
});
