import { PropsWithChildren, useMemo } from 'react';

import { GraphData, GraphDataContext } from './GraphData';

type GraphDataProviderProps = PropsWithChildren<Omit<GraphData, 'state'>>;

export const GraphDataProvider = ({
  children,
  graph,
  nodes,
}: GraphDataProviderProps) => {
  const context = useMemo(() => {
    return { graph, nodes };
  }, [graph, nodes]);

  return (
    <GraphDataContext.Provider value={context}>
      {children}
    </GraphDataContext.Provider>
  );
};
