/**
 * Adapted from https://github.com/feathericons/react-feather/blob/master/src/icons/terminal.js
 *
 * Adds the `<rect>` element as outline border, and adjusts the size of the
 * inner elements from {@see Terminal}.
 */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type TerminalOutlinedProps = {
  color?: string;
  size?: string | number;
};

const TerminalOutlined = forwardRef<SVGSVGElement, TerminalOutlinedProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <polyline points="7 15 10 12 7 9" />

        <line x1="14" y1="15" x2="17" y2="15" />

        <rect x="2" y="3" width="20" height="18" rx="2" ry="2" />
      </svg>
    );
  }
);

TerminalOutlined.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TerminalOutlined.displayName = 'TerminalOutlined';

export default TerminalOutlined;
