import { IconButton } from '@mui/material';

import { IconArrowDown, IconArrowUp } from '../../themes';

export interface DataTableSortIconButtonProps {
  isSorted: boolean;
  isSortedDesc?: boolean;
}

export const DataTableSortIconButton = ({
  isSorted,
  isSortedDesc = false,
}: DataTableSortIconButtonProps) => {
  return (
    <IconButton
      size="small"
      sx={{
        opacity: isSorted ? 1 : 0,
        '.MuiTableCell-root:hover &': {
          opacity: 0.5,
        },
      }}
    >
      {isSorted ? (
        isSortedDesc ? (
          <IconArrowDown fontSize="inherit" />
        ) : (
          <IconArrowUp fontSize="inherit" />
        )
      ) : (
        <IconArrowDown fontSize="inherit" />
      )}
    </IconButton>
  );
};
