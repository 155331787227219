import { defineMessages, MessageDescriptor } from 'react-intl';

import { RemediationRisk } from '../types';
/**
 * Map of RemediationRisk to internationalized, human-readable labels.
 */
export const RemediationRiskMessages: Record<
  RemediationRisk,
  MessageDescriptor
> = defineMessages({
  low: { defaultMessage: 'Low' },
  medium: { defaultMessage: 'Medium' },
  high: { defaultMessage: 'High' },
});
