/* eslint sort-keys: "error" */
import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl';

import { ResourceCRUDAction } from '@endorlabs/endor-core/api';

const ResourceReadActionMessage = defineMessage({ defaultMessage: 'Read' });
const ResourceReadActionCompletedMessage = defineMessage({
  defaultMessage: 'Read',
});

export const ResourceCRUDActionMessages: Record<
  ResourceCRUDAction,
  MessageDescriptor
> = {
  ...defineMessages({
    COUNT: { defaultMessage: 'Count' },
    CREATE: { defaultMessage: 'Create' },
    DELETE: { defaultMessage: 'Delete' },
    UPDATE: { defaultMessage: 'Update' },
  }),
  // Use a common intl message for other "read" actions
  GET: ResourceReadActionMessage,
  GROUP: ResourceReadActionMessage,
  LIST: ResourceReadActionMessage,
  LIST_ALL: ResourceReadActionMessage,
};

export const ResourceCRUDActionCompletedMessages: Record<
  ResourceCRUDAction,
  MessageDescriptor
> = {
  ...defineMessages({
    COUNT: { defaultMessage: 'Counted' },
    CREATE: { defaultMessage: 'Created' },
    DELETE: { defaultMessage: 'Deleted' },
    UPDATE: { defaultMessage: 'Updated' },
  }),
  // Use a common intl message for other "read" actions
  GET: ResourceReadActionCompletedMessage,
  GROUP: ResourceReadActionCompletedMessage,
  LIST: ResourceReadActionCompletedMessage,
  LIST_ALL: ResourceReadActionCompletedMessage,
};
