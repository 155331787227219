import {
  defineMessages,
  FormattedMessage as FM,
  MessageDescriptor,
} from 'react-intl';

import { Pip } from '@endorlabs/ui-common';

const CONFIDENCE_LEVELS = ['HIGH', 'MEDIUM', 'LOW', 'UNKNOWN'] as const;

type ConfidenceType = (typeof CONFIDENCE_LEVELS)[number];

type ConfidenceDisplayProps = {
  confidence?: string;
  showIcon?: boolean;
  showLabel?: boolean;
  size?: 'xsmall' | 'small' | 'medium';
};

const ConfidenceColorMap: Record<
  ConfidenceType,
  'default' | 'critical' | 'error' | 'warning'
> = {
  HIGH: 'critical',
  MEDIUM: 'error',
  LOW: 'warning',
  UNKNOWN: 'default',
} as const;

const ConfidenceMessage: Record<ConfidenceType, MessageDescriptor> =
  defineMessages({
    HIGH: { defaultMessage: 'High' },
    LOW: { defaultMessage: 'Low' },
    MEDIUM: { defaultMessage: 'Medium' },
    UNKNOWN: { defaultMessage: 'Unknown' },
  });

export const ConfidenceDisplaySection = ({
  confidence,
  showLabel = false,
  size = 'xsmall',
}: ConfidenceDisplayProps) => {
  const mapConfidenceValueToType = (value?: string): ConfidenceType => {
    if (!value) return 'UNKNOWN';
    return value.toUpperCase() as ConfidenceType;
  };

  const confidenceValue = mapConfidenceValueToType(confidence);

  return (
    <Pip
      color={ConfidenceColorMap[confidenceValue] ?? 'default'}
      label={
        showLabel ? <FM {...ConfidenceMessage[confidenceValue]} /> : 'Unknown'
      }
      size={size}
    />
  );
};
