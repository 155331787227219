import { set as _set } from 'lodash-es';
import { useMemo } from 'react';

import {
  GroupByTimeGroupByTimeInterval,
  V1FindingLogSpecOperation,
} from '@endorlabs/api_client';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import { ResourceGroupResponse, useGroupFindingLogs } from '@endorlabs/queries';
import { weightedAverage } from '@endorlabs/utils/math';

export const useDashboardFindingResolutionTimeTrendData = ({
  namespace,
  baseFilter,
  groupByTimeOptions,
}: {
  namespace: string;
  baseFilter: FilterExpression;
  groupByTimeOptions: {
    interval: GroupByTimeGroupByTimeInterval;
    group_size: number;
  };
}) => {
  const getFindingResolutionOverTimeParams = (
    aggregationOperator: 'min' | 'max' | 'avg'
  ) => {
    return {
      filter: filterExpressionBuilders.and([
        baseFilter,
        `spec.operation==${V1FindingLogSpecOperation.Delete}`,
      ]),
      group_by_time: {
        aggregation_operator: aggregationOperator,
        aggregation_paths: 'meta.create_time',
        aggregation_value_field: 'spec.days_unresolved',
        // interval: GroupByTimeGroupByTimeInterval.Week,
        mode: 'count',
        ...groupByTimeOptions,
      },
    };
  };
  const qGroupMin = useGroupFindingLogs(
    namespace,
    getFindingResolutionOverTimeParams('min')
  );

  const qGroupMax = useGroupFindingLogs(
    namespace,
    getFindingResolutionOverTimeParams('max')
  );

  const qGroupAvg = useGroupFindingLogs(
    namespace,
    getFindingResolutionOverTimeParams('avg')
  );

  const isLoading = [qGroupMin, qGroupMax, qGroupAvg].some((q) => q.isLoading);

  const data = useMemo(() => {
    const chartDataByDate: Record<string, Record<string, number>> = {};

    const setDataForAggregator = (
      aggregationOperator: 'min' | 'max' | 'avg',
      data?: ResourceGroupResponse
    ) => {
      const group = data?.groups ?? {};
      Object.entries(group).forEach(([key, group]) => {
        const count = group.aggregation_count?.count ?? 0;
        const value = group.aggregation_value?.value ?? 0;
        _set(chartDataByDate, [key, 'count'], count);
        _set(chartDataByDate, [key, aggregationOperator], value);
      });
    };

    setDataForAggregator('min', qGroupMin.data);
    setDataForAggregator('max', qGroupMax.data);
    setDataForAggregator('avg', qGroupAvg.data);

    const defaultValues = { min: 0, max: 0, avg: 0, count: 0 };

    // TODO: limit to max item count?
    return (
      Object.entries(chartDataByDate)
        .map(([key, data]) => ({
          key,
          // HACK: date is a JSON-encoded ISO Date
          date: JSON.parse(key) as string,
          ...defaultValues,
          ...data,
        }))
        // sort by date, ascending
        .sort((a, b) => a.key.localeCompare(b.key))
    );
  }, [qGroupAvg.data, qGroupMax.data, qGroupMin.data]);

  const isEmpty = data.length === 0;

  // From the trend data, calculate a snapshot of
  // the min, max, and weighted average from the series
  const snapshot = useMemo(() => {
    if (!data.length) {
      return { avg: 0, count: 0, max: 0, min: 0 };
    }

    let count = 0;
    let max = 0;
    let min = Infinity;
    const weightedValues = [];

    for (const d of data) {
      count += d.count;
      max = Math.max(d.max, max);
      min = Math.min(d.min, min);
      weightedValues.push([d.avg, d.count]);
    }

    const avg = weightedAverage(weightedValues);

    return { avg, count, max, min };
  }, [data]);

  return { data, isEmpty, isLoading, snapshot };
};
