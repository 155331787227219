import { Grid, Stack, Typography } from '@mui/material';
import { MakeGenerics } from '@tanstack/react-location';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  useCountInstallations,
  useGetProject,
  useListRepositories,
  useListRepositoryVersions,
  useListScanResults,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  ButtonSecondary,
  CopyToClipboardChip,
  EmptyState,
  IconCode,
  IconDownload,
  IconLayout,
  ProjectNameDisplay,
  ScanResultStatusIndicator,
  UIProjectUtils,
  useFileDownload,
} from '@endorlabs/ui-common';

import { PageHeader, useScanIssuesDetailDrawer } from '../../../components';
import { useProjectVersionMetadata } from '../../../domains/Projects';
import { useAuthInfo } from '../../../providers';
import {
  getProjectDetailPath,
  getProjectPath,
  useFullMatch,
  usePageTitle,
} from '../../../routes';
import { ProjectVersionDetailView } from '../../ProjectVersion/ProjectVersionDetailView';

export type ProjectCIRunDetailLocationGenerics = MakeGenerics<{
  Params: {
    activeView?: string;
    pr_run_id: string;
    project_uuid: string;
  };
}>;

/**
 * @deprecated will be replaced with the {@see ProjectVersionDetailPage}
 */
export const ProjectCIRunDetailPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { pr_run_id: ciRunId, project_uuid: projectUuid, activeView },
  } = useFullMatch<ProjectCIRunDetailLocationGenerics>();

  const { DetailDrawer } = useScanIssuesDetailDrawer();
  const handleStatusIndicatorClick = () =>
    DetailDrawer.activate(
      {
        contextId: repositoryVersion?.context?.id,
        contextType: repositoryVersion?.context?.type,
        namespace: tenantName,
        projectUuid: projectUuid,
      },
      {
        namespace: tenantName,
        scanContext: repositoryVersion?.context,
        scanResults: qScanResults.data?.objects,
        projectUuid: projectUuid,
      }
    );

  const [viewMode, setViewMode] = useState<'metadata' | undefined>();
  const isMetadataView = viewMode === 'metadata';
  const toggleViewMode = () => {
    if (viewMode === 'metadata') {
      setViewMode(undefined);
    } else {
      setViewMode('metadata');
    }
  };

  const qProject = useGetProject({ namespace: tenantName, uuid: projectUuid });
  const qInstallationCount = useCountInstallations(
    tenantName,
    { enabled: !!qProject.data },
    {
      filter: `meta.parent_uuid==${projectUuid}`,
    }
  );
  const isGitHubInstallation = (qInstallationCount.data?.count ?? 0) > 0;

  const qRepositories = useListRepositories(
    tenantName,
    { enabled: !!projectUuid },
    {
      filter: `meta.parent_uuid==${projectUuid}`,
      mask: 'uuid,tenant_meta,meta.name,spec.default_branch',
      // should only be 1
      page_size: 1,
    }
  );
  const repository = qRepositories.data?.list?.objects[0];

  const qRepositoryVersions = useListRepositoryVersions(
    tenantName,
    {},
    {
      filter: [
        `context.id==${ciRunId}`,
        `context.type==CONTEXT_TYPE_CI_RUN`,
      ].join(' and '),
      // should only be one
      page_size: 1,
    }
  );

  const repositoryVersion = qRepositoryVersions.data?.list?.objects[0];

  const qScanResults = useListScanResults(
    tenantName,
    {},
    {
      filter: [
        `context.id==${ciRunId}`,
        `context.type==CONTEXT_TYPE_CI_RUN`,
      ].join(' and '),
    }
  );

  const [_, downloadData, setIsDownloaded] = useFileDownload({
    filetype: 'json',
    filename: `ci-run_scan-results_${ciRunId}.json`,
  });
  const handleDownload = () => {
    downloadData(JSON.stringify(qScanResults.data, null, 2));

    setTimeout(() => {
      setIsDownloaded(false);
    }, 1800);
  };

  const projectName = qProject.data
    ? UIProjectUtils.parseProjectName(
        qProject.data.meta.name,
        qProject.data.spec.platform_source
      )
    : undefined;
  const ProjectPlatformIcon = UIProjectUtils.getPlatformIcon(
    qProject.data?.spec.platform_source
  );

  usePageTitle({
    projectName: projectName,
  });

  const { summary } = useProjectVersionMetadata(
    qProject.data,
    repositoryVersion
  );

  const repositoryVersionMetadataProps = {
    summary,
  };

  // Handle error, loading, and empty states
  const hasError = qProject.isError || qRepositoryVersions.isError;
  const isLoading = qProject.isLoading || qRepositoryVersions.isLoading;
  const isEmptyState = !isLoading && !hasError && !repositoryVersion;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          action={
            <Stack direction="row" spacing={2}>
              <ButtonSecondary
                onClick={() => toggleViewMode()}
                startIcon={isMetadataView ? <IconLayout /> : <IconCode />}
              >
                {isMetadataView ? (
                  <FM defaultMessage="Project Version Details" />
                ) : (
                  <FM defaultMessage="Raw Data" />
                )}
              </ButtonSecondary>

              <ButtonPrimary
                disabled={!qScanResults.isSuccess}
                onClick={handleDownload}
                startIcon={<IconDownload />}
              >
                <FM defaultMessage="Download Scan Results" />
              </ButtonPrimary>
            </Stack>
          }
          breadcrumbsLinks={[
            {
              label: <FM defaultMessage="All Projects" />,
              url: getProjectPath({ tenantName }),
            },
            {
              label: <FM defaultMessage="Project Detail" />,
              url: getProjectPath({ tenantName, uuid: projectUuid }),
            },
            {
              label: <FM defaultMessage="PR Runs" />,
              url: getProjectDetailPath({
                tenantName,
                uuid: projectUuid,
                section: 'pr-runs',
              }),
            },
          ]}
          breadcrumbsItems={
            projectName
              ? [
                  <ProjectNameDisplay
                    key={projectName}
                    name={projectName}
                    platformSource={qProject.data?.spec.platform_source}
                    size="medium"
                  />,
                  <Stack
                    key={ciRunId}
                    direction="row"
                    alignItems="center"
                    gap={2}
                  >
                    <ScanResultStatusIndicator
                      scanResults={qScanResults.data?.objects}
                      onClick={handleStatusIndicatorClick}
                    />
                    <Typography variant="button">{ciRunId}</Typography>
                  </Stack>,
                ]
              : []
          }
          title={projectName}
          titleDetails={
            <Stack direction="row" spacing={2}>
              <ScanResultStatusIndicator
                scanResults={qScanResults.data?.objects}
                onClick={handleStatusIndicatorClick}
              />

              <CopyToClipboardChip value={ciRunId} />
            </Stack>
          }
          metadata={repositoryVersionMetadataProps}
          Icon={ProjectPlatformIcon}
        />
      </Grid>

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="PR Run Details Not Found" />}
          />
        </Grid>
      )}

      {!isEmptyState && !hasError && (
        <Grid item>
          <ProjectVersionDetailView
            activeTab={activeView}
            isLoading={isLoading}
            namespace={tenantName}
            project={qProject.data}
            repository={repository}
            repositoryVersion={repositoryVersion}
            viewMode={viewMode}
          />
        </Grid>
      )}
    </Grid>
  );
};
