import {
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { JSXElementConstructor, ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

export type NotificationTargetIntegrationDisplayProps = {
  action?: ReactNode;
  count?: number;
  Icon: JSXElementConstructor<SvgIconProps>;
  isLoading?: boolean;
  showCount?: boolean;
  title: ReactNode;
};

export const NotificationTargetIntegrationDisplay = ({
  action,
  count = 0,
  Icon,
  isLoading,
  showCount = true,
  title,
}: NotificationTargetIntegrationDisplayProps) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        action={action}
        avatar={<Icon fontSize="large" />}
        title={title}
        titleTypographyProps={{
          component: 'h3',
          variant: 'h2',
        }}
      ></CardHeader>
      {showCount && (
        <CardContent>
          <Typography color="text.secondary">
            {isLoading ? (
              <Skeleton width={80} />
            ) : (
              <FM
                defaultMessage="{count, plural, =0 {No notification integrations} one {# notification integration} other {# notification integrations} } configured"
                values={{ count: count ?? 0 }}
              />
            )}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};
