import { Skeleton, Stack, Typography } from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  AttributeDisplay,
  AttributeDisplayStack,
  FormattedTypography,
  Link,
  NilDisplay,
  ProjectNameDisplayV2,
} from '@endorlabs/ui-common';

import { DetailDrawerSectionStack } from '../../../../components/DetailDrawer';
import { FindingDetailCodeSnippet } from '../../../../components/FindingDetail/FindingDetailCodeSnippet';
import { getPoliciesPath, getProjectPath } from '../../../../routes';
import { FindingsDetailSASTSectionProps } from '../../types';
import { SASTRuleCustomType } from '../FindingsTable';
import { ReferencesStringDisplaySection } from './ReferencesStringDisplaySection';

const genResource = ({
  namespace,
  sastRuleForFinding,
  project,
}: FindingsDetailSASTSectionProps) => {
  const references = sastRuleForFinding?.spec?.rule?.metadata?.references ?? [];
  const resource = {
    project: project && (
      <ProjectNameDisplayV2
        project={project}
        url={getProjectPath({
          tenantName: namespace,
          uuid: project.uuid,
        })}
      />
    ),
    ruleId: (
      <Typography
        component="span"
        color="text.primary"
        sx={{ lineBreak: 'anywhere' }}
      >
        <Link
          to={getPoliciesPath({
            tenantName: namespace,
            section: `sast/edit-sast-rule/${sastRuleForFinding?.uuid}`,
          })}
          target="_blank"
        >
          {sastRuleForFinding?.spec?.rule?.id ?? ''}
        </Link>
      </Typography>
    ),

    references: !_isEmpty(references) ? (
      <ReferencesStringDisplaySection references={references} />
    ) : (
      <NilDisplay variant="text" />
    ),
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'project',
      heading: <FM defaultMessage="Project" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'ruleId',
      heading: <FM defaultMessage="Rule ID" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'references',
      heading: <FM defaultMessage="References" />,
      hideIfNil: true,
    },
  ];
};
export const FindingSASTDetailSection = ({
  sastRuleForFinding,
  isLoading,
  finding,
  namespace,
  project,
}: FindingsDetailSASTSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, sastRuleForFinding, project }),
    [finding, namespace, sastRuleForFinding, project]
  );
  const result: SASTRuleCustomType =
    finding?.spec?.finding_metadata?.custom ?? {};

  return (
    <DetailDrawerSectionStack>
      <AttributeDisplayStack
        attributeRecords={records.slice(0, 2)}
        headingWidth="30%"
        isLoading={isLoading}
        resource={resource}
      />

      {isLoading ? (
        <Skeleton />
      ) : (
        <Stack spacing={4}>
          <AttributeDisplay
            heading={<FM defaultMessage="Description" />}
            value={
              <FindingDetailCodeSnippet
                code={result?.['Code Snippet'] ?? ''}
                fileLocation={result?.Location ?? ''}
              />
            }
          />

        {sastRuleForFinding?.spec?.rule?.metadata?.explanation && (
          <AttributeDisplay
            heading={<FM defaultMessage="Explanation" />}
            value={
              <FormattedTypography
                text={sastRuleForFinding?.spec?.rule?.metadata?.explanation}
              />
            }
          />
        )}

        {/* Remediation - only if exists and not empty */}
        {sastRuleForFinding?.spec?.rule?.metadata?.remediation && (
          <AttributeDisplay
            heading={<FM defaultMessage="Remediation" />}
            value={
              <FormattedTypography
                text={sastRuleForFinding?.spec?.rule?.metadata?.remediation}
              />
            }
          />
        )}

        {(!sastRuleForFinding?.spec?.rule?.metadata?.explanation &&
          !sastRuleForFinding?.spec?.rule?.metadata?.remediation) && (
          <FormattedTypography
            text={sastRuleForFinding?.spec?.rule?.message}
          />
        )}

        </Stack>
      )}


      <AttributeDisplayStack
        attributeRecords={records.slice(2)}
        headingWidth="30%"
        isLoading={isLoading}
        resource={resource}
      />
    </DetailDrawerSectionStack>
  );
};
