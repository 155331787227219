import GitHub from '@mui/icons-material/GitHub';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';

import { getEnv } from '../../../constants';
import { NewProjectEndorCtlCli } from './NewProjectEndorCtlCli';
import { NewProjectGithubAction } from './NewProjectGithubAction';
import { InstallMethod } from './types';

const ENV = getEnv();

interface NewProjectGitHubProps {
  namespace: string;
}

export const NewProjectGitHub = ({ namespace }: NewProjectGitHubProps) => {
  const [activeMethod, setActiveMethod] = useState<InstallMethod>(false);
  const { formatMessage: fm } = useIntl();
  const sx = styles(useTheme());

  const handleMethodChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setActiveMethod(evt.target.value as InstallMethod);
    },
    []
  );

  return (
    <Card>
      <CardHeader
        title={
          <FM defaultMessage="How do you want to scan your repositories?" />
        }
      />
      <CardContent>
        <RadioGroup
          aria-label={fm({
            defaultMessage:
              'Choose manual or automatic scanning of repositories',
          })}
          name="install-method"
          onChange={handleMethodChange}
          value={activeMethod}
        >
          <FormControlLabel
            control={<Radio />}
            value="CLI"
            label={fm({
              defaultMessage: 'CLI',
            })}
          />

          {activeMethod === 'CLI' && (
            <Box sx={sx.contentSection}>
              <NewProjectEndorCtlCli
                tenantName={namespace}
                platformSource={V1PlatformSource.Github}
              />
            </Box>
          )}

          <FormControlLabel
            control={<Radio />}
            value="ACTION"
            label={fm({
              defaultMessage: 'GitHub Action',
            })}
          />
          {activeMethod === 'ACTION' && (
            <Box sx={sx.contentSection}>
              <Typography paragraph variant="body2">
                <FM defaultMessage="Install the GitHub Action on your repository to add it to your CI/CD pipeline." />
              </Typography>
              <Button
                color="primary"
                href={ENV.URL_GITHUB_ACTION}
                startIcon={<GitHub />}
                variant="contained"
              >
                <FM defaultMessage="Install GitHub Action" />
              </Button>
            </Box>
          )}

          <FormControlLabel
            control={<Radio />}
            value="APP"
            label={fm({
              defaultMessage: 'GitHub App',
            })}
          />
          {activeMethod === 'APP' && <NewProjectGithubAction />}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

const styles = ({ palette }: Theme) => ({
  contentSection: {
    borderLeft: `1px solid ${palette.divider}`,
    marginLeft: 2,
    paddingX: 6,
    paddingBottom: 4,
    paddingTop: -2,
  },
});
