import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  SystemConfigServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1SystemConfig,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import { SystemConfigResource, SystemConfigResourceList } from './types';
import { ResourceMutateOptions, ResourceQueryOptions } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface SystemConfigReadParams {
  namespace: string;
  uuid: string;
}
export interface SystemConfigWriteParams {
  namespace: string;
  resource: V1SystemConfig;
}
export interface SystemConfigUpdateParams extends SystemConfigWriteParams {
  mask?: string;
}

type CountSystemConfigOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListSystemConfigOptions = ResourceQueryOptions<SystemConfigResourceList>;
type GetSystemConfigOptions = ResourceQueryOptions<SystemConfigResource>;

type UpsertSystemConfigOptions = ResourceMutateOptions<
  V1SystemConfig,
  SystemConfigWriteParams
>;
type DeleteSystemConfigOptions = ResourceMutateOptions<
  object,
  SystemConfigReadParams
>;

const BASE_KEY = 'v1/system-configs';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const SystemConfigsQueryKeys = QK;

const SYSTEM_CONFIG_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new SystemConfigServiceApi(getClientConfiguration());

const countSystemConfigs = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.systemConfigServiceListSystemConfig(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountSystemConfigs = (
  namespace: string,
  opts: CountSystemConfigOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SystemConfig',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countSystemConfigs(namespace, requestParameters),
    opts
  );
};

const listSystemConfigs = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.systemConfigServiceListSystemConfig(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as SystemConfigResourceList;
};

export const useListSystemConfigs = (
  namespace: string,
  opts: ListSystemConfigOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'SystemConfig',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listSystemConfigs(namespace, requestParameters),
    opts
  );
};

const getSystemConfig = async (namespace: string, systemConfigUuid: string) => {
  const api = getApiService();
  const resp = await api.systemConfigServiceGetSystemConfig(
    namespace,
    systemConfigUuid
  );
  return resp.data as SystemConfigResource;
};

export const useGetSystemConfig = (
  params: SystemConfigReadParams,
  opts: GetSystemConfigOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getSystemConfig(params.namespace, params.uuid),
    opts
  );
};

const createSystemConfig = async (
  namespace: string,
  systemConfigBody: V1SystemConfig
) => {
  const api = getApiService();
  const resp = await api.systemConfigServiceCreateSystemConfig(
    namespace,
    systemConfigBody
  );
  return resp.data as SystemConfigResource;
};

export const useCreateSystemConfig = (opts: UpsertSystemConfigOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'SystemConfig'),
    mutationFn: (params: SystemConfigWriteParams) =>
      createSystemConfig(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateSystemConfig = async (params: SystemConfigUpdateParams) => {
  const { resource, namespace, mask = SYSTEM_CONFIG_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.systemConfigServiceUpdateSystemConfig(namespace, req);
  return resp.data as SystemConfigResource;
};

export const useUpdateSystemConfig = (opts: UpsertSystemConfigOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'SystemConfig'),
    mutationFn: (params: SystemConfigUpdateParams) =>
      updateSystemConfig(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteSystemConfig = async (params: SystemConfigReadParams) => {
  const api = getApiService();
  const resp = await api.systemConfigServiceDeleteSystemConfig(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteSystemConfig = (opts: DeleteSystemConfigOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'SystemConfig'),
    mutationFn: (params: SystemConfigReadParams) => deleteSystemConfig(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(QK.record(vars.namespace, vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
