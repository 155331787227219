import { V1ListParameters } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import {
  buildReadRequestParameters,
  ResourceReadAction,
} from '@endorlabs/endor-core/api';

import { ResourceQueryOptions } from '../types';
import { useUserPreferences } from '../useUserPreferences';

/**
 * Wraps the request parameter builder from `@endorlabs/endor-core`, and allows
 * for application-specific overrides.
 */
export const useBuildReadRequestParameters = (
  kind: ResourceKind,
  action: ResourceReadAction,
  params: Partial<V1ListParameters> = {},
  options?: Pick<ResourceQueryOptions<any>, 'enabled'>
): V1ListParameters => {
  // Build the request parameters
  const requestParameters = buildReadRequestParameters(kind, action, params, {
    skipValidation: options?.enabled === false,
  });

  // Override the request parameters if needed
  const includeChildNamespaces = useUserPreferences(
    (s) => s.auth?.includeChildNamespaces
  );

  if (includeChildNamespaces) {
    return {
      ...requestParameters,
      traverse: requestParameters.traverse ?? true,
    };
  }

  return requestParameters;
};
