import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  ToggleButtonProps,
} from '@mui/material';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type ControlledToggleButtonGroupProps<TFieldValues extends FieldValues> =
  Omit<ControllerProps<TFieldValues>, 'render'> &
    // `control` prop is required for this component, but may be replaced with
    // the use of `FormProvider` in the future.
    Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
    ToggleButtonGroupProps & {
      buttonPropsArray: (Omit<ToggleButtonProps, 'value'> & {
        value: string;
      })[];
      htmlName?: string;
    };

/**
 * Allows react-hook-form to treat a ToggleButtonGroup as a named field.
 * Basically by delegating the onChange event to the controller-provided version.
 */
export const ControlledToggleButtonGroup = <T extends FieldValues>({
  buttonPropsArray,
  control,
  defaultValue,
  exclusive,
  htmlName,
  name,
  rules,
  ...ToggleButtonGroupProps
}: ControlledToggleButtonGroupProps<T>) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <ToggleButtonGroup
          {...ToggleButtonGroupProps}
          {...fieldProps}
          exclusive
          onChange={(evt, newValue) => onChange(newValue)}
          value={value}
        >
          {buttonPropsArray.map((btnProps) => (
            <ToggleButton
              aria-label={btnProps.value}
              key={btnProps.value}
              {...btnProps}
            />
          ))}
        </ToggleButtonGroup>
      )}
    />
  );
};
