import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecFindingLevel,
  V1FindingLogSpecOperation,
  V1Method,
} from '@endorlabs/api_client';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import { useCountFindingLogs } from '@endorlabs/queries';
import {
  FindingLevelLabel,
  IconEdit,
  RowStack,
  useDialog,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { DashboardConfigValue, useDashboardConfigValue } from '../hooks';
import {
  DashboardConfigDialog,
  DashboardConfigDialogState,
} from './DashboardConfigDialog';
import { DashboardStat } from './DashboardStat';
import { FormDashboardConfigValueFieldset } from './FormDashboardConfigValue';

/**
 * Shows the counts of "Unresolved" findings with a create time older than the sla value - 24 hours
 */
export const DashboardVulnerabilitiesSLA = ({
  baseFilter,
  namespace,
}: {
  baseFilter: FilterExpression;
  namespace: string;
}) => {
  const { palette } = useTheme();

  const { checkActivePermission } = useAuthInfo();
  const canEditDashboardConfig = checkActivePermission(
    V1Method.Create,
    'DashboardConfig'
  );

  const dashboardConfigDialog = useDialog<DashboardConfigDialogState>({
    component: DashboardConfigDialog,
  });

  const dashboardConfigDialogHandler = (
    fieldset: FormDashboardConfigValueFieldset
  ) =>
    canEditDashboardConfig
      ? () => {
          const handleSubmit = (partial: Partial<DashboardConfigValue>) => {
            dashboardConfigDialog.update({ isLoading: true });
            updateConfig(
              { ...config, ...partial },
              {
                onSuccess: () => dashboardConfigDialog.closeDialog(),
                onError: () =>
                  dashboardConfigDialog.update({ isLoading: false }),
              }
            );
          };

          dashboardConfigDialog.openDialog({
            config,
            onSubmit: handleSubmit,
            fieldsets: [fieldset],
            isLoading: false,
          });
        }
      : undefined;

  const {
    config,
    update: updateConfig,
    isLoading: isLoadingConfig,
  } = useDashboardConfigValue({
    namespace,
  });

  const getSLAConfigDaysBySeverity = (severity: SpecFindingLevel) => {
    switch (severity) {
      case SpecFindingLevel.Critical:
        return config.slaCritical;
      case SpecFindingLevel.High:
        return config.slaHigh;
      case SpecFindingLevel.Medium:
        return config.slaMedium;
      case SpecFindingLevel.Low:
        return config.slaLow;
      default:
        return 30;
    }
  };

  const getSLAFilter = (severity: SpecFindingLevel) => {
    // Default to 30 days
    const slaConfigDays = getSLAConfigDaysBySeverity(severity) ?? 30;
    const startOffset = -24 * slaConfigDays;
    const endOffset = -24 * Math.max(1, slaConfigDays - 1);

    return filterExpressionBuilders.and([
      `meta.create_time > now(${startOffset}h)`,
      `meta.create_time < now(${endOffset}h)`,
    ]);
  };

  const getFilterExpressionForSeverity = (severity: SpecFindingLevel) =>
    filterExpressionBuilders.and([
      baseFilter,
      `spec.operation==${V1FindingLogSpecOperation.Create}`,
      `spec.level==${severity}`,
      getSLAFilter(severity),
    ]);

  const qCountSLAFindingsCritical = useCountFindingLogs(namespace, {
    filter: getFilterExpressionForSeverity(SpecFindingLevel.Critical),
  });

  const qCountSLAFindingsHigh = useCountFindingLogs(namespace, {
    filter: getFilterExpressionForSeverity(SpecFindingLevel.High),
  });

  const qCountSLAFindingsMedium = useCountFindingLogs(namespace, {
    filter: getFilterExpressionForSeverity(SpecFindingLevel.Medium),
  });

  const qCountSLAFindingsLow = useCountFindingLogs(namespace, {
    filter: getFilterExpressionForSeverity(SpecFindingLevel.Low),
  });

  const SLA_INFO: {
    accentColor: string;
    count?: number;
    days: number;
    fieldSet: FormDashboardConfigValueFieldset;
    isLoading: boolean;
    title: JSX.Element;
  }[] = [
    {
      accentColor: palette.severity.critical,
      count: qCountSLAFindingsCritical.data?.count,
      days: config.slaCritical,
      fieldSet: 'SLA_CRITICAL',
      isLoading: qCountSLAFindingsCritical.isLoading,
      title: <FindingLevelLabel findingLevel={SpecFindingLevel.Critical} />,
    },
    {
      accentColor: palette.severity.high,
      count: qCountSLAFindingsHigh.data?.count,
      days: config.slaHigh,
      fieldSet: 'SLA_HIGH',
      isLoading: qCountSLAFindingsHigh.isLoading,
      title: <FindingLevelLabel findingLevel={SpecFindingLevel.High} />,
    },
    {
      accentColor: palette.severity.medium,
      count: qCountSLAFindingsMedium.data?.count,
      days: config.slaMedium,
      fieldSet: 'SLA_MEDIUM',
      isLoading: qCountSLAFindingsMedium.isLoading,
      title: <FindingLevelLabel findingLevel={SpecFindingLevel.Medium} />,
    },
    {
      accentColor: palette.severity.low,
      count: qCountSLAFindingsLow.data?.count,
      days: config.slaLow,
      fieldSet: 'SLA_LOW',
      isLoading: qCountSLAFindingsLow.isLoading,
      title: <FindingLevelLabel findingLevel={SpecFindingLevel.Low} />,
    },
  ];

  return (
    <Card elevation={0}>
      <dashboardConfigDialog.Dialog {...dashboardConfigDialog.dialogProps} />
      <CardHeader
        title={
          <RowStack>
            <FM defaultMessage="New Open Vulnerabilities Approaching SLA" />

            <Typography variant="caption">
              <FM defaultMessage="Less than 24 Hours till Missed SLA" />
            </Typography>
          </RowStack>
        }
      />
      <CardContent>
        <RowStack gap={4}>
          {SLA_INFO.map((slaInfo) => (
            <DashboardStat
              key={slaInfo.fieldSet}
              accentColor={slaInfo.accentColor}
              action={
                <Chip
                  icon={<IconEdit />}
                  label={
                    <FM
                      defaultMessage="SLA {value} days"
                      values={{
                        value: slaInfo.days,
                      }}
                    />
                  }
                  onClick={dashboardConfigDialogHandler(slaInfo.fieldSet)}
                  size="small"
                  sx={{
                    color: 'text.secondary',
                    paddingLeft: 1,
                    '& .MuiChip-icon': {
                      color: 'text.secondary',
                      fontSize: '1rem',
                    },
                  }}
                  variant="outlined"
                />
              }
              isLoading={slaInfo.isLoading}
              title={slaInfo.title}
              value={slaInfo.count}
              valueColor="secondary"
            />
          ))}
        </RowStack>
      </CardContent>
    </Card>
  );
};
