import { defineMessages, MessageDescriptor } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';

export const EcosystemMessages: Record<V1Ecosystem, MessageDescriptor> =
  defineMessages({
    [V1Ecosystem.Apk]: { defaultMessage: 'APK' },
    [V1Ecosystem.C]: { defaultMessage: 'C/C++' },
    [V1Ecosystem.Cargo]: { defaultMessage: 'Cargo' },
    [V1Ecosystem.Cocoapod]: { defaultMessage: 'CocoaPods ' },
    [V1Ecosystem.Debian]: { defaultMessage: 'Debian' },
    [V1Ecosystem.Gem]: { defaultMessage: 'Gem' },
    [V1Ecosystem.Git]: { defaultMessage: 'Git' },
    [V1Ecosystem.GithubAction]: { defaultMessage: 'GitHub Action' },
    [V1Ecosystem.Go]: { defaultMessage: 'Go' },
    [V1Ecosystem.Maven]: { defaultMessage: 'Maven' },
    [V1Ecosystem.Npm]: { defaultMessage: 'npm' },
    [V1Ecosystem.Nuget]: { defaultMessage: 'Nuget' },
    [V1Ecosystem.Packagist]: { defaultMessage: 'Packagist' },
    [V1Ecosystem.Pypi]: { defaultMessage: 'PyPI' },
    [V1Ecosystem.Rpm]: { defaultMessage: 'RPM' },
    [V1Ecosystem.Sbom]: { defaultMessage: 'SBOM' },
    [V1Ecosystem.Container]: { defaultMessage: 'Container' },
    [V1Ecosystem.HuggingFace]: { defaultMessage: 'Hugging Face' },
    [V1Ecosystem.Unspecified]: { defaultMessage: 'Unspecified' },
  });
