import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'Scan Profiles',
});

export const Route = createFileRoute('/t/:namespace/settings/scan-profiles')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
