import { RowSelectionState } from '@tanstack/react-table';
import { ReactNode, useLayoutEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonCancel,
  CommonDataTableRowActions,
  DataTablePaginator,
  DataTableView,
} from '@endorlabs/ui-common';

import { FilterBar, FilterFieldConfig } from '../../../domains/filters';
import { usePackageVersionDetailDrawer } from '../../../domains/PackageVersion';
import {
  buildPackageVersionsTableColumns,
  PackageVersionsTableRow,
} from './buildPackageVersionsTableViewColumns';

export interface PackageVersionsTableViewProps {
  data: PackageVersionsTableRow[];
  isLoading: boolean;
  namespace: string;
  paginator: DataTablePaginator;
  onClickResolutionErrors?: (row?: PackageVersionsTableRow) => void;
  handleRowSelection: (state: RowSelectionState) => void;
  clearFilter: () => void;
  packageSourcePlural: string;
  countMessage: ReactNode;
  filterFields: FilterFieldConfig[];
}

export const PackageVersionsTableView = ({
  data,
  isLoading,
  namespace,
  paginator,
  onClickResolutionErrors,
  handleRowSelection,
  packageSourcePlural,
  clearFilter,
  countMessage,
  filterFields,
}: PackageVersionsTableViewProps) => {
  const { DetailDrawer, permalinkEffect } = usePackageVersionDetailDrawer();

  useLayoutEffect(
    () =>
      permalinkEffect({
        packages: data,
      }),
    [permalinkEffect, data]
  );

  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      onClick: (row: PackageVersionsTableRow) => {
        DetailDrawer.activate(
          {
            packageNamespace: row.namespace,
            packageUuid: row.uuid,
          },
          {
            namespace: namespace,
            uuid: row.uuid,
          }
        );
      },
    },
  ];
  return (
    <DataTableView
      namespace={namespace}
      data={data}
      tableId="package-versions"
      columns={buildPackageVersionsTableColumns({ onClickResolutionErrors })}
      isLoading={isLoading}
      rowActions={rowActions}
      paginator={paginator}
      onRowSelectionChange={handleRowSelection}
      enablePagination
      enableRowSelection
      countMessage={countMessage}
      filtersContent={<FilterBar fields={filterFields} isCard={false} />}
      emptyStateProps={{
        title: (
          <FM
            defaultMessage="No {packageSourcePlural} match the filter criteria"
            values={{ packageSourcePlural }}
          />
        ),
        children: (
          <ButtonCancel onClick={clearFilter}>
            <FM defaultMessage="Clear Filters" />
          </ButtonCancel>
        ),
      }}
    />
  );
};
