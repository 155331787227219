import { FallbackProps as ErrorBoundaryFallbackProps } from 'react-error-boundary';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonPrimary, EmptyState } from '@endorlabs/ui-common';

import { InfoDrawerContent } from './InfoDrawerContent';
import { useInfoDrawer } from './useInfoDrawer';

export const InfoDrawerFallback = ({
  error,
  resetErrorBoundary,
}: ErrorBoundaryFallbackProps) => {
  const { close } = useInfoDrawer();

  return (
    <InfoDrawerContent>
      <EmptyState
        title={<FM defaultMessage="We've Encountered a Problem" />}
        description={
          <FM defaultMessage="Unable to display the content for this drawer." />
        }
      >
        <ButtonPrimary onClick={close}>
          <FM defaultMessage="Close Drawer" />
        </ButtonPrimary>
      </EmptyState>
    </InfoDrawerContent>
  );
};
