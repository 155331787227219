import _noop from 'lodash-es/noop';
import { ChangeEvent, useRef, useState } from 'react';

export type FileContent = ArrayBuffer | string | null;

export enum FileUploadStatus {
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

interface useFileUploadProps {
  contentFormat?: 'buffer' | 'binary' | 'text';
  inputName?: string;
  acceptedTypes?: string[];
  onError?: (error: ProgressEvent) => void;
  onLoad?: (content: FileContent) => void;
}

interface FileUploadInputProps {
  accept: string;
  name: string;
  onChange: (input: ChangeEvent<HTMLInputElement>) => void;
}

interface FileUploadDragDropProps {
  isDragging?: boolean;
  handleDragOver: (e: React.DragEvent) => void;
  handleDragLeave: () => void;
  handleDrop: (e: React.DragEvent) => void;
}

export interface FileUploadProps {
  content: FileContent;
  dragAndDropProps: FileUploadDragDropProps;
  fileData: File | null;
  inputProps: FileUploadInputProps;
  readError: string | undefined;
  readStatus: number; // FileReader.readyState (0 | 1 | 2)
}

export const useFileUpload = ({
  contentFormat = 'text',
  inputName = 'fileUpload',
  acceptedTypes = [],
  onError = _noop,
  onLoad = _noop,
}: useFileUploadProps): FileUploadProps => {
  const fileContent = useRef<FileContent>(null);
  const [fileData, setFileData] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [readError, setReadError] = useState<string>();
  const reader = new FileReader();

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (acceptedTypes.length > 0 && acceptedTypes.includes(file.type)) {
      readFileContent(file);
    } else {
      setReadError('Only json and xml files are supported.');
    }
  };

  reader.onload = async () => {
    fileContent.current = reader.result;

    onLoad(fileContent.current);
  };

  reader.onerror = async (err) => {
    setReadError('An error occured while reading the file from device');
    onError(err);
  };

  const readFileContent = (file: File | null) => {
    setFileData(file);
    setReadError(undefined);
    if (file) {
      if (contentFormat === 'buffer') {
        reader.readAsArrayBuffer(file);
      } else if (contentFormat === 'binary') {
        reader.readAsBinaryString(file);
      } else if (contentFormat === 'text') {
        reader.readAsText(file);
      }
    }
  };

  const inputProps = {
    accept: acceptedTypes.join(','),
    name: inputName,
    onChange: (input: ChangeEvent<HTMLInputElement>) => {
      const file = (input.target.files ?? new FileList()).item(0);
      readFileContent(file);
    },
  };

  const dragAndDropProps = {
    isDragging,
    handleDragOver,
    handleDragLeave,
    handleDrop,
  };

  return {
    content: fileContent.current,
    dragAndDropProps,
    fileData,
    inputProps,
    readError,
    readStatus: reader.readyState,
  };
};
