import { ElementType, ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { AddProjectChildren } from '../../views/Onboarding/AddProjectComponent';
import { InviteTeamChildren } from '../../views/Onboarding/InviteTeamComponent';
import { getOnboardingLink } from '../../views/Onboarding/onboardingLinks';
import { OnboardingScanProjectGitHubApp } from '../../views/Onboarding/OnboardingScanProjectGitHubApp';
import { ViewScanResultsChildren } from '../../views/Onboarding/ViewScanResultsComponent';
import { NewProjectGithubAction } from '../../views/Projects/NewProject';
import { NewProjectAzureApp } from '../../views/Projects/NewProject/NewProjectAzureApp';

export const OnboardingApproaches = {
  CLI: 'CLI',
  GITHUB_ACTION: 'GITHUB_ACTION',
  GITHUB_APP: 'GITHUB_APP',
  AZURE_APP: 'AZURE_APP',
} as const;

export type OnboardingApproach =
  (typeof OnboardingApproaches)[keyof typeof OnboardingApproaches];

export enum OnboardingStepIds {
  ONBOARD_ADD_PROJECT = 'ONBOARD_ADD_PROJECT',
  ONBOARD_VIEW_FINDINGS = 'ONBOARD_VIEW_FINDINGS',
  ONBOARD_INVITE_TEAM = 'ONBOARD_INVITE_TEAM',
}

export type OnboardingStepId =
  (typeof OnboardingStepIds)[keyof typeof OnboardingStepIds];

/**
 * Some onboarding steps accomplish the same goals regardless of approach. E.g. "Invite team members"
 * In this case, `approaches` is undefined.
 * Others can be revisited using different approaches.
 * These can be interpreted as incomplete when viewing a different approach,
 * but count for overall onboarding completion as long as one approach is complete.
 */

export interface OnboardingStep {
  approaches?: OnboardingApproach[];
  ContentComponent: ElementType | Record<OnboardingApproach, ElementType>;
  completionTimeInMinutes: number | Record<OnboardingApproach, number>;
  id: OnboardingStepId;
  prerequisiteSteps?: OnboardingStepId[];
}

/**
 * NOTE: Order determines step order
 */
export const OnboardingSteps: OnboardingStep[] = [
  {
    approaches: [
      OnboardingApproaches.CLI,
      OnboardingApproaches.GITHUB_ACTION,
      OnboardingApproaches.GITHUB_APP,
      OnboardingApproaches.AZURE_APP,
    ],
    completionTimeInMinutes: {
      [OnboardingApproaches.CLI]: 11,
      [OnboardingApproaches.GITHUB_ACTION]: 5,
      [OnboardingApproaches.GITHUB_APP]: 7,
      [OnboardingApproaches.AZURE_APP]: 3,
    },
    ContentComponent: {
      [OnboardingApproaches.CLI]: AddProjectChildren,
      [OnboardingApproaches.GITHUB_ACTION]: OnboardingScanProjectGitHubApp,
      [OnboardingApproaches.GITHUB_APP]: NewProjectGithubAction,
      [OnboardingApproaches.AZURE_APP]: NewProjectAzureApp,
    },
    id: OnboardingStepIds.ONBOARD_ADD_PROJECT,
  },
  {
    approaches: undefined,
    completionTimeInMinutes: 5,
    ContentComponent: ViewScanResultsChildren,
    id: OnboardingStepIds.ONBOARD_VIEW_FINDINGS,
    prerequisiteSteps: [OnboardingStepIds.ONBOARD_ADD_PROJECT],
  },
  {
    approaches: undefined,
    completionTimeInMinutes: 3,
    ContentComponent: InviteTeamChildren,
    id: OnboardingStepIds.ONBOARD_INVITE_TEAM,
  },
];

export const ResourceLinks: { text: ReactNode; url: string }[] = [
  {
    text: <FM defaultMessage="Documentation" />,
    url: getOnboardingLink('onboard-documentation'),
  },
  {
    text: <FM defaultMessage="Blog" />,
    url: getOnboardingLink('onboard-blog'),
  },
  {
    text: <FM defaultMessage="YouTube" />,
    url: getOnboardingLink('onboard-youtube'),
  },
];
