import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1ChangeType } from '@endorlabs/api_client';
import {
  HelpIconButton,
  InlineCodeDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { BreakingChangeTypeIconMap } from '../../constants';
import {
  BreakingChangeModifierMessage,
  BreakingChangeTypeMessage,
} from '../../locales/BreakingChangeMessage';
import { BreakingChangeConfidenceDisplay } from '../BreakingChangeConfidenceDisplay';
import { BreakingChangeData } from './BreakingChangeDisplay';

export const BreakingChangeDisplayTitle = ({
  breakingChanges,
}: {
  breakingChanges?: BreakingChangeData;
}) => {
  const { space } = useTheme();

  if (!breakingChanges) {
    return null;
  }

  const Icon = breakingChanges.diffChange
    ? BreakingChangeTypeIconMap[breakingChanges.diffChange]
    : BreakingChangeTypeIconMap[V1ChangeType.Unspecified];
  return (
    <Stack spacing={space.xs}>
      <RowStack>
        <Icon />
        {breakingChanges.diffChange && (
          <RowStack gap={1}>
            <Typography variant="subtitle2">
              <FM {...BreakingChangeTypeMessage[breakingChanges.diffChange]} />
            </Typography>
            {breakingChanges.diffChange === V1ChangeType.Changed &&
              breakingChanges.modifierChange && (
                <HelpIconButton
                  tooltip={
                    <FM
                      {...BreakingChangeModifierMessage[
                        breakingChanges.modifierChange
                      ]}
                    />
                  }
                />
              )}
          </RowStack>
        )}
        {breakingChanges.confidence && (
          <BreakingChangeConfidenceDisplay
            confidence={breakingChanges.confidence}
            showIcon={true}
            showLabel={true}
            size="small"
          />
        )}
      </RowStack>
      <RowStack>
        {breakingChanges.className && (
          <InlineCodeDisplay>{[breakingChanges.className]}</InlineCodeDisplay>
        )}
        {breakingChanges.functionName && (
          <InlineCodeDisplay display="color">
            {[`${breakingChanges.functionName}()`]}
          </InlineCodeDisplay>
        )}
      </RowStack>
    </Stack>
  );
};
