import {
  isArray as _isArray,
  isNil as _isNil,
  isObject as _isObject,
  mergeWith as _mergeWith,
} from 'lodash-es';

/**
 * Return the given default value when `undefined` or `null`
 */
const defaultNil = (a: object, b: object) => (_isNil(a) ? b : a);

/**
 * Similar to {@see lodash#defaultsDeep}, but sets default values in an object
 * when the value is either `undefined` or `null`
 *
 * Reference: {@link https://stackoverflow.com/questions/50692777/versions-of-defaultdeep-and-defaults-that-override-undefined-and-null-values}
 *
 * @example
 *
 * defaultNilsDeep({ 'foo': null }, { foo: 'foo', bar: 'bar' });
 * // => { 'foo': 'foo', 'bar': 'bar' }
 *
 * // The same objects with lodash defaultsDeep will return
 * _.defaultsDeep({ 'foo': null }, { foo: 'foo', bar: 'bar' });
 * // => { 'foo': null, 'bar': 'bar' }
 */
export const defaultNilsDeep = (a: object, b: object): object =>
  _isObject(a) && !_isArray(a)
    ? _mergeWith({}, a, b, defaultNilsDeep)
    : defaultNil(a, b);
