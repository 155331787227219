import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type PlanetProps = {
  color?: string;
  size?: string | number;
};

const Planet = forwardRef<SVGSVGElement, PlanetProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M2.91988 14.052C1.35333 17.1462 1.04635 19.9881 2.45694 21.4416C4.20817 23.1928 10.5571 21.1263 15.9076 15.9338C21.258 10.7414 23.7606 4.56607 21.8326 2.6381C20.4176 1.18002 17.5621 1.40849 14.4102 2.89432M2.91988 14.052C3.79582 18.4029 7.6395 21.6796 12.2485 21.6796C17.504 21.6796 21.7644 17.4192 21.7644 12.1637C21.7644 7.65173 18.6243 3.87321 14.4102 2.89432M2.91988 14.052C2.797 13.4417 2.73254 12.8102 2.73254 12.1637C2.73254 6.90816 6.99297 2.64774 12.2485 2.64774C12.992 2.64774 13.7157 2.733 14.4102 2.89432"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    );
  }
);

Planet.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Planet.displayName = 'Planet';

export default Planet;
