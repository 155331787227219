import {
  V1CountResponse,
  V1ListParameters,
  VersionUpgradeServiceApi,
} from '@endorlabs/api_client';
import { VersionUpgradeResource } from '@endorlabs/endor-core/VersionUpgrade';

import { createResourceReadHooks, ReadServiceRequestOptions } from './hooks';
import { ResourceGroupResponse, ResourceListResponse } from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

export const listVersionUpgrade = async (
  namespace: string,
  params: V1ListParameters,
  options: ReadServiceRequestOptions
) => {
  const api = new VersionUpgradeServiceApi(getClientConfiguration());
  const args = buildListParamArgs(params);
  const resp = await api.versionUpgradeServiceListVersionUpgrades(
    namespace,
    ...args,
    options
  );
  return resp.data.list as ResourceListResponse<VersionUpgradeResource>;
};

const queries = createResourceReadHooks('VersionUpgrade', () => {
  const getApiService = () =>
    new VersionUpgradeServiceApi(getClientConfiguration());

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp =
        await getApiService().versionUpgradeServiceListVersionUpgrades(
          namespace,
          ...args,
          options
        );
      return resp.data.count_response as Required<V1CountResponse>;
    },
    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp = await getApiService().versionUpgradeServiceGetVersionUpgrade(
        namespace,
        ...args,
        options
      );
      return resp.data as VersionUpgradeResource;
    },
    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().versionUpgradeServiceListVersionUpgrades(
          namespace,
          ...args,
          options
        );
      return resp.data.group_response as ResourceGroupResponse;
    },
    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().versionUpgradeServiceListVersionUpgrades(
          namespace,
          ...args,
          options
        );
      return resp.data.list as ResourceListResponse<VersionUpgradeResource>;
    },
  };
});

// Expose query hooks for VersionUpgrade
export const useCountVersionUpgrade = queries.useCount;
export const useGetVersionUpgrade = queries.useGet;
export const useGroupVersionUpgrade = queries.useGroup;
export const useListVersionUpgrade = queries.useList;
export const useListAllVersionUpgrade = queries.useListAll;
