import { NAMESPACES } from '@endorlabs/endor-core/Namespace';

export enum TourVariableKeys {
  TENANT = 'tenant',
  PROJECT = 'project',
  CALLPATH = 'callpath',
  SECRETS = 'secrets',
}

const Staging = [
  {
    key: TourVariableKeys.TENANT,
    name: NAMESPACES.DEMO_TRIAL,
  },
  {
    key: TourVariableKeys.PROJECT,
    name: 'https://github.com/endorlabs/app-java-demo.git',
  },
  {
    key: TourVariableKeys.CALLPATH,
    name: 'GHSA-599f-7c49-w659: Arbitrary code execution in Apache Commons Text',
  },
  {
    key: TourVariableKeys.SECRETS,
    name: 'https://github.com/OWASP/NodeGoat.git',
  },
];

const Production = [
  {
    key: TourVariableKeys.TENANT,
    name: NAMESPACES.DEMO_TRIAL,
  },
  {
    key: TourVariableKeys.PROJECT,
    name: 'https://github.com/endorlabs/app-java-demo.git',
  },
  {
    key: TourVariableKeys.CALLPATH,
    name: 'GHSA-599f-7c49-w659: Arbitrary code execution in Apache Commons Text',
  },
  {
    key: TourVariableKeys.SECRETS,
    name: 'https://github.com/OWASP/NodeGoat.git',
  },
];

export const getTourVariables = (key: string) => {
  if (process.env.NODE_ENV === 'production') {
    return Production.find((d) => d.key === key)?.name;
  } else {
    return Staging.find((d) => d.key === key)?.name;
  }
  //Add fallback here for localhost
};

export const TourLoadIntervals = {
  small: 1000,
  medium: 2000,
  high: 3000,
  extraHigh: 4000,
};
