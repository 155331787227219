import { useCallback, useState } from 'react';

import {
  NotificationTargetActionActionType,
  V1NotificationTarget,
} from '@endorlabs/api_client';
import {
  IdentityProviderResource,
  NotificationTargetResource,
  ResourceMutateOptions,
  useCreateNotificationTarget,
  useUpdateNotificationTarget,
} from '@endorlabs/queries';
import { getObjectKeys } from '@endorlabs/ui-common';

import {
  NotificationTargetUpsertDialog,
  NotificationTargetUpsertDialogProps,
} from '../components';
import { FormNotificationDirtyValues } from '../components/FormUpsertNotificationTarget/types';

/**
 * Utility hook to Manage Notification Target Upsert Dialog
 */
export const useNotificationTargetUpsertDialog = (
  options: Pick<ResourceMutateOptions<unknown, unknown>, 'onSuccess'>
) => {
  const [dialogState, setDialogState] = useState<
    | {
        namespace: string;
        actionType: NotificationTargetActionActionType;
        notificationTarget?: NotificationTargetResource;
      }
    | undefined
  >(undefined);
  const isOpen = Boolean(dialogState);

  const handleOpenDialog = useCallback(
    (props: {
      namespace: string;
      notificationTarget?: NotificationTargetResource;
      actionType: NotificationTargetActionActionType;
      identityProvider?: IdentityProviderResource;
    }) => {
      setDialogState(props);
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setDialogState(undefined);
  }, []);

  const qCreateAuthPolicy = useCreateNotificationTarget({
    ...options,
    onError: () => {
      // Errors are handled inline with the form
    },
  });
  const qUpdateAuthPolicy = useUpdateNotificationTarget({
    ...options,
    onError: () => {
      // Errors are handled inline with the form
    },
  });

  // error response from server is exposed in the form dialog
  const serverErrorResponse =
    qCreateAuthPolicy.error?.response ?? qUpdateAuthPolicy.error?.response;

  const handleSubmit = useCallback(
    (model: V1NotificationTarget, dirtyFields: FormNotificationDirtyValues) => {
      // TODO: handle possible edge case?
      if (!dialogState) return;

      const { namespace, notificationTarget } = dialogState;

      /* If form has array of fields the dirtyFields is entire object with key as the field name
       *  and value as true for fields which are edited. Below functions sends the edited fields
       *  as array of field names. */
      const updateMaskFields: string[] = getObjectKeys(dirtyFields, true);

      // if there is an existing auth policy to be updated
      if (notificationTarget) {
        qUpdateAuthPolicy.mutate(
          {
            namespace,
            resource: model,
            mask: updateMaskFields.join(','),
          },
          { onSuccess: () => handleCloseDialog() }
        );
      } else {
        qCreateAuthPolicy.mutate(
          { namespace, resource: model },
          { onSuccess: () => handleCloseDialog() }
        );
      }
    },
    [dialogState, handleCloseDialog, qCreateAuthPolicy, qUpdateAuthPolicy]
  );

  const getNotificationTargetUpsertDialogProps = useCallback<
    () => NotificationTargetUpsertDialogProps
  >(
    () => ({
      isLoading: qCreateAuthPolicy.isLoading || qUpdateAuthPolicy.isLoading,
      open: isOpen,
      onClose: handleCloseDialog,
      onSubmit: handleSubmit,
      notificationTarget: dialogState?.notificationTarget,
      notificationTargetActionType:
        dialogState?.actionType ??
        NotificationTargetActionActionType.Unspecified,
      notificationTargetNamespace: dialogState?.namespace ?? '',
      serverErrorResponse,
    }),
    [
      qCreateAuthPolicy.isLoading,
      qUpdateAuthPolicy.isLoading,
      isOpen,
      handleCloseDialog,
      handleSubmit,
      dialogState?.notificationTarget,
      dialogState?.actionType,
      dialogState?.namespace,
      serverErrorResponse,
    ]
  );

  return {
    Dialog: NotificationTargetUpsertDialog,
    openNotificationTargetUpsertDialog: handleOpenDialog,
    getNotificationTargetUpsertDialogProps,
  };
};
