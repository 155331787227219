import { Typography, useTheme } from '@mui/material';
import { isEmpty as _isEmpty, noop as _noop } from 'lodash-es';
import { ReactNode, SyntheticEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { RowStack, SimpleMenu } from '@endorlabs/ui-common';

import { TitleActionHeader } from '../../domains/layout/components/TitleActionHeader';

export interface BulkActionRecord<RowType> {
  actionId: string;
  disabled?: boolean;
  // Does the action operate on individual rows?
  isSelectionRequired?: boolean;
  label: ReactNode;
  // Callback when action is selected
  onApply: (rows: RowType[]) => void;
}

interface DataTableViewActionsProps<RowType> {
  actionDisplay?: ReactNode;
  bulkActions?: BulkActionRecord<RowType>[];
  countMessage?: ReactNode;
  onActionSelect?: (
    event: SyntheticEvent,
    action: BulkActionRecord<RowType>
  ) => void;
  titleContent?: ReactNode;
}

export const DataTableViewActions = <RowType,>({
  actionDisplay,
  bulkActions = [],
  countMessage,
  onActionSelect = _noop,
  titleContent,
}: DataTableViewActionsProps<RowType>) => {
  const { space } = useTheme();

  const handleActionClick = (event: SyntheticEvent, item: { key: string }) => {
    const action = bulkActions.find((b) => b.actionId === item.key);
    if (action) {
      onActionSelect(event, action);
    }
  };

  const bulkActionControl = (
    <SimpleMenu
      id="bulk-action-menu"
      onClick={handleActionClick}
      options={bulkActions.map((a) => ({
        disabled: a.disabled,
        key: a.actionId,
        label: a.label,
      }))}
      position="bottom-left"
      triggerTitle={<FM defaultMessage="Actions" />}
      triggerVariant="button"
    />
  );

  const titleDisplay = (
    <RowStack gap={space.sm}>
      <Typography color="text.secondary" component="div" variant="body2">
        {countMessage}
      </Typography>

      {titleContent}
    </RowStack>
  );

  const actionContent = (
    <RowStack gap={space.sm}>
      {actionDisplay}
      {!_isEmpty(bulkActions) && bulkActionControl}
    </RowStack>
  );

  return <TitleActionHeader action={actionContent} title={titleDisplay} />;
};
