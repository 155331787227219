import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  ProjectResource,
  QueryProjectsResponseObject,
} from '@endorlabs/queries';

import { getProjectPath } from '../../routes';
import { ProjectsTableRow } from './utils/buildProjectsTableColumns';

export const mapToProjectTableRows = (
  projects?: QueryProjectsResponseObject[] | ProjectResource[],
  override?: (row: ProjectsTableRow) => ProjectsTableRow
): ProjectsTableRow[] => {
  if (!projects?.length) {
    return [];
  }

  return projects.map((project) => {
    const { DefaultRepositoryVersion, LatestScanResult } =
      project.meta.references ?? {};

    const projectLink = getProjectPath({
      tenantName: project.tenant_meta.namespace,
      uuid: project.uuid,
    });

    const defaultRepositoryVersion = DefaultRepositoryVersion?.list?.objects[0];

    // Take the latest "all" scan result
    const scanResults = (LatestScanResult?.list.objects.slice(0, 1) ??
      []) as ScanResultResource[];

    const sortedTags = project?.meta?.tags?.sort((a, b) => a.localeCompare(b));

    const row: ProjectsTableRow = {
      uuid: project.uuid,
      name: project.meta.name,
      namespace: project.tenant_meta.namespace,
      link: projectLink,
      platformSource: project.spec.platform_source,
      project,
      defaultRepositoryVersion,
      scanResults,
      tags: sortedTags,
    };

    if (override) {
      return override(row);
    }

    return row;
  });
};
