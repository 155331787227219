import { Box, useTheme } from '@mui/material';
import { ParentSize } from '@visx/responsive';

import { V1GroupResponse } from '@endorlabs/api_client';
import {
  BarChartHorizontal,
  Color,
  PackageVersionDependency,
} from '@endorlabs/ui-common';

import { useProjectVersionLicenseChartData } from './useProjectVersionLicenseChartData';

export interface ProjectVersionLicenseProps {
  dependencies?: PackageVersionDependency[];
  packageMetadataGroupResponse?: V1GroupResponse;
  height: number;
}

export const ProjectVersionLicense = ({
  packageMetadataGroupResponse,
  dependencies,
  height,
}: ProjectVersionLicenseProps) => {
  const theme = useTheme();

  const chartData = useProjectVersionLicenseChartData(
    packageMetadataGroupResponse,
    dependencies
  );

  return (
    <Box position="relative">
      <ParentSize debounceTime={100}>
        {({ width: visWidth }) => (
          <BarChartHorizontal
            width={visWidth}
            height={height}
            data={chartData}
            xKey="count"
            yKey="name"
            axisLeft={false}
            axisBottom={false}
            barPadding={0.4}
            showBarTitles={true}
            barTitleFontSize={10}
            showGrid={false}
            barColors={[theme.palette.brand.main] as Color[]}
            labelColor={theme.palette.text.primary as Color}
            margin={{ top: 4, left: 0, bottom: 4, right: 32 }}
          />
        )}
      </ParentSize>
    </Box>
  );
};
