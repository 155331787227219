import { CargoVersionReqOp, V1Ecosystem } from '@endorlabs/api_client';

const CARGO_OP_SPECIFIER: Partial<Record<CargoVersionReqOp, string>> = {
  [CargoVersionReqOp.Exact]: '=',
  [CargoVersionReqOp.Greater]: '>',
  [CargoVersionReqOp.Greatereq]: '>=',
  [CargoVersionReqOp.Less]: '<',
  [CargoVersionReqOp.Lesseq]: '<=',
  [CargoVersionReqOp.Tilde]: '~',
  [CargoVersionReqOp.Caret]: '^',
  [CargoVersionReqOp.Wildcard]: '*',
};

/**
 * Normalize versions for Cargo Packages.
 *
 * Handle serialize version requirements like:
 * - `op:OP_CARET  major:{}  minor:{value:9}  patch:{}  pre:{}`
 *
 * @link https://doc.rust-lang.org/cargo/reference/specifying-dependencies.html#specifying-dependencies
 */
const getNormalizedCargoVersionRefs = (versionRefs: string[]): string[] => {
  const normalized = [];

  for (const ref of versionRefs) {
    if (!ref.startsWith('op:OP_')) {
      normalized.push(ref);
      continue;
    }

    const parts = ref.split(/\s+/);
    const req: Record<string, string> = {
      major: '',
      minor: '',
      op: '',
      patch: '',
      pre: '',
    };

    for (const p of parts) {
      const prefixIx = p.indexOf(':');
      const prefix = p.slice(0, prefixIx);
      // unwrap the value
      const value = p
        .slice(prefixIx + 1)
        .replace(/^{(.*)}$/, '$1')
        .replace(/^value:(.*)$/, '$1')
        .replace(/^"(.*)"$/, '$1');

      if (prefix in req) {
        // set the value, or default
        req[prefix] = value || '0';
      }
    }

    // Get base semver
    let versionRef = [req.major, req.minor, req.patch]
      .filter(Boolean)
      .join('.');

    // Get known specifier for requirement
    const specifier = req.op && Reflect.get(CARGO_OP_SPECIFIER, req.op);
    if (specifier === '*') {
      if (versionRef) versionRef = versionRef + '.';
      versionRef = versionRef + specifier;
    } else if (specifier) {
      versionRef = specifier + versionRef;
    }

    // If pre-release is fond, append
    if (req.pre && req.pre !== '0') {
      versionRef = versionRef + '-' + req.pre;
    }

    normalized.push(versionRef);
  }

  return normalized;
};

/**
 * Helper to normalize version references for Packages
 */
export const getNormalizedVersionRefs = (
  ecosystem: V1Ecosystem,
  versionRefs: string[]
): string[] => {
  if (ecosystem === V1Ecosystem.Cargo) {
    return getNormalizedCargoVersionRefs(versionRefs);
  }

  // no-op for other ecosystems
  return versionRefs;
};
