import { Stack, Typography, useTheme } from '@mui/material';
import { isPast } from 'date-fns';
import { FormattedDate, FormattedMessage as FM } from 'react-intl';

import { RelativeTimeDisplay } from '@endorlabs/ui-common';

export const ExpirationTimeDisplay = ({
  enableColor,
  value,
}: {
  enableColor?: boolean;
  value?: string | Date;
}) => {
  const { palette } = useTheme();

  if (!value) {
    return (
      <Typography component="span" color="text.secondary">
        <FM defaultMessage="No Expiration" />
      </Typography>
    );
  }

  const date = new Date(value);
  const isElapsed = isPast(date);
  const timeTextColor =
    enableColor && isElapsed ? palette.error.main : palette.text.primary;
  const dateTextColor =
    enableColor && isElapsed ? palette.error.main : palette.text.secondary;

  return (
    <Stack direction="row" spacing={1} whiteSpace="nowrap">
      <Typography component="span" color={timeTextColor}>
        <RelativeTimeDisplay value={date} />
      </Typography>
      <Typography component="span" color="text.secondary">
        &middot;
      </Typography>
      <Typography component="span" color={dateTextColor}>
        <FormattedDate value={date} dateStyle="medium" />
      </Typography>
    </Stack>
  );
};
