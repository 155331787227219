import {
  Checkbox,
  Divider,
  FormControlLabel,
  Input,
  InputAdornment,
  Radio,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { addDays, addHours, isWithinInterval } from 'date-fns';
import { uniq as _uniq } from 'lodash-es';
import { matchSorter } from 'match-sorter';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  PolicyExceptionReason,
  PolicyPolicyType,
  V1FindingTags,
} from '@endorlabs/api_client';
import { Filter } from '@endorlabs/filters';
import { PolicyResource, useListAllPolicies } from '@endorlabs/queries';
import { IconSearch, RelativeTimeDisplay } from '@endorlabs/ui-common';

import { StaleTimes } from '../../../constants';
import { FilterDropdown } from '../../../domains/filters/components/FilterDropdown';
import { ExceptionPolicyReasonLabels } from '../../../domains/Policies/locales';
import { useAuthInfo } from '../../../providers';

export interface FindingExceptionsFilterFieldProps {
  filter?: Filter;
  onChange: (value: string[] | undefined) => void;
  value: string[] | number[] | undefined;
}

/**
 * Facet Filter control for matching against exception policies.
 */
export const FindingExceptionsFilterField = ({
  onChange,
  value: filterValue,
}: FindingExceptionsFilterFieldProps) => {
  const { activeNamespace } = useAuthInfo();
  const { formatMessage: fm } = useIntl();
  const { space, spacing } = useTheme();

  const qExceptionPolicies = useListAllPolicies(
    activeNamespace,
    { staleTime: StaleTimes.LONG },
    {
      filter: `spec.policy_type==${PolicyPolicyType.Exception}`,
      mask: 'meta.name,uuid,spec.exception,spec.policy_type',
    }
  );

  const exceptionPolicies = useMemo(() => {
    return qExceptionPolicies.data ?? [];
  }, [qExceptionPolicies.data]);

  /**
   * Update filter application
   */
  const [hideExceptions, setHideExceptions] = useState<boolean>(
    filterValue === undefined ? false : true
  );

  const updateHideExceptions = useCallback(
    (willHide: boolean) => {
      // If specifically enabling "Hide Exceptions",
      // reset all other exception-based filters so they don't still apply
      if (willHide) {
        setSearchedPolicyName('');
        setNameSelectedPolicyUuids([]);
        setReasons([]);
        setExpirationDate(undefined);
      }

      setHideExceptions(willHide);
    },
    [setHideExceptions]
  );

  /**
   * Name-based filter logic
   */
  const [searchedPolicyName, setSearchedPolicyName] = useState<string>('');
  /**
   * Detect if filter value is based on finding tag ("Hide exceptions" filter).
   * If so, policy UUIDs should be empty
   */
  const [nameSelectedPolicyUuids, setNameSelectedPolicyUuids] = useState<
    string[]
  >(
    filterValue === undefined ||
      (filterValue.length === 1 && filterValue[0] === V1FindingTags.Exception)
      ? []
      : ((filterValue ?? []) as string[])
  );

  const updateNameSelection = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, bool: boolean) => {
      const newUuids = bool
        ? _uniq(
            nameSelectedPolicyUuids.concat([
              evt.target.value as PolicyExceptionReason,
            ])
          )
        : _uniq(nameSelectedPolicyUuids.filter((r) => r !== evt.target.value));
      setNameSelectedPolicyUuids(newUuids);
    },
    [nameSelectedPolicyUuids]
  );

  const policiesMatchingSearch = useMemo(() => {
    return matchSorter(exceptionPolicies, searchedPolicyName, {
      keys: ['meta.name'],
    });
  }, [exceptionPolicies, searchedPolicyName]);

  /**
   * Reason-based filter logic
   */
  const [reasons, setReasons] = useState<PolicyExceptionReason[]>([]);

  const updateReasons = useCallback(
    (evt: ChangeEvent<HTMLInputElement>, bool: boolean) => {
      const newReasons = bool
        ? _uniq(reasons.concat([evt.target.value as PolicyExceptionReason]))
        : _uniq(reasons.filter((r) => r !== evt.target.value));
      setReasons(newReasons);
    },
    [reasons]
  );

  /**
   * Expiration-based filter logic
   */
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(
    undefined
  );

  const currentTime = useMemo(() => {
    return new Date();
  }, []);

  const expirationValues = useMemo(() => {
    const now = currentTime;
    const timestamps = {
      '24_HOURS': addHours(now, 24),
      '7_DAYS': addDays(now, 7),
      '14_DAYS': addDays(now, 14),
      '30_DAYS': addDays(now, 30),
    };

    const values: { label: ReactNode; value: string | undefined }[] = [
      {
        label: <FM defaultMessage="Any" />,
        value: undefined,
      },
    ];

    return values.concat(
      Object.values(timestamps).map((ts) => ({
        label: <RelativeTimeDisplay key={ts.toISOString()} value={ts} />,
        value: ts.toISOString(),
      }))
    );
  }, [currentTime]);

  const updateExpiry = useCallback((timestamp?: string) => {
    setExpirationDate(timestamp ? new Date(timestamp) : undefined);
  }, []);

  /**
   * Transform user-set values of different filter controls into a matching set of policy uuids
   */
  const generateFilters = useCallback(() => {
    let applicablePolicies: PolicyResource[] = [];

    if (nameSelectedPolicyUuids.length > 0) {
      applicablePolicies = exceptionPolicies.filter((policy) => {
        return nameSelectedPolicyUuids.includes(policy.uuid);
      });
    }

    if (reasons.length > 0) {
      applicablePolicies = exceptionPolicies.filter((policy) => {
        if (!policy.spec.exception?.reason ?? false) return false;

        return reasons.includes(policy.spec.exception?.reason);
      });
    }

    if (expirationDate) {
      const interval = { start: currentTime, end: expirationDate };
      applicablePolicies = applicablePolicies.filter((policy) => {
        const expTime = policy.spec.exception?.expiration_time;

        const isExp = expTime
          ? isWithinInterval(new Date(expTime), interval)
          : false;

        return isExp;
      });
    }

    const applicableUuids = applicablePolicies.map((policy) => policy.uuid);

    // If showing exceptions, but not related to specific policies, return undefined.
    if (applicableUuids.length === 0 && !hideExceptions) {
      return undefined;
    }

    return applicableUuids;
  }, [
    currentTime,
    exceptionPolicies,
    expirationDate,
    hideExceptions,
    nameSelectedPolicyUuids,
    reasons,
  ]);

  // If user selects any filters around exception policies
  // exceptions should automatically be shown.
  useEffect(() => {
    if (
      expirationDate ||
      reasons.length > 0 ||
      nameSelectedPolicyUuids.length > 0
    ) {
      setHideExceptions(false);
    }
  }, [expirationDate, nameSelectedPolicyUuids, reasons]);

  // If filter value is explicitly undefined, show exceptions
  useEffect(() => {
    if (filterValue === undefined) {
      setHideExceptions(false);
    }
  }, [filterValue]);

  const handleApply = () => {
    const uuids = generateFilters();
    onChange(uuids);
  };

  const handleCancel = () => {
    setSearchedPolicyName('');
  };

  const content = (
    <Stack direction="column" spacing={space.sm} width={spacing(90)}>
      <Stack>
        <Typography variant="button">
          <FM defaultMessage="Exception Policy" />
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={hideExceptions}
              onChange={() => {
                updateHideExceptions(!hideExceptions);
              }}
            />
          }
          label={<FM defaultMessage="Hide Exceptions" />}
        />
      </Stack>

      <Stack spacing={space.xs}>
        <Stack>
          <Typography variant="button">
            <FM defaultMessage="Exception Policy Name" />
          </Typography>

          <Input
            startAdornment={
              <InputAdornment position="start">
                <IconSearch />
              </InputAdornment>
            }
            onChange={(evt) => setSearchedPolicyName(evt.target.value)}
            placeholder={fm({ defaultMessage: 'Search Policy Name' })}
            value={searchedPolicyName}
          />
        </Stack>

        <Stack
          maxHeight={spacing(40)}
          spacing={space.xs}
          sx={{ overflowY: 'auto' }}
        >
          {policiesMatchingSearch.map((policy) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={nameSelectedPolicyUuids.includes(policy.uuid)}
                    onChange={updateNameSelection}
                    value={policy.uuid}
                  />
                }
                key={policy.uuid}
                label={policy.meta.name}
              />
            );
          })}
        </Stack>
      </Stack>

      <Divider />

      <Stack spacing={space.xs}>
        <Typography variant="button">
          <FM defaultMessage="Reason" />
        </Typography>

        <Stack direction="row" flexWrap="wrap" gap={space.sm}>
          {Object.entries(ExceptionPolicyReasonLabels).map(
            ([reason, label]) => {
              if (reason === PolicyExceptionReason.Unspecified)
                return undefined;

              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reasons.includes(
                        reason as PolicyExceptionReason
                      )}
                      onChange={updateReasons}
                      value={reason}
                    />
                  }
                  key={reason}
                  label={<FM {...label} />}
                  sx={{ flexShrink: 0 }}
                />
              );
            }
          )}
        </Stack>
      </Stack>

      <Divider />

      <Stack spacing={space.xs}>
        <Typography variant="button">
          <FM defaultMessage="Expires Within …" />
        </Typography>

        <Stack direction="row" flexWrap="wrap" gap={space.xs}>
          {expirationValues.map((exp, i) => {
            return (
              <FormControlLabel
                control={
                  <Radio
                    checked={
                      exp.value === expirationDate?.toISOString() ?? false
                    }
                    value={exp.value}
                  />
                }
                key={i}
                label={exp.label}
                onChange={() => updateExpiry(exp.value)}
                sx={{ flexShrink: 0, width: '40%' }}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <FilterDropdown
      id="ControlsFacetFilterFindingExceptions-popover"
      isActive={hideExceptions}
      label={<FM defaultMessage="Exceptions" />}
      onApply={handleApply}
      onCancel={handleCancel}
    >
      {content}
    </FilterDropdown>
  );
};
