import {
  PackageManagerAWSAuthProvider,
  PackagistSpecAuthKind,
  V1GemSpec,
  V1MavenSpec,
  V1NPMSpec,
  V1NugetSpec,
  V1PackagistSpec,
  V1PypiSpec,
} from '@endorlabs/api_client';

import { InstallationListItem } from '../../domains/Installations';

export type FormUpsertPackageManagerFieldValues = {
  name?: string;
  password?: string;
  priority?: number;
  scope?: string;
  token?: string;
  url?: string;
  user?: string;
  host?: string;
  auth_kind?: PackagistSpecAuthKind;
  propagate?: boolean;
  auth_provider?: AWSCodeArtifactFieldValues;
};

export type PackageManagerBaseAuthFields = {
  name?: string;
  propagate?: boolean;
  auth_provider?: AWSCodeArtifactFieldValues;
};

export type PasswordAuthFieldValues = PackageManagerBaseAuthFields & {
  password?: string;
  priority?: number;
  url?: string;
  user?: string;
};

export type PackagistFieldValues = PackageManagerBaseAuthFields & {
  password?: string;
  user?: string;
  host?: string;
  auth_kind?: PackagistSpecAuthKind;
};

export type TokenAuthFieldValues = PackageManagerBaseAuthFields & {
  priority?: number;
  scope?: string;
  token?: string;
  url?: string;
};

export type AWSCodeArtifactFieldValues = {
  aws?: PackageManagerAWSAuthProvider;
};

export enum PackageManagerAuthMode {
  Basic = 'Basic',
  AWS = 'AWS',
}

export type AllPackageManagerFieldKeys =
  | keyof V1MavenSpec
  | keyof V1NPMSpec
  | keyof V1PypiSpec
  | keyof V1GemSpec
  | keyof V1NugetSpec
  | keyof V1PackagistSpec
  | keyof PackageManagerAWSAuthProvider;

export type SelectContainerProps = {
  selectedInstallation: InstallationListItem;
};
