import { FormattedMessage as FM } from 'react-intl';

import { FindingAttributeValue } from '@endorlabs/endor-core/Finding';

import { FindingTagLabel } from '../FindingTagLabel';

/**
 * Wrapper around {@see FindingTagLabel}, to handle additional attribute values that are not finding tags
 */
export const FindingAttributeLabel = ({
  value,
}: {
  value: FindingAttributeValue;
}) => {
  if (value === 'FINDING_SPEC_DISMISSED') {
    return <FM defaultMessage="Dismissed" />;
  }

  return <FindingTagLabel findingTag={value} />;
};
