/**
 * @todo UI-914:
 * - Fetch data for component
 * - Add link to external source
 * - Add affordance for alert
 */

import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedDate, FormattedMessage as FM } from 'react-intl';

import { sortParamBuilders } from '@endorlabs/queries';
import { IconCircle, IconCircleFilled, IconSquare } from '@endorlabs/ui-common';

const DASHBOARD_FEED_ITEMS = [
  {
    alert: {
      severity: 'warning' as const,
      title: '2 projects need attention',
    },
    published: '2024-01-10T08:13:57Z',
    source: {
      icon: <IconCircle />,
      link: 'https://www.securityweek.com/saps-first-patches-of-2024-resolve-critical-vulnerabilities',
      title: 'SecurityWeek',
    },
    title: `SAP's First Patches of 2024 Resolve Critical Vulnerabilities`,
  },
  {
    published: '2023-06-23T01:30:01Z',
    source: {
      icon: <IconSquare />,
      link: 'https://www.csoonline.com/article/643153/millions-of-github-repositories-vulnerable-to-repojacking-report.html',
      title: 'CSO Online',
    },
    title: 'Millions of GitHub repositories vulnerable to RepoJacking',
  },
];

export const DashboardFeed = ({ namespace }: { namespace: string }) => {
  const { space } = useTheme();

  // TODO: Fetch the most recently updated dashboard feed items
  // const qDashboardFeed = useListDashboardFeed(namespace, {
  //   page_size: 2,
  //   sort: sortParamBuilders.descendingBy('meta.update_time'),
  // });

  return (
    <Grid container spacing={space.xl}>
      {DASHBOARD_FEED_ITEMS.map((f, index) => (
        <Grid item md={6} xs={12} key={index}>
          <Card>
            <CardContent>
              <Stack
                direction="row"
                spacing={space.sm}
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="column" spacing={space.xs}>
                  <Stack direction="row" spacing={space.xs} alignItems="center">
                    {f.source.icon}

                    <Typography
                      color="text.secondary"
                      component="span"
                      variant="body2"
                    >
                      {f.source.title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      component="span"
                      variant="body2"
                    >
                      •
                    </Typography>
                    <Typography
                      color="text.secondary"
                      component="span"
                      variant="body2"
                    >
                      <FormattedDate value={f.published} dateStyle="medium" />
                    </Typography>
                  </Stack>

                  <Typography variant="body1" fontWeight={500}>
                    {f.title}
                  </Typography>
                </Stack>

                {f.alert ? (
                  <Alert severity={f.alert.severity}>
                    <AlertTitle>{f.alert.title}</AlertTitle>
                  </Alert>
                ) : (
                  <Alert severity="success">
                    <AlertTitle>
                      <FM defaultMessage="You're good!" />
                    </AlertTitle>
                  </Alert>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
