import { Box, Skeleton } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog } from '@endorlabs/ui-common';

import { DependenciesExportDialogContent } from './DependenciesExportDialogContent';
import { DependenciesExportDialogProps } from './types';

/**
 * Dialog for Dependencies Export
 */
export const DependenciesExportDialog = (
  props: DependenciesExportDialogProps
) => {
  const { open, onClose } = props;

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      titleText={<FM defaultMessage="Export Filtered Dependencies" />}
      descriptionText={
        <FM defaultMessage="Exports the results for your current view and filter as a CSV file. " />
      }
      disableCloseOnOutsideClick
    >
      {/* NOTE: avoiding mounting content until the dialog is open */}
      {props.state ? (
        <DependenciesExportDialogContent {...props} state={props.state} />
      ) : (
        <Box>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" height={400} />
        </Box>
      )}
    </SimpleDialog>
  );
};
