import {
  QueryServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { ResourceReadAction } from '@endorlabs/endor-core/api';
import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';

import { createResourceReadHooks, ReadServiceRequestOptions } from './hooks';
import {
  ResourceGroupResponse,
  ResourceListResponse,
  TResourceList,
} from './types';
import { getClientConfiguration } from './utils';

const RESOURCE_KIND: ResourceKind = 'AssuredPackageVersion';

const queries = createResourceReadHooks('AssuredPackageVersion', () => {
  const getApiService = () => new QueryServiceApi(getClientConfiguration());

  const queryHandler = async (
    action: ResourceReadAction,
    namespace: string,
    params: V1ListParameters,
    options: ReadServiceRequestOptions
  ) => {
    const resp = await getApiService().queryServiceCreateQuery(
      namespace,
      {
        meta: { name: `${RESOURCE_KIND}:${action}` },
        spec: { query_spec: { kind: RESOURCE_KIND, list_parameters: params } },
      },
      { ...options, params: { k: RESOURCE_KIND } }
    );

    return resp.data.spec
      ?.query_response as TResourceList<AssuredPackageVersionResource>;
  };

  return {
    async count(namespace, params, options) {
      const resp = await queryHandler('COUNT', namespace, params, options);
      return resp.count_response as Required<V1CountResponse>;
    },
    async get(namespace, params, options) {
      const { mask, uuid } = params;
      const listParameters: V1ListParameters = {
        filter: `uuid=="${uuid}"`,
        mask,
        page_size: 0,
      };

      const resp = await queryHandler(
        'GET',
        namespace,
        listParameters,
        options
      );
      return resp.list?.objects.at(0) as AssuredPackageVersionResource;
    },
    async group(namespace, params, options) {
      const resp = await queryHandler('GROUP', namespace, params, options);
      return resp.group_response as ResourceGroupResponse;
    },
    async list(namespace, params, options) {
      const resp = await queryHandler('LIST', namespace, params, options);
      return resp.list as ResourceListResponse<AssuredPackageVersionResource>;
    },
  };
});

export const useListAssuredPackageVersions = queries.useList;
