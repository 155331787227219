import { Stack, Typography, useTheme } from '@mui/material';

import { ToolLogoDisplay, ToolLogoDisplayProps } from './ToolLogoDisplay';

export type ToolNameDisplayProps = {
  toolName: string;
  iconProps?: Omit<ToolLogoDisplayProps, 'toolName'>;
};

export const ToolNameDisplay = ({
  toolName,
  iconProps = {},
}: ToolNameDisplayProps) => {
  const theme = useTheme();

  return (
    <Stack alignItems="center" direction="row" gap={theme.space.xs}>
      <ToolLogoDisplay
        toolName={toolName}
        sx={{ fontSize: '1.75em' }}
        {...iconProps}
      />
      <Typography variant="body1">{toolName}</Typography>
    </Stack>
  );
};
