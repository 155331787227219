import { Theme, Typography, useTheme } from '@mui/material';

import { IconCwe, RowStack, useStyles } from '@endorlabs/ui-common';

interface CweIdDisplayProps {
  cweId: string;
}

export const CweIdDisplay = ({ cweId }: CweIdDisplayProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  return (
    <RowStack className="CveIdDisplay-root" gap={space.xs} sx={sx}>
      <IconCwe fontSize="large" />
      <Typography color="text.secondary" variant="code">
        {cweId}
      </Typography>
    </RowStack>
  );
};

function styles({ palette }: Theme) {
  return {
    '&.CveIdDisplay-root': {
      color: palette.text.secondary,
    },
  };
}
