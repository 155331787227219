import { useContext } from 'react';

import { FilterSavedFiltersContext } from './FilterSavedFiltersContext';

export const useFilterSavedFiltersContext = () => {
  const context = useContext(FilterSavedFiltersContext);

  // Ensure context is present for consumer
  if (!context) {
    throw new Error('Missing provider for FilterSavedFiltersContext');
  }

  return context;
};
