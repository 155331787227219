import {
  Outlet,
  ReactLocation,
  Router,
  stringifySearchWith,
} from '@tanstack/react-location';
import { identity as _identity, set as _set } from 'lodash-es';
import { QueryClient } from 'react-query';

import { isQueryError } from '@endorlabs/queries';
import { AppContainer } from '@endorlabs/ui-common';

import { getEnv } from './constants';
import { AuthProviders } from './domains/Auth';
import messages from './locales/en.json';
import { EventTrackingProvider } from './providers';
import { routeTree } from './routes/routeTree';

const { HUBSPOT_PORTAL_ID } = getEnv();

// inject dev tools for local development
if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { register } = require('./__dev__/register');
  register({ routes: routeTree.children, authProviders: AuthProviders });
}

/**
 * The default Query String parser used by react-location is an inlined version
 * of [qss](https://github.com/lukeed/qss). The parser attempts to decode  any
 * parameters in the Query String from strings to JS primitives.
 *
 * This can cause issues with params such as version refs (ex: 3.0, 14.2.0, etc)
 * which when parsed from the Query String would strip the decimal from the
 * number, breaking the ability to string match the version ref.
 *
 * This parser does not attempt to decode the Query String params any further
 * than Record<string, string>, and allow require further decoding to happen
 * in the application (if needed).
 */
const parseSearchSimple = (queryString?: string) => {
  const params: Record<string, string> = {};
  if (!queryString || queryString == '?') return params;

  const url = new URL(queryString, window.location.origin);

  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

const location = new ReactLocation({
  parseSearch: parseSearchSimple,
  stringifySearch: stringifySearchWith(_identity),
});

const QUERY_CLIENT_MAX_RETRIES = 2;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (isQueryError(error)) {
          const status = error.response.status;

          // Don't retry for any 4xx errors
          if (status >= 400 && status < 500) return false;
        }

        return failureCount < QUERY_CLIENT_MAX_RETRIES;
      },
      // set default stale time for query data. Can be overriden per-query
      staleTime: 1000 * 60,
    },
  },
});

// HACK: set the root route context here
_set(routeTree, ['meta', 'context', 'queryClient'], queryClient);
const ROUTES = [routeTree];

export const WebApp = () => {
  return (
    <AppContainer messages={messages} queryClient={queryClient}>
      <Router location={location} routes={ROUTES}>
        <EventTrackingProvider hubspotPortalId={HUBSPOT_PORTAL_ID}>
          <Outlet />
        </EventTrackingProvider>
      </Router>
    </AppContainer>
  );
};
