import { Button } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { IconSidebarRight } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { usePoliciesAndRelatedProjects } from '../hooks/usePoliciesAndRelatedProjects';
import { usePolicyProjectsDrawer } from '../hooks/usePolicyProjectsDrawer';

/**
 * Tracks projects affected by the working policy & allows toggle of policy projects drawer
 */
export const PolicyProjectsDrawerButton = () => {
  const { DetailDrawer: PolicyProjectsDrawer } = usePolicyProjectsDrawer();
  const { activeNamespace: tenantName } = useAuthInfo();
  const { control } = useFormContext();

  const policy_name = useWatch({ control, name: 'meta.name' });
  const policy_type = useWatch({ control, name: 'spec.policy_type' });
  const project_selector = useWatch({ control, name: 'spec.project_selector' });
  const project_exceptions = useWatch({
    control,
    name: 'spec.project_exceptions',
  });

  const { getProjectCountForPolicy, policies: workingPolicies } =
    usePoliciesAndRelatedProjects({
      namespace: tenantName,
      policies: [
        {
          spec: { policy_type, project_selector, project_exceptions },
          meta: { name: policy_name },
        },
      ],
    });

  /**
   * Update drawer content if working policy field values change
   */
  useEffect(() => {
    if (
      PolicyProjectsDrawer.isOpen &&
      !!PolicyProjectsDrawer.getSearchParams()?.activePolicies &&
      PolicyProjectsDrawer.getSearchParams()?.activePolicies !==
        JSON.stringify(workingPolicies)
    ) {
      PolicyProjectsDrawer.activate(
        { activePolicies: JSON.stringify(workingPolicies) },
        { policies: workingPolicies }
      );
    }
  }, [PolicyProjectsDrawer, workingPolicies]);

  return (
    <Button
      className="PolicyProjectsDrawerButton-root"
      endIcon={<IconSidebarRight />}
      onClick={() =>
        PolicyProjectsDrawer.toggle(
          { activePolicies: JSON.stringify(workingPolicies) },
          { policies: workingPolicies }
        )
      }
    >
      <FM
        defaultMessage="This policy will apply to {projectCount, number} projects"
        values={{
          projectCount: getProjectCountForPolicy(),
        }}
      />
    </Button>
  );
};
