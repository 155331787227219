import { isEmpty as _isEmpty } from 'lodash-es';

import {
  SpecEnabledFeatureType as EnabledFeatureType,
  SpecEndorLicenseFeatureType as FeatureType,
  V1EndorLicense,
} from '@endorlabs/api_client';

import { LICENSE_FEATURE_TO_ENABLED_FEATURE } from './constants';

/**
 * For the given Endor Licenses, return the features that may be enabled
 */
export const getEnabledFeaturesFromLicenses = (
  licenses: V1EndorLicense[]
): EnabledFeatureType[] => {
  const licenseInfoList = licenses.flatMap((l) => {
    return l.spec.license_info ?? [];
  });

  // For free trial and existing customers, show all options
  if (_isEmpty(licenseInfoList)) {
    return Object.values(LICENSE_FEATURE_TO_ENABLED_FEATURE);
  }

  const licenseFeatureTypes = licenseInfoList
    .map((l) => l.type)
    .filter((v): v is FeatureType => !!v);

  // For licenses which are not mapped under Enabledfeatures, ignore for now.
  const featuresList: EnabledFeatureType[] = [];
  for (const feature of licenseFeatureTypes) {
    const enabledFeature = LICENSE_FEATURE_TO_ENABLED_FEATURE[feature];
    if (enabledFeature) {
      featuresList.push(enabledFeature);
    }
  }

  return featuresList;
};
