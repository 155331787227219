import { Button } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1FindingCategory,
  V1GroupResponse,
  V1ScoreCard,
  V1ScoreFactor,
} from '@endorlabs/api_client';
import { RepoVersionResource } from '@endorlabs/queries';
import {
  FindingCategorySelect,
  FullHeightCard,
  IconSidebarRight,
  ResponsiveCardLayout,
} from '@endorlabs/ui-common';

import {
  FindingRiskMatrixTable,
  ProjectOverviewTopMetadata,
  ProjectOverviewTopMetadataProps,
  ScoreCardDisplay,
} from '../../components';
import {
  ScoreFactorsOverview,
  useScoreFactorsDrawer,
} from '../../domains/Metrics';

interface ProjectVersionOverviewProps {
  findingGroups: V1GroupResponse['groups'];
  repositoryVersion?: RepoVersionResource;
  isMetricLoading?: boolean;
  scoreCard?: V1ScoreCard;
  scoreFactors?: V1ScoreFactor[];
  topMetadataProps: ProjectOverviewTopMetadataProps;
}

export const ProjectVersionOverview = ({
  findingGroups,
  repositoryVersion,
  isMetricLoading,
  scoreCard,
  scoreFactors,
  topMetadataProps,
}: ProjectVersionOverviewProps) => {
  const { DetailDrawer, permalinkEffect } = useScoreFactorsDrawer();

  useLayoutEffect(() => {
    permalinkEffect();
  }, [repositoryVersion?.uuid, permalinkEffect]);

  const [activeFindingCategory, setActiveFindingCategory] = useState<
    V1FindingCategory | undefined
  >(undefined);

  return (
    <ResponsiveCardLayout>
      <FullHeightCard title={<FM defaultMessage="Project Scores" />}>
        <ScoreCardDisplay scoreCard={scoreCard} isLoading={isMetricLoading} />
      </FullHeightCard>

      <FullHeightCard
        action={
          <Button
            data-testid="all-score-factors"
            endIcon={<IconSidebarRight />}
            disabled={!repositoryVersion}
            onClick={() => {
              DetailDrawer.activate(
                {
                  resourceNamespace: repositoryVersion?.tenant_meta.namespace,
                  resourceUuid: repositoryVersion?.uuid,
                },
                {
                  resourceNamespace: repositoryVersion?.tenant_meta.namespace,
                  resourceUuid: repositoryVersion?.uuid,
                }
              );
            }}
          >
            <FM defaultMessage="All Score Factors" />
          </Button>
        }
        title={<FM defaultMessage="Score Factors" />}
      >
        <ScoreFactorsOverview scoreFactors={scoreFactors} />
      </FullHeightCard>

      <FullHeightCard
        action={
          <FindingCategorySelect
            activeFindingCategory={activeFindingCategory}
            onChange={(_, findingCategory) =>
              setActiveFindingCategory(findingCategory)
            }
          />
        }
        title={<FM defaultMessage="Finding Risk Matrix" />}
      >
        <FindingRiskMatrixTable
          activeFindingCategory={activeFindingCategory}
          findingGroups={findingGroups}
        />
      </FullHeightCard>

      <FullHeightCard title={<FM defaultMessage="Top Metadata" />}>
        <ProjectOverviewTopMetadata {...topMetadataProps} />
      </FullHeightCard>
    </ResponsiveCardLayout>
  );
};
