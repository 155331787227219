import { Grid } from '@mui/material';
import { ReactNode } from 'react';

import { MetadataList, MetadataMetric } from '@endorlabs/ui-common';

export interface PageMetadataProps {
  summary: MetadataMetric[];
  action?: ReactNode;
}

export const PageMetadata = ({ summary = [], action }: PageMetadataProps) => {
  return (
    <Grid container alignItems="center">
      <Grid
        item
        flexGrow={1}
        sx={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        <MetadataList metrics={summary} />
      </Grid>
      {action && <Grid item>{action}</Grid>}
    </Grid>
  );
};
