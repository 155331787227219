import { Box, Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import {
  FormattedMessage as FM,
  MessageFormatElement,
  useIntl,
} from 'react-intl';

import { ButtonPrimary } from '@endorlabs/ui-common';
import errorPageBackground from '@endorlabs/ui-common/assets/errors/error_background_shapes.svg';

interface ErrorPageProps {
  errorCode: number;
  errorTitle?: ReactNode;
  errorDescription?: ReactNode;
}

const getErrorMessaging = (
  code: number
): { title: ReactNode; description: ReactNode } => {
  switch (code) {
    case 401:
    case 403: {
      return {
        title: <FM defaultMessage="Unable to Authorize" />,
        description: (
          <Box textAlign="center">
            <FM defaultMessage="We were unable to successfully authorize this account. If logging in with GitHub, please make sure the invitation was sent to the primary email address for your GitHub account." />
          </Box>
        ),
      };
    }
    case 404: {
      return {
        title: <FM defaultMessage="Page Not Found" />,
        description: (
          <Box textAlign="center">
            <FM defaultMessage="You've requested a screen that does not exist. Please check the URL you were trying to reach." />
          </Box>
        ),
      };
    }
    default: {
      return {
        title: <FM defaultMessage="We've Encountered a Problem" />,
        description: (
          <Box textAlign="center">
            <FM defaultMessage="An unknown error has occurred. Please navigate to a previous screen, or return to your dashboard." />
          </Box>
        ),
      };
    }
  }
};

export const ErrorPage = ({
  errorCode,
  errorDescription,
  errorTitle,
}: ErrorPageProps) => {
  const sx = styles();
  const { formatMessage: fm } = useIntl();

  const messaging = getErrorMessaging(errorCode);
  const title = errorTitle ?? messaging.title;
  const description = errorDescription ?? messaging.description;

  return (
    <Box sx={sx.background}>
      <Grid container alignItems="center" sx={{ height: '100%' }}>
        <Grid item xs={6}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            {errorCode && (
              <Typography fontSize="250px" variant="h1" align="center">
                {errorCode}
              </Typography>
            )}

            <Typography fontSize="30px" variant="h3" align="center">
              {title}
            </Typography>

            <Box width="50%">
              <Typography fontSize="15px" variant="subtitle1" align="center">
                {description}
              </Typography>
            </Box>

            <ButtonPrimary href="/">
              <FM defaultMessage="Return Home" />
            </ButtonPrimary>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Box sx={sx.backgroundShapes} />
        </Grid>
      </Grid>
    </Box>
  );
};

function styles() {
  return {
    background: {
      width: '100vw',
      height: '100vh',
    },
    backgroundShapes: {
      backgroundImage: `url(${errorPageBackground})`,
      backgroundPosition: 'flex-start',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '100vh',
    },
  };
}
