import { Box, Chip, ChipProps, Fade, Stack, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import { useIntl } from 'react-intl';

import { useCopyToClipboard } from '../../hooks';
import { IconCheck, IconClipboard } from '../../themes';

interface CopyToClipboardChipProps extends Omit<ChipProps, 'label'> {
  value: string;
  displayValue?: string;
}

/**
 * Related to the {@see CopyToClipboardButton}
 */
export const CopyToClipboardChip = ({
  value,
  displayValue,
  ...props
}: CopyToClipboardChipProps) => {
  const [isCopied, copyText, setIsCopied] = useCopyToClipboard(value);
  const { formatMessage: fm } = useIntl();
  const sizeSmall = props.size === 'small';

  const handleCopy = (event: MouseEvent) => {
    event.stopPropagation();
    copyText();

    setTimeout(() => {
      setIsCopied(false);
    }, 1800);
  };

  return (
    <Chip
      aria-label={fm({ defaultMessage: 'Copy to Clipboard' })}
      onClick={handleCopy}
      {...props}
      label={
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          sx={{ position: 'relative' }}
        >
          <Typography
            component="span"
            color="text.primary"
            fontSize="inherit"
            lineHeight={sizeSmall ? 1 : 'inherit'}
          >
            {displayValue ?? value}
          </Typography>

          <Box
            sx={{
              color: 'text.primary',
              fontSize: sizeSmall ? '14px' : '16px',
              lineHeight: 1,
            }}
          >
            <IconClipboard fontSize="inherit" />
          </Box>

          <Fade in={isCopied}>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: 'success.main',
                border: '1px solid white',
                borderRadius: '50%',
                color: 'common.white',
                display: 'flex',
                fontSize: '8px',
                height: '1.2em',
                justifyContent: 'center',
                padding: '6px',
                position: 'absolute',
                right: '-0.2em',
                top: '0.2em',
                width: '1.2em',
              }}
            >
              <IconCheck
                aria-label={fm({ defaultMessage: 'Copy successful' })}
                fontSize="inherit"
              />
            </Box>
          </Fade>
        </Stack>
      }
    />
  );
};
