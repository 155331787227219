import { Grid, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonCancel,
  FileContent,
  FileUploadBox,
  FileUploadStatus,
  SimpleDialog,
  SimpleDialogProps,
  useAppNotify,
  useFileUpload,
} from '@endorlabs/ui-common';

type ImportDialogProps = Pick<SimpleDialogProps, 'open'> & {
  enableUpload?: boolean;
  namespace: string;
  onClose: () => void;
};

export const SASTRulesImportDialog = ({ open, onClose }: ImportDialogProps) => {
  const theme = useTheme();
  const title = <FM defaultMessage="Import SAST Rule" />;

  const addAppNotification = useAppNotify();

  const [uploadStatus, setUploadStatus] = useState<FileUploadStatus>(
    FileUploadStatus.IDLE
  );
  const [uploadError, setUploadError] = useState<string>();

  const handleFileUpload = async (fileContent: FileContent) => {
    // TODO: handle non-text file upload
    if (typeof fileContent !== 'string') return;

    setUploadStatus(FileUploadStatus.LOADING);
    setUploadError(undefined);

    // const { compressString } = await import(
    //   '@endorlabs/utils/compress/zstd/compress'
    // );
    // const compressed = await compressString(fileContent);

    // useCreateSemgrepRules({
    //   namespace: namespace,
    // });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
      setUploadStatus(FileUploadStatus.IDLE);
      setUploadError(undefined);
    }
  };

  const { dragAndDropProps, fileData, inputProps, readError } = useFileUpload({
    acceptedTypes: ['application/json', 'application/xml'],
    contentFormat: 'text',
    inputName: 'sastRuleImport',
    onLoad: handleFileUpload,
  });

  return (
    <SimpleDialog
      open={open}
      titleText={title}
      onClose={handleClose}
      maxWidth="sm"
    >
      <Grid container direction="column" spacing={theme.space.lg}>
        <Grid item>
          <Stack direction="column" spacing={theme.space.xs}>
            <FileUploadBox
              dragAndDropProps={dragAndDropProps}
              fileData={fileData}
              inputProps={inputProps}
              readError={readError}
              titleText={
                <FM defaultMessage="Upload your SAST Rule file in JSON or XML format" />
              }
              uploadStatus={uploadStatus}
              uploadError={uploadError}
            />
          </Stack>
        </Grid>

        <Grid item>
          <ButtonCancel onClick={handleClose} />
        </Grid>
      </Grid>
    </SimpleDialog>
  );
};
