import { offset } from '@floating-ui/dom';
import Shepherd from 'shepherd.js';

export const ProductTourDefaultOptions: Shepherd.Tour.TourOptions = {
  exitOnEsc: true,
  keyboardNavigation: true,
  tourName: 'ENDOR_PRODUCT_TOUR',
  useModalOverlay: false,

  defaultStepOptions: {
    arrow: false,
    buttons: [
      {
        action: function () {
          return this ? this.next() : undefined;
        },
        text: 'Next',
      },
    ],
    cancelIcon: {
      enabled: true,
      label: 'Exit Tour',
    },
    canClickTarget: false,
    classes: 'product-tour-step',

    // Provide an offset from the target element. Can override at individual step level.
    floatingUIOptions: { middleware: [offset(24)] },

    scrollTo: false,
  },
};
