import type { ReactNode } from 'react';
import { List } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const ReorderableList = ({ children }: { children: ReactNode }) => {
  {
    return (
      <DndProvider backend={HTML5Backend}>
        <List>{children}</List>
      </DndProvider>
    );
  }
};
