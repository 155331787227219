import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  PRCommentConfigServiceApi,
  V1PRCommentConfig,
} from '@endorlabs/api_client';

import {
  PRCommentConfigResource,
  PRCommentConfigResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface PRCommentConfigsReadParams {
  namespace: string;
}

export interface PRCommentConfigsWriteParams {
  namespace: string;
  resource: V1PRCommentConfig;
}

export interface PRCommentConfigsUpdateParams
  extends PRCommentConfigsWriteParams {
  mask?: string;
}

type ListPRCommentConfigsOptions =
  ResourceQueryOptions<PRCommentConfigResourceList>;

type UpsertPRCommentConfigsOptions = ResourceMutateOptions<
  V1PRCommentConfig,
  PRCommentConfigsWriteParams
>;

const BASE_KEY = 'v1/pr-comments-configs';
const QK = {
  list: (namespace: string): QueryKey => [BASE_KEY, 'list', namespace] as const,
};
export const PRCommentConfigsQueryKeys = QK;

const getApiService = () =>
  new PRCommentConfigServiceApi(getClientConfiguration());

export const listPRCommentConfigs = async ({
  namespace,
}: PRCommentConfigsReadParams) => {
  const api = getApiService();
  const resp = await api.pRCommentConfigServiceListPRCommentConfigs(namespace);
  return resp.data as PRCommentConfigResourceList;
};

export const useListPRCommentConfigs = (
  params: PRCommentConfigsReadParams,
  opts: ListPRCommentConfigsOptions = {}
) => {
  return useQuery(
    QK.list(params.namespace),
    () => listPRCommentConfigs(params),
    opts
  );
};

const createPRCommentConfig = async (
  namespace: string,
  configBody: V1PRCommentConfig
) => {
  const api = getApiService();
  const resp = await api.pRCommentConfigServiceCreatePRCommentConfig(
    namespace,
    configBody
  );
  return resp.data as PRCommentConfigResource;
};

export const useCreatePRCommentConfig = (
  opts: UpsertPRCommentConfigsOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'PRCommentConfig'),
    mutationFn: (params: PRCommentConfigsWriteParams) =>
      createPRCommentConfig(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updatePRCommentConfig = async (params: PRCommentConfigsUpdateParams) => {
  const { resource, namespace, mask } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.pRCommentConfigServiceUpdatePRCommentConfig(
    namespace,
    req
  );
  return resp.data as PRCommentConfigResource;
};

export const useUpdatePRCommentConfig = (
  opts: UpsertPRCommentConfigsOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'PRCommentConfig'),
    mutationFn: (params: PRCommentConfigsUpdateParams) =>
      updatePRCommentConfig(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
