import { Card, CardContent, CardHeader, Grid, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import {
  useCountAuthorizationPolicies,
  useListAuthorizationPolicies,
  useListIdentityProviders,
} from '@endorlabs/queries';
import {
  AuthorizationPolicyUpsertDialog,
  ButtonPrimary,
  ConfirmationDialog,
  EmptyState,
  Link,
  useAuthorizationPolicyUpsertDialog,
  useDataTablePaginator,
  useDeleteAuthorizationPolicyConfirmationDialog,
} from '@endorlabs/ui-common';

import {
  AuthorizationPoliciesTable,
  AuthorizationPoliciesTableRow,
  mapAuthorizationPoliciesToTableRow,
} from '../../components';
import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';
import { getAccessPath } from '../../routes';

export interface AuthorizationPoliciesViewProps {
  namespace: string;
}

export const AuthorizationPoliciesView = ({
  namespace,
}: AuthorizationPoliciesViewProps) => {
  // Should only be 1 identity provider per tenant, defined at the tenant namespace
  const tenantNamespace = getRootNamespace(namespace);
  const qListIdentityProvider = useListIdentityProviders(tenantNamespace, {
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
  });
  const identityProvider = qListIdentityProvider.data?.list?.objects[0];

  // NOTE: explictly filtering out API Key Auth Policies. Auth Policies for an
  // API Key are automatically created for the key.
  const authorizationPoliciesFilterExpression =
    'meta.parent_kind not in [APIKey]';

  const qCountAuthorizationPolices = useCountAuthorizationPolicies(
    namespace,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      filter: authorizationPoliciesFilterExpression,
    }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountAuthorizationPolices.data?.count,
  });

  const qListAuthorizationPolices = useListAuthorizationPolicies(
    namespace,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
    },
    {
      ...paginator.getListParameters(),
      filter: authorizationPoliciesFilterExpression,
    }
  );

  const authorizationPolicies = useMemo(() => {
    return mapAuthorizationPoliciesToTableRow(
      qListAuthorizationPolices.data?.objects ?? []
    );
  }, [qListAuthorizationPolices.data]);

  const {
    openAuthorizationPolicyUpsertDialog,
    getAuthorizationPolicyUpsertDialogProps,
  } = useAuthorizationPolicyUpsertDialog();

  const handleCreateAuthPolicy = useCallback(() => {
    openAuthorizationPolicyUpsertDialog({ namespace, identityProvider });
  }, [namespace, identityProvider, openAuthorizationPolicyUpsertDialog]);

  const handleEditAuthPolicy = useCallback(
    (_: unknown, row: AuthorizationPoliciesTableRow) => {
      const authorizationPolicy = qListAuthorizationPolices.data?.objects.find(
        (ap) => ap.uuid === row.uuid
      );
      openAuthorizationPolicyUpsertDialog({
        namespace: authorizationPolicy?.tenant_meta.namespace ?? namespace,
        identityProvider,
        authorizationPolicy,
      });
    },
    [
      namespace,
      identityProvider,
      openAuthorizationPolicyUpsertDialog,
      qListAuthorizationPolices.data,
    ]
  );

  const DeleteAuthPolicyConfirmationDialog =
    useDeleteAuthorizationPolicyConfirmationDialog();

  const handleDeleteAuthPolicy = useCallback(
    (_: unknown, row: AuthorizationPoliciesTableRow) => {
      const authPolicy = qListAuthorizationPolices.data?.objects.find(
        (ap) => ap.uuid === row.uuid
      );

      // validate resource exists before using
      if (authPolicy) {
        DeleteAuthPolicyConfirmationDialog.openDialog(authPolicy);
      }
    },
    [DeleteAuthPolicyConfirmationDialog, qListAuthorizationPolices.data]
  );

  const isEmptyState =
    !qListAuthorizationPolices.isLoading && authorizationPolicies.length === 0;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              action={
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <ButtonPrimary onClick={handleCreateAuthPolicy}>
                    <FM defaultMessage="Add Auth Policy" />
                  </ButtonPrimary>
                </Stack>
              }
              title={
                <FM defaultMessage="Manage and configure authorization policies to allow access to your tenant" />
              }
            />

            <CardContent>
              <AuthorizationPoliciesTable
                activeNamespace={namespace}
                data={authorizationPolicies}
                enablePagination
                identityProvider={identityProvider}
                paginator={paginator}
                isLoading={qListAuthorizationPolices.isLoading}
                onEdit={handleEditAuthPolicy}
                onDelete={handleDeleteAuthPolicy}
              />
            </CardContent>
          </Card>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <EmptyState
            title={<FM defaultMessage="Need help?" />}
            description={
              <FM defaultMessage="Build more robust and secure software together" />
            }
          >
            <ButtonPrimary
              // @ts-expect-error FIXME: `Button` doesn't expect the prop `to` from LinkComponent
              to={getAccessPath({
                tenantName: namespace,
                section: 'invitations',
              })}
              LinkComponent={Link}
            >
              <FM defaultMessage="Invite Your Team" />
            </ButtonPrimary>
          </EmptyState>
        </Grid>
      )}

      {isEmptyState && (
        <EmptyState
          size="large"
          title={<FM defaultMessage="Need Help?" />}
          description={
            <FM defaultMessage="Build more robust and secure software together" />
          }
        >
          <ButtonPrimary onClick={handleCreateAuthPolicy}>
            <FM defaultMessage="Add Auth Policy" />
          </ButtonPrimary>
        </EmptyState>
      )}

      {/* Auth Policy Related Dialogs */}
      <AuthorizationPolicyUpsertDialog
        {...getAuthorizationPolicyUpsertDialogProps()}
      />
      <ConfirmationDialog
        {...DeleteAuthPolicyConfirmationDialog.getConfirmationDialogProps()}
      />
    </Grid>
  );
};
