import produce from 'immer';
import { castArray as _castArray } from 'lodash-es';
import { useMemo, useRef } from 'react';

import {
  Endorv1Metric as V1Metric,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import {
  PackageVersionResource,
  selectMetricScores,
  useQueryResourceRelatedMetrics,
} from '@endorlabs/queries';

import { PackageVersionDependency } from './usePackageVersionDependencies';

type ScoreMetrics = Partial<
  Record<Exclude<V1ScoreCategory, V1ScoreCategory.Unspecified>, number>
>;

/**
 * Given partial dependency data, build a Resource model]
 *
 * NOTE: filters out dependencies with incomplete or missing data
 */
const mapToPackageVersionResources = (
  dependencies: PackageVersionDependency[]
): PackageVersionResource[] => {
  return (
    dependencies
      .filter((v) => v.uuid && v.namespace)
      .map(
        ({ uuid, namespace, name }) =>
          ({
            uuid,
            tenant_meta: {
              namespace,
            },
            meta: {
              kind: 'PackageVersion',
              name,
            },
          } as PackageVersionResource)
      )
      // ensure consistent ordering for cache keys
      .sort((a, b) => a.uuid.localeCompare(b.uuid))
  );
};

export const usePackageVersionDependencyScoreMetrics = (
  namespace: string,
  singleValueOrList: PackageVersionDependency | PackageVersionDependency[]
) => {
  const packageVersions = mapToPackageVersionResources(
    _castArray(singleValueOrList)
  );

  const qPackageVersionMetrics = useQueryResourceRelatedMetrics(
    packageVersions,
    { enabled: !!packageVersions.length }
  );

  // only mutate the score metric object on data change, adding to the previous
  // object and preserving referential equality checks when data is unchanged.
  const previousScoreMetrics = useRef<Record<string, ScoreMetrics>>({});
  const scoreMetrics: Record<string, ScoreMetrics> = useMemo(() => {
    const metrics = qPackageVersionMetrics.data ?? [];

    // immer tracks mutate calls, and will only return a new object if a mutation
    const scoreMetrics = produce(previousScoreMetrics.current, (draft) => {
      for (const metric of metrics) {
        // don't mutate if value already exists in the object
        if (metric.meta.parent_uuid in draft) continue;

        const metricScores = selectMetricScores(metric as V1Metric);
        draft[metric.meta.parent_uuid] = {
          [V1ScoreCategory.Activity]: metricScores.scoreActivity?.score,
          [V1ScoreCategory.CodeQuality]: metricScores.scoreCodeQuality?.score,
          [V1ScoreCategory.Popularity]: metricScores.scorePopularity?.score,
          [V1ScoreCategory.Security]: metricScores.scoreSecurity?.score,
        };
      }
    });

    previousScoreMetrics.current = scoreMetrics;
    return scoreMetrics;
  }, [qPackageVersionMetrics.data]);

  return { ...qPackageVersionMetrics, scoreMetrics };
};
