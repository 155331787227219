import { JSXElementConstructor, useCallback, useMemo, useState } from 'react';

import { SimpleDialogProps } from '../components';

/**
 * Utility hook to manage Simple Dialogs
 */
export function useDialog<State extends object>({
  component: DialogComponent,
  initialState,
}: {
  component: JSXElementConstructor<SimpleDialogProps & { state?: State }>;
  initialState?: Partial<State>;
}) {
  const [dialogState, setDialogState] = useState<State | undefined>(undefined);
  const isOpen = Boolean(dialogState);

  const handleCloseDialog = useCallback(() => {
    setDialogState(undefined);
  }, []);

  const handleOpenDialog = useCallback(
    (state: State) => {
      setDialogState({ ...initialState, ...state });
    },
    [initialState]
  );

  const handleUpdateDialog = useCallback((update: Partial<State>) => {
    setDialogState((state) => {
      return { ...state, ...update } as State;
    });
  }, []);

  const dialogProps = useMemo(
    () =>
      ({
        state: dialogState,
        open: isOpen,
        onClose: handleCloseDialog,
      } as SimpleDialogProps & { state: State }),
    [dialogState, handleCloseDialog, isOpen]
  );

  return {
    Dialog: DialogComponent,
    closeDialog: handleCloseDialog,
    dialogProps,
    openDialog: handleOpenDialog,
    update: handleUpdateDialog,
  };
}
