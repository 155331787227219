import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { SpecFindingLevel, V1FindingSpec } from '@endorlabs/api_client';
import { FindingCount } from '@endorlabs/queries';
import {
  FindingCountArrayDisplay,
  FindingNameDisplay,
  PackageVersionNameDisplay,
  RowStack,
} from '@endorlabs/ui-common';

import { AggregatedFinding } from '../hooks';
import { FindingAggregation } from '../types';

interface FindingsDataTableViewAccordionGroupTitleNodeProps {
  aggregationGroup: AggregatedFinding;
  aggregationType: FindingAggregation;
  level?: SpecFindingLevel;
  title: string;
}

export const FindingsDataTableViewAccordionGroupTitleNode = ({
  aggregationGroup,
  aggregationType,
  level,
  title,
}: FindingsDataTableViewAccordionGroupTitleNodeProps) => {
  const findingCountArray = useMemo(() => {
    return Object.entries(aggregationGroup.findingCounts).reduce(
      (acc: FindingCount[], [lvl, count]) =>
        acc.concat([{ level: lvl as SpecFindingLevel, value: count }]),
      []
    );
  }, [aggregationGroup.findingCounts]);

  if (
    aggregationType === FindingAggregation.Dependency ||
    aggregationType === FindingAggregation.Package
  ) {
    return (
      <RowStack justifyContent="space-between" width="100%">
        <PackageVersionNameDisplay
          packageVersion={{ meta: { name: title } }}
          showVersion
          size="medium"
        />

        <FindingCountArrayDisplay hideEmptyValues value={findingCountArray} />
      </RowStack>
    );
  }

  if (aggregationType === FindingAggregation.Finding) {
    return (
      <FindingNameDisplay
        finding={{
          meta: { name: title },
          spec: {
            level: level ?? SpecFindingLevel.Unspecified,
          } as V1FindingSpec,
        }}
        showCategories
        size="medium"
      />
    );
  }

  if (aggregationType === FindingAggregation.FindingAndDependency)
    return (
      <RowStack justifyContent="space-between" width="100%">
        <Typography variant="h6">{title}</Typography>

        <FindingCountArrayDisplay hideEmptyValues value={findingCountArray} />
      </RowStack>
    );
};
