import { Column } from '@tanstack/react-table';

import { DataTableRowData } from '../types';

export const getColWidthSx = <T extends DataTableRowData>(
  column: Column<T, unknown>,
  extraPixels = 0
) => {
  const { getSize } = column;
  const { minSize, maxSize } = column.columnDef;

  return {
    maxWidth: maxSize ? maxSize + extraPixels : undefined,
    minWidth: minSize ?? undefined,
    width: getSize() + extraPixels,
  };
};
