import { head as _head } from 'lodash-es';
import create from 'zustand';

import { AppNotification, AppNotificationSeverity } from './types';

const SEVERITY_PRIORITY: AppNotificationSeverity[] = [
  'info',
  'success',
  'warning',
  'error',
];

/**
 * Inserts the given notification before notifications with lower severity, but
 * after other notifications with the same severity.
 */
const insertNotification = (
  queue: readonly AppNotification[],
  notification: AppNotification
) => {
  // don't insert a duplicate notification
  if (notification.id && queue.some((n) => n.id === notification.id)) {
    return queue;
  }

  const priority = SEVERITY_PRIORITY.indexOf(notification.severity);

  // handle edge cases
  if (!queue.length || priority === -1) return [...queue, notification];

  let ix = 0;
  while (ix < queue.length) {
    const priorityOther = SEVERITY_PRIORITY.indexOf(queue[ix].severity);
    if (priority > priorityOther) break;
    ix++;
  }

  const shallowClone = queue.slice();
  shallowClone.splice(ix, 0, notification);
  return shallowClone;
};

type AppNotificationStore = {
  notifications: readonly AppNotification[];
  addNotification: (notification: AppNotification) => void;
  peekNotification: () => AppNotification | undefined;
  popNotification: () => AppNotification | undefined;
  resetNotifications: () => void;
};

export const useAppNotificationStore = create<AppNotificationStore>(
  (setState, getState) => {
    return {
      notifications: [],
      addNotification: (notification: AppNotification) =>
        setState((state) => ({
          ...state,
          notifications: insertNotification(state.notifications, notification),
        })),
      peekNotification: () => _head(getState().notifications),
      popNotification: () => {
        const notification = _head(getState().notifications);
        setState((state) => ({
          ...state,
          notifications: state.notifications.slice(1),
        }));
        return notification;
      },
      resetNotifications: () => {
        // if there are no notifications, don't update state
        if (getState().notifications.length === 0) return;

        setState((state) => ({
          ...state,
          notifications: [],
        }));
      },
    };
  }
);
