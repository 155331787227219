import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { ForwardedRef, forwardRef, memo } from 'react';

/**
 * Wraps an image as an SvgIcon, allowing consistent size and layout between
 * images and svg icons.
 *
 * Based on {@see createSvgIcon}
 */
export default function createImgIcon(imageUrl: string, displayName: string) {
  function Component(props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) {
    return (
      <SvgIcon data-testid={`${displayName}ImgIcon`} ref={ref} {...props}>
        <image href={imageUrl} x="0" y="0" width="100%" height="100%" />
      </SvgIcon>
    );
  }

  if (process.env.NODE_ENV !== 'production') {
    // Need to set `displayName` on the inner component for React.memo.
    Component.displayName = `${displayName}ImgIcon`;
  }

  return memo(forwardRef(Component));
}
