import { FormattedMessage as FM } from 'react-intl';

import { OSSExplorerPageSource } from '../../domains/OSS';
import { getOSSExplorerPath } from '../../routes/utils';

const buildTabRecords = () => {
  return [
    {
      label: <FM defaultMessage="Droid GPT" />,
      to: getOSSExplorerPath({ section: OSSExplorerPageSource.DroidGPT }),
      value: OSSExplorerPageSource.DroidGPT,
    },
    {
      label: <FM defaultMessage="Open Source Packages" />,
      to: getOSSExplorerPath({ section: OSSExplorerPageSource.OSS }),
      value: OSSExplorerPageSource.OSS,
    },
    {
      label: <FM defaultMessage="Hugging Face" />,
      to: getOSSExplorerPath({ section: OSSExplorerPageSource.HuggingFace }),
      value: OSSExplorerPageSource.HuggingFace,
    },
  ];
};

export const useOSSExplorerPageTabs = ({
  activeTab: targetActiveTab,
}: {
  activeTab: OSSExplorerPageSource;
}) => {
  const tabRecords = buildTabRecords();

  const activeTab =
    tabRecords.find((r) => r.value === targetActiveTab)?.value ??
    OSSExplorerPageSource.OSS;

  return { activeTab, tabRecords };
};
