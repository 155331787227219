import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { MouseEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  FindingCountDisplayProps,
  UIEventUtils,
  useLatestCallback,
} from '@endorlabs/ui-common';

import { ScanResultScannedByDisplay } from '../../ScanResult';

export type ProjectVersionsTableRow = {
  name: string;
  link: string;
  findingCounts: FindingCountDisplayProps[];
  latestScanResult?: ScanResultResource;
};

const PROJECT_VERSIONS_TABLE_COLUMNS: DataTableColumnDef<ProjectVersionsTableRow>[] =
  [
    {
      accessorKey: 'name',
      colType: ColTypes.TEXT_LONG,
      header: () => <FM defaultMessage="Name" />,
    },
    {
      accessorKey: 'findingCounts',
      colType: ColTypes.FINDING_COUNTS,
    },
    {
      accessorKey: 'latestScanResult',
      cell: ({ getValue }) => (
        <ScanResultScannedByDisplay scanResult={getValue()} showScanTime />
      ),
      header: () => <FM defaultMessage="Last Scanned" />,
    },
  ];

export interface ProjectVersionsTableProps
  extends Omit<
    DataTableProps<ProjectVersionsTableRow>,
    'columns' | 'onRowClick'
  > {
  onClickDetail?: (row?: ProjectVersionsTableRow) => void;
}

export const ProjectVersionsTable = ({
  onClickDetail,
  ...props
}: ProjectVersionsTableProps) => {
  const navigate = useNavigate();
  const handleRowClick = useLatestCallback(
    (
      row: ProjectVersionsTableRow,
      _: Row<ProjectVersionsTableRow>,
      evt: MouseEvent
    ) => {
      if (onClickDetail) {
        return onClickDetail(row);
      }

      if (row.link) {
        UIEventUtils.simulateLinkClick(row.link, evt, navigate);
      }
    }
  );

  return (
    <DataTable
      {...props}
      columns={PROJECT_VERSIONS_TABLE_COLUMNS}
      onRowClick={handleRowClick}
    />
  );
};
