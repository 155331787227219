import { Theme } from '@mui/material';

export const getGlobalStyles = ({ typography, palette }: Theme) => {
  return {
    fieldset: {
      border: 0,
      padding: 0,
    },

    pre: {
      fontFamily: typography.code.fontFamily,
      fontSize: typography.code.fontSize,
      fontWeight: typography.code.fontWeight,
      lineHeight: typography.code.lineHeight,
    },
  };
};
