import { FormattedMessage as FM } from 'react-intl';

import { Pip } from '@endorlabs/ui-common';

interface FindingExceptionStateDisplayProps {
  hasExceptions: boolean;
}

export const FindingExceptionStateDisplay = ({
  hasExceptions,
}: FindingExceptionStateDisplayProps) => {
  return hasExceptions ? (
    <Pip color="info" label={<FM defaultMessage="Exception" />} />
  ) : undefined;
};
