import {
  CardContent,
  Grid,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { FullHeightCard, IconCheck, useStyles } from '@endorlabs/ui-common';
import mascotImage from '@endorlabs/ui-common/assets/mascot/mascot_greeting.png';

import { productHighlights, trialHighlights } from '../locales';
import { MarketingHeading } from './AuthHelperComponents';

const trialHighlightList = [
  trialHighlights.thirtyDays,
  trialHighlights.creditCard,
  trialHighlights.invite,
];

const productHighlightList = [
  productHighlights.openSource,
  productHighlights.trust,
  productHighlights.compliance,
];

export const SignupHighlightsCard = () => {
  const theme = useTheme();
  const { palette, space } = theme;
  const sx = useStyles(styles);

  return (
    <FullHeightCard elevation={24} sx={sx}>
      <CardContent>
        <Stack spacing={space.md}>
          <MarketingHeading
            text={<FM defaultMessage="Start Your Trial" />}
          ></MarketingHeading>

          <Grid
            alignItems="center"
            className="trialHighlights"
            container
            marginTop={0}
            spacing={space.md}
          >
            <Grid item xs={3} justifyContent="center">
              <img
                alt="Endor Labs mascot waving hello"
                height="100%"
                src={mascotImage}
                style={{ maxHeight: '120px' }}
              />
            </Grid>

            <Grid alignItems="flex-end" item xs={9}>
              <Stack spacing={space.xs}>
                {trialHighlightList.map((highlight, i) => {
                  return (
                    <Stack
                      alignItems="center"
                      direction="row"
                      key={i}
                      spacing={space.xs}
                    >
                      <IconCheck htmlColor={palette.success.main} />
                      <Typography component="span" variant="h3">
                        <FM {...highlight} />
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={space.md}>
            {productHighlightList.map((highlight, i) => {
              return (
                <Stack key={i} spacing={1}>
                  <Typography color="brand.main" variant="h2">
                    <FM {...highlight.heading} />
                  </Typography>

                  <Typography className="highlightBody">
                    <FM {...highlight.body} />
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </CardContent>
    </FullHeightCard>
  );
};

const styles = ({ typography }: Theme) => ({
  '.heading': { fontSize: '2.25rem' },
  '.trialHighlights': { marginTop: 0 },
  '.highlightBody': { fontSize: typography.h3.fontSize },
});
