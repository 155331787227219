import { LoadingButton } from '@mui/lab';
import { Grid, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Tenant } from '@endorlabs/api_client';
import { IQueryErrorResponse, useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonLinkSecondary,
  ControlledTextField,
  REGEX_NAMESPACE_VALIDATION,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../routes';

interface ServerFieldError {
  field: string;
  object: { type: string; message: string };
}

const translateServerError = (
  error: IQueryErrorResponse
): ServerFieldError & { isAuthFailure: boolean } => {
  const statusCode = error.status;

  // Handle tenant creation failure due to auth failure
  if (statusCode === 401 || statusCode === 403) {
    let errorMessage =
      error.data?.message ??
      'Unable to create a tenant. Please try again or return to sign in.';

    // Override with custom error message for free-trial sign-up:
    if (errorMessage.includes('provide a business email to create a tenant')) {
      errorMessage =
        'A business email address for your account is required to create a tenant. Please return and sign up for an account with a business email address.';
    }

    return {
      isAuthFailure: true,
      field: 'meta.name',
      object: {
        type: 'server',
        message: errorMessage,
      },
    };
  }

  // Handle tenant creation failure due to existing tenant
  if (statusCode === 409) {
    return {
      isAuthFailure: false,
      field: 'meta.name',
      object: {
        type: 'server',
        message:
          'A tenant with this name already exists. Please select another.',
      },
    };
  }

  // Handle rate limit response
  if (statusCode === 429) {
    return {
      isAuthFailure: false,
      field: 'meta.name',
      object: {
        type: 'server',
        message:
          'Rate limit exceed for creating Tenant. Please try again later.',
      },
    };
  }

  const errorMessage = error.data?.message ?? 'An unknown error occurred';

  return {
    isAuthFailure: false,
    field: 'meta.name',
    object: {
      type: 'server',
      message: errorMessage,
    },
  };
};

interface FormNewTenantProps {
  isLoading?: boolean;
  onSubmit: (data: Partial<V1Tenant>) => void;
  serverErrorResponse?: IQueryErrorResponse;
  showSubmitButton?: boolean;
}

// TODO: Generic component for react-hook-form controlled inputs
export const FormNewTenant = ({
  isLoading,
  onSubmit,
  serverErrorResponse,
  showSubmitButton = true,
}: FormNewTenantProps) => {
  const { control, handleSubmit, setError } = useForm();
  const { formatMessage: fm } = useIntl();

  // Get field error from server response
  const fieldError = useMemo(() => {
    return serverErrorResponse
      ? translateServerError(serverErrorResponse)
      : undefined;
  }, [serverErrorResponse]);

  useEffect(() => {
    if (fieldError) {
      setError(fieldError.field, fieldError.object);
    }
  }, [fieldError, setError]);

  return (
    <form id="FormNewTenant" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue=""
            label={fm({ defaultMessage: 'Tenant Name' })}
            helperText={fm({
              defaultMessage:
                'Names must be 32 characters or less and may contain only lowercase letters (a-z), numbers (0-9) and the following characters (_ -)',
            })}
            name="meta.name"
            placeholder={fm({
              defaultMessage: 'Enter your tenant name',
            })}
            sx={{ width: '95%' }}
            required
            rules={{
              required: fm({ defaultMessage: 'This is a required field' }),
              minLength: {
                value: 1,
                message: fm({
                  defaultMessage: 'A non-empty value is required',
                }),
              },
              maxLength: {
                value: 32,
                message: fm({
                  defaultMessage: 'The value must be 32 characters or less',
                }),
              },
              pattern: {
                value: REGEX_NAMESPACE_VALIDATION,
                message: fm({
                  defaultMessage:
                    '32 characters maximum. Only lowercase letters, numbers, hyphen and underscores allowed.',
                }),
              },
            }}
          />
        </Grid>

        {showSubmitButton && (
          <Grid item>
            <Stack direction="row" spacing={2}>
              {
                // Replace form submit button with link button to the signup/login page
                // if the previous attempt was an auth failure, as the current authenticated
                // user is not authorized to onboard a tenant.
                !fieldError?.isAuthFailure && (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    loading={isLoading}
                    type="submit"
                  >
                    <FM defaultMessage="Get Started" />
                  </LoadingButton>
                )
              }

              {fieldError?.isAuthFailure && (
                <ButtonLinkSecondary
                  linkProps={{
                    to: NamedRoutes.SIGNUP,
                    replace: false,
                  }}
                >
                  <FM defaultMessage="Return to Sign Up" />
                </ButtonLinkSecondary>
              )}
            </Stack>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
