import { Alert, AlertTitle, Stack } from '@mui/material';
import { chunk as _chunk, uniq as _uniq } from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { QueryClient, useQueryClient } from 'react-query';

import {
  DependencyMetadataReachabilityType,
  V1Ecosystem,
  V1ScoreCategory,
} from '@endorlabs/api_client';
import {
  getLicenseMetricLicenseInfoValues,
  LicenseInfoValues,
  MetricResource,
} from '@endorlabs/endor-core/Metric';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  DependencyMetadataResource,
  isQueryError,
  listAllDependencyMetadataQueryOptions,
  listMetricsQueryOptions,
  listProjectsQueryOptions,
  PackageVersionResource,
  selectMetricScoresByCategory,
  useUserPreferences,
} from '@endorlabs/queries';
import { UIPackageVersionUtils, useFileDownload } from '@endorlabs/ui-common';
import { WithRequired } from '@endorlabs/utils';
import * as CSV from '@endorlabs/utils/encoding/csv';

import {
  ExportResourceColumn,
  FormExportResource,
  FormExportResourceFieldValues,
} from '../../../../components';
import { DependenciesExportDialogProps } from './types';

type DependencyExportColumn = {
  isDirect?: boolean;
  ecosystem: V1Ecosystem;
  importing: {
    packageVersion?: PackageVersionResource;
    project?: ProjectResource;
  };
  licenseInfo: LicenseInfoValues;
  name: string;
  reachability?: DependencyMetadataReachabilityType;
  tags: string[];
  uuid: string;
  version: string;
  scores: Partial<Record<V1ScoreCategory, number>>;
  copyrights: string[];
};

type DependencyExportColumnPaths =
  | keyof DependencyExportColumn
  | `licenseInfo.${keyof LicenseInfoValues}`
  | `importing.packageVersion.meta.name`
  | `importing.packageVersion.uuid`
  | `importing.project.meta.name`
  | `importing.project.uuid`
  | `scores.${V1ScoreCategory}`;

const DEPENDENCY_EXPORT_COLUMNS = (
  [
    // Dependency Columns
    { isDefault: true, key: 'uuid', label: 'UUID' },
    { key: 'ecosystem', label: 'Ecosystem' },
    { isDefault: true, key: 'name', label: 'Name' },
    { isDefault: true, key: 'version', label: 'Version' },
    { key: 'tags', label: 'Tags' },
    { key: 'reachability', label: 'Reachability' },
    { key: 'isDirect', label: 'Is Direct' },
    // Dependency License Columns
    { isGroupHeader: true, key: 'licenseInfo', label: 'License' },
    {
      key: 'licenseInfo.files',
      label: 'License File',
    },
    {
      key: 'licenseInfo.matchedTexts',
      label: 'License Matched Text',
    },
    {
      isDefault: true,
      key: 'licenseInfo.names',
      label: 'License Name',
    },
    {
      key: 'licenseInfo.types',
      label: 'License Type',
    },
    {
      key: 'licenseInfo.urls',
      label: 'License URL',
    },
    {
      key: 'licenseInfo.spdxids',
      label: 'License SPDX ID',
    },
    {
      key: 'licenseInfo.fileLocations',
      label: 'License File Locations',
    },
    // Copyright Information
    {
      key: 'copyrights',
      label: 'Copyright Information',
    },
    // Dependency Score Columns
    { isGroupHeader: true, key: 'scores', label: 'Endor Scores' },
    {
      key: 'scores.SCORE_CATEGORY_SECURITY',
      label: 'Endor Security Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_ACTIVITY',
      label: 'Endor Activity Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_POPULARITY',
      label: 'Endor Popularity Score',
    },
    {
      key: 'scores.SCORE_CATEGORY_CODE_QUALITY',
      label: 'Endor Quality Score',
    },
    // Importing Columns
    {
      isGroupHeader: true,
      key: 'importing',
      label: 'Importing',
    },
    {
      key: 'importing.project.uuid',
      label: 'Project UUID',
    },
    {
      isDefault: true,
      key: 'importing.project.meta.name',
      label: 'Project Name',
    },
    {
      key: 'importing.packageVersion.uuid',
      label: 'Package Version UUID',
    },
    {
      isDefault: true,
      key: 'importing.packageVersion.meta.name',
      label: 'Package Version Name',
    },
  ] satisfies ExportResourceColumn<DependencyExportColumnPaths>[]
).map((column) => {
  const groupKey = column.key.split('.')[0];
  if (column.key === groupKey) return column;
  return { ...column, groupKey };
});

type ExportResult = {
  dependencies: DependencyMetadataResource[];
  importingProjects?: ProjectResource[];
  fileStatMetrics?: MetricResource[];
  licenseMetrics?: MetricResource[];
  scoreMetrics?: MetricResource[];
};

const loadDependencyExportData = async (
  client: QueryClient,
  options: { namespace: string; filter: string; traverse?: boolean },
  selectedColumns: ExportResourceColumn<string>[]
) => {
  const { filter, namespace, traverse } = options;

  const dependencies = await client.fetchQuery({
    ...listAllDependencyMetadataQueryOptions(namespace, {
      filter,
      traverse,
      mask: [
        'context',
        'meta',
        'spec.dependency_data.direct',
        'spec.dependency_data.namespace',
        'spec.dependency_data.project_uuid',
        'spec.dependency_data.package_version_uuid',
        'spec.dependency_data.reachable',
        'spec.importer_data.project_uuid',
        'spec.importer_data.package_version_name',
        'spec.importer_data.package_version_uuid',
        'spec.importer_data.package_version_ref',
        'tenant_meta',
        'uuid',
      ].join(','),
      page_size: 500,
    }),
    retry: false,
  });

  /**
   * Importing Project UUIDs, grouped by namespace
   */
  const importingProjectParams: Record<string, string[]> = {};

  /**
   * Dependency Project and Package Version UUIDs, grouped by namespace
   */
  const dependencyParams: Record<
    string,
    { packageVersionUuids: string[]; projectUuids: string[] }
  > = {};

  dependencies.forEach((d) => {
    const namespace = d.spec.dependency_data?.namespace;
    const packageVersionUuid = d.spec.dependency_data?.package_version_uuid;
    const projectUuid = d.spec.dependency_data?.package_version_uuid;

    const importingNamespace = d.tenant_meta.namespace;
    const importingProjectUuid = d.spec.importer_data?.project_uuid;

    if (importingProjectUuid) {
      if (!importingProjectParams[importingNamespace]) {
        importingProjectParams[importingNamespace] = [];
      }

      importingProjectParams[importingNamespace].push(importingProjectUuid);
    }

    if (namespace) {
      if (!dependencyParams[namespace]) {
        dependencyParams[namespace] = {
          packageVersionUuids: [],
          projectUuids: [],
        };
      }

      if (packageVersionUuid) {
        dependencyParams[namespace].packageVersionUuids.push(
          packageVersionUuid
        );
      }

      if (projectUuid) {
        dependencyParams[namespace].projectUuids.push(projectUuid);
      }
    }
  });

  const result: ExportResult = { dependencies };

  // Skip fetching importing projects if not selected in the export
  if (selectedColumns.some((c) => c.key.startsWith('importing.project'))) {
    result.importingProjects = [];

    const pages = Object.entries(importingProjectParams).map(
      ([namespace, projectUuids]) => ({
        namespace,
        projectUuids: _uniq(projectUuids).sort(),
      })
    );

    for (const { namespace, projectUuids } of pages) {
      const pageData = await client
        .fetchQuery(
          listProjectsQueryOptions(namespace, {
            filter: `uuid in ["${projectUuids.join('","')}"]`,
          })
        )
        .then((r) => r.list?.objects ?? []);

      result.importingProjects.push(...pageData);
    }
  }

  // Skip fetching score metrics if not selected in the export
  if (selectedColumns.some((c) => c.groupKey === 'scores')) {
    result.scoreMetrics = [];

    const pages = Object.entries(dependencyParams).flatMap(
      ([namespace, params]) => {
        const uuids = _uniq(params.packageVersionUuids).sort();

        return _chunk(uuids, 100).map((batch) => ({
          namespace,
          packageVersionUuids: batch,
        }));
      }
    );

    for (const { namespace, packageVersionUuids } of pages) {
      const pageData = await client
        .fetchQuery(
          listMetricsQueryOptions(namespace, {
            filter: filterExpressionBuilders.and([
              `meta.name==package_version_scorecard`,
              `meta.parent_uuid in ["${packageVersionUuids.join('","')}"]`,
            ]),
            mask: 'meta.name,meta.parent_uuid,spec.metric_values',
          })
        )
        .then((r) => r.list?.objects ?? []);
      result.scoreMetrics.push(...pageData);
    }
  }

  // Skip fetching license metrics if not selected in the export
  if (selectedColumns.some((c) => c.groupKey === 'licenseInfo')) {
    result.licenseMetrics = [];

    const pages = Object.entries(dependencyParams).flatMap(
      ([namespace, params]) => {
        const uuids = _uniq(params.packageVersionUuids).sort();
        return _chunk(uuids, 100).map((batch) => ({
          namespace,
          packageVersionUuids: batch,
        }));
      }
    );

    for (const { namespace, packageVersionUuids } of pages) {
      const pageData = await client
        .fetchQuery(
          listMetricsQueryOptions(namespace, {
            filter: filterExpressionBuilders.and([
              `meta.name==pkg_version_info_for_license`,
              `meta.parent_uuid in ["${packageVersionUuids.join('","')}"]`,
            ]),
            mask: 'meta.name,meta.parent_uuid,spec.metric_values',
          })
        )
        .then((r) => r.list?.objects ?? []);
      result.licenseMetrics.push(...pageData);
    }
  }

  // Skip fetching copyright metrics if not selected in the export
  if (selectedColumns.some((c) => c.key === 'copyrights')) {
    result.fileStatMetrics = [];

    const pages = Object.entries(dependencyParams).flatMap(
      ([namespace, params]) => {
        const uuids = _uniq(params.projectUuids).sort();
        return _chunk(uuids, 100).map((batch) => ({
          namespace,
          projectUuids: batch,
        }));
      }
    );

    for (const { namespace, projectUuids } of pages) {
      const pageData = await client
        .fetchQuery(
          listMetricsQueryOptions(namespace, {
            filter: filterExpressionBuilders.and([
              `meta.name==repo_stats_for_file`,
              `spec.project_uuid in ["${projectUuids.join('","')}"]`,
            ]),
            mask: 'meta.name,meta.parent_uuid,spec.metric_values',
          })
        )
        .then((r) => r.list?.objects ?? []);
      result.fileStatMetrics.push(...pageData);
    }
  }

  return result;
};

const mapDependencyExportDataToColumns = ({
  dependencies,
  importingProjects,
  fileStatMetrics,
  licenseMetrics,
  scoreMetrics,
}: ExportResult): DependencyExportColumn[] => {
  return dependencies
    .map((dep) => {
      const {
        ecosystem,
        label: dependencyPackageName,
        version: dependencyPackageVersionRef,
      } = UIPackageVersionUtils.parsePackageName(dep.meta.name);

      const importingProject = importingProjects?.find(
        (p) => p.uuid === dep.spec.importer_data?.project_uuid
      );

      const importingPackageVersion = {
        meta: { name: dep.spec.importer_data?.package_version_name },
        uuid: dep.spec.importer_data?.package_version_uuid,
      } as PackageVersionResource;

      const licenseMetric = licenseMetrics?.find(
        (m) =>
          m.meta.parent_uuid === dep.spec.dependency_data?.package_version_uuid
      );
      const licenseInfo = getLicenseMetricLicenseInfoValues(licenseMetric);

      const scoreMetric = scoreMetrics?.find(
        (m) =>
          m.meta.parent_uuid === dep.spec.dependency_data?.package_version_uuid
      );
      const metricScores = selectMetricScoresByCategory(scoreMetric);

      const fileStatMetric = fileStatMetrics?.find(
        (m) => m.spec.project_uuid === dep.spec.dependency_data?.project_uuid
      );
      const copyrights =
        fileStatMetric?.spec.metric_values.fileStats?.file_stats?.copyrights ??
        [];

      return {
        ecosystem,
        isDirect: dep.spec.dependency_data?.direct === true,
        licenseInfo,
        name: dependencyPackageName,
        importing: {
          packageVersion: importingPackageVersion,
          project: importingProject,
        },
        reachability: dep.spec.dependency_data?.reachable,
        tags: dep.meta.tags ?? [],
        uuid: dep.uuid,
        version: dependencyPackageVersionRef,
        scores: metricScores,
        copyrights,
      } satisfies DependencyExportColumn;
    })
    .sort((a, b) => {
      // Sort dependencies before export by:
      // - name, ascending
      // - version, ascending by semver
      return (
        a.name.localeCompare(b.name) ||
        UIPackageVersionUtils.sortBySemanticVersion(a.version, b.version)
      );
    });
};

export const DependenciesExportDialogContent = (
  props: WithRequired<DependenciesExportDialogProps, 'state'>
) => {
  const {
    state: { downloadProps, filter, namespace },
    onClose,
  } = props;

  const isCancelled = useRef(false);
  const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
  const [exportErrorMessage, setExportErrorMessage] = useState<string | null>(
    null
  );

  const queryClient = useQueryClient();
  const authPreferences = useUserPreferences((s) => s.auth);

  const [_, downloadData] = useFileDownload({
    filetype: 'csv',
    filename: downloadProps?.filename ?? 'dependencies-export.csv',
  });

  const handleExportDependencies = useCallback(
    (values: FormExportResourceFieldValues) => {
      setExportErrorMessage(null);

      const selectedColumns = values.columns;
      if (!selectedColumns.length) {
        // TODO: handle edge case
        return;
      }

      setIsDownloadingExportData(true);

      loadDependencyExportData(
        queryClient,
        {
          namespace,
          filter,
          traverse: authPreferences?.includeChildNamespaces,
        },
        selectedColumns
      )
        .then((result) => {
          // Exit without download if cancelled
          if (isCancelled.current) return;

          const dependencies = mapDependencyExportDataToColumns(result);

          // convert to CSV
          const output = CSV.stringify(dependencies, null, {
            headers: selectedColumns,
          });

          downloadData(output);
        })
        .catch((error) => {
          // Handle error
          if (isQueryError(error) && error.response.data?.message) {
            setExportErrorMessage(error.response.data.message);
            return;
          }

          if (error.message) {
            setExportErrorMessage(error.message);
            return;
          }

          setExportErrorMessage(error.toString());
        })
        .finally(() => {
          setIsDownloadingExportData(false);
        });
    },
    [
      authPreferences?.includeChildNamespaces,
      downloadData,
      filter,
      namespace,
      queryClient,
    ]
  );

  const handleCancel = useCallback(() => {
    isCancelled.current = true;

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Stack spacing={4}>
      <FormExportResource
        columns={DEPENDENCY_EXPORT_COLUMNS}
        enableGrouping
        isLoading={isDownloadingExportData}
        onSubmit={handleExportDependencies}
        onCancel={handleCancel}
      />

      {exportErrorMessage && (
        <Alert severity="error">
          <AlertTitle>
            <FM defaultMessage="Unable to Export Dependencies" />
          </AlertTitle>

          {exportErrorMessage}
        </Alert>
      )}
    </Stack>
  );
};
