export const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000;
export const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;
export const FIVE_MINUTES_IN_MILLISECONDS = 5 * ONE_MINUTE_IN_MILLISECONDS;
export const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * ONE_MINUTE_IN_MILLISECONDS;
export const ONE_HOUR_IN_MILLISECONDS = 4 * FIFTEEN_MINUTES_IN_MILLISECONDS;

export const StaleTimes = {
  REDUCED: TEN_SECONDS_IN_MILLISECONDS,
  SHORT: ONE_MINUTE_IN_MILLISECONDS,
  MEDIUM: FIVE_MINUTES_IN_MILLISECONDS,
  LONG: FIFTEEN_MINUTES_IN_MILLISECONDS,
  EXTENDED: ONE_HOUR_IN_MILLISECONDS,
};
