import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import _isNumber from 'lodash-es/isNumber';
import { ElementType, useMemo } from 'react';
import { FormattedMessage as FM, FormattedNumber } from 'react-intl';

import { IconCheckCircle } from '@endorlabs/ui-common';

import {
  OnboardingApproach,
  useOnboardingSteps,
} from '../../domains/Onboarding';
import { OnboardingStepMessages } from '../../domains/Onboarding/locales';
import { OnboardingAccordionCard } from './OnboardingAccordionCard';

/**
 * This is a generic component that can be used for any set of onboarding steps (approach)
 */
export const OnboardingStepsView = ({
  approach,
}: {
  approach: OnboardingApproach;
}) => {
  const { palette, space, typography } = useTheme();

  const {
    completeStep,
    getIsStepComplete,
    getOnboardingSteps,
    getNextStepId,
    uncompleteStep,
  } = useOnboardingSteps();

  const steps = getOnboardingSteps(approach);
  const nextStepId = getNextStepId(approach);

  const onboardingSteps = useMemo(() => {
    return steps.map((onboardStep, stepNum) => {
      const isComplete = getIsStepComplete(onboardStep.id);

      const { ContentComponent, id, completionTimeInMinutes } = onboardStep;

      // Find the content component type for this approach
      let RenderedContentComponent: ElementType;
      if (typeof ContentComponent === 'object') {
        RenderedContentComponent = ContentComponent[approach];
      } else {
        RenderedContentComponent = ContentComponent;
      }

      const estTime = _isNumber(completionTimeInMinutes)
        ? completionTimeInMinutes
        : completionTimeInMinutes[approach];

      const action = (
        <Stack alignItems="center" direction="row" spacing={space.sm}>
          <Typography color="textSecondary">
            <FormattedNumber
              style="unit"
              value={estTime}
              unit="minute"
              unitDisplay="long"
            />
          </Typography>

          {/**
           * WARNING: For dev/debugging, this make it simple to complete/uncomplete a step.
           * Be careful not to check it in uncommented.
           */}
          {/* <Button
            onClick={() => (isComplete ? uncompleteStep(id) : completeStep(id))}
            size="small"
          >
            {isComplete ? 'UNCOMPLETE' : 'COMPLETE'}
          </Button> */}
        </Stack>
      );

      const numberCircle = (
        <Box
          sx={{
            alignItems: 'center',
            border: '2px solid black',
            borderRadius: '50%',
            display: 'flex',
            height: '20px',
            fontSize: typography.body2.fontSize,
            fontWeight: 700,
            justifyContent: 'center',
            width: '20px',
          }}
        >
          {stepNum + 1}
        </Box>
      );

      // Create a generic title node using specific step messaging
      const title = (
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <Stack alignItems="center" direction="row" spacing={2}>
            {isComplete ? <IconCheckCircle color="primary" /> : numberCircle}
            <Typography variant="h3">
              <FM {...OnboardingStepMessages[id]?.title} />
            </Typography>
          </Stack>
        </Stack>
      );

      return {
        ...onboardStep,
        action,
        content: (
          <RenderedContentComponent
            approach={approach}
            stepRecord={onboardStep}
          />
        ),
        title,
      };
    });
  }, [approach, getIsStepComplete, typography, space, steps]);

  // backgroundColor:
  // t.id === highlightStep
  //   ? 'hsla(180deg 12.5% 93.73%)'
  //   : palette.background.paper,

  return (
    <Stack rowGap={space.md}>
      {onboardingSteps.map((onboardStep) => {
        const { action, content, id, title } = onboardStep;

        return (
          <OnboardingAccordionCard
            action={action}
            expanded={id === nextStepId}
            key={id}
            stepId={id}
            title={title}
          >
            <Box paddingX={9}>{content}</Box>
          </OnboardingAccordionCard>
        );
      })}
    </Stack>
  );
};
