import {
  castArray as _castArray,
  get as _get,
  isNil as _isNil,
  stubTrue as _stubTrue,
} from 'lodash-es';

import { FILTER_COMPARATORS, FilterComparator } from './comparator';
import { ResourceFilter, ValueFilter } from './types';

const simpleCompare = (a: number | string, b: number | string): -1 | 0 | 1 => {
  // allow for non-strict equality
  if (a == b) return 0;

  return a > b ? 1 : -1;
};

export type FilterCompare = (
  targetValue: any,
  filterValue: any,
  options?: ApplyFilterOptions
) => boolean;

const FILTER_COMPARE_FUNCTIONS: Record<FilterComparator, FilterCompare> = {
  [FILTER_COMPARATORS.EQUAL]: (targetValue, filterValue, options) => {
    if (options?.strictEqual) return targetValue === filterValue;
    return simpleCompare(targetValue, filterValue) === 0;
  },
  [FILTER_COMPARATORS.NOT_EQUAL]: (a, b) => simpleCompare(a, b) !== 0,
  [FILTER_COMPARATORS.MATCHES]: (targetValue, filterValue, options) => {
    const filterStringValue = filterValue.toString();
    const targetStringValue = targetValue.toString();

    if (options?.caseSensitive)
      return targetStringValue.includes(filterStringValue);

    return targetStringValue
      .toLowerCase()
      .includes(filterStringValue.toLowerCase());
  },
  [FILTER_COMPARATORS.GREATER]: (targetValue, filterValue) =>
    simpleCompare(targetValue, filterValue) > 0,
  [FILTER_COMPARATORS.GREATER_OR_EQUAL]: (targetValue, filterValue) =>
    simpleCompare(targetValue, filterValue) >= 0,
  [FILTER_COMPARATORS.LESSER]: (targetValue, filterValue) =>
    simpleCompare(targetValue, filterValue) < 0,
  [FILTER_COMPARATORS.LESSER_OR_EQUAL]: (targetValue, filterValue) =>
    simpleCompare(targetValue, filterValue) <= 0,
  [FILTER_COMPARATORS.IN]: (targetValue: string, filterValue: string[]) =>
    _castArray(filterValue).includes(targetValue),
  [FILTER_COMPARATORS.NOT_IN]: (targetValue: string, filterValue: string[]) =>
    !_castArray(filterValue).includes(targetValue),
  [FILTER_COMPARATORS.CONTAINS]: (
    targetValue: string[],
    filterValue: string | string[]
  ) => _castArray(filterValue).some((v) => _castArray(targetValue).includes(v)),
  [FILTER_COMPARATORS.CONTAINS_ALL]: (
    targetValue: string[],
    filterValue: string | string[]
  ) =>
    _castArray(filterValue).every((v) => _castArray(targetValue).includes(v)),
  [FILTER_COMPARATORS.NOT_CONTAINS]: (
    targetValue: string[],
    filterValue: string | string[]
  ) =>
    _castArray(filterValue).every((v) => !_castArray(targetValue).includes(v)),
  [FILTER_COMPARATORS.EXISTS]: (targetValue) => !_isNil(targetValue),
  [FILTER_COMPARATORS.NOT_EXISTS]: (targetValue) => _isNil(targetValue),
  [FILTER_COMPARATORS.WITHIN_RANGE]: (
    targetValue: number,
    filterValue: number[]
  ) => {
    const [min, max] = _castArray(filterValue);
    return targetValue > min && targetValue <= max;
  },
};

export const getFilterComparatorCompareFunction = (
  comparator: FilterComparator
): FilterCompare => {
  if (Reflect.has(FILTER_COMPARE_FUNCTIONS, comparator)) {
    return FILTER_COMPARE_FUNCTIONS[comparator];
  }

  if (process.env.NODE_ENV !== 'production') {
    return () => {
      throw new Error(
        'Compare function not implemented for comparator: ' + comparator
      );
    };
  }

  return _stubTrue;
};

type ApplyFilterOptions = {
  /**
   * Should treat string matches as case sensitive
   */
  caseSensitive?: boolean;
  /**
   * Should compare values with strict equality
   */
  strictEqual?: boolean;
};

/**
 * Support client-side filtering for data with the given filters
 */
export function applyFilters<TData extends object = any>(
  filters: (ValueFilter | ResourceFilter)[],
  data: TData[],
  options: ApplyFilterOptions = {}
): TData[] {
  if (!filters.length) return data;

  return data.filter((item) => {
    return filters.every((filter) => {
      const targetValue = _get(item, filter.key);
      const compareFn = getFilterComparatorCompareFunction(filter.comparator);

      try {
        return compareFn(targetValue, filter.value, options);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Failed to compare with filter value');

        // default behavior: include items values when failing to filter
        return true;
      }
    });
  });
}
