import { FormattedMessage as FM } from 'react-intl';

import { getEnv } from '../../constants';
import { BrandedLink } from './BrandedLink';
import { LinkProps } from './types';
const { URL_ENDOR_MARKETING } = getEnv();

export const EndorMarketingLink = ({
  text = <FM defaultMessage="Contact Us" />,
  to = URL_ENDOR_MARKETING,
}: LinkProps) => {
  return (
    <BrandedLink target="_blank" to={to}>
      {text}
    </BrandedLink>
  );
};
