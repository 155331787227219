import React, { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { UpsertInstallationFields } from './FormUpsertInstallation';

// Define a context type
type FormContextType = UseFormReturn<UpsertInstallationFields> | null;

// Create the context with an initial null value
export const FormUpsertContext = createContext<FormContextType>(null);

// Helper hook to consume the context easily
export const useFormUpsertContext = () => {
  const context = useContext(FormUpsertContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
