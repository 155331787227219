import { Box, useTheme } from '@mui/material';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Policy } from '@endorlabs/api_client';
import {
  FlexList,
  FlexListItem,
  IconArrowOut,
  Link,
  ProjectNameDisplayV2,
  RowStack,
  TagsDisplay,
} from '@endorlabs/ui-common';

import { InfoDrawerContent } from '../../../components/InfoDrawer';
import { useAuthInfo } from '../../../providers';
import { getProjectPath } from '../../../routes';
import { usePoliciesAndRelatedProjects } from '../hooks';

export interface PolicyProjectsDrawerProps {
  policies: V1Policy[];
}

/**
 * TODO:
 * - Add search + pagination for affected projects
 */
export const PolicyProjectsDrawer = ({
  policies = [],
}: PolicyProjectsDrawerProps) => {
  const { formatMessage: fm } = useIntl();
  const { space, spacing } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const { getProjectsForAllPolicies, getProjectsForPolicy } =
    usePoliciesAndRelatedProjects({
      namespace: tenantName,
      policies,
    });

  const projects =
    policies.length === 1
      ? getProjectsForPolicy(policies[0].uuid)
      : getProjectsForAllPolicies();

  return (
    <InfoDrawerContent
      title={
        <FM
          defaultMessage="{projectCount, plural, =0 {# Projects} one {# Project} other {# Projects}} In Scope"
          values={{ projectCount: projects.length }}
        />
      }
    >
      <Box>
        <FlexList>
          {projects.map((project) => {
            return (
              <FlexListItem
                key={project.uuid}
                sx={({ palette }) => ({
                  borderBottom: `1px solid ${palette.divider}`,
                  alignItems: 'center',
                  paddingTop: `${space.xs}`,
                  paddingBottom: `${space.sm}`,
                  display: 'flex',
                  flexDirection: 'row',
                  alignSelf: 'flex-start',
                  justifyContent: 'space-between',
                  width: '100%',
                })}
              >
                <RowStack>
                  <ProjectNameDisplayV2 project={project} />
                  <TagsDisplay tags={project.meta.tags} />
                </RowStack>

                <Link
                  to={getProjectPath({
                    tenantName: project.tenant_meta.namespace,
                    uuid: project.uuid,
                  })}
                  sx={{ alignSelf: 'baseline' }}
                >
                  <IconArrowOut
                    aria-label={fm({
                      defaultMessage: 'View project page',
                    })}
                  />
                </Link>
              </FlexListItem>
            );
          })}
        </FlexList>
      </Box>
    </InfoDrawerContent>
  );
};
