import { List, ListItem, Typography, useTheme } from '@mui/material';

interface CiCdToolPatternListDisplayProps {
  patternList: string[];
  listStyle?: 'disc' | 'circle';
  highlight?: boolean;
  bold?: boolean;
}

export const CiCdToolPatternListDisplay = ({
  patternList,
  listStyle = 'disc',
  highlight = true,
  bold = false,
}: CiCdToolPatternListDisplayProps) => {
  const { palette, spacing, typography } = useTheme();
  return (
    <List
      sx={{
        listStyleType: listStyle,
        listStylePosition: 'inside',
        paddingY: 0,
      }}
    >
      {patternList?.map((fnp, index) => (
        <ListItem key={index} sx={{ display: 'list-item', paddingLeft: 0 }}>
          <Typography
            variant="code"
            paddingX={spacing(2)}
            paddingY={spacing(1)}
            borderRadius=".33rem"
            fontWeight={
              bold ? typography.fontWeightBold : typography.fontWeightRegular
            }
            sx={{
              backgroundColor: highlight
                ? palette.background.default
                : 'transparent',
            }}
          >
            {fnp}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};
