import { Box, Tooltip, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1NotificationTarget } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  IconHelp,
} from '@endorlabs/ui-common';

export interface NotificationTargetsTableRow {
  uuid: string;
  name: string;
  namespace: string;
  description?: string;
  notificationTarget?: V1NotificationTarget;
  policyCount?: number;
  // action specific columns
  hasCustomTemplate?: boolean;
  jiraLabels?: string[];
  jiraProject?: string;
  webhookURL?: string;
}

type NotificationTargetsTableColumnId = keyof NotificationTargetsTableRow;

export type OptionalNotificationTargetCols =
  | NotificationTargetsTableColumnId[]
  | undefined;

export interface NotificationTargetsTableProps
  extends Omit<DataTableProps<NotificationTargetsTableRow>, 'columns'> {
  includeColumns?: NotificationTargetsTableColumnId[];
  onEdit?: (row: NotificationTargetsTableRow) => void;
  onDelete?: (row: NotificationTargetsTableRow) => void;
  onTemplateEdit?: (row: NotificationTargetsTableRow) => void;
  onTemplateRestore?: (row: NotificationTargetsTableRow) => void;
}

const buildNotificationTargetTableColDefs = ({
  includeColumns = [],
  onDelete,
  onEdit,
  onTemplateEdit,
  onTemplateRestore,
}: Pick<
  NotificationTargetsTableProps,
  | 'includeColumns'
  | 'onDelete'
  | 'onEdit'
  | 'onTemplateEdit'
  | 'onTemplateRestore'
>) => {
  const columns: DataTableColumnDef<NotificationTargetsTableRow>[] = [
    {
      accessorKey: 'name',
      cell: ({ row }) => {
        return (
          <>
            <Typography
              sx={({ space }) => ({
                display: 'inline',
                marginRight: space.xs,
                maxWidth: '94%',
                verticalAlign: 'middle',
              })}
            >
              {row.original?.name}
            </Typography>
            {row.original?.description && (
              <Tooltip title={row.original?.description}>
                <IconHelp
                  sx={({ palette }) => ({
                    color: palette.text.secondary,
                    lineHeight: '24px',
                    verticalAlign: 'middle',
                  })}
                />
              </Tooltip>
            )}
          </>
        );
      },
      header: () => <FM defaultMessage="Name" />,
    },
    {
      accessorKey: 'webhookURL',
      colType: 'url',
      header: () => <FM defaultMessage="Webhook URL" />,
      hidden: !includeColumns.includes('webhookURL'),
    },
    {
      accessorKey: 'jiraProject',
      header: () => <FM defaultMessage="Project" />,
      hidden: !includeColumns.includes('jiraProject'),
    },
    {
      accessorKey: 'policyCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Policies" />,
    },
    {
      accessorKey: 'jiraLabels',
      colType: ColTypes.TAGS,
      header: () => <FM defaultMessage="Labels" />,
      hidden: !includeColumns.includes('jiraLabels'),
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'hasCustomTemplate',
      cell: (t) => (
        <Typography>
          {t.getValue() ? (
            <FM defaultMessage="Custom" />
          ) : (
            <FM defaultMessage="Default" />
          )}
        </Typography>
      ),
      header: () => <FM defaultMessage="Template" />,
      hidden: !includeColumns.includes('hasCustomTemplate'),
    },
  ];

  if (onDelete || onEdit) {
    columns.push({
      id: 'actions',
      cell: ({ row: { original } }) => {
        if (!original) return null;

        const actions: DataTableActionDropdownItem[] = [];

        if (onEdit) {
          actions.push({
            label: <FM defaultMessage="Edit Notification Integration" />,
            onClick: () => onEdit(original),
          });
        }

        if (onDelete) {
          actions.push({
            isDestructive: true,
            label: <FM defaultMessage="Delete Notification Integration" />,
            onClick: () => onDelete(original),
          });
        }
        if (onTemplateEdit) {
          actions.push({
            label: <FM defaultMessage="Edit Template" />,
            onClick: () => onTemplateEdit(original),
          });
        }
        if (onTemplateRestore) {
          actions.push({
            isDestructive: true,
            label: <FM defaultMessage="Restore Template to Default" />,
            onClick: () => onTemplateRestore(original),
            disabled: !original.hasCustomTemplate,
          });
        }

        return (
          <Box display="flex" justifyContent="flex-end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
      colType: ColTypes.ACTIONS,
    });
  }

  return columns.filter((col) => !col.hidden);
};

/**
 * Displays a collection of package managers using a given platform
 */
export const NotificationTargetsTable = ({
  includeColumns,
  onDelete,
  onEdit,
  onTemplateEdit,
  onTemplateRestore,
  ...props
}: NotificationTargetsTableProps) => {
  const columns = buildNotificationTargetTableColDefs({
    includeColumns,
    onDelete,
    onEdit,
    onTemplateEdit,
    onTemplateRestore,
  });

  return <DataTable columns={columns} {...props} />;
};
