import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  IconArrowRight,
  Link,
  RowStack,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer/DetailDrawerSection';
import {
  getAssuredPackageVersionPath,
  getProjectDetailPath,
  getRemediationsPath,
} from '../../../routes';
import {
  RemediationRecommendationDisplay,
  RemediationRecommendationType,
  RemediationRisk,
} from '../../Remediations';
import { useFindingDetailData } from '../hooks';

interface FindingRemediationsSectionProps {
  findingUuid?: string;
  namespace: string;
}

export const FindingRemediationsSection = ({
  findingUuid,
  namespace,
}: FindingRemediationsSectionProps) => {
  const { space } = useTheme();

  const {
    finding,
    endorPatchAssuredPackageVersion,
    endorPatchVersionUpgrade,
    project,
    versionUpgrade,
  } = useFindingDetailData({
    findingUuid,
    namespace,
  });

  // Hide the link to remediations if there is no remediation from a UIA VersionUpgrade
  const showRemediationsLink = Boolean(
    versionUpgrade || endorPatchVersionUpgrade
  );

  return (
    <DetailDrawerSection
      title={<FM defaultMessage="Remediation Recommendation" />}
    >
      <Stack gap={space.sm}>
        {endorPatchAssuredPackageVersion && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Patch}
            fromVersion={finding?.spec.target_dependency_version}
            toVersion={
              UIPackageVersionUtils.parsePackageName(
                endorPatchAssuredPackageVersion?.meta.name
              ).version
            }
            packageName={finding?.spec.target_dependency_name}
            remediationRisk={RemediationRisk.Low}
            url={getAssuredPackageVersionPath({
              namespace: endorPatchAssuredPackageVersion.tenant_meta.namespace,
              packageVersionName: endorPatchAssuredPackageVersion.meta.name,
            })}
          />
        )}

        {endorPatchVersionUpgrade && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Patch}
            fromVersion={
              endorPatchVersionUpgrade.spec?.upgrade_info?.from_version
            }
            toVersion={endorPatchVersionUpgrade.spec?.upgrade_info?.to_version}
            packageName={
              endorPatchVersionUpgrade.spec?.upgrade_info
                ?.direct_dependency_package
            }
            remediationRisk={
              endorPatchVersionUpgrade.spec?.upgrade_info?.upgrade_risk
            }
            url={getRemediationsPath({
              tenantName: endorPatchVersionUpgrade.tenant_meta.namespace,
              uuid: endorPatchVersionUpgrade.uuid,
            })}
          />
        )}

        {versionUpgrade && (
          <RemediationRecommendationDisplay
            recommendation={RemediationRecommendationType.Upgrade}
            fromVersion={versionUpgrade.spec?.upgrade_info?.from_version}
            toVersion={versionUpgrade.spec?.upgrade_info?.to_version}
            packageName={
              versionUpgrade.spec?.upgrade_info?.direct_dependency_package
            }
            remediationRisk={versionUpgrade.spec?.upgrade_info?.upgrade_risk}
            url={getRemediationsPath({
              tenantName: versionUpgrade.tenant_meta.namespace,
              uuid: versionUpgrade.uuid,
            })}
          />
        )}

        {project && showRemediationsLink && (
          <Link
            to={getProjectDetailPath({
              tenantName: project.tenant_meta.namespace,
              uuid: project.uuid,
              section: 'remediations',
            })}
            search={{ 'filter.search': finding?.spec.target_dependency_name }}
          >
            <RowStack>
              <FM defaultMessage="View Full Recommendation" />
              <IconArrowRight />
            </RowStack>
          </Link>
        )}
      </Stack>
    </DetailDrawerSection>
  );
};
