import { Chip } from '@mui/material';

import { V1FindingTags } from '@endorlabs/api_client';
import { FindingAttributeValue } from '@endorlabs/endor-core/Finding';

import { FlexList, FlexListItem } from '../../../../components';
import { FindingAttributeLabel } from '../FindingAttributeLabel';

export const FindingAttributesArrayDisplay = ({
  value,
  isGlobalTenant = false,
}: {
  value: FindingAttributeValue[];
  isGlobalTenant?: boolean;
}) => {
  // There is not a reliable way to determine "first party" for oss/global tenants.
  // Hiding the tag when displaying in the UI.
  const visibleAttributes = isGlobalTenant
    ? value.filter((attribute) => attribute !== V1FindingTags.NamespaceInternal)
    : value;

  return (
    <FlexList gap={1}>
      {visibleAttributes.map((value) => (
        <FlexListItem key={value}>
          <Chip label={<FindingAttributeLabel value={value} />} size="small" />
        </FlexListItem>
      ))}
    </FlexList>
  );
};
