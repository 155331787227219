import { defineMessage } from 'react-intl';

import { AuthenticatedRouteGuard } from '../../../components/AuthenticatedRouteGuard';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'CI/CD Tools',
});

export const Route = createFileRoute('/t/:namespace/tools')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  component: () => <AuthenticatedRouteGuard />,
});
