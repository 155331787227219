import { FormattedMessage as FM } from 'react-intl';

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from '@endorlabs/ui-common';

type CustomTemplateRestoreConfirmationDialogProps = Pick<
  ConfirmationDialogProps,
  'open' | 'onCancel' | 'onConfirm'
>;

export const CustomTemplateRestoreConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
}: CustomTemplateRestoreConfirmationDialogProps) => {
  return (
    <ConfirmationDialog
      confirmText={<FM defaultMessage="Restore" />}
      isDestructive={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      titleText={<FM defaultMessage="Restore this template to default?" />}
    />
  );
};
