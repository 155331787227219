import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type UfoProps = {
  color?: string;
  size?: string | number;
};

const Ufo = forwardRef<SVGSVGElement, UfoProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_507_19449)">
          <path
            d="M14.7138 6.27799C18.5846 4.952 21.7017 4.86328 22.4519 6.27799C23.4891 8.23372 19.6102 12.3221 13.7883 15.4096C7.96628 18.4971 2.40584 19.4146 1.36868 17.4589C0.64743 16.0988 2.30351 13.7075 5.33716 11.3386"
            stroke={color}
            strokeWidth="2"
          />
          <path
            d="M5.74641 12.1237C5.24902 11.2223 5.1022 9.79003 5.46604 8.34767C5.82605 6.92048 6.64071 5.66217 7.84607 5.00138C9.0496 4.34159 10.5337 4.33742 11.918 4.80596C13.3164 5.27931 14.4426 6.17832 14.9444 7.08757C15.072 7.31894 15.1164 7.78003 14.5666 8.55373C14.0323 9.3055 13.0585 10.1448 11.7013 10.8889C10.3443 11.6328 8.83045 12.1574 7.60327 12.3305C6.98628 12.4175 6.49999 12.4072 6.16381 12.3313C5.82877 12.2556 5.75874 12.146 5.74641 12.1237Z"
            stroke={color}
            strokeWidth="2"
          />
        </g>
        <defs>
          <clipPath id="clip0_507_19449">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Ufo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Ufo.displayName = 'Ufo';

export default Ufo;
