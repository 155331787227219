import { RESOURCE_ENDPOINTS } from '../constants';
import { ResourceKind } from '../types';

/**
 * For a given resource kind, return the defined service endpoint
 */
export const getEndpointForResource = (kind: ResourceKind): string => {
  if (kind in RESOURCE_ENDPOINTS) {
    return RESOURCE_ENDPOINTS[kind];
  }

  throw new Error('Unexpected Resource Kind: ' + kind);
};

/**
 * For a given service endpoint, return the resource kind, if known
 */
export const getResourceFromEndpoint = (
  endpoint: string
): ResourceKind | undefined => {
  for (const [key, value] of Object.entries(RESOURCE_ENDPOINTS)) {
    if (value === endpoint) {
      return key as ResourceKind;
    }
  }
};
