import { ReactNode } from 'react';

import { FilterExpression } from '@endorlabs/filters';
import { Filter } from '@endorlabs/filters';

export type FilterValidationError = {
  details: string;
  isSyntaxError: boolean;
  message: string;
};

export enum FilterBarMode {
  Advanced = 'FILTER_BAR_MODE_ADVANCED',
  Basic = 'FILTER_BAR_MODE_BASIC',
}

export interface FilterFieldProps<T> {
  onChange: (value?: T) => void;
  value: T;
}

export type FilterFieldConfig<T = unknown> = {
  defaultFilter?: Filter;
  format: (value?: T) => Filter | undefined;
  id: string;
  initialFilter?: Filter;
  parse: (filter: Filter) => T | undefined;
  renderInput: (props: FilterFieldProps<T>) => ReactNode;
};

export type SavedFilter = {
  id: string;
  filter: FilterExpression;
  name: string;
  tags?: string[];
};
