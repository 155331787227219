import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { alpha } from '@mui/system';
import { Navigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import { ButtonLinkPrimary, IconLock } from '@endorlabs/ui-common';
import screenshot from '@endorlabs/ui-common/assets/screenshot-findings-2024-05-02.png';

import { getEnv } from '../../constants';
import { useAuthInfo } from '../../providers';
import { getDashboardPath } from '../../routes';

export const NoLicenseIndexPage = () => {
  const { URL_ENDOR_SALES } = getEnv();

  const isLicensingAndBundlingAllowed = useFeatureFlags(
    (s) => s.ENABLE_LICENSE_AND_BUNDLING
  );
  const { activeNamespace: tenantName } = useAuthInfo();

  const theme = useTheme();
  const { typography, palette } = theme;

  const sx = styles(theme);

  if (!isLicensingAndBundlingAllowed)
    return <Navigate to={getDashboardPath({ tenantName })} />;

  return (
    <Grid container height="100vh" justifyContent="center" alignItems="center">
      {/* Background image */}
      <Card variant="outlined" sx={sx.pageBackground}>
        {/* Overlay */}
        <CardContent sx={sx.pageOverlay}>
          <Grid
            item
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: 'inherit',
              zIndex: 1,
            }}
          >
            <Stack
              direction="row"
              columnGap={1}
              alignItems="center"
              sx={{
                backgroundColor: palette.status.pending,
                color: palette.background.dark,
                width: 'fit-content',
                padding: '1px 4px',
                borderRadius: '4px',
              }}
            >
              <IconLock
                sx={{
                  fontSize: theme.typography.h4,
                  color: palette.background.dark,
                }}
              />
              <Typography variant="subtitle2">
                <FM defaultMessage="License required" />
              </Typography>
            </Stack>
            <Stack direction="column" my={3} alignItems="center">
              <Typography
                color={palette.brand.main}
                variant="xl"
                fontWeight="bold"
              >
                <FM defaultMessage="Endor Labs Code License Required" />
              </Typography>
              <Typography
                fontWeight="regular"
                fontSize={typography.h2.fontSize}
              >
                <FM defaultMessage="Please contact sales to get access to these results." />
              </Typography>
            </Stack>

            <Box>
              <ButtonLinkPrimary
                size="large"
                linkProps={{
                  target: '_blank',
                  to: URL_ENDOR_SALES,
                }}
              >
                <FM defaultMessage="Contact Sales" />
              </ButtonLinkPrimary>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

function styles({ palette }: Theme) {
  return {
    pageBackground: {
      width: '100%',
      height: '85vh',
      backgroundImage: `url(${screenshot})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      zIndex: -1,
      border: `1px solid ${palette.status.pending}`,
    },
    pageOverlay: {
      width: 'inherit',
      height: 'inherit',
      backgroundColor: alpha(`${palette.background.paper}`, 0.737),
      backdropFilter: 'blur(5px)',
    },
  };
}
