import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import { SemgrepRulesResource } from '@endorlabs/endor-core/SemgrepRules';
import {
  FileDownloadButton,
  IconEdit,
  Link,
  RowStack,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

import { DetailDrawerSection } from '../../../../components/DetailDrawer/DetailDrawerSection';
import { useAuthInfo } from '../../../../providers';
import { getPoliciesPath } from '../../../../routes';

export const FindingRuleDrawer = ({
  sastRuleForFinding,
}: {
  sastRuleForFinding: SemgrepRulesResource | undefined;
}) => {
  const { activeNamespace: tenantName } = useAuthInfo();

  const { space } = useTheme();

  const fileName = `${sastRuleForFinding?.spec?.rule?.id ?? 'rule'}.yaml`;
  return (
    <DetailDrawerSection>
      <Stack direction="column">
        <Stack
          gap={space.xs}
          width="100%"
          direction="row"
          justifyContent={'space-between'}
          padding={2}
          alignItems="center"
        >
          <Typography variant="body2">
            {sastRuleForFinding?.spec?.rule?.id ?? 'Rule'}
          </Typography>
          <RowStack gap={space.sm} alignItems="center">
            <FileDownloadButton
              filetype="yaml"
              filename={fileName}
              data={sastRuleForFinding?.spec?.yaml ?? ''}
              size="small"
            />
            {sastRuleForFinding?.uuid && (
              <Link
                to={getPoliciesPath({
                  tenantName,
                  section: `sast/edit-sast-rule/${sastRuleForFinding?.uuid}`,
                })}
              >
                <IconButton>
                  <IconEdit />
                </IconButton>
              </Link>
            )}
          </RowStack>
        </Stack>

        <Box>
          <CodeEditor
            editorOptions={{
              lineNumbers: true,
              lineWrapping: false,
            }}
            enableClipboard={false}
            height="auto"
            language="text/x-yaml"
            value={sastRuleForFinding?.spec?.yaml ?? ''}
            width="100%"
            readOnly={true}
          />
        </Box>
      </Stack>
    </DetailDrawerSection>
  );
};
