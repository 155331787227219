import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Stack,
  TextFieldProps,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form';

import { IconTrash } from '../../themes';
import { RowStack } from '../RowStack';
import { ControlledTextField } from './ControlledTextField';

export type ControlledTextArrayProps<TFieldValues extends FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
  TextFieldProps & {
    addItemText?: ReactNode;
  };

/**
 * Wraps a stack of ControlledTextField components for `string[]`, as an
 * alternative to useFieldArray.
 */
export const ControlledTextFieldArray = <T extends FieldValues>({
  addItemText,
  control,
  defaultValue = [''] as PathValue<T, Path<T>>,
  name,
  label,
  rules,
  ...textFieldProps
}: ControlledTextArrayProps<T>) => {
  const { field, formState, fieldState } = useController({
    control,
    defaultValue,
    // NOTE: `name` prop is used for react-hook-form. Input `name` can be set with `htmlName`.
    name,
    rules,
  });

  const value = field.value ?? [];
  const displayCount = Math.max(1, value?.length ?? 0);

  const handleAdd = () => {
    const next = [...value, ''];
    if (next.length === 1) {
      next.push('');
    }

    field.onChange(next);
    field.onBlur();
  };

  const handleRemove = (index: number) => {
    const next = value.slice(0, index);
    field.onChange(next);
    field.onBlur();
  };

  return (
    <Box>
      {label && (
        <InputLabel required={textFieldProps.required}>{label}</InputLabel>
      )}

      <Stack gap={2}>
        {Array.from({ length: displayCount }, (_, index) => (
          <RowStack key={index} flexWrap="nowrap">
            <ControlledTextField
              control={control}
              defaultValue={defaultValue.at(index) ?? defaultValue.at(0)}
              name={`${name}.${index}` as PathValue<T, Path<T>>}
              {...textFieldProps}
            />

            {displayCount > 1 && index === 0 && <Box width={20} />}

            {index > 0 && (
              <IconButton color="error" onClick={() => handleRemove(index)}>
                <IconTrash />
              </IconButton>
            )}
          </RowStack>
        ))}
      </Stack>

      <Button onClick={() => handleAdd()} sx={{ marginTop: 2 }}>
        {addItemText}
      </Button>
    </Box>
  );
};
