import { has, isMatch, isNil, pick } from 'lodash-es';

import {
  FILTER_COMPARATORS,
  FilterComparator,
  ResourceFilter,
} from '@endorlabs/filters';

import { FacetFilterDefinition } from './types';

export type FacetFilterValueMap = Record<string, any>;

export const buildFacetFilterValueMap = (
  facets: FacetFilterDefinition[],
  filters?: ResourceFilter[]
) => {
  const facetFilterValues: FacetFilterValueMap = {};
  for (const facet of facets ?? []) {
    const facetFilterIdentifier = pick(facet.filter, ['kind', 'key']);
    const filter = filters?.find((f) => isMatch(f, facetFilterIdentifier));

    if (filter && filter.value) {
      facetFilterValues[facet.id] = filter.value;
    }
  }

  return facetFilterValues;
};

const getFacetComparator = (
  facet: FacetFilterDefinition,
  value: any
): FilterComparator => {
  if (facet.comparator) return facet.comparator;

  return Array.isArray(value)
    ? FILTER_COMPARATORS.IN
    : FILTER_COMPARATORS.EQUAL;
};

export const getNextFacetFilters = (
  facetFilterValues: FacetFilterValueMap,
  facets: FacetFilterDefinition[],
  facet: FacetFilterDefinition,
  value: any
): ResourceFilter[] => {
  const nextFilterValues: FacetFilterValueMap = {
    ...facetFilterValues,
    [facet.id]: value,
  };

  const nextFilters: ResourceFilter[] = [];

  for (const facet of facets) {
    if (!has(nextFilterValues, facet.id)) continue;

    const nextFilterValue = nextFilterValues[facet.id];

    // validate value
    if (isNil(nextFilterValue)) continue;
    if (Array.isArray(nextFilterValue) && !nextFilterValue.length) {
      continue;
    }

    // drop 'empty' values from filters
    if (nextFilterValue === '' || nextFilterValue === false) continue;

    // map facet to filter
    nextFilters.push({
      ...facet.filter,
      value: nextFilterValue,
      comparator: getFacetComparator(facet, nextFilterValue),
    } as ResourceFilter);
  }

  return nextFilters;
};
