import { minutesToMilliseconds } from 'date-fns';
import { QueryKey, useQuery } from 'react-query';

import {
  QueryQuerySpec,
  QueryServiceApi,
  V1ListParameters,
  V1PackageVersionMetadata,
  V1Query,
} from '@endorlabs/api_client';

import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration } from './utils';

export type QueryPackageVersionsMetadataResponse =
  TResourceList<V1PackageVersionMetadata>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'package-versions-metadata', namespace, listParams] as const,
};
export const QueryPackageVersionsQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (rootListParams: V1ListParameters): QueryQuerySpec => {
  return {
    kind: 'PackageVersionMetadata',
    list_parameters: {
      ...rootListParams,
    },
  };
};

const buildQuery = (
  namespace: string,
  listParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryPackageVersionsMetadata(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildQuerySpec(listParams),
    },
    tenant_meta: {
      namespace,
    },
  };
};

const queryPackageVersionsMetadata = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryPackageVersionsMetadataResponse;
};

/**
 * Custom query for Package Versions Metadata
 */
export const useQueryPackageVersionsMetadata = (
  namespace: string,
  opts: ResourceQueryOptions<QueryPackageVersionsMetadataResponse> = {},
  listParams: Omit<V1ListParameters, 'mask'> = {}
) => {
  return useQuery(
    QK.query(namespace, listParams),
    ({ signal }) => queryPackageVersionsMetadata(namespace, listParams, signal),
    {
      staleTime: QUERY_STALE_TIME,
      ...opts,
    }
  );
};
