import { useRouter } from '@tanstack/react-location';
import { useEffect } from 'react';

import { SpecEndorLicenseFeatureType } from '@endorlabs/api_client';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';

import { withFilterProvider } from '../../domains/filters';
import {
  REMEDIATIONS_SEARCH_KEYS,
  RemediationsAggregatedView,
} from '../../domains/Remediations';
import { useAuthInfo, useLicensingInfo } from '../../providers';

export interface PackageVersionRemediationsProps {
  tenantName: string;
  packageVersion?: PackageVersionResource;
}

const BasePackageVersionRemediations = ({
  tenantName: namespace,
  packageVersion,
}: PackageVersionRemediationsProps) => {
  const { setLicenseInCurrentRoute } = useAuthInfo();
  const { checkLicensePresent } = useLicensingInfo();
  const router = useRouter();

  const isRemediationLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Recommendation
  );

  useEffect(() => {
    if (!isRemediationLicensePresent) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => setLicenseInCurrentRoute({ pathname: '', isLicense: true });
    }
  }, [
    isRemediationLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  return (
    <RemediationsAggregatedView
      namespace={namespace}
      packageVersion={packageVersion}
    />
  );
};

export const PackageVersionRemediations = withFilterProvider(
  BasePackageVersionRemediations,
  {
    displayName: 'PackageVersionRemediations',
    searchKeys: REMEDIATIONS_SEARCH_KEYS,
  }
);
