import {
  Box,
  Divider,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ToolPatternResource } from '@endorlabs/queries';
import {
  AccordionCard,
  buildCiCdToolPatternLists,
  IconCommand,
  IconFileText,
} from '@endorlabs/ui-common';

import { CiCdToolPatternListDisplay } from './CiCdToolPatternListDisplay';

export const CiCdToolPatternDisplay = ({
  tool,
}: {
  tool?: ToolPatternResource;
}) => {
  const { palette, space } = useTheme();
  const sx = styles({ palette });
  const {
    appPatterns,
    appPatternCount,
    cmdPatterns,
    cmdPatternCount,
    fileAndLinePatternCount,
    fileAndLinePatterns,
    filePatterns,
    githubBadgePatterns,
    webhookPatterns,
  } = useMemo(() => {
    return buildCiCdToolPatternLists(tool);
  }, [tool]);
  return (
    <>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="FilePattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconFileText />
              <FM
                defaultMessage="Filename Patterns {count}"
                values={{
                  count: filePatterns.length,
                }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search patterns applied to file/path name" />
            </Typography>
          }
          disabled={filePatterns.length === 0}
        >
          {filePatterns.length > 0 && (
            <CiCdToolPatternListDisplay patternList={filePatterns} />
          )}
        </AccordionCard>
      </Stack>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="FileAndLinePattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconFileText />
              <FM
                defaultMessage="Filename Patterns with Line Patterns {count}"
                values={{
                  count: fileAndLinePatternCount,
                }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search patterns applied to file/path name and their content" />
            </Typography>
          }
          disabled={fileAndLinePatternCount === 0}
        >
          <Stack divider={<Divider variant="fullWidth" />} gap={space.sm}>
            {fileAndLinePatterns.length > 0 &&
              fileAndLinePatterns.map((flp, index) => (
                <Stack key={index} gap={space.sm}>
                  <Stack>
                    <Typography variant="subtitle2">
                      <FM defaultMessage="Filename Patterns" />
                    </Typography>
                    <CiCdToolPatternListDisplay
                      patternList={flp.filename_patterns ?? []}
                    />
                  </Stack>
                  <Stack>
                    <Typography variant="subtitle2">
                      <FM defaultMessage="Line Patterns" />
                    </Typography>
                    <CiCdToolPatternListDisplay
                      patternList={flp.line_patterns ?? []}
                    />
                  </Stack>
                </Stack>
              ))}
          </Stack>
        </AccordionCard>
      </Stack>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="CmdPattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconCommand />
              <FM
                defaultMessage="Command Patterns {count}"
                values={{ count: cmdPatternCount }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search pattern applied to CI/CD configuration file content" />
            </Typography>
          }
          disabled={cmdPatternCount === 0}
        >
          {!_isEmpty(cmdPatterns) &&
            Object.entries(cmdPatterns).map(([tool, patterns]) => (
              <Stack key={tool} gap={space.xs}>
                <CiCdToolPatternListDisplay
                  patternList={[tool]}
                  highlight={false}
                  bold={true}
                />
                <Box paddingLeft={space.md}>
                  <CiCdToolPatternListDisplay
                    patternList={patterns}
                    listStyle="circle"
                  />
                </Box>
              </Stack>
            ))}
        </AccordionCard>
      </Stack>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="WebhookPattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconCommand />
              <FM
                defaultMessage="Webhook Patterns {count}"
                values={{ count: webhookPatterns.length }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search pattern applied to webhook payload URLs." />
            </Typography>
          }
          disabled={webhookPatterns.length === 0}
        >
          {webhookPatterns.length > 0 && (
            <CiCdToolPatternListDisplay patternList={webhookPatterns} />
          )}
        </AccordionCard>
      </Stack>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="BadgePattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconCommand />
              <FM
                defaultMessage="Github Badge Patterns {count}"
                values={{ count: githubBadgePatterns.length }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search pattern applied to github badge URLs." />
            </Typography>
          }
          disabled={githubBadgePatterns.length === 0}
        >
          {githubBadgePatterns.length > 0 && (
            <CiCdToolPatternListDisplay patternList={githubBadgePatterns} />
          )}
        </AccordionCard>
      </Stack>
      <Stack sx={sx.AccordionContainer}>
        <AccordionCard
          id="AppPattern_Accordion"
          title={
            <Stack alignItems="center" direction="row" gap={space.xs}>
              <IconCommand />
              <FM
                defaultMessage="Github Application Patterns {count}"
                values={{ count: appPatternCount }}
              />
            </Stack>
          }
          summaryContent={
            <Typography color={palette.text.secondary}>
              <FM defaultMessage="Search pattern applied to GitHub Application slug name" />
            </Typography>
          }
          disabled={appPatternCount === 0}
        >
          {!_isEmpty(appPatterns) &&
            Object.entries(appPatterns).map(([appField, patterns]) => (
              <Stack key={appField} gap={space.xs}>
                <CiCdToolPatternListDisplay
                  patternList={[appField]}
                  highlight={false}
                  bold={true}
                />
                <Box paddingLeft={space.md}>
                  <CiCdToolPatternListDisplay
                    patternList={patterns}
                    listStyle="circle"
                  />
                </Box>
              </Stack>
            ))}
        </AccordionCard>
      </Stack>
    </>
  );
};

function styles({ palette }: Pick<Theme, 'palette'>) {
  return {
    AccordionContainer: {
      '& .MuiPaper-root': {
        border: `1px solid ${palette.divider}`,
      },
    },
  };
}
