import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { MakeGenerics, useNavigate } from '@tanstack/react-location';
import { useMemo } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { V1CustomTemplateType } from '@endorlabs/api_client';
import {
  CustomNotificationTemplateTypeKey,
  CustomNotificationTemplateTypeKeys,
  IconExternalLink,
  Link,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  EditNotificationTargetCustomTemplate,
  EditPRCommentsCustomTemplate,
} from '../../domains/Integrations/components';
import { useAuthInfo } from '../../providers';
import {
  getIntegrationsRootPath,
  NamedRoutes,
  useFullMatch,
  usePageTitle,
} from '../../routes';

const customNotificationTemplateTitleMessages =
  defineMessages<CustomNotificationTemplateTypeKey>({
    email: {
      defaultMessage: 'Edit Email Notification Template',
    },
    pr_comments: {
      defaultMessage: 'Edit Pull Request Comment Notification Template',
    },
    slack: {
      defaultMessage: 'Edit Slack Notification Template',
    },
    webhook: {
      defaultMessage: 'Edit Webhook Notification Template',
    },
  });

type CustomNotificationTemplateLocationGenerics = MakeGenerics<{
  Params: {
    templateType: CustomNotificationTemplateTypeKey;
    uuid?: string;
  };
}>;

export const CustomNotificationTemplatePage = () => {
  const navigate = useNavigate();
  const { space, spacing } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { templateType: templateTypeKey, uuid: notificationTargetUuid },
  } = useFullMatch<CustomNotificationTemplateLocationGenerics>();
  usePageTitle();

  const titleMessage = customNotificationTemplateTitleMessages[templateTypeKey];
  const templateType = CustomNotificationTemplateTypeKeys[templateTypeKey];

  const linkToDocs = useMemo(() => {
    switch (templateType) {
      case V1CustomTemplateType.Email:
        return NamedRoutes.DOCS_CUSTOM_TEMPLATE_EMAIL;
      case V1CustomTemplateType.Slack:
        return NamedRoutes.DOCS_CUSTOM_TEMPLATE_SLACK;
      case V1CustomTemplateType.Webhook:
        return NamedRoutes.DOCS_CUSTOM_TEMPLATE_WEBHOOK;
      case V1CustomTemplateType.PrComments:
        return NamedRoutes.DOCS_CUSTOM_TEMPLATE_PR_COMMENT;
      default:
        return undefined;
    }
  }, [templateType]);

  const handleCancel = () => {
    navigate({
      to: getIntegrationsRootPath({ tenantName }),
    });
  };

  return (
    <>
      <Grid container direction="column" spacing={6} height="100%">
        <Grid item>
          <PageHeader
            breadcrumbsLinks={[
              {
                label: <FM defaultMessage="Integrations" />,
                url: getIntegrationsRootPath({ tenantName }),
              },
            ]}
            title={<FM {...titleMessage} />}
          />
        </Grid>

        <Grid item>
          <Card>
            <CardHeader
              sx={{
                paddingTop: spacing(8),
                marginRight: spacing(4),
              }}
              title={
                <Typography component="span" variant="subtitle2">
                  <FM
                    defaultMessage="Create Custom templates using <link>Go Templates</link>"
                    values={{
                      link: () => (
                        <Link
                          href="https://pkg.go.dev/text/template"
                          target="_blank"
                        >
                          Go Templates
                        </Link>
                      ),
                    }}
                  />
                </Typography>
              }
              action={
                linkToDocs && <Link
                  href={linkToDocs}
                  target="_blank"
                  color="primary"
                >
                  <Stack direction="row" alignItems="center" gap={space.xs}>
                    <IconExternalLink fontSize="inherit" />
                    <FM defaultMessage="How-to Guide" />
                  </Stack>
                </Link>
              }
            />
            <CardContent sx={{ overflow: 'hidden' }}>
              {templateType === V1CustomTemplateType.PrComments ? (
                <EditPRCommentsCustomTemplate
                  tenantName={tenantName}
                  onCancel={handleCancel}
                />
              ) : (
                <EditNotificationTargetCustomTemplate
                  templateType={templateType}
                  tenantName={tenantName}
                  notificationTargetUuid={notificationTargetUuid}
                  onCancel={handleCancel}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
