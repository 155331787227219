import { Card, CardContent, CardProps, Grid, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { TitleActionHeader, TitleActionHeaderProps } from './TitleActionHeader';

export interface FullHeightCardProps extends TitleActionHeaderProps {
  elevation?: number;
  title?: ReactNode;
  children: ReactNode;
}

export const FullHeightCard = ({
  action,
  children,
  elevation,
  sx,
  title,
  variant,
}: FullHeightCardProps) => {
  const { space } = useTheme();

  return (
    <Grid container flexWrap="nowrap" direction="column" height="100%">
      {Boolean(title || action) && (
        <Grid flexShrink={0} item marginBottom={space.xs}>
          <TitleActionHeader action={action} title={title} variant={variant} />
        </Grid>
      )}

      <Grid height="100%" item>
        <Card elevation={elevation} sx={{ height: '100%', ...sx }}>
          <CardContent sx={{ height: '100%' }}>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
