import { Grid, List, ListItem, Typography } from '@mui/material';
import _trimEnd from 'lodash-es/trimEnd';
import { ReactNode } from 'react';

import { useFeatureFlags } from '@endorlabs/queries';

import { useFullMatch } from '../../routes/hooks';
import { AppDrawerNavItem } from './AppDrawerNavItem';
import { AppDrawerNavItemV2 } from './AppDrawerNavItemV2';
import { AppDrawerNavItemProps } from './types';

export interface AppDrawerNavSectionProps {
  navItems: AppDrawerNavItemProps[];
  title?: ReactNode;
  appDrawerMinimized?: boolean;
}

export const AppDrawerNavSection = ({
  navItems,
  title,
  appDrawerMinimized,
}: AppDrawerNavSectionProps) => {
  const { pathname } = useFullMatch();

  const { ENABLE_LICENSE_AND_BUNDLING: isLicenseAndBundlingEnabled } =
    useFeatureFlags();

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      sx={{ '&:not(:last-of-type)': { marginBottom: 4 }, overflowX: 'hidden' }}
    >
      <Grid item>
        {title && !appDrawerMinimized && (
          <Typography sx={{ paddingX: 6 }} variant="h4">
            {title}
          </Typography>
        )}
      </Grid>

      <Grid item>
        <List disablePadding>
          {Object.values(navItems).map((item) => {
            const isActive = isNavItemActive(pathname, item.url);
            return (
              <ListItem disableGutters key={item.url} sx={{ p: 0 }}>
                {isLicenseAndBundlingEnabled ? (
                  <AppDrawerNavItemV2
                    appDrawerMinimized={appDrawerMinimized}
                    badgeContent={item.badgeContent}
                    Icon={item.Icon}
                    isActive={isActive}
                    isBeta={item.isBeta}
                    navKey={item.navKey}
                    label={item.label}
                    url={item.url}
                    displayStatus={item.displayStatus}
                  />
                ) : (
                  <AppDrawerNavItem
                    appDrawerMinimized={appDrawerMinimized}
                    badgeContent={item.badgeContent}
                    Icon={item.Icon}
                    isActive={isActive}
                    isBeta={item.isBeta}
                    navKey={item.navKey}
                    label={item.label}
                    url={item.url}
                  />
                )}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

const isNavItemActive = (path: string, navItemUrl: string) => {
  const pathToCheck = _trimEnd(path, '/');
  const itemUrl = _trimEnd(navItemUrl, '/');

  if (itemUrl === '/t/oss') {
    return pathToCheck === itemUrl;
  }

  const ossVersionOfUrl = itemUrl.replace(new RegExp('/t/[^/]+/'), '/t/oss/');
  return Boolean(
    pathToCheck.match(itemUrl) || pathToCheck.match(ossVersionOfUrl)
  );
};
