import _head from 'lodash-es/head';
import { useMemo } from 'react';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageVersionResource,
  useListAllDependencyMetadata,
  useListDependencyMetadata,
  useListMetrics,
  useQueryPackageVersions,
} from '@endorlabs/queries';

import { DependencyDetailOverview } from './DependencyDetailOverview';

interface DependencyDetailOverviewLogicProps {
  namespace: string;
  packageVersion?: PackageVersionResource;
}

export const DependencyDetailOverviewLogic = ({
  namespace,
  packageVersion,
}: DependencyDetailOverviewLogicProps) => {
  const qQueryPackageVersions = useQueryPackageVersions(
    packageVersion?.tenant_meta?.namespace as string,
    {
      enabled: !!packageVersion?.uuid,
    },
    {
      filter: [
        `uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      page_size: 1,
    }
  );

  const packageVersionQueryObject = _head(
    qQueryPackageVersions.data?.list?.objects ?? []
  );

  const findingsByLevelAndReachability =
    packageVersionQueryObject?.meta.references.FindingsGroupByReachability
      ?.group_response?.groups ?? {};

  const metric = _head(
    packageVersionQueryObject?.meta.references.Metric?.list?.objects ?? []
  );
  const scoreCard = metric?.spec.metric_values?.scorecard.score_card;
  const scoreFactors =
    metric?.spec.metric_values?.scorefactor.score_factor_list.score_factors;

  const qListLicenseMetrics = useListMetrics(
    packageVersion?.tenant_meta.namespace ?? '',
    { enabled: !!packageVersion },
    {
      filter: [
        'meta.name==pkg_version_info_for_license',
        `meta.parent_uuid == ${packageVersion?.uuid}`,
        filterExpressionBuilders.relatedResourceContext(packageVersion),
      ].join(' and '),
      mask: 'spec.metric_values',
      page_size: 1,
    }
  );

  // return all licenses found for the dependency package version
  const licenses =
    qListLicenseMetrics.data?.list?.objects[0]?.spec.metric_values
      .licenseInfoType.license_info?.all_licenses;

  const qDependencyMetadata = useListAllDependencyMetadata(
    packageVersion?.tenant_meta.namespace as string,
    {
      filter: [
        `meta.parent_uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      mask: [
        'uuid',
        'spec.dependency_data.direct',
        'spec.importer_data.callgraph_available',
      ].join(','),
    },
    {
      enabled:
        !!packageVersion?.tenant_meta.namespace && !!packageVersion?.uuid,
    }
  );

  const dependencyMetadata = useMemo(() => {
    return qDependencyMetadata.data?.map((o) => o.spec) ?? [];
  }, [qDependencyMetadata.data]);

  const qDependentMetadata = useListDependencyMetadata(
    namespace,
    { enabled: !!packageVersion?.uuid },
    {
      filter: [
        `spec.dependency_data.package_version_uuid==${packageVersion?.uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      group: { aggregation_paths: 'spec.dependency_data.direct' },
    }
  );
  const dependentGroups = qDependentMetadata.data?.group_response?.groups ?? {};

  return (
    <DependencyDetailOverview
      namespace={namespace}
      dependencyMetadata={dependencyMetadata}
      dependentGroups={dependentGroups}
      findingGroups={findingsByLevelAndReachability}
      licenses={licenses}
      packageVersion={packageVersion}
      packageVersionQueryObject={packageVersionQueryObject}
      isMetricLoading={qQueryPackageVersions.isLoading}
      scoreCard={scoreCard}
      scoreFactors={scoreFactors}
    />
  );
};
