import { LoadingButton } from '@mui/lab';
import { Grid, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import produce from 'immer';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { V1SecretRule } from '@endorlabs/api_client';
import { SecretRuleResource } from '@endorlabs/queries';
import {
  ControlledLabelsField,
  ControlledTextField,
  LabelsFieldValidationType,
} from '@endorlabs/ui-common';

import { SecretRuleAdvancedFields } from './SecretRuleAdvancedFields';

// import { fromSecretRuleModel, toSecretRuleModel } from '../utils';

export interface FormUpsertSecretRuleProps {
  isLoading?: boolean;
  isSubmitting?: boolean;
  namespace: string;
  secretRule?: SecretRuleResource;
  onSubmit: (data: V1SecretRule) => void;
}

/**
 * Form used to create or edit an Custom Identity Provider
 *
 * Currently, only manages 'global' permissions for a user
 */
export const FormUpsertSecretRule = ({
  isLoading,
  isSubmitting,
  namespace,
  onSubmit,
  secretRule,
}: FormUpsertSecretRuleProps) => {
  const { space } = useTheme();

  // Set default values for form
  const defaultValues = useMemo(() => {
    return secretRule ? fromSecretRuleModel(secretRule) : {};
  }, [secretRule]);

  const formMethods = useForm<SecretRuleResource>({
    mode: 'onTouched',
    defaultValues,
  });

  const { handleSubmit: hookFormSubmit, reset } = formMethods;

  // reset the form with defaultValues
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, secretRule, reset]);

  const handleSubmit = useCallback(
    (fieldValues: V1SecretRule) => {
      const model = produce(fieldValues, () => {
        const uri = fieldValues.spec.validation?.http_request?.uri;
        if (!uri) {
          delete fieldValues.spec.validation;
        }
        return fieldValues;
      });
      onSubmit(model);
    },
    [onSubmit]
  );

  const isEditing = !!secretRule;
  const isSystem = secretRule?.tenant_meta.namespace === 'system';
  const isWaiting = isLoading || isSubmitting;

  return (
    <FormProvider {...formMethods}>
      <form id="FormUpsertSecretRule" onSubmit={hookFormSubmit(handleSubmit)}>
        <Grid container direction="column" spacing={space.md}>
          <Grid item>
            <ControlledTextField
              control={formMethods.control}
              defaultValue={namespace}
              disabled={isSystem}
              name="tenant_meta.namespace"
              sx={{ display: 'none' }}
              type="hidden"
            />
          </Grid>

          <Grid item>
            <Stack spacing={space.sm}>
              <ControlledTextField
                disabled={isSystem}
                helperText={
                  <FM defaultMessage="Enter a unique rule identifier for your environment, e.g. 'my-rule-id'" />
                }
                control={formMethods.control}
                label={<FM defaultMessage="Rule Identifier" />}
                name="spec.rule_id"
                required
              />

              <ControlledTextField
                disabled={isSystem}
                helperText={
                  <FM defaultMessage="Enter a unique rule name for your environment, e.g. 'My Custom Rule" />
                }
                control={formMethods.control}
                label={<FM defaultMessage="Rule Name" />}
                name="meta.name"
                required
              />

              <ControlledTextField
                control={formMethods.control}
                disabled={isSystem}
                helperText={
                  <FM defaultMessage="Enter a description for your rule" />
                }
                label={<FM defaultMessage="Description" />}
                name="spec.description"
              />

              <ControlledTextField
                control={formMethods.control}
                disabled={isSystem}
                helperText={
                  <FM defaultMessage="Enter a regex to detect this specific type of secret." />
                }
                label={<FM defaultMessage="Detection Rule" />}
                name="spec.regex"
                required
              />
            </Stack>
          </Grid>

          <Grid item>
            <Stack spacing={space.xs}>
              <Typography variant="h3">
                <FM defaultMessage="Secret Validation Rule" />
              </Typography>
              <Typography variant="body2">
                <FM defaultMessage="A secret validation rule is used to validate if the secret is still active." />
              </Typography>
            </Stack>
          </Grid>

          <Grid item>
            <Stack spacing={space.sm}>
              <ControlledTextField
                control={formMethods.control}
                disabled={isSystem}
                label={<FM defaultMessage="Validation URL" />}
                name="spec.validation.http_request.uri"
              />

              {!isSystem && (
                <ControlledTextField
                  control={formMethods.control}
                  defaultValue={HTTP_METHOD_OPTIONS[0]}
                  helperText={
                    <FM defaultMessage="Select the HTTP method used by the secrets provider." />
                  }
                  label={<FM defaultMessage="HTTP Method" />}
                  name="spec.validation.http_request.method"
                  select
                >
                  {HTTP_METHOD_OPTIONS.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              )}

              <Grid
                columnSpacing={space.md}
                container
                sx={{ '& > .MuiGrid-item:first-of-type': { paddingLeft: 0 } }}
                width="80%"
              >
                <Grid item xs={6}>
                  <ControlledLabelsField
                    control={formMethods.control}
                    disabled={isSystem}
                    defaultValue={['200']}
                    label={<FM defaultMessage="Success Response Codes" />}
                    name="spec.validation.http_response.successful_auth_codes"
                    validation={LabelsFieldValidationType.HTTPStatusCode}
                  />
                </Grid>

                <Grid item xs={6}>
                  <ControlledLabelsField
                    control={formMethods.control}
                    defaultValue={['401', '403']}
                    disabled={isSystem}
                    label={<FM defaultMessage="Failure Response Codes" />}
                    name="spec.validation.http_response.failed_auth_codes"
                    validation={LabelsFieldValidationType.HTTPStatusCode}
                  />
                </Grid>
              </Grid>

              <Typography
                color="text.secondary"
                variant="body2"
                sx={{ position: 'relative', top: '-12px' }}
              >
                <FM defaultMessage="Enter HTTP response codes expected for valid and invalid secrets" />
              </Typography>

              {isSystem && (
                <ControlledTextField
                  control={formMethods.control}
                  defaultValue=""
                  disabled
                  helperText={
                    <FM defaultMessage="Select the authorization method used by the secrets provider." />
                  }
                  label={<FM defaultMessage="Authorization Details" />}
                  name="spec.validation.http_request.header.0.key"
                />
              )}

              {!isSystem && (
                <ControlledTextField
                  control={formMethods.control}
                  defaultValue={AUTH_HEADER_OPTIONS[0]}
                  helperText={
                    <FM defaultMessage="Select the authorization method used by the secrets provider." />
                  }
                  label={<FM defaultMessage="Authorization Details" />}
                  name="spec.validation.http_request.header.0.key"
                  select
                >
                  {AUTH_HEADER_OPTIONS.map((header) => (
                    <MenuItem key={header} value={header}>
                      {header}
                    </MenuItem>
                  ))}
                </ControlledTextField>
              )}
            </Stack>
          </Grid>

          {/* Show fields for non-system Secret Rules */}
          {!isSystem && (
            <>
              <Grid item>
                <SecretRuleAdvancedFields />
              </Grid>

              <Grid item>
                <Stack direction="row" spacing={space.xs}>
                  <LoadingButton
                    loading={isWaiting}
                    onClick={hookFormSubmit(handleSubmit)}
                    type="submit"
                    variant="contained"
                  >
                    {!isWaiting && isEditing ? (
                      <FM defaultMessage="Update Rule" />
                    ) : (
                      <FM defaultMessage="Add Rule" />
                    )}
                  </LoadingButton>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
};

const HTTP_METHOD_OPTIONS = ['GET', 'POST'];

const AUTH_HEADER_OPTIONS = [
  'Authorization Header',
  'Bearer Token',
  'Basic Authentication',
];

const fromSecretRuleModel = (model: SecretRuleResource) => {
  return produce(model, (draft) => {
    if (draft.spec.validation === null) draft.spec.validation = undefined;
    if (draft.propagate === null) draft.propagate = false;
  });
};
