import { Chip, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { isEqual } from 'lodash-es';
import { Children as ReactChildren, ReactNode } from 'react';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import { V1Method, V1Permissions } from '@endorlabs/api_client';
import { getResourceFromEndpoint } from '@endorlabs/endor-core';
import { AUTHORIZATION_POLICY_PERMISSIONS_RULE_GLOBAL_KEY } from '@endorlabs/endor-core/AuthorizationPolicy';

import {
  PermissionRoleLabel,
  PermissionRuleMethodLabel,
} from '../../domains/AuthorizationPolicy';
import { ResourceKindDisplay } from '../ResourceKindDisplay';

const getPermissionRuleLabel = (key: string, methods?: V1Method[]) => {
  const labelParts: ReactNode[] = [];

  // handle special cases
  if (isEqual(methods, [V1Method.Read])) {
    labelParts.push(<FM defaultMessage="Read Only" />);
  } else if (isEqual(methods, [V1Method.All])) {
    labelParts.push(<FM defaultMessage="Full Permissions" />);
  } else if (methods) {
    labelParts.push(
      // NOTE: array keys are added below with ReactChildren
      // eslint-disable-next-line react/jsx-key
      ...methods.map((method) => <PermissionRuleMethodLabel method={method} />)
    );
  }

  // NOTE: ensures elements in array are properley keyed
  const keyedLabels = ReactChildren.toArray(labelParts);
  const permissionsList = <FormattedList value={keyedLabels} type="unit" />;

  // if the permission is not global, include in the label
  if (key === AUTHORIZATION_POLICY_PERMISSIONS_RULE_GLOBAL_KEY) {
    return permissionsList;
  }

  // Try to get the resource kind from the endpoint, falling back to the raw key
  const resourceKind = getResourceFromEndpoint(key);
  const resourceKindLabel = resourceKind ? (
    <ResourceKindDisplay value={resourceKind} isPlural />
  ) : (
    key
  );

  return (
    <FM
      defaultMessage="{resourceKind}: {permissionsList}"
      values={{
        resourceKind: resourceKindLabel,
        permissionsList,
      }}
    />
  );
};

export interface PermissionsDisplayProps {
  value: V1Permissions;
  /**
   * (optional) display the permission rules in addition to roles
   */
  hidePermissionRules?: boolean;
  /**
   * The number of items to show before
   */
  overflowThreshold?: number;
}

export const PermissionsDisplay = ({
  value,
  hidePermissionRules = false,
  overflowThreshold = 3,
}: PermissionsDisplayProps) => {
  const { palette } = useTheme();

  const permissionRoles = (value.roles ?? []).map((role, index) => (
    <PermissionRoleLabel key={`${role}_${index}`} role={role} />
  ));

  // Omit permission rules when flag is set
  const permissionRules = hidePermissionRules
    ? []
    : Object.entries(value.rules ?? []).map(([key, method]) =>
        getPermissionRuleLabel(key, method.methods)
      );

  const allPermissions = [...permissionRoles, ...permissionRules];
  const remainingCount = allPermissions.length - overflowThreshold;

  const permissionsDisplay = ReactChildren.toArray(allPermissions);
  const permissionsPreviewDisplay = ReactChildren.toArray(
    allPermissions.slice(0, overflowThreshold)
  );

  if (allPermissions.length <= 3) {
    return (
      <Stack direction="row" gap={1} flexWrap="wrap">
        {permissionsDisplay.map((p, index) => (
          <Chip key={index} label={p} size="small" />
        ))}
      </Stack>
    );
  }

  return (
    <Tooltip
      title={
        <Stack spacing={2}>
          <Typography variant="h4" marginBottom={1}>
            <FM defaultMessage="Permissions" />
          </Typography>

          <Stack direction="row" gap={1} flexWrap="wrap">
            {permissionsDisplay.map((p, index) => (
              <Chip
                key={index}
                label={p}
                size="small"
                sx={{
                  backgroundColor: palette.getContrastText(
                    palette.text.primary
                  ),
                  color: palette.text.primary,
                }}
              />
            ))}
          </Stack>
        </Stack>
      }
    >
      <Stack direction="row" gap={1} flexWrap="wrap">
        {permissionsPreviewDisplay.map((p, index) => (
          <Chip key={index} label={p} size="small" />
        ))}

        <Chip label={`+${remainingCount}`} size="small" />
      </Stack>
    </Tooltip>
  );
};
