import { QueryKey } from 'react-query';

import { getEndpointForResource, ResourceKind } from '@endorlabs/endor-core';
import {
  CountRequestParameters,
  GetRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

export type ResourceQueryKeyBuilders = {
  count: (namespace: string, params?: CountRequestParameters) => QueryKey;
  record: (
    namespace: string,
    uuid: string,
    params?: Omit<GetRequestParameters, 'uuid'>
  ) => QueryKey;
  list: (namespace: string, params?: ListRequestParameters) => QueryKey;
  listAll: (namespace: string, params?: ListAllRequestParameters) => QueryKey;
};

/**
 * Create the Query Key helper methods for a given resource kind
 */
export const createResourceQueryKeyBuilders = (
  kind: ResourceKind,
  options?: { version?: 'v1' }
): ResourceQueryKeyBuilders => {
  const { version = 'v1' } = options ?? {};
  const endpoint = getEndpointForResource(kind);

  return {
    count: (namespace, params = {}) => [
      version,
      endpoint,
      'count',
      namespace,
      params,
    ],
    record: (namespace, uuid, params = {}) => [
      version,
      endpoint,
      'record',
      namespace,
      uuid,
      params,
    ],
    list: (namespace, params = {}) => [
      version,
      endpoint,
      'list',
      namespace,
      params,
    ],
    listAll: (namespace, params = {}) => [
      version,
      endpoint,
      'list-all',
      namespace,
      params,
    ],
  };
};
