import { FormattedMessage as FM } from 'react-intl';

import { getEnv } from '../../constants';
import { AuthenticatedRedirect } from '../components/AuthenticatedRedirect';
import { NamedRoutes } from '../constants';

const { URL_ENDOR_DOCS } = getEnv();
const REDIRECT_TIMEOUT = 3000;

export const Route = {
  path: NamedRoutes.REDIRECT_DOCS,
  element: (
    <AuthenticatedRedirect
      baseUrl={URL_ENDOR_DOCS}
      redirectTimeout={REDIRECT_TIMEOUT}
      stripPrefix={NamedRoutes.REDIRECT_DOCS}
      title={<FM defaultMessage="Endor Labs Docs" />}
    />
  ),
};
