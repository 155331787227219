import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type EnergyLeaf = {
  color?: string;
  size?: string | number;
};

const EnergyLeaf = forwardRef<SVGSVGElement, EnergyLeaf>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M12.5 3C7.7 3 3.5 6.86 3.5 12C3.5 14.12 4.24 16.07 5.47 17.61L3.5 19.59L4.91 21L6.88 19.03C8.43 20.26 10.38 21 12.5 21C14.8 21 17.11 20.12 18.86 18.36C20.62 16.61 21.5 14.3 21.5 12V3H12.5ZM19.5 12C19.5 13.87 18.77 15.63 17.45 16.95C16.13 18.27 14.37 19 12.5 19C8.64 19 5.5 15.86 5.5 12C5.5 10.1 6.24 8.32 7.6 7.01C8.92 5.71 10.66 5 12.5 5H19.5V12Z"
          fill={color}
        />
        <path
          d="M8.96 12.63L13.01 13.03L10.57 16.36C10.46 16.52 10.47 16.74 10.61 16.88C10.76 17.03 11.01 17.04 11.17 16.89L16.33 12.26C16.66 11.96 16.48 11.41 16.03 11.37L11.98 10.97L14.42 7.64C14.53 7.48 14.52 7.26 14.38 7.12C14.23 6.97 13.98 6.96 13.82 7.11L8.66 11.74C8.34 12.04 8.52 12.59 8.96 12.63Z"
          fill={color}
        />
      </svg>
    );
  }
);

EnergyLeaf.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EnergyLeaf.displayName = 'EnergyLeaf';

export default EnergyLeaf;
