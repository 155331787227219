import { useMatch } from '@tanstack/react-location';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';

import { ScanProfileDetailProfileView } from '../../../../../../views/Settings/ScanProfiles';
import { createFileRoute } from '../../../../../utils/tanstack-react-router-shim';

const TenantScanProfileDetailProfileRoute = () => {
  const { data } = useMatch();

  const scanProfile = data.scanProfile as ScanProfileResource;

  return <ScanProfileDetailProfileView scanProfile={scanProfile} />;
};

export const Route = createFileRoute(
  '/t/:namespace/settings/scan-profiles/:scan_profile_uuid/profile'
)({
  component: TenantScanProfileDetailProfileRoute,
});
