import { Skeleton, Stack, StackProps, useTheme } from '@mui/material';
import _groupBy from 'lodash-es/groupBy';
import _sortBy from 'lodash-es/sortBy';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreFactor } from '@endorlabs/api_client';
import {
  EmptyState,
  FlexList,
  FlexListItem,
  RowStack,
  ScoreDisplay,
  TruncatedTextDisplay,
} from '@endorlabs/ui-common';

interface ScoreFactorsOverviewProps {
  scoreFactors?: V1ScoreFactor[];
  textWidth?: number | `${number}%`;
  isLoading?: boolean;
}

export const ScoreFactorsOverview = ({
  scoreFactors,
  textWidth,
  isLoading,
}: ScoreFactorsOverviewProps) => {
  const factors = useMemo(() => {
    const sorted = _sortBy(scoreFactors ?? [], 'score');

    const grouped = _groupBy(sorted, (factor) =>
      (factor?.score ?? 0) > 0 ? 'good' : 'bad'
    );

    return grouped;
  }, [scoreFactors]);

  return (
    <>
      {isLoading && (
        <Stack gap={4}>
          {Array(3)
            .fill(undefined)
            .map((n, i) => (
              <Skeleton height={24} key={i} sx={{ transform: 'none' }} />
            ))}
        </Stack>
      )}
      {!isLoading && scoreFactors && (
        <RowStack flexWrap="wrap" alignItems="flex-start" gap={4}>
          <ScoreFactorsOverviewList
            factors={factors.good}
            textWidth={textWidth}
          />
          <ScoreFactorsOverviewList
            factors={factors.bad}
            textWidth={textWidth}
          />
        </RowStack>
      )}
      {!isLoading && !scoreFactors && (
        <EmptyState
          size="small"
          title={<FM defaultMessage="No factors found" />}
        />
      )}
    </>
  );
};

const ScoreFactorsOverviewList = ({
  factors = [],
  textWidth = '92%',
}: {
  factors: V1ScoreFactor[];
  textWidth?: number | `${number}%`;
}) => {
  const empty = factors.length === 0;
  const { space } = useTheme();

  return (
    <FlexList direction="column" gap={4}>
      {!empty &&
        factors.slice(0, 3).map((factor, i) => {
          return (
            <FlexListItem key={i} sx={{ maxWidth: '100%' }}>
              <Stack direction="row" spacing={space.xs}>
                <ScoreDisplay
                  size="small"
                  value={factor.score}
                  variant="factor"
                />
                <TruncatedTextDisplay
                  end={0}
                  start={0}
                  value={factor.name as string}
                  variant="body2"
                  width={textWidth}
                />
              </Stack>
            </FlexListItem>
          );
        })}
    </FlexList>
  );
};
