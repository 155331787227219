import { ReactNode, Reducer, useCallback, useMemo, useReducer } from 'react';

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from './ConfirmationDialog';

type DialogState<TProps> = {
  isOpen: boolean;
  props?: TProps;
};
type DialogAction<TProps> =
  | { type: 'open'; props?: TProps }
  | { type: 'close' };

const dialogReducer = <T>(
  state: DialogState<T>,
  action: DialogAction<T>
): DialogState<T> => {
  switch (action.type) {
    case 'open':
      return { isOpen: true, props: action.props };
    case 'close':
      return { isOpen: false };
  }

  return state;
};

export interface UseConfirmationDialogProps<TProps>
  extends Pick<
    ConfirmationDialogProps,
    'titleText' | 'confirmText' | 'cancelText' | 'isDestructive'
  > {
  descriptionText?: ReactNode;
  onCancel?: () => void;
  // extends the the onConfirm handler from the dialog to pass props
  onConfirm: (props?: TProps) => void;
}

export function useConfirmationDialog<TProps extends object = never>({
  titleText,
  descriptionText,
  confirmText,
  cancelText,
  isDestructive,
  onCancel,
  onConfirm,
}: UseConfirmationDialogProps<TProps>) {
  const [dialogState, dispatch] = useReducer<
    Reducer<DialogState<TProps>, DialogAction<TProps>>
  >(dialogReducer, {
    isOpen: false,
  });

  const handleOpenDialog = useCallback((props?: TProps) => {
    dispatch({ type: 'open', props });
  }, []);

  const handleCancelDialog = useCallback(() => {
    dispatch({ type: 'close' });
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleConfirmDialog = useCallback(() => {
    onConfirm(dialogState.props);
    dispatch({ type: 'close' });
  }, [onConfirm, dialogState.props]);

  const dialogProps = useMemo(() => {
    const confirmationDialogProps: ConfirmationDialogProps = {
      // handle dialog state from hook
      open: dialogState.isOpen,
      onCancel: handleCancelDialog,
      onConfirm: handleConfirmDialog,
      // pass through props from caller
      titleText,
      confirmText,
      cancelText,
      isDestructive,
      children: descriptionText,
    };

    return confirmationDialogProps;
  }, [
    dialogState.isOpen,
    handleCancelDialog,
    handleConfirmDialog,
    titleText,
    confirmText,
    cancelText,
    isDestructive,
    descriptionText,
  ]);

  return {
    Dialog: ConfirmationDialog,
    dialogProps,
    openDialog: handleOpenDialog,
  };
}
