import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface DataTableHeaderProps {
  actions?: ReactNode;
  title?: ReactNode;
}

export const DataTableHeader = ({ actions, title }: DataTableHeaderProps) => {
  return (
    <Grid
      alignItems="center"
      className="DataTableHeader-root"
      container
      item
      justifyContent="space-between"
      xs={12}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h2">{title}</Typography>
      </Grid>

      <Grid
        alignItems="center"
        container
        item
        justifyContent="flex-end"
        xs={12}
        md={6}
      >
        <Grid item>{actions}</Grid>
      </Grid>
    </Grid>
  );
};
