import { Box, Grid, Stack, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import logo from '@endorlabs/ui-common/assets/logos/logo-endor-primary-grey.svg';
import logoDark from '@endorlabs/ui-common/assets/logos/logo-endor-primary-white.svg';
import mascotImage from '@endorlabs/ui-common/assets/mascot/mascot_greeting.png';

interface AuthContainerProps {
  children: ReactNode;
}

export const AuthContainer: React.FC<AuthContainerProps> = ({ children }) => {
  const theme = useTheme();
  const sx = styles(theme);
  const { formatMessage: fm } = useIntl();

  return (
    <Grid container sx={sx.container}>
      <Grid item xs={12} md={6}>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            height: '100%',
          }}
        >
          <Box sx={sx.logoWrapper}>
            <img
              alt={fm({ defaultMessage: 'Endor Labs' })}
              src={theme.palette.darkMode ? logoDark : logo}
              style={{
                display: 'block',
              }}
              width={125}
            />
          </Box>

          <Box sx={sx.signinDesktop}></Box>
        </Stack>
      </Grid>

      <Grid item xs={12} md={6} p={2}>
        {children}
      </Grid>

      <Grid
        sx={sx.gridSigninMobile}
        item
        xs={12}
        display="flex"
        alignItems="center"
        width="auto"
        justifyContent="center"
      >
        <Box sx={sx.signinMobile}></Box>
      </Grid>
    </Grid>
  );
};

function styles({ breakpoints }: Theme) {
  return {
    container: {
      height: '100%',
      background:
        'radial-gradient(ellipse at bottom right, rgb(0 240 120 / 10%) 0%, rgb(242 242 242 / 10%) 100%)',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '6%',
      [breakpoints.down('md')]: {
        background:
          'radial-gradient(ellipse at center right, rgb(0 240 120 / 10%) 0%, rgb(242 242 242 / 10%) 100%)',
      },
    },
    logoWrapper: {
      top: '50%',
      width: '438px',
      [breakpoints.down('md')]: {
        width: 'auto',
        paddingTop: '50px',
      },
    },
    signinDesktop: {
      backgroundImage: `url(${mascotImage})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      width: '30vw',
      height: '60vh',
      marginY: '2rem',
      marginLeft: '10%',
      [breakpoints.down('md')]: {
        display: 'none',
      },
    },
    gridSigninMobile: {
      [breakpoints.up('md')]: {
        display: 'none',
      },
    },
    signinMobile: {
      display: 'none',
      [breakpoints.down('md')]: {
        display: 'flex',
        backgroundImage: `url(${mascotImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '500px',
        height: '550px',
        alignItems: 'flex-start',
        margin: '5%',
      },
    },
  };
}
