import { PolicyExceptionReason, PolicyPolicyType } from '@endorlabs/api_client';

import { FormUpsertPolicyFieldValues } from '../types';

export const DEFAULT_ADMISSION_POLICY: FormUpsertPolicyFieldValues = {
  spec: {
    admission: { disable_enforcement: false },
    disable: false,
    policy_type: PolicyPolicyType.Admission,
    project_exceptions: [],
    project_selector: [],
    template_values: {},
  },
  meta: {
    description: '',
    name: '',
  },
  propagate: true,
};

export const DEFAULT_FINDING_POLICY: FormUpsertPolicyFieldValues = {
  spec: {
    disable: false,
    policy_type: PolicyPolicyType.UserFinding,
    project_exceptions: [],
    project_selector: [],
    template_values: {},
  },
  meta: {
    description: '',
    name: '',
  },
  propagate: true,
};

export const DEFAULT_EXCEPTION_POLICY: FormUpsertPolicyFieldValues = {
  spec: {
    exception: {
      reason: PolicyExceptionReason.InTriage,
    },
    disable: false,
    policy_type: PolicyPolicyType.Exception,
    project_exceptions: [],
    project_selector: [],
    template_values: {},
  },
  meta: {
    description: '',
    name: '',
  },
  propagate: true,
};

export const DEFAULT_REMEDIATION_POLICY: FormUpsertPolicyFieldValues = {
  spec: {
    admission: { disable_enforcement: true },
    disable: false,
    policy_type: PolicyPolicyType.Remediation,
    project_exceptions: [],
    project_selector: [],
    template_values: {},
  },
  meta: {
    description: '',
    name: '',
  },
  propagate: true,
};

export const DEFAULT_EXCEPTION_POLICY_TEMPLATE =
  'Custom Exception Finding Attributes';
