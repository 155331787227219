import { getDefaultRepositoryVersion } from './getDefaultRepositoryVersion';
import { parseProjectName } from './parseProjectName';
import {
  getProjectFindingFilterExpression,
  getProjectRelatedFilterExpression,
} from './projectFilterExpressions';
import { getPlatformIcon } from './projectPlatform';
import {
  getLastAnalyticScanTime,
  getLastCodeScanTime,
} from './projectScanTime';

export const UIProjectUtils = {
  getDefaultRepositoryVersion,
  getLastAnalyticScanTime,
  getLastCodeScanTime,
  getPlatformIcon,
  getProjectFindingFilterExpressions: getProjectFindingFilterExpression,
  getProjectRelatedFilterExpressions: getProjectRelatedFilterExpression,
  parseProjectName,
};
