import { useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1Ecosystem,
  V1PlatformSource,
  V1RepositoryVersion,
} from '@endorlabs/api_client';
import { isContainerProject } from '@endorlabs/endor-core/Project';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import {
  ProjectResource,
  QueryProjectsResponseObject,
} from '@endorlabs/queries';
import {
  DataTableColumnDef,
  DataTableColumnHeader,
  DataTableColumnTypeKeys as ColTypes,
  EcosystemIconDisplay,
  FindingCountDisplayProps,
  IconTitleDisplay,
  ImgIconLogoMarkDocker,
  NumberDisplay,
  RowStack,
  ScanResultStatusIndicator,
} from '@endorlabs/ui-common';
import { UIProjectUtils } from '@endorlabs/ui-common';

import { ScanResultScannedByDisplay } from '../../../domains/ScanResult';
import { getProjectPath } from '../../../routes';

export interface ProjectsTableRow {
  defaultRepositoryVersion?: V1RepositoryVersion;
  findingCounts?: FindingCountDisplayProps[];
  link: string;
  name: string;
  namespace: string;
  platformSource: V1PlatformSource;
  packagesList?: V1Ecosystem[];
  packagesCount?: number;
  project: ProjectResource | QueryProjectsResponseObject;
  scanResults: ScanResultResource[];
  tags?: string[];
  uuid: string;
}

export const buildProjectsTableColumns =
  (): DataTableColumnDef<ProjectsTableRow>[] => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { palette } = useTheme();

    return [
      {
        accessorKey: 'scanResults',
        cell: ({ getValue }) => (
          <ScanResultStatusIndicator scanResults={getValue()} />
        ),
        colType: ColTypes.STATUS_INDICATOR,
      },
      {
        accessorKey: 'name',
        cell: (t) => {
          const { project, packagesList, namespace, uuid } = t.row.original;
          const projectLink = getProjectPath({
            tenantName: namespace,
            uuid: uuid,
          });

          return (
            <IconTitleDisplay
              iconFrame
              size="large"
              Icon={
                isContainerProject(project, packagesList)
                  ? ImgIconLogoMarkDocker
                  : UIProjectUtils.getPlatformIcon(project.spec.platform_source)
              }
              title={UIProjectUtils.parseProjectName(
                project.meta.name,
                project.spec.platform_source
              )}
              url={projectLink}
              namespace={namespace}
            />
          );
        },
        colType: ColTypes.PROJECT,
        enableSorting: true,
        header: () => <FM defaultMessage="Project" />,
      },
      {
        accessorKey: 'findingCounts',
        colType: ColTypes.FINDING_COUNTS,
        header: () => <FM defaultMessage="Findings" />,
      },
      {
        accessorKey: 'tags',
        colType: ColTypes.TAGS,
      },
      {
        accessorKey: 'packagesCount',
        colType: ColTypes.PACKAGES_COUNT,
        cell: ({ row }) => {
          const packagesList = row.original?.packagesList ?? [];
          const packagesCount = row.original?.packagesCount;
          const multiplePackages =
            packagesCount !== undefined && packagesCount > 1;
          const packagesPresent =
            packagesCount !== undefined && packagesCount > 0;

          return (
            <RowStack justifyContent="center">
              {packagesPresent ? (
                <NumberDisplay value={packagesCount} />
              ) : (
                <NumberDisplay
                  value={0}
                  typographyProps={{ color: palette.text.secondary }}
                />
              )}
              {packagesPresent && (
                <EcosystemIconDisplay
                  ecosystem={packagesList}
                  displayDefault="package"
                  multiplePackages={multiplePackages}
                />
              )}
            </RowStack>
          );
        },
        header: () => <FM defaultMessage="Packages" />,
      },
      {
        id: 'processingStatus',
        colType: ColTypes.TEXT,
        cell: (t) => (
          <ScanResultScannedByDisplay
            scanResult={t.row.original.scanResults[0]}
            showScanTime
          />
        ),
        header: () => (
          <DataTableColumnHeader
            label={<FM defaultMessage="Last Scanned" />}
            helpTooltip={
              <FM defaultMessage="How long ago was the default branch scanned?" />
            }
          />
        ),
        minSize: 170,
      },
    ];
  };
