import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';

import { EcosystemMessages } from '../locales';

interface EcosystemLabelProps {
  value?: V1Ecosystem;
}

export const EcosystemLabel = ({
  value = V1Ecosystem.Unspecified,
}: EcosystemLabelProps) => <FM {...EcosystemMessages[value]} />;
