import { Checkbox, ListItemText, MenuItem, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { FormattedList } from 'react-intl';

import { IconChevronDown } from '../../themes';
import {
  ControlledTextField,
  ControlledTextFieldProps,
} from './ControlledTextField';

export type ControlledTextFieldSelectProps<TFieldValues extends FieldValues> =
  Omit<
    ControlledTextFieldProps<TFieldValues>,
    'control' | 'select' | 'SelectProps'
  > & {
    options: {
      label: ReactNode;
      value: string;
    }[];
  };

/**
 * Wraps ControlledTextField with common overrides for display as select input
 */
export const ControlledTextFieldSelect = <T extends FieldValues>({
  defaultValue = [] as PathValue<T, Path<T>>,
  name,
  options,
  placeholder,
  ...textFieldProps
}: ControlledTextFieldSelectProps<T>) => {
  const { control, watch } = useFormContext<T>();
  const selectedValues = (watch(name) ?? []) as string[];

  return (
    <ControlledTextField
      control={control}
      defaultValue={defaultValue}
      name={name}
      {...textFieldProps}
      select
      SelectProps={{
        displayEmpty: true,
        IconComponent: IconChevronDown,
        multiple: true,
        renderValue: (value) =>
          Array.isArray(value) && value.length ? (
            <FormattedList
              type="unit"
              value={value
                .slice()
                .sort()
                .map((value) => options.find((o) => o.value === value)?.label)}
            />
          ) : (
            <Typography component="span" color="text.secondary">
              {placeholder}
            </Typography>
          ),
      }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={selectedValues.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </ControlledTextField>
  );
};
