import { Card, CardContent, Grid, Stack, useTheme } from '@mui/material';
import { MakeGenerics } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { V1Method, V1RequestStatus } from '@endorlabs/api_client';
import {
  useCreateApproveServiceRequest,
  useQueryServiceRequests,
} from '@endorlabs/queries';
import {
  ButtonDestructive,
  ButtonPrimary,
  DateTimeDisplay,
  IconArtifactRegistry,
  useAppNotify,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import {
  parseUserName,
  ServiceRequestForm,
  ServiceRequestStatusDisplay,
} from '../../domains/ServiceRequest';
import { useAuthInfo } from '../../providers';
import { getServiceRequestPath, useFullMatch } from '../../routes';

type ServiceRequestGenerics = MakeGenerics<{
  Params: {
    serviceRequestUuid: string;
  };
}>;

export const ServiceRequestDetail = () => {
  const { activeNamespace: tenantName, checkActivePermission } = useAuthInfo();
  const {
    params: { serviceRequestUuid },
  } = useFullMatch<ServiceRequestGenerics>();

  const addAppNotification = useAppNotify();

  const { space } = useTheme();

  const qApproveServiceRequest = useQueryServiceRequests(tenantName, {
    filter: `uuid=="${serviceRequestUuid}"`,
    mask: [
      'uuid',
      'meta.name',
      'meta.create_time',
      'meta.created_by',
      'meta.update_time',
      'spec.approvers',
      'spec.data',
      'spec.description',
      'spec.method',
      'spec.resource',
      'spec.status',
      'spec.target_namespace',
    ].join(),
  });
  const serviceRequest = qApproveServiceRequest.data?.list?.objects[0];
  const serviceRequestApprove =
    serviceRequest?.meta.references?.ApproveServiceRequest?.list?.objects[0];

  const approver = parseUserName(serviceRequestApprove?.meta.created_by ?? '-');

  const canApproveServiceRequest = checkActivePermission(
    V1Method.Create,
    'ApproveServiceRequest'
  );

  const isServieRequestApprovable =
    qApproveServiceRequest.isSuccess &&
    qApproveServiceRequest.data?.list?.objects[0]?.spec.status ===
      V1RequestStatus.Unspecified;

  const qCreateApproveServiceRequest = useCreateApproveServiceRequest({
    onSuccess: (response) => {
      addAppNotification({
        message:
          response.spec.status === V1RequestStatus.Approved ? (
            <FM defaultMessage="Service Request Approved" />
          ) : (
            <FM defaultMessage="Service Request Denied" />
          ),
        severity: 'success',
      });
      qApproveServiceRequest.refetch();
    },
  });

  const creator = parseUserName(serviceRequest?.meta.created_by ?? '-');

  const createApproveServiceRequest = (status: V1RequestStatus) => {
    if (canApproveServiceRequest && serviceRequestUuid) {
      qCreateApproveServiceRequest.mutate({
        namespace: tenantName,
        resource: {
          meta: { name: `Approve Service Request ${serviceRequestUuid}` },
          spec: { service_request_uuid: serviceRequestUuid, status },
        },
      });
    }
  };

  const status = serviceRequest?.spec.status;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          breadcrumbsLinks={[
            {
              url: getServiceRequestPath({ tenantName }),
              label: <FM defaultMessage="All Service Requests" />,
            },
          ]}
          Icon={IconArtifactRegistry}
          title={<FM defaultMessage="View Service Request" />}
          titleDetails={
            serviceRequest?.spec.status && (
              <ServiceRequestStatusDisplay
                size="medium"
                status={serviceRequest?.spec.status}
              />
            )
          }
          metadata={{
            summary: [
              {
                label: <FM defaultMessage="Created At" />,
                value: (
                  <DateTimeDisplay
                    value={serviceRequest?.meta.create_time as string}
                  />
                ),
              },
              {
                label: <FM defaultMessage="Created By" />,
                value: creator,
              },
              ...(status !== V1RequestStatus.Unspecified
                ? [
                    {
                      label: <FM defaultMessage="Approved At" />,
                      value: (
                        <DateTimeDisplay
                          value={serviceRequest?.meta.update_time as string}
                        />
                      ),
                    },
                    {
                      label: <FM defaultMessage="Approved By" />,
                      value: approver,
                    },
                  ]
                : []),
            ],
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <ServiceRequestForm readOnly defaultValues={serviceRequest} />
            <Stack direction="row" marginTop={space.lg} spacing={space.sm}>
              <ButtonPrimary
                onClick={() =>
                  createApproveServiceRequest(V1RequestStatus.Approved)
                }
                disabled={
                  !canApproveServiceRequest || !isServieRequestApprovable
                }
              >
                Approve
              </ButtonPrimary>
              <ButtonDestructive
                onClick={() =>
                  createApproveServiceRequest(V1RequestStatus.Denied)
                }
                disabled={
                  !canApproveServiceRequest || !isServieRequestApprovable
                }
              >
                Deny
              </ButtonDestructive>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
