import { SxProps, Theme, useTheme } from '@mui/material';
import { useMemo } from 'react';

type StyleFnOptions = Record<string, unknown>;

export type UseStylesFunction<T extends StyleFnOptions> = (
  theme: Theme,
  options?: T
) => SxProps;

export const useStyles = <T extends StyleFnOptions>(
  stylesFn: UseStylesFunction<T>,
  options?: T
) => {
  const theme = useTheme();

  return useMemo(() => {
    return stylesFn(theme, options);
  }, [options, stylesFn, theme]);
};
