import { Components, Theme } from '@mui/material';

type AccordionComponentOverrides = Pick<
  Components<Theme>,
  'MuiAccordion' | 'MuiAccordionDetails' | 'MuiAccordionSummary'
>;

export const getAccordionOverrides = (
  theme: Theme
): AccordionComponentOverrides => {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&::before': { display: 'none' },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
        content: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  };
};
