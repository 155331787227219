/**
 * The context or environment in which the present Packages or Package Versions
 * are queried, grouped and displayed.
 *
 * As a user, I may be viewing Packages in the context of:
 * - Packages I control
 * - Packages I depend on
 * - Packages that depend on me
 *
 * Views and components may render different attributes for a resource,
 * based on this value.
 */
export enum PackageContexture {
  Packages = 'PACKAGE_CONTEXTURE_PACKAGES',
  Dependencies = 'PACKAGE_CONTEXT_DEPENDENCY',
  Dependents = 'PACKAGE_CONTEXT_DEPENDENTS',
  GithubActions = 'PACKAGE_CONTEXT_GITHUB_ACTIONS',
}

export enum PackageSource {
  Packages = 'PACKAGE_SOURCE_PACKAGES',
  GithubActions = 'PACKAGE_SOURCE_GITHUB_ACTIONS',
}
