import { FormattedMessage as FM } from 'react-intl';

import {
  isOperationalFinding,
  isSecurityFinding,
} from '@endorlabs/endor-core/Finding';
import { EmptyState } from '@endorlabs/ui-common';

import { FindingDetailDynamic } from './FindingDetailDynamic';
import { FindingDetailOperational } from './FindingDetailOperational';
import { FindingDetailSecurity } from './FindingDetailSecurity';
import { FindingDetailProps } from './types';

const FindingDetailComponentFallBack = () => {
  return (
    <EmptyState
      title={<FM defaultMessage="We've Encountered a Problem" />}
      description={
        <FM defaultMessage="Unable to display the details for this Finding." />
      }
    ></EmptyState>
  );
};

/**
 * Get the best Finding detail component to display, given the finding categories
 *
 * TODO: refine handling in {@link https://github.com/endorlabs/monorepo/issues/5871}
 */
export const getBestFindingDetailComponent = ({
  finding,
}: FindingDetailProps): ((props: FindingDetailProps) => JSX.Element) => {
  if (isSecurityFinding(finding)) {
    return FindingDetailSecurity;
  }

  if (isOperationalFinding(finding)) {
    return FindingDetailOperational;
  }

  const hasMetadata = !!Object.keys(finding.spec.finding_metadata ?? {}).length;
  if (hasMetadata) {
    return FindingDetailDynamic;
  }

  return FindingDetailComponentFallBack;
};

export const FindingDetail = ({
  exceptionPolicies = [],
  finding,
  project,
  sourcePackageVersion,
  targetDependencyMetadata,
  ...rest
}: FindingDetailProps) => {
  const DetailComponent = getBestFindingDetailComponent({ finding });

  return (
    <DetailComponent
      exceptionPolicies={exceptionPolicies}
      finding={finding}
      project={project}
      sourcePackageVersion={sourcePackageVersion}
      targetDependencyMetadata={targetDependencyMetadata}
      {...rest}
    />
  );
};
