import { defineMessages, MessageDescriptor } from 'react-intl';

import { SpecInvitationState } from '@endorlabs/api_client';

export const InvitationStatusMessages: Record<
  SpecInvitationState,
  MessageDescriptor
> = defineMessages({
  [SpecInvitationState.Accepted]: { defaultMessage: 'Active' },
  [SpecInvitationState.EmailFailure]: { defaultMessage: 'Email failure' },
  [SpecInvitationState.Pending]: { defaultMessage: 'Pending' },
  [SpecInvitationState.Unspecified]: { defaultMessage: 'Unknown' },
});
