import { isNil as _isNil } from 'lodash-es';
import { FormattedDate } from 'react-intl';

import { NilDisplay, NilDisplayProps } from '../NilDisplay';
import { DateTimeComponentProps } from './types';

interface DateDisplayProps extends Partial<DateTimeComponentProps> {
  nilDisplayProps?: NilDisplayProps;
}

/**
 * DateDisplay presents just the date portion of a timestamp in a consistent, internationalized format.
 */
export const DateDisplay = ({ nilDisplayProps, value }: DateDisplayProps) => {
  // handle an nil/empty date
  if (_isNil(value) || value === '') {
    return <NilDisplay variant="text" {...nilDisplayProps} />;
  }

  return <FormattedDate value={value} dateStyle="medium" />;
};
