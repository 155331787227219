import { Stack, SvgIconProps, Typography, useTheme } from '@mui/material';
import { JSXElementConstructor } from 'react';

import { NotificationTargetActionActionType } from '@endorlabs/api_client';
import { NotificationTargetResource } from '@endorlabs/queries';

import {
  IconGitPullRequest,
  IconMail,
  ImgIconLogoMarkJira,
} from '../../../themes/icons';
import { NotificationTargetActionTypeKeys } from '../types';

const NotificationTargetIcons: Partial<
  Record<
    NotificationTargetActionActionType,
    JSXElementConstructor<SvgIconProps>
  >
> = {
  [NotificationTargetActionTypeKeys.email]: IconMail,
  [NotificationTargetActionTypeKeys.jira]: ImgIconLogoMarkJira,
  [NotificationTargetActionTypeKeys.github_pr]: IconGitPullRequest,
};

interface NotificationTargetDisplayProps {
  notificationTarget: NotificationTargetResource;
}

export const NotificationTargetDisplay = ({
  notificationTarget,
}: NotificationTargetDisplayProps) => {
  const { space } = useTheme();
  const Icon =
    NotificationTargetIcons[notificationTarget.spec.action.action_type];

  return (
    <Stack alignItems="center" direction="row" spacing={space.sm}>
      {Icon && <Icon />}
      <Typography>{notificationTarget.meta.name}</Typography>
    </Stack>
  );
};
