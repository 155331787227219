import { FilterStateValues } from '../hooks';
import { FilterFieldConfig } from '../types';

export const getDefaultFilterValues = (
  fields: FilterFieldConfig<unknown>[]
): FilterStateValues => {
  const defaultFilters: FilterStateValues = new Map();

  for (const field of fields) {
    if (field.defaultFilter) {
      defaultFilters.set(field.id, field.defaultFilter);
    }
  }

  return defaultFilters;
};
