import { Box, Grid, Stack, ToggleButton, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import {
  ButtonDestructive,
  ButtonPrimary,
  ButtonSecondary,
  ControlledCodeEditorField,
  ExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import {
  CustomTemplateFieldKeys,
  CustomTemplateFields,
  FormCustomTemplateFieldValues,
} from '../types';

const templateFieldMessages = defineMessages<CustomTemplateFieldKeys>({
  findings_summary_template: {
    defaultMessage: 'Finding Summary',
  },
  open_action_template: {
    defaultMessage: 'Open',
  },
  update_action_template: {
    defaultMessage: 'Update',
  },
  resolve_action_template: {
    defaultMessage: 'Resolve',
  },
});

export type FormUpsertCustomNotificationTemplateProps<
  T extends CustomTemplateFields
> = {
  defaultValues?: T;
  onCancel?: () => void;
  onSubmit?: (values: T) => void;
  values?: T;
};

export const FormUpsertCustomNotificationTemplate = <
  T extends CustomTemplateFields
>({
  defaultValues,
  onCancel,
  onSubmit,
  values,
}: FormUpsertCustomNotificationTemplateProps<T>) => {
  const { space } = useTheme();

  const formMethods = useForm<FormCustomTemplateFieldValues>({
    mode: 'onTouched',
    defaultValues: values,
  });

  const { handleSubmit: hookFormSubmit, reset, formState } = formMethods;

  const { templateFieldToggleOptions } = useMemo(() => {
    const templateFieldNames = Object.keys(
      defaultValues ?? {}
    ) as CustomTemplateFieldKeys[];

    const templateFieldToggleOptions = templateFieldNames.map((fieldName) => {
      return {
        value: fieldName,
        label: <FM {...templateFieldMessages[fieldName]} />,
      };
    });
    return { templateFieldToggleOptions };
  }, [defaultValues]);

  const [selectedField, setSelectedField] = useState(
    templateFieldToggleOptions[0]?.value
  );

  const handleSubmit = (fieldValues: FormCustomTemplateFieldValues) => {
    if (onSubmit) {
      onSubmit(fieldValues as T);
    }
  };

  const handleRestore = () => {
    reset(defaultValues);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  useEffect(() => {
    setSelectedField(templateFieldToggleOptions[0]?.value);
  }, [templateFieldToggleOptions]);

  useEffect(() => {
    reset(values);
  }, [values, reset]);

  return (
    <FormProvider {...formMethods}>
      <form id="FormCustomTemplate" onSubmit={hookFormSubmit(handleSubmit)}>
        <Grid container spacing={space.lg}>
          <Grid item xs={12}>
            <Box sx={{ marginTop: space.sm, position: 'relative' }}>
              {templateFieldToggleOptions.length > 1 && (
                <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
                  <ExclusiveToggleButtonGroup
                    value={selectedField}
                    onChange={(_, value) => setSelectedField(value)}
                  >
                    {templateFieldToggleOptions.map(({ label, value }) => {
                      return (
                        <ToggleButton key={value} value={value}>
                          {label}
                        </ToggleButton>
                      );
                    })}
                  </ExclusiveToggleButtonGroup>
                </Box>
              )}
              {selectedField && (
                <ControlledCodeEditorField
                  key={selectedField}
                  codeEditorProps={{
                    downloadProps: {
                      filename: 'template.go',
                      filetype: 'go',
                    },
                    editorOptions: { lineWrapping: true },
                    language: 'text/x-go',
                    height: 600,
                    width: '100%',
                  }}
                  control={formMethods.control}
                  name={selectedField}
                  required
                />
              )}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={space.sm}>
                <ButtonSecondary type="button" onClick={handleCancel}>
                  <FM defaultMessage="Cancel" />
                </ButtonSecondary>
                <ButtonPrimary type="submit" disabled={!formState.isValid}>
                  <FM defaultMessage="Save Template" />
                </ButtonPrimary>
              </Stack>
              <ButtonDestructive type="button" onClick={handleRestore}>
                <FM defaultMessage="Restore to Default" />
              </ButtonDestructive>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
