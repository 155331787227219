import { Button, CircularProgress, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1DependencyMetadataSpec,
  V1FindingCategory,
  V1GroupResponse,
  V1LicenseInfo,
  V1ScoreCard,
  V1ScoreFactor,
} from '@endorlabs/api_client';
import {
  PackageVersionResource,
  QueryPackageVersionsResponseObject,
} from '@endorlabs/queries';
import { useQueryPackageVersionsMetadata } from '@endorlabs/queries/query_package_versions_metadata';
import {
  EmptyState,
  FindingCategorySelect,
  FullHeightCard,
  IconSidebarRight,
  ResponsiveCardLayout,
  TitleActionHeader,
  usePackageVersionDependencies,
} from '@endorlabs/ui-common';

import {
  FindingRiskMatrixTable,
  ProjectVersionDependencies,
  ProjectVersionLicense,
  ScoreCardDisplay,
  ScoreFactorsOverview,
} from '../../components';
import { useScoreFactorsDrawer } from '../../domains/Metrics';
import { METADATA_STALE_TIME } from '../Projects/constants';
import { DependencyDetailOverviewMetadata } from './DependencyDetailOverviewMetadata';

interface DependencyDetailOverviewProps {
  namespace: string;
  dependencyMetadata: V1DependencyMetadataSpec[];
  dependentGroups: V1GroupResponse;
  findingGroups: V1GroupResponse['groups'];
  licenses?: V1LicenseInfo[];
  packageVersion?: PackageVersionResource;
  packageVersionQueryObject?: QueryPackageVersionsResponseObject;
  isMetricLoading?: boolean;
  scoreCard?: V1ScoreCard;
  scoreFactors?: V1ScoreFactor[];
}

const visHeights = {
  LOW: 200,
  MEDIUM: 330,
  HIGH: 500,
};

export const DependencyDetailOverview = ({
  namespace,
  dependencyMetadata,
  dependentGroups,
  findingGroups,
  licenses,
  packageVersion,
  packageVersionQueryObject,
  isMetricLoading,
  scoreCard,
  scoreFactors,
}: DependencyDetailOverviewProps) => {
  const { space } = useTheme();
  const navigate = useNavigate();

  const { DetailDrawer, permalinkEffect } = useScoreFactorsDrawer();
  useLayoutEffect(() => {
    permalinkEffect();
  }, [packageVersionQueryObject, permalinkEffect]);

  const [activeFindingCategory, setActiveFindingCategory] = useState<
    V1FindingCategory | undefined
  >(undefined);

  // Total Dependencies Circle Graph
  const packageVersionDependencies = usePackageVersionDependencies(
    namespace,
    packageVersion
  );

  const dependenciesPackageNames = useMemo(() => {
    const packageNames: string[] = [];
    packageVersionDependencies.dependencies.forEach((d) => {
      if (d.packageName) {
        packageNames.push(d.packageName);
      }
    });

    return packageNames;
  }, [packageVersionDependencies.dependencies]);

  const hasDependencies = !_isEmpty(packageVersionDependencies.dependencies);

  // Dependency Licenses Graph
  const qPackageVersionsMetadata = useQueryPackageVersionsMetadata(
    'oss',
    {
      enabled: !!dependenciesPackageNames.length,
      staleTime: METADATA_STALE_TIME,
    },
    {
      filter: `meta.name in ["${dependenciesPackageNames.join('","')}"]`,
      group: {
        aggregation_paths: 'meta.name,spec.versions',
      },
    }
  );
  const packageMetadataGroupResponse =
    qPackageVersionsMetadata.data?.group_response;

  const hasLicenses = !_isEmpty(packageMetadataGroupResponse?.groups);

  return (
    <ResponsiveCardLayout>
      <FullHeightCard title={<FM defaultMessage="Scorecard" />}>
        <ScoreCardDisplay scoreCard={scoreCard} isLoading={isMetricLoading} />
      </FullHeightCard>

      <FullHeightCard
        action={
          <Button
            endIcon={<IconSidebarRight />}
            disabled={!packageVersionQueryObject}
            onClick={() => {
              DetailDrawer.activate(
                {
                  resourceNamespace:
                    packageVersionQueryObject?.tenant_meta.namespace,
                  resourceUuid: packageVersionQueryObject?.uuid,
                },
                {
                  resourceNamespace:
                    packageVersionQueryObject?.tenant_meta.namespace,
                  resourceUuid: packageVersionQueryObject?.uuid,
                }
              );
            }}
          >
            <FM defaultMessage="All Score Factors" />
          </Button>
        }
        title={<FM defaultMessage="Score Factors" />}
      >
        <ScoreFactorsOverview scoreFactors={scoreFactors} />
      </FullHeightCard>

      <FullHeightCard
        action={
          <FindingCategorySelect
            activeFindingCategory={activeFindingCategory}
            onChange={(_, findingCategory) =>
              setActiveFindingCategory(findingCategory)
            }
          />
        }
        title={<FM defaultMessage="Finding Risk Matrix" />}
      >
        <FindingRiskMatrixTable
          activeFindingCategory={activeFindingCategory}
          findingGroups={findingGroups}
        />
      </FullHeightCard>

      <FullHeightCard title={<FM defaultMessage="Top Metadata" />}>
        <DependencyDetailOverviewMetadata
          dependencyMetadata={dependencyMetadata}
          dependentGroups={dependentGroups}
          licenses={licenses}
          packageVersion={packageVersionQueryObject}
        />
      </FullHeightCard>

      <FullHeightCard>
        <Stack gap={space.md}>
          <TitleActionHeader
            action={
              <Button
                onClick={() => {
                  navigate({ to: 'dependencies' });
                }}
              >
                <FM defaultMessage="See All Dependencies" />
              </Button>
            }
            title={<FM defaultMessage="Dependency Licenses" />}
            variant="subtitle2"
          />

          {qPackageVersionsMetadata.isLoading && (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {!qPackageVersionsMetadata.isLoading &&
            qPackageVersionsMetadata.isSuccess &&
            hasLicenses && (
              <ProjectVersionLicense
                dependencies={packageVersionDependencies.dependencies}
                packageMetadataGroupResponse={packageMetadataGroupResponse}
                height={visHeights.LOW}
              />
            )}
          {!qPackageVersionsMetadata.isLoading &&
            (!qPackageVersionsMetadata.isSuccess || !hasLicenses) && (
              <EmptyState
                size="small"
                textAlign="center"
                title={
                  <FM defaultMessage="No dependency licenses found in this version" />
                }
              />
            )}
        </Stack>
      </FullHeightCard>

      <FullHeightCard>
        <Stack gap={space.md}>
          <TitleActionHeader
            action={
              <Button onClick={() => navigate({ to: 'dependencies' })}>
                <FM defaultMessage="See All Dependencies" />
              </Button>
            }
            title={<FM defaultMessage="Total Dependencies" />}
            variant="subtitle2"
          />
          {packageVersionDependencies.isLoading && (
            <Stack alignItems="center">
              <CircularProgress />
            </Stack>
          )}
          {!packageVersionDependencies.isLoading &&
            packageVersionDependencies.isSuccess &&
            hasDependencies && (
              <ProjectVersionDependencies
                dependencies={packageVersionDependencies.dependencies}
                height={visHeights.LOW}
              />
            )}
          {!packageVersionDependencies.isLoading &&
            (!packageVersionDependencies.isSuccess || !hasDependencies) && (
              <EmptyState
                size="small"
                textAlign="center"
                title={
                  <FM defaultMessage="No dependencies found in this version" />
                }
              />
            )}
        </Stack>
      </FullHeightCard>
    </ResponsiveCardLayout>
  );
};
