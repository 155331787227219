type HubSpotQueue = Array<[event: string, value?: unknown]>;

export type HubSpotClientOptions = {
  /**
   * (optional) The initial path to set for page tracking
   */
  initialPath?: string;
};

/**
 * Wrapper around the HubSpot tracking code
 *
 * @link https://developers.hubspot.com/docs/api/events/tracking-code
 */
export class HubSpotClient {
  private _hsq: HubSpotQueue;

  constructor(options?: HubSpotClientOptions) {
    // @ts-expect-error _hsq is not known on window
    this._hsq = window._hsq = window._hsq || [];

    if (options?.initialPath) {
      this.setPath(options.initialPath);
    }
  }

  /**
   * @link https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
   **/
  identifyVisitor(email: string) {
    this._hsq.push(['identify', { email }]);
  }

  /**
   * @link https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-applications
   **/
  setPath(pathname: string) {
    this._hsq.push(['setPath', pathname]);
  }

  /**
   * @link https://developers.hubspot.com/docs/api/events/tracking-code#tracking-in-single-page-applications
   *
   **/
  trackPageView(pathname: string) {
    this.setPath(pathname);
    this._hsq.push(['trackPageView']);
  }
}
