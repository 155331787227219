import { AuthenticatedRouteGuard } from '../../../components/AuthenticatedRouteGuard';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const TenantArtifactsRoute = () => {
  return <AuthenticatedRouteGuard />;
};

export const Route = createFileRoute('/t/:namespace/artifacts')({
  component: TenantArtifactsRoute,
});
