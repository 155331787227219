import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel, V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  EmptyState,
  FindingCountArrayDisplay,
  FindingNameDisplay,
  getPaginatorPageSlice,
  RowStack,
  SimplePagination,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { DetailDrawerDivider } from '../../../../components/DetailDrawer';
import {
  getAllFixedFindingsCount,
  getAllFixedFindingsList,
  getFixedFindingCountsBySeverity,
} from '../../utils/getFixedFindingsBySeverity';

const FINDINGS_PAGE_SIZE = 10;

export const FixedFindingsSection = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { space } = useTheme();
  const findingCountsBySeverity = getFixedFindingCountsBySeverity(upgradeInfo);
  const allFindingsCount = upgradeInfo?.total_findings_fixed ?? 0;

  const allFindings = getAllFixedFindingsList(upgradeInfo);

  const paginator = useDataTablePaginator({
    pageSize: FINDINGS_PAGE_SIZE,
    totalCount: allFindingsCount,
  });

  const currentFindings = getPaginatorPageSlice(paginator.state, allFindings);

  const isEmpty = allFindingsCount === 0;

  if (isEmpty) {
    return (
      <EmptyState
        imageWidth={300}
        size="medium"
        title={<FM defaultMessage="No Fixed Findings" />}
      />
    );
  }

  return (
    <Stack divider={<DetailDrawerDivider fullWidth />} gap={space.sm}>
      <RowStack justifyContent="space-between">
        <RowStack justifyContent="start">
          <Typography variant="body2">
            <FM
              defaultMessage="{count} Fixed Findings"
              values={{ count: allFindingsCount }}
            />
          </Typography>
          <FindingCountArrayDisplay value={findingCountsBySeverity} />
        </RowStack>
        <SimplePagination paginator={paginator} />
      </RowStack>

      <Stack
        gap={space.sm}
        marginBottom={space.sm}
        divider={<DetailDrawerDivider fullWidth />}
      >
        {currentFindings.map((f) => (
          <FindingNameDisplay
            key={f.uuid}
            finding={{
              meta: { name: f.name ?? '', description: f.description },
              // @ts-expect-error - some of the required properties are not present in the version upgrade finding object and those are not needed for FindingNameDisplay
              spec: {
                level: f.level ?? SpecFindingLevel.Unspecified,
                finding_tags: f.finding_tags ?? [],
              },
            }}
            showCve={false}
            showTags={true}
          />
        ))}
      </Stack>
    </Stack>
  );
};
