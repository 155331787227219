import {
  SpecFindingLevel,
  V1FindingSummaryInfo,
  V1ReleaseUpgradeInfo,
} from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { FindingCount } from '@endorlabs/queries';

export const getFixedFindingCountForSeverity = (
  upgradeInfo?: V1ReleaseUpgradeInfo,
  severity?: SpecFindingLevel
) => {
  if (!upgradeInfo || !severity) {
    return 0;
  }
  const vulnFindings =
    upgradeInfo?.vuln_finding_info?.severity?.[severity]?.reduction ?? 0;

  const otherFindings =
    upgradeInfo?.other_finding_info?.severity?.[severity]?.reduction ?? 0;

  return vulnFindings + otherFindings;
};

export const getFixedFindingCountsBySeverity = (
  upgradeInfo?: V1ReleaseUpgradeInfo
): FindingCount[] => {
  return [
    {
      level: SpecFindingLevel.Critical,
      value: getFixedFindingCountForSeverity(
        upgradeInfo,
        SpecFindingLevel.Critical
      ),
    },
    {
      level: SpecFindingLevel.High,
      value: getFixedFindingCountForSeverity(
        upgradeInfo,
        SpecFindingLevel.High
      ),
    },
    {
      level: SpecFindingLevel.Medium,
      value: getFixedFindingCountForSeverity(
        upgradeInfo,
        SpecFindingLevel.Medium
      ),
    },
    {
      level: SpecFindingLevel.Low,
      value: getFixedFindingCountForSeverity(upgradeInfo, SpecFindingLevel.Low),
    },
  ];
};

export const getAllFixedFindingsCount = (
  upgradeInfo?: V1ReleaseUpgradeInfo
) => {
  return FINDING_LEVELS.reduce((acc, level) => {
    return acc + getFixedFindingCountForSeverity(upgradeInfo, level);
  }, 0);
};

export const getFixedFindingsListForSeverity = (
  upgradeInfo?: V1ReleaseUpgradeInfo,
  severity?: SpecFindingLevel
): V1FindingSummaryInfo[] => {
  if (!upgradeInfo || !severity) {
    return [];
  }
  const vulnFindings =
    upgradeInfo?.vuln_finding_info?.severity?.[severity]?.fixed_summary ?? {};

  const otherFindings =
    upgradeInfo?.other_finding_info?.severity?.[severity]?.fixed_summary ?? {};

  const allFindings = { ...vulnFindings, ...otherFindings };

  return Object.values(allFindings);
};

export const getAllFixedFindingsList = (
  upgradeInfo?: V1ReleaseUpgradeInfo
): V1FindingSummaryInfo[] => {
  const findingLevels = [
    SpecFindingLevel.Critical,
    SpecFindingLevel.High,
    SpecFindingLevel.Medium,
    SpecFindingLevel.Low,
  ];
  return FINDING_LEVELS.reduce((acc, level) => {
    return [...acc, ...getFixedFindingsListForSeverity(upgradeInfo, level)];
  }, [] as V1FindingSummaryInfo[]);
};
