import { Grid, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ScoreDisplay } from '@endorlabs/ui-common';

export interface ScoreCardDisplayItemV2Props {
  colorize?: boolean;
  isLoading?: boolean;
  nilDisplay?: ReactNode;
  score?: number;
  showTotal?: boolean;
  title?: ReactNode;
  size?: 'large' | 'xlarge';
  totalSize?: 'small' | 'large' | 'xlarge';
}

export const ScoreCardDisplayItemV2 = ({
  colorize = false,
  isLoading = false,
  nilDisplay,
  score,
  size = 'large',
  showTotal = false,
  title,
}: ScoreCardDisplayItemV2Props) => {
  const { space, spacing, typography } = useTheme();
  const showTotalScore =
    showTotal && score !== undefined && score > 0 ? showTotal : false;
  const defaultNilDisplay = (
    <Typography
      color="text.secondary"
      alignItems="center"
      display="flex"
      variant="h3"
    >
      {isLoading ? (
        <Skeleton height={typography.xl.fontSize} width="1.2em" />
      ) : (
        <FM defaultMessage="N/A" />
      )}
    </Typography>
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      gap={1}
      px={space.xs}
      pb={space.xs}
    >
      {title && (
        <Grid item>
          <Typography variant="h4">
            {title ?? <Skeleton width="70%" />}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        flexWrap="nowrap"
        item
        alignItems="baseline"
        justifyContent="center"
        direction="row"
      >
        <ScoreDisplay
          colorize={colorize}
          nilDisplay={nilDisplay ?? defaultNilDisplay}
          zeroDisplay={defaultNilDisplay}
          size={size === 'large' ? 'large' : 'xlarge'}
          value={score}
        />

        {showTotalScore && (
          <Typography
            color="text.secondary"
            component="span"
            variant="h3"
            ml={spacing(0.5)}
          >
            /10
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
