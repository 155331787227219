import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { FilterExpression } from '@endorlabs/filters';
import {
  ButtonPrimary,
  ButtonSecondary,
  CopyToClipboardButton,
  useStyles,
} from '@endorlabs/ui-common';

import { getEnv } from '../../../constants';
import { FilterValidationError } from '../types';

const { URL_ENDOR_DOCS } = getEnv();

// HACK: detect mac user agent for helper text undo command
const isMac = /macintosh/i.test(navigator.userAgent);

const messages = defineMessages({
  buttonApply: { defaultMessage: 'Apply' },
  buttonReset: { defaultMessage: 'Reset' },
  filterSyntaxErrorHelp: {
    defaultMessage:
      'Fix the errors listed, use {os, select, macOS {cmd+z} other {ctrl+z}} to undo your changes, or click Basic Filters to clear your changes.',
  },
  filterLabel: { defaultMessage: 'Customize Filter' },
  filterPlaceholder: {
    defaultMessage: 'To create more complex filters, edit this expression',
  },
});

export const FilterBarAdvanced = ({
  filter,
  filterValidationError,
  onClear,
  onExpressionChange,
  searchPlaceholder,
}: {
  filter?: FilterExpression;
  filterValidationError?: FilterValidationError;
  onClear: () => void;
  onExpressionChange: (value: FilterExpression) => void;
  searchPlaceholder?: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const [internalValue, setInternalValue] = useState<string>(
    filter?.toString() ?? ''
  );

  const handleEnter = (event: KeyboardEvent) => {
    const hasModifier = event.shiftKey || event.altKey;
    if (event.code === 'Enter' && !hasModifier) {
      event.preventDefault();
      onExpressionChange(internalValue);
    }
  };

  const handleApply = (_: MouseEvent) => {
    // TODO: should the filter expression parsing happen here?
    onExpressionChange(internalValue);
  };

  // sync internal value when filter changes
  useEffect(() => {
    setInternalValue(filter ?? '');
  }, [filter]);

  const isClean = filter?.toString() === internalValue.toString();
  const isError = !!filterValidationError;

  const containerStyles = useStyles(styles, { isError });

  return (
    <Stack className="FilterBarAdvanced-root" spacing={2} sx={containerStyles}>
      <TextField
        className="FilterBarAdvanced-input"
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <Box sx={{ alignSelf: 'start' }}>
              <CopyToClipboardButton value={internalValue} />
            </Box>
          ),
          spellCheck: false,
        }}
        label={fm(messages.filterLabel)}
        maxRows={10}
        minRows={4}
        multiline
        onChange={(event) => setInternalValue(event.target.value)}
        onKeyDown={handleEnter}
        placeholder={searchPlaceholder ?? fm(messages.filterPlaceholder)}
        value={internalValue}
      />

      <Stack direction="row" justifyContent="end" spacing={2}>
        <ButtonSecondary onClick={onClear}>
          <FM {...messages.buttonReset} />
        </ButtonSecondary>

        <ButtonPrimary onClick={handleApply} disabled={isClean}>
          <FM {...messages.buttonApply} />
        </ButtonPrimary>
      </Stack>

      {filterValidationError && (
        <Alert severity="error" variant="outlined">
          <AlertTitle>{filterValidationError.message}</AlertTitle>

          {filterValidationError.isSyntaxError && (
            <Typography variant="body2" color="text.primary" marginBottom={1}>
              {fm(messages.filterSyntaxErrorHelp, {
                os: isMac ? 'macOS' : 'other',
              })}
            </Typography>
          )}

          <Typography variant="body2" color="text.primary">
            {filterValidationError.details}
          </Typography>
        </Alert>
      )}
    </Stack>
  );
};

function styles(theme: Theme, options?: { isError: boolean }) {
  const { palette, shape, spacing, typography } = theme;

  return {
    '& .FilterBarAdvanced-input .MuiInput-root': {
      borderColor: options?.isError ? palette.error.main : palette.divider,
      borderRadius: `${shape.borderRadius}px`,
      borderStyle: 'solid',
      borderWidth: 1,
      fontFamily: typography.code.fontFamily,
      padding: spacing(1, 1, 1, 2),
    },
  };
}
