import {
  Button,
  Grid,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingTags } from '@endorlabs/api_client';
import {
  ButtonCancel,
  ButtonSecondary,
  IconChevronDown,
} from '@endorlabs/ui-common';

import { FacetFilterDefinition } from './types';

export interface ControlsFacetFilterFindingAttributesProps {
  facet: FacetFilterDefinition;
  value: string[];
  onChange: (value: string[]) => void;
}

/**
 * Facet Filter control specifically designed for Finding Attributes
 *
 * NOTE: This hard-codes the attribute toggle groups below, ignoring
 * the provided Filter Definition.
 *
 * @deprecated use instead {@see FindingAttributesFilterField}
 */
export const ControlsFacetFilterFindingAttributes = ({
  value,
  onChange,
}: ControlsFacetFilterFindingAttributesProps) => {
  const [patchToggle, setPatchToggle] = useState<string>(
    // check if the initial filter contains a value from this toggle group
    () =>
      value?.find(
        (v) => v === V1FindingTags.FixAvailable || v === V1FindingTags.Unfixable
      ) ?? ''
  );
  const handlePatch = (
    event: React.MouseEvent<HTMLElement>,
    newPatchValue: string
  ) => {
    setPatchToggle(newPatchValue);
  };

  const [reachFunc, setReachFunc] = useState<string>(
    () =>
      value?.find(
        (v) =>
          v === V1FindingTags.PotentiallyReachableFunction ||
          v === V1FindingTags.ReachableFunction ||
          v === V1FindingTags.UnreachableFunction
      ) ?? ''
  );
  const handleReachFunc = (
    event: React.MouseEvent<HTMLElement>,
    newReachFunc: string
  ) => {
    setReachFunc(newReachFunc);
  };

  const [reachDep, setReachDep] = useState<string>(
    () =>
      value?.find(
        (v) =>
          v === V1FindingTags.PotentiallyReachableDependency ||
          v === V1FindingTags.ReachableDependency ||
          v === V1FindingTags.UnreachableDependency
      ) ?? ''
  );
  const handleReachDep = (
    event: React.MouseEvent<HTMLElement>,
    newReachDep: string
  ) => {
    setReachDep(newReachDep);
  };

  const [originDep, setOriginDep] = useState<string>(
    () =>
      value?.find(
        (v) =>
          v === V1FindingTags.ProjectInternal ||
          v === V1FindingTags.NamespaceInternal
      ) ?? ''
  );
  const handleOriginDep = (
    event: React.MouseEvent<HTMLElement>,
    newOriginDep: string
  ) => {
    setOriginDep(newOriginDep);
  };

  const [testDep, setTestDep] = useState<string>(
    () =>
      value?.find(
        (v) => v === V1FindingTags.Test || v === V1FindingTags.Normal
      ) ?? ''
  );
  const handleTestDep = (
    event: React.MouseEvent<HTMLElement>,
    newTestDep: string
  ) => {
    setTestDep(newTestDep);
  };

  const [originFinding, setOriginFinding] = useState<string>(
    () =>
      value?.find(
        (v) =>
          v === V1FindingTags.Self ||
          v === V1FindingTags.Direct ||
          v === V1FindingTags.Transitive
      ) ?? ''
  );
  const handleOriginFinding = (
    event: React.MouseEvent<HTMLElement>,
    newOriginFinding: string
  ) => {
    setOriginFinding(newOriginFinding);
  };

  useEffect(() => {
    //Called on clear of filter or initial state
    if (!value || value.length === 0) {
      setPatchToggle('');
      setReachFunc('');
      setReachDep('');
      setOriginDep('');
      setTestDep('');
      setOriginFinding('');
    }
  }, [value]);

  const attributes = [
    {
      header: 'Is there a patch available?',
      values: ['Yes', 'No'],
      exclusive: true,
      excValue: patchToggle,
      setExcValue: setPatchToggle,
      excFunc: handlePatch,
      tags: [V1FindingTags.FixAvailable, V1FindingTags.Unfixable],
    },
    {
      header: 'Is the vulnerable function reachable?',
      values: ['Reachable', 'Potentially Reachable', 'Unreachable'],
      exclusive: true,
      excValue: reachFunc,
      setExcValue: setReachFunc,
      excFunc: handleReachFunc,
      tags: [
        V1FindingTags.ReachableFunction,
        V1FindingTags.PotentiallyReachableFunction,
        V1FindingTags.UnreachableFunction,
      ],
    },
    {
      header: 'Is the affected dependency reachable?',
      values: ['Reachable', 'Potentially Reachable', 'Unreachable'],
      exclusive: true,
      excValue: reachDep,
      setExcValue: setReachDep,
      excFunc: handleReachDep,
      tags: [
        V1FindingTags.ReachableDependency,
        V1FindingTags.PotentiallyReachableDependency,
        V1FindingTags.UnreachableDependency,
      ],
    },
    {
      header: 'The affected dependency originates from...',
      values: ['This Repository', 'This Tenant'],
      exclusive: true,
      excValue: originDep,
      setExcValue: setOriginDep,
      excFunc: handleOriginDep,
      tags: [V1FindingTags.ProjectInternal, V1FindingTags.NamespaceInternal],
    },
    {
      header: 'Is it a test dependency?',
      values: ['Yes', 'No'],
      exclusive: true,
      excValue: testDep,
      setExcValue: setTestDep,
      excFunc: handleTestDep,
      tags: [V1FindingTags.Test, V1FindingTags.Normal],
    },
    {
      header: 'The finding originates from...',
      values: ['Self', 'Direct Dependency', 'Transitive Dependency'],
      exclusive: true,
      excValue: originFinding,
      setExcValue: setOriginFinding,
      excFunc: handleOriginFinding,
      tags: [
        V1FindingTags.Self,
        V1FindingTags.Direct,
        V1FindingTags.Transitive,
      ],
    },
  ];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'facet-filter-popover' : undefined;

  const handleInputClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    const nextValues = [
      patchToggle,
      reachFunc,
      reachDep,
      originDep,
      testDep,
      originFinding,
    ].filter((v) => !!v); // remove "falsy" values

    onChange(nextValues);
    setAnchorEl(null);
  };

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const nextValues = [
      patchToggle,
      reachFunc,
      reachDep,
      originDep,
      testDep,
      originFinding,
    ].filter((v) => !!v); // remove "falsy" values

    onChange(nextValues);
    setAnchorEl(null);
  };

  const handleGoBackClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Button
          data-testid="ControlsFacetFilterFindingAttributes-button"
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
          }}
          onClick={handleInputClick}
          variant="text"
        >
          <Typography color="primary" fontWeight={value?.length ? 'bold' : ''}>
            <FM defaultMessage="Attributes" />
          </Typography>
        </Button>

        {/* mimic the dropdown arrow on the Select */}
        <IconChevronDown
          color="primary"
          sx={{
            fontSize: 16,
            marginRight: 2,
            ...(anchorEl && { transform: 'rotate(180deg)' }),
          }}
        />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          padding={4}
          spacing={4}
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          {attributes.map(
            ({ header, values, exclusive, excValue, excFunc, tags }) => (
              <Grid item key={header}>
                {header}
                <Grid item sx={{ marginTop: 2 }}>
                  <ToggleButtonGroup
                    color="primary"
                    value={excValue}
                    onChange={excFunc}
                    exclusive={exclusive}
                  >
                    {values.map((v, index) => (
                      <ToggleButton
                        key={tags[index]}
                        value={tags[index]}
                        size="small"
                      >
                        {v}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            )
          )}
          <Grid item sx={{ marginTop: 4 }}>
            <Stack direction="row" spacing={2}>
              <ButtonSecondary onClick={handleApplyClick}>
                <FM defaultMessage="Apply Filter" />
              </ButtonSecondary>

              <ButtonCancel onClick={handleGoBackClick} />
            </Stack>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
