import { useMatch } from '@tanstack/react-location';

import { NewProjectAzure } from '../../../../../views/Projects/NewProject/NewProjectAzure';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantCreateProjectAzureRoute = () => {
  const {
    params: { namespace },
  } = useMatch();
  return <NewProjectAzure namespace={namespace} />;
};

export const Route = createFileRoute('/t/:namespace/projects/new/azure')({
  component: TenantCreateProjectAzureRoute,
});
