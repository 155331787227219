import { get as _get, isMatch as _isMatch, pick as _pick } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TOOL_DEFINITIONS } from '../constants';
import {
  OSLabelMessages,
  ToolchainLabelMessages,
  ToolchainToolLabelMessages,
} from '../locales';
import { ToolchainToolInfo, ToolInfoType } from '../types';

export const getToolchainToolInfoDefinition = (
  toolInfo: Pick<ToolchainToolInfo, 'toolchain' | 'tool'>
) => {
  return TOOL_DEFINITIONS.find(
    (td) => td.toolchain === toolInfo.toolchain && td.tool === toolInfo.tool
  );
};

export const getToolchainLabel = (
  toolInfo: Pick<ToolchainToolInfo, 'toolchain'>
): ReactNode => {
  const message = _get(ToolchainLabelMessages, [toolInfo.toolchain]);
  if (message) {
    return <FormattedMessage {...message} />;
  }

  // Handle fallback to get a tool name from the toolchain + tool
  const label = toolInfo.toolchain;
  if (label.endsWith('_tool_chain')) {
    return label.replace(/_tool_chain$/, '');
  }

  return label;
};

export const getToolchainToolLabel = (
  toolInfo: Partial<ToolchainToolInfo>
): ReactNode => {
  // For tool types defined by a package name, return the name directly
  if (
    (toolInfo.type === ToolInfoType.ToolChainVersionMap ||
      toolInfo.type === ToolInfoType.ToolList) &&
    toolInfo.value?.key
  ) {
    return toolInfo.value.key;
  }

  if (!toolInfo.tool) {
    return 'unknown';
  }

  const key = [toolInfo.toolchain, toolInfo.tool].join('.');
  const message = _get(ToolchainToolLabelMessages, [key]);
  if (message) {
    return <FormattedMessage {...message} />;
  }

  // Handle fallback to get a tool name from the toolchain + tool
  const label = toolInfo.tool;
  if (label.endsWith('_version')) {
    return label.replace(/_version$/, '');
  }

  if (toolInfo.toolchain && label === 'version') {
    return getToolchainLabel({ toolchain: toolInfo.toolchain });
  }

  return label;
};

export const getToolchainOSLabel = (
  toolInfo: Pick<ToolchainToolInfo, 'os'>
): ReactNode => {
  const message = _get(OSLabelMessages, [toolInfo.os]);
  if (message) {
    return <FormattedMessage {...message} />;
  }

  return toolInfo.os;
};

/**
 * Given a list of ToolchainToolInfo, return a match for the given tool partial, if exists
 */
export const getToolchainToolInfoMatch = (
  tools: ToolchainToolInfo[],
  tool: ToolchainToolInfo
) => {
  const base = _pick(tool, ['os', 'arch', 'toolchain', 'tool']);
  const matches = tools.filter((t) => _isMatch(t, base));

  if (tool.type === ToolInfoType.ToolChainVersion) {
    return matches.at(0);
  }

  return matches
    .filter((m) => 'key' in m.value && m.value.key === tool.value.key)
    .at(0);
};
