import { Box, Button, Popover, Theme } from '@mui/material';
import clsx from 'clsx';
import { MouseEvent, ReactNode, useState } from 'react';

import { IconChevronDown, useStyles } from '@endorlabs/ui-common';

interface FilterDropdownProps {
  children: ReactNode;
  id: string;
  isActive: boolean;
  label: ReactNode;
  onApply: () => void;
  onCancel?: () => void;
  useContentContainer?: boolean;
}

export const FilterDropdown = ({
  children,
  id,
  isActive,
  label,
  onApply,
  onCancel,
  useContentContainer = true,
}: FilterDropdownProps) => {
  const sx = useStyles(styles);
  const popoverSx = useStyles(popoverStyles);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isOpen = Boolean(anchorEl);
  const menuId = isOpen ? id : undefined;

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget);
  };

  const closeMenu = () => setAnchorEl(null);

  const handleApply = () => {
    closeMenu();
    onApply();
  };

  const handleCancel = () => {
    closeMenu();
    if (onCancel) onCancel();
  };

  const handlePopClose = (evt: Event, reason: 'escapeKeyDown' | string) => {
    if (reason === 'escapeKeyDown') {
      handleCancel();
    } else {
      handleApply();
    }
  };

  const content = useContentContainer ? (
    <Box className="FilterDropdown-contentContainer">{children}</Box>
  ) : (
    children
  );

  const classNames = clsx({
    'FilterDropdown-root': true,
    'FilterDropdown-isActive': isActive,
    'FilterDropdown-isOpen': isOpen,
  });

  return (
    <Box className={classNames} sx={sx}>
      <Button
        className="FilterDropdown-button"
        endIcon={
          <IconChevronDown
            color="primary"
            sx={{
              fontSize: 16,
              ...(isOpen && { transform: 'rotate(180deg)' }),
            }}
          />
        }
        onClick={handleOpen}
        variant="text"
      >
        {label}
      </Button>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        className="FilterDropdown-popover"
        id={menuId}
        onClose={handlePopClose}
        open={isOpen}
        sx={popoverSx}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        {content}
      </Popover>
    </Box>
  );
};

const styles = ({ space }: Theme) => {
  return {
    '&': {
      display: 'inline-block',
    },

    '&.FilterDropdown-isActive': {
      '.FilterDropdown-button': {
        fontWeight: 'bold',
      },
    },

    '.FilterDropdown-contentContainer': {
      padding: `${space.md} ${space.md}`,
    },
  };
};

const popoverStyles = ({ space }: Theme) => {
  return {
    '& .FilterDropdown-contentContainer': {
      padding: `${space.md} ${space.md}`,
    },
  };
};
