import { Chip, Stack, useTheme } from '@mui/material';

import { V1CiCdTool } from '@endorlabs/api_client';

import { ToolNameDisplay } from './ToolNameDisplay';

export const ToolNameArrayDisplay = ({ tools }: { tools: V1CiCdTool[] }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" flexWrap="wrap" gap={theme.space.sm}>
      {tools.length === 0 ? (
        <Chip label="None" />
      ) : (
        tools.map((tool) => (
          <ToolNameDisplay key={tool.name} toolName={tool.name} />
        ))
      )}
    </Stack>
  );
};
