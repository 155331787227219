import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  IdentityProviderServiceApi,
  V1IdentityProvider,
  V1ListParameters,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  IdentityProviderResource,
  IdentityProviderResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface IdentityProviderReadParams {
  namespace: string;
  uuid: string;
}

export interface IdentityProviderWriteParams {
  namespace: string;
  resource: V1IdentityProvider;
}

export interface IdentityProviderUpdateParams
  extends IdentityProviderWriteParams {
  mask?: string;
}

type ListIdentityProviderOptions =
  ResourceQueryOptions<IdentityProviderResourceList>;
type GetIdentityProviderOptions =
  ResourceQueryOptions<IdentityProviderResource>;
type UpsertIdentityProviderOptions = ResourceMutateOptions<
  V1IdentityProvider,
  IdentityProviderWriteParams
>;
type DeleteIdentityProviderOptions = ResourceMutateOptions<
  object,
  IdentityProviderReadParams
>;

const BASE_KEY = 'v1/identityProvider';
const QK = {
  count: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, listParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const IdentityProviderQueryKeys = QK;
export const IDENTITY_PROVIDER_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new IdentityProviderServiceApi(getClientConfiguration());

export const listIdentityProviders = async (
  namespace: string,
  listParams?: V1ListParameters
) => {
  const api = getApiService();
  const resp = await api.identityProviderServiceListIdentityProviders(
    namespace,
    ...buildListParamArgs(listParams ?? {})
  );
  return resp.data as IdentityProviderResourceList;
};

export const useListIdentityProviders = (
  namespace: string,
  opts: ListIdentityProviderOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'IdentityProvider',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listIdentityProviders(namespace, requestParameters),
    opts
  );
};

export const getIdentityProvider = async ({
  namespace,
  uuid,
}: IdentityProviderReadParams) => {
  const api = getApiService();
  const resp = await api.identityProviderServiceGetIdentityProvider(
    namespace,
    uuid
  );
  return resp.data as IdentityProviderResource;
};

export const useGetIdentityProvider = (
  params: IdentityProviderReadParams,
  opts: GetIdentityProviderOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getIdentityProvider(params),
    opts
  );
};

const createIdentityProvider = async (params: IdentityProviderWriteParams) => {
  const api = getApiService();
  const resp = await api.identityProviderServiceCreateIdentityProvider(
    params.namespace,
    params.resource
  );
  return resp.data as IdentityProviderResource;
};

export const useCreateIdentityProvider = (
  opts: UpsertIdentityProviderOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'IdentityProvider'),
    mutationFn: (params: IdentityProviderWriteParams) =>
      createIdentityProvider(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateIdentityProvider = async (params: IdentityProviderUpdateParams) => {
  const { resource, namespace, mask = IDENTITY_PROVIDER_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.identityProviderServiceUpdateIdentityProvider(
    namespace,
    req
  );
  return resp.data as IdentityProviderResource;
};

export const useUpdateIdentityProvider = (
  opts: UpsertIdentityProviderOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'IdentityProvider'),
    mutationFn: (params: IdentityProviderUpdateParams) =>
      updateIdentityProvider(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteIdentityProvider = async (params: IdentityProviderReadParams) => {
  const api = getApiService();
  const resp = await api.identityProviderServiceDeleteIdentityProvider(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteIdentityProvider = (
  opts: DeleteIdentityProviderOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'IdentityProvider'),
    mutationFn: (params: IdentityProviderReadParams) =>
      deleteIdentityProvider(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
