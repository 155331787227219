import { Stack, Typography } from '@mui/material';

import { V1Ecosystem } from '@endorlabs/api_client';
import {
  FindingResource,
  isPackageFinding,
  isSelfFinding,
} from '@endorlabs/endor-core/Finding';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  getDependencyFileLocations,
  PackageVersionResource,
} from '@endorlabs/endor-core/PackageVersion';
import { QueryFindingsResponseObject } from '@endorlabs/queries';
import {
  CiWorkflowNameDisplay,
  GITHUB_ACTION_PACKAGE_SUFFIX,
  Link,
  PackageVersionNameDisplay,
  ProjectNameDisplay,
} from '@endorlabs/ui-common';
import { ProjectInfoCacheEntry } from '@endorlabs/ui-common/domains/Projects';

import {
  getDependencyPath,
  getPackageVersionPath,
  getPackageVersionRedirectPath,
  getProjectPath,
} from '../../../routes';

export const isOssFinding = (finding: FindingResource) =>
  finding.tenant_meta.namespace === NAMESPACES.OSS;

export const getFindingProjectNameDisplay = (
  projectInfo: ProjectInfoCacheEntry,
  namespace: string
) => {
  if (!projectInfo) return undefined;

  return (
    <Link
      to={getProjectPath({ tenantName: namespace, uuid: projectInfo.uuid })}
    >
      <ProjectNameDisplay {...projectInfo} />
    </Link>
  );
};

export const getFindingDependencyURL = (
  finding: FindingResource,
  namespace: string
) => {
  return isOssFinding(finding)
    ? getPackageVersionRedirectPath({
        tenantName: NAMESPACES.OSS,
        packageVersionName: finding.spec.target_dependency_package_name ?? '',
      })
    : getDependencyPath({
        tenantName: namespace,
        uuid: finding.spec.target_uuid,
      });
};

export const getFindingDependencyNameDisplay = (
  finding: FindingResource,
  namespace: string,
  packageVersion?: PackageVersionResource
) => {
  if (!finding.spec.target_uuid) return undefined;
  if (!finding.spec.target_uuid && isSelfFinding(finding)) return undefined;
  if (!finding.spec.target_dependency_package_name) return undefined;

  const dependencyPackageVersionName =
    finding.spec.target_dependency_package_name;
  const dependencyFileLocations = packageVersion
    ? getDependencyFileLocations(packageVersion, {
        name: dependencyPackageVersionName,
      })
    : [];

  return (
    <Stack direction="column">
      <Link
        color="text.primary"
        to={getFindingDependencyURL(finding, namespace)}
      >
        <PackageVersionNameDisplay
          packageVersion={{
            meta: { name: dependencyPackageVersionName },
          }}
          showVersion
        />
      </Link>

      {dependencyFileLocations.map((value, index) => (
        <Typography
          color="text.secondary"
          component="span"
          key={index}
          sx={{ wordBreak: 'break-all' }}
          variant="body2"
        >
          {value}
        </Typography>
      ))}
    </Stack>
  );
};

export const getFindingPackageResource = (
  finding: QueryFindingsResponseObject
) => {
  return finding.meta.references.PackageVersion?.list?.objects[0];
};

export const getFindingPackageNameDisplay = (
  finding: FindingResource,
  namespace: string,
  packageResource?: PackageVersionResource
) => {
  // const packageResource = getFindingPackageResource(finding);
  if (!packageResource) return undefined;
  if (!isPackageFinding(finding)) return undefined;
  if (isPackageFinding(finding) && isSelfFinding(finding)) return undefined;

  if (packageResource?.spec.ecosystem === V1Ecosystem.GithubAction) {
    return (
      <Link
        color="text.primary"
        to={getPackageVersionPath({
          tenantName: namespace,
          uuid: packageResource.uuid,
        })}
      >
        <CiWorkflowNameDisplay
          name={packageResource.meta.name}
          relativePath={packageResource.spec.relative_path}
          size="small"
          showIcon
          suffix={GITHUB_ACTION_PACKAGE_SUFFIX}
        />
      </Link>
    );
  }

  return (
    <Link
      color="text.primary"
      to={getPackageVersionPath({
        tenantName: namespace,
        uuid: packageResource.uuid,
      })}
    >
      <PackageVersionNameDisplay packageVersion={packageResource} showVersion />
    </Link>
  );
};
