import { useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';

import { PackageIconDisplay } from '../../../components/PackageIconDisplay';
import { RowStack } from '../../../components/RowStack';
import { EcosystemMessages } from '../locales';

interface EcosystemNameDisplayProps {
  value?: V1Ecosystem;
  iconWithBorder?: boolean;
  size?: 'inherit' | 'small' | 'medium' | 'large';
}

export const EcosystemNameDisplay = ({
  value = V1Ecosystem.Unspecified,
  iconWithBorder = false,
  size = 'small',
}: EcosystemNameDisplayProps) => {
  const { space } = useTheme();

  return (
    <RowStack gap={space.xs}>
      <PackageIconDisplay
        displayDefault="package"
        ecosystem={value}
        withBorder={iconWithBorder}
        size={size}
      />
      <FM {...EcosystemMessages[value]} />
    </RowStack>
  );
};
