import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import produce from 'immer';
import { unset as _unset } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { FieldNamesMarkedBoolean, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  IQueryErrorResponse,
  PackageManagerResource,
} from '@endorlabs/queries';
import { ControlledTextField } from '@endorlabs/ui-common';

import { TokenLoginPackageManagerKey } from './constants';
import { PackageManagerAdvancedFields } from './PackageManagerAdvancedFields';
import { PackageManagerAWSCodeArtifactFields } from './PackageManagerAWSCodeArtifactFields';
import { PackageManagerMainFormFields } from './PackageManagerMainFormFields';
import { PackageManagerAuthMode, TokenAuthFieldValues } from './types';

interface FormTokenManagerProps {
  authRequired: boolean;
  isSubmitting: boolean;
  awsCodeArtifactEnabled: boolean;
  onSubmit: (
    data: TokenAuthFieldValues,
    dirtyFields: FieldNamesMarkedBoolean<TokenAuthFieldValues>
  ) => void;
  packageManager?: PackageManagerResource;
  packageManagerKey: TokenLoginPackageManagerKey;
  serverErrorResponse?: IQueryErrorResponse;
}

export const FormUpsertTokenAuthPackageManager = ({
  authRequired,
  isSubmitting,
  awsCodeArtifactEnabled,
  onSubmit,
  packageManager,
  packageManagerKey,
}: FormTokenManagerProps) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<TokenAuthFieldValues>();

  const { formatMessage: fm } = useIntl();
  const hasAuthProvider = !!packageManager?.spec.auth_provider;
  const hasUrl = !!packageManager?.spec[packageManagerKey]?.url?.length;

  const [selectedAuthMode, setSelectedAuthMode] =
    useState<PackageManagerAuthMode>(() => {
      return hasAuthProvider
        ? PackageManagerAuthMode.AWS
        : PackageManagerAuthMode.Basic;
    });

  useEffect(() => {
    // reset fields from the given package manager
    reset({
      propagate: packageManager?.propagate ?? false,
      priority: packageManager?.spec[packageManagerKey]?.priority,
    });
  }, [packageManager, reset, packageManagerKey]);

  const wrappedOnSubmit = useCallback(
    (fieldValues: TokenAuthFieldValues) => {
      if (selectedAuthMode === PackageManagerAuthMode.Basic) {
        const formattedValues = produce(fieldValues, (draft) => {
          //Reset auth_provider for basic auth
          _unset(draft, 'auth_provider');
        });

        onSubmit(formattedValues, dirtyFields);
      } else {
        onSubmit(fieldValues, {});
      }
    },
    [onSubmit, selectedAuthMode, dirtyFields]
  );

  return (
    <form id="tokenAuthPM" onSubmit={handleSubmit(wrappedOnSubmit)}>
      <Grid container direction="column" item spacing={5}>
        <PackageManagerMainFormFields
          selectedAuthMode={selectedAuthMode}
          setSelectedAuthMode={setSelectedAuthMode}
          packageManager={packageManager}
          control={control}
          hasAuthProvider={hasAuthProvider}
          hasBasicAuthField={hasUrl}
          awsCodeArtifactEnabled={awsCodeArtifactEnabled}
        />
        {selectedAuthMode === PackageManagerAuthMode.Basic && (
          <>
            <Grid item>
              <ControlledTextField
                autoComplete="off"
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.url ?? ''
                }
                fullWidth
                label={fm({
                  defaultMessage: 'Package manager URL',
                })}
                name="url"
                rules={{
                  required: fm({
                    defaultMessage: 'Package manager URL is required',
                  }),
                  // TODO: URL validation that accounts for S3
                }}
                variant="standard"
              />
            </Grid>
            <Grid item>
              <ControlledTextField
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.scope ?? ''
                }
                label={<FM defaultMessage="Scope" />}
                placeholder={fm({
                  defaultMessage: 'Example: @my-org/package-name',
                })}
                name="scope"
                rules={{
                  pattern: {
                    value: /\S+/,
                    message: fm({
                      defaultMessage: 'Must be a valid npm scope',
                    }),
                  },
                }}
              />
            </Grid>
            <Grid item width="36%">
              <ControlledTextField
                control={control}
                defaultValue={
                  packageManager?.spec[packageManagerKey]?.token ?? ''
                }
                label={<FM defaultMessage="Token" />}
                placeholder={fm({
                  defaultMessage:
                    'Token used to authenticate to private registry',
                })}
                name="token"
                rules={
                  authRequired
                    ? {
                        required: fm({
                          defaultMessage:
                            'Token is required for this package manager',
                        }),
                      }
                    : undefined
                }
                type="password"
              />
            </Grid>
          </>
        )}
        {selectedAuthMode === PackageManagerAuthMode.AWS && (
          <PackageManagerAWSCodeArtifactFields
            control={control}
            packageManager={packageManager}
          />
        )}
        <Grid item>
          <PackageManagerAdvancedFields control={control} />
        </Grid>

        <Grid item marginTop={4}>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            disabled={isSubmitting}
            variant="contained"
          >
            {packageManager && <FM defaultMessage="Save Changes" />}
            {!packageManager && <FM defaultMessage="Add Package Manager" />}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
