import { FormattedMessage as FM } from 'react-intl';

import { FILTER_COMPARATORS, FilterableResource } from '@endorlabs/filters';

import { FacetFilterDefinition } from '../../../components';

export const SecretRuleFacets = [
  {
    id: 'SecretRule:spec.validation',
    filter: {
      kind: 'SecretRule' as FilterableResource,
      key: 'spec.validation',
      type: 'boolean',
    },
    control: 'FacetFilterToggle',
    comparator: FILTER_COMPARATORS.EXISTS,
    label: <FM defaultMessage="Has Validator" />,
  },
  {
    id: 'SecretRule:tenant_meta.namespace',
    filter: {
      kind: 'SecretRule' as FilterableResource,
      key: 'tenant_meta.namespace',
      type: 'string',
    },
    comparator: FILTER_COMPARATORS.EQUAL,
    label: <FM defaultMessage="Defined by" />,
    control: 'FacetFilterSelect',
    values: [
      {
        key: 'system',
        value: 'system',
        label: <FM defaultMessage="Endor" />,
      },
    ],
  },
] as FacetFilterDefinition[];
