import { isArray } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { V1ToolPattern } from '@endorlabs/api_client';
import {
  selectCiCdToolListByCategory,
  ToolPatternResource,
} from '@endorlabs/queries';

import { DataTableRegistryEntry } from '../../Policies/constants/DataTableRegistry';

interface ToolPatternTableRow {
  category_name?: string;
  category_tools?: string[];
  tool_name?: string;
  tool_categories?: string[];
  rowValue?: string;
}

export const ToolPatternSelectionTableRegistryEntry: DataTableRegistryEntry<
  ToolPatternResource,
  ToolPatternTableRow
> = {
  // @ts-expect-error - accessorFn complaint. See https://github.com/TanStack/table/issues/4241
  buildColumns: (valueKey) => {
    switch (valueKey) {
      case 'spec.categories':
        return [
          {
            accessorKey: 'category_name',
            enableSorting: true,
            header: () => <FM defaultMessage="Category Name" />,
          },
          {
            accessorKey: 'category_tools',
            enableSorting: true,
            header: () => <FM defaultMessage="Category Tools" />,
          },
        ];
      case 'meta.name':
        return [
          {
            accessorKey: 'tool_name',
            enableSorting: true,
            header: () => <FM defaultMessage="Tool Name" />,
          },
          {
            accessorKey: 'tool_categories',
            enableSorting: true,
            header: () => <FM defaultMessage="Tool Categories" />,
          },
        ];
      default:
        return [];
    }
  },

  buildRows: (
    tool_patterns: ToolPatternResource[],
    valueKey
  ): ToolPatternTableRow[] => {
    switch (valueKey) {
      case 'spec.categories': {
        const categories = selectCiCdToolListByCategory(tool_patterns);
        if (!isArray(categories)) {
          return Object.entries(categories).map(([key, value]) => ({
            category_name: key,
            category_tools: (value as ToolPatternResource[]).map(
              (val: V1ToolPattern) => val.meta.name
            ),
            rowValue: key,
          }));
        }
        return [];
      }
      case 'meta.name':
        return tool_patterns.map((tool_pattern) => ({
          tool_name: tool_pattern.meta.name,
          tool_categories: tool_pattern.spec.categories,
          rowValue: tool_pattern.meta.name,
        }));
      default:
        return [];
    }
  },
};
