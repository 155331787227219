import { debounce as _debounce } from 'lodash-es';
import { useCallback, useEffect, useRef } from 'react';

import {
  HubSpotClient,
  injectTrackingScript,
} from '@endorlabs/analytics-hubspot';
import { useLatestCallback } from '@endorlabs/ui-common';

import { NamedRoutes } from '../../routes';
import { HubspotEvent } from './types';

const ALLOWED_PATHS: string[] = [NamedRoutes.LOGIN, NamedRoutes.SIGNUP];

const HUBSPOT_EVENT_BATCH_WAIT = 1_000;
const HUBSPOT_EVENT_BATCH_MAX_WAIT = 10_000;
const HUBSPOT_EVENT_BATCH_SIZE = 10;

/**
 * Manages queuing and sending Hubspot events
 */
export const useHubspotEventQueue = ({
  enabled,
  portalId,
}: {
  enabled?: boolean;
  /**
   * The HubSpot Portal ID
   */
  portalId?: string;
}) => {
  const hubspotClient = useRef<HubSpotClient | undefined>();
  const eventQueue = useRef<HubspotEvent[]>([]);

  // Inject tracking script and initialize HubSpot instance
  useEffect(() => {
    const initialPath = window.location.pathname;

    // Don't inject the script:
    // - When missing env vars
    // - When not enabled
    // - For paths not in the allow list
    if (
      !portalId ||
      !enabled ||
      !ALLOWED_PATHS.includes(initialPath) ||
      process.env.NODE_ENV !== 'production'
    ) {
      return;
    }

    // Skip if already initialized
    if (hubspotClient.current) {
      return;
    }

    // Instantiate a HubSpot client, and inject the tracking code
    hubspotClient.current = new HubSpotClient({ initialPath });

    injectTrackingScript({ portalId }).then((result) => {
      if (!result.ok) {
        // The tracking code script failed to inject.
        return;
      }
    });
  }, [enabled, portalId]);

  const sendBatch = useLatestCallback(
    _debounce(
      () => {
        if (!hubspotClient.current) {
          // trigger to attempt send again later
          sendBatch();
          return;
        }

        // Get next events to send
        if (eventQueue.current.length === 0) return;
        const pending = eventQueue.current.splice(0, HUBSPOT_EVENT_BATCH_SIZE);

        const client = hubspotClient.current;
        for (const { event, value } of pending) {
          switch (event) {
            case 'PAGE_VIEW':
              client.trackPageView(value.pathname);
              break;
            case 'IDENTIFY':
              client.identifyVisitor(value.email);
              break;
          }
        }

        // If additional events remain in the queue, trigger send again
        if (eventQueue.current.length) {
          sendBatch();
        }
      },
      HUBSPOT_EVENT_BATCH_WAIT,
      { maxWait: HUBSPOT_EVENT_BATCH_MAX_WAIT }
    )
  );

  const enqueue = useCallback(
    (events: HubspotEvent[]) => {
      eventQueue.current.push(...events);
      sendBatch();
    },
    [sendBatch]
  );

  return { enqueue };
};
