import { CircularProgress, Grid, Stack } from '@mui/material';
import { uniq as _uniq } from 'lodash-es';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/queries';
import {
  DependencyGraph,
  EmptyState,
  GraphData,
  GraphDataInput,
  useAppNotify,
  usePackageVersionDependencies,
} from '@endorlabs/ui-common';

import { useDependencyDetailDrawer } from '../../domains/Dependencies';

const MAX_SUPPORTED_DEPENDECY_COUNT = 1000;

export const PackageVersionDetailDependencyGraph = ({
  tenantName,
  packageVersion,
}: {
  tenantName: string;
  packageVersion?: PackageVersionResource;
}) => {
  const appNotification = useAppNotify();
  const qPackageVersionDependencies = usePackageVersionDependencies(
    tenantName,
    packageVersion
  );
  const { DetailDrawer, permalinkEffect } = useDependencyDetailDrawer();
  const packageName = packageVersion?.meta?.name;
  const packageUuid = packageVersion?.uuid;
  const packageNamespace = packageVersion?.tenant_meta.namespace;

  const depGraph = useMemo(
    () => packageVersion?.spec?.resolved_dependencies?.dependency_graph ?? {},
    [packageVersion?.spec?.resolved_dependencies?.dependency_graph]
  );

  const globalVisited = useRef<string[]>([]);

  const genData = useCallback(
    (
      depName: string,
      isCollapsed = false,
      visited: string[] = []
    ): GraphDataInput => {
      const depChildren = depGraph[depName];
      const dep = qPackageVersionDependencies.dependencies.find(
        (d) => d.name === depName
      );
      const uuid = depName === packageName ? packageUuid : dep?.uuid;
      const namespace =
        depName === packageName ? packageNamespace : dep?.namespace;

      if (
        !depChildren ||
        depChildren.length === 0 ||
        visited.includes(depName)
      ) {
        return {
          uuid,
          name: depName,
          namespace: namespace ?? '',
        };
      } else {
        const collapsed =
          isCollapsed || globalVisited.current.includes(depName);

        visited.push(depName);
        globalVisited.current.push(depName);
        return {
          uuid: uuid,
          name: depName,
          namespace: namespace ?? '',
          children: _uniq(depChildren).map((d) => {
            return genData(String(d), collapsed, [...visited]);
          }),
          isCollapsed: collapsed,
        };
      }
    },
    [
      depGraph,
      packageName,
      packageNamespace,
      packageUuid,
      qPackageVersionDependencies.dependencies,
    ]
  );

  const graph = useMemo(() => {
    if (qPackageVersionDependencies.isSuccess) {
      return packageName ? genData(packageName) : undefined;
    }
  }, [genData, packageName, qPackageVersionDependencies.isSuccess]);

  const openDependencyDrawer = (data: GraphData) => {
    if (data.uuid) {
      DetailDrawer.activate(
        {
          name: data.name,
          namespace: data.namespace,
        },
        {
          importingNamespace: tenantName,
          importingPackageVersion: packageVersion,
          name: data.name,
          namespace: data.namespace,
          uuid: data.uuid,
        }
      );
    } else {
      appNotification({
        message: <FM defaultMessage="Unable to get dependency details" />,
        severity: 'error',
      });
    }
  };

  useLayoutEffect(
    () =>
      permalinkEffect({
        dependencies: qPackageVersionDependencies.dependencies,
        importingNamespace: tenantName,
        importingPackageVersion: packageVersion,
      }),

    [
      permalinkEffect,
      packageVersion,
      qPackageVersionDependencies.dependencies,
      tenantName,
    ]
  );

  const hideGraph =
    qPackageVersionDependencies.dependencies.length >
    MAX_SUPPORTED_DEPENDECY_COUNT;

  return (
    <Grid item>
      <div
        style={{
          height: `calc(100vh - 300px)`,
        }}
      >
        {qPackageVersionDependencies.isLoading && (
          <Stack height="inherit" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {!qPackageVersionDependencies.isLoading &&
          qPackageVersionDependencies.isSuccess &&
          !hideGraph && (
            <DependencyGraph
              graphData={graph as GraphData}
              onOpen={openDependencyDrawer}
            />
          )}
        {!qPackageVersionDependencies.isLoading &&
          qPackageVersionDependencies.isSuccess &&
          hideGraph && (
            <Stack>
              <EmptyState
                size="large"
                title={
                  <FM defaultMessage="The Dependency Graph is too large to be displayed. Please contact Endor Labs for support." />
                }
              ></EmptyState>
            </Stack>
          )}
        {!qPackageVersionDependencies.isLoading &&
          !qPackageVersionDependencies.isSuccess && (
            <Stack>
              <EmptyState
                size="large"
                title={<FM defaultMessage="Unable to load dependency data" />}
              ></EmptyState>
            </Stack>
          )}
      </div>
    </Grid>
  );
};
