import { Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  getSecurityFindingFixVersion,
  getSecurityFindingIntroducedVersion,
} from '@endorlabs/endor-core/Finding';
import {
  AttributeDisplay,
  AttributeDisplayStack,
  FormattedTypography,
} from '@endorlabs/ui-common';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../components/DetailDrawer';
import { FindingDetailSecurityReferences } from '../../../components/FindingDetail/FindingDetailSecurityReferences';
import { FindingDetailDrawerSectionProps } from '../types';

const genResource = ({ finding }: FindingDetailDrawerSectionProps) => {
  const vuln = finding?.spec.finding_metadata?.vulnerability?.spec;
  const vulnAffected = vuln?.affected?.[0];

  const resource = {
    remediation: finding?.spec.remediation,

    vuln_fix_version: finding
      ? getSecurityFindingFixVersion(finding)
      : undefined,

    vuln_introduced_version: finding
      ? getSecurityFindingIntroducedVersion(finding)
      : undefined,

    vuln_notes: (vuln?.additional_notes ?? []).map(
      (note: string, index: number) => (
        <FormattedTypography key={index} text={note} variant="body2" />
      )
    ),

    vuln_references: vuln?.references ? (
      <FindingDetailSecurityReferences references={vuln?.references} />
    ) : undefined,

    vuln_versions: (
      <Typography
        variant="code"
        sx={({ typography }) => ({ fontSize: typography.body2.fontSize })}
      >
        {(vulnAffected?.versions ?? []).join(', ')}
      </Typography>
    ),
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'remediation',
    },
    {
      attributeKey: 'vuln_introduced_version',
      heading: <FM defaultMessage="Introduced Version" />,
    },
    {
      attributeKey: 'vuln_fix_version',
      heading: <FM defaultMessage="Fix Available" />,
    },
    {
      attributeKey: 'vuln_versions',
      heading: <FM defaultMessage="Affected Versions" />,
    },
  ];
};

export const FindingFixInfoSection = ({
  finding,
  isLoading = false,
  namespace,
}: FindingDetailDrawerSectionProps) => {
  const { space } = useTheme();

  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace }),
    [finding, namespace]
  );

  return (
    <DetailDrawerSectionStack>
      <DetailDrawerSection>
        <Stack gap={space.sm}>
          <AttributeDisplayStack
            attributeRecords={records.slice(1)}
            headingWidth="30%"
            isLoading={isLoading}
            resource={resource}
            variant="flex"
          />

          <AttributeDisplay
            heading={<FM defaultMessage="References" />}
            isLoading={isLoading}
            value={resource.vuln_references}
          />
        </Stack>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
