import { QueryKey, useQuery } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryServiceApi,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Query,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { HuggingFaceModelResource } from '@endorlabs/endor-core/HuggingFaceModel';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration } from './utils';

export const QueryHuggingFaceModelsQueryKeys = {
  query: (namespace: string, listParams: V1ListParameters): QueryKey => [
    'v1/queries',
    namespace,
    'hugging-face-models',
    { listParams },
  ],
};

export type QueryHuggingFaceModelsResponse =
  TResourceList<QueryHuggingFaceModelsResponseObject>;

export type QueryHuggingFaceModelsResponseObject = SelectFrom<
  HuggingFaceModelResource,
  'uuid' | 'spec',
  {
    meta: SelectFrom<
      V1Meta,
      'name',
      {
        create_time: never;
        update_time: never;
        references: {
          ScorecardMetric: QueryHuggingFaceModelMetricsResponse;
        };
      }
    >;
  }
>;

type QueryHuggingFaceModelMetricsResponse = TResourceList<
  SelectFrom<
    V1Metric,
    'context' | 'uuid',
    {
      meta: SelectFrom<
        V1Meta,
        'update_time',
        {
          name: 'model_scorecard';
        }
      >;
      spec: SelectFrom<
        V1MetricSpec,
        'analytic' | 'project_uuid',
        {
          metric_values: {
            scorecard: Required<V1MetricValue>;
            scorefactor: Required<V1MetricValue>;
          };
          // properties required, but not included in query
          raw: never;
        }
      >;
      tenant_meta: never;
    }
  >
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryHuggingFaceModels(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'HuggingFaceModel',
        list_parameters: {
          ...rootListParams,
        },
        references: [
          {
            connect_from: 'spec.project_uuid',
            connect_to: 'spec.project_uuid',
            query_spec: {
              kind: 'Metric',
              return_as: 'ScorecardMetric',
              list_parameters: {
                filter: 'meta.name == "model_scorecard"',
                page_size: 1,
                mask: ['spec.metric_values', 'spec.project_uuid'].join(','),
              },
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

const queryHuggingFaceModels = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryHuggingFaceModelsResponse;
};

/**
 * Custom query for Hugging Face models
 */
export const useQueryHuggingFaceModels = (
  namespace: string,
  listParams: ListRequestParameters = {},
  options: ResourceQueryOptions<QueryHuggingFaceModelsResponse> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'HuggingFaceModel',
    'LIST',
    listParams,
    options
  );

  return useQuery(
    QueryHuggingFaceModelsQueryKeys.query(namespace, requestParameters),
    async ({ signal }) =>
      queryHuggingFaceModels(namespace, requestParameters, signal),
    { staleTime: Infinity }
  );
};
