import { Grid, IconButton, Stack, Typography } from '@mui/material';
import _noop from 'lodash-es/noop';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage as FM } from 'react-intl';

import { IconChevronLeft, IconChevronRight } from '../../themes';
import { MAX_PATHS_LIMIT } from './constants';

interface DependencyPathDisplayHeaderProps {
  count: number;
  current: number;
  showControls?: boolean;
  title?: ReactNode;
  updateCurrent?: (n: number) => void;
}

export const DependencyPathDisplayHeader = ({
  count,
  current,
  showControls = true,
  title,
  updateCurrent = _noop,
}: DependencyPathDisplayHeaderProps) => {
  const { formatMessage: fm, formatNumber } = useIntl();

  const currentPath = formatNumber(current + 1, {
    notation: 'compact',
  });

  const isLimitReached = count >= MAX_PATHS_LIMIT;
  let totalPathCount = formatNumber(count, { notation: 'compact' });
  if (isLimitReached) {
    totalPathCount =
      formatNumber(MAX_PATHS_LIMIT, { notation: 'compact' }) + '+';
  }

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      <Grid item>
        {title ?? (
          <Typography variant="h3">
            <FM defaultMessage="Dependency Path" />
          </Typography>
        )}
      </Grid>

      {showControls && (
        <Grid display="flex" justifyContent="flex-end" flexShrink={0} item>
          <Stack
            alignItems="center"
            direction="row"
            // HACK: prevent collapsing an accordion in the drawer when
            // interacting with the control.
            // When one of the IconButtons below is disabled, the event is not
            // caught by the respective onClick, and bubbles up to this element
            onClick={(evt) => evt.stopPropagation()}
          >
            <IconButton
              aria-label={fm({ defaultMessage: 'Previous path' })}
              disabled={current === 0}
              onClick={(event) => {
                updateCurrent(current - 1);
              }}
            >
              <IconChevronLeft />
            </IconButton>
            <Typography variant="body2">
              <FM
                defaultMessage="{currentPath} of {totalPathCount} paths"
                values={{
                  currentPath,
                  totalPathCount,
                }}
              />
            </Typography>
            <IconButton
              aria-label={fm({ defaultMessage: 'Next path' })}
              disabled={current === count - 1}
              onClick={(event) => {
                updateCurrent(current + 1);
              }}
            >
              <IconChevronRight />
            </IconButton>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
