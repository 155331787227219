import {
  Box,
  Skeleton,
  Stack,
  SvgIcon,
  SvgIconProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { JSXElementConstructor, ReactNode, useCallback } from 'react';

import { FindingLevelChipProps } from '../../domains/Findings';
import { useStyles, UseStylesFunction } from '../../hooks';
import { ICON_SIZES } from '../../themes/endor/SvgIcons';
import { ImgIcon } from '../../themes/icons/ImgIcons';
import { TShirtSize } from '../../types';
import { LinkSecondary } from '../Link';
import { RowStack } from '../RowStack';

export interface IconTitleDisplayProps {
  Icon?: typeof SvgIcon | ImgIcon | JSXElementConstructor<SvgIconProps>;
  iconFrame?: boolean;
  IconProps?: SvgIconProps | FindingLevelChipProps;
  lineBreak?: string;
  showIcon?: boolean;
  size?: TShirtSize;
  title?: ReactNode;
  url?: string;
  namespace?: string;
}

type StyleOptions = Pick<IconTitleDisplayProps, 'lineBreak'>;

/**
 * Utility component meant to be extended.
 * Standardizes common proportions of icons and typography.
 */
export const IconTitleDisplay = ({
  Icon,
  iconFrame = false,
  IconProps = {},
  lineBreak = 'anywhere',
  showIcon = true,
  size = 'small',
  title,
  url,
  namespace,
}: IconTitleDisplayProps) => {
  const { palette, shape, space } = useTheme();
  const sx = useStyles<StyleOptions>(styles, {
    lineBreak,
  });

  const getTypeVariant = useCallback((size?: TShirtSize) => {
    switch (size) {
      case 'xsmall':
        return 'body2';
      case 'small':
        return 'body1';
      case 'medium':
        return 'button';
      case 'large':
      case 'xlarge':
        return 'h3';
      default:
        return 'body1';
    }
  }, []);

  const getIconSize = useCallback((size?: TShirtSize) => {
    switch (size) {
      case 'xsmall':
        return 'xsmall';
      case 'small':
        return 'small';
      case 'medium':
        return 'small';
      case 'large':
        return 'medium';
      case 'xlarge':
        return 'large';
      default:
        return 'small';
    }
  }, []);

  const getPadding = useCallback((size?: TShirtSize) => {
    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
        return 1;
      case 'large':
      case 'xlarge':
        return 2;
      default:
        return 1;
    }
  }, []);
  const getBorderRadius = useCallback((size?: TShirtSize) => {
    switch (size) {
      case 'xsmall':
      case 'small':
      case 'medium':
        return 1;
      case 'large':
      case 'xlarge':
        return 2;
      default:
        return 1;
    }
  }, []);

  let iconNode = Icon ? (
    <Icon
      className="IconTitleDisplay-icon"
      {...IconProps}
      fontSize={getIconSize(size)}
    />
  ) : (
    <Skeleton sx={{ transform: 'scale(85%)' }} width={ICON_SIZES[size] ?? 16} />
  );

  if (iconFrame) {
    iconNode = (
      <Box
        className="IconTitleDisplay-iconFrame"
        sx={{
          display: 'flex',
          padding: getPadding(size),
          border: `1px solid ${palette.divider}`,
          borderRadius: getBorderRadius(size),
          // marginTop: '-0.25em',
        }}
      >
        {iconNode}
      </Box>
    );
  }

  const classNames = clsx({
    'IconTitleDisplay-root': true,
    'IconTitleDisplay-isFramed': iconFrame,
    [`IconTitleDisplay-size${size}`]: true,
  });

  const display = (
    <RowStack
      alignItems="flex-start"
      className={classNames}
      display="inline-flex"
      flexShrink={0}
      flexWrap="nowrap"
      gap={space.xs}
      maxWidth="100%"
      sx={sx}
    >
      {showIcon && iconNode}
      {namespace ? (
        <Stack direction="column">
          <Typography
            className="IconTitleDisplay-title"
            component="div"
            variant={getTypeVariant('medium')}
          >
            {title}
          </Typography>
          <Typography
            className="IconTitleDisplay-namespace"
            component="div"
            variant="caption"
            color="text.secondary"
          >
            {namespace}
          </Typography>
        </Stack>
      ) : (
        <Typography
          className="IconTitleDisplay-title"
          component="div"
          variant={getTypeVariant(size)}
        >
          {title}
        </Typography>
      )}
    </RowStack>
  );

  return url ? (
    <LinkSecondary onClick={(e) => e.stopPropagation()} to={url}>
      {display}
    </LinkSecondary>
  ) : (
    display
  );
};

const styles: UseStylesFunction<StyleOptions> = (
  theme: Theme,
  // @ts-expect-error - I give up. "lineBreak" does not exist.
  { lineBreak }
) => {
  return {
    '&': {
      lineHeight: '1.2',
    },

    '& .IconTitleDisplay-title': {
      lineBreak,
      /**
       * NOTE: The goal here is to:
       * 1) Use the minimal possible line-height possible so icon remains visually aligned with the top line of text.
       * 2) But still prevent the text from looking awful when it does wrap.
       */
      lineHeight: '1.2',
    },

    // xsmall size needs a minor position tweak
    '&.IconTitleDisplay-sizexsmall:not(.IconTitleDisplay-isFramed)': {
      '& .IconTitleDisplay-icon': {
        marginTop: '1px',
      },
    },

    // When icon is framed, adjust vertical alignment to account for the frame.
    // Slightly shift the title up & the icon down to minimize overall layout impact.
    '&.IconTitleDisplay-isFramed': {
      '& .IconTitleDisplay-iconFrame': {
        marginTop: '-0.125em',
      },

      '& .IconTitleDisplay-title': {
        marginTop: '0.125em',
      },
    },
  };
};
