import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  Link,
  NilDisplay,
  NumberDisplay,
  ProjectInfoCacheEntry,
  ProjectNameDisplay,
} from '@endorlabs/ui-common';

import { getProjectPath } from '../../../routes';

export type PackagesIndexDetailSummaryProps = {
  latestPackageVersionRef?: string;
  packageNamespace: string;
  packageVersionCount: number;
  projectInfo?: ProjectInfoCacheEntry;
  repositoryUrl?: string;
};

export const PackagesIndexDetailSummary = ({
  latestPackageVersionRef,
  packageNamespace,
  packageVersionCount,
  projectInfo,
  repositoryUrl,
}: PackagesIndexDetailSummaryProps) => {
  return (
    <Stack
      flexWrap="wrap"
      spacing={{ lg: 2, xs: 1 }}
      direction={{ lg: 'row', xs: 'column' }}
      divider={
        <Typography
          component="span"
          color="text.secondary"
          sx={({ breakpoints }) => ({
            [breakpoints.down('lg')]: {
              // Hide when list switches to column layout
              display: 'none',
            },
          })}
        >
          &middot;
        </Typography>
      }
    >
      <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
        <Typography component="span" color="text.secondary">
          <FM defaultMessage="Versions" />
        </Typography>
        <Typography component="span" color="text.primary">
          {packageVersionCount ? (
            <NumberDisplay value={packageVersionCount} />
          ) : (
            <NilDisplay variant="text" />
          )}
        </Typography>
      </Box>

      {latestPackageVersionRef && (
        <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
          <Typography component="span" color="text.secondary">
            <FM defaultMessage="Latest Version" />
          </Typography>
          <Typography component="span" color="text.primary">
            {latestPackageVersionRef}
          </Typography>
        </Box>
      )}

      {projectInfo && (
        <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
          <Typography component="span" color="text.secondary">
            <FM defaultMessage="Project" />
          </Typography>
          {projectInfo.name ? (
            <Link
              to={getProjectPath({
                tenantName: packageNamespace,
                uuid: projectInfo.uuid,
              })}
            >
              <ProjectNameDisplay
                name={projectInfo.name}
                platformSource={projectInfo.platformSource}
              />
            </Link>
          ) : (
            <Tooltip
              title={<FM defaultMessage="No Project found for this Package" />}
            >
              <NilDisplay fontSize="inherit" />
            </Tooltip>
          )}
        </Box>
      )}

      {repositoryUrl && (
        <Box sx={{ display: 'inline-flex', gap: 1, alignItems: 'center' }}>
          <Typography component="span" color="text.secondary">
            <FM defaultMessage="Repository" />
          </Typography>
          <Typography component="span" color="text.primary">
            <Link
              to={repositoryUrl}
              target="_blank"
              onClick={(event) => event.stopPropagation()}
            >
              {repositoryUrl}
            </Link>
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
