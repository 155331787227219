import { ToggleButtonGroup, Tooltip, useTheme } from '@mui/material';
import { typography } from '@mui/system';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import {
  FindingLevelLabel,
  ToggleButton,
  UIFindingUtils,
} from '@endorlabs/ui-common';

import { findingLevelFilterOptions } from './constants';

export const FindingLevelToggles = ({
  allowEmptyValue,
  onChange,
  value,
}: {
  allowEmptyValue?: boolean;
  onChange: (filter: SpecFindingLevel[]) => void;
  value?: SpecFindingLevel[];
}) => {
  const theme = useTheme();

  const handleLevelToggle = (
    event: React.MouseEvent<HTMLElement>,
    newFilterList: SpecFindingLevel[]
  ) => {
    if (newFilterList.length > 0 || allowEmptyValue) {
      onChange(newFilterList);
    }
  };

  // allow the toggle buttons to display as _all_ selected when no value is selected
  const checkSelected = (target: SpecFindingLevel) => {
    const isEmptyValue = !value?.length;
    const isSelected = value?.includes(target);

    return isSelected || (allowEmptyValue && isEmptyValue);
  };

  return (
    <ToggleButtonGroup size="small" value={value} onChange={handleLevelToggle}>
      {findingLevelFilterOptions.map((filter, index) => (
        <Tooltip
          key={`toggle-${filter}-${index}`}
          title={
            <FM
              defaultMessage="{level} Findings"
              values={{
                level: <FindingLevelLabel findingLevel={filter} />,
              }}
            />
          }
        >
          <ToggleButton
            selected={checkSelected(filter)}
            selectedcolor={UIFindingUtils.getSeverityColor(filter, theme)}
            size="small"
            sx={({ spacing, typography }) => ({
              '&.MuiToggleButton-sizeSmall': {
                fontSize: typography.body2.fontSize,
                padding: `${spacing(0.5)} ${spacing(2.5)}`,
              },
            })}
            value={filter}
          >
            <FindingLevelLabel findingLevel={filter} abbreviate />
          </ToggleButton>
        </Tooltip>
      ))}
    </ToggleButtonGroup>
  );
};
