import { Box, Stack } from '@mui/material';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { RepoVersionResource } from '@endorlabs/queries';

import { useInfoDrawer } from '../../components';
import { ProjectVersionDependencies } from './ProjectVersionDependencies';
import { ProjectVersionPackages } from './ProjectVersionPackages';
import { ProjectVersionPageNavigation } from './ProjectVersionPageNavigation';

type ProjectVersionInventoryViewProps = {
  activeView: string;
  project: ProjectResource;
  projectVersion: ProjectVersionResource;
};

export const ProjectVersionInventoryView = ({
  activeView,
  project,
  projectVersion,
}: ProjectVersionInventoryViewProps) => {
  const infoDrawer = useInfoDrawer();

  const showPageNavigation = !infoDrawer.isOpen;

  return (
    <Stack direction="row" alignItems="flex-start" gap={6} width="100%">
      {showPageNavigation && (
        <ProjectVersionPageNavigation
          project={project}
          projectVersion={projectVersion}
        />
      )}

      <Box flexGrow={1} paddingTop={2} overflow="auto">
        {activeView === 'dependencies' && (
          <ProjectVersionDependencies
            namespace={project.tenant_meta.namespace}
            project={project}
            repositoryVersion={projectVersion as RepoVersionResource}
          />
        )}

        {activeView === 'packages' && (
          <ProjectVersionPackages
            namespace={project.tenant_meta.namespace}
            project={project}
            repositoryVersion={projectVersion as RepoVersionResource}
          />
        )}
      </Box>
    </Stack>
  );
};
