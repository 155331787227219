import { Paper, Stack } from '@mui/material';
import { partition as _partition } from 'lodash-es';

import { PackageVersionResource } from '@endorlabs/queries';
import { useStyles, useTanstackTableRef } from '@endorlabs/ui-common';

import {
  ContainerImagesTable,
  ContainerImagesTableRow,
  ContainerLayersTable,
  ContainerLayersTableRow,
  useContainerDetailDrawer,
  useContainerLayerData,
} from '../../domains/Containers';

type PackageVersionDetailContainerLayersProps = {
  packageVersion?: PackageVersionResource;
};

export const PackageVersionDetailContainerLayers = ({
  packageVersion,
}: PackageVersionDetailContainerLayersProps) => {
  const tableWrapperStyles = useStyles((t) => ({
    border: `1px solid ${t.palette.divider}`,
    borderRadius: `${t.shape.borderRadius}px`,
    overflow: 'hidden',
  }));

  const { data, isLoading } = useContainerLayerData({
    packageVersion,
  });

  const showImagesTable = data.images.length > 0;

  const { DetailDrawer } = useContainerDetailDrawer();

  const imagesTableRef = useTanstackTableRef<ContainerImagesTableRow>();
  const layersTableRef = useTanstackTableRef<ContainerLayersTableRow>();

  const handleImageDetailClick = (row: ContainerImagesTableRow) => {
    if (!packageVersion) return;

    imagesTableRef.current?.setRowSelection({ [row.key]: true });

    const [baseLayers, otherLayers] = _partition(
      data.layers,
      (d) => d.isBaseImageLayer
    );

    const selected = row.isBaseImage
      ? Object.fromEntries(baseLayers.map((l) => [l.digest, true]))
      : Object.fromEntries(otherLayers.map((l) => [l.digest, true]));
    layersTableRef.current?.setRowSelection(selected);

    const params = {
      containerNamespace: packageVersion.tenant_meta.namespace,
      containerUuid: packageVersion.uuid,
      imageName: row.name,
    };

    DetailDrawer.activate(params, params);
  };

  const handleLayerDetailClick = (row: ContainerLayersTableRow) => {
    if (!packageVersion) return;

    const props = {
      containerNamespace: packageVersion.tenant_meta.namespace,
      containerUuid: packageVersion.uuid,
      layerIndex: row.layerIndex,
    };

    // Drawer params expects string value keys
    const params = { ...props, layerIndex: String(row.layerIndex) };

    DetailDrawer.activate(params, props);

    layersTableRef.current?.setRowSelection({ [row.digest]: true });
    imagesTableRef.current?.setRowSelection({});
  };

  return (
    <Stack direction="column" gap={4}>
      {showImagesTable && (
        <Paper sx={tableWrapperStyles}>
          <ContainerImagesTable
            ref={imagesTableRef}
            data={data.images}
            onClickDetail={handleImageDetailClick}
          />
        </Paper>
      )}

      <Paper sx={tableWrapperStyles}>
        <ContainerLayersTable
          ref={layersTableRef}
          data={data.layers}
          isLoading={isLoading}
          onClickDetail={handleLayerDetailClick}
        />
      </Paper>
    </Stack>
  );
};
