import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useEffect, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { SortEntrySortEntryOrder } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  selectMetricScoresByCategory,
  useQueryHuggingFaceModels,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  IconAlertTriangle,
  useDataTablePaginator,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import {
  FilterBar,
  useFilterContext,
  withFilterProvider,
} from '../../domains/filters';
import {
  HuggingFaceModelsTable,
  HuggingFaceModelsTableRow,
  OSSExplorerPageSource,
} from '../../domains/OSS';
import { getOSSExplorerPath } from '../../routes';

export const BaseOSSExplorerHuggingFaceModelsView = () => {
  const { formatMessage: fm } = useIntl();

  const navigate = useNavigate();

  const { space } = useTheme();

  const {
    filter: filterExpression,
    _state: filterState,
    updateFilter,
  } = useFilterContext();

  const { getErrorMessage } = useResourceCRUDMessages();

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () =>
      !!qHuggingFaceModels.data?.list?.response?.next_page_token,
  });

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginator.resetPagination, filterExpression]
  );

  const queryOptions = useMemo(() => {
    const sortParams = {
      path: 'spec.downloads',
      order: SortEntrySortEntryOrder.Desc,
    };

    const fieldMask = [
      'meta.name',
      'spec.downloads',
      'spec.name',
      'spec.project_uuid',
      'uuid',
    ].join(',');

    if (filterExpression) {
      return {
        filter: filterExpression,
        mask: fieldMask,
        sort: sortParams,
        ...paginator.getListParameters(),
      };
    }
    return {
      mask: fieldMask,
      page_size: 5,
      sort: sortParams,
    };
  }, [paginator, filterExpression]);

  const qHuggingFaceModels = useQueryHuggingFaceModels(
    NAMESPACES.OSS,
    queryOptions
  );

  const data: HuggingFaceModelsTableRow[] = useMemo(() => {
    const models =
      qHuggingFaceModels.data?.list?.objects.map((model) => {
        const scorecardMetric =
          model.meta.references.ScorecardMetric.list?.objects[0];

        const scores = selectMetricScoresByCategory(scorecardMetric);

        return {
          name: model.spec.name,
          uuid: model.uuid,
          ...scores,
        };
      }) ?? [];
    return models;
  }, [qHuggingFaceModels.data?.list?.objects]);

  const errorAlert = useMemo(() => {
    if (!qHuggingFaceModels.isError) return;

    // Get default error message
    const errorMessage = getErrorMessage(
      'LIST',
      'Package',
      qHuggingFaceModels.error
    );
    return (
      <Alert icon={<IconAlertTriangle />} severity="error">
        <AlertTitle>{errorMessage.message}</AlertTitle>
        {errorMessage.details}
      </Alert>
    );
  }, [getErrorMessage, qHuggingFaceModels.error, qHuggingFaceModels.isError]);

  const handleSearch = () => {
    if (filterExpression) {
      const searchValue = filterState.search;
      updateFilter({ search: searchValue });
    }
  };

  return (
    <>
      <Grid container justifyContent="center">
        {errorAlert && (
          <Grid item width={{ sm: '100%', md: '60%' }}>
            {errorAlert}
          </Grid>
        )}

        <Grid item marginBottom={space.lg} width={{ sm: '100%', md: '60%' }}>
          <FilterBar
            fields={[]}
            searchPlaceholder={fm({
              defaultMessage: 'Search for an AI/LLM model',
            })}
          />
        </Grid>

        <Grid item marginBottom={space.lg} width={{ sm: '100%', md: '60%' }}>
          <Stack direction="row" justifyContent="center" spacing={6}>
            <ButtonPrimary onClick={handleSearch}>
              <FM defaultMessage="Search AI/LLM Models" />
            </ButtonPrimary>
          </Stack>
        </Grid>

        <Grid
          item
          role="region"
          aria-labelledby="search-results-summary"
          id="oss-result-container"
          justifyContent="center"
          width="100%"
        >
          <Typography
            id="search-results-summary"
            variant="h3"
            textAlign="center"
            marginBottom={space.sm}
          >
            {filterExpression ? (
              <FM defaultMessage="Search Results" />
            ) : (
              <FM defaultMessage="Top Hugging Face Models" />
            )}
          </Typography>

          <Card>
            <CardContent>
              <HuggingFaceModelsTable
                data={data}
                emptyStateProps={{
                  description: (
                    <FM defaultMessage="Explore other OSS Packages" />
                  ),
                  title: <FM defaultMessage="No results found" />,
                }}
                isLoading={qHuggingFaceModels.isLoading}
                onClickDetail={(row) => {
                  navigate({
                    to: getOSSExplorerPath({
                      section: OSSExplorerPageSource.HuggingFace,
                      uuid: row.uuid,
                    }),
                  });
                }}
                paginator={filterExpression ? paginator : undefined}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Wire Filter Context to page
 */
export const OSSExplorerHuggingFaceModelsView = withFilterProvider(
  BaseOSSExplorerHuggingFaceModelsView,
  {
    displayName: 'OSSExplorerHuggingFaceModelsView',
    searchKeys: ['spec.name'],
  }
);
