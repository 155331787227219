import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { RefObject } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PackageVersionResource } from '@endorlabs/queries';
import {
  DrawerAccordion,
  FileDownloadButton,
  ResolutionStatusErrorLabel,
} from '@endorlabs/ui-common';

import { PackageVersionResolution } from './usePackageVersionResolutionErrors';

/**
 * Displays a list of Package Version resolution errors
 */
export const ScanIssuesDetailPackageVersionResolutions = ({
  focusElementRef,
  focusResource,
  packageVersionResolutions: packageVersions,
}: {
  focusElementRef?: RefObject<HTMLDivElement>;
  focusResource?: Pick<PackageVersionResource, 'uuid'>;
  packageVersionResolutions: PackageVersionResolution[];
}) => {
  return (
    <Stack spacing={6} marginTop={4}>
      {packageVersions.map((pkg) => (
        <DrawerAccordion
          accordionSummaryRef={
            // set the ref if we should focus this accordion
            focusResource?.uuid === pkg.uuid ? focusElementRef : undefined
          }
          id={`PackageVersionResolution-${pkg.uuid}`}
          expanded={!focusResource || focusResource.uuid === pkg.uuid}
          key={pkg.uuid}
          titleNode={
            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
              <FM
                defaultMessage="Scan issues related to Package {packageName}@{versionRef}"
                values={{
                  packageName: pkg.packageName.replaceAll(/-/g, '_'),
                  versionRef: pkg.versionRef,
                }}
              />
            </Typography>
          }
        >
          <Stack spacing={4}>
            {pkg.errors.map((error) => (
              <Alert
                key={error.key}
                severity="error"
                action={
                  pkg.data && (
                    <FileDownloadButton
                      data={pkg.data}
                      filename={`${pkg.namespace}_scan-results_${pkg.uuid}.json`}
                      filetype="json"
                      size="small"
                    />
                  )
                }
              >
                <AlertTitle>
                  <ResolutionStatusErrorLabel
                    statusError={error.status_error}
                  />
                </AlertTitle>

                <Typography variant="body2">{error.description}</Typography>
              </Alert>
            ))}
          </Stack>
        </DrawerAccordion>
      ))}
    </Stack>
  );
};
