import { without as _without } from 'lodash-es';
import { LiteralUnion, ValueOf } from 'type-fest';

import { V1Language } from '@endorlabs/api_client';

export const LANGUAGE_MAP = Object.freeze({
  [V1Language.C]: 'c',
  [V1Language.Csharp]: 'c#',
  [V1Language.Go]: 'go',
  [V1Language.Java]: 'java',
  [V1Language.Js]: 'javascript',
  [V1Language.Kotlin]: 'kotlin',
  [V1Language.Objectivec]: 'objective-c',
  [V1Language.Php]: 'php',
  [V1Language.Python]: 'python',
  [V1Language.Ruby]: 'ruby',
  [V1Language.Rust]: 'rust',
  [V1Language.Scala]: 'scala',
  [V1Language.Swift]: 'swift',
  [V1Language.Typescript]: 'typescript',
}) satisfies Partial<Record<V1Language, string>>;

export type LanguageString = LiteralUnion<ValueOf<typeof LANGUAGE_MAP>, string>;

export const SUPPORTED_SCAN_LANGUAGES = Object.keys(
  LANGUAGE_MAP
).sort() as V1Language[];

export const SUPPORTED_CALL_GRAPH_LANGUAGES = _without(
  SUPPORTED_SCAN_LANGUAGES,
  V1Language.C,
  V1Language.Js,
  V1Language.Typescript,
  V1Language.Php,
  V1Language.Ruby,
  V1Language.Swift,
  V1Language.Objectivec
);
