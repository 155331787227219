import { Grid, Typography } from '@mui/material';
import { MakeGenerics } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ContextContextType } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import {
  useGetDependencyMetadata,
  useGetPackageVersion,
} from '@endorlabs/queries';
import {
  EmptyState,
  NavigationTabs,
  PackageIconDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import {
  PackageVersionGlobalViewLinkButton,
  PageHeader,
} from '../../components';
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../constants';
import { AssuredPackageVersionDetailView } from '../../domains/AssuredPackageVersion';
import { useAuthInfo } from '../../providers';
import { getDependencyPath, useFullMatch, usePageTitle } from '../../routes';
import { PackageVersionDetailDependencyGraph } from '../PackageVersions/PackageVersionDetailDependencyGraph';
import { PackageVersionDetailFindings } from '../PackageVersions/PackageVersionDetailFindings';
import { usePackageVersionMetadata } from '../PackageVersions/usePackageVersionMetadata';
// import { DependencyDetailCompare } from './DependencyDetailCompare';
import { DependencyDetailDependencies } from './DependencyDetailDependencies';
import { DependencyDetailDependents } from './DependencyDetailDependents';
import { DependencyDetailOverviewLogic } from './DependencyDetailOverviewLogic';
import {
  DependencyDetailTabNames,
  useDependencyDetailTabs,
} from './useDependencyDetailTabs';

type DependencyDetailLocationGenerics = MakeGenerics<{
  Params: {
    dependencyUuid: string;
    activeView: string;
  };
}>;

/**
 * Dependency Detail page
 *
 * Loads details about the Dependency from the given Package Version Resource
 */
export const DependencyDetailPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { dependencyUuid, activeView },
  } = useFullMatch<DependencyDetailLocationGenerics>();
  // data loading
  const qDependencyMetadata = useGetDependencyMetadata(
    {
      namespace: tenantName,
      uuid: dependencyUuid,
    },
    {
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    }
  );

  const dependencyPackageVersionMetadata =
    qDependencyMetadata.data?.spec.dependency_data;

  // fetch the dependency package version
  const qDependencyPackageVersion = useGetPackageVersion(
    {
      namespace: dependencyPackageVersionMetadata?.namespace ?? '',
      uuid: dependencyPackageVersionMetadata?.package_version_uuid ?? '',
    },
    {
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
      enabled: !!(
        dependencyPackageVersionMetadata?.namespace &&
        dependencyPackageVersionMetadata?.package_version_uuid
      ),
    }
  );

  // get the package version resource from the list
  const packageVersion = qDependencyPackageVersion.data;
  const {
    ecosystem,
    label,
    version: versionRef,
  } = useMemo(
    () =>
      UIPackageVersionUtils.parsePackageName(
        qDependencyMetadata.data?.meta.name ?? ''
      ),
    [qDependencyMetadata]
  );

  // get the metadata for the related package version
  // using the uuid from the response to delay, until the namespace is known
  const pageMetadata = usePackageVersionMetadata(
    packageVersion?.tenant_meta.namespace as string,
    true, // TODO: change to package/dependency instead of isGlobal
    packageVersion?.uuid
  );

  // calculated page state
  const isLoading =
    qDependencyMetadata.isLoading || qDependencyPackageVersion.isLoading;

  const { activeTab, tabRecords } = useDependencyDetailTabs({
    activeTab: activeView,
    namespace: tenantName,
    dependency: qDependencyMetadata.data,
    dependencyPackageVersion: packageVersion,
  });

  usePageTitle({
    packageName: label,
    packageVersion: versionRef,
  });

  const isMissingPackageVersion =
    !qDependencyPackageVersion.isLoading && !packageVersion;

  // hide link to package version page for the current dependency if context type is not main/ref
  const hasPackageVersionPage =
    packageVersion &&
    [ContextContextType.Main, ContextContextType.Ref].includes(
      packageVersion.context.type
    );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item data-testid="dependency-details-header">
        <PageHeader
          breadcrumbsLinks={[
            {
              url: getDependencyPath({ tenantName }),
              label: <FM defaultMessage="All Dependencies" />,
            },
          ]}
          breadcrumbsItems={[
            <>
              <PackageIconDisplay
                ecosystem={ecosystem}
                displayDefault="dependency"
                size="inherit"
              />
              <Typography variant="button">{label}</Typography>
            </>,
            <Typography key={versionRef} variant="button">
              {versionRef}
            </Typography>,
          ]}
          isLoading={isLoading}
          image={
            <PackageIconDisplay
              ecosystem={ecosystem}
              displayDefault="dependency"
              size="large"
            />
          }
          title={
            label || (
              <Typography variant="inherit" color="text.secondary">
                <FM defaultMessage="Unknown Package" />
              </Typography>
            )
          }
          titleDetails={
            hasPackageVersionPage ? (
              <PackageVersionGlobalViewLinkButton
                packageVersion={packageVersion}
              />
            ) : (
              <Typography variant="h1" component="span" color="grey.500">
                {versionRef}
              </Typography>
            )
          }
          metadata={pageMetadata}
        />
      </Grid>

      {isMissingPackageVersion && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="Dependency Details Not Found" />}
            description={
              <FM defaultMessage="The dependency package version may have been deleted, or has not yet been scanned." />
            }
          />
        </Grid>
      )}

      {!isMissingPackageVersion && (
        <>
          <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
            <NavigationTabs
              data-testid="dependency-details-tab"
              activeTab={activeTab}
              tabs={tabRecords}
            />
          </Grid>

          <Grid item>
            {activeTab === DependencyDetailTabNames.OVERVIEW && (
              <DependencyDetailOverviewLogic
                namespace={tenantName}
                packageVersion={packageVersion}
              />
            )}
            {activeTab === DependencyDetailTabNames.FINDINGS && (
              <PackageVersionDetailFindings
                defaultFindingSource={FindingSource.Package}
                tenantName={packageVersion?.tenant_meta.namespace as string}
                packageVersion={packageVersion}
              />
            )}
            {activeTab === DependencyDetailTabNames.DEPENDENTS && (
              <DependencyDetailDependents
                tenantName={tenantName}
                dependencyPackageVersion={packageVersion}
              />
            )}
            {activeTab === DependencyDetailTabNames.DEPENDENCIES && (
              <DependencyDetailDependencies
                tenantName={tenantName}
                dependencyPackageVersion={packageVersion}
              />
            )}
            {activeTab === DependencyDetailTabNames.DEP_GRAPH && (
              <PackageVersionDetailDependencyGraph
                tenantName={packageVersion?.tenant_meta.namespace as string}
                packageVersion={packageVersion}
              />
            )}
            {activeTab === DependencyDetailTabNames.ENDOR_PATCH && (
              <AssuredPackageVersionDetailView
                namespace={packageVersion?.tenant_meta.namespace as string}
                packageVersionName={packageVersion?.meta.name}
              />
            )}
            {/* TODO: compare tab
            activeTab === DependencyDetailTabNames.COMPARE && (
              <DependencyDetailCompare />
            )
            */}
          </Grid>
        </>
      )}
    </Grid>
  );
};
