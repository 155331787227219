import { useCallback, useState } from 'react';

import { AuthorizationPolicyResource } from '@endorlabs/endor-core/AuthorizationPolicy';
import {
  IdentityProviderResource,
  useCreateAuthorizationPolicy,
  useUpdateAuthorizationPolicy,
} from '@endorlabs/queries';

import {
  AuthorizationPolicyUpsertDialog,
  AuthorizationPolicyUpsertDialogProps,
} from '../components';

/**
 * Utility hook to Manage Auth Policy Upsert Dialog
 */
export const useAuthorizationPolicyUpsertDialog = () => {
  const [authPolicyState, setAuthPolicyState] = useState<
    | {
        namespace: string;
        authorizationPolicy?: AuthorizationPolicyResource;
        identityProvider?: IdentityProviderResource;
      }
    | undefined
  >(undefined);
  const isOpen = Boolean(authPolicyState);

  const qCreateAuthPolicy = useCreateAuthorizationPolicy({
    // NOTE: error for API call is handled in the form
    onError: () => undefined,
  });
  const qUpdateAuthPolicy = useUpdateAuthorizationPolicy({
    // NOTE: error for API call is handled in the form
    onError: () => undefined,
  });

  const handleOpenDialog = useCallback(
    (props: {
      namespace: string;
      authorizationPolicy?: AuthorizationPolicyResource;
      identityProvider?: IdentityProviderResource;
    }) => {
      setAuthPolicyState(props);
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setAuthPolicyState(undefined);
    // ensure possible errors are reset on dialog close
    qCreateAuthPolicy.reset();
    qUpdateAuthPolicy.reset();
  }, [qCreateAuthPolicy, qUpdateAuthPolicy]);

  const serverError =
    qCreateAuthPolicy.error ?? qUpdateAuthPolicy.error ?? undefined;

  const handleSubmit = useCallback(
    (model: AuthorizationPolicyResource) => {
      // TODO: handle possible edge case?
      if (!authPolicyState) return;

      const { namespace, authorizationPolicy } = authPolicyState;

      // field mask for update
      const updateMask = ['meta.name', 'spec', 'propagate'].join();

      // if there is an existing auth policy to be updated
      if (authorizationPolicy) {
        qUpdateAuthPolicy.mutate(
          { namespace, resource: model, mask: updateMask },
          { onSuccess: () => handleCloseDialog() }
        );
      } else {
        qCreateAuthPolicy.mutate(
          { namespace, resource: model },
          { onSuccess: () => handleCloseDialog() }
        );
      }
    },
    [authPolicyState, handleCloseDialog, qCreateAuthPolicy, qUpdateAuthPolicy]
  );

  const getAuthorizationPolicyUpsertDialogProps = useCallback<
    () => AuthorizationPolicyUpsertDialogProps
  >(
    () => ({
      open: isOpen,
      onClose: handleCloseDialog,
      onSubmit: handleSubmit,
      authorizationPolicy: authPolicyState?.authorizationPolicy,
      authorizationPolicyIdentityProvider: authPolicyState?.identityProvider,
      authorizationPolicyNamespace: authPolicyState?.namespace ?? '',
      serverError,
    }),
    [
      authPolicyState?.authorizationPolicy,
      authPolicyState?.identityProvider,
      authPolicyState?.namespace,
      handleCloseDialog,
      handleSubmit,
      isOpen,
      serverError,
    ]
  );

  return {
    Dialog: AuthorizationPolicyUpsertDialog,
    openAuthorizationPolicyUpsertDialog: handleOpenDialog,
    getAuthorizationPolicyUpsertDialogProps,
  };
};
