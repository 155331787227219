import { Box } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
} from '@endorlabs/ui-common';

import { PolicyTemplateOwnerLabels, PolicyTypeLabels } from '../locales';

export type PolicyTemplatesTableRowActionHandler = (uuid: string) => void;

export interface PolicyTemplatesTableRow {
  description?: string;
  name: string;
  namespace: string;
  policyType: PolicyPolicyType;
  uuid: string;
}

/**
 * Build the Table columns, including actions when handlers are provided
 */
const buildPoliciesTableColDefs = ({
  createPolicy,
  onDelete,
  onEdit,
}: {
  createPolicy?: (row: PolicyTemplatesTableRow) => void;
  onDelete?: PolicyTemplatesTableRowActionHandler;
  onEdit?: PolicyTemplatesTableRowActionHandler;
}) => {
  const columns: DataTableColumnDef<PolicyTemplatesTableRow>[] = [
    {
      accessorKey: 'name',
      header: () => <FM defaultMessage="Name" />,
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
    },
    {
      accessorKey: 'namespace',
      cell: (t) => {
        const labelKey = t.getValue() === 'system' ? 'system' : 'other';

        // TODO: show namespace value when defined by tenant

        return (
          <FM
            {...PolicyTemplateOwnerLabels[
              labelKey as keyof typeof PolicyTemplateOwnerLabels
            ]}
          />
        );
      },
      header: () => <FM defaultMessage="Defined By" />,
    },
    {
      accessorKey: 'policyType',
      cell: (t) => {
        return (
          <FM
            {...PolicyTypeLabels[t.getValue() as keyof typeof PolicyTypeLabels]}
          />
        );
      },
      header: () => <FM defaultMessage="Type" />,
    },
  ];

  columns.push({
    id: 'actions',
    header: '',
    cell: ({ row }) => {
      const actions: DataTableActionDropdownItem[] = [];

      actions.push({
        label: <FM defaultMessage="Create Policy" />,
        onClick: () =>
          createPolicy && row.original && createPolicy(row.original),
      });

      if (onEdit) {
        actions.push({
          label: <FM defaultMessage="Edit Template" />,
          onClick: () => onEdit(row.getValue('uuid')),
        });
      }

      if (onDelete) {
        actions.push({
          isDestructive: true,
          label: <FM defaultMessage="Delete Template" />,
          onClick: () => onDelete(row.getValue('uuid')),
        });
      }

      return (
        <Box display="flex" justifyContent="flex-end">
          <DataTableActionDropdown items={actions} />
        </Box>
      );
    },
    colType: ColTypes.ACTIONS,
  });
  return columns;
};

export interface PolicyTemplatesTableProps
  extends Omit<DataTableProps<PolicyTemplatesTableRow>, 'columns'> {
  createPolicy?: (row: PolicyTemplatesTableRow) => void;
  onDelete?: PolicyTemplatesTableRowActionHandler;
  onEdit?: PolicyTemplatesTableRowActionHandler;
}

export const PolicyTemplatesTable = ({
  createPolicy,
  onDelete,
  onEdit,
  ...props
}: PolicyTemplatesTableProps) => {
  const columns = useMemo(
    () => buildPoliciesTableColDefs({ createPolicy, onDelete, onEdit }),
    [createPolicy, onDelete, onEdit]
  );

  return <DataTable {...props} columns={columns} />;
};
