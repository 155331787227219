import { useCallback, useMemo } from 'react';
import { ErrorOption, FieldPath } from 'react-hook-form';

import {
  PolicyValidationResult,
  V1PolicyValidationRequest,
} from '@endorlabs/api_client';
import {
  isQueryError,
  PolicyResource,
  useSubmitPolicyValidation,
} from '@endorlabs/queries';

type ValidateResult<TResult, TError> =
  | { ok: true; value: TResult }
  | { ok: false; error: TError };

interface FieldError extends ErrorOption {
  field: FieldPath<PolicyResource>; // string;
  message: string;
}

export type ValidateOptions = {
  /**
   * When set, will attempt to prevent the full validation of a policy against
   * real resources.
   */
  skipFullValidation?: boolean;
};

const POLICY_REGO_FIELD = 'spec.rule';
const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred.';
const SKIP_VALIDATION_SELECTOR = '__skip-validation__';
const ERROR_NO_MATCHING_PROJECTS = 'No matching projects';

export const useValidatePolicy = ({ namespace }: { namespace: string }) => {
  const hook = useSubmitPolicyValidation();

  const validate = useCallback(
    async (
      model: PolicyResource,
      options?: ValidateOptions
    ): Promise<ValidateResult<PolicyValidationResult, FieldError>> => {
      // TODO: handle validation for polices from template
      if (model.spec.template_uuid) {
        return {
          ok: true,
          value: { valid_policy: true } as PolicyValidationResult,
        };
      }

      // handle validation for rego policy
      if (!model.spec.rule || model.spec.rule?.trim() === '') {
        return {
          ok: false,
          error: {
            field: POLICY_REGO_FIELD,
            message: 'A policy rule is required.',
            type: 'validate',
          },
        };
      }

      // validate rego policy against API
      try {
        const {
          project_exceptions,
          project_selector,
          query_statements,
          rule,
          resource_kinds,
        } = model.spec;

        const validationRequest: V1PolicyValidationRequest = {
          project_exceptions,
          project_selector,
          query_statements,
          rule,
          resource_kinds,
        };

        // HACK: if option to skip full validation is set, use set selectors to
        // avoid matching against projects and related resources.
        if (options?.skipFullValidation) {
          validationRequest.project_selector = [SKIP_VALIDATION_SELECTOR];
          validationRequest.project_exceptions = [SKIP_VALIDATION_SELECTOR];
        }

        const validationResult = await hook.mutateAsync({
          namespace,
          validation: {
            meta: {
              name: `Validate Policy ${model.meta.name}`,
            },
            spec: {
              request: validationRequest,
            },
            tenant_meta: {
              namespace,
            },
          },
        });

        if (validationResult.spec.result?.valid_policy === false) {
          return {
            ok: false,
            error: {
              field: POLICY_REGO_FIELD,
              message:
                validationResult.spec.result?.validation_error ??
                DEFAULT_ERROR_MESSAGE,
              type: 'validate',
            },
          };
        }

        return {
          ok: true,
          value: validationResult.spec.result as PolicyValidationResult,
        };
      } catch (error) {
        let errorMessage = DEFAULT_ERROR_MESSAGE;
        let type = 'server';

        if (isQueryError(error) && error.response?.data?.message) {
          const responseMessage = error.response.data.message;

          if (
            options?.skipFullValidation &&
            responseMessage === ERROR_NO_MATCHING_PROJECTS
          ) {
            return {
              ok: true,
              value: { valid_policy: true } as PolicyValidationResult,
            };
          }

          // Handle timeout specific failure
          const responseStatus = error.response.status;
          const responseCode = error.response.data.code;

          if (responseStatus === 504 || responseCode === 4) {
            errorMessage =
              'The validation request timed out checking the policy against your projects.';
            type = 'server.timeout';
          } else {
            errorMessage = error.response.data.message;
          }
        }

        return {
          ok: false,
          error: {
            field: POLICY_REGO_FIELD,
            message: errorMessage,
            type,
          },
        };
      }
    },
    [hook, namespace]
  );

  return useMemo(
    () => ({ validate, isLoading: hook.isLoading }),
    [hook.isLoading, validate]
  );
};
