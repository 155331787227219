import {
  Alert,
  AlertTitle,
  Button,
  Snackbar,
  SnackbarProps,
  Typography,
} from '@mui/material';
import { isEqual as _isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import { AppNotification, AppNotificationAction } from './types';
import { useAppNotificationStore } from './useAppNotificationStore';

const DEFAULT_AUTO_HIDE_DURATION = 4000;

const getAppNotificationActionNode = (action?: AppNotificationAction) => {
  switch (action?.type) {
    case 'link':
      return (
        <Button
          color="inherit"
          href={action.value}
          variant="outlined"
          sx={{
            whiteSpace: 'nowrap',
            minWidth: 'auto',
          }}
        >
          {action.title}
        </Button>
      );
  }

  return null;
};

export interface AppNotificationContainerProps
  extends Pick<SnackbarProps, 'anchorOrigin' | 'autoHideDuration'> {
  resetKeys?: unknown[];
}

/***
 * @link https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
 */
export const AppNotificationContainer = ({
  anchorOrigin,
  autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
  resetKeys = [],
}: AppNotificationContainerProps) => {
  const { peekNotification, popNotification, resetNotifications } =
    useAppNotificationStore();
  const topNotification = peekNotification();

  const [visibleNotification, setVisibleNotification] = useState<
    AppNotification | undefined
  >(undefined);

  const resetKeysRef = useRef<unknown[] | null>(null);

  useEffect(() => {
    if (!topNotification) return;

    // debounce notification display
    const timer = setTimeout(() => {
      if (!visibleNotification) {
        setVisibleNotification(topNotification);
        return;
      }
    }, 300);

    return () => clearTimeout(timer);
  }, [topNotification, visibleNotification]);

  useEffect(() => {
    const previousResetKeys = resetKeysRef.current;

    // reset notifications when reset keys change
    if (!_isEqual(previousResetKeys, resetKeys)) {
      resetKeysRef.current = resetKeys;
      resetNotifications();
    }
  }, [resetKeys, resetNotifications]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    // ignore outside clicks
    if (reason === 'clickaway') {
      return;
    }

    popNotification();
  };

  const handleExited = () => {
    // should unset visible notification after transition
    setVisibleNotification(undefined);
  };

  if (!visibleNotification) return null;
  const { message, details, severity, action, autoHide } = visibleNotification;
  const notificationAutoHideDuration = autoHide ? autoHideDuration : null;
  const notificationColor = severity === 'info' ? 'primary' : severity;

  // should close when visible notification is no longer the top notification
  const isOpen = visibleNotification === topNotification;

  // get formatted action node, if present
  const actionNode = getAppNotificationActionNode(action);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={notificationAutoHideDuration}
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
    >
      <Alert
        color={notificationColor}
        // Set specific elevation
        elevation={12}
        onClose={handleClose}
        severity={severity}
        variant="filled"
        action={actionNode}
      >
        <AlertTitle>{message}</AlertTitle>
        {details && <Typography>{details}</Typography>}
      </Alert>
    </Snackbar>
  );
};
