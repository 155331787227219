import noop from 'lodash-es/noop';
import { QueryKey, useMutation, useQueryClient } from 'react-query';

import {
  PolicyValidationServiceApi,
  V1PolicyValidation,
  V1PolicyValidationSpec,
} from '@endorlabs/api_client';

import { ResourceMutateOptions } from './types';
import { getClientConfiguration } from './utils';

export interface PolicyValidationParams {
  namespace: string;
  validation: V1PolicyValidation;
}

type PolicyValidationOptions = ResourceMutateOptions<
  V1PolicyValidation,
  PolicyValidationParams
>;

const BASE_KEY = 'v1/policy_validation';
const QK = {
  validation: (namespace: string): QueryKey =>
    [BASE_KEY, 'post', namespace] as const,
};
export const PolicyValidationQueryKeys = QK;

const getApiService = () =>
  new PolicyValidationServiceApi(getClientConfiguration());

const submitPolicyValidation = async (
  namespace: string,
  validation: V1PolicyValidation
) => {
  const api = getApiService();
  const resp = await api.policyValidationServiceCreatePolicyValidation(
    namespace,
    validation
  );
  return resp.data as V1PolicyValidation;
};

export const useSubmitPolicyValidation = (
  opts: PolicyValidationOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: PolicyValidationParams) =>
      submitPolicyValidation(params.namespace, params.validation),
    {
      ...opts,
      // Invalidate policy list query for this tenant namespace
      onSuccess: (data, vars, context) => {
        queryClient.invalidateQueries(QK.validation(vars.namespace));
        (opts.onSuccess ?? noop)(data, vars, context);
      },
    }
  );
};
