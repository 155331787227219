import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import {
  AttributeDisplayRecord,
  AttributeDisplayStack,
  FindingAttributesArrayDisplay,
  FindingCategoriesArrayDisplay,
  Link,
  ProjectNameDisplayV2,
} from '@endorlabs/ui-common';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { getPoliciesPath, getProjectPath } from '../../../routes';
import { FindingDetailDrawerSectionProps } from '../types';
import {
  getFindingDependencyNameDisplay,
  getFindingPackageNameDisplay,
} from '../utils';

interface FindingPrimaryAttributesSectionProps
  extends FindingDetailDrawerSectionProps {
  packageVersion?: PackageVersionResource;
  project?: ProjectResource;
}

const genResource = ({
  finding,
  namespace,
  packageVersion,
  project,
}: FindingPrimaryAttributesSectionProps) => {
  const resource = {
    dependency: finding
      ? getFindingDependencyNameDisplay(finding, namespace, packageVersion)
      : undefined,

    finding_categories: finding?.spec?.finding_categories ? (
      <FindingCategoriesArrayDisplay
        value={finding?.spec?.finding_categories}
      />
    ) : undefined,

    finding_policy_info: finding?.spec.finding_metadata?.source_policy_info
      ?.uuid && (
      <Link
        to={getPoliciesPath({
          tenantName: getRootNamespace(finding.tenant_meta.namespace),
          section: `findings/edit/${finding?.spec.finding_metadata?.source_policy_info?.uuid}`,
        })}
      >
        {finding?.spec.finding_metadata?.source_policy_info.name}
      </Link>
    ),

    package: finding
      ? getFindingPackageNameDisplay(finding, namespace, packageVersion)
      : undefined,

    project: project && (
      <ProjectNameDisplayV2
        project={project}
        url={getProjectPath({
          tenantName: namespace,
          uuid: project.uuid,
        })}
      />
    ),

    tags: (
      <FindingAttributesArrayDisplay
        value={finding?.spec?.finding_tags ?? []}
      />
    ),
  };

  return resource;
};

const genRecords = (): AttributeDisplayRecord[] => {
  return [
    { attributeKey: 'project', heading: <FM defaultMessage="Project" /> },
    {
      attributeKey: 'package',
      heading: <FM defaultMessage="Package" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'dependency',
      heading: <FM defaultMessage="Dependency" />,
      hideIfNil: true,
    },
    { attributeKey: 'tags', heading: <FM defaultMessage="Tags" /> },
    {
      attributeKey: 'finding_categories',
      heading: <FM defaultMessage="Categories" />,
    },
    {
      attributeKey: 'finding_policy_info',
      heading: <FM defaultMessage="Finding Policy" />,
    },
  ];
};

export const FindingPrimaryAttributesSection = ({
  finding,
  isLoading,
  namespace,
  packageVersion,
  project,
}: FindingPrimaryAttributesSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, packageVersion, project }),
    [finding, namespace, packageVersion, project]
  );

  return (
    <DetailDrawerSection>
      <AttributeDisplayStack
        attributeRecords={records}
        isLoading={isLoading}
        resource={resource}
      ></AttributeDisplayStack>
    </DetailDrawerSection>
  );
};
