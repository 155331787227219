import { QueryKey, useQuery } from 'react-query';

import {
  CIRunServiceApi,
  V1CIRun,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  CIRunResource,
  CIRunResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

interface CIRunReadParams {
  namespace: string;
  uuid: string;
}

interface CIRunWriteParams {
  namespace: string;
  resource: V1CIRun;
}

interface CIRunUpdateParams extends CIRunWriteParams {
  mask?: string;
}

type CountCIRunsOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListCIRunsOptions = ResourceQueryOptions<CIRunResourceList>;
type GetCIRunOptions = ResourceQueryOptions<CIRunResource>;
type UpsertCIRunOptions = ResourceMutateOptions<V1CIRun, CIRunWriteParams>;
type DeleteCIRunOptions = ResourceMutateOptions<object, CIRunReadParams>;

const BASE_KEY = 'v1/ci-runs';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const CIRunQueryKeys = QK;

export const CI_RUN_UPDATE_MASK = 'meta,spec';

const getApiService = () => new CIRunServiceApi(getClientConfiguration());

const countCIRuns = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.cIRunServiceListCIRuns(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountCIRuns = (
  namespace: string,
  opts: CountCIRunsOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'CIRun',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countCIRuns(namespace, requestParameters),
    opts
  );
};

const listCIRuns = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.cIRunServiceListCIRuns(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as CIRunResourceList;
};

export const useListCIRuns = (
  namespace: string,
  opts: ListCIRunsOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'CIRun',
    'COUNT',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listCIRuns(namespace, requestParameters),
    opts
  );
};

const getCIRun = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.cIRunServiceGetCIRun(namespace, uuid);
  return resp.data as CIRunResource;
};

export const useGetCIRun = (
  params: CIRunReadParams,
  opts: GetCIRunOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getCIRun(params.namespace, params.uuid),
    opts
  );
};
