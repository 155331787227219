import { FindingSpecPathNode } from '@endorlabs/endor-core/Finding';

import { GraphPathListProps } from '../../../components/GraphPathList';
import { IconCode, IconPlanet } from '../../../themes';
import { UIPackageVersionUtils } from '../../PackageVersion';

export type CallPath = GraphPathListProps['path'];

const addPackageNode = (path: CallPath, node: FindingSpecPathNode) => {
  // try parsing as package version name
  const { label, version } = UIPackageVersionUtils.parsePackageName(
    node.package_version
  );
  const packageVersionName =
    label && version ? `${label}@${version}` : node.package_version;

  path.push({
    id: node.package_version,
    attributes: {
      label: packageVersionName,
      Icon: IconPlanet,
      // TODO: additional styles for internal/external
      // color:  node.internal ? 'primary' : 'secondary',
    },
  });
};

const addMethodNode = (path: CallPath, node: FindingSpecPathNode) => {
  // get method display
  const { namespace, classname, function_or_attribute_name } =
    node.function_ref;

  const functionName = function_or_attribute_name
    ? function_or_attribute_name + '()'
    : '';

  const method = [namespace, classname, functionName].join('.');

  path.push({
    id: method,
    attributes: {
      label: method,
      Icon: IconCode,
      // TODO: additional styles for internal/external
      // color: node.internal ? 'primary' : 'secondary',
    },
  });
};

export const buildCallPathFromFunctionPath = (
  functionPath: FindingSpecPathNode[]
) => {
  const callPath: CallPath = [];

  // handle empty list
  if (!functionPath?.length) return callPath;

  // track root
  const root = functionPath[0];

  let currentPackage;
  for (let i = 0; i < functionPath.length; i++) {
    const node = functionPath[i];

    // for each unique package we encounter, add it to the path
    if (node.package_version !== currentPackage) {
      // NOTE: annotates the last method of the 'root' package
      if (currentPackage === root.package_version) {
        callPath[callPath.length - 1].attributes.fontWeight = 600;
      }

      addPackageNode(callPath, node);

      // track the current package
      currentPackage = node.package_version;
    }

    // add each method to the path
    addMethodNode(callPath, node);
  }

  return callPath;
};
