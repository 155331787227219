import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type EndorLogoProps = {
  color?: string;
  size?: string | number;
};

const EndorLogo = forwardRef<SVGSVGElement, EndorLogoProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        fill="none"
        height={size}
        width={size}
        ref={ref}
        viewBox="0 0 120.63 141.64"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <polygon
          fill={color}
          points="51.17 85.28 96.46 85.28 96.46 51.29 51.17 51.29 51.17 34.41 120.63 34.41 120.63 0 10.56 0 10.56 85.3 51.17 85.3 51.17 85.28"
        />
        <rect fill={color} x="51.26" y="104.08" width="69.36" height="34.41" />
        <polygon
          fill={color}
          points="28.34 138.5 28.34 138.49 28.33 138.49 28.34 138.5"
        />
        <path
          fill={color}
          d="M40.71,121.29a20.36,20.36,0,1,0-20.36,20.35h0A20.35,20.35,0,0,0,40.71,121.29Z"
        />
      </svg>
    );
  }
);

EndorLogo.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

EndorLogo.displayName = 'EndorLogo';

export default EndorLogo;
