/* eslint sort-keys: "error" */
import { defineMessages } from 'react-intl';

export const SecurityLabels = defineMessages({
  additional_notes: { defaultMessage: 'Additional Notes' },
  advisory: { defaultMessage: 'Advisory' },
  affected_versions: { defaultMessage: 'Affected Versions' },
  aliases: { defaultMessage: 'Aliases' },
  base_cvss_score: { defaultMessage: 'Base CVSS Score' },
  base_score_metrics: { defaultMessage: 'Base Score Metrics' },
  cve: { defaultMessage: 'CVE' },
  cwe_id: { defaultMessage: 'CWE ID' },
  dependency: { defaultMessage: 'Dependency' },
  description: { defaultMessage: 'Description' },
  detail: { defaultMessage: 'Detail' },
  details: { defaultMessage: 'Details' },
  dismiss: { defaultMessage: 'Dismissed' },
  ecosystem: { defaultMessage: 'Ecosystem' },
  epss: { defaultMessage: 'EPSS Score' },
  finding_category: { defaultMessage: 'Category' },
  fix_available: { defaultMessage: 'Fix Available' },
  fix_information: { defaultMessage: 'Fix Information' },
  fix_priority: { defaultMessage: 'Fix Priority' },
  fixed_version: { defaultMessage: 'Fixed Version' },
  github_reviewed: { defaultMessage: 'GitHub Reviewed' },
  id: { defaultMessage: 'ID' },
  identifier: { defaultMessage: 'Identifier' },
  introduced_version: { defaultMessage: 'Introduced Version' },
  issue: { defaultMessage: 'Issue' },
  last_processed: { defaultMessage: 'Last Processed' },
  level: { defaultMessage: 'Level' },
  metadata: { defaultMessage: 'Metadata' },
  modified: { defaultMessage: 'Modified' },
  osv_identifier: { defaultMessage: 'OSV Identifier' },
  osv_schema_version: { defaultMessage: 'OSV Schema Version' },
  osv_severity: { defaultMessage: 'OSV Severity' },
  package: { defaultMessage: 'Package' },
  priority_information: { defaultMessage: 'Priority Information' },
  project: { defaultMessage: 'Project' },
  published: { defaultMessage: 'Published' },
  references: { defaultMessage: 'References' },
  remediation: { defaultMessage: 'Remediation' },
  score: { defaultMessage: 'Score' },
  score_type: { defaultMessage: 'Score Type' },
  severity: { defaultMessage: 'Severity' },
});
