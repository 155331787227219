import { TypographyProps, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  getSecurityFindingCvssScore,
  getSecurityFindingCweId,
  getSecurityFindingFixVersion,
  getSecurityFindingIntroducedVersion,
} from '@endorlabs/endor-core/Finding';
import { AttributeDisplayStack, DateDisplay } from '@endorlabs/ui-common';
import { EcosystemNameDisplay } from '@endorlabs/ui-common/domains/Package';

import { DetailDrawerSection } from '../../../components/DetailDrawer';
import { FindingDetailDrawerSectionProps } from '../types';

const genResource = ({ finding }: FindingDetailDrawerSectionProps) => {
  const vuln = finding?.spec.finding_metadata?.vulnerability?.spec;
  const vulnAffected = vuln?.affected?.[0];

  const osvScores = finding ? getSecurityFindingCvssScore(finding) : undefined;

  const resource = {
    cwe: finding ? getSecurityFindingCweId(finding) : undefined,
    osv_base_score: osvScores?.baseScore,
    osv_id: vuln?.raw?.osv_vulnerability?.id,
    osv_score_type: osvScores?.scoreType,
    osv_score_vector: osvScores?.scoreVector,
    osv_schema_version: vuln?.raw?.osv_vulnerability?.schema_version,
    vuln_ecosystem: (
      <EcosystemNameDisplay
        value={vulnAffected?.package?.ecosystem}
        size="inherit"
      />
    ),
    vuln_published: <DateDisplay value={vuln?.published} />,
    vuln_fix_version: finding
      ? getSecurityFindingFixVersion(finding)
      : undefined,
    vuln_introduced_version: finding
      ? getSecurityFindingIntroducedVersion(finding)
      : undefined,
  };

  return resource;
};

const genRecords = () => {
  return [
    {
      attributeKey: 'cwe',
      heading: <FM defaultMessage="CWE ID" />,
    },
    {
      attributeKey: 'osv_id',
      heading: <FM defaultMessage="OSV ID" />,
    },
    {
      attributeKey: 'vuln_ecosystem',
      heading: <FM defaultMessage="Ecosystem" />,
    },
    {
      attributeKey: 'vuln_published',
      heading: <FM defaultMessage="Published" />,
    },
    {
      attributeKey: 'osv_score_vector',
      heading: <FM defaultMessage="Score Metrics" />,
      valueTypographyProps: { variant: 'body2' } satisfies TypographyProps,
    },
  ];
};

export const FindingMetadataSection = ({
  finding,
  isLoading,
  namespace,
}: FindingDetailDrawerSectionProps) => {
  const { spacing } = useTheme();

  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace }),
    [finding, namespace]
  );

  return (
    <DetailDrawerSection title={<FM defaultMessage="Metadata" />}>
      <AttributeDisplayStack
        attributeRecords={records}
        headingWidth={spacing(34)}
        isLoading={isLoading}
        resource={resource}
        variant="flex"
      />
    </DetailDrawerSection>
  );
};
