import {
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1FindingCategory as FindingCategory } from '@endorlabs/api_client';
import { DEFAULT_FINDING_CATEGORIES } from '@endorlabs/endor-core/Finding';

import { FindingCategoryLabel } from '../FindingCategoryLabel';

export interface FindingCategorySelectProps
  extends Omit<SelectProps, 'onChange' | 'multiple'> {
  activeFindingCategory?: FindingCategory;
  includeAll?: boolean;
  findingCategories?: FindingCategory[];
  onChange: (
    event: SelectChangeEvent<unknown>,
    findingCategory?: FindingCategory
  ) => void;
}

export const FindingCategorySelect = ({
  activeFindingCategory,
  findingCategories = DEFAULT_FINDING_CATEGORIES,
  includeAll = true,
  onChange,
  ...props
}: FindingCategorySelectProps) => {
  const { formatMessage: fm } = useIntl();

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value;
      if (value) {
        onChange(event, value as FindingCategory);
      } else {
        onChange(event);
      }
    },
    [onChange]
  );

  return (
    <Select
      aria-label={fm({ defaultMessage: 'Select Finding Category' })}
      displayEmpty
      onChange={handleChange}
      renderValue={() => (
        <Stack direction="row" spacing={2}>
          <Typography>
            {!activeFindingCategory ? (
              <FM defaultMessage="All Findings" />
            ) : (
              <FindingCategoryLabel findingCategory={activeFindingCategory} />
            )}
          </Typography>
        </Stack>
      )}
      value={activeFindingCategory ?? ''}
      variant="standard"
      {...props}
    >
      {includeAll && (
        <MenuItem key="all" value="">
          <FM defaultMessage="All Findings" />
        </MenuItem>
      )}

      {findingCategories.map((findingCategory) => {
        return (
          <MenuItem
            key={findingCategory}
            onClick={(evt) => evt.stopPropagation()}
            value={findingCategory}
          >
            <FindingCategoryLabel findingCategory={findingCategory} />
          </MenuItem>
        );
      })}
    </Select>
  );
};
