import { Box, Fade, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import endorBranding from '../../../assets/storybook/empty-state-animation-one.svg';

export interface EmptyStateProps {
  /**
   * The title text for the Empty State.
   */
  title: ReactNode;
  /**
   * The description text for the Empty State.
   */
  description?: ReactNode;
  /**
   * The size of the Empty State
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * The size of the image element
   */
  imageWidth?: number;
  /**
   * The alignment of title and description text
   */
  textAlign?: 'left' | 'center' | 'right';
  /**
   * Children slot, to pass Call to Action, or related content for the Empty State
   */
  children?: ReactNode;
}

export const EmptyState = ({
  title,
  description,
  size = 'medium',
  imageWidth = 400,
  textAlign,
  children,
}: EmptyStateProps) => {
  const isSmall = size === 'small';

  const cls = clsx({
    'EmptyState--small': isSmall,
    'EmptyState--medium': size === 'medium',
    'EmptyState--large': size === 'large',
  });

  return (
    <Fade in={true} timeout={1000}>
      <Box
        className={cls}
        sx={({ palette, space, spacing }) => ({
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(3),
          justifyContent: 'center',
          textAlign: textAlign ?? 'center',

          '&.EmptyState--small': {
            color: palette.text.secondary,
            paddingBottom: space.xs,
            paddingTop: space.xs,
            textAlign: textAlign ?? 'left',
          },

          '&.EmptyState--medium': {
            minHeight: '40vh',
            padding: space.md,
          },

          '&.EmptyState--large': {
            minHeight: '80vh',
            padding: space.lg,
          },
        })}
      >
        <Grid container direction="column" spacing={5}>
          {!isSmall && (
            <Grid item>
              <Box>
                <img alt="Empty State" src={endorBranding} width={imageWidth} />
              </Box>
            </Grid>
          )}

          <Grid item>
            <Typography variant={isSmall ? 'h6' : 'h3'}>{title}</Typography>
          </Grid>

          {description && (
            <Grid item>
              <Typography component="p" variant={isSmall ? 'body2' : 'body1'}>
                {description}
              </Typography>
            </Grid>
          )}

          {children && (
            <Grid item>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {children}
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Fade>
  );
};
