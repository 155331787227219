import { defineMessages } from 'react-intl';

import { V1ScoreCategory as SC } from '@endorlabs/api_client';

export const ScoreCategoryMessages = defineMessages({
  [SC.Security]: { defaultMessage: 'Security' },
  [SC.CodeQuality]: { defaultMessage: 'Quality' },
  [SC.Popularity]: { defaultMessage: 'Popularity' },
  [SC.Activity]: { defaultMessage: 'Activity' },
  [SC.BestPractices]: { defaultMessage: 'Best Practices' },
  [SC.SuspiciousActivity]: { defaultMessage: 'Suspicious Activity' },
  [SC.Dependencies]: { defaultMessage: 'Dependencies' },
  [SC.OperationalRisk]: { defaultMessage: 'Operational Risk' },
  [SC.Unspecified]: { defaultMessage: 'Unspecified' },
});
