import { V1Confidence, V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

export const getBreakingChangeCountByConfidence = (
  upgradeInfo?: V1ReleaseUpgradeInfo
): { [key in V1Confidence]?: number } => {
  const countsByConfidence: { [key in V1Confidence]?: number } = {
    [V1Confidence.High]: 0,
    [V1Confidence.Medium]: 0,
    [V1Confidence.Low]: 0,
  };
  if (!upgradeInfo) {
    return countsByConfidence;
  }
  const breakingChanges = upgradeInfo?.cia_results ?? [];
  for (const change of breakingChanges) {
    if (change && change.confidence) {
      countsByConfidence[change.confidence] =
        (countsByConfidence[change.confidence] ?? 0) + 1;
    }
  }
  return countsByConfidence;
};
