import { get as _get, isEqual as _isEqual, uniq as _uniq } from 'lodash-es';

import {
  PermissionsMethods,
  V1Method,
  V1SystemRole,
} from '@endorlabs/api_client';

import { AUTHORIZATION_POLICY_PERMISSIONS_RULE_GLOBAL_KEY } from '../constants';
import { AuthorizationPolicyResource } from '../types';

const getGlobalPermissionRule = (
  authPolicy: AuthorizationPolicyResource
): PermissionsMethods | undefined =>
  _get(authPolicy, [
    'spec',
    'permissions',
    'rules',
    AUTHORIZATION_POLICY_PERMISSIONS_RULE_GLOBAL_KEY,
  ]);

/**
 * find and return roles for an Authorization Policy
 */
export const getAuthorizationPolicyPermissionRoles = (
  authPolicy: AuthorizationPolicyResource,
  options?: {
    enableRuleTransform?: boolean;
  }
): V1SystemRole[] => {
  const roles: V1SystemRole[] = [];

  if (authPolicy.spec.permissions?.roles) {
    roles.push(...authPolicy.spec.permissions.roles);
  }

  // Backwards-compatible check for "admin" role from rules
  const globalPermissionRule = getGlobalPermissionRule(authPolicy);

  if (
    options?.enableRuleTransform &&
    globalPermissionRule?.methods &&
    _isEqual(globalPermissionRule.methods, [V1Method.All])
  ) {
    roles.push(V1SystemRole.Admin);
  }

  return _uniq(roles);
};
