import { useContext } from 'react';

import { FilterValidationContext } from './FilterValidationContext';

export const useFilterValidationContext = () => {
  const context = useContext(FilterValidationContext);

  // Ensure context is present for consumer
  if (!context) {
    throw new Error('Missing provider for FilterValidationContext');
  }

  return context;
};
