import { uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { DependencyMetadataDiscoveryType } from '@endorlabs/api_client';
import { getLicenseMetricLicenseInfoValues } from '@endorlabs/endor-core/Metric';
import { getNormalizedVersionRefs } from '@endorlabs/endor-core/Package';
import { getDependencyFileLocations } from '@endorlabs/endor-core/PackageVersion';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  DependencyMetadataResource,
  PackageVersionResource,
  QueryPackageVersionsResponseObject,
  selectFindingCountsFromGroupResponse,
  useListDependencyMetadata,
  useListMetrics,
} from '@endorlabs/queries';
import {
  PackageNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';
import { GraphAdjacencyList } from '@endorlabs/utils/graph';

import { useProjectResolvedDependencies } from '../../Projects';
import { DependencyScopeLabel } from '../components';

/**
 * Get dependency metadata for the dependency specification, optionally filtered
 * by a package version.
 */
const mapDependencyMetadataSpecifications = (
  importingDependencyMetadata: DependencyMetadataResource[] = [],
  importingPackageVersion?: PackageVersionResource
) => {
  let dependencyMetadata = importingDependencyMetadata;

  if (importingPackageVersion) {
    dependencyMetadata = dependencyMetadata.filter(
      (d) =>
        d.spec.importer_data?.package_name ===
        importingPackageVersion.spec.package_name
    );
  }

  return dependencyMetadata.map((d) => {
    const dependencyData = d.spec.dependency_data;

    const ecosystem = dependencyData?.ecosystem;
    const unresolvedVersionRef = dependencyData?.unresolved_version;

    const normalizedVersionRefs =
      ecosystem && unresolvedVersionRef
        ? getNormalizedVersionRefs(ecosystem, [unresolvedVersionRef])
        : [unresolvedVersionRef];

    return {
      importerPackage: d.spec.importer_data?.package_name ? (
        <PackageNameDisplay name={d.spec.importer_data.package_name} />
      ) : undefined,
      resolvedVersion: dependencyData?.resolved_version,
      unresolvedVersion: normalizedVersionRefs[0],
      scope: dependencyData?.scope ? (
        <DependencyScopeLabel value={dependencyData.scope} />
      ) : undefined,
    };
  });
};

export const useDependencyDetailOverviewData = ({
  dependencyPackageVersion,
  dependencyPackageVersionName,
  importingNamespace,
  importingPackageVersion,
  importingProject,
  importingProjectVersion,
}: {
  dependencyPackageVersion?: QueryPackageVersionsResponseObject;
  dependencyPackageVersionName: string;
  importingNamespace: string;
  importingPackageVersion?: PackageVersionResource;
  importingProject?: ProjectResource;
  importingProjectVersion?: ProjectVersionResource;
}) => {
  // Load dependency graphs from project, when specified
  const qProjectResolvedDependencies = useProjectResolvedDependencies({
    namespace: importingNamespace,
    project: importingProject,
    projectVersion: importingProjectVersion,
    targetPackageVersionName: dependencyPackageVersionName,
  });

  const qListDependencyMetadata = useListDependencyMetadata(
    importingNamespace,
    {
      enabled: !!importingNamespace && !!dependencyPackageVersion,
    },
    {
      filter: filterExpressionBuilders.and([
        `spec.dependency_data.package_version_uuid==${dependencyPackageVersion?.uuid}`,
        ...(importingPackageVersion
          ? [
              filterExpressionBuilders.relatedResourceContext(
                importingPackageVersion
              ) ?? filterExpressionBuilders.mainResourceContext(),
            ]
          : []),
        ...(importingProject
          ? [
              `spec.importer_data.project_uuid==${importingProject.uuid}`,
              filterExpressionBuilders.relatedResourceContext(
                importingProjectVersion
              ) ?? filterExpressionBuilders.mainResourceContext(),
            ]
          : []),
      ]),
    }
  );

  const qListLicenseMetrics = useListMetrics(
    dependencyPackageVersion?.tenant_meta.namespace ?? '',
    { enabled: !!dependencyPackageVersion },
    {
      filter: [
        'meta.name==pkg_version_info_for_license',
        `meta.parent_uuid=="${dependencyPackageVersion?.uuid}"`,
        filterExpressionBuilders.relatedResourceContext(
          dependencyPackageVersion
        ),
      ].join(' and '),
      mask: 'spec.metric_values',
      page_size: 1,
    }
  );

  const dependencyCount = useMemo(() => {
    if (dependencyPackageVersion) {
      return dependencyPackageVersion?.meta.references.DependencyPackagesCount
        ?.count_response.count;
    }

    return importingPackageVersion?.spec.resolved_dependencies
      ?.dependency_graph?.[dependencyPackageVersionName]?.length;
  }, [
    dependencyPackageVersionName,
    dependencyPackageVersion,
    importingPackageVersion?.spec.resolved_dependencies?.dependency_graph,
  ]);

  const { ecosystem: dependencyEcosystem } =
    UIPackageVersionUtils.parsePackageName(dependencyPackageVersionName);

  const dependencyFileLocations = useMemo(() => {
    if (importingPackageVersion) {
      return getDependencyFileLocations(importingPackageVersion, {
        name: dependencyPackageVersionName,
      });
    }

    return qProjectResolvedDependencies.dependencyFileLocations;
  }, [
    dependencyPackageVersionName,
    importingPackageVersion,
    qProjectResolvedDependencies.dependencyFileLocations,
  ]);

  const dependencyGraph = useMemo(() => {
    if (importingPackageVersion) {
      const dependencyGraph = (importingPackageVersion?.spec
        .resolved_dependencies?.dependency_graph ??
        {}) as unknown as GraphAdjacencyList;

      return dependencyGraph;
    }

    return qProjectResolvedDependencies.dependencyGraph;
  }, [importingPackageVersion, qProjectResolvedDependencies.dependencyGraph]);

  const dependencySpecifications = mapDependencyMetadataSpecifications(
    qListDependencyMetadata.data?.list?.objects ?? [],
    importingPackageVersion
  );

  const findingCounts = selectFindingCountsFromGroupResponse(
    dependencyPackageVersion?.meta.references.FindingsGroupByLevel
      ?.group_response
  );

  const hasPhantomDependency =
    qListDependencyMetadata.data?.list?.objects?.some(
      (dm) =>
        dm.spec.dependency_data?.discovery_type ===
        DependencyMetadataDiscoveryType.Phantom
    ) ?? false;

  const hasEndorPatch = qListDependencyMetadata.data?.list?.objects?.some(
    (d) => d.spec.dependency_data?.patched
  );

  // return all licenses found for the package version
  const { names: licenseNames } = getLicenseMetricLicenseInfoValues(
    qListLicenseMetrics.data?.list?.objects[0]
  );

  const resolutionTimestamp =
    importingPackageVersion?.spec.resolved_dependencies?.resolution_timestamp ??
    dependencyPackageVersion?.meta.create_time;

  const [importingPackageVersionNames, importingPackageVersionEcosystems] =
    useMemo(() => {
      const packageVersionNames = importingPackageVersion
        ? [importingPackageVersion.meta.name]
        : qProjectResolvedDependencies.packageVersionNames;

      const packageVersionEcosystems = _uniq(
        packageVersionNames.map(
          (name) => UIPackageVersionUtils.parsePackageName(name).ecosystem
        )
      );

      return [packageVersionNames, packageVersionEcosystems];
    }, [
      importingPackageVersion,
      qProjectResolvedDependencies.packageVersionNames,
    ]);

  const isLoading =
    qProjectResolvedDependencies.isLoading ||
    qListDependencyMetadata.isLoading ||
    qListLicenseMetrics.isLoading;

  return {
    dependencyCount,
    dependencyEcosystem,
    dependencyFileLocations,
    dependencyGraph,
    dependencySpecifications,
    findingCounts,
    hasEndorPatch,
    hasPhantomDependency,
    isLoading,
    importingPackageVersionNames,
    importingPackageVersionEcosystems,
    isPartial: qProjectResolvedDependencies.isPartial,
    licenseNames,
    resolutionTimestamp,
  };
};
