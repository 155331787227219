import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { FullHeightCard, useStyles } from '@endorlabs/ui-common';

interface OnboardingCTACardProps {
  action: ReactNode;
  description: ReactNode;
  title: ReactNode;
}

export const OnboardingCTACard = ({
  action,
  description,
  title,
}: OnboardingCTACardProps) => {
  const sxCard = useStyles(cardStyles);
  const { space } = useTheme();

  return (
    <FullHeightCard sx={sxCard}>
      <Stack marginBottom={space.md} spacing={space.xs}>
        <Typography variant="h2">{title}</Typography>
        <Typography color="textSecondary">{description}</Typography>
      </Stack>
      {action}
    </FullHeightCard>
  );
};

const cardStyles = () => {
  const color = 'hsla(150, 100%, 47%, 0.4)';
  return {
    backgroundImage: `linear-gradient(333deg, ${color} 0%, transparent 36%)`,
    '& .MuiCardContent-root': {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      height: 'inherit',
      justifyContent: 'space-between',
    },
  };
};
