import { Typography } from '@mui/material';

import { ResourceKind } from '@endorlabs/endor-core';

import { useResourceKindDisplayLabel } from './useResourceKindDisplayLabel';

export interface ResourceKindDisplayProps {
  value: ResourceKind | string;
  isPlural?: boolean;
}

export const ResourceKindDisplay = ({
  value,
  isPlural,
}: ResourceKindDisplayProps) => {
  const label = useResourceKindDisplayLabel(value, isPlural);

  return (
    <Typography component="span" variant="inherit">
      {label}
    </Typography>
  );
};
