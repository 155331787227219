import { Stack, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { CiCdToolEvidence } from '@endorlabs/api_client';
import { AttributeDisplay, ExternalLink } from '@endorlabs/ui-common';

export const ToolEvidenceWebhookURLsDisplay = ({
  evidences,
}: {
  evidences?: CiCdToolEvidence[];
}) => {
  const { spacing } = useTheme();
  if (!evidences) {
    return;
  }

  return (
    <AttributeDisplay
      disableTypography
      heading={<FM defaultMessage="Webhook URLs" />}
      value={
        <Stack spacing={spacing(2)}>
          {evidences.map((evidence, i) => {
            const webhookURL = evidence.url;
            return (
              <ExternalLink key={`webhookURL-${i}`} to={webhookURL}>
                {webhookURL}
              </ExternalLink>
            );
          })}
        </Stack>
      }
    />
  );
};
