import _castArray from 'lodash-es/castArray';
import _findKey from 'lodash-es/findKey';
import _uniq from 'lodash-es/uniq';

import {
  PolicyPolicyType,
  PolicyTemplateParameterValues,
} from '@endorlabs/api_client';
import { PolicyResource } from '@endorlabs/queries';

import {
  DEFAULT_ADMISSION_POLICY,
  DEFAULT_EXCEPTION_POLICY,
  DEFAULT_FINDING_POLICY,
  DEFAULT_REMEDIATION_POLICY,
} from '../constants';
import {
  AllowedTemplateTypesByPolicyUmbrellaType,
  FormUpsertPolicyFieldValues,
  PolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';

export const getDefaultPolicy = (policyUmbrellaType: PolicyUmbrellaType) => {
  switch (policyUmbrellaType) {
    case PolicyUmbrellaTypes.ACTION: {
      return DEFAULT_ADMISSION_POLICY;
    }
    case PolicyUmbrellaTypes.FINDING: {
      return DEFAULT_FINDING_POLICY;
    }
    case PolicyUmbrellaTypes.EXCEPTION: {
      return DEFAULT_EXCEPTION_POLICY;
    }
    case PolicyUmbrellaTypes.REMEDIATION: {
      return DEFAULT_REMEDIATION_POLICY;
    }
    default: {
      return DEFAULT_ADMISSION_POLICY;
    }
  }
};

export const policyFieldValuesToModel = (
  policyFieldValues: FormUpsertPolicyFieldValues
) => {
  const workingModel = {
    ...policyFieldValues,
    spec: { ...policyFieldValues.spec },
  } as PolicyResource;

  // If notification targets are set, force policy type
  if (
    (policyFieldValues.spec?.notification?.notification_target_uuids ?? [])
      .length > 0
  ) {
    // Don't force the type for remediation policies
    if (policyFieldValues.spec?.policy_type !== PolicyPolicyType.Remediation) {
      workingModel.spec.policy_type = PolicyPolicyType.Notification;
    }
    workingModel.spec.admission = undefined;
  } else {
    workingModel.spec.notification = undefined;
  }

  // Clean up labels for project selectors & exceptions
  workingModel.spec.project_exceptions = processLabelValues(
    policyFieldValues.spec?.project_exceptions ?? []
  );
  workingModel.spec.project_selector = processLabelValues(
    policyFieldValues.spec?.project_selector ?? []
  );

  // If a template is declared, remove the rego definition from the model.
  // The template rule will be copied from the referenced uuid on the backend.
  if (workingModel.spec.template_uuid) {
    workingModel.spec.rule = undefined;
  }

  if (workingModel.spec.exception?.expiration_time === '') {
    workingModel.spec.exception.expiration_time = undefined;
  }

  // Ensure template values are always an array
  const newTemplateValues = Object.entries(
    policyFieldValues.spec.template_values ?? {}
  ).reduce((acc, tmplValue) => {
    acc[tmplValue[0]] = {
      values: _castArray(tmplValue[1].values).filter((v) => v !== ''),
    } as PolicyTemplateParameterValues;
    return acc;
  }, {} as Record<string, PolicyTemplateParameterValues>);

  workingModel.spec.template_values = newTemplateValues;

  switch (policyFieldValues.spec.policy_type) {
    case PolicyPolicyType.Admission: {
      return actionPolicyFieldValuesToModel(workingModel);
    }
    case PolicyPolicyType.SystemFinding: {
      return findingPolicyFieldValuesToModel(workingModel);
    }
    default: {
      return workingModel;
    }
  }
};

/**
 * Clean & transform field values into a valid policy
 */
export const actionPolicyFieldValuesToModel = (
  policyFieldValues: PolicyResource
) => {
  return { ...policyFieldValues } as PolicyResource;
};

export const findingPolicyFieldValuesToModel = (
  policyFieldValues: PolicyResource
) => {
  return { ...policyFieldValues } as PolicyResource;
};

/**
 * Ensures unique, trimmed, non-empty labels
 */
export const processLabelValues = (values: string[]) =>
  _uniq(
    values
      .filter((v) => !(v === undefined || v === null))
      .map((p) => p.trim())
      .filter((p) => p !== '')
  );

export const getUmbrellaTypeFromPolicyType = (policyType: PolicyPolicyType) =>
  _findKey(AllowedTemplateTypesByPolicyUmbrellaType, (v) =>
    v.includes(policyType)
  );
