import { useCallback, useMemo } from 'react';

import {
  buildSimpleFilterExpression,
  extendResourceFiltersWithDefinitions,
  FILTER_COMPARATORS,
  FilterableResource,
  ResourceFilter,
  ResourceFilterDefinitionMatch,
  ResourceFilterSingleValue,
  selectResourceFilters,
  useFilterDefinitions,
  useFilterSearchParams,
} from '@endorlabs/filters';

import { FilterBuilderProps } from './FilterBuilder';

export type UseFilterBuilderProps<TResource extends FilterableResource> = {
  include: TResource[];
  exclude?: ResourceFilterDefinitionMatch<TResource>[];
};

export type UseFilterBuilderResult<TResource extends FilterableResource> = {
  isLoading: boolean;
  filters: ResourceFilter[];
  setFilters: (filters: ResourceFilter[]) => void;
  clearFilters: () => void;
  filterExpressionMap: Map<TResource, string>;
  getFilterBuilderProps: () => FilterBuilderProps;
};

/**
 * Utility wrapper around Filter Builder
 */
export function useFilterBuilder<TResource extends FilterableResource>({
  include,
  exclude,
}: UseFilterBuilderProps<TResource>): UseFilterBuilderResult<TResource> {
  // get the filter definitions
  const qFilterDefinitions = useFilterDefinitions({ include, exclude });
  const { isLoading, data: definitions } = qFilterDefinitions;

  const [resourceKinds, primaryResourceKind] = useMemo(() => {
    return [include, include[0]];
  }, [include]);

  const defaultSearchFilter = useMemo(() => {
    const filterDefinition = definitions.find(
      (d) => d.kind === primaryResourceKind && d.key === 'meta.name'
    );

    return {
      kind: primaryResourceKind,
      key: 'meta.name',
      type: 'string',
      ...filterDefinition,
      comparator: FILTER_COMPARATORS.MATCHES,
    } as ResourceFilterSingleValue;
  }, [definitions, primaryResourceKind]);

  const { filterSearchParams, updateFilterSearchParams } =
    useFilterSearchParams();

  // filters, extended with additional fields from the definitions
  const enrichedFilters = useMemo(() => {
    if (!definitions.length) return filterSearchParams;
    return extendResourceFiltersWithDefinitions(
      filterSearchParams,
      definitions
    );
  }, [filterSearchParams, definitions]);

  const handleChangeFilters = useCallback(
    (filters: ResourceFilter[]) => {
      updateFilterSearchParams(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearFilters = useCallback(
    () => {
      updateFilterSearchParams([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // rebuild expressions on filter changes
  const filterExpressionMap = useMemo(() => {
    const expressionMap = new Map<TResource, string>();

    for (const kind of include) {
      const resourceFilters = selectResourceFilters(enrichedFilters, kind);
      if (!resourceFilters.length) continue;
      const { expression } = buildSimpleFilterExpression(resourceFilters);
      expressionMap.set(kind, expression);
    }
    return expressionMap;
  }, [enrichedFilters, include]);

  return {
    isLoading,
    filters: enrichedFilters,
    setFilters: handleChangeFilters,
    clearFilters,
    filterExpressionMap,
    getFilterBuilderProps: () => ({
      defaultSearchFilter,
      definitions,
      filters: enrichedFilters,
      onChange: handleChangeFilters,
      primaryResourceKind,
      resourceKinds,
    }),
  };
}
