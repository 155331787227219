import { Theme } from '@mui/material/styles/createTheme';

// FIXME: Need to get these on theme.spacing itself
export const getSpacingOverrides = ({ spacing }: Theme) => {
  return {
    space: {
      xs: spacing(2),
      sm: spacing(4),
      md: spacing(6),
      lg: spacing(8),
      xl: spacing(10),
    },
  };
};
