import { Navigate, useSearch } from '@tanstack/react-location';
import { defineMessage } from 'react-intl';

import { NamedRoutes } from './constants';
import { createFileRoute } from './utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Login Error' });

/**
 * Waypoint route that handles login errors from an external source and routes
 * the user appropriately.
 *
 * NOTE: The backend redirects to this route after failed authentication via
 * magic link or  3rd-party provider.
 */
const LoginErrorRoute = () => {
  const { code, event } = useSearch();

  if (event === 'signup') {
    return <Navigate to={NamedRoutes.SIGNUP} search={{ code }} />;
  }

  return <Navigate to={NamedRoutes.LOGIN} search={{ code }} />;
};

export const Route = createFileRoute(NamedRoutes.LOGIN_ERROR)({
  component: LoginErrorRoute,
  beforeLoad: () => {
    return { title: ROUTE_TITLE };
  },
});
