import { Chip, Stack, Typography } from '@mui/material';
import { MouseEvent, useMemo } from 'react';

import {
  getFilterComparatorDefinition,
  isFilterComparatorWithoutValue,
  ResourceFilter,
} from '@endorlabs/filters';
import { useResourceKindDisplayLabel } from '@endorlabs/ui-common';

interface FilterValueDisplayProps {
  filter: ResourceFilter;
  onDelete?: (event: Event) => void;
  onEdit?: (event: MouseEvent) => void;
}

/**
 * TODO: styles
 */
export const FilterValueDisplay = ({
  filter,
  onDelete,
  onEdit,
}: FilterValueDisplayProps) => {
  // get the comparator name
  const { name, kind, key, comparator, value } = filter;

  const resourceKindDisplayLabel = useResourceKindDisplayLabel(kind);
  const filterDisplayName = name ?? `${resourceKindDisplayLabel}:${key}`;

  const comparatorDefinition = getFilterComparatorDefinition(comparator);

  // TODO: better handle rendering a multi-value filter
  const isMultiple = Array.isArray(value);

  // hide the 'value' when displaying a comparator without
  const hasNoValue = useMemo(() => {
    return isFilterComparatorWithoutValue(comparatorDefinition.comparator);
  }, [comparatorDefinition.comparator]);

  return (
    <Chip
      clickable
      label={
        <Stack direction="row" spacing={1}>
          <Typography>{filterDisplayName}</Typography>

          <Typography>{comparatorDefinition.name}</Typography>

          {!hasNoValue && (
            <Typography
              fontWeight="bold"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {isMultiple ? value.join(',') : value.toString()}
            </Typography>
          )}
        </Stack>
      }
      onClick={onEdit}
      onDelete={onDelete}
    />
  );
};
