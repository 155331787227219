import { Divider } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1ScoreCard,
  V1ScoreCategory,
  V1ScoreFactor,
} from '@endorlabs/api_client';
import { ScoreCategorySelect } from '@endorlabs/ui-common';

import { ScoreFactorsList } from '../../domains/Metrics';
import { ScoreCardDisplayV2 } from '../ScoreCardDisplay';
import { DetailDrawerSection } from './DetailDrawerSection';
import { DetailDrawerSectionStack } from './DetailDrawerSectionStack';

interface DetailDrawerScoreSectionProps {
  scoreCard?: V1ScoreCard;
  scoreFactors?: V1ScoreFactor[];
  isLoading?: boolean;
}

export const DetailDrawerScoreSection = ({
  scoreCard,
  scoreFactors,
  isLoading,
}: DetailDrawerScoreSectionProps) => {
  const [activeMetricCategory, setActiveMetricCategory] = useState<
    V1ScoreCategory | undefined
  >(undefined);

  return (
    <DetailDrawerSectionStack divider={<Divider />}>
      <DetailDrawerSection id="score_card" disableGutters disablePadding>
        <ScoreCardDisplayV2 scoreCard={scoreCard} isLoading={isLoading} />
      </DetailDrawerSection>
      <DetailDrawerSection
        headerProps={{
          action: (
            <ScoreCategorySelect
              activeCategory={activeMetricCategory}
              onChange={(_, category) => setActiveMetricCategory(category)}
            />
          ),
          title: <FM defaultMessage="Endor Score Factors" />,
          variant: 'h3',
        }}
        id="score_factors"
      >
        <ScoreFactorsList
          scoreCard={scoreCard}
          scoreFactors={scoreFactors}
          scoreCategory={activeMetricCategory}
          isLoading={isLoading}
        />
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};
