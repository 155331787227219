import { Stack, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ControlledCheckbox,
  ControlledLabelsField,
  ControlledTextField,
  LabelsFieldValidationType,
} from '@endorlabs/ui-common';

export const PolicyNameFields = () => {
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();
  const { control, trigger } = useFormContext();

  return (
    <Stack alignItems="flex-start" spacing={space.md} width="100%">
      <ControlledTextField
        control={control}
        defaultValue=""
        data-testid="policy-name-field"
        label={<FM defaultMessage="Name" />}
        name="meta.name"
        onChange={() => trigger()}
        placeholder={fm({
          defaultMessage: 'Enter a name for this policy',
        })}
        rules={{ required: true }}
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        data-testid="policy-description-field"
        label={<FM defaultMessage="Description" />}
        name="meta.description"
        placeholder={fm({
          defaultMessage: 'What does this policy do?',
        })}
      />

      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        fullWidth
        label={<FM defaultMessage="Policy Tags" />}
        name="meta.tags"
        helperText={fm({
          defaultMessage: 'The policy will have these tags. Tags can have a maximum of 63 characters and can contain letters, numbers, and characters = @ _ -.',
        })}
        validation={LabelsFieldValidationType.MetaTag}
      />

      <ControlledCheckbox
        control={control}
        name="spec.disable"
        sx={{ display: 'none' }}
      />
    </Stack>
  );
};
