import { Stack, StackProps, useTheme } from '@mui/material';

interface DetailDrawerSectionStackProps
  extends Pick<StackProps, 'children' | 'divider'> {}

/**
 * Layout component to keep detail drawer layout simple
 */
export const DetailDrawerSectionStack = ({
  children,
  divider,
}: DetailDrawerSectionStackProps) => {
  const { space } = useTheme();

  return (
    <Stack divider={divider} spacing={space.md}>
      {children}
    </Stack>
  );
};
