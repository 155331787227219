import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '../../../../components';
import {
  FormUpsertAuthorizationPolicy,
  FormUpsertAuthorizationPolicyProps,
} from '../FormUpsertAuthorizationPolicy';

export interface AuthorizationPolicyUpsertDialogProps
  extends Pick<SimpleDialogProps, 'open' | 'onClose'>,
    FormUpsertAuthorizationPolicyProps {}

/**
 * Dialog Wrapper around Auth Policy Upsert Form
 */
export const AuthorizationPolicyUpsertDialog = ({
  open,
  onClose,
  ...props
}: AuthorizationPolicyUpsertDialogProps) => {
  const isEdit = Boolean(props.authorizationPolicy);

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      titleText={
        isEdit ? (
          <FM defaultMessage="Update Authorization Policy" />
        ) : (
          <FM defaultMessage="Add Authorization Policy" />
        )
      }
      descriptionText={
        <FM defaultMessage="Allow users access to a tenant from an external identity provider" />
      }
    >
      <FormUpsertAuthorizationPolicy {...props} />
    </SimpleDialog>
  );
};
