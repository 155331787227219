import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Endor Patch Detail' });

// Get the package name from the url-encoded route parameter
// Supports formatting like:
// - /t/:namespace/explore/patches/mvn%3A%2F%2Fcom.fasterxml.jackson.dataformat%3Ajackson-dataformat-xml%402.7.3
// - /t/:namespace/explore/patches/mvn/com.fasterxml.jackson.dataformat%3Ajackson-dataformat-xml/2.7.3
const getPackageVersionNameFromRouteParam = (splat: string) => {
  const parts = splat.split('/').map((v) => decodeURIComponent(v));

  if (parts.length === 1) {
    return parts[0];
  } else if (parts.length === 3) {
    const [prefix, name, version] = parts;
    return `${prefix}://${name}@${version}`;
  }
};

export const Route = createFileRoute('/t/:namespace/explore/patches/*')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  loader: async ({ params }) => {
    const packageVersionName = getPackageVersionNameFromRouteParam(params['*']);
    return { packageVersionName };
  },
});
