import { Outlet, useMatch } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';
import { useQuery } from 'react-query';

import { buildQueryCall, getProjectQueryOptions } from '@endorlabs/queries';
import {
  EmptyState,
  LoadingState,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../../../providers';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

/**
 * Detail Route for a Tenant Project
 *
 * Load:
 * - Prefetches Project by namespace and uuid
 *
 * Handle:
 * - Missing Project (404)
 * - Errors loading project data
 */
const TenantProjectDetailRoute = () => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { activeNamespace, isLoading: isLoadingAuth } = useAuthInfo();
  const {
    params: { project_uuid },
  } = useMatch();

  const {
    data,
    error,
    isLoading: isLoadingProject,
  } = useQuery(
    getProjectQueryOptions({
      namespace: activeNamespace,
      uuid: project_uuid,
    })
  );

  if (isLoadingAuth || isLoadingProject) {
    return <LoadingState />;
  }

  if (!data || error) {
    const errorMessage = error
      ? getErrorMessage('GET', 'Project', error)
      : undefined;

    return (
      <EmptyState
        title={<FM defaultMessage="Unable to load Project details" />}
      >
        {errorMessage?.details}
      </EmptyState>
    );
  }

  return <Outlet />;
};

export const Route = createFileRoute('/t/:namespace/projects/:project_uuid')({
  component: TenantProjectDetailRoute,
  loader: async ({ context, params }) => {
    const { namespace, project_uuid } = params;

    // Preload the project data for the route
    const project = await context.queryClient.fetchQuery(
      getProjectQueryOptions({
        namespace,
        uuid: project_uuid,
      })
    );

    return {
      project,
    };
  },
});
