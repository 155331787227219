import { V1FindingTags } from '@endorlabs/api_client';
import { getGroupedFindingAttributes } from '@endorlabs/endor-core/Finding';
import {
  Filter,
  FILTER_COMPARATORS,
  isLogicFilter,
  isValueFilter,
} from '@endorlabs/filters';

import { FilterFieldConfig } from '../../../filters';
import { FindingAttributesFilterField } from '../../components/FindingAttributesFilterField';

const groupedAttrs = getGroupedFindingAttributes([
  V1FindingTags.PotentiallyReachableFunction,
  V1FindingTags.ReachableDependency,
  V1FindingTags.ReachableFunction,
  V1FindingTags.FixAvailable,
  V1FindingTags.Normal,
]);

export const FindingAttributesFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.finding_tags',
  initialFilter: {
    operator: 'AND',

    value: Object.values(groupedAttrs).map((group) => ({
      key: 'spec.finding_tags',
      comparator: FILTER_COMPARATORS.CONTAINS,
      value: group,
    })),
  },

  format: (value?: string[]): Filter | undefined => {
    if (!value?.length) return;

    const grouped = getGroupedFindingAttributes(value as V1FindingTags[]);

    const filters = Object.values(grouped).map((group) => ({
      key: 'spec.finding_tags',
      comparator: FILTER_COMPARATORS.CONTAINS,
      value: group,
    }));

    if (filters.length === 1) return filters[0];

    return {
      operator: 'AND',
      value: filters,
    };
  },

  parse: (filter: Filter): V1FindingTags[] => {
    const isValidValueFilter = (filter: Filter) =>
      isValueFilter(filter) &&
      filter.comparator === FILTER_COMPARATORS.CONTAINS &&
      Array.isArray(filter.value);

    // Handle single value
    if (isValidValueFilter(filter)) {
      return filter.value as V1FindingTags[];
    }

    // Unwrap logical filter
    if (
      isLogicFilter(filter) &&
      filter.operator === 'AND' &&
      filter.value.every(isValidValueFilter)
    ) {
      return filter.value.flatMap((f) => f.value) as V1FindingTags[];
    }

    return [];
  },

  renderInput: ({ onChange, value }) => (
    <FindingAttributesFilterField onChange={onChange} value={value} />
  ),
} satisfies FilterFieldConfig<V1FindingTags[]>;
