import {
  Box,
  IconButton,
  Popper,
  PopperProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';

import { IconX } from '@endorlabs/ui-common';

export interface OnboardingPopoverProps extends PopperProps {
  children?: ReactNode;
  // Title - `title` prop already exists on Popover
  heading?: ReactNode;
  // DOM query selector
  selector: string;
  // Since open is handled in internal state, offer another prop to control whether popover will appear
  willShow?: boolean;
}

/**
 * This component attaches a styled Popper to the first element found using the provided selector.
 * Implements Popover interface. Not necessarily just for onboarding.
 */
export const OnboardingPopover = ({
  children,
  heading,
  // onClose,
  open = false,
  selector,
  willShow = true,
  ...props
}: OnboardingPopoverProps) => {
  const { shadows, space, spacing, typography } = useTheme();

  const [isOpen, setIsOpen] = useState<boolean>(open);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // Handle closing the Popover internally—the onClose prop is an optional callback
  const closeCallback = useCallback(() => {
    setAnchorEl(null);
    setIsOpen(false);
    // onClose && onClose(evt, 'backdropClick');
  }, [setAnchorEl, setIsOpen]);

  // Find an element matching the provided selector & attach
  const attachTo = useCallback(() => {
    const popoverAnchor = document.querySelector(selector);

    if (popoverAnchor) {
      setAnchorEl(popoverAnchor);
      setIsOpen(true);
    }
  }, [selector, setIsOpen]);

  // Effect to show popover
  useLayoutEffect(() => {
    willShow && attachTo();
  }, [attachTo, willShow]);

  return (
    <Popper {...props} anchorEl={anchorEl} open={isOpen}>
      <Box
        sx={{
          backgroundColor: ({ palette }) => palette.brand.main,
          borderRadius: '6px',
          boxShadow: shadows[8],
          color: ({ palette }) => palette.getContrastText(palette.brand.main),
          maxWidth: spacing(90),
          minWidth: spacing(60),
          padding: spacing(4, 4, 5, 5),
        }}
      >
        <Box>
          <Stack spacing={space.sm}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              width="100%"
            >
              {heading && <Typography variant="h3">{heading}</Typography>}

              <IconButton
                aria-label="Close"
                onClick={closeCallback}
                sx={{
                  color: 'inherit',
                  '&:hover': {
                    color: 'inherit',
                    opacity: 0.8,
                  },
                }}
              >
                <IconX />
              </IconButton>
            </Stack>

            <Box sx={{ ...typography.body2 }}>{children}</Box>
          </Stack>
        </Box>
      </Box>
    </Popper>
  );
};
