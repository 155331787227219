import { Navigate, useMatch } from '@tanstack/react-location';

import {
  isContainerProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import { LoadingState } from '@endorlabs/ui-common';

import { useProjectPackageEcosystems } from '../../../../../domains/Projects';
import { ProjectVersionPage } from '../../../../../views';
import { PageTitles } from '../../../../constants';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantProjectDetailIndexRoute = () => {
  const { data } = useMatch();
  const project = data.project as ProjectResource;

  const qProjectPackageEcosystems = useProjectPackageEcosystems(project);

  if (qProjectPackageEcosystems.isLoading) {
    return <LoadingState />;
  }

  // Handle for "Binary" projects
  // - When package ecosystems identifies this as a project from a container scan, redirect to the version select page
  // - For projects from package scan, display with default handling for projects
  if (isContainerProject(project, qProjectPackageEcosystems.data.ecosystems)) {
    return <Navigate to="versions" replace />;
  }

  // TODO: Add handling for "HuggingFace/Model" projects
  // TODO: Add handling for "SBOM" projects

  // For all other project types, render the project version page
  return <ProjectVersionPage />;
};

export const Route = createFileRoute('/t/:namespace/projects/:project_uuid/')({
  children: [{ path: ':activeView' }],
  component: TenantProjectDetailIndexRoute,
  meta: { pageTitle: PageTitles.projectDetailPage },
});
