import { defineMessage } from 'react-intl';

import { SettingsPage } from '../../../../views';
import { AuthenticatedRouteGuard } from '../../../components/AuthenticatedRouteGuard';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'Settings',
});

const TenantSettingsRoute = () => {
  return (
    <AuthenticatedRouteGuard>
      <SettingsPage />
    </AuthenticatedRouteGuard>
  );
};

export const Route = createFileRoute('/t/:namespace/settings')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  component: TenantSettingsRoute,
});
