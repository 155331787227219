import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';
import { ResourceCRUDAction } from '@endorlabs/endor-core/api';
import { isQueryError } from '@endorlabs/queries';

import {
  ResourceCRUDActionCompletedMessages,
  ResourceCRUDActionMessages,
  ResourceKindMessages,
  ResourceKindPluralMessages,
} from '../locales';

export type ResourceCRUDMessage = {
  message: string;
  details?: string;
};

/**
 * Known GRPC response codes sent from the API server.
 * {@link https://grpc.github.io/grpc/core/md_doc_statuscodes.html}
 */
const GRPC_RESPONSE_CODES = {
  DEADLINE_EXCEEDED: 4,
};

/**
 * Known HTTP status codes sent from the API server.
 * {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status}
 */
const HTTP_STATUS_CODES = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  UNAUTHORIZED: 401,
};

export const useResourceCRUDMessages = () => {
  const { formatMessage: fm } = useIntl();

  const getErrorMessage = useCallback(
    (
      action: ResourceCRUDAction,
      resourceKind: ResourceKind,
      error: unknown
    ): ResourceCRUDMessage => {
      const actionLabel = fm(ResourceCRUDActionMessages[action]);
      const resourceKindDisplayLabel = fm(ResourceKindMessages[resourceKind]);
      const resourceKindDisplayLabelPlural = fm(
        ResourceKindPluralMessages[resourceKind]
      );

      if (isQueryError(error)) {
        const statusCode = error.response?.status;
        const responseCode = error.response.data?.code;
        const responseMessage = error.response.data?.message;

        const defaultMessage = fm(
          { defaultMessage: 'Failed to {action} {resourceKind}' },
          { action: actionLabel, resourceKind: resourceKindDisplayLabel }
        );

        // handle: not authorized error
        if (statusCode === HTTP_STATUS_CODES.FORBIDDEN) {
          return {
            message: defaultMessage,
            details: fm(
              {
                defaultMessage:
                  'Not authorized to {action} {resourceKind} for this namespace.',
              },
              {
                action: actionLabel,
                resourceKind: resourceKindDisplayLabelPlural,
              }
            ),
          };
        }

        // handle: not found error
        if (statusCode === HTTP_STATUS_CODES.NOT_FOUND) {
          return {
            message: defaultMessage,
            details: fm(
              {
                defaultMessage:
                  'The requested {resourceKind} was not found in this namespace.',
              },
              {
                action: actionLabel,
                resourceKind: resourceKindDisplayLabel,
              }
            ),
          };
        }

        if (statusCode === HTTP_STATUS_CODES.TOO_MANY_REQUESTS) {
          return {
            message: defaultMessage,
            details: fm({
              defaultMessage: 'Rate limit exceeded. Please try again later.',
            }),
          };
        }

        if (responseCode === GRPC_RESPONSE_CODES.DEADLINE_EXCEEDED) {
          return {
            message: defaultMessage,
            details: fm({
              defaultMessage: 'The request timed out attempting to load.',
            }),
          };
        }

        if (responseMessage?.includes('bad filter')) {
          return {
            message: fm({
              defaultMessage:
                'The request includes a bad filter. Please remove and retry',
            }),
            details: responseMessage,
          };
        }

        return {
          message: defaultMessage,
          details: responseMessage,
        };
      }

      return {
        message: fm({ defaultMessage: "We've Encountered a Problem" }),
        details: fm(
          {
            defaultMessage:
              'Failed to {action} {resourceKind} for this namespace. Please try again.',
          },
          { action: actionLabel, resourceKind: resourceKindDisplayLabelPlural }
        ),
      };
    },
    [fm]
  );

  const getSuccessMessage = useCallback(
    (
      action: ResourceCRUDAction,
      resourceKind: ResourceKind
    ): ResourceCRUDMessage => {
      const actionLabel = fm(ResourceCRUDActionCompletedMessages[action]);
      const resourceKindDisplayLabel = fm(ResourceKindMessages[resourceKind]);

      return {
        message: fm(
          { defaultMessage: '{resourceKind} Successfully {action}' },
          { action: actionLabel, resourceKind: resourceKindDisplayLabel }
        ),
      };
    },
    [fm]
  );

  return { getErrorMessage, getSuccessMessage };
};
