import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyExceptionReason } from '@endorlabs/api_client';
import { PolicyResource } from '@endorlabs/queries';
import { DateDisplay, IconEdit, RowStack } from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { ExceptionPolicyReasonLabels } from '../locales';

interface ExceptionPolicyDetailProps {
  exceptionPolicies: PolicyResource[];
}

export const ExceptionPolicyDetails = ({
  exceptionPolicies = [],
}: ExceptionPolicyDetailProps) => {
  const { space, spacing } = useTheme();
  const navigate = useNavigate();
  const { activeNamespace: tenantName } = useAuthInfo();

  return (
    <Stack rowGap={space.xs}>
      {exceptionPolicies.map((policy) => {
        const { exception } = policy.spec;

        return (
          <Stack key={policy.uuid} rowGap={space.xs}>
            <RowStack gap={0}>
              <Typography variant="body2">
                <strong>
                  <FM
                    {...ExceptionPolicyReasonLabels[
                      exception?.reason ?? PolicyExceptionReason.Unspecified
                    ]}
                  />
                </strong>
              </Typography>

              <Typography>・</Typography>

              <Typography color="textSecondary" variant="body2">
                {exception?.expiration_time && (
                  <FM
                    defaultMessage="Expires on {date}"
                    values={{
                      date: <DateDisplay value={exception.expiration_time} />,
                    }}
                  />
                )}

                {!exception?.expiration_time && (
                  <FM defaultMessage="No expiration" />
                )}
              </Typography>
            </RowStack>

            <RowStack
              alignItems="flex-start"
              flexWrap="nowrap"
              justifyContent="space-between"
              width="100%"
            >
              <RowStack>
                <Typography component="span" variant="body2">
                  {policy.meta.name}
                </Typography>
              </RowStack>

              <Box flexShrink={0}>
                <IconButton
                  onClick={() =>
                    navigate({
                      to: getPoliciesPath({
                        tenantName,
                        section: `exceptions/edit/${policy.uuid}`,
                      }),
                    })
                  }
                >
                  <IconEdit
                    fontSize="small"
                    sx={{ width: spacing(4), height: spacing(4) }}
                  />
                </IconButton>
              </Box>
            </RowStack>
          </Stack>
        );
      })}
    </Stack>
  );
};
