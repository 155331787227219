import { Grammar, Parser } from 'nearley';

import { FilterExpression } from '../expression';
import { Filter, ValueFilter } from '../types';
import { isLogicFilter, isValueFilter } from '../utils';
import GENERATED_GRAMMER from './generated/grammar';

export class FilterParseError extends Error {
  override name = 'FilterParseError';
  public input: FilterExpression;
  constructor(message: string, options: { input: FilterExpression }) {
    super(message);
    this.input = options.input;
  }
}

export class FilterParser {
  private grammar: Grammar;

  constructor() {
    this.grammar = Grammar.fromCompiled(GENERATED_GRAMMER);
  }

  /**
   * @throws {Error} throws exception on parsing errors
   */
  parse(input: FilterExpression): Filter | undefined {
    const parser = new Parser(this.grammar);
    parser.feed(input);
    parser.finish();

    // multiple parsings are possible, use the first
    return parser.results[0];
  }

  /**
   * Wrapper around parse to prevent exceptions on parse failure.
   */
  safeParse(
    input: FilterExpression
  ): { ok: true; value: Filter } | { ok: false; error: Error } {
    try {
      const value = this.parse(input);
      if (!value) {
        return {
          ok: false,
          error: new FilterParseError('Unable to parse filter expression', {
            input,
          }),
        };
      }

      return { ok: true, value };
    } catch (error) {
      return { ok: false, error: error as Error };
    }
  }
}
