import { FormattedMessage as FM } from 'react-intl';

import { V1SystemRole as PermissionRole } from '@endorlabs/api_client';

import { PermissionRoleMessages } from '../../locales';

interface PermissionRoleLabelProps {
  role: PermissionRole;
}

export const PermissionRoleLabel = ({ role }: PermissionRoleLabelProps) => (
  <FM {...PermissionRoleMessages[role]} />
);
