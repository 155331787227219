import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog } from '@endorlabs/ui-common';

import {
  FormUpsertInstallation,
  FormUpsertInstallationProps,
} from './FormUpsertInstallation';

interface EditInstallationDialogProps
  extends Omit<FormUpsertInstallationProps, 'onCancel'> {
  onClose: FormUpsertInstallationProps['onCancel'];
  onSuccess: FormUpsertInstallationProps['onSuccess'];
  open: boolean;
}

export const EditInstallationDialog = ({
  installation,
  onClose,
  onSuccess,
  open,

  ...formProps
}: EditInstallationDialogProps) => {
  return (
    <SimpleDialog
      onClose={onClose}
      open={open}
      titleText={<FM defaultMessage="Integration Settings" />}
    >
      <FormUpsertInstallation
        installation={installation}
        {...formProps}
        onCancel={onClose}
        onSuccess={onSuccess}
      />
    </SimpleDialog>
  );
};
