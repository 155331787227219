import { FilterStateValues } from '../hooks';
import { FilterFieldConfig } from '../types';

export const getInitialFilterValues = (
  fields: FilterFieldConfig<unknown>[]
): FilterStateValues => {
  const initialFilterValues: FilterStateValues = new Map();

  for (const field of fields) {
    if (field.initialFilter) {
      initialFilterValues.set(field.id, field.initialFilter);
    }
  }

  return initialFilterValues;
};
