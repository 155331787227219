import './prism-endor.css';
import './prism-line-numbers.css';
import './prism-autolinker.css';

import { Box, Button, Divider, Stack } from '@mui/material';
import Prism from 'prismjs';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  CopyToClipboardButton,
  FileDownloadButton,
  FileDownloadButtonProps,
} from '../../components';
import { IconChevronDown, IconChevronUp } from '../../themes';

const MAX_LINES_SHOWN = 15;
interface HighlightCodeBlockProps {
  enableClipboard?: boolean;
  downloadProps?: FileDownloadButtonProps;
  header?: ReactNode;
  value: string;
  filetype: string;
}

export const HighlightCodeBlock = ({
  enableClipboard = true,
  downloadProps,
  header,
  value,
  filetype,
}: HighlightCodeBlockProps) => {
  const [showMore, setShowMore] = useState(false);
  const totalNumLines = value?.split('\n').length ?? 0;
  const truncatedValue = value
    ?.split('\n')
    .slice(0, MAX_LINES_SHOWN)
    .join('\n');

  const showValue = showMore ? value : truncatedValue;
  useEffect(() => {
    Prism.highlightAll();
  }, [showValue]);
  return (
    <Box className="Code highlight-codeblock">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {header && <Box>{header}</Box>}
        <Stack direction="row" justifyContent="flex-end">
          {enableClipboard && (
            <CopyToClipboardButton size="medium" value={value} />
          )}
          {downloadProps && (
            <FileDownloadButton size="medium" {...downloadProps} />
          )}
        </Stack>
      </Stack>
      <pre>
        <code className={`language-${filetype} line-numbers`}>{showValue}</code>
      </pre>
      {totalNumLines > MAX_LINES_SHOWN && (
        <Stack justifyContent="center" alignItems="center">
          <Button
            onClick={() => setShowMore(!showMore)}
            startIcon={showMore ? <IconChevronUp /> : <IconChevronDown />}
          >
            {showMore ? (
              <FM defaultMessage="SHOW LESS" />
            ) : (
              <FM
                defaultMessage="SHOW {remaining, number} MORE LINES"
                values={{
                  remaining: totalNumLines - MAX_LINES_SHOWN,
                }}
              />
            )}
          </Button>
        </Stack>
      )}
    </Box>
  );
};
