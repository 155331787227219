import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';
import { PickDeep } from 'type-fest';

import { InstallationResource, useFeatureFlags } from '@endorlabs/queries';
import { useTabs } from '@endorlabs/ui-common';

import {
  EnabledFeaturesFields,
  EnabledFeaturesFieldsProps,
} from './EnabledFeaturesFields';
import { PullRequestConfigFields } from './PullRequestConfigFields';

export type UpsertInstallationGitHubFields = PickDeep<
  InstallationResource,
  'spec.github_config' | 'spec.enabled_features'
>;

export interface FormUpsertInstallationGitHubProps {
  featureRecords?: EnabledFeaturesFieldsProps['featureRecords'];
  installation?: InstallationResource;
  secondaryButtons?: ReactNode;
}

/**
 * Form to create/update installation config options specific to GitHub app installations
 */
export const FormUpsertInstallationGitHub = ({
  featureRecords = [],
}: FormUpsertInstallationGitHubProps) => {
  const { space } = useTheme();

  const { ENABLE_PR_SCAN_OPTIONS } = useFeatureFlags();

  const TabRecords = [
    {
      label: <FM defaultMessage="Scanners" />,
      value: 'ENABLED_FEATURES',
    },
  ];

  if (ENABLE_PR_SCAN_OPTIONS) {
    TabRecords.push({
      label: <FM defaultMessage="Pull Request Scans" />,
      value: 'PR_SCANS',
    });
  }

  const { activeTabValue, getTabsProps } = useTabs({
    activeTabKey: 'ENABLED_FEATURES',
    id: 'FormUpsertInstallationGitHub',
    tabRecords: TabRecords,
  });

  const tabPanelMap = {
    ENABLED_FEATURES: (
      <Stack rowGap={space.sm}>
        <Box>
          <Typography variant="h3">
            <FM defaultMessage="Enabled Scanners" />
          </Typography>
          <Typography variant="caption">
            <FM defaultMessage="Select the scan types to enable. Results for these scan types will be generated once your next scan is initiated." />
          </Typography>
        </Box>

        <EnabledFeaturesFields featureRecords={featureRecords} />
      </Stack>
    ),

    PR_SCANS: <PullRequestConfigFields />,
  };

  return (
    <TabContext value={activeTabValue}>
      <Stack rowGap={space.sm}>
        <TabList {...getTabsProps()} />

        <Stack rowGap={space.md}>
          {Object.entries(tabPanelMap).map(([tabValue, tabPanelNode]) => {
            // Ideally these TabPanels would have keepMounted={true} (MUI 6)
            return (
              <TabPanel key={tabValue} value={tabValue}>
                {tabPanelNode}
              </TabPanel>
            );
          })}
        </Stack>
      </Stack>
    </TabContext>
  );
};
