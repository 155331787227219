import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { addDays, addHours } from 'date-fns';
import _sortBy from 'lodash-es/sortBy';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  ErrorOption,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  defineMessages,
  FormattedMessage as FM,
  MessageDescriptor,
  useIntl,
} from 'react-intl';

import {
  AuthorizationPolicyIdentityClaimPrefix,
  AuthorizationPolicyIdentitySourceType,
  AuthorizationPolicyResource,
  parseAuthorizationPolicyClause,
} from '@endorlabs/endor-core/AuthorizationPolicy';
import { IdentityProviderResource, IQueryError } from '@endorlabs/queries';

import { ButtonPrimary } from '../../../../components';
import { useResourceCRUDMessages } from '../../../../hooks';
import { IconAlertTriangle, IconPlusCircle, IconX } from '../../../../themes';
import { AuthorizationPolicyAdvancedFields } from './AuthorizationPolicyAdvancedFields';
import {
  DEFAULT_IDENTITY_CLAIMS,
  DEFAULT_IDENTITY_SOURCE_OPTIONS,
  DEFAULT_PERMISSION_ROLES_OPTIONS,
} from './constants';
import { FormUpsertAuthorizationPolicyFieldValues } from './types';
import {
  fromAuthPolicyResourceModel,
  toAuthPolicyResourceModel,
} from './utils';

const getExpirationTimeOptions = (
  authorizationPolicy?: AuthorizationPolicyResource
) => {
  const now = Date.now();

  const offsetHours = [24, 72];
  const offsetDays = [7, 14, 30];

  const expirationTimeOptions = [];

  // add an timestamp from the auth policy
  if (authorizationPolicy?.spec.expiration_time) {
    const timestampValue = authorizationPolicy?.spec.expiration_time;
    const timestampDate = new Date(timestampValue);
    expirationTimeOptions.push({
      key: 'spec.expiration_time',
      value: timestampValue,
      label: (
        <FM
          defaultMessage="Expires on {timestamp, date, medium}"
          values={{ timestamp: timestampDate }}
        />
      ),
    });
  }

  offsetHours.forEach((offset) => {
    const timestamp = addHours(now, offset);
    expirationTimeOptions.push({
      key: `offset_hours_${offset}`,
      value: timestamp.toISOString(),
      label: (
        <FM
          defaultMessage="Expires in {offset} Hours &middot; {timestamp, date, medium}"
          values={{ offset, timestamp }}
        />
      ),
    });
  });

  offsetDays.forEach((offset) => {
    const timestamp = addDays(now, offset);
    expirationTimeOptions.push({
      key: `offset_days_${offset}`,
      value: timestamp.toISOString(),
      label: (
        <FM
          defaultMessage="Expires in {offset} Days &middot; {timestamp, date, medium}"
          values={{ offset, timestamp }}
        />
      ),
    });
  });

  return expirationTimeOptions;
};

const IdentityClaimValuePlaceholderMessages: Record<string, MessageDescriptor> =
  defineMessages({
    [AuthorizationPolicyIdentitySourceType.Azure]: {
      defaultMessage: 'Grant access to a Microsoft email address',
    },
    [AuthorizationPolicyIdentitySourceType.GitHub]: {
      defaultMessage: 'Grant access to a GitHub handle',
    },
    [AuthorizationPolicyIdentitySourceType.GitLab]: {
      defaultMessage: 'Grant access to a GitLab handle',
    },
    [AuthorizationPolicyIdentitySourceType.Google]: {
      defaultMessage: 'Grant access to a Gmail email address',
    },
    default: { defaultMessage: 'Claim Value' },
  });

const getIdentityClaimValuePlaceholder = (
  identitySource: AuthorizationPolicyIdentitySourceType | string
): MessageDescriptor => {
  return (
    IdentityClaimValuePlaceholderMessages[identitySource] ??
    IdentityClaimValuePlaceholderMessages.default
  );
};

/**
 * If specified, limit the claim prefixes for the given source type.
 *
 * If not specified, default to allow `user` claim
 */
const IdentityClaimPrefixMap: Record<
  string,
  Array<AuthorizationPolicyIdentityClaimPrefix>
> = {
  [AuthorizationPolicyIdentitySourceType.GCP]: ['email'],
  [AuthorizationPolicyIdentitySourceType.Google]: ['user', 'domain'],
  [AuthorizationPolicyIdentitySourceType.Endor]: ['email'],
};

const getIdentityClaimPrefixOptions = (
  identitySource?: AuthorizationPolicyIdentitySourceType | string,
  authorizationPolicy?: AuthorizationPolicyResource
) => {
  if (!identitySource) return [];

  let claimPrefixes = IdentityClaimPrefixMap[identitySource] ?? ['user'];

  // add additional prefixes present in the policy
  if (authorizationPolicy) {
    const authPolicyClaimPrefixes = parseAuthorizationPolicyClause(
      authorizationPolicy
    ).claims.map((c) => c.prefix);

    for (const p of authPolicyClaimPrefixes) {
      if (!claimPrefixes.includes(p)) {
        claimPrefixes = [...claimPrefixes, p];
      }
    }
  }

  return claimPrefixes;
};

// merge identity source options with custom, if given
const getIdentitySourceOptions = (
  namespace: string,
  authorizationPolicy?: AuthorizationPolicyResource,
  identityProvider?: IdentityProviderResource
): [
  options: { label: ReactNode; value: AuthorizationPolicyIdentitySourceType }[],
  isCustom: boolean
] => {
  let isCustom = false;
  const defaultIdentitySource = authorizationPolicy
    ? parseAuthorizationPolicyClause(authorizationPolicy).source
    : undefined;

  // build options from shallow clone of default options
  const options: {
    label: ReactNode;
    value: AuthorizationPolicyIdentitySourceType;
  }[] = [...DEFAULT_IDENTITY_SOURCE_OPTIONS];

  // Add the Identity Provider as auth provider option when provided
  if (
    identityProvider &&
    // NOTE: the IdentityProvider object does not propagate, and should only be
    // included as an option if the IdentityProvider exists in the namespace of
    // an AuthorizationPolicy under edit, or the active namespace when creating
    // a new AuthorizationPolicy.
    (authorizationPolicy
      ? authorizationPolicy.tenant_meta.namespace === namespace
      : identityProvider?.tenant_meta.namespace === namespace)
  ) {
    options.push({
      value: AuthorizationPolicyIdentitySourceType.IdentityProvider,
      label: identityProvider.meta.name,
    });
  }

  if (
    defaultIdentitySource &&
    !options.some((o) => o.value === defaultIdentitySource.type)
  ) {
    options.unshift({
      value: defaultIdentitySource.type,
      label: defaultIdentitySource.type,
    });
    isCustom = true;
  }

  return [options, isCustom];
};

export interface FormUpsertAuthorizationPolicyProps {
  /**
   * Existing Auth Policy to update. Must be memoized.
   */
  authorizationPolicy?: AuthorizationPolicyResource;
  /**
   * (optional) Identity Provider for Tenant
   */
  authorizationPolicyIdentityProvider?: IdentityProviderResource;
  /**
   * Namespace to create Auth Policy in.
   */
  authorizationPolicyNamespace: string;
  onSubmit: (data: AuthorizationPolicyResource) => void;
  serverError?: IQueryError;
}

/**
 * Form used to create or edit an Auth Policy
 *
 * Currently, only manages 'global' permissions for a user
 */
export const FormUpsertAuthorizationPolicy = ({
  authorizationPolicy,
  authorizationPolicyNamespace,
  authorizationPolicyIdentityProvider,
  onSubmit,
  serverError,
}: FormUpsertAuthorizationPolicyProps) => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { formatMessage: fm } = useIntl();
  const {
    control,
    handleSubmit: hookFormSubmit,
    reset,
    setError,
    watch,
    getValues,
    setValue,
  } = useForm<FormUpsertAuthorizationPolicyFieldValues>();
  const identityClaimsFieldArray = useFieldArray({
    control,
    name: 'identityClaims',
  });

  // reset the form with defaultValues
  useEffect(() => {
    const defaultValues = fromAuthPolicyResourceModel(
      authorizationPolicyNamespace,
      authorizationPolicy,
      authorizationPolicyIdentityProvider
    );
    reset(defaultValues);
  }, [
    authorizationPolicy,
    authorizationPolicyIdentityProvider,
    authorizationPolicyNamespace,
    reset,
  ]);

  // manage server errors
  const [localError, setLocalError] = useState<
    (ErrorOption & { details?: string }) | undefined
  >();
  useEffect(() => {
    if (serverError) {
      const action = authorizationPolicy ? 'UPDATE' : 'CREATE';
      const { message, details } = getErrorMessage(
        action,
        'AuthorizationPolicy',
        serverError
      );
      setLocalError({ type: 'server', message, details });
    } else {
      setLocalError(undefined);
    }
  }, [authorizationPolicy, getErrorMessage, serverError, setError]);

  const identitySourceTypeValue = watch('identitySourceType');
  const [identitySourceTypeOptions, isCustomIdentitySource] = useMemo(
    () =>
      getIdentitySourceOptions(
        authorizationPolicyNamespace,
        authorizationPolicy,
        authorizationPolicyIdentityProvider
      ),
    [
      authorizationPolicy,
      authorizationPolicyIdentityProvider,
      authorizationPolicyNamespace,
    ]
  );

  const permissionRolesValue = watch('permissionRoles');

  const expirationTimeOptions = useMemo(
    () => getExpirationTimeOptions(authorizationPolicy),
    [authorizationPolicy]
  );

  const [identityClaimPrefixOptions, setIdentityClaimPrefixOptions] = useState<
    string[]
  >([]);

  // keep claim prefix options in sync with identity source
  useEffect(() => {
    const prefixOptions = getIdentityClaimPrefixOptions(
      identitySourceTypeValue,
      authorizationPolicy
    );

    const identityClaims = getValues('identityClaims');

    if (prefixOptions.length) {
      identityClaims?.forEach((claim, index) => {
        if (!prefixOptions.includes(claim.prefix)) {
          setValue(`identityClaims.${index}.prefix`, prefixOptions[0]);
        }
      });
    }

    setIdentityClaimPrefixOptions(prefixOptions);
  }, [authorizationPolicy, getValues, identitySourceTypeValue, setValue]);

  const handleSubmit = useCallback(
    (fieldValues: FormUpsertAuthorizationPolicyFieldValues) => {
      const model = toAuthPolicyResourceModel(
        authorizationPolicyNamespace,
        fieldValues,
        authorizationPolicy,
        authorizationPolicyIdentityProvider
      );

      onSubmit(model);
    },
    [
      authorizationPolicyNamespace,
      authorizationPolicy,
      authorizationPolicyIdentityProvider,
      onSubmit,
    ]
  );

  const enableCustomClaim =
    identitySourceTypeValue === 'IdentityProvider' ||
    identityClaimPrefixOptions.length === 0;
  const isEdit = Boolean(authorizationPolicy);

  return (
    <form
      id="FormUpsertAuthorizationPolicy"
      onSubmit={hookFormSubmit(handleSubmit)}
    >
      <Grid container direction="column" spacing={8}>
        <Grid item xs={12}>
          <Controller
            control={control}
            defaultValue=""
            name="identitySourceType"
            render={({ field: { ref, ...props }, fieldState }) => {
              return (
                <TextField
                  {...props}
                  autoComplete="off"
                  error={!!fieldState.error}
                  fullWidth
                  helperText={
                    fieldState.error ? (
                      fieldState.error.message
                    ) : (
                      <FM defaultMessage="The identity provider to which the authorization policy will apply" />
                    )
                  }
                  inputRef={ref}
                  label={fm({
                    defaultMessage: 'Identity Provider',
                  })}
                  select
                  variant="standard"
                >
                  {_sortBy(identitySourceTypeOptions, ['value']).map(
                    ({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              );
            }}
            rules={{
              required: fm({ defaultMessage: 'Identity Provider is required' }),
            }}
          />
        </Grid>

        {/* Hide Permission Control until Provider is selected */}
        {identitySourceTypeValue && (
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue={[]}
              name="permissionRoles"
              render={({ field: { ref, ...props }, fieldState }) => {
                return (
                  <TextField
                    {...props}
                    autoComplete="off"
                    error={!!fieldState.error}
                    fullWidth
                    helperText={
                      fieldState.error ? (
                        fieldState.error.message
                      ) : (
                        <FM defaultMessage="These permissions will be provided to any user that matches the corresponding claims" />
                      )
                    }
                    inputRef={ref}
                    label={fm({
                      defaultMessage: 'Permissions',
                    })}
                    variant="standard"
                    select
                    SelectProps={{ multiple: true }}
                  >
                    {DEFAULT_PERMISSION_ROLES_OPTIONS.map(
                      ({ label, value }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                );
              }}
              rules={{
                required: fm({ defaultMessage: 'Permissions are required' }),
              }}
            />
          </Grid>
        )}

        {/* Hide Expiration Time until Provider and Permission are selected */}
        {identitySourceTypeValue && !!permissionRolesValue?.length && (
          <Grid item xs={12}>
            <Controller
              control={control}
              defaultValue=""
              name="expirationTime"
              render={({ field: { ref, ...props }, fieldState }) => {
                return (
                  <TextField
                    {...props}
                    autoComplete="off"
                    error={!!fieldState.error}
                    fullWidth
                    helperText={
                      fieldState.error ? (
                        fieldState.error.message
                      ) : (
                        <FM defaultMessage="The authorization policy will be disabled after an expiration time, if given" />
                      )
                    }
                    inputRef={ref}
                    label={fm({
                      defaultMessage: 'Expiration Time (optional)',
                    })}
                    select
                    variant="standard"
                  >
                    <MenuItem value="">
                      <FM defaultMessage="No Expiration" />
                    </MenuItem>

                    {expirationTimeOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              }}
            />
          </Grid>
        )}

        {/* Hide Identity Claims until Provider and Permission are selected */}
        {identitySourceTypeValue && !!permissionRolesValue?.length && (
          <Grid item>
            <Box
              component="fieldset"
              sx={{ margin: 0, padding: 0, border: 'none ' }}
            >
              <FormLabel component="legend" sx={{ marginBottom: 2 }}>
                <Typography variant="body1" color="text.primary">
                  <FM defaultMessage="Claims" />
                </Typography>

                <Typography variant="body2">
                  <FM defaultMessage="Configure the key value pairs provided in the claims of your identity provider and their corresponding permissions" />
                </Typography>
              </FormLabel>
            </Box>

            {identityClaimsFieldArray.fields.map((field, index) => (
              <Grid key={field.id} container spacing={4} marginTop={2}>
                <Grid item xs={enableCustomClaim ? 4 : 6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`identityClaims.${index}.prefix`}
                    render={({ field: { ref, ...props }, fieldState }) => {
                      return (
                        <TextField
                          {...props}
                          autoComplete="off"
                          error={!!fieldState.error}
                          fullWidth
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          inputRef={ref}
                          label={fm({
                            defaultMessage: 'Key',
                          })}
                          placeholder={fm({ defaultMessage: 'Identity' })}
                          select={!enableCustomClaim}
                          variant="standard"
                        >
                          {identityClaimPrefixOptions.map((value) => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    }}
                    rules={{
                      required: fm({
                        defaultMessage: 'Claim Key is required',
                      }),
                    }}
                  />
                </Grid>

                <Grid item xs={enableCustomClaim ? 4 : 6}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`identityClaims.${index}.value`}
                    render={({ field: { ref, ...props }, fieldState }) => {
                      return (
                        <TextField
                          {...props}
                          autoComplete="off"
                          error={!!fieldState.error}
                          fullWidth
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                          inputRef={ref}
                          label={fm({
                            defaultMessage: 'Value',
                          })}
                          placeholder={fm(
                            getIdentityClaimValuePlaceholder(
                              identitySourceTypeValue
                            )
                          )}
                          variant="standard"
                        />
                      );
                    }}
                    rules={{
                      required: fm({
                        defaultMessage: 'Claim Value is required',
                      }),
                      validate: (value) => {
                        return (
                          !!value.trim() ||
                          fm({
                            defaultMessage: 'Non-empty Claim value is required',
                          })
                        );
                      },
                    }}
                  />
                </Grid>

                {enableCustomClaim && (
                  <Grid item xs={4}>
                    {index === identityClaimsFieldArray.fields.length - 1 ? (
                      <Button
                        startIcon={<IconPlusCircle />}
                        onClick={() =>
                          identityClaimsFieldArray.append(
                            DEFAULT_IDENTITY_CLAIMS[0]
                          )
                        }
                        sx={{ fontWeight: 600, paddingX: 3 }}
                      >
                        <FM defaultMessage="Add Another Claim" />
                      </Button>
                    ) : (
                      <IconButton
                        color="inherit"
                        size="medium"
                        onClick={() => identityClaimsFieldArray.remove(index)}
                      >
                        <IconX />
                      </IconButton>
                    )}
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )}

        {/* Hide Advanced Fields until Provider and Permission are selected */}
        {identitySourceTypeValue && !!permissionRolesValue?.length && (
          <Grid item>
            <AuthorizationPolicyAdvancedFields control={control} />
          </Grid>
        )}

        {localError && (
          <Grid item>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>

              {localError.details}
            </Alert>
          </Grid>
        )}

        <Grid item>
          <ButtonPrimary type="submit">
            {isEdit ? (
              <FM defaultMessage="Update Auth Policy" />
            ) : (
              <FM defaultMessage="Add Auth Policy" />
            )}
          </ButtonPrimary>
        </Grid>
      </Grid>
    </form>
  );
};
