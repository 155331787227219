import { Stack } from '@mui/material';

import { AnyResourceType } from '@endorlabs/endor-core';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { IconGitBranch, ScanResultStatusIndicator } from '@endorlabs/ui-common';

import {
  ResourceVersionSelectProps,
  ResourceVersionSelectV2,
} from '../../components';

interface ProjectVersionBreadcrumbProps<TResource extends AnyResourceType>
  extends ResourceVersionSelectProps<TResource> {
  scanResults?: ScanResultResource[];
  onStatusClick?: () => void;
}

export const ProjectVersionBreadcrumb = <TResource extends AnyResourceType>({
  scanResults,
  getLabelFn,
  isLoading,
  resourceList,
  selectedResource,
  onChange,
  onStatusClick,
}: ProjectVersionBreadcrumbProps<TResource>) => {
  const displayAsGitRepo = selectedResource?.meta.kind === 'RepositoryVersion';

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <ScanResultStatusIndicator
        scanResults={scanResults}
        onClick={onStatusClick}
      />

      {displayAsGitRepo && <IconGitBranch fontSize="small" />}

      <ResourceVersionSelectV2
        getLabelFn={getLabelFn}
        isLoading={isLoading}
        resourceList={resourceList}
        selectedResource={selectedResource}
        onChange={onChange}
      />
    </Stack>
  );
};
