import { OnChangeFn, PaginationState, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { V1ListParameters } from '@endorlabs/api_client';

import { DEFAULT_PAGE_SIZE } from './constants';

export interface DataTablePaginatorState extends PaginationState {
  totalCount: number;
}

/**
 * When pagination is "infinite", require the caller to provide a function to
 * determine if there is a next page.
 */
type DataTablePaginatorInfiniteProps =
  | {
      isInfinite: true;
      hasNextPage: () => boolean;
    }
  | { isInfinite?: false; hasNextPage?: never };

export type DataTablePaginatorProps = Partial<
  Pick<DataTablePaginatorState, 'pageIndex' | 'pageSize' | 'totalCount'>
> &
  DataTablePaginatorInfiniteProps;

export interface DataTablePaginator {
  pageCount: number;
  state: DataTablePaginatorState;
  onPaginationChange: OnChangeFn<PaginationState>;
  getListParameters: () => Pick<V1ListParameters, 'page_size' | 'page_token'>;
  getPageSlice: <T = unknown>(items: T[]) => T[];
  hasNextPage: () => boolean;
  isInfinite: boolean;
  resetPagination: () => void;
}

/**
 * Utility function to return a page slice for the given paginator
 */
export function getPaginatorPageSlice<T = unknown>(
  paginatorState: DataTablePaginatorState,
  items: T[]
): T[] {
  const { pageIndex, pageSize } = paginatorState;
  const start = pageIndex * pageSize;
  const page = items.slice(start, start + pageSize);

  return page;
}

export const useDataTablePaginator = ({
  totalCount = 0,
  pageIndex: initialPageIndex = 0,
  pageSize: initialPageSize = DEFAULT_PAGE_SIZE,
  isInfinite,
  hasNextPage: infiniteHasNextPage,
}: DataTablePaginatorProps): DataTablePaginator => {
  const [state, setPagination] = useState<DataTablePaginatorState>({
    totalCount,
    pageIndex: initialPageIndex,
    pageSize: initialPageSize,
  });

  // page count is calulated from state
  const pageCount = useMemo(() => {
    if (isInfinite) return -1;

    return Math.ceil(state.totalCount / state.pageSize);
  }, [isInfinite, state.totalCount, state.pageSize]);

  const pageToken = useMemo(
    () => state.pageIndex * state.pageSize,
    [state.pageIndex, state.pageSize]
  );

  const getListParameters = useCallback(() => {
    return {
      page_size: state.pageSize,
      page_token: pageToken,
    };
  }, [state.pageSize, pageToken]);

  const getPageSlice = useCallback(
    function <T = unknown>(items: T[]) {
      return getPaginatorPageSlice(state, items);
    },
    [state]
  );

  const hasNextPage = useCallback(() => {
    if (isInfinite) {
      return infiniteHasNextPage();
    }

    return state.pageIndex < pageCount - 1;
  }, [infiniteHasNextPage, isInfinite, pageCount, state.pageIndex]);

  const resetPagination = useCallback(() => {
    setPagination({
      totalCount,
      pageIndex: initialPageIndex,
      pageSize: initialPageSize,
    });
  }, [totalCount, initialPageIndex, initialPageSize]);

  useEffect(() => {
    setPagination((state) => ({ ...state, totalCount, pageIndex: 0 }));
  }, [totalCount]);

  return {
    isInfinite: isInfinite ?? false,
    state,
    pageCount,
    onPaginationChange: (value: Updater<PaginationState>) =>
      setPagination(value as any),
    getListParameters,
    getPageSlice,
    hasNextPage,
    resetPagination,
  };
};
