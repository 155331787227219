import { Grid } from '@mui/material';
import { Outlet } from '@tanstack/react-location';
import { remove as _remove } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useFeatureFlags } from '@endorlabs/queries';
import { IconSettings, NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';

const SettingsTabNames = {
  LICENSE: 'license',
  SAVED_FILTERS: 'saved-filters',
  SCAN_PROFILES: 'scan-profiles',
  SYSTEM: 'system',
};

const DefaultSettingsTabRecords = [
  {
    label: <FM defaultMessage="License" />,
    to: SettingsTabNames.LICENSE,
    value: SettingsTabNames.LICENSE,
    key: SettingsTabNames.LICENSE,
  },
  {
    label: <FM defaultMessage="System Settings" />,
    to: SettingsTabNames.SYSTEM,
    value: SettingsTabNames.SYSTEM,
    key: SettingsTabNames.SYSTEM,
  },
  {
    label: <FM defaultMessage="Saved Filters" />,
    to: SettingsTabNames.SAVED_FILTERS,
    value: SettingsTabNames.SAVED_FILTERS,
    key: SettingsTabNames.SAVED_FILTERS,
  },
  {
    label: <FM defaultMessage="Scan Profiles" />,
    to: SettingsTabNames.SCAN_PROFILES,
    value: SettingsTabNames.SCAN_PROFILES,
    key: SettingsTabNames.SCAN_PROFILES,
  },
];

export const SettingsPage = () => {
  const { ENABLE_LICENSE_AND_BUNDLING } = useFeatureFlags();

  const SettingsTabRecords = useMemo(() => {
    const tabRecords = DefaultSettingsTabRecords.slice();

    // Omit license tab when feature flag is not enabled
    if (!ENABLE_LICENSE_AND_BUNDLING) {
      _remove(tabRecords, (r) => r.key === SettingsTabNames.LICENSE);
    }

    return tabRecords;
  }, [ENABLE_LICENSE_AND_BUNDLING]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="Settings" />} />
      </Grid>
      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs tabs={SettingsTabRecords} />
      </Grid>

      <Grid item>
        <Outlet />
      </Grid>
    </Grid>
  );
};
