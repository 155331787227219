import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

const TenantProjectVersionDetailIndexRoute = () => {
  return <Navigate to="findings" replace />;
};

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/versions/:version_ref/'
)({
  component: TenantProjectVersionDetailIndexRoute,
});
