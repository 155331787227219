import { Stack } from '@mui/material';
import { get } from 'lodash-es';
import { ReactNode, useCallback } from 'react';

import { AnyResourceType } from '@endorlabs/endor-core';
import { DateDisplay, IconClock } from '@endorlabs/ui-common';

/**
 * Helper for the Resource Version Select, to return a formatted description
 * for the given Resource
 */
export const useResourceTimestampDescription = ({
  key = 'meta.update_time',
}: {
  key?: string;
}): ((resource?: AnyResourceType) => ReactNode) => {
  return useCallback(
    (resource?: AnyResourceType) => {
      const timestamp = resource && get(resource, key);

      return timestamp ? (
        <Stack alignItems="center" component="span" direction="row" gap={1}>
          <IconClock fontSize="inherit" />
          <DateDisplay value={timestamp} />
        </Stack>
      ) : (
        <span>&mdash;</span>
      );
    },
    [key]
  );
};
