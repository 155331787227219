import { FormattedMessage as FM } from 'react-intl';

import {
  V1ExportedSBOMComponentType,
  V1ExportedSBOMFormat,
  V1SBOMKind,
} from '@endorlabs/api_client';

export const SBOM_KIND_OPTIONS = [
  {
    value: V1SBOMKind.Cyclonedx,
    label: <FM defaultMessage="CycloneDX" />,
  },
];
export const SBOM_COMPONENT_TYPE_OPTIONS = [
  {
    value: V1ExportedSBOMComponentType.Application,
    label: <FM defaultMessage="Application" />,
  },
  {
    value: V1ExportedSBOMComponentType.Library,
    label: <FM defaultMessage="Library" />,
  },
];
export const SBOM_FORMAT_OPTIONS = [
  {
    value: V1ExportedSBOMFormat.Json,
    label: <FM defaultMessage="JSON" />,
  },
  {
    value: V1ExportedSBOMFormat.Xml,
    label: <FM defaultMessage="XML" />,
  },
];
