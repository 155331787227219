import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { get as _get, head as _head } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PackageContexture,
  PackageResource,
  PackageVersionResource,
  sortParamBuilders,
  useListPackageVersions,
  useQueryPackagesIndexPackageVersions,
} from '@endorlabs/queries';
import {
  AccordionCard,
  PackageNameDisplay,
  UIPackageVersionUtils,
  useProjectInfoCache,
} from '@endorlabs/ui-common';

import { usePackageVersionDetailDrawer } from '../../../domains/PackageVersion';
import { PackagesIndexDetailSummary } from './PackagesIndexDetailSummary';
import { PackagesIndexDetailTable } from './PackagesIndexDetailTable';
import { mapToPackagesIndexDetailTableRow } from './utils';

const REFERENCES_OSS_PACKAGES: ResourceKind[] = [
  'Metric',
  'DependencyMetadata',
];
const REFERENCES_TENANT_PACKAGES: ResourceKind[] = ['Finding'];

type PackageVersionSummary = {
  name: string;
  versionRef: string;
  repositoryUrl?: string;
};

export interface PackagesIndexDetailProps {
  packageContexture: PackageContexture;
  packageResource: PackageResource;
  projectUuid?: string;
  versionCount: number;
  versions?: PackageVersionSummary[];
}

export const PackagesIndexDetail = ({
  packageContexture,
  packageResource,
  projectUuid,
  versionCount,
  versions,
}: PackagesIndexDetailProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { DetailDrawer, permalinkEffect } = usePackageVersionDetailDrawer();

  const packageNamespace = packageResource.tenant_meta.namespace;
  const latestPackageVersionRef = _get(versions, [0, 'versionRef']);
  const latestPackageVersionRepositoryUrl = _get(versions, [
    0,
    'repositoryUrl',
  ]);

  const packageVersionFilters = [
    filterExpressionBuilders.defaultResourceContexts(
      packageResource.tenant_meta.namespace
    ),
    `spec.package_name=="${packageResource.meta.name}"`,
  ];

  // If a project_uuid is provided, further filter the packages
  if (projectUuid) {
    packageVersionFilters.push(`spec.project_uuid=="${projectUuid}"`);
  }

  const projectCache = useProjectInfoCache(packageNamespace, {
    enabled: !!projectUuid,
  });

  const qQueryPackageVersions = useQueryPackagesIndexPackageVersions(
    packageNamespace,
    {
      enabled: !!packageResource && isExpanded,
      staleTime: 5 * 60 * 1000,
    },
    {
      filter: filterExpressionBuilders.and(packageVersionFilters),
      page_size: 10,
      sort: sortParamBuilders.descendingBy('spec.release_timestamp'),
    },
    packageNamespace === NAMESPACES.OSS
      ? REFERENCES_OSS_PACKAGES
      : REFERENCES_TENANT_PACKAGES
  );

  const packageVersions = useMemo(() => {
    const pages = qQueryPackageVersions.data?.pages ?? [];

    return pages
      .flatMap((page) => {
        const pv = page?.list?.objects ?? [];
        return pv.map((obj) => mapToPackagesIndexDetailTableRow(obj));
      })
      .sort((a, b) =>
        UIPackageVersionUtils.sortBySemanticVersion(
          b.version ?? '',
          a.version ?? ''
        )
      );
  }, [qQueryPackageVersions.data]);

  const hasMorePackageVersions =
    qQueryPackageVersions.hasNextPage && packageVersions.length < versionCount;

  useEffect(
    () =>
      permalinkEffect({
        packages: packageVersions,
      }),
    [packageVersions, permalinkEffect]
  );

  return (
    <AccordionCard
      onToggle={setIsExpanded}
      id={packageResource.meta.name}
      summaryContent={
        <PackagesIndexDetailSummary
          latestPackageVersionRef={latestPackageVersionRef}
          packageNamespace={packageNamespace}
          packageVersionCount={versionCount}
          repositoryUrl={latestPackageVersionRepositoryUrl}
          projectInfo={
            projectUuid ? projectCache.cache.get(projectUuid) : undefined
          }
        />
      }
      title={<PackageNameDisplay name={packageResource.meta.name} />}
      subheader={packageResource.meta.description}
    >
      <PackagesIndexDetailTable
        packageContexture={packageContexture}
        data={packageVersions}
        isLoading={qQueryPackageVersions.isLoading}
        enablePagination={false}
        onClickDetail={(row) =>
          row &&
          DetailDrawer.activate(
            {
              packageNamespace: row.namespace,
              packageUuid: row.uuid,
            },
            {
              namespace: row.namespace,
              uuid: row.uuid,
            }
          )
        }
        enableColumnSort
        emptyStateProps={{
          title: (
            <FM defaultMessage="This package has not been scanned by Endor Labs" />
          ),
          description: (
            <FM defaultMessage="As projects or packages with this dependency are scanned, package information will appear here" />
          ),
        }}
      />

      {hasMorePackageVersions && (
        <Box display="flex" justifyContent="left" sx={{ paddingTop: 3 }}>
          <LoadingButton
            loading={qQueryPackageVersions.isFetchingNextPage}
            onClick={() => qQueryPackageVersions.fetchNextPage()}
            disabled={qQueryPackageVersions.isLoading}
          >
            <FM defaultMessage="View More Package Versions" />
          </LoadingButton>
        </Box>
      )}
    </AccordionCard>
  );
};
