import { Slide, Theme } from '@mui/material';

export const getSnackbarOverrides = (theme: Theme) => {
  return {
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        // autoHideDuration: 3000,
        TransitionComponent: Slide,
      },

      styleOverrides: {
        root: {
          [theme.breakpoints.up('md')]: {
            maxWidth: '40%',
          },
        },
      },
    },
  };
};
