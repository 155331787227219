import { FindingSpecPathNode } from '@endorlabs/endor-core/Finding';

/**
 * Utility to parse fastenuri encoded strings back to Function Ref parts
 */
export const _parseFastenUri = (
  fastenUri: string
): FindingSpecPathNode | undefined => {
  const [language, path] = fastenUri.split('://');

  let start = 0;
  let end = path.indexOf('/');

  if (end === -1) return;

  // handle optional registry
  const registryIndex = path.indexOf('!');
  if (registryIndex !== -1 && registryIndex < end) {
    start = registryIndex + 1;
  }

  const packageVersionName = path.slice(start, end).replace('$', '@');

  // increment index and check
  start = end + 1;
  end = path.indexOf('/', start);

  if (end === -1) return;

  const namespace = path.slice(start, end);

  // increment index and check
  end++;
  if (end >= path.length) return;

  const method = path.slice(end);

  start = method.indexOf('.');

  const classname = method.slice(0, start);
  const signature = method.slice(start + 1);

  const [functionName, functionArgs, returnType] = signature.split(/[()]/g) as (
    | string
    | undefined
  )[];
  const args = functionArgs?.length ? functionArgs.split(',') : [];

  return {
    package_version: packageVersionName,
    function_ref: {
      language,
      namespace,
      classname,
      signature,
      function_or_attribute_name: functionName,
      args,
      return_type: returnType,
    },
  };
};

/**
 * Maps list of fasten uri to parsed function refs
 */
export const buildFunctionPathFromReachablePath = (path: string[]) => {
  const functionPath: FindingSpecPathNode[] = [];

  for (const fastenUri of path) {
    const functionPathNode = _parseFastenUri(fastenUri);
    if (!functionPathNode) {
      return;
    }

    functionPath.push(functionPathNode);
  }

  return functionPath;
};
