import { PickDeep } from 'type-fest';

import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { DependencyMetadataResource } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

const ENDOR_PATCH_VERSION_REGEX = /-endor-(latest|\d{4}-\d{2}-\d{2})$/;

export const isPatchedDependencyMetadata = (
  resource?: PickDeep<
    DependencyMetadataResource,
    'spec.dependency_data.patched'
  >
): boolean => {
  return resource?.spec.dependency_data?.patched === true;
};

export const isPatchedPackageVerson = (
  resource?: PickDeep<PackageVersionResource, 'meta.name'>
): boolean => {
  const { version } = UIPackageVersionUtils.parsePackageName(
    resource?.meta.name
  );

  return ENDOR_PATCH_VERSION_REGEX.test(version);
};

export const stripEndorPatchVersionSuffix = (version: string): string => {
  return version.replace(ENDOR_PATCH_VERSION_REGEX, '');
};
