import { Grid, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { ButtonLinkPrimary, ButtonPrimary } from '@endorlabs/ui-common';

import { OnboardingCTACard } from '../../domains/Onboarding/components/OnboardingCTACard';
import { useProductTour } from '../../domains/Tour';
import { getProjectPath } from '../../routes';

export const OnboardingCTAs = () => {
  const { space } = useTheme();
  const { productTour } = useProductTour();

  const productTourClick = useCallback(() => {
    productTour.start();
  }, [productTour]);

  return (
    <Grid container item spacing={space.lg}>
      <Grid item lg={6} sm={12}>
        <OnboardingCTACard
          action={
            <ButtonPrimary onClick={productTourClick}>
              <FM defaultMessage="Start Tour" />
            </ButtonPrimary>
          }
          description={
            <FM defaultMessage="Learn how Endor Labs helps you secure open source dependencies, protect repos and pipelines, and comply with regulations" />
          }
          title={<FM defaultMessage="Take a Tour" />}
        />
      </Grid>

      <Grid item lg={6} sm={12}>
        <OnboardingCTACard
          action={
            <ButtonLinkPrimary
              linkProps={{
                to: getProjectPath({ tenantName: NAMESPACES.DEMO_TRIAL }),
              }}
            >
              <FM defaultMessage="Explore Demo Sandbox" />
            </ButtonLinkPrimary>
          }
          description={
            <FM
              defaultMessage="Switch to the <code>{url}</code> namespace to explore our capabilities."
              values={{
                code: (v) => <code>{v}</code>,
                url: NAMESPACES.DEMO_TRIAL,
              }}
            />
          }
          title={<FM defaultMessage="Try Out a Demo Project" />}
        />
      </Grid>
    </Grid>
  );
};
