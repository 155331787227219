import {
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { Control } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PackageManagerResource } from '@endorlabs/queries';
import { ControlledTextField } from '@endorlabs/ui-common';

import { PackageManagerAuthMode, PackageManagerBaseAuthFields } from './types';

interface PackageManagerMainFormFieldsType {
  selectedAuthMode: PackageManagerAuthMode;
  setSelectedAuthMode: (
    value: React.SetStateAction<PackageManagerAuthMode>
  ) => void;
  packageManager?: PackageManagerResource;
  control: Control<PackageManagerBaseAuthFields>;
  hasAuthProvider: boolean;
  awsCodeArtifactEnabled: boolean;
  hasBasicAuthField: boolean;
}

export const PackageManagerMainFormFields = ({
  selectedAuthMode,
  setSelectedAuthMode,
  packageManager,
  control,
  hasAuthProvider,
  awsCodeArtifactEnabled,
  hasBasicAuthField,
}: PackageManagerMainFormFieldsType) => {
  const { formatMessage: fm } = useIntl();

  const toggleAuthMode = useCallback(
    (evt: React.MouseEvent<HTMLElement>, value: PackageManagerAuthMode) => {
      setSelectedAuthMode(value);
    },
    [setSelectedAuthMode]
  );

  return (
    <>
      <Grid item marginBottom={4}>
        <ControlledTextField
          autoComplete="off"
          control={control}
          defaultValue={packageManager?.meta?.name ?? ''}
          fullWidth
          label={fm({
            defaultMessage: 'Name *',
          })}
          name="name"
          rules={{
            required: fm({
              defaultMessage: 'Package Manager name is required',
            }),
          }}
          variant="standard"
          data-testid="package-manager-name"
        />
      </Grid>
      <Grid item>
        <Stack gap={2}>
          <Typography variant="h4">Authentication type</Typography>
          <ToggleButtonGroup
            title="Auth method"
            value={selectedAuthMode}
            onChange={toggleAuthMode}
            exclusive
          >
            <ToggleButton
              value={PackageManagerAuthMode.Basic}
              disabled={hasAuthProvider}
            >
              <FM defaultMessage="Basic" />
            </ToggleButton>
            <ToggleButton
              value={PackageManagerAuthMode.AWS}
              disabled={!awsCodeArtifactEnabled || hasBasicAuthField}
            >
              <FM defaultMessage="AWS Code Artifactory" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
    </>
  );
};
