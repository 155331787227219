// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-codeblock pre[class*="language-"].line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
}

.highlight-codeblock pre[class*="language-"].line-numbers > .highlight-codeblock code {
    position: relative;
    white-space: inherit;
}

.highlight-codeblock .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em; /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;
    border-right: 1px solid rgb(192, 190, 190);

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

    .highlight-codeblock .line-numbers-rows > .highlight-codeblock span {
        display: block;
        counter-increment: linenumber;
    }

        .highlight-codeblock .line-numbers-rows > .highlight-codeblock span:before {
            content: counter(linenumber);
            color: #999;
            display: block;
            padding-right: 0.8em;
            text-align: right;
        }`, "",{"version":3,"sources":["webpack://./src/typescript/packages/ui-common/src/components/CodeBlock/prism-line-numbers.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,MAAM;IACN,eAAe;IACf,YAAY;IACZ,UAAU,EAAE,4CAA4C;IACxD,oBAAoB;IACpB,0CAA0C;;IAE1C,yBAAyB;IACzB,sBAAsB;IACtB,qBAAqB;IACrB,iBAAiB;;AAErB;;IAEI;QACI,cAAc;QACd,6BAA6B;IACjC;;QAEI;YACI,4BAA4B;YAC5B,WAAW;YACX,cAAc;YACd,oBAAoB;YACpB,iBAAiB;QACrB","sourcesContent":[".highlight-codeblock pre[class*=\"language-\"].line-numbers {\n    position: relative;\n    padding-left: 3.8em;\n    counter-reset: linenumber;\n}\n\n.highlight-codeblock pre[class*=\"language-\"].line-numbers > .highlight-codeblock code {\n    position: relative;\n    white-space: inherit;\n}\n\n.highlight-codeblock .line-numbers .line-numbers-rows {\n    position: absolute;\n    pointer-events: none;\n    top: 0;\n    font-size: 100%;\n    left: -3.8em;\n    width: 3em; /* works for line-numbers below 1000 lines */\n    letter-spacing: -1px;\n    border-right: 1px solid rgb(192, 190, 190);\n\n    -webkit-user-select: none;\n    -moz-user-select: none;\n    -ms-user-select: none;\n    user-select: none;\n\n}\n\n    .highlight-codeblock .line-numbers-rows > .highlight-codeblock span {\n        display: block;\n        counter-increment: linenumber;\n    }\n\n        .highlight-codeblock .line-numbers-rows > .highlight-codeblock span:before {\n            content: counter(linenumber);\n            color: #999;\n            display: block;\n            padding-right: 0.8em;\n            text-align: right;\n        }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
