/**
 * Cloned from https://github.com/feathericons/react-feather/blob/3a85e8a2b67ba2142a7576928799944aa910dde2/src/icons/bar-chart.js
 *
 * The `<line>` elements in the SVG icon has been rotated 90 degrees.
 */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type BarChartHorizontalProps = {
  color?: string;
  size?: string | number;
};

const BarChartHorizontal = forwardRef<SVGSVGElement, BarChartHorizontalProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <line x1="4" y1="6" x2="20" y2="6" />
        <line x1="10" y1="12" x2="20" y2="12" />
        <line x1="16" y1="18" x2="20" y2="18" />
      </svg>
    );
  }
);

BarChartHorizontal.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

BarChartHorizontal.displayName = 'BarChartHorizontal';

export default BarChartHorizontal;
