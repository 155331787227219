import { Box } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ToolPatternResource } from '@endorlabs/queries';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  ToolCategoryListDisplay,
  ToolNameDisplay,
} from '@endorlabs/ui-common';

export type CiCdToolsRulesTableRow = {
  categories?: string[];
  isSystem?: boolean;
  namespace: string;
  tool: ToolPatternResource;
};

export interface CiCdToolsRulesTableProps
  extends Omit<DataTableProps<CiCdToolsRulesTableRow>, 'columns'> {
  onView?: (row: CiCdToolsRulesTableRow) => void;
}

const buildCiCdRulesTableColumns = ({
  onView,
}: Pick<
  CiCdToolsRulesTableProps,
  'onView'
>): DataTableColumnDef<CiCdToolsRulesTableRow>[] => [
  {
    accessorKey: 'tool',
    cell: (t) => <ToolNameDisplay toolName={t.getValue().meta.name} />,
    colType: ColTypes.TEXT_LONG,
    header: () => <FM defaultMessage="Tool Rule" />,
  },
  {
    accessorKey: 'isSystem',
    cell: (t) =>
      t.getValue() === true ? (
        <FM defaultMessage="Endor Labs" />
      ) : (
        <FM defaultMessage="Custom" />
      ),
    header: () => <FM defaultMessage="Defined By" />,
  },
  {
    accessorKey: 'categories',
    cell: (t) => <ToolCategoryListDisplay categories={t.getValue() ?? []} />,
    header: () => <FM defaultMessage="Categories" />,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const items = [
        {
          label: <FM defaultMessage="View Rule" />,
          onClick: () => {
            if (onView) {
              onView(row.original as CiCdToolsRulesTableRow);
            }
          },
        },
      ];

      return row.original ? (
        <Box display="flex" justifyContent="end">
          <DataTableActionDropdown items={items} />
        </Box>
      ) : null;
    },
    colType: ColTypes.ACTIONS,
  },
];

export const CiCdToolsRulesTable = ({
  onView,
  ...props
}: CiCdToolsRulesTableProps) => {
  const columns = useMemo(
    () =>
      buildCiCdRulesTableColumns({
        onView,
      }),
    [onView]
  );
  return <DataTable {...props} columns={columns} />;
};
