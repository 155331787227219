import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { isMatch as _isMatch } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { SupportedToolChainProfileResource } from '@endorlabs/endor-core/SupportedToolChainProfile';
import { RowStack } from '@endorlabs/ui-common';

import { ToolchainToolInfo } from '../../types';
import {
  fromToolchainToolInfo,
  getToolchainToolInfoMatch,
  toToolchainToolInfo,
} from '../../utils';
import { ToolchainToolInfoSection } from './ToolchainToolInfoSection';

export type FormUpsertScanProfileToolchainsProps = {
  isLoading?: boolean;
  namespace: string;
  onSubmit: (data: ScanProfileResource) => void;
  scanProfile?: ScanProfileResource;
  supportedToolChainProfile?: SupportedToolChainProfileResource;
};

export const FormUpsertScanProfileToolchains = ({
  isLoading,
  supportedToolChainProfile,
  onSubmit,
  scanProfile,
}: FormUpsertScanProfileToolchainsProps) => {
  const [tools, setTools] = useState<ToolchainToolInfo[]>([]);

  // initialize
  useEffect(() => {
    const tools = toToolchainToolInfo(scanProfile?.spec.toolchain_profile);
    setTools(tools);
  }, [scanProfile]);

  const handleAddTool = (tool: ToolchainToolInfo) => {
    const next = [...tools];

    const current = getToolchainToolInfoMatch(tools, tool);
    if (current) {
      const ix = tools.indexOf(current);
      next[ix] = tool;
    } else {
      next.push(tool);
    }

    setTools(next);
  };

  const handleRemoveTool = (tool: ToolchainToolInfo) => {
    setTools((value) => value.filter((v) => !_isMatch(v, tool)));
  };

  const handleSubmit = () => {
    const result = fromToolchainToolInfo(tools);

    const updated = {
      ...scanProfile,
      spec: {
        ...scanProfile?.spec,
        toolchain_profile: result.toolchain,
      },
    } as ScanProfileResource;

    onSubmit(updated);
  };

  const isEdit = !!scanProfile;

  return (
    <Stack gap={4}>
      <RowStack justifyContent="flex-end">
        <LoadingButton
          loading={isLoading}
          onClick={handleSubmit}
          variant="contained"
        >
          {isEdit ? (
            <FM defaultMessage="Save Scan Profile" />
          ) : (
            <FM defaultMessage="Create Scan Profile" />
          )}
        </LoadingButton>
      </RowStack>

      <ToolchainToolInfoSection
        isLoading={isLoading}
        supportedToolChainProfile={supportedToolChainProfile}
        onAddTool={handleAddTool}
        onRemoveTool={handleRemoveTool}
        tools={tools}
      />
    </Stack>
  );
};
