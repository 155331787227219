import {
  IdentityProviderFieldValues,
  IdentityProviderTypes,
  SamlMetadataMethods,
} from '../types';

export const DEFAULT_IDENTITY_PROVIDERS = {
  [IdentityProviderTypes.OIDC]: {
    meta: { name: '' },
    saml_metadata_method: SamlMetadataMethods.URL,
    spec: {
      oidc_provider: {
        discover_url: '',
        key: '',
        secret: '',
        scopes: [],
        claim_names: [],
      },
    },
  } as IdentityProviderFieldValues,

  [IdentityProviderTypes.SAML]: {
    meta: { name: '' },
    saml_metadata_method: SamlMetadataMethods.URL,
    spec: {
      saml_provider: {
        attribute_names: [],
        certificates: [],
        issuer: '',
        provider_metadata_url: '',
        sso_url: '',
      },
    },
  } as IdentityProviderFieldValues,
};
