import {
  MakeGenerics,
  Navigate,
  useMatch,
  useSearch,
} from '@tanstack/react-location';
import { useEffect } from 'react';

import { useAuthentication } from '@endorlabs/queries';

import { LoginPage } from '../../domains/Auth';
import { useInvitation } from '../../domains/Invitation';
import { NamedRoutes } from '../../routes';

type InvitationAcceptPageGenerics = MakeGenerics<{
  Params: {
    namespace?: string;
  };
  Search: {
    invitation_id?: string;
  };
}>;

export const InvitationAcceptPage = () => {
  const {
    params: { namespace: invitation_namespace },
  } = useMatch<InvitationAcceptPageGenerics>();
  const { invitation_id } = useSearch<InvitationAcceptPageGenerics>();

  const { invitationUuid, invitationNamespace, storeInvitation } =
    useInvitation();
  const auth = useAuthentication();

  useEffect(() => {
    if (
      invitation_namespace &&
      invitation_id &&
      (invitation_namespace !== invitationNamespace ||
        invitation_id !== invitationUuid)
    ) {
      // NOTE: always updates invitation store with the current invitation
      storeInvitation({ namespace: invitation_namespace, uuid: invitation_id });
    }
  }, [
    invitation_id,
    invitationUuid,
    invitation_namespace,
    storeInvitation,
    invitationNamespace,
  ]);

  // if user is already authenticated, redirect to the login success page to
  // onboard to the new tenant
  if (auth.isAuthenticated) {
    return <Navigate to={NamedRoutes.LOGIN_SUCCESS} replace />;
  }

  // else send user through the login flow
  return <LoginPage invitation={true} />;
};
