import { V1Ecosystem, V1ScoreCategory } from '@endorlabs/api_client';
import {
  QueryPackagesIndexPackageVersionsObject,
  selectFindingCountsFromGroupResponse,
  selectMetricScores,
} from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import {
  getHuggingFaceModelRedirectPath,
  getPackageVersionPath,
} from '../../../routes';
import { PackagesIndexDetailTableRow } from './PackagesIndexDetailTable';

export const mapToPackagesIndexDetailTableRow = (
  object: QueryPackagesIndexPackageVersionsObject
) => {
  const metricScores = selectMetricScores(
    object.meta.references.Metric?.list?.objects[0]
  );

  const findingCounts = selectFindingCountsFromGroupResponse(
    object?.meta.references.FindingsGroupByLevel?.group_response
  );

  // get the package version from the package version name
  const { version: versionRef } = UIPackageVersionUtils.parsePackageName(
    object.meta.name
  );

  const ecosystem = object.spec.ecosystem;
  const projectUuid = object.spec.project_uuid;

  // Use different links for oss-explorer & regular package versions
  const packageVersionPath = getPackageVersionPath({
    tenantName: object.tenant_meta.namespace,
    uuid: object.uuid,
  });

  const huggingFacePath = getHuggingFaceModelRedirectPath({ projectUuid });

  // Package versions with HuggingFace ecosystem should redirect to HuggingFace model details page
  const packageLink =
    ecosystem === V1Ecosystem.HuggingFace
      ? huggingFacePath
      : packageVersionPath;

  // NOTE: dependency and dependent counts are taken from DependencyMetadata
  // these counts are eventually consistent, and may not be accurate
  const dependenciesCount =
    object?.meta.references.DependencyPackagesCount?.count_response.count ?? 0;
  const dependentsCount =
    object.meta.references.DependentPackagesCount?.count_response.count ?? 0;

  const row: PackagesIndexDetailTableRow = {
    context: object.context,
    uuid: object.uuid,
    name: object.meta.name,
    namespace: object.tenant_meta.namespace,
    link: packageLink,
    version: versionRef,
    findingCounts,
    dependenciesCount,
    dependentsCount,
    [V1ScoreCategory.Security]: metricScores.scoreSecurity?.score,
    [V1ScoreCategory.Activity]: metricScores.scoreActivity?.score,
    [V1ScoreCategory.Popularity]: metricScores.scorePopularity?.score,
    [V1ScoreCategory.CodeQuality]: metricScores.scoreCodeQuality?.score,
  };

  return row;
};
