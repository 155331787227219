import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  QueryServiceApi,
  ScanResultSpecType,
  V1GitSpec,
  V1Installation,
  V1ListParameters,
  V1Meta,
  V1Project,
  V1ProjectSpec,
  V1Query,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import { getClientConfiguration, sortParamBuilders } from './utils';

export const QueryInstallationsQueryKeys = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey => [
    'v1/queries',
    namespace,
    'installations',
    listParams,
  ],
};

export type QueryInstallationsResponse =
  TResourceList<QueryInstallationsResponseObject>;

export type QueryInstallationsResponseObject = SelectFrom<
  V1Installation,
  'processing_status' | 'spec' | 'tenant_meta' | 'uuid',
  {
    meta: SelectFrom<
      V1Meta,
      'name',
      {
        references: {
          Project?: QueryInstallationsProjectResponse;
        };
      }
    >;
  }
>;

type QueryInstallationsProjectResponse = TResourceList<
  SelectFrom<
    V1Project,
    'uuid' | 'processing_status' | 'tenant_meta',
    {
      spec: SelectFrom<
        V1ProjectSpec,
        'platform_source',
        {
          git?: SelectFrom<V1GitSpec, 'web_url'>;
        }
      >;
      meta: SelectFrom<
        V1Meta,
        'name' | 'tags',
        {
          references: {
            LatestScanResult?: TResourceList<ScanResultResource>;
          };
        }
      >;
    }
  >
>;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuery = (
  namespace: string,
  rootListParams: V1ListParameters
): V1Query => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  return {
    meta: {
      name: `QueryInstallations(namespace: ${namespace})`,
    },
    spec: {
      query_spec: {
        kind: 'Installation',
        list_parameters: { ...rootListParams },
        references: [
          {
            connect_from: 'spec.project_uuids',
            connect_to: 'uuid',
            query_spec: {
              kind: 'Project',
              list_parameters: {
                ...commonListParameters,
                mask: [
                  'uuid',
                  'meta.name',
                  'meta.tags',
                  'processing_status',
                  'spec.platform_source',
                  'spec.git.web_url',
                  'tenant_meta',
                ].join(),
              },
              references: [
                {
                  connect_from: 'uuid',
                  connect_to: 'meta.parent_uuid',
                  query_spec: {
                    kind: 'ScanResult',
                    return_as: 'LatestScanResult',
                    list_parameters: {
                      ...commonListParameters,
                      filter: filterExpressionBuilders.and([
                        filterExpressionBuilders.mainResourceContext(),
                        `spec.type==${ScanResultSpecType.AllScans}`,
                      ]),
                      sort: sortParamBuilders.descendingBy('meta.create_time'),
                      page_size: 1,
                      mask: [
                        'context',
                        'uuid',
                        'spec.exit_code',
                        'spec.status',
                        'spec.type',
                        'spec.environment.config',
                      ].join(','),
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    },
    tenant_meta: { namespace },
  };
};

const queryInstallations = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryInstallationsResponse;
};

/**
 * Custom query for the Installations page
 */
export const useQueryInstallations = (
  namespace: string,
  listParams: Omit<ListRequestParameters, 'mask'> = {},
  options: ResourceQueryOptions<QueryInstallationsResponse> = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Installation',
    'LIST',
    listParams,
    options
  );

  return useQuery(
    QueryInstallationsQueryKeys.query(namespace, requestParameters),
    ({ signal }) => queryInstallations(namespace, requestParameters, signal),
    { staleTime: Infinity, ...options }
  );
};
