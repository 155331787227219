import { RowSelectionState, TableOptions } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { DataTableRowSelectionRow } from '../types';

interface UseDataTableRowSelectionProps {
  enableRowSelection: boolean;
  onRowSelectionChange?: (rowSelection: RowSelectionState) => void;
  isSingleRowSelect?: boolean;
}

export const useDataTableRowSelection = <T extends DataTableRowSelectionRow>({
  enableRowSelection,
  onRowSelectionChange,
  isSingleRowSelect,
}: UseDataTableRowSelectionProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const rowSelectionProps: Partial<TableOptions<T>> = useMemo(() => {
    if (enableRowSelection) {
      // Remove old row selection ids for isSingleRowSelect is enabled.
      const oldRowSelectionState = isSingleRowSelect ? {} : rowSelection;

      return {
        // Use the required `optionValue` as row ID
        getRowId: (row) => row.optionValue,
        onRowSelectionChange: (updaterFn) => {
          const updated =
            typeof updaterFn === 'object'
              ? updaterFn
              : updaterFn(oldRowSelectionState);
          setRowSelection(updated);

          if (onRowSelectionChange) {
            onRowSelectionChange(updated);
          }
        },
        state: {
          rowSelection,
        },
      };
    }

    return {};
  }, [
    enableRowSelection,
    isSingleRowSelect,
    onRowSelectionChange,
    rowSelection,
  ]);

  return rowSelectionProps;
};
