import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1IdentityProvider } from '@endorlabs/api_client';
import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import {
  useCreateIdentityProvider,
  useDeleteIdentityProvider,
  useListIdentityProviders,
  useUpdateIdentityProvider,
} from '@endorlabs/queries';
import {
  ConfirmationDialog,
  FormUpsertIdentityProvider,
  IdentityProviderType,
  IdentityProviderTypes,
  useAppNotify,
} from '@endorlabs/ui-common';
import { determineIdentityProviderType } from '@endorlabs/ui-common/domains/IdentityProvider/utils';

import { FIVE_MINUTES_IN_MILLISECONDS } from '../../constants';

export interface IdentityProvidersViewProps {
  namespace: string;
}

export const IdentityProvidersView = ({
  namespace,
}: IdentityProvidersViewProps) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const addNotification = useAppNotify();

  const tenantNamespace = getRootNamespace(namespace);
  const qListIdentityProviders = useListIdentityProviders(tenantNamespace, {
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
  });

  const identityProvider = qListIdentityProviders.data?.list?.objects[0];

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [selectedIdentityProviderType, setSelectedIdentityProviderType] =
    useState<IdentityProviderType>(
      determineIdentityProviderType(identityProvider)
    );

  const identityProviderType = useMemo(() => {
    return identityProvider
      ? determineIdentityProviderType(identityProvider)
      : selectedIdentityProviderType;
  }, [identityProvider, selectedIdentityProviderType]);

  const qCreateIdentityProvider = useCreateIdentityProvider({
    // NOTE: explicitly handling succes/error notifications
    onError: (err) =>
      addNotification({
        details: err.response.data?.message,
        message: fm({
          defaultMessage: 'Unable to create this identity provider',
        }),
        severity: 'error',
      }),
    onSuccess: (data) =>
      addNotification({
        message: fm(
          {
            defaultMessage:
              'Successfully created the "{name}" identity provider',
          },
          { name: data.meta.name }
        ),
        severity: 'success',
      }),
  });
  const qDeleteIdentityProvider = useDeleteIdentityProvider({
    // NOTE: explicitly handling succes/error notifications
    onError: (err) => {
      addNotification({
        details: err.response.data?.message,
        message: fm({
          defaultMessage: 'Could not delete this identity provider',
        }),
        severity: 'error',
      });
    },
    onSettled: () => setShowDeleteConfirmation(false),
    onSuccess: () => {
      addNotification({
        message: fm({
          defaultMessage: 'Successfully deleted this identity provider',
        }),
        severity: 'success',
      });
    },
  });
  const qUpdateIdentityProvider = useUpdateIdentityProvider({
    // NOTE: explicitly handling succes/error notifications
    onError: (err) =>
      addNotification({
        details: err.response.data?.message,
        message: fm({
          defaultMessage: 'Unable to update this identity provider',
        }),
        severity: 'error',
      }),
    onSuccess: (data) =>
      addNotification({
        message: fm(
          {
            defaultMessage:
              'Successfully updated the "{name}" identity provider',
          },
          { name: data.meta.name }
        ),
        severity: 'success',
      }),
  });

  const handleSubmit = useCallback(
    (model: V1IdentityProvider) => {
      // field mask for update
      const updateMask = ['meta.name', 'spec'].join();

      // if there is an existing auth policy to be updated
      if (identityProvider) {
        qUpdateIdentityProvider.mutate({
          namespace: identityProvider.tenant_meta.namespace,
          resource: model,
          mask: updateMask,
        });
      } else {
        qCreateIdentityProvider.mutate({
          namespace: tenantNamespace,
          resource: model,
        });
      }
    },
    [
      identityProvider,
      tenantNamespace,
      qCreateIdentityProvider,
      qUpdateIdentityProvider,
    ]
  );

  const handleDelete = useCallback(() => {
    if (!identityProvider) return;

    qDeleteIdentityProvider.mutate({
      namespace: identityProvider.tenant_meta.namespace,
      uuid: identityProvider.uuid,
    });
  }, [identityProvider, qDeleteIdentityProvider]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <Card>
          <CardHeader
            title={<FM defaultMessage="Configure Identity Provider" />}
            subheader="Manage and configure an Enterprise Identity Provider for your Tenant"
          />

          <CardContent>
            <Stack spacing={space.sm}>
              <TextField
                disabled={Boolean(identityProvider)}
                label="Type of Identity Provider"
                fullWidth={false}
                onChange={(evt) =>
                  setSelectedIdentityProviderType(
                    evt.target.value as IdentityProviderType
                  )
                }
                select
                sx={{ width: 240 }}
                value={identityProviderType}
              >
                {Object.values(IdentityProviderTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>

              <FormUpsertIdentityProvider
                handleDelete={() => setShowDeleteConfirmation(true)}
                identityProvider={identityProvider}
                identityProviderType={identityProviderType}
                isDeleting={qDeleteIdentityProvider.isLoading}
                isLoading={qListIdentityProviders.isLoading}
                isSubmitting={
                  qCreateIdentityProvider.isLoading ||
                  qUpdateIdentityProvider.isLoading
                }
                namespace={tenantNamespace}
                onSubmit={handleSubmit}
              />
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <ConfirmationDialog
        isDestructive
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={handleDelete}
        open={showDeleteConfirmation}
        titleText={
          <FM defaultMessage="Delete this identity provider configuration?" />
        }
      />
    </Grid>
  );
};
