export const ICON_SIZES = {
  xsmall: 12,
  small: 16,
  medium: 20,
  large: 24,
  xlarge: 32,
};

export const getSvgIconOverrides = () => {
  return {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },

      styleOverrides: {
        root: {
          alignItems: 'center',
          display: 'inline-flex',
          justifyContent: 'center',
        },

        fontSizeSmall: {
          height: ICON_SIZES.small,
          width: ICON_SIZES.small,
        },
        fontSizeMedium: {
          height: ICON_SIZES.medium,
          width: ICON_SIZES.medium,
        },
        fontSizeLarge: {
          height: ICON_SIZES.large,
          width: ICON_SIZES.large,
        },
      },

      variants: [
        {
          props: { fontSize: 'xsmall' },
          style: {
            height: ICON_SIZES.xsmall,
            width: ICON_SIZES.xsmall,
          },
        },
        {
          props: { fontSize: 'xlarge' },
          style: {
            height: ICON_SIZES.xlarge,
            width: ICON_SIZES.xlarge,
          },
        },
      ],
    },
  };
};
