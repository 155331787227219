import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Stack,
} from '@mui/material';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { TitleActionHeader } from '../../domains/layout';
import { IconChevronDown } from '../../themes';

export interface AccordionCardProps
  extends Pick<AccordionProps, 'elevation' | 'expanded' | 'children' | 'sx'> {
  action?: ReactNode;
  disabled?: boolean;
  disableHeaderTextSelection?: boolean;
  id: string;
  headerDirection?: 'row' | 'row-reverse';
  onToggle?: (isExpanded: boolean) => void;
  subheader?: ReactNode | string;
  summaryContent?: ReactNode;
  title: ReactNode | string;
}

/**
 * An Accordion that functions as a standalone Card and enforces standard Card styles.
 *
 * Title and arbitrary summary content are placed in the `AccordionSummary` (collapsed state). Can be expanded to display arbitrary content in `AccordionDetail`.
 */
export const AccordionCard = ({
  action,
  children,
  disabled = false,
  disableHeaderTextSelection,
  elevation,
  expanded = false,
  headerDirection = 'row',
  id,
  onToggle,
  subheader,
  summaryContent,
  sx,
  title,
}: AccordionCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const handleToggleExpanded = useCallback(
    (event: SyntheticEvent, isExpanded: boolean) => {
      if (!disableHeaderTextSelection) {
        // if the user has selected text, prevent the accordion from toggling
        const selection = window.getSelection()?.toString().trim();
        if (selection) {
          return;
        }
      }

      setIsExpanded(isExpanded);

      if (onToggle) {
        onToggle(isExpanded);
      }
    },
    [disableHeaderTextSelection, onToggle]
  );

  useEffect(() => {
    if (expanded !== undefined) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  return (
    <Accordion
      id={id}
      disabled={disabled}
      disableGutters
      elevation={elevation}
      expanded={isExpanded}
      onChange={handleToggleExpanded}
      sx={{
        padding: 4,
        paddingTop: 3,

        '&.Mui-disabled': {
          backgroundColor: 'transparent',
        },
        ...sx,
      }}
    >
      <Stack spacing={2} width="100%">
        <AccordionSummary
          aria-controls={`${id}-content`}
          id={`${id}-header`}
          sx={{
            padding: 0,
            minHeight: 'auto',
            userSelect: disableHeaderTextSelection ? 'none' : 'text',
          }}
        >
          <Stack
            alignItems="center"
            direction={headerDirection}
            spacing={2}
            width="100%"
          >
            <TitleActionHeader
              action={action}
              subheader={subheader}
              title={title}
              titleTypographyProps={{ variant: 'h3' }}
            />

            <IconChevronDown
              sx={[
                {
                  minHeight: 36,
                  transition: 'transform 300ms',
                  transform: 'rotate(0)',
                },
                isExpanded && { transform: 'rotate(-180deg)' },
              ]}
            />
          </Stack>
        </AccordionSummary>

        {/*
          NOTE: for the AccordionCard, allow for interactive summary content
          below the accordion header to show in the collapsed state.
        */}
        {summaryContent && <Box>{summaryContent}</Box>}
      </Stack>

      <AccordionDetails sx={{ paddingTop: 4, paddingX: 0 }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
