import { Box } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  BooleanIconDisplay,
  DataTable,
  DataTableActionDropdown,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
} from '@endorlabs/ui-common';

export type SecretRulesTableRow = {
  isSystem: boolean;
  description?: string;
  name: string;
  namespace: string;
  uuid: string;
  validator: boolean;
};

export interface SecretRulesTableProps
  extends Omit<DataTableProps<SecretRulesTableRow>, 'columns'> {
  identityProviderName?: string;
  onEdit: (event: MouseEvent, row: SecretRulesTableRow) => void;
  onDelete: (event: MouseEvent, row: SecretRulesTableRow) => void;
}

const buildSecretRulesTableColumns = ({
  onEdit,
  onDelete,
}: Pick<
  SecretRulesTableProps,
  'onEdit' | 'onDelete'
>): DataTableColumnDef<SecretRulesTableRow>[] => [
  {
    accessorKey: 'name',
    header: () => <FM defaultMessage="Secrets Rule" />,
  },
  {
    accessorKey: 'description',
    colType: ColTypes.TEXT_LONG,
    header: () => <FM defaultMessage="Description" />,
  },
  {
    accessorKey: 'validator',
    cell: ({ row }) => (
      <BooleanIconDisplay colorize value={Boolean(row.original?.validator)} />
    ),
    colType: ColTypes.BOOLEAN,
    header: () => <FM defaultMessage="Validator" />,
  },
  {
    accessorKey: 'isSystem',
    cell: (t) =>
      t.getValue() === true ? (
        <FM defaultMessage="Endor Labs" />
      ) : (
        <FM defaultMessage="Custom" />
      ),
    header: () => <FM defaultMessage="Defined By" />,
  },
  {
    accessorKey: 'namespace',
    colType: ColTypes.NAMESPACE,
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      const systemItems = [
        {
          label: <FM defaultMessage="View Rule" />,
          onClick: (evt: MouseEvent) =>
            onEdit(evt, row.original as SecretRulesTableRow),
        },
      ];

      const nonSystemItems = [
        {
          label: <FM defaultMessage="Edit Rule" />,
          onClick: (evt: MouseEvent) =>
            onEdit(evt, row.original as SecretRulesTableRow),
        },
        {
          isDestructive: true,
          label: <FM defaultMessage="Delete Rule" />,
          onClick: (evt: MouseEvent) =>
            onDelete(evt, row.original as SecretRulesTableRow),
        },
      ];

      const items =
        row.original && row.original.isSystem ? systemItems : nonSystemItems;

      return row.original ? (
        <Box display="flex" justifyContent="end">
          <DataTableActionDropdown items={items} />
        </Box>
      ) : null;
    },
    colType: ColTypes.ACTIONS,
  },
];

export const SecretRulesTable = ({
  onEdit,
  onDelete,

  ...props
}: SecretRulesTableProps) => {
  const columns = useMemo(
    () =>
      buildSecretRulesTableColumns({
        onEdit,
        onDelete,
      }),
    [onDelete, onEdit]
  );
  return <DataTable {...props} columns={columns} />;
};
