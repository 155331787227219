import { Chip, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { V1RequestStatus } from '@endorlabs/api_client';

import { ServiceRequestStatusColorMap } from '../../constants';
import { ServiceRequestStatusTypeMessages } from '../../locales';

export const ServiceRequestStatusDisplay = ({
  size = 'small',
  status,
}: {
  size?: 'small' | 'medium';
  status: V1RequestStatus;
}) => {
  return (
    <Chip
      size={size}
      label={
        <Typography variant={size === 'medium' ? 'h2' : 'body1'}>
          <FM {...ServiceRequestStatusTypeMessages[status]} />
        </Typography>
      }
      color={ServiceRequestStatusColorMap[status]}
    />
  );
};
