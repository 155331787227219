import _isMatch from 'lodash-es/isMatch';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';

import { useInfoDrawer } from '../../../components';
import {
  ScanProfileDetailDrawer,
  ScanProfileDetailDrawerProps,
} from '../components/ScanProfileDetailDrawer';

interface PermalinkEffectProps {
  scanProfiles: ScanProfileResource[];
}

export const useScanProfileDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    { scanProfileNamespace: string; scanProfileUuid: string },
    ScanProfileDetailDrawerProps
  >({
    Component: ScanProfileDetailDrawer,
    drawerParams: ['scanProfileNamespace', 'scanProfileUuid'],
  });

  const permalinkEffect = ({ scanProfiles }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    const activeScanProfile = activeDrawerParams
      ? scanProfiles.find((n) =>
          _isMatch(n, {
            uuid: activeDrawerParams.scanProfileUuid,
          })
        )
      : undefined;

    if (
      activeScanProfile &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      const params = {
        scanProfileNamespace: activeScanProfile.tenant_meta.namespace,
        scanProfileUuid: activeScanProfile.uuid,
      };
      DetailDrawer.activate(params, params);
    }
  };

  return { DetailDrawer, permalinkEffect };
};
