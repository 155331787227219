import hoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import { useMemo } from 'react';
import { QueryOptions, useQuery } from 'react-query';

import { UserInfoServiceApi } from '@endorlabs/api_client';

import { AuthenticationQueryKeys } from './auth';
import { UserSession, useSession } from './useSession';
import { getClientConfiguration } from './utils';

/* Unless a new query is triggered by a related action (e.g. tenant creation),
 * Auth will rely on session in memory for this long before refreshing.
 */
const AUTH_QUERY_STALE_TIME_IN_HOURS = 1;

export interface IUseAuthenticationResult {
  isAuthenticated: boolean;
  isLoading: boolean;
}

const getApiService = () => new UserInfoServiceApi(getClientConfiguration());

const getAuthVerification = async (signal?: AbortSignal) => {
  const api = getApiService();
  const resp = await api.userInfoServiceVerify({ signal });
  return resp.data as UserSession;
};

export const getAuthVerificationQueryOptions =
  (): QueryOptions<UserSession> => ({
    queryKey: AuthenticationQueryKeys.record(),
    queryFn: (ctx) => getAuthVerification(ctx.signal),
  });

/**
 * Performs an authentication check with the backend as needed,
 * sets or clears session object in memory on success/error,
 */
export const useAuthentication = (): IUseAuthenticationResult => {
  const { clearSession, getUser, updateSession } = useSession();

  const authQuery = useQuery({
    ...getAuthVerificationQueryOptions(),
    onError: () => clearSession(),
    onSuccess: (data) => updateSession(data),
    retry: false,
    staleTime: hoursToMilliseconds(AUTH_QUERY_STALE_TIME_IN_HOURS),
  });

  const returnObj = useMemo(() => {
    const DEFAULT_RESULT = {
      isAuthenticated: false,
      isLoading: false,
    };

    const user = getUser();
    if (user) {
      return { ...DEFAULT_RESULT, isAuthenticated: true };
    }

    if (authQuery.isLoading) {
      return { ...DEFAULT_RESULT, isLoading: true };
    }

    if (authQuery.isError) {
      return DEFAULT_RESULT;
    }

    if (authQuery.isSuccess) {
      return {
        ...DEFAULT_RESULT,
        isAuthenticated: true,
      };
    }

    return DEFAULT_RESULT;
  }, [authQuery, getUser]);

  return returnObj;
};
