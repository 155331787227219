import { FormattedMessage as FM } from 'react-intl';

import { FindingDetailDrawerTabRecord } from './types';

export const FINDINGS_DETAIL_DRAWER_TABS: FindingDetailDrawerTabRecord[] = [
  { label: <FM defaultMessage="Info" />, value: 'info' },
  { label: <FM defaultMessage="Fix Info" />, value: 'fix' },
  { label: <FM defaultMessage="Dependency Path" />, value: 'depPath' },
  { label: <FM defaultMessage="Call Paths" />, value: 'callPath' },
  { label: <FM defaultMessage="Info" />, value: 'sastInfo' },
  { label: <FM defaultMessage="Rule" />, value: 'sastRule' },
];
