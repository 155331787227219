import { FormattedDate } from 'react-intl';

import { DateTimeComponentProps } from './types';

/**
 * TimeDisplay presents just the time portion of a timestamp in a consistent, internationalized format.
 */
export const TimeDisplay = ({ value }: DateTimeComponentProps) => {
  return <FormattedDate value={value} timeStyle="short" />;
};
