import { Box, Stack, Typography } from '@mui/material';
import { isNil as _isNil, orderBy as _orderBy } from 'lodash-es';
import { useMemo, useReducer } from 'react';
import { FormattedList, FormattedMessage as FM } from 'react-intl';

import { FindingResource } from '@endorlabs/endor-core/Finding';
import {
  FindingCount,
  useGetPackageVersion,
  useListFindings,
} from '@endorlabs/queries';
import {
  CodeBlock,
  DrawerAccordion,
  FindingCategoryLabel,
  FindingCountArrayDisplay,
  FindingLevelChip,
  getPaginatorPageSlice,
  NilDisplay,
  PackageVersionNameDisplay,
  RowStack,
  SimplePagination,
  UIFindingUtils,
  useDataTablePaginator,
  useStyles,
} from '@endorlabs/ui-common';

import {
  DetailDrawerDivider,
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { ContainerDependencyData, useContainerLayerData } from '../../hooks';
import { ContainerDetailDrawerProps } from './types';

export const ContainerDetailDrawerFindingsSection = ({
  containerNamespace: namespace,
  containerUuid: uuid,
  layerIndex,
  imageName,
}: ContainerDetailDrawerProps) => {
  // Load container data
  const qGetPackageVersion = useGetPackageVersion({ namespace, uuid });
  const containerPackageVersion = qGetPackageVersion.data;

  const { data } = useContainerLayerData({
    packageVersion: containerPackageVersion,
  });

  // Select the dependencies and findings from the target image or layer
  const { dependencies, findingCounts, layerInfo, totalFindingCount } =
    useMemo(() => {
      let dependencies: ContainerDependencyData[] = [];
      let findingCounts: FindingCount[] | undefined;
      let layerInfo: { command?: string } | undefined;
      let totalFindingCount: number | undefined;

      if (!_isNil(imageName)) {
        const containerImage = data.images.find((i) => i.name === imageName);
        dependencies = containerImage?.dependencies ?? [];
        findingCounts = containerImage?.findingCounts;
        totalFindingCount = containerImage?.findingCounts.reduce(
          (s, v) => s + v.value,
          0
        );
      }

      if (!_isNil(layerIndex)) {
        const containerLayer = data.layers.find(
          (l) => l.layerIndex === layerIndex
        );

        dependencies = containerLayer?.dependencies ?? [];
        findingCounts = containerLayer?.findingCounts;
        layerInfo = { command: containerLayer?.command };
        totalFindingCount = containerLayer?.findingCounts.reduce(
          (s, v) => s + v.value,
          0
        );
      }

      dependencies = dependencies.filter((d) => d.findings.length);
      dependencies = _orderBy(
        dependencies,
        [
          (v) => v.findingCounts.at(0)?.value ?? 0,
          (v) => v.findingCounts.at(1)?.value ?? 0,
          // use total count as the tie breaker after Critical and High
          (v) => v.findings.length,
        ],
        ['desc', 'desc', 'desc']
      );

      return { dependencies, findingCounts, layerInfo, totalFindingCount };
    }, [data.images, data.layers, imageName, layerIndex]);

  const findingsContentStyles = useStyles(({ palette, spacing }) => {
    return {
      '.DetailDrawerSection-root#layer_command pre': {
        backgroundColor: palette.background.paper,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        padding: spacing(4, 4, 0, 0),
        overflowX: 'auto',
      },
    };
  });

  return (
    <Box sx={findingsContentStyles}>
      <DetailDrawerSectionStack divider={<DetailDrawerDivider />}>
        {
          // Hide the command section when showing image info
          !!layerInfo && (
            <DetailDrawerSection id="layer_command">
              {layerInfo?.command ? (
                <CodeBlock value={layerInfo.command} />
              ) : (
                <NilDisplay />
              )}
            </DetailDrawerSection>
          )
        }

        <DetailDrawerSection id="layer_findings">
          <RowStack marginBottom={4}>
            <Typography color="text.secondary" component="span" variant="body2">
              <FM
                defaultMessage="{totalFindingCount, number, ::compact-short} {totalFindingCount, plural, one {finding} other {findings}}"
                values={{
                  totalFindingCount,
                }}
              />
            </Typography>
            <FindingCountArrayDisplay hideEmptyValues value={findingCounts} />
          </RowStack>

          <Stack divider={<DetailDrawerDivider fullWidth />}>
            {dependencies?.map((dep, index) => (
              <LayerDependencyFindingsAccordion
                key={index}
                id={`LayerDependency-${index}`}
                dependency={dep}
                namespace={namespace}
              />
            ))}
          </Stack>
        </DetailDrawerSection>
      </DetailDrawerSectionStack>
    </Box>
  );
};

const LayerDependencyFindingsAccordion = ({
  dependency,
  id,
  namespace,
}: {
  dependency: ContainerDependencyData;
  id: string;
  namespace: string;
}) => {
  const [expanded, triggerExpanded] = useReducer(() => true, false);

  const paginator = useDataTablePaginator({
    pageSize: 10,
    totalCount: dependency.findings.length,
  });

  const uuids = getPaginatorPageSlice(
    paginator.state,
    UIFindingUtils.sortByFindingSeverity(dependency.findings, 'level').map(
      (f) => f.uuid
    )
  );

  const qListFindings = useListFindings(
    namespace,
    { enabled: expanded && uuids.length > 0 },
    {
      filter: `uuid in [${uuids}]`,
      mask: [
        'uuid,meta.description,spec.finding_tags,spec.finding_categories',
        'spec.level',
      ].join(','),
    }
  );

  const sortedFindings = useMemo(() => {
    return uuids
      .map((uuid) =>
        qListFindings.data?.list?.objects.find((f) => f.uuid === uuid)
      )
      .filter((v) => v) as FindingResource[];
  }, [qListFindings.data, uuids]);

  return (
    <DrawerAccordion
      expanded={false}
      id={id}
      onChange={triggerExpanded}
      titleNode={
        <RowStack
          flexGrow={1}
          flexWrap="nowrap"
          justifyContent="space-between"
          key={dependency.name}
        >
          {/* Force Package Name to shrink */}
          <Box flexShrink={1}>
            <PackageVersionNameDisplay
              packageVersion={{
                meta: {
                  name: dependency.name,
                },
              }}
              showVersion
            />
          </Box>

          <FindingCountArrayDisplay
            hideEmptyValues
            value={dependency.findingCounts}
          />
        </RowStack>
      }
    >
      <Stack paddingLeft={2} gap={4} marginBottom={4}>
        {sortedFindings.map((f) => (
          <LayerDependencyFindingDetail key={f.uuid} finding={f} />
        ))}
      </Stack>

      <RowStack justifyContent="end">
        <SimplePagination paginator={paginator} />
      </RowStack>
    </DrawerAccordion>
  );
};

/**
 * TODO: normalize with {@see FindingNameDisplay}
 */
const LayerDependencyFindingDetail = ({
  finding,
}: {
  finding: FindingResource;
}) => {
  const { meta, spec, uuid } = finding;

  return (
    <RowStack key={uuid} flexWrap="nowrap">
      <FindingLevelChip level={spec.level} />

      <Stack>
        <Typography variant="body2" fontWeight={500}>
          {meta.description}
        </Typography>

        {spec.finding_categories && (
          <Typography color="text.secondary" variant="body2" component="div">
            <FormattedList
              type="unit"
              value={spec.finding_categories.map((c) => (
                <FindingCategoryLabel key={c} findingCategory={c} />
              ))}
            />
          </Typography>
        )}
      </Stack>
    </RowStack>
  );
};
