import { Card, CardContent, CardHeader, Grid, useTheme } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ApiKeyResource,
  useCountApiKeys,
  useCreateApiKey,
  useDeleteApiKey,
  useListApiKeys,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  EmptyState,
  SimpleDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { ApiKeysTable, ApiKeysTableRow, FormNewApiKey } from '../../components';
import { APIKeyPreview } from '../../domains/APIKey';
import { ApiKeyDeleteConfirmationDialog } from './ApiKeyDeleteConfirmation';

/**
 * Helper to shape the API Key objects for the API Keys Table
 */
const mapToApiKeysTableRow = (object: ApiKeyResource): ApiKeysTableRow => {
  return {
    uuid: object.uuid,
    name: object.meta.name,
    namespace: object.tenant_meta.namespace,
    keyId: object.spec.key,
    expires: object.spec.expiration_time,
    permissions: object.spec.permissions,
  };
};

export const ApiKeysView = ({ namespace }: { namespace: string }) => {
  const { space } = useTheme();
  const qCountApiKeys = useCountApiKeys(namespace);

  const paginator = useDataTablePaginator({
    totalCount: qCountApiKeys.data?.count,
  });

  const qListApiKeys = useListApiKeys(namespace, paginator.getListParameters());
  const qApiKeyCreate = useCreateApiKey({
    // NOTE: error for API key is handled in the dialog
    onError: () => undefined,
    onSuccess: () => {
      setOpenModal('Preview');
    },
  });
  const qApiKeyDelete = useDeleteApiKey();

  const [openModal, setOpenModal] = useState<
    'Form' | 'Preview' | 'Delete' | null
  >(null);
  const handleOpenFormModal = useCallback(() => setOpenModal('Form'), []);
  const handleCloseModal = useCallback(() => setOpenModal(null), []);

  const handleSubmitFormNewApiKey = useCallback(
    (model: ApiKeyResource) => {
      // TODO: modify the model before sending, if needed
      qApiKeyCreate.mutate({ namespace: namespace, resource: model });
    },
    [namespace, qApiKeyCreate]
  );

  const [apiKeyDelete, setApiKeyDelete] = useState<ApiKeysTableRow>();
  const handleDelete = useCallback(
    (row: ApiKeysTableRow) => {
      const currentDate = new Date();
      const expirationDate = new Date(row.expires ?? '');

      // do not ask for confirmation if api key is already expired
      if (expirationDate < currentDate) {
        qApiKeyDelete.mutate({ namespace: row.namespace, uuid: row.uuid });
        return;
      }

      setOpenModal('Delete');
      setApiKeyDelete(row);
    },
    [qApiKeyDelete]
  );

  const handleConfirmDelete = useCallback(() => {
    if (apiKeyDelete) {
      qApiKeyDelete.mutate({
        namespace: apiKeyDelete.namespace,
        uuid: apiKeyDelete.uuid,
      });
    }
    handleCloseModal();
  }, [qApiKeyDelete, apiKeyDelete, handleCloseModal]);

  // page data
  const apiKeys = useMemo(() => {
    return (qListApiKeys.data?.list?.objects ?? []).map((o) =>
      mapToApiKeysTableRow(o)
    );
  }, [qListApiKeys.data]);

  const isEmptyState = !qListApiKeys.isLoading && !apiKeys.length;

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        <Grid item>
          <Card>
            <CardHeader
              action={
                !isEmptyState && (
                  <ButtonPrimary
                    onClick={handleOpenFormModal}
                    disabled={qListApiKeys.isLoading}
                  >
                    <FM defaultMessage="Generate API Key" />
                  </ButtonPrimary>
                )
              }
              title={<FM defaultMessage="API Keys" />}
            ></CardHeader>

            <CardContent>
              <Grid container direction="column" spacing={space.md}>
                {isEmptyState && (
                  <Grid item>
                    <EmptyState
                      size="medium"
                      title={
                        <FM defaultMessage="You have not created any API keys" />
                      }
                      description={
                        <FM defaultMessage="Track and manage all points of programmatic access to your Endor data." />
                      }
                    >
                      <ButtonPrimary onClick={handleOpenFormModal}>
                        <FM defaultMessage="Generate API Key" />
                      </ButtonPrimary>
                    </EmptyState>
                  </Grid>
                )}
                {/* hide table and wrapper when empty state */}
                {!isEmptyState && (
                  <Grid item>
                    <ApiKeysTable
                      activeNamespace={namespace}
                      isLoading={qListApiKeys.isLoading}
                      data={apiKeys}
                      enablePagination
                      paginator={paginator}
                      onDelete={handleDelete}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <SimpleDialog
        maxWidth="sm"
        onClose={handleCloseModal}
        open={openModal === 'Form'}
        titleText={<FM defaultMessage="Generate an API Key" />}
      >
        <FormNewApiKey
          isLoading={qApiKeyCreate.isLoading}
          onSubmit={handleSubmitFormNewApiKey}
          serverErrorResponse={qApiKeyCreate.error?.response}
        />
      </SimpleDialog>

      <SimpleDialog
        maxWidth="sm"
        onClose={handleCloseModal}
        open={openModal === 'Preview'}
        titleText={<FM defaultMessage="Copy Your API Key" />}
      >
        <APIKeyPreview apiKey={qApiKeyCreate.data as ApiKeyResource} />
      </SimpleDialog>

      <ApiKeyDeleteConfirmationDialog
        open={openModal === 'Delete'}
        apiKeyName={apiKeyDelete?.name ?? ''}
        onConfirm={handleConfirmDelete}
        onCancel={handleCloseModal}
      />
    </>
  );
};
