import _head from 'lodash-es/head';
import { useMemo } from 'react';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  useListAllDependencyMetadata,
  useListDependencyMetadata,
  useListMetrics,
  useQueryPackageVersions,
} from '@endorlabs/queries';

export const usePackageVersionDetailData = ({
  namespace,
  uuid,
}: {
  namespace: string;
  uuid?: string;
}) => {
  const qQueryPackageVersions = useQueryPackageVersions(
    namespace,
    {
      enabled: !!uuid,
    },
    {
      filter: [
        `uuid==${uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      page_size: 1,
    }
  );

  const packageVersionQueryObject = _head(
    qQueryPackageVersions.data?.list?.objects ?? []
  );
  const packageVersionContext = packageVersionQueryObject?.context;
  const findingsByLevelAndReachability =
    packageVersionQueryObject?.meta.references.FindingsGroupByReachability
      ?.group_response?.groups ?? {};

  const metric = _head(
    packageVersionQueryObject?.meta.references.Metric?.list?.objects ?? []
  );

  const project = _head(
    packageVersionQueryObject?.meta.references.Project?.list?.objects ?? []
  );

  const scoreCard = metric?.spec.metric_values?.scorecard.score_card;
  const scoreFactors =
    metric?.spec.metric_values?.scorefactor.score_factor_list.score_factors;

  const qListLicenseMetrics = useListMetrics(
    namespace ?? '',
    { enabled: !!packageVersionContext },
    {
      filter: [
        'meta.name==pkg_version_info_for_license',
        `meta.parent_uuid == ${uuid}`,
        filterExpressionBuilders.relatedResourceContext({
          context: packageVersionContext,
        }),
      ].join(' and '),
      mask: 'spec.metric_values',
      page_size: 1,
    }
  );

  // return all licenses found for the package version
  const licenses =
    qListLicenseMetrics.data?.list?.objects[0]?.spec.metric_values
      .licenseInfoType.license_info?.all_licenses;

  const qDependencyMetadata = useListAllDependencyMetadata(
    namespace as string,
    {
      filter: [
        `meta.parent_uuid==${uuid}`,
        filterExpressionBuilders.relatedResourceContext({
          context: packageVersionContext,
        }),
      ].join(' and '),
      mask: [
        'uuid',
        'spec.dependency_data.direct',
        'spec.dependency_data.namespace',
        'spec.dependency_data.pinned',
        'spec.dependency_data.reachable',
        'spec.dependency_data.repo_name',
        'spec.dependency_data.scope',
        'spec.importer_data.callgraph_available',
      ].join(','),
    },
    {
      enabled: !!namespace && !!uuid && !!packageVersionContext,
    }
  );

  const dependencyMetadata = useMemo(() => {
    return qDependencyMetadata.data?.map((o) => o.spec) ?? [];
  }, [qDependencyMetadata.data]);

  const qDependentMetadata = useListDependencyMetadata(
    namespace,
    { enabled: !!uuid },
    {
      filter: [
        `spec.dependency_data.package_version_uuid==${uuid}`,
        filterExpressionBuilders.defaultResourceContexts(),
      ].join(' and '),
      group: { aggregation_paths: 'spec.dependency_data.direct' },
    }
  );

  const dependentGroups = qDependentMetadata.data?.group_response?.groups ?? {};
  const isLoading =
    qQueryPackageVersions.isLoading ||
    qListLicenseMetrics.isLoading ||
    qDependencyMetadata.isLoading ||
    qDependentMetadata.isLoading;

  return {
    findingsByLevelAndReachability,
    packageVersionQueryObject,
    scoreCard,
    scoreFactors,
    licenses,
    dependencyMetadata,
    dependentGroups,
    project,
    isLoading,
    isPackageVersionLoading: qQueryPackageVersions.isLoading,
  };
};
