import { ButtonProps } from '@mui/material';
import { useLocation } from '@tanstack/react-location';
import { FunctionComponent } from 'react';

import { Link, LinkProps } from '../Link';
import { ButtonPrimary } from './ButtonPrimary';
import { ButtonSecondary } from './ButtonSecondary';

export interface ButtonLinkProps extends Omit<ButtonProps, 'href'> {
  linkProps: Pick<LinkProps, 'target' | 'to' | 'search' | 'replace'>;
}

/**
 * HOC to wrap a styled button with Link Props
 *
 * Returns internal link styled as button
 */
const withLinkProps = (
  Button: FunctionComponent<ButtonProps>,
  displayName: string
) => {
  const Component = ({ linkProps, ...props }: ButtonLinkProps) => {
    const l = useLocation();
    const { href } = l.buildNext('/', linkProps);

    return (
      <Button
        // take Button props
        {...props}
        // take Link props
        {...linkProps}
        // use the link component
        LinkComponent={Link}
        // use a resolved href for the link
        href={href}
      />
    );
  };

  // ensure display name is set
  Component.displayName = displayName;
  return Component;
};

export const ButtonLinkPrimary = withLinkProps(
  ButtonPrimary,
  'ButtonLinkPrimary'
);

export const ButtonLinkSecondary = withLinkProps(
  ButtonSecondary,
  'ButtonLinkSeconButtonSecondary'
);
