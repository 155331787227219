import { useMemo } from 'react';

import { V1Ecosystem } from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';
import { useCountFindings, useFeatureFlags } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { buildFindingSourceToggleOptions } from '../../domains/Findings';

/**
 * Return the counts by Finding Source for Package Version Findings
 */
export const usePackageVersionFindingCountsByFindingSource = ({
  namespace,
  packageVersion,
}: {
  namespace: string;
  packageVersion?: PackageVersionResource;
}) => {
  const isContainerPackageVersion =
    packageVersion?.spec.ecosystem === V1Ecosystem.Container;
  const isGitHubActionsEnabled = useFeatureFlags(
    (s) => s.ENABLE_GITHUB_ACTIONS
  );

  const containerFilterExpression =
    UIPackageVersionUtils.getPackageVersionFindingFilterExpression(
      packageVersion,
      FindingSource.Container
    );
  const qCountContainerFindings = useCountFindings(
    namespace,
    {
      enabled: isContainerPackageVersion && !!containerFilterExpression,
    },
    {
      filter: containerFilterExpression,
    }
  );

  const dependencyFilterExpression =
    UIPackageVersionUtils.getPackageVersionFindingFilterExpression(
      packageVersion,
      FindingSource.Dependency
    );
  const qCountDependencyFindings = useCountFindings(
    namespace,
    {
      enabled: !!dependencyFilterExpression,
    },
    {
      filter: dependencyFilterExpression,
    }
  );

  const githubActionFilterExpression =
    UIPackageVersionUtils.getPackageVersionFindingFilterExpression(
      packageVersion,
      FindingSource.GithubAction
    );
  const qCountGithubActionFindings = useCountFindings(
    namespace,
    {
      enabled: isGitHubActionsEnabled && !!githubActionFilterExpression,
    },
    {
      filter: githubActionFilterExpression,
    }
  );

  const packageFilterExpression =
    UIPackageVersionUtils.getPackageVersionFindingFilterExpression(
      packageVersion,
      FindingSource.Package
    );
  const qCountPackageFindings = useCountFindings(
    namespace,
    {
      enabled: !!packageFilterExpression,
    },
    {
      filter: packageFilterExpression,
    }
  );

  const [totalFindingsCount, findingSourceToggleOptions] = useMemo(() => {
    const totalFindingsCount = [
      qCountDependencyFindings.data,
      qCountGithubActionFindings.data,
      qCountPackageFindings.data,
    ].reduce((sum, data) => sum + (data?.count ?? 0), 0);

    // Exclude the toggles for these sources
    const excludedFindingSources = [
      FindingSource.All,
      FindingSource.Repository,
      FindingSource.Secrets,
    ];

    // Exclude the GitHub Actions toggle if the feature is not enabled
    if (!isGitHubActionsEnabled) {
      excludedFindingSources.push(FindingSource.GithubAction);
    }

    // Exclude the container finding source for non-container packages
    if (!isContainerPackageVersion) {
      excludedFindingSources.push(FindingSource.Container);
    }

    const options = buildFindingSourceToggleOptions(
      {
        containerFindings: qCountContainerFindings.data?.count,
        dependencyFindings: qCountDependencyFindings.data?.count,
        githubActionFindings: qCountGithubActionFindings.data?.count,
        packageFindings: qCountPackageFindings.data?.count,
      },
      excludedFindingSources
    );

    return [totalFindingsCount, options];
  }, [
    isContainerPackageVersion,
    isGitHubActionsEnabled,
    qCountContainerFindings.data?.count,
    qCountDependencyFindings.data,
    qCountGithubActionFindings.data,
    qCountPackageFindings.data,
  ]);

  const isLoading = [
    qCountContainerFindings,
    qCountDependencyFindings,
    qCountGithubActionFindings,
    qCountPackageFindings,
  ].some((q) => q.isLoading);

  return { isLoading, findingSourceToggleOptions, totalFindingsCount };
};
