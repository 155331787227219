import { MenuItem, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  PolicyFindingAction,
  SpecFindingLevel,
  V1FindingCategory,
} from '@endorlabs/api_client';
import {
  ControlledLabelsField,
  ControlledTextField,
  FindingCategoryLabel,
  LabelsFieldValidationType,
  SeverityDisplay,
} from '@endorlabs/ui-common';

interface FindingPolicyFieldsProps {
  finding?: PolicyFindingAction;
}

/**
 * Form fields specific to Finding type policies
 */
export const FindingPolicyFields = ({ finding }: FindingPolicyFieldsProps) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control, setValue } = useFormContext();

  // If finding is updated, set form values to match
  useEffect(() => {
    const findingStringValues: (keyof PolicyFindingAction)[] = [
      'explanation',
      'external_name',
      'level',
      'remediation',
      'summary',
    ];

    const findingArrayValues: (keyof PolicyFindingAction)[] = [
      'categories',
      'meta_tags',
      'tags',
    ];

    findingStringValues.forEach((key) => {
      setValue(`spec.finding.${key}`, finding?.[key] ?? '');
    });

    findingArrayValues.forEach((key) => {
      setValue(`spec.finding.${key}`, finding?.[key] ?? []);
    });
  }, [finding, setValue]);

  return (
    <Stack alignItems="flex-start" spacing={space.md} width="100%">
      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="Severity" />}
        name="spec.finding.level"
        placeholder={fm({
          defaultMessage: 'Choose Severity',
        })}
        rules={{ required: true }}
        select
      >
        {Object.values(SpecFindingLevel)
          .filter((lvl) => lvl !== SpecFindingLevel.Unspecified)
          .map((level) => (
            <MenuItem key={level} value={level}>
              <SeverityDisplay value={level} />
            </MenuItem>
          ))}
      </ControlledTextField>

      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="Summary" />}
        // Set min/max rows the same to avoid this ResizeObserver bug when drawer toggles
        // https://github.com/mui/base-ui/issues/167
        maxRows={4}
        minRows={4}
        multiline
        name="spec.finding.summary"
        placeholder={fm({
          defaultMessage: 'Short summary of the finding',
        })}
        rules={{ required: true }}
        variant="outlined"
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="Explanation" />}
        maxRows={4}
        minRows={4}
        multiline
        name="spec.finding.explanation"
        placeholder={fm({
          defaultMessage: 'Provide a detailed description of the finding',
        })}
        rules={{ required: true }}
        variant="outlined"
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="Remediation" />}
        maxRows={4}
        minRows={4}
        multiline
        name="spec.finding.remediation"
        placeholder={fm({
          defaultMessage: 'Enter recommended steps to address the finding',
        })}
        rules={{ required: true }}
        variant="outlined"
      />

      <ControlledTextField
        control={control}
        defaultValue=""
        label={<FM defaultMessage="Finding Name" />}
        name="spec.finding.external_name"
        placeholder={fm({
          defaultMessage: 'Findings created by this policy will have this name',
        })}
        rules={{ required: true }}
      />

      <ControlledTextField
        control={control}
        defaultValue={[]}
        label={<FM defaultMessage="Finding Categories" />}
        name="spec.finding.categories"
        placeholder={fm({
          defaultMessage: 'The categories for the finding',
        })}
        rules={{ required: true }}
        select
        SelectProps={{ multiple: true }}
      >
        {Object.values(V1FindingCategory)
          .filter((value) => value !== V1FindingCategory.Unspecified)
          .map((value) => (
            <MenuItem key={value} value={value}>
              <FindingCategoryLabel findingCategory={value} />
            </MenuItem>
          ))}
      </ControlledTextField>

      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        fullWidth
        label={<FM defaultMessage="Finding Meta Tags" />}
        name="spec.finding.meta_tags"
        helperText={fm({
          defaultMessage:
            'Findings created by this policy will have these meta tags. Meta tags can have a maximum of 63 characters and can contain letters, numbers, and characters = @ _ -. Note that these are different and separate from the system defined finding tags.',
        })}
        validation={LabelsFieldValidationType.MetaTag}
      />

      {/* Pass some values through in hidden fields */}
      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        name="spec.finding.tags"
        type="hidden"
        validation={LabelsFieldValidationType.MetaTag}
      />
    </Stack>
  );
};
