import { defineMessage } from 'react-intl';

import { createFileRoute } from '../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Feature Flags' });

export const Route = createFileRoute('/admin/feature-flags')({
  beforeLoad: () => {
    return { title: ROUTE_TITLE };
  },
});
