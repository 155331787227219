import { Box } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { DataTable, DataTableProps } from '@endorlabs/ui-common';

import { ProjectCiCdToolsTableRow } from './types';
import { buildProjectCiCdToolsTableColumns } from './utils';

type ProjectCiCdToolsTableProps = Omit<
  DataTableProps<ProjectCiCdToolsTableRow>,
  'columns'
>;

export const ProjectCiCdToolsTable = (props: ProjectCiCdToolsTableProps) => {
  const sx = styles();
  const columns = useMemo(
    () =>
      buildProjectCiCdToolsTableColumns({
        maxToolCount: 3,
        excludeColumns: ['toolCount'],
      }),
    []
  );

  return (
    <Box sx={sx} textAlign="right">
      <DataTable
        columns={columns}
        emptyStateProps={{
          title: <FM defaultMessage="No tools found" />,
          imageWidth: 300,
        }}
        {...props}
      />
    </Box>
  );
};

const styles = () => ({
  ' .MuiTableContainer-root': {
    borderBottom: 0,
  },

  ' .MuiTableBody-root .MuiTableRow-root:last-of-type': {
    '.MuiTableCell-root': {
      borderBottom: 0,
    },
  },

  ' .MuiTableCell-root': {
    padding: '8px',
  },
});
