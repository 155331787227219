import { FormattedMessage as FM } from 'react-intl';

import { V1Language } from '@endorlabs/api_client';

import { LanguageMessages } from '../locales';

interface LanguageLabelProps {
  value?: V1Language;
}

export const LanguageLabel = ({
  value = V1Language.Unspecified,
}: LanguageLabelProps) => <FM {...LanguageMessages[value]} />;
