import { Chip, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  IconVerified,
  ImgIconLogoMarkDocker,
  RowStack,
  withIconFrame,
} from '@endorlabs/ui-common';

const IconVerifiedFramed = withIconFrame(IconVerified, {
  displayName: 'IconVerifiedFramed',
  variant: 'circular',
});

export interface ContainerNameDisplayProps {
  isDefaultVersion?: boolean;
  isSigned?: boolean;
  name: string;
  showIcon?: boolean;
  size?: 'inherit' | 'small' | 'medium' | 'large';
  version?: string;
}

/**
 * Display for a Container Name
 */
export const ContainerNameDisplay = ({
  isDefaultVersion,
  isSigned,
  name,
  version,
  showIcon,
  size,
}: ContainerNameDisplayProps) => {
  return (
    <RowStack gap={2} flexWrap="nowrap">
      {showIcon && <ImgIconLogoMarkDocker fontSize={size} />}

      <Typography component="span" fontSize={size} variant="inherit">
        {name}
        {!!version && (
          <>
            {/* hint for the browser to add line break before version identifier, when needed */}
            <wbr />
            <Typography component="span" fontSize={size} color="text.secondary">
              @{version}
            </Typography>
          </>
        )}
      </Typography>

      {isSigned && <IconVerifiedFramed fontSize={size} />}

      {isDefaultVersion && (
        <Chip label={<FM defaultMessage="Default" />} size="small" />
      )}
    </RowStack>
  );
};
