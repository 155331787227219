import { useCallback, useEffect, useRef, useState } from 'react';

export function useCopyToClipboard(
  str: string
): [boolean, (s?: string) => void, (value: boolean) => void] {
  const copyableString = useRef(str);
  const [isCopied, setIsCopied] = useState(false);

  const copyAction = useCallback(
    async (dynamicString?: string) => {
      await navigator.clipboard
        .writeText(dynamicString ?? copyableString.current)
        .then(() => {
          setIsCopied(true);
        });
    },
    [copyableString]
  );

  useEffect(() => {
    copyableString.current = str;
  }, [str]);

  return [isCopied, copyAction, setIsCopied];
}
