import { Outlet, Route as RouteType } from '@tanstack/react-location';
import { defineMessage } from 'react-intl';

import { usePageTitle } from './hooks';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Endor Labs' });

/**
 * The "root" route for the web app
 */
const RootRoute = () => {
  usePageTitle();

  return <Outlet />;
};

export const Route: RouteType = {
  element: <RootRoute />,
  meta: {
    context: { title: ROUTE_TITLE },
  },
};
