import { Box } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Row, Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { DependencyMetadataReachabilityType } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  Link,
  NilDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  ReachabilityTypeDisplay,
  SBOMNameDisplay,
} from '@endorlabs/ui-common';

import { DependencyDependentsType } from './types';

export interface DependencyDetailDependentsTableRow {
  namespace: string;
  uuid: string;
  id: string;
  packageName: string;
  packageVersionRef: string;
  packageLink?: string;
  projectName?: string;
  projectLink?: string;
  projectPlatformSource?: string;
  sbomLink?: string;
  sbomName?: string;
  hasDirectDependent?: boolean;
  reachability?: DependencyMetadataReachabilityType;
}

export interface DependencyDetailDependentsTableProps
  extends Omit<DataTableProps<DependencyDetailDependentsTableRow>, 'columns'> {
  onClickDetail?: (row?: DependencyDetailDependentsTableRow) => void;
  dependencyDependentsType: DependencyDependentsType;
}

const getDependencyDetailDependentsTableColumnDefs = ({
  onClickDetail,
  dependencyDependentsType,
}: DependencyDetailDependentsTableProps) => {
  const columns: DataTableColumnDef<DependencyDetailDependentsTableRow>[] = [
    {
      hidden: dependencyDependentsType === DependencyDependentsType.PROJECTS,
      accessorKey: 'packageName',
      cell: ({ getValue }) => (
        <PackageNameDisplay name={getValue()} showVersion />
      ),
      colType: ColTypes.PACKAGE,
      header: () => <FM defaultMessage="Dependent Package" />,
    },
    {
      // The "parent" of the dependent can be a Project in either the MAIN or
      // SBOM context. Handle displaying the correct link.
      id: 'parent',
      cell: ({ row }) =>
        row.original?.sbomLink ? (
          <Link
            to={row.original.sbomLink}
            color="text.primary"
            onClick={(event) => event.stopPropagation()}
          >
            <SBOMNameDisplay name={row.original.sbomName ?? ''} />
          </Link>
        ) : row.original?.projectLink ? (
          <Link
            to={row.original.projectLink}
            color="text.primary"
            onClick={(event) => event.stopPropagation()}
          >
            <ProjectNameDisplay
              name={row.original.projectName}
              platformSource={row.original?.projectPlatformSource as any} // FIXME: typing
              showIcon={true}
            />
          </Link>
        ) : (
          <NilDisplay variant="text" />
        ),
      colType: ColTypes.PROJECT,
      header: () => <FM defaultMessage="Project" />,
    },
    {
      accessorKey: 'hasDirectDependent',
      cell: ({ getValue }) =>
        getValue() ? (
          <FM defaultMessage="Direct" />
        ) : (
          <FM defaultMessage="Transitive" />
        ),
      header: () => <FM defaultMessage="Type" />,
    },
    {
      accessorKey: 'reachability',
      cell: ({ getValue }) => <ReachabilityTypeDisplay value={getValue()} />,
      colType: ColTypes.BOOLEAN,
      header: () => <FM defaultMessage="Reachability" />,
    },
  ];

  if (onClickDetail) {
    columns.push({
      id: 'actions',
      cell: ({ row }) =>
        row.original ? (
          <Box display="flex" justifyContent="end">
            <DataTableDrawerButton
              disabled={!row.original?.uuid}
              onClick={(event) => {
                event.stopPropagation();
                onClickDetail(row.original);
                if (!row.getIsSelected()) row.toggleSelected();
              }}
            />
          </Box>
        ) : null,
      colType: ColTypes.ACTIONS,
      enablePinning: true,
      header: '',
    });
  }

  return columns.filter((c) => !c.hidden);
};

export const DependencyDetailDependentsTable = forwardRef(
  function DependencyTableComponent(
    props: DependencyDetailDependentsTableProps,
    ref: ForwardedRef<Table<DependencyDetailDependentsTableRow>>
  ) {
    const navigate = useNavigate();
    const columns = getDependencyDetailDependentsTableColumnDefs(props);
    const { onClickDetail } = props;
    function handleRowClick(
      row: DependencyDetailDependentsTableRow,
      rowRef: Row<DependencyDetailDependentsTableRow>
    ) {
      if (onClickDetail) {
        if (!rowRef.getIsSelected()) rowRef.toggleSelected();
        return onClickDetail(row);
      }
      if (row.packageLink) {
        navigate({ to: row.packageLink });
      }
    }
    /* User should select only one row at a time when displaying row detail. */
    const isSingleRowSelect = onClickDetail ? true : false;
    return (
      <DataTable
        {...props}
        columns={columns}
        ref={ref}
        onRowClick={handleRowClick}
        isSingleRowSelect={isSingleRowSelect}
      />
    );
  }
);
