import create from 'zustand';
import { persist } from 'zustand/middleware';

const VERSION = 0;

export type DataTableColumnPreferences = {
  columnOrder?: string[];
  columnVisibility?: Record<string, boolean>;
};

export interface DataTablePreferencesStore {
  tableColumns: Record<string, DataTableColumnPreferences>;
  getTableColumnPreferences(
    tableId: string
  ): DataTableColumnPreferences | undefined;
  setTableColumnPreferences(
    tableId: string,
    state: DataTableColumnPreferences
  ): void;
}

export const useDataTablePreferences = create(
  persist<DataTablePreferencesStore>(
    (setState, getState): DataTablePreferencesStore => {
      return {
        tableColumns: {},
        getTableColumnPreferences: (tableId: string) => {
          const state = getState().tableColumns;
          return Reflect.get(state, tableId);
        },
        setTableColumnPreferences: (
          tableId: string,
          preferences: DataTableColumnPreferences
        ) => {
          setState((last) => ({
            tableColumns: { ...last.tableColumns, [tableId]: preferences },
          }));
        },
      };
    },
    {
      name: 'data-table-preferences-store',
      version: VERSION,
    }
  )
);
