import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';
import { PickDeep } from 'type-fest';

import { SpecEnabledFeatureType } from '@endorlabs/api_client';
import { InstallationResource } from '@endorlabs/queries';
import { useTabs } from '@endorlabs/ui-common';

import { AzureConfigFields } from './AzureConfigFields';
import {
  EnabledFeaturesFields,
  EnabledFeaturesFieldsProps,
} from './EnabledFeaturesFields';

export type UpsertInstallationAzureFields = PickDeep<
  InstallationResource,
  'spec.azure_config' | 'spec.enabled_features'
>;

type FormUpsertInstallationAzureProps = {
  featureRecords?: EnabledFeaturesFieldsProps['featureRecords'];
  installation?: InstallationResource;
  // secondaryButtons?: ReactNode;
};

export const FormUpsertInstallationAzure = ({
  featureRecords = [],
  installation,
}: FormUpsertInstallationAzureProps) => {
  const { space } = useTheme();

  const isEditing = Boolean(installation);

  const TabRecords = [
    {
      label: <FM defaultMessage="Azure Config" />,
      value: 'AZURE_CONFIG',
    },
    { label: <FM defaultMessage="Scanners" />, value: 'ENABLED_FEATURES' },
  ];

  const { activeTabValue, getTabsProps } = useTabs({
    activeTabKey: 'AZURE_CONFIG',
    id: 'FormUpsertInstallationAzure',
    tabRecords: TabRecords,
  });

  // Remove GitHub scan control
  const azureFeatureRecords = featureRecords.filter(
    (f) => f.key !== SpecEnabledFeatureType.GithubScan
  );

  const tabPanelMap = {
    //forms control is sent via context
    AZURE_CONFIG: <AzureConfigFields isEditing={isEditing} />,
    ENABLED_FEATURES: (
      <Stack rowGap={space.sm}>
        <Box>
          <Typography variant="h3">
            <FM defaultMessage="Enabled Scanners" />
          </Typography>
          <Typography variant="caption">
            <FM defaultMessage="Select the scan types to enable. Results for these scan types will be generated once your next scan is initiated." />
          </Typography>
        </Box>

        <EnabledFeaturesFields featureRecords={azureFeatureRecords} />
      </Stack>
    ),
  };

  return (
    <Stack rowGap={space.md}>
      <TabContext value={activeTabValue}>
        <TabList {...getTabsProps()} />

        {Object.entries(tabPanelMap).map(([tabValue, tabPanelNode]) => {
          // Ideally these TabPanels would have keepMounted={true} (MUI 6)
          return (
            <TabPanel key={tabValue} value={tabValue}>
              {tabPanelNode}
            </TabPanel>
          );
        })}
      </TabContext>
    </Stack>
  );
};
