import { Grid, MenuItem, Stack, useTheme } from '@mui/material';
import { without as _without } from 'lodash-es';
import { omit as _omit } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Method, V1ServiceRequest } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { buildQueryCall } from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  CodeBlock,
  ControlledLabelsField,
  ControlledTextField,
  LabelsFieldValidationType,
  ResourceKindDisplay,
} from '@endorlabs/ui-common';

import { TemporaryResourceKindList } from '../../constants';
import { useCreateTemplate } from '../../hooks';
import { ServiceRequestMethodTypeMessages } from '../../locales';
import { ServiceRequestDataField } from './ServiceRequestDataField';

export type ServiceRequestFormProps = {
  defaultValues?: V1ServiceRequest;
  readOnly?: boolean;
  onSubmit?: (values: V1ServiceRequest) => void;
  onSuccess?: (response: V1ServiceRequest) => void;
};

export const ServiceRequestForm = ({
  defaultValues,
  readOnly = false,
  onSubmit,
}: ServiceRequestFormProps) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();

  const formMethods = useForm<V1ServiceRequest>({
    mode: 'onTouched',
    defaultValues: defaultValues,
  });

  const {
    handleSubmit: hookFormSubmit,
    reset,
    formState,
    setValue,
    watch,
  } = formMethods;

  const resourceKindSelected = watch('spec.resource');
  const methodSelected = watch('spec.method');
  const namespaceSelected = watch('spec.target_namespace');
  const uuidSelected = watch('uuid');

  const data = buildQueryCall(resourceKindSelected as ResourceKind, {
    filter:
      resourceKindSelected === 'EndorLicense'
        ? `uuid==${uuidSelected} or spec.target_namespace matches ${uuidSelected}`
        : `uuid==${uuidSelected} `,
  }).useBuiltQuery(namespaceSelected, {
    enabled: !!resourceKindSelected && !!uuidSelected,
  });
  const qCreateTemplate = useCreateTemplate(
    resourceKindSelected as ResourceKind
  );

  useEffect(() => {
    const dataObject = data?.data?.spec?.query_response?.list.objects[0];
    if (methodSelected === V1Method.Update) {
      const updateType = `Update${resourceKindSelected}Request`;
      const updateObject = {
        '@type': `internal.endor.ai.endor.v1.${updateType}`,
        request: {
          update_mask: '',
        },
        object: dataObject,
      };
      setValue('spec.data', updateObject);
    }

    if (methodSelected === V1Method.Delete) {
      const deleteObject = {
        '@type': `internal.endor.ai.endor.v1.${resourceKindSelected}`,
        ...dataObject,
      };
      setValue('spec.data', deleteObject);
    }

    if (methodSelected === V1Method.Create && qCreateTemplate.data) {
      setValue('spec.data', {
        '@type': `internal.endor.ai.endor.v1.${resourceKindSelected}`,
        ...qCreateTemplate.data,
      });
    }
  }, [
    setValue,
    data?.data?.spec?.query_response?.list.objects,
    resourceKindSelected,
    methodSelected,
    qCreateTemplate.data,
  ]);

  useEffect(() => {
    setValue('uuid', undefined);
  }, [resourceKindSelected, setValue]);

  useEffect(() => {
    if (resourceKindSelected === 'EndorLicense') {
      setValue('spec.method', V1Method.Update);
      setValue('spec.target_namespace', 'system');
    }
    if (methodSelected === V1Method.Create) {
      setValue('uuid', undefined);
    }
  }, [methodSelected, resourceKindSelected, setValue]);

  const handleSubmit = (fieldValues: V1ServiceRequest) => {
    if (onSubmit) {
      onSubmit(_omit(fieldValues, ['uuid']));
    }
  };

  const handleClear = () => {
    reset(defaultValues);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...formMethods}>
      <form
        id="FormToolPattern"
        onSubmit={!readOnly ? hookFormSubmit(handleSubmit) : undefined}
      >
        <Grid container spacing={space.lg} width="50%">
          <Grid item xs={6}>
            <ControlledTextField
              control={formMethods.control}
              inputProps={{
                readOnly,
              }}
              label={<FM defaultMessage="Resource Kind" />}
              name="spec.resource"
              rules={{
                required: fm({
                  defaultMessage: 'Resource kind is required',
                }),
              }}
              select
            >
              {TemporaryResourceKindList.map((resourceKind) => (
                <MenuItem key={resourceKind} value={resourceKind}>
                  <ResourceKindDisplay value={resourceKind} />
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              control={formMethods.control}
              inputProps={{
                readOnly: readOnly || resourceKindSelected === 'EndorLicense',
              }}
              label={<FM defaultMessage="Operation" />}
              name="spec.method"
              rules={{
                required: fm({
                  defaultMessage: 'Operation is required',
                }),
              }}
              select
            >
              {_without(
                Object.values(V1Method),
                // omit unspecified as an option
                V1Method.Unspecified,
                V1Method.Read,
                V1Method.All
              )
                .sort((a, b) => a.localeCompare(b))
                .map((method) => (
                  <MenuItem key={method} value={method}>
                    <FM {...ServiceRequestMethodTypeMessages[method]} />
                  </MenuItem>
                ))}
            </ControlledTextField>
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={formMethods.control}
              inputProps={{
                readOnly: readOnly || resourceKindSelected === 'EndorLicense',
              }}
              label={<FM defaultMessage="Tenant" />}
              name="spec.target_namespace"
              rules={{
                required: fm({
                  defaultMessage: 'Tenant namespace is required',
                }),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledTextField
              control={formMethods.control}
              inputProps={{ readOnly }}
              label={<FM defaultMessage="Description" />}
              name="spec.description"
              rules={{
                required: fm({ defaultMessage: 'Description is required' }),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <ControlledLabelsField
              control={formMethods.control}
              chipProps={readOnly ? { onDelete: undefined } : undefined}
              inputProps={{
                readOnly,
              }}
              label={<FM defaultMessage="Approvers" />}
              name="spec.approvers"
              required
              validation={LabelsFieldValidationType.Email}
            />
          </Grid>

          {!readOnly && (
            <Grid item xs={12}>
              <ControlledTextField
                control={formMethods.control}
                inputProps={{
                  readOnly:
                    readOnly ||
                    !(
                      methodSelected === V1Method.Update ||
                      methodSelected === V1Method.Delete
                    ),
                }}
                label={
                  resourceKindSelected === 'EndorLicense' ? (
                    <FM defaultMessage="Customer-Namespace or Uuid" />
                  ) : (
                    <FM defaultMessage="Uuid" />
                  )
                }
                name="uuid"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {readOnly ? (
              <CodeBlock
                enableClipboard={false}
                filetype="json"
                header={
                  <FM
                    defaultMessage="{resource} Data"
                    values={{ resource: defaultValues?.spec.resource }}
                  />
                }
                value={JSON.stringify(defaultValues?.spec.data, null, 2)}
              />
            ) : (
              <ServiceRequestDataField />
            )}
          </Grid>
          <Grid item xs={12}>
            {!readOnly && (
              <Stack direction="row" spacing={space.sm}>
                <ButtonPrimary
                  type="submit"
                  disabled={!formState.isDirty || !formState.isValid}
                >
                  <FM defaultMessage="Submit" />
                </ButtonPrimary>
                <ButtonCancel type="button" onClick={handleClear}>
                  <FM defaultMessage="Clear" />
                </ButtonCancel>
              </Stack>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
