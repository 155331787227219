import { defineMessage } from 'react-intl';

import { DashboardPage } from '../../../../views/Dashboard';
import { AuthenticatedRouteGuard } from '../../../components/AuthenticatedRouteGuard';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Dashboard' });

const TenantDashboardRoute = () => {
  return (
    <AuthenticatedRouteGuard>
      <DashboardPage />
    </AuthenticatedRouteGuard>
  );
};

export const Route = createFileRoute('/t/:namespace/dashboard')({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  component: TenantDashboardRoute,
});
