import { DependencyMetadataReachabilityType } from '@endorlabs/api_client';

export enum DependencyDependentsType {
  PACKAGES = 'PACKAGES',
  PROJECTS = 'PROJECTS',
}

export interface DependencyDependentAggregate {
  uuid: string;
  relatedUuids: string[];
  count: number;
  hasDirect: boolean;
  hasTransitive: boolean;
  reachability?: DependencyMetadataReachabilityType;
}
