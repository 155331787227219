import { Box } from '@mui/material';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  useGetScanProfile,
  useListSupportedToolChainProfiles,
  useUpdateScanProfile,
} from '@endorlabs/queries';

import { FormUpsertScanProfileToolchains } from '../../../domains/ScanProfiles';

export const ScanProfileDetailToolchainsView = ({
  scanProfile: scanProfileSnapshot,
}: {
  scanProfile: ScanProfileResource;
}) => {
  const scanProfileNamespace = scanProfileSnapshot.tenant_meta.namespace;

  const qScanProfile = useGetScanProfile(
    scanProfileNamespace,
    { uuid: scanProfileSnapshot.uuid },
    { initialData: scanProfileSnapshot, keepPreviousData: true }
  );

  const qListSupportedToolChainProfiles = useListSupportedToolChainProfiles(
    scanProfileNamespace,
    {},
    { staleTime: Infinity }
  );

  const scanProfile = qScanProfile.data;
  const supportedToolChainProfile = qListSupportedToolChainProfiles.data?.objects[0];

  const qUpdateScanProfile = useUpdateScanProfile();

  const handleSubmit = (resource: ScanProfileResource) => {
    if (!scanProfile) return;

    const updated = {
      ...scanProfile,
      ...resource,
    };

    qUpdateScanProfile.mutate({
      namespace: scanProfileNamespace,
      resource: updated,
      mask: ['meta.name', 'spec.toolchain_profile'].join(','),
    });
  };

  return (
    <Box>
      <FormUpsertScanProfileToolchains
        isLoading={
          qScanProfile.isLoading ||
          qListSupportedToolChainProfiles.isLoading ||
          qUpdateScanProfile.isLoading
        }
        supportedToolChainProfile={supportedToolChainProfile}
        namespace={scanProfileNamespace}
        onSubmit={handleSubmit}
        scanProfile={scanProfile}
      />
    </Box>
  );
};
