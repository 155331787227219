import { CircularProgress } from '@mui/material';
import { MakeGenerics, Navigate, useMatch } from '@tanstack/react-location';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListPackageVersions } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  EmptyState,
  useResourceCRUDMessages,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../providers/AuthInfo';
import { NamedRoutes } from '../constants';
import { getPackageVersionPath } from '../utils';

type PackageVersionRedirectByNameLocationGenerics = MakeGenerics<{
  Params: {
    packageVersionName?: string;
  };
}>;

/**
 * Redirects to a PackageVersion uuid from the given package version name
 *
 * NOTE: limited to package version under the MAIN context.
 */
export const PackageVersionRedirectByName = () => {
  const { getErrorMessage } = useResourceCRUDMessages();
  const { activeNamespace } = useAuthInfo();
  const { params } = useMatch<PackageVersionRedirectByNameLocationGenerics>();

  // NOTE: dependency name in url is encoded to prevent issues
  const packageVersionName = params.packageVersionName
    ? decodeURIComponent(params.packageVersionName)
    : undefined;

  const qListPackageVersions = useListPackageVersions(
    activeNamespace,
    { enabled: !!activeNamespace && !!packageVersionName },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `meta.name=="${packageVersionName}"`,
      ]),
      mask: ['tenant_meta.namespace', 'uuid'].join(','),
      page_size: 1, // get the first,
    }
  );

  const redirectPath = useMemo(() => {
    const packageVersion = qListPackageVersions.data?.list?.objects[0];
    if (!packageVersion) return;

    return getPackageVersionPath({
      tenantName: packageVersion.tenant_meta.namespace,
      uuid: packageVersion.uuid,
    });
  }, [qListPackageVersions.data]);

  const errorMessage = useMemo(() => {
    return qListPackageVersions.isError
      ? getErrorMessage('GET', 'PackageVersion', qListPackageVersions.error)
      : undefined;
  }, [
    getErrorMessage,
    qListPackageVersions.error,
    qListPackageVersions.isError,
  ]);

  if (qListPackageVersions.isLoading) {
    return (
      <EmptyState title={<FM defaultMessage="Loading&hellip;" />} size="large">
        <CircularProgress />;
      </EmptyState>
    );
  }

  if (qListPackageVersions.isSuccess && redirectPath) {
    return <Navigate to={redirectPath} replace />;
  }

  if (errorMessage) {
    return (
      <EmptyState
        title={errorMessage?.message}
        description={errorMessage?.details}
      >
        <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
          <FM defaultMessage="Return Home" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  return (
    <EmptyState
      title={<FM defaultMessage="Unable to Find Package" />}
      description={
        <FM
          defaultMessage="We were unable to find the package `{packageVersionName}`. Please try again or contact Endor Labs for support."
          values={{ packageVersionName }}
        />
      }
    >
      <ButtonLinkPrimary linkProps={{ to: NamedRoutes.TENANTS_INDEX }}>
        <FM defaultMessage="Return Home" />
      </ButtonLinkPrimary>
    </EmptyState>
  );
};
