import { PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import { TenantType } from '@endorlabs/endor-core/auth';
import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import { useAuthentication, useSession } from '@endorlabs/queries';

import { EventTrackingContext } from './EventTrackingContext';
import {
  HubspotEvent,
  TrackHubspotEventFn,
  UIEventKey,
  UserEventKey,
} from './types';
import { useEventIdentifiers } from './useEventIdentifiers';
import { useHubspotEventQueue } from './useHubspotEventQueue';
import { useUITelemetryEventQueue } from './useUITelemetryEventQueue';
import { useUserTelemetryEventQueue } from './useUserTelemetryEventQueue';
import { parsePathname } from './utils';

const PAGE_VISIT_RATE_LIMIT = 30_000;

export type EventTrackingProviderProps = PropsWithChildren<{
  hubspotPortalId?: string;
}>;

export const EventTrackingProvider = ({
  children,
  hubspotPortalId,
}: EventTrackingProviderProps) => {
  const { isAuthenticated, isLoading } = useAuthentication();
  const { session } = useSession();
  const eventIdentifiers = useEventIdentifiers({ session });

  const hasPremiumTenant =
    session?.tenants &&
    session.tenants.some((t) => t.tenantType == TenantType.Premium);

  const { enqueue: enqueueHubspotEvents } = useHubspotEventQueue({
    // Disable HubSpot while loading, or if the user is authenticated with premium tenants
    enabled: !(isLoading || (isAuthenticated && hasPremiumTenant)),
    portalId: hubspotPortalId,
  });
  const { enqueue: enqueueUIEvents } = useUITelemetryEventQueue({
    eventIdentifiers,
  });
  const { enqueue: enqueueUserEvents } = useUserTelemetryEventQueue({
    eventIdentifiers,
  });

  // Store for page visit rate limiting
  const pageVisitLimiter = useRef(new Map<string, number>());

  const trackEvent = useCallback(
    (
      namespace: string,
      key: UIEventKey,
      value?: string,
      properties?: Record<string, string>
    ) => {
      const timestamp = new Date().toISOString();
      const events = [
        {
          timestamp,
          key,
          value,
          properties,
        },
      ];

      // Event Tracking is disabled in local enviroment
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.info('[EventTracking] Track Event', namespace, ...events);
        return;
      }

      enqueueUIEvents(namespace, events);
    },
    [enqueueUIEvents]
  );

  const trackHubspotEvent: TrackHubspotEventFn = useCallback(
    (event, value: unknown) => {
      enqueueHubspotEvents([{ event, value } as HubspotEvent]);
    },
    [enqueueHubspotEvents]
  );

  const trackPageVisit = useCallback(
    (
      namespace: string,
      pathname: string,
      properties?: Record<string, string>
    ) => {
      const now = Date.now();
      const { route } = parsePathname(pathname);

      // Rate limiting page visit events per route
      if (pageVisitLimiter.current.has(route)) {
        const lastVisit = pageVisitLimiter.current.get(route) as number;
        if (now - lastVisit < PAGE_VISIT_RATE_LIMIT) return;
      }

      pageVisitLimiter.current.set(route, now);

      trackEvent(namespace, 'PAGE_VISIT', route, properties);
    },
    [trackEvent]
  );

  const trackUserEvent = useCallback(
    (
      namespace: string,
      key: UserEventKey,
      value?: string,
      properties?: Record<string, string>
    ) => {
      // Force user events to be tracked at the root (tenant) namespace
      const rootNamespace = getRootNamespace(namespace);

      const timestamp = new Date().toISOString();
      const events = [
        {
          timestamp,
          key,
          value,
          properties,
        },
      ];

      // Event Tracking is disabled in local enviroment
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.info('[EventTracking] Track User Event', namespace, ...events);
        return;
      }

      enqueueUserEvents(rootNamespace, events);
    },
    [enqueueUserEvents]
  );

  const context = useMemo(() => {
    return {
      trackEvent,
      trackPageVisit,
      trackUserEvent,
      trackHubspotEvent,
    };
  }, [trackEvent, trackHubspotEvent, trackPageVisit, trackUserEvent]);

  return (
    <EventTrackingContext.Provider value={context}>
      {children}
    </EventTrackingContext.Provider>
  );
};
