import { Route } from '@tanstack/react-location';
import { merge as _merge } from 'lodash-es';

import { FileRouteShim } from './createFileRoute';
import { LazyFileRouteShim } from './createLazyFileRoute';

export const withLazyRoute = (
  route: FileRouteShim,
  loader: () => Promise<LazyFileRouteShim>
) => {
  const { import: baseImport, ...base } = route;

  const partial: FileRouteShim = {
    ...base,
    import: async (opts) => {
      const promises: Promise<Route>[] = [loader()];

      if (baseImport) {
        promises.push(baseImport(opts));
      }

      const results = await Promise.all(promises);
      return _merge(base, ...results);
    },
  };

  return partial;
};
