import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { getFindingTitle } from '@endorlabs/endor-core/Finding';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import {
  QueryFindingsResponseObject,
  useGetDependencyMetadata,
  useGetFinding,
  useGetPackageVersion,
  useGetProject,
  useListPolicies,
} from '@endorlabs/queries';
import { IconExternalLink, Link } from '@endorlabs/ui-common';

import { FindingDetail } from '../../../components/FindingDetail';
import { InfoDrawerContent } from '../../../components/InfoDrawer';
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../../constants';
import { getFindingsPath } from '../../../routes';

export interface FindingDetailDrawerProps {
  finding: QueryFindingsResponseObject;
  findingUuid?: string;
}

/**
 *
 * @deprecated
 * @see FindingDetailDrawerV2
 */
export const FindingDetailDrawer = ({ finding }: FindingDetailDrawerProps) => {
  /**
   * Loads the full finding object from the given query object
   */
  const qGetFinding = useGetFinding(
    { namespace: finding.tenant_meta.namespace, uuid: finding.uuid },
    {
      enabled: !!finding.uuid && !!finding.tenant_meta.namespace,
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    }
  );

  const qGetProject = useGetProject(
    {
      namespace: finding.tenant_meta.namespace,
      uuid: finding.spec.project_uuid,
    },
    {
      enabled: !!finding.spec.project_uuid,
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    }
  );

  const qGetPackageVersion = useGetPackageVersion(
    {
      namespace: finding.tenant_meta.namespace,
      uuid: finding.meta.parent_uuid,
    },
    {
      enabled:
        !!finding.meta.parent_uuid &&
        finding.meta.parent_kind === 'PackageVersion',
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
    }
  );

  const qTargetDependencyMetadata = useGetDependencyMetadata(
    {
      namespace: finding.tenant_meta.namespace,
      uuid: finding?.spec.target_uuid ?? '',
    },
    {
      enabled: !!(finding.tenant_meta.namespace && finding?.spec.target_uuid),
    }
  );

  const hasExceptions =
    finding.spec.exceptions?.policy_uuids &&
    (finding.spec.exceptions?.policy_uuids ?? []).length > 0;

  const qExceptionPolicies = useListPolicies(
    finding.tenant_meta.namespace,
    {
      enabled: hasExceptions,
    },
    {
      filter: `uuid in [${(finding.spec.exceptions?.policy_uuids ?? []).join(
        ','
      )}]`,
    }
  );

  const exceptionPolicies = useMemo(() => {
    return qExceptionPolicies.data?.list?.objects ?? [];
  }, [qExceptionPolicies.data?.list?.objects]);

  const sourcePackageVersion = qGetPackageVersion.data;
  const findingData = qGetFinding.data ?? finding;

  const isOssFinding = findingData.tenant_meta.namespace === NAMESPACES.OSS;
  const findingLink =
    !isOssFinding &&
    getFindingsPath({
      tenantName: findingData.tenant_meta.namespace,
      uuid: findingData.uuid,
    });

  return (
    <InfoDrawerContent title={getFindingTitle(finding)}>
      {findingLink && (
        <Link
          to={findingLink}
          sx={{
            display: 'block',
            marginBottom: 3,
          }}
        >
          <Stack
            alignItems="center"
            component={Typography}
            direction="row"
            flexWrap="wrap"
            gap={2}
            variant="body1"
          >
            <FM defaultMessage="Finding Detail" />
            <IconExternalLink fontSize="inherit" />
          </Stack>
        </Link>
      )}

      <FindingDetail
        exceptionPolicies={exceptionPolicies}
        finding={findingData}
        project={qGetProject.data}
        sourcePackageVersion={sourcePackageVersion}
        targetDependencyMetadata={qTargetDependencyMetadata.data}
      />
    </InfoDrawerContent>
  );
};
