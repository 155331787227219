import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useListAssuredPackageVersions } from '@endorlabs/queries';
import { useStyles } from '@endorlabs/ui-common';

import { Layout } from '../../../constants';
import { AssuredPackageVersionDetailDrawerOverview } from './AssuredPackageVersionDetailDrawer';
import { AssuredPackageVersionPatchesSection } from './AssuredPackageVersionPatchesSection';

export const AssuredPackageVersionDetailView = ({
  namespace,
  packageVersionName,
}: {
  namespace?: string;
  packageVersionName?: string;
}) => {
  const containerSx = useStyles(containerStyles);

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    namespace ?? '',
    {
      filter: `meta.name=="${packageVersionName}"`,
      mask: ['meta', 'spec'].join(','),
      page_size: 1,
    },
    { enabled: !!namespace && !!packageVersionName }
  );

  const assuredPackageVersion = qListAssuredPackageVersions.data?.objects.at(0);
  const isLoading = qListAssuredPackageVersions.isLoading;

  return (
    <Stack sx={containerSx}>
      <Card className="AssuredPackageVersionDetailView-overview" elevation={0}>
        <CardHeader
          className="AssuredPackageVersionDetailView-overviewHeader"
          title={<FM defaultMessage="Overview" />}
          titleTypographyProps={{ variant: 'h3' }}
        />

        <AssuredPackageVersionDetailDrawerOverview
          assuredPackageVersion={assuredPackageVersion}
          isLoading={isLoading}
        />
      </Card>

      <Card className="AssuredPackageVersionDetailView-details" elevation={0}>
        <CardHeader
          className="AssuredPackageVersionDetailView-patchesHeader"
          title={<FM defaultMessage="Patches" />}
          titleTypographyProps={{ variant: 'h3' }}
        />
        <CardContent>
          <AssuredPackageVersionPatchesSection
            assuredPackageVersion={assuredPackageVersion}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

function containerStyles({ breakpoints, space, spacing }: Theme) {
  return {
    alignItems: 'stretch',
    flexDirection: 'column',
    gap: space.md,

    '.MuiCardContent-root': {
      paddingX: 0,
    },

    '.MuiCardHeader-root': {
      marginBottom: 2,
      paddingTop: 8,
    },

    [breakpoints.up('lg')]: {
      alignItems: 'stretch',
      flexDirection: 'row',

      '.AssuredPackageVersionDetailView-details': {
        flexGrow: 1,
      },

      '.AssuredPackageVersionDetailView-overview': {
        flexShrink: 0,
        width: spacing(Layout.INFO_DRAWER_WIDTH),
      },
    },
  } satisfies SxProps;
}
