import { createSvgIcon } from '@mui/material';

import Artifacts from '../../../assets/artifacts/artifact-registry';
import ArtifactTypeJarIcon from '../../../assets/artifacts/artifact-type-jar.svg';
import createImgIcon from './createImgIcon';

export const IconArtifactRegistry = createSvgIcon(
  <Artifacts />,
  'ArtifactRegistryIcon'
);

export const IconArtifactTypeJar = createImgIcon(
  ArtifactTypeJarIcon,
  'ArtifactTypeJarIcon'
);
