import {
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import {
  ButtonLinkPrimary,
  IconExternalLink,
  Link,
} from '@endorlabs/ui-common';

import { ResourceLinks } from '../../domains/Onboarding';
import { getOnboardingLink } from './onboardingLinks';

export const OnboardingResourcesCard = () => {
  const { space } = useTheme();

  return (
    <Stack spacing={space.md}>
      <Card>
        <CardContent>
          <Stack spacing={space.sm}>
            <Typography variant="h3">
              <FM defaultMessage="Resources" />
            </Typography>

            <Stack spacing={space.xs} width="100%">
              {ResourceLinks.map((obj) => {
                return (
                  <Link key={obj.url} target="_blank" to={obj.url}>
                    <Stack alignItems="center" direction="row" spacing={2}>
                      <Typography>{obj.text}</Typography>
                      <IconExternalLink fontSize="inherit" />
                    </Stack>
                  </Link>
                );
              })}
            </Stack>

            <Divider variant="middle" />

            <Stack spacing={space.sm}>
              <Typography variant="h3">
                <FM defaultMessage="Need more help?" />
              </Typography>
              <Typography color="textSecondary" paragraph>
                <FM defaultMessage="Register for a live demo and Q&A with our experts." />
              </Typography>

              <ButtonLinkPrimary
                linkProps={{
                  target: '_blank',
                  to: `${getOnboardingLink('onboarding-talk-to-expert')}`,
                }}
                sx={{ width: 'fit-content' }}
              >
                <FM defaultMessage="Talk to an Expert" />
              </ButtonLinkPrimary>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
