import _delay from 'lodash-es/delay';
import _isArray from 'lodash-es/isArray';
import _kebabCase from 'lodash-es/kebabCase';
import Shepherd from 'shepherd.js';

import { BasicTourStepProps, TourStepDefinition } from '../types';

export const clickOn = async function (selector: string) {
  const clickTarget = (await waitForElementAvailable(selector)) as HTMLElement;

  const clickPromise = new Promise((resolve) => {
    clickTarget.addEventListener('click', function () {
      resolve(true);
    });
  });

  if (clickTarget) {
    clickTarget.dispatchEvent(new Event('click', { bubbles: true }));
  }

  return clickPromise;
};

export async function waitForElementAvailable(
  selector: string
): Promise<HTMLElement | null> {
  return new Promise((resolve) => {
    const element = document.querySelector(selector) as HTMLElement | null;

    if (element) {
      resolve(element);
    } else {
      const observer = new MutationObserver(() => {
        const updatedElement = document.querySelector(
          selector
        ) as HTMLElement | null;

        if (updatedElement) {
          observer.disconnect();
          resolve(updatedElement);
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    }
  });
}

export async function waitForElementsAvailable(
  selectors: string[]
): Promise<(HTMLElement | null)[]> {
  return new Promise((resolve) => {
    const elements = selectors.map((s) =>
      document.querySelector(s)
    ) as (HTMLElement | null)[];

    if (!elements.includes(null)) {
      resolve(elements);
    } else {
      const observer = new MutationObserver(() => {
        const updatedElements = selectors.map((s) =>
          document.querySelector(s)
        ) as (HTMLElement | null)[];

        if (!updatedElements.includes(null)) {
          observer.disconnect();
          resolve(updatedElements);
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    }
  });
}

interface SimplifiedDOMRect {
  bottom?: number;
  left?: number;
  right?: number;
  top?: number;
}

const MaskProperties = [
  'maskImage',
  'maskSize',
  'maskPosition',
  'maskRepeat',
  'maskComposite',
  'webkitMaskComposite',
];

const primaryOverlayMask = {
  maskImage: 'linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(0,255,0,1) 100%)',
  maskSize: '100vw 100vh',
  maskRepeat: 'no-repeat',
  maskPosition: '0 0',
  maskComposite: 'exclude',
  webkitMaskComposite: 'destination-out',
};

export const generateMaskImage = (
  elementOrElements: HTMLElement | HTMLElement[]
) => {
  const highlightPadding = 12;

  const boundingBox = _isArray(elementOrElements)
    ? getMultiElementBoundingBox(elementOrElements)
    : elementOrElements.getBoundingClientRect();

  if (!boundingBox) return;

  const maskProperties: Record<string, string> = {
    maskImage: `linear-gradient(90deg, black 0%, black 100%)`,
    maskSize: `${boundingBox.width + highlightPadding}px ${
      boundingBox.height + highlightPadding
    }px`,
    maskPosition: `${boundingBox.left - highlightPadding / 2}px ${
      boundingBox.top - highlightPadding / 2
    }px`,
    maskComposite: 'exclude',
    webkitMaskComposite: 'destination-out',
    maskRepeat: 'no-repeat',
  };

  return maskProperties;
};

/**
 * Determines the position & dimensions of a group of elements
 */
export const getMultiElementBoundingBox = (elements: HTMLElement[]) => {
  const boundingBoxes = elements.map((el) => el?.getBoundingClientRect());

  const boxDims = boundingBoxes.reduce(
    (dims: SimplifiedDOMRect, rect) => {
      if (!dims.top || rect.top < dims.top) dims.top = rect.top;
      if (!dims.left || rect.left < dims.left) dims.left = rect.left;
      if (!dims.bottom || rect.bottom > dims.bottom) dims.bottom = rect.bottom;
      if (!dims.right || rect.right > dims.right) dims.right = rect.right;
      return dims;
    },
    {
      bottom: undefined,
      left: undefined,
      right: undefined,
      top: undefined,
    }
  );

  return {
    height: (boxDims.bottom ?? 0) - (boxDims.top ?? 0),
    left: boxDims.left ?? 0,
    top: boxDims.top ?? 0,
    width: (boxDims.right ?? 0) - (boxDims.left ?? 0),
  };
};

// Add a mask image to the overlay for each DOM element flagged for highlight
const addMaskImages = async function (stepDef: TourStepDefinition) {
  const highlightElements = await waitForElementsAvailable(
    stepDef.highlightSelectors ?? []
  );

  const maskPropertyArray = (highlightElements ?? [])
    .filter((el) => !!el)
    .map((el) => generateMaskImage(el as HTMLElement))
    .concat([primaryOverlayMask]);

  const maskStyles: Record<string, string> = MaskProperties.reduce(
    (styles: Record<string, string>, propName) => {
      styles[propName] = maskPropertyArray
        .map((mask) => mask && mask[propName])
        .join(', ');
      return styles;
    },
    {}
  );

  const overlay = getOverlay();
  // @ts-expect-error - Element implicitly has an 'any' type because index expression is not of type 'number'
  MaskProperties.forEach((prop) => (overlay.style[prop] = maskStyles[prop]));
};

const removeMaskImages = function () {
  const overlay = getOverlay();
  requestAnimationFrame(() => {
    MaskProperties.forEach((prop) =>
      overlay.style.removeProperty(_kebabCase(prop))
    );
  });
};

const toggleScroll = function (enabled: boolean) {
  const appRoot = document.querySelector('#app-root') as HTMLElement;
  if (appRoot) appRoot.style.overflowY = enabled ? 'auto' : 'hidden';

  const body = document.querySelector('body') as HTMLElement;
  if (body) body.style.overflowY = enabled ? 'auto' : 'hidden';
};

const togglePointerEvents = function (enabled: boolean) {
  const appRoot = document.querySelector('#app-root') as HTMLElement;
  if (appRoot) appRoot.style.pointerEvents = enabled ? 'auto' : 'none';
};

const createOverlay = function () {
  const overlay = document.createElement('div');
  overlay.style.height = '100vh';
  overlay.style.width = '100vw';
  overlay.style.backgroundColor = `rgba(224,224,224,0.6)`;
  overlay.style.pointerEvents = 'none';
  overlay.style.position = 'fixed';
  overlay.style.zIndex = '9997';
  overlay.style.top = '0';
  overlay.style.left = '0';

  overlay.id = 'product-tour-overlay';

  return overlay;
};

const getOverlay = function () {
  return document.querySelector('#product-tour-overlay') as HTMLElement;
};

const toggleOverlay = function (enabled: boolean) {
  if (enabled) {
    const overlay = createOverlay();
    document.body.appendChild(overlay);

    return;
  }

  const overlay = getOverlay();
  if (overlay) document.body.removeChild(overlay);
};

export const onStepNext = function (
  props: BasicTourStepProps,
  stepDef: Partial<TourStepDefinition>
) {
  removeMaskImages();
};

export const onStepShow = async function (
  props: BasicTourStepProps,
  stepDef: Partial<TourStepDefinition>
) {
  _delay(addMaskImages, 250, stepDef);
};

export const onTourStart = function (tour: Shepherd.Tour) {
  // Disable scroll
  toggleScroll(false);
  // Enable overlay
  toggleOverlay(true);
  // Disable pointer events
  togglePointerEvents(false);

  // Cancel tour if user navigates back. Not graceful, but better than orphaning a tour step popover.
  window.addEventListener('popstate', function () {
    tour.cancel();
  });
};

export const onTourEnd = function () {
  togglePointerEvents(true);
  toggleScroll(true);
  toggleOverlay(false);
};
