import {
  Box,
  Button,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  Popper,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FilterableResource } from '@endorlabs/filters';
import { IconChevronDown, ResourceKindDisplay } from '@endorlabs/ui-common';

export interface AddResourceFilterButtonProps {
  resourceKinds: FilterableResource[];
  onSelect: (kind: FilterableResource) => void;
  primaryResourceKindLabel?: string;
}

export const AddResourceFilterButton = ({
  resourceKinds,
  onSelect,
  primaryResourceKindLabel,
}: AddResourceFilterButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (kind: FilterableResource) => {
    onSelect(kind);
    handleClose();
  };

  const primaryResourceKind = resourceKinds[0];

  return (
    <Box>
      <Button
        endIcon={<IconChevronDown sx={{ fontSize: 16 }} />}
        onClick={handleOpen}
      >
        <FM defaultMessage="Add Filter" />
      </Button>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: (theme) => theme.zIndex.tooltip,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              border: 'none',
              borderRadius: '6px',
              // TODO: them shadow with contrast from Card
              boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.1)`,
            }}
          >
            <List>
              {resourceKinds.map((kind, index) => (
                <ListItem key={kind} disablePadding disableGutters>
                  <ListItemButton onClick={() => handleSelect(kind)}>
                    {kind === primaryResourceKind ? (
                      <Typography color="text.secondary">
                        <FM
                          defaultMessage="List {kind} Where..."
                          values={{
                            kind: (
                              <Typography
                                component="span"
                                variant="inherit"
                                color="text.primary"
                              >
                                {primaryResourceKindLabel ?? (
                                  <ResourceKindDisplay value={kind} isPlural />
                                )}
                              </Typography>
                            ),
                          }}
                        />
                      </Typography>
                    ) : (
                      <Typography color="text.secondary">
                        <FM
                          defaultMessage="Have {kind} Where..."
                          values={{
                            kind: (
                              <Typography
                                component="span"
                                variant="inherit"
                                color="text.primary"
                              >
                                <ResourceKindDisplay value={kind} isPlural />
                              </Typography>
                            ),
                          }}
                        />
                      </Typography>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
