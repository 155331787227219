import { PaletteMode } from '@mui/material';
import _cloneDeep from 'lodash-es/cloneDeep';
import _merge from 'lodash-es/merge';

const brandColors = {
  dark: 'hsla(180, 98%, 12%, 1)',
  main: 'hsla(177, 100%, 17%, 1)', // '#005652'
  light: 'hsla(150, 100%, 47%, 1)', // '#00f078'
  contrastText: '#fff',
};

const defaultPalette = {
  background: {
    default: 'hsla(216, 45%, 98%, 1)', // #f7f9fc
    paper: '#fff',
    dark: 'hsla(0, 0%, 0%, 0.87)',
  },

  primary: brandColors,

  secondary: {
    main: 'hsla(216, 67%, 57%, 1)', // #4782da
    contrastText: '#fff',
  },

  brand: brandColors,

  critical: {
    contrastText: '#fff',
    dark: 'hsl(346, 62%, 29%)',
    light: 'hsl(345, 33%, 81%)',
    main: 'hsl(346, 60%, 35%)',
  },

  data: {
    blue: 'hsla(202, 100%, 71%, 1)', // #6AC9FF
    purple: 'hsla(269, 67%, 48%, 1)', // #7729cc
    yellow: 'hsla(47, 100%, 50%, 1)', // #FFC700
  },

  priority: {
    backlog: 'hsla(199, 92%, 56%, 1)', // #29b6f6
    defer: 'hsla(199, 92%, 74%, 1)', // #81d4fa
    plan: 'hsla(201, 98%, 41%, 1)', // #0288d1
    prioritize: 'hsla(206, 99%, 31%, 1)', // #01579b
  },

  severity: {
    critical: 'hsla(346, 60%, 35%, 1)', // #8F2406
    high: 'hsla(357, 76%, 49%, 1)', // #DC1E27
    medium: 'hsla(25, 100%, 62%, 1)', // #FF8E3D
    low: 'hsla(47, 100%, 50%, 1)', // #FFC800
  },

  status: {
    failure: 'hsla(4, 96%, 46%, 1)', // #E41405
    partialSuccess: 'hsla(47, 86%, 49%, 1)', // #EABA12
    pending: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
    success: 'hsla(76, 89%, 41%, 1)', // #96C70C
  },

  scores: {
    average: 'hsla(47, 86%, 49%, 1)', // #EABA12
    bad: 'hsla(4, 96%, 46%, 1)', // #E41405
    good: 'hsla(76, 89%, 41%, 1)', // #EABA12
    unknown: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
  },

  text: {
    light: 'hsla(0, 0%, 88%, 1)', // #E0E0E0
    link: 'hsla(212, 80%, 42%, 1)', // #1565C0
    primary: 'hsla(0, 0%, 12%, 1)', // #1F1F1F
    secondary: 'hsla(0, 0%, 44%, 1)', // #6F6F6F
    tertiary: 'hsla(36, 2%, 43%, 1)', // #706e6b
  },

  design: {
    severity: {
      critical: {
        [100]: 'hsl(345, 33%, 81%)',
        [200]: 'hsl(346, 35%, 48%)',
        [50]: 'hsl(0, 87%, 94%)',
        hover: 'hsl(346, 62%, 29%)',
        main: 'hsl(346, 60%, 35%)',
      },

      medium: {
        [100]: 'hsl(24, 100%, 87%)',
        [200]: 'hsl(25, 100%, 77%)',
        [50]: 'hsl(23, 100%, 95%)',
        hover: 'hsl(15, 82%, 40%)',
        main: 'hsl(25, 100%, 62%)',
      },

      high: {
        [100]: 'hsl(358, 84%, 88%)',
        [200]: 'hsl(357, 72%, 69%)',
        [50]: 'hsl(0, 81%, 94%)',
        hover: 'hsl(355, 71%, 45%)',
        main: 'hsl(357, 76%, 49%)',
      },

      low: {
        [100]: 'hsl(46, 100%, 86%)',
        [200]: 'hsl(47, 100%, 75%)',
        [50]: 'hsl(47, 100%, 91%)',
        hover: 'hsl(32, 35%, 46%)',
        main: 'hsl(42, 100%, 46%)',
      },

      safe: {
        [100]: 'hsl(85, 43%, 85%)',
        [200]: 'hsl(85, 42%, 73%)',
        hover: 'hsl(85, 56%, 26%)',
        main: 'hsl(86, 49%, 46%)',
      },

      info: {
        [100]: 'hsl(185, 76%, 85%)',
        [200]: 'hsl(185, 55%, 68%)',
        hover: 'hsl(185, 100%, 27%)',
        main: 'hsl(185, 100%, 35%)',
      },

      unknown: {
        [100]: 'hsl(240, 33%, 98%)',
        [200]: 'hsl(0, 0%, 88%)',
        hover: 'hsl(0, 0%, 12%)',
        main: 'hsl(0, 0%, 44%)',
      },

      in_progress: {
        [100]: 'hsl(229, 65%, 97%)',
        [200]: 'hsl(230, 65%, 91%)',
        hover: 'hsl(229, 35%, 49%)',
        main: 'hsl(229, 68%, 69%)',
      },
    },

    grays: {
      [100]: 'hsl(240, 33%, 98%)',
      [150]: 'hsl(0, 0%, 94%)',
      [200]: 'hsl(0, 0%, 88%)',
      hot_black: 'hsl(240, 33%, 98%)',
    },

    greens: {
      dark_teal: {
        [100]: 'hsl(180, 12%, 94%)',
        [200]: 'hsl(180, 15%, 90%)',
        [50]: 'hsl(216, 45%, 98%)',
        [600]: 'hsl(178, 10%, 48%)',
        [800]: 'hsl(178, 16%, 37%)',
        hover: 'hsl(177, 100%, 12%)',
        main: 'hsl(177, 100%, 17%)',
      },
      hot: {
        hover: 'hsl(132, 100%, 41%)',
        main: 'hsl(132, 100%, 41%)',
      },
    },

    data: {
      green: {
        [100]: 'hsl(154, 57%, 95%)',
        [200]: 'hsl(153, 58%, 85%)',
        [300]: 'hsl(153, 60%, 68%)',
        [400]: 'hsl(155, 70%, 42%)',
        hover: 'hsl(180, 45%, 16%)',
        main: 'hsl(177, 100%, 17%)',
      },

      blue: {
        [100]: 'hsl(203, 100%, 95%)',
        [200]: 'hsl(204, 100%, 90%)',
        [300]: 'hsl(204, 100%, 78%)',
        [400]: 'hsl(203, 100%, 63%)',
        hover: 'hsl(203, 100%, 36%)',
        main: 'hsl(203, 77%, 48%)',
      },

      teal: {
        [100]: 'hsl(186, 77%, 95%)',
        [200]: 'hsl(185, 76%, 85%)',
        [300]: 'hsl(185, 78%, 69%)',
        [400]: 'hsl(185, 84%, 45%)',
        hover: 'hsl(185, 100%, 27%)',
        main: 'hsl(185, 100%, 35%)',
      },

      brown: {
        [100]: 'hsl(24, 88%, 97%)',
        [200]: 'hsl(23, 91%, 91%)',
        [300]: 'hsl(24, 94%, 81%)',
        [400]: 'hsl(24, 95%, 69%)',
        hover: 'hsl(17, 57%, 39%)',
        main: 'hsl(21, 55%, 53%)',
      },

      salmon: {
        [100]: 'hsl(0, 81%, 94%)',
        [200]: 'hsl(350, 96%, 91%)',
        [300]: 'hsl(350, 96%, 81%)',
        [400]: 'hsl(351, 97%, 69%)',
        hover: 'hsl(350, 59%, 53%)',
        main: 'hsl(350, 73%, 60%)',
      },

      purple: {
        [100]: 'hsl(323, 87%, 97%)',
        [200]: 'hsl(324, 85%, 92%)',
        [300]: 'hsl(324, 88%, 83%)',
        [400]: 'hsl(324, 85%, 71%)',
        hover: 'hsl(324, 49%, 51%)',
        main: 'hsl(324, 59%, 59%)',
      },

      violet: {
        [100]: 'hsl(229, 65%, 97%)',
        [200]: 'hsl(230, 65%, 91%)',
        [300]: 'hsl(229, 67%, 81%)',
        [400]: 'hsl(229, 68%, 69%)',
        hover: 'hsl(228, 43%, 31%)',
        main: 'hsl(229, 35%, 49%)',
      },
    },

    hots: {
      blue: 'hsl(186, 88%, 60%)',
      navy: 'hsl(231, 100%, 48%)',
      purple: 'hsl(263, 90%, 69%)',
    },
  },
};

/**
 * ===== Dark mode overrides =====
 */
const darkPalette = {
  background: {
    default: 'hsla(215, 33%, 16%, 1)', // #1B2635
    paper: 'hsla(216, 32%, 20%, 1)', // #233044
  },

  critical: {
    dark: 'hsl(345, 33%, 81%)',
    light: 'hsl(346, 62%, 29%)',
  },

  text: {
    link: 'hsl(203, 100%, 63%)',
    primary: 'hsla(0, 100%, 100%, 0.95)',
    secondary: 'hsla(0, 100%, 100%, 0.5)',
  },

  design: {
    data: {
      // Data dark mode colors not implemented until they see use
    },

    grays: {
      [100]: 'hsl(156, 100%, 1%)',
      [150]: 'hsl(0, 0%, 20%)',
      [200]: 'hsl(0, 0%, 44%)',
      hot_black: 'hsl(156, 100%, 1%)',
    },

    greens: {
      dark_teal: {
        [100]: 'hsl(178, 16%, 37%)',
        [200]: 'hsl(178, 10%, 48%)',
        [50]: 'hsl(168, 5%, 19%)',
        [600]: 'hsl(180, 15%, 90%)',
        [800]: 'hsl(180, 15%, 90%)',
        hover: 'hsl(132, 100%, 41%)',
        main: 'hsl(150, 100%, 47%)',
      },

      hot: {
        hover: 'hsl(177, 100%, 12%)',
        main: 'hsl(177, 100%, 17%)',
      },
    },

    severity: {
      critical: {
        [200]: 'hsl(345, 33%, 81%)',
        [100]: 'hsl(346, 35%, 48%)',
        [50]: 'hsl(346, 62%, 29%)',
        hover: 'hsl(0, 87%, 94%)',
      },

      high: {
        [100]: 'hsl(357, 76%, 49%)',
        [50]: 'hsl(355, 71%, 45%)',
        hover: 'hsl(0, 81%, 94%)',
      },

      medium: {
        [100]: 'hsl(25, 100%, 62%)',
        [50]: 'hsl(15, 82%, 40%)',
        hover: 'hsl(24, 100%, 87%)',
      },

      low: {
        [100]: 'hsl(32, 35%, 46%)',
        [50]: 'hsl(17, 57%, 39%)',
        hover: 'hsl(46, 100%, 86%)',
      },

      safe: {
        [100]: 'hsl(85, 56%, 26%)',
        hover: 'hsl(85, 43%, 85%)',
      },

      info: {
        [100]: 'hsl(185, 100%, 27%)',
        hover: 'hsl(185, 76%, 85%)',
      },

      unknown: {
        [100]: 'hsl(0, 0%, 12%)',
        hover: 'hsl(240, 33%, 98%)',
      },

      in_progress: {
        [100]: 'hsl(229, 35%, 49%)',
        hover: 'hsl(229, 65%, 97%)',
      },
    },
  },
};

export const PaletteLight = {
  ..._cloneDeep(defaultPalette),
  darkMode: false,
  mode: 'light' as PaletteMode,
};

export const PaletteDark = _merge(
  _cloneDeep(defaultPalette),
  _cloneDeep(darkPalette),
  { darkMode: true, mode: 'dark' as PaletteMode }
);

export const getPalette = (paletteMode: 'light' | 'dark' = 'light') => {
  return paletteMode === 'light' ? PaletteLight : PaletteDark;
};
