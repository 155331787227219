import {
  Alert,
  AlertTitle,
  IconButton,
  Snackbar,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IconRocket, IconX } from '@endorlabs/ui-common';

import { useProductTour } from '../../Tour';

interface ReturnAlertProps {
  isOpen: boolean;
  closeCallback: () => void;
}

export const StartDemoFromProjects = ({
  isOpen,
  closeCallback,
}: ReturnAlertProps) => {
  const theme = useTheme();

  const { productTour } = useProductTour();

  const productTourClick = useCallback(() => {
    productTour.start();
  }, [productTour]);

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    closeCallback();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClick={productTourClick}
      open={isOpen}
      sx={{ cursor: 'pointer' }}
    >
      <Alert
        variant="filled"
        severity="success"
        sx={{ backgroundColor: theme.palette.brand.main }}
        elevation={12}
        icon={<IconRocket />}
        action={
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              marginLeft: '0.25rem',
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
              },
            }}
          >
            <IconX />
          </IconButton>
        }
      >
        <AlertTitle>
          <FM defaultMessage="Start Product Tour" />
        </AlertTitle>
      </Alert>
    </Snackbar>
  );
};
