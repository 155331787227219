import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel, V1PlatformSource } from '@endorlabs/api_client';
import {
  getFindingDescription,
  getFindingDiscovered,
  getFindingVulnerabilityMetadata,
  getFirstFindingSecurityAdvisoryUrl,
  getSecurityFindingCvssScore,
  getSecurityFindingCweId,
  getSecurityFindingFixVersion,
  getSecurityFindingIntroducedVersion,
} from '@endorlabs/endor-core/Finding';
import {
  getDependencyFileLocations,
  PackageVersionResource,
} from '@endorlabs/endor-core/PackageVersion';
import {
  DateDisplay,
  DependencyPathDisplay,
  DescriptionList,
  DescriptionListItem,
  EPSSDisplay,
  FindingCategoriesArrayDisplay,
  FormattedTypography,
  IconGalaxy,
  Link,
  NilDisplay,
  PackageVersionNameDisplay,
  RowStack,
  SeverityDisplay,
  StatusDisplay,
  UIFindingUtils,
  UIPackageVersionUtils,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { FindingExceptionStateDisplay } from '../../domains/Findings';
import { ExceptionPolicyDetails } from '../../domains/Policies/components/ExceptionPolicyDetails';
import { SecurityLabels } from './constants';
import { FindingDetailSecurityReferences } from './FindingDetailSecurityReferences';
import { FindingDetailProps } from './types';

const URL_EPSS_DOCS =
  'https://www.first.org/epss/articles/prob_percentile_bins';

/**
 * @deprecated
 * @see FindingDetailDrawerV2
 */
export const FindingDetailSecurity = ({
  direction,
  exceptionPolicies = [],
  finding,
  project,
  sourcePackageVersion,
}: FindingDetailProps) => {
  const { spec } = finding;
  const { space } = useTheme();

  const isSelfFinding = UIFindingUtils.isSelfFinding(finding);
  const platformIcon =
    project && project?.spec
      ? UIProjectUtils.getPlatformIcon(
          project.spec.platform_source as V1PlatformSource
        )
      : IconGalaxy;

  const advisoryUrl = getFirstFindingSecurityAdvisoryUrl(finding);

  const esppPercentileScore =
    finding.spec.finding_metadata?.vulnerability?.spec?.epss_score
      ?.percentile_score;
  const esppProbabilityScore =
    finding.spec.finding_metadata?.vulnerability?.spec?.epss_score
      ?.probability_score;

  const findingDescription = getFindingDescription(finding);

  // get properties from vuln metadata, if present
  const findingVuln = getFindingVulnerabilityMetadata(finding);

  const vulnAffected = findingVuln?.spec?.affected?.[0];
  const vulnAffectedVersions = vulnAffected?.versions;
  const vulnAffectedPackageEcosytem = vulnAffected?.package?.ecosystem;
  const vulnAliases = findingVuln?.spec?.aliases;
  const vulnReferences = findingVuln?.spec?.references;
  const vulnAdditionalNotes = findingVuln?.spec?.additional_notes ?? [];

  /**
   * @deprecated properties pulled from raw osv data object
   */
  const findingOsvData = findingVuln?.spec?.raw?.osv_vulnerability;
  const osvId = findingOsvData?.id;
  const osvSchemaVersion = findingOsvData?.schema_version;

  const {
    baseScore: osvSeverityBaseScore,
    scoreType: osvSeverityScoreType,
    scoreVector: osvSeverityScoreVector,
  } = getSecurityFindingCvssScore(finding);
  const cweId = getSecurityFindingCweId(finding);
  const securityFindingFixVersion = getSecurityFindingFixVersion(finding);

  const hasExceptions = exceptionPolicies.length > 0;

  const [dependencyPackageVersion, dependencyFileLocations] = useMemo(() => {
    const dependencyPackageVersionName =
      finding.spec.target_dependency_package_name;

    if (!dependencyPackageVersionName) return [undefined, []];

    const { ecosystem } = UIPackageVersionUtils.parsePackageName(
      dependencyPackageVersionName
    );

    // Wrap as partial PackageVersion
    const dependencyPackageVersion = {
      meta: { name: dependencyPackageVersionName },
      spec: { ecosystem, project_uuid: finding.spec.project_uuid },
    } as PackageVersionResource;

    const dependencyFileLocations = sourcePackageVersion
      ? getDependencyFileLocations(sourcePackageVersion, {
          name: dependencyPackageVersionName,
        })
      : [];
    return [dependencyPackageVersion, dependencyFileLocations];
  }, [finding, sourcePackageVersion]);

  const Detail = (
    <Stack spacing={space.md}>
      {/* PRIORITY INFORMATION */}
      <DescriptionList title={SecurityLabels.priority_information}>
        {/* Level/Severity */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.severity}
          value={
            spec.level ? (
              <SeverityDisplay value={spec.level as SpecFindingLevel} />
            ) : (
              <NilDisplay />
            )
          }
        />

        <Grid container direction="row">
          <Grid item>
            {/* Score */}
            <DescriptionListItem
              direction={direction}
              term={SecurityLabels.base_cvss_score}
              value={osvSeverityBaseScore ?? <NilDisplay />}
            />
          </Grid>

          <Grid item ml={4}>
            {/* Score Type */}
            <DescriptionListItem
              direction={direction}
              term={SecurityLabels.score_type}
              value={osvSeverityScoreType ?? <NilDisplay />}
            />
          </Grid>
        </Grid>

        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.epss}
          value={
            <EPSSDisplay
              percentile={esppPercentileScore}
              probability={esppProbabilityScore}
            />
          }
          helpTooltip={
            <FM
              defaultMessage="EPSS measures an estimated probability of observing exploitation activity in the next 30 days in the wild. The EPSS percentile rank is a percentile rank ordering of the probability from highest to lowest. (<link>Hyperlink</link>)"
              values={{
                link: (value) => (
                  <Link color="inherit" target="_blank" to={URL_EPSS_DOCS}>
                    {value}
                  </Link>
                ),
              }}
            />
          }
        />

        {sourcePackageVersion && (
          <DescriptionListItem
            direction={direction}
            term={SecurityLabels.package}
            value={
              <PackageVersionNameDisplay
                packageVersion={sourcePackageVersion}
                showIcon
                showVersion
                size="small"
              />
            }
          />
        )}

        {dependencyPackageVersion && (
          <DescriptionListItem
            direction={direction}
            term={SecurityLabels.dependency}
            value={
              <Stack direction="column">
                <PackageVersionNameDisplay
                  packageVersion={dependencyPackageVersion}
                  showIcon
                  showVersion
                  size="small"
                />

                {dependencyFileLocations.map((value, index) => (
                  <Typography
                    color="text.secondary"
                    component="span"
                    key={index}
                    sx={{ wordBreak: 'break-all' }}
                    variant="body2"
                  >
                    {value}
                  </Typography>
                ))}
              </Stack>
            }
          />
        )}
      </DescriptionList>

      {/* ISSUE */}
      <DescriptionList title={SecurityLabels.issue}>
        {/* Identifier */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.identifier}
          value={vulnAliases?.join(', ') ?? <NilDisplay />}
        />

        {/* Description */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.description}
          value={
            findingDescription ? (
              <FormattedTypography text={findingDescription} />
            ) : (
              <NilDisplay />
            )
          }
        />

        {/* Advisory */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.advisory}
          value={
            advisoryUrl ? (
              <Link to={advisoryUrl} target="_blank">
                {advisoryUrl}
              </Link>
            ) : (
              <NilDisplay />
            )
          }
        />

        {/* Base Score Metrics */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.base_score_metrics}
          value={osvSeverityScoreVector ?? <NilDisplay />}
        />
      </DescriptionList>

      {exceptionPolicies.length > 0 && (
        <Stack spacing={space.sm}>
          <RowStack>
            <Typography variant="h6">
              <FM defaultMessage="Exception Policies" />
            </Typography>
            <FindingExceptionStateDisplay hasExceptions={hasExceptions} />
          </RowStack>
          <ExceptionPolicyDetails exceptionPolicies={exceptionPolicies} />
        </Stack>
      )}

      {sourcePackageVersion && !isSelfFinding && (
        <DependencyPathDisplay
          dependencyGraph={
            (sourcePackageVersion?.spec?.resolved_dependencies
              ?.dependency_graph as unknown as Record<string, string[]>) ??
            ({} as Record<string, string[]>)
          }
          displayAsAccordion={false}
          project={project}
          sourcePackageNames={[sourcePackageVersion?.meta?.name ?? '']}
          targetPackageName={dependencyPackageVersion?.meta.name}
        />
      )}

      {/* FIX INFORMATION */}
      <DescriptionList title={SecurityLabels.fix_information}>
        {/* Fix Available */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.fix_available}
          value={
            <StatusDisplay
              status={securityFindingFixVersion ? 'success' : 'nil'}
            />
          }
        />

        {/* Remediation */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.remediation}
          value={spec.remediation ?? <NilDisplay />}
        />

        {/* Fix Version */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.fixed_version}
          value={securityFindingFixVersion ?? <NilDisplay />}
        />

        {/* Introduced Version */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.introduced_version}
          value={getSecurityFindingIntroducedVersion(finding) ?? <NilDisplay />}
        />

        {/* Affected Versions */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.affected_versions}
          value={vulnAffectedVersions?.join(', ') ?? <NilDisplay />}
        />
      </DescriptionList>

      {/* METADATA */}
      <DescriptionList title={SecurityLabels.metadata}>
        {/* CWE */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.cwe_id}
          value={cweId ?? <NilDisplay />}
        />
        {/* OSV Identifier */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.osv_identifier}
          value={osvId ?? <NilDisplay />}
        />
        {/* Dismissed */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.dismiss}
          value={<StatusDisplay status={spec.dismiss ? 'success' : 'nil'} />}
        />
        {/* Ecosystem */}
        {vulnAffectedPackageEcosytem && (
          <DescriptionListItem
            direction={direction}
            term={SecurityLabels.ecosystem}
            value={vulnAffectedPackageEcosytem}
          />
        )}
        {/* Published */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.published}
          value={
            getFindingDiscovered(finding) ? (
              <DateDisplay value={getFindingDiscovered(finding) as string} />
            ) : (
              <NilDisplay />
            )
          }
        />
        {/* OSV Schema Version */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.osv_schema_version}
          value={osvSchemaVersion ?? <NilDisplay />}
        />
        {/* Finding Categories */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.finding_category}
          value={
            spec.finding_categories ? (
              <FindingCategoriesArrayDisplay value={spec.finding_categories} />
            ) : (
              <NilDisplay />
            )
          }
        />

        {/* Additional Notes */}
        {vulnAdditionalNotes?.length > 0 && (
          <DescriptionListItem
            direction={direction}
            term={SecurityLabels.additional_notes}
            value={vulnAdditionalNotes.map((note: string, index: number) => (
              <FormattedTypography key={index} text={note} />
            ))}
          />
        )}

        {/* OSV References */}
        <DescriptionListItem
          direction={direction}
          term={SecurityLabels.references}
          value={
            vulnReferences ? (
              <FindingDetailSecurityReferences
                direction={direction}
                references={vulnReferences}
              />
            ) : (
              <NilDisplay />
            )
          }
        />
      </DescriptionList>
    </Stack>
  );

  return Detail;
};
