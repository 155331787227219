import { RowData } from '@tanstack/react-table';
import _get from 'lodash-es/get';
import { FormattedMessage as FM } from 'react-intl';

import { TResourceObject } from '@endorlabs/queries';
import { DataTableColumnDef } from '@endorlabs/ui-common';

import { ToolPatternSelectionTableRegistryEntry } from '../../CiCdTools/utils';
import { LicensesSelectionTableRegistryEntry } from '../../Licenses/utils';
import { SecretRulesSelectionTableRegistryEntry } from '../../SecretRules/utils';

interface RowValue {
  rowValue?: string;
}
export interface DataTableRegistryEntry<
  I,
  O extends RowData | (RowData & RowValue)
> {
  buildColumns: (valueKey?: string) => DataTableColumnDef<I>[];
  buildRows: (data: I[], valueKey?: string) => O[];
}

// TODO: Base this off ResourceKind types
export const DataTableRegistry: Record<
  string,
  DataTableRegistryEntry<any, any>
> = {
  License: LicensesSelectionTableRegistryEntry,
  SecretRule: SecretRulesSelectionTableRegistryEntry,
  ToolPattern: ToolPatternSelectionTableRegistryEntry,
};

const DEFAULT_TABLE_REGISTRY_ENTRY: DataTableRegistryEntry<any, any> = {
  buildColumns: () => {
    return [
      {
        accessorKey: 'optionLabel',
        header: () => <FM defaultMessage="Description" />,
        id: 'optionLabel',
      },
      {
        accessorKey: 'optionValue',
        header: () => <FM defaultMessage="Value" />,
        id: 'optionValue',
      },
    ];
  },

  buildRows: (resources) => resources,
};

export const setTableRegistry = (
  resourceKind: string,
  entry: DataTableRegistryEntry<any, any>
) => {
  DataTableRegistry[resourceKind] = entry;
};

export const getTableRegistryEntry = (
  resourceKind: string,
  valueKey: string
) => {
  const registerEntry =
    DataTableRegistry[resourceKind] ?? DEFAULT_TABLE_REGISTRY_ENTRY;

  // Augment buildRows to ensure that option value is ultimately set by the backend-defined resource field.
  // Implementing tables have total control over display, but row selection is based on the optionValue.
  const augmentedRegisterEntry = {
    ...registerEntry,
    buildColumns: () => {
      const columns = registerEntry.buildColumns(valueKey);
      return columns.length > 0
        ? columns
        : DEFAULT_TABLE_REGISTRY_ENTRY.buildColumns();
    },
    buildRows: (resources: TResourceObject<unknown>[]) => {
      const rows = registerEntry
        .buildRows(resources, valueKey)
        .map((row, i) => {
          const resource = resources[i];

          const rowValue = _get(row, 'rowValue');

          // use rowValue for option, if set by the row builder
          if (rowValue) {
            return {
              ...row,
              optionLabel: rowValue,
              optionValue: rowValue,
            };
          }

          return {
            ...row,
            optionLabel: _get(resource, 'meta.name'),
            optionValue: _get(resource, valueKey),
          };
        });
      return rows.length > 0
        ? rows
        : DEFAULT_TABLE_REGISTRY_ENTRY.buildRows(resources);
    },
  };

  return augmentedRegisterEntry;
};
