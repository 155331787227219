import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { FindingCount } from '@endorlabs/queries';

import { RowStack } from '../../../../components';
import { FindingCountDisplay } from '../FindingCountDisplay';

export interface FindingCountArrayDisplayProps {
  forceWidth?: boolean;
  hideEmptyValues?: boolean;
  value?: FindingCount[];
}

/**
 * Displays a series of FindingCountDisplays by level
 */
export const FindingCountArrayDisplay = ({
  forceWidth = false,
  hideEmptyValues = false,
  value = [],
}: FindingCountArrayDisplayProps) => {
  const { space, spacing } = useTheme();

  const levelValues = useMemo(() => {
    const values = FINDING_LEVELS.map(
      (lvl) =>
        value.find((v) => v.level === lvl) ?? { level: lvl, value: undefined }
    );

    if (hideEmptyValues) {
      return values.filter((v) => !!v.value);
    }

    return values;
  }, [hideEmptyValues, value]);

  return (
    <RowStack
      className="FindingCountArrayDisplay-root"
      flexWrap="nowrap"
      gap={space.xs}
    >
      {levelValues.map((levelItem, index) => {
        return (
          <FindingCountDisplay
            key={index}
            level={levelItem.level}
            value={levelItem.value}
            width={forceWidth ? spacing(12) : undefined}
          />
        );
      })}
    </RowStack>
  );
};
