import { Alert, AlertTitle, Grid, TextField } from '@mui/material';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Controller, ErrorOption, FieldValues, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { IQueryErrorResponse } from '@endorlabs/queries';
import {
  ButtonPrimary,
  IconAlertTriangle,
  REGEX_EMAIL_VALIDATION,
} from '@endorlabs/ui-common';

interface ServerFieldError {
  field?: string;
  object: { type: string; message: string };
}

const translateServerError = (
  code: number,
  errMessage: string
): ServerFieldError => {
  switch (code) {
    case 403:
      return {
        object: {
          type: 'server',
          message: 'Not authorized to create Invitations for this tenant.',
        },
      };
    case 429:
      return {
        object: {
          type: 'server',
          message:
            'Rate limit exceed for sending Invitations. Please try again later.',
        },
      };
    default:
      return {
        object: {
          type: 'server',
          message: errMessage ? errMessage : 'An unknown error occurred',
        },
      };
  }
};

export interface FormNewInvitationProps {
  helperText?: ReactNode;
  onSubmit: (data: FieldValues) => void;
  serverErrorResponse?: IQueryErrorResponse;
  showSubmitButton?: boolean;
}

export type InvitationRef = {
  submitForm: () => void;
};

export const FormNewInvitation = forwardRef(function FormNewInvitation(
  {
    helperText,
    onSubmit,
    serverErrorResponse,
    showSubmitButton = true,
  }: FormNewInvitationProps,
  ref
) {
  const {
    control,
    handleSubmit: hookFormSubmit,
    resetField,
    setError,
  } = useForm();
  const { formatMessage: fm } = useIntl();
  const [localError, setLocalError] = useState<
    (ErrorOption & { details?: string }) | undefined
  >();

  useEffect(() => {
    if (serverErrorResponse) {
      const { field, object } = translateServerError(
        serverErrorResponse.status,
        serverErrorResponse.data?.message ?? ''
      );
      if (field) {
        setError(field, object);
      } else {
        setLocalError({ details: object.message });
      }
    } else {
      setLocalError(undefined);
    }
  }, [serverErrorResponse, setError]);

  const handleSubmit = (f: FieldValues) => {
    resetField('emails');
    onSubmit(f);
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      hookFormSubmit(handleSubmit)();
    },
  }));

  return (
    <form id="FormNewInvitation" onSubmit={hookFormSubmit(handleSubmit)}>
      <Grid container direction="column" spacing={8}>
        <Grid item>
          <Controller
            control={control}
            defaultValue=""
            name="emails"
            render={({ field: { onChange, value }, fieldState }) => {
              return (
                <TextField
                  autoComplete="off"
                  error={!!fieldState.error}
                  fullWidth
                  helperText={
                    fieldState.error ? fieldState.error.message : helperText
                  }
                  label={fm({ defaultMessage: 'Email addresses' })}
                  onChange={onChange}
                  placeholder={fm({
                    defaultMessage: 'user1@myorg.com, user2@myorg.com',
                  })}
                  value={value}
                  variant="standard"
                />
              );
            }}
            rules={{
              required: fm({ defaultMessage: 'This is a required field' }),
              validate: (value) => {
                return (
                  value
                    .split(',')
                    .map((v: string) => v.trim().match(REGEX_EMAIL_VALIDATION))
                    .includes(null) === false ||
                  fm({
                    defaultMessage: 'One or more email addresses is invalid',
                  })
                );
              },
              // TODO: Cannot be current user email
            }}
          />
        </Grid>
        {localError && (
          <Grid item>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>

              {localError.details}
            </Alert>
          </Grid>
        )}
        {showSubmitButton && (
          <Grid item>
            <ButtonPrimary type="submit">
              <FM defaultMessage="Invite Users" />
            </ButtonPrimary>
          </Grid>
        )}
      </Grid>
    </form>
  );
});
