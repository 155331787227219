import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecArchType,
  V1Language,
  V1PlatformSource,
} from '@endorlabs/api_client';
import { PluginBinaryResource } from '@endorlabs/queries';
import {
  CodeBlock,
  IconExternalLink,
  LanguageLabel,
  Link,
} from '@endorlabs/ui-common';

import { getEnv } from '../../../constants';
import {
  AuthMode,
  InstallMode,
  PlatformSourceType,
  SupportedOS,
} from './constants';
import {
  getEndorctlInitCodeBlock,
  getEndorctlInstallCodeBlock,
  getEndorctlInstallCodeBlockWindows,
  getEndorctlScanCodeBlock,
  getHomebrewCodeBlock,
  getNpmCodeBlock,
  stepHeadingsEndorctl,
  stepHeadingsGeneral,
} from './endorctlStepsAndInstallationCode';
import { useListEndorctlBinaries } from './hooks';
import {
  getPackageManager,
  PackageManagerCodeBlock,
  PhantomDependencyCode,
  StepHeading,
} from './StepHelpers';
import {
  InstallModeType,
  LanguageType,
  OSArchTypeOption,
  PlatformSourceTypeOption,
} from './types';

const OS_TYPE_CHECKS: Partial<Record<SupportedOS, RegExp[]>> = {
  LINUX: [/linux/i],
  MAC: [/macintosh/i],
};

const OS_ARCH_TYPES: Record<SupportedOS, OSArchTypeOption[]> = {
  LINUX: [
    {
      type: SpecArchType.LinuxAmd64,
      label: <FM defaultMessage="Intel" />,
    },
  ],
  MAC: [
    {
      type: SpecArchType.MacosArm64,
      label: <FM defaultMessage="Apple Silicon" />,
    },
    {
      type: SpecArchType.MacosAmd64,
      label: <FM defaultMessage="Intel" />,
    },
  ],
  WINDOWS: [
    {
      type: SpecArchType.WindowsAmd64,
      label: <FM defaultMessage="Windows" />,
    },
  ],
};

const PLATFORM_SOURCE_TYPES: Record<
  V1PlatformSource,
  PlatformSourceTypeOption[]
> = {
  [V1PlatformSource.Unspecified]: [],
  [V1PlatformSource.Gitserver]: [],
  [V1PlatformSource.Github]: [
    {
      type: PlatformSourceType.GITHUB,
      label: <FM defaultMessage="GitHub" />,
    },
  ],
  [V1PlatformSource.Gitlab]: [
    {
      type: PlatformSourceType.GITLAB,
      label: <FM defaultMessage="GitLab" />,
    },
  ],
  [V1PlatformSource.Bitbucket]: [
    {
      type: PlatformSourceType.BITBUCKET,
      label: <FM defaultMessage="Bitbucket" />,
    },
  ],
  [V1PlatformSource.Binary]: [
    {
      type: PlatformSourceType.BINARY,
      label: <FM defaultMessage="Binary" />,
    },
  ],
  [V1PlatformSource.HuggingFace]: [
    {
      type: PlatformSourceType.HUGGINGFACE,
      label: <FM defaultMessage="Hugging Face" />,
    },
  ],
  [V1PlatformSource.Azure]: [
    {
      type: PlatformSourceType.AZURE,
      label: <FM defaultMessage="Azure" />,
    },
  ],
};

const SCAN_LANGUAGES = [
  V1Language.Csharp,
  V1Language.Go,
  V1Language.Java,
  V1Language.Js,
  V1Language.Php,
  V1Language.Python,
  V1Language.Ruby,
  V1Language.Rust,
  V1Language.Scala,
  V1Language.Typescript,
].map((lang) => ({
  label: <LanguageLabel value={lang} />,
  value: lang,
}));

const DEFAULT_LANGUAGE = SCAN_LANGUAGES.find(
  (lang) => lang.value === V1Language.Java
);

const { URL_ENDOR_DOCS } = getEnv();

export const AddNewProjectPage = () => {
  const platformSource = V1PlatformSource.Github;
  const { palette } = useTheme();
  const [installationMode, setInstallationMode] = useState<InstallModeType>(
    InstallMode.HOMEBREW
  );

  const [selectedPlatformSourceType, setSelectedPlatformSourceType] = useState<
    PlatformSourceType | undefined
  >();

  const selectedPlatformSourceTypes = useMemo<
    PlatformSourceTypeOption[]
  >(() => {
    return PLATFORM_SOURCE_TYPES[platformSource] ?? [];
  }, [platformSource]);
  // default the first platform source type option as selected when switching platform sources
  useEffect(() => {
    if (selectedPlatformSourceTypes.length) {
      setSelectedPlatformSourceType(selectedPlatformSourceTypes[0].type);
    } else {
      setSelectedPlatformSourceType(undefined);
    }
  }, [selectedPlatformSourceTypes]);

  const detectSupportedOS = (): SupportedOS | undefined => {
    const { userAgent } = navigator;
    for (const [os, checks] of Object.entries(OS_TYPE_CHECKS)) {
      const isCandidate = checks.every((check) => check.test(userAgent));
      if (isCandidate) {
        return os as SupportedOS;
      }
    }
  };

  const [selectedArchType, setSelectedArchType] = useState<
    SpecArchType | undefined
  >();
  const qListEndorctlBinaries = useListEndorctlBinaries(selectedArchType);

  const [endorctlBinaryOptions, hasEndorctlBinary] = useMemo(() => {
    const options = qListEndorctlBinaries.data?.list?.objects ?? [];
    return [options, !!options.length];
  }, [qListEndorctlBinaries.data]);

  const [selectedEndorctlBinary, setSelectedEndorctlBinary] =
    useState<PluginBinaryResource>();

  const [selectedAuthMode, setSelectedAuthMode] = useState<
    AuthMode | undefined
  >(AuthMode.Google);

  useEffect(() => {
    if (endorctlBinaryOptions.length > 0) {
      setSelectedEndorctlBinary(endorctlBinaryOptions[0]);
    } else {
      setSelectedEndorctlBinary(undefined);
    }
  }, [installationMode, endorctlBinaryOptions]);

  const handleArchTypeChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: SpecArchType | null
  ) => {
    if (value !== null) {
      setSelectedArchType(value);
    }
  };

  const [selectedPlatform, setSelectedPlatform] = useState<
    SupportedOS | undefined
  >(() => detectSupportedOS() ?? SupportedOS.Linux);

  // update the arch type base on the selected os type
  const archTypes = useMemo<OSArchTypeOption[]>(() => {
    if (selectedPlatform && selectedPlatform in OS_ARCH_TYPES) {
      return OS_ARCH_TYPES[selectedPlatform as SupportedOS] ?? [];
    }
    return [];
  }, [selectedPlatform]);

  const handlePlatformChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: SupportedOS | null
  ) => {
    if (value !== null) {
      setSelectedPlatform(value);
    }
  };

  const handleInstallationChange = (
    e: React.MouseEvent<HTMLElement>,
    value: InstallMode
  ) => {
    if (value !== null) {
      setInstallationMode(value);
    }
  };

  const handleAuthModeChange = (
    evt: React.MouseEvent<HTMLElement>,
    value: AuthMode | null
  ) => {
    if (value !== null) {
      setSelectedAuthMode(value);
    }
  };

  const showCodeBlock = useMemo(() => {
    if (
      (selectedPlatform === SupportedOS.Linux ||
        selectedPlatform === SupportedOS.MacOS ||
        selectedPlatform === SupportedOS.Windows) &&
      installationMode === InstallMode.NPM
    ) {
      return 'NPM';
    } else if (
      (selectedPlatform === SupportedOS.Linux ||
        selectedPlatform === SupportedOS.MacOS) &&
      installationMode === InstallMode.CURL
    ) {
      return 'MACANDLINUXCURL';
    } else if (selectedPlatform === SupportedOS.Windows) {
      return 'WINDOWSCURL';
    }
    return 'BREW';
  }, [installationMode, selectedPlatform]);

  // default the first arch type option as selected when switching os types
  useEffect(() => {
    if (archTypes.length) {
      setSelectedArchType(archTypes[0].type);
    } else {
      setSelectedArchType(undefined);
    }

    if (selectedPlatform === SupportedOS.MacOS) {
      setInstallationMode(InstallMode.HOMEBREW);
    } else {
      setInstallationMode(InstallMode.NPM);
    }
  }, [archTypes, selectedPlatform]);

  const [language, setLanguage] = useState(
    DEFAULT_LANGUAGE?.value as V1Language
  );
  const [packageManagers, setPackageManagers] = useState(
    getPackageManager(DEFAULT_LANGUAGE?.value as V1Language)
  );
  const [selectedPackageManager, setSelctedPackageManager] = useState(
    packageManagers[0]
  );

  useEffect(() => {
    const packageManagers = getPackageManager(language);
    setPackageManagers(packageManagers);
    setSelctedPackageManager(packageManagers[0]);
  }, [language]);

  return (
    <Stack spacing={6} marginY={2}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        marginY={4}
        position="relative"
      >
        <Stack spacing={4} alignItems="flex-start">
          <StepHeading msg={stepHeadingsGeneral.step1} count={1} />

          <Box
            sx={{
              borderLeft: `2px solid ${palette.text.light}`,
              padding: '1rem 1rem 1.5rem 1rem',
              marginLeft: '0.75rem !important',
            }}
          >
            <ToggleButtonGroup
              exclusive
              onChange={handlePlatformChange}
              value={selectedPlatform}
            >
              <ToggleButton color="primary" value={SupportedOS.MacOS}>
                <FM defaultMessage="macOS" />
              </ToggleButton>

              <ToggleButton color="primary" value={SupportedOS.Linux}>
                <FM defaultMessage="Linux" />
              </ToggleButton>

              <ToggleButton color="primary" value={SupportedOS.Windows}>
                <FM defaultMessage="Windows" />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Stack>

        <Stack spacing={1} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <iframe
            width="360"
            height="200"
            src="https://www.youtube.com/embed/w6i8Lfk_atw?si=EUsjAxtxq04keE6f"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>

          <Link
            target="_blank"
            to="https://www.youtube.com/watch?v=w6i8Lfk_atw&t=1s"
          >
            <FM defaultMessage="Watch Video: How to Scan a Project" />
          </Link>
        </Stack>
      </Box>

      <Stack spacing={4} alignItems="flex-start">
        <StepHeading msg={stepHeadingsEndorctl.stepEndorctlInstall} count={2} />
        {/*Empty block to give a small margin from step number */}
        <>
          <Stack
            spacing={2}
            sx={{
              borderLeft: `2px solid ${palette.text.light}`,
              padding: '1rem 1rem 1.5rem 1rem',
              marginLeft: '0.75rem !important',
              width: '100%',
            }}
          >
            <ToggleButtonGroup
              exclusive
              onChange={handleInstallationChange}
              value={installationMode}
            >
              {selectedPlatform === SupportedOS.MacOS && (
                <ToggleButton color="primary" value={InstallMode.HOMEBREW}>
                  <FM defaultMessage="brew" />
                </ToggleButton>
              )}

              {
                <ToggleButton color="primary" value={InstallMode.NPM}>
                  <FM defaultMessage="npm" />
                </ToggleButton>
              }
            </ToggleButtonGroup>

            <>
              {/* Code block for brew install */}
              {showCodeBlock === 'BREW' &&
                (hasEndorctlBinary ? (
                  <Stack spacing={4}>
                    <CodeBlock value={getHomebrewCodeBlock()} />
                  </Stack>
                ) : (
                  <Alert severity="error" sx={{ marginTop: 4 }}>
                    <FM defaultMessage="No endorctl binary found for the selected Platform" />
                  </Alert>
                ))}

              {/* Code block for npm install */}
              {showCodeBlock === 'NPM' &&
                (hasEndorctlBinary ? (
                  <Stack spacing={4}>
                    <CodeBlock value={getNpmCodeBlock()} />
                  </Stack>
                ) : (
                  <Alert severity="error" sx={{ marginTop: 4 }}>
                    <FM defaultMessage="No endorctl binary found for the selected Platform" />
                  </Alert>
                ))}

              {/* Code block for curl install in mac and linux */}
              {showCodeBlock === 'MACANDLINUXCURL' && (
                <Stack spacing={4}>
                  {/* Allow selection if multiple architecture types are present */}
                  {archTypes.length > 1 && (
                    <ToggleButtonGroup
                      exclusive
                      onChange={handleArchTypeChange}
                      value={selectedArchType}
                      sx={{ marginBottom: 4 }}
                    >
                      {archTypes.map((archType) => (
                        <ToggleButton
                          key={archType.type}
                          value={archType.type}
                          color="primary"
                        >
                          {archType.label}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  )}

                  {/* Show the placeholder while loading, code block for install, or an alert when no binary is available */}
                  {qListEndorctlBinaries.isLoading ? (
                    <Skeleton width="100%" sx={{ transform: 'none' }}>
                      <CodeBlock value={getEndorctlInstallCodeBlock()} />
                    </Skeleton>
                  ) : hasEndorctlBinary ? (
                    <>
                      <CodeBlock
                        value={getEndorctlInstallCodeBlock(
                          selectedPlatform,
                          selectedEndorctlBinary?.spec
                        )}
                      />
                      <Alert
                        severity="info"
                        variant="outlined"
                        sx={{ marginTop: 4 }}
                      >
                        <AlertTitle
                          sx={({ spacing }) => ({
                            fontSize: spacing(3.5),
                            fontWeight: '400',
                          })}
                        >
                          <FM defaultMessage="We recommend you place this binary in a location defined in your $PATH" />
                        </AlertTitle>
                      </Alert>
                    </>
                  ) : (
                    <Alert severity="error" sx={{ marginTop: 4 }}>
                      <FM defaultMessage="No endorctl binary found for the selected Platform" />
                    </Alert>
                  )}
                </Stack>
              )}
              {showCodeBlock === 'WINDOWSCURL' && (
                <Stack spacing={4}>
                  {/* Show the placeholder while loading, code block for install, or an alert when no binary is available */}
                  {qListEndorctlBinaries.isLoading ? (
                    <Skeleton width="100%" sx={{ transform: 'none' }}>
                      <CodeBlock value={getEndorctlInstallCodeBlockWindows()} />
                    </Skeleton>
                  ) : hasEndorctlBinary ? (
                    <>
                      <CodeBlock
                        value={getEndorctlInstallCodeBlockWindows(
                          selectedEndorctlBinary?.spec
                        )}
                      />
                    </>
                  ) : (
                    <Alert severity="error" sx={{ marginTop: 4 }}>
                      <FM defaultMessage="No endorctl binary found for the selected Platform" />
                    </Alert>
                  )}
                </Stack>
              )}
            </>
          </Stack>
        </>
      </Stack>

      <Stack spacing={4} alignItems="flex-start">
        <StepHeading msg={stepHeadingsEndorctl.stepEndorctlInit} count={3} />
        {/*Empty block to give a small margin from step number */}
        <>
          <Stack
            spacing={3}
            sx={{
              borderLeft: `2px solid ${palette.text.light}`,
              padding: '1rem 1rem 1.5rem 1rem',
              marginLeft: '0.75rem !important',
              width: '100%',
            }}
          >
            <Typography variant="body1">
              <FM defaultMessage="The initialization process is needed the first time you use endorctl in your machine. Please make your selection based on how you log into Endor Labs." />
            </Typography>

            <ToggleButtonGroup
              exclusive
              onChange={handleAuthModeChange}
              value={selectedAuthMode}
            >
              <ToggleButton color="primary" value={AuthMode.Google}>
                <FM defaultMessage="Google" />
              </ToggleButton>

              <ToggleButton color="primary" value={AuthMode.GitHub}>
                <FM defaultMessage="GitHub" />
              </ToggleButton>

              <ToggleButton color="primary" value={AuthMode.GitLab}>
                <FM defaultMessage="GitLab" />
              </ToggleButton>

              <ToggleButton color="primary" value={AuthMode.Email}>
                <FM defaultMessage="Email" />
              </ToggleButton>
            </ToggleButtonGroup>

            <CodeBlock value={getEndorctlInitCodeBlock(selectedAuthMode)} />

            <Link
              to={`${URL_ENDOR_DOCS}/endorctl/install-and-configure/#authenticate-to-endor-labs`}
              target="_blank"
            >
              <Stack
                alignItems="center"
                direction="row"
                fontSize="small"
                spacing={2}
                padding="6px 16px"
                sx={{ color: palette.brand.main }}
              >
                <IconExternalLink />

                <Typography variant="h6" sx={{ color: palette.brand.main }}>
                  <FM defaultMessage="How-to Guide" />
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </>
      </Stack>

      <Stack spacing={4} alignItems="flex-start">
        <StepHeading
          msg={stepHeadingsEndorctl.stepVerifyPrerequisites}
          count={4}
        />
        <>
          <Stack
            spacing={2}
            sx={{
              borderLeft: `2px solid ${palette.text.light}`,
              padding: '0.5rem 1rem',
              marginLeft: '0.75rem !important',
              width: '100%',
            }}
          >
            <List>
              <ListItem sx={{ padding: 0 }}>
                <ListItemText>
                  <Typography variant="body1">
                    <FM
                      defaultMessage="<a></a>Runtime environment for supported software development languages your team uses. See Supported languages and frameworks for more information <link>here</link>"
                      values={{
                        a: () => <span>&bull;&nbsp;&nbsp;</span>,
                        link: () => (
                          <Link
                            to={`${URL_ENDOR_DOCS}/scan-with-endorlabs/language-scanning/#complete-support-matrix`}
                            target="_blank"
                          >
                            here.
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem sx={{ padding: 0 }}>
                <ListItemText>
                  <Typography variant="body1">
                    <FM
                      defaultMessage="<a></a>A local installation of Git or the ability to clone repositories in CI. See the Git documentation for instructions on installing Git <link>here</link>"
                      values={{
                        a: () => <span>&bull;&nbsp;&nbsp;</span>,
                        link: () => (
                          <Link
                            to="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git"
                            target="_blank"
                          >
                            here.
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem sx={{ padding: 0 }}>
                <ListItemText>
                  <Typography variant="body1">
                    <FM
                      defaultMessage="<a></a>Access to private registries required to build the target project if necessary (e.g. Artifactory, etc.) can be found <link>here</link>"
                      values={{
                        a: () => <span>&bull;&nbsp;&nbsp;</span>,
                        link: () => (
                          <Link
                            to={`${URL_ENDOR_DOCS}/integrations/package-manager/`}
                            target="_blank"
                          >
                            here.
                          </Link>
                        ),
                      }}
                    />
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Stack>
        </>
      </Stack>

      <Stack spacing={4} alignItems="flex-start">
        <StepHeading msg={stepHeadingsEndorctl.stepEndorctlScan} count={5} />
        <>
          <Stack
            spacing={3}
            sx={{
              borderLeft: `2px solid ${palette.text.light}`,
              padding: '0.5rem 1rem',
              marginLeft: '0.75rem !important',
              width: '100%',
            }}
          >
            {/* Step 4 & 5 - MAC and Linux */}
            {/* Hide the below steps unless a binary is present */}
            {(selectedPlatform === SupportedOS.Linux ||
              selectedPlatform === SupportedOS.MacOS ||
              selectedPlatform === SupportedOS.Windows) &&
              hasEndorctlBinary && (
                <Stack spacing={3}>
                  <CodeBlock
                    value={getEndorctlScanCodeBlock(
                      'step1',
                      selectedPlatformSourceType
                    )}
                  />
                  <CodeBlock
                    value={getEndorctlScanCodeBlock(
                      'step2',
                      selectedPlatformSourceType
                    )}
                  />
                </Stack>
              )}

            <Stack
              direction="row"
              sx={{ color: palette.text.secondary }}
              spacing={2}
              alignItems="center"
            >
              <Typography variant="body1">
                <FM defaultMessage="Scan" />
              </Typography>
              <Select
                onChange={(e) => {
                  const lang = e.target.value as V1Language;
                  setLanguage(lang);
                }}
                value={language}
                label={language}
                size="small"
                sx={{
                  background: palette.primary.main,
                  color: palette.background.paper,
                  borderRadius: 16,
                  padding: '2px 16px',
                  '& .MuiSelect-select': {
                    padding: '2px',
                    width: 'auto',
                  },
                  '& .MuiSvgIcon-root': {
                    color: palette.background.paper,
                    fontSize: 16,
                  },
                  '&:hover': {
                    background: palette.primary.main,
                    color: palette.background.paper,
                  },
                }}
              >
                {SCAN_LANGUAGES.map((language) => {
                  return (
                    <MenuItem key={language.value} value={language.value}>
                      {language.label}
                    </MenuItem>
                  );
                })}
              </Select>

              <Typography variant="body1">
                <FM defaultMessage="Using" />
              </Typography>
              <Select
                onChange={(e) => {
                  const packageManager = e.target.value as string;
                  setSelctedPackageManager(packageManager);
                }}
                value={selectedPackageManager}
                label={selectedPackageManager}
                sx={{
                  background: palette.primary.main,
                  color: palette.background.paper,
                  borderRadius: 16,
                  padding: '2px 16px',
                  '& .MuiSelect-select': {
                    padding: '2px',
                    width: 'auto',
                  },
                  '& .MuiSvgIcon-root': {
                    color: palette.background.paper,
                    fontSize: 16,
                  },
                  '&:hover': {
                    background: palette.primary.main,
                    color: palette.background.paper,
                  },
                }}
              >
                {packageManagers &&
                  packageManagers?.map((p, index) => {
                    return (
                      <MenuItem key={`${p}-${index}`} value={p}>
                        <ListItemText
                          primary={p}
                          primaryTypographyProps={{ variant: 'body1' }}
                        />
                      </MenuItem>
                    );
                  })}
              </Select>
            </Stack>

            {selectedPackageManager && (
              <PackageManagerCodeBlock
                packageManager={selectedPackageManager as string}
              />
            )}

            {hasEndorctlBinary && (
              <CodeBlock
                value={getEndorctlScanCodeBlock(
                  'step3',
                  selectedPlatformSourceType
                )}
              />
            )}
            {(language === V1Language.Typescript ||
              language === V1Language.Js) && <PhantomDependencyCode />}
            {/* Step 4 & 5 - Windows */}
            {/* Hide the below steps unless a binary is present */}
            {selectedPlatform === SupportedOS.Windows && hasEndorctlBinary && (
              <CodeBlock value="endorctl scan --path=<insert path to cloned source code>" />
            )}
            <Link
              to={`${URL_ENDOR_DOCS}/scan-with-endorlabs/language-scanning/`}
              target="_blank"
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                padding="6px 16px"
                sx={{ color: palette.brand.main }}
              >
                <IconExternalLink />
                <Typography variant="h6">
                  <FM defaultMessage="How-to Guide" />
                </Typography>
              </Stack>
            </Link>
          </Stack>
        </>
      </Stack>
      <Link
        to={`${URL_ENDOR_DOCS}/troubleshooting/`}
        target="_blank"
        underline="none"
        paddingX={4}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ color: palette.brand.main }}
        >
          <IconExternalLink />
          <Typography variant="h6" sx={{ color: palette.brand.main }}>
            <FM defaultMessage="Troubleshooting Guide" />
          </Typography>
        </Stack>
      </Link>
    </Stack>
  );
};
