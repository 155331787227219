import {
  Autocomplete,
  Chip,
  ChipProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { get as _get } from 'lodash-es';
import { useCallback, useState } from 'react';
import {
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form';

import { LabelFieldHelperText } from '../LabelFieldHelperText';
import {
  getLabelsFieldValidationRules,
  LabelsFieldValidationType,
} from './utils';

export type ControlledLabelsFieldProps<TFieldValues extends FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render' | 'rules'
> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
  TextFieldProps & {
    chipProps?: ChipProps;
    options?: string[];
    validation?: LabelsFieldValidationType;
  };

/**
 * A controlled field for use in react-hook-form. Wraps MUI's Autocomplete to
 * provide a normalized interface for adding labels ("tags") in the UI.
 */
export const ControlledLabelsField = <T extends FieldValues>({
  chipProps,
  control,
  defaultValue = [] as PathValue<T, Path<T>>,
  helperText,
  inputProps,
  label,
  name,
  options = [],
  placeholder,
  validation,
  required,
  ...textFieldProps
}: ControlledLabelsFieldProps<T>) => {
  const requiredFieldRule = required
    ? { required: { value: true, message: 'This field is required' } }
    : {};

  const { rules, validateSingleValue } =
    getLabelsFieldValidationRules(validation);

  const { field, formState, fieldState } = useController({
    control,
    // NOTE: `name` prop is used for react-hook-form. Input `name` can be set with `htmlName`.
    name,
    defaultValue,
    rules: { ...rules, ...requiredFieldRule },
  });

  // track the input value, and add as a label on blur, if it does not already exist,
  // allowing the user to enter value, then tab to the save button.
  const [labelInput, setLabelInput] = useState('');
  const handleBlur = useCallback(() => {
    const labels: string[] = field.value ?? [];
    const cleanLabelInput = labelInput.trim();

    // // if the current text input is a new label, add it to the fields
    if (cleanLabelInput && !labels.includes(cleanLabelInput)) {
      const nextValues = labels.concat(cleanLabelInput);
      field.onChange(nextValues);
      field.onBlur();
      setLabelInput('');
    }
  }, [field, labelInput]);

  const isDirtyValue = (value: string) => {
    const tagList = _get(formState.defaultValues, name);
    if (tagList?.includes(value)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Autocomplete
      disabled={textFieldProps.disabled}
      id={`${name}-autocomplete`}
      onChange={(_, value: string[]) => field.onChange(value)}
      onBlur={handleBlur}
      inputValue={labelInput}
      onInputChange={(_, value) => setLabelInput(value)}
      // NOTE: `multiple`, combined with `freeSolo`, allows for adding arbitrary values ("tags")
      // https://mui.com/material-ui/react-autocomplete/#free-solo
      multiple
      freeSolo
      fullWidth
      options={options}
      disableClearable // NOTE: prevents clearing _all_ tags
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          // NOTE: `key` provided from `getTagProps()`
          // eslint-disable-next-line react/jsx-key
          <Chip
            {...getTagProps({ index })}
            color={validateSingleValue(option) === true ? 'default' : 'error'}
            label={option}
            size="small"
            sx={{ fontWeight: isDirtyValue(option) ? 'bold' : 'normal' }}
            {...chipProps}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...textFieldProps}
          {...params}
          error={Boolean(fieldState.error)}
          id={`${name}-input`}
          inputProps={{ ...params.inputProps, ...inputProps }}
          inputRef={field.ref}
          label={label}
          placeholder={placeholder}
          helperText={
            <LabelFieldHelperText
              fieldState={fieldState}
              helperText={helperText}
            />
          }
          variant="standard"
        />
      )}
      sx={({ spacing }) => ({
        display: textFieldProps.type === 'hidden' ? 'none' : 'auto',

        // apply styles to the nested input
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
          // prevent the height from jumping when a chip is added
          minHeight: spacing(8),
          // force the input to wrap when close to the end
          minWidth: spacing(32),
        },
      })}
      value={field.value}
    />
  );
};
