import { useMemo } from 'react';

import {
  QueryProjectToolsJoinFilters,
  sortParamBuilders,
  useCountProjects,
  useQueryProjectTools,
} from '@endorlabs/queries';
import { DataTablePaginator } from '@endorlabs/ui-common';

import { mapToCiCdToolsTableRows } from '../utils';
import { useCiCdToolsCount } from './useCiCdToolsCount';

export const useCiCdToolsIndexPageData = ({
  baseFilter,
  namespace,
  paginator,
  queryFilters,
}: {
  namespace: string;
  paginator: DataTablePaginator;
  baseFilter: string;
  queryFilters: QueryProjectToolsJoinFilters[];
}) => {
  // get TOTAL count of projects
  const qCountProjectTools = useCountProjects(
    namespace,
    {
      staleTime: Infinity,
    },
    { filter: baseFilter }
  );

  const qQueryProjectTools = useQueryProjectTools(
    namespace,
    {
      ...paginator.getListParameters(),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    },
    {
      filters: queryFilters,
    }
  );

  const cicdTools = useMemo(() => {
    return mapToCiCdToolsTableRows(namespace, qQueryProjectTools.data);
  }, [qQueryProjectTools.data, namespace]);

  const qToolCount = useCiCdToolsCount({ namespace });

  return {
    ...qQueryProjectTools,
    cicdTools,
    projectCount: qCountProjectTools.data?.count ?? 0,
    toolCount: qToolCount.count,
  };
};
