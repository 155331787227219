import { SpecFindingLevel, V1GroupResponse } from '@endorlabs/api_client';

type FindingLevelKeys = 'Critical' | 'High' | 'Medium' | 'Low';

const specLevelMap: Record<string, FindingLevelKeys> = {
  [SpecFindingLevel.Critical]: 'Critical',
  [SpecFindingLevel.High]: 'High',
  [SpecFindingLevel.Medium]: 'Medium',
  [SpecFindingLevel.Low]: 'Low',
};

type FindingCountsByLevel = Record<FindingLevelKeys, number>;

type FindingsByLevelChartData = FindingCountsByLevel & Record<'name', string>;

export const buildFindingsByLevelChartData = (
  findingGroups?: V1GroupResponse['groups']
): FindingsByLevelChartData[] => {
  const chartData: FindingsByLevelChartData[] = [];

  if (findingGroups) {
    const findingGroupValues = Object.values(findingGroups);

    findingGroupValues.forEach((item) => {
      const findingsObj: FindingCountsByLevel = {
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 0,
      };

      const level = item.unique_values?.['spec.level']?.[0] as unknown as
        | string
        | undefined;

      const versionRef = item.unique_values?.[
        'spec.source_code_version.ref'
      ]?.[0] as unknown as string | undefined;

      if (level && versionRef) {
        const findingGroupData = chartData.find((d) => d.name === versionRef);

        const findingLevelKey = specLevelMap[
          level
        ] as keyof FindingCountsByLevel;

        if (findingGroupData) {
          findingGroupData[findingLevelKey] =
            item.aggregation_count?.count ?? 0;
        } else {
          chartData.push({
            name: versionRef,
            ...findingsObj,
            [findingLevelKey]: item.aggregation_count?.count ?? 0,
          });
        }
      }
    });
  }

  return chartData;
};
