import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { ResourceFilter } from '@endorlabs/filters';

import { FindingLevelToggles } from '../FindingLevelToggles';
import { ControlsFacetFilterDatePicker } from './ControlsFacetFilterDatePicker';
import { ControlsFacetFilterFindingAttributes } from './ControlsFacetFilterFindingAttributes';
import { ControlsFacetFilterFindingEpssProbability } from './ControlsFacetFilterFindingEpssProbability';
import { ControlsFacetFilterMultiSelect } from './ControlsFacetFilterMultiSelect';
import { ControlsFacetFilterSelect } from './ControlsFacetFilterSelect';
import { ControlsFacetFilterToggle } from './ControlsFacetFilterToggle';
import { FacetFilterDefinition } from './types';
import { buildFacetFilterValueMap, getNextFacetFilters } from './utils';

export interface ControlsFacetFilterProps {
  facets: FacetFilterDefinition[];
  filters?: ResourceFilter[];
  onChange: (filters: ResourceFilter[]) => void;
}

export const ControlsFacetFilter = ({
  facets,
  filters,
  onChange,
}: ControlsFacetFilterProps) => {
  // rebuild values on filter change
  const facetFilterValues = useMemo(
    () => buildFacetFilterValueMap(facets, filters),
    [facets, filters]
  );

  // update filters on facet value change
  const handleFacetValueChange =
    (facet: FacetFilterDefinition) => (value: any) => {
      const nextFilters = getNextFacetFilters(
        facetFilterValues,
        facets,
        facet,
        value
      );
      onChange(nextFilters);
    };

  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      justifyContent="end"
      spacing={2}
    >
      {facets.map(
        (facet) =>
          // determine which control type to show, based on the prescence of values
          facet.control === 'FacetFilterToggle' ? (
            <ControlsFacetFilterToggle
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : facet.control === 'FacetFilterSelect' ? (
            <ControlsFacetFilterSelect
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : facet.control === 'FacetFilterMultiSelect' ? (
            <ControlsFacetFilterMultiSelect
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : facet.control === 'FacetFilterFindingAttributes' ? (
            <ControlsFacetFilterFindingAttributes
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : facet.control === 'FacetFilterDatePicker' ? (
            <ControlsFacetFilterDatePicker
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : facet.control === 'FacetFilterFindingLevel' ? (
            <FindingLevelToggles
              key={facet.id}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
              allowEmptyValue
            />
          ) : facet.control === 'FacetFilterFindingEpssProbability' ? (
            <ControlsFacetFilterFindingEpssProbability
              key={facet.id}
              facet={facet}
              value={facetFilterValues[facet.id]}
              onChange={handleFacetValueChange(facet)}
            />
          ) : null // no matching control
      )}
    </Stack>
  );
};
