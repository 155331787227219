import { useState } from 'react';

export type FileDownloadFileType =
  | 'csv'
  | 'go'
  | 'json'
  | 'rego'
  | 'text'
  | 'xml'
  | 'yaml';

const getMimeType = (fileType: FileDownloadFileType = 'text') => {
  switch (fileType) {
    case 'csv':
      return 'text/csv';
    case 'go':
      return 'text/x-go';
    case 'json':
      return 'application/json';
    case 'xml':
      return 'application/xml';
    case 'yaml':
      return 'application/x-yaml';
    case 'text':
    default:
      return 'text/plain';
  }
};

export type UseFileDownloadProps = {
  filename: string;
  filetype: FileDownloadFileType;
};

export function useFileDownload(
  props: UseFileDownloadProps
): [
  boolean,
  (data: string, overrides?: Partial<UseFileDownloadProps>) => void,
  (value: boolean) => void
] {
  const [isDownloaded, setIsDownloaded] = useState(false);

  const downloadData = (
    data = '',
    overrides?: Partial<UseFileDownloadProps>
  ) => {
    const { filename, filetype } = { ...props, ...overrides };

    const blob = new Blob([data], {
      type: getMimeType(filetype),
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.download = filename;
    link.click();

    window.URL.revokeObjectURL(url);
    setIsDownloaded(true);
  };

  return [isDownloaded, downloadData, setIsDownloaded];
}
