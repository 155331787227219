import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Grid, MenuItem, Stack } from '@mui/material';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { ReactNode } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1ExportedSBOM, V1ExportedVEX } from '@endorlabs/api_client';
import {
  IQueryErrorResponse,
  PackageVersionResource,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ControlledTextField,
  IconAlertTriangle,
  IconDownload,
  Link,
} from '@endorlabs/ui-common';

import { getSettingsPath } from '../../../../routes';
import {
  SBOM_COMPONENT_TYPE_OPTIONS,
  SBOM_FORMAT_OPTIONS,
  SBOM_KIND_OPTIONS,
} from './constants';
interface ServerFieldErrorMessage extends ErrorOption {
  detail?: ReactNode;
}
interface ServerFieldError<KField extends string> {
  field?: KField;
  object: ServerFieldErrorMessage;
}

export type FormCreateSBOMExportFieldValues = Pick<
  V1ExportedSBOM,
  'spec' | 'meta'
>;
export type FormCreateVEXExportFieldValues = Pick<
  V1ExportedVEX,
  'spec' | 'meta'
>;

export const translateServerErrorSBOM = (
  error: IQueryErrorResponse,
  props: Pick<FormCreateExportedSBOMProps, 'namespace'>
): ServerFieldError<'spec.kind'> => {
  switch (error.status) {
    case 404:
      return {
        object: {
          type: 'server',
          message: 'Failed to Create SBOM Export: Missing SBOM Settings',
          detail: (
            <FM
              defaultMessage="Ensure <link>CycloneDX SBOM Settings</link> have been configured, and try again."
              values={{
                link: (value) => (
                  <Link
                    to={getSettingsPath({
                      tenantName: props.namespace,
                      section: 'system',
                    })}
                  >
                    {value}
                  </Link>
                ),
              }}
            />
          ),
        },
      };
    case 412:
      return {
        object: {
          type: 'server',
          message: 'Failed to Create SBOM Export: Missing Package Version',
          detail:
            'The SBOM you are requesting does not have the package version scanned yet. Please retry after the package version has been scanned.',
        },
      };
    case 504:
      return {
        object: {
          type: 'server',
          message: 'Failed to Create SBOM Export: Timeout',
          detail: 'Please try again, or contact Endor Labs for support.',
        },
      };
    default:
      return {
        object: {
          type: 'server',
          message: 'An error occurred',
          detail: error.data?.message,
        },
      };
  }
};
export const translateServerErrorVEX = (
  error: IQueryErrorResponse,
  props: Pick<FormCreateExportedSBOMProps, 'namespace'>
): ServerFieldError<'spec.kind'> => {
  switch (error.status) {
    case 504:
      return {
        object: {
          type: 'server',
          message: 'Failed to Create VEX Export: Timeout',
          detail: 'Please try again, or contact Endor Labs for support.',
        },
      };
    default:
      return {
        object: {
          type: 'server',
          message: 'An unknown error occurred',
        },
      };
  }
};

export interface FormCreateExportedSBOMProps {
  namespace: string;
  onCancel?: () => void;
  isLoadingPackageVersions?: boolean;

  isLoadingSBOM?: boolean;
  onSubmitSBOM: (data: FormCreateSBOMExportFieldValues) => void;
  serverSBOMErrorResponse?: IQueryErrorResponse;

  isLoadingVEX?: boolean;
  onSubmitVEX: (data: FormCreateVEXExportFieldValues) => void;
  serverVEXErrorResponse?: IQueryErrorResponse;

  packageVersions?: PackageVersionResource[];
}

export const FormCreateExportedSBOM = forwardRef<
  HTMLFormElement,
  FormCreateExportedSBOMProps
>(function FormCreateExportedSBOM(
  {
    namespace,
    onCancel,
    isLoadingPackageVersions,
    isLoadingSBOM,
    onSubmitSBOM,
    serverSBOMErrorResponse,
    isLoadingVEX,
    onSubmitVEX,
    serverVEXErrorResponse,
    packageVersions,
  }: FormCreateExportedSBOMProps,
  ref
) {
  const { control, handleSubmit, setError } =
    useForm<FormCreateSBOMExportFieldValues>();

  const { formatMessage: fm } = useIntl();

  const [localError, setLocalError] = useState<
    ServerFieldErrorMessage | undefined
  >();
  useEffect(() => {
    // handle server error
    if (serverSBOMErrorResponse) {
      const { field, object } = translateServerErrorSBOM(
        serverSBOMErrorResponse,
        {
          namespace,
        }
      );
      if (field) {
        setError(field, object);
      } else {
        setLocalError(object);
      }
    } else if (serverVEXErrorResponse) {
      const { field, object } = translateServerErrorVEX(
        serverVEXErrorResponse,
        {
          namespace,
        }
      );
      if (field) {
        setError(field, object);
      } else {
        setLocalError(object);
      }
    } else {
      setLocalError(undefined);
    }
  }, [namespace, serverSBOMErrorResponse, serverVEXErrorResponse, setError]);

  // optionally, process the form data before passing it up to the parent
  const wrappedOnSubmitSBOM = useCallback(
    (SBOMfieldValues: FormCreateSBOMExportFieldValues) => {
      onSubmitSBOM(SBOMfieldValues);
    },
    [onSubmitSBOM]
  );
  const wrappedOnSubmitVEX = useCallback(
    (SBOMfieldValues: FormCreateSBOMExportFieldValues) => {
      const VEXfieldValues = SBOMfieldValues as any;
      onSubmitVEX(VEXfieldValues);
    },
    [onSubmitVEX]
  );

  return (
    <form
      id="FormCreateSBOMExport"
      onSubmit={handleSubmit(wrappedOnSubmitSBOM)}
      ref={ref}
    >
      <Grid container direction="column" item spacing={6}>
        {packageVersions && (
          <Grid item>
            <ControlledTextField
              control={control}
              defaultValue={packageVersions[0].uuid}
              name="meta.parent_uuid"
              variant="standard"
              select
              autoComplete="off"
              fullWidth
              label={fm({
                defaultMessage: 'PACKAGE',
              })}
              rules={{
                required: fm({
                  defaultMessage: 'A package selection is required',
                }),
              }}
            >
              {packageVersions.map(({ meta, uuid }) => (
                <MenuItem key={uuid} value={uuid}>
                  {meta.name}
                </MenuItem>
              ))}
            </ControlledTextField>
          </Grid>
        )}

        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={SBOM_KIND_OPTIONS[0].value}
            name="spec.kind"
            variant="standard"
            select
            autoComplete="off"
            fullWidth
            label={fm({
              defaultMessage: 'CHOOSE A CONTAINER FORMAT',
            })}
            rules={{
              required: fm({
                defaultMessage: 'A container format is required',
              }),
            }}
          >
            {SBOM_KIND_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>

        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={SBOM_COMPONENT_TYPE_OPTIONS[0].value}
            name="spec.component_type"
            variant="standard"
            select
            autoComplete="off"
            fullWidth
            label={fm({
              defaultMessage: 'EXPORT AS AN APPLICATION OR LIBRARY?',
            })}
            rules={{
              required: fm({
                defaultMessage: 'A component type is required',
              }),
            }}
          >
            {SBOM_COMPONENT_TYPE_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>

        <Grid item>
          <ControlledTextField
            control={control}
            defaultValue={SBOM_FORMAT_OPTIONS[0].value}
            name="spec.format"
            variant="standard"
            select
            autoComplete="off"
            fullWidth
            label={fm({
              defaultMessage: 'FILE FORMAT',
            })}
            rules={{
              required: fm({
                defaultMessage: 'A file format is required',
              }),
            }}
          >
            {SBOM_FORMAT_OPTIONS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </ControlledTextField>
        </Grid>

        <Grid item marginTop={4}>
          <Stack direction="row" spacing={4}>
            <LoadingButton
              disabled={isLoadingPackageVersions}
              type="submit"
              startIcon={<IconDownload />}
              loading={isLoadingSBOM}
              color="primary"
              variant="contained"
              onClick={handleSubmit(wrappedOnSubmitSBOM)}
            >
              <FM defaultMessage="Export SBOM" />
            </LoadingButton>

            <LoadingButton
              disabled={isLoadingPackageVersions}
              type="submit"
              startIcon={<IconDownload />}
              loading={isLoadingVEX}
              color="primary"
              variant="contained"
              onClick={handleSubmit(wrappedOnSubmitVEX)}
            >
              <FM defaultMessage="Export VEX" />
            </LoadingButton>

            {onCancel && <ButtonCancel onClick={onCancel} />}
          </Stack>
        </Grid>

        {localError && (
          <Grid item>
            <Alert severity="error" icon={<IconAlertTriangle />}>
              <AlertTitle>{localError.message}</AlertTitle>

              {localError.detail ?? null}
            </Alert>
          </Grid>
        )}
      </Grid>
    </form>
  );
});
