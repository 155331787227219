import { Card, CardContent, CardHeader, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { pick as _pick } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType, V1Policy } from '@endorlabs/api_client';
import {
  PolicyResource,
  useCreatePolicy,
  useDeletePolicy,
  useUpdatePolicy,
} from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ExclusiveToggleButtonGroup,
  useConfirmationDialog,
  useExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import { FacetedSearchBar, useFacetedSearchBar } from '../../../components';
import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { AdmissionPolicyFacets } from '../constants/filters';
import { usePoliciesAndRelatedProjects } from '../hooks';
import { usePolicyScanResults } from '../hooks/usePoliciesAndScanResults';
import { AdmissionPoliciesView as messages } from '../locales';
import {
  AdmissionPoliciesTable,
  AdmissionPoliciesTableRow,
} from './AdmissionPoliciesTable';
import { PolicyTemplatesView } from './PolicyTemplatesView';

export const AdmissionPoliciesView = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();

  type TableViews = 'policies' | 'templates';
  const { getToggleButtonGroupProps, value: selectedTable } =
    useExclusiveToggleButtonGroup<TableViews>('policies', [
      { value: 'policies', children: <FM defaultMessage="Policies" /> },
      { value: 'templates', children: <FM defaultMessage="Templates" /> },
    ]);

  const { getFacetedSearchBarProps, filters, searchValue } =
    useFacetedSearchBar({
      resourceKind: 'Policy',
    });

  const { getProjectCountForPolicy, policies, totalPolicyCount } =
    usePoliciesAndRelatedProjects({
      // Different policy types require different filters
      filters: filters.filter(
        (f) => !['spec.disable', 'spec.finding.level'].includes(f.key)
      ),
      namespace: tenantName,
      listParameters: {
        filter: `spec.policy_type in [${PolicyPolicyType.Admission}, ${PolicyPolicyType.Notification}]`,
        page_size: 500,
      },
      searchValue,
    });

  const { getLastScanTimeForPolicy, getTriggerCountForPolicy } =
    usePolicyScanResults({ namespace: tenantName, policies });

  const qCreatePolicy = useCreatePolicy();

  /** DELETION */
  const qPolicyDelete = useDeletePolicy();

  const PolicyDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,
    descriptionText: (
      <FM defaultMessage="Notifications will no longer be sent for this policy." />
    ),
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qPolicyDelete.mutate({
          namespace: props.namespace,
          uuid: props.uuid,
        });
      }
    },
    titleText: <FM defaultMessage="Delete this Policy?" />,
  });

  /** UPDATE */
  const qPolicyEdit = useUpdatePolicy();

  // NOTE: navigating to the policy under the policy namespace
  const onPolicyEdit = ({
    namespace,
    uuid,
  }: {
    namespace: string;
    uuid: string;
  }) =>
    navigate({
      to: getPoliciesPath({
        tenantName: namespace,
        section: `actions/edit/${uuid}`,
      }),
    });

  const onPolicyClone = ({ uuid }: { uuid: string }) => {
    const policyToBeCloned = policies.filter((p) => p.uuid === uuid)[0];
    const policyName = policyToBeCloned.meta.name;
    const cloneCount =
      policies.filter((p) => p.meta.name.includes(policyName))?.length || 1;
    const newPolicy: PolicyResource = _pick(policyToBeCloned, [
      'meta.name',
      'meta.description',
      'meta.tags',
      'spec.admission',
      'spec.disable',
      'spec.notification',
      'spec.policy_type',
      'spec.project_exceptions',
      'spec.project_selector',
      'spec.template_values',
      'spec.template_uuid',
    ]) as PolicyResource;

    // Added to count to avoid duplicate of policy names in listing
    if (newPolicy?.meta?.name) {
      newPolicy.meta.name = `${policyName}-${cloneCount}`;
    }

    // NOTE: policy wiill be created in the active namespace
    qCreatePolicy.mutate({ namespace: tenantName, resource: newPolicy });
  };

  const onPolicyDisable = useCallback(
    ({ uuid }: { uuid: string }) => {
      const policy = policies.find((policy) => policy.uuid === uuid);

      if (!policy || !policy.tenant_meta?.namespace) return;

      const updatedPolicy = {
        spec: { disable: !policy.spec.disable },
        uuid: policy.uuid,
      };

      qPolicyEdit.mutate({
        mask: 'spec.disable',
        namespace: policy.tenant_meta.namespace,
        resource: updatedPolicy as V1Policy,
      });
    },
    [policies, qPolicyEdit]
  );

  const buildPolicyTableRows = (
    policies: PolicyResource[]
  ): AdmissionPoliciesTableRow[] => {
    return policies.map((policy) => {
      return {
        description: policy.meta.description,
        disable: policy.spec.disable,
        labels: policy.meta.tags,
        lastTriggered: getLastScanTimeForPolicy(policy.uuid),
        name: policy.meta.name,
        namespace: policy.tenant_meta.namespace,
        policyAction:
          policy.spec.policy_type === PolicyPolicyType.Admission ? (
            policy.spec.admission?.disable_enforcement ? (
              <FM defaultMessage="Warn" />
            ) : (
              <FM defaultMessage="Block" />
            )
          ) : (
            <FM defaultMessage="Notify" />
          ),
        projectCount: getProjectCountForPolicy(policy.uuid),
        triggerCount: getTriggerCountForPolicy(policy.uuid),
        uuid: policy.uuid,
      };
    });
  };

  const policyTableRows = buildPolicyTableRows(policies as PolicyResource[]);

  const headerMessage =
    Boolean(searchValue) || filters.length > 0 ? (
      <FM
        {...messages.headerWithSearch}
        values={{
          policyCount: policyTableRows.length,
          totalPolicyCount,
        }}
      />
    ) : (
      <FM
        {...messages.headerWithoutSearch}
        values={{
          policyCount: policyTableRows.length,
          totalPolicyCount,
        }}
      />
    );

  return (
    <Stack spacing={space.md}>
      <ExclusiveToggleButtonGroup {...getToggleButtonGroupProps()} />

      {selectedTable === 'policies' && (
        <>
          {totalPolicyCount > 0 && (
            <FacetedSearchBar
              {...getFacetedSearchBarProps()}
              facets={AdmissionPolicyFacets}
            />
          )}

          <Card>
            <CardHeader
              action={
                <ButtonLinkPrimary
                  linkProps={{
                    to: getPoliciesPath({ tenantName, section: `actions/new` }),
                  }}
                >
                  <FM defaultMessage="Create Action Policy" />
                </ButtonLinkPrimary>
              }
              title={headerMessage}
            />
            <CardContent>
              <AdmissionPoliciesTable
                data={policyTableRows}
                emptyStateProps={{
                  title: <FM defaultMessage="No Policies Found" />,
                }}
                onEdit={onPolicyEdit}
                onDelete={PolicyDeleteConfirmation.openDialog}
                onClone={onPolicyClone}
                onDisable={onPolicyDisable}
              />
            </CardContent>
          </Card>

          <PolicyDeleteConfirmation.Dialog
            {...PolicyDeleteConfirmation.dialogProps}
          />
        </>
      )}

      {selectedTable === 'templates' && (
        <PolicyTemplatesView
          policyTypes={[
            PolicyPolicyType.Admission,
            PolicyPolicyType.Notification,
          ]}
        />
      )}
    </Stack>
  );
};
