import { Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { TenantType } from '@endorlabs/endor-core/auth';
import { TenantInfo } from '@endorlabs/queries';
import { Link } from '@endorlabs/ui-common';

import { getEnv, Layout } from '../../constants';
import { EndorSalesLink } from '../Links';
import {
  LICENSE_PERIOD_EXPIRY_DISPLAY_IN_BANNER,
  LICENSE_PERIOD_EXPIRY_INVALID,
} from './constants';

type BannerProps = {
  isFreeTrialTenant: boolean;
  isPremiumTenant: boolean;
  isLicenseAndBundlingEnabled: boolean;
  isPageAccessAllowed: boolean;
  remainingPeriodToExpiry: number;
  tenantInfo: TenantInfo | undefined;
};

const Banner: React.FC<BannerProps> = ({
  isFreeTrialTenant,
  isPremiumTenant,
  isLicenseAndBundlingEnabled,
  isPageAccessAllowed,
  remainingPeriodToExpiry,
  tenantInfo,
}: BannerProps) => {
  const theme = useTheme();
  const sx = styles(useTheme());

  // if (premiumBannerInvalidTime) return <></>;
  const { definedMessage, upgradeMessage, isLink } = useMemo(() => {
    const tenantType = tenantInfo?.tenantType;
    let message: string | null = null,
      upgradeMessage: string | null = null;
    let showLink: boolean = false;

    if (isFreeTrialTenant && tenantType === TenantType.Trial) {
      message = `You have ${remainingPeriodToExpiry} days remaining in your free trial.`;
      showLink = true;
    } else if (isFreeTrialTenant && tenantType === TenantType.TrialExpired) {
      message = `Your free trial is over, but your time with Endor Labs doesn't have to end!`;
      showLink = true;
    } else if (
      isLicenseAndBundlingEnabled &&
      isPremiumTenant &&
      remainingPeriodToExpiry !== LICENSE_PERIOD_EXPIRY_INVALID &&
      remainingPeriodToExpiry <= LICENSE_PERIOD_EXPIRY_DISPLAY_IN_BANNER
    ) {
      message = `You have ${remainingPeriodToExpiry} days remaining for your license to expire.`;
      showLink = true;
    }
    if (
      isLicenseAndBundlingEnabled &&
      isPremiumTenant &&
      !isPageAccessAllowed
    ) {
      upgradeMessage = `Premium Feature - Please Contact Sales to Upgrade. `;
    }

    return { definedMessage: message, upgradeMessage, isLink: showLink };
  }, [
    tenantInfo?.tenantType,
    isFreeTrialTenant,
    isLicenseAndBundlingEnabled,
    isPremiumTenant,
    remainingPeriodToExpiry,
    isPageAccessAllowed,
  ]);

  if (!definedMessage && !upgradeMessage) return null;

  const { URL_ENDOR_SALES } = getEnv();

  return (
    <Stack spacing={1} direction="column">
      {definedMessage && (
        <Paper
          elevation={3}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}` }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingY={3}
            spacing={1}
            sx={{ background: theme.palette.status.pending }}
            width="100%"
          >
            <Typography sx={sx.trialContainer}>{definedMessage}</Typography>
            {isLink && <EndorSalesLink />}
          </Stack>
        </Paper>
      )}
      {upgradeMessage && (
        <Paper
          elevation={3}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}` }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingY={3}
            spacing={1}
            sx={{ background: theme.palette.status.pending }}
            width="100%"
          >
            <Typography sx={sx.trialContainer}>
              <FM
                defaultMessage="Premium Feature. Please {salesLink}."
                values={{
                  salesLink: (
                    <Link to={URL_ENDOR_SALES} target="_blank">
                      contact sales to upgrade
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Stack>
        </Paper>
      )}

      {/* {upgradeMessage && (
        <Paper
          elevation={3}
          sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}` }}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingY={3}
            spacing={1}
            width="100%"
            sx={{ background: theme.palette.status.pending }}
          >
            <Pip
              color="warning"
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.error}`,
              }}
              label={<FM defaultMessage="Premium Features" />}
            />
            <Typography sx={sx.trialContainer}>
              <FM
                defaultMessage="Please {salesLink} to continue using."
                values={{
                  salesLink: (
                    <Link to={URL_ENDOR_SALES} target="_blank">
                      contact sales to upgrade
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Stack>
        </Paper>
      )} */}
    </Stack>
  );
};

function styles({ spacing, breakpoints }: Theme) {
  return {
    trialContainer: {
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        marginLeft: spacing(Layout.APP_DRAWER_WIDTH_MIN),
      },
    },
  };
}

export default Banner;
