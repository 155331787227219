import { Theme, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

import { V1ScoreCategory } from '@endorlabs/api_client';

import { NumberDisplay } from '../formats/';
import { ScoreDisplayBar } from './ScoreDisplayBar';
import { ScoreDisplayFactor } from './ScoreDisplayFactor';

export interface ScoreDisplayProps {
  category?: string;
  colorize?: boolean;
  nilDisplay?: ReactNode;
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  value?: number;
  variant?: 'default' | 'factor' | 'summary' | 'bar';
  zeroDisplay?: ReactNode;
}

/**
 * Displays a metric score for a package, repository, etc.
 * Automatically color-codes display based on score value.
 *
 * Has three variants:
 * * `default`: Standard display.
 * * `factor`: Positive/negative factor (e.g. +2, -1)
 * * `bar`: Display as mini bar graph
 * * `summary`: Special, larger, treatment for composite scores
 */
export const ScoreDisplay = ({
  category,
  colorize = false,
  nilDisplay = '-',
  size = 'medium',
  value,
  variant = 'default',
  zeroDisplay = <NumberDisplay value={0} />,
}: ScoreDisplayProps) => {
  const sx = styles(useTheme());

  const isModifier = variant === 'factor';
  const isNil = value === undefined;
  const isSummary = variant === 'summary';

  const displayValue = useMemo(() => {
    if (variant === 'bar') {
      return (
        <ScoreDisplayBar category={category as V1ScoreCategory} value={value} />
      );
    }

    if (variant === 'factor') {
      return (
        <ScoreDisplayFactor
          category={category as V1ScoreCategory}
          value={value}
        />
      );
    }

    if (isNil) return nilDisplay;

    if (value === 0) return zeroDisplay;

    return Math.round(value);
  }, [category, isNil, nilDisplay, value, variant, zeroDisplay]);

  const cls = useMemo(() => {
    return clsx({
      [variant]: true,
      colorized: colorize,
      nil: isNil,
      scoreBad: !isNil && value <= 3,
      scoreAverage: !isNil && value > 3 && value <= 7,
      scoreGood: !isNil && value > 7,
      [`score-${Math.floor(value as number)}`]: value !== undefined,
      summary: isSummary,
      factorBad: value && value < 0,
      factorGood: isModifier && value && value > 0,
      factorNeutral: isModifier && value === 0,
      small: size === 'small',
      medium: size === 'medium',
      large: size === 'large',
      xlarge: size === 'xlarge',
    });
  }, [colorize, isModifier, isNil, isSummary, size, value, variant]);

  return (
    <Typography component="span" className={cls} sx={sx.root}>
      {displayValue}
    </Typography>
  );
};

function styles({ palette, typography }: Theme) {
  return {
    root: {
      fontWeight: 500,

      '&.nil, &.nil.colorized': {
        color: palette.text.secondary,
      },
      '&.colorized': {
        '&.scoreBad': {
          color: palette.scores.bad,
        },
        '&.scoreAverage': {
          color: palette.scores.average,
        },
        '&.scoreGood': {
          color: palette.scores.good,
        },
      },

      '&.small': {
        ...typography.body2,
      },
      '&.large': {
        ...typography.h3,

        '&.summary': { ...typography.xl },
      },
      '&.xlarge': {
        ...typography.h1,

        '&.summary': { ...typography.xxl },
      },
    },
  };
}
