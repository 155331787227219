import { uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  tryParseGroupResponseAggregationKey,
  useListMetrics,
} from '@endorlabs/queries';

export const useCiCdToolsCount = ({ namespace }: { namespace: string }) => {
  const qToolGroups = useListMetrics(
    namespace,
    {},
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `meta.name==version_cicd_tools`,
      ]),
      group: {
        aggregation_paths:
          'spec.metric_values.CiCdTools.ci_cd_tools.tools.name',
      },
    }
  );

  const count = useMemo(() => {
    const allTools = Object.keys(
      qToolGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, key) => {
      const values = tryParseGroupResponseAggregationKey(key);
      const projectToolNames = values.find(
        (kv) => kv.key === 'spec.metric_values.CiCdTools.ci_cd_tools.tools.name'
      )?.value as string[] | undefined;
      if (projectToolNames) {
        acc.push(...projectToolNames);
      }
      return acc;
    }, [] as string[]);

    const uniqueTools = _uniq(allTools);
    return uniqueTools.length;
  }, [qToolGroups.data?.group_response?.groups]);

  return {
    count,
    isLoading: qToolGroups.isLoading,
  };
};
