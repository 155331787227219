import {
  Box,
  FormLabel,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { V1FindingTags } from '@endorlabs/api_client';
import {
  ButtonCancel,
  ControlledTextField,
  FindingTagLabel,
} from '@endorlabs/ui-common';

const FINDING_REACHABILITY_OPTIONS: V1FindingTags[] = [
  V1FindingTags.ReachableFunction,
  V1FindingTags.PotentiallyReachableFunction,
];

export const FindingReachabilityFields = () => {
  const { space } = useTheme();

  const { control, reset } = useFormContext();

  return (
    <Stack direction="column" spacing={space.md} component="fieldset">
      <FormLabel component="legend">
        <Typography variant="body1" color="text.primary">
          <FM defaultMessage="Customize Finding Reachability accordingly for your AppSec program." />
        </Typography>
      </FormLabel>

      <Stack direction="row" spacing={space.sm} alignItems="flex-start">
        <ControlledTextField
          control={control}
          defaultValue={FINDING_REACHABILITY_OPTIONS}
          label={<FM defaultMessage="Finding Reachability" />}
          name="findingReachabilityTags"
          select
          SelectProps={{ multiple: true }}
          required
        >
          {FINDING_REACHABILITY_OPTIONS.map((value) => (
            <MenuItem key={value} value={value}>
              <FindingTagLabel findingTag={value} />
            </MenuItem>
          ))}
        </ControlledTextField>

        <Box paddingTop={space.sm}>
          <ButtonCancel onClick={() => reset()}>
            <FM defaultMessage="Reset" />
          </ButtonCancel>
        </Box>
      </Stack>
    </Stack>
  );
};
