import {
  AuthorizationPolicyIdentityClaim,
  AuthorizationPolicyIdentitySource,
  AuthorizationPolicyIdentitySourceType,
  AuthorizationPolicyResource,
  getAuthorizationPolicyPermissionRoles,
  isAuthorizationPolicyIdentitySourceType,
  parseAuthorizationPolicyClause,
} from '@endorlabs/endor-core/AuthorizationPolicy';
import { IdentityProviderResource } from '@endorlabs/queries';

import { REGEX_DATE_ISO_VALIDATION } from '../../../../constants';
import { DEFAULT_IDENTITY_CLAIMS } from './constants';
import { FormUpsertAuthorizationPolicyFieldValues } from './types';

const buildAuthorizationPolicyIdentityClaimsClause = (
  identityClaims: AuthorizationPolicyIdentityClaim[],
  identitySource?: AuthorizationPolicyIdentitySource
): string[] => {
  // rebuild the clause from the claims
  return identityClaims.map(({ prefix, value }) => {
    let claim = [prefix, value].map((v) => v.trim()).join('=');

    // NOTE: identity source is appended to user claims
    if (identitySource && prefix == 'user') {
      claim += `@${identitySource.value}`;
    }

    return claim;
  });
};

const buildAuthorizationPolicyName = (
  namespace: string,
  identityClaims: AuthorizationPolicyIdentityClaim[],
  identitySource?: AuthorizationPolicyIdentitySource
) => {
  const claimsClause = buildAuthorizationPolicyIdentityClaimsClause(
    identityClaims,
    identitySource
  );

  return `Auth Policy for User: ${claimsClause} and Tenant: ${namespace}`;
};

export const fromAuthPolicyResourceModel = (
  namespace: string,
  model?: AuthorizationPolicyResource,
  identityProvider?: IdentityProviderResource
): FormUpsertAuthorizationPolicyFieldValues => {
  let identityClaims = DEFAULT_IDENTITY_CLAIMS;
  let identitySourceType = undefined;

  if (model) {
    const parsed = parseAuthorizationPolicyClause(model);
    identityClaims = parsed.claims;
    identitySourceType = parsed.source?.type;

    // If the parsed identity matches with the existing identity provider,
    // override the type.
    if (identityProvider && identityProvider.uuid === parsed.source?.value) {
      identitySourceType =
        AuthorizationPolicyIdentitySourceType.IdentityProvider;
    }
  }

  const permissionRoles = model
    ? getAuthorizationPolicyPermissionRoles(model)
    : undefined;

  const expirationTime = model?.spec.expiration_time ?? '';
  const targetNamespaces = model?.spec.target_namespaces ?? [namespace];

  return {
    permissionRoles,
    identityClaims,
    identitySourceType,
    expirationTime,
    propagate: model?.propagate ?? true,
    targetNamespaces,
  };
};

export const toAuthPolicyResourceModel = (
  namespace: string,
  fieldValues: FormUpsertAuthorizationPolicyFieldValues,
  authorizationPolicy?: AuthorizationPolicyResource,
  identityProvider?: IdentityProviderResource
): AuthorizationPolicyResource => {
  const {
    identityClaims = [],
    identitySourceType,
    expirationTime,
    permissionRoles,
    propagate,
    targetNamespaces,
  } = fieldValues;

  let identitySource: AuthorizationPolicyIdentitySource | undefined =
    isAuthorizationPolicyIdentitySourceType(identitySourceType)
      ? {
          type: identitySourceType,
          value: identitySourceType,
        }
      : undefined;

  if (
    identityProvider &&
    identitySourceType ===
      AuthorizationPolicyIdentitySourceType.IdentityProvider
  ) {
    identitySource = {
      type: identitySourceType,
      value: identityProvider.uuid,
    };
  }

  // rebuild the clause from the claims
  const claimsClause = buildAuthorizationPolicyIdentityClaimsClause(
    identityClaims,
    identitySource
  );

  if (identitySource) {
    claimsClause.push(identitySource.value);
  }

  // validate the given expiration timestamp
  const validatedExpirationTime =
    expirationTime && REGEX_DATE_ISO_VALIDATION.test(expirationTime)
      ? expirationTime
      : undefined;

  const modelSpec: AuthorizationPolicyResource['spec'] = {
    ...authorizationPolicy?.spec,
    clause: claimsClause,
    // @ts-expect-error null instead of undefined to overwrite an existing expiration_time, if exists
    expiration_time: validatedExpirationTime ?? null,
    permissions: {
      ...authorizationPolicy?.spec.permissions,
      roles: permissionRoles,
    },
    propagate,
    target_namespaces: targetNamespaces,
  };

  let modelMeta: Partial<AuthorizationPolicyResource['meta']> = {
    name: buildAuthorizationPolicyName(
      namespace,
      identityClaims,
      identitySource
    ),
    // TODO: sanitize and re-enable tags
    // tags: [...claimsClause, namespace]
  };

  if (
    identitySource?.type ===
    AuthorizationPolicyIdentitySourceType.IdentityProvider
  ) {
    modelMeta = {
      ...modelMeta,
      parent_kind: 'IdentityProvider',
      parent_uuid: identitySource.value,
    };
  }

  return {
    uuid: authorizationPolicy?.uuid,
    tenant_meta: { namespace },
    meta: modelMeta,
    spec: modelSpec,
    propagate,
  } as AuthorizationPolicyResource;
};
