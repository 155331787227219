import { useCallback, useState } from 'react';

import {
  FindingsExportDialog,
  FindingsExportDialogProps,
} from '../components/FindingsExportDialog';

type DialogState = Omit<FindingsExportDialogProps, 'onClose' | 'open'>;

/**
 * Utility hook to manage Findings Export Dialog
 */
export function useFindingsExportDialog() {
  const [dialogState, setDialogState] = useState<DialogState | undefined>(
    undefined
  );
  const isOpen = Boolean(dialogState);

  const handleOpenDialog = useCallback((state: DialogState) => {
    setDialogState(state);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogState(undefined);
  }, []);

  const getDialogProps = useCallback(
    () =>
      ({
        ...dialogState,
        open: isOpen,
        onClose: handleCloseDialog,
      } as FindingsExportDialogProps),
    [dialogState, handleCloseDialog, isOpen]
  );

  return {
    Dialog: FindingsExportDialog,
    openDialog: handleOpenDialog,
    getDialogProps,
  };
}
