import { Button, Stack, Typography } from '@mui/material';
import { keyBy as _keyBy } from 'lodash-es';
import { ReactNode, useMemo, useState } from 'react';
import { defineMessages, FormattedMessage as FM } from 'react-intl';

import { SpecFindingLevel } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { useQueryFindings } from '@endorlabs/queries';
import {
  DescriptionTooltip,
  FindingLevelChip,
  NilDisplay,
  StackedCardLayout,
} from '@endorlabs/ui-common';

import {
  FindingSummaryAccordionV2,
  FindingSummaryAccordionV2Props,
  MAX_FINDING_TITLE_LENGTH,
} from '../../domains/Findings';

/**
 * i18n messages
 */
const messages = defineMessages({
  aggregationSummary: {
    defaultMessage: `{totalCount, plural, one {<bold>#</bold> finding} other {<bold>#</bold> findings}}`,
  },
  aggregationSummaryWithReferenceCounts: {
    defaultMessage: `{totalCount, plural, one {<bold>#</bold> finding} other {<bold>#</bold> findings}} for {packageCount, plural, one {<bold>#</bold> package} other {<bold>#</bold> packages}} affected in {projectCount, plural, one {<bold>#</bold> project} other {<bold>#</bold> projects}}`,
  },
  aggregationSummaryWithProjectReferenceCounts: {
    defaultMessage: `{totalCount, plural, one {<bold>#</bold> finding} other {<bold>#</bold> findings}} for {projectCount, plural, one {<bold>#</bold> project} other {<bold>#</bold> projects}}`,
  },
});

type FindingsAggregatedGroupSectionProps = {
  findingSummaryProps?: Partial<FindingSummaryAccordionV2Props>;
  namespace: string;
  referenceCounts?: Partial<Record<ResourceKind, number>>;
  severity: SpecFindingLevel;
  title?: ReactNode;
  totalCount: number;
  uuids: string[];
};

export const FindingsAggregatedGroupSection = ({
  findingSummaryProps,
  namespace,
  referenceCounts,
  severity,
  title,
  totalCount,
  uuids,
}: FindingsAggregatedGroupSectionProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const [visibleCount, setVisibleCount] = useState(10);
  const handleViewMore = () => {
    setVisibleCount(totalCount);
  };
  const remainingCount = totalCount - visibleCount;

  // the 'page' of findings that should be visible
  const [uuidsPage, findingsFilter] = useMemo(() => {
    const uuidsPage = uuids.slice(0, visibleCount);
    // NOTE: explicitly filtering on uuid, context is not needed
    const findingsFilter = `uuid in ["${uuidsPage.join('","')}"]`;

    return [uuidsPage, findingsFilter];
  }, [uuids, visibleCount]);

  const qFindings = useQueryFindings(
    namespace,
    {
      filter: findingsFilter,
      page_size: Math.min(500, visibleCount),
    },
    { enabled: isVisible }
  );

  const findings = useMemo(() => {
    const findingsByUuid = _keyBy(qFindings.data?.list?.objects ?? [], 'uuid');
    return uuidsPage.map((uuid) => ({ uuid, data: findingsByUuid[uuid] }));
  }, [qFindings.data, uuidsPage]);

  const titleDisplay = useMemo(() => {
    if (!title) return null;
    if ('string' !== typeof title) return title as ReactNode;

    if (title.length < MAX_FINDING_TITLE_LENGTH) return title;
    return (
      <DescriptionTooltip title={title}>
        <span>{title.slice(0, MAX_FINDING_TITLE_LENGTH)}&hellip;</span>
      </DescriptionTooltip>
    );
  }, [title]);

  return (
    <StackedCardLayout
      onTransitionEnd={(_, expanded) => setIsVisible(expanded)}
      title={
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <FindingLevelChip level={severity} />

          <Typography
            variant="h3"
            sx={{
              // force the title text to align with the icon
              lineHeight: (t) => t.spacing(6),
            }}
          >
            {titleDisplay ? titleDisplay : <NilDisplay variant="text" />}
          </Typography>
        </Stack>
      }
      summary={
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="body1" color="text.secondary">
            <FM
              {...(referenceCounts
                ? referenceCounts.PackageVersion
                  ? messages.aggregationSummaryWithReferenceCounts
                  : messages.aggregationSummaryWithProjectReferenceCounts
                : messages.aggregationSummary)}
              values={{
                bold: (value) => (
                  <Typography
                    component="span"
                    variant="inherit"
                    color="text.primary"
                  >
                    {value}
                  </Typography>
                ),
                totalCount,
                packageCount: referenceCounts?.PackageVersion,
                projectCount: referenceCounts?.Project,
              }}
            />
          </Typography>
        </Stack>
      }
    >
      {findings.map((f) => (
        <FindingSummaryAccordionV2
          {...findingSummaryProps}
          namespace={namespace}
          finding={f.data}
          isLoading={!f.data}
          key={f.uuid}
        />
      ))}

      {remainingCount > 0 && (
        <Button onClick={handleViewMore} sx={{ alignSelf: 'flex-start' }}>
          <FM
            defaultMessage="View {remainingCount, number} More Findings"
            values={{ remainingCount }}
          />
        </Button>
      )}
    </StackedCardLayout>
  );
};
