import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '@endorlabs/ui-common';

import {
  AddScanProfileProjectDialogContent,
  AddScanProfileProjectDialogContentProps,
} from './AddScanProfileProjectDialogContent';

type AddScanProfileProjectDialogProps = Pick<
  SimpleDialogProps,
  'onClose' | 'open'
> & {
  state?: AddScanProfileProjectDialogContentProps;
};

export const AddScanProfileProjectDialog = ({
  onClose,
  open,
  state,
}: AddScanProfileProjectDialogProps) => {
  return (
    <SimpleDialog
      maxWidth="sm"
      onClose={onClose}
      open={open}
      titleText={<FM defaultMessage="Add Projects to Scan Profile" />}
    >
      {state && <AddScanProfileProjectDialogContent {...state} />}
    </SimpleDialog>
  );
};
