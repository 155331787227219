import { Box, Button, Stack } from '@mui/material';
import clsx from 'clsx';
import { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

export interface ClampLinesDisplayProps {
  children: React.ReactNode;
  maxLines?: number;
}

export const ClampLinesDisplay = ({
  children,
  maxLines = 3,
}: ClampLinesDisplayProps) => {
  const ref = useRef<HTMLDivElement>();
  const [isOverflowing, setIsOverflowing] = useState(true);
  const [isClamped, setIsClamped] = useState(true);

  const toggleClamped = () => {
    setIsClamped((value) => !value);
  };

  useLayoutEffect(() => {
    // On layout, determine if text is overflowing clamped container
    // TODO: recalculate overflowing on window resize
    if (ref.current) {
      const { offsetHeight, offsetWidth, scrollHeight, scrollWidth } =
        ref.current ?? {};
      setIsOverflowing(scrollHeight > offsetHeight);
    }
  }, [children, maxLines]);

  const cls = clsx({
    isClamped,
  });

  return (
    <Stack direction="column" spacing={2} alignItems="flex-start">
      <Box
        ref={ref}
        className={cls}
        sx={{
          '&.isClamped': {
            display: '-webkit-box',
            WebkitLineClamp: maxLines,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>

      {isOverflowing && (
        <Button size="small" onClick={toggleClamped}>
          {isClamped ? (
            <FM defaultMessage="Show More" />
          ) : (
            <FM defaultMessage="Show Less" />
          )}
        </Button>
      )}
    </Stack>
  );
};
