import { LoadingButton } from '@mui/lab';
import {
  Divider,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import produce from 'immer';
import { set as _set } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { MetaAnnotations } from '@endorlabs/endor-core/api';
import { ProjectResource } from '@endorlabs/endor-core/Project';
import { useListAllScanProfiles, useUpdateProject } from '@endorlabs/queries';
import { IconChevronDown, Link } from '@endorlabs/ui-common';

import { getSettingsPath } from '../../../../routes';

export const ProjectSettingsScanProfile = ({
  namespace,
  project,
}: {
  namespace: string;
  project?: ProjectResource;
}) => {
  const qUpdateProject = useUpdateProject();

  const qListScanProfiles = useListAllScanProfiles(namespace, {
    mask: ['meta.name', 'meta.description', 'uuid'].join(','),
    traverse: false,
  });

  const [selectedScanProfileValue, setSelectedScanProfileValue] =
    useState<string>('');

  useEffect(() => {
    const scanProfileUuid = project?.spec.scan_profile_uuid;
    const autoDetectAnnotation =
      project?.meta.annotations?.[
        MetaAnnotations.EndorScanEnableBuildToolsVersionDetection
      ];

    if (scanProfileUuid) {
      setSelectedScanProfileValue(scanProfileUuid);
    } else if (autoDetectAnnotation === 'true') {
      setSelectedScanProfileValue(
        MetaAnnotations.EndorScanEnableBuildToolsVersionDetection
      );
    }
  }, [project]);

  const handleUpdateScanProfile = () => {
    if (!project) return;

    const updated = produce(project, (draft) => {
      // Reset the project fields
      _set(
        draft,
        [
          'meta',
          'annotations',
          MetaAnnotations.EndorScanEnableBuildToolsVersionDetection,
        ],
        'false'
      );
      draft.spec.scan_profile_uuid = undefined;

      if (
        selectedScanProfileValue ===
        MetaAnnotations.EndorScanEnableBuildToolsVersionDetection
      ) {
        _set(
          draft,
          [
            'meta',
            'annotations',
            MetaAnnotations.EndorScanEnableBuildToolsVersionDetection,
          ],
          'true'
        );
      } else if (selectedScanProfileValue) {
        draft.spec.scan_profile_uuid = selectedScanProfileValue;
      }
    });

    qUpdateProject.mutate({
      mask: ['meta.annotations', 'spec.scan_profile_uuid'].join(','),
      namespace: project.tenant_meta.namespace,
      resource: updated,
    });
  };

  return (
    <Stack gap={4} alignItems="start">
      <Typography>
        <FM
          defaultMessage="Specify which build tools to use for this project. Scan Profiles can be managed in the <link>settings page</link>."
          values={{
            link: (value) => (
              <Link
                to={getSettingsPath({
                  tenantName: namespace,
                  section: 'scan-profiles',
                })}
              >
                {value}
              </Link>
            ),
          }}
        />
      </Typography>

      <Select
        displayEmpty
        IconComponent={IconChevronDown}
        onChange={(evt) => setSelectedScanProfileValue(evt.target.value)}
        sx={{
          color: selectedScanProfileValue ? 'text.primary' : 'text.secondary',
          minWidth: 240,
        }}
        value={selectedScanProfileValue}
      >
        <MenuItem value="">
          <ListItemText primary={<FM defaultMessage="None" />} />
        </MenuItem>

        <MenuItem
          value={MetaAnnotations.EndorScanEnableBuildToolsVersionDetection}
        >
          <ListItemText primary={<FM defaultMessage="Auto-Detect" />} />
        </MenuItem>

        {!!qListScanProfiles.data?.length && <Divider />}

        {qListScanProfiles.data?.map((sp) => (
          <MenuItem key={sp.uuid} value={sp.uuid}>
            <ListItemText
              primary={sp.meta.name}
              secondary={sp.meta.description}
            />
          </MenuItem>
        ))}
      </Select>

      <LoadingButton
        color="primary"
        loading={qUpdateProject.isLoading}
        onClick={handleUpdateScanProfile}
        variant="contained"
      >
        <FM defaultMessage="Save Profile" />
      </LoadingButton>
    </Stack>
  );
};
