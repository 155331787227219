import {
  isEmpty as _isEmpty,
  sortBy as _sortBy,
  uniq as _uniq,
} from 'lodash-es';
import { useMemo } from 'react';

import {
  tryParseGroupResponseAggregationKey,
  useGroupSemgrepRules,
} from '@endorlabs/queries';

interface UseSASTRulesFiltersProps {
  tenantName: string;
}

export const useSASTRulesFilters = ({
  tenantName,
}: UseSASTRulesFiltersProps) => {
  const qDefinedBy = useGroupSemgrepRules(
    tenantName,
    {
      filter: 'spec.rule.metadata.endor_category == "vulnerability"',
      group: {
        aggregation_paths: 'spec.defined_by',
      },
    },
    { enabled: !!tenantName }
  );

  const qConfidenceData = useGroupSemgrepRules(
    tenantName,
    {
      filter: 'spec.rule.metadata.endor_category == "vulnerability"',
      group: {
        aggregation_paths: 'spec.rule.metadata.confidence',
      },
    },
    { enabled: !!tenantName }
  );

  const qLanguagesList = useGroupSemgrepRules(
    tenantName,
    {
      filter: 'spec.rule.metadata.endor_category == "vulnerability"',
      group: {
        aggregation_paths: 'spec.rule.languages',
      },
    },
    { enabled: !!tenantName }
  );

  const qEndorTagsList = useGroupSemgrepRules(
    tenantName,
    {
      filter: 'spec.rule.metadata.endor_category == "vulnerability"',
      group: {
        aggregation_paths: 'spec.rule.metadata.endor_tags',
      },
    },
    { enabled: !!tenantName }
  );

  const definedByFilters = useMemo(() => {
    const definedBy: string[] = [];
    if (!_isEmpty(qDefinedBy.data?.groups)) {
      for (const [key, value] of Object.entries(qDefinedBy.data?.groups)) {
        const values = tryParseGroupResponseAggregationKey(key);
        definedBy.push(
          (values.find((kv) => kv.key === 'spec.defined_by')
            ?.value as string) ?? ''
        );
      }
      return _sortBy(definedBy.filter((d) => d));
    }
    return definedBy;
  }, [qDefinedBy.data]);

  const confidenceFilters = useMemo(() => {
    const confidenceList: string[] = [];
    if (!_isEmpty(qConfidenceData.data?.groups)) {
      for (const [key, value] of Object.entries(qConfidenceData.data?.groups)) {
        const values = tryParseGroupResponseAggregationKey(key);
        confidenceList.push(
          (values.find((kv) => kv.key === 'spec.rule.metadata.confidence')
            ?.value as string) ?? ''
        );
      }
      return _sortBy(confidenceList.filter((c) => c));
    }
    return confidenceList;
  }, [qConfidenceData.data]);

  const languageFilters = useMemo(() => {
    const languageList: string[] = [];
    if (!_isEmpty(qLanguagesList.data?.groups)) {
      for (const [key, value] of Object.entries(qLanguagesList.data?.groups)) {
        const values = tryParseGroupResponseAggregationKey(key);
        languageList.push(
          (values.find((kv) => kv.key === 'spec.rule.languages')
            ?.value as string) ?? []
        );
      }
      /* Convert languages into linear array of unique values, remove empty strings and sort in alphabetical order*/
      return _sortBy(_uniq(languageList.flat()).filter((l) => l));
    }
    return languageList;
  }, [qLanguagesList.data]);

  const endorTagsFilters = useMemo(() => {
    const endorTagsList: string[] = [];
    if (!_isEmpty(qEndorTagsList.data?.groups)) {
      for (const [key, value] of Object.entries(qEndorTagsList.data?.groups)) {
        const values = tryParseGroupResponseAggregationKey(key);
        endorTagsList.push(
          (values.find((kv) => kv.key === 'spec.rule.metadata.endor_tags')
            ?.value as string) ?? []
        );
      }
      return _sortBy(_uniq(endorTagsList.flat()).filter((t) => t));
    }
    return endorTagsList;
  }, [qEndorTagsList.data]);

  return {
    definedByFilters,
    confidenceFilters,
    languageFilters,
    endorTagsFilters,
  };
};
