import { useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { useGetProject } from '@endorlabs/queries';
import { ButtonLinkSecondary, ButtonStack } from '@endorlabs/ui-common';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer';
import { InfoDrawerContentProps } from '../../../../components/InfoDrawer';
import { getProjectPath } from '../../../../routes';
import { ProjectDetailDrawerContent } from './ProjectDetailDrawerContent';
import { ProjectDetailDrawerHeader } from './ProjectDetailDrawerHeader';

export interface ProjectDetailDrawerProps extends InfoDrawerContentProps {
  uuid: string;
  namespace: string;
}

export const ProjectDetailDrawer = ({
  uuid,
  namespace,
}: ProjectDetailDrawerProps) => {
  const { space } = useTheme();
  const qProject = useGetProject({ namespace, uuid });
  const projectLink = getProjectPath({
    tenantName: namespace,
    uuid: uuid,
  });

  return (
    <DetailDrawerContainer
      headerProps={{
        title: qProject.data && (
          <ProjectDetailDrawerHeader
            uuid={uuid}
            namespace={namespace}
            project={qProject.data}
            isProjectLoading={qProject.isLoading}
          />
        ),
        action: (
          <ButtonStack paddingTop={space.sm} paddingBottom={space.sm}>
            <ButtonLinkSecondary linkProps={{ to: projectLink }} size="small">
              <FM defaultMessage="View Details" />
            </ButtonLinkSecondary>
          </ButtonStack>
        ),
      }}
    >
      <ProjectDetailDrawerContent
        uuid={uuid}
        project={qProject.data}
        isProjectLoading={qProject.isLoading}
        namespace={namespace}
      />
    </DetailDrawerContainer>
  );
};
