import { ApiKeyResource } from '@endorlabs/queries';

import { FormNewApiKeyFieldValues } from './types';

/**
 * Helper to re-shape the form data to the API Key object shape
 */
export const toApiKeyResourceModel = (
  fieldValues: FormNewApiKeyFieldValues
): ApiKeyResource => {
  const { permissionRoles, propagate, ...rest } = fieldValues;
  const model = {
    ...rest,
    propagate,
    spec: {
      ...rest.spec,
      permissions: {
        roles: permissionRoles,
      },
    },
  };

  return model as ApiKeyResource;
};
