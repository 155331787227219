import { Navigate } from '@tanstack/react-location';

import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantCreateProjectIndexRoute = () => {
  return <Navigate to="github" replace />;
};
export const Route = createFileRoute('/t/:namespace/projects/new/')({
  component: TenantCreateProjectIndexRoute,
});
