import { useInfoDrawer } from '../../../../components/InfoDrawer';
import {
  ScoreFactorsDrawer,
  ScoreFactorsDrawerProps,
} from './ScoreFactorsDrawer';

export const useScoreFactorsDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    { resourceNamespace?: string; resourceUuid?: string },
    ScoreFactorsDrawerProps
  >({
    drawerParams: ['resourceNamespace', 'resourceUuid'],
    Component: ScoreFactorsDrawer,
  });

  const permalinkEffect = () => {
    const activeDrawerParams = DetailDrawer.getSearchParams();

    if (
      activeDrawerParams?.resourceNamespace &&
      activeDrawerParams?.resourceUuid &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          resourceNamespace: activeDrawerParams.resourceNamespace,
          resourceUuid: activeDrawerParams.resourceUuid,
        },
        {
          resourceNamespace: activeDrawerParams.resourceNamespace,
          resourceUuid: activeDrawerParams.resourceUuid,
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
