import { CircularProgress, Grid, Tab } from '@mui/material';
import { SvgIconProps } from '@mui/material';
import { isNil as _isNil } from 'lodash-es';
import { ReactNode } from 'react';
import { JSXElementConstructor } from 'react';

import { BetaBadge } from '../BetaBadge';
import { Link, LinkProps } from '../Link';
import { LinkTabCount, LinkTabCountProps } from './LinkTabCount';

export interface LinkTabProps
  extends Pick<LinkProps, 'to' | 'search' | 'sx'>,
    LinkTabCountProps {
  isApproximateCount?: boolean;
  isBeta?: boolean;
  disabled?: boolean;
  Icon?: JSXElementConstructor<SvgIconProps>;
  isLoading?: boolean;
  label: ReactNode;
  value: string;
}

export function LinkTab({
  count,
  disabled,
  Icon,
  isBeta = false,
  isLoading,
  label,
  sx,
  to,
  ...props
}: LinkTabProps) {
  // build up the full tab label
  let fullLabel = (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      spacing={2}
      sx={{ minWidth: '58px' }}
    >
      {Icon && (
        <Grid item>
          <Icon />
        </Grid>
      )}

      <Grid item>{label}</Grid>

      {isLoading && (
        <Grid item>
          <CircularProgress size="1em" />
        </Grid>
      )}

      {!isLoading && !_isNil(count) && (
        <Grid item>
          <LinkTabCount count={count} />
        </Grid>
      )}
    </Grid>
  );

  if (isBeta) {
    fullLabel = <BetaBadge>{fullLabel}</BetaBadge>;
  }

  return (
    <Tab
      component={Link}
      disabled={disabled}
      label={fullLabel}
      replace // should not preserve tab state in browser history
      sx={{
        ...sx,
        '&:hover': {
          color: ({ palette }) => palette.primary.main,
          textDecoration: 'none',
        },
        // add padding to make space for the beta badge
        paddingRight: ({ typography }) =>
          isBeta ? typography.pxToRem(56) : undefined,
      }}
      to={to}
      {...props}
    />
  );
}
