import { Alert, AlertTitle, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ProjectResource } from '@endorlabs/queries';
import {
  CommonDataTableRowActions,
  DataTableView,
  IconInfo,
  Link,
} from '@endorlabs/ui-common';

import { buildPRRunsTableColumnDefs, PRRunsTableRow } from '../../components';
import { FilterBar, useFilterContext } from '../../domains/filters';
import { getProjectDetailPath, NamedRoutes } from '../../routes';
import { usePRRunsData } from './useCIRunsData';

export interface ProjectDetailPRRunsProps {
  namespace: string;
  project?: ProjectResource;
}

export const ProjectDetailPRRunsV2 = ({
  namespace,
  project,
}: ProjectDetailPRRunsProps) => {
  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { palette } = useTheme();

  const [alertDismissed, setAlertDismissed] = useState(false);

  const { filter: searchFilterExpression } = useFilterContext();

  const { data, isLoading, paginator, qCountRepoVersions } = usePRRunsData({
    namespace,
    project,
    searchFilterExpression,
  });

  const handleRowClick = (row: PRRunsTableRow) => {
    const base = getProjectDetailPath({
      tenantName: namespace,
      uuid: project?.uuid,
      section: 'pr-runs',
    });
    const link = [base, row.prRunId].join('/');
    navigate({ to: link });
  };

  const rowActions = [
    { ...CommonDataTableRowActions.LINK, onClick: handleRowClick },
  ];

  const emptyStateProps = {
    title: <FM defaultMessage="There are no PR runs in this project" />,
    children: (
      <Typography>
        <FM defaultMessage="As new PR runs are created, they will appear here." />
      </Typography>
    ),
  };

  return (
    <Stack direction="column" spacing={3}>
      {!alertDismissed && (
        <Alert
          severity="success"
          sx={{
            color: palette.text.primary,
            border: `1px solid ${palette.design.severity.safe.main}`,
            '.MuiAlert-icon': { color: palette.text.primary },
          }}
          onClose={() => setAlertDismissed(true)}
          iconMapping={{
            success: <IconInfo fontSize="inherit" />,
          }}
        >
          <AlertTitle>
            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
              <FM
                // TODO: when Project Overview is re-added, update this text to direct user to the Versions tab.
                // defaultMessage="These scans are from running <code>`endorctl scan --pr`</code>. To see the results of scans without the <code>--pr</code> flag, go to the Versions tab."
                defaultMessage="These scans are from running <code> endorctl scan --pr</code>"
                values={{
                  code: (value) => (
                    <Typography
                      variant="code"
                      sx={{ background: palette.background.paper }}
                    >
                      {value}
                    </Typography>
                  ),
                  desc: (value) => (
                    <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
                  ),
                }}
              />
            </Typography>
          </AlertTitle>

          {NamedRoutes.DOCS_ENDORCTL && (
            <FM
              defaultMessage="To learn more about using <code> endorctl </code>, please visit <link>our documentation</link>."
              values={{
                code: (value) => <b>{value}</b>,
                link: (value) => (
                  <Link target="_blank" to={NamedRoutes.DOCS_ENDORCTL}>
                    {value}
                  </Link>
                ),
              }}
            />
          )}
        </Alert>
      )}

      <DataTableView
        bulkActions={[]}
        columns={buildPRRunsTableColumnDefs({ excludeColumns: [] })}
        countMessage={
          <FM
            defaultMessage="{count} Runs"
            values={{ count: qCountRepoVersions?.data?.count ?? 0 }}
          />
        }
        data={data}
        emptyStateProps={emptyStateProps}
        filtersContent={
          <FilterBar
            fields={[]}
            isCard={false}
            searchPlaceholder={fm({
              defaultMessage: 'Branch, tag, or commit SHA',
            })}
          />
        }
        isLoading={isLoading}
        namespace={namespace}
        paginator={paginator}
        rowActions={rowActions}
      />
    </Stack>
  );
};
