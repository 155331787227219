import { Components, Theme } from '@mui/material';

type ChipOverrides = Pick<Components<Theme>, 'MuiChip'>;

export const getChipOverrides = ({
  spacing,
  typography,
}: Theme): ChipOverrides => {
  return {
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: spacing(5),
        },
        labelSmall: {
          fontSize: typography.body2.fontSize,
        },
      },
      variants: [
        {
          props: { size: 'xsmall' },
          style: {
            height: spacing(4),
            '& .MuiChip-labelXsmall': {
              fontSize: typography.pxToRem(11),
              paddingLeft: spacing(0.5),
              paddingRight: spacing(0.5),
            },
          },
        },
      ],
    },
  };
};
