import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { debounce as _debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { FilterableResource, ResourceFilterDefinition } from '@endorlabs/filters';
import {
  ResourceKindDisplay,
  useResourceKindDisplayLabel,
} from '@endorlabs/ui-common';

import { getFilteredOptions } from './utils';

export interface ControlSelectFilterDefinitionProps {
  options: ResourceFilterDefinition[];
  onCancel: () => void;
  onSelect: (definition: ResourceFilterDefinition) => void;
  primaryResourceKind: FilterableResource;
  primaryResourceKindLabel?: string;
  resourceKinds: FilterableResource[];
  selectedResourceKind?: FilterableResource;
}

export const ControlSelectFilterDefinition = ({
  options,
  onSelect,
  primaryResourceKind,
  primaryResourceKindLabel,
  resourceKinds,
  selectedResourceKind,
  onCancel,
}: ControlSelectFilterDefinitionProps) => {
  const { formatMessage: fm } = useIntl();
  // manage input field
  const [inputValue, setInputValue] = useState('');
  // manage filter definition options with filter
  const [filteredOptions, setFilteredOptions] = useState<ResourceFilterDefinition[]>(
    []
  );

  // manage focus on component mount, and each time the target resource changes
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    ref.current?.focus();
  }, [selectedResourceKind]);

  const defaultPrimaryResourceKindLabel = useResourceKindDisplayLabel(
    primaryResourceKind,
    true
  );

  // get placeholder for the autocomplete input
  const placeholder = fm(
    { defaultMessage: 'Filter your {kind}' },
    {
      kind: primaryResourceKindLabel ?? defaultPrimaryResourceKindLabel,
    }
  );

  useEffect(() => {
    const filterResourceKinds = selectedResourceKind
      ? [selectedResourceKind]
      : resourceKinds;

    const timer = setTimeout(() => {
      const results = getFilteredOptions(
        filterResourceKinds,
        options,
        inputValue
      );
      setFilteredOptions(results);
    }, 0);

    return () => clearTimeout(timer);
  }, [options, resourceKinds, selectedResourceKind, inputValue]);

  // debounce the cancel, allowing a timeout on inactivity
  const debouncedCancel = _debounce(onCancel, 1000);

  return (
    <Autocomplete
      openOnFocus
      noOptionsText={<FM defaultMessage="No Matching Filters Found" />}
      popupIcon={false}
      onBlur={() => debouncedCancel()}
      onChange={(_, value) => value && onSelect(value)}
      onFocus={() => debouncedCancel.cancel()}
      inputValue={inputValue}
      onInputChange={(_, value, reason) =>
        reason === 'input' && setInputValue(value)
      }
      options={filteredOptions}
      filterOptions={(i) => i}
      groupBy={(option) => option.kind}
      getOptionLabel={(option: ResourceFilterDefinition | string) =>
        'string' === typeof option
          ? option
          : option.name ?? `${option.kind}:${option.key}`
      }
      isOptionEqualToValue={(option, value) =>
        option.kind === value?.kind && option.key === value?.key
      }
      PopperComponent={(props) => (
        <Popper {...props} sx={{ maxWidth: 800 }} placement="bottom-start" />
      )}
      renderGroup={({ key, group, children }) => (
        <Box
          key={key}
          sx={({ breakpoints }) => ({
            [`& .${autocompleteClasses.option}`]: {
              paddingLeft: 6,
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              [breakpoints.down('md')]: {
                alignItems: 'start',
              },
            },
          })}
        >
          <Typography
            variant="h4"
            color="text.secondary"
            sx={{ paddingX: 4, paddingY: 2 }}
          >
            {group === primaryResourceKind ? (
              <FM
                defaultMessage="List {kind} Where..."
                values={{
                  kind: primaryResourceKindLabel ?? (
                    <ResourceKindDisplay value={group} isPlural />
                  ),
                }}
              />
            ) : (
              <FM
                defaultMessage="Have {kind} Where..."
                values={{
                  kind: <ResourceKindDisplay value={group} isPlural />,
                }}
              />
            )}
          </Typography>

          {children}
        </Box>
      )}
      renderOption={(props, option) => (
        <Stack
          {...props}
          key={`${option.kind}:${option.key}`}
          component="li"
          direction={{ sm: 'column', md: 'row' }}
          spacing={2}
          alignItems="center"
        >
          {option.name ? (
            <>
              <Typography component="span">{option.name}</Typography>
              <Typography
                component="code"
                variant="code"
                color="text.secondary"
                flexGrow={1}
                textAlign="right"
              >
                {option.kind}:{option.key}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                component="code"
                variant="body2"
                fontFamily="monospace"
              >
                {option.key}
              </Typography>
            </>
          )}
        </Stack>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={ref}
          placeholder={placeholder}
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};
