import { Stack } from '@mui/material';
import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import { Pie } from '@visx/shape';
import { defaultStyles, Tooltip, useTooltip } from '@visx/tooltip';
import { useCallback, useMemo } from 'react';

import { Colors } from './constants';
import { Legend } from './Legend';
import { PieChartProps, TooltipData } from './types';
import { getChartContainerDirection, getChartDimensions } from './utils';

const defaultMargin = { top: 16, left: 16, right: 16, bottom: 16 };

export function PieChart<T extends Record<string, string | number>>({
  data,
  nameKey,
  valueKey,
  valueFormat,
  width,
  height,
  donutThickness = 16,
  cornerRadius = 0,
  padAngle = 0,
  colorList = [Colors.RED, Colors.ORANGE, Colors.YELLOW],
  showLabels = false,
  labelSize = 12,
  labelColor = Colors.BLACK,
  tooltips = true,
  tooltipTextColor = Colors.WHITE,
  tooltipBackgroundColor = Colors.BLACK,
  margin = defaultMargin,
  showLegends = true,
  legendPosition = 'center',
  legendDirection = 'column',
  legendJustify = 'center',
  legendAlign = 'center',
  legendIconShape = 'circle',
  legendFontSize = 12,
  legendContainerSize = 0.2,
  legendGap,
  onClickHandler,
  showLegendValues = true,
  showTotalValue = false,
  totalValueLabelSize = 24,
}: PieChartProps<T>) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TooltipData>();

  const [
    svgWidth,
    svgHeight,
    chartWidth,
    chartHeight,
    legendContainerWidth,
    legendContainerHeight,
  ] = getChartDimensions(
    width,
    height,
    margin,
    showLegends,
    legendPosition,
    legendContainerSize
  );

  const radius = Math.min(chartWidth, chartHeight) / 2;
  const centerY = chartHeight / 2;
  const centerX = chartWidth / 2;

  const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: tooltipBackgroundColor,
    color: tooltipTextColor,
  };

  const tooltipOffsetLeft = ['left', 'right'].includes(legendPosition)
    ? legendContainerWidth
    : 0;

  const tooltipOffsetTop = ['top'].includes(legendPosition)
    ? legendContainerHeight
    : 0;

  const getName = useCallback((d: T): string => String(d[nameKey]), [nameKey]);

  const getValue = useCallback(
    (d: T): number => (!isNaN(Number(d[valueKey])) ? Number(d[valueKey]) : 0),
    [valueKey]
  );

  const getColor = useMemo(
    () =>
      scaleOrdinal<string | number, string>({
        domain: data.map(getName),
        range: colorList,
      }),
    [data, getName, colorList]
  );

  const dataValues: number[] = useMemo(() => {
    return data.map((d) => getValue(d));
  }, [data, getValue]);

  const totalValue: string = useMemo(() => {
    const total = dataValues.reduce((s, v) => s + v, 0);
    return valueFormat ? valueFormat(total) : total.toString();
  }, [dataValues, valueFormat]);

  return width < 10 ? null : (
    <Stack
      direction={getChartContainerDirection(legendPosition)}
      style={{ position: 'relative' }}
      gap={legendGap}
    >
      <svg width={svgWidth} height={svgHeight}>
        {showTotalValue && (
          <Group
            top={centerY + margin.top}
            left={centerX + margin.left}
            onClick={
              onClickHandler
                ? () =>
                    onClickHandler({ key: 'TOTAL_VALUE', value: totalValue })
                : undefined
            }
            style={{ cursor: onClickHandler ? 'pointer' : undefined }}
          >
            <text
              fill={Colors.BLACK}
              dominantBaseline="middle"
              textAnchor="middle"
              fontSize={totalValueLabelSize}
            >
              {totalValue}
            </text>
          </Group>
        )}

        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={data}
            pieValue={getValue}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={cornerRadius}
            padAngle={padAngle}
          >
            {(pie) =>
              pie.arcs.map((arc, index) => {
                const [centroidX, centroidY] = pie.path.centroid(arc);
                const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;
                return (
                  <g
                    key={index}
                    onClick={
                      onClickHandler
                        ? () => onClickHandler(arc.data)
                        : undefined
                    }
                    style={{ cursor: onClickHandler ? 'pointer' : undefined }}
                  >
                    <path
                      d={pie.path(arc) ?? undefined}
                      fill={getColor(String(arc.data[nameKey]))}
                      onMouseLeave={
                        tooltips
                          ? () => {
                              hideTooltip();
                            }
                          : undefined
                      }
                      onMouseMove={
                        tooltips
                          ? () => {
                              showTooltip({
                                tooltipData: {
                                  key: String(arc.data[nameKey]),
                                  value: valueFormat
                                    ? valueFormat(arc.value)
                                    : arc.value,
                                },
                                tooltipTop:
                                  centroidY + centerY + tooltipOffsetTop,
                                tooltipLeft:
                                  centroidX + centerX + tooltipOffsetLeft,
                              });
                            }
                          : undefined
                      }
                    />
                    {showLabels && hasSpaceForLabel && (
                      <g>
                        <text
                          fill={labelColor}
                          x={centroidX}
                          y={centroidY}
                          dy=".33em"
                          fontSize={labelSize}
                          textAnchor="middle"
                          pointerEvents="none"
                        >
                          {arc.value}
                        </text>
                      </g>
                    )}
                  </g>
                );
              })
            }
          </Pie>
        </Group>
      </svg>
      {showLegends && (
        <Legend
          legendPosition={legendPosition}
          legendDirection={legendDirection}
          legendJustify={legendJustify}
          legendGap={legendGap}
          legendAlign={legendAlign}
          legendFontSize={legendFontSize}
          legendIconShape={legendIconShape}
          maxWidth={legendContainerWidth}
          maxHeight={legendContainerHeight}
          margin={margin}
          colorScale={getColor}
          dataValues={dataValues}
          showLegendValues={showLegendValues}
          valueFormat={valueFormat}
        />
      )}
      {tooltips && tooltipOpen && tooltipData && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          offsetTop={0}
          offsetLeft={0}
        >
          <div>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.value}</div>
        </Tooltip>
      )}
    </Stack>
  );
}
