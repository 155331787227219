import { Stack, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';

import {
  DataTableViewProps,
  DrawerAccordion,
  DrawerAccordionProps,
} from '@endorlabs/ui-common';

import { AggregatedFinding } from '../hooks';
import { FindingsDataTableView } from './FindingsDataTableView';
import { FindingsTableRowV2 } from './FindingsTable';

type FindingsDataTableViewAccordionGroupProps = Omit<
  DataTableViewProps<FindingsTableRowV2>,
  'columns' | 'data'
> &
  DrawerAccordionProps & {
    aggregationGroup: AggregatedFinding;
    namespace: string;
    remediationUuids?: string[];
    titleNode: ReactNode;
  };

export const FindingsDataTableViewAccordionGroup = ({
  aggregationGroup,
  id,
  namespace,
  titleNode,
  ...props
}: FindingsDataTableViewAccordionGroupProps) => {
  const { space, spacing } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DrawerAccordion
      expanded={isOpen}
      id={id}
      onChange={(_, value) => setIsOpen(value)}
      titleNode={titleNode}
    >
      <Stack rowGap={space.sm} paddingLeft={spacing(4)}>
        {isOpen && (
          <FindingsDataTableView
            {...props}
            actionsContent={undefined}
            baseFilterExpression={aggregationGroup.filter}
            bulkActions={[]}
            enableFilters={false}
            enablePagination={true}
            namespace={namespace}
          />
        )}
      </Stack>
    </DrawerAccordion>
  );
};
