import { ScanResultSpecType, V1Context } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { sortParamBuilders, useListScanResults } from '@endorlabs/queries';

/**
 * Common logic for fetching the "latest" code scan results for a given Project and repository version.
 */
export const useProjectLatestScanResults = (
  params: {
    namespace: string;
    projectUuid?: string;
    scanContext?: V1Context;
  },
  options: { enabled?: boolean } = {}
) => {
  const { namespace, projectUuid, scanContext } = params;
  const queryOptions = {
    enabled: Boolean(params.projectUuid && options.enabled !== false),
  };

  const scanContextFilterExpression = scanContext
    ? filterExpressionBuilders.relatedContext(scanContext)
    : filterExpressionBuilders.mainResourceContext();

  const qScanResults = useListScanResults(namespace, queryOptions, {
    filter: filterExpressionBuilders.and([
      `meta.parent_uuid==${projectUuid}`,
      `spec.type==${ScanResultSpecType.AllScans}`,
      scanContextFilterExpression as string,
    ]),
    page_size: 1,
    sort: sortParamBuilders.descendingBy('meta.create_time'),
  });

  return qScanResults;
};
