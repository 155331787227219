import produce from 'immer';
import { isEmpty as _isEmpty, unset as _unset } from 'lodash-es';

import {
  PackagistSpecAuthKind,
  SpecPackageManagerStatusState,
} from '@endorlabs/api_client';
import { Status } from '@endorlabs/ui-common';

import { UpsertInstallationFields } from '../../domains/Installations/components/FormUpsertInstallation';
import { PackagistFieldValues } from './types';

interface ServerFieldError {
  field: 'url' | 'user' | 'password';
  object: { type: string; message: string };
}

export const translateServerError = (code: number): ServerFieldError => {
  switch (code) {
    default:
      return {
        field: 'url',
        object: {
          type: 'server',
          message: 'An unknown error occurred',
        },
      };
  }
};

export const updatePackagistFieldsToModel = (
  fieldValues: PackagistFieldValues
) => {
  const formattedValues = produce(fieldValues, (draft) => {
    if (
      fieldValues.auth_kind != PackagistSpecAuthKind.HttpBasic &&
      fieldValues.auth_kind != PackagistSpecAuthKind.BitbucketOauth &&
      fieldValues.auth_kind != PackagistSpecAuthKind.GitlabToken
    ) {
      _unset(draft, 'user');
    }
    //Reset auth_provider for basic auth
    _unset(draft, 'auth_provider');
  });
  return formattedValues;
};

export const updateAzureFormFieldsToModel = (
  fieldValues: UpsertInstallationFields
) => {
  const formattedValues = produce(fieldValues, (draft) => {
    if (_isEmpty(fieldValues?.spec?.azure_config?.personal_access_token)) {
      _unset(draft, 'spec.azure_config.personal_access_token');
    }
  });
  return formattedValues;
};

export const transformStateToStatus = (
  state: SpecPackageManagerStatusState
): Status => {
  switch (state) {
    case SpecPackageManagerStatusState.Fail:
      return Status.Failure;
    case SpecPackageManagerStatusState.Success:
      return Status.Success;
    case SpecPackageManagerStatusState.Unspecified:
    default:
      return Status.Pending;
  }
};
