import { TRACKING_CODE_TEMPLATE } from './constants';

type InjectTrackingScriptResult =
  | { ok: true; value: { id: string } }
  | { ok: false; error: Error };

/**
 * Utility to inject HubSpot tracking code
 */
export const injectTrackingScript = async (options: {
  portalId: string;
  nonce?: string;
}): Promise<InjectTrackingScriptResult> => {
  // Random ID for the script
  const id = ['hs', Date.now().toString(16)].join('-');
  const script = document.createElement('script');

  script.id = id;
  script.type = 'text/javascript';
  script.async = true;

  script.src = TRACKING_CODE_TEMPLATE.replace('{PORTAL_ID}', options.portalId);

  if (options?.nonce) {
    script.nonce = options.nonce;
  }

  const load = new Promise<InjectTrackingScriptResult>((resolve, reject) => {
    script.addEventListener(
      'load',
      (event) => {
        resolve({ ok: true, value: { id } });
      },
      { once: true }
    );

    script.addEventListener(
      'error',
      (event) => {
        // Remove the script from the DOM on failure
        const script = document.getElementById(id);
        script?.parentNode?.removeChild(script);

        resolve({ ok: false, error: event.error });
      },
      { once: true }
    );
  });

  const node = document.getElementsByTagName('script')[0];
  node.parentNode?.insertBefore(script, node);

  return load;
};
