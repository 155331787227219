import { FormattedMessage as FM } from 'react-intl';

import { V1Confidence } from '@endorlabs/api_client';
import { Pip } from '@endorlabs/ui-common';

import {
  BreakingConfidenceColorMap,
  BreakingConfidenceIconMap,
} from '../../constants';
import { BreakingChangeConfidenceMessage } from '../../locales/BreakingChangeMessage';

export interface BreakingChangeConfidenceDisplayProps {
  confidence: V1Confidence;
  showIcon?: boolean;
  showLabel?: boolean;
  size?: 'xsmall' | 'small' | 'medium';
}

export const BreakingChangeConfidenceDisplay = ({
  confidence,
  showIcon = true,
  showLabel = true,
  size = 'xsmall',
}: BreakingChangeConfidenceDisplayProps) => {
  const Icon = BreakingConfidenceIconMap[confidence];
  return (
    <Pip
      color={BreakingConfidenceColorMap[confidence] ?? 'default'}
      icon={showIcon ? <Icon fontSize={size} /> : undefined}
      label={
        showLabel ? (
          <FM {...BreakingChangeConfidenceMessage[confidence]} />
        ) : undefined
      }
      size={size}
    />
  );
};
