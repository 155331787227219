import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  KeyboardEvent,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import {
  getFilterComparatorsForFilterDefinition,
  isFilterComparatorMultipleValue,
  isFilterComparatorSingleValue,
  ResourceFilter,
  ResourceFilterDefinition,
  ResourceFilterMultipleValue,
  ResourceFilterSingleValue,
} from '@endorlabs/filters';
import { IconX, useResourceKindDisplayLabel } from '@endorlabs/ui-common';

import { ControlInputBase } from './ControlInputBase';

export type FormNewFilterFieldValues = {
  comparator: ResourceFilter['comparator'];
  // support one of the filter value types
  value: {
    single: ResourceFilterSingleValue['value'];
    multiple: ResourceFilterMultipleValue['value'];
  };
};

export interface FormNewFilterProps {
  /**
   * Base definition and partial value for creating or editing a Filter
   */
  filter: ResourceFilterDefinition & Partial<ResourceFilter>;
  onSubmit: (filter: Partial<ResourceFilter>) => void;
  onCancel: () => void;
}

export const FormNewFilter = ({
  filter,
  onSubmit: afterSubmit,
  onCancel,
}: FormNewFilterProps) => {
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const comparators = getFilterComparatorsForFilterDefinition(filter);
  const defaultComparator = filter.comparator ?? comparators[0]?.comparator;

  const { control, handleSubmit, setFocus, watch, formState, setValue } =
    useForm<FormNewFilterFieldValues>({
      defaultValues: {
        comparator: defaultComparator,
      },
    });

  // populate form from provided filter partial
  useEffect(() => {
    if (!filter.value) return;

    if (Array.isArray(filter.value)) {
      setValue('value.multiple', filter.value);
    } else {
      setValue('value.single', filter.value);
    }
  }, [filter, setValue]);

  const comparatorValue = watch('comparator');

  const [hasSingleValue, hasMultipleValue] = useMemo(() => {
    if (isFilterComparatorSingleValue(comparatorValue)) {
      return [true, false];
    }

    if (isFilterComparatorMultipleValue(comparatorValue)) {
      return [false, true];
    }

    return [false, false];
  }, [comparatorValue]);

  // observe filter value state, to enable/disable the active state of the 'add' button
  const filterValue = watch('value');
  const hasValue = !!filterValue?.single ?? !!filterValue?.multiple?.length;

  // focus the default input, or the submit button when no input field
  useEffect(() => {
    if (hasSingleValue) {
      setFocus('value.single');
    } else if (hasMultipleValue) {
      setFocus('value.multiple');
    } else {
      submitBtnRef.current?.focus();
    }
  }, [hasMultipleValue, hasSingleValue, setFocus]);

  // handle key events within an input
  const [pendingClear, setPendingClear] = useState(false);
  const handleInputKeyUp: KeyboardEventHandler<HTMLInputElement> = (
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    const { code, currentTarget } = event;
    if (currentTarget?.value === '') {
      if (code !== 'Escape' && code !== 'Backspace') {
        setPendingClear(false);
      } else if (code === 'Escape' || pendingClear) {
        setPendingClear(false);
        onCancel();
      } else if (code === 'Backspace') {
        setPendingClear(true);
      }
    } else {
      setPendingClear(false);
    }
  };

  const onSubmit = ({ comparator, value }: FormNewFilterFieldValues) => {
    if (hasSingleValue) {
      afterSubmit({ comparator, value: value.single } as ResourceFilter);
    } else if (hasMultipleValue) {
      afterSubmit({ comparator, value: value.multiple } as ResourceFilter);
    } else {
      afterSubmit({ comparator, value: true } as ResourceFilter);
    }
  };

  const resourceKindDisplayLabel = useResourceKindDisplayLabel(filter.kind);
  const filterDefinitionDisplayName =
    filter.name ?? `${resourceKindDisplayLabel}:${filter.key}`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ margin: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          paddingX: 2,
          marginRight: -1,
        }}
      >
        <Typography
          component="span"
          color="text.secondary"
          sx={{ paddingRight: 2 }}
        >
          {filterDefinitionDisplayName}
        </Typography>

        <Controller
          control={control}
          name="comparator"
          render={({ field: { ref, ...fieldProps } }) => (
            <Select
              {...fieldProps}
              inputRef={ref}
              // NOTE: using base input for consistent styling
              input={<ControlInputBase />}
            >
              {comparators.map(({ comparator, name }) => (
                <MenuItem key={comparator} value={comparator}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
        />

        <Box sx={{ flexGrow: 1 }}>
          {/* hide the value field when using a filter without values */}

          {hasSingleValue && !filter.options && (
            <Controller
              control={control}
              name="value.single"
              defaultValue={''}
              rules={{ required: true, minLength: 1 }}
              render={({ field: { ref, ...fieldProps }, fieldState }) => (
                // NOTE: using base input for consistent styling
                <ControlInputBase
                  {...fieldProps}
                  inputRef={ref}
                  autoComplete="off"
                  placeholder={filter.name}
                  fullWidth
                  error={!!fieldState.error}
                  title={fieldState.error?.message}
                  onKeyUp={handleInputKeyUp}
                />
              )}
            />
          )}

          {hasSingleValue && filter.options && (
            <Controller
              control={control}
              name="value.single"
              rules={{ required: true }}
              render={({
                field: { ref, onChange, value, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  freeSolo // allow free input
                  value={value as string}
                  onChange={(_, value) => onChange(value)}
                  options={filter.options as string[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!fieldState.error}
                      inputRef={ref}
                      variant="standard"
                      placeholder={filter.name}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{
                        verticalAlign: 'inherit',
                        '& .MuiInput-root': {
                          alignItems: 'baseline',
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          )}

          {hasMultipleValue && (
            <Controller
              control={control}
              name="value.multiple"
              defaultValue={[]}
              rules={{ required: true }}
              render={({
                field: { ref, onChange, ...fieldProps },
                fieldState,
              }) => (
                <Autocomplete
                  {...fieldProps}
                  onChange={(_, value) => onChange(value)}
                  multiple
                  freeSolo
                  options={filter.options ?? []}
                  disableClearable
                  renderTags={(values, getTagProps) =>
                    values.map((option, index: number) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip
                        {...getTagProps({ index })}
                        label={option as ReactNode}
                        size="small"
                        deleteIcon={<IconX />}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputRef={ref}
                      variant="standard"
                      placeholder={filter.name}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{
                        verticalAlign: 'inherit',
                        '& .MuiInput-root': {
                          alignItems: 'baseline',
                        },
                      }}
                    />
                  )}
                />
              )}
            />
          )}

          <span></span>
        </Box>

        <Button
          ref={submitBtnRef}
          type="submit"
          className={formState.isDirty && hasValue ? 'active' : ''}
          sx={{
            alignSelf: 'center',
            border: 'none',
            height: '100%',
            marginLeft: 2,
            opacity: 0.5,
            backgroundColor: 'transparent',
            '&.active': {
              opacity: 1,
            },
            '&:hover,&:focus': {
              opacity: 1,
              backgroundColor: ({ palette }) => palette.action.hover,
            },
          }}
        >
          <FM defaultMessage="Add Filter" />
        </Button>

        <IconButton
          onClick={onCancel}
          sx={{
            alignSelf: 'center',
            marginLeft: 2,
            marginRight: -1,
            padding: 1,
            color: 'text.secondary',
            '&:hover,&:focus': {
              backgroundColor: ({ palette }) => palette.action.hover,
            },
          }}
          title="Cancel"
        >
          <IconX />
        </IconButton>
      </Box>
    </form>
  );
};
