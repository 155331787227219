import { useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ContextContextType } from '@endorlabs/api_client';
import { useQueryPackageVersions } from '@endorlabs/queries';
import { ButtonLinkSecondary, ButtonStack } from '@endorlabs/ui-common';

import {
  DetailDrawerContainer,
  DetailDrawerScoreSection,
} from '../../../../components/DetailDrawer';
import { getPackageVersionPath } from '../../../../routes';
import { DependencyDetailDrawerHeader } from './DependencyDetailDrawerHeader';
import { DependencyDetailDrawerOverviewSection } from './DependencyDetailDrawerOverviewSection';
import { DependencyDetailDrawerProps } from './types';

const DependencyDetailDrawerTabRecords = [
  {
    label: <FM defaultMessage="Overview" />,
    value: 'overview',
  },
  {
    label: <FM defaultMessage="Scores" />,
    value: 'scores',
  },
];

/**
 * Detail Info Drawer for a Dependency of a Package Version or Repository Version
 */
export const DependencyDetailDrawer = (props: DependencyDetailDrawerProps) => {
  const { name: dependencyPackageVersionName, namespace, uuid } = props;
  const { space } = useTheme();

  const qQueryDependencyPackageVersion = useQueryPackageVersions(
    namespace as string,
    { enabled: !!namespace && !!uuid },
    { filter: `uuid=="${uuid}"` }
  );
  const dependencyPackageVersion =
    qQueryDependencyPackageVersion.data?.list?.objects[0];
  const dependencyPackageVersionMetric =
    dependencyPackageVersion?.meta.references.Metric?.list?.objects[0];
  const scoreCard =
    dependencyPackageVersionMetric?.spec.metric_values?.scorecard.score_card;
  const scoreFactors =
    dependencyPackageVersionMetric?.spec.metric_values.scorefactor
      .score_factor_list.score_factors;

  // get package link - either within the tenant, or OSS Explorer
  const packageLink = dependencyPackageVersion
    ? getPackageVersionPath({
        tenantName: dependencyPackageVersion.tenant_meta.namespace,
        uuid: dependencyPackageVersion.uuid,
      })
    : undefined;

  const showPackageLink =
    packageLink &&
    dependencyPackageVersion?.context.type !== ContextContextType.External;

  const overviewContent = (
    <DependencyDetailDrawerOverviewSection
      {...props}
      isLoading={qQueryDependencyPackageVersion.isLoading}
      dependencyPackageVersion={dependencyPackageVersion}
      name={dependencyPackageVersionName}
    />
  );

  const scoresContent = (
    <DetailDrawerScoreSection
      scoreCard={scoreCard}
      scoreFactors={scoreFactors}
      isLoading={qQueryDependencyPackageVersion.isLoading}
    />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        action: showPackageLink && (
          <ButtonStack paddingTop={space.sm} paddingBottom={space.sm}>
            <ButtonLinkSecondary linkProps={{ to: packageLink }} size="small">
              <FM defaultMessage="View Details" />
            </ButtonLinkSecondary>
          </ButtonStack>
        ),
        title: (
          <DependencyDetailDrawerHeader
            dependencyPackageVersionName={
              dependencyPackageVersion?.meta.name ??
              dependencyPackageVersionName
            }
          />
        ),
      }}
      tabProps={{
        id: 'DependencyDetailDrawer',
        tabRecords: DependencyDetailDrawerTabRecords,
        tabPanelMap: {
          overview: overviewContent,
          scores: scoresContent,
        },
      }}
    />
  );
};
