import { uniq as _uniq } from 'lodash-es';

import { UserInfoResponseTenantInfo as TenantInfo } from '@endorlabs/api_client';
import { NamespaceResource } from '@endorlabs/endor-core/Namespace';
import { WithRequired } from '@endorlabs/utils';

import { buildNamespaceResourceFrom } from './buildNamespaceResourceFrom';

export const mapToNamespaceResources = (
  tenants: TenantInfo[],
  extraNamespaces?: string[]
): NamespaceResource[] => {
  const validTenants = tenants.filter(
    (v: TenantInfo): v is WithRequired<TenantInfo, 'name' | 'uuid'> =>
      !!v.name && !!v.uuid
  );

  const tenantNamespaces = validTenants.map((info) => info.name);

  const namespaces = _uniq([...tenantNamespaces, ...(extraNamespaces ?? [])])
    // NOTE: sorted by full namespace
    .sort((a, b) => a.localeCompare(b))
    .map((ns) => buildNamespaceResourceFrom(ns));

  return namespaces;
};
