import { Stack, StackProps, SvgIcon, Typography } from '@mui/material';

import { IconBox } from '../../themes/icons/FeatherIcons';

interface DependencyPathDisplayItemProps extends StackProps {
  bold?: boolean;
  IconCmp?: typeof SvgIcon;
  name: string;
}

export const DependencyPathDisplayItem = ({
  bold,
  IconCmp = IconBox,
  name,
  ...props
}: DependencyPathDisplayItemProps) => {
  const icon = (
    <IconCmp
      fontSize="small"
      sx={{ color: ({ palette }) => palette.text.secondary }}
    />
  );

  return (
    <Stack direction="row" gap={2} {...props}>
      {icon}
      <Typography fontWeight={bold ? 'bold' : undefined} variant="body2">
        {name}
      </Typography>
    </Stack>
  );
};
