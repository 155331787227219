import { useMatch } from '@tanstack/react-location';
import { FormattedMessage as FM } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import { getScanProfileQueryOptions } from '@endorlabs/queries';
import { EmptyState, useResourceCRUDMessages } from '@endorlabs/ui-common';

import { ScanProfileDetailPage } from '../../../../../../views/Settings/ScanProfiles';
import { createFileRoute } from '../../../../../utils/tanstack-react-router-shim';

/**
 * Detail Route for a Tenant Scan Profile
 *
 * Load:
 * - Prefetches Scan Profile by namespace and uuid
 *
 * Handle:
 * - Missing Resource (404)
 * - Errors loading data
 */
const TenantScanProfileDetailRoute = () => {
  const { data, error } = useMatch();
  const { getErrorMessage } = useResourceCRUDMessages();

  if (error) {
    const errorMessage = getErrorMessage('GET', 'ScanProfile', error);

    return (
      <EmptyState
        title={<FM defaultMessage="Unable to load Scan Profile details" />}
      >
        {errorMessage?.details}
      </EmptyState>
    );
  }

  const scanProfile = data.scanProfile as ScanProfileResource;

  return <ScanProfileDetailPage scanProfile={scanProfile} />;
};

export const Route = createFileRoute(
  '/t/:namespace/settings/scan-profiles/:scan_profile_uuid'
)({
  component: TenantScanProfileDetailRoute,
  loader: async ({ context, params }) => {
    const { namespace, scan_profile_uuid } = params;

    // Preload the ScanProfile data for the route
    const scanProfile = await context.queryClient.fetchQuery(
      getScanProfileQueryOptions(namespace, { uuid: scan_profile_uuid })
    );

    return {
      scanProfile,
    };
  },
});
