import { useMatch } from '@tanstack/react-location';

import { NewProjectGitlab } from '../../../../../views/Projects/NewProject/NewProjectGitlab';
import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const TenantCreateProjectGitlabRoute = () => {
  const {
    params: { namespace },
  } = useMatch();
  return <NewProjectGitlab namespace={namespace} />;
};

export const Route = createFileRoute('/t/:namespace/projects/new/gitlab')({
  component: TenantCreateProjectGitlabRoute,
});
