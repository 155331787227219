import { Message, RegisterOptions, ValidateResult } from 'react-hook-form';

import {
  REGEX_EMAIL_VALIDATION,
  REGEX_HTTP_STATUS_CODE_VALIDATION,
  REGEX_TAG_VALIDATION,
} from '../../constants';

export enum LabelsFieldValidationType {
  /**
   * Validate that all provided label values are valid as values in `meta.tags`
   */
  MetaTag = 'LABELS_FIELD_TYPE_META_TAG',
  HTTPStatusCode = 'LABELS_FIELD_TYPE_HTTP_STATUS_CODE',
  Email = 'LABELS_FIELD_TYPE_EMAIL',
}

export const VALIDATION_MESSAGES: Record<string, Message> = {
  INVALID_HTTP_STATUS_CODE: 'INVALID_HTTP_STATUS_CODE',
  INVALID_META_TAG: 'INVALID_META_TAG',
  INVALID_EMAIL: 'INVALID_EMAIL',
};

export type ValidationOptions = Partial<Pick<RegisterOptions, 'required'>> & {
  validate: (value: unknown[]) => ValidateResult;
};

const LabelsFieldValidationRules: Record<
  LabelsFieldValidationType,
  ValidationOptions
> = {
  [LabelsFieldValidationType.MetaTag]: {
    /**
     * Check the label length, and test against the validation regex
     */
    validate: (values: unknown[]) => {
      const isValid =
        Array.isArray(values) &&
        values.every((value) => {
          const label =
            typeof value === 'object' && value && 'label' in value
              ? value?.label
              : value;
          return (
            'string' === typeof label &&
            label.length < 64 &&
            REGEX_TAG_VALIDATION.test(label)
          );
        });
      return isValid || VALIDATION_MESSAGES.INVALID_META_TAG;
    },
  },
  [LabelsFieldValidationType.HTTPStatusCode]: {
    validate: (values: unknown[]) => {
      const isValid =
        Array.isArray(values) &&
        values.every((value) =>
          REGEX_HTTP_STATUS_CODE_VALIDATION.test(value + '')
        );

      return isValid || VALIDATION_MESSAGES.INVALID_HTTP_STATUS_CODE;
    },
  },
  [LabelsFieldValidationType.Email]: {
    validate: (values: unknown[]) => {
      const isValid =
        Array.isArray(values) &&
        values.every((value) => REGEX_EMAIL_VALIDATION.test(value + ''));

      return isValid || VALIDATION_MESSAGES.INVALID_EMAIL;
    },
  },
};

export const getLabelsFieldValidationRules = (
  validation?: LabelsFieldValidationType
): {
  rules?: ValidationOptions;
  validateSingleValue: (
    value: unknown
  ) => ReturnType<ValidationOptions['validate']>;
} => {
  const rules = validation ? LabelsFieldValidationRules[validation] : undefined;

  // wrapper around `rules.validate` to allow validating a single value
  const validateSingleValue = (value: unknown) => {
    if (rules?.validate && 'function' === typeof rules.validate) {
      return rules.validate([value]);
    }

    return true;
  };

  return { rules, validateSingleValue };
};
