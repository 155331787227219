import { minutesToMilliseconds } from 'date-fns';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  GLOBAL_TENANTS,
  selectFindingCountsFromGroupResponse,
  selectMetricScores,
  useQueryPackageVersions,
} from '@endorlabs/queries';
import {
  ExternalLink,
  FindingCountArrayDisplay,
  Link,
  MetadataMetric,
  NilDisplay,
  UIProjectUtils,
} from '@endorlabs/ui-common';

import { PageMetadataProps } from '../../components';
import { getProjectPath } from '../../routes';

const PACKAGE_VERSION_METADATA_STALE_TIME = minutesToMilliseconds(15);

/**
 * Utility to fetch Metadata props for a given Package Version
 */
export const usePackageVersionMetadata = (
  namespace: string,
  isGlobalTenant: boolean,
  packageVersionUuid?: string
): PageMetadataProps => {
  // The following resources are pulled through this query:
  // - PackageVersion
  // - Project
  // - Finding Counts
  // - Metric Scores
  const qQueryPackageVersions = useQueryPackageVersions(
    namespace,
    {
      enabled: !!packageVersionUuid,
      staleTime: PACKAGE_VERSION_METADATA_STALE_TIME,
    },
    {
      filter: `uuid=="${packageVersionUuid}"`,
      page_size: 1,
    }
  );

  const packageVersion = qQueryPackageVersions.data?.list?.objects[0];

  return useMemo(() => {
    const { FindingsGroupByLevel, Metric, Project } =
      packageVersion?.meta.references ?? {};

    const scores = selectMetricScores(Metric?.list?.objects[0]);
    const findingCounts = selectFindingCountsFromGroupResponse(
      FindingsGroupByLevel?.group_response
    );

    // build up the summary metadata, based on the tenant type
    const summary: MetadataMetric[] = [];
    if (isGlobalTenant) {
      summary.push(
        {
          label: <FM defaultMessage="Security" />,
          value: scores.scoreSecurity?.score,
          variant: 'score',
        },
        {
          label: <FM defaultMessage="Activity" />,
          value: scores.scoreActivity?.score,
          variant: 'score',
        },
        {
          label: <FM defaultMessage="Popularity" />,
          value: scores.scorePopularity?.score,
          variant: 'score',
        },
        {
          label: <FM defaultMessage="Quality" />,
          value: scores.scoreCodeQuality?.score,
          variant: 'score',
        }
      );
    } else {
      summary.push({
        label: <FM defaultMessage="Findings" />,
        value: <FindingCountArrayDisplay value={findingCounts} />,
        variant: 'raw',
      });
    }

    if (Project?.list?.objects.length) {
      const project = Project.list?.objects[0];
      const projectName = UIProjectUtils.parseProjectName(project.meta.name);
      const projectLink = getProjectPath({
        tenantName: project.tenant_meta.namespace,
        uuid: project.uuid,
      });

      // Add link to the related project
      if (!GLOBAL_TENANTS.includes(project.tenant_meta.namespace)) {
        summary.push({
          label: <FM defaultMessage="Project" />,
          value: <Link to={projectLink}>{projectName}</Link>,
          variant: 'raw',
        });
      }
    }

    const repositoryLink =
      packageVersion?.spec?.source_code_reference?.http_clone_url?.replace(
        /\.git$/,
        ''
      );

    summary.push({
      label: <FM defaultMessage="Repository" />,
      value: repositoryLink ? (
        <ExternalLink to={repositoryLink}>{repositoryLink}</ExternalLink>
      ) : (
        <NilDisplay variant="text" />
      ),
      variant: 'raw',
    });

    return {
      summary,
    };
  }, [isGlobalTenant, packageVersion]);
};
