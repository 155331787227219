import { Box, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Permissions } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  ExpirationTimeDisplay,
  IconTrash,
  PermissionsDisplay,
} from '@endorlabs/ui-common';

export type ApiKeysTableRowDeleteHandler = (row: ApiKeysTableRow) => void;

export interface ApiKeysTableProps
  extends Omit<DataTableProps<ApiKeysTableRow>, 'columns'> {
  activeNamespace: string;
  onDelete?: ApiKeysTableRowDeleteHandler;
}

export interface ApiKeysTableRow {
  uuid: string;
  name: string;
  namespace: string;
  keyId?: string;
  expires: string;
  permissions?: V1Permissions;
}

/**
 * Build the Table columns
 * Include the Delete column when `onDelete` handler is provided
 */
const buildApiKeysTableColDefs = ({
  activeNamespace,
  onDelete,
}: Pick<ApiKeysTableProps, 'activeNamespace' | 'onDelete'>) => {
  const columns: DataTableColumnDef<ApiKeysTableRow>[] = [
    {
      accessorKey: 'name',
      header: () => <FM defaultMessage="Name" />,
      cell: ({ getValue, row }) => {
        const shouldHighlight = activeNamespace === row.original.namespace;

        return (
          <Typography
            sx={{
              minWidth: '32ch',
              maxWidth: '64ch',
              wordBreak: 'break-all',
              fontWeight: shouldHighlight ? 600 : undefined,
            }}
          >
            {getValue()}
          </Typography>
        );
      },
      colType: ColTypes.TEXT_LONG,
    },
    {
      accessorKey: 'keyId',
      header: () => <FM defaultMessage="Key ID" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'expires',
      cell: ({ getValue }) => <ExpirationTimeDisplay value={getValue()} />,
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Expires" />,
    },
    {
      accessorKey: 'permissions',
      header: () => <FM defaultMessage="Permissions" />,
      cell: (info) => <PermissionsDisplay value={info.getValue()} />,
      colType: ColTypes.TAGS,
    },
  ];

  if (onDelete) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => (
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={() => onDelete(row.original)} size="small">
            <IconTrash fontSize="inherit" />
          </IconButton>
        </Box>
      ),
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

export const ApiKeysTable = ({
  activeNamespace,
  onDelete,
  ...props
}: ApiKeysTableProps) => {
  const columns = useMemo(
    () => buildApiKeysTableColDefs({ activeNamespace, onDelete }),
    [activeNamespace, onDelete]
  );

  return <DataTable {...props} columns={columns} />;
};
