import { Box, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { mapValues as _mapValues } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import {
  isSASTFinding,
  isSecurityFinding,
  isSelfFinding,
  isVulnerabilityFinding,
} from '@endorlabs/endor-core/Finding';
import { useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  DependencyPathDisplay,
  EmptyState,
  FindingNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { FindingCallPathsDrawerContent } from '../../../components';
import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
  DetailDrawerTabs,
} from '../../../components/DetailDrawer';
import { useFindingDetailData } from '../../../domains/Findings';
import { FINDINGS_DETAIL_DRAWER_TABS } from '../../../domains/Findings/components/FindingDetailDrawer/constants';
import { getTargetDependencyEcosystem } from '../../../domains/Findings/components/FindingDetailDrawer/utils';
import { FindingExceptionPoliciesSection } from '../../../domains/Findings/components/FindingExceptionPoliciesSection';
import { FindingFixInfoSection } from '../../../domains/Findings/components/FindingFixInfoSection';
import { FindingMetadataSection } from '../../../domains/Findings/components/FindingMetadataSection';
import { FindingPrimaryAttributesSection } from '../../../domains/Findings/components/FindingPrimaryAttributesSection';
import { FindingRemediationsSection } from '../../../domains/Findings/components/FindingRemediationsSection';
import { FindingRiskDetailsSection } from '../../../domains/Findings/components/FindingRiskDetailsSection';
import { FindingRiskStatsSection } from '../../../domains/Findings/components/FindingRiskStatsSection';
import { FindingRuleDrawer } from '../../../domains/Findings/components/FindingSASTDetailDrawer';
import { FindingInfoDrawer } from '../../../domains/Findings/components/FindingSASTDetailDrawer/FindingSASTInfoDrawer';
import { useAuthInfo } from '../../../providers';
import { getFindingsPath, useFullMatch } from '../../../routes';
import { FindingDetailPageLocation } from './types';
import { getFindingErrorMessage } from './utils';

export const FindingDetailPage = () => {
  const { activeNamespace: namespace } = useAuthInfo();
  const {
    params: { findingUuid },
  } = useFullMatch<FindingDetailPageLocation>();

  const { ENABLE_SAST_FEATURE: isSASTFeatureEnabled } = useFeatureFlags();

  const {
    exceptionPolicies,
    finding,
    isLoading,
    packageVersion,
    project,
    versionUpgrade,
    endorPatchVersionUpgrade,
    sastRuleForFinding,
    error,
  } = useFindingDetailData({
    findingUuid,
    namespace,
  });

  const isSecurity = finding ? isSecurityFinding(finding) : false;
  const isVuln = finding ? isVulnerabilityFinding(finding) : false;
  const isSAST = finding
    ? isSASTFeatureEnabled && isSASTFinding(finding)
    : false;
  const hasRemediations = !!versionUpgrade || !!endorPatchVersionUpgrade;

  const drawerTabRecords = useMemo(() => {
    if (!finding) return [];
    if (isSAST)
      return FINDINGS_DETAIL_DRAWER_TABS.filter((t) =>
        ['sastInfo', 'sastRule'].includes(t.value)
      );
    const tabs = FINDINGS_DETAIL_DRAWER_TABS.filter(
      (t) => !['sastInfo', 'sastRule'].includes(t.value)
    );
    if (!isSecurity)
      return tabs.filter((t) => ['info', 'depPath'].includes(t.value));
    return tabs;
  }, [finding, isSAST, isSecurity]);

  const tabContent = useMemo(() => {
    const infoContent = (
      <DetailDrawerSectionStack>
        {hasRemediations && (
          <FindingRemediationsSection
            findingUuid={finding?.uuid}
            namespace={namespace}
          />
        )}

        {isVuln && (
          <FindingRiskStatsSection finding={finding} namespace={namespace} />
        )}

        <FindingPrimaryAttributesSection
          finding={finding}
          isLoading={isLoading}
          namespace={namespace}
          packageVersion={packageVersion}
          project={project}
        />

        {exceptionPolicies.length > 0 && (
          <FindingExceptionPoliciesSection
            exceptionPolicies={exceptionPolicies}
          />
        )}

        <FindingRiskDetailsSection
          finding={finding}
          isLoading={isLoading}
          namespace={namespace}
        />

        {isSecurity && (
          <FindingMetadataSection
            finding={finding}
            isLoading={isLoading}
            namespace={namespace}
          />
        )}
      </DetailDrawerSectionStack>
    );

    const depPathContent = (
      <DetailDrawerSection>
        <DependencyPathDisplay
          dependencyGraph={
            (packageVersion?.spec?.resolved_dependencies
              ?.dependency_graph as unknown as Record<string, string[]>) ??
            ({} as Record<string, string[]>)
          }
          displayAsAccordion={false}
          isLoading={isLoading}
          project={project}
          sourcePackageNames={[packageVersion?.meta?.name ?? '']}
          targetPackageName={UIPackageVersionUtils.deriveFullPackageName({
            ecosystem:
              finding && packageVersion
                ? getTargetDependencyEcosystem(finding, packageVersion)
                : V1Ecosystem.Unspecified,
            name: finding?.spec.target_dependency_name ?? '',
            version: finding?.spec.target_dependency_version ?? '',
          })}
        />
      </DetailDrawerSection>
    );

    const fixInfo = (
      <FindingFixInfoSection
        finding={finding}
        isLoading={isLoading}
        namespace={namespace}
      />
    );

    const callPath = (
      <DetailDrawerSection>
        <FindingCallPathsDrawerContent
          finding={finding}
          findingPackageVersion={packageVersion}
          findingProject={project}
          isLoading={isLoading}
        />
      </DetailDrawerSection>
    );

    const sastInfoContent = (
      <Box width="55%">
        <FindingInfoDrawer
          sastRuleForFinding={sastRuleForFinding}
          finding={finding}
          isLoading={isLoading}
          project={project}
          namespace={namespace}
        />
      </Box>
    );

    const sastRuleContent = (
      <FindingRuleDrawer sastRuleForFinding={sastRuleForFinding} />
    );

    const tabComponents = {
      callPath,
      depPath:
        finding && packageVersion && !isSelfFinding(finding)
          ? depPathContent
          : undefined,
      fix: fixInfo,
      info: infoContent,
      sastInfo: sastInfoContent,
      sastRule: sastRuleContent,
    };

    return _mapValues(tabComponents, (component) => (
      <Card>
        <CardContent>{component}</CardContent>
      </Card>
    ));
  }, [
    exceptionPolicies,
    finding,
    hasRemediations,
    isLoading,
    isSecurity,
    isVuln,
    namespace,
    packageVersion,
    project,
    sastRuleForFinding,
  ]);

  const hasError = !isLoading && error;

  if (hasError) {
    const { title, description } = getFindingErrorMessage(error);
    return (
      <EmptyState size="large" title={title} description={description}>
        <ButtonLinkPrimary
          linkProps={{ to: getFindingsPath({ tenantName: namespace }) }}
        >
          <FM defaultMessage="View All Findings" />
        </ButtonLinkPrimary>
      </EmptyState>
    );
  }

  return (
    <Grid>
      <Grid item>
        <FindingNameDisplay finding={finding} size="large" />
      </Grid>
      <Grid item>
        {!finding ? (
          <Skeleton />
        ) : (
          <DetailDrawerTabs
            id="FindingDetailDrawer"
            tabRecords={drawerTabRecords}
            tabPanelMap={tabContent}
          />
        )}
      </Grid>
    </Grid>
  );
};
