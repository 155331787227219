import { Box, BoxProps, Theme } from '@mui/material';

import { useStyles } from '@endorlabs/ui-common';

import { Layout } from '../../constants';
import {
  DetailDrawerHeader,
  DetailDrawerHeaderProps,
} from './DetailDrawerHeader';
import { DetailDrawerTabs, DetailDrawerTabsProps } from './DetailDrawerTabs';

interface DetailDrawerContainerProps extends BoxProps {
  headerProps: DetailDrawerHeaderProps;
  tabProps?: DetailDrawerTabsProps;
}

/**
 * Layout component to keep detail drawer layout simple
 */
export const DetailDrawerContainer = ({
  children,
  headerProps,
  tabProps,
  ...props
}: DetailDrawerContainerProps) => {
  const sx = useStyles(styles);

  return (
    <Box className="DetailDrawerContainer-root" {...props} sx={sx}>
      <DetailDrawerHeader {...headerProps} />
      {tabProps && <DetailDrawerTabs {...tabProps} />}

      {!tabProps && (
        <Box className="DetailDrawerContainer-content">{children}</Box>
      )}
    </Box>
  );
};

const styles = ({ palette, space, spacing }: Theme) => ({
  '&.DetailDrawerContainer-root': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'hidden',

    '&:not(:has(.DetailDrawerTabs-root)) .DetailDrawerHeader-root': {
      borderBottom: `1px solid ${palette.divider}`,
      paddingBottom: space.sm,
    },
  },

  '& .DetailDrawerHeader-root': {
    flexShrink: 0,
  },

  '& .DetailDrawerTabs-root': {
    flexShrink: 0,
  },

  '& .DetailDrawerContainer-content': {
    flexShrink: 2,
    paddingBottom: space.md,
    paddingTop: space.sm,
    overflowY: 'auto',
  },
});
