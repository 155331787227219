import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  TenantServiceApi,
  V1ListParameters,
  V1Tenant,
  V1UpdateTenantRequest,
} from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';

import { AuthenticationQueryKeys } from './auth';
import { TenantResource, TenantResourceList } from './types';
import { ResourceMutateOptions, ResourceQueryOptions } from './types';
import {
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

type ListTenantOptions = ResourceQueryOptions<TenantResourceList>;
type GetTenantOptions = ResourceQueryOptions<TenantResource>;
type CreateTenantOptions = ResourceMutateOptions<V1Tenant, V1Tenant>;
type UpsertTenantOptions = ResourceMutateOptions<V1Tenant, TenantWriteParams>;
type DeleteTenantOptions = ResourceMutateOptions<object, string>;

export interface TenantWriteParams {
  uuid: string;
  resource: V1Tenant;
}

interface TenantUpdateParams extends TenantWriteParams {
  mask?: string;
}

// Global tenants can be accessed by all users but are not exposed in tenant lists
export const GLOBAL_TENANTS: string[] = [NAMESPACES.OSS, NAMESPACES.ENDOR_OSS];
// Shared tenants can be accessed by all users, and are exposed, but do not "count" as a user tenant
export const SHARED_TENANTS: string[] = [NAMESPACES.DEMO_TRIAL];

const BASE_KEY = 'v1/tenants';
const QK = {
  list: (listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', listParams] as const,
  record: (uuid: string): QueryKey => [BASE_KEY, 'get', uuid] as const,
};
export const TenantsQueryKeys = QK;

export const TENANT_UPDATE_MASK = 'meta,spec';

const getApiService = () => new TenantServiceApi(getClientConfiguration());

const listTenants = async (listParams: V1ListParameters = {}) => {
  const api = getApiService();
  const resp = await api.tenantServiceListTenants(
    ...buildListParamArgs(listParams)
  );
  return resp.data as TenantResourceList;
};

export const useListTenants = (
  opts: ListTenantOptions = {},
  listParams?: V1ListParameters
) => {
  return useQuery(QK.list(listParams), () => listTenants(listParams), opts);
};

export const getTenant = async (tenantUuid: string) => {
  const api = getApiService();
  const resp = await api.tenantServiceGetTenant(tenantUuid);
  return resp.data as TenantResource;
};

export const useGetTenant = (
  tenantUuid: string,
  opts: GetTenantOptions = {}
) => {
  return useQuery(QK.record(tenantUuid), () => getTenant(tenantUuid), opts);
};

const createTenant = async (newTenant: V1Tenant) => {
  const api = getApiService();
  const resp = await api.tenantServiceCreateTenant(newTenant);
  return resp.data as TenantResource;
};

export const useCreateTenant = (opts: CreateTenantOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'Tenant'),
    mutationFn: (newTenant: V1Tenant) => createTenant(newTenant),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list());
        queryClient.invalidateQueries(AuthenticationQueryKeys.record());
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateTenant = async (params: TenantUpdateParams) => {
  const { resource, mask = TENANT_UPDATE_MASK } = params;
  const req: V1UpdateTenantRequest = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.tenantServiceUpdateTenant(req);
  return resp.data as TenantResource;
};

export const useUpdateTenant = (opts: UpsertTenantOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Tenant'),
    mutationFn: (params: TenantUpdateParams) => updateTenant(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list());
        queryClient.invalidateQueries(QK.record(vars.uuid));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteTenant = async (tenantUuid: string) => {
  const api = getApiService();
  const resp = await api.tenantServiceDeleteTenant(tenantUuid);
  return resp.data;
};

export const useDeleteTenant = (opts: DeleteTenantOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'Tenant'),
    mutationFn: (tenantUuid: string) => deleteTenant(tenantUuid),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list());
        queryClient.invalidateQueries(AuthenticationQueryKeys.record());
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
