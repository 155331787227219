import { clone as _clone, head as _head, sortBy as _sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { ContextContextType, SpecFindingLevel } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  buildQueryCall,
  CIRunResource,
  EMPTY_FINDING_LEVEL_COUNTS,
  FindingCount,
  ProjectResource,
  RepoVersionResource,
  ScanResultResource,
  sortParamBuilders,
  TResourceList,
  tryParseGroupResponseAggregationKey,
  useCountRepositoryVersions,
  useListFindings,
} from '@endorlabs/queries';
import { useDataTablePaginator } from '@endorlabs/ui-common';

import { StaleTimes } from '../../constants';

/**
 * Loads Repository Version and Scan Result data relevant to PR Runs
 */
export const useCIRunsData = ({
  namespace,
  project,
  searchValue,
}: {
  namespace: string;
  project?: ProjectResource;
  searchValue?: string;
}) => {
  const filterExpression = useMemo(() => {
    const expressions = [
      `meta.parent_uuid==${project?.uuid}`,
      `context.type==${ContextContextType.CiRun}`,
    ];

    if (searchValue) {
      const searchFilterExpression = filterExpressionBuilders.or([
        `context.id matches "${searchValue}"`,
        `context.tags matches "${searchValue}"`,
        `spec.version.sha matches "${searchValue}"`,
        `spec.version.ref matches "${searchValue}"`,
      ]);

      expressions.push(searchFilterExpression);
    }

    return filterExpressionBuilders.and(expressions);
  }, [project?.uuid, searchValue]);

  const qCountRepoVersions = useCountRepositoryVersions(
    namespace,
    {
      staleTime: StaleTimes.LONG,
      enabled: !!project?.uuid,
    },
    {
      filter: filterExpression,
    }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountRepoVersions.data?.count,
  });

  const qQueryCIRuns = buildQueryCall('RepositoryVersion', {
    filter: filterExpression,
    mask: ['context', 'uuid', 'meta.name', 'spec.version', 'tenant_meta'].join(
      ','
    ),
    sort: sortParamBuilders.descendingBy('meta.create_time'),
    ...paginator.getListParameters(),
  })
    .addReference(
      'CIRun',
      {
        mask: ['spec.end_time', 'spec.run_uuid'].join(','),
        page_size: 1,
      },
      {
        connect_from: 'context.id',
        connect_to: 'spec.run_uuid',
      }
    )
    .addReference(
      'ScanResult',
      {
        filter: `context.type==${ContextContextType.CiRun}`,
        mask: [
          'context.type',
          'spec.environment.config',
          'spec.exit_code',
          'spec.status',
          'spec.type',
          'uuid',
        ].join(','),
        page_size: 1,
        sort: sortParamBuilders.descendingBy('meta.create_time'),
      },
      {
        connect_from: 'context.id',
        connect_to: 'context.id',
        return_as: 'LatestScanResult',
      }
    )
    .useBuiltQuery(namespace, {
      enabled: !!project,
      staleTime: StaleTimes.MEDIUM,
    });

  const repositoryVersions = useMemo(() => {
    const queryResponse = qQueryCIRuns.data?.spec?.query_response as
      | undefined
      | TResourceList<
          RepoVersionResource & {
            meta: {
              references: {
                CIRun: TResourceList<Pick<CIRunResource, 'spec'>>;
                LatestScanResult: TResourceList<ScanResultResource>;
              };
            };
          }
        >;
    return queryResponse?.list?.objects ?? [];
  }, [qQueryCIRuns.data]);

  const contextIds = useMemo(() => {
    return repositoryVersions.map((o) => o.context.id).sort() ?? [];
  }, [repositoryVersions]);

  const qCIRunFindingGroups = useListFindings(
    namespace,
    { enabled: qQueryCIRuns.isSuccess && !!contextIds.length },
    {
      filter: filterExpressionBuilders.and([
        `context.type==${ContextContextType.CiRun}`,
        `context.id in ["${contextIds.join('","')}"]`,
      ]),
      group: { aggregation_paths: 'context.id,spec.level' },
    }
  );

  const data = useMemo(() => {
    const ciRunFindingGroupsByRunId = Object.entries(
      qCIRunFindingGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, [key, group]) => {
      const values = tryParseGroupResponseAggregationKey(key);

      const contextId = values.find((kv) => kv.key === 'context.id')?.value as
        | string
        | undefined;
      const findingLevel = values.find((kv) => kv.key === 'spec.level')
        ?.value as SpecFindingLevel | undefined;
      const count = group.aggregation_count?.count ?? 1;

      if (contextId && findingLevel) {
        let findingCounts = acc[contextId];
        if (!findingCounts) {
          findingCounts = _clone(EMPTY_FINDING_LEVEL_COUNTS);
        }

        const ix = findingCounts.findIndex((c) => c.level === findingLevel);
        if (ix !== -1) {
          findingCounts[ix] = { level: findingLevel, value: count };
        }

        acc[contextId] = findingCounts;
      }

      return acc;
    }, {} as Record<string, FindingCount[]>);

    const data =
      repositoryVersions.map((rv) => {
        const { CIRun, LatestScanResult } = rv.meta.references;

        const ciRun = _head(CIRun.list?.objects ?? []);
        const latestScanResults = LatestScanResult?.list?.objects ?? [];

        // NOTE: only show ref when different from SHA
        const versionSha = rv?.spec.version?.sha;
        let versionRef = rv?.spec.version?.ref;
        if (versionRef === versionSha) {
          versionRef = undefined;
        }

        const ciRunId = rv.context.id ?? '';
        const ciRunTags = _sortBy(rv.context.tags ?? []);

        return {
          namespace: rv.tenant_meta.namespace,
          ciRunId,
          versionRef,
          versionSha,
          endTime: ciRun?.spec.end_time,
          findingCounts: ciRunFindingGroupsByRunId[ciRunId],
          scanResults: latestScanResults,
          tags: ciRunTags,
        };
      }) ?? [];

    return data;
  }, [qCIRunFindingGroups.data, repositoryVersions]);

  const isEmptyState =
    !qCountRepoVersions.isLoading && qCountRepoVersions.data?.count === 0;

  const isLoading = qCountRepoVersions.isLoading || qQueryCIRuns.isLoading;
  const isSuccess = qQueryCIRuns.isSuccess;

  return {
    data,
    isEmptyState,
    isLoading,
    isLoadingRelated: qCIRunFindingGroups.isLoading,
    isSuccess,
    paginator,
  };
};

export const usePRRunsData = ({
  namespace,
  project,
  searchFilterExpression,
}: {
  namespace: string;
  project?: ProjectResource;
  searchFilterExpression?: string;
}) => {
  const filterExpression = useMemo(() => {
    const expressions = [
      `meta.parent_uuid==${project?.uuid}`,
      `context.type==${ContextContextType.CiRun}`,
    ];

    if (searchFilterExpression) {
      expressions.push(searchFilterExpression);
    }

    return filterExpressionBuilders.and(expressions);
  }, [project?.uuid, searchFilterExpression]);

  const qCountRepoVersions = useCountRepositoryVersions(
    namespace,
    {
      staleTime: StaleTimes.LONG,
      enabled: !!project?.uuid,
    },
    {
      filter: filterExpression,
    }
  );

  const paginator = useDataTablePaginator({
    totalCount: qCountRepoVersions.data?.count,
  });

  const qQueryPRRuns = buildQueryCall('RepositoryVersion', {
    filter: filterExpression,
    mask: ['context', 'uuid', 'meta.name', 'spec.version', 'tenant_meta'].join(
      ','
    ),
    sort: sortParamBuilders.descendingBy('meta.create_time'),
    ...paginator.getListParameters(),
  })
    .addReference(
      'CIRun',
      {
        mask: ['spec.end_time', 'spec.run_uuid'].join(','),
        page_size: 1,
      },
      {
        connect_from: 'context.id',
        connect_to: 'spec.run_uuid',
      }
    )
    .addReference(
      'ScanResult',
      {
        filter: `context.type==${ContextContextType.CiRun}`,
        mask: [
          'context.type',
          'spec.environment.config',
          'spec.exit_code',
          'spec.status',
          'spec.type',
          'uuid',
        ].join(','),
        page_size: 1,
        sort: sortParamBuilders.descendingBy('meta.create_time'),
      },
      {
        connect_from: 'context.id',
        connect_to: 'context.id',
        return_as: 'LatestScanResult',
      }
    )
    .useBuiltQuery(namespace, {
      enabled: !!project,
      staleTime: StaleTimes.MEDIUM,
    });

  const repositoryVersions = useMemo(() => {
    const queryResponse = qQueryPRRuns.data?.spec?.query_response as
      | undefined
      | TResourceList<
          RepoVersionResource & {
            meta: {
              references: {
                CIRun: TResourceList<Pick<CIRunResource, 'spec'>>;
                LatestScanResult: TResourceList<ScanResultResource>;
              };
            };
          }
        >;
    return queryResponse?.list?.objects ?? [];
  }, [qQueryPRRuns.data]);

  const contextIds = useMemo(() => {
    return repositoryVersions.map((o) => o.context.id).sort() ?? [];
  }, [repositoryVersions]);

  const qPRRunFindingGroups = useListFindings(
    namespace,
    { enabled: qQueryPRRuns.isSuccess && !!contextIds.length },
    {
      filter: filterExpressionBuilders.and([
        `context.type==${ContextContextType.CiRun}`,
        `context.id in ["${contextIds.join('","')}"]`,
      ]),
      group: { aggregation_paths: 'context.id,spec.level' },
    }
  );

  const data = useMemo(() => {
    const prRunFindingGroupsByRunId = Object.entries(
      qPRRunFindingGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, [key, group]) => {
      const values = tryParseGroupResponseAggregationKey(key);

      const contextId = values.find((kv) => kv.key === 'context.id')?.value as
        | string
        | undefined;
      const findingLevel = values.find((kv) => kv.key === 'spec.level')
        ?.value as SpecFindingLevel | undefined;
      const count = group.aggregation_count?.count ?? 1;

      if (contextId && findingLevel) {
        let findingCounts = acc[contextId];
        if (!findingCounts) {
          findingCounts = _clone(EMPTY_FINDING_LEVEL_COUNTS);
        }

        const ix = findingCounts.findIndex((c) => c.level === findingLevel);
        if (ix !== -1) {
          findingCounts[ix] = { level: findingLevel, value: count };
        }

        acc[contextId] = findingCounts;
      }

      return acc;
    }, {} as Record<string, FindingCount[]>);

    const data =
      repositoryVersions.map((rv) => {
        const { CIRun, LatestScanResult } = rv.meta.references;

        const prRun = _head(CIRun.list?.objects ?? []);
        const latestScanResults = LatestScanResult?.list?.objects ?? [];

        // NOTE: only show ref when different from SHA
        const versionSha = rv?.spec.version?.sha;
        let versionRef = rv?.spec.version?.ref;
        if (versionRef === versionSha) {
          versionRef = undefined;
        }

        const prRunId = rv.context.id ?? '';
        const prRunTags = _sortBy(rv.context.tags ?? []);

        return {
          namespace: rv.tenant_meta.namespace,
          prRunId,
          versionRef,
          versionSha,
          endTime: prRun?.spec.end_time,
          findingCounts: prRunFindingGroupsByRunId[prRunId],
          scanResults: latestScanResults,
          tags: prRunTags,
        };
      }) ?? [];

    return data;
  }, [qPRRunFindingGroups.data, repositoryVersions]);

  const isEmptyState =
    !qCountRepoVersions.isLoading && qCountRepoVersions.data?.count === 0;

  const isLoading = qCountRepoVersions.isLoading || qQueryPRRuns.isLoading;
  const isSuccess = qQueryPRRuns.isSuccess;

  return {
    data,
    isEmptyState,
    isLoading,
    isLoadingRelated: qPRRunFindingGroups.isLoading,
    isSuccess,
    paginator,
    qCountRepoVersions,
  };
};
