import { defineMessages, MessageDescriptor } from 'react-intl';

import {
  NotificationTargetActionActionType as ActionType,
  SpecNotificationState,
} from '@endorlabs/api_client';

export const NotificationTargetActionTypeMessages: Record<
  ActionType,
  MessageDescriptor
> = defineMessages({
  [ActionType.Email]: { defaultMessage: 'Email' },
  [ActionType.Jira]: { defaultMessage: 'Jira' },
  [ActionType.Webhook]: { defaultMessage: 'Webhook' },
  [ActionType.Vanta]: { defaultMessage: 'Vanta' },
  [ActionType.Slack]: { defaultMessage: 'Slack' },
  [ActionType.GithubPr]: { defaultMessage: 'GitHub PRs' },
  [ActionType.Unspecified]: { defaultMessage: 'Unspecified' },
});

export const SpecNotificationStateMessages: Record<
  SpecNotificationState,
  MessageDescriptor
> = defineMessages({
  [SpecNotificationState.Unspecified]: { defaultMessage: 'Unspecified' },
  [SpecNotificationState.OpenNotificationPending]: {
    defaultMessage: 'Open Notification Pending',
  },
  [SpecNotificationState.Open]: {
    defaultMessage: 'Open',
  },
  [SpecNotificationState.Dismissed]: {
    defaultMessage: 'Dismissed',
  },
  [SpecNotificationState.Snoozed]: {
    defaultMessage: 'Snoozed',
  },
  [SpecNotificationState.ResolvedNotificationPending]: {
    defaultMessage: 'Resolved Notification Pending',
  },
  [SpecNotificationState.Resolved]: {
    defaultMessage: 'Resolved',
  },
  [SpecNotificationState.Deleted]: {
    defaultMessage: 'Deleted',
  },
});
