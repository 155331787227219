import {
  Box,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { ElementType, ReactNode } from 'react';

import { TitleActionHeader } from '@endorlabs/ui-common';

import { PageBreadcrumbLink, PageBreadcrumbsV2 } from '../PageBreadcrumbs';
import { PageMetadata, PageMetadataProps } from '../PageMetadata';

interface PageHeaderProps {
  action?: ReactNode;
  breadcrumbsLabel?: ReactNode;
  breadcrumbsLinks?: PageBreadcrumbLink[];
  breadcrumbsItems?: ReactNode[];
  disableTypography?: boolean;
  Icon?: ElementType | null;
  image?: ReactNode;
  isLoading?: boolean;
  title: ReactNode;
  titleDetails?: ReactNode;
  metadata?: PageMetadataProps;
}

/**
 * A layout component modeled off of [`CardHeader`](https://material-ui.com/api/card-header/).
 * Contains title, breadcrumbs, & top-level actions for a page.
 */
export const PageHeader = ({
  action,

  breadcrumbsItems = [],
  disableTypography,
  Icon,
  image,
  isLoading = false,
  title,
  titleDetails,
  metadata,
}: PageHeaderProps) => {
  const sx = styles(useTheme());
  const icon = Icon ? <Icon fontSize="xlarge" /> : null;

  let titleNode = title;
  if (!disableTypography) {
    titleNode = (
      <Typography variant="h1">
        {isLoading ? <Skeleton height={48} width={240} /> : title}
      </Typography>
    );
  }

  return (
    <Grid container direction="column">
      <Grid sx={sx.breadcrumbs} item xs={12}>
        {isLoading ? (
          <Skeleton width="20%" />
        ) : (
          <PageBreadcrumbsV2 breadcrumbsItems={breadcrumbsItems} />
        )}
      </Grid>

      <Grid
        alignItems="center"
        container
        item
        justifyContent="space-between"
        sx={sx.titleAndAction}
      >
        <TitleActionHeader
          action={isLoading ? <Skeleton /> : action}
          disableTypography
          title={
            <Stack alignItems="center" direction="row" spacing={2}>
              {icon && <Box sx={sx.icon}>{icon}</Box>}
              {image && <Box sx={sx.icon}>{image}</Box>}
              {titleNode}

              {titleDetails && titleDetails}
            </Stack>
          }
        />

        {metadata && (
          <Grid item xs={12}>
            <PageMetadata {...metadata} />
            <Divider />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

function styles({ breakpoints, spacing, space }: Theme) {
  return {
    titleAndAction: {
      [breakpoints.between('sm', 'md')]: {
        order: 2,
      },
    },
    icon: {
      alignItems: 'center',
      display: 'flex',
    },
    action: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexShrink: 0,

      [breakpoints.down('md')]: {
        justifyContent: 'flex-start',
        marginTop: spacing(3),
      },
    },
    breadcrumbs: {
      marginBottom: space.md,

      [breakpoints.down('md')]: {
        order: 1,
      },
    },
  };
}
