import { Box, Tooltip, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { Row, Table } from '@tanstack/react-table';
import { isNil as _isNil } from 'lodash-es';
import { ForwardedRef, forwardRef, MouseEvent } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { compareFindingCounts, PackageContexture } from '@endorlabs/queries';
import {
  CiWorkflowNameDisplay,
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  FindingCountArrayDisplay,
  GITHUB_ACTION_PACKAGE_SUFFIX,
  Link,
  NilDisplay,
  NumberDisplay,
  PackageNameDisplay,
  ProjectNameDisplay,
  ResolutionErrorStatusIndicator,
  UIEventUtils,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { PackageVersionsTableColumnId, PackageVersionsTableRow } from './types';

const getRepository = (row: Row<PackageVersionsTableRow>) => {
  const { namespace, projectName, repositoryUrl } = row.original || {};

  const projectDisplayName =
    namespace === NAMESPACES.OSS ? repositoryUrl : projectName;
  // Projects under oss are not currently routable - link direct to the repo url
  const projectLink =
    namespace === NAMESPACES.OSS ? repositoryUrl : row.original.projectLink;

  return projectDisplayName ? (
    <Link
      to={projectLink}
      disabled={!projectLink}
      onClick={(event) => event.stopPropagation()}
    >
      <ProjectNameDisplay
        name={projectDisplayName}
        showIcon={false}
        platformSource={row.original?.projectPlatformSource}
      />
    </Link>
  ) : (
    <Tooltip
      title={
        <FM defaultMessage="No Repository Found for this Package Version" />
      }
    >
      <Typography component="span">
        <NilDisplay fontSize="inherit" />
      </Typography>
    </Tooltip>
  );
};

const getNameHeader = (packageContexture: PackageContexture) => {
  switch (packageContexture) {
    case PackageContexture.Dependents:
      return <FM defaultMessage="Dependent Package" />;
    case PackageContexture.GithubActions:
      return <FM defaultMessage="CI Workflow" />;
    case PackageContexture.Packages:
    default:
      return <FM defaultMessage="Package" />;
  }
};

export interface PackageVersionsTableProps
  extends Omit<DataTableProps<PackageVersionsTableRow>, 'columns'> {
  packageContexture:
    | PackageContexture.Packages
    | PackageContexture.Dependents
    | PackageContexture.GithubActions;
  onClickDetail?: (row?: PackageVersionsTableRow) => void;
  onClickResolutionErrors?: (row?: PackageVersionsTableRow) => void;
  includeColumns?: PackageVersionsTableColumnId[];
  showVersion?: boolean;
}

const buildPackageVersionsTableColumns = ({
  packageContexture,
  onClickDetail,
  onClickResolutionErrors,
  includeColumns,
  showVersion,
}: Omit<
  PackageVersionsTableProps,
  'data'
>): DataTableColumnDef<PackageVersionsTableRow>[] => {
  const activeColumns = new Set<PackageVersionsTableColumnId>(includeColumns);

  const nameHeader = getNameHeader(packageContexture);

  const columns: DataTableColumnDef<PackageVersionsTableRow>[] = [
    {
      colType: ColTypes.STATUS_INDICATOR,
      accessorKey: 'resolutionErrors',
      cell: ({ getValue, row }) => (
        <ResolutionErrorStatusIndicator
          errors={getValue()}
          onClick={
            onClickResolutionErrors
              ? (event) => {
                  event.stopPropagation();
                  onClickResolutionErrors(row.original);
                }
              : undefined
          }
        />
      ),
      header: '',
      hidden: !onClickResolutionErrors,
    },
    {
      accessorKey: 'name',
      cell: ({ getValue, row }) => {
        const packageName = getValue() as string;
        const { ecosystem } =
          UIPackageVersionUtils.parsePackageName(packageName);
        if (ecosystem === V1Ecosystem.GithubAction) {
          return (
            <CiWorkflowNameDisplay
              name={packageName}
              showIcon={true}
              relativePath={row.original.relativePath}
              suffix={GITHUB_ACTION_PACKAGE_SUFFIX}
            />
          );
        }
        return (
          <PackageNameDisplay name={packageName} showVersion={showVersion} />
        );
      },
      colType: ColTypes.PACKAGE,
      header: () => nameHeader,
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('versionRef'),
      accessorKey: 'versionRef',
      cell: ({ getValue }) => <Typography>{getValue()}</Typography>,
      colType: ColTypes.VERSION,
      header: () =>
        packageContexture === PackageContexture.Dependents ? (
          <FM defaultMessage="Package Version" />
        ) : (
          <FM defaultMessage="Version" />
        ),
      enableSorting: true,
    },
    {
      hidden: !activeColumns.has('repository'),
      id: 'repository',
      cell: ({ row }) => getRepository(row),
      header: () => <FM defaultMessage="Repository" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
  ];

  // Show findings when in a "My Packages" context
  if (
    packageContexture === PackageContexture.Packages ||
    packageContexture === PackageContexture.GithubActions
  ) {
    columns.push(
      {
        accessorKey: 'dependenciesCount',
        cell: ({ getValue }) => {
          const count = getValue();
          // handle `undefined` value
          return _isNil(count) ? (
            <NilDisplay variant="text" />
          ) : (
            <NumberDisplay value={count} />
          );
        },
        colType: ColTypes.NUMBER,
        header: () => <FM defaultMessage="Dependencies" />,
        enableSorting: true,
      },
      {
        accessorKey: 'findingCounts',
        cell: ({ getValue }) => <FindingCountArrayDisplay value={getValue()} />,
        colType: ColTypes.FINDING_COUNTS,
        header: () => <FM defaultMessage="Findings" />,
        enableSorting: true,
        sortingFn: (a, b) =>
          compareFindingCounts(
            a.original?.findingCounts,
            b.original?.findingCounts
          ),
      }
    );
  }

  // Show the actions
  if (onClickDetail) {
    columns.push({
      id: 'actions',
      cell: ({ row }) =>
        row.original && (
          <Box display="flex" justifyContent="end">
            <DataTableDrawerButton
              onClick={(event) => {
                event.stopPropagation();
                onClickDetail(row.original);
                if (!row.getIsSelected()) row.toggleSelected();
              }}
            />
          </Box>
        ),
      colType: ColTypes.ACTIONS,
      header: '',
    });
  }

  // HACK: removing columns marked as hidden
  return columns.filter((col) => !col.hidden);
};

/**
 * Table intended for displaying a list of Package Versions
 *
 * For dependency packages, prefer {@see DependenciesTable}
 */
export const PackageVersionsTable = forwardRef(
  function PackageVersionsTableComponent(
    {
      packageContexture,
      onClickDetail,
      includeColumns,
      onClickResolutionErrors,
      showVersion,
      ...props
    }: PackageVersionsTableProps,
    ref: ForwardedRef<Table<PackageVersionsTableRow>>
  ) {
    const navigate = useNavigate();
    const columns = buildPackageVersionsTableColumns({
      packageContexture,
      onClickDetail,
      includeColumns,
      onClickResolutionErrors,
      showVersion,
    });

    function handleRowClick(
      row: PackageVersionsTableRow,
      _: Row<PackageVersionsTableRow>,
      evt: MouseEvent
    ) {
      if (row.link) {
        return UIEventUtils.simulateLinkClick(row.link, evt, navigate);
      }

      if (onClickDetail) {
        return onClickDetail(row);
      }
    }

    /* User should select only one row at a time when displaying row detail. */
    const isSingleRowSelect = onClickDetail ? true : false;
    return (
      <DataTable
        {...props}
        tableId="package-versions"
        ref={ref}
        columns={columns}
        onRowClick={handleRowClick}
        isSingleRowSelect={isSingleRowSelect}
      />
    );
  }
);
