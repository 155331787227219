import { AuthInfoProvider } from '../../providers';
import { SecureRoute } from '../components/SecureRoute';
import { NamedRoutes } from '../constants';
import { createFileRoute } from '../utils/tanstack-react-router-shim';

const TenantCreateRoute = () => (
  <AuthInfoProvider>
    <SecureRoute />
  </AuthInfoProvider>
);

export const Route = createFileRoute(NamedRoutes.TENANT_CREATE)({
  component: TenantCreateRoute,
});
