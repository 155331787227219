import { Theme } from '@mui/material';
import { spacing } from '@mui/system';

export const getBreadcrumbOverrides = ({ palette, spacing }: Theme) => {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .MuiLink-root': {
            color: palette.text.secondary,

            '&:hover': {
              color: palette.text.primary,
            },
          },
        },
      },
    },
  };
};
