export const TShirtSizes = {
  xsmall: 'xsmall',
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge',
} as const;

export type TShirtSize =
  | (typeof TShirtSizes)[keyof typeof TShirtSizes]
  | undefined;
