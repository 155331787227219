import { Box, Switch } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PolicyExceptionReason } from '@endorlabs/api_client';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
} from '@endorlabs/ui-common';

import { ExceptionPolicyReasonLabels, messages } from '../locales';

export type ExceptionPoliciesTableRowDeleteHandler = (
  row?: ExceptionPoliciesTableRow
) => void;

export type ExceptionPoliciesTableRowActionHandler = (args: {
  namespace: string;
  uuid: string;
}) => void;

export interface ExceptionPoliciesTableRow {
  description?: string;
  disable?: boolean;
  expiration?: string;
  labels?: string[];
  lastTriggered?: string;
  name: string;
  namespace: string;
  reason?: string;
  uuid: string;
}

const actionHandler =
  (
    row: Row<ExceptionPoliciesTableRow>,
    action: ExceptionPoliciesTableRowActionHandler
  ) =>
  (_: SyntheticEvent) => {
    if (!row.original) return;

    const { namespace, uuid } = row.original;
    return action({ namespace, uuid });
  };

/**
 * Build the Table columns
 * Include the Delete column when `onDelete` handler is provided
 */
const buildPoliciesTableColDefs = ({
  labelSwitch,
  onDelete,
  onDisable,
  onEdit,
  onClone,
}: {
  labelSwitch?: string;
  onDelete?: ExceptionPoliciesTableRowActionHandler;
  onDisable?: ExceptionPoliciesTableRowActionHandler;
  onEdit?: ExceptionPoliciesTableRowActionHandler;
  onClone?: ExceptionPoliciesTableRowActionHandler;
}) => {
  const columns: DataTableColumnDef<ExceptionPoliciesTableRow>[] = [
    {
      accessorKey: 'name',
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
      header: () => <FM defaultMessage="Exception Policy Name" />,
    },
    {
      accessorKey: 'reason',
      cell: (t) => {
        return t.getValue() ? (
          <FM
            {...ExceptionPolicyReasonLabels[
              t.getValue() as PolicyExceptionReason
            ]}
          />
        ) : (
          ''
        );
      },
      header: () => <FM defaultMessage="Reason" />,
    },
    {
      accessorKey: 'expiration',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Expiration" />,
    },
    {
      accessorKey: 'lastTriggered',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Triggered" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'labels',
      colType: ColTypes.TAGS,
    },
  ];

  if (onDisable) {
    columns.unshift({
      id: 'enabled',
      cell: ({ row }) => {
        return (
          <Switch
            aria-label={labelSwitch}
            checked={!row.original?.disable}
            onChange={actionHandler(row, onDisable)}
            size="small"
          />
        );
      },
      colType: ColTypes.SWITCH,
    });
  }

  if (onDelete || onEdit || onClone) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => {
        const actions: DataTableActionDropdownItem[] = [];
        if (onEdit) {
          actions.push({
            label: <FM defaultMessage="Edit Policy" />,
            onClick: actionHandler(row, onEdit),
          });
        }
        if (onClone) {
          actions.push({
            label: <FM defaultMessage="Clone Policy" />,
            onClick: actionHandler(row, onClone),
          });
        }
        if (onDelete) {
          actions.push({
            isDestructive: true,
            label: <FM defaultMessage="Delete Policy" />,
            onClick: actionHandler(row, onDelete),
          });
        }
        return (
          <Box display="flex" justifyContent="flex-end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

export interface ExceptionPoliciesTableProps
  extends Omit<DataTableProps<ExceptionPoliciesTableRow>, 'columns'> {
  onDelete?: ExceptionPoliciesTableRowActionHandler;
  onDisable?: ExceptionPoliciesTableRowActionHandler;
  onEdit?: ExceptionPoliciesTableRowActionHandler;
  onClone?: ExceptionPoliciesTableRowActionHandler;
}

export const ExceptionPoliciesTable = ({
  onDelete,
  onDisable,
  onEdit,
  onClone,
  ...props
}: ExceptionPoliciesTableProps) => {
  const { formatMessage: fm } = useIntl();

  const columns = useMemo(
    () =>
      buildPoliciesTableColDefs({
        labelSwitch: fm(messages.labelSwitch),
        onDelete,
        onDisable,
        onEdit,
        onClone,
      }),
    [fm, onDelete, onDisable, onEdit, onClone]
  );

  return <DataTable {...props} columns={columns} />;
};
