import { useNavigate } from '@tanstack/react-location';
import { Row } from '@tanstack/react-table';
import { isNil as _isNil } from 'lodash-es';
import { MouseEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Method, V1RequestStatus } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  NilDisplay,
  ResourceKindDisplay,
  UIEventUtils,
} from '@endorlabs/ui-common';

import {
  ServiceRequestMethodTypeMessages,
  ServiceRequestStatusDisplay,
} from '../../domains/ServiceRequest';
import { getServiceRequestPath } from '../../routes';

export interface ServiceRequestTableProps
  extends Omit<DataTableProps<ServiceRequestTableRow>, 'columns'> {
  namespace?: string;
}

export const ServiceRequestTable = ({
  namespace,

  ...props
}: ServiceRequestTableProps) => {
  const { formatMessage: fm } = useIntl();

  const navigate = useNavigate();

  function handleRowClick(
    row: ServiceRequestTableRow,
    _: Row<ServiceRequestTableRow>,
    evt: MouseEvent
  ) {
    const url = getServiceRequestPath({
      tenantName: namespace ?? '',
      uuid: row.uuid,
    });
    UIEventUtils.simulateLinkClick(url, evt, navigate);
  }

  const columns = useMemo(() => buildServiceRequestTableColDefs(), []);

  return (
    <DataTable
      {...props}
      columns={columns}
      onRowClick={handleRowClick}
      enablePagination
    />
  );
};

export interface ServiceRequestTableRow {
  approver?: string;
  createTime: string;
  creator?: string;
  description: string;
  operation: string;
  resource: string;
  status?: string;
  tenant: string;
  updateTime?: string;
  uuid: string;
}

/**
 * Build the Table columns
 * Allow for ServiceRequest dismissal, navigation to policy, and opening the detail drawer to learn more about ServiceRequest findings
 */
const buildServiceRequestTableColDefs = () => {
  const columns: DataTableColumnDef<ServiceRequestTableRow>[] = [
    {
      accessorKey: 'status',
      cell: ({ getValue }) => {
        const status = getValue() as V1RequestStatus;
        // handle `undefined` value
        return _isNil(status) ? (
          <NilDisplay variant="text" />
        ) : (
          <ServiceRequestStatusDisplay status={status} />
        );
      },
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Status" />,
      enableSorting: true,
    },
    {
      accessorKey: 'tenant',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Tenant" />,
      enableSorting: true,
    },
    {
      accessorKey: 'resource',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Resource" />,
      cell: ({ getValue }) => {
        const resource = getValue() as ResourceKind;
        // handle `undefined` value
        return _isNil(resource) ? (
          <NilDisplay variant="text" />
        ) : (
          <ResourceKindDisplay value={resource} />
        );
      },
      enableSorting: true,
    },
    {
      accessorKey: 'operation',
      cell: ({ getValue }) => {
        const method = getValue() as V1Method;
        // handle `undefined` value
        return _isNil(method) ? (
          <NilDisplay variant="text" />
        ) : (
          <FM {...ServiceRequestMethodTypeMessages[method]} />
        );
      },
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Operation" />,
      enableSorting: true,
    },
    {
      accessorKey: 'description',
      colType: ColTypes.TEXT_LONG,
      header: () => <FM defaultMessage="Description" />,
    },
    {
      accessorKey: 'creator',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Created By" />,
      enableSorting: true,
    },
    {
      accessorKey: 'createTime',
      colType: ColTypes.DATE_TIME,
      header: () => <FM defaultMessage="Created Time" />,
      enableSorting: true,
    },
    {
      accessorKey: 'approver',
      colType: ColTypes.TEXT,
      header: () => <FM defaultMessage="Approved/Denied By" />,
    },
    {
      accessorKey: 'updateTime',
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Approved Time" />,
    },
  ];

  return columns;
};
