import { FormattedMessage as FM } from 'react-intl';

import { PackagistSpecAuthKind } from '@endorlabs/api_client';

import { PackagistAuthKindMessages } from '../locales';

export const PackagistAuthKindLabel = ({
  packagistAuthKind,
}: {
  packagistAuthKind: PackagistSpecAuthKind;
}) => <FM {...PackagistAuthKindMessages[packagistAuthKind]} />;
