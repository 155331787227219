import {
  get as _get,
  has as _has,
  isArray as _isArray,
  sortBy as _sortBy,
  uniqBy as _uniqBy,
} from 'lodash-es';

import { V1CiCdTool, V1ToolPattern } from '@endorlabs/api_client';

export type ToolPatternsByCategory = Record<string, V1ToolPattern[]>;

export const selectCiCdToolListByCategory = (
  toolPatterns: V1ToolPattern[],
  normalize = true
) => {
  const categories: ToolPatternsByCategory = {};

  for (const tool of toolPatterns) {
    const toolCategories = tool.spec.categories ?? [];
    for (const category of toolCategories) {
      if (categories[category]) {
        categories[category].push(tool);
      } else {
        categories[category] = [tool];
      }
    }
  }
  return (
    normalize ? normalizeCiCdToolCategories(categories) : categories
  ) as ToolPatternsByCategory;
};

export function normalizeCiCdToolCategories<
  T extends V1CiCdTool | V1ToolPattern
>(categories: string[] | Record<string, T[]>, sort = true) {
  if (_isArray(categories)) {
    const normalizedCategories: string[] = _uniqBy<string>(
      categories,
      (value) => value.toLowerCase()
    );
    if (sort) {
      normalizedCategories.sort((a, b) => a.localeCompare(b));
    }
    return normalizedCategories;
  } else {
    const normalizedCategories: Record<string, T[]> = {};

    const originalCategoryNames = Object.keys(categories);

    const normalizedCategoryNames = _uniqBy<string>(
      originalCategoryNames,
      (value) => value.toLowerCase()
    );

    if (sort) {
      normalizedCategoryNames.sort((a, b) => a.localeCompare(b));
    }

    normalizedCategoryNames.map((categoryName) => {
      originalCategoryNames.forEach((category) => {
        if (category.toLowerCase() === categoryName.toLowerCase()) {
          const currentToolList = normalizedCategories[categoryName];
          const nextOccurenceToolList = categories[category];
          const iteratee = (value: T) =>
            _has(value, 'name')
              ? _get(value, 'name')
              : _get(value, 'meta.name');
          normalizedCategories[categoryName] = _sortBy(
            _uniqBy(
              [...(currentToolList ?? []), ...(nextOccurenceToolList ?? [])],
              iteratee
            ),
            iteratee
          );
        }
      });
    });
    return normalizedCategories;
  }
}
