import { uniqueId as _uniqueId } from 'lodash-es';
import { useCallback } from 'react';

import { WithOptional } from '@endorlabs/utils';

import { AppNotification, AppNotificationSeverity } from './types';
import { useAppNotificationStore } from './useAppNotificationStore';

type PartialAppNotification = WithOptional<
  AppNotification,
  'id' | 'severity' | 'details'
>;

const AUTOHIDE_SEVERITY_LEVELS: AppNotificationSeverity[] = ['info', 'success'];

export const useAppNotify = () => {
  const { addNotification } = useAppNotificationStore();

  return useCallback(
    (notification: PartialAppNotification) => {
      // ensure that the notification has an id set
      if (!notification.id) {
        notification = { ...notification, id: _uniqueId('app-notification') };
      }

      // ensure that the notification has a severity set
      if (!notification.severity) {
        notification = { ...notification, severity: 'info' };
      }

      // default the value for `autoHide` to true for low severity notifications
      if (
        AUTOHIDE_SEVERITY_LEVELS.some((s) => s === notification.severity) &&
        !Reflect.has(notification, 'autoHide')
      ) {
        notification = { ...notification, autoHide: true };
      }

      addNotification(notification as AppNotification);
    },
    [addNotification]
  );
};
