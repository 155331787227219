import { Grid, IconButton, Paper } from '@mui/material';
import { useIntl } from 'react-intl';

import {
  IconX,
  SearchBarInput,
  SearchBarInputProps,
} from '@endorlabs/ui-common';

export type SearchBarProps = Pick<
  SearchBarInputProps,
  'onSearch' | 'placeholder' | 'searchEvent' | 'searchValue'
>;

/**
 * Search Bar Component
 *
 * If more complex filtering is needed use {@see FilterBar}
 */
export const SearchBar = ({
  onSearch,
  placeholder,
  searchEvent,
  searchValue,
}: SearchBarProps) => {
  const { formatMessage: fm } = useIntl();

  const handleClear = () => {
    onSearch('');
  };

  return (
    <Paper sx={{ padding: 4 }}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item flexGrow={1} order={0}>
          <SearchBarInput
            placeholder={placeholder}
            onSearch={onSearch}
            searchEvent={searchEvent}
            searchValue={searchValue}
            disableUnderline
            size="medium"
          />
        </Grid>

        {searchValue && (
          <Grid item>
            <IconButton
              onClick={handleClear}
              size="small"
              title={fm({ defaultMessage: 'Clear Search' })}
            >
              <IconX fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
