import { uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { V1ScanState } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListAssuredPackageVersions } from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { RemediationsTableRow } from '../components/RemediationsTable/types';
import { RemediationRisk } from '../types';

/**
 * HACK: this hook is intended for use in the remediations table, to prepend
 * phantom rows if an Endor Patch is available for a dependency, but not included
 * in the UIA Version Upgrade data.
 */
export const useRemediationTableEndorPatchFallback = ({
  data,
  enabled,
  namespace,
}: {
  data: RemediationsTableRow[];
  enabled?: boolean;
  namespace: string;
}) => {
  const fallbackDependencyPackageVersionNames = useMemo(() => {
    const hasEndorPatchUpgrade = data.some((u) => u.isEndorPatchUpgrade);

    // If an Endor Patch upgrade already exists for this dependency, don't fallback
    if (hasEndorPatchUpgrade) return [];

    const dependencyPackageVersionNames = data
      .filter((u) => u.dependencyName && u.fromVersion)
      .map((u) => u.dependencyName + '@' + u.fromVersion);

    return _uniq(dependencyPackageVersionNames);
  }, [data]);

  const qListAssuredPackageVersions = useListAssuredPackageVersions(
    namespace,
    {
      filter: filterExpressionBuilders.and([
        `meta.name in ["${fallbackDependencyPackageVersionNames
          .map((packageVersionName) => packageVersionName + '-endor-latest')
          .join('","')}"]`,
        `processing_status.scan_state==${V1ScanState.Idle}`,
      ]),
      mask: ['uuid', 'meta.name'].join(','),
    },
    {
      enabled: enabled && fallbackDependencyPackageVersionNames.length > 0,
    }
  );

  const mergedData = useMemo(() => {
    const assuredPackageVersions =
      qListAssuredPackageVersions.data?.objects ?? [];
    if (!enabled || !assuredPackageVersions.length) {
      return data;
    }

    // shallow copy of the existing data
    const mergedData = data.slice();

    // Map from the AssuredPackageVersion to the RemediationTable rows
    for (const apv of assuredPackageVersions) {
      const { version } = UIPackageVersionUtils.parsePackageName(apv.meta.name);

      const findingCounts = FINDING_LEVELS.map((level) => ({
        level,
        value:
          apv.spec?.upgrade_summary?.fixed_vulns?.[level]?.vulns?.length ?? 0,
      }));

      mergedData.unshift({
        dependencyName: apv.meta.name,
        findingCounts,
        isBest: true,
        isEndorPatchUpgrade: true,
        remediationRisk: RemediationRisk.Low,
        toVersion: version,
      });
    }

    return mergedData;
  }, [data, enabled, qListAssuredPackageVersions.data]);

  return { data: mergedData, isLoading: qListAssuredPackageVersions.isLoading };
};
