import { isObject as _isObject, isString as _isString } from 'lodash-es';

import { SpecFindingLevel, V1GroupResponse } from '@endorlabs/api_client';
import { FINDING_LEVELS } from '@endorlabs/endor-core/Finding';

import { tryParseGroupResponseAggregationKey } from './utils';

export const EMPTY_FINDING_LEVEL_COUNTS = [
  {
    level: SpecFindingLevel.Critical,
    value: 0,
  },
  {
    level: SpecFindingLevel.High,
    value: 0,
  },
  {
    level: SpecFindingLevel.Medium,
    value: 0,
  },
  {
    level: SpecFindingLevel.Low,
    value: 0,
  },
];

const isFindingLevel = (v: any): v is SpecFindingLevel => {
  return v && _isString(v) && FINDING_LEVELS.includes(v as any);
};

export type FindingCountsByLevel = {
  [k in SpecFindingLevel]?: number;
};

export const selectFindingCountsByLevelFromGroupResponse = (
  groupResponse?: V1GroupResponse
): FindingCountsByLevel => {
  const findingCountsByLevel: FindingCountsByLevel = {};

  if (groupResponse?.groups && _isObject(groupResponse.groups)) {
    for (const [key, groupData] of Object.entries(groupResponse.groups)) {
      const aggregationValues = tryParseGroupResponseAggregationKey(key);
      const findingLevel = aggregationValues.find(
        (p) => p.key === 'spec.level'
      )?.value;

      if (isFindingLevel(findingLevel)) {
        findingCountsByLevel[findingLevel] =
          groupData.aggregation_count?.count ?? 0;
      }
    }
  }

  return findingCountsByLevel;
};

export type FindingCount = {
  level: SpecFindingLevel;
  value: number;
};

export const selectFindingCountsFromGroupResponse = (
  groupResponse?: V1GroupResponse
): FindingCount[] => {
  const findingCountsByLevel =
    selectFindingCountsByLevelFromGroupResponse(groupResponse);

  return [
    {
      level: SpecFindingLevel.Critical,
      value: findingCountsByLevel.FINDING_LEVEL_CRITICAL ?? 0,
    },
    {
      level: SpecFindingLevel.High,
      value: findingCountsByLevel.FINDING_LEVEL_HIGH ?? 0,
    },
    {
      level: SpecFindingLevel.Medium,
      value: findingCountsByLevel.FINDING_LEVEL_MEDIUM ?? 0,
    },
    {
      level: SpecFindingLevel.Low,
      value: findingCountsByLevel.FINDING_LEVEL_LOW ?? 0,
    },
  ];
};

export const compareFindingCounts = (
  a?: FindingCount[],
  b?: FindingCount[]
) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  const serializedA = a.map((f) => f.value).join(':');
  const serializedB = b.map((f) => f.value).join(':');

  return serializedA.localeCompare(serializedB);
};
