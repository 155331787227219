import { uniq as _uniq } from 'lodash-es';

import { SecurityAttestationPatch } from '@endorlabs/api_client';

const REGEX_PATCH_PREFIX = /[-+]{3} [ab]\//;

export type PatchFileDiffStat = {
  diff: string;
  linesAdded: number;
  linesChanged: number;
  linesRemoved: number;
  files: string[];
};

/**
 * Naive parser for git diff patch files
 *
 * References
 * - https://git-scm.com/docs/git-diff
 */
export const parseSecurityAttestationPatch = (
  patch: SecurityAttestationPatch
): PatchFileDiffStat[] => {
  const stats: PatchFileDiffStat[] = [];

  for (const diff of patch.patch_files ?? []) {
    let linesAdded = 0;
    let linesChanged = 0;
    let linesRemoved = 0;

    const files = [];

    const lines = diff.split('\n');
    if (lines.length < 2) continue;

    let inHeader = false;

    // Count the lines added/removed
    for (let ix = 0; ix < lines.length; ix++) {
      const line = lines[ix];

      if (line.startsWith('diff')) {
        inHeader = true;
      }

      // Parse header
      if (inHeader) {
        if (line.startsWith('@@')) {
          // End of header block
          inHeader = false;
        }

        // Get affected file names
        if (line.startsWith('+++') || line.startsWith('---')) {
          if (REGEX_PATCH_PREFIX.test(line)) {
            files.push(line.replace(REGEX_PATCH_PREFIX, ''));
          }
        }

        continue;
      }

      // Parse chunk
      const nextLine = lines[ix + 1] ?? '';
      if (line.startsWith('+')) {
        if (nextLine.startsWith('-')) {
          linesChanged++;
          ix++;
        } else {
          linesAdded++;
        }
      }

      if (line.startsWith('-')) {
        if (nextLine.startsWith('+')) {
          linesChanged++;
          ix++;
        } else {
          linesRemoved++;
        }
      }
    }

    stats.push({
      diff,
      files: _uniq(files),
      linesAdded,
      linesChanged,
      linesRemoved,
    });
  }

  return stats;
};
