import {
  HuggingFaceModelServiceApi,
  V1CountResponse,
} from '@endorlabs/api_client';
import { HuggingFaceModelResource } from '@endorlabs/endor-core/HuggingFaceModel';

import { createResourceReadHooks } from './hooks';
import { ResourceGroupResponse, ResourceListResponse } from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

const queries = createResourceReadHooks('HuggingFaceModel', () => {
  const getApiService = () =>
    new HuggingFaceModelServiceApi(getClientConfiguration());

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp =
        await getApiService().huggingFaceModelServiceListHuggingFaceModels(
          namespace,
          ...args,
          options
        );
      return resp?.data.count_response as Required<V1CountResponse>;
    },

    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp =
        await getApiService().huggingFaceModelServiceGetHuggingFaceModel(
          namespace,
          ...args,
          options
        );
      return resp?.data as HuggingFaceModelResource;
    },

    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().huggingFaceModelServiceListHuggingFaceModels(
          namespace,
          ...args,
          options
        );
      return resp?.data.group_response as ResourceGroupResponse;
    },

    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp =
        await getApiService().huggingFaceModelServiceListHuggingFaceModels(
          namespace,
          ...args,
          options
        );
      return resp?.data.list as ResourceListResponse<HuggingFaceModelResource>;
    },
  };
});

//Query hooks
export const useCountHuggingFaceModels = queries.useCount;
export const useGetHuggingFaceModel = queries.useGet;
export const useGroupHuggingFaceModels = queries.useGroup;
export const useListHuggingFaceModels = queries.useList;
