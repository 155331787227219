import {
  V1AuthorizationPolicy,
  V1AuthorizationPolicySpec,
} from '@endorlabs/api_client';
import { LiteralString } from '@endorlabs/utils';

import { ResourceType } from '../common';

/**
 * Authorization Policy Resource
 */
export type AuthorizationPolicyResource = ResourceType<
  V1AuthorizationPolicy,
  V1AuthorizationPolicySpec
>;

export enum AuthorizationPolicyIdentitySourceType {
  APIKey = 'api-key',
  AWS = 'aws',
  Azure = 'azureadv2',
  Endor = 'endor',
  GCP = 'gcp',
  GitHub = 'github',
  GitHubAction = 'github-action',
  GitLab = 'gitlab',
  Google = 'google',
  IdentityProvider = 'IdentityProvider',
  Unknown = 'Unknown',
}

export type AuthorizationPolicyIdentitySource = {
  type: AuthorizationPolicyIdentitySourceType;
  value: string;
};

export type AuthorizationPolicyIdentityClaimPrefix = LiteralString<
  'domain' | 'email' | 'user'
>;

export type AuthorizationPolicyIdentityClaim = {
  prefix: AuthorizationPolicyIdentityClaimPrefix;
  value: string;
};
