import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type CweProps = {
  color?: string;
  size?: string | number;
};

const Cwe = forwardRef<SVGSVGElement, CweProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5"
          y="5.5"
          width="23"
          height="13"
          rx="3.5"
          stroke={color}
          strokeOpacity="0.5"
        />
        <path
          d="M6.82727 8.664C7.23527 8.664 7.58327 8.715 7.87127 8.817C8.15927 8.919 8.43527 9.078 8.69927 9.294L8.15927 9.942C7.78127 9.636 7.35227 9.483 6.87227 9.483C6.36827 9.483 5.95727 9.678 5.63927 10.068C5.32127 10.452 5.16227 11.058 5.16227 11.886C5.16227 12.702 5.31827 13.305 5.63027 13.695C5.94227 14.079 6.35927 14.271 6.88127 14.271C7.18127 14.271 7.43627 14.226 7.64627 14.136C7.86227 14.04 8.07527 13.908 8.28527 13.74L8.78927 14.379C8.28527 14.871 7.63727 15.117 6.84527 15.117C6.31127 15.117 5.83427 14.994 5.41427 14.748C4.99427 14.496 4.66427 14.127 4.42427 13.641C4.19027 13.155 4.07327 12.57 4.07327 11.886C4.07327 11.214 4.19327 10.635 4.43327 10.149C4.67927 9.663 5.01227 9.294 5.43227 9.042C5.85227 8.79 6.31727 8.664 6.82727 8.664ZM13.9288 15H12.7048L12.0928 10.401L11.4538 15H10.2118L9.41976 8.79H10.4278L10.9138 13.992L11.5798 9.51H12.6508L13.2718 13.992L13.8478 8.79H14.7838L13.9288 15ZM17.0592 11.454H19.3092V12.237H17.0592V14.208H19.8042V15H16.0152V8.79H19.7502L19.6332 9.582H17.0592V11.454Z"
          fill="#6F6F6F"
        />
      </svg>
    );
  }
);

Cwe.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Cwe.displayName = 'Cwe';

export default Cwe;
