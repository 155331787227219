import { V1GroupResponse } from '@endorlabs/api_client';
import { tryParseGroupResponseAggregationKey } from '@endorlabs/queries';
import { GraphAdjacencyList } from '@endorlabs/utils/graph';

/**
 * Builds a dependency graph from the given Dependency Metadata group response
 */
export const buildDependencyGraphFromDependencyMetadata = (
  groupResponse?: V1GroupResponse
) => {
  const dependencyGraph: GraphAdjacencyList = {};
  const entries = Object.entries(groupResponse?.groups ?? {});

  for (const [key, group] of entries) {
    const aggregationValues = tryParseGroupResponseAggregationKey(key);

    const depName = aggregationValues.find((kv) => kv.key === 'meta.name')
      ?.value as string | undefined;
    const parents = (group.unique_values?.[
      'spec.dependency_data.parent_version_name'
    ] ?? []) as unknown as string[];

    if (depName) {
      dependencyGraph[depName] = dependencyGraph[depName] || [];

      for (const p of parents) {
        dependencyGraph[p] = (dependencyGraph[p] || []).concat(depName);
      }
    }
  }

  return dependencyGraph;
};
