import { useMemo } from 'react';

import { V1Policy } from '@endorlabs/api_client';
import {
  buildQueryCall,
  PolicyResource,
  sortParamBuilders,
} from '@endorlabs/queries';

interface ScanStatisticsPerPolicy {
  lastTriggered: string;
  triggerCount: number;
}

/**

 */
export const usePolicyScanResults = ({
  namespace,
  policies,
}: {
  namespace: string;
  policies: V1Policy[];
}) => {
  const policyUuids = policies.map((p) => p.uuid);

  const qPoliciesWithScanResults = buildQueryCall('Policy', {
    filter: `uuid in [${policyUuids.join(', ')}]`,
    mask: 'uuid',
  })
    .addReference(
      'ScanResult',
      {
        mask: 'uuid,spec.end_time',
        page_size: 1,
        sort: sortParamBuilders.descendingBy('spec.end_time'),
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.policies_triggered',
        return_as: 'LastScanResult',
      }
    )
    .addReference(
      'ScanResult',
      { count: true },
      {
        connect_from: 'uuid',
        connect_to: 'spec.policies_triggered',
        return_as: 'ScanTriggerCount',
      }
    )
    .useBuiltQuery(namespace);

  const scanResultsPerPolicy = useMemo(() => {
    const workingPolicies = (qPoliciesWithScanResults.data?.spec?.query_response
      ?.list?.objects ?? []) as PolicyResource[];

    return workingPolicies.reduce((acc, policy) => {
      acc[policy.uuid] = {
        lastTriggered:
          policy.meta.references?.LastScanResult?.list?.objects[0]?.spec
            .end_time,
        triggerCount:
          policy.meta.references?.ScanTriggerCount?.count_response?.count,
      };
      return acc;
    }, {} as Record<string, ScanStatisticsPerPolicy>);
  }, [qPoliciesWithScanResults.data]);

  const getLastScanTimeForPolicy = (policyUuid: string) =>
    scanResultsPerPolicy[policyUuid]?.lastTriggered;

  const getTriggerCountForPolicy = (policyUuid: string) =>
    scanResultsPerPolicy[policyUuid]?.triggerCount;

  return {
    getLastScanTimeForPolicy,
    getTriggerCountForPolicy,
  };
};
