import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import { RefObject } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScanResult } from '@endorlabs/api_client';
import {
  DrawerAccordion,
  FileDownloadButton,
  ScanResultExitCodeLabel,
} from '@endorlabs/ui-common';

import { ScanResultWithIssues } from './useScanResultsWithIssues';

/**
 * Displays a list of Scan Results and the related issues
 */
export const ScanIssuesDetailScanResults = ({
  focusElementRef,
  focusResource,
  scanResults,
}: {
  focusElementRef?: RefObject<HTMLDivElement>;
  focusResource?: Pick<V1ScanResult, 'uuid'>;
  scanResults: ScanResultWithIssues[];
}) => {
  return (
    <Stack spacing={6} marginTop={4}>
      {scanResults.map((sr, ix) => (
        <DrawerAccordion
          accordionSummaryRef={
            // set the ref if we should focus this accordion
            focusResource?.uuid === sr.uuid ? focusElementRef : undefined
          }
          id={`ScanResultIssues-${sr.scanType}`}
          expanded={!focusResource || focusResource.uuid === sr.uuid}
          key={sr.uuid}
          titleNode={
            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
              <FM defaultMessage="Scan Issues" />
            </Typography>
          }
        >
          <Stack spacing={4}>
            <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
              <FM
                defaultMessage="Scan exited with code: {exitCode}"
                values={{
                  exitCode: <ScanResultExitCodeLabel value={sr.exitCode} />,
                }}
              />
            </Typography>

            {/* Display all related issues for the scan result */}
            {sr.issues.map(({ severity, title, description: detail }, ix) => (
              <Alert
                severity={severity}
                key={`${severity}_${ix}`}
                action={
                  sr.data && (
                    <FileDownloadButton
                      data={sr.data}
                      filename={`${sr.namespace}_scan-results_${sr.uuid}.json`}
                      filetype="json"
                      size="small"
                    />
                  )
                }
              >
                <AlertTitle sx={{ wordBreak: 'break-word' }}>
                  {title}
                </AlertTitle>
                {detail && (
                  <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                    {detail}
                  </Typography>
                )}
              </Alert>
            ))}
          </Stack>
        </DrawerAccordion>
      ))}
    </Stack>
  );
};
