import { useCallback, useMemo } from 'react';

import {
  buildSimpleFilterExpression,
  FilterableResource,
  filterExpressionBuilders,
  getExpressionBuilder,
  ResourceFilter,
  useFilterSearchParams,
} from '@endorlabs/filters';

import {
  FACET_FILTER_DEFAULT_FILTER_PARTIALS,
  FACET_FILTER_DEFINITIONS,
} from './constants';
import { FacetedSearchBarProps } from './FacetedSearchBar';

const buildSearchExpression = (filters: ResourceFilter[]): string => {
  const searchExpressions = filters.map((filter) => {
    // get the expression builder
    const builder = getExpressionBuilder(filter.comparator);
    return builder(filter);
  });

  return filterExpressionBuilders.or(searchExpressions);
};

export interface UseFacetedSearchBarProps {
  resourceKind: Extract<FilterableResource, 'Finding' | 'Policy'>;
}

export const useFacetedSearchBar = ({
  resourceKind,
}: UseFacetedSearchBarProps) => {
  // manage the faceted filter search bar
  const {
    filterSearchParams: filters,
    filterDefaultSearchParams: searchValue,
    updateFilterSearchParams,
    updateFilterDefaultSearchParams,
  } = useFilterSearchParams();

  const facets = useMemo(
    () => FACET_FILTER_DEFINITIONS[resourceKind] ?? [],
    [resourceKind]
  );

  const defaultSearchFilters = useMemo(
    () => FACET_FILTER_DEFAULT_FILTER_PARTIALS[resourceKind] ?? [],
    [resourceKind]
  );

  const filterExpression = useMemo(() => {
    const filterExpressions: string[] = [];

    if (searchValue) {
      filterExpressions.push(
        buildSearchExpression(
          defaultSearchFilters.map(
            (filter) => ({ ...filter, value: searchValue } as ResourceFilter)
          )
        )
      );
    }

    if (filters.length) {
      const { expression } = buildSimpleFilterExpression(filters);
      filterExpressions.push(expression);
    }

    return filterExpressions.map((expr) => `(${expr})`).join(' and ');
  }, [defaultSearchFilters, filters, searchValue]);

  const handleFiltersChange = useCallback(
    (filters: ResourceFilter[]) => updateFilterSearchParams(filters),
    [updateFilterSearchParams]
  );

  const handleSearchChange = useCallback(
    (searchValue: string) => updateFilterDefaultSearchParams(searchValue),
    [updateFilterDefaultSearchParams]
  );

  const clearFilters = useCallback(() => {
    updateFilterSearchParams([]);
    updateFilterDefaultSearchParams('');
  }, [updateFilterDefaultSearchParams, updateFilterSearchParams]);

  const getFacetedSearchBarProps = useCallback((): FacetedSearchBarProps => {
    return {
      facets,
      filters,
      onFiltersChange: handleFiltersChange,
      onSearchChange: handleSearchChange,
      primaryResourceKind: resourceKind,
      searchValue,
    };
  }, [
    facets,
    filters,
    handleFiltersChange,
    handleSearchChange,
    resourceKind,
    searchValue,
  ]);

  return {
    clearFilters,
    filterExpression,
    filters,
    getFacetedSearchBarProps,
    searchValue,
  };
};
