import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog, SimpleDialogProps } from '@endorlabs/ui-common';

import {
  FormMultiResourceTags,
  FormMultiResourceTagsProps,
} from '../FormMultiResourceTags';

export interface TagEditorDialogProps
  extends Pick<SimpleDialogProps, 'open' | 'onClose' | 'titleText'>,
    FormMultiResourceTagsProps {}

/**
 * Dialog Wrapper around Project tags editor
 */
export const TagEditorDialog = ({
  open,
  onClose,
  titleText,
  ...props
}: TagEditorDialogProps) => {
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      titleText={
        titleText ?? (
          <FM
            defaultMessage="Edit Tags: {selectedCount} {selectedCount, plural, one {Resource} other {Resources}}"
            values={{ selectedCount: props.resources?.length ?? 0 }}
          />
        )
      }
    >
      <FormMultiResourceTags {...props} />
    </SimpleDialog>
  );
};
