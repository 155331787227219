import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type RocketIconProps = {
  color?: string;
  size?: string | number;
};

const Rocket = forwardRef<SVGSVGElement, RocketIconProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <g clipPath="url(#clip0_517_929)">
          <path
            d="M18.5089 0.893806C19.6792 1.26196 19.1821 3.12173 17.851 5.83117C15.5591 10.4962 13.5374 11.9894 13.5374 11.9894C13.5374 11.9894 13.5403 12.1492 13.542 12.2892C13.5452 12.5701 13.5545 12.8508 13.5803 13.5224C13.6059 14.1908 13.6153 14.4726 13.6191 14.767C13.6274 15.3998 13.6199 15.552 13.4798 15.7723C13.3305 16.0071 12.3982 16.784 11.5026 17.4495C11.012 17.814 10.5856 18.1032 10.2597 18.2848C10.0406 18.407 9.86003 18.4863 9.68852 18.5215C9.23594 18.6145 8.74947 18.3746 8.74947 17.798C8.74947 17.7412 8.74526 17.7129 8.52214 16.2573C8.51069 16.1826 8.51069 16.1826 8.49926 16.1076C8.4518 15.7957 8.28567 14.4911 8.28567 14.4911C8.28567 14.4911 8.15302 14.3448 8.04463 14.2291C7.83108 14.0012 7.54388 13.711 7.14163 13.3138C7.11324 13.2858 7.11324 13.2858 7.08481 13.2577C7.07279 13.2459 6.35333 12.5391 6.18384 12.3703C6.14255 12.3292 5.81006 11.9894 5.81006 11.9894C5.81006 11.9894 5.62448 11.9584 5.48503 11.9459C5.19527 11.9199 4.88012 11.8982 4.22123 11.8567C4.09316 11.8486 4.09316 11.8486 3.96549 11.8406C3.36965 11.8028 3.03263 11.7788 2.75667 11.7518C2.5747 11.734 2.43672 11.7162 2.33204 11.6953C2.24008 11.6769 2.16641 11.6562 2.0864 11.6177C1.74519 11.4533 1.48939 11.0225 1.82159 10.6064L4.02733 6.94795C4.09952 6.74462 4.26006 6.61762 4.44489 6.54347C4.53588 6.50697 4.62705 6.48739 4.73246 6.47451C4.86837 6.4579 5.03849 6.4526 5.25484 6.45531C5.57349 6.45931 5.9203 6.47706 6.6041 6.51963L6.66699 6.52355C7.34608 6.56592 7.60195 6.58008 7.87275 6.58718C7.96887 6.5897 8.05099 6.59044 8.11792 6.58949C10.8619 3.34587 16.8386 0.368366 18.5089 0.893806Z"
            stroke={color}
            strokeWidth="2"
          />
          <path
            d="M1.95222 18.5779C1.48695 18.4316 1.68457 17.6922 2.21377 16.615C3.12494 14.7604 3.9287 14.1667 3.9287 14.1667L6.08329 16.3136C4.99239 17.6031 2.61627 18.7868 1.95222 18.5779Z"
            stroke={color}
            strokeWidth="2"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_517_929">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

Rocket.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Rocket.displayName = 'Rocket';

export default Rocket;
