import { Grid, Stack } from '@mui/material';
import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ContextContextType,
  V1PlatformSource,
  V1ScanState,
} from '@endorlabs/api_client';
import {
  isBinaryProject,
  ProjectResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  buildQueryCall,
  RepositoryResource,
  RepoVersionResource,
  sortParamBuilders,
  useCountCIRuns,
  useGetProject,
  useUpdateProject,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  ButtonSecondary,
  IconCode,
  IconDownload,
  IconLayout,
  ProjectNameDisplay,
  REGEX_COMMIT_SHA_VALIDATION,
  UIProjectUtils,
  useAppNotify,
  useDialog,
  useProjectLatestScanResults,
} from '@endorlabs/ui-common';

import {
  PageHeader,
  useResourceTimestampDescription,
  useScanIssuesDetailDrawer,
} from '../../components';
import { ExportedSBOMCreateDialog } from '../../domains/ExportedSBOM';
import { useProjectVersionMetadata } from '../../domains/Projects';
import { useAuthInfo } from '../../providers';
import { getProjectPath, useFullMatch, usePageTitle } from '../../routes';
import { ProjectVersionBreadcrumb } from './ProjectVersionBreadcrumb';
import { ProjectVersionDetailView } from './ProjectVersionDetailView';
import { ProjectVersionScanButton } from './ProjectVersionScanButton';

type ProjectVersionLocationGenerics = MakeGenerics<{
  Params: {
    project_uuid: string;
    activeView: string;
  };
  Search: {
    versionRef: string;
  };
}>;

/**
 * @deprecated will be replaced with the {@see ProjectVersionDetailPage}
 */
export const ProjectVersionPage = () => {
  const {
    params: { project_uuid: projectUuid, activeView },
  } = useFullMatch<ProjectVersionLocationGenerics>();
  const { versionRef } = useSearch<ProjectVersionLocationGenerics>();

  const navigate = useNavigate<ProjectVersionLocationGenerics>();

  const { activeNamespace: tenantName } = useAuthInfo();
  const { DetailDrawer } = useScanIssuesDetailDrawer();
  const packageExportDialog = useDialog({
    component: ExportedSBOMCreateDialog,
  });
  const addAppNotification = useAppNotify();

  const [viewMode, setViewMode] = useState<'metadata' | undefined>();

  const qProject = useGetProject({
    namespace: tenantName,
    uuid: projectUuid,
  });

  const project = qProject.data;

  const qProjectReferences = buildQueryCall('Project', {
    filter: `uuid == ${projectUuid}`,
  })
    .addReference(
      'Installation',
      { count: true },
      { connect_from: 'uuid', connect_to: 'spec.project_uuids' }
    )
    .addReference('Repository', {
      mask: 'uuid,tenant_meta,meta.name,meta.parent_uuid,spec.default_branch,spec.http_clone_url',
    })
    .addReference('RepositoryVersion', {
      filter: filterExpressionBuilders.defaultResourceContexts(),
      mask: 'uuid,tenant_meta,meta.kind,meta.name,meta.parent_uuid,spec.version,spec.last_commit_date,scan_object,context',
      sort: sortParamBuilders.descendingBy('spec.last_commit_date'),
    })
    .useBuiltQuery(tenantName);

  const {
    isGitHubInstallation,
    projectName,
    repository,
    repositoryVersions,
    platformSource,
  } = useMemo<{
    isGitHubInstallation?: boolean;
    project?: ProjectResource;
    projectName: string | null;
    platformSource: V1PlatformSource;
    repository?: RepositoryResource;
    repositoryDefaultRef: string;
    repositoryVersions: RepoVersionResource[];
  }>(() => {
    const project: ProjectResource | undefined =
      qProjectReferences.data?.spec?.query_response?.list.objects[0];
    const projectRefs = project?.meta.references;

    return {
      isGitHubInstallation:
        (projectRefs?.Installation.count_response.count ?? 0) > 0,
      project,
      projectName: UIProjectUtils.parseProjectName(
        project?.meta.name ?? '',
        project?.spec.platform_source
      ),
      platformSource: project?.spec?.platform_source ?? V1PlatformSource.Github,
      repository: projectRefs?.Repository.list.objects[0],
      repositoryDefaultRef:
        projectRefs?.Repository.list.objects[0]?.spec.default_branch,
      repositoryVersions: projectRefs?.RepositoryVersion.list.objects ?? [],
    };
  }, [qProjectReferences.data]);

  usePageTitle({ projectName });

  const platformIcon = UIProjectUtils.getPlatformIcon(
    project?.spec.platform_source
  );

  const [selectedRepositoryVersion, setSelectedRepositoryVersion] =
    useState<RepoVersionResource>();

  // select the default repo version on load, and on query param change
  useEffect(() => {
    if (!project) return;
    const defaultRepositoryVersion = UIProjectUtils.getDefaultRepositoryVersion(
      {
        project,
        repository,
        repositoryVersions,
        versionRef,
      }
    );
    setSelectedRepositoryVersion(defaultRepositoryVersion);
  }, [versionRef, repository, repositoryVersions, project]);

  const handleChangeRepositoryVersion = useCallback(
    (repositoryVersion: RepoVersionResource) => {
      setSelectedRepositoryVersion(repositoryVersion);
      navigate({
        // replace existing search params with the new version ref
        search: {
          versionRef: repositoryVersion.meta.name,
        },
      });
    },
    [navigate]
  );

  const qScanResults = useProjectLatestScanResults({
    namespace: tenantName,
    projectUuid,
    scanContext: selectedRepositoryVersion?.context,
  });

  const { summary } = useProjectVersionMetadata(
    project,
    selectedRepositoryVersion
  );

  const projectMetadataProps = {
    summary,
  };

  const resourceDescriptionFn = useResourceTimestampDescription({
    key: 'spec.last_commit_date',
  });

  const qCountCIRuns = useCountCIRuns(
    tenantName,
    {
      enabled: !!projectUuid,
    },
    {
      filter: `meta.parent_kind==Project and meta.parent_uuid==${projectUuid}`,
    }
  );

  const qUpdateProject = useUpdateProject({
    onError: (err) => {
      const isUnauthorized = err.response.status === 403;

      addAppNotification({
        id: 'project:rescan:error',
        details: isUnauthorized ? '' : err.response.data?.message,
        message: isUnauthorized ? (
          <FM defaultMessage="You do not have permissions to schedule a project scan" />
        ) : (
          <FM defaultMessage="Failed to schedule a rescan. Please try again." />
        ),
        severity: 'error',
      });
    },
    onSuccess: () => {
      addAppNotification({
        id: 'project:rescan:success',
        message: <FM defaultMessage="Successfully scheduled a rescan. " />,
        severity: 'success',
      });
    },
  });

  const handleRescanProject = useCallback(() => {
    if (!project) return;

    const updatedProject = produce(project, (draft) => {
      // unset the scan state to trigger a rescan
      draft.processing_status = {
        ...draft.processing_status,
        scan_state: V1ScanState.NotProcessed,
        scan_time: undefined,
      };
    });

    qUpdateProject.mutate({
      namespace: updatedProject.tenant_meta.namespace,
      resource: updatedProject,
      mask: [
        'processing_status.scan_state',
        'processing_status.scan_time',
      ].join(','),
    });
  }, [project, qUpdateProject]);

  // handle loading and empty state
  const isLoading = qProject.isLoading || qCountCIRuns.isLoading;
  const isMetadataView = !!viewMode && viewMode === 'metadata';

  // hide the rescan button for projects from a package or container scan
  const showRescanButton = project && !isBinaryProject(project);

  const handleStatusIndicatorClick = () =>
    DetailDrawer.activate(
      {
        contextId: selectedRepositoryVersion?.context?.id,
        contextType: selectedRepositoryVersion?.context?.type,
        namespace: tenantName,
        projectUuid: projectUuid,
      },
      {
        namespace: tenantName,
        scanContext: selectedRepositoryVersion?.context,
        scanResults: qScanResults.data?.objects,
        projectUuid: projectUuid,
      }
    );
  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item data-testid="project-details-header">
        <PageHeader
          action={
            <Stack direction="row" spacing={2}>
              {showRescanButton && (
                <ProjectVersionScanButton
                  platformSource={platformSource}
                  allowRescan={isGitHubInstallation}
                  handleRescan={handleRescanProject}
                  processingStatus={project?.processing_status}
                />
              )}

              <ButtonSecondary
                data-testid="project-details-btn-metadata"
                onClick={() =>
                  setViewMode(isMetadataView ? undefined : 'metadata')
                }
                startIcon={isMetadataView ? <IconLayout /> : <IconCode />}
              >
                {isMetadataView ? (
                  <FM defaultMessage="Project Details" />
                ) : (
                  <FM defaultMessage="Raw Data" />
                )}
              </ButtonSecondary>

              <ButtonPrimary
                data-testid="project-details-btn-export"
                disabled={isLoading || !project || !selectedRepositoryVersion}
                onClick={() => {
                  if (!project || !selectedRepositoryVersion) return;
                  packageExportDialog.openDialog({
                    namespace: project?.tenant_meta.namespace,
                    project,
                    projectVersion: selectedRepositoryVersion,
                  });
                }}
                startIcon={<IconDownload />}
              >
                <FM defaultMessage="Export SBOM" />
              </ButtonPrimary>
            </Stack>
          }
          breadcrumbsLinks={[
            {
              url: getProjectPath({ tenantName }),
              label: <FM defaultMessage="All Projects" />,
            },
          ]}
          breadcrumbsItems={
            projectName
              ? [
                  <ProjectNameDisplay
                    key={projectName}
                    name={projectName}
                    platformSource={project?.spec.platform_source}
                    size="medium"
                  />,
                  ...(repositoryVersions.length > 0
                    ? [
                        <ProjectVersionBreadcrumb
                          key={selectedRepositoryVersion?.uuid}
                          scanResults={qScanResults.data?.objects}
                          getLabelFn={getResourceLabel}
                          getDescriptionFn={resourceDescriptionFn}
                          isLoading={qProject.isLoading}
                          resourceList={repositoryVersions}
                          selectedResource={selectedRepositoryVersion}
                          onChange={handleChangeRepositoryVersion}
                          onStatusClick={handleStatusIndicatorClick}
                        />,
                      ]
                    : []),
                ]
              : []
          }
          isLoading={isLoading}
          Icon={platformIcon}
          title={projectName}
          metadata={projectMetadataProps}
        />
      </Grid>

      <Grid item>
        <ProjectVersionDetailView
          activeTab={activeView}
          isGitHubInstallation={isGitHubInstallation}
          isLoading={isLoading}
          namespace={tenantName}
          project={project}
          repository={repository}
          repositoryVersion={selectedRepositoryVersion}
          viewMode={viewMode}
        />
      </Grid>

      {/* SBOM Export Dialog */}
      <packageExportDialog.Dialog {...packageExportDialog.dialogProps} />
    </Grid>
  );
};

function getResourceLabel(
  resource?: RepoVersionResource,
  options?: { raw: boolean }
): string {
  let versionRef = resource?.meta.name ?? '';

  if (options?.raw) {
    return versionRef;
  }

  // shorten commit sha display
  if (REGEX_COMMIT_SHA_VALIDATION.test(versionRef)) {
    versionRef = versionRef.slice(0, 7);
  }

  if (resource?.context.type === ContextContextType.Ref) {
    let contextId = resource?.context.id ?? '';

    if (REGEX_COMMIT_SHA_VALIDATION.test(contextId)) {
      contextId = contextId.slice(0, 7);
    }

    if (versionRef !== contextId) {
      return `${versionRef} (${contextId})`;
    }
  }

  return versionRef;
}
