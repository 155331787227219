import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  NotificationServiceApi,
  V1CountResponse,
  V1GroupResponse,
  V1ListParameters,
  V1Notification,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  GroupRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  NotificationResource,
  NotificationResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  DEFAULT_UPDATE_MASK,
  getClientConfiguration,
} from './utils';

export type NotificationReadParams = {
  namespace: string;
  uuid: string;
};
export interface NotificationWriteParams {
  namespace: string;
  resource: V1Notification;
}
export interface NotificationUpdateParams extends NotificationWriteParams {
  mask?: string;
}

type CountNotificationsOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
// type DeleteNotificationOptions = ResourceMutateOptions<
//   object,
//   NotificationReadParams
// >;
type GetNotificationOptions = ResourceQueryOptions<NotificationResource>;
type GroupNotificationOptions = ResourceQueryOptions<Required<V1GroupResponse>>;
type ListNotificationOptions = ResourceQueryOptions<NotificationResourceList>;
type UpsertNotificationOptions = ResourceMutateOptions<
  V1Notification,
  NotificationWriteParams
>;

const BASE_KEY = 'v1/notifications';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const NotificationsQueryKeys = QK;

const getApiService = () =>
  new NotificationServiceApi(getClientConfiguration());

const countNotifications = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.notificationServiceListNotifications(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

const getNotification = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.notificationServiceGetNotification(namespace, uuid);
  return resp.data as NotificationResource;
};

const listNotifications = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.notificationServiceListNotifications(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as NotificationResourceList;
};

const updateNotification = async (params: NotificationUpdateParams) => {
  const { resource, namespace, mask = DEFAULT_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.notificationServiceUpdateNotification(namespace, req);
  return resp.data as NotificationResource;
};

export const useCountNotifications = (
  namespace: string,
  countParams: CountRequestParameters = {},
  opts: CountNotificationsOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Notification',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countNotifications(namespace, requestParameters),
    opts
  );
};

export const useGetNotification = (
  { namespace, uuid }: NotificationReadParams,
  opts: GetNotificationOptions = {}
) => {
  return useQuery(
    QK.record(namespace, uuid),
    () => getNotification(namespace, uuid),
    opts
  );
};

export const useGroupNotifications = (
  namespace: string,
  groupParams: GroupRequestParameters,
  opts: GroupNotificationOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Notification',
    'GROUP',
    groupParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () =>
      listNotifications(namespace, requestParameters).then((r) => {
        const groups = r.group_response?.groups ?? [];
        return { groups } as Required<V1GroupResponse>;
      }),
    opts
  );
};

export const useListNotifications = (
  namespace: string,
  listParams: ListRequestParameters = {},
  opts: ListNotificationOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Notification',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listNotifications(namespace, requestParameters),
    opts
  );
};

export const useUpdateNotification = (opts: UpsertNotificationOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Notification'),
    mutationFn: (params: NotificationUpdateParams) =>
      updateNotification(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
