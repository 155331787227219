/**
 * Runtime check that the current browser supports SubtleCrypto digest and TextEncoder
 */
const isSupported = () =>
  !!window.crypto &&
  !!window.crypto.subtle &&
  !!window.crypto.subtle.digest &&
  !!window.TextEncoder;

/**
 * Generate a digest from the given string data.
 */
export const digestString = (
  algorithm: 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512',
  value: string
): Promise<ArrayBuffer> => {
  if (!isSupported()) {
    return Promise.reject(
      new Error('Unsupported: browser does not support SubtleCrypto')
    );
  }

  return window.crypto.subtle.digest(
    algorithm,
    new window.TextEncoder().encode(value)
  );
};
