import { Box, Switch } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { ReactNode, SyntheticEvent, useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  DataTable,
  DataTableActionDropdown,
  DataTableActionDropdownItem,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  RelativeTimeDisplay,
} from '@endorlabs/ui-common';

import { messages } from '../locales';

export type AdmissionPoliciesTableRowDeleteHandler = (
  row?: AdmissionPoliciesTableRow
) => void;

export type AdmissionPoliciesTableRowActionHandler = (args: {
  namespace: string;
  uuid: string;
}) => void;

export interface AdmissionPoliciesTableRow {
  description?: string;
  disable?: boolean;
  labels?: string[];
  lastTriggered?: string;
  name: string;
  namespace: string;
  policyAction: ReactNode;
  projectCount: number;
  triggerCount: number;
  uuid: string;
}

const actionHandler =
  (
    row: Row<AdmissionPoliciesTableRow>,
    action: AdmissionPoliciesTableRowActionHandler
  ) =>
  (_: SyntheticEvent) => {
    if (!row.original) return;

    const { namespace, uuid } = row.original;
    return action({ namespace, uuid });
  };

/**
 * Build the Table columns
 * Include the Delete column when `onDelete` handler is provided
 */
const buildPoliciesTableColDefs = ({
  labelSwitch,
  onDelete,
  onDisable,
  onEdit,
  onClone,
}: {
  labelSwitch?: string;
  onDelete?: AdmissionPoliciesTableRowActionHandler;
  onDisable?: AdmissionPoliciesTableRowActionHandler;
  onEdit?: AdmissionPoliciesTableRowActionHandler;
  onClone?: AdmissionPoliciesTableRowActionHandler;
}) => {
  const columns: DataTableColumnDef<AdmissionPoliciesTableRow>[] = [
    {
      accessorKey: 'name',
      colType: ColTypes.TEXT_WITH_DESCRIPTION,
      header: () => <FM defaultMessage="Action Policy Name" />,
    },
    {
      accessorKey: 'policyAction',
      cell: (t) => t.getValue(),
      header: () => <FM defaultMessage="Action(s)" />,
    },
    {
      accessorKey: 'projectCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Applicable Projects" />,
    },
    {
      accessorKey: 'triggerCount',
      colType: ColTypes.NUMBER,
      header: () => <FM defaultMessage="Times Triggered" />,
    },
    {
      accessorKey: 'lastTriggered',
      cell: (t) => (
        <RelativeTimeDisplay
          nilDisplayProps={{ variant: 'dash' }}
          value={t.getValue()}
        />
      ),
      colType: ColTypes.RELATIVE_TIME,
      header: () => <FM defaultMessage="Last Triggered" />,
    },
    {
      accessorKey: 'namespace',
      colType: ColTypes.NAMESPACE,
    },
    {
      accessorKey: 'labels',
      colType: ColTypes.TAGS,
    },
  ];

  if (onDisable) {
    columns.unshift({
      id: 'enabled',
      cell: ({ row }) => {
        return (
          <Switch
            aria-label={labelSwitch}
            checked={!row.original?.disable}
            onChange={actionHandler(row, onDisable)}
            size="small"
          />
        );
      },
      colType: ColTypes.SWITCH,
    });
  }

  if (onDelete || onEdit || onClone) {
    columns.push({
      id: 'actions',
      cell: ({ row }) => {
        const actions: DataTableActionDropdownItem[] = [];
        if (onEdit) {
          actions.push({
            label: <FM defaultMessage="Edit Policy" />,
            onClick: actionHandler(row, onEdit),
          });
        }
        if (onClone) {
          actions.push({
            label: <FM defaultMessage="Clone Policy" />,
            onClick: actionHandler(row, onClone),
          });
        }
        if (onDelete) {
          actions.push({
            isDestructive: true,
            label: <FM defaultMessage="Delete Policy" />,
            onClick: actionHandler(row, onDelete),
          });
        }
        return (
          <Box display="flex" justifyContent="flex-end">
            <DataTableActionDropdown items={actions} />
          </Box>
        );
      },
      colType: ColTypes.ACTIONS,
    });
  }

  return columns;
};

export interface AdmissionPoliciesTableProps
  extends Omit<DataTableProps<AdmissionPoliciesTableRow>, 'columns'> {
  onDelete?: AdmissionPoliciesTableRowActionHandler;
  onDisable?: AdmissionPoliciesTableRowActionHandler;
  onEdit?: AdmissionPoliciesTableRowActionHandler;
  onClone?: AdmissionPoliciesTableRowActionHandler;
}

export const AdmissionPoliciesTable = ({
  onDelete,
  onDisable,
  onEdit,
  onClone,
  ...props
}: AdmissionPoliciesTableProps) => {
  const { formatMessage: fm } = useIntl();

  const columns = useMemo(
    () =>
      buildPoliciesTableColDefs({
        labelSwitch: fm(messages.labelSwitch),
        onDelete,
        onDisable,
        onEdit,
        onClone,
      }),
    [fm, onDelete, onDisable, onEdit, onClone]
  );

  return <DataTable {...props} columns={columns} />;
};
