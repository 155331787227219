import { defineMessage } from 'react-intl';

import { DashboardOSSOverview } from '../../../../views/Dashboard/DashboardOSSOverview';
import { createFileRoute } from '../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({ defaultMessage: 'Overview' });

const TenantDashboardOSSOverviewRoute = () => {
  return <DashboardOSSOverview />;
};

export const Route = createFileRoute('/t/:namespace/dashboard/oss-overview')({
  component: TenantDashboardOSSOverviewRoute,
  beforeLoad: () => ({ title: ROUTE_TITLE }),
});
