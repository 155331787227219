import { useIntl } from 'react-intl';

import {
  V1CustomTemplateType,
  V1PRCommentsTemplate,
} from '@endorlabs/api_client';
import {
  useCreatePRCommentConfig,
  useGetDefaultCustomTemplate,
  useListPRCommentConfigs,
  useUpdatePRCommentConfig,
} from '@endorlabs/queries';
import { useAppNotify } from '@endorlabs/ui-common';

/**
 * Custom hook to get PR Comment Template data and
 * expose methods to save the template.
 */
export const usePRCommentTemplateData = ({
  tenantName,
}: {
  tenantName: string;
}) => {
  const { formatMessage: fm } = useIntl();
  const addAppNotification = useAppNotify();

  const qPRCommentConfig = useListPRCommentConfigs(
    { namespace: tenantName },
    { staleTime: Infinity }
  );

  const pRCommentConfig = qPRCommentConfig.data?.list?.objects?.[0];

  const customTemplate = pRCommentConfig?.spec?.template;

  const qDefaultTemplate = useGetDefaultCustomTemplate(
    {
      customTemplateType: V1CustomTemplateType.PrComments,
    },
    { staleTime: Infinity }
  );

  const defaultTemplate = qDefaultTemplate.data?.prcomments_template;

  const template = customTemplate ?? defaultTemplate;

  const qUpdatePRCommentConfig = useUpdatePRCommentConfig({
    onError: (error) => {
      addAppNotification({
        message:
          error.response.data?.message ??
          fm({ defaultMessage: 'Failed to update PR Comment Template' }),
        severity: 'error',
      });
    },
  });

  const qCreatePRCommentConfig = useCreatePRCommentConfig({
    onError: (error) => {
      addAppNotification({
        message:
          error.response.data?.message ??
          fm({ defaultMessage: 'Failed to create PR Comment Template' }),
        severity: 'error',
      });
    },
  });

  const saveTemplate = (template: V1PRCommentsTemplate) => {
    if (template.findings_summary_template) {
      if (pRCommentConfig?.uuid) {
        qUpdatePRCommentConfig.mutate({
          mask: 'spec.template',
          namespace: pRCommentConfig.tenant_meta.namespace,
          resource: {
            uuid: pRCommentConfig.uuid,
            meta: { name: 'PR Comment Template' },
            spec: {
              template,
            },
          },
        });
      } else {
        qCreatePRCommentConfig.mutate({
          namespace: tenantName,
          resource: {
            meta: { name: 'PR Comment Template' },
            spec: {
              template,
            },
          },
        });
      }
    }
  };

  return {
    defaultTemplate,
    template,
    saveTemplate,
  };
};
