import { Grid } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { IconLock, NavigationTabs } from '@endorlabs/ui-common';

import { PageHeader } from '../../components';
import { useAuthInfo } from '../../providers';
import { useFullMatch } from '../../routes';
import { ApiKeysView } from './ApiKeysView';
import { AuthorizationPoliciesView } from './AuthorizationPoliciesView';
import { IdentityProvidersView } from './IdentityProvidersView';
import { InvitationsView } from './InvitationsView';

const AccessControlTabNames = {
  API_KEYS: 'api-keys',
  AUTHORIZATION_POLICIES: 'authorization-policies',
  IDENTITY_PROVIDER: 'identity-provider',
  INVITATIONS: 'invitations',
};

const AccessControlTabRecords = [
  {
    label: <FM defaultMessage="Auth Policy" />,
    to: AccessControlTabNames.AUTHORIZATION_POLICIES,
    value: AccessControlTabNames.AUTHORIZATION_POLICIES,
  },
  {
    label: <FM defaultMessage="API Keys" />,
    to: AccessControlTabNames.API_KEYS,
    value: AccessControlTabNames.API_KEYS,
  },
  {
    label: <FM defaultMessage="Custom Identity Provider" />,
    to: AccessControlTabNames.IDENTITY_PROVIDER,
    value: AccessControlTabNames.IDENTITY_PROVIDER,
  },
  {
    label: <FM defaultMessage="Invitations" />,
    to: AccessControlTabNames.INVITATIONS,
    value: AccessControlTabNames.INVITATIONS,
  },
];

export const AccessControlIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const {
    params: { activeView },
  } = useFullMatch();

  const activeTab =
    AccessControlTabRecords.find((r) => r.value === activeView)?.value ?? false;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          Icon={IconLock}
          title={<FM defaultMessage="Access Control" />}
          metadata={{ summary: [] }}
        />
      </Grid>

      <Grid item flexWrap="nowrap" sx={{ width: '100%' }} xs={12}>
        <NavigationTabs activeTab={activeTab} tabs={AccessControlTabRecords} />
      </Grid>

      <Grid item>
        {activeTab === AccessControlTabNames.AUTHORIZATION_POLICIES && (
          <AuthorizationPoliciesView namespace={tenantName} />
        )}
        {activeTab === AccessControlTabNames.INVITATIONS && (
          <InvitationsView namespace={tenantName} />
        )}
        {activeTab === AccessControlTabNames.API_KEYS && (
          <ApiKeysView namespace={tenantName} />
        )}
        {activeTab === AccessControlTabNames.IDENTITY_PROVIDER && (
          <IdentityProvidersView namespace={tenantName} />
        )}
      </Grid>
    </Grid>
  );
};
