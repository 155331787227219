import { MenuItem, Select, Typography } from '@mui/material';

import {
  FilterComparator,
  FilterComparatorDefinition,
} from '@endorlabs/filters';
import { IconChevronDown } from '@endorlabs/ui-common';
import { SelectFrom } from '@endorlabs/utils';

type ValueFilterComparator = Exclude<
  FilterComparator,
  'CONTAINS_ALL' | 'WITHIN_RANGE'
>;

export type FilterComparatorSelectOption = SelectFrom<
  FilterComparatorDefinition,
  'name',
  { comparator: ValueFilterComparator }
>;

export const FilterComparatorSelect = ({
  onChange,
  options,
  selectedOption,
}: {
  onChange: (selected: FilterComparatorSelectOption) => void;
  options: FilterComparatorSelectOption[];
  selectedOption: FilterComparatorSelectOption;
}) => {
  return (
    <Select
      IconComponent={IconChevronDown}
      value={selectedOption.comparator}
      renderValue={() => {
        return (
          <Typography
            component="span"
            variant="inherit"
            color="primary"
            fontWeight="medium"
          >
            {selectedOption.name}
          </Typography>
        );
      }}
      onChange={(event) => {
        const selected = options.find(
          (option) => option.comparator === event.target.value
        );
        if (selected) {
          onChange(selected);
        }
      }}
      MenuProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 'top' },
      }}
      sx={{
        '& .MuiSelect-select': { paddingLeft: 0, marginLeft: '1rem' },
        // remove the border
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        // set color for dropdown arrow
        '& .MuiSelect-icon': {
          color: 'primary.main',
          fontSize: 16,
        },
      }}
    >
      {options.map(({ comparator, name }) => (
        <MenuItem key={comparator} value={comparator}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};
