import { Stack, useTheme } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';
import {
  ButtonPrimary,
  ControlledTextField,
  SimpleDialog,
  SimpleDialogProps,
} from '@endorlabs/ui-common';

import { DEFAULT_SCAN_PROFILE_FIELD_VALUES } from '../constants';

export type ScanProfileCreateDialogState = {
  namespace: string;
  onSubmit: (scanProfile: ScanProfileResource) => void;
};

export type ScanProfileCreateDialogProps = Pick<
  SimpleDialogProps,
  'onClose' | 'open'
> & {
  state?: ScanProfileCreateDialogState;
};

export const ScanProfileCreateDialog = ({
  state,
  ...props
}: ScanProfileCreateDialogProps) => {
  const { space } = useTheme();
  const formMethods = useForm<ScanProfileResource>({
    defaultValues: DEFAULT_SCAN_PROFILE_FIELD_VALUES,
  });
  const { control, handleSubmit: hookFormSubmit } = formMethods;

  const handleSubmit = (fieldValues: ScanProfileResource) => {
    state?.onSubmit(fieldValues);
  };

  return (
    <SimpleDialog
      {...props}
      disableCloseOnOutsideClick
      maxWidth="md"
      titleText={<FM defaultMessage="Create new Scan Profile" />}
    >
      <FormProvider {...formMethods}>
        <form
          id="FormCreateScanProfile"
          noValidate
          onSubmit={hookFormSubmit(handleSubmit)}
        >
          <Stack spacing={space.md} alignItems="flex-start">
            <ControlledTextField
              control={control}
              label="Scan Profile Name"
              name="meta.name"
              required
            />

            <ButtonPrimary type="submit">
              <FM defaultMessage="Create Scan Profile" />
            </ButtonPrimary>
          </Stack>
        </form>
      </FormProvider>
    </SimpleDialog>
  );
};
