import { Row } from '@tanstack/react-table';
import { isBoolean as _isBoolean, isFunction as _isFunction } from 'lodash-es';

export const disableRowSelection = <RowType>(
  row: RowType,
  disabled: undefined | boolean | ((row: RowType) => boolean)
) => {
  return _isFunction(disabled)
    ? disabled(row)
    : _isBoolean(disabled)
    ? disabled
    : false;
};
