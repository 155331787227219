// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight-codeblock .token a {
    color: #1565C0;
}`, "",{"version":3,"sources":["webpack://./src/typescript/packages/ui-common/src/components/CodeBlock/prism-autolinker.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB","sourcesContent":[".highlight-codeblock .token a {\n    color: #1565C0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
