import { QueryKey, useQuery } from 'react-query';

import {
  ArtifactSignatureServiceApi,
  V1ArtifactSignature,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { ArtifactSignatureResource } from '@endorlabs/endor-core/Artifact';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

export type ArtifactSignatureResourceList =
  TResourceList<ArtifactSignatureResource>;

export type CountArtifactSignaturesOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
export type ListArtifactSignatureOptions =
  ResourceQueryOptions<ArtifactSignatureResourceList>;

export interface ArtifactSignatureWriteParams {
  namespace: string;
  resource: V1ArtifactSignature;
}

const BASE_KEY = 'v1/artifact-signature';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const ArtifactSignatureQueryKeys = QK;

const getApiService = () =>
  new ArtifactSignatureServiceApi(getClientConfiguration());

const countArtifactSignature = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.artifactSignatureServiceListArtifactSignatures(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountArtifactSignature = (
  namespace: string,
  opts: CountArtifactSignaturesOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ArtifactSignature',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countArtifactSignature(namespace, requestParameters),
    opts
  );
};

const listArtifactSignature = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.artifactSignatureServiceListArtifactSignatures(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as ArtifactSignatureResourceList;
};

export const useListArtifactSignature = (
  namespace: string,
  opts: ListArtifactSignatureOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ArtifactSignature',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listArtifactSignature(namespace, requestParameters),
    opts
  );
};
