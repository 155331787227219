import { FormattedMessage as FM } from 'react-intl';

import { MetricToolsByCategory } from '@endorlabs/queries';
import {
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  ToolLogoArrayDisplay,
} from '@endorlabs/ui-common';

import {
  ProjectCiCdToolsTableColumnId,
  ProjectCiCdToolsTableRow,
} from './types';

const DEFAULT_MAX_LOGO_COUNT = 10;

export const buildProjectCiCdToolsTableColumns = (
  options: {
    excludeColumns?: ProjectCiCdToolsTableColumnId[];
    maxToolCount?: number;
  } = {}
) => {
  const { excludeColumns = [], maxToolCount = DEFAULT_MAX_LOGO_COUNT } =
    options;

  const columns: DataTableColumnDef<ProjectCiCdToolsTableRow>[] = [
    {
      accessorKey: 'category',
      header: () => <FM defaultMessage="Tool Category" />,
    },
    {
      accessorKey: 'toolCount',
      colType: ColTypes.NUMBER_LARGE,
      header: () => <FM defaultMessage="Number of Tools" />,
      hidden: excludeColumns.includes('toolCount'),
    },
    {
      accessorKey: 'tools',
      cell: ({ getValue, row }) => {
        return (
          <ToolLogoArrayDisplay
            category={row.original.category}
            justifyContent="start"
            maxCount={maxToolCount}
            tools={getValue()}
          />
        );
      },
      header: () => <FM defaultMessage="Tools" />,
      size: 400,
    },
  ];

  return columns.filter((c) => !c.hidden);
};

export const mapToProjectCiCdToolsTableRows = (
  toolsByCategory: MetricToolsByCategory
): ProjectCiCdToolsTableRow[] => {
  const sortedToolCategories = Object.keys(toolsByCategory).sort((a, b) =>
    a.localeCompare(b)
  );

  return sortedToolCategories.map((category) => {
    const tools = toolsByCategory[category];
    const toolCount = tools.length;

    const row: ProjectCiCdToolsTableRow = {
      category,
      toolCount,
      tools,
    };

    return row;
  });
};
