import { styled, ToggleButton as MuiToggleButton } from '@mui/material';

export const ToggleButton = styled(MuiToggleButton)(
  ({ selectedcolor }: { selectedcolor?: string }) =>
    selectedcolor && {
      '&.Mui-selected, &.Mui-selected:hover': {
        color: 'white',
        backgroundColor: selectedcolor,
      },
      '&.Mui-selected:hover': {
        boxShadow: 'inset 0px 0px 0px 50px rgba(0,0,0,0.2);',
      },
    }
);
