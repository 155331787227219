/**
 * Set or override search parameters for a given url,
 * respecting existing paremeters
 */
export const setURLSearchParams = (
  url: string,
  params: Record<string, string>,
  base = location.origin
): string => {
  const parsed = new URL(url, base);
  for (const [key, value] of Object.entries(params)) {
    parsed.searchParams.set(key, value);
  }

  return parsed.toString();
};
