import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import _difference from 'lodash-es/difference';
import _isEqual from 'lodash-es/isEqual';
import _omit from 'lodash-es/omit';
import { ElementType, ReactNode, useEffect } from 'react';
import create from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export const DRAWER_SEARCH_PARAM = 'resourceDetail';

type DrawerSearchParams = Record<string, string | undefined> | undefined;

type InfoDrawerLocationGenerics = MakeGenerics<{
  Search: {
    [DRAWER_SEARCH_PARAM]: string;
  };
}>;

export interface InfoDrawerStore {
  // The populated component currently displayed
  activeContent?: ReactNode;

  // The search parameters associated with activeContent. These update in sync with activeContent.
  activeContentParams: DrawerSearchParams;

  // Clear "active" content
  clearContent: () => void;

  // Internal map of serialized search params to content components
  componentRegistry: Record<string, ElementType>;

  // Get a specific component
  getComponent: (key: string) => ElementType;

  // Associate a component with a set of search params
  registerComponent: (key: string, component: ElementType) => void;

  // Update active content
  setContent: (
    contentParams: DrawerSearchParams,
    newContent: ReactNode
  ) => void;
}

/**
 * Global store for internal management of registered drawer content
 */
export const useInfoDrawerStore = create(
  subscribeWithSelector<InfoDrawerStore>((setState, getState) => {
    return {
      activeContent: undefined,

      activeContentParams: {},

      clearContent: () =>
        setState((state) => ({
          ...state,
          activeContent: undefined,
          activeContentParams: {},
        })),
      componentRegistry: {},

      getComponent: (key) => getState().componentRegistry[key],

      registerComponent: (key, component) =>
        setState((state) => {
          if (!state.componentRegistry[key]) {
            const updatedRegistry = {
              ...state.componentRegistry,
              [key]: component,
            };
            return { ...state, componentRegistry: updatedRegistry };
          }

          return state;
        }),

      setContent: (newContentParams, newContent) =>
        setState((state) => ({
          ...state,
          activeContent: newContent,
          activeContentParams: newContentParams,
        })),
    };
  })
);

interface UseInfoDrawerProps<T> {
  Component?: ElementType;
  drawerParams?: T[keyof T][];
}

/**
 * Query String parameters are parsed as string.
 *
 * Attempt to parse the JSON-encoded value from the param.
 */
const parseActiveSearchParams = (
  searchParams: Record<string, string>
): DrawerSearchParams => {
  if (searchParams[DRAWER_SEARCH_PARAM]) {
    const activeSearchParams = searchParams[DRAWER_SEARCH_PARAM];
    try {
      const parsed = JSON.parse(activeSearchParams);
      return parsed;
    } catch (e) {
      // failed to parse params
    }
  }

  return undefined;
};

/**
 * Hook to register and activate content within the InfoDrawer
 *
 * const WidgetDrawerContentCmp = (props) => <Typography>Info for {props.widget}</Typography>
 *
 * const WidgetInfoDrawer = useInfoDrawer(['widgetUuid], WidgetDrawerContentCmp);
 *
 * const onClick = (widgetUuid) => {
 *   const activeWidget = widgets.find(w => w.uuid === widgetUuid);
 *   WidgetInfoDrawer.activate({ widgetUuid }, { widget: activeWidget })
 * }
 */
export function useInfoDrawer<T, DrawerComponentProps extends object>({
  Component,
  drawerParams,
}: UseInfoDrawerProps<T> = {}) {
  const searchParams = useSearch<InfoDrawerLocationGenerics>();
  const navigate = useNavigate();

  const {
    activeContent,
    activeContentParams,
    clearContent,
    getComponent,
    registerComponent,
    setContent,
  } = useInfoDrawerStore();

  // Relevant drawer search params from current URL
  const activeSearchParams = parseActiveSearchParams(searchParams);

  // Accessor
  const serializedKey = JSON.stringify(drawerParams);

  useEffect(() => {
    // If provided with appropriate arguments, register new component
    if (Component && !getComponent(serializedKey)) {
      registerComponent(serializedKey, Component);
    }
  });

  /**
   * Function to activate the InfoDrawer with new content
   * @param newDrawerParams - Object serialized in the URL as InfoDrawer-specific search params.
   * @param componentProps - Props passed to the registered component. Determines InfoDrawer content.
   */
  const activate = (
    newDrawerParams: DrawerSearchParams,
    componentProps: DrawerComponentProps = {} as DrawerComponentProps
  ) => {
    // Bail if provided parameters match those of active content.
    if (newDrawerParams === activeContentParams) return;

    // Bail if activate request does not include values for all registered params
    // NOTE: This means callers cannot omit keys for undefined values.
    const newDrawerKeys = newDrawerParams ? Object.keys(newDrawerParams) : [];
    const includesAllKeys =
      _difference(newDrawerKeys, JSON.parse(serializedKey)).length === 0;
    if (!includesAllKeys) return;

    // Otherwise, hydrate registered component for these parameters & set as active content.
    const ActiveDrawerCmp = getComponent(serializedKey);
    const hydratedCmp = ActiveDrawerCmp ? (
      <ActiveDrawerCmp {...componentProps} />
    ) : undefined;

    if (!hydratedCmp) return;

    setContent(newDrawerParams, hydratedCmp);

    // Update params in URL if they don't match new content params.
    if (!_isEqual(activeSearchParams ?? {}, newDrawerParams)) {
      navigate({
        search: (old) => ({
          ...old,
          [DRAWER_SEARCH_PARAM]: JSON.stringify(newDrawerParams),
        }),
        replace: true,
      });
    }
  };

  const close = () => {
    clearContent();
    navigate({
      search: (old) => _omit(old, DRAWER_SEARCH_PARAM),
      replace: true,
    });
  };

  const isOpen = Boolean(activeSearchParams) && activeContent !== undefined;

  const returnObj = {
    activate,
    activeContent,
    close,
    getSearchParams: () => activeSearchParams,
    isOpen,
    matchesActiveContent: (paramsToCheck: DrawerSearchParams) =>
      _isEqual(paramsToCheck, activeContentParams),
    toggle: (
      newDrawerParams: DrawerSearchParams,
      componentProps: DrawerComponentProps = {} as DrawerComponentProps
    ) => (isOpen ? close() : activate(newDrawerParams, componentProps)),
  };

  return returnObj;
}
