import { Stack, Typography, TypographyProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonLinkPrimary, IconLock, Pip } from '@endorlabs/ui-common';

import { getEnv } from '../../constants';

const { URL_ENDOR_SALES } = getEnv();

export const LicenseRequired = ({
  description = <FM defaultMessage="Please contact sales to get access." />,
  descriptionTypographyProps,
  title = <FM defaultMessage="Endor Labs License Required" />,
  titleTypographyProps,
}: {
  description?: ReactNode;
  descriptionTypographyProps?: TypographyProps;
  title?: ReactNode;
  titleTypographyProps?: TypographyProps;
}) => {
  const { palette, space } = useTheme();

  return (
    <Stack alignItems="center" gap={space.xs}>
      <Pip
        icon={<IconLock fontSize="xsmall" />}
        label={<FM defaultMessage="License required" />}
        size="xsmall"
      />

      <Typography
        color={palette.brand.main}
        component="span"
        fontWeight={800}
        textAlign="center"
        variant="h2"
        {...titleTypographyProps}
      >
        {title}
      </Typography>

      {description && (
        <Typography
          textAlign="center"
          variant="body1"
          {...descriptionTypographyProps}
        >
          {description}
        </Typography>
      )}

      <ButtonLinkPrimary
        linkProps={{ target: '_blank', to: URL_ENDOR_SALES }}
        size="small"
      >
        <FM defaultMessage="Contact Sales" />
      </ButtonLinkPrimary>
    </Stack>
  );
};
