import { get as _get } from 'lodash-es';
import { Entries } from 'type-fest';

import { V1Language } from '@endorlabs/api_client';

import { LANGUAGE_MAP, LanguageString } from './constants';

export const isLanguageEnum = (value: unknown): value is V1Language => {
  return !!value && Object.values(V1Language).some((v) => v === value);
};

/**
 * Given a language string, return the corresponding language enum
 */
export const toLanguageEnum = (value: LanguageString): V1Language => {
  for (const [langEnum, langString] of Object.entries(LANGUAGE_MAP) as Entries<
    typeof LANGUAGE_MAP
  >) {
    if (langString === value) {
      return langEnum;
    }
  }

  return V1Language.Unspecified;
};

/**
 * Given a language enum, return the corresponding language string
 */
export const toLanguageString = (value: V1Language): LanguageString => {
  return _get(LANGUAGE_MAP, [value], '');
};
