import { Skeleton, TableCell, TableRow } from '@mui/material';

/**
 * Loading state for Data Table Body
 */
export function DataTableSkeletonRows({
  columnCount,
}: {
  columnCount: number;
}) {
  const rowCount = 8;
  const colArr = Array(columnCount).fill(undefined);
  const rowArr = Array(rowCount).fill(undefined);

  return (
    <>
      {rowArr.map((r, i) => {
        return (
          <TableRow key={i}>
            {colArr.map((c, j) => (
              <TableCell key={j}>
                <Skeleton height={26} />
              </TableCell>
            ))}
          </TableRow>
        );
      })}
    </>
  );
}
