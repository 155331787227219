import { Box } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  V1ScoreCard,
  V1ScoreCategory,
  V1ScoreFactor,
} from '@endorlabs/api_client';
import { UIMetricsUtils } from '@endorlabs/ui-common';

import { DetailDrawerTabs } from '../../../../components/DetailDrawer';
import { ScoreFactorsList } from '../../../Metrics';

const DEFAULT_SCORE_CATEGORIES: V1ScoreCategory[] = [
  V1ScoreCategory.Security,
  V1ScoreCategory.Activity,
  V1ScoreCategory.Popularity,
  V1ScoreCategory.CodeQuality,
];

export const ScoreFactorsListTabs = ({
  includeAll = true,
  isLoading,
  scoreCard,
  scoreCategories = DEFAULT_SCORE_CATEGORIES,
  scoreFactors,
}: {
  includeAll?: boolean;
  isLoading: boolean;
  scoreCard?: V1ScoreCard;
  scoreCategories?: V1ScoreCategory[];
  scoreFactors?: V1ScoreFactor[];
}) => {
  const tabRecords = useMemo(() => {
    const tabRecords = [];

    if (includeAll) {
      tabRecords.push({
        label: <FM defaultMessage="All Factors" />,
        value: 'allFactors',
      });
    }

    for (const category of scoreCategories) {
      tabRecords.push({
        label: UIMetricsUtils.getScoreCategoryLabel(category),
        value: category,
      });
    }

    return tabRecords;
  }, [includeAll, scoreCategories]);

  const getScoreFactorList = (category?: V1ScoreCategory) => {
    return (
      <Box sx={{ marginX: 'auto', padding: 4, width: '70%' }}>
        <ScoreFactorsList
          isLoading={isLoading}
          scoreCard={scoreCard}
          scoreCategory={category}
          scoreFactors={scoreFactors}
        />
      </Box>
    );
  };

  return (
    <DetailDrawerTabs
      id="ModelScoreTabs"
      tabRecords={tabRecords}
      tabPanelMap={{
        allFactors: getScoreFactorList(),
        [V1ScoreCategory.Security]: getScoreFactorList(
          V1ScoreCategory.Security
        ),
        [V1ScoreCategory.Activity]: getScoreFactorList(
          V1ScoreCategory.Activity
        ),
        [V1ScoreCategory.Popularity]: getScoreFactorList(
          V1ScoreCategory.Popularity
        ),
        [V1ScoreCategory.CodeQuality]: getScoreFactorList(
          V1ScoreCategory.CodeQuality
        ),
      }}
    />
  );
};
