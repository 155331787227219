import { isMatch as _isMatch, uniq as _uniq } from 'lodash-es';

import {
  Filter,
  FILTER_COMPARATORS,
  FilterExpression,
  isLogicFilter,
  isValueFilter,
  LogicFilter,
  ValueFilter,
} from '@endorlabs/filters';
import { FilterParser } from '@endorlabs/filters/parser';

const SEARCH_COMPARATORS: Extract<
  ValueFilter['comparator'],
  'EQUAL' | 'MATCHES'
>[] = [FILTER_COMPARATORS.EQUAL, FILTER_COMPARATORS.MATCHES];

export const buildFilterValueMap = (filters: Filter[]): Map<string, Filter> => {
  const valueMap = new Map();

  for (const filter of filters) {
    if (isValueFilter(filter)) {
      valueMap.set(filter.key, filter);
      continue;
    }

    const keys = getParsedFilterKeys(filter);
    if (keys.length == 1) {
      valueMap.set(keys[0], filter);
    }
  }

  return valueMap;
};

const getParsedFilterKeys = (filter: Filter): string[] => {
  if (isValueFilter(filter)) {
    return [filter.key];
  }

  return _uniq(filter.value.flatMap((f) => getParsedFilterKeys(f)));
};

const isSearchValueFilter = (
  f: Filter,
  searchKeys: string[]
): f is ValueFilter =>
  isValueFilter(f) &&
  SEARCH_COMPARATORS.some((c) => c === f.comparator) &&
  searchKeys.includes(f.key);

const isSearchValueLogicFilter = (
  f: Filter,
  searchKeys: string[]
): f is LogicFilter =>
  isLogicFilter(f) && f.value.every((f) => isSearchValueFilter(f, searchKeys));

export const parseFilterStateExpression = (
  expression: FilterExpression,
  searchKeys: string[] = [],
  baseFilterExpression?: FilterExpression
) => {
  const parser = new FilterParser();

  const result = parser.safeParse(expression);
  if (!result.ok) {
    return { expression };
  }

  const filter = result.value;

  if (isSearchValueFilter(filter, searchKeys)) {
    if ('string' === typeof filter.value) {
      return { search: filter.value };
    }

    return { expression };
  }

  if (
    !isLogicFilter(filter) ||
    (isLogicFilter(filter) && filter.operator !== 'AND')
  ) {
    return { expression };
  }

  // shallow clone the filter values
  const filterValues = filter.value.slice();

  // remove base filter, if found
  if (baseFilterExpression) {
    const result = parser.safeParse(baseFilterExpression);
    if (result.ok) {
      const baseFilterIndex = filterValues.findIndex((f) =>
        _isMatch(f, result.value)
      );

      if (baseFilterIndex !== -1) {
        filterValues.splice(baseFilterIndex, 1);
      } else {
        return { expression };
      }
    } else {
      return { expression };
    }
  }

  // extract search values, if present
  let search;
  if (searchKeys.length) {
    const searchValues = new Set<string>();
    const searchIndex = filterValues.findIndex((f) => {
      if (isSearchValueFilter(f, searchKeys)) {
        const value = Array.isArray(f.value) ? f.value[0] : f.value;
        if ('string' === typeof value) {
          searchValues.add(value);
          return true;
        }
        return false;
      }

      if (isSearchValueLogicFilter(f, searchKeys)) {
        for (const sf of f.value) {
          const value = Array.isArray(sf.value) ? sf.value[0] : sf.value;
          if ('string' !== typeof value) {
            return false;
          }
          searchValues.add(value);
        }

        return true;
      }
    });

    if (searchValues.size === 1 && searchIndex !== -1) {
      search = Array.from(searchValues.values())[0];
      filterValues.splice(searchIndex, 1);
    }
  }

  if (filterValues.length === 0) {
    return { search };
  }

  if (filterValues.length == 1) {
    const first = filterValues[0];
    if (isValueFilter(first)) {
      return { search, values: buildFilterValueMap([first]) };
    } else if (first.operator !== 'AND') {
      return { expression };
    }

    // If the first filter is a nested `AND` for mixed keys, unwrap
    const keys = getParsedFilterKeys(first);
    if (keys.length > 1) {
      // replace the filter values with the logical group found in first
      filterValues.splice(0, 1, ...first.value);
    }
  }

  const valueMap = new Map();
  for (const filter of filterValues) {
    if (isValueFilter(filter)) {
      valueMap.set(filter.key, filter);
      continue;
    }

    // If all filters within the logical filter are for the same key, set as value
    const keys = getParsedFilterKeys(filter);
    if (keys.length == 1) {
      valueMap.set(keys[0], filter);
    }
  }

  // Only return if all values were parsed
  if (valueMap.size === filterValues.length) {
    return { search, values: valueMap };
  }

  return { expression };
};
