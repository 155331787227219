import { QueryKey, useQuery } from 'react-query';

import {
  PackageVersionServiceApi,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { PackageVersionResource } from '@endorlabs/endor-core/PackageVersion';

import { useBuildReadRequestParameters } from './hooks';
import { ResourceQueryOptions, TResourceList } from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
  listAllResource,
} from './utils';

export interface PackageVersionReadParams {
  namespace: string;
  uuid: string;
}

export type CountPackageVersionOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
export type ListPackageVersionOptions =
  ResourceQueryOptions<PackageVersionResourceList>;
export type ListALlPackageVersionOptions = ResourceQueryOptions<
  PackageVersionResource[]
>;
export type GetPackageVersionOptions =
  ResourceQueryOptions<PackageVersionResource>;

export type PackageVersionResourceList = TResourceList<PackageVersionResource>;

const BASE_KEY = 'v1/package-versions';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list-all', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const PackageVersionQueryKeys = QK;

const getApiService = () =>
  new PackageVersionServiceApi(getClientConfiguration());

const countPackageVersions = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.packageVersionServiceListPackageVersions(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountPackageVersions = (
  namespace: string,
  opts: CountPackageVersionOptions = {},
  countParams: CountRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersion',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countPackageVersions(namespace, requestParameters),
    opts
  );
};

const listPackageVersions = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.packageVersionServiceListPackageVersions(
    namespace,
    ...buildListParamArgs(listParams),
    { signal }
  );

  return resp.data as PackageVersionResourceList;
};

export const useListPackageVersions = (
  namespace: string,
  opts: ListPackageVersionOptions = {},
  listParams: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersion',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listPackageVersions(namespace, requestParameters),
    opts
  );
};

export const useListAllPackageVersions = (
  namespace: string,
  opts: ListALlPackageVersionOptions = {},
  listParams: ListAllRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersion',
    'LIST_ALL',
    listParams,
    opts
  );

  return useQuery(
    QK.listAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<PackageVersionResource, PackageVersionResourceList>(
        (pageToken) => {
          const pageListParams: V1ListParameters = {
            // take base params, and add the page param
            ...requestParameters,
            page_token: pageToken,
          };

          return listPackageVersions(namespace, pageListParams, ctx.signal);
        },
        { signal: ctx.signal }
      ),
    opts
  );
};

const getPackageVersion = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.packageVersionServiceGetPackageVersion(
    namespace,
    uuid
  );
  return resp.data as PackageVersionResource;
};

export const useGetPackageVersion = (
  params: PackageVersionReadParams,
  opts: GetPackageVersionOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getPackageVersion(params.namespace, params.uuid),
    opts
  );
};
