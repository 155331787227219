import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  AuthorizationPolicyServiceApi,
  V1AuthorizationPolicy,
  V1CountResponse,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { AuthorizationPolicyResource } from '@endorlabs/endor-core/AuthorizationPolicy';

import { invalidateListQueries } from './client';
import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceListResponse,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface AuthPolicyReadParams {
  namespace: string;
  uuid: string;
}

export interface AuthPolicyWriteParams {
  namespace: string;
  resource: V1AuthorizationPolicy;
}

export interface AuthPolicyUpdateParams extends AuthPolicyWriteParams {
  mask?: string;
}

type CountAuthPolicyOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListAuthPolicyOptions = ResourceQueryOptions<
  ResourceListResponse<AuthorizationPolicyResource>
>;
type GetAuthPolicyOptions = ResourceQueryOptions<AuthorizationPolicyResource>;
type UpsertAuthPolicyOptions = ResourceMutateOptions<
  V1AuthorizationPolicy,
  AuthPolicyWriteParams
>;
type DeleteAuthorizationPolicyOptions = ResourceMutateOptions<
  object,
  AuthPolicyReadParams
>;

const BASE_KEY = 'v1/authorizationPolicies';
const QK = {
  count: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, listParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const AuthorizationPolicyQueryKeys = QK;

export const AUTH_POLICY_UPDATE_MASK = 'meta,spec';

const getApiService = () =>
  new AuthorizationPolicyServiceApi(getClientConfiguration());

const countAuthorizationPolicies = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceListAuthorizationPolicies(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountAuthorizationPolicies = (
  namespace: string,
  opts: CountAuthPolicyOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'AuthorizationPolicy',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countAuthorizationPolicies(namespace, requestParameters),
    opts
  );
};

const listAuthorizationPolicies = async (
  namespace: string,
  listParams?: V1ListParameters
) => {
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceListAuthorizationPolicies(
    namespace,
    ...buildListParamArgs(listParams ?? {})
  );
  return resp.data.list as ResourceListResponse<AuthorizationPolicyResource>;
};

export const useListAuthorizationPolicies = (
  namespace: string,
  opts: ListAuthPolicyOptions = {},
  listParams?: Omit<ListRequestParameters, 'group'>
) => {
  const requestParameters = useBuildReadRequestParameters(
    'AuthorizationPolicy',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listAuthorizationPolicies(namespace, requestParameters),
    opts
  );
};

const getAuthorizationPolicy = async ({
  namespace,
  uuid,
}: AuthPolicyReadParams) => {
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceGetAuthorizationPolicy(
    namespace,
    uuid
  );
  return resp.data as AuthorizationPolicyResource;
};

export const useGetAuthorizationPolicy = (
  params: AuthPolicyReadParams,
  opts: GetAuthPolicyOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getAuthorizationPolicy(params),
    opts
  );
};

const createAuthorizationPolicy = async (params: AuthPolicyWriteParams) => {
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceCreateAuthorizationPolicy(
    params.namespace,
    params.resource
  );
  return resp.data as AuthorizationPolicyResource;
};

export const useCreateAuthorizationPolicy = (
  opts: UpsertAuthPolicyOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'AuthorizationPolicy'),
    mutationFn: (params: AuthPolicyWriteParams) =>
      createAuthorizationPolicy(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          includeChildren: true,
          includePropagated: true,
        });
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const updateAuthorizationPolicy = async (params: AuthPolicyUpdateParams) => {
  const { resource, namespace, mask = AUTH_POLICY_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceUpdateAuthorizationPolicy(
    namespace,
    req
  );
  return resp.data as AuthorizationPolicyResource;
};

export const useUpdateAuthorizationPolicy = (
  opts: UpsertAuthPolicyOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'AuthorizationPolicy'),
    mutationFn: (params: AuthPolicyUpdateParams) =>
      updateAuthorizationPolicy(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          includeChildren: true,
          includePropagated: true,
        });

        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

const deleteAuthorizationPolicy = async (params: AuthPolicyReadParams) => {
  const api = getApiService();
  const resp = await api.authorizationPolicyServiceDeleteAuthorizationPolicy(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

export const useDeleteAuthorizationPolicy = (
  opts: DeleteAuthorizationPolicyOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'AuthorizationPolicy'),
    mutationFn: (params: AuthPolicyReadParams) =>
      deleteAuthorizationPolicy(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        invalidateListQueries(queryClient, QK.list(vars.namespace), {
          includeChildren: true,
          includePropagated: true,
        });
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
