import { Alert, AlertTitle, Box, Stack, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ApiKeyResource } from '@endorlabs/queries';
import {
  AttributeDisplay,
  ButtonPrimary,
  CodeBlock,
  useCopyToClipboard,
} from '@endorlabs/ui-common';

/**
 * YAML formatted summary of the API Key
 */
const getApiKeyClipboardText = (apiKey: ApiKeyResource) => `\
# Endor API Key: ${apiKey.meta.name}
# Expires: ${new Date(apiKey.spec.expiration_time).toISOString()}
ENDOR_API_CREDENTIALS_KEY: ${apiKey.spec.key}
ENDOR_API_CREDENTIALS_SECRET: ${apiKey.spec.secret}
`;

/**
 * Preview a fenerated API Key
 */
export const APIKeyPreview = ({ apiKey }: { apiKey?: ApiKeyResource }) => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const [isCopied, copyToClipboard, setIsCopied] = useCopyToClipboard(
    apiKey
      ? getApiKeyClipboardText(apiKey)
      : fm({ defaultMessage: 'Failed to copy API Key' })
  );

  useEffect(() => {
    if (!isCopied) return;
    // NOTE: clearing timer in effect to prevent state change after unmount
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 1800);
    return () => clearTimeout(timer);
  }, [isCopied, setIsCopied]);

  if (!apiKey) return null;

  return (
    <Stack spacing={space.sm}>
      <Alert severity="warning" variant="outlined">
        <AlertTitle>
          <FM defaultMessage="Save your API secret in a safe place. You will not be able to access it again from here." />
        </AlertTitle>
      </Alert>

      <AttributeDisplay
        heading={<FM defaultMessage="API Key ID" />}
        value={<CodeBlock value={apiKey.spec.key ?? ''} />}
      />

      <AttributeDisplay
        heading={<FM defaultMessage="API Secret" />}
        value={<CodeBlock value={apiKey.spec.secret ?? ''} />}
      />

      <Box>
        <ButtonPrimary onClick={() => copyToClipboard()} disabled={isCopied}>
          {isCopied ? (
            <FM defaultMessage="API Key &amp; Secret Copied" />
          ) : (
            <FM defaultMessage="Copy API Key &amp; Secret" />
          )}
        </ButtonPrimary>
      </Box>
    </Stack>
  );
};
