import { Box } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Permissions } from '@endorlabs/api_client';
import { AuthorizationPolicyIdentitySource } from '@endorlabs/endor-core/AuthorizationPolicy';
import { IdentityProviderResource } from '@endorlabs/queries';
import {
  DataTable,
  DataTableActionDropdown,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableProps,
  ExpirationTimeDisplay,
  OverflowFormattedListDisplay,
  PermissionsDisplay,
} from '@endorlabs/ui-common';

import { AuthPolicyIdentitySourceDisplay } from './AuthPolicyIdentitySourceDisplay';

export type AuthorizationPoliciesTableRow = {
  uuid: string;
  namespace: string;
  targetNamespaces: string[];
  identitySource?: AuthorizationPolicyIdentitySource;
  identityClaims: string[];
  permissions?: V1Permissions;
  expirationTime?: string;
};

export interface AuthorizationPoliciesTableProps
  extends Omit<DataTableProps<AuthorizationPoliciesTableRow>, 'columns'> {
  activeNamespace: string;
  identityProvider?: IdentityProviderResource;
  onEdit: (event: MouseEvent, row: AuthorizationPoliciesTableRow) => void;
  onDelete: (event: MouseEvent, row: AuthorizationPoliciesTableRow) => void;
}

const buildAuthorizationPoliciesTableColumns = ({
  activeNamespace,
  identityProvider,
  onEdit,
  onDelete,
}: Pick<
  AuthorizationPoliciesTableProps,
  'activeNamespace' | 'identityProvider' | 'onEdit' | 'onDelete'
>): DataTableColumnDef<AuthorizationPoliciesTableRow>[] => [
  {
    accessorKey: 'identitySource',
    header: () => <FM defaultMessage="Identity Provider" />,
    cell: ({ getValue }) => (
      <AuthPolicyIdentitySourceDisplay
        value={getValue()}
        identityProvider={identityProvider}
      />
    ),
    colType: ColTypes.PACKAGE,
  },
  {
    accessorKey: 'identityClaims',
    header: () => <FM defaultMessage="Rule" />,
    cell: ({ getValue, row }) => {
      const claims = getValue() as string[];
      const shouldHighlight = row.original.namespace === activeNamespace;

      return (
        <OverflowFormattedListDisplay
          value={claims}
          typographyProps={{ fontWeight: shouldHighlight ? 600 : 0 }}
        />
      );
    },
  },
  {
    accessorKey: 'permissions',
    header: () => <FM defaultMessage="Permissions" />,
    cell: ({ getValue }) => <PermissionsDisplay value={getValue()} />,
    colType: ColTypes.TAGS,
  },
  {
    accessorKey: 'namespace',
    colType: ColTypes.NAMESPACE,
  },
  {
    accessorKey: 'targetNamespaces',
    header: () => <FM defaultMessage="Applies To" />,
    cell: ({ getValue }) => <OverflowFormattedListDisplay value={getValue()} />,
  },
  {
    accessorKey: 'expirationTime',
    header: () => <FM defaultMessage="Expiration Time" />,
    cell: ({ getValue }) => <ExpirationTimeDisplay value={getValue()} />,
    colType: ColTypes.RELATIVE_TIME,
  },
  {
    id: 'actions',
    cell: ({ row }) =>
      row.original ? (
        <Box display="flex" justifyContent="end">
          <DataTableActionDropdown
            items={[
              {
                label: <FM defaultMessage="Edit Auth Policy" />,
                onClick: (event) =>
                  onEdit(event, row.original as AuthorizationPoliciesTableRow),
              },
              {
                isDestructive: true,
                label: <FM defaultMessage="Delete Auth Policy" />,
                onClick: (event) =>
                  onDelete(
                    event,
                    row.original as AuthorizationPoliciesTableRow
                  ),
              },
            ]}
          />
        </Box>
      ) : null,
    colType: ColTypes.ACTIONS,
  },
];

export const AuthorizationPoliciesTable = ({
  activeNamespace,
  identityProvider,
  onEdit,
  onDelete,
  ...props
}: AuthorizationPoliciesTableProps) => {
  const columns = useMemo(
    () =>
      buildAuthorizationPoliciesTableColumns({
        activeNamespace,
        identityProvider,
        onEdit,
        onDelete,
      }),
    [activeNamespace, identityProvider, onEdit, onDelete]
  );
  return <DataTable {...props} columns={columns} />;
};
