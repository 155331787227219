import { CircularProgress } from '@mui/material';
import {
  MakeGenerics,
  Navigate,
  useNavigate,
  useRouter,
} from '@tanstack/react-location';
import { ReactNode, useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useAuthentication } from '@endorlabs/queries';
import { EmptyState, Link } from '@endorlabs/ui-common';

import { NamedRoutes } from '../constants';

export type AuthenticatedRedirectProps = {
  /**
   * The base url for the redirect
   */
  baseUrl: string;
  /**
   * Timeout before automatically redirecting the user to the new route
   * Set to `false` to disable the automatic redirect.
   *
   * @default 0
   */
  redirectTimeout?: number | false;
  /**
   * Prefix that should be removed from the redirect pathname
   */
  stripPrefix?: string;
  /**
   * Title for the redirect
   */
  title: ReactNode;
};

type LocationGenerics = MakeGenerics<{
  Search: {
    // Partial typing for unknown search params
  };
}>;

export const AuthenticatedRedirect = ({
  baseUrl,
  redirectTimeout = 0,
  stripPrefix,
  title,
}: AuthenticatedRedirectProps) => {
  const { isAuthenticated, isLoading } = useAuthentication();

  const router = useRouter<LocationGenerics>();
  const navigate = useNavigate();

  const { search: currentSearch, pathname: currentPathname } =
    router.state.location;

  // cleanup pathname
  let cleanPathname = router.state.location.pathname;
  if (stripPrefix && cleanPathname.startsWith(stripPrefix)) {
    cleanPathname = cleanPathname.slice(stripPrefix.length);
  }
  if (cleanPathname.startsWith('/')) {
    cleanPathname = cleanPathname.slice(1);
  }

  const redirectTo = [baseUrl, cleanPathname].join('/');

  useEffect(() => {
    if (!isAuthenticated || redirectTimeout === false) return;

    const timer = setTimeout(() => {
      if (redirectTo.startsWith('http')) {
        const params = new URLSearchParams(currentSearch);

        // NOTE: navigating to new domain, unable to use `navigate(...)`
        window.location.assign(
          redirectTo + (params.size ? `?${params.toString()}` : '')
        );
        return;
      }

      navigate({ to: redirectTo, search: currentSearch });
    }, redirectTimeout);

    return () => clearTimeout(timer);
  }, [isAuthenticated, navigate, redirectTimeout, redirectTo, currentSearch]);

  if (isLoading) {
    return (
      <EmptyState size="large" title={<FM defaultMessage="Loading &hellip;" />}>
        <CircularProgress />;
      </EmptyState>
    );
  }

  // When the user is not authenticated: redirect to login, and set the return to the current route
  if (!isAuthenticated) {
    const params = new URLSearchParams(currentSearch);
    let returnTo = currentPathname;
    if (params.size) {
      returnTo += `?${params.toString()}`;
    }

    return <Navigate to={NamedRoutes.LOGIN} search={{ return_to: returnTo }} />;
  }

  return (
    <EmptyState
      size="large"
      title={
        <FM
          defaultMessage="Redirecting to {title} &hellip;"
          values={{ title }}
        />
      }
    >
      <Link to={redirectTo} search={currentSearch}>
        {redirectTo}
      </Link>
    </EmptyState>
  );
};
