import { List, ListSubheader, Typography, useTheme } from '@mui/material';

import {
  PageNavigationListItem,
  PageNavigationListItemProps,
} from './PageNavigationListItem';

export interface PageNavigationGroupProps
  extends Omit<PageNavigationListItemProps, 'isSelected' | 'onClick'> {
  groupItems?: PageNavigationListItemProps[];
  handleSelection?: PageNavigationListItemProps['onClick'];
  selectedItemKey?: string;
}

export type PageNavigationItem = PageNavigationGroupProps;

export const PageNavigationListRenderer = ({
  groupItems,
  handleSelection,
  selectedItemKey,
  ...item
}: PageNavigationGroupProps) => {
  return groupItems ? (
    <PageNavigationGroup
      {...item}
      groupItems={groupItems}
      handleSelection={handleSelection}
      selectedItemKey={selectedItemKey}
    />
  ) : (
    <PageNavigationListItem
      {...item}
      isSelected={selectedItemKey === item.navKey}
      onClick={handleSelection}
    />
  );
};

export const PageNavigationGroup = ({
  groupItems = [],
  handleSelection,
  label,
  selectedItemKey,
}: PageNavigationGroupProps) => {
  const { spacing } = useTheme();

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader
          sx={{ backgroundColor: 'transparent', p: '8px 0px', display: 'flex' }}
        >
          <Typography variant="body2" fontWeight={600}>
            {label}
          </Typography>
        </ListSubheader>
      }
      sx={{ pl: spacing(4) }}
    >
      {groupItems.map((item, index) => (
        <PageNavigationListRenderer
          key={item?.navKey ?? index}
          {...item}
          handleSelection={handleSelection}
          selectedItemKey={selectedItemKey}
        />
      ))}
    </List>
  );
};
