import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { CiCdToolEvidence } from '@endorlabs/api_client';
import { AttributeDisplay } from '@endorlabs/ui-common';

export const ToolEvidenceBadgesDisplay = ({
  evidences,
}: {
  evidences?: CiCdToolEvidence[];
}) => {
  const { palette, spacing } = useTheme();
  if (!evidences) {
    return;
  }

  return (
    <AttributeDisplay
      disableTypography
      heading={<FM defaultMessage="Badges" />}
      value={
        <Stack spacing={spacing(2)}>
          {evidences.map((evidence) => {
            const badgeUrl = evidence.badge;

            return (
              <Typography
                key={badgeUrl}
                color={palette.info.main}
                noWrap
                paddingRight={4}
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {badgeUrl}
              </Typography>
            );
          })}
        </Stack>
      }
    />
  );
};
