import { Alert, Grid, Stack, Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { RowSelectionState } from '@tanstack/react-table';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1Method, V1PlatformSource } from '@endorlabs/api_client';
import {
  useCountProjects,
  useDeleteManyProjects,
  useDeleteProject,
} from '@endorlabs/queries';
import {
  ButtonPrimary,
  EmptyState,
  useAppNotify,
  useConfirmationDialog,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  PageHeader,
  TagEditorDialog,
  useFilterBuilder,
} from '../../components';
import { useOnboardingSteps } from '../../domains/Onboarding';
import { StartDemoFromProjects } from '../../domains/Onboarding/components/StartDemoFromProjects';
import { useUpdateMultiProjectFields } from '../../domains/Projects';
import { useAuthInfo, useAuthTenantInfo } from '../../providers';
import { getProjectPath } from '../../routes';
import { ProjectsTableRow } from './ProjectsTable';
import { ProjectsTableView } from './ProjectsTableView';
import { useProjectsIndexPageData } from './useProjectsIndexPageData';

export const ProjectsIndexPage = () => {
  const addAppNotification = useAppNotify();
  const { checkActivePermission, activeNamespace: tenantName } = useAuthInfo();
  const canCreateProject = checkActivePermission(V1Method.Create, 'Project');

  const { getIsOnboardingComplete } = useOnboardingSteps();

  const navigate = useNavigate();

  const { formatMessage: fm } = useIntl();

  const { filters, clearFilters, getFilterBuilderProps, filterExpressionMap } =
    useFilterBuilder({
      include: [
        'Project',
        'PackageVersion',
        'Repository',
        'RepositoryVersion',
        'DependencyMetadata',
        'Finding',
        'Metric',
      ],
      exclude: [
        {
          // NOTE: hiding SBOM specific filters from the projects page
          kind: 'Project',
          key: 'spec.sbom.main_component_purl',
        },
      ],
    });

  // NOTE: base filter to exclude projects created from SBOM imports
  const baseProjectFilterExpression = `spec.platform_source != ${V1PlatformSource.Unspecified}`;

  // get TOTAL count of projects
  const qCountProjectsTotal = useCountProjects(
    tenantName,
    {
      staleTime: Infinity,
    },
    { filter: baseProjectFilterExpression }
  );

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, paginator.resetPagination, tenantName]
  );

  // build up project query filters
  const projectQueryFilters = useMemo(() => {
    const filterCopy = new Map(filterExpressionMap);

    if (filterCopy.has('Project')) {
      const projectFilterExpression = `${baseProjectFilterExpression} and (${filterCopy.get(
        'Project'
      )})`;
      filterCopy.set('Project', projectFilterExpression);
    } else {
      filterCopy.set('Project', baseProjectFilterExpression);
    }

    const queryFilters = Array.from(filterCopy.entries()).map(
      ([kind, filter]) => ({
        kind,
        filter,
      })
    );

    return queryFilters;
  }, [baseProjectFilterExpression, filterExpressionMap]);

  const qProjectsIndexPageData = useProjectsIndexPageData({
    namespace: tenantName,
    projectQueryFilters,
    paginator,
  });

  const {
    isLoading,
    isError,
    projects,
    nextPageToken,
    projectsWithoutReferences,
    refetch: refetchProjects,
  } = qProjectsIndexPageData;

  const newProjectPath = getProjectPath({ tenantName, uuid: 'new' });
  const handleNavClick = () => {
    navigate({ to: newProjectPath });
  };

  const [returnAlertOpen, setReturnAlertOpen] = useState(false);

  const [projectToDelete, setProjectToDelete] =
    useState<ProjectsTableRow | null>(null);

  const [bulkProjectsToDelete, setBulkProjectsToDelete] = useState<
    ProjectsTableRow[]
  >([]);

  const [selectedRows, setSelectedRows] = useState({});
  const [tagEditorOpen, setTagEditorOpen] = useState(false);

  const tableRef = useRef<any>(null);

  const qBulkProjectDelete = useDeleteManyProjects({
    onSuccess: () => {
      qCountProjectsTotal.refetch();
      qProjectsIndexPageData.refetch();

      setBulkProjectsToDelete([]);

      addAppNotification({
        id: 'project:bulk_delete:success',
        message: <FM defaultMessage="Projects Deleted" />,
        severity: 'success',
      });
    },
  });

  const qProjectDelete = useDeleteProject({
    onSuccess: () => {
      // repopulate list of projects after delete
      refetchProjects();

      setProjectToDelete(null);

      addAppNotification({
        id: 'project:delete:success',
        message: <FM defaultMessage="Project Deleted" />,
        severity: 'success',
      });
    },
  });

  const ProjectDeleteConfirmation = useConfirmationDialog<ProjectsTableRow>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,
    descriptionText: projectToDelete ? (
      <FM
        defaultMessage="The Project <code>{name}</code> and related resources will be deleted. Are you sure you want to proceed?"
        values={{
          name: projectToDelete.name,
          // styled `code` element
          // forces content to wrap
          code: (value) => (
            <Typography
              component="code"
              variant="inherit"
              sx={{ fontWeight: 'bold', lineBreak: 'anywhere' }}
            >
              {value}
            </Typography>
          ),
        }}
      />
    ) : (
      <FM defaultMessage="The Project and related resources will be deleted. Are you sure you want to proceed?" />
    ),
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qProjectDelete.mutate({ namespace: props.namespace, uuid: props.uuid });
      }
    },
    titleText: <FM defaultMessage="Delete this Project" />,
  });

  const ProjectBulkDeleteConfirmation = useConfirmationDialog<
    { namespace: string; uuid: string }[]
  >({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,
    descriptionText: bulkProjectsToDelete ? (
      <Alert severity="warning">
        <FM
          defaultMessage="This will delete {projectCount, plural, =0 {# project} one {# project} other {3 projects}} and related resources across {namespaceCount, plural, =0 {# namespaces} one {# namespace} other {# namespaces}}. Are you sure you want to proceed?"
          values={{
            namespaceCount: bulkProjectsToDelete.reduce((acc: string[], p) => {
              return acc.length === 0 || !acc.includes(p.namespace)
                ? acc.concat([p.namespace])
                : acc;
            }, []).length,
            projectCount: bulkProjectsToDelete.length,
          }}
        />
      </Alert>
    ) : (
      <Alert severity="warning">
        <FM defaultMessage="These Projects and related resources will be deleted. Are you sure you want to proceed?" />
      </Alert>
    ),
    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qBulkProjectDelete.mutate({ resources: bulkProjectsToDelete });
      }
    },
    titleText: <FM defaultMessage="Delete Multiple Projects" />,
  });

  const handleDelete = useCallback(
    (row: ProjectsTableRow) => {
      setProjectToDelete(row);
      ProjectDeleteConfirmation.openDialog(row);
    },
    [ProjectDeleteConfirmation]
  );

  const handleBulkDelete = useCallback(
    (rows: ProjectsTableRow[]) => {
      const projectDeleteResources = rows.map((row) => ({
        namespace: row.namespace,
        uuid: row.uuid,
      }));
      setBulkProjectsToDelete(rows);
      ProjectBulkDeleteConfirmation.openDialog(projectDeleteResources);
    },
    [ProjectBulkDeleteConfirmation]
  );

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  // Handle error and empty states
  const isEmptyState =
    !qCountProjectsTotal.isError &&
    !qCountProjectsTotal.isLoading &&
    qCountProjectsTotal.data?.count === 0;

  const selectedProjects = useMemo(() => {
    const uuids = new Set(Object.keys(selectedRows));
    const selectedProjectRows = projects
      .filter((p) => uuids.has(p.uuid))
      .map((p) => p.project);

    return selectedProjectRows;
  }, [selectedRows, projects]);

  const onLabelUpdateSuccess = () => {
    setTagEditorOpen(false);
    refetchProjects();
  };

  const { handleUpdateLabels, qUpdateManyProjects } =
    useUpdateMultiProjectFields(selectedProjects, onLabelUpdateSuccess);

  const { isSharedTenant } = useAuthTenantInfo(tenantName);

  useEffect(() => {
    /**
     * Clear row selection when data changes, since rows selection is
     * index based and the count and positions can change on refetch.
     */
    tableRef.current?.resetRowSelection(true);
  }, [projects]);

  useEffect(() => {
    const completed = getIsOnboardingComplete();
    if (!completed && isSharedTenant) {
      setReturnAlertOpen(true);
    }
  }, [getIsOnboardingComplete, isSharedTenant]);

  const actionList = [
    {
      actionId: 'projects-tags-edit',
      label: 'Edit tags',
      isSelectionRequired: true,
      disabled: _isEmpty(selectedRows),
      onApply: () => {
        setTagEditorOpen(true);
      },
    },
    {
      actionId: 'projects-tags-delete',
      label: 'Delete Projects',
      isSelectionRequired: true,
      disabled: _isEmpty(selectedRows),
      onApply: handleBulkDelete,
    },
  ];

  return (
    <>
      <Grid container direction="column" flexWrap="nowrap" spacing={6}>
        <Grid item>
          <PageHeader
            action={
              !isEmptyState &&
              canCreateProject && (
                <Grid alignItems="center" container justifyContent="flex-end">
                  <Grid item>
                    <ButtonPrimary
                      onClick={handleNavClick}
                      data-testid="add-project"
                    >
                      <FM defaultMessage="Add Project" />
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              )
            }
            isLoading={qCountProjectsTotal.isLoading}
            title={<FM defaultMessage="Projects" />}
          />
        </Grid>

        {/* Handle Error State */}
        {isError && (
          <Grid item>
            <EmptyState
              title={<FM defaultMessage="Failed to load Projects" />}
              description={
                <FM defaultMessage="The request to load the Projects failed to complete. Please remove filters or try again." />
              }
            >
              <Stack direction="row" spacing={4}>
                {!!filterExpressionMap.size && (
                  <ButtonPrimary onClick={clearFilters}>
                    <FM defaultMessage="Clear Filters" />
                  </ButtonPrimary>
                )}

                <ButtonPrimary onClick={() => refetchProjects()}>
                  <FM defaultMessage="Try Again" />
                </ButtonPrimary>
              </Stack>
            </EmptyState>
          </Grid>
        )}

        {/* Handle Empty State */}
        {isEmptyState && (
          <Grid item>
            <EmptyState
              size="large"
              title={
                <FM defaultMessage="You have not added any projects yet" />
              }
              description={
                <FM defaultMessage="Projects are how you monitor the health of your code, one repository at a time." />
              }
            >
              <ButtonPrimary onClick={handleNavClick} data-testid="add-project">
                <FM defaultMessage="Add Project" />
              </ButtonPrimary>
            </EmptyState>
          </Grid>
        )}

        {!isEmptyState && !isError && (
          <Grid item id="project-container">
            <ProjectsTableView
              bulkActions={actionList}
              clearFilters={clearFilters}
              data={projects}
              filters={filters}
              getFilterBuilderProps={getFilterBuilderProps}
              handleRowSelection={handleRowSelection}
              isLoading={isLoading}
              namespace={tenantName}
              onDelete={handleDelete}
              paginator={paginator}
              projectsWithoutReferences={projectsWithoutReferences}
              totalCount={qCountProjectsTotal.data?.count ?? 0}
            />
          </Grid>
        )}
      </Grid>

      <StartDemoFromProjects
        isOpen={returnAlertOpen}
        closeCallback={() => setReturnAlertOpen(false)}
      />

      <ProjectBulkDeleteConfirmation.Dialog
        {...ProjectBulkDeleteConfirmation.dialogProps}
      />

      <ProjectDeleteConfirmation.Dialog
        {...ProjectDeleteConfirmation.dialogProps}
      />

      <TagEditorDialog
        open={tagEditorOpen}
        onClose={() => {
          setTagEditorOpen(false);
        }}
        isLoading={qUpdateManyProjects.isLoading}
        resources={selectedProjects}
        onSubmit={handleUpdateLabels}
        placeholderText={fm(
          {
            defaultMessage:
              'What tags do you want to assign {selectedCount, plural, one {this Project} other {these Projects}}?',
          },
          {
            selectedCount: Object.keys(selectedRows).length,
          }
        )}
        titleText={
          <FM
            defaultMessage="Edit Tags: {selectedCount} {selectedCount, plural, one {Project} other {Projects}}"
            values={{ selectedCount: selectedProjects?.length ?? 0 }}
          />
        }
      />
    </>
  );
};
