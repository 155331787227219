import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { IconHelp } from '../../themes';

interface DataTableColumnHeaderProps {
  label: ReactNode;
  helpTooltip: ReactNode;
}

export const DataTableColumnHeader = ({
  label,
  helpTooltip,
}: DataTableColumnHeaderProps) => {
  const { palette } = useTheme();

  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Typography variant="inherit">{label}</Typography>

      {helpTooltip && (
        <Tooltip title={helpTooltip}>
          <IconHelp
            htmlColor={palette.text.secondary}
            sx={{ '&:hover': { cursor: 'pointer' } }}
          ></IconHelp>
        </Tooltip>
      )}
    </Stack>
  );
};
