import {
  ContextContextType as ResourceContextType,
  V1LicenseInfo,
} from '@endorlabs/api_client';
import {
  QueryPackageVersionsResponseObject,
  selectFindingCountsByLevelFromGroupResponse,
  selectMetricScores,
} from '@endorlabs/queries';
import { UIPackageVersionUtils } from '@endorlabs/ui-common';

import { getPackageVersionPath } from '../../../routes';

export const getPackageVersionDetails = (
  object?: QueryPackageVersionsResponseObject
) => {
  const metricScores = selectMetricScores(
    object?.meta.references.Metric?.list?.objects[0]
  );

  const { label: packageName = '', version: versionRef = '' } = object
    ? UIPackageVersionUtils.parsePackageName(object.meta.name)
    : {};

  // TODO: support linking to Package Verison with Context
  const isDefaultContext =
    object &&
    (object.context.type === ResourceContextType.Main ||
      object.context.type === ResourceContextType.Ref);

  const packageLink = object
    ? isDefaultContext
      ? getPackageVersionPath({
          tenantName: object.tenant_meta.namespace,
          uuid: object.uuid,
        })
      : null
    : null;

  const findingCountsByLevel = selectFindingCountsByLevelFromGroupResponse(
    object?.meta.references.FindingsGroupByLevel?.group_response
  );

  const resolutionTimestamp =
    object?.spec?.resolved_dependencies?.resolution_timestamp;

  // NOTE: dependency counts are taken from DependencyMetadata
  // these counts are eventually consistent, and may not be accurate
  const dependencyCount =
    object?.meta.references.DependencyPackagesCount?.count_response.count ?? 0;

  return {
    packageName,
    packageFullName: object?.meta.name,
    versionRef,
    packageLink,
    resolutionTimestamp,
    findingsCriticalCount: findingCountsByLevel.FINDING_LEVEL_CRITICAL,
    findingsHighCount: findingCountsByLevel.FINDING_LEVEL_HIGH,
    findingsMediumCount: findingCountsByLevel.FINDING_LEVEL_MEDIUM,
    findingsLowCount: findingCountsByLevel.FINDING_LEVEL_LOW,
    dependencyCount,
    ...metricScores,
  };
};

export const genLicenseList = (licenses: V1LicenseInfo[]): string[] => {
  const list: string[] = [];
  licenses.map((l) => {
    if (l.name !== '') list.push(l.name);
  });
  return list;
};
