import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { V1PlatformSource } from '@endorlabs/api_client';
import { BetaBadge, ButtonExternalLink } from '@endorlabs/ui-common';

import { NamedRoutes } from '../../../routes';
import { NewProjectAzureApp } from './NewProjectAzureApp';
import { NewProjectEndorCtlCli } from './NewProjectEndorCtlCli';
import { InstallMethod } from './types';

interface NewProjectAzureProps {
  namespace: string;
}

export const NewProjectAzure = ({ namespace }: NewProjectAzureProps) => {
  const [activeMethod, setActiveMethod] = useState<InstallMethod>(false);
  const { formatMessage: fm } = useIntl();
  const sx = styles(useTheme());

  const handleMethodChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setActiveMethod(evt.target.value as InstallMethod);
    },
    []
  );

  return (
    <Card>
      <CardHeader
        title={
          <FM defaultMessage="How do you want to scan your repositories?" />
        }
      />
      <CardContent>
        <RadioGroup
          aria-label={fm({
            defaultMessage:
              'Choose manual or automatic scanning of repositories',
          })}
          name="install-method"
          onChange={handleMethodChange}
          value={activeMethod}
        >
          <FormControlLabel
            control={<Radio />}
            value="CLI"
            label={fm({
              defaultMessage: 'CLI',
            })}
          />
          {activeMethod === 'CLI' && (
            <Box sx={sx.contentSection}>
              <NewProjectEndorCtlCli
                tenantName={namespace}
                platformSource={V1PlatformSource.Azure}
              />
            </Box>
          )}
          <FormControlLabel
            control={<Radio />}
            value="ACTION"
            label={
              <Stack direction="row" alignItems="center" spacing={2}>
                {fm({ defaultMessage: 'Azure Pipelines' })}
                <BetaBadge text={<FM defaultMessage="New" />} color="warning" />
              </Stack>
            }
          />
          {activeMethod === 'ACTION' && (
            <Box sx={sx.contentSection}>
              <ButtonExternalLink
                linkProps={{ to: NamedRoutes.DOCS_AZURE_PIPELINE }}
              >
                <FM defaultMessage="View Documentation" />
              </ButtonExternalLink>
            </Box>
          )}
          <FormControlLabel
            control={<Radio />}
            value="APP"
            label={
              <Stack direction="row" alignItems="center" spacing={2}>
                {fm({ defaultMessage: 'Azure DevOps App' })}
                <BetaBadge text={<FM defaultMessage="New" />} color="warning" />
              </Stack>
            }
          />
          {activeMethod === 'APP' && <NewProjectAzureApp />}
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

const styles = ({ palette }: Theme) => ({
  contentSection: {
    borderLeft: `1px solid ${palette.divider}`,
    paddingX: 6,
    paddingBottom: 4,
    paddingTop: 4,
  },
});
