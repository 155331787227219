/**
 * Remediations related filter field configs for use with FilterBar
 */
import { addHours, addMinutes, addSeconds, isValid } from 'date-fns';
import { FormattedMessage as FM } from 'react-intl';

import { FILTER_COMPARATORS, isValueFilter } from '@endorlabs/filters';
import { MultiSelectInput } from '@endorlabs/ui-common';

import { ControlsFacetFilterDatePicker } from '../../../components/FacetedSearchBar/ControlsFacetFilterDatePicker';
import { FilterFieldConfig, filterFieldTransformBuilders } from '../../filters';
import { RemediationRiskMessages } from '../locales';
import { RemediationRisk } from '../types';

const RemediationRiskFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.upgrade_info.upgrade_risk',
  ...filterFieldTransformBuilders.fromFilter({
    key: 'spec.upgrade_info.upgrade_risk',
    comparator: FILTER_COMPARATORS.IN,
  }),
  renderInput: ({ onChange, value }) => (
    <MultiSelectInput
      label={<FM defaultMessage="Remediation Risk" />}
      onChange={onChange}
      options={Object.values(RemediationRisk).map((value) => ({
        value,
        // eslint-disable-next-line react/jsx-no-undef
        label: <FM {...RemediationRiskMessages[value]} />,
      }))}
      value={value}
    />
  ),
} satisfies FilterFieldConfig<RemediationRisk[]>;

const VersionAgeFilterFieldConfig: FilterFieldConfig<any> = {
  id: 'spec.upgrade_info.from_version_publish_time',
  format: (value) => {
    if (value && 'string' === typeof value) {
      const date = new Date(value);
      if (isValid(date)) {
        return {
          key: 'spec.upgrade_info.from_version_publish_time',
          comparator: FILTER_COMPARATORS.GREATER_OR_EQUAL,
          value: `date(${date.toISOString()})`,
        };
      }
    }
  },
  parse: (filter) => {
    if (isValueFilter(filter) && 'string' === typeof filter.value) {
      // HACK: ensure date value is properly escaped for the filter, and parsed for the date picker component
      let dateValue = filter.value;
      if (dateValue.startsWith('date(')) {
        dateValue = dateValue.replace(/date\((.*)\)/, '$1');
      }

      // handle: parse date offset
      if (dateValue.startsWith('now(')) {
        const parsed = dateValue.match(/now\("?(.*)(\w)"?\)/);
        if (parsed) {
          const offset = Number(parsed[1]);
          const increment = parsed[2];

          if (!isNaN(offset)) {
            switch (increment) {
              case 's':
                dateValue = addSeconds(Date.now(), offset).toISOString();
                break;
              case 'm':
                dateValue = addMinutes(Date.now(), offset).toISOString();
                break;

              case 'h':
                dateValue = addHours(Date.now(), offset).toISOString();
                break;
            }
          }
        }
      }

      if (isValid(new Date(dateValue))) {
        return dateValue;
      }
    }
  },
  renderInput: ({ onChange, value }) => (
    <ControlsFacetFilterDatePicker
      onChange={onChange}
      value={value as any}
      facet={
        {
          label: <FM defaultMessage="When was the version released" />,
        } as any
      }
    />
  ),
} satisfies FilterFieldConfig<string>;

// Remediations page filter fields
export const REMEDIATIONS_FILTER_FIELDS: FilterFieldConfig<any>[] = [
  RemediationRiskFilterFieldConfig,
  VersionAgeFilterFieldConfig,
];

/**
 * Default search keys for use with Remediations filters
 */
export const REMEDIATIONS_SEARCH_KEYS = [
  'spec.upgrade_info.direct_dependency_package',
  'spec.upgrade_info.from_version',
  'spec.upgrade_info.root_package_version',
  'spec.upgrade_info.project',
];
