import { NamedRoutes } from '../constants';
import { shouldAllowRedirect } from './shouldAllowRedirect';

/**
 * Get the current relative URL
 *
 * Equivalent to location.href with the location.origin removed.
 */
const getCurrentURL = ({
  pathname,
  search,
}: Pick<Location, 'pathname' | 'search'> = location) => {
  let returnTo = pathname;

  // if search params are present, and non-empty,
  // add those to the return url
  if (search.length > 1) {
    returnTo += search;
  }

  return returnTo;
};

/**
 * Get the Login URL, with a `return_url` set for redirect after auth
 */
export const getLoginPath = (currentLocation = window.location) => {
  if (!shouldAllowRedirect(currentLocation)) {
    return NamedRoutes.LOGIN;
  }

  const currentUrl = getCurrentURL(currentLocation);
  const params = new URLSearchParams({
    return_to: currentUrl,
  });

  return `${NamedRoutes.LOGIN}?${params}`;
};
