import { Box, Stack, Typography } from '@mui/material';
import { isNil as _isNil, sortBy as _sortBy } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { useGetPackageVersion } from '@endorlabs/queries';
import {
  AttributeDisplay,
  DrawerAccordion,
  FindingCountArrayDisplay,
  getPaginatorPageSlice,
  PackageVersionNameDisplay,
  RowStack,
  SimplePagination,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  DetailDrawerDivider,
  DetailDrawerSection,
} from '../../../../components/DetailDrawer';
import { ContainerDependencyData, useContainerLayerData } from '../../hooks';
import { ContainerDetailDrawerProps } from './types';

export const ContainerDetailDrawerDependenciesSection = ({
  containerNamespace: namespace,
  containerUuid: uuid,
  layerIndex,
  imageName,
}: ContainerDetailDrawerProps) => {
  // Load container data
  const qGetPackageVersion = useGetPackageVersion({ namespace, uuid });
  const containerPackageVersion = qGetPackageVersion.data;

  const { data } = useContainerLayerData({
    packageVersion: containerPackageVersion,
  });

  // Select the dependencies from the target image or layer
  const dependencies = useMemo(() => {
    let dependencies: ContainerDependencyData[] = [];

    if (!_isNil(imageName)) {
      const containerImage = data.images.find((i) => i.name === imageName);
      dependencies = containerImage?.dependencies ?? [];
    }

    if (!_isNil(layerIndex)) {
      const containerLayer = data.layers.find(
        (l) => l.layerIndex === layerIndex
      );

      dependencies = containerLayer?.dependencies ?? [];
    }

    return dependencies;
  }, [data.images, data.layers, imageName, layerIndex]);

  const totalCount = dependencies.length;
  const paginator = useDataTablePaginator({
    totalCount: totalCount,
    pageSize: 10,
  });

  const layerDependencies = useMemo(() => {
    const sorted = _sortBy(dependencies ?? [], 'name');
    return getPaginatorPageSlice(paginator.state, sorted);
  }, [dependencies, paginator.state]);

  const showPagination = layerDependencies.length > 0;

  return (
    <DetailDrawerSection id="dependencies">
      <RowStack marginBottom={4} justifyContent="space-between">
        <Typography component="span" color="text.secondary" variant="body2">
          <FM
            defaultMessage="{totalCount, number, ::compact-short} {totalCount, plural, one {dependency} other {dependencies}}"
            values={{
              totalCount,
            }}
          />
        </Typography>

        {showPagination && <SimplePagination paginator={paginator} />}
      </RowStack>

      <Stack divider={<DetailDrawerDivider fullWidth />}>
        {layerDependencies.map((dep, index) => (
          <LayerDependencyAccordion
            key={index}
            id={`LayerDependency-${index}`}
            dependency={dep}
          />
        ))}
      </Stack>
    </DetailDrawerSection>
  );
};

const LayerDependencyAccordion = ({
  dependency,
  id,
}: {
  dependency: ContainerDependencyData;
  id: string;
}) => {
  return (
    <DrawerAccordion
      expanded={false}
      id={id}
      titleNode={
        <RowStack
          flexGrow={1}
          flexWrap="nowrap"
          justifyContent="space-between"
          key={dependency.name}
        >
          {/* Force Package Name to shrink */}
          <Box flexShrink={1}>
            <PackageVersionNameDisplay
              packageVersion={{
                meta: {
                  name: dependency.name,
                },
              }}
              showVersion
            />
          </Box>

          <FindingCountArrayDisplay
            hideEmptyValues
            value={dependency.findingCounts}
          />
        </RowStack>
      }
    >
      <AttributeDisplay
        heading={<FM defaultMessage="Locations" />}
        value={
          dependency.containerFileLocations?.length ? (
            <Stack direction="column">
              {dependency.containerFileLocations.map((value, index) => (
                <Typography
                  component="span"
                  key={index}
                  sx={{ wordBreak: 'break-all' }}
                >
                  {value}
                </Typography>
              ))}
            </Stack>
          ) : undefined
        }
      />
    </DrawerAccordion>
  );
};
