import { EndorThemeConstants } from '@endorlabs/ui-common';

/**
 * NOTE: Dimensions here are spacing units (e.g. theme.spacing = 4)
 */
export const Layout = {
  APP_BAR_HEIGHT: 12,
  APP_DRAWER_WIDTH: 50,
  APP_DRAWER_WIDTH_MIN: 17,
  APP_DRAWER_LOGO_CONTAINER_HEIGHT: 8,
  APP_DRAWER_HEADER_HEIGHT: 27,
  CARD_PADDING: EndorThemeConstants.CARD_PADDING,
  INFO_DRAWER_WIDTH: 115,

  // screen width (px) breakpoint which causes app drawer minimization
  APP_DRAWER_MINIMIZATION_WIDTH: 1366,

  // screen width (px) breakpoint which causes info drawer takeover
  INFO_DRAWER_MAXIMIZED_WIDTH: 1024,

  INFO_DRAWER_MAXIMIZED_PADDING: 15,
};
