export const buildNextDataTableColumnOrder = (
  columnOrder: string[],
  nextColumnOrder: string[]
) => {
  const columnIds = new Set();
  const mergedOrder: string[] = [];

  // add given columns to order
  for (const id of nextColumnOrder) {
    mergedOrder.push(id);
    columnIds.add(id);
  }

  // check for missing columns in the new order
  if (
    columnIds.size === columnOrder.length &&
    columnOrder.every((id) => columnIds.has(id))
  ) {
    return mergedOrder;
  }

  // insert the missing columns
  const missingColumns = columnOrder
    .map((id, index) => ({ id, index }))
    .filter((col) => !columnIds.has(col.id));

  let insertOffset = 1;
  missingColumns.forEach((col) => {
    const insertIx = col.index + insertOffset;
    mergedOrder.splice(insertIx, 0, col.id);
    insertOffset++;
  });

  return mergedOrder;
};
