import { Theme } from '@mui/material';

export const getTableOverrides = ({ palette, spacing, typography }: Theme) => {
  return {
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            '& .MuiIconButton-root': {
              color: palette.primary.main,
            },
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography.body1,
          padding: spacing(3, 2.5),

          '&:first-of-type': {
            paddingLeft: spacing(4),
          },

          '&:last-of-type': {
            paddingRight: spacing(4),
          },
        },

        head: {
          ...typography.button,
          backgroundColor: palette.background.paper,
          fontSize: typography.body2.fontSize,
          lineHeight: 1.25,

          '& .ScoreCategoryIcon-root': {
            fontSize: typography.pxToRem(20),
          },
        },
      },
    },
  };
};
