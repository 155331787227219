import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  RepositoryServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1Repository,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  RepositoryResource,
  RepositoryResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export type CountRepositoriesOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type ListRepositoryOptions = ResourceQueryOptions<RepositoryResourceList>;
type GetRepositoryOptions = ResourceQueryOptions<RepositoryResource>;
type UpsertRepositoryOptions = ResourceMutateOptions<
  V1Repository,
  RepositoryWriteParams
>;
export interface RepositoryWriteParams {
  namespace: string;
  resource: V1Repository;
}

interface RepositoryUpdateParams extends RepositoryWriteParams {
  mask?: string;
}

const BASE_KEY = 'v1/repositories';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const RepositoriesQueryKeys = QK;

export const REPOSITORY_UPDATE_MASK = 'meta,spec';

const getApiService = () => new RepositoryServiceApi(getClientConfiguration());

const countRepositories = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.repositoryServiceListRepositories(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountRepositories = (
  namespace: string,
  opts: CountRepositoriesOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Repository',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countRepositories(namespace, requestParameters),
    opts
  );
};

const listRepositories = async (
  namespace: string,

  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.repositoryServiceListRepositories(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as RepositoryResourceList;
};

export const useListRepositories = (
  namespace: string,
  opts: ListRepositoryOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Repository',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listRepositories(namespace, requestParameters),
    opts
  );
};

export const getRepository = async (
  namespace: string,
  repositoryUuid: string
) => {
  const api = getApiService();
  const resp = await api.repositoryServiceGetRepository(
    namespace,
    repositoryUuid
  );
  return resp.data as RepositoryResource;
};

export const useGetRepository = (
  namespace: string,
  repositoryUuid: string,
  opts: GetRepositoryOptions = {}
) => {
  return useQuery(
    QK.record(namespace, repositoryUuid),
    () => getRepository(namespace, repositoryUuid),
    opts
  );
};

const updateRepository = async (params: RepositoryUpdateParams) => {
  const { resource, namespace, mask = REPOSITORY_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.repositoryServiceUpdateRepository(namespace, req);
  return resp.data as RepositoryResource;
};

export const useUpdateRepository = (opts: UpsertRepositoryOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Repository'),
    mutationFn: (params: RepositoryUpdateParams) => updateRepository(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
