import { isEqual as _isEqual } from 'lodash-es';
import { QueryClient, QueryKey } from 'react-query';

import { isChildNamespace } from '@endorlabs/endor-core/Namespace';

type InvalidateListQueriesOptions = {
  /**
   * When the `includePropagated` flag is passed, invalidate any queries
   * that may include data propagated from a parent namespace.
   *
   * Example: A user edits a Finding Policy when viewing the list of
   * policies from a child namespace.
   * - Query Key: `endor.dev.domain`. This would match the active namespace
   *   where the list call was made.
   * - Target Namespace: `endor.dev`. This would be the namespace of the
   *   propagated resource.
   */
  includePropagated?: boolean;
  /**
   * When the `includeChildren` flag is passed, invalidate any queries that
   * may include data exposed from child namespaces.
   *
   * Example: A user edits a Project when viewing the list of projects for
   * the active namespace, with the `traverse` list parameter set.
   * - Query Key: `endor.dev`. This would match the active namespace
   *   where the list call was made.
   * - Target Namespace: `endor.dev.domain`. This would be the namespace of
   *   the child resource included in the query result.
   */
  includeChildren?: boolean;
};

export const invalidateListQueries = (
  queryClient: QueryClient,
  target: QueryKey,
  options?: InvalidateListQueriesOptions
) => {
  queryClient.invalidateQueries({
    predicate: ({ queryKey }) => {
      if (!Array.isArray(queryKey) || !Array.isArray(target)) return false;

      // NOTE: assumption made about shape of "list: query keys.
      const base = queryKey.slice(0, 2);
      if (!_isEqual(base, target.slice(0, 2))) return false;

      const qkNamespace = queryKey[2];
      const targetNamespace = target[2];

      if (qkNamespace === targetNamespace) return true;

      if (
        options?.includePropagated &&
        isChildNamespace(qkNamespace, targetNamespace)
      ) {
        return true;
      }

      if (
        options?.includeChildren &&
        isChildNamespace(targetNamespace, qkNamespace)
      ) {
        return true;
      }

      return false;
    },
  });
};
