import { Box, Theme, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';

import { V1PlatformSource } from '@endorlabs/api_client';

import { FormUpsertInstallation } from '../../../domains/Installations/components/FormUpsertInstallation';
import { useAuthInfo } from '../../../providers';
import { getDashboardPath } from '../../../routes';

export const NewProjectAzureApp = () => {
  const sx = styles(useTheme());

  const { activeNamespace } = useAuthInfo();
  const navigate = useNavigate();

  const handleSuccess = () => {
    setTimeout(
      () => navigate({ to: getDashboardPath({ tenantName: activeNamespace }) }),
      1500
    );
  };

  return (
    <Box sx={sx.contentSection} width={`50%`}>
      <FormUpsertInstallation
        installationPlatformType={V1PlatformSource.Azure}
        namespace={activeNamespace}
        onSuccess={handleSuccess}
      />
    </Box>
  );
};

const styles = ({ palette }: Theme) => ({
  contentSection: {
    borderLeft: `1px solid ${palette.divider}`,
    marginLeft: 2,
    paddingX: 6,
    paddingBottom: 4,
  },
});
