import { QueryKey, useQuery } from 'react-query';

import {
  ScanResultServiceApi,
  V1CountResponse,
  V1ListParameters,
  V1ScanResult,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';
import { ScanResultResource } from '@endorlabs/endor-core/ScanResult';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceListResponse,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  getClientConfiguration,
} from './utils';

interface ScanResultReadParams {
  namespace: string;
  uuid: string;
}

interface ScanResultWriteParams {
  namespace: string;
  resource: V1ScanResult;
}

interface ScanResultUpdateParams extends ScanResultWriteParams {
  mask?: string;
}

type CountScanResultsOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListScanResultsOptions = ResourceQueryOptions<
  ResourceListResponse<ScanResultResource>
>;
type GetScanResultOptions = ResourceQueryOptions<ScanResultResource>;
type UpsertScanResultOptions = ResourceMutateOptions<
  V1ScanResult,
  ScanResultWriteParams
>;
type DeleteScanResultOptions = ResourceMutateOptions<
  object,
  ScanResultReadParams
>;

const BASE_KEY = 'v1/scan-results';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const ScanResultQueryKeys = QK;

export const SCAN_RESULT_UPDATE_MASK = 'meta,spec';

const getApiService = () => new ScanResultServiceApi(getClientConfiguration());

const countScanResults = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.scanResultServiceListScanResults(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountScanResults = (
  namespace: string,
  opts: CountScanResultsOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ScanResult',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countScanResults(namespace, requestParameters),
    opts
  );
};

const listScanResults = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.scanResultServiceListScanResults(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data.list as ResourceListResponse<ScanResultResource>;
};

export const useListScanResults = (
  namespace: string,
  opts: ListScanResultsOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ScanResult',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listScanResults(namespace, requestParameters),
    opts
  );
};

const getScanResult = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.scanResultServiceGetScanResult(namespace, uuid);
  return resp.data as ScanResultResource;
};

export const useGetScanResult = (
  params: ScanResultReadParams,
  opts: GetScanResultOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getScanResult(params.namespace, params.uuid),
    opts
  );
};
