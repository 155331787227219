import {
  Stack,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { MouseEvent, ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  IconCheckCircle,
  IconInfo,
  Link,
  ThemeFontStacks,
} from '@endorlabs/ui-common';

export const privacyPolicyLink = (
  <Link
    target="_blank"
    data-testid="privacy-policy"
    to="https://www.endorlabs.com/privacy-policy"
  >
    Privacy Policy
  </Link>
);

export const termsOfUseLink = (
  <Link
    target="_blank"
    data-testid="terms-of-use"
    to="https://www.endorlabs.com/terms-of-use"
  >
    Terms of Use
  </Link>
);

export const NoLongerValidEmail = () => {
  const theme = useTheme();
  const sx = styles(theme);
  return (
    <>
      <Typography sx={sx.heading}>
        <FM defaultMessage="Oops, please enter your email again!" />
      </Typography>
      <Stack direction="row" sx={sx.errorText} spacing={2}>
        <IconInfo sx={{ color: theme.palette.severity.high }} />
        <Typography variant="body1">
          <FM defaultMessage="It looks like the special link we’ve emailed you is no longer valid. Please request another one by entering your work email address." />
        </Typography>
      </Stack>
    </>
  );
};

export const NotValidBusinessEmail = () => {
  const theme = useTheme();
  const sx = styles(theme);
  return (
    <>
      <Typography sx={sx.heading}>
        <FM defaultMessage="Oops, please enter your email again!" />
      </Typography>
      <Stack direction="row" sx={sx.errorText} spacing={2}>
        <IconInfo sx={{ color: theme.palette.severity.high }} />
        <Typography variant="body1">
          <FM defaultMessage="It looks like the email address provided is not a business email. Please try again by entering your work email address." />
        </Typography>
      </Stack>
    </>
  );
};

export const InvalidBrowserWindowEmail = () => {
  const theme = useTheme();
  const sx = styles(theme);
  return (
    <>
      <Typography sx={sx.heading}>
        <FM defaultMessage="Oops, please try again!" />
      </Typography>
      <Stack direction="row" sx={sx.errorText} spacing={2}>
        <IconInfo sx={{ color: theme.palette.severity.high }} />
        <Typography variant="body1">
          <FM
            defaultMessage="It looks like the special link we’ve emailed you is not working as expected. Please try opening the link in the <b>same browser window</b> or request a new link."
            values={{
              b: (str) => <b>{str}</b>,
            }}
          />
        </Typography>
      </Stack>
    </>
  );
};

export const SuccessEmail = ({
  onReturnLinkClick,
  returnTo,
}: {
  /**
   * Click listener attached to the return link, allows preventing navigation behavior
   */
  onReturnLinkClick?: (event: MouseEvent) => void;
  /**
   * Path for the return link.
   */
  returnTo: string;
}) => {
  const theme = useTheme();
  const sx = styles(theme);

  return (
    <Stack spacing={6} alignItems="center" sx={sx.textWrapper}>
      <MarketingHeading text={<FM defaultMessage="Check your email!" />} />

      <Stack sx={sx.success} direction="row">
        <IconCheckCircle sx={{ mr: 2, color: theme.palette.brand.main }} />
        <Typography variant="body1">
          <FM defaultMessage="We've emailed a special link to your email. You must open the link in the same browser window as this page." />
        </Typography>
      </Stack>

      <Typography>
        <FM
          defaultMessage="Incorrect email? Please <returnLink>re-enter your email address.</returnLink>"
          values={{
            returnLink: (text) => (
              <Link onClick={onReturnLinkClick} to={returnTo}>
                {text}
              </Link>
            ),
          }}
        />
      </Typography>
      <Typography
        color="text.secondary"
        sx={{ textAlign: 'center' }}
        variant="body2"
      >
        <FM
          defaultMessage="For your security, the link expires in 15 minutes.<br></br>If your link has expired, you can <returnLink>request another.</returnLink>"
          values={{
            br: () => <br />,
            returnLink: (text) => (
              <Link onClick={onReturnLinkClick} to={returnTo}>
                {text}
              </Link>
            ),
          }}
        />
      </Typography>
    </Stack>
  );
};

export const MarketingHeading = ({
  sx,
  text,
  ...typographyProps
}: TypographyProps & {
  text: ReactNode;
}) => {
  const defaultStyles = styles(useTheme());

  return (
    <Typography
      // @ts-expect-error - sx widening
      sx={{ ...defaultStyles.heading, ...sx }}
      variant="h1"
      {...typographyProps}
    >
      {text}
    </Typography>
  );
};

function styles({ space, palette, breakpoints }: Theme) {
  return {
    heading: {
      fontFamily: ThemeFontStacks.SWITZER,
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.1,
      textAlign: 'center',
    },
    errorText: {
      background: 'rgba(234, 186, 18, 0.25)',
      borderRadius: '4px',
      padding: 3,
      alignItems: 'center',
    },
    textWrapper: {
      height: '100%',
      background: palette.background.paper,
      padding: '36px',
      width: '31vw',
      [breakpoints.down('md')]: {
        marginTop: '1rem',
        width: '100vw',
      },
    },

    success: {
      borderRadius: '4px',
      background: 'rgba(150, 199, 12, 0.25)',
      padding: 3,
      color: palette.brand.main,
    },
  };
}
