import { castArray as _castArray } from 'lodash-es';

import {
  FilterComparator,
  FilterComparatorMultipleValue,
  FilterComparatorSingleValue,
  FilterComparatorWithoutValue,
} from './comparator';
import { filterExpressionBuilders } from './expressionBuilders';
import {
  ResourceFilter,
  ResourceFilterMultipleValue,
  ResourceFilterSingleValue,
  ResourceFilterWithoutValue,
  ValueFilter,
} from './types';
import { escapeValue, groupBy } from './utils';

/**
 * The serialized format for a constructed expression (currently stored as string)
 */
export type FilterExpression = string;

/**
 * An expression builder takes a filter, and returns the expression string value
 */
export type FilterExpressionBuilder<
  T extends ResourceFilter,
  KInclude extends keyof ValueFilter = 'key' | 'value'
> = (filter: Pick<T, KInclude>) => FilterExpression;

const singleValueExpressionBuilders: Record<
  FilterComparatorSingleValue,
  FilterExpressionBuilder<ResourceFilterSingleValue>
> = {
  EQUAL: ({ key, value }) => `${key}==${escapeValue(value)}`,
  NOT_EQUAL: ({ key, value }) => `${key}!=${escapeValue(value)}`,
  MATCHES: ({ key, value }) => `${key} matches ${escapeValue(value)}`,
  GREATER: ({ key, value }) => `${key} > ${escapeValue(value)}`,
  GREATER_OR_EQUAL: ({ key, value }) => `${key} >= ${escapeValue(value)}`,
  LESSER: ({ key, value }) => `${key} < ${escapeValue(value)}`,
  LESSER_OR_EQUAL: ({ key, value }) => `${key} <= ${escapeValue(value)}`,
};

const multipleValueExpressionBuilders: Record<
  FilterComparatorMultipleValue,
  FilterExpressionBuilder<ResourceFilterMultipleValue>
> = {
  IN: ({ key, value }) =>
    `${key} in [${value.map((v) => escapeValue(v)).join(',')}]`,
  NOT_IN: ({ key, value }) =>
    `${key} not in [${value.map((v) => escapeValue(v)).join(',')}]`,
  CONTAINS: ({ key, value }) =>
    `${key} contains [${value.map((v) => escapeValue(v)).join(',')}]`,
  NOT_CONTAINS: ({ key, value }) =>
    `${key} not contains [${value.map((v) => escapeValue(v)).join(',')}]`,
  CONTAINS_ALL: ({ key, value }) =>
    filterExpressionBuilders.and(
      value.map((v) =>
        multipleValueExpressionBuilders.CONTAINS({ key, value: [v] })
      )
    ),
  WITHIN_RANGE: ({ key, value }) => {
    const [min, max] = _castArray(value) as number[];

    return filterExpressionBuilders.and([
      singleValueExpressionBuilders.GREATER({ key, value: min }),
      singleValueExpressionBuilders.LESSER_OR_EQUAL({ key, value: max }),
    ]);
  },
};

/**
 * Expressions for comparators that don't use a value
 */
const withoutValueExpressionBuilders: Record<
  FilterComparatorWithoutValue,
  FilterExpressionBuilder<ResourceFilterWithoutValue, 'key'>
> = {
  EXISTS: ({ key }) => `${key} exists`,
  NOT_EXISTS: ({ key }) => `${key} not exists`,
};

const allExpressionBuilders: Record<
  FilterComparator,
  // Merge types for the expression builders
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterExpressionBuilder<any>
> = {
  ...singleValueExpressionBuilders,
  ...multipleValueExpressionBuilders,
  ...withoutValueExpressionBuilders,
};

/**
 * Gets the expression builder for the given comparator
 */
export const getExpressionBuilder = (
  comparator: FilterComparator
): FilterExpressionBuilder<ResourceFilter, 'comparator' | 'key' | 'value'> => {
  return allExpressionBuilders[comparator];
};

export type FilterExpressionResult = {
  expression: FilterExpression;
  keys: string[];
};

/**
 * Builds the expression string from the given filters
 *
 * - groups filters for the same key with logical OR
 * - groups resulting filters with logical AND
 */
export const buildSimpleFilterExpression = (
  filters: ResourceFilter[]
): FilterExpressionResult => {
  // track the keys used for the filter expression
  const keys = new Set<string>();

  const grouped = groupBy(filters, (filter) => `${filter.kind}|${filter.key}`);

  const expressions = Object.values(grouped).map((relatedFilters) => {
    const relatedExpressions = relatedFilters.map((filter) => {
      // track the values used
      keys.add(filter.key);

      // get the expression builder
      const builder = getExpressionBuilder(filter.comparator);

      return builder(filter);
    });

    return relatedExpressions.length > 1
      ? filterExpressionBuilders.or(relatedExpressions)
      : relatedExpressions[0];
  });

  const expression =
    expressions.length > 1
      ? filterExpressionBuilders.and(expressions)
      : expressions[0];

  return { expression, keys: Array.from(keys) };
};
