import { minutesToMilliseconds } from 'date-fns';

export const METADATA_STALE_TIME = minutesToMilliseconds(15);

export const PR_RUNS_SEARCH_KEYS = [
  'context.id',
  'context.tags',
  'spec.version.sha',
  'spec.version.ref',
];
