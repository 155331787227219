import { Box, Typography } from '@mui/material';
import { Table } from '@tanstack/react-table';
import { ForwardedRef, forwardRef } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { FindingCount } from '@endorlabs/queries';
import {
  DataTable,
  DataTableColumnDef,
  DataTableColumnTypeKeys as ColTypes,
  DataTableDrawerButton,
  DataTableProps,
  FindingCountArrayDisplay,
} from '@endorlabs/ui-common';

export type ContainerLayersTableRow = {
  command?: string;
  digest: string;
  findingCounts?: FindingCount[];
  layerIndex: number;
};

export interface ContainerLayersTableProps
  extends Omit<
    DataTableProps<ContainerLayersTableRow>,
    'columns' | 'getRowId' | 'onRowClick' | 'tableId'
  > {
  onClickDetail: (row: ContainerLayersTableRow) => void;
}

const buildContainerLayersTableColumns = ({
  onClickDetail,
}: Pick<
  ContainerLayersTableProps,
  'onClickDetail'
>): DataTableColumnDef<ContainerLayersTableRow>[] => [
  {
    accessorKey: 'layerIndex',
    header: () => <FM defaultMessage="#" />,
    size: 24,
  },
  {
    accessorKey: 'command',
    cell: (t) => (
      <Typography
        maxWidth="72ch"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {t.getValue()}
      </Typography>
    ),
    header: () => <FM defaultMessage="Layer" />,
  },
  {
    accessorKey: 'findingCounts',
    colType: ColTypes.FINDING_COUNTS,
    cell: (t) => {
      return (
        <FindingCountArrayDisplay
          hideEmptyValues
          value={t.getValue() as FindingCount[]}
        />
      );
    },
  },
  {
    id: 'actions',
    cell: ({ row }) =>
      row.original && (
        <Box display="flex" justifyContent="end">
          <DataTableDrawerButton
            onClick={(event) => {
              event.stopPropagation();
              onClickDetail(row.original);
              if (!row.getIsSelected()) row.toggleSelected();
            }}
          />
        </Box>
      ),
    colType: ColTypes.ACTIONS,
    header: '',
  },
];

export const ContainerLayersTable = forwardRef(
  function ContainerLayersTableComponent(
    { onClickDetail, ...props }: ContainerLayersTableProps,
    ref: ForwardedRef<Table<ContainerLayersTableRow>>
  ) {
    const columns = buildContainerLayersTableColumns({ onClickDetail });

    function handleRowClick(row: ContainerLayersTableRow) {
      return onClickDetail(row);
    }

    return (
      <DataTable
        {...props}
        columns={columns}
        getRowId={(r) => r.digest}
        onRowClick={handleRowClick}
        ref={ref}
        tableId="ContainerLayersTable"
      />
    );
  }
);
