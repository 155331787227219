import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

const legendSkeleton = Array(3)
  .fill(undefined)
  .map((v, i) => <Skeleton key={i} width="100%" />);

const visSkeleton = (
  <Skeleton
    sx={{
      transform: 'none',
      height: '100%',
    }}
  />
);

const getVisWithContainer = (vis: ReactNode) => {
  return (
    <Box height="100%" width="100%">
      {vis}
    </Box>
  );
};

export type VisCardVariant = 'horizontal' | 'vertical';

interface VisCardProps {
  legendContent?: ReactNode;
  title: ReactNode;
  variant?: VisCardVariant;
  visContent?: ReactNode;
}

export const VisCard = ({
  legendContent,
  title,
  variant = 'vertical',
  visContent,
}: VisCardProps) => {
  return (
    <Card>
      <CardContent>
        {variant === 'vertical' && (
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3">{title}</Typography>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={8}>
                {getVisWithContainer(visContent ?? visSkeleton)}
              </Grid>

              <Grid item xs={4}>
                {legendContent ?? legendSkeleton}
              </Grid>
            </Grid>
          </Grid>
        )}

        {variant === 'horizontal' && (
          <Grid container spacing={4}>
            <Grid container direction="column" item spacing={2} xs={6}>
              <Grid item>
                <Typography variant="h3">{title}</Typography>
              </Grid>

              <Grid item>{legendContent ?? legendSkeleton}</Grid>
            </Grid>

            <Grid item xs={6}>
              {getVisWithContainer(visContent ?? visSkeleton)}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
