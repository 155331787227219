import { ToolPatternResource } from '@endorlabs/queries';

export const buildCiCdToolPatternLists = (tool?: ToolPatternResource) => {
  const filePatterns =
    tool?.spec.file_patterns?.reduce((acc, fp) => {
      if (!fp.line_patterns || fp.line_patterns.length === 0) {
        acc.push(...(fp.filename_patterns ?? []));
      }
      return acc;
    }, [] as string[]) ?? [];

  const fileAndLinePatterns =
    tool?.spec.file_patterns?.filter(
      (t) => t.line_patterns && t.line_patterns.length > 0
    ) ?? [];

  const allCmdPatterns =
    tool?.spec.cmd_patterns?.reduce((acc, cp) => {
      acc.push(...(cp.line_patterns ?? []));
      return acc;
    }, [] as string[]) ?? [];

  const toolCmdPatterns = Object.entries(tool?.spec.tool_patterns ?? {}).reduce(
    (acc, [tool, patterns]) => {
      acc[tool] = patterns.line_patterns ?? [];
      return acc;
    },
    {} as Record<string, string[]>
  );

  const cmdPatterns =
    allCmdPatterns.length > 0
      ? { All: allCmdPatterns, ...toolCmdPatterns }
      : toolCmdPatterns;

  const appPatterns =
    tool?.spec.app_patterns?.reduce((acc, ap) => {
      if (ap.app_slug) {
        acc['App Slug'] = acc['App Slug'] ?? [];
        acc['App Slug'].push(ap.app_slug);
      }

      return acc;
    }, {} as Record<string, string[]>) ?? [];

  const githubBadgePatterns = tool?.spec.badge_patterns ?? [];

  const webhookPatterns = tool?.spec.webhook_patterns ?? [];

  const fileAndLinePatternCount =
    fileAndLinePatterns.reduce((count, item) => {
      return count + (item.filename_patterns?.length ?? 0);
    }, 0) ?? 0;

  const cmdPatternCount =
    Object.values(cmdPatterns).reduce((count, item) => {
      return count + (item.length ?? 0);
    }, 0) ?? 0;

  const appPatternCount = Object.values(appPatterns).reduce((count, item) => {
    return count + (item.length ?? 0);
  }, 0);

  return {
    appPatterns,
    appPatternCount,
    cmdPatterns,
    cmdPatternCount,
    fileAndLinePatterns,
    fileAndLinePatternCount,
    filePatterns,
    githubBadgePatterns,
    webhookPatterns,
  };
};
