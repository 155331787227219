import 'regenerator-runtime/runtime';

// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { setClientConfiguration } from '@endorlabs/queries';

import { getEnv } from './constants';
import { WebApp } from './WebApp';

const ENV = getEnv();
setClientConfiguration({ basePath: ENV.API_BASE_URL });
const container = document.getElementById('app-root');
const root = container && createRoot(container);

root?.render(<WebApp />);
