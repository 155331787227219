import { Skeleton, Typography, useTheme } from '@mui/material';

import { SpecFindingLevel } from '@endorlabs/api_client';

import { NumberDisplay, RowStack } from '../../../../components';
import { FindingLevelChip } from '../FindingLevelChip/FindingLevelChip';

export interface FindingCountDisplayProps {
  level: SpecFindingLevel;
  value?: number;
  width?: string;
}

/**
 * Displays a FindingLevelChip along with a number
 */
export const FindingCountDisplay = ({
  level,
  value,
  width,
}: FindingCountDisplayProps) => {
  const { palette, spacing } = useTheme();

  return (
    <RowStack
      className="FindingCountDisplay-root"
      flexWrap="nowrap"
      gap={spacing(1)}
      width={width}
    >
      <FindingLevelChip disabled={value === 0} level={level} size="xsmall" />

      {value === undefined && (
        <Typography variant="body2">
          <Skeleton
            height={14}
            width={12}
            sx={{ borderRadius: '2px', transform: 'none' }}
          />
        </Typography>
      )}

      {value !== undefined && (
        <NumberDisplay
          typographyProps={{
            color: value === 0 ? palette.action.disabled : undefined,
            fontWeight: 500,
            variant: 'body2',
          }}
          value={value}
        />
      )}
    </RowStack>
  );
};
