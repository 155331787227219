import { Theme } from '@mui/material';

import { EndorThemeConstants } from './constants';

export const getCardOverrides = (theme: Theme) => {
  const { spacing } = theme;

  const CARD_PADDING = spacing(EndorThemeConstants.CARD_PADDING);

  return {
    MuiCard: {
      defaultProps: {
        elevation: 3,
      },

      styleOverrides: {
        root: {
          border: `1px solid ${theme.palette.grey[200]}`,
          borderRadius: '0.333rem',
        },
        outlined: {
          border: `1px solid ${theme.palette.grey[200]}`,
        },
      },
    },

    MuiCardHeader: {
      styleOverrides: {
        root: {
          marginBottom: spacing(2),
          paddingTop: spacing(5),
          paddingLeft: CARD_PADDING,
          paddingRight: CARD_PADDING,
          paddingBottom: 0,
        },
        title: {
          marginBottom: spacing(1),
        },
      },
      defaultProps: {
        subheaderTypographyProps: {
          variant: 'body2',
        },

        titleTypographyProps: {
          variant: 'h3',
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingLeft: CARD_PADDING,
          paddingRight: CARD_PADDING,
          paddingTop: spacing(5),

          // Remove top padding if CardHeader is present.
          '.MuiCardHeader-root + &': {
            paddingTop: 0,
          },
        },
      },
    },
  };
};
