import { QueryKey, useQuery } from 'react-query';

import {
  PolicyTemplateServiceApi,
  V1ListParameters,
} from '@endorlabs/api_client';

import { useBuildReadRequestParameters } from './hooks';
import {
  PolicyTemplateResource,
  PolicyTemplateResourceList,
  ResourceQueryOptions,
} from './types';
import { buildListParamArgs, getClientConfiguration } from './utils';

interface PolicyTemplateReadParams {
  namespace: string;
  uuid: string;
}

type GetPolicyTemplateOptions = ResourceQueryOptions<PolicyTemplateResource>;
type ListPolicyTemplateOptions =
  ResourceQueryOptions<PolicyTemplateResourceList>;

const BASE_KEY = 'v1/policy_templates';
const QK = {
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const PolicyTemplateQueryKeys = QK;

const getApiService = () =>
  new PolicyTemplateServiceApi(getClientConfiguration());

const getPolicyTemplate = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.policyTemplateServiceGetPolicyTemplate(
    namespace,
    uuid
  );
  return resp.data as PolicyTemplateResource;
};

export const useGetPolicyTemplate = (
  params: PolicyTemplateReadParams,
  opts: GetPolicyTemplateOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getPolicyTemplate(params.namespace, params.uuid),
    opts
  );
};

const listPolicyTemplates = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.policyTemplateServiceListPolicyTemplates(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as PolicyTemplateResourceList;
};

export const useListPolicyTemplates = (
  namespace: string,
  opts: ListPolicyTemplateOptions = {},
  listParams: V1ListParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PolicyTemplate',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listPolicyTemplates(namespace, requestParameters),
    opts
  );
};
