import { Stack } from '@mui/material';
import {
  hierarchy,
  HierarchyNode,
  HierarchyPointLink,
  tree,
} from 'd3-hierarchy';
import { select } from 'd3-selection';
import { linkHorizontal } from 'd3-shape';
import { zoom, zoomIdentity, ZoomTransform } from 'd3-zoom';
import { throttle } from 'lodash-es';
import { useMemo } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { UIPackageVersionUtils } from '../../domains/PackageVersion';
import { ButtonPrimary } from '../Button';
import {
  CursorType,
  GraphData,
  GraphProps,
  HierarchyPointNode,
  NodeAction,
} from './types';

export const DependencyGraph = ({ graphData, onOpen }: GraphProps) => {
  const { devicePixelRatio: pixelRatio = 1 } = window;

  const nodeBg = '#ffffff';
  const selectedNodeBg = '#3b82f6';
  const nodeBorderColor = '#cccccc';
  const selectedNodeBorderColor = '#3b82f6';
  const nodeColor = '#333333';
  const selectedNodeColor = '#ffffff';
  const linkColor = '#aaaaaa';
  const selectedLinkColor = '#3b82f6';
  const countBoxColor = '#333333';
  const countBoxBg = '#cccccc';
  const drawerIconColor = '#aaaaaa';

  const nodeWidth = 250;
  const nodeHeight = 30;
  const margins = {
    top: nodeHeight / 2,
    left: nodeWidth / 2,
    bottom: nodeHeight / 2,
    right: nodeWidth / 2,
  };
  const labelSize = 14;

  const nodePadding = {
    top: 12,
    left: 12,
    bottom: 12,
    right: 12,
  };

  const countBoxPadding = {
    top: 8,
    left: 8,
    bottom: 8,
    right: 8,
  };

  const countBoxHeight =
    labelSize + countBoxPadding.top + countBoxPadding.bottom;

  const drawerIconHeight = 16;
  const drawerIconWidth = 16;

  const hierachyData = useRef<
    HierarchyNode<GraphData> & {
      _children?: unknown;
    }
  >();

  const nodes = useRef<HierarchyPointNode[]>([]);
  const links = useRef<HierarchyPointLink<GraphData>[]>([]);

  const data = useRef<GraphData>(graphData);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const selectedNode = useRef<HierarchyPointNode>();
  const selectedNodes = useRef<HierarchyPointNode[]>([]);
  const transformRef = useRef<ZoomTransform>(zoomIdentity);
  const [cursor, setCursor] = useState<CursorType>('grab');
  const [init, setInit] = useState<boolean>(false);
  const dataLoaded = useRef<boolean>(false);

  const containerSize = containerRef.current?.getBoundingClientRect();

  const stageWidth = containerSize?.width ?? 0;
  const stageHeight = containerSize?.height ?? 0;
  const canvasWidth = useMemo(
    () => stageWidth * pixelRatio,
    [pixelRatio, stageWidth]
  );
  const canvasHeight = useMemo(
    () => stageHeight * pixelRatio,
    [pixelRatio, stageHeight]
  );

  const treeWidth = stageHeight - margins.left - margins.right;

  const getDisplayText = (
    text: string,
    containerWidth: number,
    fontSize: number
  ): string[] => {
    const context = contextRef.current;
    if (context) {
      context.save();
      context.font = `${fontSize}px sans-serif`;
      const textWidth = context.measureText(text).width ?? 0;
      context.restore();
      const textLength = text.length;
      const textArray = [];
      if (textWidth > containerWidth) {
        const percent = containerWidth / textWidth;
        const breakpoint = Math.round(textLength * percent);
        const parts = Math.ceil(textLength / breakpoint);
        for (let i = 0; i < parts; i++) {
          textArray.push(text.slice(i * breakpoint, (i + 1) * breakpoint));
        }

        return textArray;
      }
    }

    return [text];
  };

  const treeRef = tree<GraphData>();

  const updateData = useCallback(() => {
    if (hierachyData.current) {
      const tree = treeRef(hierachyData.current);

      const levelNodeCount: Record<string, number> = {};

      let maxDepth = 0;

      tree.count().each((node: HierarchyPointNode) => {
        if (levelNodeCount[String(node.depth)]) {
          levelNodeCount[String(node.depth)] += 1;
        } else {
          levelNodeCount[String(node.depth)] = 1;
        }
        if (node.depth > maxDepth) {
          maxDepth = node.depth;
        }
      });

      const maxSpan = Math.max(...Object.values(levelNodeCount));

      treeRef.size([
        treeWidth + maxSpan * nodeHeight * 5,
        maxDepth * nodeWidth * 2,
      ]);

      nodes.current = hierachyData.current
        ? treeRef(hierachyData.current).descendants()
        : [];
      links.current = hierachyData.current
        ? treeRef(hierachyData.current).links()
        : [];
    }
  }, [treeRef, treeWidth]);

  const drawCountBox = useCallback(
    (node: HierarchyPointNode) => {
      if (contextRef.current) {
        if (node.height > 0) {
          contextRef.current.save();
          contextRef.current.strokeStyle = linkColor;
          contextRef.current.beginPath();
          contextRef.current.moveTo(
            node.y + nodeWidth,
            node.x + (node.data.boxHeight ?? nodeHeight) / 2
          );
          contextRef.current.lineTo(
            node.y + nodeWidth + nodePadding.right,
            node.x + (node.data.boxHeight ?? nodeHeight) / 2
          );
          contextRef.current.stroke();
          contextRef.current.closePath();
          const children = node._children as typeof node.children;
          const childCount = `+${children?.length ?? 0}`;

          const countWidth = node._children
            ? contextRef.current.measureText(childCount).width
            : contextRef.current.measureText('-').width;

          const countBoxTop =
            ((node.data.boxHeight ?? nodeHeight) - countBoxHeight) / 2;
          contextRef.current.fillStyle = countBoxBg;
          contextRef.current.fillRect(
            node.y + nodeWidth + nodePadding.right,
            node.x + countBoxTop,
            countWidth + countBoxPadding.left + countBoxPadding.right,
            countBoxHeight
          );
          contextRef.current.fillStyle = countBoxColor;
          contextRef.current.textBaseline = 'top';
          contextRef.current.fillText(
            node._children ? childCount : '-',
            node.y + nodeWidth + countBoxPadding.left + nodePadding.right,
            node.x + countBoxTop + countBoxPadding.top,
            countWidth
          );

          contextRef.current.restore();
        }
      }
    },
    [
      countBoxHeight,
      countBoxPadding.left,
      countBoxPadding.right,
      countBoxPadding.top,
      nodePadding.right,
    ]
  );

  const drawNode = useCallback(
    (node: HierarchyPointNode) => {
      if (contextRef.current) {
        const boxHeight = node.data.boxHeight ?? nodeHeight + labelSize;

        const isSelected =
          selectedNodes.current.findIndex(
            (sn) => sn.data.nodeId === node.data.nodeId
          ) > -1 ||
          node.data.name ===
            (selectedNode.current && selectedNode.current.data.name);

        contextRef.current.save();
        contextRef.current.lineJoin = 'round';
        if (isSelected) {
          contextRef.current.fillStyle = selectedNodeBg;
          contextRef.current.strokeStyle = selectedNodeBorderColor;
        } else {
          contextRef.current.fillStyle = nodeBg;
          contextRef.current.strokeStyle = nodeBorderColor;
        }

        contextRef.current.fillRect(node.y, node.x, nodeWidth, boxHeight);
        contextRef.current.strokeRect(node.y, node.x, nodeWidth, boxHeight);

        contextRef.current.save();
        if (isSelected) {
          contextRef.current.strokeStyle = selectedNodeColor;
        } else {
          contextRef.current.strokeStyle = drawerIconColor;
        }
        contextRef.current.lineWidth = 2;

        const drawerX =
          node.y + nodeWidth - nodePadding.right - drawerIconWidth;

        const drawerY =
          node.x + ((node.data.boxHeight ?? 0) - drawerIconHeight) / 2;

        contextRef.current.strokeRect(
          drawerX,
          drawerY,
          drawerIconWidth,
          drawerIconHeight
        );

        contextRef.current.beginPath();

        contextRef.current.moveTo(drawerX + drawerIconWidth * 0.66, drawerY);
        contextRef.current.lineTo(
          drawerX + drawerIconWidth * 0.66,
          drawerY + drawerIconHeight
        );
        contextRef.current.stroke();
        contextRef.current.closePath();

        contextRef.current.restore();

        if (isSelected) {
          contextRef.current.fillStyle = selectedNodeColor;
        } else {
          contextRef.current.fillStyle = nodeColor;
        }
        contextRef.current.textBaseline = 'middle';
        contextRef.current.font = `${labelSize}px sans-serif`;
        contextRef.current.textBaseline = 'top';
        node.data.displayText?.forEach((t, i) => {
          if (contextRef.current) {
            contextRef.current.fillText(
              t,
              node.y + nodePadding.left,
              node.x + nodePadding.top + i * labelSize * 1.2,
              nodeWidth
            );
          }
        });

        drawCountBox(node);
        contextRef.current.restore();
      }
    },
    [drawCountBox, nodePadding.left, nodePadding.right, nodePadding.top]
  );

  const drawLink = useCallback(
    (link: HierarchyPointLink<GraphData>) => {
      if (contextRef.current) {
        const sourceHeight = link.source.data.boxHeight ?? nodeHeight;
        const targetHeight = link.target.data.boxHeight ?? nodeHeight;
        const path = String(
          linkHorizontal().context(null)({
            source: [
              link.source.y +
                nodeWidth +
                nodePadding.right +
                countBoxPadding.left +
                countBoxPadding.right +
                labelSize / 4,
              link.source.x + sourceHeight / 2,
            ],
            target: [link.target.y, link.target.x + targetHeight / 2],
          })
        );

        contextRef.current.save();

        const isSelected =
          selectedNodes.current.findIndex(
            (sn) => sn.data.nodeId === link.source.data.nodeId
          ) > -1 &&
          selectedNodes.current.findIndex(
            (sn) => sn.data.nodeId === link.target.data.nodeId
          ) > -1;

        if (isSelected) {
          contextRef.current.strokeStyle = selectedLinkColor;
          contextRef.current.fillStyle = selectedLinkColor;
        } else {
          contextRef.current.strokeStyle = linkColor;
          contextRef.current.fillStyle = linkColor;
        }
        contextRef.current.lineWidth = 1;
        const p = new Path2D(path);
        contextRef.current.stroke(p);
        contextRef.current.beginPath();
        contextRef.current.moveTo(
          link.target.y,
          link.target.x + targetHeight / 2
        );
        contextRef.current.lineTo(
          link.target.y - 10,
          link.target.x + targetHeight / 2 + 4
        );
        contextRef.current.lineTo(
          link.target.y - 10,
          link.target.x + targetHeight / 2 - 4
        );
        contextRef.current.lineTo(
          link.target.y,
          link.target.x + targetHeight / 2
        );
        contextRef.current.fill();
        contextRef.current.closePath();
        contextRef.current.restore();
      }
    },
    [countBoxPadding.left, countBoxPadding.right, nodePadding.right]
  );

  const drawTree = useCallback(() => {
    if (contextRef.current) {
      contextRef.current.save();

      const scaleX = transformRef.current.k * pixelRatio;
      const scaleY = transformRef.current.k * pixelRatio;

      const translateX = (transformRef.current.x + margins.left) * pixelRatio;
      const translateY = (transformRef.current.y + margins.top) * pixelRatio;

      contextRef.current.clearRect(
        0,
        0,
        canvasRef.current?.width ?? canvasWidth,
        canvasRef.current?.height ?? canvasHeight
      );

      contextRef.current.translate(translateX, translateY);
      contextRef.current.scale(scaleX, scaleY);

      links.current.forEach((link) => {
        drawLink(link);
      });
      nodes.current.forEach((node) => {
        drawNode(node);
      });
      contextRef.current.restore();
    }
  }, [
    canvasHeight,
    canvasWidth,
    drawLink,
    drawNode,
    margins.left,
    margins.top,
    pixelRatio,
  ]);

  const getActiveNode = useCallback(
    (
      mouseX: number,
      mouseY: number
    ): [HierarchyPointNode | undefined, NodeAction] => {
      let action: NodeAction = 'select';
      const scale = transformRef.current.k;
      const translateX = transformRef.current.x;
      const translateY = transformRef.current.y;
      for (let i = 0; i < nodes.current.length; i++) {
        const node = nodes.current[i];
        const x1 = node.y * scale + (translateX + margins.left);
        const x2 = x1 + nodeWidth * scale;
        const y1 = node.x * scale + (translateY + margins.top);
        const y2 = y1 + (node.data.boxHeight ?? nodeHeight) * scale;

        const isCollapsed = !node.children && node._children;

        const ex1 = x2 + nodePadding.right * scale;
        let ex2 = ex1 + (countBoxPadding.left + countBoxPadding.right) * scale;
        const children = node._children as typeof node.children;
        const childCount = `+${children?.length ?? 0}`;
        if (contextRef.current) {
          ex2 += isCollapsed
            ? contextRef.current.measureText(`+${childCount}`).width * scale
            : contextRef.current.measureText('-').width * scale;
        }
        const countBoxTop =
          ((node.data.boxHeight ?? nodeHeight) - countBoxHeight) / 2;
        const ey1 = y1 + countBoxTop * scale;
        const ey2 = ey1 + countBoxHeight * scale;

        if (mouseX >= x1 && mouseX <= x2 && mouseY >= y1 && mouseY <= y2) {
          const ax1 = x2 - (drawerIconWidth + nodePadding.right) * scale;
          const ax2 = x2 - nodePadding.right * scale;
          const ay1 = y1 + nodePadding.top * scale;
          const ay2 = y2 - nodePadding.bottom * scale;
          if (
            mouseX >= ax1 &&
            mouseX <= ax2 &&
            mouseY >= ay1 &&
            mouseY <= ay2
          ) {
            action = 'open';
          }
          return [node, action];
        } else if (
          mouseX >= ex1 &&
          mouseX <= ex2 &&
          mouseY >= ey1 &&
          mouseY <= ey2
        ) {
          action = 'expand';
          return [node, action];
        }
      }
      return [undefined, action];
    },
    [
      countBoxHeight,
      countBoxPadding.left,
      countBoxPadding.right,
      margins.left,
      margins.top,
      nodePadding.bottom,
      nodePadding.right,
      nodePadding.top,
    ]
  );

  const centerTree = useCallback(
    (scale: number) => {
      const zoomed = (transform: ZoomTransform) => {
        transformRef.current = transform;
        if (dataLoaded.current) {
          drawTree();
        }
      };
      if (canvasRef.current) {
        const canvasElement = select<HTMLCanvasElement, unknown>(
          canvasRef.current
        );
        const zoomElement = zoom<HTMLCanvasElement, unknown>();
        canvasElement.call(
          zoomElement
            .scaleExtent([0.2, 3])
            .on('zoom', ({ transform }: { transform: ZoomTransform }) =>
              zoomed(transform)
            )
        );
        updateData();
        const tree = hierachyData.current && treeRef(hierachyData.current);
        canvasElement.call(zoomElement.scaleTo, scale);
        canvasElement.call(
          zoomElement.translateTo,
          stageWidth / (pixelRatio * scale),
          tree?.x as number
        );
        if (dataLoaded.current) {
          drawTree();
        }
      }
    },
    [drawTree, pixelRatio, stageWidth, treeRef, updateData]
  );

  const handleNodeClick = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.stopImmediatePropagation();
      const [clickedNode, action] = getActiveNode(evt.offsetX, evt.offsetY);
      if (clickedNode) {
        switch (action) {
          case 'open':
            onOpen(clickedNode?.data);
            break;
          case 'expand':
            if (clickedNode._children) {
              clickedNode.children =
                clickedNode._children as HierarchyPointNode[];
              clickedNode._children = undefined;
              clickedNode.data.isCollapsed = false;
              centerTree(transformRef.current.k);
            } else {
              clickedNode._children = clickedNode.children;
              clickedNode.children = undefined;
              clickedNode.data.isCollapsed = true;
              centerTree(transformRef.current.k);
            }
            break;
          case 'select': {
            selectedNode.current = clickedNode;
            const ancestors = clickedNode.ancestors().slice(1);
            const decendants = clickedNode.descendants();
            selectedNodes.current = [...ancestors, ...decendants];
            break;
          }
        }
      } else {
        selectedNode.current = undefined;
        selectedNodes.current = [];
      }
      drawTree();
    },
    [getActiveNode, drawTree, onOpen, centerTree]
  );

  const expandAllNodes = () => {
    nodes.current
      .filter((node) => node.depth === 0 || node.depth === 1)
      .forEach((node) => {
        if (node._children) {
          node.children = node._children as HierarchyPointNode[];
          node._children = undefined;
        }
      });
    centerTree(transformRef.current.k);
  };

  const collapseAllNodes = () => {
    nodes.current
      .filter((node) => node.depth === 1)
      .forEach((node) => {
        if (node.children) {
          node._children = node.children;
          node.children = undefined;
        }
      });
    centerTree(transformRef.current.k);
  };

  const collapseMarkedNodes = useCallback(() => {
    nodes.current.forEach((node) => {
      if (node.data.isCollapsed && node.children) {
        node._children = node.children;
        node.children = undefined;
      }
    });
  }, []);

  const handleMouseMove = useCallback(
    (evt: MouseEvent) => {
      evt.stopPropagation();
      evt.stopImmediatePropagation();
      const [activeNode] = getActiveNode(evt.offsetX, evt.offsetY);
      if (activeNode) {
        setCursor('pointer');
      } else {
        setCursor('grab');
      }
    },
    [getActiveNode]
  );

  const throttledMouseMove = useRef(throttle(handleMouseMove, 100));

  useEffect(() => {
    const canvas = canvasRef.current;
    const mouseMoveHandler = throttledMouseMove.current;
    if (canvas) {
      canvas.addEventListener('click', handleNodeClick);
      canvas.addEventListener('mousemove', mouseMoveHandler);

      if (!contextRef.current) {
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const renderCtx = canvas.getContext('2d');

        if (renderCtx) {
          contextRef.current = renderCtx;
          drawTree();
        }
      }
    }

    return () => {
      canvas?.removeEventListener('click', handleNodeClick);
      canvas?.removeEventListener('mousemove', mouseMoveHandler);
    };
  }, [canvasHeight, canvasWidth, drawTree, handleNodeClick]);

  useEffect(() => {
    updateData();
    drawTree();
  }, [canvasHeight, canvasWidth, drawTree, updateData, graphData, centerTree]);

  useEffect(() => {
    let idCount = 1;
    hierachyData.current = hierarchy(data.current).each((d) => {
      const maxTextWidth =
        nodeWidth - drawerIconWidth - nodePadding.left - nodePadding.right * 2;
      const { label, version } = UIPackageVersionUtils.parsePackageName(
        d.data.name
      );
      const displayText = getDisplayText(
        `${label}@${version}`,
        maxTextWidth,
        labelSize + 1
      );
      d.data.displayText = displayText;
      d.data.boxHeight =
        displayText.length * labelSize * 1.2 +
        nodePadding.top +
        nodePadding.bottom;
      d.data.nodeId = idCount++;
    });
  }, [
    nodePadding.bottom,
    nodePadding.left,
    nodePadding.right,
    nodePadding.top,
  ]);

  useEffect(() => {
    if (canvasRef.current) {
      if (!init) {
        centerTree(0.75);
        setInit(true);
      }
    }
  }, [init, centerTree]);

  useEffect(() => {
    if (canvasRef.current) {
      if (!dataLoaded.current) {
        collapseMarkedNodes();
        centerTree(0.75);
        dataLoaded.current = true;
      }
    }
  }, [collapseMarkedNodes, centerTree]);

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
      }}
      ref={containerRef}
    >
      <canvas
        width={canvasWidth}
        height={canvasHeight}
        style={{ width: stageWidth, height: stageHeight, cursor }}
        ref={canvasRef}
      />
      <Stack
        direction="row"
        position="absolute"
        top={12}
        right={12}
        columnGap={2}
      >
        <ButtonPrimary onClick={expandAllNodes}>
          <FM defaultMessage="Expand All" />
        </ButtonPrimary>
        <ButtonPrimary onClick={collapseAllNodes}>
          <FM defaultMessage="Collapse All" />
        </ButtonPrimary>
      </Stack>
    </div>
  );
};
