/**
 * Provide helper functions to generate paths to projects/packages in a particular tenant or namespace.
 */

import { partition as _partition } from 'lodash-es';
import { isEqual as _isEqual, remove as _remove } from 'lodash-es';

import { V1FindingTags } from '@endorlabs/api_client';
import { NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { Filter, FILTER_COMPARATORS, ValueFilter } from '@endorlabs/filters';
import { WithOnly, WithRequired } from '@endorlabs/utils/types';

import { OSSExplorerPageSource } from '../../domains/OSS';
import { NamedRoutes } from '../constants';

interface TenantRoutePathSegments<KSection extends string = string> {
  tenantName: string;
  section: KSection;
}

export const getAccessRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'access'].join('/');

export const getAccessPath = (
  args: TenantRoutePathSegments<
    'api-keys' | 'authorization-policies' | 'identity-providers' | 'invitations'
  >
) => [getAccessRootPath(args), args.section].join('/');

export const getNamespacesRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'namespaces'].join('/');

export const getNotificationsRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'notifications'].join('/');

export const getNotificationsPath = (
  args: TenantRoutePathSegments<'all' | 'open' | 'resolved'>
) => ['/t', args.tenantName, 'notifications', args.section].join('/');

export const getNotificationDetailPath = (args: {
  tenantName: string;
  notificationUuid: string;
}) =>
  ['/t', args.tenantName, 'notifications/view', args.notificationUuid].join(
    '/'
  );

export const getIntegrationsRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'integrations'].join('/');

export const getOnboardRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'> & {
    onboardApproach?: string;
  }
) =>
  ['/t', args.tenantName, 'onboard', args.onboardApproach]
    .filter((arg) => !!arg)
    .join('/');

interface CustomNotificationTemplatePathSegments
  extends TenantRoutePathSegments<'custom-notification-template'> {
  key: 'pr_comments' | 'email' | 'slack' | 'webhook';
}

interface NotificationTargetPathSegments
  extends TenantRoutePathSegments<'notification-targets'> {
  key: 'email' | 'jira' | 'slack' | 'vanta' | 'webhook' | 'github_pr';
}

interface PackageManagerPathSegments
  extends TenantRoutePathSegments<'package-managers'> {
  key: 'mvn' | 'npm' | 'pypi' | 'gem' | 'packagist' | 'nuget';
}

interface SourceControlPathSegments
  extends TenantRoutePathSegments<'source-control'> {
  key: 'GitHub' | 'GitLab' | 'Azure';
}

type IntegrationsPathSegments =
  | CustomNotificationTemplatePathSegments
  | NotificationTargetPathSegments
  | PackageManagerPathSegments
  | SourceControlPathSegments;

export const getIntegrationsPath = (args: IntegrationsPathSegments) =>
  [getIntegrationsRootPath(args), args.section, args.key].join('/');

export const getCustomTemplatePath = (
  args: IntegrationsPathSegments & { uuid?: string }
) => {
  const integrationPath = getIntegrationsPath(args);
  if (args.uuid) {
    return [integrationPath, args.uuid].join('/');
  }
  return integrationPath;
};

export const getPoliciesRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'policies'].join('/');

export const getPoliciesPath = (args: TenantRoutePathSegments) =>
  [getPoliciesRootPath(args), args.section].join('/');

export const getSettingsRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName, 'settings'].join('/');

export const getSettingsPath = (
  args: TenantRoutePathSegments<
    'saved-filters' | 'scan-profiles' | 'secrets' | 'system'
  >
) => [getSettingsRootPath(args), args.section].join('/');

export const getSettingsScanProfilesPath = (
  args: Omit<TenantRoutePathSegments, 'section'> & { uuid: string }
) =>
  [getSettingsPath({ ...args, section: 'scan-profiles' }), args.uuid].join('/');

export const getTenantIndexPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => ['/t', args.tenantName].join('/');

interface ResourcePathSegments {
  resourceName?:
    | 'artifacts'
    | 'assured-package-versions'
    | 'dashboard'
    | 'dependencies'
    | 'findings'
    | 'license-required'
    | 'llms'
    | 'packages'
    | 'projects'
    | 'remediations'
    | 'scan-profiles'
    | 'service-requests'
    | 'third-party'
    | 'tools'
    | 'sast';
  tenantName: string;
  uuid?: string;
}

interface ResourceVersionPathSegments extends ResourcePathSegments {
  uuid: string;
  versionRef?: string;
  additionalSegments?: string[];
}

export const getDashboardPath = (args: ResourcePathSegments) =>
  getTenantResourcePath({
    ...args,
    resourceName: 'dashboard',
  });

export const getProjectPath = (args: ResourcePathSegments) =>
  getTenantResourcePath({
    ...args,
    resourceName: 'projects',
  });

export const getProjectDetailPath = (
  args: ResourcePathSegments & {
    section:
      | 'overview'
      | 'packages'
      | 'dependencies'
      | 'findings'
      | 'tools'
      | 'remediations'
      | 'pr-runs'
      | 'settings';
  }
) => {
  const base = getTenantResourcePath({
    ...args,
    resourceName: 'projects',
  });

  if (args.section) {
    return [base, args.section].join('/');
  }

  return base;
};

/**
 * @deprecated will be replaced by {@see getProjectVersionDetailPath} with the IA Project work UI-976
 */
export const getProjectVersionPath = (args: ResourceVersionPathSegments) => {
  let base = getProjectPath(args);
  if (args.additionalSegments) {
    base = [base, ...args.additionalSegments].join('/');
  }
  if (!args.versionRef) return base;
  const params = new URLSearchParams({ versionRef: args.versionRef });
  return `${base}?${params}`;
};

export const getProjectVersionDetailPath = (
  args: WithRequired<
    Omit<ResourceVersionPathSegments, 'resourceName'>,
    'versionRef'
  >
) => {
  const base = getProjectPath(args);
  const path = [base, 'versions', encodeURIComponent(args.versionRef)];

  if (args.additionalSegments) {
    path.push(...args.additionalSegments);
  }

  return path.join('/');
};

export const getPackageVersionRootPath = (
  args: Pick<TenantRoutePathSegments, 'tenantName'>
) => {
  return getTenantResourcePath({
    resourceName: 'packages',
    tenantName: args.tenantName,
  });
};

export const getPackageVersionPath = (
  args: WithOnly<ResourcePathSegments, 'tenantName' | 'uuid'>
) => {
  const { tenantName, uuid } = args;

  if (tenantName === NAMESPACES.OSS) {
    const segments: string[] = [NamedRoutes.OSS_EXPLORER];
    if (uuid) segments.push('packages', uuid);
    // NOTE: OSS Explorer route is prefixed with `/`
    return segments.join('/');
  }

  return getTenantResourcePath({
    resourceName: 'packages',
    tenantName,
    uuid,
  });
};

export const getPackageVersionRedirectPath = (
  args: Pick<ResourcePathSegments, 'tenantName'> & {
    packageVersionName: string;
  }
) => {
  const { tenantName } = args;

  const encodedPackageVersionName = encodeURIComponent(args.packageVersionName);

  if (tenantName === NAMESPACES.OSS) {
    const segments: string[] = [
      NamedRoutes.OSS_EXPLORER,
      'packages',
      'redirect-by-name',
      encodedPackageVersionName,
    ];
    // NOTE: OSS Explorer route is prefixed with `/`
    return segments.join('/');
  }

  const base = getTenantResourcePath({
    resourceName: 'packages',
    tenantName,
  });

  return [base, 'redirect-by-name', encodedPackageVersionName].join('/');
};

/**
 * Get the path to dependency by either:
 * - DependencyMetadata uuid
 * - Dependency Package Name
 * - Root Dependencies page (with optional filters)
 */
export const getDependencyPath = (
  args:
    | (Pick<ResourcePathSegments, 'tenantName'> & { uuid: string })
    | (Pick<ResourcePathSegments, 'tenantName'> & {
        dependencyPackageVersionName: string;
      })
    | (Pick<ResourcePathSegments, 'tenantName'> & {
        uuid?: never;
        filterValues?: ValueFilter[];
      })
) => {
  const resourceName = 'dependencies';

  if ('dependencyPackageVersionName' in args) {
    const { dependencyPackageVersionName, tenantName } = args;

    const base = getTenantResourcePath({
      resourceName,
      tenantName: tenantName,
    });

    return [
      base,
      'redirect-by-name',
      encodeURIComponent(dependencyPackageVersionName),
    ].join('/');
  }

  const path = getTenantResourcePath({ ...args, resourceName });
  if ('uuid' in args) {
    return path;
  }

  // Handle appending filter values
  if (args.filterValues?.length) {
    const params = new URLSearchParams({
      'filter.values': JSON.stringify(
        // Mapping to filter shape expected by {@see useFilterSearchParams}
        args.filterValues.map((f) => ({ kind: 'DependencyMetadata', ...f }))
      ),
    });
    return `${path}?${params}`;
  }

  return path;
};

/**
 * Get the path to findings by either:
 * - Finding uuid
 * - Root Findings page by section (with optional filters)
 */
export const getFindingsPath = (
  args:
    | (Pick<ResourcePathSegments, 'tenantName'> & { uuid: string })
    | (Pick<ResourcePathSegments, 'tenantName'> & { uuid?: never })
    | (Pick<ResourcePathSegments, 'tenantName'> & {
        section: 'dependency' | 'package' | 'repository' | 'secrets';
        filterValues?: ValueFilter[];
      })
) => {
  const resourceName = 'findings';
  const { tenantName } = args;

  if ('uuid' in args) {
    return getTenantResourcePath({ resourceName, tenantName, uuid: args.uuid });
  }

  const base = getTenantResourcePath({ resourceName, tenantName });
  const parts = [base];
  if ('section' in args) {
    parts.push(args.section);

    // Handle appending filter values
    if (args.filterValues?.length) {
      const findingTagsKey = 'spec.finding_tags';
      const [findingTagFilterValues, filterValues] = _partition(
        args.filterValues,
        (f) => f.key === findingTagsKey
      );

      const keyed = {} as Record<string, Filter>;

      for (const f of filterValues) {
        keyed[f.key] = f;
      }

      // HACK: wrap finding tags in logical filter for expected shape
      if (findingTagFilterValues.length) {
        const exceptionsFilter = {
          comparator: FILTER_COMPARATORS.NOT_CONTAINS,
          key: 'spec.finding_tags',
          value: [V1FindingTags.Exception],
        };

        // HACK: Special handling for exceptions use case
        const exceptionsFilterMember = _remove(findingTagFilterValues, (f) =>
          _isEqual(f, exceptionsFilter)
        );

        if (exceptionsFilterMember.length) {
          keyed['findingExceptions'] = exceptionsFilter;
        }

        keyed[findingTagsKey] = {
          key: findingTagsKey,
          operator: 'AND',
          value: findingTagFilterValues,
        };
      }

      const params = new URLSearchParams({
        'filter.values': JSON.stringify(keyed),
      });

      return `${parts.join('/')}?${params}`;
    }
  }

  return parts.join('/');
};

export const getLicenseRequiredPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'license-required' });

export const getThirdPartyPath = (
  args: Omit<ResourcePathSegments, 'resourceName' | 'uuid'>
) =>
  getTenantResourcePath({
    ...args,
    resourceName: 'third-party',
  });

export const getSBOMImportPath = (
  args: Omit<ResourcePathSegments, 'resourceName' | 'uuid'> & {
    sbomImportUuid: string;
  }
) => {
  const thirdPartyLink = getThirdPartyPath(args);
  const base = `${thirdPartyLink}/sboms/${args.sbomImportUuid}`;
  return base;
};

export const getCiCdToolsPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'tools' });

export const getArtifactsPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'artifacts' });

export const getArtifactDetailPath = (
  args: Omit<ResourcePathSegments, 'resourceName'> & { artifactName: string }
) => {
  const artifactbase = getArtifactsPath(args);
  const base = `${artifactbase}/${args.artifactName}`;
  return base;
};

export const getServiceRequestPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'service-requests' });

export const getServiceRequestDetailPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => {
  const serviceRequestbase = getServiceRequestPath(args);
  const base = `${serviceRequestbase}/${args.uuid}`;
  return base;
};

export const getRemediationsPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'remediations' });

// FIXME: Not the path we want long-term
export const getAssuredPackageVersionPath = (args: {
  namespace: string;
  packageVersionName: string;
}) =>
  `/t/${args.namespace}/explore/patches/${encodeURIComponent(
    args.packageVersionName
  )}`;

export const getPackageFindingPath = (args: {
  namespace: string;
  packageUuid: string;
  findingUuid?: string;
}) =>
  `/t/${args.namespace}/packages/${args.packageUuid}/findings/${args.findingUuid}`;

export const getOSSExplorerPath = (args: {
  section: OSSExplorerPageSource;
  uuid?: string;
}) => {
  const { section, uuid } = args;

  const segments: string[] = [NamedRoutes.OSS_EXPLORER, section];

  if (uuid) {
    segments.push(uuid);
  }

  // NOTE: OSS Explorer route is prefixed with `/`
  return segments.join('/');
};

export const getHuggingFaceModelRedirectPath = (args: {
  projectUuid: string;
}) => {
  const encodedProjectUuid = encodeURIComponent(args.projectUuid);

  const segments: string[] = [
    NamedRoutes.OSS_EXPLORER,
    'hugging-face',
    'redirect-by-project',
    encodedProjectUuid,
  ];
  // NOTE: OSS Explorer route is prefixed with `/`
  return segments.join('/');
};

export const getLLMDiscoveryPath = (
  args: Omit<ResourcePathSegments, 'resourceName'>
) => getTenantResourcePath({ ...args, resourceName: 'llms' });

export function getTenantResourcePath({
  tenantName,
  uuid,
  resourceName = 'projects',
}: ResourcePathSegments) {
  return ['/t', tenantName, resourceName, uuid].filter(Boolean).join('/');
}
