import { FormattedMessage as FM } from 'react-intl';

import { V1ReleaseUpgradeInfo } from '@endorlabs/api_client';

import { DetailDrawerSection } from '../../../../components/DetailDrawer';
import { FixedFindingsSection } from '../FixedFindingsSection';

export const RemediationsDetailDrawerFixedFindings = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  return (
    <DetailDrawerSection title={<FM defaultMessage="Fixed Findings" />}>
      <FixedFindingsSection upgradeInfo={upgradeInfo} />
    </DetailDrawerSection>
  );
};
