import { ProjectCIRunDetailPage } from '../../../../../../../views';
import { PageTitles } from '../../../../../../constants';
import { createFileRoute } from '../../../../../../utils/tanstack-react-router-shim';

export const Route = createFileRoute(
  '/t/:namespace/projects/:project_uuid/pr-runs/:pr_run_id'
)({
  children: [{ path: ':activeView' }],
  component: ProjectCIRunDetailPage,
  meta: { pageTitle: PageTitles.projectCIRunDetailPage },
});
