import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import {
  NotificationTargetServiceApi,
  V1CountResponse,
  V1GroupResponse,
  V1ListParameters,
  V1NotificationTarget,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  GroupRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  NotificationTargetResource,
  NotificationTargetResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  DEFAULT_UPDATE_MASK,
  getClientConfiguration,
} from './utils';

export type NotificationTargetReadParams = {
  namespace: string;
  uuid: string;
};
export interface NotificationTargetWriteParams {
  namespace: string;
  resource: V1NotificationTarget;
}
export interface NotificationTargetUpdateParams
  extends NotificationTargetWriteParams {
  mask?: string;
}

type CountNotificationTargetsOptions = ResourceQueryOptions<
  Required<V1CountResponse>
>;
type DeleteNotificationTargetOptions = ResourceMutateOptions<
  object,
  NotificationTargetReadParams
>;
type GetNotificationTargetOptions =
  ResourceQueryOptions<NotificationTargetResource>;
type GroupNotificationTargetOptions = ResourceQueryOptions<
  Required<V1GroupResponse>
>;
type ListNotificationTargetOptions =
  ResourceQueryOptions<NotificationTargetResourceList>;
type UpsertNotificationTargetOptions = ResourceMutateOptions<
  V1NotificationTarget,
  NotificationTargetWriteParams
>;

const BASE_KEY = 'v1/notification-targets';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const NotificationTargetsQueryKeys = QK;

const getApiService = () =>
  new NotificationTargetServiceApi(getClientConfiguration());

const countNotificationTargets = async (
  namespace: string,
  countParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.notificationTargetServiceListNotificationTargets(
    namespace,
    ...buildCountParamArgs(countParams)
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

const createNotificationTarget = async (
  namespace: string,
  notificationTarget: V1NotificationTarget
) => {
  const api = getApiService();
  const resp = await api.notificationTargetServiceCreateNotificationTarget(
    namespace,
    notificationTarget
  );
  return resp.data as NotificationTargetResource;
};

const deleteNotificationTarget = async (
  params: NotificationTargetReadParams
) => {
  const api = getApiService();
  const resp = await api.notificationTargetServiceDeleteNotificationTarget(
    params.namespace,
    params.uuid
  );
  return resp.data;
};

const getNotificationTarget = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.notificationTargetServiceGetNotificationTarget(
    namespace,
    uuid
  );
  return resp.data as NotificationTargetResource;
};

const listNotificationTargets = async (
  namespace: string,
  listParams: V1ListParameters = {}
) => {
  const api = getApiService();
  const resp = await api.notificationTargetServiceListNotificationTargets(
    namespace,
    ...buildListParamArgs(listParams)
  );
  return resp.data as NotificationTargetResourceList;
};

const updateNotificationTarget = async (
  params: NotificationTargetUpdateParams
) => {
  const { resource, namespace, mask = DEFAULT_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.notificationTargetServiceUpdateNotificationTarget(
    namespace,
    req
  );
  return resp.data as NotificationTargetResource;
};

export const useCountNotificationTargets = (
  namespace: string,
  countParams: CountRequestParameters = {},
  opts: CountNotificationTargetsOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'NotificationTarget',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    () => countNotificationTargets(namespace, requestParameters),
    opts
  );
};

export const useCreateNotificationTarget = (
  opts: UpsertNotificationTargetOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('CREATE', 'NotificationTarget'),
    mutationFn: (params: NotificationTargetWriteParams) =>
      createNotificationTarget(params.namespace, params.resource),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

export const useDeleteNotificationTarget = (
  opts: DeleteNotificationTargetOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('DELETE', 'NotificationTarget'),
    mutationFn: (params: NotificationTargetReadParams) =>
      deleteNotificationTarget(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};

export const useGetNotificationTarget = (
  { namespace, uuid }: NotificationTargetReadParams,
  opts: GetNotificationTargetOptions = {}
) => {
  return useQuery(
    QK.record(namespace, uuid),
    () => getNotificationTarget(namespace, uuid),
    opts
  );
};

export const useGroupNotificationTargets = (
  namespace: string,
  groupParams: GroupRequestParameters,
  opts: GroupNotificationTargetOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'NotificationTarget',
    'GROUP',
    groupParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () =>
      listNotificationTargets(namespace, requestParameters).then((r) => {
        const groups = r.group_response?.groups ?? [];
        return { groups } as Required<V1GroupResponse>;
      }),
    opts
  );
};

export const useListNotificationTargets = (
  namespace: string,
  listParams: ListRequestParameters = {},
  opts: ListNotificationTargetOptions = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'NotificationTarget',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listNotificationTargets(namespace, requestParameters),
    opts
  );
};

export const useUpdateNotificationTarget = (
  opts: UpsertNotificationTargetOptions = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'NotificationTarget'),
    mutationFn: (params: NotificationTargetUpdateParams) =>
      updateNotificationTarget(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
