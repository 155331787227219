import { Grid, IconButton, Popover, Stack, Typography } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { IconX } from '@endorlabs/ui-common';

interface PopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

export const PolicyOnboardingPopover = ({
  open,
  anchorEl,
  handleClose,
}: PopoverProps) => {
  return (
    <Grid sx={{}}>
      <div id="popover-anchor"></div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          sx={{
            backgroundColor: (theme) => theme.palette.brand.main,
            color: (theme) => theme.palette.background.paper,
            width: '284px',
            padding: '0.5rem 1rem 1rem 1rem',
            letterSpacing: '0.3px',
          }}
        >
          <IconButton
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              top: '8px',
              right: '16px',
              color: 'inherit',
              '&:hover': {
                color: 'inherit',
              },
            }}
          >
            <IconX />
          </IconButton>
          <Stack spacing={1}>
            <Typography variant="h3">
              <FM defaultMessage="Start with Policy Template" />
            </Typography>
            <Typography variant="body2">
              <FM defaultMessage="Choose 'Vulnerabilities' from the Policy Template drop down for a quick start." />
            </Typography>
          </Stack>
        </Grid>
      </Popover>
    </Grid>
  );
};
