import _sortBy from 'lodash-es/sortBy';
import { useMemo } from 'react';

import {
  PolicyFindingAction,
  PolicyPolicyType,
  SpecFindingLevel,
  V1FindingTags,
} from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PolicyResource,
  useCountFindings,
  useListPolicies,
} from '@endorlabs/queries';

import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../../constants';

/**
 *
 */
export const usePoliciesAndRelatedFindings = ({
  namespace,
  policies,
  policyTypes = [
    PolicyPolicyType.MlFinding,
    PolicyPolicyType.SystemFinding,
    PolicyPolicyType.UserFinding,
  ],
}: {
  namespace: string;
  policies?: PolicyResource[];
  policyTypes?: PolicyPolicyType[];
}) => {
  // If no set of policies is provided, retrieve them
  const qListPolicies = useListPolicies(
    namespace,
    { enabled: !policies },
    { filter: `spec.policy_type in [${policyTypes?.join(',')}]` }
  );

  const workingPolicies = useMemo(() => {
    const workingPolicies =
      policies ?? qListPolicies.data?.list?.objects ?? ([] as PolicyResource[]);

    // Sort by finding severity
    return _sortBy(workingPolicies, [
      (p) => {
        // HACK: ML findings don't have a spec.finding field. Severity is instead specified by spec.finding_level.
        // Copy that value to spec.finding to allow filtering on severity via a single key.
        // This should be a benign workaround, as ML findings cannot be edited.
        if (!p.spec.finding) {
          p.spec.finding = {
            level: p.spec.finding_level as SpecFindingLevel,
          } as PolicyFindingAction;
        }

        switch (p.spec.finding_level) {
          case SpecFindingLevel.Critical:
            return -4;
          case SpecFindingLevel.High:
            return -3;
          case SpecFindingLevel.Medium:
            return -2;
          case SpecFindingLevel.Low:
            return -1;
          default:
            return 0;
        }
      },
    ]);
  }, [policies, qListPolicies.data]);

  const findingsFilter = [
    filterExpressionBuilders.defaultResourceContexts(),
    `spec.finding_tags in ["${V1FindingTags.Policy}"]`,
  ].join(' and ');

  const qCountFindings = useCountFindings(
    namespace,
    { staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS },
    { filter: findingsFilter }
  );

  return {
    getFindingCountForAllPolicies: () => qCountFindings.data?.count,
    policies: workingPolicies,
    totalPolicyCount: workingPolicies.length,
  };
};
