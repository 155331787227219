import { FormattedMessage as FM } from 'react-intl';

import { Endorv1License } from '@endorlabs/api_client';
import { DataTableColumnTypeKeys as ColTypes } from '@endorlabs/ui-common';

import { LicensesTableRow } from '../types';

export const LicensesSelectionTableRegistryEntry = {
  buildColumns: () => {
    return [
      {
        accessorKey: 'license_name',
        enableSorting: true,
        header: () => <FM defaultMessage="License Name" />,
      },
      {
        accessorKey: 'license_id',
        enableSorting: true,
        header: () => <FM defaultMessage="Identifier" />,
      },
      {
        accessorKey: 'is_osi_approved',
        colType: ColTypes.BOOLEAN,
        enableSorting: true,
        header: () => <FM defaultMessage="OSI Approved" />,
      },
    ];
  },

  buildRows: (licenses: Endorv1License[]): LicensesTableRow[] => {
    return licenses.map((license) => ({
      is_osi_approved: license.spec.is_osi_approved ?? false,
      license_name: license.meta.name,
      license_id: license.spec.license_id,
      uuid: license.uuid,
    }));
  },
};
