import { Filter, ValueFilter } from './types';
import { isLogicFilter, isValueFilter } from './utils';

/**
 * Traverse a given Filter, calling a visitor function for each Value Filter
 */
export const walkFilter = (
  filter: Filter,
  options: { onVisit: (valueFilter: ValueFilter) => void }
) => {
  if (isValueFilter(filter)) {
    options.onVisit(filter);
  }

  if (isLogicFilter(filter)) {
    for (const subFilter of filter.value) {
      walkFilter(subFilter, options);
    }
  }
};
