import { SvgIconProps } from '@mui/material';

import LogoCPlusPlus from '../../../assets/languages/logomark_c++.svg';
import LogoObjectiveC from '../../../assets/languages/logomark_objective_c.svg';
import LogoSwift from '../../../assets/languages/logomark_swift.svg';
import LogoAWS from '../../../assets/login/aws.svg';
import LogoGoogle from '../../../assets/login/google.svg';
import LogoMarkGoogleCloud from '../../../assets/login/logomark_gcp.svg';
import LogoMicrosoft from '../../../assets/login/logomark_microsoft.svg';
import LogoMarkAzure from '../../../assets/logomark_azure.svg';
import LogoBitbucket from '../../../assets/logomark_bitbucket.svg';
import LogoCocoaPods from '../../../assets/logomark_cocoapods.svg';
import LogoCyclone from '../../../assets/logomark_cyclone.svg';
import LogoDebian from '../../../assets/logomark_debian.svg';
import LogoDocker from '../../../assets/logomark_docker.svg';
import LogoMarkGitHub from '../../../assets/logomark_github.svg';
import LogoGitHubAction from '../../../assets/logomark_github_action.svg';
import LogoMarkGitLab from '../../../assets/logomark_gitlab.svg';
import LogoGo from '../../../assets/logomark_go.svg';
import LogoHuggingFace from '../../../assets/logomark_hugging_face.svg';
import LogoMarkJira from '../../../assets/logomark_jira.svg';
import LogoMaven from '../../../assets/logomark_maven.svg';
import LogoNPM from '../../../assets/logomark_npm.svg';
import LogoNuget from '../../../assets/logomark_nuget.svg';
import LogoPackagist from '../../../assets/logomark_packagist.svg';
import LogoPypi from '../../../assets/logomark_pypi.svg';
import LogoRubyGems from '../../../assets/logomark_rubygems.svg';
import LogoRust from '../../../assets/logomark_rust.svg';
import LogoSlack from '../../../assets/logomark_slack.svg';
import LogoVanta from '../../../assets/logomark_vanta.svg';
import createImgIcon from './createImgIcon';

export type ImgIcon = React.MemoExoticComponent<
  React.ForwardRefExoticComponent<
    Omit<SvgIconProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >
>;

export const ImgIconLogoAWS = createImgIcon(LogoAWS, 'LogoAWS');
export const ImgIconLogoMarkBitbucket = createImgIcon(
  LogoBitbucket,
  'LogoBitbucket'
);
export const ImgIconLogoCocoapods = createImgIcon(
  LogoCocoaPods,
  'LogoCocoaPods'
);
export const ImgIconLogoCPlusPlus = createImgIcon(
  LogoCPlusPlus,
  'LogoCPlusPlus'
);
export const ImgIconLogoMarkCyclone = createImgIcon(LogoCyclone, 'LogoCyclone');
export const ImgIconLogoMarkDebian = createImgIcon(LogoDebian, 'LogoDebian');
export const ImgIconLogoMarkDocker = createImgIcon(LogoDocker, 'LogoDocker');
export const ImgIconLogoMarkGitHub = createImgIcon(
  LogoMarkGitHub,
  'LogoMarkGitHub'
);
export const ImgIconLogoGitHubAction = createImgIcon(
  LogoGitHubAction,
  'LogoGitHubAction'
);
export const ImgIconLogoMarkGitLab = createImgIcon(
  LogoMarkGitLab,
  'LogoMarkGitLab'
);
export const ImgIconLogoMarkAzure = createImgIcon(
  LogoMarkAzure,
  'LogoMarkAzure'
);
export const ImgIconLogoGo = createImgIcon(LogoGo, 'LogoGo');
export const ImgIconLogoGoogle = createImgIcon(LogoGoogle, 'LogoGoogle');
export const ImgIconLogoMarkGoogleCloud = createImgIcon(
  LogoMarkGoogleCloud,
  'LogoMarkGoogleCloud'
);
export const ImgIconLogoHuggingFace = createImgIcon(
  LogoHuggingFace,
  'LogoHuggingFace'
);
export const ImgIconLogoMarkJira = createImgIcon(LogoMarkJira, 'LogoMarkJira');
export const ImgIconLogoMaven = createImgIcon(LogoMaven, 'LogoMaven');
export const ImgIconLogoMicrosoft = createImgIcon(
  LogoMicrosoft,
  'LogoMicrosoft'
);
export const ImgIconLogoNPM = createImgIcon(LogoNPM, 'LogoNPM');
export const ImgIconLogoNuget = createImgIcon(LogoNuget, 'LogoNuget');
export const ImgIconLogoObjectiveC = createImgIcon(
  LogoObjectiveC,
  'LogoObjectiveC'
);
export const ImgIconLogoPackagist = createImgIcon(
  LogoPackagist,
  'LogoPackagist'
);
export const ImgIconLogoPypi = createImgIcon(LogoPypi, 'LogoPypi');
export const ImgIconLogoRubyGems = createImgIcon(LogoRubyGems, 'LogoRubyGems');
export const ImgIconLogoRust = createImgIcon(LogoRust, 'LogoRust');
export const ImgIconLogoSlack = createImgIcon(LogoSlack, 'LogoSlack');
export const ImgIconLogoSwift = createImgIcon(LogoSwift, 'LogoSwift');
export const ImgIconLogoVanta = createImgIcon(LogoVanta, 'LogoVanta');
