/**
 * Validate a string as a commit SHA.
 */
export const REGEX_COMMIT_SHA_VALIDATION = /^[0-9a-f]{40}$/;

/**
 * Validation for Endor namespaces.
 */
export const REGEX_NAMESPACE_VALIDATION = /^[0-9a-z\-_]{1,32}$/;

/**
 * Email address validation is of course non-trivial & beyond the capabilities of one regex.
 * This does the job for the vast majority of use cases. Taken from https://emailregex.com
 * NOTE: NOT I18n.
 */
export const REGEX_EMAIL_VALIDATION =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * Taken from https://uibakery.io/regex-library/date
 */
export const REGEX_DATE_ISO_VALIDATION =
  /^(?:\d{4})-(?:\d{2})-(?:\d{2})T(?:\d{2}):(?:\d{2}):(?:\d{2}(?:\.\d*)?)(?:(?:-(?:\d{2}):(?:\d{2})|Z)?)$/;

export const REGEX_TAG_VALIDATION =
  /^(([A-Za-z0-9][-A-Za-z0-9_.=]*)?[-A-Za-z0-9@_.])+$/;

/**
 * Taken from https://uibakery.io/regex-library/url
 */
export const REGEX_URL_VALIDATION =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

/**
 * Taken from https://stackoverflow.com/a/50484916/458940
 */
export const REGEX_S3_BUCKET_VALIDATION =
  /^(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/;

export const REGEX_HTTP_STATUS_CODE_VALIDATION = /^[1-5][0-9][0-9]$/;

/*Regex read from https://github.com/miguelmota/is-valid-hostname*/
export const REGEX_HOSTNAME_VALIDATION = /^[a-zA-Z0-9-.]{1,253}$/;
