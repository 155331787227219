import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import _sortBy from 'lodash-es/sortBy';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListMetrics } from '@endorlabs/queries';
import { IconHelp, ScoreCategorySelect } from '@endorlabs/ui-common';

import { InfoDrawerContent } from '../../../../components/InfoDrawer';
import { ScoreFactorsList } from './ScoreFactorsList';

const METRIC_NAMES = ['package_version_scorecard', 'version_scorecard'];

export interface ScoreFactorsDrawerProps {
  resourceNamespace?: string;
  resourceUuid?: string;
}

export const ScoreFactorsDrawer = ({
  resourceNamespace,
  resourceUuid,
}: ScoreFactorsDrawerProps) => {
  const { palette } = useTheme();
  const [activeMetricCategory, setActiveMetricCategory] = useState<
    V1ScoreCategory | undefined
  >(undefined);

  const qListMetrics = useListMetrics(
    resourceNamespace as string,
    { enabled: !!resourceNamespace && !!resourceUuid },
    {
      filter: filterExpressionBuilders.and([
        `meta.parent_uuid=="${resourceUuid}"`,
        `meta.name in ["${METRIC_NAMES.join('","')}"]`,
      ]),
      mask: 'spec.metric_values',
      page_size: METRIC_NAMES.length,
      traverse: false,
    }
  );

  const [scoreCard, scoreFactors] = useMemo(() => {
    const metricValues =
      qListMetrics.data?.list?.objects[0]?.spec.metric_values;

    const scoreCard = metricValues?.scorecard.score_card ?? undefined;
    const scoreFactors = _sortBy(
      metricValues?.scorefactor?.score_factor_list?.score_factors ?? [],
      ['score']
    );

    return [scoreCard, scoreFactors];
  }, [qListMetrics.data]);

  return (
    <InfoDrawerContent title={<FM defaultMessage="Score Factors" />}>
      <Stack direction="column" spacing={4}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" spacing={2}>
            <Typography variant="h6">
              <FM defaultMessage="Score Factors" />
            </Typography>

            <Tooltip
              title={
                <FM defaultMessage="Dependencies are not currently used to calculate scores" />
              }
            >
              <IconHelp
                htmlColor={palette.text.secondary}
                fontSize="small"
                sx={{ '&:hover': { cursor: 'pointer' } }}
              ></IconHelp>
            </Tooltip>
          </Stack>

          <ScoreCategorySelect
            activeCategory={activeMetricCategory}
            onChange={(_, category) => setActiveMetricCategory(category)}
          />
        </Stack>

        <ScoreFactorsList
          isLoading={qListMetrics.isLoading}
          scoreCard={scoreCard}
          scoreCategory={activeMetricCategory}
          scoreFactors={scoreFactors}
        />
      </Stack>
    </InfoDrawerContent>
  );
};
