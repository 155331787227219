import { Box, CardHeader, IconButton, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { IconX } from '@endorlabs/ui-common';

import { useInfoDrawer } from './useInfoDrawer';

const INFO_DRAWER_PADDING_X = 6;
const INFO_DRAWER_PADDING_Y = 10;

export interface InfoDrawerContentProps {
  children?: ReactNode;
  title?: ReactNode;
}

/**
 * Generic component wrapper for InfoDrawer content.
 */
export const InfoDrawerContent = ({
  children,
  title,
}: InfoDrawerContentProps) => {
  const InfoDrawerState = useInfoDrawer();

  return (
    <Stack className="InfoDrawerContent-root">
      <CardHeader
        action={
          <IconButton
            className="InfoDrawerContent-close"
            onClick={InfoDrawerState.close}
          >
            <IconX fontSize="inherit" />
          </IconButton>
        }
        sx={{
          marginBottom: 2,
          paddingTop: INFO_DRAWER_PADDING_Y,
          paddingX: INFO_DRAWER_PADDING_X,
          '& .MuiCardHeader-root': { alignItems: 'flex-start' },
          '& .MuiCardHeader-action .MuiIconButton-root': {
            padding: 0,
            marginLeft: ({ spacing }) => spacing(2),
            marginTop: ({ spacing }) => spacing(1),
          },
        }}
        title={title}
      />
      <Box
        sx={{
          paddingX: INFO_DRAWER_PADDING_X,
          paddingBottom: INFO_DRAWER_PADDING_Y,
        }}
      >
        {children}
      </Box>
    </Stack>
  );
};
