import { Box, BoxProps, Stack, StackProps } from '@mui/material';

export type FlexListProps = Pick<
  StackProps,
  | 'children'
  | 'className'
  | 'component'
  | 'direction'
  | 'divider'
  | 'flexWrap'
  | 'gap'
  | 'justifyContent'
>;

/**
 * Simple, flex-based List component. Intended for use as a lightweight replacement for Mui List/ListItem
 *
 * @example
 * <FlexList divider={<FlexListItem>&middot;</FlexListItem>}>
 *  <FlexListItem>Item One</FlexListItem>
 *  <FlexListItem>Item Two</FlexListItem>
 * </FlexList>
 */
export const FlexList = ({
  children,
  className,
  component = 'ul',
  direction = 'row',
  divider,
  flexWrap = 'wrap',
  gap = 2,
  justifyContent,
}: FlexListProps) => (
  <Stack
    className={className}
    component={component}
    direction={direction}
    divider={divider}
    flexWrap={flexWrap}
    gap={gap}
    justifyContent={justifyContent}
    sx={[
      { listStyle: 'none', margin: 0, padding: 0 },
      direction === 'row' && { alignItems: 'center' },
    ]}
    role="list"
  >
    {children}
  </Stack>
);

export const FlexListItem = ({ children, ...props }: BoxProps<'li'>) => (
  <Box {...props} component="li" role="listitem">
    {children}
  </Box>
);
