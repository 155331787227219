import { css, Global } from '@emotion/react';

import InterVarFont from '../../../assets/fonts/Inter.var.woff2';
import SwitzerFont from '../../../assets/fonts/Switzer-Variable.woff2';

/**
 * Provides global @font-face declarations & paths to static font files.
 */
export const FontEmbeds = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Switzer';
          font-weight: 100 900;
          font-display: swap;
          font-style: normal;
          src: url('${SwitzerFont}') format('woff2 supports variations'),
            url('${SwitzerFont}') format('woff2-variations');
        }

        @font-face {
          font-family: 'Inter';
          font-weight: 100 900;
          font-display: swap;
          font-style: oblique 0deg 10deg;
          src: url('${InterVarFont}') format('woff2 supports variations'),
            url('${InterVarFont}') format('woff2-variations');
        }
        @font-face {
          font-family: 'Switzer';
          font-weight: 100 900;
          font-display: swap;
          font-style: oblique 0deg 10deg;
          src: url('${SwitzerFont}') format('woff2 supports variations'),
            url('${SwitzerFont}') format('woff2-variations');
        }
      `}
    />
  );
};
