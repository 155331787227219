import { Stack, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ControlledLabelsField,
  LabelsFieldValidationType,
} from '@endorlabs/ui-common';

export const PolicyScopeFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control } = useFormContext();

  return (
    <Stack alignItems="flex-start" spacing={space.md} width="100%">
      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        fullWidth
        label={<FM defaultMessage="Inclusions" />}
        name="spec.project_selector"
        helperText={fm({
          defaultMessage: 'Include only projects with the following tags.',
        })}
        validation={LabelsFieldValidationType.MetaTag}
      />

      <ControlledLabelsField
        control={control}
        defaultValue={[]}
        fullWidth
        label={<FM defaultMessage="Exclusions" />}
        name="spec.project_exceptions"
        helperText={fm({
          defaultMessage:
            'Exclude projects with the following tags. This will take precedence over inclusions.',
        })}
        validation={LabelsFieldValidationType.MetaTag}
      />
    </Stack>
  );
};
