import {
  Badge,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import _noop from 'lodash-es/noop';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { Link } from '@endorlabs/ui-common';

import { Layout } from '../../constants';
import { AppDrawerNavItemProps, DisplayStatus } from './types';

export const AppDrawerNavItemV2 = ({
  appDrawerMinimized,
  badgeContent,
  Icon,
  isActive = false,
  isBeta = false,
  label,
  navKey,
  url,
  displayStatus,
}: AppDrawerNavItemProps) => {
  const sx = styles(useTheme());
  const { formatMessage: fm } = useIntl();

  const icon = <Icon fontSize="medium" />;

  const cls = clsx({
    isActive: isActive,
  });

  const labelDisplay =
    isBeta || Boolean(badgeContent) ? (
      <Badge
        badgeContent={isBeta ? <FM defaultMessage="Beta" /> : badgeContent}
        color={isBeta ? 'warning' : 'error'}
        sx={{
          '& .MuiBadge-badge': {
            fontSize: 10,
            right: -16,
            top: 2,
            textTransform: 'uppercase',
          },
        }}
      >
        {label}
      </Badge>
    ) : (
      label
    );

  return (
    <Tooltip title={appDrawerMinimized ? label : ''} placement="right" arrow>
      <>
        {!displayStatus || displayStatus !== DisplayStatus.Deactivate ? (
          <ListItemButton
            component={Link}
            data-testid={`AppDrawerNavItem-${navKey}`}
            onClick={_noop}
            className={cls}
            sx={sx.activeBtn}
            to={url}
          >
            <ListItemAvatar sx={sx.avatar}>{icon}</ListItemAvatar>
            {!appDrawerMinimized && <ListItemText primary={labelDisplay} />}
          </ListItemButton>
        ) : (
          <ListItemButton
            component={Link}
            onClick={_noop}
            className={cls}
            sx={sx.inactiveButton}
            to={url}
          >
            <ListItemAvatar sx={sx.inactiveAvatar}>{icon}</ListItemAvatar>
            {!appDrawerMinimized && (
              <Stack>
                <ListItemText primary={labelDisplay} sx={{ margin: 0 }} />
                <ListItemText
                  secondary={<FM defaultMessage="License Required" />}
                  sx={{ margin: 0 }}
                />
              </Stack>
            )}
          </ListItemButton>
        )}
      </>
    </Tooltip>
  );
};

const styles = ({ breakpoints, palette }: Theme) => {
  const commonBtn = {
    paddingX: 6,
    paddingY: 1.5,

    '&:hover': {
      backgroundColor: palette.background.dark,
    },
    '&.isActive': {
      backgroundColor: palette.background.dark,

      // Add pointer to right side
      '&::after': {
        content: '""',
        borderRadius: '1px',
        borderStyle: 'solid',
        borderWidth: '9px',
        borderColor: `transparent ${palette.background.default} transparent transparent`,
        position: 'absolute',
        right: '-1px',
      },
    },
    [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
      paddingLeft: 6,
      paddingRight: 2,
    },
  };
  return {
    activeBtn: {
      ...commonBtn,
    },
    inactiveButton: {
      ...commonBtn,
      opacity: 0.6,
      'span, p': {
        color: palette.status.pending,
      },
    },
    inactiveAvatar: {
      color: palette.status.pending,
      display: 'flex',
      minWidth: '36px',
    },

    avatar: {
      color: palette.grey[200],
      display: 'flex',
      minWidth: '36px',
    },
  };
};
