import { FormattedMessage as FM } from 'react-intl';

import { SecretRuleResource } from '@endorlabs/queries';

import { DataTableRegistryEntry } from '../../Policies/constants/DataTableRegistry';

interface SecretRulesTableRow {
  secret_rule_name?: string;
  secret_rule_id?: string;
}

export const SecretRulesSelectionTableRegistryEntry: DataTableRegistryEntry<
  SecretRuleResource,
  SecretRulesTableRow
> = {
  // @ts-expect-error - accessorFn complaint. See https://github.com/TanStack/table/issues/4241
  buildColumns: () => {
    return [
      {
        accessorKey: 'secret_rule_name',
        enableSorting: true,
        header: () => <FM defaultMessage="Secrets Rule Name" />,
      },
      {
        accessorKey: 'secret_rule_id',
        enableSorting: true,
        header: () => <FM defaultMessage="Identifier" />,
      },
    ];
  },

  buildRows: (secret_rules: SecretRuleResource[]): SecretRulesTableRow[] => {
    return secret_rules.map((secret_rule) => ({
      secret_rule_name: secret_rule.spec.description,
      secret_rule_id: secret_rule.spec.rule_id,
    }));
  },
};
