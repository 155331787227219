import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';
import { FilterExpression, filterExpressionBuilders } from '@endorlabs/filters';
import { buildQueryCall, isQueryError } from '@endorlabs/queries';

import { FilterValidationError } from '../types';

const messages = defineMessages({
  defaultErrorTitle: { defaultMessage: 'Unable to validate filter expression' },
  filterSyntaxErrorTitle: {
    defaultMessage:
      'The filter expression has syntax errors that need to be corrected',
  },
  timeoutErrorDetails: {
    defaultMessage: 'The request timed out attempting to load.',
  },
});

type UseResourceFilterValidationResult = {
  isValidating: boolean;
  error?: FilterValidationError;
};

/**
 * Provides validation for a given resource under a namespaces
 */
export const useResourceFilterValidation = (
  namespace: string,
  resourceKind: ResourceKind,
  filterExpression: FilterExpression,
  options?: { enabled?: boolean }
): UseResourceFilterValidationResult => {
  const { formatMessage: fm } = useIntl();

  // filter expression validation
  const qFilterValidation = buildQueryCall(resourceKind, {
    filter: filterExpressionBuilders.and([
      // HACK: decrease the expense of the query
      filterExpressionBuilders.mainResourceContext(),
      filterExpression,
    ]),
    count: true,
  }).useBuiltQuery(namespace, {
    enabled: options?.enabled,
    retry: false,
  });

  const filterValidationError = useMemo(() => {
    if (qFilterValidation.error && isQueryError(qFilterValidation.error)) {
      const errorResponse = qFilterValidation.error.response;
      const errorMessage = errorResponse.data?.message ?? '';

      const isSyntaxError =
        errorMessage.includes('bad filter') ||
        errorMessage.includes('filter keys');

      if (isSyntaxError) {
        const details = errorMessage
          .replaceAll(/&#39;/g, `'`)
          .replaceAll(/&#34;/g, `"`)
          .replaceAll(/&lt;/g, `<`)
          .replaceAll(/&gt;/g, `>`);
        return {
          message: fm(messages.filterSyntaxErrorTitle),
          details,
          isSyntaxError,
        };
      }

      let errorDetails = errorMessage;
      if (errorMessage.includes('context deadline exceeded')) {
        errorDetails = fm(messages.timeoutErrorDetails);
      }

      return {
        message: fm(messages.defaultErrorTitle),
        details: errorDetails,
        isSyntaxError,
      };
    }
  }, [fm, qFilterValidation.error]);

  return {
    isValidating: qFilterValidation.isFetching,
    error: filterValidationError,
  };
};
