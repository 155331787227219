import { LinearProgress, Theme, Tooltip, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCategory } from '@endorlabs/api_client';

import { getScoreCategoryLabel } from '../../domains/Metrics';

interface ScoreDisplayBarProps {
  category?: V1ScoreCategory;
  value?: number;
}

export const ScoreDisplayBar = ({ category, value }: ScoreDisplayBarProps) => {
  const sx = styles(useTheme());

  if (value === undefined) {
    return (
      <Tooltip
        title={
          <FM defaultMessage="Endor Score unavailable for this category" />
        }
      >
        <LinearProgress sx={sx.nil} value={0} variant="determinate" />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      title={
        <FM
          defaultMessage="{score} / 10 Endor {category} Score"
          values={{
            score: Math.round(value),
            category: getScoreCategoryLabel(category),
          }}
        />
      }
    >
      <LinearProgress
        sx={sx.root}
        value={value >= 10 ? 100 : value * 10}
        variant="determinate"
      />
    </Tooltip>
  );
};

const styles = ({ palette, spacing }: Theme) => ({
  root: {
    cursor: 'pointer',
    width: spacing(7),

    '&.MuiLinearProgress-root': {
      borderRadius: '4px',
      width: spacing(7),
    },
    '&.MuiLinearProgress-bar1Determinate': {
      borderRadius: '4px',
    },

    '.scoreBad &': {
      '&.MuiLinearProgress-root': {
        backgroundColor: alpha(palette.error.light, 0.4),
      },
      '.MuiLinearProgress-bar1Determinate': {
        backgroundColor: palette.error.light,
      },
    },
    '.scoreAverage &': {
      '&.MuiLinearProgress-root': {
        backgroundColor: alpha(palette.warning.light, 0.4),
      },
      '.MuiLinearProgress-bar1Determinate': {
        backgroundColor: palette.warning.light,
      },
    },
    '.scoreGood &': {
      '&.MuiLinearProgress-root': {
        backgroundColor: alpha(palette.success.light, 0.4),
      },
      '.MuiLinearProgress-bar1Determinate': {
        backgroundColor: palette.success.light,
      },
    },
  },

  nil: {
    cursor: 'pointer',
    width: spacing(7),

    '&.MuiLinearProgress-root': {
      backgroundColor: palette.grey[200],
    },
    '&.MuiLinearProgress-bar1Determinate': {
      backgroundColor: palette.grey[200],
    },
  },
});
