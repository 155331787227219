import {
  Grid,
  Skeleton,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  flexRender,
  getCoreRowModel,
  Row,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import noop from 'lodash-es/noop';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { buildDataTableColumnDefs } from '../DataTable';
import { DataTableSkeletonRows } from '../DataTable/DataTableSkeletonRows';
import { DataTableColumnDef, DataTableRowData } from '../DataTable/types';
import { EmptyState, EmptyStateProps } from '../EmptyState';
import { DEFAULT_PAGE_SIZE } from './constants';
import { DraggableTableRow, DragItem } from './DraggableTableRow';

export interface OrderableTableProps<T extends DataTableRowData> {
  /**
   * Content area above table content and right-aligned. Typically used for controls.
   */
  actions?: ReactNode;
  /**
   * ReactTable `columns`
   */
  columns: DataTableColumnDef<T>[];
  /**
   * ReactTable `data`
   */
  data: T[];
  /**
   * Allow the table to display an empty state when no data present
   */
  emptyStateProps?: EmptyStateProps;
  /**
   * Displays loading state for table
   */
  isLoading?: boolean;
  /**
   * Callback when a drag and drop reorder action has completed
   */
  onReorderComplete?: (
    rows: Row<T>[],
    droppedRow: Row<T>,
    newIndex: number
  ) => void;
  /**
   * Forces table to a fixed height & enables vertical scrolling of table rows.
   */
  maxHeight?: number;
  /**
   * Content area above table content & left-aligned. Typically a header element.
   */
  title?: ReactNode;
}

/**
 * Simplified, general purpose data table/grid with drag and drop reordering.
 * Functionality powered by https://tanstack.com/table/v8
 * Markup/styles rely on https://mui.com/material-ui/react-table/
 *
 * Allows for title and action content sections similar to `CardHeader`.
 */
export function OrderableTable<T extends DataTableRowData>({
  actions = null,
  columns,
  data = [],
  emptyStateProps,
  isLoading = false,
  maxHeight,
  onReorderComplete = noop,
  title,
}: OrderableTableProps<T>) {
  const memoizedColumns = useMemo(() => {
    return buildDataTableColumnDefs(columns) as DataTableColumnDef<T>[];
  }, [columns]);

  const memoizedData = useMemo(() => data, [data]);

  const calcMaxHeight = maxHeight ? `${maxHeight}px` : undefined;

  // build the table props from the component props
  const tableDefaultProps: Partial<TableOptions<T>> = useMemo(() => {
    return {
      enableMultiSort: false,
      manualPagination: true,
      defaultColumn: {
        enableSorting: false,
        sortDescFirst: true,
        sortUndefined: 1,
      },
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: DEFAULT_PAGE_SIZE,
        },
        columnVisibility: {},
      },
    };
  }, []);

  // get the table instance
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: memoizedData,
    columns: memoizedColumns,
    ...tableDefaultProps,
  });

  // get the rows
  const { rows } = table.getRowModel();

  /**
   * ==== REORDER ====
   *
   * Maintain a local copy of page row records for use in reordering.
   * Note that enabling reordering removes pagination.
   */
  const [reorderedRowRecords, setReorderedRowRecords] = useState<Row<T>[]>([]);
  const isReordered = reorderedRowRecords.length > 0;

  const finalRowRecords = isReordered ? reorderedRowRecords : rows;

  /* Custom callback providing the reordered rows and details of dragged row */
  const wrappedReorderComplete = (dragItem: DragItem) => {
    onReorderComplete(
      finalRowRecords,
      finalRowRecords[dragItem.index],
      dragItem.index
    );
  };

  /**
   * If the table receives brand new records, reset locally-maintained row order.
   * Typically a re-orderable table triggers new data upon a drop operation,
   * & that new data represents the new row order. But watch for unexpected data refreshes.
   */
  useEffect(() => {
    setReorderedRowRecords([]);
  }, [memoizedData]);

  /* Performs dynamic reordering of records during drag */
  const reorderRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newRowRecords: Row<T>[] = isReordered
        ? [...reorderedRowRecords]
        : [...rows];
      const removedRecord = newRowRecords.splice(dragIndex, 1)[0];
      newRowRecords.splice(hoverIndex, 0, removedRecord);

      setReorderedRowRecords(newRowRecords);
    },
    [isReordered, reorderedRowRecords, rows]
  );

  const reorderRowProps = {
    reorderFn: reorderRow,
    onReorderComplete: wrappedReorderComplete,
  };

  /**
   * ==== DISPLAY ====
   *
   * Establish content/controls that should not be displayed
   */

  const showHeader = title || actions;

  const showEmptyState =
    !!emptyStateProps && !isLoading && !memoizedData.length;

  return (
    <DndProvider backend={HTML5Backend}>
      <Grid container direction="column" spacing={4}>
        {showHeader && (
          <Grid
            alignItems="center"
            container
            item
            justifyContent="space-between"
            xs={12}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2">{title}</Typography>
            </Grid>

            <Grid
              alignItems="center"
              container
              item
              justifyContent="flex-end"
              xs={12}
              md={6}
            >
              <Grid item>{actions}</Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} sx={{ width: '100%' }}>
          <TableContainer
            sx={{
              borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
              maxHeight: calcMaxHeight,
            }}
          >
            <MuiTable stickyHeader>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {/* Add extra table header cell to accomodate drag handle column */}
                    <TableCell />

                    {headerGroup.headers.map((header) => (
                      <TableCell key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : isLoading ? (
                          <Skeleton height={38} />
                        ) : (
                          <Stack alignItems="center" direction="row" gap={2}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Stack>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody>
                {isLoading ? (
                  <DataTableSkeletonRows
                    columnCount={table.getHeaderGroups()[0].headers.length}
                  />
                ) : (
                  finalRowRecords.map((row, i) => (
                    <DraggableTableRow
                      hover
                      index={i}
                      key={row.id}
                      {...reorderRowProps}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </DraggableTableRow>
                  ))
                )}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </Grid>
      </Grid>

      {showEmptyState && <EmptyState {...emptyStateProps} />}
    </DndProvider>
  );
}
