import { List } from '@mui/material';

import {
  PageNavigationGroupProps,
  PageNavigationItem,
  PageNavigationListRenderer,
} from './PageNavigationGroup';
import { PageNavigationListItemProps } from './PageNavigationListItem';

export const isPageNavigationGroupItem = (
  value: PageNavigationItem
): value is PageNavigationGroupProps => {
  return Reflect.has(value, 'groupItems');
};

interface PageNavigationProps {
  handleSelection: (navKey: string) => void;
  items: PageNavigationItem[];
  selectedItemKey?: string;
}

export const PageNavigation = ({
  handleSelection,
  items,
  selectedItemKey,
}: PageNavigationProps) => {
  return (
    <List className="PageNavigation-root">
      {items.map((item, index) => (
        <PageNavigationListRenderer
          key={(item as PageNavigationListItemProps).navKey ?? index}
          {...item}
          selectedItemKey={selectedItemKey}
          handleSelection={handleSelection}
        />
      ))}
    </List>
  );
};
