import { Typography } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { ReactNode, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { ContextContextType } from '@endorlabs/api_client';
import {
  isContainerProject,
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { useListAllProjectVersions } from '@endorlabs/queries';
import {
  ImgIconLogoMarkDocker,
  ProjectNameDisplay,
  REGEX_COMMIT_SHA_VALIDATION,
  UIPackageVersionUtils,
  UIProjectUtils,
  useLatestCallback,
} from '@endorlabs/ui-common';

import { PageHeader, useScanIssuesDetailDrawer } from '../../../components';
import { getProjectPath, getProjectVersionDetailPath } from '../../../routes';
import { ProjectVersionBreadcrumb } from '../../../views';
import { ContainerNameDisplay } from '../../Containers';
import { useProjectVersionMetadata } from '../hooks';

type ProjectHeaderProps = {
  action?: ReactNode;
  project: ProjectResource;
  projectVersion?: ProjectVersionResource;
};

export const ProjectPageHeader = ({
  action,
  project,
  projectVersion,
}: ProjectHeaderProps) => {
  const navigate = useNavigate();

  // Fetch Project metadata and related versions
  const { packageEcosystems, latestScanResult, summary } =
    useProjectVersionMetadata(project, projectVersion);
  const qListProjectVersions = useListAllProjectVersions({ project });

  const { DetailDrawer: ScanIssuesDetailDrawer } = useScanIssuesDetailDrawer();
  const handleStatusIndicatorClick = useLatestCallback(() =>
    ScanIssuesDetailDrawer.activate(
      {
        contextId: projectVersion?.context?.id,
        contextType: projectVersion?.context?.type,
        namespace: project.tenant_meta.namespace,
        projectUuid: project.uuid,
      },
      {
        namespace: project.tenant_meta.namespace,
        projectUuid: project.uuid,
        scanContext: projectVersion?.context,
        scanResults: latestScanResult ? [latestScanResult] : undefined,
      }
    )
  );

  const projectName = UIProjectUtils.parseProjectName(
    project.meta.name,
    project.spec.platform_source
  );
  const PlatformIcon = UIProjectUtils.getPlatformIcon(
    project.spec.platform_source
  );
  const ProjectIcon = isContainerProject(project, packageEcosystems)
    ? ImgIconLogoMarkDocker
    : PlatformIcon;

  const projectBreadcrumbItems = useMemo(() => {
    const items = [];

    if (isContainerProject(project, packageEcosystems)) {
      items.push(
        <Typography component="div" variant="button">
          <ContainerNameDisplay name={project.meta.name} showIcon />
        </Typography>
      );
    } else {
      items.push(
        <ProjectNameDisplay
          key={projectName}
          name={project.meta.name}
          platformSource={project.spec.platform_source}
          size="medium"
        />
      );
    }

    if (projectVersion) {
      const relatedVersions = qListProjectVersions.data ?? [];
      items.push(
        <ProjectVersionBreadcrumb
          getLabelFn={getProjectVersionLabel}
          isLoading={qListProjectVersions.isLoading}
          onChange={(v) => {
            const path = getProjectVersionDetailPath({
              tenantName: project.tenant_meta.namespace,
              uuid: project.uuid,
              versionRef: v.context.id,
            });
            navigate({ to: path });
          }}
          onStatusClick={handleStatusIndicatorClick}
          resourceList={relatedVersions}
          scanResults={latestScanResult ? [latestScanResult] : undefined}
          selectedResource={projectVersion}
        />
      );
    }

    return items;
  }, [
    handleStatusIndicatorClick,
    latestScanResult,
    navigate,
    packageEcosystems,
    project,
    projectName,
    projectVersion,
    qListProjectVersions.data,
    qListProjectVersions.isLoading,
  ]);

  const breadcrumbsLinks = useMemo(() => {
    const projectNamespace = project.tenant_meta.namespace;
    const projectUuid = project.uuid;

    const links = [
      {
        label: <FM defaultMessage="All Projects" />,
        url: getProjectPath({ tenantName: projectNamespace }),
      },
    ];

    if (projectVersion) {
      links.push({
        label: isContainerProject(project, packageEcosystems) ? (
          <FM defaultMessage="Project Versions" />
        ) : (
          <FM defaultMessage="Project Detail" />
        ),
        url: getProjectPath({
          tenantName: projectNamespace,
          uuid: projectUuid,
        }),
      });
    }
    return links;
  }, [project, projectVersion, packageEcosystems]);

  return (
    <PageHeader
      action={action}
      breadcrumbsItems={projectBreadcrumbItems}
      breadcrumbsLinks={breadcrumbsLinks}
      Icon={ProjectIcon}
      title={projectName}
      metadata={projectVersion && { summary }}
    />
  );
};

function getProjectVersionLabel(resource?: ProjectVersionResource): string {
  let versionRef = resource?.context.id ?? '';

  // Handle: PackageVersion as Project version
  if (resource?.meta.kind === 'RepositoryVersion') {
    versionRef = resource?.meta.name;
  } else if (resource?.meta.kind === 'PackageVersion') {
    const parsed = UIPackageVersionUtils.parsePackageName(resource.meta.name);
    versionRef = parsed.version;
  }

  // shorten commit sha display
  if (REGEX_COMMIT_SHA_VALIDATION.test(versionRef)) {
    versionRef = versionRef.slice(0, 7);
  }

  if (resource?.context.type === ContextContextType.Ref) {
    let contextId = resource?.context.id ?? '';

    if (REGEX_COMMIT_SHA_VALIDATION.test(contextId)) {
      contextId = contextId.slice(0, 7);
    }

    if (versionRef !== contextId) {
      return `${versionRef} (${contextId})`;
    }
  }

  return versionRef;
}
