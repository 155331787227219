import { QueryKey, useQuery } from 'react-query';

import { ToolPatternServiceApi, V1ListParameters } from '@endorlabs/api_client';
import {
  ListAllRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import {
  ResourceQueryOptions,
  ToolPatternResource,
  ToolPatternResourceList,
} from './types';
import {
  buildListParamArgs,
  getClientConfiguration,
  listAllResource,
} from './utils';

type GetToolPatternOptions = ResourceQueryOptions<ToolPatternResource>;
type ListToolPatternsOptions = ResourceQueryOptions<ToolPatternResourceList>;
type ListAllToolPatternsOptions = ResourceQueryOptions<ToolPatternResource[]>;

const BASE_KEY = 'v1/tool-patterns';
const QK = {
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  listAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list-all', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};

export const ToolPatternQueryKeys = QK;

const getApiService = () => new ToolPatternServiceApi(getClientConfiguration());

export const listToolPatterns = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.toolPatternServiceListToolPatterns(
    namespace,
    ...buildListParamArgs(listParams),
    { signal }
  );
  return resp.data as ToolPatternResourceList;
};

export const useListToolPatterns = (
  namespace: string,
  opts: ListToolPatternsOptions = {},
  listParams?: ListRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ToolPattern',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.list(namespace, requestParameters),
    () => listToolPatterns(namespace, requestParameters),
    opts
  );
};

export const useListAllToolPatterns = (
  namespace: string,
  opts: ListAllToolPatternsOptions = {},
  listParams: ListAllRequestParameters
) => {
  const requestParameters = useBuildReadRequestParameters(
    'ToolPattern',
    'LIST_ALL',
    listParams,
    opts
  );

  return useQuery(
    QK.listAll(namespace, requestParameters),
    (ctx) =>
      listAllResource<ToolPatternResource, ToolPatternResourceList>(
        (pageToken) => {
          const pageListParams: V1ListParameters = {
            // take base params, and add the page param
            ...requestParameters,
            page_token: pageToken,
          };

          return listToolPatterns(namespace, pageListParams, ctx.signal);
        },
        { signal: ctx.signal }
      ),
    opts
  );
};

const getToolPattern = async (namespace: string, toolUuid: string) => {
  const api = getApiService();
  const resp = await api.toolPatternServiceGetToolPattern(namespace, toolUuid);
  return resp.data as ToolPatternResource;
};

export const useGetToolPattern = (
  namespace: string,
  toolUuid: string,
  opts: GetToolPatternOptions = {}
) => {
  return useQuery(
    QK.record(namespace, toolUuid),
    () => getToolPattern(namespace, toolUuid),
    opts
  );
};
