import { getEnv } from '../../../constants';
import { getProjectDetailPath } from '../../../routes';
import { BasicTourStepProps } from '../types';
import { clickOn, waitForElementAvailable } from '../utils';

const ENV = getEnv();

export const getRemediationsStep = ({
  navigate,
  tourData,
}: BasicTourStepProps) => {
  const selDependency = `.MuiButtonBase-root[id*="${tourData.remediationsDependencyName}"]`;
  const selRemediationGroup = `.MuiAccordion-root:has(${selDependency})`;

  return {
    attachTo: {
      element: selRemediationGroup,
      on: 'top-center',
    },

    beforeShowPromise: async function () {
      navigate({
        to: `${getProjectDetailPath({
          tenantName: tourData.tourTenant,
          uuid: tourData.findingsProject?.uuid,
          section: 'remediations',
        })}`,
      });

      await waitForElementAvailable(selDependency);
      await waitForElementAvailable(selRemediationGroup);
      await clickOn(selDependency);
    },
    title: 'Fix without Breaking Changes',
    description: 'Apply ROI based upgrades based on context of your code. ',
    highlightSelectors: [selRemediationGroup],

    urlLearn: `${ENV.URL_ENDOR_DOCS}/upgrades-and-remediation/upgrade-impact-analysis`,
  };
};
