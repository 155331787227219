import { Card, CardContent, CardHeader, Stack, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { pick as _pick } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { PolicyPolicyType, V1Policy } from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  PolicyResource,
  useCreatePolicy,
  useDeletePolicy,
  useUpdatePolicy,
} from '@endorlabs/queries';
import {
  ButtonLinkPrimary,
  ExclusiveToggleButtonGroup,
  useConfirmationDialog,
  useExclusiveToggleButtonGroup,
} from '@endorlabs/ui-common';

import { useAuthInfo } from '../../../providers';
import { getPoliciesPath } from '../../../routes';
import { FilterBar, useFilterContext, withFilterProvider } from '../../filters';
import {
  REMEDIATION_POLICIES_FILTER_FIELDS,
  REMEDIATION_POLICIES_SEARCH_KEYS,
} from '../constants/filters';
import { usePoliciesAndRelatedProjects } from '../hooks';
import { usePolicyScanResults } from '../hooks/usePoliciesAndScanResults';
import { RemediationPoliciesView as messages } from '../locales';
import {
  AllowedTemplateTypesByPolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';
import { PolicyTemplatesView } from './PolicyTemplatesView';
import {
  RemediationPoliciesTable,
  RemediationPoliciesTableRow,
} from './RemediationPoliciesTable';

export const RemediationPoliciesViewBase = () => {
  const { space } = useTheme();
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();

  type TableViews = 'policies' | 'templates';
  const { getToggleButtonGroupProps, value: selectedTable } =
    useExclusiveToggleButtonGroup<TableViews>('policies', [
      { value: 'policies', children: <FM defaultMessage="Policies" /> },
      { value: 'templates', children: <FM defaultMessage="Templates" /> },
    ]);

  const { filter: userFilterExpression } = useFilterContext();

  const policiesFilterExpression = useMemo(() => {
    const baseFilterExpression = `spec.policy_type in [${PolicyPolicyType.Remediation}]`;
    if (userFilterExpression) {
      return filterExpressionBuilders.and([
        baseFilterExpression,
        userFilterExpression,
      ]);
    }
    return baseFilterExpression;
  }, [userFilterExpression]);

  const { policies, totalPolicyCount, getProjectCountForPolicy } =
    usePoliciesAndRelatedProjects({
      namespace: tenantName,
      listParameters: {
        filter: policiesFilterExpression,
        page_size: 500,
      },
    });

  const { getLastScanTimeForPolicy, getTriggerCountForPolicy } =
    usePolicyScanResults({
      namespace: tenantName,
      policies,
    });

  const qCreatePolicy = useCreatePolicy();

  /** DELETION */
  const qPolicyDelete = useDeletePolicy();

  const PolicyDeleteConfirmation = useConfirmationDialog<{
    namespace: string;
    uuid: string;
  }>({
    cancelText: <FM defaultMessage="Cancel" />,
    confirmText: <FM defaultMessage="Delete" />,

    isDestructive: true,
    onConfirm: (props) => {
      if (props) {
        qPolicyDelete.mutate({
          namespace: props.namespace,
          uuid: props.uuid,
        });
      }
    },
    titleText: <FM defaultMessage="Remove this Remediation Policy?" />,
  });

  /** UPDATE */
  const qPolicyEdit = useUpdatePolicy();

  // NOTE: navigating to the policy under the policy namespace
  const onPolicyEdit = ({
    namespace,
    uuid,
  }: {
    namespace: string;
    uuid: string;
  }) =>
    navigate({
      to: getPoliciesPath({
        tenantName: namespace,
        section: `remediations/edit/${uuid}`,
      }),
    });

  const onPolicyClone = ({ uuid }: { uuid: string }) => {
    const policyToBeCloned = policies.filter((p) => p.uuid === uuid)[0];
    const policyName = policyToBeCloned.meta.name;
    const cloneCount =
      policies.filter((p) => p.meta.name.includes(policyName))?.length || 1;
    const newPolicy: PolicyResource = _pick(policyToBeCloned, [
      'meta.name',
      'meta.description',
      'meta.tags',
      'spec.admission',
      'spec.disable',
      'spec.notification',
      'spec.policy_type',
      'spec.project_exceptions',
      'spec.project_selector',
      'spec.template_values',
      'spec.template_uuid',
    ]) as PolicyResource;

    // Added to count to avoid duplicate of policy names in listing
    if (newPolicy?.meta?.name) {
      newPolicy.meta.name = `${policyName}-${cloneCount}`;
    }

    // NOTE: policy wiill be created in the active namespace
    qCreatePolicy.mutate({ namespace: tenantName, resource: newPolicy });
  };

  const onPolicyDisable = useCallback(
    ({ uuid }: { uuid: string }) => {
      const policy = policies.find((policy) => policy.uuid === uuid);

      if (!policy || !policy.tenant_meta?.namespace) return;

      const updatedPolicy = {
        spec: { disable: !policy.spec.disable },
        uuid: policy.uuid,
      };

      qPolicyEdit.mutate({
        mask: 'spec.disable',
        namespace: policy.tenant_meta.namespace,
        resource: updatedPolicy as V1Policy,
      });
    },
    [policies, qPolicyEdit]
  );

  const buildPolicyTableRows = (
    policies: PolicyResource[]
  ): RemediationPoliciesTableRow[] => {
    return policies.map((policy) => {
      return {
        description: policy.meta.description,
        disable: policy.spec.disable,
        labels: policy.meta.tags,
        lastTriggered: getLastScanTimeForPolicy(policy.uuid),
        name: policy.meta.name,
        namespace: policy.tenant_meta.namespace,
        projectCount: getProjectCountForPolicy(policy.uuid),
        triggerCount: getTriggerCountForPolicy(policy.uuid),
        uuid: policy.uuid,
      };
    });
  };

  const policyTableRows = buildPolicyTableRows(policies as PolicyResource[]);

  const headerMessage = !userFilterExpression ? (
    <FM
      {...messages.headerWithSearch}
      values={{
        policyCount: policyTableRows.length,
        totalPolicyCount,
      }}
    />
  ) : (
    <FM
      {...messages.headerWithoutSearch}
      values={{
        policyCount: policyTableRows.length,
        totalPolicyCount,
      }}
    />
  );

  return (
    <Stack spacing={space.md}>
      <ExclusiveToggleButtonGroup {...getToggleButtonGroupProps()} />

      {selectedTable === 'policies' && (
        <>
          {totalPolicyCount > 0 && (
            <FilterBar
              fields={REMEDIATION_POLICIES_FILTER_FIELDS}
              searchPlaceholder="Search Remediation Policies"
            />
          )}

          <Card>
            <CardHeader
              action={
                <ButtonLinkPrimary
                  linkProps={{
                    to: getPoliciesPath({
                      tenantName,
                      section: `remediations/new`,
                    }),
                  }}
                >
                  <FM defaultMessage="Create Remediation Policy" />
                </ButtonLinkPrimary>
              }
              title={headerMessage}
            />
            <CardContent>
              <RemediationPoliciesTable
                data={policyTableRows}
                emptyStateProps={{
                  title: <FM defaultMessage="No Policies Found" />,
                }}
                onClone={onPolicyClone}
                onDisable={onPolicyDisable}
                onDelete={PolicyDeleteConfirmation.openDialog}
                onEdit={onPolicyEdit}
              />
            </CardContent>
          </Card>

          <PolicyDeleteConfirmation.Dialog
            {...PolicyDeleteConfirmation.dialogProps}
          />
        </>
      )}

      {selectedTable === 'templates' && (
        <PolicyTemplatesView
          policyTypes={
            AllowedTemplateTypesByPolicyUmbrellaType[
              PolicyUmbrellaTypes.REMEDIATION
            ]
          }
        />
      )}
    </Stack>
  );
};

export const RemediationPoliciesView = withFilterProvider(
  RemediationPoliciesViewBase,
  {
    displayName: 'RemediationPolicies',
    searchKeys: REMEDIATION_POLICIES_SEARCH_KEYS,
  }
);
