import { Divider, Grid, useTheme } from '@mui/material';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScoreCard, V1ScoreCategory } from '@endorlabs/api_client';
import { ScoreCategoryMessages } from '@endorlabs/ui-common';

import {
  ScoreCardDisplayItemV2,
  ScoreCardDisplayItemV2Props,
} from './ScoreCardDisplayItemV2';

const INCLUDED_SCORE_CATEGORIES = [
  V1ScoreCategory.Security,
  V1ScoreCategory.Activity,
  V1ScoreCategory.Popularity,
  V1ScoreCategory.CodeQuality,
];

export interface ScoreCardDisplayV2Props extends ScoreCardDisplayItemV2Props {
  scoreCard?: V1ScoreCard;
  includedScoreCategories?: V1ScoreCategory[];
}

export const ScoreCardDisplayV2 = ({
  isLoading,
  scoreCard,
  showTotal,
  size,
  totalSize,
  includedScoreCategories = INCLUDED_SCORE_CATEGORIES,
}: ScoreCardDisplayV2Props) => {
  const { palette, spacing } = useTheme();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      gap={2}
      sx={{ backgroundColor: palette.grey[100] }}
      padding={spacing(4, 2, 2)}
    >
      <Grid item>
        <ScoreCardDisplayItemV2
          colorize
          isLoading={isLoading}
          score={scoreCard?.overall_score}
          showTotal
          size={size}
          totalSize={totalSize}
          title={<FM defaultMessage="Overall" />}
        />
      </Grid>
      <Divider variant="middle" flexItem />
      <Grid item container direction="row" justifyContent="space-evenly">
        {includedScoreCategories.map((cat, index) => {
          return (
            <Fragment key={cat}>
              {index !== 0 && (
                <Divider variant="middle" orientation="vertical" flexItem />
              )}
              <Grid item xs>
                <ScoreCardDisplayItemV2
                  colorize
                  isLoading={isLoading}
                  score={
                    scoreCard?.category_scores?.find(
                      (score) => score.category === cat
                    )?.score
                  }
                  showTotal={showTotal}
                  size={size}
                  totalSize={totalSize}
                  title={<FM {...ScoreCategoryMessages[cat]} />}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Grid>
  );
};
