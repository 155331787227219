import { validate } from 'compare-versions';

import { deriveFullPackageName } from './deriveFullPackageName';
import { prefixFromEcosystem } from './ecosystem';
import { getAllPackageVersionDependencies } from './getAllPackageVersionDependencies';
import { packageDescriptorFromPackageURL } from './packageDescriptorFromPackageURL';
import { getPackageVersionFindingFilterExpression } from './packageVersionFilterExpressions';
import { parsePackageName } from './parsePackageName';
import { sortBySemanticVersion } from './sortBySemanticVersion';
import { stripEcosystemPrefix } from './stripEcosystemPrefix';
import { stripPackageVersionRef } from './stripPackageVersionRef';
import { mapUnresolvedToResolvedDependencies } from './transformDependencies';

export const UIPackageVersionUtils = {
  deriveFullPackageName,
  /**
   * @deprecated not currently used, and will be removed in a future release
   */
  getAllPackageVersionDependencies,
  getPackageVersionFindingFilterExpression,
  /**
   * Expose functionality from `compare-versions` here
   */
  isValidSemanticVersion: validate,
  /**
   * @deprecated not currently used, and will be removed in a future release
   */
  mapUnresolvedToResolvedDependencies,
  packageDescriptorFromPackageURL,
  parsePackageName,
  prefixFromEcosystem,
  sortBySemanticVersion,
  stripEcosystemPrefix,
  /**
   * @deprecated not currently used, and will be removed in a future release
   */
  stripPackageVersionRef,
};
