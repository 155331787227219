import {
  Box,
  ClickAwayListener,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Popper,
  SvgIcon,
  Typography,
} from '@mui/material';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { ReactNode } from 'react';

import { IconMoreVertical } from '../../themes';

export type DataTableActionDropdownItem = {
  disabled?: boolean;
  Icon?: typeof SvgIcon;
  isDestructive?: boolean;
  isPrimaryAction?: boolean;
  label: ReactNode;
  onClick: (event: MouseEvent) => void;
};

export type DataTableActionDropdownProps = {
  IconCmp?: typeof SvgIcon;
  items: DataTableActionDropdownItem[];
  /** Do not wrap click handlers with any special behavior */
  useRawClickHandlers?: boolean;
};

export const DataTableActionDropdown = ({
  IconCmp = IconMoreVertical,
  items,
  useRawClickHandlers = false,
}: DataTableActionDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (action: MouseEventHandler) => (event: MouseEvent) => {
    action(event);

    if (!event.defaultPrevented) {
      handleClose();
    }
  };

  return (
    <Box>
      <IconButton onClick={handleOpen} size="small">
        <IconCmp fontSize="inherit" />
      </IconButton>

      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: (theme) => theme.zIndex.tooltip,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              border: 'none',
              borderRadius: '6px',
              // TODO: them shadow with contrast from Card
              boxShadow: `0px 2px 4px 0px rgba(0, 0, 0, 0.1)`,
            }}
          >
            <List>
              {items.map(
                (
                  { disabled = false, label, onClick, isDestructive },
                  index
                ) => (
                  <ListItem key={index} disablePadding disableGutters>
                    <ListItemButton
                      disabled={disabled}
                      onClick={
                        useRawClickHandlers ? onClick : handleClick(onClick)
                      }
                    >
                      <Typography
                        color={isDestructive ? 'error' : 'text.primary'}
                      >
                        {label}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};
