import { Filter, isValueFilter, ValueFilter } from '@endorlabs/filters';

import { FilterFieldConfig } from '../types';

/**
 * Uilities for building Filter Field Config
 */
export const filterFieldTransformBuilders = {
  /**
   * Given a Filter, build the `format` and `parse` transforms for the Filter field
   */
  fromFilter<T = unknown>(
    base: Omit<ValueFilter, 'value'>,
    options?: { defaultValue: T }
  ) {
    const config: Pick<
      FilterFieldConfig<T>,
      'defaultFilter' | 'format' | 'parse'
    > = {
      format: (value) => {
        // return undefined when filter would be empty
        if (!value || (Array.isArray(value) && value.length === 0)) return;

        return {
          ...base,
          value: value,
        } as Filter;
      },
      parse: (filter) => {
        if (isValueFilter(filter) && filter.comparator === base.comparator) {
          return filter.value as T;
        }

        return options?.defaultValue;
      },
    };

    if (options?.defaultValue) {
      config.defaultFilter = config.format(options.defaultValue);
    }

    return config;
  },
};
