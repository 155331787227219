/**
 * Common 'status' values
 */
export enum Status {
  Failure = 'STATUS_FAILURE',
  PartialSuccess = 'STATUS_PARTIAL_SUCCESS',
  Pending = 'STATUS_PENDING',
  Running = 'STATUS_RUNNING',
  Success = 'STATUS_SUCCESS',
}
