import {
  Alert,
  AlertTitle,
  Box,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {
  MutableRefObject,
  Suspense,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';
import {
  ControlledLabelsField,
  ControlledTextField,
  EmptyState,
  ExternalLink,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

import { getEnv } from '../../../constants';
import {
  FormUpsertPolicyFieldValues,
  PolicyUmbrellaType,
  PolicyUmbrellaTypes,
} from '../types';

const DEFAULT_EDITOR_VALUE = `# Enter your policy here\n\n`;
const ENV = getEnv();

const REGO_DOCS_LINKS: Record<PolicyUmbrellaType, string> = {
  [PolicyUmbrellaTypes.ACTION]: `${ENV.URL_ENDOR_DOCS}/managing-policies/action-policies/#create-an-action-policy-from-scratch`,
  [PolicyUmbrellaTypes.EXCEPTION]: `${ENV.URL_ENDOR_DOCS}/managing-policies/exception-policies/#create-an-exception-policy-from-scratch`,
  [PolicyUmbrellaTypes.FINDING]: `${ENV.URL_ENDOR_DOCS}/managing-policies/finding-policies/#create-a-finding-policy-from-scratch`,
  // NOTE: Remediation policy docs are not available yet. Link to general policy docs
  [PolicyUmbrellaTypes.REMEDIATION]: `${ENV.URL_ENDOR_DOCS}/managing-policies`,
};

// Subset of all Resource Kinds that may be selected
const RESOURCE_KIND_OPTIONS: ResourceKind[] = [
  'DependencyMetadata',
  'Finding',
  'Metric',
  'PackageVersion',
  'Project',
  'Repository',
  'RepositoryVersion',
];

interface PolicyRulesFromCodeEditorProps {
  policyUmbrellaType: PolicyUmbrellaType;
}

export const PolicyRulesFromCodeEditor = ({
  policyUmbrellaType,
}: PolicyRulesFromCodeEditorProps) => {
  const { formatMessage: fm } = useIntl();
  const { clearErrors, control, formState, setValue } =
    useFormContext<FormUpsertPolicyFieldValues>();
  const { space } = useTheme();
  const containerRef = useRef() as MutableRefObject<HTMLDivElement>;

  const policyRegoValue = useWatch({ control, name: 'spec.rule' });
  const policyRegoError = formState.errors.spec?.rule;

  useEffect(() => {
    // unset a selected template uuid when switching to code editor
    setValue('spec.template_uuid', undefined);
  }, [setValue]);

  useEffect(() => {
    // if error for policy rego is added, scroll to top of container
    if (policyRegoError) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [policyRegoError]);

  const resetRegoFieldError = useCallback(() => {
    clearErrors('spec.rule');
  }, [clearErrors]);

  const updateHiddenInput = useCallback(
    (codeEditorValue?: string) => {
      resetRegoFieldError();
      setValue('spec.rule', codeEditorValue ?? '', {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue, resetRegoFieldError]
  );

  return (
    <Stack spacing={space.sm} ref={containerRef}>
      {policyRegoError && (
        <Alert
          severity={
            policyRegoError.type === 'server.timeout' ? 'warning' : 'error'
          }
        >
          <AlertTitle>
            {policyRegoError.type === 'validate' ? (
              <FM defaultMessage="Invalid Policy Definition. Please check the Rego definition and query statement below, and try again" />
            ) : (
              <FM defaultMessage="Failed to validate Policy Definition." />
            )}
          </AlertTitle>

          <Typography>{policyRegoError.message}</Typography>
        </Alert>
      )}

      <Typography>
        <FM
          defaultMessage="<a>Author a custom policy from scratch using the Rego Policy language</a>"
          values={{
            a: (v) => (
              <ExternalLink to={REGO_DOCS_LINKS[policyUmbrellaType]}>
                {v}
              </ExternalLink>
            ),
          }}
        />
      </Typography>

      <Box>
        <Suspense
          fallback={
            <EmptyState
              size="medium"
              title={<FM defaultMessage="Loading Editor …" />}
            />
          }
        >
          <CodeEditor
            defaultValue={DEFAULT_EDITOR_VALUE}
            downloadProps={{
              filetype: 'rego',
              filename: 'policy.rego',
            }}
            language="rego"
            onChange={updateHiddenInput}
            title={<FM defaultMessage="Rego Definition" />}
            value={policyRegoValue}
          />
        </Suspense>
      </Box>

      <ControlledTextField
        control={control}
        helperText={fm({
          defaultMessage:
            'OPA query statement that must be executed to evaluate the policy',
        })}
        label={fm({ defaultMessage: 'Query Statement' })}
        name="spec.query_statements.0"
        onChange={resetRegoFieldError}
        placeholder="data.packagename.query"
        required
      />

      <ControlledTextField
        control={control}
        defaultValue={[]}
        label={<FM defaultMessage="Resource Kinds" />}
        name="spec.resource_kinds"
        placeholder={fm({
          defaultMessage: 'All resources that a policy requires.',
        })}
        rules={{ required: true }}
        select
        SelectProps={{ multiple: true }}
      >
        {RESOURCE_KIND_OPTIONS.map((kind) => (
          <MenuItem key={kind} value={kind}>
            {kind}
          </MenuItem>
        ))}
      </ControlledTextField>

      {policyUmbrellaType === PolicyUmbrellaTypes.FINDING && (
        <ControlledLabelsField
          control={control}
          defaultValue={[]}
          fullWidth
          label={<FM defaultMessage="Group by Fields" />}
          name="spec.group_by_fields"
          helperText={fm({
            defaultMessage: 'Custom fields to group policy output by.',
          })}
        />
      )}
    </Stack>
  );
};
