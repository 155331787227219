import { useRouter } from '@tanstack/react-location';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { V1Method } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { checkPermission } from '@endorlabs/endor-core/auth';
import { getRootNamespace, NAMESPACES } from '@endorlabs/endor-core/Namespace';
import { useActivePermissions, useFeatureFlags } from '@endorlabs/queries';
import { useListEndorLicenses } from '@endorlabs/queries/licenses';

import { NamedRoutes } from '../../routes';
import { useEventTracking } from '../EventTracking';
import { AuthInfoContext } from './AuthInfoContext';
import {
  AuthenticatedRouteLocationGenerics,
  AuthInfoProviderProps,
} from './types';

export function AuthInfoProvider({ children }: AuthInfoProviderProps) {
  const { trackPageVisit } = useEventTracking();
  // NOTE: must use active state of the router in order to avoid context update
  // during a route transition from tenant -> oss route.
  const router = useRouter<AuthenticatedRouteLocationGenerics>();
  const routeNamespace = router.state.matches.find((m) => m.params.namespace);
  const { ENABLE_LICENSE_AND_BUNDLING: isLicensingAndBundling } =
    useFeatureFlags();
  const [licenseInCurrentRoute, setLicenseInCurrentRoute] = useState({
    pathname: '',
    isLicense: true,
  });

  // Determine if we're in the special OSS Explorer context
  const isOssExplorer = router.state.location.pathname.startsWith(
    NamedRoutes.OSS_EXPLORER
  );

  // Select the active namespace
  // TODO: missing namespace value
  const activeNamespace = isOssExplorer
    ? NAMESPACES.OSS
    : routeNamespace?.params.namespace ?? '';

  const qActivePermissions = useActivePermissions(activeNamespace, {
    enabled: !!activeNamespace,
  });

  const qLicensesForTenants = useListEndorLicenses(
    { namespace: getRootNamespace(activeNamespace) },
    {
      enabled: !!activeNamespace && isLicensingAndBundling,
    }
  );

  const licenseInfo = useMemo(() => {
    const tenantsList = qLicensesForTenants?.data?.list?.objects || [];
    const licenseInfo =
      tenantsList.find((t) => t.spec.target_namespace === activeNamespace)?.spec
        .license_info || [];
    return licenseInfo;
  }, [activeNamespace, qLicensesForTenants?.data]);

  const checkActivePermission = useCallback(
    (...args: [V1Method | undefined, ResourceKind | undefined]) => {
      if (!qActivePermissions.data?.permissions) return false;
      return checkPermission(qActivePermissions.data.permissions, ...args);
    },
    [qActivePermissions.data]
  );

  useEffect(() => {
    // Track page visits for an authenticated user
    if (!activeNamespace) return;

    // Check that the user has write access for telemetry in the namespace
    const canCreateTelemetry = checkActivePermission(
      V1Method.Create,
      'UITelemetry'
    );

    if (!canCreateTelemetry) return;

    trackPageVisit(activeNamespace, router.state.location.pathname);
  }, [
    activeNamespace,
    checkActivePermission,
    router.state.location.pathname,
    trackPageVisit,
  ]);

  const context = useMemo(() => {
    return {
      activeNamespace,
      checkActivePermission,
      isLoading: qActivePermissions.isLoading || qLicensesForTenants?.isLoading,
      licenseInfo: licenseInfo ?? [],
      licenseInCurrentRoute,
      setLicenseInCurrentRoute,
    };
  }, [
    activeNamespace,
    checkActivePermission,
    qActivePermissions.isLoading,
    qLicensesForTenants?.isLoading,
    licenseInfo,
    licenseInCurrentRoute,
  ]);

  return (
    <AuthInfoContext.Provider value={context}>
      {children}
    </AuthInfoContext.Provider>
  );
}
