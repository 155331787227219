import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
} from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useMemo } from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { GroupByTimeGroupByTimeInterval } from '@endorlabs/api_client';
import { FilterExpression } from '@endorlabs/filters';
import { Colors, EmptyState, LineChart } from '@endorlabs/ui-common';

import { useDashboardFindingResolutionTimeTrendData } from '../hooks';

export const DashboardFindingResolutionTimeTrend = ({
  namespace,
  baseFilter,
  groupByTimeOptions,
}: {
  namespace: string;
  baseFilter: FilterExpression;
  groupByTimeOptions: {
    interval: GroupByTimeGroupByTimeInterval;
    group_size: number;
  };
}) => {
  const { formatDate } = useIntl();

  const { data, isEmpty, isLoading } =
    useDashboardFindingResolutionTimeTrendData({
      namespace,
      baseFilter,
      groupByTimeOptions,
    });

  const chartData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      label: formatDate(item.date, {
        dateStyle: 'medium',
      }),
    }));
  }, [data, formatDate]);

  return (
    <Card
      elevation={0}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible', // HACK: prevent cutting off tooltip overlay at bottom of chart
        '& .MuiCardContent-root': {
          flexGrow: 1,
        },
      }}
    >
      <CardHeader
        title={<FM defaultMessage="Average Time for Issues Resolved" />}
      />
      <CardContent>
        {isLoading && (
          <Stack alignItems="center" height="100%" justifyContent="center">
            <CircularProgress />
          </Stack>
        )}
        {isEmpty && !isLoading && (
          <EmptyState
            size="medium"
            title={<FM defaultMessage="No records match the filter criteria" />}
          />
        )}
        {!isLoading && !isEmpty && (
          <ParentSize debounceTime={100}>
            {({ width: visWidth, height: visHeight }) => (
              <LineChart
                width={visWidth}
                height={visHeight}
                data={chartData}
                xKey="label"
                yKeys={['avg']}
                axisBottom={true}
                axisColor={Colors.BLACK}
                axisLabel="Counts"
                axisNumTicks={4}
                axisRight={false}
                lineColors={[Colors.GREEN]}
                lineThickness={3}
                margin={{
                  bottom: 24,
                  left: 60,
                  top: 16,
                  right: 32,
                }}
                showGrid={true}
              />
            )}
          </ParentSize>
        )}
      </CardContent>
    </Card>
  );
};
