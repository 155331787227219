import { Grid, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import {
  defineMessages,
  FormattedList,
  FormattedMessage as FM,
  MessageDescriptor,
} from 'react-intl';

import {
  V1DependencyMetadataSpec,
  V1GroupResponse,
  V1LicenseInfo,
} from '@endorlabs/api_client';
import { QueryPackageVersionsResponseObject } from '@endorlabs/queries';
import { CopyToClipboardButton } from '@endorlabs/ui-common';

const column1 = ['dependencies', 'dependents', 'callgraph_available'];

type DependencyMetadataSpecStub = {
  dependency_data?: Pick<
    Exclude<V1DependencyMetadataSpec['dependency_data'], undefined>,
    'direct'
  >;
  importer_data?: Pick<
    Exclude<V1DependencyMetadataSpec['importer_data'], undefined>,
    'callgraph_available'
  >;
};

interface DependencyDetailOverviewMetadataProps {
  dependencyMetadata?: DependencyMetadataSpecStub[];
  dependentGroups?: V1GroupResponse;
  licenses?: V1LicenseInfo[];
  packageVersion?: QueryPackageVersionsResponseObject;
}

export const DependencyDetailOverviewMetadata = ({
  dependencyMetadata,
  dependentGroups,
  licenses,
  packageVersion,
}: DependencyDetailOverviewMetadataProps) => {
  const { space } = useTheme();
  const sx = styles();

  const collectedMetadata = useMemo(() => {
    if (packageVersion && dependencyMetadata) {
      return getDepMetadataCounts(dependencyMetadata, dependentGroups);
    } else {
      return genBlankMetadata();
    }
  }, [dependencyMetadata, dependentGroups, packageVersion]);

  return (
    <Grid container>
      <Grid item md={6} sm={12}>
        <Stack
          component="ul"
          paddingLeft={0}
          paddingRight={space.lg}
          spacing={space.md}
          sx={sx.list}
        >
          {column1.map((dataPointKey) => {
            const dataPoints = collectedMetadata[dataPointKey];

            return (
              <Stack component="li" key={dataPointKey} spacing={space.xs}>
                <Typography component="h6" variant="h4">
                  <FM {...headings[dataPointKey]} />
                </Typography>
                <Typography variant="body2">
                  <FM {...entryMessages[dataPointKey]} values={dataPoints} />
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      </Grid>

      <Grid item md={6} sm={12}>
        <Stack
          component="ul"
          borderLeft={({ palette }) => `1px solid ${palette.divider}`}
          paddingLeft={space.lg}
          paddingRight={0}
          spacing={space.md}
          sx={sx.list}
        >
          <Stack component="li" spacing={space.xs}>
            <Typography component="h6" variant="h4">
              <FM defaultMessage="License" />
            </Typography>
            <Typography variant="body2">
              {licenses?.length ? (
                <FormattedList
                  value={licenses?.map((l) => l.name)}
                  type="unit"
                />
              ) : (
                <FM defaultMessage="No license specified" />
              )}
            </Typography>
          </Stack>

          <Stack component="li" spacing={space.xs}>
            <Typography component="h6" variant="h4">
              <FM defaultMessage="Package Version UUID" />
            </Typography>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="body2">{packageVersion?.uuid}</Typography>
              {packageVersion && (
                <CopyToClipboardButton
                  size="small"
                  value={packageVersion?.uuid ?? ''}
                />
              )}
            </Stack>
          </Stack>

          {packageVersion?.spec.resolved_dependencies?.resolution_timestamp && (
            <Stack component="li" spacing={space.xs}>
              <Typography component="h6" variant="h4">
                <FM defaultMessage="Resolution Timestamp" />
              </Typography>
              <Typography variant="body2">
                {
                  packageVersion?.spec.resolved_dependencies
                    ?.resolution_timestamp
                }
              </Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  list: {
    listStyle: 'none',
    margin: 0,
  },
});

const genBlankMetadata = (): Record<string, Record<string, number>> => ({
  callgraph_available: { available: 0, unavailable: 0 },
  dependencies: { direct: 0, indirect: 0 },
  dependents: { direct: 0, indirect: 0 },
});

const getDepMetadataCounts = (
  depMetadata: DependencyMetadataSpecStub[] = [],
  dependentGroups: V1GroupResponse = {}
) => {
  /**
   * Aggregate counts of various metadata found on DependencyMetadata
   */
  const dataFromDepMetadata = depMetadata.reduce((acc, depMetadata) => {
    const { dependency_data: data, importer_data } = depMetadata;

    if (importer_data?.callgraph_available) {
      acc.callgraph_available.available += 1;
    } else {
      acc.callgraph_available.unavailable += 1;
    }

    if (data?.direct) {
      acc.dependencies.direct += 1;
    } else {
      acc.dependencies.indirect += 1;
    }

    return acc;
  }, genBlankMetadata());

  /** Add dependent count breakdown from dependents metadata query */
  const directDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('true')
  );
  const indirectDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('false')
  );

  dataFromDepMetadata.dependents.direct = directDependents
    ? directDependents[1].aggregation_count.count
    : 0;
  dataFromDepMetadata.dependents.indirect = indirectDependents
    ? indirectDependents[1].aggregation_count.count
    : 0;

  return dataFromDepMetadata;
};

const headings: Record<string, MessageDescriptor> = defineMessages({
  callgraph_available: { defaultMessage: 'Call Graph Available Dependencies' },
  dependents: { defaultMessage: 'Dependents in Your Environment' },
  dependencies: { defaultMessage: 'Dependencies' },
});

const entryMessages: Record<string, MessageDescriptor> = defineMessages({
  callgraph_available: {
    defaultMessage:
      '{available, number} Available ・ {unavailable, number} Unavailable',
  },
  dependents: {
    defaultMessage: '{direct, number} Direct ・ {indirect, number} Transitive',
  },
  dependencies: {
    defaultMessage: '{direct, number} Direct ・ {indirect, number} Transitive',
  },
});
