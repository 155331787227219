import { V1ListParameters } from '@endorlabs/api_client';
import { GetRequestParameters } from '@endorlabs/endor-core/api';

import { GetParamsArray, ListParamsArray } from '../types';

/**
 * Ensures order of count params for use when passing to generated api client methods
 */
export const buildCountParamArgs = (
  params: Partial<V1ListParameters>
): ListParamsArray => {
  // Ensure `count` is enabled
  return mapToListParameterTuple({ ...params, count: true });
};

/**
 * Ensures order of get params for use when passing to generated api client methods
 */
export const buildGetParamArgs = (
  params: GetRequestParameters
): GetParamsArray => {
  return mapToGetParameterTuple(params);
};

/**
 * Ensures order of list params for use when passing to generated api client methods
 */
export const buildListParamArgs = (
  params: Partial<V1ListParameters>
): ListParamsArray => {
  // Ensure `count` is disabled
  return mapToListParameterTuple({ ...params, count: false });
};

/**
 * Return the flattened Get Parameters in the expected order
 *
 * NOTE: The generated axios client from the OpenAPI generator expects the
 * parameters for the service method calls to be in a flatted list, matching
 * the order defined in the `parameters` array for the call definitions.
 */
export const mapToGetParameterTuple = (
  getParameters: GetRequestParameters
): GetParamsArray => {
  return [getParameters.uuid, getParameters.mask];
};

/**
 * Return the flattened List Parameters in the expected order
 *
 * NOTE: The generated axios client from the OpenAPI generator expects the
 * parameters for the service method calls to be in a flatted list, matching
 * the order defined in the `parameters` array for the call definitions.
 */
export const mapToListParameterTuple = (listParameters: V1ListParameters) => {
  return [
    listParameters.filter,
    listParameters.page_token,
    listParameters.page_size,
    listParameters.action,
    listParameters.mask,
    listParameters.traverse,
    listParameters.sort?.path,
    listParameters.sort?.order,
    listParameters.count,
    listParameters.group?.aggregation_paths,
    listParameters.group?.show_aggregation_uuids,
    listParameters.group?.unique_count_paths,
    listParameters.group?.unique_value_paths,
    listParameters.ci_run_uuid,
    listParameters.page_id,
    listParameters.group_by_time?.aggregation_paths,
    listParameters.group_by_time?.show_aggregation_uuids,
    listParameters.group_by_time?.interval,
    listParameters.group_by_time?.group_size,
    listParameters.group_by_time?.start_time,
    listParameters.group_by_time?.end_time,
    listParameters.group_by_time?.mode,
    listParameters.group_by_time?.aggregation_value_field,
    listParameters.group_by_time?.aggregation_operator,
  ] satisfies Readonly<ListParamsArray>;
};
