import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

import { LiteralString } from '@endorlabs/utils';

import { IconCircle } from '../../themes';

type CurrencySymbolDisplayProps = {
  /**
   * Currency Code for the desired symbol.
   *
   * NOTE: Should target supporting the ISO-4217 codes {@link https://en.wikipedia.org/wiki/ISO_4217}
   */
  value: LiteralString<'USD' | 'EUR'>;
  /**
   * TODO: support icon sizes
   */
  size?: 'medium';
};

/**
 * Wrapper around Intl Formatting for currency. Frames the target currency
 * symbol in a circular icon.
 *
 * Related {@link https://endorlabs.atlassian.net/browse/UI-802}
 */
export const CurrencySymbolDisplay = ({
  value,
  size,
}: CurrencySymbolDisplayProps) => {
  const { formatNumber } = useIntl();

  // HACK: get the symbol for a currency
  const symbolDisplay = formatNumber(0, {
    currency: value,
    style: 'currency',
    currencyDisplay: 'symbol',
    maximumSignificantDigits: 1,
  }).replace(/\d+/, '');

  return (
    <Box sx={{ position: 'relative' }}>
      <IconCircle fontSize="large" />

      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: 0,
          width: 24,
          height: 24,
          justifyContent: 'center',
          lineHeight: `24px`,
          textAlign: 'center',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {symbolDisplay}
      </Box>
    </Box>
  );
};
