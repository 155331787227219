import { uniqBy as _uniqBy } from 'lodash-es';
import { useMemo } from 'react';

import { useListNamespaces } from '@endorlabs/queries';
import { useGraphDataNode } from '@endorlabs/ui-common/domains/graph';

import { buildNamespaceResourceFrom } from '../utils';

export const useChildNamespaces = ({
  targetNamespace,
}: {
  targetNamespace: string;
}) => {
  const graphData = useGraphDataNode(targetNamespace);
  const qChildNamespaces = useListNamespaces(
    targetNamespace,
    { enabled: !!targetNamespace },
    { traverse: false }
  );

  const childNamespaces = useMemo(() => {
    const childNamespaces = graphData.children.map(({ id }) =>
      buildNamespaceResourceFrom(id)
    );

    const namespaceResources = _uniqBy(
      [...childNamespaces, ...(qChildNamespaces.data?.objects ?? [])],
      (o) => o.spec.full_name
    );

    // ensure child namespaces are sorted
    return namespaceResources.sort((a, b) =>
      a.meta.name.localeCompare(b.meta.name)
    );
  }, [graphData.children, qChildNamespaces.data]);

  return { ...qChildNamespaces, data: childNamespaces };
};
