import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useRouter } from '@tanstack/react-location';
import { useEffect, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  SpecEndorLicenseFeatureType,
  V1Ecosystem,
} from '@endorlabs/api_client';
import { FindingSource } from '@endorlabs/endor-core/Finding';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  ButtonCancel,
  ButtonPrimary,
  EmptyState,
  FindingLevelLabel,
} from '@endorlabs/ui-common';

import { FilterBar, useFilterContext } from '../../domains/filters';
import {
  FindingAggregation,
  FindingDetailSectionPagination,
  useAggregatedFindingsData,
  useFindingsExportDialog,
  useFindingsFilterFields,
} from '../../domains/Findings';
import {
  OnboardingStepIds,
  useOnboardingSteps,
  useOnboardingUserEventTracking,
} from '../../domains/Onboarding';
import { useAuthInfo, useLicensingInfo } from '../../providers';
import { OnboardingPopoverFindings } from '../Onboarding/OnboardingPopoverFindings';
import { FindingsAggregatedGroupSection } from './FindingsAggregatedGroupSection';

export interface FindingsAggregatedViewProps {
  namespace: string;
  findingSource: FindingSource;
}

export const FindingsAggregatedView = ({
  namespace,
  findingSource,
}: FindingsAggregatedViewProps) => {
  // Show a popover if user arrived at this page during onboarding
  const { getStepCompletedWithin } = useOnboardingSteps();
  const completedOnboarding = getStepCompletedWithin(
    OnboardingStepIds.ONBOARD_VIEW_FINDINGS,
    10
  );

  const { setLicenseInCurrentRoute } = useAuthInfo();

  const router = useRouter();

  const { checkLicensePresent } = useLicensingInfo();
  const isSecretsLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.Secrets
  );

  const isContainerLicensePresent = checkLicensePresent(
    SpecEndorLicenseFeatureType.ContainerScan
  );

  useEffect(() => {
    if (!isSecretsLicensePresent && findingSource === FindingSource.Secrets) {
      setLicenseInCurrentRoute({
        pathname: router.state.location.pathname,
        isLicense: false,
      });
      return () => {
        setLicenseInCurrentRoute({
          pathname: '',
          isLicense: true,
        });
      };
    }
  }, [
    findingSource,
    isSecretsLicensePresent,
    router.state.location.pathname,
    setLicenseInCurrentRoute,
  ]);

  const filterFields = useFindingsFilterFields(findingSource);

  const {
    filter: userFilterExpression,
    clearFilter,
    _state: filterState,
  } = useFilterContext();

  // Check the filter value on change
  const { checkFindingReachabilityFilter } = useOnboardingUserEventTracking();

  useEffect(() => {
    checkFindingReachabilityFilter(filterState.values);
  }, [checkFindingReachabilityFilter, filterState.values]);

  // Enforce the MAIN context for the global findings page
  const findingsFilterExpression = useMemo(() => {
    const expressions = [filterExpressionBuilders.mainResourceContext()];

    if (userFilterExpression) {
      expressions.push(userFilterExpression);
    }

    if (!isContainerLicensePresent) {
      expressions.push(`spec.ecosystem!=${V1Ecosystem.Container}`);
    }

    return filterExpressionBuilders.and(expressions);
  }, [isContainerLicensePresent, userFilterExpression]);

  const findingsExportDialog = useFindingsExportDialog();

  const { data, error, isLoading, isLoadingAny, paginator, totalCount } =
    useAggregatedFindingsData({
      namespace,
      findingSource,
      // Currentlty restricted to group-by-finding for global page
      findingAggregation: FindingAggregation.Finding,
      filterExpression: findingsFilterExpression,
      filterState,
    });

  const isFiltered = !!(filterState.search || filterState.values?.size);

  const hasError = !!error;
  const isEmptyState =
    !isLoading && totalCount === 0 && !isFiltered && !hasError;
  const isErrorEmptyState = !isLoading && totalCount === 0 && hasError;
  const isFilteredEmptyState = !isLoading && totalCount === 0 && isFiltered;

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <FilterBar enableAdvanced enableSavedFilters fields={filterFields} />
      </Grid>

      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="No findings to report" />}
            description={
              <FM defaultMessage="As new issues arise with your code, they will appear here." />
            }
          />
        </Grid>
      )}

      {(isErrorEmptyState || isFilteredEmptyState) && (
        <Grid item>
          <EmptyState
            size="medium"
            title={
              hasError || (
                <FM defaultMessage="No findings match the filter criteria" />
              )
            }
          >
            <Stack direction="column" spacing={2}>
              {hasError && (
                <Alert severity="warning" color="warning" variant="outlined">
                  <AlertTitle>
                    <FM defaultMessage="Unable to load Finding data." />
                  </AlertTitle>
                  {error?.response.data?.message}
                </Alert>
              )}

              <ButtonCancel onClick={clearFilter}>
                <FM defaultMessage="Clear Filter" />
              </ButtonCancel>
            </Stack>
          </EmptyState>
        </Grid>
      )}

      {isLoading && (
        <Grid item>
          <Card>
            <CardHeader title={<Skeleton height={24} width={400} />} />
            <CardContent>
              {Array.from({ length: paginator.state.pageSize }, (_, ix) => (
                <Skeleton height={100} key={ix} />
              ))}
            </CardContent>
          </Card>
        </Grid>
      )}

      {!isLoading && !isEmptyState && !isFilteredEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              disableTypography
              subheader={
                hasError && (
                  <Alert severity="warning" color="warning" variant="outlined">
                    <AlertTitle>
                      <FM
                        defaultMessage="Unable to load {level} Severity Finding data."
                        values={{
                          level: (
                            <FindingLevelLabel findingLevel={error.level} />
                          ),
                        }}
                      />
                    </AlertTitle>
                    {error.response.data?.message}
                  </Alert>
                )
              }
              title={
                <Stack
                  alignItems="center"
                  direction="row"
                  marginBottom={2}
                  spacing={2}
                >
                  <Box flexGrow={1}>
                    {isLoadingAny ? (
                      <Skeleton height={24} width={400} />
                    ) : (
                      <Typography variant="h3" color="text.secondary">
                        <FM
                          defaultMessage="{totalCount, number, ::compact-short} {totalCount, plural, one {finding} other {findings}}"
                          values={{ totalCount }}
                        />
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    {isLoadingAny ? (
                      <Skeleton height={24} width={300} />
                    ) : (
                      <FindingDetailSectionPagination
                        hidePageSizeSelect
                        paginator={paginator}
                        resourceLabel="Group"
                        resourceLabelPlural="Groups"
                      />
                    )}
                  </Box>

                  <ButtonPrimary
                    disabled={isLoading || isFilteredEmptyState}
                    onClick={() =>
                      findingsExportDialog.openDialog({
                        namespace,
                        filter: findingsFilterExpression,
                        downloadProps: {
                          filename: `tenant_${namespace}_findings-export.csv`,
                        },
                      })
                    }
                  >
                    <FM defaultMessage="Export Findings" />
                  </ButtonPrimary>
                </Stack>
              }
            />

            <CardContent sx={{ marginTop: 4 }}>
              <Stack spacing={4}>
                {data.map((group) => (
                  <FindingsAggregatedGroupSection
                    key={group.key}
                    namespace={namespace}
                    referenceCounts={group.referenceCounts}
                    severity={group.severity}
                    title={group.title}
                    totalCount={group.totalCount}
                    uuids={group.uuids}
                  />
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      )}

      <findingsExportDialog.Dialog {...findingsExportDialog.getDialogProps()} />
      <OnboardingPopoverFindings willShow={completedOnboarding} />
    </Grid>
  );
};
