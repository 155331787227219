import {
  Box,
  Button,
  Grid,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { getRootNamespace } from '@endorlabs/endor-core/Namespace';
import { useSession } from '@endorlabs/queries';
import { IconHelp, Link } from '@endorlabs/ui-common';
import EndorLogo from '@endorlabs/ui-common/assets/logos/symbol-endor-primary-white.svg';

import { Layout } from '../../constants';
import { NamedRoutes } from '../../routes/constants';
import { AppDrawerNavSection } from './AppDrawerNavSection';
import { SessionDisplay } from './SessionDisplay';
import { useAppDrawerNavSections } from './useAppDrawerNavSections';

export const AppDrawerV2 = () => {
  const { getLastUsedTenant, hasValidTenantAccess } = useSession();
  const { formatMessage: fm } = useIntl();
  const theme = useTheme();

  const lastUsedTenant = getLastUsedTenant(true);
  /**
   * Verify the user can access tenants other than Global (oss) or Shared (demo-trial)
   */
  const hasTenants = hasValidTenantAccess();

  // boolean to determine which app drawer width to display
  const appDrawerMinimized = useMediaQuery(
    theme.breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)
  );

  const sx = styles(theme, { appDrawerMinimized, hasTenants });

  const { navSections } = useAppDrawerNavSections();

  return (
    // @ts-expect-error - known issue with overflowY: https://github.com/emotion-js/emotion/issues/1179
    <Box sx={sx.drawer}>
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        sx={{ height: '100%' }}
      >
        <Grid
          alignItems="center"
          container
          direction="column"
          item
          justifyContent="center"
          sx={sx.headerWrapper}
        >
          <Grid item sx={sx.logo}>
            <Link to="/">
              <img
                alt={fm({ defaultMessage: 'Endor Labs' })}
                src={EndorLogo}
                height={32}
              />
            </Link>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          flexWrap="nowrap"
          item
          justifyContent="space-between"
          sx={sx.contentWrapper}
        >
          <Grid item sx={sx.navWrapper}>
            <nav
              aria-label={fm({ defaultMessage: 'Primary navigation' })}
              id="app-primary-nav"
            >
              {navSections.map((section, i) => {
                return (
                  <AppDrawerNavSection
                    key={i}
                    navItems={section.navItems}
                    title={section.title}
                    appDrawerMinimized={appDrawerMinimized}
                  />
                );
              })}
            </nav>
          </Grid>

          {/* Show docs URL when ENV variable is present */}
          {NamedRoutes.DOCS && (
            <Grid item sx={sx.docs}>
              <Tooltip
                title={
                  appDrawerMinimized
                    ? fm({ defaultMessage: 'View Documentation' })
                    : ''
                }
                placement="right"
                arrow
              >
                <Button
                  href={NamedRoutes.DOCS}
                  size="small"
                  startIcon={<IconHelp />}
                  target="_blank"
                  sx={{ color: ({ palette }) => palette.grey[200] }}
                >
                  {!appDrawerMinimized && (
                    <FM defaultMessage="View Documentation" />
                  )}
                </Button>
              </Tooltip>
            </Grid>
          )}

          <Grid item sx={sx.session}>
            <SessionDisplay
              appDrawerMinimized={appDrawerMinimized}
              tenantName={
                lastUsedTenant ? getRootNamespace(lastUsedTenant) : undefined
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

function styles(
  { breakpoints, palette, spacing }: Theme,
  options: { appDrawerMinimized: boolean; hasTenants: boolean }
) {
  return {
    drawer: {
      backgroundColor: palette.background.dark,
      color: palette.grey[200],
      height: '100%',
      overflowY: 'hidden',
      width: spacing(Layout.APP_DRAWER_WIDTH),

      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        width: spacing(Layout.APP_DRAWER_WIDTH_MIN),
      },
    },
    headerWrapper: {
      backgroundColor: palette.brand.main,
      flexShrink: 0, // preserve header size on smaller screens
      padding: spacing(6, 0),
    },
    logo: {
      height: spacing(Layout.APP_DRAWER_LOGO_CONTAINER_HEIGHT),
      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        marginRight: 1,
        left: '50%',
      },
    },
    contentWrapper: {
      flexGrow: 1,
      overflowY: 'hidden',
      // Add padding to app drawer when tenant-related nav options are hidden
      paddingTop: options.hasTenants ? 0 : 4,
    },
    navWrapper: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: spacing(2, 0, 0),
    },
    docs: {
      padding: spacing(2, 4, 2),

      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        padding: spacing(4, 1, 2),
      },
    },
    session: {
      backgroundColor: palette.background.dark,
      flexShrink: 0,
      padding: spacing(4, 4, 2),

      [breakpoints.down(Layout.APP_DRAWER_MINIMIZATION_WIDTH)]: {
        padding: spacing(4, 1, 2),
      },
    },
  };
}
