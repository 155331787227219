import _intersection from 'lodash-es/intersection';
import _sortBy from 'lodash-es/sortBy';
import { matchSorter } from 'match-sorter';
import { useCallback, useMemo } from 'react';

import { V1ListParameters, V1Policy } from '@endorlabs/api_client';
import { applyFilters, ResourceFilter } from '@endorlabs/filters';
import {
  ProjectResource,
  useListAllProjects,
  useListPolicies,
  useListProjects,
} from '@endorlabs/queries';

import { FIFTEEN_MINUTES_IN_MILLISECONDS } from '../../../constants';
import { processLabelValues } from '../utils';

/**
 * Given a set of policies (real or potential), retrieve & maintain a set of projects applicable to those policies.
 * Provides access those projects and the working set of policies.
 */
export const usePoliciesAndRelatedProjects = ({
  filters = [],
  listParameters,
  namespace,
  policies,
  searchValue,
}: {
  filters?: ResourceFilter[];
  listParameters?: V1ListParameters;
  namespace: string;
  policies?: V1Policy[];
  searchValue?: string;
}) => {
  // If no set of policies is provided, retrieve them
  const qListPolicies = useListPolicies(
    namespace,
    { enabled: !policies },
    listParameters
  );

  const initialPolicies = useMemo(
    () => policies ?? qListPolicies.data?.list?.objects ?? [],
    [policies, qListPolicies]
  );

  const workingPolicies = useMemo(() => {
    const filteredPolicies = applyFilters(
      filters,
      initialPolicies as V1Policy[]
    );
    const searchedPolicies = !searchValue
      ? filteredPolicies
      : matchSorter(filteredPolicies, searchValue, {
          // use the default sort order as tie breaker
          baseSort: (a, b) => (a.index < b.index ? -1 : 1),
          keys: ['meta.name', 'meta.description', 'meta.tags'],
          threshold: matchSorter.rankings.CONTAINS,
        });

    return _sortBy(searchedPolicies, [(p) => p.meta.name.toLowerCase()]);
  }, [filters, initialPolicies, searchValue]);

  const allProjectSelectors = useMemo(() => {
    return workingPolicies.reduce((acc, policy) => {
      return acc.concat(
        processLabelValues(policy.spec?.project_selector ?? [])
      );
    }, [] as string[]);
  }, [workingPolicies]);

  const allProjectExceptions = useMemo(() => {
    return workingPolicies.reduce((acc, policy) => {
      return acc.concat(
        processLabelValues(policy.spec?.project_exceptions ?? [])
      );
    }, [] as string[]);
  }, [workingPolicies]);

  const qListProjects = useListAllProjects(namespace, {
    staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
  });

  const allProjects = useMemo(() => {
    return qListProjects.data ?? [];
  }, [qListProjects.data]);

  // Utility method. From available projects, filter to those with matching +/- labels.
  const findProjectsForPolicies = useCallback(
    (
      projectSelectors: string[] = [],
      projectExclusions: string[] = []
    ): ProjectResource[] => {
      const selectors = processLabelValues(projectSelectors);
      const exclusions = processLabelValues(projectExclusions);

      // Policy applies to all projects if no selectors are provided
      const selected =
        selectors.length > 0
          ? allProjects.filter((project) => {
              return _intersection(project.meta.tags, selectors).length > 0;
            })
          : allProjects;

      const selectedMinusExcluded = selected.filter((project) => {
        return _intersection(project.meta.tags, exclusions).length === 0;
      });

      return selectedMinusExcluded;
    },
    [allProjects]
  );

  const projectsForAllPolicies = useMemo(() => {
    return findProjectsForPolicies(allProjectSelectors, allProjectExceptions);
  }, [allProjectExceptions, allProjectSelectors, findProjectsForPolicies]);

  const getProjectsForPolicy = (policyUuid?: string) => {
    const relevantPolicy = workingPolicies.find(
      (policy) => policy.uuid === policyUuid
    );

    return relevantPolicy
      ? findProjectsForPolicies(
          relevantPolicy.spec?.project_selector,
          relevantPolicy.spec?.project_exceptions
        )
      : [];
  };

  const getProjectCountForPolicy = (policyUuid?: string) => {
    return getProjectsForPolicy(policyUuid).length;
  };

  return {
    getProjectCountForAllPolicies: () => projectsForAllPolicies.length,
    getProjectCountForPolicy,
    getProjectsForPolicy,
    getProjectsForAllPolicies: () => projectsForAllPolicies,
    policies: workingPolicies,
    totalPolicyCount: initialPolicies.length,
  };
};
