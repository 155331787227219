import { SvgIconProps } from '@mui/material';

import { IconCheckCircle } from '../../themes';
import { NilDisplay } from '../NilDisplay/NilDisplay';

export interface StatusDisplayProps extends SvgIconProps {
  status: 'success' | 'nil';
}

export const StatusDisplay = ({ status, ...rest }: StatusDisplayProps) => {
  if (status === 'success')
    return <IconCheckCircle {...rest} color="success" />;

  if (status === 'nil') return <NilDisplay {...rest} />;

  return null;
};
