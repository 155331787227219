import { clone as _clone } from 'lodash-es';
import { useMemo } from 'react';

import {
  QueryJoinFilters,
  SpecFindingLevel,
  V1Ecosystem,
} from '@endorlabs/api_client';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  EMPTY_FINDING_LEVEL_COUNTS,
  QueryProjectsResponseObject,
  sortParamBuilders,
  tryParseGroupResponseAggregationKey,
  useListFindings,
  useListPackageVersions,
  useQueryProjects,
} from '@endorlabs/queries';
import {
  DataTablePaginator,
  FindingCountDisplayProps,
} from '@endorlabs/ui-common';

import { mapToProjectTableRows } from './utils';

export const useProjectsIndexPageData = ({
  namespace,
  paginator,
  projectQueryFilters,
}: {
  namespace: string;
  paginator: DataTablePaginator;
  projectQueryFilters: QueryJoinFilters[];
}) => {
  const qQueryProjects = useQueryProjects(
    namespace,
    {
      ...paginator.getListParameters(),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    },
    {
      filters: projectQueryFilters,
      includeReferences: false,
    }
  );

  const qQueryProjectsWithReferences = useQueryProjects(
    namespace,
    {
      ...paginator.getListParameters(),
      sort: sortParamBuilders.descendingBy('meta.update_time'),
    },
    {
      filters: projectQueryFilters,
      includeReferences: true,
    }
  );

  // collect the list of projects, their uuids, and next page token (if present)
  const [projectsWithoutReferences, projectUuids, nextPageToken] =
    useMemo(() => {
      const projects = qQueryProjects.data?.list?.objects ?? [];
      const nextPageToken =
        qQueryProjects.data?.list?.response?.next_page_token;
      const projectUuids = projects.map((p) => p.uuid).sort();
      return [projects, projectUuids, nextPageToken];
    }, [qQueryProjects.data]);

  const qProjectEcosystemGroups = useListPackageVersions(
    namespace,
    { enabled: !!projectsWithoutReferences.length },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `spec.project_uuid in ["${projectUuids.join('","')}"]`,
      ]),
      group: {
        aggregation_paths: 'spec.project_uuid',
        unique_value_paths: 'spec.ecosystem',
      },
    }
  );

  const qProjectFindingGroups = useListFindings(
    namespace,
    { enabled: !!projectsWithoutReferences.length },
    {
      filter: filterExpressionBuilders.and([
        filterExpressionBuilders.mainResourceContext(),
        `spec.project_uuid in ["${projectUuids.join('","')}"]`,
        `spec.finding_categories not contains [FINDING_CATEGORY_GHACTIONS]`,
      ]),
      group: { aggregation_paths: 'spec.project_uuid,spec.level' },
    }
  );

  const projects = useMemo(() => {
    const projectReferencesByUuid =
      qQueryProjectsWithReferences.data?.list?.objects?.reduce(
        (acc, project) => {
          acc[project.uuid] = project.meta.references;
          return acc;
        },
        {} as Record<string, QueryProjectsResponseObject['meta']['references']>
      ) ?? {};

    const projectEcosystemGroupsByUuid = Object.entries(
      qProjectEcosystemGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, [key, group]) => {
      const values = tryParseGroupResponseAggregationKey(key);
      const projectUuid = values.find((kv) => kv.key === 'spec.project_uuid')
        ?.value as string | undefined;
      const ecosystems = (group.unique_values?.['spec.ecosystem'] ??
        []) as V1Ecosystem[];

      if (projectUuid && ecosystems) {
        acc[projectUuid] = {
          ecosystems,
          count: group.aggregation_count?.count as number,
        };
      }

      return acc;
    }, {} as Record<string, { ecosystems: V1Ecosystem[]; count: number }>);

    const isLoadingProjectFindingGroups = qProjectFindingGroups.isLoading;
    const projectFindingGroupsByUuid = Object.entries(
      qProjectFindingGroups.data?.group_response?.groups ?? {}
    ).reduce((acc, [key, group]) => {
      const values = tryParseGroupResponseAggregationKey(key);
      const projectUuid = values.find((kv) => kv.key === 'spec.project_uuid')
        ?.value as string | undefined;
      const findingLevel = values.find((kv) => kv.key === 'spec.level')
        ?.value as SpecFindingLevel | undefined;
      const count = group.aggregation_count?.count ?? 1;

      if (projectUuid && findingLevel) {
        let findingCounts = Reflect.get(acc, projectUuid);
        if (!findingCounts) {
          findingCounts = _clone(EMPTY_FINDING_LEVEL_COUNTS);
        }

        const ix = findingCounts.findIndex((c) => c.level === findingLevel);
        if (ix !== -1) {
          findingCounts[ix] = { level: findingLevel, value: count };
        }

        acc[projectUuid] = findingCounts;
      }

      return acc;
    }, {} as Record<string, FindingCountDisplayProps[]>);

    const projects = qQueryProjects.data?.list?.objects?.map((project) => {
      // If project references are loaded, merge with the base project
      if (Reflect.has(projectReferencesByUuid, project.uuid)) {
        return {
          ...project,
          meta: {
            ...project.meta,
            references: Reflect.get(projectReferencesByUuid, project.uuid),
          },
        };
      }

      return project;
    });

    return mapToProjectTableRows(projects, (projectRow) => {
      if (Reflect.has(projectFindingGroupsByUuid, projectRow.uuid)) {
        projectRow.findingCounts = Reflect.get(
          projectFindingGroupsByUuid,
          projectRow.uuid
        );
      } else if (!isLoadingProjectFindingGroups) {
        // populate with empty finding counts, unless counts are loading
        projectRow.findingCounts = EMPTY_FINDING_LEVEL_COUNTS;
      }

      if (Reflect.has(projectEcosystemGroupsByUuid, projectRow.uuid)) {
        const { ecosystems, count } = Reflect.get(
          projectEcosystemGroupsByUuid,
          projectRow.uuid
        );
        projectRow.packagesList = ecosystems;
        projectRow.packagesCount = count;
      } else {
        projectRow.packagesList = [];
      }
      return projectRow;
    });
  }, [
    qProjectEcosystemGroups.data,
    qProjectFindingGroups.data,
    qProjectFindingGroups.isLoading,
    qQueryProjects.data,
    qQueryProjectsWithReferences.data,
  ]);

  const isLoading = [
    qQueryProjects,
    qProjectEcosystemGroups,
    qProjectFindingGroups,
    qQueryProjectsWithReferences,
  ].some((q) => q.isLoading);

  return {
    isError: qQueryProjects.isError,
    isLoading,
    nextPageToken,
    projects,
    projectsWithoutReferences,
    refetch: () => qQueryProjects.refetch(),
  };
};
