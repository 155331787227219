import {
  Box,
  FormLabel,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage as FM, useIntl } from 'react-intl';

import { ButtonCancel, ControlledTextField } from '@endorlabs/ui-common';

import { VULN_PRIORITIZATION_STAGES } from '../../hooks';

// NOTE: omitting "exploitable" from baseline options
const VULN_PRIORITIZATION_STAGE_OPTIONS = VULN_PRIORITIZATION_STAGES.filter(
  (s) => s.key !== 'EXPLOITABLE'
);

const devHoursValidationMessages = defineMessages({
  min: {
    defaultMessage: 'Dev hours value must be a number greater than 1',
  },
});

export const DevEstimateFields = () => {
  const { formatMessage } = useIntl();
  const { space } = useTheme();

  const { control, reset } = useFormContext();

  return (
    <Stack direction="column" spacing={space.md} component="fieldset">
      <FormLabel component="legend">
        <Typography variant="body1" color="text.primary" marginBottom={2}>
          <FM
            defaultMessage="Dev hours saved is an estimate calculated based on the reduction of
vulnerabilities not needed to be prioritized. The default baseline is
total open vulnerabilities."
          />
        </Typography>

        <Typography variant="body1" color="text.primary">
          <FM
            defaultMessage="Please choose baseline (Total open vulnerabilities, Not in Test, Fix Available, Reachable) and provide approximate dev hours for triaging
vulnerabilities that apply to your AppSec program."
          />
        </Typography>
      </FormLabel>

      <Stack direction="row" spacing={space.sm} alignItems="flex-start">
        <ControlledTextField
          control={control}
          defaultValue={VULN_PRIORITIZATION_STAGE_OPTIONS[0].key}
          label={<FM defaultMessage="Baseline" />}
          name="baselineFindingsFilter"
          required
          select
        >
          {VULN_PRIORITIZATION_STAGE_OPTIONS.map((s) => (
            <MenuItem key={s.key} value={s.key}>
              <FM {...s.labelMessage} />
            </MenuItem>
          ))}
        </ControlledTextField>

        <ControlledTextField
          control={control}
          inputProps={{ min: 1 }}
          label={<FM defaultMessage="Dev Hours" />}
          name="devHours"
          type="number"
          required
          rules={{
            min: {
              value: 1,
              message: formatMessage(devHoursValidationMessages.min),
            },
          }}
        />

        <Box paddingTop={space.sm}>
          <ButtonCancel onClick={() => reset()}>
            <FM defaultMessage="Reset" />
          </ButtonCancel>
        </Box>
      </Stack>
    </Stack>
  );
};
