import { Tabs, TabsProps } from '@mui/material';
import { Search, useMatches } from '@tanstack/react-location';
import { last as _last } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { LinkTab, LinkTabProps } from './LinkTab';

interface NavigationTabsProps extends TabsProps {
  activeTab?: string | false;
  /**
   * The search parameters that should be persisted in the query string params
   * when navigating between routes from these navigation tabs
   */
  persistSearchParams?: string[];
  tabs: LinkTabProps[];
}

export function NavigationTabs({
  activeTab: targetActiveTab,
  persistSearchParams = [],
  tabs,
  variant = 'scrollable',
  ...props
}: NavigationTabsProps) {
  const searchUpdater = useCallback(
    (params?: Search<unknown>): Search<unknown> => {
      const next: Search<unknown> = {};
      // When search parameters are present, clear all params except those marked with `persistSearchParams`;
      if (params) {
        for (const key of Object.keys(params)) {
          next[key] = undefined;
        }

        for (const key of persistSearchParams) {
          next[key] = params[key];
        }
      }

      return next;
    },
    [persistSearchParams]
  );

  const matches = useMatches();
  const activeTab = useMemo(() => {
    if (targetActiveTab) return targetActiveTab;

    // If no active tab is specified fallback to match against final path segment from router
    const lastMatch = _last(matches);
    const final = lastMatch?.pathname.split('/').pop();
    return (lastMatch && tabs.find((t) => t.value === final)?.value) ?? false;
  }, [matches, tabs, targetActiveTab]);

  return (
    <Tabs variant={variant} {...props} value={activeTab}>
      {tabs.map((tab) => (
        <LinkTab key={tab.value} search={searchUpdater} {...tab} {...props} />
      ))}
    </Tabs>
  );
}
