import { QueryKey, useMutation } from 'react-query';

import { UITelemetryServiceApi, V1UITelemetry } from '@endorlabs/api_client';

import { ResourceMutateOptions } from './types';
import { getClientConfiguration } from './utils';

export interface CreateUITelemetryParams {
  namespace: string;
  resource: V1UITelemetry;
}

const BASE_KEY = 'v1/ui-telemetries';
const QK = {
  create: ({ namespace, ...params }: CreateUITelemetryParams): QueryKey =>
    [BASE_KEY, 'query', namespace, params] as const,
};
export const UITelementryQueryKeys = QK;

const getApiService = () => new UITelemetryServiceApi(getClientConfiguration());

const createUITelemetry = async (params: CreateUITelemetryParams) => {
  const api = getApiService();
  const resp = await api.uITelemetryServiceCreateUITelemetry(
    params.namespace,
    params.resource
  );

  return resp.data as V1UITelemetry;
};

export const useCreateUITelemetry = (
  options: ResourceMutateOptions<V1UITelemetry, CreateUITelemetryParams> = {}
) => {
  return useMutation({
    mutationFn: (params) => createUITelemetry(params),
    ...options,
  });
};
