import {
  Box,
  ButtonBase,
  Collapse,
  Paper,
  Stack,
  Theme,
  useTheme,
} from '@mui/material';
import clsx from 'clsx';
import { debounce as _debounce, uniqueId as _uniqueId } from 'lodash-es';
import * as React from 'react';

import { IconChevronsUp } from '../../../../themes/icons';

const DEFAULT_MIN_HEIGHT = 96;

export interface StackedCardLayoutProps {
  children: React.ReactNode;
  minHeight?: number;
  onTransitionEnd?: (event: React.TransitionEvent, expanded: boolean) => void;
  title: React.ReactNode;
  summary?: React.ReactNode;
}

/**
 * Component intended to wrap a group of Card-like elements, collapsing them into a stack behind a top Card.
 */
export const StackedCardLayout = ({
  children,
  minHeight = DEFAULT_MIN_HEIGHT,
  onTransitionEnd,
  title,
  summary,
}: StackedCardLayoutProps) => {
  const theme = useTheme();
  const collapseId = _uniqueId(`StackedCardLayout-collapse-`);

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (_: React.MouseEvent<HTMLElement>) => {
    // if the user has selected text, prevent the accordion from toggling
    const selection = window.getSelection()?.toString().trim();
    if (!selection) {
      setExpanded((prev) => !prev);
    }
  };

  const containerRef = React.useRef<HTMLDivElement>();
  const [containerHeight, setContainerHeight] = React.useState(minHeight);

  React.useLayoutEffect(() => {
    const onResize = _debounce(
      () => {
        if (containerRef.current) {
          const containerHeight = Math.ceil(
            containerRef.current.getBoundingClientRect().height
          );
          setContainerHeight(containerHeight);
        }
      },
      300,
      { leading: false }
    );

    // set initial container size, and handle window resize events
    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const handleTransitionEnd = React.useCallback(
    (event: React.TransitionEvent) => {
      if (onTransitionEnd) {
        onTransitionEnd(event, expanded);
      }
    },
    [expanded, onTransitionEnd]
  );

  const cls = clsx('StackedCardLayout', {
    'StackedCardLayout-expanded': expanded,
  });
  const sx = styles(theme, { containerHeight });

  return (
    <Box className={cls}>
      <Box ref={containerRef} sx={sx.container}>
        <Paper sx={sx.containerCard}>
          <Stack direction="column" spacing={4}>
            <ButtonBase
              onClick={handleChange}
              aria-controls={collapseId}
              sx={sx.collapseTarget}
            >
              {/* NOTE: similar to TitleActionHeader, but keeps the expand toggle pinned to the right */}
              <Stack
                alignItems="flex-start"
                direction="row"
                flexGrow={1}
                flexWrap="wrap"
                spacing={2}
                textAlign="left"
              >
                {title}
              </Stack>

              <IconChevronsUp sx={sx.collapseIcon} />
            </ButtonBase>

            {summary}
          </Stack>
        </Paper>
      </Box>

      <Collapse
        id={collapseId}
        aria-expanded={expanded}
        in={expanded}
        collapsedSize={containerHeight + 16}
        onTransitionEnd={handleTransitionEnd}
        sx={sx.collapse}
      >
        <Stack direction="column" spacing={4}>
          <Box role="presentation" height={containerHeight}>
            {/* placeholder element */}
          </Box>

          {/* children rendered inside the stack */}
          {children}
        </Stack>
      </Collapse>
    </Box>
  );
};

function styles(theme: Theme, options: { containerHeight: number }) {
  const { palette, transitions, shape, spacing } = theme;
  const { containerHeight } = options;

  return {
    collapseTarget: {
      alignItems: 'flex-start',
      textAlign: 'center',
      userSelect: 'text',
    },
    collapse: {
      paddingLeft: spacing(4),
      marginTop: `${-1 * containerHeight}px`,
      '& .MuiPaper-root': {
        height: containerHeight,
        backgroundColor: palette.grey[100],
        border: `1px dashed ${palette.divider}`,
        borderRadius: `${shape.borderRadius}px`,
        opacity: 0,
        paddingX: spacing(4),
        transition: transitions.create(['background-color', 'transform']),
        '&:before': {
          display: 'none', // hide the top border on the collapse
        },
        '&:nth-of-type(2)': {
          opacity: 1,
          transform: `translate3d(-8px, ${-1 * containerHeight - 8}px, 0)`,
          zIndex: 98,
        },
        '&:nth-of-type(3)': {
          opacity: 1,
          transform: `translate3d(0, ${-2 * containerHeight - 16}px, 0)`,
          zIndex: 97,
        },
        //Hide text on close of stack cards to prevent showing the wrapped text
        '& .MuiBox-root': {
          opacity: 0,
        },
      },
      '.StackedCardLayout-expanded & .MuiPaper-root': {
        backgroundColor: palette.background.paper,
        height: 'auto',
        opacity: 1,
        transform: `translate3d(0, 0, 0)`, // when expanded, reset the translate offsets
        '& .MuiBox-root': {
          opacity: 1,
        },
      },
    },
    collapseIcon: {
      color: palette.action.active,
      marginLeft: spacing(2),
      transition: 'transform 300ms',
      transform: 'rotate(180deg)',
      '.StackedCardLayout-expanded &': { transform: 'rotate(0)' },
    },
    container: {
      marginRight: spacing(4),
      position: 'relative',
      zIndex: 100,
    },
    containerCard: {
      padding: spacing(4),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: palette.grey[200],
    },
  };
}
