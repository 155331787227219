import { debounce as _debounce } from 'lodash-es';
import { useCallback, useRef } from 'react';

import { SpecUITelemetryEvent, V1UITelemetry } from '@endorlabs/api_client';
import { useCreateUITelemetry } from '@endorlabs/queries';
import { useLatestCallback } from '@endorlabs/ui-common';

import { EventIdentifiers } from './types';
import { getDeviceScreenInfo, getDeviceUserAgent } from './utils';

const UI_TELEMETRY_EVENT_BATCH_WAIT = 3_000;
const UI_TELEMETRY_EVENT_BATCH_MAX_WAIT = 30_000;
const UI_TELEMETRY_EVENT_BATCH_SIZE = 10;

/**
 * Manages queuing and sending UI Telemetry events
 */
export const useUITelemetryEventQueue = ({
  eventIdentifiers,
}: {
  eventIdentifiers?: EventIdentifiers;
}) => {
  const eventQueue = useRef<Map<string, SpecUITelemetryEvent[]>>(new Map());

  const qCreateUITelementry = useCreateUITelemetry();

  const sendBatch = useLatestCallback(
    _debounce(
      () => {
        const isReady = !!eventIdentifiers;

        if (!isReady) {
          // trigger to attempt send again later
          sendBatch();
          return;
        }

        // Get next events to send
        const next = eventQueue.current.entries().next();

        // If no events exist to send, exit
        if (next.done) return;

        const { userId, sessionId } = eventIdentifiers;

        const [namespace, events] = next.value;
        const pending = events.slice(0, UI_TELEMETRY_EVENT_BATCH_SIZE);
        const remaining = events.slice(UI_TELEMETRY_EVENT_BATCH_SIZE);

        const resource: V1UITelemetry = {
          meta: { name: 'UI Telemetry Events' },
          spec: {
            user_id: userId,
            session_id: sessionId,
            events: pending,
            device_screen_info: getDeviceScreenInfo(),
            device_user_agent: getDeviceUserAgent(),
          },
        };

        // Send the pending UI Telemetry events
        // NOTE: events that fail to send are not currently re-queued
        qCreateUITelementry.mutate({ namespace, resource });

        // Update event queue
        if (remaining.length) {
          eventQueue.current.set(namespace, remaining);
        } else {
          eventQueue.current.delete(namespace);
        }

        // If additional events remain in the queue, trigger send again
        if (eventQueue.current.size) {
          sendBatch();
        }
      },
      UI_TELEMETRY_EVENT_BATCH_WAIT,
      { maxWait: UI_TELEMETRY_EVENT_BATCH_MAX_WAIT }
    )
  );

  const enqueue = useCallback(
    (namespace: string, events: SpecUITelemetryEvent[]) => {
      const current = eventQueue.current.get(namespace) ?? [];
      eventQueue.current.set(namespace, current.concat(events));
      sendBatch();
    },
    [sendBatch]
  );

  return { enqueue };
};
