import { UseNavigateType } from '@tanstack/react-location';
import Shepherd from 'shepherd.js';

import { ProjectResource } from '@endorlabs/endor-core/Project';

export const TourStepNames = {
  assuredPackageVersion: 'assuredPackageVersion',
  dashboard: 'dashboard',
  projectFindings: 'projectFindings',
  remediations: 'remediations',
} as const;

export type TourStepName = (typeof TourStepNames)[keyof typeof TourStepNames];

// !!! NOTE: This constant determines the final ordered list of steps !!!
export const TourSteps = [
  TourStepNames.dashboard,
  TourStepNames.projectFindings,
  TourStepNames.remediations,
  TourStepNames.assuredPackageVersion,
];

export interface ProductTourInputData {
  assuredPackageVersionName: string;
  findingsProjectName: string;
  remediationsDependencyName: string;
  tourTenant: string;
  vulnFindingName: string;
}

export interface ProductTourData {
  assuredPackageVersionName: string;
  assuredPackageVersionUuid?: string;
  findingsProject?: ProjectResource;
  lastUsedTenant?: string;
  remediationsDependencyName?: string;
  tourTenant: string;
  vulnFindingUuid?: string;
}

export interface BasicTourStepProps {
  id: TourStepName;
  namespace?: string;
  navigate: UseNavigateType;
  stepNumber: number;
  tour?: Shepherd.Tour;
  tourData: ProductTourData;
  uuid?: string;
}

export type TourStepDefinition = Shepherd.Step.StepOptions & {
  // Primary step text
  description?: string;
  // All elements to highlight
  highlightSelectors?: string[];
  // Number of the step (not 0-indexed)
  stepNumber: number;
  // Step will navigate to this URL before showing
  stepUrl?: string;
  // URL for the "Learn More" button
  urlLearn?: string;
};
