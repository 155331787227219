import { defineMessage } from 'react-intl';

import { createFileRoute } from '../../../../utils/tanstack-react-router-shim';

const ROUTE_TITLE = defineMessage({
  defaultMessage: 'SBOM Detail',
});

export const Route = createFileRoute(
  '/t/:namespace/third-party/sboms/:sbom_uuid'
)({
  beforeLoad: () => ({ title: ROUTE_TITLE }),
  children: [{ path: ':active_view' }],
});
