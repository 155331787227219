import { Chip, Stack, Theme, Typography, useTheme } from '@mui/material';

import { GraphEdgeDirection, GraphNode } from '@endorlabs/utils/graph';

import { ICON_SIZES } from '../../themes/endor/SvgIcons';
import { GraphPathListEdgeIndicator } from './GraphPathListEdgeIndicator';
import { GraphPathListNodeAttributes } from './types';
import { getGraphPathListNodeColor } from './utils';

export interface GraphPathListNodeProps {
  node: GraphNode<GraphPathListNodeAttributes>;
  direction: GraphEdgeDirection;
}

export const GraphPathListNode = ({
  node,
  direction,
}: GraphPathListNodeProps) => {
  const theme = useTheme();
  const sx = styles(theme);

  // get attributes from node
  const {
    id,
    attributes: { Icon, label, tags, fontWeight },
  } = node;
  const color = getGraphPathListNodeColor(theme.palette, node.attributes.color);

  return (
    <Stack
      className="GraphPathList-node"
      component="li"
      direction="row"
      gap={2}
    >
      <Stack alignItems="center" direction="column">
        <Icon fontSize="small" htmlColor={color} />

        <GraphPathListEdgeIndicator direction={direction} />
      </Stack>

      <Stack
        className="GraphPathList-nodeLabel"
        direction="row"
        gap={1}
        alignSelf="start"
        alignItems="center"
        flexWrap="wrap"
        sx={sx.nodeLabel}
      >
        <Typography
          sx={sx.nodeLabelText}
          color={color}
          fontWeight={fontWeight}
          variant="body2"
        >
          {label ?? id}
        </Typography>

        {tags && tags.map((t) => <Chip key={t} label={t} sx={sx.chip} />)}
      </Stack>
    </Stack>
  );
};

const styles = ({ typography, spacing }: Theme) => ({
  chip: {
    height: 'auto',
    '& .MuiChip-label': {
      padding: spacing(0.5, 2),
    },
  },

  nodeLabel: {
    paddingBottom: spacing(4),
    '.GraphPathList-node:last-of-type &': {
      paddingBottom: 0,
    },
  },

  nodeLabelText: {
    // NOTE: the line-height here matches the height (font-size) of the node icon
    lineHeight: typography.pxToRem(ICON_SIZES.small),
    wordBreak: 'break-word',
  },
});
