import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import subMinutes from 'date-fns/subMinutes';
import create from 'zustand';

import {
  SpecEndorLicenseType,
  UserInfoResponseTenantInfo,
  V1UserInfoResponse,
} from '@endorlabs/api_client';
import { TenantType } from '@endorlabs/endor-core/auth';
import {
  getRootNamespace,
  isChildNamespace,
} from '@endorlabs/endor-core/Namespace';
import { WithRequired } from '@endorlabs/utils';

import { GLOBAL_TENANTS, SHARED_TENANTS } from './tenants';
import { UserResource } from './types';
import { useUserPreferences } from './useUserPreferences';

/* A session is considered "expiring" this long before expiration.
 * Used to trigger "Please renew your login" prompt.
 */
export const EXPIRATION_WINDOW_IN_MINUTES = 5;

export interface TenantInfo
  extends WithRequired<UserInfoResponseTenantInfo, 'name' | 'uuid'> {
  tenantType: TenantType;
}

export interface UserSession extends V1UserInfoResponse {
  lastUsedTenant?: string;
  tenants?: TenantInfo[];
  user?: UserResource;
  expiration_time?: string;
}

export interface SessionStore {
  canAccessTenant: (t: string) => boolean;
  clearSession: () => void;
  getAuthProvider: () => string | undefined;
  getLastUsedTenant: (includeFallback?: boolean) => string | undefined;
  hasValidTenantAccess: () => boolean;
  getUser: () => UserResource | undefined;
  getUserNamespaces: () => string[];
  getUserTenants: () => TenantInfo[];
  isEndorUser: () => boolean;
  isSessionExpiring: () => boolean;
  isSessionExpired: () => boolean;
  session?: UserSession;
  setLastUsedTenant: (t: string) => void;
  updateSession: (s: V1UserInfoResponse | undefined) => void;
}

export const useSession = create<SessionStore>(
  (setState, getState): SessionStore => {
    const getTenants = () => getState().session?.tenants ?? [];

    const getUserTenants = () =>
      getTenants().filter((t) => !isGlobalTenant(t.name));

    const canAccessNamespace = (namespace: string): boolean => {
      return getTenants().some(
        (t) =>
          // support root tenant namespaces
          t.name === namespace ||
          // also support child namespaces
          isChildNamespace(namespace, t.name)
      );
    };

    return {
      canAccessTenant: (tenantName) => canAccessNamespace(tenantName),

      clearSession: () => setState({ session: undefined }),

      getAuthProvider: () => getState().session?.authentication_source,

      getLastUsedTenant: (includeFallback = false) => {
        const sess = getState().session;
        const lastUsedTenant = sess?.lastUsedTenant;
        const userTenants = getUserTenants();
        if (userTenants.length === 0) return undefined;

        // If including fallback, return the user tenant available
        return includeFallback
          ? lastUsedTenant ?? userTenants[userTenants.length - 1]?.name
          : lastUsedTenant;
      },

      getUser: () => getState().session?.user,
      getUserNamespaces: () => getState().session?.namespaces ?? [],
      getUserTenants,

      hasValidTenantAccess: () => {
        const tenants = getTenants();
        const excludedTenants = SHARED_TENANTS.concat(GLOBAL_TENANTS);
        const validTenants = tenants.filter(
          (t) => !excludedTenants.includes(t.name)
        );
        return validTenants.length > 0;
      },

      isEndorUser: () =>
        getState().session?.user?.spec.email?.endsWith('@endor.ai') ?? false,

      isSessionExpired: () => {
        const sess = getState().session;
        return sess
          ? isSessionExpired(new Date(sess.expiration_time as string))
          : true;
      },
      isSessionExpiring: () => {
        const sess = getState().session;
        return sess
          ? isSessionExpiring(new Date(sess.expiration_time as string))
          : false;
      },

      session: undefined,

      setLastUsedTenant: (namespace: string) => {
        if (isGlobalTenant(namespace)) return;
        if (!canAccessNamespace(namespace)) return;

        // when the user chooses a tenant, also persist as the default tenant for preferences
        useUserPreferences.setState((state) => ({
          ...state,
          // NOTE: only persisting the tenant name, instead of a child namespace
          defaultTenant: getRootNamespace(namespace),
        }));

        setState((state) => ({
          ...state,
          session: {
            ...state.session,
            lastUsedTenant: namespace,
          },
        }));
      },

      updateSession: (newSessionState) => {
        // Shallow clone the provided session object to create the next
        const nextSession = Object.assign({}, newSessionState) as UserSession;

        const lastUsedTenant =
          getState().session?.lastUsedTenant ??
          useUserPreferences.getState().defaultTenant;
        // Check if user session has access to last used tenant
        if (
          lastUsedTenant &&
          newSessionState?.tenants?.some((t) => t.name === lastUsedTenant)
        ) {
          nextSession.lastUsedTenant = lastUsedTenant;
        }

        // Validate and sort the user tenants
        nextSession.tenants = validateTenantInfo(
          newSessionState?.tenants ?? []
        );

        setState({ session: nextSession });
      },
    };
  }
);

const isSessionExpiring = (expTime: Date) => {
  return isWithinInterval(Date.now(), {
    start: subMinutes(expTime, EXPIRATION_WINDOW_IN_MINUTES),
    end: expTime,
  });
};

const isSessionExpired = (expTime: Date) => {
  return isAfter(Date.now(), expTime);
};

const isGlobalTenant = (tenantName?: string) =>
  !tenantName ? false : GLOBAL_TENANTS.includes(tenantName);

const validateTenantInfo = (
  tenants: UserInfoResponseTenantInfo[]
): TenantInfo[] => {
  return tenants
    .filter((t): t is TenantInfo => !!t.name && !!t.uuid)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((tenantInfo) => {
      // Default tenant as paid
      let tenantType = TenantType.Premium;

      // Handle paid tenant type from license
      if (
        tenantInfo.license_type === SpecEndorLicenseType.Pov ||
        tenantInfo.license_type === SpecEndorLicenseType.Premium
      ) {
        tenantType = TenantType.Premium;
      }

      // Handle trial tenant type from license
      if (tenantInfo.license_type === SpecEndorLicenseType.FreeTrial) {
        const isExpired =
          tenantInfo.expiry && isAfter(Date.now(), new Date(tenantInfo.expiry));

        tenantType = isExpired ? TenantType.TrialExpired : TenantType.Trial;
      }

      // Override tenant type based on known tenant names
      if (GLOBAL_TENANTS.includes(tenantInfo.name)) {
        tenantType = TenantType.Global;
      } else if (SHARED_TENANTS.includes(tenantInfo.name)) {
        tenantType = TenantType.Shared;
      }

      return {
        ...tenantInfo,
        tenantType,
      };
    });
};
