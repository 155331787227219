import { PropsWithChildren, useCallback } from 'react';

import { V1Meta } from '@endorlabs/api_client';
import { ResourceKind } from '@endorlabs/endor-core';
import { FilterExpression } from '@endorlabs/filters';

import {
  useFilterParams,
  useResourceFilterSavedQueries,
  useResourceFilterValidation,
} from '../hooks';
import { FilterStateValues, useFilterState } from '../hooks/useFilterState';
import { FilterContext } from './FilterContext';
import { FilterSavedFiltersContext } from './FilterSavedFiltersContext';
import { FilterValidationContext } from './FilterValidationContext';

const DEFAULT_RESOURCE_SEARCH_KEYS: `meta.${keyof V1Meta}`[] = [
  'meta.name',
  'meta.description',
  'meta.tags',
];

// utility to build filter expression from a given search value and resource keys
const buildResourceSearchFilter = (
  searchKeys: string[],
  searchValue?: string
): FilterExpression | undefined => {
  const trimmed = searchValue?.trim();
  if (!trimmed) return;

  const expression = searchKeys
    .map((key) => `${key} matches "${trimmed}"`)
    .join(' or ');

  return `(${expression})`;
};

export type ResourceFilterContextProviderProps = PropsWithChildren<{
  baseFilter?: FilterExpression;
  defaultFilterValues?: FilterStateValues;
  initialFilterValues?: FilterStateValues;
  namespace: string;
  resourceKind: ResourceKind;
  resourceSearchKeys?: string[];
}>;

/**
 * Provides Filter, Filter Validation, and Saved Filters for a given tenant resource
 */
export const ResourceFilterContextProvider = ({
  baseFilter,
  children,
  defaultFilterValues,
  initialFilterValues,
  namespace,
  resourceKind,
  resourceSearchKeys = DEFAULT_RESOURCE_SEARCH_KEYS,
}: ResourceFilterContextProviderProps) => {
  const buildSearchFilter = useCallback(
    (searchValue: string) => {
      return buildResourceSearchFilter(resourceSearchKeys, searchValue);
    },
    [resourceSearchKeys]
  );

  const store = useFilterParams({ initialFilterValues });
  const filterState = useFilterState({
    baseFilter,
    buildSearchFilter,
    defaultFilterValues,
    searchKeys: resourceSearchKeys,
    store,
  });

  const savedFilters = useResourceFilterSavedQueries(namespace, resourceKind);
  const filterValidation = useResourceFilterValidation(
    namespace,
    resourceKind,
    filterState.filter,
    // Only validate filter if using a raw expression
    { enabled: !filterState.isParseable }
  );

  return (
    <FilterContext.Provider value={filterState}>
      <FilterSavedFiltersContext.Provider value={savedFilters}>
        <FilterValidationContext.Provider value={filterValidation}>
          {children}
        </FilterValidationContext.Provider>
      </FilterSavedFiltersContext.Provider>
    </FilterContext.Provider>
  );
};
