import { MakeGenerics, Navigate, useSearch } from '@tanstack/react-location';

import { useAuthentication } from '@endorlabs/queries';

import { shouldAllowRedirect } from '../routes';
import { NamedRoutes, RedirectParams } from '../routes/constants';

type RootRouteLocationGenerics = MakeGenerics<{
  Search: {
    redirect?: string;
    event?: string;
  };
}>;

export const PublicHomePage = () => {
  const auth = useAuthentication();
  const { redirect, ...otherQueryParams } =
    useSearch<RootRouteLocationGenerics>();

  // WHEN authentication is pending
  // > TODO: show loading state
  if (auth.isLoading) {
    return null;
  }

  // WHEN the user is not authenticated
  // > redirect to the login page
  if (!auth.isAuthenticated) {
    return <Navigate to={NamedRoutes.LOGIN} />;
  }

  // WHEN `redirect` search param is included
  if (redirect) {
    // AND the redirect value exists as a named preset
    // > redirect the user
    if (Reflect.has(RedirectParams, redirect)) {
      return (
        <Navigate to={RedirectParams[redirect]} search={otherQueryParams} />
      );
    }

    // parse the redirect into pathname and search
    const url = new URL(redirect, location.origin);

    // AND the redirect value is allowed
    // > redirect the user
    if (shouldAllowRedirect(url)) {
      return <Navigate to={redirect} search={otherQueryParams} />;
    }
  }

  // WHEN the user is authenticated, and has no redirect given
  // > redirect to a default route
  return <Navigate to={NamedRoutes.TENANTS_INDEX} />;
};
