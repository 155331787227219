import { Button, ButtonProps, useTheme } from '@mui/material';
import { SvgIconProps } from '@mui/material';
import { JSXElementConstructor } from 'react';
export interface ButtonExternalProps extends ButtonProps {
  Icon?: JSXElementConstructor<SvgIconProps>;
  imageUrl?: string;
  platformName?: string;
}

/**
 * Button that links to or interacts with an external platform.
 * Handles icon display & uses distinctive styles to differentiate 3rd party action.
 */
export const ButtonLogin = ({
  Icon,
  imageUrl,
  ...props
}: ButtonExternalProps) => {
  const { palette } = useTheme();
  return (
    <Button
      {...props}
      size="large"
      startIcon={
        Icon ? (
          <Icon fontSize="medium" />
        ) : imageUrl ? (
          <img alt="" src={imageUrl} height="22" />
        ) : undefined
      }
      variant="outlined"
      sx={{
        background: palette.common.white,
        border: `1px solid ${palette.grey[400]}`,
        borderRadius: '16px',
        justifyContent: 'flex-start',
      }}
    />
  );
};
