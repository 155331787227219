import { Card, CardContent, Grid } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { ColumnSort } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  sortParamBuilders,
  useCountServiceRequest,
  useQueryServiceRequests,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  ButtonPrimary,
  EmptyState,
  IconArtifactRegistry,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { PageHeader, PageHeaderCount } from '../../components';
import {
  FilterBar,
  ResourceFilterContextProvider,
  useFilterContext,
} from '../../domains/filters';
import { SERVICE_REQUEST_FILTER_FIELDS } from '../../domains/ServiceRequest';
import { useAuthInfo } from '../../providers';
import { getServiceRequestPath } from '../../routes';
import { ServiceRequestColumnIdMap } from './constants';
import {
  ServiceRequestTable,
  ServiceRequestTableRow,
} from './ServiceRequestTable';
import { mapToServiceRequestTableRow } from './utils';

export const BaseIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  const navigate = useNavigate();

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!nextPageToken,
  });

  const [columnSort, setColumnSort] = useState<ColumnSort | undefined>({
    id: 'updateTime',
    desc: true,
  });
  const { filter: userFilterExpression, clearFilter } = useFilterContext();

  const handleSortChange = (columnSort?: ColumnSort | undefined) => {
    if (columnSort) {
      setColumnSort(columnSort);
    }
  };

  const sortParam = useMemo(() => {
    if (columnSort) {
      const columnId = ServiceRequestColumnIdMap[columnSort.id];
      if (columnId) {
        return columnSort.desc
          ? sortParamBuilders.descendingBy(columnId)
          : sortParamBuilders.ascendingBy(columnId);
      }
    }
    return sortParamBuilders.descendingBy('meta.update_time');
  }, [columnSort]);

  const qCountServiceRequest = useCountServiceRequest(tenantName, {
    staleTime: Infinity,
  });

  const qServiceRequest = useQueryServiceRequests(tenantName, {
    filter: userFilterExpression,
    ...paginator.getListParameters(),
    sort: sortParam,
    mask: [
      'uuid',
      'meta.name',
      'meta.create_time',
      'meta.created_by',
      'meta.update_time',
      'spec.description',
      'spec.method',
      'spec.resource',
      'spec.status',
      'spec.target_namespace',
    ].join(),
  });

  const [serviceRequests, nextPageToken] = useMemo(() => {
    const serviceRequests = qServiceRequest.data?.list?.objects ?? [];
    const nextPageToken = qServiceRequest.data?.list?.response?.next_page_token;
    return [serviceRequests, nextPageToken];
  }, [qServiceRequest.data]);

  const handleNavClick = () => {
    navigate({ to: getServiceRequestPath({ tenantName, uuid: 'new' }) });
  };

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginator.resetPagination, tenantName, userFilterExpression]
  );

  const hasError = qServiceRequest.isError;
  const isLoading = qServiceRequest.isLoading;
  const isEmptyState =
    !isLoading &&
    !hasError &&
    serviceRequests.length === 0 &&
    !userFilterExpression;
  const rows: ServiceRequestTableRow[] = useMemo(() => {
    return mapToServiceRequestTableRow(serviceRequests);
  }, [serviceRequests]);

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      <Grid item>
        <PageHeader
          action={
            <Grid alignItems="center" container justifyContent="flex-end">
              <Grid item>
                <ButtonPrimary onClick={handleNavClick}>
                  <FM defaultMessage="Create Service Request" />
                </ButtonPrimary>
              </Grid>
            </Grid>
          }
          Icon={IconArtifactRegistry}
          title={<FM defaultMessage="Service Requests" />}
          titleDetails={
            <PageHeaderCount value={qCountServiceRequest?.data?.count} />
          }
        />
      </Grid>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={<FM defaultMessage="No Service Request to display." />}
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <FilterBar fields={SERVICE_REQUEST_FILTER_FIELDS} />
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardContent>
              <ServiceRequestTable
                data={rows}
                emptyStateProps={{
                  title: <FM defaultMessage="No Service requests found." />,
                  children: (
                    <ButtonCancel onClick={clearFilter}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonCancel>
                  ),
                }}
                isLoading={isLoading}
                namespace={tenantName}
                paginator={paginator}
                enableColumnSort
                onColumnSort={handleSortChange}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const ServiceRequestIndexPage = () => {
  const { activeNamespace: tenantName } = useAuthInfo();
  return (
    <ResourceFilterContextProvider
      namespace={tenantName}
      resourceKind="ServiceRequest"
      resourceSearchKeys={['spec.target_namespace', 'spec.description']}
    >
      <BaseIndexPage />
    </ResourceFilterContextProvider>
  );
};
