import { Box, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { FormattedMessage as FM } from 'react-intl';

import { ButtonLinkSecondary, IconInfo } from '@endorlabs/ui-common';

export const NoTenantAccount = () => {
  const theme = useTheme();
  const sx = styles(theme);
  return (
    <Grid
      container
      direction="column"
      spacing={4}
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Grid
        container
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container direction="column" item xs={12} md={3} spacing={3}>
          <Grid item>
            <Typography sx={sx.heading}>
              <FM defaultMessage="No Available Tenants Found" />
            </Typography>
          </Grid>
          <Grid item>
            <Stack
              direction="column"
              sx={{ background: theme.palette.background.paper, padding: 4 }}
              spacing={4}
            >
              <Stack direction="row" sx={sx.errorText} spacing={2}>
                <IconInfo sx={{ color: theme.palette.severity.high }} />
                <Typography variant="body1">
                  <FM defaultMessage="To use Endor Labs, you must have received an invitation to an pre-existing account." />
                </Typography>
              </Stack>
              <Box>
                <ButtonLinkSecondary
                  linkProps={{
                    to: '/login',
                    replace: false,
                  }}
                  size="small"
                >
                  <FM defaultMessage="Return to Sign In" />
                </ButtonLinkSecondary>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

function styles({ breakpoints, palette }: Theme) {
  return {
    heading: {
      fontSize: '41px',
      fontWeight: '600',
      padding: '8px 16px',
      fontFamily: 'Switzer',
      textAlign: 'center',
    },
    errorText: {
      background: 'rgba(234, 186, 18, 0.25)',
      borderRadius: '4px',
      padding: 3,
      alignItems: 'center',
    },
  };
}
