import { Palette } from '@mui/material';
import _get from 'lodash-es/get';

import { Color } from '@endorlabs/ui-common';

import {
  ChartPaletteDefault,
  ChartPaletteFindingLevel,
  ChartPaletteFindingPriority,
} from '../constants/colors';
import { DashboardBucketContext, DashboardBucketContexts } from '../types';

const getChartPalette = (context: DashboardBucketContext | undefined) => {
  switch (context) {
    case DashboardBucketContexts.FINDING_SEVERITY:
      return ChartPaletteFindingLevel;
    case DashboardBucketContexts.FINDING_PRIORITY_BUCKETS:
      return ChartPaletteFindingPriority;
    default:
      return ChartPaletteDefault;
  }
};

export const getChartColors = (
  context: DashboardBucketContext | undefined,
  themePalette: Palette
) => {
  const spectrum = getChartPalette(context);

  return spectrum.map((color) => _get(themePalette, color)) as Color[];
};
