import { TextField, TextFieldProps } from '@mui/material';
import _noop from 'lodash-es/noop';
import {
  Controller,
  ControllerProps,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

export type ControlledTextFieldProps<TFieldValues extends FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  // `control` prop is required for this component, but may be replaced with
  // the use of `FormProvider` in the future.
  Required<Pick<ControllerProps<TFieldValues>, 'control'>> &
  TextFieldProps & { htmlName?: string };

/**
 * A composite component that reduces boilerplate for the common pattern of a TextField controlled by react-hook-form.
 * Combines component interfaces, with most props belonging to TextField.
 * Handles render prop & common error state patterns.
 */
export const ControlledTextField = <T extends FieldValues>({
  control,
  defaultValue,
  helperText,
  htmlName,
  inputProps,
  name,
  rules,
  ...textFieldProps
}: ControlledTextFieldProps<T>) => {
  const { formatMessage: fm } = useIntl();

  // Add react-hook-form required rule based on TextField `required` prop
  if (textFieldProps.required) {
    rules = {
      required: {
        value: true,
        message: fm({ defaultMessage: 'This field is required' }),
      },
      ...rules,
    };
  }

  return (
    <Controller
      control={control}
      // Default value to empty string
      defaultValue={defaultValue ?? ('' as PathValue<T, Path<T>>)}
      // NOTE: `name` prop is used for react-hook-form. Input `name` can be set with `htmlName`.
      name={name}
      render={({ field: { ref, ...props }, fieldState }) => {
        return (
          <TextField
            // Common defaults
            autoComplete="off"
            fullWidth
            // Standard TextField props
            {...textFieldProps}
            // Controller props take precedence
            {...props}
            // Overrides
            error={!!fieldState.error}
            helperText={
              fieldState.error ? fieldState.error.message : helperText
            }
            inputProps={{ ...inputProps, name: htmlName }}
            inputRef={ref}
            onChange={(evt) => {
              // If not explicitly set, set event target name to controlled field name,
              // so that react-hook-form can operate on it.
              evt.target.name = htmlName ?? name;

              // Call all change event handlers
              props.onChange(evt);
              (textFieldProps.onChange ?? _noop)(evt);
            }}
          />
        );
      }}
      rules={rules}
    />
  );
};
