import { useMemo } from 'react';

import { V1Ecosystem } from '@endorlabs/api_client';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import { useListPackageVersions } from '@endorlabs/queries';

import { StaleTimes } from '../../../constants';

export const useProjectPackageEcosystems = (
  project?: ProjectResource,
  projectVersion?: ProjectVersionResource
) => {
  const relatedResourceContextFilter =
    filterExpressionBuilders.relatedResourceContext(projectVersion) ??
    filterExpressionBuilders.mainResourceContext();

  const projectNamespace = project?.tenant_meta.namespace ?? '';

  const qGroupPackageVersions = useListPackageVersions(
    projectNamespace,
    { enabled: !!project, staleTime: StaleTimes.MEDIUM },
    {
      filter: filterExpressionBuilders.and([
        relatedResourceContextFilter,
        `spec.project_uuid=="${project?.uuid}"`,
      ]),
      group: {
        aggregation_paths: 'spec.project_uuid',
        unique_value_paths: 'spec.ecosystem',
      },
      traverse: false,
    }
  );

  const data = useMemo(() => {
    const groupData = Object.values(
      qGroupPackageVersions.data?.group_response?.groups ?? {}
    ).at(0);

    const count = groupData?.aggregation_count?.count;
    const ecosystems = (groupData?.unique_values?.['spec.ecosystem'] ??
      []) as V1Ecosystem[];

    return { count, ecosystems };
  }, [qGroupPackageVersions.data]);

  return {
    data,
    isLoading: qGroupPackageVersions.isLoading,
  };
};
