import { createContext } from 'react';

import { SavedFilter } from '../types';

export interface FilterSavedFiltersContextValue {
  createSavedFilter: (newSavedFilter: Omit<SavedFilter, 'id'>) => void;
  isLoading: boolean;
  savedFilters: SavedFilter[];
}

export const FilterSavedFiltersContext =
  createContext<FilterSavedFiltersContextValue>({
    createSavedFilter: () => {
      throw new Error('Missing provider for FilterSavedFiltersContext');
    },
    isLoading: false,
    savedFilters: [],
  });
