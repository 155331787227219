import { Divider, Stack, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ChangeType, V1ReleaseUpgradeInfo } from '@endorlabs/api_client';
import {
  EmptyState,
  ExpandCollapseControl,
  getPaginatorPageSlice,
  RowStack,
  SimplePagination,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import {
  BreakingChangeConfidenceOrder,
  BreakingChangeTypeOrder,
} from '../../constants';
import { BreakingChangeDisplay } from '../BreakingChangeDisplay/BreakingChangeDisplay';

const BREAKING_CHANGES_PAGE_SIZE = 5;

export const BreakingChangesSection = ({
  upgradeInfo,
}: {
  upgradeInfo?: V1ReleaseUpgradeInfo;
}) => {
  const { space } = useTheme();

  const [allExpanded, setAllExpanded] = useState(true);

  const allBreakingChanges = useMemo(() => {
    const breakingChanges = upgradeInfo?.cia_results ?? [];
    breakingChanges.sort((a, b) => {
      if (!a.confidence || !b.confidence) {
        return 0;
      }

      const changeTypeA =
        a.function_change?.diff_change ??
        a.type_change?.diff_change ??
        V1ChangeType.Unspecified;

      const changeTypeB =
        b.function_change?.diff_change ??
        b.type_change?.diff_change ??
        V1ChangeType.Unspecified;

      // Sort by confidence first, then by change type for each confidence level
      return (
        (BreakingChangeConfidenceOrder[a.confidence] ?? 0) -
          (BreakingChangeConfidenceOrder[b.confidence] ?? 0) ||
        (BreakingChangeTypeOrder[changeTypeA] ?? 0) -
          (BreakingChangeTypeOrder[changeTypeB] ?? 0)
      );
    });
    return breakingChanges;
  }, [upgradeInfo]);
  const breakingChangeCount = upgradeInfo?.cia_results?.length ?? 0;

  const paginator = useDataTablePaginator({
    pageSize: BREAKING_CHANGES_PAGE_SIZE,
    totalCount: breakingChangeCount,
  });

  const currentList = getPaginatorPageSlice(
    paginator.state,
    allBreakingChanges
  );

  const isEmpty = breakingChangeCount === 0;

  if (isEmpty) {
    return (
      <EmptyState
        imageWidth={300}
        size="medium"
        title={<FM defaultMessage="No Breaking Changes" />}
      />
    );
  }

  return (
    <Stack spacing={space.sm}>
      <RowStack justifyContent="space-between">
        <ExpandCollapseControl onClick={setAllExpanded} />
        <SimplePagination paginator={paginator} />
      </RowStack>
      {breakingChangeCount > 0 && (
        <Stack spacing={space.lg} divider={<Divider />}>
          {currentList?.map((change, i) => (
            <BreakingChangeDisplay
              key={i}
              ciaResult={change}
              isExpanded={allExpanded}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
