import { useMemo } from 'react';

import { DataTable, DataTableProps } from '@endorlabs/ui-common';

import {
  buildCIRunsTableColumnDefs,
  CIRunsTableColumnId,
  CIRunsTableRow,
} from './columnDefs';

const DEFAULT_COLUMNS: CIRunsTableColumnId[] = [
  'scanResults',
  'ciRunId',
  'versionRef',
  'versionSha',
  'endTime',
  'findingCounts',
  'tags',
];

export interface CIRunsTableProps
  extends Omit<DataTableProps<CIRunsTableRow>, 'columns'> {
  includeColumns?: CIRunsTableColumnId[];
  isLoadingRelated?: boolean;
}

export const CIRunsTable = ({
  includeColumns = DEFAULT_COLUMNS,
  isLoadingRelated,
  ...rest
}: CIRunsTableProps) => {
  const columns = useMemo(() => {
    return buildCIRunsTableColumnDefs({ includeColumns });
  }, [includeColumns]);

  return (
    <DataTable
      {...rest}
      columns={columns}
      customTableColProps={{ isCountsLoading: isLoadingRelated }}
    />
  );
};
