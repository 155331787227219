import { minutesToMilliseconds } from 'date-fns';
import { pick as _pick } from 'lodash-es';
import { QueryKey, useQuery } from 'react-query';

import {
  Endorv1Metric as V1Metric,
  QueryQuerySpec,
  QueryServiceApi,
  V1Bom,
  V1ListParameters,
  V1Meta,
  V1MetricSpec,
  V1MetricValue,
  V1Project,
  V1ProjectSpec,
  V1Query,
  V1ResolutionStatus,
} from '@endorlabs/api_client';
import { ListRequestParameters } from '@endorlabs/endor-core/api';
import { SelectFrom } from '@endorlabs/utils';

import { useBuildReadRequestParameters } from './hooks';
import {
  PackageVersionResource,
  ResourceCountResponse,
  ResourceQueryOptions,
  TResourceList,
} from './types';
import { getClientConfiguration } from './utils';

export type QueryPackageVersionsResponse =
  TResourceList<QueryPackageVersionsResponseObject>;

export type QueryPackageVersionsResponseObject = SelectFrom<
  PackageVersionResource,
  'uuid' | 'tenant_meta' | 'context',
  {
    spec: SelectFrom<
      PackageVersionResource['spec'],
      'project_uuid' | 'relative_path' | 'source_code_reference',
      {
        resolved_dependencies?: SelectFrom<
          V1Bom,
          'dependencies' | 'resolution_timestamp'
        >;
        resolution_errors?: {
          call_graph?: SelectFrom<V1ResolutionStatus, 'status_error'>;
          resolved?: SelectFrom<V1ResolutionStatus, 'status_error'>;
          unresolved?: SelectFrom<V1ResolutionStatus, 'status_error'>;
        };
      }
    >;
    meta: SelectFrom<
      PackageVersionResource['meta'],
      'name' | 'tags' | 'create_time' | 'update_time',
      {
        references: {
          FindingsGroupByLevel?: Pick<TResourceList<unknown>, 'group_response'>;
          FindingsGroupByReachability?: Pick<
            TResourceList<unknown>,
            'group_response'
          >;
          Project?: QueryPackageVersionProjectResponse;
          Metric?: QueryPackageVersionMetricResponse;
          DependentPackagesCount?: ResourceCountResponse;
          DependencyPackagesCount?: ResourceCountResponse;
        };
      }
    >;
  }
>;

type QueryPackageVersionProjectResponse = TResourceList<
  SelectFrom<
    V1Project,
    'uuid' | 'tenant_meta',
    {
      meta: SelectFrom<V1Meta, 'name'>;
      spec: SelectFrom<V1ProjectSpec, 'platform_source'>;
    }
  >
>;

type QueryPackageVersionMetricResponse = TResourceList<
  SelectFrom<
    V1Metric,
    'uuid',
    {
      meta: SelectFrom<V1Meta, 'name'>;
      spec: SelectFrom<
        V1MetricSpec,
        'analytic',
        {
          metric_values: {
            scorecard: Required<V1MetricValue>;
            scorefactor: Required<V1MetricValue>;
          };
          // properties required, but not included in query
          project_uuid: never;
          raw: never;
        }
      >;
      tenant_meta: never;
      context: never;
    }
  >
>;

const QUERY_STALE_TIME = minutesToMilliseconds(15);
const BASE_KEY = 'v1/queries';
const QK = {
  query: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'package-versions', namespace, listParams] as const,
  queryAll: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'package-versions-all', namespace, listParams] as const,
};
export const QueryPackageVersionsQueryKeys = QK;

const apiService = () => new QueryServiceApi(getClientConfiguration());

const buildQuerySpec = (rootListParams: V1ListParameters): QueryQuerySpec => {
  const commonListParameters = _pick(rootListParams, ['traverse']);

  return {
    kind: 'PackageVersion',
    list_parameters: {
      ...rootListParams,
      mask: [
        'uuid',
        'tenant_meta',
        'context',
        'meta.create_time',
        'meta.name',
        'meta.tags',
        'meta.update_time',
        'spec.project_uuid',
        'spec.relative_path',
        'spec.resolution_errors.call_graph.status_error',
        'spec.resolution_errors.resolved.status_error',
        'spec.resolution_errors.unresolved.status_error',
        'spec.resolved_dependencies.dependencies', // TODO: remove field from response, as can result in excessive data load
        'spec.resolved_dependencies.resolution_timestamp',
        'spec.source_code_reference',
      ].join(),
    },
    references: [
      {
        // gets Project for PackageVersion (if exists)
        connect_from: 'spec.project_uuid',
        connect_to: 'uuid',
        query_spec: {
          kind: 'Project',
          list_parameters: {
            ...commonListParameters,
            page_size: 1, // there should only be 1, return the first found
            mask: [
              'uuid',
              'meta.name',
              'tenant_meta',
              'spec.platform_source',
            ].join(),
          },
        },
      },
      {
        // NOTE: inherits `context` from parent PackageVersion
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'Finding',
          return_as: 'FindingsGroupByLevel',
          list_parameters: {
            ...commonListParameters,
            group: {
              aggregation_paths: 'spec.level',
            },
          },
        },
      },
      {
        // NOTE: inherits `context` from parent PackageVersion
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'Finding',
          return_as: 'FindingsGroupByReachability',
          list_parameters: {
            ...commonListParameters,
            group: {
              aggregation_paths: [
                'spec.level',
                'spec.finding_tags',
                'spec.finding_categories',
              ].join(','),
              show_aggregation_uuids: true,
            },
          },
        },
      },
      {
        // NOTE: inherits `context` from parent PackageVersion
        connect_from: 'uuid',
        connect_to: 'meta.parent_uuid',
        query_spec: {
          kind: 'Metric',
          list_parameters: {
            ...commonListParameters,
            // there should only be 1, return the first found
            page_size: 1,
            filter: 'meta.name==package_version_scorecard',
            mask: [
              'uuid',
              'meta.name',
              'spec.analytic',
              'spec.metric_values',
            ].join(),
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.dependency_data.package_version_uuid',
        query_spec: {
          kind: 'DependencyMetadata',
          return_as: 'DependentPackagesCount',
          list_parameters: {
            ...commonListParameters,
            count: true,
          },
        },
      },
      {
        connect_from: 'uuid',
        connect_to: 'spec.importer_data.package_version_uuid',
        query_spec: {
          kind: 'DependencyMetadata',
          return_as: 'DependencyPackagesCount',
          list_parameters: {
            ...commonListParameters,
            count: true,
          },
        },
      },
    ],
  };
};

const buildQuery = (
  namespace: string,
  listParams: V1ListParameters
): V1Query => {
  return {
    meta: {
      name: `QueryPackageVersions(namespace: ${namespace})`,
    },
    spec: {
      query_spec: buildQuerySpec(listParams),
    },
    tenant_meta: { namespace },
  };
};

const queryPackageVersions = async (
  namespace: string,
  listParams: V1ListParameters,
  signal?: AbortSignal
) => {
  const query = buildQuery(namespace, listParams);
  const resp = await apiService().queryServiceCreateQuery(namespace, query, {
    // pass abort signal to Axios, to support request cancellation on param changes
    signal,
  });
  return resp.data.spec?.query_response as QueryPackageVersionsResponse;
};

/**
 * Custom query for Package Versions, including metrics
 *
 * NOTE: this query sets the mask for the response objects
 */
export const useQueryPackageVersions = (
  namespace: string,
  opts: ResourceQueryOptions<QueryPackageVersionsResponse> = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'PackageVersion',
    'LIST',
    listParams,
    opts
  );

  return useQuery(
    QK.query(namespace, requestParameters),
    ({ signal }) => queryPackageVersions(namespace, requestParameters, signal),
    {
      staleTime: QUERY_STALE_TIME,
      ...opts,
    }
  );
};
