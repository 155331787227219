import { Chip } from '@mui/material';

import { FlexList, FlexListItem, NilDisplay } from '../../../../components';

export const FindingMetaTagsArrayDisplay = ({
  values = [],
}: {
  values?: string[];
}) => {
  if (!values?.length) return <NilDisplay variant="text" />;
  return (
    <FlexList>
      {values.map((value) => (
        <FlexListItem key={value}>
          <Chip label={value} size="small" />
        </FlexListItem>
      ))}
    </FlexList>
  );
};
