import { CVSSV3SeverityLevel, SpecFindingLevel } from '@endorlabs/api_client';

type AnySeverityLevel = SpecFindingLevel | CVSSV3SeverityLevel;

/**
 * Transparently compare Finding levels or CVSS severity to determine if they're the "same" level.
 */
export const isFindingLevelMatch = (
  level1: AnySeverityLevel,
  level2: AnySeverityLevel
) => {
  const severityGroups = [
    [SpecFindingLevel.Critical, CVSSV3SeverityLevel.Critical],
    [SpecFindingLevel.High, CVSSV3SeverityLevel.High],
    [SpecFindingLevel.Medium, CVSSV3SeverityLevel.Medium],
    [SpecFindingLevel.Low, CVSSV3SeverityLevel.Low],
    [
      SpecFindingLevel.Unspecified,
      CVSSV3SeverityLevel.Unspecified,
      CVSSV3SeverityLevel.None,
    ],
  ];

  const groupMatch = severityGroups.find(
    (group) => group.includes(level1) && group.includes(level2)
  );

  return Boolean(groupMatch);
};
