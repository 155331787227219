import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  Stack,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

export interface CheckboxInputProps extends Omit<CheckboxProps, 'key'> {
  error?: string;
  helperText?: ReactNode;
  label?: ReactNode;
}

/**
 * Augmented Checkbox component that provides handling for label and helperText
 */
export const CheckboxInput = ({
  error,
  helperText,
  inputProps,
  label,
  ...checkboxProps
}: CheckboxInputProps) => {
  const { space } = useTheme();

  let checkbox;

  // console.log('ERROR', error, 'dis', disabled, 'req', required);
  if (label) {
    checkbox = (
      <FormControlLabel
        {...(checkboxProps as FormControlLabelProps)}
        control={<Checkbox inputProps={inputProps} />}
        label={label}
      />
    );
  } else {
    checkbox = <Checkbox {...checkboxProps} />;
  }

  if (helperText) {
    return (
      <Stack>
        <FormControl error={!!error}>
          {checkbox}

          <FormHelperText sx={{ marginLeft: space.md }}>
            {helperText}
          </FormHelperText>
        </FormControl>
      </Stack>
    );
  }

  return checkbox;
};
