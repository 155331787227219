import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Grid, Stack, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { ErrorOption, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ButtonPrimary,
  ControlledEmailField,
  IconAlertTriangle,
  IconSave,
  REGEX_EMAIL_VALIDATION,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../../routes/constants';

interface LoginFormEmailLinkFieldValues {
  email: string;
}

interface LoginFormEmailLinkProps {
  event?: string | null;
  onEmailLinkSent?: () => void;
  returnTo?: string;
}

const getDefaultErrorMessage = (tenantName: string) =>
  `Unable to retrieve provider data for tenant ${tenantName}: no identity providers configured for the tenant ${tenantName}`;

/**
 * Form used to log in using EmailLink
 */
export const LoginFormEmailLink = ({
  event = null,
  onEmailLinkSent,
  returnTo,
}: LoginFormEmailLinkProps) => {
  const { formatMessage: fm } = useIntl();
  const { handleSubmit: hookFormSubmit, ...formMethods } =
    useForm<LoginFormEmailLinkFieldValues>({
      mode: 'onTouched',
    });
  const { space } = useTheme();
  const [localError, setLocalError] = useState<ErrorOption | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (fieldValues: LoginFormEmailLinkFieldValues) => {
      setIsSubmitting(true);
      setLocalError(undefined);

      const { email } = fieldValues;

      const params = new URLSearchParams({ email });
      if (event) {
        params.set('event', event);
      }
      if (returnTo) {
        params.set('redirect', returnTo);
      }

      const url = `${NamedRoutes.AUTH_LOGIN}?${params}`;
      const res = await fetch(url, {
        credentials: 'include',
        method: 'GET',
        redirect: 'manual',
      });

      if (res.ok) {
        if (onEmailLinkSent) onEmailLinkSent();
      }

      if (!res.ok && res.type !== 'opaqueredirect') {
        const errorMessage = await res.text();
        setLocalError({
          message: errorMessage || getDefaultErrorMessage(email),
        });

        setIsSubmitting(false);
        return;
      }

      setIsSubmitting(false);
    },
    [event, onEmailLinkSent, returnTo]
  );

  return (
    <Stack spacing={space.md} maxWidth="485px" width="100%">
      {localError && (
        <Grid item>
          <Alert severity="error" icon={<IconAlertTriangle />}>
            <AlertTitle>{localError.message}</AlertTitle>
          </Alert>
        </Grid>
      )}

      <FormProvider handleSubmit={hookFormSubmit} {...formMethods}>
        <form
          id="FormEmailLink"
          onSubmit={hookFormSubmit(handleSubmit)}
          noValidate
        >
          <Grid container rowSpacing={5}>
            <Grid item xs={12}>
              <ControlledEmailField
                control={formMethods.control}
                label={<FM defaultMessage="Email address" />}
                placeholder={fm({
                  defaultMessage: 'Enter your email address',
                })}
                name="email"
                required
                rules={{
                  required: fm({
                    defaultMessage: 'Must enter a valid email address',
                  }),

                  pattern: {
                    value: REGEX_EMAIL_VALIDATION,
                    message: fm({
                      defaultMessage: 'Must be a valid email address',
                    }),
                  },
                }}
                sx={{
                  '& .MuiInputLabel-shrink': {
                    // HACK: overrides scale transform added to form labels
                    transform: `translate(0, -3px) scale(1)`,
                  },
                }}
              />
            </Grid>

            <Grid item sx={{ width: '100%' }}>
              {isSubmitting ? (
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<IconSave />}
                  variant="contained"
                  sx={{ width: '100%' }}
                >
                  <FM defaultMessage="Continue" />
                </LoadingButton>
              ) : (
                <ButtonPrimary
                  type="submit"
                  disabled={isSubmitting || !formMethods.formState.isDirty}
                  sx={{ width: '100%' }}
                >
                  <FM defaultMessage="Continue" />
                </ButtonPrimary>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Stack>
  );
};
