import { PackageVersionResource } from '@endorlabs/queries';

import { stripPackageVersionRef } from './stripPackageVersionRef';
import { mapUnresolvedToResolvedDependencies } from './transformDependencies';

/**
 * For a given package version, return the union of the resolved and unresolved dependencies.
 */
export const getAllPackageVersionDependencies = (
  packageVersion: PackageVersionResource
) => {
  // take a shallow copy of the resolved dependencies
  const dependencies =
    packageVersion?.spec.resolved_dependencies?.dependencies?.slice() ?? [];
  const unresolvedDependencies =
    mapUnresolvedToResolvedDependencies(packageVersion);

  const unresolvedDependencyPackageNames = new Set<string>();
  const dependencyPackageNames = new Set<string>(
    dependencies.map((dep) => stripPackageVersionRef(dep.name))
  );

  for (const dep of unresolvedDependencies) {
    if (!dependencyPackageNames.has(dep.packageName)) {
      unresolvedDependencyPackageNames.add(dep.packageName);
      dependencies.push(dep);
    }
  }

  return {
    dependencies,
    dependencyPackageNames,
    unresolvedDependencyPackageNames,
  };
};
