import { useTheme } from '@mui/material';

import { Link, LinkProps } from '@endorlabs/ui-common';

export const BrandedLink = (props: LinkProps) => {
  const theme = useTheme();

  return (
    <Link
      sx={{
        ...theme.typography.button,
        color: theme.palette.brand.main,
        fontWeight: 500,
      }}
      {...props}
    />
  );
};
