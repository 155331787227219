import {
  Box,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import {
  CopyToClipboardButton,
  IconExternalLink,
  Link,
  RowStack,
  useStyles,
} from '@endorlabs/ui-common';
import { CodeEditor } from '@endorlabs/ui-common/domains/code';

export interface CodeSnippetProps {
  code?: string;
  fileLocation?: string;
}

export const FindingDetailCodeSnippet = ({
  code,
  fileLocation,
}: CodeSnippetProps) => {
  const { space, palette } = useTheme();
  const sx = useStyles(styles);

  // Function to extract line number from fileLocation and adjust it
  const extractAdjustedLineNumberFromLocation = (location?: string) => {
    if (!location) return 1; // Default to line 1 if location is undefined
    const match = location.match(/#L(\d+)/);
    if (match) {
      const lineNumber = parseInt(match[1], 10);
      return Math.max(lineNumber - 3, 1); // Start from lineNumber - 3, but not less than 1
    }
    return 1; // Default to 1 if no line number is found
  };

  const startLineNumber = extractAdjustedLineNumberFromLocation(fileLocation);

  return (
    <Stack
      direction="column"
      border={`1px solid ${palette.design.grays[200]}`}
      sx={sx}
    >
      <Stack
        gap={space.xs}
        direction="row"
        justifyContent={'space-between'}
        borderBottom={`1px solid ${palette.design.grays[200]}`}
        alignItems="flex-start"
        padding={2}
      >
        <Typography variant="body2" sx={{ lineBreak: 'anywhere' }}>
          {fileLocation}
        </Typography>

        <RowStack gap={space.sm} alignItems="center" minWidth="max-content">
          <CopyToClipboardButton value={code ?? ''} />
          <Link to={fileLocation ?? '#'} target="_blank">
            <IconButton>
              <IconExternalLink />
            </IconButton>
          </Link>
        </RowStack>
      </Stack>

      <Box>
        <CodeEditor
          editorOptions={{
            lineNumbers: true,
            lineWrapping: false,
            firstLineNumber: startLineNumber, // Use adjusted starting line number
          }}
          enableClipboard={false}
          height="auto"
          language="text/x-yaml"
          value={code}
          width="100%"
        />
      </Box>
    </Stack>
  );
};

function styles({ palette }: Theme) {
  return {
    '.CodeMirror': {
      border: 'none',
      background: palette.design.grays[100],
    },
  };
}
