import { Alert, AlertTitle, Stack } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  IQueryError,
  QueryFindingsFields,
  useQueryAllFindings,
} from '@endorlabs/queries';
import { UIFindingUtils, useFileDownload } from '@endorlabs/ui-common';
import * as CSV from '@endorlabs/utils/encoding/csv';

import {
  ExportResourceColumn,
  FormExportResource,
  FormExportResourceFieldValues,
} from '../../../../components';
import { FindingsExportDialogProps } from './types';

const FINDING_EXPORT_COLUMNS: ExportResourceColumn<QueryFindingsFields>[] = [
  { isDefault: true, key: 'uuid', label: 'UUID' },
  { isDefault: true, key: 'meta.description', label: 'Title' },
  { key: 'meta.tags', label: 'Tags' },
  {
    isDefault: true,
    key: 'spec.level',
    label: 'Severity Level',
  },
  {
    isDefault: true,
    key: 'spec.finding_tags',
    label: 'Finding Attributes',
  },
  {
    isDefault: true,
    key: 'spec.finding_categories',
    label: 'Finding Categories',
  },
  { key: 'spec.dismiss', label: 'Dismissed' },
  { key: 'spec.remediation', label: 'Remediation' },
  { key: 'spec.proposed_version', label: 'Fix Version' },
  { key: 'spec.explanation', label: 'Explanation' },
  {
    key: 'spec.finding_metadata.vulnerability.spec.epss_score.probability_score',
    label: 'EPSS Score Probability',
  },
  {
    key: 'spec.finding_metadata.vulnerability.spec.epss_score.percentile_score',
    label: 'EPSS Score Percentile',
  },
  {
    isDefault: true,
    key: 'spec.finding_metadata.vulnerability.spec.raw.endor_vulnerability.cve_id',
    label: 'CVE',
  },
  {
    key: 'spec.finding_metadata.vulnerability.spec.raw.endor_vulnerability.cwe',
    label: 'CWE',
  },
  // Project Columns
  { key: 'spec.project_uuid', label: 'Project UUID' },
  {
    isDefault: true,
    key: 'meta.references.Project.list.objects.0.meta.name',
    label: 'Project Name',
  },
  // Package Columns
  {
    key: 'meta.references.PackageVersion.list.objects.0.uuid',
    label: 'Package UUID',
  },
  {
    key: 'meta.references.PackageVersion.list.objects.0.meta.name',
    label: 'Package Name',
  },
  // Dependency Columns
  { key: 'spec.target_uuid', label: 'Dependency UUID' },
  {
    key: 'spec.target_dependency_package_name',
    label: 'Dependency Name',
  },
];

export const FindingsExportDialogContent = (
  props: FindingsExportDialogProps
) => {
  const { filter, namespace, onClose } = props;
  const isCancelled = useRef(false);
  const [isDownloadingExportData, setIsDownloadingExportData] = useState(false);
  const [exportError, setExportError] = useState<IQueryError | null>(null);

  const qQueryFindings = useQueryAllFindings(
    namespace,
    { filter, page_size: 500 },
    // HACK: query is disabled, but manually invoked
    { enabled: false }
  );

  const [isDownloaded, downloadData] = useFileDownload({
    filetype: 'csv',
    filename: props.downloadProps?.filename ?? 'findings-export.csv',
  });

  const handleExportFindings = useCallback(
    (values: FormExportResourceFieldValues) => {
      setExportError(null);

      const selectedColumns = values.columns;
      if (!selectedColumns.length) {
        // TODO: handle edge case
        return;
      }

      setIsDownloadingExportData(true);

      // wrap promise with loading/error handling
      qQueryFindings
        .refetch()
        .then((result) => {
          // Exit without download if cancelled
          if (isCancelled.current) return;

          const findings = result.data ?? [];

          // sort by severity
          const sorted = UIFindingUtils.sortByFindingSeverity(
            findings,
            'spec.level',
            (a, b) =>
              (a.meta.description ?? '').localeCompare(b.meta.description ?? '')
          );

          // convert to CSV
          const output = CSV.stringify(sorted, null, {
            headers: selectedColumns,
          });

          downloadData(output);
        })
        .catch((error) => {
          setExportError(error);
        })
        .finally(() => {
          setIsDownloadingExportData(false);
        });
    },
    [downloadData, qQueryFindings]
  );

  const handleCancel = useCallback(() => {
    isCancelled.current = true;

    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Stack spacing={4}>
      <FormExportResource
        columns={FINDING_EXPORT_COLUMNS}
        isLoading={isDownloadingExportData}
        onSubmit={handleExportFindings}
        onCancel={handleCancel}
      />

      {exportError && (
        <Alert severity="error">
          <AlertTitle>
            <FM defaultMessage="Unable to Export Findings" />
          </AlertTitle>

          {exportError.response.data?.message}
        </Alert>
      )}
    </Stack>
  );
};
