import { useMatch, useNavigate } from '@tanstack/react-location';
import { useCallback, useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import {
  useCountPackageVersions,
  useGroupDependencyMetadata,
} from '@endorlabs/queries';
import { UIProjectUtils } from '@endorlabs/ui-common';

import { PageNavigation } from '../../components';
import { PageNavigationItem } from '../../components/PageNavigation/PageNavigationGroup';
import { getProjectVersionDetailPath } from '../../routes';

interface ProjectVersionPageNavigationProps {
  project: ProjectResource;
  projectVersion: ProjectVersionResource;
}

export const ProjectVersionPageNavigation = ({
  project,
  projectVersion,
}: ProjectVersionPageNavigationProps) => {
  const urlMatch = useMatch();
  const navigate = useNavigate();

  const namespace = project.tenant_meta.namespace;

  const qCountProjectDependencies = useGroupDependencyMetadata(
    namespace,
    { enabled: !!projectVersion },
    {
      filter: UIProjectUtils.getProjectRelatedFilterExpressions(
        project,
        projectVersion,
        { key: 'spec.importer_data.project_uuid' }
      ),
      group: {
        aggregation_paths: 'spec.importer_data.project_uuid',
        unique_count_paths: 'meta.name',
      },
      traverse: false,
    }
  );

  const projectDependencyCount = Object.values(
    qCountProjectDependencies.data?.groups ?? {}
  ).at(0)?.unique_counts?.['meta.name']?.count;

  const qCountProjectPackages = useCountPackageVersions(
    namespace,
    { enabled: !!projectVersion },
    {
      count: true,
      filter: UIProjectUtils.getProjectRelatedFilterExpressions(
        project,
        projectVersion,
        { key: 'spec.project_uuid' }
      ),
      traverse: false,
    }
  );

  const dependenciesUrl = getProjectVersionDetailPath({
    tenantName: namespace,
    uuid: project.uuid,
    versionRef: projectVersion.context.id,
    additionalSegments: ['dependencies'],
  });

  const packagesUrl = getProjectVersionDetailPath({
    tenantName: namespace,
    uuid: project.uuid,
    versionRef: projectVersion.context.id,
    additionalSegments: ['packages'],
  });

  const navigationItems = useMemo((): PageNavigationItem[] => {
    return [
      {
        navKey: 'dependencies',
        label: <FM defaultMessage="Dependencies" />,
        suffix: projectDependencyCount,
        value: dependenciesUrl,
      },
      {
        navKey: 'packages',
        label: <FM defaultMessage="Packages" />,
        suffix: qCountProjectPackages.data?.count,
        value: packagesUrl,
      },
    ];
  }, [
    dependenciesUrl,
    packagesUrl,
    projectDependencyCount,
    qCountProjectPackages.data?.count,
  ]);

  const selectedItemKey = useMemo(() => {
    const matchingItem = navigationItems.find((item) => {
      return urlMatch.pathname === item.value;
    });

    return matchingItem?.navKey;
  }, [navigationItems, urlMatch.pathname]);

  const handleSelection = useCallback(
    (navKey: string) => {
      const selectedItem = navigationItems.find(
        (item) => item.navKey === navKey
      );
      if (!selectedItem) return;

      navigate({
        replace: true,
        to: selectedItem.value,
      });
    },
    [navigate, navigationItems]
  );

  return (
    <PageNavigation
      handleSelection={handleSelection}
      items={navigationItems}
      selectedItemKey={selectedItemKey ?? 'custom'}
    />
  );
};
