import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IQueryError } from '@endorlabs/queries';

export const getFindingErrorMessage = (
  error?: IQueryError
): { title: ReactNode; description: ReactNode } => {
  const statusCode = error?.response?.status;

  switch (statusCode) {
    case 403: {
      return {
        title: <FM defaultMessage="Unable to Authorize" />,
        description: (
          <FM defaultMessage="This account is not authorized to view the requested Finding for the current Tenant. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
    case 404: {
      return {
        title: <FM defaultMessage="Finding Not Found" />,
        description: (
          <FM defaultMessage="We were unable to find the Finding you requested. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
    default: {
      return {
        title: <FM defaultMessage="We've Encountered a Problem" />,
        description: (
          <FM defaultMessage="An unknown error has occurred. Please check the URL and try again, or return to view all Findings." />
        ),
      };
    }
  }
};
