import { V1PlatformSource } from '@endorlabs/api_client';

export const PLATFORM_SOURCE_PREFIXES = {
  GitHub: 'https://github.com/',
} as const;

export const parseProjectName = (
  projectName?: string,
  platformSource?: V1PlatformSource
) => {
  if (!projectName) return null;

  let label = projectName.replace(/\.git$/, '');

  // strip the platform prefix, if given, and present
  if (platformSource === V1PlatformSource.Github) {
    label = label.replace(PLATFORM_SOURCE_PREFIXES.GitHub, '');
  } else {
    // strip a schema, if present
    label = label.replace(/^https:\/\//, '');
  }

  return label;
};
