import { ContextContextType } from '@endorlabs/api_client';
import {
  ProjectResource,
  RepositoryResource,
  RepoVersionResource,
} from '@endorlabs/queries';

export const getDefaultRepositoryVersion = ({
  project,
  repository,
  repositoryVersions,
  versionRef,
}: {
  project: ProjectResource;
  repository?: RepositoryResource;
  repositoryVersions: RepoVersionResource[];
  versionRef?: string;
}): RepoVersionResource | undefined => {
  // When version ref is provided, find match
  if (versionRef) {
    // Find versions matching the requested version
    const matchingVersions = repositoryVersions.filter(
      (r) => r.meta.name === versionRef
    );

    if (matchingVersions && matchingVersions.length > 0) {
      const mainContextVersion = matchingVersions.find(
        (v) => v.context.type === ContextContextType.Main
      );

      // Handle possible duplicates in version names
      // prefer version with `MAIN` context, if present
      return mainContextVersion ?? matchingVersions[0];
    }
  }

  // Prefer the repo version with `MAIN` context
  const mainContextVersion = repositoryVersions.find(
    (v) => v.context.type === ContextContextType.Main
  );
  if (mainContextVersion) return mainContextVersion;

  // If set, find version matching the repository default branch
  if (repository) {
    const repositoryDefaultRef = repository?.spec.default_branch;
    const defaultBranchVersion = repositoryVersions.find(
      (r) => r.meta.name === repositoryDefaultRef
    );
    if (defaultBranchVersion) return defaultBranchVersion;
  }

  // Otherwise, use the first repo version in the list
  return repositoryVersions[0];
};
