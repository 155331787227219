import { MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { addDays, formatISO, startOfDay, startOfToday } from 'date-fns';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { PolicyExceptionReason } from '@endorlabs/api_client';
import { PolicyResource } from '@endorlabs/queries';
import {
  ControlledTextField,
  ControlledToggleButtonGroup,
} from '@endorlabs/ui-common';

import { ExceptionPolicyReasonLabels } from '../locales';

interface ExceptionPolicyReasonFieldsProps {
  policy?: PolicyResource;
}

export const ExceptionPolicyReasonFields = ({
  policy,
}: ExceptionPolicyReasonFieldsProps) => {
  const { space } = useTheme();

  if (policy?.spec.exception?.expiration_time === null) {
    policy.spec.exception.expiration_time = '';
  }

  const { control } = useFormContext();
  const { formatMessage: fm } = useIntl();

  const expirationTimes = useMemo(() => {
    const intervals = [30, 60, 90];

    return intervals.map((val) => ({
      label: <FM defaultMessage={`In {count} days`} values={{ count: val }} />,
      value:
        // Backend dates are stored as ISO strings without milliseconds, so options need to match
        startOfDay(addDays(startOfToday(), val)).toISOString().split('.')[0] +
        'Z',
    }));
  }, []);

  const reasonButtonProps = useMemo(() => {
    const orderedReasons = [
      PolicyExceptionReason.InTriage,
      PolicyExceptionReason.FalsePositive,
      PolicyExceptionReason.RiskAccepted,
      PolicyExceptionReason.Other,
    ];

    return orderedReasons.map((reason) => {
      return {
        value: reason as PolicyExceptionReason,
        children: <FM {...ExceptionPolicyReasonLabels[reason]} />,
      };
    });
  }, []);

  return (
    <Stack gap={space.md} width="100%">
      <ControlledToggleButtonGroup
        buttonPropsArray={reasonButtonProps}
        control={control}
        exclusive
        id="spec.exception.reason"
        name="spec.exception.reason"
      />

      <Stack
        alignItems="flex-start"
        direction="column"
        gap={space.md}
        width="100%"
      >
        <ControlledTextField
          control={control}
          defaultValue=""
          fullWidth
          label={fm({ defaultMessage: 'Expiration' })}
          name="spec.exception.expiration_time"
          placeholder={fm({ defaultMessage: 'Never' })}
          select
          SelectProps={{
            displayEmpty: true,
            multiple: false,
            renderValue: (v) => {
              return v === '' || v === null ? (
                <FM defaultMessage="Never" />
              ) : (
                expirationTimes.find((t) => t.value === v)?.label ?? ''
              );
            },
          }}
        >
          <MenuItem key="never" value="">
            <Typography>
              <FM defaultMessage="Never" />
            </Typography>
          </MenuItem>

          {expirationTimes.map((expirationTime) => (
            <MenuItem key={expirationTime.value} value={expirationTime.value}>
              <Typography>{expirationTime.label}</Typography>
            </MenuItem>
          ))}
        </ControlledTextField>
      </Stack>
    </Stack>
  );
};
