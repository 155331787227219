import { Typography } from '@mui/material';
import { ControllerFieldState } from 'react-hook-form';
import { FormattedMessage as FM } from 'react-intl';

import { VALIDATION_MESSAGES } from '../forms';

/** Get the helper text for the Project Tag Field */
export const LabelFieldHelperText = ({
  fieldState,
  helperText,
}: {
  fieldState?: ControllerFieldState;
  helperText?: React.ReactNode;
}) => {
  const errorMessage = fieldState?.error?.message;
  if (errorMessage) {
    switch (errorMessage) {
      case VALIDATION_MESSAGES.INVALID_META_TAG: {
        return (
          <Typography variant="body2" color="error" component="span">
            <FM
              defaultMessage="One or more tags are invalid, please remove and try again. Tags must be 63 characters or less, and may contain only letters (<code>A-Z</code>), numbers (<code>0-9</code>), and the following characters (<code>=@_.-</code>)"
              values={{
                code: (v) => <code>{v}</code>,
              }}
            />
          </Typography>
        );
      }
      case VALIDATION_MESSAGES.INVALID_HTTP_STATUS_CODE: {
        return (
          <FM defaultMessage="All values must be valid HTTP status codes" />
        );
      }
      case VALIDATION_MESSAGES.INVALID_EMAIL: {
        return <FM defaultMessage="All values must be valid emails" />;
      }
      default: {
        return (
          <Typography variant="body2" color="error" component="span">
            {errorMessage}
          </Typography>
        );
      }
    }
  }

  if (helperText) {
    return (
      <Typography variant="body2" color="info" component="span">
        {helperText}
      </Typography>
    );
  }

  return null;
};
