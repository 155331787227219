import { Grid, useTheme } from '@mui/material';
import { useNavigate } from '@tanstack/react-location';
import { without as _without } from 'lodash-es';
import { useEffect } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1ScanState } from '@endorlabs/api_client';
import { AssuredPackageVersionResource } from '@endorlabs/endor-core/AssuredPackageVersion';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import {
  sortParamBuilders,
  useListAssuredPackageVersions,
} from '@endorlabs/queries';
import {
  CommonDataTableRowActions,
  DataTableColumnDef,
  DataTableColumnTypeKeys,
  DataTableView,
  MultiSelectInput,
  useDataTablePaginator,
} from '@endorlabs/ui-common';

import { PageHeader } from '../../../components';
import { useAuthInfo } from '../../../providers';
import {
  FilterBar,
  FilterFieldConfig,
  filterFieldTransformBuilders,
  useFilterContext,
  withFilterProvider,
} from '../../filters';
import { useAssuredPackageVersionDetailDrawer } from '../hooks';

const FILTER_FIELDS: FilterFieldConfig<any>[] = [
  {
    id: 'processing_status.scan_state',
    ...filterFieldTransformBuilders.fromFilter({
      key: 'processing_status.scan_state',
      comparator: FILTER_COMPARATORS.IN,
    }),
    renderInput: ({ onChange, value }) => (
      <MultiSelectInput
        label={<FM defaultMessage="Scan State" />}
        onChange={onChange}
        options={_without(
          Object.values(V1ScanState),
          // omit unspecified as an option
          V1ScanState.Unspecified
        )
          .map((value) => ({ value, label: value }))
          .sort((a, b) => a.value.localeCompare(b.value))}
        value={value}
      />
    ),
  } satisfies FilterFieldConfig<V1ScanState[]>,
];

const COLUMNS: DataTableColumnDef<AssuredPackageVersionResource>[] = [
  {
    id: 'name',
    accessorFn: (d) => d.meta.name,
    header: () => <FM defaultMessage="Package Name" />,

    colType: DataTableColumnTypeKeys.TEXT_LONG,
  },
  {
    id: 'status',
    accessorFn: (d) => d.processing_status?.scan_state,
    header: () => <FM defaultMessage="Scan Status" />,
  },

  {
    id: 'createTime',
    accessorFn: (d) => d.meta.create_time,
    header: () => <FM defaultMessage="Created" />,
    colType: DataTableColumnTypeKeys.RELATIVE_TIME,
  },
  {
    id: 'updateTime',
    accessorFn: (d) => d.meta.update_time,
    header: () => <FM defaultMessage="Updated" />,
    colType: DataTableColumnTypeKeys.RELATIVE_TIME,
  },
];

const BaseAssuredPackageVersionsIndexPage = () => {
  const { activeNamespace } = useAuthInfo();
  const navigate = useNavigate();
  const { space } = useTheme();
  const { filter } = useFilterContext();

  const paginator = useDataTablePaginator({
    isInfinite: true,
    hasNextPage: () => !!data?.response?.next_page_token,
  });

  const { data, isLoading } = useListAssuredPackageVersions(activeNamespace, {
    ...paginator.getListParameters(),
    mask: [
      'meta.create_time',
      'meta.name',
      'meta.update_time',
      'processing_status.scan_state',
      'uuid',
    ].join(','),
    filter: filter,
    sort: sortParamBuilders.ascendingBy('meta.name'),
  });

  const { DetailDrawer, permalinkEffect } =
    useAssuredPackageVersionDetailDrawer();

  useEffect(() => {
    permalinkEffect({ assuredPackageVersions: data?.objects ?? [] });
  }, [data?.objects, permalinkEffect]);

  const goToDetail = (row: AssuredPackageVersionResource) =>
    navigate({ to: encodeURIComponent(row.meta.name) });

  const rowActions = [
    {
      ...CommonDataTableRowActions.ACTIVATE_DRAWER,
      isPrimary: true,
      onClick: (row: AssuredPackageVersionResource) => {
        const params = {
          namespace: activeNamespace,
          packageVersionName: row.meta.name,
        };
        DetailDrawer.activate(params, params);
      },
    },
    {
      ...CommonDataTableRowActions.LINK,
      onClick: goToDetail,
    },
  ];

  // Reset pagination on filter or tenant change.
  // NOTE: with infinite pagination, the paginator is not reset on the total
  // count change when filters are applied
  useEffect(
    () => {
      paginator.resetPagination();
    },
    // ignore changes from paginator outside of the reset handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, paginator.resetPagination, activeNamespace]
  );

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={space.md}>
      <Grid item>
        <PageHeader title={<FM defaultMessage="Endor Patches" />} />
      </Grid>

      <Grid item>
        <DataTableView
          columns={COLUMNS}
          data={data?.objects ?? []}
          filtersContent={
            <FilterBar enableAdvanced fields={FILTER_FIELDS} isCard={false} />
          }
          isLoading={isLoading}
          namespace={activeNamespace}
          rowActions={rowActions}
        />
      </Grid>
    </Grid>
  );
};

export const AssuredPackageVersionsIndexPage = withFilterProvider(
  BaseAssuredPackageVersionsIndexPage,
  {
    displayName: 'AssuredPackageVersionsIndexPage',
    searchKeys: ['meta.name', 'spec.sha256'],
  }
);
