import { Stack, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ControlledLabelsField,
  ControlledTextField,
  ControlledURLField,
  DrawerAccordion,
} from '@endorlabs/ui-common';

export const OidcIdentityProviderFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control } = useFormContext();

  return (
    <Stack spacing={space.md}>
      <ControlledTextField
        control={control}
        label={fm({
          defaultMessage: 'Identity Provider Name',
        })}
        name="meta.name"
        placeholder={fm({
          defaultMessage:
            'Enter a name for your identity provider configuration',
        })}
        required
      />

      <ControlledURLField
        control={control}
        label={fm({
          defaultMessage: 'Discovery URL',
        })}
        name="spec.oidc_provider.discover_url"
        placeholder={fm({
          defaultMessage:
            'Enter the location of your Identity Providers OIDC discovery document',
        })}
        required
      />

      <ControlledTextField
        control={control}
        label={fm({
          defaultMessage: 'Client ID Key',
        })}
        name="spec.oidc_provider.key"
        placeholder={fm({
          defaultMessage:
            'Enter the client ID key used to authenticate with your identity provider',
        })}
        required
      />

      <ControlledTextField
        control={control}
        label={fm({
          defaultMessage: 'Client Secret',
        })}
        name="spec.oidc_provider.secret"
        placeholder={fm({
          defaultMessage:
            'Enter the secret associated with your client ID key used to authenticate with your identity provider',
        })}
        required
        type="password"
      />

      <DrawerAccordion
        expanded={false}
        id="advancedSettings"
        titleNode={
          <Typography variant="button">
            <FM defaultMessage="Advanced Settings" />
          </Typography>
        }
      >
        <Stack direction="column" spacing={5}>
          <ControlledLabelsField
            control={control}
            defaultValue={[]}
            helperText={
              <FM defaultMessage="Enter custom scopes to be included in your request for claims from your identity provider" />
            }
            label={fm({
              defaultMessage: 'Scopes',
            })}
            name="spec.oidc_provider.scopes"
            placeholder={fm({
              defaultMessage: 'e.g. profile,email',
            })}
          />

          <ControlledLabelsField
            control={control}
            defaultValue={[]}
            helperText={
              <FM defaultMessage="Enter claim names be included in the request from your identity provider" />
            }
            label={fm({
              defaultMessage: 'Claim Names',
            })}
            name="spec.oidc_provider.claim_names"
            placeholder={fm({
              defaultMessage: 'e.g. groups,teams',
            })}
          />
        </Stack>
      </DrawerAccordion>
    </Stack>
  );
};
