import { darken, Theme } from '@mui/material';

import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCheckCircle,
  IconInfo,
  IconX,
} from '../icons';
import { ICON_SIZES } from './SvgIcons';

export const getAlertOverrides = ({ spacing, palette }: Theme) => {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          '&:has(.MuiAlertTitle-root:only-child)': {
            alignItems: 'center',

            '& .MuiAlert-action': {
              paddingTop: 0,
            },
          },
        },
        icon: {
          fontSize: ICON_SIZES.medium,
        },
        // handle icon button when using primary color
        filledPrimary: {
          backgroundColor: palette.brand.main,

          '& .MuiIconButton-root:hover': {
            color: darken(palette.brand.main, 0.6),
          },
        },
        outlinedPrimary: {
          borderColor: palette.brand.main,

          '& .MuiIconButton-root:hover': {
            color: darken(palette.brand.main, 0.6),
          },
          '& .MuiAlert-icon': {
            color: palette.brand.main,
          },
        },
      },

      defaultProps: {
        iconMapping: {
          error: <IconAlertCircle fontSize="inherit" />,
          info: <IconInfo fontSize="inherit" />,
          success: <IconCheckCircle fontSize="inherit" />,
          warning: <IconAlertTriangle fontSize="inherit" />,
        },
        components: {
          CloseIcon: IconX,
        },
      },
    },

    MuiAlertTitle: {
      styleOverrides: {
        root: {
          lineHeight: spacing(5),
          marginTop: 0,
          marginBottom: 0,

          '&:has(+ .MuiTypography-root)': {
            marginBottom: spacing(2),
          },
        },
      },
    },
  };
};
