import { TableCell, TableRow, TableRowProps } from '@mui/material';
import noop from 'lodash-es/noop';
import { useRef } from 'react';
import { DragLayerMonitor, useDrag, useDrop } from 'react-dnd';

import { IconDragHandle } from '../../themes';

const DND_ITEM_TYPE = 'DraggableTableRow';

export interface DragItem {
  id?: number;
  index: number;
  type?: string;
}

interface DraggableTableRowProps extends TableRowProps {
  index: number;
  onReorderComplete?: (item: DragItem) => void;
  reorderFn?: (dragIndex: number, hoverIndex: number) => void;
}

/**
 * TableRow component with special handling that allows drag-and-drop to re-sort rows.
 * Patterns adapted from: https://react-dnd.github.io/react-dnd/examples/sortable/simple
 *
 * Essentially this row becomes both a drag target & a drop target.
 * During drag, its position is compared against sibling rows, & the DataTable-provided `reorderFn`
 * reorders its row records if it crosses a threshold.
 * `onReorderComplete` fires at the end of the drag to handle any desired side effects.
 */
export const DraggableTableRow = ({
  children,
  index,
  onReorderComplete = noop,
  reorderFn = noop,
}: DraggableTableRowProps) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,

    item: () => {
      return { index };
    },
    // Will be called on drag completion
    end: (item: DragItem) => {
      onReorderComplete(item);
    },
    collect: (monitor: DragLayerMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,

    hover: (item: DragItem, monitor) => {
      if (!rowRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = rowRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset?.y ?? 0) - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the item's height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      reorderFn(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(rowRef));

  return (
    <TableRow ref={rowRef} sx={{ opacity: isDragging ? 0 : 1 }}>
      <TableCell>
        <IconDragHandle sx={{ '&:hover': { cursor: 'move' } }} />
      </TableCell>
      {children}
    </TableRow>
  );
};
