import { Children } from 'react';

import { Filter } from '@endorlabs/filters';

import { FilterFieldConfig } from '../types';

const buildFilterValueMap = (
  fields: FilterFieldConfig[],
  filterValues?: Map<string, Filter>
) => {
  const filterValueMap = new Map();

  for (const field of fields) {
    const filter = filterValues?.get(field.id);
    const value = filter ? field.parse(filter) : undefined;
    if (undefined === value) continue;

    filterValueMap.set(field.id, value);
  }
  return filterValueMap;
};

export const getUpdatedFilterMap = ({
  fields,
  filterValues,
  value,
}: {
  fields: FilterFieldConfig;
  filterValues?: Map<string, Filter>;
  value: unknown;
}) => {
  const filter = fields.format(value);
  const next = new Map(filterValues);
  if (filter) {
    next.set(fields.id, filter);
  } else {
    next.delete(fields.id);
  }

  return next;
};

export const buildFilterFieldElements = ({
  fields,
  filterValues,
  handleChange,
}: {
  fields: FilterFieldConfig[];
  filterValues?: Map<string, Filter>;
  handleChange: (config: FilterFieldConfig, value: unknown) => void;
}) => {
  const filterValueMap = buildFilterValueMap(fields, filterValues);

  return Children.toArray(
    fields.map((c) => {
      const value = filterValueMap.get(c.id);
      const onChange = (value: unknown) => handleChange(c, value);
      return c.renderInput({ value, onChange });
    })
  );
};
