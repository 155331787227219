import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog } from '@endorlabs/ui-common';

import { ExceptionPolicyCreateDialogContent } from './ExceptionPolicyCreateDialogContent';
import { ExceptionPolicyCreateDialogProps } from './types';

export const ExceptionPolicyCreateDialog = ({
  onClose,
  open,
  state,
}: ExceptionPolicyCreateDialogProps) => {
  return (
    <SimpleDialog
      descriptionText={
        <FM defaultMessage="Add an Exception Policy to prevent this Finding from triggering Action Policies in future scans. Note that new policies and changes to existing policies will not take affect until the next scan. All Exception Policies can be found under Policies > Exception Policies." />
      }
      maxWidth="lg"
      onClose={onClose}
      open={open}
      titleText={<FM defaultMessage="Add Exception Policy" />}
    >
      {/* TODO: loading state */}
      {state && (
        <ExceptionPolicyCreateDialogContent
          onClose={onClose}
          open={open}
          state={state}
        />
      )}
    </SimpleDialog>
  );
};
