import {
  getChildNamespace,
  getParentNamespace,
} from '@endorlabs/endor-core/Namespace';

const DEFAULT_UUID = '000000000000000000000000';

export const buildNamespaceResourceFrom = (namespace: string) => {
  return {
    uuid: DEFAULT_UUID,
    meta: { name: getChildNamespace(namespace) },
    spec: { full_name: namespace },
    tenant_meta: { namespace: getParentNamespace(namespace) },
  };
};
