import { useIntl } from 'react-intl';

import { ResourceKind } from '@endorlabs/endor-core';

import {
  ResourceKindMessages,
  ResourceKindPluralMessages,
} from '../../locales';

export const useResourceKindDisplayLabel = (
  resourceKind: ResourceKind | string,
  isPlural?: boolean
): string => {
  const { formatMessage: fm } = useIntl();

  const labelMessageDescriptor = isPlural
    ? ResourceKindPluralMessages[resourceKind as ResourceKind]
    : ResourceKindMessages[resourceKind as ResourceKind];

  if (labelMessageDescriptor) return fm(labelMessageDescriptor);

  return resourceKind;
};
