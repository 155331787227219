import { alpha, darken, Theme } from '@mui/material';
// import { createStyles } from '@mui/system/createStyled';

export const getProductTourStyles = ({ typography, palette, space }: Theme) => {
  const white = palette.getContrastText(palette.brand.main);
  const hoverText = alpha(white, 0.7);
  const hoverBg = alpha(palette.brand.light, 0.7);
  const darkPurple = darken(palette.data.purple, 0.5);

  return {
    '.shepherd-element': {
      backgroundColor: palette.brand.main,
      // backgroundColor: darkPurple,
      borderRadius: '6px',
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.4)',
      color: white,
      maxWidth: '38vw',
      opacity: 0,
      outline: 'none',
      padding: '40px',
      transition: 'opacity 0.75s',
      width: '100%',
      zIndex: 9999,
    },

    '.shepherd-button': {
      ...typography.button,
      backgroundColor: palette.brand.light,
      // backgroundColor: palette.grey[400],
      border: 0,
      borderRadius: '3px',
      color: palette.brand.dark,
      cursor: 'pointer',
      display: 'inline-flex',
      justifyContent: 'center',
      marginRight: space.xs,
      padding: '5px 15px',
      transition: 'backgroundColor 0.2s ease',
    },

    '.shepherd-button:not(:disabled):hover': {
      backgroundColor: hoverBg,
      // backgroundColor: alpha(palette.grey[400], 0.7),
    },

    '.shepherd-button.shepherd-button-secondary': {
      background: 'transparent',
      color: white,
    },

    '.shepherd-button.shepherd-button-secondary:not(:disabled):hover': {
      background: 'transparent',
      color: hoverText,
    },

    // '.shepherd-button:disabled': { cursor: 'not-allowed' },
    // '.shepherd-footer': {},

    '.shepherd-footer .shepherd-button:last-child': { marginRight: 0 },

    '.shepherd-cancel-icon': {
      ...typography.h1,
      background: 'transparent',
      border: 'none',
      color: white,
      cursor: 'pointer',
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1,
      margin: 0,
      padding: 0,
      transition: 'color 0.5s ease',
    },

    '.shepherd-cancel-icon:hover': { color: hoverText },

    '.shepherd-title': {
      ...typography.h1,
      margin: 0,
    },

    '.shepherd-header': {
      alignItems: 'center',
      display: 'flex',
      marginBottom: space.sm,
      justifyContent: 'space-between',
    },

    '.shepherd-text': {
      ...typography.body1,
      color: white,
    },

    '.shepherd-text p': { marginTop: '0' },
    '.shepherd-text p:last-child': { marginBottom: '0' },
    '.shepherd-content': { borderRadius: '6px', outline: 'none', padding: '0' },

    '.shepherd-enabled.shepherd-element': { opacity: 1 },
    '.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered)': {
      opacity: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
    },

    '.product-tour-step-count': {
      ...typography.caption,
      color: palette.grey[100],
      marginBottom: space.md,
    },

    '.product-tour-step-desc': {
      marginBottom: space.md,
    },
  };
};
