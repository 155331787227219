import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { RowSelectionState, Table } from '@tanstack/react-table';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import {
  ContextContextType,
  DependencyMetadataReachabilityType,
} from '@endorlabs/api_client';
import { FILTER_COMPARATORS } from '@endorlabs/filters';
import {
  PackageContexture,
  PackageVersionResource,
  QueryDependencyDependentsResponseObject,
  useGroupDependencyMetadata,
  useQueryDependencyDependents,
} from '@endorlabs/queries';
import {
  ButtonCancel,
  EmptyState,
  UIPackageVersionUtils,
  UIProjectUtils,
  useDataTablePaginator,
} from '@endorlabs/ui-common';
import { useTanstackTableRef } from '@endorlabs/ui-common';
import { PackageURL } from '@endorlabs/ui-common/domains/PackageURL';

import { FilterBuilder, useFilterBuilder } from '../../components';
import {
  FIFTEEN_MINUTES_IN_MILLISECONDS,
  FIVE_MINUTES_IN_MILLISECONDS,
} from '../../constants';
import { usePackageVersionDetailDrawer } from '../../domains/PackageVersion';
import {
  getPackageVersionPath,
  getProjectVersionPath,
  getSBOMImportPath,
} from '../../routes';
import { DependencyDetailDependentsSummary } from './DependencyDetailDependentsSummary';
import {
  DependencyDetailDependentsTable,
  DependencyDetailDependentsTableRow,
} from './DependencyDetailDependentsTable';
import {
  DependencyDependentAggregate,
  DependencyDependentsType,
} from './types';

const mapToDependencyDetailDependentsTableRows = (
  objects: QueryDependencyDependentsResponseObject[],
  aggregatedDependents: DependencyDependentAggregate[]
): DependencyDetailDependentsTableRow[] => {
  if (objects.length === 0) return [];

  const aggregatedDependentsMap = new Map(
    aggregatedDependents.map((d) => [d.uuid, d])
  );

  return objects.map((o) => {
    const { PackageVersion, Project, RepositoryVersion, ImportedSBOM } =
      o.meta.references;
    const packageVersion = PackageVersion?.list?.objects[0];
    const project = Project?.list?.objects[0];
    const repoVersion = RepositoryVersion?.list?.objects[0];
    const sbomImport = ImportedSBOM.list?.objects[0];

    const isSbomContext = o.context.type === ContextContextType.Sbom;

    const { label: packageName, version: packageVersionRef } =
      UIPackageVersionUtils.parsePackageName(packageVersion?.meta.name ?? '');

    const packageLink = packageVersion
      ? getPackageVersionPath({
          tenantName: packageVersion.tenant_meta.namespace,
          uuid: packageVersion.uuid,
        })
      : undefined;
    const projectName = project
      ? UIProjectUtils.parseProjectName(
          project.meta.name,
          project.spec.platform_source
        )
      : undefined;

    const projectVersionRef = repoVersion?.spec.version.ref;

    // Build Project link when the dependent package is not an SBOM
    const projectLink =
      !isSbomContext && project
        ? getProjectVersionPath({
            tenantName: project.tenant_meta.namespace,
            uuid: project.uuid,
            versionRef: projectVersionRef,
          })
        : undefined;

    // Build SBOM link when the dependent package is an SBOM
    const sbomLink =
      isSbomContext && sbomImport
        ? getSBOMImportPath({
            tenantName: sbomImport.tenant_meta.namespace,
            sbomImportUuid: sbomImport.uuid,
          })
        : undefined;

    const sbomName = sbomImport?.spec.main_component_purl
      ? PackageURL.safeParse(sbomImport?.spec.main_component_purl)?.name
      : undefined;

    const aggregatedDependent = aggregatedDependentsMap.get(o.uuid);

    return {
      id: packageVersion?.uuid as string, // Required for tanstack-table operations
      namespace: packageVersion?.tenant_meta.namespace as string,
      uuid: packageVersion?.uuid as string,
      packageName: packageVersion?.meta.name as string,
      packageVersionRef,
      packageLink,
      projectLink,
      projectName: projectName as string,
      projectPlatformSource: project?.spec.platform_source,
      sbomName,
      sbomLink,
      // aggregate properties
      hasDirectDependent: aggregatedDependent?.hasDirect,
      hasTransitiveDependent: aggregatedDependent?.hasTransitive,
      reachability: aggregatedDependent?.reachability,
    };
  });
};

/**
 * Get the aggregated counts and uuids for dependency dependents,
 * grouped by project, or by project + package
 */
const useAggregatedDependencyDependents = ({
  namespace,
  dependencyPackageVersion,
  dependencyType,
  filter,
}: {
  namespace: string;
  dependencyPackageVersion?: PackageVersionResource;
  dependencyType: DependencyDependentsType;
  filter?: string;
}) => {
  const dependentsBaseFilter = [
    `spec.dependency_data.package_version_uuid==${dependencyPackageVersion?.uuid}`,
    `context.type in [${[ContextContextType.Main, ContextContextType.Sbom]}]`,
  ];
  const dependentsFilters = [...dependentsBaseFilter];

  if (filter) {
    dependentsFilters.push(`(${filter})`);
  }

  const dependentsFilterExpression = dependentsFilters.join(' and ');

  const groupDependentsAggregationPaths = ['spec.importer_data.project_uuid'];
  if (dependencyType === DependencyDependentsType.PACKAGES) {
    groupDependentsAggregationPaths.push(
      'spec.importer_data.package_version_uuid'
    );
  }

  const qGroupedDependentsTotal = useGroupDependencyMetadata(
    namespace,
    {
      staleTime: FIFTEEN_MINUTES_IN_MILLISECONDS,
      enabled: !!dependencyPackageVersion,
    },
    {
      filter: dependentsBaseFilter.join(' and '),
      group: {
        show_aggregation_uuids: true,
        aggregation_paths: groupDependentsAggregationPaths.join(','),
        unique_value_paths: [
          'spec.dependency_data.direct',
          'spec.dependency_data.reachable',
        ].join(','),
      },
    }
  );

  const qGroupedDependents = useGroupDependencyMetadata(
    namespace,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      enabled: !!dependencyPackageVersion,
    },
    {
      filter: dependentsFilterExpression,
      group: {
        show_aggregation_uuids: true,
        aggregation_paths: groupDependentsAggregationPaths.join(','),
        unique_value_paths: [
          'spec.dependency_data.direct',
          'spec.dependency_data.reachable',
        ].join(','),
      },
    }
  );

  const aggregatedDependents: DependencyDependentAggregate[] = useMemo(() => {
    const aggregatedDependents = Object.values(
      qGroupedDependents.data?.groups ?? {}
    )
      .map((group) => {
        const [uuid, ...relatedUuids] = group.aggregation_uuids as string[];

        const count = group.aggregation_count?.count as number;

        const directDependencyValues = (group.unique_values?.[
          'spec.dependency_data.direct'
        ] ?? []) as unknown as boolean[];

        const hasDirect = directDependencyValues.includes(true);
        const hasTransitive = directDependencyValues.includes(false);

        const reachableDependencyValues = (group.unique_values?.[
          'spec.dependency_data.reachable'
        ] ?? []) as unknown as DependencyMetadataReachabilityType[];

        // get the first matching reachability type
        const reachability = [
          DependencyMetadataReachabilityType.Unknown,
          DependencyMetadataReachabilityType.Unspecified,
          DependencyMetadataReachabilityType.Reachable,
          DependencyMetadataReachabilityType.Unreachable,
        ].find((value) => reachableDependencyValues.includes(value));

        return {
          uuid,
          relatedUuids,
          count,
          hasDirect,
          hasTransitive,
          reachability,
        };
      })
      .filter((d) => !!d.uuid);

    // sort the initial list of dependencies
    aggregatedDependents.sort((a, b) => b.count - a.count);

    return aggregatedDependents;
  }, [qGroupedDependents.data]);

  const dependentsTotalCount = useMemo(() => {
    return Object.keys(qGroupedDependentsTotal.data?.groups ?? {}).length;
  }, [qGroupedDependentsTotal.data]);

  return {
    totalCount: dependentsTotalCount,
    data: aggregatedDependents,
    isLoading:
      qGroupedDependentsTotal.isLoading || qGroupedDependents.isLoading,
  };
};

export interface DependencyDetailDependentsProps {
  tenantName: string;
  dependencyPackageVersion?: PackageVersionResource;
}

export const DependencyDetailDependents = ({
  tenantName,
  dependencyPackageVersion,
}: DependencyDetailDependentsProps) => {
  const { formatMessage: fm } = useIntl();
  const [dependencyDependentType, setDependencyDependentType] = useState(
    DependencyDependentsType.PACKAGES
  );

  const { DetailDrawer, permalinkEffect } = usePackageVersionDetailDrawer();
  const [selectedRows, setSelectedRows] = useState({});
  const tableRef = useTanstackTableRef<DependencyDetailDependentsTableRow>();

  useEffect(() => {
    if (!DetailDrawer.isOpen && Object.keys(selectedRows).length > 0) {
      if (tableRef.current) {
        const currentTable =
          tableRef.current as Table<DependencyDetailDependentsTableRow>;
        currentTable.resetRowSelection();
        setSelectedRows({});
      }
    }
  }, [DetailDrawer, selectedRows, tableRef]);

  const handleRowSelection = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(rowSelection);
  }, []);

  const { getFilterBuilderProps, filterExpressionMap, clearFilters } =
    useFilterBuilder({
      include: ['DependencyMetadata'],
      exclude: [
        // This page is under the depedency detail, all dependents in this list
        // will have this same dependency.
        {
          kind: 'DependencyMetadata',
          key: 'meta.name',
        },
      ],
    });

  const qAggregatedDependencyDependents = useAggregatedDependencyDependents({
    namespace: tenantName,
    dependencyPackageVersion,
    dependencyType: dependencyDependentType,
    filter: filterExpressionMap.get('DependencyMetadata'),
  });

  const paginator = useDataTablePaginator({
    totalCount: qAggregatedDependencyDependents.data.length,
  });

  const dependentsUuids = useMemo(
    () => qAggregatedDependencyDependents.data.map((d) => d.uuid),
    [qAggregatedDependencyDependents.data]
  );

  const dependentsUuidsPage = paginator.getPageSlice(dependentsUuids);
  const qDependents = useQueryDependencyDependents(
    tenantName,
    {
      staleTime: FIVE_MINUTES_IN_MILLISECONDS,
      enabled: !!dependencyPackageVersion && dependentsUuidsPage.length > 0,
    },
    {
      page_size: paginator.state.pageSize,
      filter: `uuid in ["${dependentsUuidsPage.join('","')}"]`,
    }
  );

  const dependents = useMemo(
    () =>
      mapToDependencyDetailDependentsTableRows(
        qDependents.data?.list?.objects ?? [],
        qAggregatedDependencyDependents.data
      ),
    [qDependents.data, qAggregatedDependencyDependents.data]
  );

  useLayoutEffect(
    () =>
      permalinkEffect({
        packages: dependents,
        sourcePackage: dependencyPackageVersion,
      }),
    [
      permalinkEffect,
      dependents,
      dependencyDependentType,
      dependencyPackageVersion,
    ]
  );

  const isLoading =
    !dependencyPackageVersion ||
    qAggregatedDependencyDependents.isLoading ||
    qDependents.isLoading;
  const isEmptyState =
    !isLoading &&
    dependents.length === 0 &&
    !filterExpressionMap.has('DependencyMetadata');

  return (
    <Grid container direction="column" flexWrap="nowrap" spacing={6}>
      {isEmptyState && (
        <Grid item>
          <EmptyState
            size="large"
            title={
              <FM defaultMessage="This dependency does not have any dependents" />
            }
            description={
              <FM defaultMessage="When other packages add this package version as a dependency, they will appear here." />
            }
          ></EmptyState>
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <FilterBuilder
            {...getFilterBuilderProps()}
            primaryResourceKindLabel={fm({ defaultMessage: 'Dependents' })}
            defaultSearchFilter={{
              kind: 'DependencyMetadata',
              key: 'spec.importer_data.package_version_name',
              comparator: FILTER_COMPARATORS.MATCHES,
              type: 'string',
            }}
          />
          {/* TODO: enable toggle for dependents by project
            <ToggleButtonGroup
              exclusive
              onChange={(_, value) => setDependencyDependentType(value)}
              value={dependencyDependentType}
            >
              <ToggleButton
                color="primary"
                value={DependencyDependentsType.PACKAGES}
              >
                <FM defaultMessage="Packages" />
              </ToggleButton>

              <ToggleButton
                color="primary"
                value={DependencyDependentsType.PROJECTS}
              >
                <FM defaultMessage="Projects" />
              </ToggleButton>
            </ToggleButtonGroup>
            */}
        </Grid>
      )}

      {!isEmptyState && (
        <Grid item>
          <Card>
            <CardHeader
              title={
                <DependencyDetailDependentsSummary
                  dependencyPackageVersion={dependencyPackageVersion}
                  dependencyDependentType={dependencyDependentType}
                  aggregatedDependents={qAggregatedDependencyDependents.data}
                  totalCount={qAggregatedDependencyDependents.totalCount}
                  isLoading={qAggregatedDependencyDependents.isLoading}
                />
              }
            />
            <CardContent>
              <DependencyDetailDependentsTable
                isLoading={isLoading}
                dependencyDependentsType={dependencyDependentType}
                data={dependents}
                enablePagination
                ref={tableRef}
                enableRowSelection
                onRowSelectionChange={handleRowSelection}
                paginator={paginator}
                onClickDetail={
                  // hide drawer toggle for projects
                  dependencyDependentType === DependencyDependentsType.PACKAGES
                    ? (row) =>
                        row &&
                        DetailDrawer.activate(
                          {
                            packageNamespace: row.namespace,
                            packageUuid: row.uuid,
                          },
                          {
                            namespace: row.namespace,
                            sourcePackage: dependencyPackageVersion,
                            uuid: row.uuid,
                          }
                        )
                    : undefined
                }
                emptyStateProps={{
                  title: (
                    <FM defaultMessage="No dependents match the filter criteria" />
                  ),
                  children: (
                    <ButtonCancel onClick={clearFilters}>
                      <FM defaultMessage="Clear Filters" />
                    </ButtonCancel>
                  ),
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
