/**
 * Cloned from https://github.com/feathericons/react-feather/blob/3a85e8a2b67ba2142a7576928799944aa910dde2/src/icons/circle.js
 *
 * The `<circle>` element in the SVG icon has been changed from using "stroke"
 * to use "fill", and the radius of the circle has been updated to account for
 * stroke width.
 */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type CircleFilledProps = {
  color?: string;
  size?: string | number;
};

const CircleFilled = forwardRef<SVGSVGElement, CircleFilledProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
        stroke="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...rest}
      >
        <circle cx="12" cy="12" r="11" />
      </svg>
    );
  }
);

CircleFilled.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CircleFilled.displayName = 'CircleFilled';

export default CircleFilled;
