import { createContext } from 'react';

import { FilterValidationError } from '../types';

export interface FilterValidationContextValue {
  error?: FilterValidationError;
  isValidating: boolean;
}

export const FilterValidationContext =
  createContext<FilterValidationContextValue>({
    error: undefined,
    isValidating: false,
  });
