import { get as _get, has as _has } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { IQueryError, isQueryError } from '@endorlabs/queries';

import { getEnv } from '../../../constants';

const { EMAIL_ENDOR_SUPPORT } = getEnv();

const getArticle = (platformName: string) =>
  ['A', 'E', 'I', 'O', 'U'].includes(platformName.charAt(0).toUpperCase())
    ? 'An'
    : 'A';

/**
 * Possible reasons for installation errors:
 * - 400: unable to process installation
 * - 401: user is not authenticated
 * - 403: user is not authorized to create an installation in this namespace
 * - 404: installation for current state was not found
 * - 409: installation already exists
 * - 424: unable to retrieve installation information from {platformName(Github, Azure, etc)}
 * - 500: unable to process installation - unable to determine installation state
 */
const KNOWN_ERROR_DESCRIPTIONS: Record<
  number,
  (error: IQueryError, platformName: string) => ReactNode
> = {
  400: (error, platformName) => {
    const message = error.response.data?.message ?? '';

    // Handle the more specific error message
    if (message.includes('Unable to create or update installation projects')) {
      return (
        <FM
          defaultMessage="Unable to create or update projects for {platformName} installation. For assistance, please contact {supportEmail}."
          values={{ supportEmail: EMAIL_ENDOR_SUPPORT, platformName }}
        />
      );
    }

    return message;
  },
  401: () => (
    <FM defaultMessage="Not authenticated. Please login and try again" />
  ),
  403: (error, platformName) => (
    <FM
      defaultMessage="Not authorized to create {platformName} application installations for this namespace."
      values={{ platformName }}
    />
  ),
  404: (error, platformName) => (
    <FM
      defaultMessage="{article} {platformName} application installation was not found for this user."
      values={{
        platformName,
        article: getArticle(platformName),
      }}
    />
  ),
  409: (error, platformName) => (
    <FM
      defaultMessage="{article} {platformName} application installation already exists in this namespace."
      values={{
        platformName,
        article: getArticle(platformName),
      }}
    />
  ),
  422: (error, platformName) => (
    <FM
      defaultMessage="The {GitHub} application installation already exists in another namespace. Please select another namespace and try again, or contact Endor Labs for support."
      values={{ platformName }}
    />
  ),
  424: (error, platformName) => (
    <FM
      defaultMessage="Unable to retrieve {platformName} application installation details."
      values={{ platformName }}
    />
  ),
  500: (error, platformName) => (
    <FM
      defaultMessage="Unable to update {platformName} installation. For assistance, please contact {supportEmail}."
      values={{ supportEmail: EMAIL_ENDOR_SUPPORT, platformName }}
    />
  ),
};

export const getInstallationErrorMessage = (
  error: unknown,
  isUpdate: boolean = false,
  platformName: string
): { message: ReactNode; details: ReactNode } => {
  // Set the default error title and description
  const message = (
    <FM
      defaultMessage="Failed to {action} this {platformName} installation. Please try again."
      values={{ action: isUpdate ? 'update' : 'create', platformName }}
    />
  );
  let details: ReactNode = (
    <FM
      defaultMessage="Unable to {action} {platformName} installation. For assistance, please contact {supportEmail}."
      values={{
        action: isUpdate ? 'update' : 'create',
        supportEmail: EMAIL_ENDOR_SUPPORT,
        platformName,
      }}
    />
  );

  // Override error description with more specific error, if possible
  if (isQueryError(error)) {
    const statusCode = error.response.status;

    if (_has(KNOWN_ERROR_DESCRIPTIONS, statusCode)) {
      const descriptionFn = _get(KNOWN_ERROR_DESCRIPTIONS, statusCode);
      details = descriptionFn(error, platformName);
    }
  }

  return {
    message,
    details,
  };
};
