import { Card, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import {
  defineMessage,
  FormattedList,
  FormattedMessage as FM,
} from 'react-intl';

import {
  DependencyMetadataReachabilityType,
  SpecFindingLevel,
  V1DependencyMetadataSpec,
  V1DependencyScope,
  V1GroupResponse,
  V1LicenseInfo,
} from '@endorlabs/api_client';
import { ProjectMinimal } from '@endorlabs/endor-core/Project';
import { QueryPackageVersionsResponseObject } from '@endorlabs/queries';
import {
  AttributeDisplayStack,
  CopyToClipboardButton,
  DateDisplay,
  FindingCountArrayDisplay,
  NilDisplay,
  ProjectNameDisplayV2,
  RowStack,
} from '@endorlabs/ui-common';

import {
  DetailDrawerSection,
  DetailDrawerSectionStack,
} from '../../../../components/DetailDrawer';
import { FindingRiskMatrixTableV2 } from '../../../../components/FindingRiskMatrix';
import { genLicenseList, getPackageVersionDetails } from '../../utils';

type DependencyMetadataSpecStub = {
  dependency_data?: Pick<
    Exclude<V1DependencyMetadataSpec['dependency_data'], undefined>,
    'direct' | 'namespace' | 'pinned' | 'reachable' | 'repo_name' | 'scope'
  >;
  importer_data?: Pick<
    Exclude<V1DependencyMetadataSpec['importer_data'], undefined>,
    'callgraph_available'
  >;
};
interface PackageVersionDetailDrawerOverviewSectionProps {
  uuid: string;
  dependencyMetadata: V1DependencyMetadataSpec[];
  dependentGroups: V1GroupResponse;
  findingsByLevelAndReachability: V1GroupResponse['groups'];
  licenses?: V1LicenseInfo[];
  packageVersionQueryObject?: QueryPackageVersionsResponseObject;
  project?: ProjectMinimal;
  isLoading: boolean;
}

const PackageVersionMetadataAttributeRecords = [
  {
    attributeKey: 'uuid',
    heading: <FM defaultMessage="Package Version UUID" />,
  },
  {
    attributeKey: 'project',
    heading: <FM defaultMessage="Project" />,
  },
  {
    attributeKey: 'resolutionTime',
    heading: <FM defaultMessage="Resolution Time" />,
  },
  { attributeKey: 'findingCounts', heading: <FM defaultMessage="Findings" /> },
  {
    attributeKey: 'ossDependencies',
    heading: <FM defaultMessage="OSS Dependencies" />,
  },
  {
    attributeKey: 'dependencyVisiblity',
    heading: <FM defaultMessage="Dependency Visiblity" />,
  },
  {
    attributeKey: 'sourceControlDependencies',
    heading: <FM defaultMessage="Dependency Source Control Visibility" />,
  },
  {
    attributeKey: 'pinnedDependencies',
    heading: <FM defaultMessage="Dependency Pinning" />,
  },
  {
    attributeKey: 'reachableDependencies',
    heading: <FM defaultMessage="Reachable Dependencies" />,
  },
  {
    attributeKey: 'license',
    heading: <FM defaultMessage="License" />,
  },
  {
    attributeKey: 'dependencyScopes',
    heading: <FM defaultMessage="Dependency Scopes" />,
  },
  {
    attributeKey: 'callGraphAvailableDependencies',
    heading: <FM defaultMessage="Call Graph Available Dependencies" />,
  },
  {
    attributeKey: 'dependents',
    heading: <FM defaultMessage="Dependents" />,
  },
];

export const PackageVersionDetailDrawerOverviewSection = ({
  uuid,
  dependencyMetadata,
  dependentGroups,
  findingsByLevelAndReachability,
  licenses,
  packageVersionQueryObject,
  project,
  isLoading,
}: PackageVersionDetailDrawerOverviewSectionProps) => {
  const { palette } = useTheme();

  const collectedMetadata = useMemo(() => {
    if (packageVersionQueryObject && dependencyMetadata) {
      return getDepMetadataCounts(
        dependencyMetadata,
        dependentGroups,
        packageVersionQueryObject
      );
    } else {
      return genBlankMetadata();
    }
  }, [dependencyMetadata, dependentGroups, packageVersionQueryObject]);

  const {
    findingsCriticalCount,
    findingsHighCount,
    findingsLowCount,
    findingsMediumCount,
    resolutionTimestamp,
  } = useMemo(
    () => getPackageVersionDetails(packageVersionQueryObject),
    [packageVersionQueryObject]
  );

  const findingCountValues = [
    { level: SpecFindingLevel.Critical, value: findingsCriticalCount ?? 0 },
    { level: SpecFindingLevel.High, value: findingsHighCount ?? 0 },
    { level: SpecFindingLevel.Medium, value: findingsMediumCount ?? 0 },
    { level: SpecFindingLevel.Low, value: findingsLowCount ?? 0 },
  ];

  const licenseList =
    licenses && licenses.length > 0 ? genLicenseList(licenses) : [];
  return (
    <DetailDrawerSectionStack divider={<Divider />}>
      <DetailDrawerSection
        title="Package Version Metadata"
        id="package_version_metadata"
      >
        <AttributeDisplayStack
          attributeRecords={PackageVersionMetadataAttributeRecords}
          headingWidth="20ch"
          isLoading={isLoading}
          resource={{
            uuid: (
              <RowStack gap={1}>
                <Typography variant="body2">{uuid}</Typography>
                <CopyToClipboardButton size="small" value={uuid} />
              </RowStack>
            ),
            project: project ? (
              <ProjectNameDisplayV2 project={project} />
            ) : (
              <NilDisplay variant="text" />
            ),
            resolutionTime: (
              <Typography variant="body2">
                <DateDisplay value={resolutionTimestamp} />
              </Typography>
            ),
            findingCounts: (
              <FindingCountArrayDisplay value={findingCountValues} />
            ),
            ossDependencies: (
              <RowStack gap={1}>
                <Typography variant="body2">
                  <FM
                    defaultMessage="{direct, number} Direct"
                    values={collectedMetadata['oss']}
                  />
                </Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                  <FM
                    defaultMessage="({transitive, number} Transitive)"
                    values={collectedMetadata['oss']}
                  />
                </Typography>
              </RowStack>
            ),
            dependencyVisiblity: (
              <Stack>
                {entryMessages.visibility.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM {...msg} values={collectedMetadata['visibility']} />
                  </Typography>
                ))}
              </Stack>
            ),
            sourceControlDependencies: (
              <Stack>
                {entryMessages.repoVisibility.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM {...msg} values={collectedMetadata['repoVisibility']} />
                  </Typography>
                ))}
              </Stack>
            ),
            pinnedDependencies: (
              <Stack>
                {entryMessages.pinned.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM {...msg} values={collectedMetadata['pinned']} />
                  </Typography>
                ))}
              </Stack>
            ),
            reachableDependencies: (
              <Stack>
                {entryMessages.reachable.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM {...msg} values={collectedMetadata['reachable']} />
                  </Typography>
                ))}
              </Stack>
            ),
            license: (
              <Typography variant="body2">
                {licenseList.length === 0 ? (
                  <FM defaultMessage="No license specified" />
                ) : (
                  <FormattedList
                    value={licenseList.map((l) => l)}
                    type="unit"
                  />
                )}
              </Typography>
            ),
            dependencyScopes: (
              <Stack>
                {entryMessages.scope.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM {...msg} values={collectedMetadata['scope']} />
                  </Typography>
                ))}
              </Stack>
            ),
            callGraphAvailableDependencies: (
              <Stack>
                {entryMessages.callGraphAvailable.map((msg, i) => (
                  <Typography variant="body2" key={i}>
                    <FM
                      {...msg}
                      values={collectedMetadata['callGraphAvailable']}
                    />
                  </Typography>
                ))}
              </Stack>
            ),
            dependents: (
              <RowStack gap={1}>
                <Typography variant="body2">
                  <FM
                    defaultMessage="{direct, number} Direct"
                    values={collectedMetadata['dependents']}
                  />
                </Typography>
                <Typography variant="body2" color={palette.text.secondary}>
                  <FM
                    defaultMessage="({transitive, number} Transitive)"
                    values={collectedMetadata['dependents']}
                  />
                </Typography>
              </RowStack>
            ),
          }}
          variant="flex"
        ></AttributeDisplayStack>
      </DetailDrawerSection>
      <DetailDrawerSection title="Risk Matrix" id="risk_matrix">
        <Card variant="outlined" elevation={0}>
          <FindingRiskMatrixTableV2
            findingGroups={findingsByLevelAndReachability}
            isLoading={isLoading}
          />
        </Card>
      </DetailDrawerSection>
    </DetailDrawerSectionStack>
  );
};

const entryMessages = {
  callGraphAvailable: [
    defineMessage({
      defaultMessage: '{available, number} Available',
    }),
    defineMessage({
      defaultMessage: '{unavailable, number} Unavailable',
    }),
  ],
  visibility: [
    defineMessage({
      defaultMessage: '{public, number} Public',
    }),
    defineMessage({
      defaultMessage: '{private, number} Private',
    }),
  ],
  repoVisibility: [
    defineMessage({
      defaultMessage: '{hasSourceControl, number} With Source Control',
    }),
    defineMessage({
      defaultMessage: '{noSourceControl, number} Without Source Control',
    }),
  ],
  pinned: [
    defineMessage({
      defaultMessage: '{pinned, number} Pinned',
    }),
    defineMessage({
      defaultMessage: '{unpinned, number} Unpinned',
    }),
  ],
  reachable: [
    defineMessage({
      defaultMessage: '{reachable, number} Reachable',
    }),
    defineMessage({
      defaultMessage: '{unreachable, number} Unreachable',
    }),
    defineMessage({
      defaultMessage: '{unknown, number} Unknown',
    }),
  ],
  scope: [
    defineMessage({
      defaultMessage: '{DEPENDENCY_SCOPE_TEST, number} Test',
    }),
    defineMessage({
      defaultMessage: '{DEPENDENCY_SCOPE_BUILD, number} Build',
    }),
    defineMessage({
      defaultMessage: '{DEPENDENCY_SCOPE_UNSPECIFIED, number} Unspecified',
    }),
    defineMessage({
      defaultMessage: '{DEPENDENCY_SCOPE_NORMAL, number} Normal',
    }),
  ],
};
/**
 * NOTE: Order is important here
 */
const genBlankMetadata = (): Record<string, Record<string, number>> => ({
  oss: { direct: 0, transitive: 0 },
  pinned: { pinned: 0, unpinned: 0 },
  scope: {
    [V1DependencyScope.Build]: 0,
    [V1DependencyScope.Test]: 0,
    [V1DependencyScope.Unspecified]: 0,
    [V1DependencyScope.Normal]: 0,
  },
  reachable: { reachable: 0, unreachable: 0, unknown: 0 },
  dependents: { direct: 0, transitive: 0 },
  repoVisibility: { hasSourceControl: 0, noSourceControl: 0 },
  callGraphAvailable: { available: 0, unavailable: 0 },
  visibility: { private: 0, public: 0 },
});

const getDepMetadataCounts = (
  depMetadata: DependencyMetadataSpecStub[] = [],
  dependentGroups: V1GroupResponse = {},
  packageVersion?: QueryPackageVersionsResponseObject
) => {
  /**
   * Aggregate counts of various metadata found on DependencyMetadata
   */
  const dataFromDepMetadata = depMetadata.reduce((acc, depMetadata) => {
    const { dependency_data: data, importer_data } = depMetadata;

    if (importer_data?.callgraph_available) {
      acc.callGraphAvailable.available += 1;
    } else {
      acc.callGraphAvailable.unavailable += 1;
    }

    if (data?.namespace === 'oss') {
      if (data?.direct) {
        acc.oss.direct += 1;
      } else {
        acc.oss.transitive += 1;
      }
    }

    if (data?.pinned) {
      acc.pinned.pinned += 1;
    } else {
      acc.pinned.unpinned += 1;
    }

    if (data?.reachable === DependencyMetadataReachabilityType.Reachable) {
      acc.reachable.reachable += 1;
    } else if (
      data?.reachable === DependencyMetadataReachabilityType.Unreachable
    ) {
      acc.reachable.unreachable += 1;
    } else {
      acc.reachable.unknown += 1;
    }

    if (data?.repo_name) {
      acc.repoVisibility.hasSourceControl += 1;
    } else {
      acc.repoVisibility.noSourceControl += 1;
    }

    if (data?.scope) {
      acc.scope[data.scope] += 1;
    }

    return acc;
  }, genBlankMetadata());

  /**
   * Aggregate counts of various metadata found on PackageVersion dependencies
   */
  const dataFromPackageVersionDeps = (
    packageVersion?.spec.resolved_dependencies?.dependencies ?? []
  ).reduce((acc, dependency) => {
    if (dependency?.public) {
      acc.visibility.public += 1;
    } else {
      acc.visibility.private += 1;
    }
    return acc;
  }, dataFromDepMetadata);

  /** Add dependent count breakdown from dependents metadata query */
  const directDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('true')
  );
  const indirectDependents = Object.entries(dependentGroups).find(
    ([key]) => !!key.match('false')
  );

  dataFromPackageVersionDeps.dependents.direct = directDependents
    ? directDependents[1].aggregation_count.count
    : 0;
  dataFromPackageVersionDeps.dependents.indirect = indirectDependents
    ? indirectDependents[1].aggregation_count.count
    : 0;

  return dataFromPackageVersionDeps;
};
