import { Grid, GridProps, useTheme } from '@mui/material';
import { Children, ReactNode } from 'react';

interface ResponsiveCardLayoutProps {
  children: ReactNode;
  columnCount?: 1 | 2 | 3 | 4 | 6;
  gridProps?: Pick<GridProps, 'spacing'>;
}

export const ResponsiveCardLayout = ({
  children,
  columnCount = 2,
  gridProps = {},
}: ResponsiveCardLayoutProps) => {
  const { space } = useTheme();
  const { spacing = space.xl } = gridProps;

  const gridSizeLg = Math.floor(12 / columnCount);

  return (
    <Grid alignItems="stretch" container spacing={spacing}>
      {Children.map(children, (child, index) => {
        return (
          <Grid item key={index} lg={gridSizeLg} md={12} sm={12} xs={12}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};
