import { RepositoryResource, RepoVersionResource } from '@endorlabs/queries';

import { useInfoDrawer } from '../InfoDrawer';
import {
  ProjectToolsDetailDrawer,
  ProjectToolsDetailDrawerProps,
} from './ProjectToolDetailDrawer';

export interface ProjectToolsDetailDrawerParams {
  namespace: string;
  toolCategory: string;
}

interface PermalinkEffectProps {
  repository?: RepositoryResource;
  repositoryVersion?: RepoVersionResource;
}

export const useProjectToolsDetailDrawer = () => {
  const DetailDrawer = useInfoDrawer<
    ProjectToolsDetailDrawerProps,
    ProjectToolsDetailDrawerProps
  >({
    drawerParams: ['namespace', 'toolCategory'],
    Component: ProjectToolsDetailDrawer,
  });

  const permalinkEffect = ({
    repository,
    repositoryVersion,
  }: PermalinkEffectProps) => {
    const activeDrawerParams = DetailDrawer.getSearchParams();
    if (
      activeDrawerParams &&
      !DetailDrawer.matchesActiveContent(activeDrawerParams)
    ) {
      DetailDrawer.activate(
        {
          namespace: activeDrawerParams.namespace,
          toolCategory: activeDrawerParams.toolCategory,
        },
        {
          namespace: activeDrawerParams.namespace ?? '',
          repository,
          repositoryVersion,
          toolCategory: activeDrawerParams.toolCategory ?? '',
        }
      );
    }
  };

  return { DetailDrawer, permalinkEffect };
};
