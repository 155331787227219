export const ChartPaletteDefault = ['data.purple', 'data.yellow', 'data.blue'];

export const ChartPaletteFindingLevel = [
  'severity.critical',
  'severity.high',
  'severity.medium',
  'severity.low',
];

export const ChartPaletteFindingPriority = [
  'priority.prioritize',
  'priority.plan',
  'priority.backlog',
  'priority.defer',
];
