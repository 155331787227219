import {
  QueryKey,
  QueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import {
  FindingServiceApi,
  V1CountResponse,
  V1Finding,
  V1ListParameters,
} from '@endorlabs/api_client';
import {
  CountRequestParameters,
  ListRequestParameters,
} from '@endorlabs/endor-core/api';

import { useBuildReadRequestParameters } from './hooks';
import { QueryFindingsQueryKeys } from './query_findings';
import {
  FindingResource,
  FindingResourceList,
  ResourceMutateOptions,
  ResourceQueryOptions,
} from './types';
import {
  buildCountParamArgs,
  buildListParamArgs,
  buildResourceMutateMeta,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

export interface FindingReadParams {
  namespace: string;
  uuid: string;
}

export interface FindingWriteParams {
  namespace: string;
  resource: V1Finding;
}

export interface FindingUpdateParams extends FindingWriteParams {
  mask?: string;
}

type CountFindingOptions = ResourceQueryOptions<Required<V1CountResponse>>;
type ListFindingOptions = ResourceQueryOptions<FindingResourceList>;
type GetFindingOptions = ResourceQueryOptions<FindingResource>;
type UpsertFindingOptions = ResourceMutateOptions<
  V1Finding,
  FindingWriteParams
>;

const BASE_KEY = 'v1/findings';
const QK = {
  count: (namespace: string, countParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'count', namespace, countParams] as const,
  list: (namespace: string, listParams: V1ListParameters = {}): QueryKey =>
    [BASE_KEY, 'list', namespace, listParams] as const,
  record: (namespace: string, uuid: string): QueryKey =>
    [BASE_KEY, 'get', namespace, uuid] as const,
};
export const FindingQueryKeys = QK;

export const FINDING_UPDATE_MASK = 'meta,spec';

const getApiService = () => new FindingServiceApi(getClientConfiguration());

const countFindings = async (
  namespace: string,
  countParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.findingServiceListFindings(
    namespace,
    ...buildCountParamArgs(countParams),
    { signal }
  );

  return resp.data.count_response as Required<V1CountResponse>;
};

export const useCountFindings = (
  namespace: string,
  opts: CountFindingOptions = {},
  countParams: CountRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Finding',
    'COUNT',
    countParams,
    opts
  );

  return useQuery(
    QK.count(namespace, requestParameters),
    (ctx) => countFindings(namespace, requestParameters, ctx.signal),
    opts
  );
};

const listFindings = async (
  namespace: string,
  listParams: V1ListParameters = {},
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.findingServiceListFindings(
    namespace,
    ...buildListParamArgs(listParams),
    { signal }
  );

  return resp.data as FindingResourceList;
};

export const listFindingsQueryOptions = (
  namespace: string,
  requestParameters: ListRequestParameters = {}
): QueryOptions<FindingResourceList> => ({
  queryKey: QK.list(namespace, requestParameters),
  queryFn: (ctx) => listFindings(namespace, requestParameters, ctx.signal),
});

export const countFindingsQueryOptions = (
  namespace: string,
  requestParameters: CountRequestParameters = {}
): QueryOptions<V1CountResponse> => ({
  queryKey: QK.count(namespace, requestParameters),
  queryFn: (ctx) => countFindings(namespace, requestParameters, ctx.signal),
});

export const useListFindings = (
  namespace: string,
  opts: ListFindingOptions = {},
  listParams: ListRequestParameters = {}
) => {
  const requestParameters = useBuildReadRequestParameters(
    'Finding',
    'LIST',
    listParams,
    opts
  );

  const { queryKey, queryFn } = listFindingsQueryOptions(
    namespace,
    requestParameters
  );

  return useQuery({ queryKey, queryFn, ...opts });
};

const getFinding = async (namespace: string, uuid: string) => {
  const api = getApiService();
  const resp = await api.findingServiceGetFinding(namespace, uuid);
  return resp.data as FindingResource;
};

export const useGetFinding = (
  params: FindingReadParams,
  opts: GetFindingOptions = {}
) => {
  return useQuery(
    QK.record(params.namespace, params.uuid),
    () => getFinding(params.namespace, params.uuid),
    opts
  );
};

const updateFinding = async (params: FindingUpdateParams) => {
  const { resource, namespace, mask = FINDING_UPDATE_MASK } = params;
  const req = buildUpdateReq(resource, mask);
  const api = getApiService();
  const resp = await api.findingServiceUpdateFinding(namespace, req);
  return resp.data as FindingResource;
};

export const useUpdateFinding = (opts: UpsertFindingOptions = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...opts,
    meta: buildResourceMutateMeta('UPDATE', 'Finding'),
    mutationFn: (params: FindingUpdateParams) => updateFinding(params),
    onSettled: (data, error, vars, context) => {
      if (data && !error) {
        // On success, invalidate cache
        queryClient.invalidateQueries(QK.list(vars.namespace));
        queryClient.invalidateQueries(
          QueryFindingsQueryKeys.query(vars.namespace)
        );

        if (vars.resource.uuid) {
          queryClient.invalidateQueries(
            QK.record(vars.namespace, vars.resource.uuid)
          );
        }
      }

      // Honor existing callback
      if (opts.onSettled) {
        opts.onSettled(data, error, vars, context);
      }
    },
  });
};
