import { ScanProfileResource } from '@endorlabs/endor-core/ScanProfile';

export const DEFAULT_SCAN_PROFILE_FIELD_VALUES: Omit<
  ScanProfileResource,
  'tenant_meta' | 'uuid'
> = {
  meta: { name: '' },
  propagate: true,
  spec: {
    automated_scan_parameters: {
      enable_pr_comments: false,
      enable_remediation_action: false,
      bazel_configuration: {
        bazel_show_internal_targets: false,
      },
    },
  },
};
