// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
function id(x) { return x[0]; }

// HELPERS
const ENCLOSED_KEY = Symbol('ENCLOSED')

const at = index => (data) => data[index]
const always = value => () => value
const enclosed = (value) => {
    Object.defineProperty(value, ENCLOSED_KEY, { enumerable: false, writable: false })
    return value
}

/**
 * Helper to post-process primitives from filter values
 */
const parseValue = (target) => {
	if (target && !Array.isArray(target.value)) {
		const numericValue = Number(target.value);
		if (numericValue.toString() === target.value) {
			target.value = numericValue;
		}

		if (target.value === true.toString()) {
			target.value = true;
		} else if (target.value === false.toString()) {
			target.value = false;
		}
	}

	return target;
}

/**
 * Helper to "unwrap" a logical filter, if sub-filters are of the same type, and
 * have not been explicitly enclosed with parentheses.
 */
const unwrapFilters = (type, subfilters) => {
	const result = [];

    // unwrap
    for (const filter of subfilters) {
        if ('comparator' in filter || filter.operator !== type || ENCLOSED_KEY in filter) {
            result.push(filter)
        } else {
            result.push(...filter.value)
        }
    }

	return result;
}

// OBJECT FACTORIES
const Operator = (operator, ...subfilters) => ({ operator, value: unwrapFilters(operator, subfilters) })
const Comparator = (comparator, key, value) => ({ comparator, key, value })

let Lexer = undefined;
let ParserRules = [
    {"name": "main", "symbols": ["_", "expr", "_"], "postprocess": at(1)},
    {"name": "expr", "symbols": [{"literal":"("}, "_", "expr", "_", {"literal":")"}], "postprocess": d => enclosed(d[2])},
    {"name": "expr$string$1", "symbols": [{"literal":"a"}, {"literal":"n"}, {"literal":"d"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expr", "symbols": ["expr", "__", "expr$string$1", "__", "expr"], "postprocess": d => Operator('AND', d[0],  d[4])},
    {"name": "expr$string$2", "symbols": [{"literal":"o"}, {"literal":"r"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expr", "symbols": ["expr", "__", "expr$string$2", "__", "expr"], "postprocess": d => Operator('OR', d[0],  d[4])},
    {"name": "expr", "symbols": ["term"], "postprocess": id},
    {"name": "term", "symbols": ["key", "_", "comparator", "_", "value"], "postprocess": d => Comparator(d[2], d[0].value, d[4].value)},
    {"name": "term", "symbols": ["key", "_", "comparator", "_", "datetime_value"], "postprocess": d => Comparator(d[2], d[0].value, d[4].value)},
    {"name": "term", "symbols": ["key", "_", "comparator", "_", {"literal":"["}, "_", "list", "_", {"literal":"]"}], "postprocess": d => Comparator(d[2], d[0].value, d[6].value)},
    {"name": "term", "symbols": ["key", "_", "comparator_without_value"], "postprocess": d => Comparator(d[2], d[0].value, undefined)},
    {"name": "comparator$string$1", "symbols": [{"literal":"="}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$1"], "postprocess": always("EQUAL")},
    {"name": "comparator$string$2", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$2"], "postprocess": always("NOT_EQUAL")},
    {"name": "comparator$string$3", "symbols": [{"literal":"m"}, {"literal":"a"}, {"literal":"t"}, {"literal":"c"}, {"literal":"h"}, {"literal":"e"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$3"], "postprocess": always("MATCHES")},
    {"name": "comparator$string$4", "symbols": [{"literal":"i"}, {"literal":"n"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$4"], "postprocess": always("IN")},
    {"name": "comparator$string$5", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"t"}, {"literal":" "}, {"literal":"i"}, {"literal":"n"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$5"], "postprocess": always("NOT_IN")},
    {"name": "comparator$string$6", "symbols": [{"literal":"c"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"a"}, {"literal":"i"}, {"literal":"n"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$6"], "postprocess": always("CONTAINS")},
    {"name": "comparator$string$7", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"t"}, {"literal":" "}, {"literal":"c"}, {"literal":"o"}, {"literal":"n"}, {"literal":"t"}, {"literal":"a"}, {"literal":"i"}, {"literal":"n"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$7"], "postprocess": always("NOT_CONTAINS")},
    {"name": "comparator", "symbols": [{"literal":">"}], "postprocess": always("GREATER")},
    {"name": "comparator$string$8", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$8"], "postprocess": always("GREATER_OR_EQUAL")},
    {"name": "comparator", "symbols": [{"literal":"<"}], "postprocess": always("LESSER")},
    {"name": "comparator$string$9", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator", "symbols": ["comparator$string$9"], "postprocess": always("LESSER_OR_EQUAL")},
    {"name": "comparator_without_value$string$1", "symbols": [{"literal":"e"}, {"literal":"x"}, {"literal":"i"}, {"literal":"s"}, {"literal":"t"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator_without_value", "symbols": ["comparator_without_value$string$1"], "postprocess": always("EXISTS")},
    {"name": "comparator_without_value$string$2", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"t"}, {"literal":" "}, {"literal":"e"}, {"literal":"x"}, {"literal":"i"}, {"literal":"s"}, {"literal":"t"}, {"literal":"s"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "comparator_without_value", "symbols": ["comparator_without_value$string$2"], "postprocess": always("NOT_EXISTS")},
    {"name": "key", "symbols": ["simple_string"], "postprocess": id},
    {"name": "value", "symbols": ["string"], "postprocess": d => parseValue(d[0])},
    {"name": "value", "symbols": [{"literal":"\""}, "string", {"literal":"\""}], "postprocess": at(1)},
    {"name": "datetime_value$string$1", "symbols": [{"literal":"d"}, {"literal":"a"}, {"literal":"t"}, {"literal":"e"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datetime_value", "symbols": ["datetime_value$string$1", "value", {"literal":")"}], "postprocess": d => ({ value: 'date(' + d[1].value + ')' })},
    {"name": "datetime_value$string$2", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"w"}, {"literal":"("}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "datetime_value", "symbols": ["datetime_value$string$2", "value", {"literal":")"}], "postprocess": d => ({ value: 'now(' + d[1].value + ')' })},
    {"name": "list", "symbols": [], "postprocess": () => ({ value: [] })},
    {"name": "list", "symbols": ["value"], "postprocess": d => ({ value: [ d[0].value ] })},
    {"name": "list", "symbols": ["list", "_", {"literal":","}, "_", "value"], "postprocess": d => ({ value: [ ...d[0].value, d[4].value ] })},
    {"name": "string$ebnf$1", "symbols": [/[a-zA-Z0-9_.^$:-]/]},
    {"name": "string$ebnf$1", "symbols": ["string$ebnf$1", /[a-zA-Z0-9_.^$:-]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "string", "symbols": ["string$ebnf$1"], "postprocess": d => ({ value: d[0].join('') })},
    {"name": "simple_string$ebnf$1", "symbols": [/[a-zA-Z0-9_.]/]},
    {"name": "simple_string$ebnf$1", "symbols": ["simple_string$ebnf$1", /[a-zA-Z0-9_.]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "simple_string", "symbols": ["simple_string$ebnf$1"], "postprocess": d => ({ value: d[0].join('') })},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", /[\s]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": always(undefined)},
    {"name": "__$ebnf$1", "symbols": [/[\s]/]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", /[\s]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": always(undefined)}
];
let ParserStart = "main";
export default { Lexer, ParserRules, ParserStart };
