import {
  SavedQueryServiceApi,
  V1CountResponse,
  V1SavedQuery,
} from '@endorlabs/api_client';
import { SavedQueryResource } from '@endorlabs/endor-core/SavedQuery';

import {
  createResourceMutateHooks,
  createResourceReadHooks,
  ReadServiceRequestOptions,
} from './hooks';
import {
  ListParamsArray,
  ResourceGroupResponse,
  ResourceListResponse,
} from './types';
import {
  buildCountParamArgs,
  buildGetParamArgs,
  buildListParamArgs,
  buildUpdateReq,
  getClientConfiguration,
} from './utils';

const DEFAULT_UPDATE_MASK = 'spec';

const queries = createResourceReadHooks('SavedQuery', () => {
  const getApiService = () =>
    new SavedQueryServiceApi(getClientConfiguration());

  const listHandler = (
    namespace: string,
    args: ListParamsArray,
    options: ReadServiceRequestOptions
  ) =>
    getApiService().savedQueryServiceListSavedQueries(
      namespace,
      ...args,
      options
    );

  return {
    async count(namespace, params, options) {
      const args = buildCountParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.count_response as Required<V1CountResponse>;
    },
    async get(namespace, params, options) {
      const args = buildGetParamArgs(params);
      const resp = await getApiService().savedQueryServiceGetSavedQuery(
        namespace,
        ...args,
        options
      );
      return resp.data as SavedQueryResource;
    },
    async group(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.group_response as ResourceGroupResponse;
    },
    async list(namespace, params, options) {
      const args = buildListParamArgs(params);
      const resp = await listHandler(namespace, args, options);
      return resp.data.list as ResourceListResponse<SavedQueryResource>;
    },
  };
});

const mutations = createResourceMutateHooks<SavedQueryResource>(
  'SavedQuery',
  () => {
    const getApiService = () =>
      new SavedQueryServiceApi(getClientConfiguration());

    return {
      async create(namespace, resource) {
        const resp = await getApiService().savedQueryServiceCreateSavedQuery(
          namespace,
          resource as V1SavedQuery
        );
        return resp.data as SavedQueryResource;
      },
      async delete(namespace, resource) {
        await getApiService().savedQueryServiceDeleteSavedQuery(
          namespace,
          resource.uuid
        );
      },
      async update(namespace, params) {
        const { resource, mask = DEFAULT_UPDATE_MASK } = params;
        const request = buildUpdateReq(resource, mask);
        const resp = await getApiService().savedQueryServiceUpdateSavedQuery(
          namespace,
          request
        );

        return resp.data as SavedQueryResource;
      },
    };
  }
);

// Expose query hooks for SavedQuery
export const useCountSavedQueries = queries.useCount;
export const useGetSavedQuery = queries.useGet;
export const useGroupSavedQueries = queries.useGroup;
export const useListSavedQueries = queries.useList;

// Expose mutation hooks for SavedQuery
export const useCreateSavedQuery = mutations.useCreate;
export const useDeleteSavedQuery = mutations.useDelete;
export const useUpdateSavedQuery = mutations.useUpdate;
