import { FormattedMessage as FM } from 'react-intl';

import { V1ScanState as ScanState } from '@endorlabs/api_client';

import { ScanStateMessages } from '../../locales';

interface ScanStateLabelProps {
  value?: ScanState;
}

export const ScanStateLabel = ({
  value = ScanState.Unspecified,
}: ScanStateLabelProps) => <FM {...ScanStateMessages[value]} />;
