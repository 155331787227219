import { Theme, Typography, useTheme } from '@mui/material';

import { IconCve, RowStack, useStyles } from '@endorlabs/ui-common';

interface CveIdDisplayProps {
  cveId: string;
}

export const CveIdDisplay = ({ cveId }: CveIdDisplayProps) => {
  const { space } = useTheme();
  const sx = useStyles(styles);

  return (
    <RowStack className="CveIdDisplay-root" gap={space.xs} sx={sx}>
      <IconCve fontSize="large" />
      <Typography color="text.secondary" sx={{ lineHeight: 1 }} variant="code">
        {cveId}
      </Typography>
    </RowStack>
  );
};

function styles({ palette }: Theme) {
  return {
    '&.CveIdDisplay-root': {
      color: palette.text.secondary,
    },
  };
}
