import LogoAnsible from '../../../assets/ci-cd-tools/tool-logo-ansible.svg';
import LogoAppveyor from '../../../assets/ci-cd-tools/tool-logo-appveyor.svg';
import LogoAwsCloudFormation from '../../../assets/ci-cd-tools/tool-logo-aws-cloudformation.svg';
import LogoAwsCodeBuild from '../../../assets/ci-cd-tools/tool-logo-aws-codebuild.svg';
import LogoAwsEcr from '../../../assets/ci-cd-tools/tool-logo-aws-ecr.svg';
import LogoAzureContainerRegistry from '../../../assets/ci-cd-tools/tool-logo-azure-containerregistry.svg';
import LogoAzurePipelines from '../../../assets/ci-cd-tools/tool-logo-azure-pipelines.svg';
import LogoAzureResourceGroup from '../../../assets/ci-cd-tools/tool-logo-azure-resourcegroup.svg';
import LogoBandit from '../../../assets/ci-cd-tools/tool-logo-bandit.svg';
import LogoBitbucket from '../../../assets/ci-cd-tools/tool-logo-bitbucket.svg';
import LogoBlackduck from '../../../assets/ci-cd-tools/tool-logo-blackduck.svg';
import LogoBuddy from '../../../assets/ci-cd-tools/tool-logo-buddy.svg';
import LogoBuildkite from '../../../assets/ci-cd-tools/tool-logo-buildkite.svg';
import LogoCargoAudit from '../../../assets/ci-cd-tools/tool-logo-cargo-audit.png';
import LogoCargoDeny from '../../../assets/ci-cd-tools/tool-logo-cargo-deny.png';
import LogoCheckmarx from '../../../assets/ci-cd-tools/tool-logo-checkmarx.png';
import LogoCheckov from '../../../assets/ci-cd-tools/tool-logo-checkov.svg';
import LogoCheckstyle from '../../../assets/ci-cd-tools/tool-logo-checkstyle.svg';
import LogoCircleci from '../../../assets/ci-cd-tools/tool-logo-circleci.svg';
import LogoCodetotal from '../../../assets/ci-cd-tools/tool-logo-code-total.png';
import LogoCodeclimate from '../../../assets/ci-cd-tools/tool-logo-codeclimate.svg';
import LogoCodecov from '../../../assets/ci-cd-tools/tool-logo-codecov.svg';
import LogoCodeql from '../../../assets/ci-cd-tools/tool-logo-codeql.svg';
import LogoCodeship from '../../../assets/ci-cd-tools/tool-logo-codeship.svg';
import LogoContrast from '../../../assets/ci-cd-tools/tool-logo-contrast.png';
import LogoCosign from '../../../assets/ci-cd-tools/tool-logo-cosign.png';
import LogoCoverity from '../../../assets/ci-cd-tools/tool-logo-coverity.svg';
import LogoCypress from '../../../assets/ci-cd-tools/tool-logo-cypress.svg';
import LogoDebricked from '../../../assets/ci-cd-tools/tool-logo-debricked.png';
import LogoDependabot from '../../../assets/ci-cd-tools/tool-logo-dependabot.svg';
import LogoDetekt from '../../../assets/ci-cd-tools/tool-logo-detekt.svg';
import LogoDocker from '../../../assets/ci-cd-tools/tool-logo-docker.svg';
import LogoDrone from '../../../assets/ci-cd-tools/tool-logo-drone.svg';
import LogoEclipseSteady from '../../../assets/ci-cd-tools/tool-logo-eclipse-steady.png';
import LogoEndorLabs from '../../../assets/ci-cd-tools/tool-logo-endorlabs.svg';
import LogoEslint from '../../../assets/ci-cd-tools/tool-logo-eslint.svg';
import LogoFortify from '../../../assets/ci-cd-tools/tool-logo-fortify.png';
import LogoGitSecrets from '../../../assets/ci-cd-tools/tool-logo-git-secrets.svg';
import LogoGitGuardian from '../../../assets/ci-cd-tools/tool-logo-gitguardian.png';
import LogoGithubActions from '../../../assets/ci-cd-tools/tool-logo-github-actions.svg';
import LogoGithubPackages from '../../../assets/ci-cd-tools/tool-logo-github-packages.png';
import LogoGithubSecrets from '../../../assets/ci-cd-tools/tool-logo-github-secrets.png';
import LogoGitlab from '../../../assets/ci-cd-tools/tool-logo-gitlab.svg';
import LogoGitLeaks from '../../../assets/ci-cd-tools/tool-logo-gitleaks.png';
import LogoGnuMake from '../../../assets/ci-cd-tools/tool-logo-gnu-make.svg';
import LogoGoogleArtifactRegistry from '../../../assets/ci-cd-tools/tool-logo-google-artifact-registry.svg';
import LogoGoogleCloudbuild from '../../../assets/ci-cd-tools/tool-logo-google-cloudbuild.svg';
import LogoGoreleaser from '../../../assets/ci-cd-tools/tool-logo-goreleaser.svg';
import LogoGrafana from '../../../assets/ci-cd-tools/tool-logo-grafana.svg';
import LogoHCLSoftware from '../../../assets/ci-cd-tools/tool-logo-hcl-software.png';
import LogoHypothesis from '../../../assets/ci-cd-tools/tool-logo-hypothesis.svg';
import LogoJenkins from '../../../assets/ci-cd-tools/tool-logo-jenkins.svg';
import LogoJest from '../../../assets/ci-cd-tools/tool-logo-jest.svg';
import LogoJfrog from '../../../assets/ci-cd-tools/tool-logo-jfrog.svg';
import LogoJscs from '../../../assets/ci-cd-tools/tool-logo-jscs.svg';
import LogoKarma from '../../../assets/ci-cd-tools/tool-logo-karma.svg';
import LogoKubernetes from '../../../assets/ci-cd-tools/tool-logo-kubernetes.svg';
import LogoMarkdownLint from '../../../assets/ci-cd-tools/tool-logo-markdownlint.svg';
import LogoMaven from '../../../assets/ci-cd-tools/tool-logo-maven.svg';
import LogoMegaLinter from '../../../assets/ci-cd-tools/tool-logo-megalinter.png';
import LogoMeson from '../../../assets/ci-cd-tools/tool-logo-meson.svg';
import LogoMypy from '../../../assets/ci-cd-tools/tool-logo-mypy.svg';
import LogoNpm from '../../../assets/ci-cd-tools/tool-logo-npm.svg';
import LogoNuget from '../../../assets/ci-cd-tools/tool-logo-nuget.svg';
import LogoOnefuzz from '../../../assets/ci-cd-tools/tool-logo-onefuzz.svg';
import LogoOpenssf from '../../../assets/ci-cd-tools/tool-logo-openssf.svg';
import LogoOrca from '../../../assets/ci-cd-tools/tool-logo-orca.svg';
import LogoOwasp from '../../../assets/ci-cd-tools/tool-logo-owasp.svg';
import LogoOxSecurity from '../../../assets/ci-cd-tools/tool-logo-oxsecurity.png';
import LogoPhpunit from '../../../assets/ci-cd-tools/tool-logo-phpunit.svg';
import LogoPodman from '../../../assets/ci-cd-tools/tool-logo-podman.svg';
import LogoPrettier from '../../../assets/ci-cd-tools/tool-logo-prettier.svg';
import LogoPrismaCloud from '../../../assets/ci-cd-tools/tool-logo-prisma-cloud.svg';
import LogoPrometheus from '../../../assets/ci-cd-tools/tool-logo-prometheus.svg';
import LogoProtractor from '../../../assets/ci-cd-tools/tool-logo-protractor.svg';
import LogoPulumi from '../../../assets/ci-cd-tools/tool-logo-pulumi.svg';
import LogoPuppet from '../../../assets/ci-cd-tools/tool-logo-puppet.svg';
import LogoPycobertura from '../../../assets/ci-cd-tools/tool-logo-pycobertura.svg';
import LogoPypi from '../../../assets/ci-cd-tools/tool-logo-pypi.svg';
import LogoPytest from '../../../assets/ci-cd-tools/tool-logo-pytest.svg';
import LogoPyup from '../../../assets/ci-cd-tools/tool-logo-pyup.svg';
import LogoQemu from '../../../assets/ci-cd-tools/tool-logo-qemu.svg';
import LogoQuay from '../../../assets/ci-cd-tools/tool-logo-quay.svg';
import LogoRancher from '../../../assets/ci-cd-tools/tool-logo-rancher.svg';
import LogoRenovate from '../../../assets/ci-cd-tools/tool-logo-renovate.svg';
import LogoRetireJS from '../../../assets/ci-cd-tools/tool-logo-retire-js.png';
import LogoRubocop from '../../../assets/ci-cd-tools/tool-logo-rubocop.svg';
import LogoSaltstack from '../../../assets/ci-cd-tools/tool-logo-saltstack.svg';
import LogoScrewdriver from '../../../assets/ci-cd-tools/tool-logo-screwdriver.png';
import LogoSelenium from '../../../assets/ci-cd-tools/tool-logo-selenium.svg';
import LogoSemgrep from '../../../assets/ci-cd-tools/tool-logo-semgrep.svg';
import LogoServerless from '../../../assets/ci-cd-tools/tool-logo-serverless.svg';
import LogoShell from '../../../assets/ci-cd-tools/tool-logo-shell.svg';
import LogoSigstore from '../../../assets/ci-cd-tools/tool-logo-sigstore.svg';
import LogoSlsa from '../../../assets/ci-cd-tools/tool-logo-slsa.svg';
import LogoSnyk from '../../../assets/ci-cd-tools/tool-logo-snyk.svg';
import LogoSonarqube from '../../../assets/ci-cd-tools/tool-logo-sonarqube.svg';
import LogoSonatype from '../../../assets/ci-cd-tools/tool-logo-sonatype.svg';
import LogoSphinx from '../../../assets/ci-cd-tools/tool-logo-sphinx.svg';
import LogoSpotBugs from '../../../assets/ci-cd-tools/tool-logo-spotbugs.png';
import LogoStepSecurity from '../../../assets/ci-cd-tools/tool-logo-step-security.jpeg';
import LogoSurge from '../../../assets/ci-cd-tools/tool-logo-surge.svg';
import LogoTeamcity from '../../../assets/ci-cd-tools/tool-logo-teamcity.svg';
import LogoTekton from '../../../assets/ci-cd-tools/tool-logo-tekton.png';
import LogoTerraform from '../../../assets/ci-cd-tools/tool-logo-terraform.svg';
import LogoTravisci from '../../../assets/ci-cd-tools/tool-logo-travisci.svg';
import LogoTrivy from '../../../assets/ci-cd-tools/tool-logo-trivy.svg';
import LogoTrufflehog from '../../../assets/ci-cd-tools/tool-logo-trufflehog.png';
import LogoVargrant from '../../../assets/ci-cd-tools/tool-logo-vagrant.svg';
import LogoVeracode from '../../../assets/ci-cd-tools/tool-logo-veracode.svg';
import LogoWhitesource from '../../../assets/ci-cd-tools/tool-logo-whitesource.svg';
import createImgIcon from './createImgIcon';

export const ToolLogoAnsible = createImgIcon(LogoAnsible, 'ToolLogoAnsible');
export const ToolLogoAppveyor = createImgIcon(LogoAppveyor, 'ToolLogoAppveyor');
export const ToolLogoAwsCloudFormation = createImgIcon(
  LogoAwsCloudFormation,
  'ToolLogoAwsCloudFormation'
);
export const ToolLogoAwsCodeBuild = createImgIcon(
  LogoAwsCodeBuild,
  'ToolLogoAwsCodeBuild'
);
export const ToolLogoAwsEcr = createImgIcon(LogoAwsEcr, 'ToolLogoAwsEcr');
export const ToolLogoAzureContainerRegistry = createImgIcon(
  LogoAzureContainerRegistry,
  'ToolLogoAzureContainerRegistry'
);
export const ToolLogoAzurePipelines = createImgIcon(
  LogoAzurePipelines,
  'ToolLogoAzurePipelines'
);
export const ToolLogoAzureResourceGroup = createImgIcon(
  LogoAzureResourceGroup,
  'ToolLogoAzureResourceGroup'
);
export const ToolLogoBandit = createImgIcon(LogoBandit, 'ToolLogoBandit');
export const ToolLogoBitbucket = createImgIcon(
  LogoBitbucket,
  'ToolLogoBitbucket'
);
export const ToolLogoBlackduck = createImgIcon(
  LogoBlackduck,
  'ToolLogoBlackduck'
);
export const ToolLogoBuddy = createImgIcon(LogoBuddy, 'ToolLogoBuddy');
export const ToolLogoBuildkite = createImgIcon(
  LogoBuildkite,
  'ToolLogoBuildkite'
);
export const ToolLogoCargoAudit = createImgIcon(
  LogoCargoAudit,
  'ToolLogoCargoAudit'
);
export const ToolLogoCargoDeny = createImgIcon(
  LogoCargoDeny,
  'ToolLogoCargoDeny'
);
export const ToolLogoCheckmarx = createImgIcon(
  LogoCheckmarx,
  'ToolLogoContrast'
);
export const ToolLogoCheckov = createImgIcon(LogoCheckov, 'ToolLogoCheckov');
export const ToolLogoCheckstyle = createImgIcon(
  LogoCheckstyle,
  'ToolLogoCheckstyle'
);
export const ToolLogoCircleci = createImgIcon(LogoCircleci, 'ToolLogoCircleci');
export const ToolLogoCodeclimate = createImgIcon(
  LogoCodeclimate,
  'ToolLogoCodeclimate'
);
export const ToolLogoCodecov = createImgIcon(LogoCodecov, 'ToolLogoCodecov');
export const ToolLogoCodeql = createImgIcon(LogoCodeql, 'ToolLogoCodeql');
export const ToolLogoCodeship = createImgIcon(LogoCodeship, 'ToolLogoCodeship');
export const ToolLogoCodetotal = createImgIcon(
  LogoCodetotal,
  'ToolLogoCodetotal'
);
export const ToolLogoContrast = createImgIcon(LogoContrast, 'ToolLogoContrast');
export const ToolLogoCosign = createImgIcon(LogoCosign, 'ToolLogoCosign');
export const ToolLogoCoverity = createImgIcon(LogoCoverity, 'ToolLogoCoverity');
export const ToolLogoCypress = createImgIcon(LogoCypress, 'ToolLogoCypress');
export const ToolLogoDebricked = createImgIcon(
  LogoDebricked,
  'ToolLogoDebricked'
);
export const ToolLogoDependabot = createImgIcon(
  LogoDependabot,
  'ToolLogoDependabot'
);
export const ToolLogoDetekt = createImgIcon(LogoDetekt, 'ToolLogoDetekt');
export const ToolLogoDocker = createImgIcon(LogoDocker, 'ToolLogoDocker');
export const ToolLogoDrone = createImgIcon(LogoDrone, 'ToolLogoDrone');
export const ToolLogoEndorLabs = createImgIcon(
  LogoEndorLabs,
  'ToolLogoEndorLabs'
);
export const ToolLogoEclipseSteady = createImgIcon(
  LogoEclipseSteady,
  'ToolLogoEclipseSteady'
);
export const ToolLogoEslint = createImgIcon(LogoEslint, 'ToolLogoEslint');
export const ToolLogoFortify = createImgIcon(LogoFortify, 'ToolLogoFortify');
export const ToolLogoGitGuardian = createImgIcon(
  LogoGitGuardian,
  'ToolLogoGitGuardian'
);
export const ToolLogoGithubActions = createImgIcon(
  LogoGithubActions,
  'ToolLogoGithubActions'
);
export const ToolLogoGithubPackages = createImgIcon(
  LogoGithubPackages,
  'ToolLogoGithubPackages'
);
export const ToolLogoGithubSecrets = createImgIcon(
  LogoGithubSecrets,
  'ToolLogoGithubSecrets'
);
export const ToolLogoGitlab = createImgIcon(LogoGitlab, 'ToolLogoGitlab');
export const ToolLogoGitLeaks = createImgIcon(LogoGitLeaks, 'ToolLogoGitLeaks');
export const ToolLogoGitSecrets = createImgIcon(
  LogoGitSecrets,
  'ToolLogoGitSecrets'
);
export const ToolLogoGnuMake = createImgIcon(LogoGnuMake, 'ToolLogoGnuMake');
export const ToolLogoGoogleArtifactRegistry = createImgIcon(
  LogoGoogleArtifactRegistry,
  'ToolLogoGoogleArtifactRegistry'
);
export const ToolLogoGoogleCloudbuild = createImgIcon(
  LogoGoogleCloudbuild,
  'ToolLogoGoogleCloudbuild'
);
export const ToolLogoGoreleaser = createImgIcon(
  LogoGoreleaser,
  'ToolLogoGoreleaser'
);
export const ToolLogoGrafana = createImgIcon(LogoGrafana, 'ToolLogoGrafana');
export const ToolLogoHCLSoftware = createImgIcon(
  LogoHCLSoftware,
  'ToolLogoHCLSoftware'
);
export const ToolLogoHypothesis = createImgIcon(
  LogoHypothesis,
  'ToolLogoHypothesis'
);
export const ToolLogoJenkins = createImgIcon(LogoJenkins, 'ToolLogoJenkins');
export const ToolLogoJest = createImgIcon(LogoJest, 'ToolLogoJest');
export const ToolLogoJfrog = createImgIcon(LogoJfrog, 'ToolLogoJfrog');
export const ToolLogoJscs = createImgIcon(LogoJscs, 'ToolLogoJscs');
export const ToolLogoKarma = createImgIcon(LogoKarma, 'ToolLogoKarma');
export const ToolLogoKubernetes = createImgIcon(
  LogoKubernetes,
  'ToolLogoKubernetes'
);
export const ToolLogoMarkdownLint = createImgIcon(
  LogoMarkdownLint,
  'ToolLogoMarkdownLint'
);
export const ToolLogoMaven = createImgIcon(LogoMaven, 'ToolLogoMaven');
export const ToolLogoMeson = createImgIcon(LogoMeson, 'ToolLogoMeson');
export const ToolLogoMegaLinter = createImgIcon(
  LogoMegaLinter,
  'ToolLogoMegaLinter'
);
export const ToolLogoMypy = createImgIcon(LogoMypy, 'ToolLogoMypy');
export const ToolLogoNpm = createImgIcon(LogoNpm, 'ToolLogoNpm');
export const ToolLogoNuget = createImgIcon(LogoNuget, 'ToolLogoNuget');
export const ToolLogoOnefuzz = createImgIcon(LogoOnefuzz, 'ToolLogoOnefuzz');
export const ToolLogoOpenssf = createImgIcon(LogoOpenssf, 'ToolLogoOpenssf');
export const ToolLogoOrca = createImgIcon(LogoOrca, 'ToolLogoOrca');
export const ToolLogoOwasp = createImgIcon(LogoOwasp, 'ToolLogoOwasp');
export const ToolLogoOxSecurity = createImgIcon(
  LogoOxSecurity,
  'ToolLogoOxSecurity'
);
export const ToolLogoPhpunit = createImgIcon(LogoPhpunit, 'ToolLogoPhpunit');
export const ToolLogoPodman = createImgIcon(LogoPodman, 'ToolLogoPodman');
export const ToolLogoPrettier = createImgIcon(LogoPrettier, 'ToolLogoPrettier');
export const ToolLogoPrismaCloud = createImgIcon(
  LogoPrismaCloud,
  'ToolLogoPrismaCloud'
);
export const ToolLogoPrometheus = createImgIcon(
  LogoPrometheus,
  'ToolLogoPrometheus'
);
export const ToolLogoProtractor = createImgIcon(
  LogoProtractor,
  'ToolLogoProtractor'
);
export const ToolLogoPulumi = createImgIcon(LogoPulumi, 'ToolLogoPulumi');
export const ToolLogoPuppet = createImgIcon(LogoPuppet, 'ToolLogoPuppet');
export const ToolLogoPycobertura = createImgIcon(
  LogoPycobertura,
  'ToolLogoPycobertura'
);
export const ToolLogoPypi = createImgIcon(LogoPypi, 'ToolLogoPypi');
export const ToolLogoPytest = createImgIcon(LogoPytest, 'ToolLogoPytest');
export const ToolLogoPyup = createImgIcon(LogoPyup, 'ToolLogoPyup');
export const ToolLogoQemu = createImgIcon(LogoQemu, 'ToolLogoQemu');
export const ToolLogoQuay = createImgIcon(LogoQuay, 'ToolLogoQuay');
export const ToolLogoRancher = createImgIcon(LogoRancher, 'ToolLogoRancher');
export const ToolLogoRenovate = createImgIcon(LogoRenovate, 'ToolLogoRenovate');
export const ToolLogoRetireJS = createImgIcon(LogoRetireJS, 'ToolLogoRetireJS');
export const ToolLogoRubocop = createImgIcon(LogoRubocop, 'ToolLogoRubocop');
export const ToolLogoSaltstack = createImgIcon(
  LogoSaltstack,
  'ToolLogoSaltstack'
);
export const ToolLogoScrewdriver = createImgIcon(
  LogoScrewdriver,
  'ToolLogoScrewdriver'
);
export const ToolLogoSelenium = createImgIcon(LogoSelenium, 'ToolLogoSelenium');
export const ToolLogoSemgrep = createImgIcon(LogoSemgrep, 'ToolLogoSemgrep');
export const ToolLogoServerless = createImgIcon(
  LogoServerless,
  'ToolLogoServerless'
);
export const ToolLogoShell = createImgIcon(LogoShell, 'ToolLogoShell');
export const ToolLogoSigstore = createImgIcon(LogoSigstore, 'ToolLogoSigstore');
export const ToolLogoSlsa = createImgIcon(LogoSlsa, 'ToolLogoSlsa');
export const ToolLogoSnyk = createImgIcon(LogoSnyk, 'ToolLogoSnyk');
export const ToolLogoSonarqube = createImgIcon(
  LogoSonarqube,
  'ToolLogoSonarqube'
);
export const ToolLogoSonatype = createImgIcon(LogoSonatype, 'ToolLogoSonatype');
export const ToolLogoSphinx = createImgIcon(LogoSphinx, 'ToolLogoSphinx');
export const ToolLogoSpotBugs = createImgIcon(LogoSpotBugs, 'ToolLogoSpotBugs');
export const ToolLogoStepSecurity = createImgIcon(
  LogoStepSecurity,
  'ToolLogoStepSecurity'
);
export const ToolLogoSurge = createImgIcon(LogoSurge, 'ToolLogoSurge');
export const ToolLogoTeamcity = createImgIcon(LogoTeamcity, 'ToolLogoTeamcity');
export const ToolLogoTerraform = createImgIcon(
  LogoTerraform,
  'ToolLogoTerraform'
);
export const ToolLogoTekton = createImgIcon(LogoTekton, 'ToolLogoTekton');
export const ToolLogoTravisci = createImgIcon(LogoTravisci, 'ToolLogoTravisci');
export const ToolLogoTrivy = createImgIcon(LogoTrivy, 'ToolLogoTrivy');
export const ToolLogoTrufflehog = createImgIcon(
  LogoTrufflehog,
  'ToolLogoTrufflehog'
);
export const ToolLogoVargrant = createImgIcon(LogoVargrant, 'ToolLogoVargrant');
export const ToolLogoVeracode = createImgIcon(LogoVeracode, 'ToolLogoVeracode');
export const ToolLogoWhitesource = createImgIcon(
  LogoWhitesource,
  'ToolLogoWhitesource'
);
