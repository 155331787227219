import { Box, Skeleton } from '@mui/material';
import { omit as _omit } from 'lodash-es';
import { FormattedMessage as FM } from 'react-intl';

import { SimpleDialog } from '@endorlabs/ui-common';

import { RemediationsExportDialogContent } from './RemediationsExportDialogContent';
import { RemediationsExportDialogProps } from './types';

/**
 * Dialog for Remediations Export
 */
export const RemediationsExportDialog = (
  props: RemediationsExportDialogProps
) => {
  const dialogProps = _omit(props, ['filter', 'namespace']);

  return (
    <SimpleDialog
      {...dialogProps}
      descriptionText={
        <FM defaultMessage="Exports the results for your current view and filter as a CSV file. " />
      }
      disableCloseOnOutsideClick
      titleText={<FM defaultMessage="Export Filtered Remediations" />}
    >
      {/* NOTE: avoiding mounting content until the dialog is open */}
      {dialogProps.open ? (
        <RemediationsExportDialogContent {...props} />
      ) : (
        <Box>
          <Skeleton variant="text" width={100} />
          <Skeleton variant="rectangular" height={400} />
        </Box>
      )}
    </SimpleDialog>
  );
};
