import { uniq as _uniq } from 'lodash-es';

import { UserInfoResponseTenantInfo as TenantInfo } from '@endorlabs/api_client';
import {
  getAncestorNamespaces,
  getParentNamespace,
  isRootNamespace,
} from '@endorlabs/endor-core/Namespace';
import {
  GraphAdjacencyList,
  GraphNode,
  GraphNodeId,
} from '@endorlabs/utils/graph';

export const buildNamespaceGraphData = (
  tenants: TenantInfo[],
  namespaces: string[] = []
) => {
  const nodesById: Record<GraphNodeId, GraphNode> = {};
  const graph: GraphAdjacencyList = {};

  for (const tenant of tenants) {
    const id = tenant.name;
    if (!id) continue;

    nodesById[id] = { id };
    graph[id] = graph[id] || [];
  }

  for (const ns of namespaces) {
    for (const id of getAncestorNamespaces(ns)) {
      nodesById[id] = { id };

      graph[id] = graph[id] || [];

      if (!isRootNamespace(id)) {
        const parent = getParentNamespace(id);
        graph[parent] = _uniq((graph[parent] || []).concat(id));
      }
    }
  }

  return { graph, nodes: Object.values(nodesById) };
};
