import { QueryKey, useQuery } from 'react-query';

import {
  AIQueryPackageData,
  AIQueryServiceApi,
  V1AIQueryQueryType,
  V1AIQuerySpec,
  V1Ecosystem,
  V1ListParameters,
} from '@endorlabs/api_client';

import { ResourceQueryOptions } from './types';
import { getClientConfiguration } from './utils';

export interface CreateAIQueryParams {
  namespace: string;
  prompt: string;
  ecosystem: V1Ecosystem;
}

const BASE_KEY = 'v1/ai-queries';
const QK = {
  query: ({ namespace, ...params }: CreateAIQueryParams): QueryKey =>
    [BASE_KEY, 'query', namespace, params] as const,
};
export const AIQueryQueryKeys = QK;

const getApiService = () => new AIQueryServiceApi(getClientConfiguration());

const createAIQuery = async (
  params: CreateAIQueryParams,
  signal?: AbortSignal
) => {
  const api = getApiService();
  const resp = await api.aIQueryServiceCreateAIQuery(
    params.namespace,
    {
      meta: {
        name: 'AI Query: ' + params.prompt.slice(0, 64),
      },
      spec: {
        input_prompt: params.prompt,
        ecosystem: params.ecosystem,
        query_type: V1AIQueryQueryType.OssExplorer,
      },
    },
    {
      // pass abort signal to Axios, to support request cancellation on param changes
      signal,
    }
  );

  return resp.data.spec as V1AIQuerySpec;
};

export const useCreateAIQuery = (
  params: CreateAIQueryParams,
  options: ResourceQueryOptions<V1AIQuerySpec> = {}
) => {
  return useQuery(
    QK.query(params),
    ({ signal }) => createAIQuery(params, signal),
    { ...options }
  );
};
