import { merge as _merge, uniq as _uniq } from 'lodash-es';
import { useMemo } from 'react';

import { getDependencyFileLocations } from '@endorlabs/endor-core/PackageVersion';
import {
  ProjectResource,
  ProjectVersionResource,
} from '@endorlabs/endor-core/Project';
import { filterExpressionBuilders } from '@endorlabs/filters';
import {
  RepoVersionResource,
  useGroupDependencyMetadata,
  useListPackageVersions,
} from '@endorlabs/queries';
import { UIProjectUtils } from '@endorlabs/ui-common';

import { buildDependencyGraphFromDependencyMetadata } from '../../Dependencies';

interface useProjectDependencyGraphsProps {
  namespace: string;
  project?: ProjectResource;
  projectVersion?: ProjectVersionResource;
  targetPackageVersionName: string;
}

export const useProjectResolvedDependencies = ({
  namespace,
  project,
  projectVersion,
  targetPackageVersionName,
}: useProjectDependencyGraphsProps) => {
  const packagesFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      projectVersion as RepoVersionResource
    );

  const dependenciesFilterExpression =
    UIProjectUtils.getProjectRelatedFilterExpressions(
      project,
      projectVersion as RepoVersionResource,
      {
        key: 'spec.importer_data.project_uuid',
      }
    );

  // For a dependency from a Project, attempt to load the dependency graph(s)
  // from the related PackageVersion object(s)
  const qListDependencyGraphs = useListPackageVersions(
    namespace,
    {
      enabled: !!packagesFilterExpression,
    },
    {
      filter:
        packagesFilterExpression &&
        filterExpressionBuilders.and([
          packagesFilterExpression,
          `spec.resolved_dependencies.dependencies.name=="${targetPackageVersionName}"`,
        ]),
      mask: [
        'meta.name',
        'spec.resolved_dependencies.dependencies',
        'spec.resolved_dependencies.dependency_graph',
      ].join(','),
      // Intentionally setting a low page size for potentially large dependency graph data
      page_size: 10,
      traverse: false,
    }
  );

  // Consider this a partial set of all dependency graphs if the response
  // indicates there may be additional dependency graphs.
  const isPartial =
    qListDependencyGraphs.isSuccess &&
    !!qListDependencyGraphs.data?.list?.response?.next_page_token;

  const qGroupedDependencyMetadata = useGroupDependencyMetadata(
    namespace,
    {
      enabled: !!namespace && !!dependenciesFilterExpression && isPartial,
    },
    {
      filter: dependenciesFilterExpression,
      group: {
        aggregation_paths: 'meta.name',
        unique_value_paths: [
          'spec.dependency_data.parent_version_name',
          'spec.importer_data.package_version_name',
        ].join(','),
      },
    }
  );

  const dependencyGraph = useMemo(() => {
    const dependencyGraph = buildDependencyGraphFromDependencyMetadata(
      qGroupedDependencyMetadata.data
    );

    if (qListDependencyGraphs.data?.list?.objects) {
      for (const o of qListDependencyGraphs.data.list.objects) {
        if (o.spec.resolved_dependencies?.dependency_graph) {
          _merge(
            dependencyGraph,
            o.spec.resolved_dependencies.dependency_graph
          );
        }
      }
    }

    return dependencyGraph;
  }, [qGroupedDependencyMetadata.data, qListDependencyGraphs.data]);

  const dependencyFileLocations = useMemo(() => {
    const allFileLocations = [];

    if (qListDependencyGraphs.data?.list?.objects) {
      for (const o of qListDependencyGraphs.data.list.objects) {
        const packageFileLocations = getDependencyFileLocations(o, {
          name: targetPackageVersionName,
        });
        allFileLocations.push(...packageFileLocations);
      }
    }

    return _uniq(allFileLocations).sort();
  }, [qListDependencyGraphs.data, targetPackageVersionName]);

  const packageVersionNames = useMemo(() => {
    const unique = new Set<string>();

    if (qListDependencyGraphs.data?.list?.objects) {
      for (const o of qListDependencyGraphs.data.list.objects) {
        unique.add(o.meta.name);
      }
    }

    for (const group of Object.values(
      qGroupedDependencyMetadata.data?.groups ?? {}
    )) {
      const packageNames =
        group.unique_values?.['spec.importer_data.package_version_name'] ?? [];

      for (const name of packageNames) {
        unique.add(String(name));
      }
    }

    return Array.from(unique.values());
  }, [qGroupedDependencyMetadata.data?.groups, qListDependencyGraphs.data]);

  const isLoading = [qListDependencyGraphs, qGroupedDependencyMetadata].some(
    (q) => q.isLoading
  );

  return {
    dependencyGraph,
    dependencyFileLocations,
    packageVersionNames,
    isLoading,
    isPartial,
  };
};
