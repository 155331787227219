import {
  FindingResource,
  PackageVersionResource,
  ProjectResource,
} from '@endorlabs/queries';

import { FindingCallPaths } from '../../domains/Findings';

export type FindingCallPathsDrawerContentProps = {
  finding?: FindingResource;
  findingPackageVersion?: PackageVersionResource;
  findingProject?: ProjectResource;
  isLoading: boolean;
};

export const FindingCallPathsDrawerContent = ({
  finding,
  findingPackageVersion,
  findingProject,
  isLoading,
}: FindingCallPathsDrawerContentProps) => {
  if (!isLoading && !finding) {
    // TODO: handle error case
    return <>Error</>;
  }

  return (
    <FindingCallPaths
      finding={finding}
      findingPackageVersion={findingPackageVersion}
      findingProject={findingProject}
      isLoading={isLoading}
    />
  );
};
