import { Stack, Typography } from '@mui/material';
import { isEmpty as _isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import {
  getCWEURL,
  getFindingsLanguageDisplay,
  getSASTSecurityFindingCwe,
} from '@endorlabs/endor-core/Finding';
import {
  AttributeDisplayStack,
  ExternalLink,
  FindingMetaTagsArrayDisplay,
  FormattedTypography,
  NilDisplay,
} from '@endorlabs/ui-common';

import { FindingsDetailSASTSectionProps } from '../../types';

const genResource = ({
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const cweData = sastRuleForFinding
    ? getSASTSecurityFindingCwe(sastRuleForFinding)
    : [];
  const languages = sastRuleForFinding?.spec?.rule?.languages ?? [];
  const tags = sastRuleForFinding?.spec?.rule?.metadata?.endor_tags ?? [];
  const resource = {
    cweId:
      sastRuleForFinding && !_isEmpty(cweData) ? (
        <Stack spacing={2}>
          {cweData?.map((cwe) => {
            const url = getCWEURL(cwe.cweId);
            return (
              <Stack key={cwe.cweId}>
                <ExternalLink
                  sx={{ display: 'inline-block' }}
                  variant="body2"
                  to={url}
                >
                  {cwe.cweId}
                </ExternalLink>
                <Typography variant="body2" sx={{ lineBreak: 'anywhere' }}>
                  {cwe.cweDescription}
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <NilDisplay variant="text" />
      ),
    languages: !_isEmpty(languages) ? (
      <FormattedTypography text={getFindingsLanguageDisplay(languages)} />
    ) : (
      <NilDisplay variant="text" />
    ),
    tags: <FindingMetaTagsArrayDisplay values={tags} />,
  };

  return resource;
};
const genRecords = () => {
  return [
    {
      attributeKey: 'cweId',
      heading: <FM defaultMessage="CWE Id" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'languages',
      heading: <FM defaultMessage="Languages" />,
      hideIfNil: true,
    },
    {
      attributeKey: 'tags',
      heading: <FM defaultMessage="Meta Tags" />,
      hideIfNil: true,
      nilDisplay: true,
    },
  ];
};
export const FindingSASTMetadataSection = ({
  isLoading,
  finding,
  namespace,
  sastRuleForFinding,
}: FindingsDetailSASTSectionProps) => {
  const records = useMemo(() => genRecords(), []);
  const resource = useMemo(
    () => genResource({ finding, namespace, sastRuleForFinding }),
    [finding, namespace, sastRuleForFinding]
  );
  return (
    <AttributeDisplayStack
      attributeRecords={records}
      headingWidth="30%"
      isLoading={isLoading}
      resource={resource}
      variant="flex"
    />
  );
};
