import {
  SpecEndorLicenseFeatureType,
  V1FindingCategory,
} from '@endorlabs/api_client';

/**
 * The max length in characters for a Finding title. If the Finding title
 * length exceeds this value, the title should be truncated.
 */
export const MAX_FINDING_TITLE_LENGTH = 135;

export const filterToLicenseMapping: Partial<
  Record<V1FindingCategory, SpecEndorLicenseFeatureType>
> = {
  [V1FindingCategory.Cicd]: SpecEndorLicenseFeatureType.CicdDiscovery,
  [V1FindingCategory.Scpm]: SpecEndorLicenseFeatureType.ScmPostureManagement,
  [V1FindingCategory.Secrets]: SpecEndorLicenseFeatureType.Secrets,
};
