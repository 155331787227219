import {
  FormLabel,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { VantaConfigVantaAuthMethod } from '@endorlabs/api_client';
import { ControlledTextField } from '@endorlabs/ui-common';

import { FormUpsertNotificationTargetFieldValues } from './FormUpsertNotificationTarget/types';

const VANTA_AUTH_METHODS = [
  {
    label: <FM defaultMessage="Access Token" />,
    value: VantaConfigVantaAuthMethod.AccessToken,
  },
  {
    label: <FM defaultMessage="API Key" />,
    value: VantaConfigVantaAuthMethod.AccessApiKey,
  },
];

type VantaConfigFieldValues = Exclude<
  FormUpsertNotificationTargetFieldValues['spec']['action']['vanta_config'],
  undefined
>;

type VantaActionTypeFieldValues = {
  spec: {
    action: {
      vanta_config: Pick<
        VantaConfigFieldValues,
        | 'auth_method'
        | 'access_token_auth'
        | 'api_key'
        | 'vulnerable_component_resource_id'
        | 'package_vulnerability_resource_id'
        | 'sca_resource_id'
      >;
    };
  };
};

export const VantaActionTypeFields = () => {
  const { space } = useTheme();
  const { formatMessage: fm } = useIntl();
  const { control, watch } = useFormContext<VantaActionTypeFieldValues>();

  const authMethod = watch('spec.action.vanta_config.auth_method');

  return (
    <Stack spacing={space.lg}>
      <Stack
        component="fieldset"
        spacing={space.sm}
        sx={{ margin: 0, padding: 0, border: 'none ' }}
      >
        <FormLabel component="legend">
          <Typography variant="body1" color="text.primary">
            <FM defaultMessage="Authentication" />
          </Typography>

          <Typography variant="body2">
            <FM defaultMessage="Configure authentication method and configuration used for Vanta." />
          </Typography>
        </FormLabel>

        {/* TODO: enable auth method selection when API Key support for Vanta is no longer beta.
        <ControlledTextField
          control={control}
          label={fm({ defaultMessage: 'Auth method' })}
          name="spec.action.vanta_config.auth_method"
          helperText={fm({
            defaultMessage: 'Authentication type to connect to Vanta',
          })}
          required
          select
        >
          {VANTA_AUTH_METHODS.map(({ label, value }, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </ControlledTextField>
        */}

        {authMethod === VantaConfigVantaAuthMethod.AccessToken && (
          <>
            <ControlledTextField
              control={control}
              defaultValue=""
              label={fm({ defaultMessage: 'Client ID' })}
              name="spec.action.vanta_config.access_token_auth.client_id"
              placeholder={fm({
                defaultMessage: 'Client ID for authenticataion',
              })}
              required
              rules={{
                minLength: {
                  value: 1,
                  message: fm({
                    defaultMessage: 'A non-empty value is required',
                  }),
                },
              }}
            />

            <ControlledTextField
              control={control}
              defaultValue=""
              label={fm({ defaultMessage: 'Client Secret' })}
              name="spec.action.vanta_config.access_token_auth.client_secret"
              placeholder={fm({
                defaultMessage: 'Client Secret for authenticataion',
              })}
              required
              rules={{
                minLength: {
                  value: 1,
                  message: fm({
                    defaultMessage: 'A non-empty value is required',
                  }),
                },
                maxLength: {
                  value: 1_024,
                  message: fm({
                    defaultMessage: 'The value must be 1024 characters or less',
                  }),
                },
              }}
              type="password"
            />
          </>
        )}

        {authMethod === VantaConfigVantaAuthMethod.AccessApiKey && (
          <ControlledTextField
            control={control}
            defaultValue=""
            label={fm({ defaultMessage: 'API Key' })}
            name="spec.action.vanta_config.api_key"
            placeholder={fm({
              defaultMessage: 'API key for authenticataion',
            })}
            rules={{
              required: fm({ defaultMessage: 'This is a required field' }),
              minLength: {
                value: 1,
                message: fm({
                  defaultMessage: 'A non-empty value is required',
                }),
              },
              maxLength: {
                value: 1_024,
                message: fm({
                  defaultMessage: 'The value must be 1024 characters or less',
                }),
              },
            }}
          />
        )}
      </Stack>

      <Stack
        component="fieldset"
        spacing={space.sm}
        sx={{ margin: 0, padding: 0, border: 'none ' }}
      >
        <FormLabel component="legend">
          <Typography variant="body1" color="text.primary">
            <FM defaultMessage="Vanta Resources" />
          </Typography>
          {/* add description if needed <Typography variant="body2">description</Typography> */}
        </FormLabel>

        <ControlledTextField
          control={control}
          defaultValue=""
          label={fm({ defaultMessage: 'Vulnerable Component' })}
          name="spec.action.vanta_config.vulnerable_component_resource_id"
          helperText={fm({
            defaultMessage:
              'The Resource ID of the Vulnerable Component created in Vanta.',
          })}
          placeholder={fm({
            defaultMessage: 'Resource ID',
          })}
          required
          rules={{
            minLength: {
              value: 1,
              message: fm({ defaultMessage: 'A non-empty value is required' }),
            },
            maxLength: {
              value: 64,
              message: fm({
                defaultMessage: 'The value must be 64 characters or less',
              }),
            },
          }}
        />

        <ControlledTextField
          control={control}
          defaultValue=""
          label={fm({ defaultMessage: 'Package Vulnerability' })}
          name="spec.action.vanta_config.package_vulnerability_resource_id"
          helperText={fm({
            defaultMessage:
              'The Resource ID of the Package Vulnerability created in Vanta.',
          })}
          placeholder={fm({
            defaultMessage: 'Resource ID',
          })}
          rules={{
            minLength: {
              value: 1,
              message: fm({ defaultMessage: 'A non-empty value is required' }),
            },
            maxLength: {
              value: 64,
              message: fm({
                defaultMessage: 'The value must be 64 characters or less',
              }),
            },
          }}
        />

        <ControlledTextField
          control={control}
          defaultValue=""
          label={fm({ defaultMessage: 'Static Code Analysis Vulnerability' })}
          name="spec.action.vanta_config.sca_resource_id"
          helperText={fm({
            defaultMessage:
              'Resource ID of the Static Code Analysis Vulnerability created in Vanta.',
          })}
          placeholder={fm({
            defaultMessage: 'Resource ID',
          })}
          rules={{
            minLength: {
              value: 1,
              message: fm({ defaultMessage: 'A non-empty value is required' }),
            },
            maxLength: {
              value: 64,
              message: fm({
                defaultMessage: 'The value must be 64 characters or less',
              }),
            },
          }}
        />
      </Stack>
    </Stack>
  );
};
