import { Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage as FM } from 'react-intl';

import { V1Ecosystem } from '@endorlabs/api_client';
import {
  FindingResource,
  getSASTSecurityFindingCweId,
  isSASTFinding,
  isSecurityFinding,
  isSelfFinding,
  isVulnerabilityFinding,
} from '@endorlabs/endor-core/Finding';
import { PackageVersionResource, useFeatureFlags } from '@endorlabs/queries';
import {
  ButtonLinkSecondary,
  DependencyPathDisplay,
  FindingNameDisplay,
  UIPackageVersionUtils,
} from '@endorlabs/ui-common';

import { DetailDrawerContainer } from '../../../../components/DetailDrawer/DetailDrawerContainer';
import { DetailDrawerSection } from '../../../../components/DetailDrawer/DetailDrawerSection';
import { DetailDrawerSectionStack } from '../../../../components/DetailDrawer/DetailDrawerSectionStack';
import { DetailDrawerTabs } from '../../../../components/DetailDrawer/DetailDrawerTabs';
import { FindingCallPathsDrawerContent } from '../../../../components/FindingCallPathsDrawer';
import { getFindingsPath } from '../../../../routes';
import { useFindingDetailData } from '../../hooks/useFindingDetailData';
import { FindingExceptionPoliciesSection } from '../FindingExceptionPoliciesSection';
import { FindingFixInfoSection } from '../FindingFixInfoSection';
import { FindingMetadataSection } from '../FindingMetadataSection';
import { FindingPrimaryAttributesSection } from '../FindingPrimaryAttributesSection';
import { FindingRemediationsSection } from '../FindingRemediationsSection';
import { FindingRiskDetailsSection } from '../FindingRiskDetailsSection';
import { FindingRiskStatsSection } from '../FindingRiskStatsSection';
import { FindingRuleDrawer } from '../FindingSASTDetailDrawer';
import { FindingInfoDrawer } from '../FindingSASTDetailDrawer/FindingSASTInfoDrawer';
import { FINDINGS_DETAIL_DRAWER_TABS } from './constants';
import { getTargetDependencyEcosystem } from './utils';

export interface FindingDetailDrawerV2Props {
  finding?: FindingResource;
  findingUuid: string;
  namespace: string;
}

export const FindingDetailDrawerV2 = ({
  findingUuid,
  namespace,
}: FindingDetailDrawerV2Props) => {
  const { ENABLE_SAST_FEATURE: isSASTFeatureEnabled } = useFeatureFlags();

  const {
    exceptionPolicies,
    finding,
    hasRemediations,
    isLoading,
    packageVersion,
    project,
    sastRuleForFinding,
  } = useFindingDetailData({
    findingUuid,
    namespace,
  });

  const isSecurity = finding ? isSecurityFinding(finding) : false;
  const isVuln = finding ? isVulnerabilityFinding(finding) : false;
  const isSAST = finding
    ? isSASTFeatureEnabled && isSASTFinding(finding)
    : false;

  const cweIdData = useMemo(() => {
    if (isSAST && sastRuleForFinding) {
      return getSASTSecurityFindingCweId(sastRuleForFinding);
    }
    return [];
  }, [sastRuleForFinding, isSAST]);

  const drawerTabRecords = useMemo(() => {
    if (!finding) return [];
    if (isSAST)
      return FINDINGS_DETAIL_DRAWER_TABS.filter((t) =>
        ['sastInfo', 'sastRule'].includes(t.value)
      );
    const tabs = FINDINGS_DETAIL_DRAWER_TABS.filter(
      (t) => !['sastInfo', 'sastRule'].includes(t.value)
    );
    if (!isSecurity)
      return tabs.filter((t) => ['info', 'depPath'].includes(t.value));
    return tabs;
  }, [finding, isSAST, isSecurity]);

  const infoContent = (
    <DetailDrawerSectionStack>
      {hasRemediations && (
        <FindingRemediationsSection
          findingUuid={finding?.uuid}
          namespace={namespace}
        />
      )}

      {isVuln && (
        <FindingRiskStatsSection finding={finding} namespace={namespace} />
      )}

      <FindingPrimaryAttributesSection
        finding={finding}
        isLoading={isLoading}
        namespace={namespace}
        packageVersion={packageVersion}
        project={project}
      />

      {exceptionPolicies.length > 0 && (
        <FindingExceptionPoliciesSection
          exceptionPolicies={exceptionPolicies}
        />
      )}

      <FindingRiskDetailsSection
        finding={finding}
        isLoading={isLoading}
        namespace={namespace}
      />

      {isSecurity && (
        <FindingMetadataSection
          finding={finding}
          isLoading={isLoading}
          namespace={namespace}
        />
      )}
    </DetailDrawerSectionStack>
  );

  const depPathContent = (
    <DetailDrawerSection>
      <DependencyPathDisplay
        dependencyGraph={
          (packageVersion?.spec?.resolved_dependencies
            ?.dependency_graph as unknown as Record<string, string[]>) ??
          ({} as Record<string, string[]>)
        }
        displayAsAccordion={false}
        isLoading={isLoading}
        project={project}
        sourcePackageNames={[packageVersion?.meta?.name ?? '']}
        targetPackageName={UIPackageVersionUtils.deriveFullPackageName({
          ecosystem:
            finding && packageVersion
              ? getTargetDependencyEcosystem(finding, packageVersion)
              : V1Ecosystem.Unspecified,
          name: finding?.spec.target_dependency_name ?? '',
          version: finding?.spec.target_dependency_version ?? '',
        })}
      />
    </DetailDrawerSection>
  );

  const fixInfo = (
    <FindingFixInfoSection
      finding={finding}
      isLoading={isLoading}
      namespace={namespace}
    />
  );

  const callPath = (
    <DetailDrawerSection>
      <FindingCallPathsDrawerContent
        finding={finding}
        findingPackageVersion={packageVersion}
        findingProject={project}
        isLoading={isLoading}
      />
    </DetailDrawerSection>
  );

  const sastInfoContent = (
    <FindingInfoDrawer
      sastRuleForFinding={sastRuleForFinding}
      finding={finding}
      isLoading={isLoading}
      namespace={namespace}
      project={project}
    />
  );

  const sastRuleContent = (
    <FindingRuleDrawer sastRuleForFinding={sastRuleForFinding} />
  );

  return (
    <DetailDrawerContainer
      headerProps={{
        action: finding && (
          <ButtonLinkSecondary
            linkProps={{
              to: getFindingsPath({
                tenantName: namespace,
                uuid: finding?.uuid,
              }),
            }}
          >
            <FM defaultMessage="View Details" />
          </ButtonLinkSecondary>
        ),
        title: (
          <FindingNameDisplay
            finding={finding}
            size="large"
            showCwe={isSAST ? true : false}
            cweIdData={cweIdData}
          />
        ),
      }}
    >
      {/* Finding is required to determine drawerTabRecords */}
      {!finding ? (
        <Skeleton />
      ) : (
        <DetailDrawerTabs
          id="FindingDetailDrawer"
          tabRecords={drawerTabRecords}
          tabPanelMap={{
            callPath,
            depPath:
              finding && packageVersion && !isSelfFinding(finding)
                ? depPathContent
                : undefined,
            fix: fixInfo,
            info: infoContent,
            sastInfo: sastInfoContent,
            sastRule: sastRuleContent,
          }}
        />
      )}
    </DetailDrawerContainer>
  );
};
