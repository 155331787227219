import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Grid, Stack, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { ErrorOption, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage as FM, useIntl } from 'react-intl';

import { IQueryErrorResponse } from '@endorlabs/queries';
import {
  ButtonPrimary,
  ControlledTextField,
  IconAlertTriangle,
  IconSave,
} from '@endorlabs/ui-common';

import { NamedRoutes } from '../../../routes/constants';

export interface LoginFormSSOFieldValues {
  tenant: string;
}

export interface LoginFormSSOProps {
  isSubmitting?: boolean;
  onSubmit?: (tenant: string) => void;
  serverErrorResponse?: IQueryErrorResponse;
}

const getDefaultErrorMessage = (tenantName: string) =>
  `Unable to retrieve provider data for tenant ${tenantName}: no identity providers configured for the tenant ${tenantName}`;

/**
 * Form used to log in using SSO
 */
export const LoginFormSSO = ({ returnTo }: { returnTo?: string }) => {
  const { formatMessage: fm } = useIntl();
  const { space } = useTheme();
  const { handleSubmit: hookFormSubmit, ...formMethods } =
    useForm<LoginFormSSOFieldValues>();
  const [localError, setLocalError] = useState<ErrorOption | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = useCallback(
    async (fieldValues: LoginFormSSOFieldValues) => {
      setIsSubmitting(true);
      setLocalError(undefined);

      const { tenant } = fieldValues;
      const urlParams = new URLSearchParams({ tenant });
      if (returnTo) {
        urlParams.set('redirect', returnTo);
      }

      const url = `${NamedRoutes.AUTH_SSO}?${urlParams}`;
      const res = await fetch(url, { method: 'GET', redirect: 'manual' });

      if (!res.ok && res.type !== 'opaqueredirect') {
        const errorMessage = await res.text();
        setLocalError({
          message: errorMessage || getDefaultErrorMessage(tenant),
        });

        setIsSubmitting(false);
        return;
      }

      window?.open(url, '_self');
      setIsSubmitting(false);
    },
    [returnTo]
  );

  return (
    <Stack spacing={space.md} width="100%" maxWidth="485px">
      {localError && (
        <Grid item>
          <Alert severity="error" icon={<IconAlertTriangle />}>
            <AlertTitle>{localError.message}</AlertTitle>
          </Alert>
        </Grid>
      )}

      <FormProvider handleSubmit={hookFormSubmit} {...formMethods}>
        <form id="FormSSO" onSubmit={hookFormSubmit(handleSubmit)} noValidate>
          <Grid container direction="column" rowSpacing={5}>
            <Grid item xs={12}>
              <ControlledTextField
                control={formMethods.control}
                name="tenant"
                label={fm({
                  defaultMessage: 'Tenant Name',
                })}
                required
                rules={{
                  // Setting `rules.required` for specific error message
                  required: fm({ defaultMessage: 'Tenant name is required' }),
                }}
                sx={{
                  '& .MuiInputLabel-shrink': {
                    // HACK: overrides scale transform added to form labels
                    transform: `translate(0, -3px) scale(1)`,
                  },
                }}
              />
            </Grid>

            <Grid item>
              {isSubmitting ? (
                <LoadingButton
                  loading
                  loadingPosition="start"
                  startIcon={<IconSave />}
                  variant="contained"
                >
                  <FM defaultMessage="Log in with SSO" />
                </LoadingButton>
              ) : (
                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  <FM defaultMessage="Log in with SSO" />
                </ButtonPrimary>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Stack>
  );
};
