import { castArray as _castArray } from 'lodash-es';
import { useCallback } from 'react';

import { V1Ecosystem, V1Method } from '@endorlabs/api_client';
import { walkFilter } from '@endorlabs/filters';
import { useSession } from '@endorlabs/queries';

import {
  useAuthInfo,
  useEventTracking,
  UserEventKey,
} from '../../../providers';
import { FilterState } from '../../filters';

/**
 * Wrapper around AuthInfo and Event Tracking for onboarding-specific event tracking
 */
export const useOnboardingUserEventTracking = () => {
  const { activeNamespace, checkActivePermission } = useAuthInfo();
  const { trackUserEvent } = useEventTracking();

  const { getLastUsedTenant } = useSession();

  /**
   * Wrapper around trackUserEvent to ensure user permission exists to create the event
   */
  const trackOnboardingUserEvent = useCallback(
    (key: UserEventKey, value?: string, properties?: any) => {
      const canCreateUserTelemetry = checkActivePermission(
        V1Method.Create,
        'UserTelemetry'
      );

      if (canCreateUserTelemetry) {
        trackUserEvent(activeNamespace, key, value, properties);
      }
    },
    [activeNamespace, checkActivePermission, trackUserEvent]
  );

  /**
   * Utility to check DroidGPT usage, and track related user events
   */
  const checkDroidGPTUsed = useCallback(
    (props: {
      isLoading: boolean;
      isEmptyState: boolean;
      prompt: string;
      ecosystem: V1Ecosystem;
    }) => {
      const { isLoading, isEmptyState, prompt, ecosystem } = props;

      // Because DroidGPT usage is under `oss`, finding last tenant
      const lastUsedTenant = getLastUsedTenant();
      if (!lastUsedTenant) return;

      // Mark as INITIATED if the user attempts a query
      if (isLoading) {
        trackUserEvent(lastUsedTenant, 'USED_DROID_GPT', 'INITIATED', {
          ecosystem,
          prompt,
        });
        return;
      }

      // Only mark as COMPLELTED if the query succeeds
      if (!isEmptyState) {
        trackUserEvent(lastUsedTenant, 'USED_DROID_GPT', 'COMPLETED', {
          ecosystem,
          prompt,
        });
      }
    },
    [getLastUsedTenant, trackUserEvent]
  );

  /**
   * Utility to check a given Filter, and track user event when reachability filters exist
   */
  const checkFindingReachabilityFilter = useCallback(
    (filterValues: FilterState['values']) => {
      // Skip when filter is empty
      if (!filterValues || !filterValues.size) {
        return;
      }

      // Traverse the filter value, recording reachability if exists
      filterValues.forEach((filter) => {
        const reachabilityFilters: unknown[] = [];

        walkFilter(filter, {
          onVisit: (valueFilter) => {
            if (valueFilter.key !== 'spec.finding_tags') return;

            const matchingTags = _castArray(valueFilter.value).filter((v) =>
              // HACK: match for Reachable/Unreachable functions and dependencies
              String(v).includes('REACHABLE_')
            );
            reachabilityFilters.push(...matchingTags);
          },
        });

        if (reachabilityFilters.length) {
          trackOnboardingUserEvent('SET_REACHABILITY_FILTER', 'COMPLETED', {
            reachabilityFilters,
          });
        }
      });
    },
    [trackOnboardingUserEvent]
  );

  return {
    checkDroidGPTUsed,
    checkFindingReachabilityFilter,
    trackOnboardingUserEvent,
  };
};
