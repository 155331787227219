export const ServiceRequestColumnIdMap: Record<string, string> = {
  approver: '',
  createTime: 'meta.create_time',
  creator: 'meta.created_by',
  description: 'spec.description',
  operation: 'spec.method',
  resource: 'spec.resource',
  status: 'spec.status',
  tenant: 'spec.target_namespace',
  updateTime: 'meta.update_time',
  uuid: 'uuid',
};
