import {
  BasicTourStepProps,
  TourStepDefinition,
  TourStepNames,
  TourSteps,
} from '../types';
import {
  onStepNext,
  onStepShow,
  waitForElementsAvailable,
} from '../utils/tourHelpers';
import { getAssuredPackageVersionStep } from './assuredPackageVersionStep';
import { getDashboardStep } from './dashboardStep';
import { getProjectFindingsStep } from './projectFindingsStep';
import { getRemediationsStep } from './remediationsStep';
import { ProductTourDefaultOptions } from './TourOptions';

const tourStepsTotal = TourSteps.length;

const getTourStepDefinition = (
  tourProps: BasicTourStepProps
): Partial<TourStepDefinition> => {
  // Define all tour steps available
  const tourStepDefs = {
    [TourStepNames.assuredPackageVersion]:
      getAssuredPackageVersionStep(tourProps),
    [TourStepNames.dashboard]: getDashboardStep(tourProps),
    [TourStepNames.projectFindings]: getProjectFindingsStep(tourProps),
    [TourStepNames.remediations]: getRemediationsStep(tourProps),
  };

  // @ts-expect-error - attachTo.on wants PopperPlacement instead of string
  return {
    ...tourStepDefs[tourProps.id],
    id: tourProps.id,
    stepNumber: tourProps.stepNumber + 1,
  };
};

const getStepBeforePromise = (
  stepDef: Partial<TourStepDefinition>,
  props: BasicTourStepProps
) => {
  const { attachTo, stepUrl } = stepDef;

  // The default before promise navigates to the stepUrl, if provided, & waits for the main attachTo element
  return async () => {
    const elementSelectors = [attachTo?.element as string];

    if (stepUrl) {
      await props.navigate({ to: stepUrl });
    }

    const elements = await waitForElementsAvailable(elementSelectors);
    return elements;
  };
};

export const getStepDefinition = (props: BasicTourStepProps) => {
  const stepDef = getTourStepDefinition(props);

  const { stepNumber } = props;

  // If a "Learn More" URL is present, add a linking button
  let buttonDefinitions =
    ProductTourDefaultOptions?.defaultStepOptions?.buttons ?? [];

  // Change button if on last step
  if (stepNumber === tourStepsTotal - 1) {
    buttonDefinitions = [
      {
        action: function () {
          return this ? this.complete() : undefined;
        },
        text: 'Finish',
      },
    ];
  }

  // Add a docs link if URL provided
  if (stepDef.urlLearn) {
    buttonDefinitions = buttonDefinitions.concat([
      {
        action: function () {
          window.open(stepDef.urlLearn, '_blank');
        },
        secondary: true,
        text: 'Learn More',
      },
    ]);
  }

  const stepOptions = {
    beforeShowPromise:
      stepDef.beforeShowPromise || getStepBeforePromise(stepDef, props),

    buttons: buttonDefinitions,

    // Templated primary content
    text: `
      <div class="product-tour-step-count">
        Step ${stepNumber + 1} of ${tourStepsTotal}
      </div>

      <div class="product-tour-step-desc">
        ${stepDef.description}
      </div>
    `,

    when: {
      'before-hide': function () {
        onStepNext(props, stepDef);
      },

      // hide: function () {},

      // 'before-show': async function () {},

      show: async function () {
        await onStepShow(props, stepDef);
      },
    },
  };

  /**
   * NOTE: This is an augmented version of StepOptions with additional properties.
   * Shepherd passes whatever object we give it to the step.
   * Not sure if this is intentional, but it's helpful.
   */
  return {
    ...stepDef,
    ...stepOptions,
  };
};
