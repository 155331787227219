import PropTypes from 'prop-types';
import { forwardRef } from 'react';

type GalaxyProps = {
  color?: string;
  size?: string | number;
};

const Galaxy = forwardRef<SVGSVGElement, GalaxyProps>(
  ({ color = 'currentColor', size = 24, ...rest }, ref) => {
    return (
      <svg
        ref={ref}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
      >
        <path
          d="M12.4622 16C17.4328 16 21.4622 12.866 21.4622 9C21.4622 5.13401 17.4328 2 12.4622 2M12.4622 16C15.2803 16 17.0067 12.9462 15.5251 10.6697M12.4622 16C9.66 16 8.24707 12.9395 9.52929 10.7197M15.5251 10.6697C13.4622 7.5 8.88466 5.87209 5.30504 7.93771C1.72543 10.0033 0.497425 14.5755 2.56222 18.15M15.5251 10.6697C14.113 8.5 10.8115 8.5 9.52929 10.7197M9.52929 10.7197C7.4645 14.2942 8.6925 18.8664 12.2721 20.932C15.8517 22.9977 20.4274 21.7745 22.4922 18.2"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
);

Galaxy.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Galaxy.displayName = 'Galaxy';

export default Galaxy;
